import useApi from '~/wm/packages/api/hook/useApi';
import { useCallback } from 'react';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { PaginationState, SortState } from '~/neo-ui/packages/table/packages/console/types';
import { buildOrdering } from '~/neo-ui/packages/table/packages/console/buildConsoleRequest';
import { hardwareConsoleIdLookup } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { Response } from '@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Console/HardwareConsoleIdLookupControllerNested.gen';

type GetHardwareConsoleByIdsFunction = (
  scope: FrontendScope,
  pagination: PaginationState,
  sort: SortState | undefined,
  assetIds: string[],
) => Promise<Response | undefined>;

type UseHardwareConsoleIdLookUp = () => {
  getHardwareConsole: GetHardwareConsoleByIdsFunction;
};

const useHardwareConsoleIdLookUp: UseHardwareConsoleIdLookUp = () => {
  const { callApi } = useApi();

  const getHardwareConsole = useCallback<GetHardwareConsoleByIdsFunction>(
    async (scope: FrontendScope, pagination: PaginationState, sort: SortState | undefined, assetIds: string[]) => {
      const response = await callApi(() =>
        hardwareConsoleIdLookup({
          sort:
            typeof sort === 'undefined'
              ? []
              : [
                  {
                    field: sort.key,
                    ordering: buildOrdering(sort.order),
                  },
                ],
          scope,
          assetIds,
          pagination: {
            pageNumber: pagination.pageNumber,
            size: pagination.perPageSize,
            pageId: '',
          },
        }),
      );

      if (!response) {
        return;
      }

      return response;
    },
    [callApi],
  );

  return { getHardwareConsole };
};

export default useHardwareConsoleIdLookUp;
