import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import React from 'react';
import Theme from '~/neo-ui/packages/color/Theme';
import { borderRadiusToCode } from '~/neo-ui/packages/style/BorderRadius';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

type ConsoleSingleSelectOptionProps<T extends string> = {
  key: string;
  option: SelectOption<T>;
  onSelectOption: (item: T) => void;
  onDeselectOption: (item: T) => void;
  isSelected: boolean;
  selectedIcon?: IconType;
  theme?: Theme;
};

const ConsoleSingleSelectOption = <T extends string>({
  key,
  option,
  onSelectOption,
  onDeselectOption,
  isSelected,
  selectedIcon,
  theme = 'secondary',
}: ConsoleSingleSelectOptionProps<T>) => {
  const themeMap = useTheme(theme).themeMap;
  const handleOptionSelection = (
    selectedOption: SelectOption<T>,
    isSelected: boolean,
    onSelectOption: (item: T) => void,
    onDeselectOption: (item: T) => void,
  ) => (isSelected ? onDeselectOption(selectedOption.value) : onSelectOption(selectedOption.value));

  return (
    <div
      key={key}
      onClick={() => handleOptionSelection(option, isSelected, onSelectOption, onDeselectOption)}
      css={css`
        padding: 0.625rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: ${isSelected && colorToCode(`${themeMap.backgroundAccent}`)};
        color: ${isSelected && colorToCode(`${themeMap.backgroundContrast}`)};
        border-radius: ${borderRadiusToCode('radius200')};
        &:hover {
          background-color: ${colorToCode(`${themeMap.backgroundRaised}`)};
          cursor: pointer;
        }
      `}
    >
      <Label
        heightSize={'md'}
        css={css`
          line-height: 1.75rem;
        `}
      >
        {option.label}
      </Label>
      {isSelected && selectedIcon && (
        <div
          css={css`
            margin-left: auto;
            display: flex;
          `}
        >
          <Icon
            icon={selectedIcon}
            color={isSelected && themeMap.backgroundContrast}
          />
        </div>
      )}
    </div>
  );
};

export default ConsoleSingleSelectOption;
