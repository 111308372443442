import * as React from 'react';
import { ConsoleState, defaultConsoleState, emptyColumns } from '~/neo-ui/packages/table/packages/console/types';
import { UseSelection } from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/hooks/useItemSelection';

export type ConsoleData = {
  consoleState: ConsoleState;
  setConsoleState: React.Dispatch<React.SetStateAction<ConsoleState>>;
  columns: Set<string> | undefined;
  setColumns: React.Dispatch<React.SetStateAction<Set<string> | undefined>>;
  filterFrame: object;
  setFilterFrame: React.Dispatch<React.SetStateAction<object>>;
  selectedConsoleItems: UseSelection<string>;
  defaultColumns: Set<string> | undefined;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ConsoleContext = React.createContext<ConsoleData>({
  consoleState: defaultConsoleState,
  setConsoleState: _ => {},
  columns: emptyColumns,
  setColumns: _ => {},
  filterFrame: {},
  setFilterFrame: _ => {},
  selectedConsoleItems: {
    areSetsEqual: (_items1: Set<string>, _items2: Set<string>) => false,
    clearSelectedItems: () => {},
    deselectItem: (_item: string) => {},
    deselectItems: (_items: string[], _itemClicked: string | undefined) => {},
    isItemSelected: (_item: string) => false,
    lastItem: undefined,
    overwriteSelectedItems: (_items: string[], _itemClicked: string | undefined) => {},
    selectItem: (_item: string) => {},
    selectItems: (_items: string[], _itemClicked: string | undefined) => {},
    selectSingleItem: (_item: string) => {},
    selectedItems: new Set<string>(),
  },
  defaultColumns: undefined,
});
export default ConsoleContext;
