import { DisposalDynamicPricingDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Dto/Model.gen';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import DisposalAssetTypeCountFormData from '~/wm/packages/disposal/types/DisposalAssetTypeCountFormData';
import DisposalCheckoutCouponFormData from '~/wm/packages/disposal/types/DisposalCheckoutCouponFormData';
import useDisposalDynamicPricingCalculator from '~/wm/packages/disposal/hooks/useDisposalDynamicPricingCalculator';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import * as React from 'react';

export type DisposalSummaryLayoutBarProps<T> = {
  assetTypeCountsFieldKey: FieldKeyExpression<T>;
  couponFieldKey: FieldKeyExpression<T>;
  disposalDynamicPricing: DisposalDynamicPricingDto;
};

const OrderPaymentCostModule = <T,>({
  assetTypeCountsFieldKey,
  couponFieldKey,
  disposalDynamicPricing,
}: DisposalSummaryLayoutBarProps<T>) => {
  // We only support USD prices for disposal orders
  const currencyCode = 'USD';
  const { getFormInput } = useFormContext<T>();

  const assetTypeCounts = getFormInput<DisposalAssetTypeCountFormData[]>(assetTypeCountsFieldKey).value;

  const assetCount = assetTypeCounts.reduce((final, current) => final + parseInt(String(current.count), 10), 0);

  const coupon = getFormInput<DisposalCheckoutCouponFormData>(couponFieldKey).value;

  const couponDiscount = typeof coupon !== 'undefined' && typeof coupon.offerCostSubunits !== 'undefined' ? coupon.offerCostSubunits : 0;

  const { totalCostSubunits, shippingCostSubunits } = useDisposalDynamicPricingCalculator({
    ...disposalDynamicPricing,
    assetCount,
  });

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.375rem;
        `}
      >
        <Header size={4}>Total</Header>
        <Label color={'dark-900-64'}>Cost before tax</Label>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 0.3125rem;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Header
            size={4}
            color={'dark-900-64'}
            css={css`
              margin-right: 0.3125rem;
            `}
          >
            {currencyCode}
          </Header>
          <Header
            size={4}
            weight={'bold'}
          >
            {formatCurrency(totalCostSubunits - couponDiscount, currencyCode)}
          </Header>
        </div>
        <div
          css={css`
            text-align: right;
          `}
        >
          <Label
            color={shippingCostSubunits ? 'info-400' : 'positive-400'}
            css={css`
              display: inline;
              margin-right: 0.125rem;
            `}
          >
            {shippingCostSubunits
              ? `Add ${disposalDynamicPricing.assetCountUntilFreeShipping - assetCount} asset${
                  disposalDynamicPricing.assetCountUntilFreeShipping - assetCount === 1 ? '' : 's'
                } for free shipping`
              : 'Free shipping'}
          </Label>
          <Label
            muted={!shippingCostSubunits}
            css={css`
              display: inline;
              ${!shippingCostSubunits && 'text-decoration: line-through;'}
            `}
          >
            {shippingCostSubunits
              ? `• Shipping ${formatCurrency(shippingCostSubunits, currencyCode)}`
              : formatCurrency(disposalDynamicPricing.shippingCostSubunits, currencyCode)}
          </Label>
        </div>
      </div>
    </div>
  );
};

export default OrderPaymentCostModule;
