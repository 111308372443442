import { css } from '@emotion/react';
import * as React from 'react';
import {
  CellProps,
  Column,
  DefaultSortTypes,
  FooterProps,
  HeaderProps,
  Renderer,
  Row,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { assertNeverOrThrow } from '~/extensions/packages/types/assertNever';
import { reactMemoTyped } from '~/extensions/packages/types/reactMemoTyped';
import PaginationControl from '~/neo-ui/packages/table/packages/data-table/packages/pagination-control/PaginationControl';
import TableFooter from '~/neo-ui/packages/table/packages/data-table/packages/table-footer/TableFooter';
import TableHead from '~/neo-ui/packages/table/packages/data-table/packages/table-head/TableHead';
import { FieldKeyExpression, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { PaginationState } from '~/neo-ui/packages/table/packages/filter-table/FilterTable';
import TableSearchInput from '~/neo-ui/packages/table/packages/table-search-input/TableSearchInput';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Testable from '~/neo-ui/packages/testable/Testable';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { pascalCaseToSpinalCase } from '~/extensions/packages/casing/pascalSpinalConversion';
import { Styleable } from '~/neo-ui/model/capacity';

export type DataTableClientPagination = 'block' | 'inline';

export type PaginationMetadata = {
  /**
   * How many pages are there in total
   */
  totalPages: number;

  /**
   * How many results are there in total
   */
  totalResults: number;
};

export type SortingOrder = 'ascending' | 'descending';
export type RowSeparation = 'zebra' | 'border';
export type SortState<T> = {
  key: FieldKeyExpression<T>;
  order: SortingOrder;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type DataTableProps<T extends {}> = {
  title?: string | React.ReactNode;
  description?: string;

  // Data
  data: T[] | 'loading';
  columns: DataTableColumn<T>[];
  // Creates the row id from the row data
  makeRowKey?: (row: T) => string;

  defaultPagination?: PaginationState;
  onPageChange?: (pagination: PaginationState) => void;
  paginationMetadata?: PaginationMetadata | 'unknown';
  enableClientPaginated?: DataTableClientPagination;

  disableSort?: boolean;
  enableClientSorted?: boolean;

  defaultSort?: SortState<T>;
  sortableColumnKeys?: string[];
  onSortChange?: (column: SortState<T> | undefined) => void;

  defaultSearchQuery?: string;
  onSearchQueryChange?: (searchQuery: string) => void;
  hasServerSideSearch?: boolean;
  disableSearch?: boolean;

  EmptyStatePlaceholder?: string | React.ComponentType;

  isVerticalAligned?: boolean;
  isDisplayDataOnly?: boolean;
  rowSeparation?: RowSeparation;
  hasTableBorder?: boolean;

  tableClass?: string;

  getRowOptions?: (row: Row<T>) => RowOptions;

  paginationInLineWithSearch?: boolean;

  // Should be true if the table component is not responsible for sorting its own values, for instance if those values are
  // sorted in the backend.
  manualSort?: boolean;

  /**
   * Optional, used for pinned rows
   */
  pinnedData?: T[];
} & Styleable;

export type RowOptions = {
  backgroundColor: string | undefined;
};

export type DataTableColumn<T extends object> = {
  /**
   * Specifies the mapping of operations on this column
   * (e.g., sorting) to a particular field in the data.
   *
   * Required for sorting functionality.
   */
  fieldKey: FieldKeyExpression<T> | string;

  Header: Renderer<HeaderProps<T>>;
  Footer?: Renderer<Record<string, never>>;

  renderCell: (row: T) => React.ReactNode;

  /**
   * Determines if this field can be globally searched
   */
  canClientSideSearch?: boolean;

  width?: string;
  /**
   * This applies styling to ensure text content of the cell will be ellipsized.
   *
   * Typically this is used when columns have specified widths, and we don't
   * want the text to wrap.
   *
   * This prevents column widths from auto-sizing based on the width of the
   * text content.
   */
  ellipsizeTextContent?: boolean;
  sortType?: DefaultSortTypes;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const DataTable = <T extends {}>({
  title,
  description,
  data,
  columns,
  makeRowKey,

  // Pagination
  defaultPagination: defaultPaginationParent,
  paginationMetadata,
  onPageChange,
  enableClientPaginated,

  defaultSearchQuery,
  onSearchQueryChange,
  hasServerSideSearch = false,
  disableSearch = false,

  disableSort = false,
  enableClientSorted = false,

  defaultSort,
  onSortChange,
  sortableColumnKeys: sortableColumnKeysOrUndefined,

  EmptyStatePlaceholder,

  isVerticalAligned = true,
  isDisplayDataOnly = false,
  rowSeparation = 'zebra',
  hasTableBorder = true,
  tableClass,
  getRowOptions,
  className,

  manualSort = false,
  pinnedData,
}: DataTableProps<T>) => {
  // weird optimization because empty array changes reference each render
  const emptyArray = React.useRef([]).current;
  const sortableColumnKeys = sortableColumnKeysOrUndefined ?? emptyArray;

  const [defaultPagination, setDefaultPagination] = React.useState(defaultPaginationParent);
  React.useEffect(() => {
    setDefaultPagination(defaultPaginationParent);
  }, [defaultPaginationParent]);

  const hasFooter = React.useMemo(() => columns.some(column => typeof column.Footer !== 'undefined'), [columns]);

  const searchType: 'none' | 'client' | 'server' = React.useMemo(() => {
    if (hasServerSideSearch) {
      return 'server';
    }
    if (columns.some(column => column.canClientSideSearch === true)) {
      return 'client';
    }
    return 'none';
  }, [columns, hasServerSideSearch]);

  const reactTableColumns: Column<T>[] = React.useMemo(
    () =>
      columns
        .map((column): Partial<Column<T>> => {
          const fieldKeyValue = resolveFieldKey(column.fieldKey);
          return {
            id: fieldKeyValue,
            accessor: fieldKeyValue as keyof T,
            Header: column.Header,
            ...{
              Footer: column.Footer as Renderer<FooterProps<T>> | undefined,
            }, // Update the type of Footer
            Cell: ({ row: { original } }: CellProps<T>): React.ReactElement | null => {
              const renderedCell = column.renderCell(original);

              // Check if renderedCell is undefined or null, and return null in those cases
              if (renderedCell === undefined || renderedCell === null) {
                return null;
              }

              return <>{renderedCell}</>;
            },
            width: column.width,
            disableGlobalFilter: !column.canClientSideSearch,
            ...{ ellipsizeTextContent: column.ellipsizeTextContent },
            ...(column.sortType && { sortType: column.sortType }),
          };
        })
        // Type is asserted since certain fields typed as required
        // by react-table are not strictly necessary
        .map(column => column as Column<T>),
    [columns],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    setGlobalFilter,
    gotoPage,
    setPageSize,
    pageCount,
    page,
    state: { pageSize, pageIndex },
  } = useTable(
    {
      columns: reactTableColumns,
      data: data === 'loading' ? [] : typeof pinnedData === 'undefined' ? data : pinnedData.concat(data),
      getRowId: makeRowKey,
      disableGlobalFilter: searchType !== 'client',
      autoResetGlobalFilter: false,

      disableSortRemove: true,
      disableSortBy: disableSort || !enableClientSorted,
      autoResetSortBy: false,

      manualPagination: !enableClientPaginated,
      initialState: React.useMemo(
        () => ({
          ...(defaultPagination && {
            pageSize: defaultPagination.perPageSize + (pinnedData?.length ?? 0),
            pageIndex: defaultPagination.pageNumber,
          }),
          ...(defaultSort && {
            sortBy: [
              {
                id: resolveFieldKey(defaultSort.key),
                desc: defaultSort.order === 'descending',
              },
            ],
          }),
        }),
        [defaultPagination, defaultSort, pinnedData?.length],
      ),
      autoResetPage: false,
      manualSortBy: manualSort,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  if (data === 'loading') {
    return null;
  }

  return (
    <div className={className}>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            flex: 1;
            margin-right: 20px;
          `}
        >
          {title && (
            <div>
              {typeof title === 'string' ? (
                <Header
                  css={css`
                    margin-bottom: 0.5rem;
                    font-weight: 500;
                  `}
                  size={3}
                >
                  {title}
                </Header>
              ) : (
                title
              )}
              {description && (
                <Label
                  muted={true}
                  css={css`
                    margin-bottom: 0.9375rem;
                  `}
                >
                  {description}
                </Label>
              )}
            </div>
          )}
        </div>
        {defaultPagination && enableClientPaginated === 'block' && (
          <PaginationControl
            defaultPagination={defaultPagination}
            paginationMetadata={
              paginationMetadata ?? {
                totalPages: pageCount,
                totalResults: data.length,
              }
            }
            onPageChange={pagination => {
              if (typeof onPageChange !== 'undefined') {
                onPageChange(pagination);
              }
              if (pageIndex !== pagination.pageNumber) {
                gotoPage(pagination.pageNumber);
              }
              if (pageSize !== pagination.perPageSize + (pinnedData?.length ?? 0)) {
                setPageSize(pagination.perPageSize);
              }
            }}
          />
        )}
      </div>

      {searchType !== 'none' && (
        <div
          css={css`
            display: flex;
            column-gap: 1rem;
            align-items: center;
            flex-direction: row;
            margin-bottom: 1.25rem;
          `}
        >
          <TableSearchInput
            css={css`
              flex-grow: 1;
            `}
            onSearch={query =>
              searchType === 'server'
                ? onSearchQueryChange!(query)
                : searchType === 'client'
                ? setGlobalFilter(query)
                : assertNeverOrThrow(searchType)
            }
            defaultValue={defaultSearchQuery}
            disabled={disableSearch}
          />
          {defaultPagination && enableClientPaginated === 'inline' && (
            <PaginationControl
              css={css`
                margin-bottom: 0;
              `}
              defaultPagination={defaultPagination}
              paginationMetadata={
                paginationMetadata ?? {
                  totalPages: pageCount,
                  totalResults: data.length,
                }
              }
              onPageChange={pagination => {
                if (typeof onPageChange !== 'undefined') {
                  onPageChange(pagination);
                }
                if (pageIndex !== pagination.pageNumber) {
                  gotoPage(pagination.pageNumber);
                }
                if (pageSize !== pagination.perPageSize + (pinnedData?.length ?? 0)) {
                  setPageSize(pagination.perPageSize);
                }
              }}
            />
          )}
        </div>
      )}

      {typeof EmptyStatePlaceholder !== 'undefined' && page.length === 0 ? (
        typeof EmptyStatePlaceholder === 'string' ? (
          EmptyStatePlaceholder
        ) : (
          <EmptyStatePlaceholder />
        )
      ) : (
        <div
          css={css`
            display: block;
            width: 100%;
            overflow-x: auto;
          `}
        >
          <table
            css={css`
              width: 100%;
              ${hasTableBorder && `border: 1px solid ${colorToCode('dark-900-12')};`}
              overflow: auto;
              margin-bottom: 0;
            `}
            className={tableClass}
            {...getTableProps()}
          >
            <TableHead
              headerGroups={headerGroups}
              sortableColumnIds={sortableColumnKeys}
              enableClientSorted={enableClientSorted}
              disableSort={disableSort}
              defaultSort={defaultSort}
              onSortChange={sort => {
                setDefaultPagination(prevState => (prevState ? { ...prevState, pageNumber: 0 } : undefined));
                if (onSortChange) {
                  onSortChange(sort);
                }
              }}
              isDisplayDataOnly={isDisplayDataOnly}
              hasTableBorder={hasTableBorder}
            />
            <tbody
              css={css`
                ${rowSeparation === 'zebra' &&
                `> *
                  {
                    &:nth-of-type(odd)
                    {
                      background-color: #f6f6fb;
                    }
                    &:nth-of-type(even)
                    {
                      background-color: ${colorToCode('light-000')};
                    }
                  }`}
              `}
              {...getTableBodyProps()}
            >
              {page.map(row => {
                prepareRow(row);
                const bgColor = typeof getRowOptions !== 'undefined' ? getRowOptions(row).backgroundColor : undefined;
                return (
                  <Testable
                    testId={'table-row'}
                    key={row.id}
                  >
                    <tr
                      css={css`
                        height: 2.5rem;

                        ${typeof bgColor !== 'undefined' && `background-color: ${bgColor} !important;`}
                      `}
                      {...row.getRowProps()}
                    >
                      {row.cells.map(cell => {
                        const renderedCell = cell.render('Cell');

                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td
                            css={[
                              css`
                                ${isDisplayDataOnly && '&:last-of-type { text-align: right; > div { justify-content: flex-end; } }'}
                                ${isVerticalAligned && 'vertical-align: middle !important;'}
                                ${rowSeparation === 'zebra' && 'border-top: 0px !important; '}
                                ${rowSeparation === 'border' && `border-top: 1px solid ${colorToCode('dark-900-12')};`}
                                padding: 0.5rem !important;
                              `,
                              ...((cell.column as unknown as DataTableColumn<T>).ellipsizeTextContent
                                ? [
                                    css`
                                      white-space: nowrap;
                                      overflow: hidden;
                                      max-width: 0;
                                      text-overflow: ellipsis;
                                    `,
                                  ]
                                : []),
                            ]}
                            {...cell.getCellProps()}
                          >
                            <Testable testId={`table-cell-data-${pascalCaseToSpinalCase(cell.column.id)}`}>{renderedCell}</Testable>
                          </td>
                        );
                      })}
                    </tr>
                  </Testable>
                );
              })}
            </tbody>
            {hasFooter && (
              <TableFooter
                footerGroups={footerGroups}
                hasBackgroundColor={true}
                isDisplayDataOnly={isDisplayDataOnly}
                isVerticallyAligned={isVerticalAligned}
              />
            )}
          </table>
        </div>
      )}
    </div>
  );
};

export default reactMemoTyped(DataTable);
