import useScorecardMutation, { UseScorecardMutationOptions } from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardMutation';
import { scorecardCategoryOrderUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { ScorecardItemIdsByScorecardCategoryIdDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardCategory/Dto/Model.gen';

export type UseScorecardCategoryArrange = {
  arrangeScorecardCategory: (arrangePayload: {
    orderedScorecardItemIdsByScorecardCategoryId: ScorecardItemIdsByScorecardCategoryIdDto[];
  }) => void;
  isArrangingScorecardCategory: boolean;
};

const useScorecardCategoryArrange = (scorecardId: string, options?: UseScorecardMutationOptions): UseScorecardCategoryArrange => {
  const [arrangeScorecardCategory, isArrangingScorecardCategory] = useScorecardMutation(
    (arrangePayload: { orderedScorecardItemIdsByScorecardCategoryId: ScorecardItemIdsByScorecardCategoryIdDto[] }) =>
      scorecardCategoryOrderUpdate({
        scorecardId,
        orderedScorecardItemIdsByScorecardCategoryId: arrangePayload.orderedScorecardItemIdsByScorecardCategoryId,
      }),
    options,
  );

  return {
    arrangeScorecardCategory,
    isArrangingScorecardCategory,
  };
};

export default useScorecardCategoryArrange;
