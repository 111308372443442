import { SubscriptionBillingPeriodEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription.gen';
import { ConsumptionDto } from '@SubscriptionClient/Subscription/Packages/Consumption/Model.gen';
import { PricingDto } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/PricingModel.gen';
import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import buildAppliedProductDiscountFormat from '~/wm/packages/subscription/packages/manage/builder/buildAppliedProductDiscountFormat';
import NextTotalInvoiceSummary from '~/wm/packages/subscription/packages/next-invoice/packages/next-total-invoice-summary/NextTotalInvoiceSummary';
import ProductDiscountSummary from '~/wm/packages/subscription/packages/plan/packages/product-discount-summary/ProductDiscountSummary';
import ProductSummary from '~/wm/packages/subscription/packages/plan/packages/product-summary/ProductSummary';

import { Response as SubscriptionGroupPricingDetails } from '@SubscriptionClient/BeastClient/Beast/Msp/Controller/Subscription/Details/SubscriptionGroupPricingDetailsGetNested.gen';
import { ComponentPropsWithoutRef, Fragment } from 'react';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import Divider from '~/neo-ui/packages/divider/Divider';
import DiscountItem from '~/wm/packages/subscription/packages/plan/packages/product-plan-summary/DiscountItem';
import ExpenseItem from '~/wm/packages/subscription/packages/plan/packages/product-plan-summary/ExpenseItem';
import BasePlanDetails from './BasePlanDetails';

export type ProductPlanSummaryProps = {
  pricingDto: PricingDto | undefined;
  isOffer?: boolean;
  consumptionDto?: ConsumptionDto | undefined;
};

const ProductPlanSummary = ({ pricingDto, isOffer, consumptionDto }: ProductPlanSummaryProps) => {
  const currencyCode = 'USD';
  const discounts = pricingDto && pricingDto.discountsDto ? buildAppliedProductDiscountFormat(pricingDto.discountsDto, currencyCode) : [];
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        text-align: left;
      `}
    >
      <ProductSummary
        productsDto={pricingDto?.productDtos}
        productConsumptionDto={consumptionDto?.productConsumptionDtos}
      />
      {discounts.length > 0 && (
        <div
          css={css`
            margin-top: 0.625rem;
          `}
        >
          <ProductDiscountSummary discounts={discounts} />
        </div>
      )}
      {!isOffer && (
        <NextTotalInvoiceSummary
          billingDetailsDto={pricingDto?.billingDetailsDto}
          priceDto={pricingDto?.priceDto}
        />
      )}
    </div>
  );
};

type ProductPlanSummaryPropsV2 = ComponentPropsWithoutRef<typeof Box> & {
  data: SubscriptionGroupPricingDetails;
};

ProductPlanSummary.V2 = ({ data, ...rest }: ProductPlanSummaryPropsV2) => {
  const { subscriptionDetails, nextBillingDateUtc } = data;

  return (
    <Box
      boxShadow="shadow300"
      borderRadius="radius400"
      padding="padding400"
      {...rest}
    >
      <h4 css={{ fontWeight: 'bold', marginBottom: '1.25rem' }}>Your ScalePad subscription includes</h4>

      {subscriptionDetails?.subscriptionPricings.map(pricing => (
        <Fragment key={pricing.product}>
          <BasePlanDetails
            key={pricing.product}
            consumption={pricing.consumption}
            originalAmount={pricing.originalAmount}
            saleAmount={pricing.saleAmount}
            productLogoUrl={pricing.productLogoUrl}
            manageUrl={pricing.manageUrl}
            productSummary={pricing.productSummary}
            extendedFeatureDescriptions={pricing.extendedFeatureDescriptions}
          />

          <Divider css={{ margin: '1rem 0' }} />

          {pricing.expenses.map((expense, index) => (
            <Fragment key={index}>
              <ExpenseItem {...expense} />
              <Divider css={{ margin: '1rem 0' }} />
            </Fragment>
          ))}

          {pricing.discounts.map((discount, index) => (
            <Fragment key={index}>
              <DiscountItem {...discount} />
              <Divider css={{ margin: '1rem 0' }} />
            </Fragment>
          ))}
        </Fragment>
      ))}

      {!!subscriptionDetails?.groupLevelDiscounts.length && (
        <ProductDiscountSummary
          discounts={subscriptionDetails.groupLevelDiscounts.map(({ amount, description, name }) => ({
            amount: formatCurrency(amount, 'USD'),
            subtitle: description,
            title: name,
          }))}
        />
      )}

      <NextTotalInvoiceSummary
        billingDetailsDto={{
          billingPeriod: SubscriptionBillingPeriodEnum.Monthly,
          nextBillingDate: nextBillingDateUtc,
          downgradeAt: undefined,
        }}
        priceDto={{
          currentTotalPrice: subscriptionDetails?.total ?? 0,
          nextTotalPrice: undefined,
          totalPriceWithoutDiscount: subscriptionDetails?.total ?? 0,
        }}
      />
    </Box>
  );
};

export default ProductPlanSummary;
