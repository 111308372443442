import { createContext } from 'react';
import { DevicesByTypeCountDto } from '@WarrantyClient/BeastClient/Beast/Warranties/Dto/DevicesByTypeCount.gen';
import { Enum as DeviceType } from '@WarrantyClient/BeastClient/Goods/DeviceType/DeviceTypeFactoryNested.gen';
import {
  EstimateCartStoreGetFilterDto as WarrantyClientBeastClientBeastEstimateDtoCartPaginatedGetEstimateCartStoreGetFilterDto,
  EstimateCartStoreGetUnitDto,
  RenewalOptionSellingPriceDto,
} from '@WarrantyClient/BeastClient/Beast/Estimate/Dto/Cart/PaginatedGet.gen';
import { Option as WarrantyClientBeastClientBeastSettingsDtoPackagesWarrantyWarrantyCartSettingsDtoNestedOption } from '@WarrantyClient/BeastClient/Beast/Settings/Dto/Packages/Warranty/WarrantyCartSettingsDtoNested.gen';
import { Enum as WarrantyType } from '@WarrantyClient/BeastClient/Goods/WarrantyType/WarrantyTypeFactoryNested.gen';

export type WarrantyCartContextData = {
  displayCurrency: string;
  durationOptions: WarrantyClientBeastClientBeastSettingsDtoPackagesWarrantyWarrantyCartSettingsDtoNestedOption[];

  organizationName: string;
  organizationId: string;

  selectedDeviceType: DeviceType;
  setSelectedDeviceType: (newType: DeviceType) => void;
  availableAssetTypes: DevicesByTypeCountDto[];

  markupPercentage: number;
  coTermination: boolean;
  duration: string;

  totalCost: number;

  getAssetCost: (assetAccountId: string | undefined, renewalOptions: RenewalOptionSellingPriceDto[]) => number;

  getCoverageEnds: (assetAccountId: string | undefined, date: string) => string;
  getIsUnitSelected: (assetAccountId: string) => boolean;

  selectUnit: (unit: EstimateCartStoreGetUnitDto) => void;

  getUnitSelectedPlan: (assetAccountId: string) => string | undefined;
  setUnitSelectedPlan: (assetAccountId: string, planId: string, callApi: boolean) => void;

  formatCostOutput: (amountInCents: number) => string;
  getCurrency: () => string;

  getSelectedAssetsCount: () => number;
  estimateId: string | undefined;
  getCoTerminationDuration: (startDate: Date) => number;

  apiUpsertLoading: boolean;
  getSelectedAccountIds: () => string[];
  selectAll: (
    searchQuery: string,
    filterQuery: WarrantyClientBeastClientBeastEstimateDtoCartPaginatedGetEstimateCartStoreGetFilterDto | undefined,
  ) => void;
  deselectAll: () => void;

  displayEmptyStateImage: boolean;

  continuousRenewalPolicyVisible: boolean;
  setContinuousRenewalPolicyVisible: (policyVisible: boolean) => void;

  warrantyType: WarrantyType | undefined;

  totalAssetsCount: number;
  setTotalAssetsCount: (totalAssetsCount: number) => void;
};

const WarrantyCartContext = createContext<WarrantyCartContextData | undefined>(undefined);

export default WarrantyCartContext;
