import useMutationInternal, {
  UseMutationInternal,
  UseMutationInternalApiCall,
  UseMutationInternalOptions,
} from '~/extensions/packages/hooks/useMutationInternal';
import useAutoEmailReportListMutationContext from '~/wm/packages/organization/packages/auto-email-report/context/hooks/useAutoEmailReportListMutationContext';

export type UseAutoEmailReportListMutationOptions<TResponse = unknown> = UseMutationInternalOptions<TResponse>;

const useAutoEmailReportListMutation = <TArg = Record<string, unknown>, TResponse = unknown>(
  apiCall: UseMutationInternalApiCall<TArg, TResponse>,
  options?: UseAutoEmailReportListMutationOptions<TResponse>,
): UseMutationInternal<TArg> => {
  const { triggerAutoEmailReportListReload } = useAutoEmailReportListMutationContext();

  return useMutationInternal(apiCall, triggerAutoEmailReportListReload, options);
};

export default useAutoEmailReportListMutation;
