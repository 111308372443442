import { Themeable } from '~/neo-ui/model/capacity';
import React from 'react';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import { css } from '@emotion/react';
import Badge from '~/neo-ui/packages/badge/Badge';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Theme from '~/neo-ui/packages/color/Theme';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

type FilterHeaderLabelProps = {
  selectedValues: string[];
  operatorLabel?: string;
  theme: Theme;
} & Themeable;

const FilterHeaderLabel: React.FunctionComponent<FilterHeaderLabelProps> = ({ selectedValues, operatorLabel, theme }) => {
  const { themeMap } = useTheme(theme);
  const customBadgeColorMap = (theme: Theme): Color => {
    switch (theme) {
      case 'secondary':
        return 'secondary-300';
      case 'negative':
        return 'negative-300';
      default:
        return themeMap.foregroundRaised;
    }
  };

  return (
    <div
      css={css`
        background-color: ${colorToCode(themeMap.foregroundAccent)};
        color: ${colorToCode('light-000')};

        padding: 0 0.5rem;
        display: flex;
        align-items: center;
        flex: 1 0;
        justify-content: center;
        gap: 0.438rem;
      `}
    >
      {operatorLabel && (
        <div
          css={css`
            flex-shrink: 0;
            font-size: 0.688rem;
            letter-spacing: 0.088rem;
            display: flex;
            align-items: center;
          `}
        >
          <Badge
            height={'1.25rem'}
            bgColor={customBadgeColorMap(theme)}
            padding={'5px'}
            borderRadius={'radius200'}
          >
            <Header size={6}>{operatorLabel}</Header>
          </Badge>
        </div>
      )}
      <div
        css={css`
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        `}
      >
        {selectedValues[0]}
      </div>
      {selectedValues.length <= 1 ? (
        ''
      ) : (
        <div
          css={css`
            margin-left: auto;
            display: flex;
            align-items: center;
          `}
        >
          <Badge
            height={'1.25rem'}
            textColor={themeMap.foregroundRaised}
            bgColor={'light-000'}
            padding={'5px'}
            borderRadius={'radius200'}
          >
            <Header size={6}>+{selectedValues.length - 1}</Header>
          </Badge>
        </div>
      )}
    </div>
  );
};

export default FilterHeaderLabel;
