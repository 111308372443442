import { initiativeAvailabilitiesGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { InitiativeAvailabilitiesDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import InitiativeAvailabilitiesContext from './InitiativeAvailabilitiesContext';
import InitiativeAvailabilitiesMutationContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/InitiativeAvailabilitiesMutationContext';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';

export type InitiativeAvailabilitiesProviderProps = { frontendScope: FrontendScope; children: ReactNode };

const InitiativeAvailabilitiesProvider = ({ frontendScope, children }: InitiativeAvailabilitiesProviderProps) => {
  const [availabilities, setAvailabilities] = useState<InitiativeAvailabilitiesDto>();
  const { callApi } = useApi();

  const reload = useCallback(
    async (isRequestActive?: () => boolean) => {
      await (async () => {
        const response = await callApi(() => initiativeAvailabilitiesGet({ scope: frontendScope, insightId: undefined }));

        if (!response) {
          return;
        }

        // if a function is provided to determine whether to propagate
        // a state update (to prevent race conditions), then it will
        // ensure not to update state. otherwise, each call updates state.
        if (!isRequestActive || isRequestActive()) {
          setAvailabilities(response.initiativeAvailabilities);
        }
      })();
    },
    [callApi, frontendScope],
  );

  // Load availabilities
  useEffect(() => {
    reload();
  }, [reload]);

  if (!availabilities) {
    return null;
  }

  return (
    <InitiativeAvailabilitiesMutationContext.Provider
      value={{ triggerInitiativeAvailabilitiesReload: isRequestActive => reload(isRequestActive) }}
    >
      <InitiativeAvailabilitiesContext.Provider
        value={{
          availabilities,
        }}
      >
        {children}
      </InitiativeAvailabilitiesContext.Provider>
    </InitiativeAvailabilitiesMutationContext.Provider>
  );
};

export default InitiativeAvailabilitiesProvider;
