import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import Button from '~/neo-ui/packages/button/Button';
import useEngagementActionTicketUnlink from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionTicketUnlink';

export type EngagementActionTicketDeleteButtonProps = {
  action: EngagementActionDto;
};

const EngagementActionTicketDeleteButton = ({ action }: EngagementActionTicketDeleteButtonProps) => {
  const { unlinkEngagementActionTicket, isUnlinkingTicket } = useEngagementActionTicketUnlink();

  return (
    <Button
      theme={'danger'}
      iconLeft={'Delete'}
      preventOnClickPropagation={true}
      disabled={isUnlinkingTicket}
      loading={isUnlinkingTicket}
      onClick={() => {
        unlinkEngagementActionTicket(action.engagementActionId);
      }}
    >
      Delete PSA Ticket
    </Button>
  );
};

export default EngagementActionTicketDeleteButton;
