import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import {
  BudgetLineItemFormData,
  InitiativeSaveFormData,
} from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { FieldKeyExpressionSegment, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import React from 'react';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';
import useTotalCostOneTimeFeesCalculated from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/hooks/useTotalCostOneTimeFeesCalculated';
import { CostTypeEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Model/BudgetLineItemNested.gen';
import InteractiveItem from '~/neo-ui/packages/interactive-item/InteractiveItem';
import { FieldArray } from 'formik';
import FeesOneTimeRow from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-fees-section/packages/fees-one-time-row/FeesOneTimeRow';

const InitiativeSaveFeesOneTime = () => {
  const { currencyInfo } = useRegionalSettingsInfoContext();
  const { getFormInput, formData } = useFormContext<InitiativeSaveFormData>();

  /**
   * Determine if the inputs may be focused
   * This is used to focus the last input when a new line item is added
   */
  const [allowFocus, setAllowFocus] = React.useState(false);

  const assetCount = formData.assetIds.length;

  const budgetLineItems = getFormInput<BudgetLineItemFormData[]>(formData => formData.budgetLineItems).value;

  const { formattedTotalCostOneTimeFees } = useTotalCostOneTimeFeesCalculated({
    currencyInfo,
    budgetLineItems,
    amountOfAssets: assetCount,
  });

  return (
    <div>
      <div
        css={css`
          margin-bottom: 1rem;
        `}
      >
        <Header
          size={5}
          weight={'bold'}
          css={css`
            margin-bottom: 0.625rem;
          `}
        >
          One-time fees
        </Header>
        <FieldArray
          name={resolveFieldKey((formData: FieldKeyExpressionSegment<InitiativeSaveFormData>) => formData.budgetLineItems)}
          render={arrayHelpers => (
            <div>
              {budgetLineItems.map((lineItem, lineItemIndex) => (
                <FeesOneTimeRow
                  key={lineItemIndex}
                  fieldKey={(formData: FieldKeyExpressionSegment<InitiativeSaveFormData>) => formData.budgetLineItems}
                  lineItem={lineItem}
                  lineItemIndex={lineItemIndex}
                  currencyIcon={currencyInfo.currencySymbol}
                  gridSize={'md'}
                  allowFocus={lineItemIndex === budgetLineItems.length - 1 ? allowFocus : false}
                  onRemove={() => {
                    arrayHelpers.remove(lineItemIndex);
                    setAllowFocus(false);
                  }}
                />
              ))}
              <InteractiveItem
                modifierButtonProps={{ icon: 'Add', theme: 'primary' }}
                onClick={() => {
                  // Budget schema reference 620dde08-da64-4f37-982d-ca70c3478399
                  arrayHelpers.push({
                    label: 'New item',
                    costUnits: 0,
                    costType: CostTypeEnum.Fixed,
                  });
                  setAllowFocus(true);
                }}
                css={css`
                  margin: 0.625rem 0;
                `}
              >
                <Label
                  color={'primary-400'}
                  heightSize={'md'}
                >
                  Add one-time fee…
                </Label>
              </InteractiveItem>
            </div>
          )}
        />
      </div>
      <div
        css={css`
          margin-top: 1rem;
        `}
      >
        <Header
          size={5}
          weight={'bold'}
          css={css`
            margin-bottom: 0.625rem;
          `}
        >
          Total one-time fees
        </Header>
        <Header
          size={4}
          weight={'bold'}
        >
          {formattedTotalCostOneTimeFees}
        </Header>
        <Label
          muted={true}
          size={'sm'}
          css={css`
            margin-top: 0.3125rem;
          `}
        >
          {`${assetCount} asset${assetCount === 1 ? '' : 's'}`}
        </Label>
      </div>
    </div>
  );
};

export default InitiativeSaveFeesOneTime;
