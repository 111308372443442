import * as React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { contractTemplateAvailabilitiesGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { ContractTemplateAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Template/Dto/Model.gen';
import ContractTemplateAvailabilitiesMutationContext from '~/wm/packages/strategy/packages/contract/packages/contract-template/context/ContractTemplateAvailabilitiesMutationContext';
import ContractTemplateAvailabilitiesContext from '~/wm/packages/strategy/packages/contract/packages/contract-template/context/ContractTemplateAvailabilitiesContext';

export type ContractTemplateAvailabilitiesProviderProps = {
  children: React.ReactNode;
};

const ContractTemplateAvailabilitiesProvider = ({ children }: ContractTemplateAvailabilitiesProviderProps) => {
  const [contractTemplateAvailabilities, setContractTemplateAvailabilities] = React.useState<ContractTemplateAvailabilityDto[] | undefined>(
    undefined,
  );

  const { callApi } = useApi();

  // Get contract list
  const reload = React.useCallback(
    async (isRequestActive?: () => boolean) => {
      const contractTemplateAvailabilitiesResponse = await callApi(() => contractTemplateAvailabilitiesGet({}));

      if (!contractTemplateAvailabilitiesResponse) {
        return;
      }

      // if a function is provided to determine whether to propagate
      // a state update (to prevent race conditions), then it will
      // ensure not to update state. otherwise, each call updates state.
      if (!isRequestActive || isRequestActive()) {
        setContractTemplateAvailabilities(contractTemplateAvailabilitiesResponse.contractTemplateAvailabilities);
      }
    },
    [callApi],
  );

  // Load contract template availabilities
  React.useEffect(() => {
    reload();
  }, [reload]);

  if (!contractTemplateAvailabilities) {
    return null;
  }

  return (
    <ContractTemplateAvailabilitiesMutationContext.Provider
      value={{
        triggerContractTemplateAvailabilitiesReload: isRequestActive => reload(isRequestActive),
      }}
    >
      <ContractTemplateAvailabilitiesContext.Provider
        value={{
          contractTemplateAvailabilities,
        }}
      >
        {children}
      </ContractTemplateAvailabilitiesContext.Provider>
    </ContractTemplateAvailabilitiesMutationContext.Provider>
  );
};

export default ContractTemplateAvailabilitiesProvider;
