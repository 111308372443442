import { SVGProps, forwardRef, memo } from 'react';

const SuccessIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
      >
        <g clipPath="url(#clip0_2209_3662)">
          <circle
            cx="12"
            cy="12"
            r="12"
            fill="#38A825"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071L5.29289 13.7071C4.90237 13.3166 4.90237 12.6834 5.29289 12.2929C5.68342 11.9024 6.31658 11.9024 6.70711 12.2929L10 15.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2209_3662">
            <rect
              width="24"
              height="24"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }),
);

export default SuccessIcon;
