import { css } from '@emotion/react';
import * as React from 'react';
import { assertNeverOrThrow } from '~/extensions/packages/types/assertNever';
import { Styleable } from '~/neo-ui/model/capacity';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Header from '../../../neo-ui/packages/text/packages/header/Header';
import Icon from '~/neo-ui/packages/icon/Icon';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

type ShadowDepth = 'none' | 'sm' | 'md' | 'lg' | 'xl';
type Padding = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export type Header = {
  title: string;
  icon?: IconType;
  color?: Color;
};

export type CardProps = {
  backgroundColor?: Color;
  shadow?: ShadowDepth;
  padding?: Padding;
  header?: Header;
  /**
   * Adds a fixed-height footer to Card and allows actions on the left and right most side
   */
  actionBarComponent?: {
    /**
     * Actions on the left side of the footer
     */
    left?: React.ReactNode;
    /**
     * Actions on the right side of the footer
     */
    right?: React.ReactNode;
  };
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
} & Styleable;

const shadowDepthToClassName = (depth: ShadowDepth) => {
  switch (depth) {
    case 'none':
      return '';
    case 'md':
      return 'shadow';
    default:
      return `shadow-${depth}`;
  }
};

const paddingToPx = (padding: Padding) => {
  switch (padding) {
    case 'xxl':
      return 50;
    case 'xl':
      return 30;
    case 'lg':
      return 20;
    case 'md':
      return 15;
    case 'sm':
      return 10;
    case 'xs':
      return 6;
    case 'none':
      return 0;
    default:
      return assertNeverOrThrow(padding);
  }
};

/**
 * @deprecated Please use Box instead
 * The one-stop-shop for all Card related needs
 *
 * Adjust shadow and padding using the selected presets
 *
 * Allows an action bar stuck to the bottom of the card
 */
const Card: React.FunctionComponent<React.PropsWithChildren<CardProps>> = ({
  className,
  backgroundColor,
  shadow = 'sm',
  padding = 'md',
  header,
  actionBarComponent,
  onClick,
  onMouseEnter,
  onMouseLeave,
  children,
}) => (
  <div
    className={`${shadowDepthToClassName(shadow)}${className ? ` ${className}` : ''}`}
    css={css`
      ${actionBarComponent &&
      `display: grid;
       grid-template-rows: 1fr min-content;
       height:100%;`}
      background-color: ${colorToCode(backgroundColor || 'light-000')};
      border-radius: 10px;
      position: relative;
      padding: ${paddingToPx(padding)}px;

      ${onClick &&
      `transition: all 200ms ease;
        &:hover {
          cursor: pointer;
          box-shadow: 0 0.0625rem 0.625rem -0.3125rem rgba(0, 0, 0, 0.3),
            0 0.4375rem 1.5625rem -0.625rem rgba(0, 0, 0, 0.15) !important;
        }`}
    `}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <div
      css={css`
        // Conditionally adding overflow only when we have an action bar
        // otherwise responsiveness will break on billing page.
        overflow: ${actionBarComponent ? 'auto' : ''};
        height: 100%;
      `}
    >
      {header && (
        <div
          css={css`
            min-height: 4rem;
            margin-bottom: ${paddingToPx(padding)}px;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin-top: 0.375rem;
              margin-bottom: 1.5625rem;
            `}
          >
            <Header size={3}>{header.title}</Header>
            {header.icon && (
              <Icon
                icon={header.icon}
                sizePx={28}
                color={header.color ?? 'dark-900'}
              />
            )}
          </div>
          <hr />
        </div>
      )}
      {children}
    </div>
    {actionBarComponent && (
      <div
        css={css`
          display: flex;
          flex-wrap: wrap-reverse;
          justify-content: space-between;
          align-items: center;
          gap: 0.625rem;
          margin-right: -${paddingToPx(padding)}px;
          margin-left: -${paddingToPx(padding)}px;
          margin-bottom: -${paddingToPx(padding)}px;
          padding: 0.625rem 1.875rem;
          box-shadow: 0 -0.25rem 1rem rgba(0, 0, 0, 0.05);
        `}
      >
        {actionBarComponent.left && (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.625rem;
            `}
          >
            {actionBarComponent.left}
          </div>
        )}
        {actionBarComponent.right && (
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {actionBarComponent.right}
          </div>
        )}
      </div>
    )}
  </div>
);

export default Card;
