import { ClassNames } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import LayoutFooterReact from '~/neo-ui/packages/layout/packages/footer/LayoutFooterReact';
import LayoutHeaderReact from '~/neo-ui/packages/layout/packages/header/LayoutHeaderReact';
import { toolbarHeightRem, ToolbarProps } from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Testable from '~/neo-ui/packages/testable/Testable';
import ModalOverlay from '~/neo-ui/packages/modal/ModalOverlay';
import useModalTriggerState from '~/neo-ui/packages/modal/hooks/useModalTriggerState';

export type WindowProps = {
  /**
   * Title of the window
   */
  title: string;
  /**
   * Optional icon in the title bar
   */
  titleIcon?: IconType;
  /**
   * Optional component in the right of the title bar
   * Be aware of height restriction on the title bar
   */
  titleBarRightSection?: React.ReactNode;
  /**
   * Optional component that sits under the title bar
   * Be aware of the height restriction on this section
   */
  titleBarBottomSection?: React.ReactNode;
  /**
   * Optional header, places props into a LayoutHeader component
   */
  headerProps?: ToolbarProps;
  /**
   * Optional footer, places props into a LayoutFooter component
   */
  footerProps?: ToolbarProps;
  onDismiss?: () => void;
  isOpen: boolean;
  /**
   * Determines whether the window can be dismissed by
   * pressing the screen outside its bounds
   */
  isStatic?: boolean;
  children: React.ReactNode;
  /**
   * Determines the maximum rem width the window can be
   */
  maxWidthRem?: number;
} & Styleable;

const windowPaddingRem = 3.5;
const windowMaxWidthRem = 48;
const windowBorderRadiusRem = 0.9375;
const windowTitleBarHeightRem = 2.375;
const windowTitleBarBottomSectionHeightRem = 6;

/**
 * Window is a pop-out component that allows the user to perform any number of actions inside it.
 *
 * A Window always fits within the viewport height, scroll on the children to make extra content fit.
 *
 * It consists of:
 * - a title bar that is always present, sticky to the top
 * - a LayoutHeader that is optionally defined, sticky to the title bar
 * - the body, anything that is passed as the children which scrolls
 * - a LayoutFooter that is optionally defined, sticky to the bottom
 */
const Window = ({
  title,
  titleIcon,
  titleBarRightSection,
  titleBarBottomSection,
  headerProps,
  children,
  footerProps,

  isOpen,

  onDismiss = () => {},

  isStatic = false,
  className,
  maxWidthRem = windowMaxWidthRem,
}: WindowProps) => {
  const windowBodyMinHeightRem =
    (headerProps ? toolbarHeightRem : 0) +
    (footerProps ? toolbarHeightRem : 0) +
    (titleBarBottomSection ? windowTitleBarBottomSectionHeightRem : 0) +
    windowTitleBarHeightRem +
    windowPaddingRem;

  const { overlayTriggerState } = useModalTriggerState({
    isOpen,
    onDismiss,
  });

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <ModalOverlay
            wrapClassName={css`
              @media (min-width: 576px) and (max-width: 1080px) {
                max-width: 94%;
              }
            `}
            contentClassName={css`
              border-radius: ${windowBorderRadiusRem}rem;
            `}
            overlayTriggerState={overlayTriggerState}
            isDismissable={!isStatic}
            maxWidthRem={maxWidthRem}
          >
            {/* Title bar of the Window, contains a left, right, and bottom side*/}
            <header>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0.625rem;
                  height: ${windowTitleBarHeightRem}rem;
                  border-radius: ${windowBorderRadiusRem}rem ${windowBorderRadiusRem}rem 0 0;
                  background-color: ${colorToCode('light-100')};
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 0.3125rem;
                    overflow: hidden;
                  `}
                >
                  {titleIcon && <Icon icon={titleIcon} />}
                  <Label
                    bold={true}
                    css={css`
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    `}
                  >
                    {title}
                  </Label>
                </div>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 0.3125rem;
                    overflow: hidden;
                  `}
                >
                  {titleBarRightSection}
                  <Testable testId={'window-close-button'}>
                    <ButtonModifier
                      icon={'Bad'}
                      onClick={onDismiss}
                      css={css`
                        margin-right: 0.3125rem;
                      `}
                    />
                  </Testable>
                </div>
              </div>

              {titleBarBottomSection && (
                <div
                  css={css`
                    height: ${windowTitleBarBottomSectionHeightRem}rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0.625rem 1.875rem;
                  `}
                >
                  {titleBarBottomSection}
                </div>
              )}
            </header>

            {/* Layout of the Window, include header, body, and footer */}
            <main>
              {headerProps && (
                <Testable testId={'window-header'}>
                  <LayoutHeaderReact {...headerProps} />
                </Testable>
              )}
              <div
                css={css`
                  padding: 1.25rem;
                  max-height: calc(100vh - ${windowBodyMinHeightRem}rem);
                  overflow: auto;
                `}
                className={className}
              >
                {children}
              </div>
              {footerProps && (
                <LayoutFooterReact
                  {...footerProps}
                  toolbarClassName={css`
                    max-height: calc(100vh - ${windowBodyMinHeightRem}rem);
                    border-radius: 0 0 ${windowBorderRadiusRem}rem ${windowBorderRadiusRem}rem;
                  `}
                />
              )}
            </main>
          </ModalOverlay>
        </>
      )}
    </ClassNames>
  );
};

export default Window;
