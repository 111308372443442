import * as React from 'react';
import { css } from '@emotion/react';
import DisposalOrderBulkAvailabilityScheduleForm from './packages/disposal-order-bulk-availability-schedule-form/DisposalOrderBulkAvailabilityScheduleForm';
import DisposalOrderBulkPickupAvailabilityScheduleFormSection from './packages/disposal-order-bulk-availability-schedule-form/packages/disposal-order-bulk-pickup-availability-schedule-form-section/DisposalOrderBulkPickupAvailabilityScheduleFormSection';

export type DisposalOrderBulkPickupSectionAvailabilityScheduleProps = {
  disposalOrderId: string;
  canSchedule: boolean;
};

const DisposalOrderBulkPickupSectionAvailabilitySchedule = ({
  disposalOrderId,
  canSchedule,
}: DisposalOrderBulkPickupSectionAvailabilityScheduleProps) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      gap: 1rem;
    `}
  >
    <DisposalOrderBulkAvailabilityScheduleForm disposalOrderId={disposalOrderId}>
      <DisposalOrderBulkPickupAvailabilityScheduleFormSection canSchedule={canSchedule} />
    </DisposalOrderBulkAvailabilityScheduleForm>
  </div>
);

export default DisposalOrderBulkPickupSectionAvailabilitySchedule;
