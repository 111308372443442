import { Themeable } from '~/neo-ui/model/capacity';
import { themeToColor } from '~/neo-ui/packages/color/Theme';
import { css } from '@emotion/react';
import Badge from '~/neo-ui/packages/badge/Badge';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import React from 'react';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';

export type FilterBadgeLabelProps = {
  selectedValues: string[];
  operatorLabel?: string;
} & Themeable;

const FilterBadgeLabel = ({ selectedValues, operatorLabel, theme }: FilterBadgeLabelProps) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      gap: 0.438rem;
    `}
  >
    {operatorLabel && (
      <Badge
        bgColor={themeToColor(theme, '600')}
        padding={'0.3125rem'}
        borderRadius={'radius200'}
      >
        <Header size={6}>{operatorLabel}</Header>
      </Badge>
    )}
    {selectedValues[0]}
    {selectedValues.length > 1 && (
      <Tooltip content={selectedValues.join(', ')}>
        <Header size={6}>+{selectedValues.length - 1}</Header>
      </Tooltip>
    )}
  </div>
);

export default FilterBadgeLabel;
