import React from 'react';
import Box from '~/neo-ui/packages/box/Box';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import DisposalAssetTypeCountFormData from '~/wm/packages/disposal/types/DisposalAssetTypeCountFormData';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import FormCheckboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckboxInput';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';
import useWizardPageContext from '~/neo-ui/packages/wizard/packages/wizard-page/context/hooks/useWizardPageContext';
import { DisposalCheckoutType } from '~/wm/packages/disposal/packages/disposal-section/DisposalSection';
import { DisposalType } from '~/wm/packages/disposal/types/DisposalSelfCheckoutFormData';
import ToggleTileHorizontal from '~/neo-ui/packages/toggle/packages/toggle-tile/packages/toggle-tile-horizontal/ToggleTileHorizontal';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';

export type DisposalCheckoutTypeSectionProps<T> = {
  isBulk: boolean;
  isDisposalFree: boolean;
  assetTypeCountsFieldKey: FieldKeyExpression<T>;
  biosCertifiedFieldKey: FieldKeyExpression<T>;
  onDisposalTypeSwap: (disposalCheckoutType: DisposalCheckoutType) => void;
  hasDisposalBulkLocationAccess: boolean;
  isBulkOrderPlacedDisabled: boolean;
  minAssetsCountForFullService: number;
};

const DisposalCheckoutTypeSection = <T,>({
  isBulk,
  isDisposalFree,
  assetTypeCountsFieldKey,
  onDisposalTypeSwap,
  biosCertifiedFieldKey,
  hasDisposalBulkLocationAccess,
  isBulkOrderPlacedDisabled,
  minAssetsCountForFullService,
}: DisposalCheckoutTypeSectionProps<T>) => {
  const { getFormInput, setFormInput } = useFormContext<T>();
  const { setFieldCompleted } = useWizardPageContext();
  const { trackEvent } = useEventTracking(TrackingServices.EventProcessor);

  const [isBiosCertified, setIsBiosCertified] = React.useState<boolean>(getFormInput(biosCertifiedFieldKey).value);

  const assetTypeCounts = getFormInput<DisposalAssetTypeCountFormData[]>(assetTypeCountsFieldKey).value;

  const assetTypeCountsSum = assetTypeCounts.reduce((final, current) => final + parseInt(String(current.count), 10), 0);

  React.useEffect(() => {
    setFieldCompleted(biosCertifiedFieldKey, isBiosCertified);
  }, [biosCertifiedFieldKey, isBiosCertified, setFieldCompleted]);

  const isBulkTypeDisabled = isBulkOrderPlacedDisabled || !(isBulk || assetTypeCountsSum >= minAssetsCountForFullService);

  return (
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
    >
      <LayoutSection
        title={'Get ready to ship'}
        titleSize={4}
        titleWeight={'bold'}
      >
        {
          // Show the section if the user has selected bulk shipping or if the user has selected more than defined assets
          hasDisposalBulkLocationAccess && (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
              `}
            >
              <Label bold={true}>How would you like to send the assets?</Label>
              <ToggleTileHorizontal
                type={'single'}
                title={'Self service'}
                description={'For smaller shipments, try our self service option'}
                icon={'Shipping'}
                onClick={() => {
                  trackEvent('disposal_self_order_type_selected', {});
                  onDisposalTypeSwap({
                    type: DisposalType.Self,
                    assetTypeCounts,
                    isBiosCertified,
                  });
                }}
                isSelected={!isBulk}
                appendComponent={
                  isDisposalFree ? (
                    <Label
                      color={isBulk ? 'primary-400' : 'dark-900-64'}
                      bold={true}
                    >
                      Free
                    </Label>
                  ) : undefined
                }
              />
              <ToggleTileHorizontal
                type={'single'}
                title={'Full service'}
                description={'Opt for Full Service for bulk shipments.'}
                icon={'FullService'}
                disabled={isBulkTypeDisabled}
                alert={
                  // Show a different alert if bulk orders are disabled globally
                  isBulkOrderPlacedDisabled
                    ? { text: 'Unavailable', color: 'warning-400' }
                    : isBulkTypeDisabled
                    ? {
                        text: `Add ${minAssetsCountForFullService - assetTypeCountsSum} asset${
                          minAssetsCountForFullService - assetTypeCountsSum === 1 ? '' : 's'
                        } to unlock`,
                      }
                    : undefined
                }
                onClick={() => {
                  trackEvent('disposal_bulk_order_type_selected', {});
                  onDisposalTypeSwap({
                    type: DisposalType.Bulk,
                    assetTypeCounts,
                    isBiosCertified,
                  });
                }}
                isSelected={isBulk}
              />
            </div>
          )
        }
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          `}
        >
          <Label bold={true}>Are the assets unlocked?</Label>
          <div
            css={css`
              display: flex;
            `}
          >
            <FormCheckboxInput
              fieldKey={biosCertifiedFieldKey}
              onChange={e => {
                setIsBiosCertified(e.target.checked);
                setFieldCompleted(biosCertifiedFieldKey, e.target.checked);
                setFormInput<boolean>(biosCertifiedFieldKey, e.target.checked);
              }}
              checked={isBiosCertified}
              description={'Assets of applicable types are unlocked'}
            />
            <Tooltip
              backgroundColor={'light-000'}
              content={'Workstations, servers, and tablets must be BIOS and/or iCloud unlocked.'}
              placement={'top'}
            >
              <Icon
                icon={'Info'}
                sizePx={16}
                color={'dark-900-24'}
                css={css`
                  margin-left: 0.625rem;
                `}
              />
            </Tooltip>
          </div>
        </div>
      </LayoutSection>
    </Box>
  );
};

export default DisposalCheckoutTypeSection;
