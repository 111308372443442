import { Styleable } from '~/neo-ui/model/capacity';
import * as React from 'react';
import SingleSelectInternal from '~/neo-ui/packages/select/packages/single-select/SingleSelectInternal';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { GroupBase } from 'react-select';
import Theme from '~/neo-ui/packages/color/Theme';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import SingleSelectOption from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-option/SingleSelectOption';
import SingleSelectSingleValue from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-single-value/SingleSelectSingleValue';
import SingleSelectGroupHeading from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-group-heading/SingleSelectGroupHeading';
import SingleSelectGroup from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-group/SingleSelectGroup';
import SingleSelectDropdownIndicator from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-dropdown-indicator/SingleSelectDropdownIndicator';
import SingleSelectMenu from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-menu/SingleSelectMenu';
import { customStylesConsole } from '~/neo-ui/packages/select/packages/single-select/packages/single-select-console/packages/styles/SingleSelectConsoleStyle';
import SingleSelectConsoleValueContainer from '~/neo-ui/packages/select/packages/single-select/packages/single-select-console/packages/components/single-select-console-select-container/SingleSelectConsoleValueContainer';

const getConsoleStyle = (height: string | undefined, isItemSelected: boolean, isOpen: boolean) =>
  customStylesConsole({
    height,
    isItemSelected,
    isOpen,
  });

export const getIconSizeConsole = () => 16;

export type SingleSelectConsoleProps<T extends string = string> = {
  options: SelectOption<T>[] | GroupBase<SelectOption<T>>[];
  selectedOption: SelectOption<T> | undefined | null;
  onOptionSelected: (option: SelectOption<T>) => void;
  isSearchable?: boolean;
  /**
   * Used when place component in footer or header to display menu dropdown
   */
  shouldUseMenuPortal?: boolean;
  disabled?: boolean;

  /** ***********
   * Styling   *
   *************/
  placeholder?: (menuOpen: boolean) => string;
  /**
   * Indicate if option description should be displayed on the control button
   */
  shouldShowDescriptionOnControlButton?: boolean;
  height?: string;
  prependIcon?: IconType;
  dropdownIndicatorIconOpen?: IconType;
  dropdownIndicatorIconClosed?: IconType;
  theme?: Theme;
  /**
   * Width in pixels to truncate the selected option at
   */
  truncateSelected?: number;
} & Styleable;

const SingleSelectConsole = <T extends string>({
  options,
  selectedOption,
  onOptionSelected,
  isSearchable,
  shouldUseMenuPortal,
  disabled = false,
  className,

  placeholder = () => 'Find in list...',
  shouldShowDescriptionOnControlButton = false,
  height,
  prependIcon,
  dropdownIndicatorIconOpen,
  dropdownIndicatorIconClosed,
  truncateSelected,
}: SingleSelectConsoleProps<T>) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const consoleStyle = getConsoleStyle(height, selectedOption !== null, menuOpen);
  const placeholderText = placeholder(menuOpen);
  const iconColor = selectedOption !== null ? 'light-000' : 'dark-700';
  const iconSize = getIconSizeConsole();
  const singleValueLabelColor = selectedOption !== null ? 'light-000' : 'dark-900';

  return (
    <SingleSelectInternal
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
      options={options}
      selectedOption={selectedOption}
      onOptionSelected={option => {
        onOptionSelected(option);
      }}
      shouldUseMenuPortal={shouldUseMenuPortal}
      className={className}
      placeholder={placeholderText}
      isSearchable={isSearchable}
      disabled={disabled}
      components={{
        Option: SingleSelectOption,
        SingleValue: props =>
          SingleSelectSingleValue(
            props,
            placeholderText,
            shouldShowDescriptionOnControlButton,
            undefined,
            truncateSelected,
            singleValueLabelColor,
          ),
        GroupHeading: SingleSelectGroupHeading,
        Group: SingleSelectGroup,
        DropdownIndicator: props =>
          SingleSelectDropdownIndicator(
            props,
            getIconSizeConsole(),
            menuOpen,
            dropdownIndicatorIconOpen,
            dropdownIndicatorIconClosed,
            iconColor,
          ),
        IndicatorSeparator: () => null,
        Menu: props => SingleSelectMenu(props),
        ValueContainer: props =>
          SingleSelectConsoleValueContainer({
            props,
            icon: prependIcon,
            sizePx: iconSize,
            color: iconColor,
          }),
      }}
      styles={consoleStyle}
    />
  );
};
export default SingleSelectConsole;
