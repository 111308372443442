import usePeopleDashboardMutation, {
  UsePeopleDashboardMutationOptions,
} from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/usePeopleDashboardMutation';
import { organizationAccountTeamMemberRoleDelete } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseOrganizationAccountTeamMemberRoleDelete = {
  deleteOrganizationAccountTeamMemberRole: (accountTeamMemberRoleId: string) => void;
  isDeletingOrganizationAccountTeamMemberRole: boolean;
};

const useOrganizationAccountTeamMemberRoleDelete = (
  options?: UsePeopleDashboardMutationOptions,
): UseOrganizationAccountTeamMemberRoleDelete => {
  const [deleteOrganizationAccountTeamMemberRole, isDeletingOrganizationAccountTeamMemberRole] = usePeopleDashboardMutation(
    (accountTeamMemberRoleId: string) =>
      organizationAccountTeamMemberRoleDelete({
        accountTeamMemberRoleId,
      }),
    options,
  );

  return {
    deleteOrganizationAccountTeamMemberRole,
    isDeletingOrganizationAccountTeamMemberRole,
  };
};

export default useOrganizationAccountTeamMemberRoleDelete;
