import * as React from 'react';
import useDisposalTreeImpactAccountSummaryContext from '~/wm/packages/disposal/packages/tree-impact/context/hooks/useDisposalTreeImpactAccountSummaryContext';
import { Styleable } from '~/neo-ui/model/capacity';
import TreeImpactIntroCard from '~/wm/packages/tree-impact/packages/tree-impact-intro-card/TreeImpactIntroCard';

export type DisposalTreeImpactIntroCardProps = {
  helpGuideUrl: string;
} & Styleable;

const DisposalTreeImpactIntroCard: React.FunctionComponent<DisposalTreeImpactIntroCardProps> = ({ helpGuideUrl, className }) => {
  const { treesPerOrderUnit } = useDisposalTreeImpactAccountSummaryContext();
  return (
    <TreeImpactIntroCard
      className={className}
      serviceId={'disposal'}
      treesPerOrderUnit={treesPerOrderUnit}
      helpGuideUrl={helpGuideUrl}
    />
  );
};

export default DisposalTreeImpactIntroCard;
