import { css } from '@emotion/react';
import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import Icon from '~/neo-ui/packages/icon/Icon';
import { PaginationMetadata } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { PaginationState } from '~/neo-ui/packages/table/packages/filter-table/FilterTable';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import ButtonGroup from '~/neo-ui/packages/button/packages/button-group/ButtonGroup';

export type ConsolePaginationProps = {
  defaultPagination: PaginationState;
  paginationMetadata: PaginationMetadata | 'unknown';
  paginationState: PaginationState;
  onPageNumberChange: (updateFunction: (oldPage: number) => number) => void;
};

const ConsolePagination: React.FunctionComponent<ConsolePaginationProps> = ({
  paginationMetadata,
  paginationState,
  onPageNumberChange,
}) => {
  const { pageNumber } = paginationState;

  React.useEffect(() => {
    onPageNumberChange(_ => 0);
  }, [onPageNumberChange]);

  const perPageSize = paginationState.perPageSize;

  const startsAtRowNumber = pageNumber * perPageSize;
  const endsAtRowNumber =
    paginationMetadata === 'unknown'
      ? // Giving the best guess is just for UX here
        startsAtRowNumber + perPageSize
      : // Theoretically this is "start index + # results in current page"
        // But we might not get the # results until after the page is loaded
        Math.min(startsAtRowNumber + perPageSize, paginationMetadata.totalResults);

  const rowRange =
    paginationMetadata !== 'unknown' && paginationMetadata.totalResults === 0
      ? '0 - 0'
      : `${(startsAtRowNumber + 1).toLocaleString()}-${endsAtRowNumber.toLocaleString()}`;

  const nbResults = paginationMetadata === 'unknown' ? -1 : paginationMetadata.totalResults;
  const resultsText = nbResults === -1 ? '-' : `${nbResults.toLocaleString()} result${nbResults === 1 ? '' : 's'}`;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        width: 100%;
      `}
    >
      <Label
        bold={true}
        size={'xlg'}
      >
        {`${rowRange} of ${resultsText}`}
      </Label>
      <ButtonGroup
        css={css`
          margin-left: auto;
        `}
      >
        <Button
          onClick={() => onPageNumberChange(pageNumber => pageNumber - 1)}
          disabled={pageNumber === 0}
        >
          <Icon icon="ArrowLeft" />
        </Button>
        <Button
          onClick={() => onPageNumberChange(pageNumber => pageNumber + 1)}
          disabled={paginationMetadata !== 'unknown' && endsAtRowNumber >= paginationMetadata.totalResults}
        >
          <Icon icon="ArrowRight" />
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default ConsolePagination;
