import * as React from 'react';
import Theme from '~/neo-ui/packages/color/Theme';
import ModalInternal from '~/neo-ui/packages/modal/ModalInternal';
import ModalActionHeader, {
  ModalActionHeaderProps,
} from '~/neo-ui/packages/modal/packages/modal-action/packages/modal-action-header/ModalActionHeader';
import ModalActionFooter, {
  ModalActionFooterProps,
} from '~/neo-ui/packages/modal/packages/modal-action/packages/modal-action-footer/ModalActionFooter';
import { css } from '@emotion/react';

export type ModalActionProps = {
  header: Omit<ModalActionHeaderProps, 'theme'>;
  footer?: Omit<ModalActionFooterProps, 'theme'>;
  theme: Theme;
  isOpen: boolean;
  onDismiss: () => void;
};
/**
 * ModalAction is a pop-out component that allows the user perform a repeated action and allow the ability
 * to provide a set of controls in the (bottom-left-hand of the) footer.
 *
 * If scrolling is required the entire modal will scroll on the viewport
 */
const ModalAction: React.FunctionComponent<React.PropsWithChildren<ModalActionProps>> = ({
  header,
  children,
  footer,
  theme,
  isOpen,
  onDismiss,
}) => (
  <ModalInternal
    header={
      <ModalActionHeader
        icon={header.icon}
        title={header.title}
        theme={theme}
      />
    }
    footer={
      typeof footer === 'undefined' ? undefined : (
        <ModalActionFooter
          confirmationButtonAlignment={footer.confirmationButtonAlignment}
          buttonModifier={footer.buttonModifier}
          controls={footer.controls}
          label={footer.label}
          closeButton={footer.closeButton}
          confirmButton={footer.confirmButton}
          theme={theme}
        />
      )
    }
    isOpen={isOpen}
    maxWidthRem={39.5}
    onDismiss={onDismiss}
    disableHorizontalRule={true}
    containerCss={css`
      padding-bottom: 0;
    `}
  >
    {children}
  </ModalInternal>
);

export default ModalAction;
