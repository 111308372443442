import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import * as React from 'react';
import DisableElementOverlay from '~/neo-ui/packages/overlay/DisableElementOverlay';
import Button from '~/neo-ui/packages/button/Button';
import PeopleDashboardDropdown from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/packages/people-dashboard-dropdown/PeopleDashboardDropdown';
import usePeopleDashboardContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/context/hooks/usePeopleDashboardContext';
import PeopleDashboardDropdownOptionOrganizationKeyContact from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/packages/people-dashboard-dropdown/packages/people-dashboard-dropdown-organization-key-contact/packages/people-dashboard-dropdown-option-organization-key-contact/PeopleDashboardDropdownOptionOrganizationKeyContact';
import OrganizationKeyContactAddWindow from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/packages/people-dashboard-dropdown/packages/people-dashboard-dropdown-organization-key-contact/packages/organization-key-contact-add-window/OrganizationKeyContactAddWindow';
import PeopleDashboardDropdownMenuHeaderOrganizationKeyContact from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/packages/people-dashboard-dropdown/packages/people-dashboard-dropdown-organization-key-contact/packages/people-dashboard-dropdown-menu-header-organization-key-contact/PeopleDashboardDropdownMenuHeaderOrganizationKeyContact';

export type PeopleDashboardDropdownOrganizationKeyContactProps = {
  peopleConsoleUrl: string;
};

const PeopleDashboardDropdownOrganizationKeyContact = ({ peopleConsoleUrl }: PeopleDashboardDropdownOrganizationKeyContactProps) => {
  const { organizationKeyContacts, organizationContacts } = usePeopleDashboardContext();
  const [isAddingOrganizationKeyContact, setAddingOrganizationKeyContact] = React.useState(false);
  const [isMenuOpen, setMenuOpen] = React.useState(false);

  return (
    <PeopleDashboardDropdown
      buttonContent={{
        icon: 'User',
        title: (
          <Label
            color={'dark-700'}
            bold={true}
            size={'md'}
          >
            Key Contacts
          </Label>
        ),
        description:
          organizationKeyContacts.length > 0 ? (
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <Label
                color={'primary-500'}
                bold={true}
                size={'md'}
              >
                {organizationKeyContacts[0].fullName}
              </Label>
              <Label
                color={'dark-900-64'}
                size={'md'}
              >
                and {organizationKeyContacts.length - 1} other
                {organizationKeyContacts.length - 1 === 1 ? '' : 's'}
              </Label>
            </div>
          ) : (
            <Label color={'dark-900-64'}>No user assigned</Label>
          ),
        onClick: isOpen => {
          setMenuOpen(isOpen);
        },
      }}
      menuContent={{
        isMenuOpen,
        preOptionsSection: <PeopleDashboardDropdownMenuHeaderOrganizationKeyContact peopleConsoleUrl={peopleConsoleUrl} />,
        options: organizationKeyContacts,
        option: option => <PeopleDashboardDropdownOptionOrganizationKeyContact organizationKeyContact={option} />,
        postOptionsSection: (
          <React.Fragment>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 0.75rem;
              `}
            >
              <DisableElementOverlay disabled={organizationContacts.length < 1}>
                {organizationContacts.length < 1 && (
                  <Label
                    color={'dark-900'}
                    size={'sm'}
                  >
                    We're unable to locate any user information. To proceed with assigning key contacts, please ensure that your PSA is
                    properly linked.
                  </Label>
                )}
              </DisableElementOverlay>
              <Button
                iconLeft={'Add'}
                size={'xs'}
                disabled={organizationContacts.length < 1}
                onClick={() => {
                  setAddingOrganizationKeyContact(true);
                  setMenuOpen(false);
                }}
              >
                Assign new contacts
              </Button>
            </div>
            {isAddingOrganizationKeyContact && (
              <OrganizationKeyContactAddWindow
                isOpen={isAddingOrganizationKeyContact}
                onDismiss={() => {
                  setAddingOrganizationKeyContact(false);
                }}
              />
            )}
          </React.Fragment>
        ),
      }}
    />
  );
};

export default PeopleDashboardDropdownOrganizationKeyContact;
