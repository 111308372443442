import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { css } from '@emotion/react';
import { FieldKeyExpression, FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { TicketCreateFormDataType } from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/types/TicketCreateFormDataType';
import { TicketFieldValues } from '~/wm/packages/integration/packages/ticket/types/TicketFieldValues';
import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';
import RenderFormIntegrationFieldOption from '~/wm/packages/integration/packages/render-form-integration-field-option/RenderFormIntegrationFieldOption';
import { Enum as InputTypeEnum } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Credential/InputTypeNested.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { ValueDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model/IntegrationFieldOptionDtoNested.gen';
import { FormErrorMessageLabel } from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';

export type TicketCreateFormModuleProps = {
  hasError?: boolean;
  fieldOptions: IntegrationFieldOptionDto[];
};

/**
 * The Field Key for the Integration Field Values for creating a ticket
 */
const ticketFieldValuesFieldKey: FieldKeyExpression<TicketCreateFormDataType> = values => values.ticketFieldValues;

const getInputSectionStyles = (inputType: InputTypeEnum): string => {
  switch (inputType) {
    case InputTypeEnum.Select:
      return 'width: 48%';
    default:
      return 'width: 100%';
  }
};

/**
 * Indicates if the input type is a textual input type
 * @param inputType the current input type enum
 */
const isTextualInputType = (inputType: InputTypeEnum): boolean =>
  ![InputTypeEnum.Select, InputTypeEnum.None, InputTypeEnum.Toggle].includes(inputType);

/**
 * Indicates if the input type is a select input type
 * @param inputType the current input type enum
 */
const isSelectInputType = (inputType: InputTypeEnum): boolean => inputType === InputTypeEnum.Select;

/**
 * This will take in the field options from the integration and return a flattened array of field options
 * Note: this will remove all possible values of the child field options for later processing of allowed values
 * @param fieldOptions array of IntegrationFieldOptionDto
 */
const getFlattenedChildFieldOptions = (fieldOptions: IntegrationFieldOptionDto[]): IntegrationFieldOptionDto[] => {
  if (typeof fieldOptions === 'undefined') {
    return [];
  }

  return fieldOptions.reduce<IntegrationFieldOptionDto[]>((acc, fieldOption) => {
    const { childOptions } = fieldOption;
    if (typeof childOptions === 'undefined' || (typeof childOptions !== 'undefined' && Object.values(childOptions).length === 0)) {
      return [...acc, fieldOption];
    }

    const innerFieldOptions = Object.values(childOptions).reduce((innerAcc, currentFieldOptions) => {
      const noValueCurrentFieldOptions = currentFieldOptions.map(fieldOption => ({ ...fieldOption, values: [] }));
      return [...innerAcc, ...noValueCurrentFieldOptions];
    }, []);

    return [...acc, fieldOption, ...getFlattenedChildFieldOptions(innerFieldOptions)];
  }, []);
};

/**
 * This will get the distinct flattened child field options
 * Note: uses getFlattenedChildFieldOptions(...) to get the flattened child field options
 * @param fieldOptions array of IntegrationFieldOptionDto
 */
const getDistinctFlattenedChildFieldOptions = (fieldOptions: IntegrationFieldOptionDto[]): IntegrationFieldOptionDto[] =>
  Object.values(getFlattenedChildFieldOptions(fieldOptions).reduce((acc, fieldOption) => ({ ...acc, [fieldOption.key]: fieldOption }), {}));

/**
 * This will take in the field options from the integration and will
 * @param fieldOptions array of IntegrationFieldOptionDto
 */
const getFieldOptionPermutations = (
  fieldOptions: IntegrationFieldOptionDto[],
): Record<string, { [key in string]: IntegrationFieldOptionDto[] }> => {
  if (typeof fieldOptions === 'undefined') {
    return {};
  }

  const fieldOptionsWithChildren = fieldOptions.filter(
    fieldOption => typeof fieldOption.childOptions !== 'undefined' && Object.values(fieldOption.childOptions).length > 0,
  );

  if (fieldOptionsWithChildren.length === 0) {
    return {};
  }

  return Object.values(fieldOptionsWithChildren).reduce(
    (acc, fieldOption) => ({ ...acc, [fieldOption.key]: fieldOption.childOptions }),
    {},
  );
};

const TicketCreateFormModule = ({ hasError, fieldOptions }: TicketCreateFormModuleProps) => {
  const { getFormInput, setFormInput, removeFormInput } = useFormContext<TicketCreateFormDataType>();
  const formFieldValuesByKey = getFormInput<TicketFieldValues>(ticketFieldValuesFieldKey).value;

  const flattenedFieldOptions = getDistinctFlattenedChildFieldOptions(fieldOptions);
  const fieldOptionPermutations = getFieldOptionPermutations(fieldOptions);
  const fieldOptionPossibleValuesByKey = useMemo(() => new Map<string, ValueDto[]>(), []);

  // When you select one dropdown option, the other drop down options need to populate with those that are set by the backend
  // -> these come from the childOptions of the field options
  useEffect(() => {
    flattenedFieldOptions.forEach(fieldOption => {
      const currentValue = formFieldValuesByKey[fieldOption.key] ?? undefined;
      const allowedValues = fieldOptionPermutations[fieldOption.key];

      const hasAllowedValues =
        typeof currentValue !== 'undefined' && typeof allowedValues !== 'undefined' && typeof allowedValues[currentValue] !== 'undefined';

      // If textual input and value has not been defined: set the form value to an empty string
      if (isTextualInputType(fieldOption.inputType.type) && typeof currentValue === 'undefined') {
        setFormInput<string>(
          ({ ticketFieldValues }: FieldKeyExpressionSegment<TicketCreateFormDataType>) => ticketFieldValues[fieldOption.key],
          '',
        );
      }

      // If the input is a select
      if (isSelectInputType(fieldOption.inputType.type)) {
        const currentOptions = fieldOptionPossibleValuesByKey.get(fieldOption.key) ?? fieldOption.values;
        const isDefaultFormValueNotDefined =
          typeof formFieldValuesByKey[fieldOption.key] === 'undefined' &&
          typeof currentOptions !== 'undefined' &&
          currentOptions.length > 0;
        const hasZeroOptionsForCurrentFormInput = typeof fieldOption.values !== 'undefined' && fieldOption.values.length > 0;

        // If the form does not have a value defined: set the form value to an empty string
        if (isDefaultFormValueNotDefined) {
          setFormInput<string>(
            ({ ticketFieldValues }: FieldKeyExpressionSegment<TicketCreateFormDataType>) => ticketFieldValues[fieldOption.key],
            '',
          );
        }

        if (hasZeroOptionsForCurrentFormInput) {
          removeFormInput<string>(
            ({ ticketFieldValues }: FieldKeyExpressionSegment<TicketCreateFormDataType>) => ticketFieldValues[fieldOption.key],
          );
        }

        // if there are possible values: set the possible values for the field option (NOTE: only for parent input selects)
        if (typeof fieldOption.values !== 'undefined' && fieldOption.values.length > 0) {
          fieldOptionPossibleValuesByKey.set(fieldOption.key, fieldOption.values);
        }
      }

      if (hasAllowedValues) {
        // Iterate all child field options and get the possible values for each
        allowedValues[currentValue].forEach(innerFieldOption => {
          const childFieldValues = innerFieldOption?.values ?? [];

          if (isSelectInputType(innerFieldOption.inputType.type)) {
            if (
              !fieldOptionPossibleValuesByKey.has(innerFieldOption.key) ||
              (fieldOptionPossibleValuesByKey.has(innerFieldOption.key) &&
                JSON.stringify(fieldOptionPossibleValuesByKey.get(innerFieldOption.key)) !== JSON.stringify(childFieldValues))
            ) {
              fieldOptionPossibleValuesByKey.set(innerFieldOption.key, childFieldValues);
              setFormInput<string>(
                ({ ticketFieldValues }: FieldKeyExpressionSegment<TicketCreateFormDataType>) => ticketFieldValues[innerFieldOption.key],
                '',
              );
            }
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldValuesByKey]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <Header
        size={4}
        weight={'bold'}
      >
        Create New Ticket
      </Header>
      {hasError && (
        <FormErrorMessageLabel
          errorMessage={
            'An error occurred while creating the ticket. Please check your PSA integration, try another service board, or try again later.'
          }
        />
      )}
      <div
        css={css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          gap: 1.5rem;
        `}
      >
        {flattenedFieldOptions.map(fieldOption => (
          <div
            key={fieldOption.key}
            css={css`
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              ${getInputSectionStyles(fieldOption.inputType.type)}
            `}
          >
            <Label>{fieldOption.label}</Label>
            <RenderFormIntegrationFieldOption
              fieldKey={({ ticketFieldValues }: FieldKeyExpressionSegment<TicketCreateFormDataType>) => ticketFieldValues[fieldOption.key]}
              data={{
                ...fieldOption,
                values: fieldOptionPossibleValuesByKey.get(fieldOption.key) ?? [],
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TicketCreateFormModule;
