import { ScopeKey } from '@AssetManagementClient/BeastClient/Beast/Settings/Scope.gen';
import * as React from 'react';
import Organization from '~/wm/packages/organization/model/Organization';
import OrganizationLock from '~/wm/packages/organization/packages/organization-lock/OrganizationLock';
import WarrantyCoverageSettings from '~/wm/packages/warranty/packages/coverage/packages/settings/WarrantyCoverageSettings';

export type OrganizationWarrantySettingsPageProps = {
  organization: Organization;
  appSettingsUrl: string;
};

const OrganizationWarrantySettingsPage: React.FunctionComponent<OrganizationWarrantySettingsPageProps> = ({
  organization,
  appSettingsUrl,
}) => {
  const scopeKey: ScopeKey = { id: 'Organization', value: organization.id };

  return (
    <OrganizationLock organizationIds={[organization.id]}>
      <WarrantyCoverageSettings
        scopeKey={scopeKey}
        appSettingsUrl={appSettingsUrl}
      />
    </OrganizationLock>
  );
};

export default OrganizationWarrantySettingsPage;
