import * as React from 'react';
import FeatureAccessBanner from '~/wm/packages/feature/packages/feature-access-banner/FeatureAccessBanner';
import useSubscriptionDetailsPlan from '~/wm/packages/subscription/packages/subscription-details/hooks/useSubscriptionDetailsPlan';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useFeatureAccessOrganizationUnlockClientFocusScorecardRoadmap from '~/wm/packages/feature/hooks/useFeatureAccessOrganizationUnlockClientFocusScorecardRoadmap';

export type FeatureAccessBannerClientFocusProps = {
  upgradeUrl: string;
  growUrl: string;
};

const FeatureAccessBannerClientFocus = ({ upgradeUrl, growUrl }: FeatureAccessBannerClientFocusProps) => {
  const featureKey = 'client-focus';
  const imageSrc = '/i/marketing/strategy/image.png';
  const { detailsPlan } = useSubscriptionDetailsPlan();
  const { organizationName } = useOrganizationContext();
  const { organizationUnlock, isUnlocking } = useFeatureAccessOrganizationUnlockClientFocusScorecardRoadmap({ reloadOnSuccess: true });

  // Don't show the a banner if we don't have enough information
  if (typeof detailsPlan === 'undefined') {
    return null;
  }

  const unlockBannerDetails = {
    title: `Want to give ${organizationName} a better experience?`,
    description: 'Unlock new features for simplified and strategic client engagement. Try it free for one client.',
  };

  if (detailsPlan.planEdition === 'Free') {
    return (
      <FeatureAccessBanner
        featureKey={featureKey}
        unlockBannerDetails={unlockBannerDetails}
        upgradeBannerDetails={{
          title: 'Tired of toggling between tools to manage client activity?',
          description: `Upgrading to a Pro plan gives you full access to our new features to centralize meeting notes, to-do's, and contact details for all of your clients.`,
        }}
        imageSrc={imageSrc}
        upgradeUrl={upgradeUrl}
        isUnlocking={isUnlocking}
        onOrganizationUnlock={(_, organizationId) => organizationUnlock({ organizationId })}
      />
    );
  }

  if (
    (detailsPlan.generation === 1 || detailsPlan.generation === 2 || detailsPlan.generation === 3 || detailsPlan.generation === 4) &&
    typeof detailsPlan.term === 'undefined'
  ) {
    return (
      <FeatureAccessBanner
        featureKey={featureKey}
        unlockBannerDetails={unlockBannerDetails}
        upgradeBannerDetails={{
          title: 'Tired of toggling between tools to manage client activity?',
          description:
            "Upgrading to the latest version gives you full access to our new features to centralize meeting notes, to-do's, and contact details for all of your clients.",
        }}
        imageSrc={imageSrc}
        upgradeUrl={growUrl}
        isUnlocking={isUnlocking}
        onOrganizationUnlock={(_, organizationId) => organizationUnlock({ organizationId })}
      />
    );
  }

  if (detailsPlan.generation === 3 && typeof detailsPlan.term !== 'undefined') {
    return (
      <FeatureAccessBanner
        featureKey={featureKey}
        unlockBannerDetails={{
          title: `Want to give ${organizationName} a better experience?`,
          description: `Unlock new features to improve your follow-through on interactions with ${organizationName}. It's free to try for one client.`,
        }}
        upgradeBannerDetails={{
          title: 'Tired of toggling between tools to manage client activity?',
          description:
            "Upgrading to the latest version gives you full access to our new features to centralize meeting notes, to-do's, and contact details for all of your clients.",
        }}
        imageSrc={imageSrc}
        upgradeUrl={growUrl}
        isUnlocking={isUnlocking}
        onOrganizationUnlock={(_, organizationId) => organizationUnlock({ organizationId })}
      />
    );
  }

  return null;
};

export default FeatureAccessBannerClientFocus;
