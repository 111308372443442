import * as React from 'react';
import Theme from '~/neo-ui/packages/color/Theme';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import ModalInternal from '~/neo-ui/packages/modal/ModalInternal';
import ModalConfirmationFooter from '~/neo-ui/packages/modal/packages/modal-confirmation/packages/modal-confirmation-footer/ModalConfirmationFooter';
import { css } from '@emotion/react';
import ModalConfirmationHeader from '~/neo-ui/packages/modal/packages/modal-confirmation/packages/modal-confirmation-header/ModalConfirmationHeader';

export type AlertModalData = {
  theme?: Theme;

  title?: string;
  description?: React.ReactNode;

  footerButtons?: AlertModalFooterProps;

  /**
   * Determines whether or not the modal can be dismissed by
   * pressing the screen outside its bounds
   */
  isStatic?: boolean;

  onConfirm?: () => void;
  onDismiss?: () => void;
  onClosed?: () => void;

  children?: React.ReactNode;
};

export type AlertModalFooterProps = {
  confirm?: AlertModalButtonProps;
  dismiss?: AlertModalButtonProps;
};

export type AlertModalButtonProps = {
  text: string;
  icon?: IconType;
  disabled?: boolean;
  loading?: boolean;
};

export type AlertModalProps = AlertModalData & { isOpen: boolean };

/**
 * @deprecated deprecated by ModalConfirmation
 */
const AlertModal: React.FunctionComponent<AlertModalProps> = ({
  isOpen = false,
  isStatic = false,
  title,
  description,
  theme = 'warning',

  footerButtons,

  onConfirm = () => {},
  onDismiss = () => {},
  onClosed,

  children,
}) => (
  <ModalInternal
    isOpen={isOpen}
    isStatic={isStatic}
    maxWidthRem={25.9375}
    header={
      typeof title === 'undefined' ? undefined : (
        <ModalConfirmationHeader
          title={title}
          theme={theme}
        />
      )
    }
    onDismiss={onDismiss}
    footer={
      typeof footerButtons === 'undefined' ? undefined : (
        <ModalConfirmationFooter
          theme={theme}
          dismissButton={
            typeof footerButtons.dismiss === 'undefined'
              ? {
                  label: 'Close',
                  icon: 'Cancel',
                  onClick: () => {
                    onDismiss();
                    if (typeof onClosed !== 'undefined') {
                      onClosed();
                    }
                  },
                }
              : {
                  label: footerButtons.dismiss.text,
                  icon: footerButtons.dismiss.icon,
                  disabled: footerButtons.dismiss.disabled,
                  loading: footerButtons.dismiss.loading,
                  onClick: () => {
                    onDismiss();
                    if (typeof onClosed !== 'undefined') {
                      onClosed();
                    }
                  },
                }
          }
          confirmButton={
            typeof footerButtons.confirm === 'undefined'
              ? undefined
              : {
                  label: footerButtons.confirm.text,
                  icon: footerButtons.confirm.icon,
                  disabled: footerButtons.confirm.disabled,
                  loading: footerButtons.confirm.loading,
                  onClick: onConfirm,
                }
          }
        />
      )
    }
  >
    {description && (
      <p
        css={css`
          ${!children && 'margin-bottom: 0'}
        `}
      >
        {description}
      </p>
    )}
    <div>{children}</div>
  </ModalInternal>
);

export default AlertModal;
