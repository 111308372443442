import ButtonGroup from '~/neo-ui/packages/button/packages/button-group/ButtonGroup';
import Button from '~/neo-ui/packages/button/Button';
import { RoadmapViewMode } from '~/wm/packages/strategy/packages/roadmap-page/RoadmapPage';

export type RoadmapViewModeButtonGroupProps = {
  roadmapViewMode: RoadmapViewMode;
  onRoadmapViewModeChange: (mode: RoadmapViewMode) => void;
};

const RoadmapViewModeButtonGroup = ({ roadmapViewMode, onRoadmapViewModeChange }: RoadmapViewModeButtonGroupProps) => {
  const onClick = (mode: RoadmapViewMode) => {
    if (roadmapViewMode !== mode) {
      onRoadmapViewModeChange(mode);
    }
  };

  return (
    <ButtonGroup>
      <Button
        iconRight={'Roadmap'}
        onClick={() => onClick('Roadmap')}
        theme={roadmapViewMode === 'Roadmap' ? 'primary' : undefined}
      >
        Roadmap view
      </Button>
      <Button
        iconRight={'List'}
        onClick={() => onClick('List')}
        theme={roadmapViewMode === 'List' ? 'primary' : undefined}
      >
        List view
      </Button>
    </ButtonGroup>
  );
};

export default RoadmapViewModeButtonGroup;
