import * as React from 'react';
import { components, GroupBase, MenuProps } from 'react-select';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';

const SingleSelectMenu = <T extends string>(
  props: React.PropsWithChildren<MenuProps<SelectOption<T>, false, GroupBase<SelectOption<T>>>>,
) => {
  const { Menu } = components;

  return <Menu {...props}>{props.children}</Menu>;
};

export default SingleSelectMenu;
