import React from 'react';

type BannerProps = {
  vendorBrandName: string;
  isNewIntegration: boolean;
};

export const DevModeBanner: React.FunctionComponent<BannerProps> = ({ vendorBrandName, isNewIntegration }) => {
  return (
    <div className="card bg-light6 shadow-sm">
      <div className="card-header bg-info text-white">
        <h3 className="wm-margin-bottom-0">Help us build our {vendorBrandName} integration!</h3>
      </div>
      <div className={`card-body ${isNewIntegration ? '' : 'wm-margin-bottom-20'}`}>
        {isNewIntegration ? (
          <ol>
            <li> Upon saving this form, your account will be placed into a manual queue.</li>
            <li> It will be submitted to the dev team to ensure that the integration is working properly.</li>
            <li> If all goes well, we'll fully activate your {vendorBrandName} integration and you'll be one of the first adopters!</li>
          </ol>
        ) : (
          <>
            We have received your submission. It is currently undergoing internal review. We hope to be in touch soon.
            <br />
          </>
        )}
      </div>
    </div>
  );
};
