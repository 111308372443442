import { css } from '@emotion/react';
import { CardElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeCardElement, StripeCardElementOptions, StripeError, Token } from '@stripe/stripe-js';
import * as React from 'react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import FormErrorMessage from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';
import { FieldKeyExpression, mutateValueAtFieldKey, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { ApiServerErrorResponseDto } from '~/wm/packages/api/packages/api-error/model/ApiServerErrorResponseDto';
import useApi from '~/wm/packages/api/hook/useApi';
import { subscriptionBillingSetupIntentCreate } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { Styleable } from '~/neo-ui/model/capacity';

/**
 * We have 2 different version of API for Stripe.
 * Depending which one is used, we need to call the API differently.
 */
export enum PaymentVersion {
  V1 = 'v2017',
  V2 = 'v2022',
}

export type FormCreditCardInputProps<T> = {
  fieldKey: FieldKeyExpression<T>;
  version: PaymentVersion;
} & Styleable;

export type CreditCardFormDataOnFile = {
  type: 'card-on-file';
  last4: string;
  brand: string;
};

export type CreditCardFormDataNewCard = {
  type: 'new-card';
  tokenId: string | undefined;
  brand: string;
  /**
   * True if the value is well-formed and potentially complete.
   * Can be used to progressively disclose the next parts of your form or to enable form submission.
   */
  isComplete: boolean;
};

/**
 * Sum-type to represent either an existing card
 * or a new card being specified.
 */
export type CreditCardFormData = CreditCardFormDataOnFile | CreditCardFormDataNewCard;

type StripeTokenResponse = {
  token?: Token | undefined;
  error?: StripeError | undefined;
};

const FormCreditCardInput = <T,>({ fieldKey, version, className }: FormCreditCardInputProps<T>) => {
  const stripePromise = React.useRef(loadStripe(WM.stripePublicKey)).current;
  const { callApi } = useApi();

  const { addBeforeSubmitListener, setFormInput } = useFormContext<T>();

  const stripeCardElementRef = React.useRef<StripeCardElement>();
  const [cardBrand, setCardBrand] = React.useState('');

  const createToken = React.useCallback(async () => {
    const stripe = await stripePromise;

    if (!stripe) {
      // Stripe is not loaded yet.
      return;
    }

    switch (version) {
      case PaymentVersion.V1:
        throw new Error(`Payment version ${version} isn't supported anymore`);
      case PaymentVersion.V2:
        const response = await callApi(() => subscriptionBillingSetupIntentCreate({}));

        const confirmCardResponse = await stripe.confirmCardSetup(response!.clientSecret, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          payment_method: {
            card: stripeCardElementRef.current!,
          },
        });

        return {
          token: confirmCardResponse.setupIntent?.id,
          brand: cardBrand,
          error: confirmCardResponse.error,
        };
    }
  }, [callApi, cardBrand, stripePromise, version]);

  const onBeforeSubmit = React.useCallback(
    async (formData: T) => {
      const result = await createToken();

      if (!result) {
        // No token. Leave unset.
        return;
      }

      if (result.error) {
        const validationError: ApiServerErrorResponseDto = {
          Errors: {
            [resolveFieldKey(fieldKey)]: [result.error.message ?? 'An error occurred processing your card.'],
          },
          Status: 400,
        };
        throw validationError;
      }

      if (!result.token) {
        // No token. Leave unset.
        return;
      }

      const value: CreditCardFormData = {
        type: 'new-card',
        tokenId: result.token,
        brand: result.brand,
        isComplete: true,
      };

      mutateValueAtFieldKey(formData, fieldKey, value);
    },
    [createToken, fieldKey],
  );

  React.useEffect(() => addBeforeSubmitListener(onBeforeSubmit), [addBeforeSubmitListener, onBeforeSubmit]);

  const options: StripeCardElementOptions = React.useMemo(
    () => ({
      hidePostalCode: true,
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: colorToCode('light-800'),
          color: 'black',
          fontWeight: 'dark-300',
          fontFamily: "'Roboto', sans-serif",
          fontSize: '14px',

          // eslint-disable-next-line @typescript-eslint/naming-convention
          '::placeholder': {
            color: colorToCode('light-800'),
          },
        },
        invalid: {
          iconColor: colorToCode('negative-400'),
          color: colorToCode('negative-400'),
        },
      },
      classes: {
        focus: 'is-focused',
        empty: 'is-empty',
      },
    }),
    [],
  );

  return (
    <div className={className}>
      {/*  This component is custom and doesn't compose the typical form input abstraction */}
      <div
        className={'form-control'}
        css={css`
          height: 2.25rem;
          border-radius: 0.375rem;
          max-width: 415px;
          padding: 0.5rem 0.75rem;
        `}
      >
        <Elements stripe={stripePromise}>
          <CardElement
            options={options}
            onReady={stripeCardElement => {
              stripeCardElementRef.current = stripeCardElement;
            }}
            onChange={evt => {
              setFormInput<CreditCardFormData>(fieldKey, {
                type: 'new-card',
                tokenId: undefined,
                brand: evt.brand,
                isComplete: evt.complete,
              });
              setCardBrand(evt.brand);
            }}
          />
        </Elements>
      </div>
      <FormErrorMessage fieldKey={fieldKey} />
    </div>
  );
};

export default FormCreditCardInput;
