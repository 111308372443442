import { organizationEngagementNoteUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementNoteListPanelTabMutation, {
  UseOrganizationEngagementNoteListPanelTabMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/context/hooks/useEngagementNoteListPanelTabMutation';
import { EngagementNoteUpdatePayload } from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/packages/engagement-note-edit-form/EngagementNoteEditForm';

export type UseEngagementNoteListUpdate = {
  updateEngagementNote: (updatePayload: EngagementNoteUpdatePayload) => void;
  isUpdatingNote: boolean;
};

const useEngagementNoteListUpdate = (options?: UseOrganizationEngagementNoteListPanelTabMutationOptions): UseEngagementNoteListUpdate => {
  const [updateEngagementNote, isUpdatingNote] = useEngagementNoteListPanelTabMutation(
    (updatePayload: EngagementNoteUpdatePayload) =>
      organizationEngagementNoteUpdate({
        updatePayload: {
          engagementNoteId: updatePayload.engagementNoteId,
          title: updatePayload.title,
          description: updatePayload.description,
        },
      }),
    options,
  );

  return {
    updateEngagementNote,
    isUpdatingNote,
  };
};

export default useEngagementNoteListUpdate;
