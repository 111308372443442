import { roadmapPdfNew } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { RoadmapPdfDownloadPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Roadmap/Dto/Model.gen';

type UseDownloadRoadmapPdfNew = (onDownload?: () => void) => {
  downloadRoadmapPdfNew: (downloadPayload: RoadmapPdfDownloadPayloadDto) => void;
};

export const useDownloadRoadmapPdfNew: UseDownloadRoadmapPdfNew = onDownload => {
  const { callApi } = useApi();

  const downloadRoadmapPdfNew = React.useCallback(
    async (downloadPayload: RoadmapPdfDownloadPayloadDto) => {
      const stream = await callApi(() => roadmapPdfNew({ downloadPayloadDto: downloadPayload }));

      if (!stream) {
        return;
      }

      if (onDownload) {
        onDownload();
      }
    },
    [callApi, onDownload],
  );

  return { downloadRoadmapPdfNew };
};

export default useDownloadRoadmapPdfNew;
