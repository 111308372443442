import { type DiscountLineItem } from '@SubscriptionClient/BeastClient/Billing/Subscription/Quotes/Application/Queries/Models.gen';
import { css } from '@emotion/react';
import { Fragment } from 'react';
import Text from '~/sp-ui/text';
import theme from '~/sp-ui/theme';
import formatCurrency from '../../formatCurrency';
import { LineItemTooltip } from './LineItemTooltip';

const discountCss = css({
  color: theme.palette.success1,
  ['&::before']: {
    content: '"-"',
  },
});

const DiscountLineItem = (props: { discountLineItem: DiscountLineItem }) => {
  const { discountLineItem } = props;

  return (
    <>
      <Text css={{ display: 'inline-flex', alignItems: 'center' }}>
        {discountLineItem.label}
        {!!discountLineItem.discountBreakdown.length && (
          <LineItemTooltip css={{ width: '1rem', height: '1rem', marginLeft: '0.5rem' }}>
            <div
              css={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                columnGap: '1rem',
                rowGap: '0.5rem',
                ['& > :nth-of-type(2n)']: css(
                  {
                    justifySelf: 'end',
                  },
                  discountCss,
                ),
              }}
            >
              {discountLineItem.discountBreakdown.map((breakdown, index) => {
                return (
                  <Fragment key={index}>
                    <Text>{breakdown.description}</Text>
                    <Text>{formatCurrency(breakdown.amountInCents)}</Text>
                  </Fragment>
                );
              })}
            </div>
            <hr css={{ backgroundColor: theme.palette.grey1, marginBlock: '8px' }} />
            <Text css={{ fontWeight: 700 }}>Total discounts</Text>
            <Text
              css={[
                {
                  float: 'right',
                },
                discountCss,
              ]}
            >
              {formatCurrency(discountLineItem.discountTotalInCents)}
            </Text>
          </LineItemTooltip>
        )}
      </Text>
      <Text css={[{ justifySelf: 'right' }, discountCss]}>{formatCurrency(discountLineItem.discountTotalInCents)}</Text>
    </>
  );
};

export default DiscountLineItem;
