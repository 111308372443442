import { parseISO } from 'date-fns';
import formatCurrency, { CurrencyCode } from '~/extensions/packages/currency/formatCurrency';
import { AppliedProductDiscountFormatted } from '~/wm/packages/subscription/packages/manage/model/AppliedProductDiscountFormatted';
import { DiscountDto } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/PricingModel.gen';

const buildAppliedProductDiscountFormat = (discounts: DiscountDto[], currencyCode: CurrencyCode): AppliedProductDiscountFormatted[] =>
  discounts.map(
    discount =>
      ({
        amount: formatCurrency(discount.amount, currencyCode),
        title: discount.title,
        subtitle: discount.subtitle,
        expiresAt: !!discount.expirationDate ? parseISO(discount.expirationDate) : undefined,
      } as AppliedProductDiscountFormatted),
  );

export default buildAppliedProductDiscountFormat;
