import Box from '~/neo-ui/packages/box/Box';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import PrioritySelect from '~/wm/packages/strategy/packages/priority/PrioritySelect';
import FormErrorMessage from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';
import useInitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesContext';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import {
  BudgetQuarterFormData,
  InitiativeSaveFormData,
} from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import SingleSelect from '~/neo-ui/packages/select/packages/single-select/SingleSelect';
import InitiativeStatusDropdown from '~/wm/packages/strategy/packages/initiative/packages/initiative-status-dropdown/InitiativeStatusDropdown';
import * as React from 'react';
import { useState } from 'react';
import FiscalQuarterSelect from '~/wm/packages/strategy/packages/fiscal-quarter/FiscalQuarterSelect';
import Button from '~/neo-ui/packages/button/Button';
import DisableElementOverlay from '~/neo-ui/packages/overlay/DisableElementOverlay';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';

const priorityFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.priority;
const pointOfContactFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.pointOfContactUserId;
const statusFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.status;
const budgetQuarterFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.budgetQuarter;

const InitiativeSaveControlsSection = () => {
  const { availabilities } = useInitiativeAvailabilitiesContext();
  const { setFormInput, getFormInput } = useFormContext<InitiativeSaveFormData>();
  const { currentFiscalQuarter } = useRegionalSettingsInfoContext();
  const fiscalQuarterSelected = getFormInput<BudgetQuarterFormData>(budgetQuarterFieldKey).value;
  const [defaultFiscalQuarterSelected] = useState(getFormInput<BudgetQuarterFormData>(budgetQuarterFieldKey).value ?? currentFiscalQuarter);

  const [pointOfContact, setPointOfContact] = useState(
    availabilities.pointOfContactAvailabilities.find(
      availability => availability.id === getFormInput<string>(pointOfContactFieldKey).value,
    )!,
  );

  return (
    <Box
      padding={'padding100'}
      borderRadius={'radius400'}
      boxShadow={'shadow100'}
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 1.25rem;
      `}
    >
      <div
        css={css`
          flex-basis: 11rem;
        `}
      >
        <Header
          size={7}
          color={'dark-050'}
          css={css`
            margin: 0.125rem 0 0.625rem 0.375rem;
          `}
        >
          Status
        </Header>
        <InitiativeStatusDropdown
          selectedStatus={getFormInput<string>(statusFieldKey).value}
          onStatusChange={status => setFormInput<string>(statusFieldKey, status)}
          initiativeStatusAvailabilities={availabilities.statusAvailabilities}
          css={css`
            width: 100%;
          `}
        />
      </div>
      <div
        css={css`
          border-left: 1px solid ${colorToCode('dark-900-24')};
        `}
      />
      <div>
        <Header
          size={7}
          color={'dark-050'}
          css={css`
            margin: 0.125rem 0 0.625rem 0.375rem;
          `}
        >
          Priority
        </Header>
        <div>
          <PrioritySelect
            priorityAvailabilities={availabilities.priorityAvailabilities}
            currentPriority={
              availabilities.priorityAvailabilities.find(option => option.key === getFormInput<string>(priorityFieldKey).value)!
            }
            onSelectPriority={async selectedPriority => {
              await setFormInput<string>(priorityFieldKey, selectedPriority.value);
            }}
          />
          <FormErrorMessage fieldKey={priorityFieldKey} />
        </div>
      </div>
      <div
        css={css`
          border-left: 1px solid ${colorToCode('dark-900-24')};
        `}
      />
      <div>
        <Header
          size={7}
          color={'dark-050'}
          css={css`
            margin: 0.125rem 0 0.625rem 0.375rem;
          `}
        >
          Schedule
        </Header>
        <div
          css={css`
            display: flex;
            gap: 0.25rem;
          `}
        >
          <Button
            theme={typeof fiscalQuarterSelected !== 'undefined' ? undefined : 'primary'}
            onClick={() =>
              typeof fiscalQuarterSelected === 'undefined'
                ? setFormInput<BudgetQuarterFormData>(budgetQuarterFieldKey, defaultFiscalQuarterSelected)
                : setFormInput<undefined>(budgetQuarterFieldKey, undefined)
            }
          >
            Not Scheduled
          </Button>
          <DisableElementOverlay disabled={typeof fiscalQuarterSelected === 'undefined'}>
            <FiscalQuarterSelect
              defaultValue={fiscalQuarterSelected}
              onSelect={(year, quarter) =>
                setFormInput<BudgetQuarterFormData>(budgetQuarterFieldKey, {
                  year,
                  quarter,
                })
              }
              theme={'secondary'}
            />
          </DisableElementOverlay>
        </div>
      </div>
      <div
        css={css`
          border-left: 1px solid ${colorToCode('dark-900-24')};
        `}
      />
      <div
        css={css`
          flex: 1;
        `}
      >
        <Header
          size={7}
          color={'dark-050'}
          css={css`
            margin: 0.125rem 0 0.625rem 0.375rem;
          `}
        >
          Point of contact
        </Header>
        <SingleSelect
          shouldUseMenuPortal={false}
          options={availabilities.pointOfContactAvailabilities.map(availability => ({
            label: availability.name,
            description: availability.email,
            value: availability.id,
          }))}
          onOptionSelected={async option => {
            setPointOfContact({
              id: option.value,
              name: option.label,
              email: option.description!,
            });
            await setFormInput<string>(pointOfContactFieldKey, option.value);
          }}
          selectedOption={{
            label: pointOfContact.name,
            description: pointOfContact.email,
            value: pointOfContact.id,
            prependIcon: 'Members',
            iconSizePx: 26,
          }}
          placeholder={'Search members...'}
          style={'transparent'}
        />
      </div>
    </Box>
  );
};

export default InitiativeSaveControlsSection;
