import { FloatingArrow, FloatingPortal, ReferenceType } from '@floating-ui/react';
import { ComponentPropsWithoutRef, ReactNode, useRef } from 'react';
import theme from '../theme';
import useTooltip from './useTooltip';

export interface ToolTipProps extends Omit<ComponentPropsWithoutRef<'div'>, 'children' | 'content'> {
  children: (props: { anchorProps: Record<string, unknown>; setAnchorRef: (node: ReferenceType | null) => void }) => ReactNode;
  content: ReactNode;
}

const ToolTip = (props: ToolTipProps) => {
  const { children, content, ...rest } = props;

  const arrowRef = useRef<SVGSVGElement | null>(null);
  const { setTooltipRef, tooltipStyles, tooltipProps, anchorProps, setAnchorRef, context } = useTooltip({
    tooltipProps: rest,
    arrowRef,
  });

  return (
    <>
      {children({ anchorProps, setAnchorRef })}
      {context.open && (
        <FloatingPortal>
          <div
            ref={setTooltipRef}
            css={[
              {
                borderRadius: 4,
                filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                backgroundColor: theme.palette.white,
              },
              { ...tooltipStyles },
            ]}
            {...tooltipProps}
          >
            <div css={[theme.typography.body, { backgroundColor: 'transparent', padding: '1rem' }]}>{content}</div>
            <FloatingArrow
              ref={arrowRef}
              context={context}
              fill={theme.palette.white}
              width={16}
              height={8}
            />
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export default ToolTip;
