import { initiativeScheduleUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useInitiativeMutation, {
  UseInitiativeMutationOptions,
} from '~/wm/packages/strategy/packages/initiative/hooks/useInitiativeMutation';
import { InitiativeScheduleUpdatePayloadDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';

export type UseInitiativeScheduleUpdate = {
  updateInitiativeSchedule: (payload: InitiativeScheduleUpdatePayloadDto) => void;
  isUpdating: boolean;
};

const useInitiativeScheduleUpdate = (options?: UseInitiativeMutationOptions): UseInitiativeScheduleUpdate => {
  const [updateInitiativeSchedule, isUpdating] = useInitiativeMutation(
    ({ initiativeId, sourceQuarter, targetQuarter, targetOrderList, organizationId }: InitiativeScheduleUpdatePayloadDto) =>
      initiativeScheduleUpdate({
        scheduleUpdatePayload: {
          initiativeId,
          organizationId,
          sourceQuarter,
          targetQuarter,
          targetOrderList,
        },
      }),
    options,
  );

  return {
    updateInitiativeSchedule,
    isUpdating,
  };
};

export default useInitiativeScheduleUpdate;
