import { useContext } from 'react';
import ScorecardContext from '~/wm/packages/strategy/packages/scorecard/context/ScorecardContext';

const useScorecardContext = () => {
  const scorecardContext = useContext(ScorecardContext);
  if (!scorecardContext) {
    throw new Error('Tried to use ScorecardContext outside of an ScorecardProvider');
  }

  return scorecardContext;
};

export default useScorecardContext;
