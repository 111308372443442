import { SubscriptionPricingDto } from '@SubscriptionClient/BeastClient/Beast/Msp/Dto/Subscription/Pricing/Get/Model.gen';
import { ComponentPropsWithoutRef } from 'react';
import PricingAmount from '~/wm/packages/subscription/packages/plan/packages/product-plan-summary/PricingAmount';

type Props = SubscriptionPricingDto['expenses'][number];

type ExpenseItemProps = Omit<ComponentPropsWithoutRef<'div'>, keyof Props> & Props;

const ExpenseItem = (props: ExpenseItemProps) => {
  const { name, description, saleAmount, originalAmount, ...rest } = props;

  return (
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      }}
      {...rest}
    >
      <span css={{ gridArea: '1 / 1 / 2 / 2', paddingLeft: '1.875rem', marginBottom: '0.5rem' }}>{name}</span>

      <ul css={{ marginLeft: '0.375rem', marginBottom: '0rem' }}>{description && <li>{description}</li>}</ul>

      {!!saleAmount && (
        <div
          css={{
            gridArea: '1 / 2 / 2 / 3',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <PricingAmount
            saleAmount={saleAmount}
            originalAmount={originalAmount}
          />
        </div>
      )}
    </div>
  );
};

export default ExpenseItem;
