import { regionalSettingsAvailabilitiesGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { RegionalSettingsAvailabilities } from '@AssetManagementClient/BeastClient/Beast/RegionalSettings/Model/Availabilities.gen';
import { ScopeKey } from '@AssetManagementClient/BeastClient/Beast/Settings/Scope.gen';

import * as React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import SettingsConfigurable from '~/wm/packages/settings/packages/configurable/SettingsConfigurable';
import FormatSettingsForm from '~/wm/packages/settings/packages/regional-settings/configurable/FormatSettingsForm';

export type FormatConfigurableProps = {
  scopeKey: ScopeKey;
};

const FormatSettingsConfigurable: React.FunctionComponent<FormatConfigurableProps> = ({ scopeKey }) => {
  const { callApi } = useApi();

  const [availabilities, setAvailabilities] = React.useState<RegionalSettingsAvailabilities>();

  React.useEffect(() => {
    (async () => {
      const response = await callApi(() => regionalSettingsAvailabilitiesGet({}));
      if (!response) {
        return;
      }
      setAvailabilities(response.settingsAvailabilities);
    })();
  }, [callApi]);

  return (
    <SettingsConfigurable
      // TEMPORARY. Hide the save indicator in the account scope for better UX
      // when this component is embedded in the PHP form.
      isEmbeddedInPhpForm={true}
      settingsKey={{
        scopeKey,
        /** Referenced in beast: {a8daa1e6-17cd-406f-b96d-14e0c23e3bbd} */
        configurableId: 'regional',
      }}
      label={''}
    >
      {({ isUsingDefault }) => (
        <FormatSettingsForm
          availabilities={availabilities}
          disabled={isUsingDefault}
        />
      )}
    </SettingsConfigurable>
  );
};

export default FormatSettingsConfigurable;
