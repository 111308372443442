import { SubscriptionDetails } from '@SubscriptionClient/Subscription/Packages/Details/Model.gen';
import { subscriptionDetailsGet } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

const useSubscriptionDetailsGet = () => {
  const [isLoading, setLoading] = useState(true);

  const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetails>();

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => subscriptionDetailsGet({}));
      setLoading(false);

      if (!response?.subscriptionDetails) {
        return;
      }

      setSubscriptionDetails(response.subscriptionDetails);
    })();
  }, [callApi]);

  return { isLoading, subscriptionDetails };
};

export default useSubscriptionDetailsGet;
