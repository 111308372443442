import usePeopleDashboardMutation, {
  UsePeopleDashboardMutationOptions,
} from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/usePeopleDashboardMutation';
import { organizationKeyContactRemove } from '@AssetManagementClient/AssetManagementClientMsp.gen';

type OrganizationKeyContactRemovePayload = {
  organizationId: string;
  organizationContactId: string;
};

export type UseOrganizationAccountTeamMemberRemove = {
  removeOrganizationKeyContact: (createPayload: OrganizationKeyContactRemovePayload) => void;
  isRemovingOrganizationKeyContact: boolean;
};

const useOrganizationKeyContactRemove = (options?: UsePeopleDashboardMutationOptions): UseOrganizationAccountTeamMemberRemove => {
  const [removeOrganizationKeyContact, isRemovingOrganizationKeyContact] = usePeopleDashboardMutation(
    (createPayload: OrganizationKeyContactRemovePayload) =>
      organizationKeyContactRemove({
        organizationId: createPayload.organizationId,
        organizationKeyContactId: createPayload.organizationContactId,
      }),
    options,
  );

  return {
    removeOrganizationKeyContact,
    isRemovingOrganizationKeyContact,
  };
};

export default useOrganizationKeyContactRemove;
