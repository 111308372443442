import { SubscriptionBillingPeriodEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription.gen';

export type BillingPeriodDisplay = {
  adjective: string;
  adverb: string;
  noun: string;
  short: string;
};

const getBillingPeriodDisplay = (periodEnum: SubscriptionBillingPeriodEnum): BillingPeriodDisplay => {
  switch (periodEnum) {
    case SubscriptionBillingPeriodEnum.Annual:
      return {
        adjective: 'annual',
        adverb: 'annually',
        noun: 'year',
        short: 'yr',
      };
    case SubscriptionBillingPeriodEnum.Monthly:
      return {
        adjective: 'monthly',
        adverb: 'monthly',
        noun: 'month',
        short: 'mo',
      };
  }
};

export default getBillingPeriodDisplay;
