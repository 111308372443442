import { SelectStaticHeaderLabel, SelectStaticSharedProps } from '~/neo-ui/packages/select/packages/multi-select-static/MultiSelectStatic';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import MultiSelectStaticOption from '~/neo-ui/packages/select/packages/multi-select-static/packages/multi-select-static-option/MultiSelectStaticOption';
import { css } from '@emotion/react';

type SingleSelectStaticProps<T extends string = string> = SelectStaticSharedProps<T> & {
  selectedOption: SelectOption<T> | undefined;
  onChange: (selected?: SelectOption<T>) => void;
};

export const SingleSelectStatic = <T extends string>({
  className,
  headerLabel,
  theme = 'secondary',
  allOptions,
  selectedOption,
  onChange,
}: SingleSelectStaticProps<T>) => {
  /** *************************
   * Handle displayed options *
   ****************************/
  const createVisibleOptions = (): React.ReactNode[] => {
    const optionsToDisplay: React.ReactNode[] = [];
    // Fill display options after they have been filtered
    allOptions.forEach(opt => {
      optionsToDisplay.push(
        <MultiSelectStaticOption
          key={`${className ? `${className}-` : ''}${headerLabel ? `${headerLabel}-` : ''}single-select-static-${opt.value}`}
          option={opt}
          onSelectOption={() => onChange(opt)}
          onDeselectOption={() => onChange(undefined)}
          isSelected={selectedOption?.value !== undefined && opt.value === selectedOption?.value}
          icon={opt.prependIcon}
          legacyIconUrl={opt.legacyIconUrl}
          theme={theme}
          displaysCheckmarkIcon={true}
        />,
      );
    });
    return optionsToDisplay;
  };

  /** ***************
   * Main component *
   ******************/
  return (
    <div
      className={className}
      css={css`
        width: 20.625rem;
      `}
    >
      <SelectStaticHeaderLabel headerLabel={headerLabel} />
      <div
        className={'Options'}
        key={`${className ? `${className}-` : ''}single-select-static-options`}
        css={css`
          max-height: 30rem;
          overflow-y: auto;
          padding: 0.5rem 0 0.5rem 0;
        `}
      >
        {createVisibleOptions()}
      </div>
    </div>
  );
};
