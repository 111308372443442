import { useFlags } from 'launchdarkly-react-client-sdk';
import FeatureFlag, { featureFlags } from '~/FeatureFlagClient/FeatureFlag.gen';

export type FeatureFlags = Record<FeatureFlag, boolean>;

type UseFeatureFlagProvider = () => FeatureFlags;

export const isFeatureFlagDisabled = (featureFlag: FeatureFlag, featureFlags: FeatureFlags): boolean =>
  typeof featureFlags[featureFlag] === 'undefined' || !featureFlags[featureFlag];

export const isFeatureFlagEnabled = (featureFlag: FeatureFlag, featureFlags: FeatureFlags): boolean =>
  typeof featureFlags[featureFlag] !== 'undefined' && featureFlags[featureFlag];

const useFeatureFlagProvider: UseFeatureFlagProvider = () => {
  const allFlags = useFlags();

  return featureFlags.reduce((acc, flag) => {
    // Launch darkly returns any form of JSON, so ignore the type error and assume it is a boolean
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    acc[flag] = allFlags[flag] satisfies boolean;
    return acc;
  }, {} as FeatureFlags);
};

export default useFeatureFlagProvider;
