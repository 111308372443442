import { PropsWithChildren, useCallback, useMemo } from 'react';
import Form from '~/neo-ui/packages/form/Form';
import { TicketCreateFormDataType } from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/types/TicketCreateFormDataType';
import { TicketCreateFieldValueDto } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Dto/Model.gen';

/**
 * The Field Key for the Title within the Integration Field Values
 */
export const titlefieldKeyId = 'Title';

/**
 * The Field Key Id for the Description within the Integration Field Values
 */
export const descriptionFieldKeyId = 'InitialDescription';

export type TicketCreateFormProps = {
  onCreateTicket: (fieldValues: TicketCreateFieldValueDto[]) => void;
  defaultFormData: TicketCreateFormDataType;
};

const TicketCreateForm = ({
  onCreateTicket,
  defaultFormData: defaultTicketCreateFormData,
  children,
}: PropsWithChildren<TicketCreateFormProps>) => {
  // Submit Logic
  const onSubmit = useCallback(
    async (data: TicketCreateFormDataType) => {
      onCreateTicket(
        /**
         * Please note: we are filtering values which are empty.
         * This is to avoid sending empty values to the server.
         *
         * As well, (regarding the select input values) we need the dynamic form fields to have a default value
         * in order for the placeholder option to work.
         *
         * Otherwise, the first option will appear as selected but the form data will not reflect this.
         */
        Object.entries(data.ticketFieldValues)
          .filter(([, value]) => value !== '')
          .map(([key, value]) => ({ key, value })),
      );
    },
    [onCreateTicket],
  );

  const defaultFormData = useMemo<TicketCreateFormDataType>(() => defaultTicketCreateFormData, [defaultTicketCreateFormData]);

  return (
    <Form
      submitMethod={'manual'}
      hideSubmissionButton={true}
      hideSubmitStatusIndicator={true}
      defaultFormData={defaultFormData}
      onSubmit={onSubmit}
    >
      {children}
    </Form>
  );
};

export default TicketCreateForm;
