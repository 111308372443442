import * as React from 'react';
import { Dispatch, SetStateAction, useEffect } from 'react';
import Popover from '~/neo-ui/packages/popover/Popover';
import MultiSelectStatic from '~/neo-ui/packages/select/packages/multi-select-static/MultiSelectStatic';
import { multiSelectHeaderConsoleFormatter } from '~/neo-ui/packages/filter/packages/filter-multi-select/packages/filter-multi-select-static/MultiSelectHeaderConsoleFormatter';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { IntegrationVendorDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Controller/IntegrationPage.gen';
import { SingleSelectStatic } from '~/neo-ui/packages/select/packages/single-select-static/SingleSelectStatic';

export type SetFilterState = {
  // Key of the filter state to set
  filterKey: string;
  // function to change the state of integration page filters
  setFilterFunction: Dispatch<SetStateAction<Map<string, (integrationVendor: IntegrationVendorDto) => boolean>>>;
  filterFunction?: (selectedValues: string[]) => (integrationVendor: IntegrationVendorDto) => boolean;
};

export type SelectIntegrationPageProps = {
  name: string;
  allOptions: SelectOption[];
  filterStatePayload: SetFilterState;
  selectionType: 'any' | 'all' | 'one';
};

const SelectIntegrationPage: React.FunctionComponent<SelectIntegrationPageProps> = ({
  name,
  allOptions,
  filterStatePayload,
  selectionType,
}) => {
  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);

  const selectedOptions = allOptions.filter(so => selectedValues.includes(so.value));

  const headerCallback = multiSelectHeaderConsoleFormatter(
    name,
    'is',
    selectedOptions.map(sel => ({ key: sel.value, label: sel.label })),
    'primary',
  );

  const { filterKey, setFilterFunction, filterFunction } = filterStatePayload;

  useEffect(() => {
    if (filterFunction === undefined) {
      return;
    }
    setFilterFunction(filterStates => {
      const updatedFilterStates = new Map(filterStates);
      if (selectedValues.length === 0) {
        updatedFilterStates.set(filterKey, () => true);
        return updatedFilterStates;
      }
      updatedFilterStates.set(filterKey, filterFunction(selectedValues));
      return updatedFilterStates;
    });
  }, [selectedValues, filterFunction, filterKey, setFilterFunction]);

  return selectionType === 'one' ? (
    <Popover header={headerCallback}>
      <SingleSelectStatic
        allOptions={allOptions}
        selectedOption={selectedOptions[0]}
        onChange={s => setSelectedValues(s ? [s.value] : [])}
      />
    </Popover>
  ) : (
    <Popover header={headerCallback}>
      <MultiSelectStatic
        headerLabel={name}
        allOptions={allOptions}
        selectedOptions={selectedOptions}
        onChange={newSelection => {
          setSelectedValues(newSelection.selectedOptions ?? []);
        }}
        selectionType={selectionType}
        allSelectionTypes={[selectionType]}
      />
    </Popover>
  );
};
export default SelectIntegrationPage;
