import React, { useMemo } from 'react';
import Dropdown, { DropdownOption } from '~/neo-ui/packages/dropdown/Dropdown';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { css } from '@emotion/react';
import { RenewalOptionSellingPriceDto } from '@WarrantyClient/BeastClient/Beast/Estimate/Dto/Cart/PaginatedGet.gen';

export type WarrantyCartDeviceTableServiceColumnProps = {
  assetAccountId: string | undefined;
  options: RenewalOptionSellingPriceDto[];
};

const WarrantyCartDeviceTableServiceColumn: React.FunctionComponent<WarrantyCartDeviceTableServiceColumnProps> = ({
  assetAccountId,
  options,
}) => {
  const { getUnitSelectedPlan, setUnitSelectedPlan } = useWarrantyCartContext();

  const dropDownOptions = useMemo((): DropdownOption[] => {
    return options.map(opt => ({
      label: opt.nameFriendly,
      value: opt.planId.toString(),
    }));
  }, [options]);

  const selectedPlan = useMemo((): DropdownOption => {
    const plan = getUnitSelectedPlan(assetAccountId || '');
    return plan ? dropDownOptions.find(p => p.value === plan.toString()) ?? dropDownOptions[0] : dropDownOptions[0];
  }, [assetAccountId, dropDownOptions, getUnitSelectedPlan]);

  if (!options.length) {
    return (
      <div
        css={css`
          text-align: center;
          color: ${colorToCode('negative-400')};
        `}
      >
        Rejected by renewal provider. Blocked for purchasing
      </div>
    );
  }

  return (
    <div
      css={css`
        .dropdown-width {
          width: 14rem;
        }
      `}
    >
      <Dropdown
        options={dropDownOptions}
        selectedOption={selectedPlan}
        onOptionSelected={e => {
          setUnitSelectedPlan(assetAccountId || '', e.value, true);
        }}
        className={'dropdown-width'}
      />
    </div>
  );
};

export default WarrantyCartDeviceTableServiceColumn;
