import { BillingInfo } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import { CreditCardFormData } from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';
import DisposalAssetTypeCountFormData from '~/wm/packages/disposal/types/DisposalAssetTypeCountFormData';
import DisposalCheckoutCouponFormData from '~/wm/packages/disposal/types/DisposalCheckoutCouponFormData';
import DisposalCheckoutCertificationFormData from '~/wm/packages/disposal/types/DisposalCheckoutCertificationFormData';

export enum DisposalType {
  'Self' = 'Self',
  'Bulk' = 'Bulk',
}

type DisposalSelfCheckoutFormData = {
  assetTypeCounts: DisposalAssetTypeCountFormData[];
  packageCount: number;
  shipmentReturnAddress: BillingInfo;
  customerToken: CreditCardFormData | undefined;
  certification: DisposalCheckoutCertificationFormData;
  coupon: DisposalCheckoutCouponFormData | undefined;
  type: DisposalType.Self;
  phoneNumber: string;
  isPhoneNumberValid: boolean;
};

export default DisposalSelfCheckoutFormData;
