import { createContext } from 'react';

export type RoadmapUrlContextData = {
  insightUrl: string;
  upgradeUrl: string;
  growUrl: string;
  organizationRegionalSettingsUrl: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const RoadmapUrlContext = createContext<RoadmapUrlContextData | undefined>(undefined);

export default RoadmapUrlContext;
