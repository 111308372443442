import { useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { subscriptionBillingSetupIntentCreate } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { Response as SetupIntentCreate } from '@SubscriptionClient/Subscription/Packages/Billing/Controller/SetupIntentCreateControllerNested.gen';

const useSetupIntent = () => {
  const [isLoading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response: SetupIntentCreate | undefined = await callApi(() => subscriptionBillingSetupIntentCreate({}));
      if (!response) {
        return;
      }
      setClientSecret(response.clientSecret);
      setLoading(false);
    })();
  }, [callApi]);

  return { isLoading, clientSecret };
};

export default useSetupIntent;
