import IconType from '~/neo-ui/packages/icon/IconType.gen';
import * as React from 'react';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';

type SummarySelectionProps = {
  isSelected: boolean;
  icon: IconType;
  label: string;
  selectionCount?: number;
};

const SummarySelection: React.FunctionComponent<SummarySelectionProps> = ({ isSelected, label, icon, selectionCount }) => (
  <div
    css={css`
      display: flex;
      gap: 0.5rem;
      align-items: center;
    `}
  >
    <Icon
      icon={icon}
      sizePx={14}
      color={isSelected ? undefined : 'dark-900-24'}
    />
    {selectionCount && (
      <Label
        size={'md'}
        bold={true}
      >
        {selectionCount}
      </Label>
    )}
    <Label
      size={'md'}
      color={isSelected ? undefined : 'dark-900-24'}
    >
      {label}
    </Label>
    {isSelected && (
      <Icon
        icon={'Done'}
        sizePx={14}
        color={'positive-400'}
      />
    )}
  </div>
);

export default SummarySelection;
