import React from 'react';
import DisposalOrderInfoProvider from '~/wm/packages/disposal/packages/order-info/context/DisposalOrderInfoProvider';
import EmailProvider from '~/wm/packages/product/context/EmailProvider';
import DisposalOrderSummarySection from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-summary-section/DisposalOrderSummarySection';

type DisposalOrderInfoPageProps = {
  disposalOrderId: string;
  invoiceUrl: string;
  disposalOrderListUrl: string;
  treeImpactHelpGuideUrl: string;
  supportEmail: string;
};
const DisposalOrderInfoPage = ({
  disposalOrderId,
  invoiceUrl,
  disposalOrderListUrl,
  treeImpactHelpGuideUrl,
  supportEmail,
}: DisposalOrderInfoPageProps) => (
  <EmailProvider supportEmail={supportEmail}>
    <DisposalOrderInfoProvider disposalOrderId={disposalOrderId}>
      <DisposalOrderSummarySection
        disposalOrderListUrl={disposalOrderListUrl}
        invoiceUrl={invoiceUrl}
        treeImpactHelpGuideUrl={treeImpactHelpGuideUrl}
      />
    </DisposalOrderInfoProvider>
  </EmailProvider>
);

export default DisposalOrderInfoPage;
