import { useSyncStatus } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useSyncStatus';
import { SyncStatus } from '@AssetManagementClient/AssetManagement/Packages/Integration/Packages/Setup/Model.gen';
import { IntegrationSetupSubmitState } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useIntegrationSetupAndDefinitions';
import { SyncStatusTile } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/sync-status/SyncStatusTile';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color from '~/neo-ui/packages/color/Color.gen';
import { subSeconds } from 'date-fns';
import { formatDateTimeFriendly } from '~/extensions/packages/date/formatDate';
import pluralize from 'pluralize';

export const friendlySyncStatusDate = (date: Date): string =>
  subSeconds(Date.now(), 60) > date ? formatDateTimeFriendly(date).replace('Today', 'today').replace('Yesterday', 'yesterday') : `just now`;

const friendlySyncStatusDateFromString = (s: string) => friendlySyncStatusDate(new Date(s));

export type SyncStatusControllerProps = {
  integrationSetupId: string;
  integrationSetupSubmitState: IntegrationSetupSubmitState;
  syncStatusTileRef: React.RefObject<HTMLDivElement>;
};

export const SyncStatusController: React.FunctionComponent<SyncStatusControllerProps> = ({
  integrationSetupId,
  integrationSetupSubmitState,
  syncStatusTileRef,
}) => {
  const { syncStatus } = useSyncStatus({ integrationSetupId, integrationSetupSubmitState });
  const [title, description, iconColor]: [string | undefined, string | undefined, Color | undefined] = (() => {
    switch (syncStatus?.status) {
      case SyncStatus.Syncing:
        return [
          'Your sync has started',
          syncStatus?.syncLastCompleted
            ? `Last synced finished ${friendlySyncStatusDateFromString(syncStatus.syncLastCompleted)}`
            : undefined,
          'primary-400',
        ];
      case SyncStatus.Disabled:
        return [
          'Syncing disabled',
          `We've tried unsuccessfully to sync this integration ${syncStatus.authErrorCount} ${pluralize(
            'time',
            syncStatus.authErrorCount,
          )}. Last sync failed ${friendlySyncStatusDateFromString(syncStatus.syncLastFailed)}`,
          'negative-400',
        ];
      case SyncStatus.Failed:
        return [`Sync failed`, `Last sync failed ${friendlySyncStatusDateFromString(syncStatus.syncLastFailed)}`, 'warning-400'];
      case SyncStatus.Succeeded:
        return ['Synced', `Last sync finished ${friendlySyncStatusDateFromString(syncStatus.syncLastCompleted)}`, 'positive-400'];
      case SyncStatus.NotYetRun:
      default:
        return [undefined, undefined, undefined];
    }
  })();

  return title !== undefined && syncStatus !== undefined ? (
    <SyncStatusTile
      syncStatusTileRef={syncStatusTileRef}
      icon={(syncStatus?.icon as IconType) ?? 'Info'}
      description={description}
      title={title}
      iconColor={iconColor}
    />
  ) : null;
};
