import Header from '~/neo-ui/packages/text/packages/header/Header';
import { Styleable } from '~/neo-ui/model/capacity';
import Box from '~/neo-ui/packages/box/Box';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Button from '~/neo-ui/packages/button/Button';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import { AutoEmailReportDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';
import AutoEmailReportTestMessageButton from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-test-message-button/AutoEmailReportTestMessageButton';
import useAutoEmailReportUrlContext from '~/wm/packages/organization/packages/auto-email-report/context/hooks/useAutoEmailReportUrlContext';
import useAutoEmailReportPdfPreview from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportPdfPreview';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';

export type AutoEmailReportPreviewProps = {
  autoEmailReport: AutoEmailReportDto;
} & Styleable;

const AutoEmailReportPreview = ({ autoEmailReport, className }: AutoEmailReportPreviewProps) => {
  const { organizationId, organizationName } = useOrganizationContext();
  const { autoEmailReportEmailTemplateEditUrl } = useAutoEmailReportUrlContext();

  const { downloadAutoEmailReportPdfPreview } = useAutoEmailReportPdfPreview();

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <Header
        size={4}
        weight={'bold'}
      >
        Preview
      </Header>
      <Box
        borderRadius={'radius300'}
        boxShadow={'shadow100'}
      >
        <Box
          background={'foregroundAccent'}
          theme={'none'}
          padding={'padding300'}
          css={css`
            display: flex;
            justify-content: space-between;
            gap: 0.3125rem;
            width: 100%;
            border-radius: 0.5rem 0.5rem 0 0;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 0.3125rem;
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.25rem;
              `}
            >
              <Label bold={true}>Reply-To:</Label>
              <Label>{autoEmailReport.autoEmailReportEmailTemplatePreview.replyToDisplay}</Label>
            </div>
            <div
              css={css`
                display: flex;
                gap: 0.25rem;
              `}
            >
              <Label bold={true}>Subject: </Label>
              <Label>{autoEmailReport.autoEmailReportEmailTemplatePreview.subjectDisplay}</Label>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              flex-shrink: 0;
            `}
          >
            <Button
              iconLeft={'Edit'}
              anchor={{
                href: autoEmailReportEmailTemplateEditUrl
                  .replace('__ORGANIZATION_ID__', organizationId)
                  .replace('__AUTO_EMAIL_REPORT_ID__', autoEmailReport.autoEmailReportId),
              }}
            >
              Edit template
            </Button>
            {autoEmailReport.autoEmailReportEmailTemplatePreview.replyToUserEmailBouncing && (
              <Tooltip content={'The email you chose may not exist. If it looks correct, please contact our support team.'}>
                <Icon
                  icon={'Warning'}
                  color={'warning-400'}
                />
              </Tooltip>
            )}
          </div>
        </Box>
        <Box
          padding={'padding300'}
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.625rem;
            width: 100%;
            border-radius: 0 0 0.5rem 0.5rem;
          `}
        >
          <Label
            css={css`
              white-space: pre-line;
            `}
          >
            {autoEmailReport.autoEmailReportEmailTemplatePreview.bodyDisplay}
          </Label>
          <Button
            iconLeft={'PDF'}
            css={css`
              align-self: flex-start;
            `}
            onClick={() =>
              downloadAutoEmailReportPdfPreview({
                organizationId,
                autoEmailReportId: autoEmailReport.autoEmailReportId,
              })
            }
          >
            Preview - {organizationName}.pdf
          </Button>
        </Box>
      </Box>
      <AutoEmailReportTestMessageButton autoEmailReportId={autoEmailReport.autoEmailReportId} />
    </div>
  );
};

export default AutoEmailReportPreview;
