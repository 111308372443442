import { useContext } from 'react';
import OrganizationContext from '~/wm/packages/organization/context/OrganizationContext';

const useOrganizationContext = () => {
  const organizationContext = useContext(OrganizationContext);
  if (!organizationContext) {
    throw new Error('Tried to use OrganizationContext outside of an OrganizationProvider');
  }

  return organizationContext;
};

export default useOrganizationContext;
