import Box from '~/neo-ui/packages/box/Box';
import IntegrationSetupPageSubSection from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/page-layout/IntegrationSetupPageSubSection';
import Button from '~/neo-ui/packages/button/Button';
import { css } from '@emotion/react';
import React from 'react';
import { ButtonInternalOnClick } from '~/neo-ui/packages/button/ButtonInternal';
import Spinner from '~/neo-ui/spinner/Spinner';
import Icon from '~/neo-ui/packages/icon/Icon';

export type OauthTileProps = {
  onClick: ButtonInternalOnClick;
  isDisabled: boolean;
  status: 'lastAttemptFailed' | 'lastAttemptSuccess' | 'noLastAttempt' | 'waiting';
  title: string;
  description: string;
};

const OauthTilestatusToIcon: Record<OauthTileProps['status'], React.JSX.Element | undefined> = {
  lastAttemptFailed: (
    <Icon
      color={'negative-400'}
      icon={'Negative'}
      sizePx={28}
    />
  ),
  lastAttemptSuccess: (
    <Icon
      color={'positive-400'}
      icon={'Positive'}
      sizePx={28}
    />
  ),
  waiting: (
    <Spinner
      size={'md'}
      color={'info-400'}
    />
  ),
  noLastAttempt: undefined,
};

export const OauthTile: React.FunctionComponent<OauthTileProps> = ({ onClick, isDisabled, status, title, description }) => {
  return (
    <Box
      borderRadius={'radius300'}
      padding={'padding200'}
    >
      <IntegrationSetupPageSubSection
        description={description}
        title={title}
        iconDisplay={OauthTilestatusToIcon[status]}
      >
        {status !== 'waiting' && (
          <Button
            iconRight={'GoExternal'}
            size={'md'}
            theme={status === 'lastAttemptSuccess' ? undefined : 'primary'}
            disabled={isDisabled}
            onClick={onClick}
            css={css`
              margin-top: 1rem;
              // Override a style setting in buttonInternalStyles.ts
              > .button-children {
                width: fit-content;
              }
            `}
          >
            {status === 'lastAttemptSuccess' ? 'Authorize again' : 'Authorize ScalePad'}
          </Button>
        )}
      </IntegrationSetupPageSubSection>
    </Box>
  );
};
