import { useState, useCallback, useEffect } from 'react';
import { supportedIntegrationsList } from '@BeastClient/BeastClientWmManagement.gen';
import { SupportedIntegrationDto } from '@BeastClient/Beast/Integration/Dto/Model.gen';
import useApi from '~/wm/packages/api/hook/useApi';

export type UseSupportedIntegrationsList = () => {
  integrations: SupportedIntegrationDto[] | undefined;
};

const useSupportedIntegrationsList: UseSupportedIntegrationsList = () => {
  const { callApi } = useApi();
  const [integrations, setIntegrations] = useState<SupportedIntegrationDto[] | undefined>(undefined);

  const reload = useCallback(async () => {
    const response = await callApi(() => supportedIntegrationsList({}));

    if (!response) {
      return;
    }

    setIntegrations(response.supportedIntegrations);
  }, [callApi]);

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    integrations,
  };
};

export default useSupportedIntegrationsList;
