import * as React from 'react';
import { DisposalOrderPickupDetailsDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Dto/Model.gen';
import DisposalOrderPickupForm from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-shipment-section-schedule/packages/disposal-order-pickup-form/DisposalOrderPickupForm';
import DisposalOrderPickupScheduleSection from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-shipment-section-schedule/packages/disposal-order-pickup-schedule-section/DisposalOrderPickupScheduleSection';

export type DisposalOrderShipmentSectionScheduleProps = {
  disposalOrderId: string;
  disposalOrderPickupDetails: DisposalOrderPickupDetailsDto | undefined;
  onCancelEdit: () => void;
};

const DisposalOrderShipmentSectionSchedule = ({
  disposalOrderId,
  disposalOrderPickupDetails,
  onCancelEdit,
}: DisposalOrderShipmentSectionScheduleProps) => (
  <DisposalOrderPickupForm
    disposalOrderId={disposalOrderId}
    onCancelEdit={onCancelEdit}
    disposalOrderPickupDetails={disposalOrderPickupDetails}
  >
    <DisposalOrderPickupScheduleSection onCancelEdit={onCancelEdit} />
  </DisposalOrderPickupForm>
);

export default DisposalOrderShipmentSectionSchedule;
