import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { ErrorIcon, SuccessIcon, WarningIcon } from '~/sp-ui/icons';
import { css } from '@emotion/react';
import { IntegrationEnhanced } from '~/wm/packages/documentation/packages/supported-integrations/packages/IntegrationDocumentationUtil';

const IntegrationFeaturesDisplay = ({ integration }: { integration: IntegrationEnhanced }) => {
  let supportsTwoWaySyncText: string;
  let twoWaySyncIcon: EmotionJSX.Element;

  if (integration.integration.supportsPurchaseDateTwoWaySync && integration.integration.supportsWarrantyExpiryDateTwoWaySync) {
    supportsTwoWaySyncText = 'Supports two-way sync';
    twoWaySyncIcon = <SuccessIcon />;
  } else if (integration.integration.supportsPurchaseDateTwoWaySync) {
    supportsTwoWaySyncText = 'Supports two-way sync only for purchase date';
    twoWaySyncIcon = <WarningIcon />;
  } else if (integration.integration.supportsWarrantyExpiryDateTwoWaySync) {
    supportsTwoWaySyncText = 'Supports two-way sync only for warranty expiry date';
    twoWaySyncIcon = <WarningIcon />;
  } else {
    supportsTwoWaySyncText = 'Does not support two-way sync';
    twoWaySyncIcon = <ErrorIcon />;
  }

  const supportsSoftwareText = integration.supportsSoftware ? 'Supports software' : 'Does not support software';
  const supportsSoftwareIcon = integration.supportsSoftware ? <SuccessIcon /> : <ErrorIcon />;

  const supportsMultipleSetupsText = integration.integration.supportsMultipleSetups
    ? 'Supports multiple setups'
    : 'Does not support multiple setups';
  const multipleSetupsIcon = integration.integration.supportsMultipleSetups ? <SuccessIcon /> : <ErrorIcon />;

  const supportsContactDeviceLinkText = integration.supportsContactDeviceLink
    ? 'Supports Assigned Person field'
    : 'Does not support Assigned Person field';
  const supportsContactDeviceLinkIcon = integration.supportsContactDeviceLink ? <SuccessIcon /> : <ErrorIcon />;

  return (
    <ul
      css={css`
        list-style-type: none;
        padding: 0;

        li {
          margin-bottom: 0.5rem;
        }
      `}
    >
      {integration.integration.isDevMode && (
        <li>
          <WarningIcon />
          &nbsp;
          <span
            css={css`
              vertical-align: middle;
              font-weight: bold;
            `}
          >
            This integration is in BETA
          </span>
        </li>
      )}
      <li>
        {supportsSoftwareIcon}&nbsp;
        <span
          css={css`
            vertical-align: middle;
            font-weight: bold;
          `}
        >
          {supportsSoftwareText}
        </span>
      </li>
      <li>
        {twoWaySyncIcon}&nbsp;
        <span
          css={css`
            vertical-align: middle;
            font-weight: bold;
          `}
        >
          {supportsTwoWaySyncText}
        </span>
      </li>
      <li>
        {multipleSetupsIcon}&nbsp;
        <span
          css={css`
            vertical-align: middle;
            font-weight: bold;
          `}
        >
          {supportsMultipleSetupsText}
        </span>
      </li>
      <li>
        {supportsContactDeviceLinkIcon}&nbsp;
        <span
          css={css`
            vertical-align: middle;
            font-weight: bold;
          `}
        >
          {supportsContactDeviceLinkText}
        </span>
      </li>
    </ul>
  );
};

export default IntegrationFeaturesDisplay;
