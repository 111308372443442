import { PresentationalDeviceTypeCount } from '@AssetManagementClient/BeastClient/Beast/Asset/Controller/Device/Stats/ScopedDeviceTypeStatsNested.gen';
import { css } from '@emotion/react';
import React from 'react';
import List from '~/legacy-ui/packages/list/List';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import TileHorizontalListItem from '~/neo-ui/packages/tile/packages/horizontal/packages/list-item/TileHorizontalListItem';

export type AssetCountListProps = {
  assetCounts: PresentationalDeviceTypeCount[];
  total: number;
  urlTemplate: string;
  hasAccessToNewConsole: boolean;
};

const generateAssetTypeParameters = (hasAccessToNewConsole: boolean, parameter: string) =>
  // VERY BAD HACK - We're hardcoding information for how the url encodes the selection type. This will break in the future
  // whenever someone changes how SelectionType or Url Encoding works.
  hasAccessToNewConsole ? `Type=EqualTo:${parameter};SelectionType:any;` : `type=${parameter};`;

const AssetCountList: React.FunctionComponent<AssetCountListProps> = ({ assetCounts, total, urlTemplate, hasAccessToNewConsole }) => (
  <List
    items={assetCounts}
    renderItem={(assetCount: PresentationalDeviceTypeCount, index: number) => (
      <TileHorizontalListItem
        icon={assetCount.icon as IconType}
        title={assetCount.label}
        number={assetCount.count}
        onClick={() => {
          window.location.href = `${urlTemplate}?${generateAssetTypeParameters(hasAccessToNewConsole, assetCount.urlParameter)}`;
        }}
        key={index}
      />
    )}
    gap={'0.625rem'}
  >
    <TileHorizontalListItem
      icon="DeviceAll"
      title="Assets"
      color="dark-900"
      number={total}
      onClick={() => {
        window.location.href = urlTemplate;
      }}
      iconSizePx={18}
      labelFontSize={'1.125rem'}
      numberFontSize={'1.125rem'}
      css={css`
        margin-bottom: 0.3125rem;
      `}
    />
  </List>
);

export default AssetCountList;
