import * as React from 'react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import useDisposalOrderPdfs from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderPdfs';
import Box from '~/neo-ui/packages/box/Box';
import useDisposalOrderInfoContext from '../../../../context/hooks/useDisposalOrderInfoContext';
import Spinner from '~/neo-ui/spinner/Spinner';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderType/Model/DisposalOrderTypeFactoryNested.gen';
import TextBlock from '~/neo-ui/packages/text/packages/text-block/TextBlock';
import getDisposalAssetTypeCountBreakdown from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-summary-section/packages/disposal-order-summary-bulk-section/getDisposalAssetTypeCountBreakdown';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color from '~/neo-ui/packages/color/Color.gen';
import { formatCurrencyByCurrencyInfo } from '~/extensions/packages/currency/formatCurrency';
import DisposalOrderSummarySectionAssetOrganizationAssignModule from '../disposal-order-summary-section-asset-organization-assign-module/DisposalOrderSummarySectionAssetOrganizationAssignModule';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';

export type DisposalOrderSummarySectionBulkProps = {
  disposalOrderListUrl: string;
  invoiceUrl?: string;
  disposalAssetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[];
};

const DisposalOrderSummaryBulkSection = ({
  disposalOrderListUrl,
  invoiceUrl,
  disposalAssetTypeAvailabilities,
}: DisposalOrderSummarySectionBulkProps) => {
  const { disposalOrderSummary } = useDisposalOrderInfoContext();
  const { downloadDisposalOrderPdfs } = useDisposalOrderPdfs();

  if (typeof disposalOrderSummary === 'undefined') {
    return <Spinner />;
  }

  if (disposalOrderSummary.orderType !== Enum.ItadBulk) {
    throw new Error('Tried to render a summary which was not a Bulk Disposal Order.');
  }

  const isDisposalOrderComplete = typeof disposalOrderSummary.completedAt !== 'undefined';

  const assetTypeCountBreakdown = getDisposalAssetTypeCountBreakdown(
    disposalOrderSummary.assetTypeAvailabilities,
    disposalOrderSummary.assetTypeCounts,
  );

  return (
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
          `}
        >
          <Header
            size={4}
            weight={'bold'}
            color={'dark-700'}
          >
            {!isDisposalOrderComplete ? 'Your order has been placed' : 'Disposal of this order is complete'}
          </Header>
          {!isDisposalOrderComplete && (
            <Label
              color={'dark-900-64'}
              size={'md'}
            >
              Once these assets have been securely disposed of, you’ll be able to download Certificates of Disposal.
            </Label>
          )}
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
            `}
          >
            <TextBlock
              icon={disposalOrderSummary.status.displayIcon as IconType}
              iconColor={disposalOrderSummary.status.displayColor as Color}
              title={`Full Service: ${disposalOrderSummary.status.displayLabel}`}
              titleColor={disposalOrderSummary.status.displayColor as Color}
              description={
                <React.Fragment>
                  Order <b>{disposalOrderSummary.disposalOrderId}</b> placed{' '}
                  {formatDate(new Date(`${disposalOrderSummary.createdAt}`), {
                    format: 'MMM dd, yyyy',
                    timezone: TimezoneFormat.Local,
                  })}
                  {disposalOrderSummary.hasError && (
                    <Label color={'negative-400'}>
                      There was a problem processing your order, and we’re working to fix it. For more information, please contact support.
                    </Label>
                  )}
                </React.Fragment>
              }
            />
            <Button
              css={css`
                margin-left: auto;
              `}
              iconRight={'GoRight'}
              anchor={{ href: disposalOrderListUrl }}
            >
              All orders
            </Button>
          </div>

          {isDisposalOrderComplete && (
            <div
              css={css`
                display: flex;
                flex-direction: row;
                align-items: center;
              `}
            >
              <TextBlock
                icon={'Certificate'}
                iconColor={'dark-700'}
                title={'Certificates of Disposal'}
                titleColor={'dark-700'}
                description={`${disposalOrderSummary.amountOfUniqueOrganizations} client${
                  disposalOrderSummary.amountOfUniqueOrganizations === 1 ? '' : 's'
                }`}
              />
              <Button
                css={css`
                  margin-left: auto;
                `}
                iconLeft={'PageZip'}
                onClick={() => {
                  downloadDisposalOrderPdfs(disposalOrderSummary.disposalOrderId);
                }}
              >
                Download ZIP ({disposalOrderSummary.amountOfUniqueOrganizations})
              </Button>
            </div>
          )}

          <TextBlock
            icon={'Asset'}
            iconColor={'dark-700'}
            title={!isDisposalOrderComplete ? 'Assets to be disposed of' : 'Assets securely disposed of'}
            titleColor={'dark-700'}
            description={assetTypeCountBreakdown}
          />

          {isDisposalOrderComplete && disposalOrderSummary.unidentifiedAssetCount > 0 && (
            <DisposalOrderSummarySectionAssetOrganizationAssignModule
              disposalOrderId={disposalOrderSummary.disposalOrderId}
              assetCountUnidentified={disposalOrderSummary.unidentifiedAssetCount}
              completedAt={disposalOrderSummary.completedAt!}
              disposalAssetTypeAvailabilities={disposalAssetTypeAvailabilities}
            />
          )}
        </div>

        {typeof invoiceUrl !== 'undefined' && typeof disposalOrderSummary.invoiceNumber !== 'undefined' && (
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
            `}
          >
            <TextBlock
              icon={'Invoice'}
              iconColor={'dark-700'}
              title={'Invoiced amount'}
              titleColor={'dark-700'}
              description={`USD
                      ${formatCurrencyByCurrencyInfo(disposalOrderSummary.costSubunits, {
                        code: 'usd',
                        subunitsPerUnit: 100,
                      })}`}
            />

            <Button
              css={css`
                margin-left: auto;
              `}
              size={'sm'}
              iconLeft={'InvoiceDownload'}
              onClick={() => {
                window.open(invoiceUrl.replace('__INVOICE_ID__', disposalOrderSummary.invoiceNumber ?? ''), '_blank');
              }}
            >
              Download Invoice
            </Button>
          </div>
        )}
      </div>
    </Box>
  );
};

export default DisposalOrderSummaryBulkSection;
