import { createContext } from 'react';

export type EngagementActionDataCollectionContextData = {
  createdFrom: string;
  completedFrom: string;
  updatedFrom: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const EngagementActionDataCollectionContext = createContext<EngagementActionDataCollectionContextData | undefined>(undefined);

export default EngagementActionDataCollectionContext;
