import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import useInitiativeAssetMoveBulk from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/hooks/useInitiativeAssetMoveBulk';
import useRoadmapInitiativesContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapInitiativesContext';
import InitiativeListSelectModal from '~/wm/packages/strategy/packages/initiative/packages/initiative-list-select-modal/InitiativeListSelectModal';

export type InitiativeSaveChangeButtonProps = {
  initiativeId?: string;
  initiativeAssetIds: string[];
  onUpdateAssets: () => void;
  disabled?: boolean;
};

const InitiativeSaveChangeButton = ({
  initiativeId,
  initiativeAssetIds,
  onUpdateAssets,
  disabled = false,
}: InitiativeSaveChangeButtonProps) => {
  const [isDisplayingModal, setDisplayModal] = React.useState(false);

  const { initiativesByFiscalQuarter } = useRoadmapInitiativesContext();
  const initiatives = Array.from(initiativesByFiscalQuarter.values())
    .flat()
    .filter(initiative => initiative.initiativeId !== initiativeId);

  const { moveAssets, isMovingAssets } = useInitiativeAssetMoveBulk({
    onSuccess: () => {
      onUpdateAssets();
    },
  });

  if (typeof initiatives === 'undefined') {
    return null;
  }

  return (
    <React.Fragment>
      <Button
        iconLeft={'InitiativeAdd'}
        disabled={disabled}
        onClick={() => setDisplayModal(true)}
      >
        Change Initiative…
      </Button>
      <InitiativeListSelectModal
        initiativeSelectOptions={initiatives}
        title={'Move selected assets'}
        description={'Transfer these assets to an existing or new Initiative.'}
        isLoading={isMovingAssets}
        isOpen={isDisplayingModal}
        onNewInitiativeSave={initiativeTemplate =>
          moveAssets({
            sourceInitiativeId: initiativeId,
            destinationInitiativeId: undefined,
            initiativeTemplateId: initiativeTemplate?.initiativeTemplateId,
            assetIds: initiativeAssetIds,
          })
        }
        onExistingInitiativeSave={destinationInitiativeId =>
          moveAssets({
            sourceInitiativeId: initiativeId,
            destinationInitiativeId,
            initiativeTemplateId: undefined,
            assetIds: initiativeAssetIds,
          })
        }
        onDismiss={() => setDisplayModal(false)}
        allowRedirect={false}
      />
    </React.Fragment>
  );
};

export default InitiativeSaveChangeButton;
