import { parseISO } from 'date-fns';
import * as React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import useDisposalOrderBulkPickupAvailabilitySchedule from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderBulkPickupAvailabilitySchedule';
import disposalOrderBulkPickupAvailabilityScheduleFormValidationSchema from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-bulk-pickup-section/packages/disposal-order-bulk-pickup-section-availability-schedule/packages/disposal-order-bulk-availability-schedule-form/validation/disposalOrderBulkPickupAvailabilityScheduleFormValidationSchema';

export type DisposalOrderBulkAvailabilityScheduleFormData = {
  /**
   * Site contact name
   */
  siteContactName: string;
  /**
   * Site contact phone number
   */
  siteContactPhone: string;
  /**
   * Site contact email
   */
  siteContactEmail: string;
  /**
   * We will use the date portion of the date for the starting date
   **/
  startingDate: Date;
  /**
   * We will use the date portion of the date for the ending date
   **/
  endingDate: Date;
  /**
   * We will use the time portion of the date for the starting time
   **/
  startingTime: string;
  /**
   * We will use the time portion of the date for the ending time
   **/
  endingTime: string;
  /**
   * Any extra details that the partner would like to communicate
   **/
  notes: string;
};

export type DisposalOrderBulkAvailabilityScheduleFormProps = {
  disposalOrderId: string;
  children: React.ReactNode;
};

const DisposalOrderBulkAvailabilityScheduleForm = ({ disposalOrderId, children }: DisposalOrderBulkAvailabilityScheduleFormProps) => {
  const { schedulePickup } = useDisposalOrderBulkPickupAvailabilitySchedule();

  const onSubmit = React.useCallback(
    async (data: DisposalOrderBulkAvailabilityScheduleFormData) => {
      const endingDate = parseISO(data.endingDate.toString());
      const startingDate = parseISO(data.startingDate.toString());
      const startingTimeSplit = data.startingTime.split(':');
      const endingTimeSplit = data.endingTime.split(':');

      const startDateTimeString = new Date(
        startingDate.getFullYear(),
        startingDate.getMonth(),
        startingDate.getDate(),
        parseInt(startingTimeSplit[0], 10),
        parseInt(startingTimeSplit[1], 10),
      ).toISOString();

      const endDateTimeString = new Date(
        endingDate.getFullYear(),
        endingDate.getMonth(),
        endingDate.getDate(),
        parseInt(endingTimeSplit[0], 10),
        parseInt(endingTimeSplit[1], 10),
      ).toISOString();

      schedulePickup({
        disposalOrderId,
        availabilitySchedulePayload: {
          disposalOrderId,
          siteContact: {
            name: data.siteContactName,
            email: data.siteContactEmail,
            phoneNumber: data.siteContactPhone,
          },
          pickupNotes: data.notes,
          requestedDateRange: {
            start: startDateTimeString,
            end: endDateTimeString,
          },
        },
      });
    },
    [disposalOrderId, schedulePickup],
  );

  const toFormData = React.useCallback(
    (): DisposalOrderBulkAvailabilityScheduleFormData => ({
      siteContactName: '',
      siteContactPhone: '',
      siteContactEmail: '',
      startingDate: '' as unknown as Date,
      endingDate: '' as unknown as Date,
      startingTime: '',
      endingTime: '',
      notes: '',
    }),
    [],
  );

  const defaultFormData = React.useMemo(() => toFormData(), [toFormData]);

  return (
    <Form
      submitMethod={'manual'}
      defaultFormData={defaultFormData}
      onSubmit={onSubmit}
      disableSubmitOnEnter={true}
      disableOverwriteDefaultFormDataOnSave={true}
      hideSubmissionButton={true}
      validationSchema={disposalOrderBulkPickupAvailabilityScheduleFormValidationSchema}
    >
      {children}
    </Form>
  );
};

export default DisposalOrderBulkAvailabilityScheduleForm;
