import React from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import Anchor from '~/neo-ui/packages/anchor/Anchor';
import routes from '~/router/types/routes';
import { useNavigate } from 'react-router-dom';
import { Enum as IntegrationVendor } from '@AssetManagementClient/SyncGod/Shared/Integration/IntegrationFactoryNested.gen';

export type TicketIntegrationRequiredModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

const TicketIntegrationRequiredModal = ({ isOpen, onDismiss }: TicketIntegrationRequiredModalProps) => {
  const navigate = useNavigate();

  return (
    <ModalConfirmation
      isOpen={isOpen}
      theme={'primary'}
      header={{
        title: 'PSA Integration Required',
      }}
      footer={{
        confirmationButtonAlignment: 'left',
        dismissButton: {
          label: 'Close',
          icon: 'ActionClose',
          onClick: onDismiss,
        },
        confirmButton: {
          label: 'Integrate ConnectWise',
          onClick: () => navigate(routes.INTEGRATION_ADD.build([IntegrationVendor.ConnectWiseManage])),
        },
      }}
    >
      <Label>
        ConnectWise Manage is currently the only supported integration. Please connect your ConnectWise Manage to proceed. If you're using a
        different PSA system, submit a feature request on our{' '}
        <Anchor
          href={routes.EXTERNAL_PRODUCT_COMMUNITY.build()}
          shouldOpenInNewTab={true}
        >
          SP Community platform
        </Anchor>{' '}
        to express your interest in future integrations.
      </Label>
    </ModalConfirmation>
  );
};

export default TicketIntegrationRequiredModal;
