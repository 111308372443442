import React from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import useIntegrationSetupDelete from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/delete/hooks/useIntegrationSetupDelete';
import routes from '~/router/types/routes';
import { ModalConfirmationFooterButtonProps } from '~/neo-ui/packages/modal/packages/modal-confirmation/packages/modal-confirmation-footer/ModalConfirmationFooter';

export type IntegrationSetupDeleteModalProps = {
  integrationSetupId: string;
  isOpen: boolean;
  onDismiss: () => void;
  onDelete: () => void;
};

const IntegrationSetupDeleteModal: React.FunctionComponent<IntegrationSetupDeleteModalProps> = ({
  integrationSetupId,
  isOpen,
  onDismiss,
}) => {
  const { deleteIntegrationSetup, isDeletingIntegrationSetup, isDeleted, hasError, errorMessage, newIntegrationRequired } =
    useIntegrationSetupDelete(integrationSetupId);
  const title = !hasError ? 'Delete this integration?' : 'You can’t delete this integration';
  const icon = !hasError ? 'Trash' : 'Negative';
  const description = !hasError
    ? 'Your customized sync settings and data organization for this integration will also be deleted.'
    : errorMessage;
  const onConfirm = !hasError
    ? () => deleteIntegrationSetup(false)
    : newIntegrationRequired
    ? () => (window.location.href = routes.ACCOUNT_INTEGRATION_ADD_INDEX.build())
    : () => undefined;
  const confirmButton: ModalConfirmationFooterButtonProps | undefined = !hasError
    ? {
        icon: 'Trash',
        label: 'Delete',
        loading: isDeletingIntegrationSetup,
        disabled: isDeletingIntegrationSetup,
        onClick: onConfirm,
      }
    : newIntegrationRequired
    ? {
        icon: 'Add',
        label: 'Add integration',
        loading: isDeletingIntegrationSetup,
        disabled: isDeletingIntegrationSetup,
        theme: 'primary',
        onClick: onConfirm,
      }
    : undefined;

  return (
    <ModalConfirmation
      header={{ title, icon }}
      theme={'negative'}
      footer={{
        confirmButton,
        dismissButton: {
          icon: 'Cancel',
          label: 'Cancel',
          disabled: isDeletingIntegrationSetup,
          onClick: onDismiss,
        },
      }}
      isOpen={isOpen && !isDeleted}
    >
      {description}
    </ModalConfirmation>
  );
};

export default IntegrationSetupDeleteModal;
