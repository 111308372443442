import { BillingInfo } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import { CreditCardFormData } from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';
import DisposalAssetTypeCountFormData from '~/wm/packages/disposal/types/DisposalAssetTypeCountFormData';
import DisposalCheckoutCouponFormData from '~/wm/packages/disposal/types/DisposalCheckoutCouponFormData';
import { DisposalCheckoutBulkPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Packages/DisposalCheckoutBulk/Dto/Model.gen';
import { DisposalCheckoutSiteInformationFormData } from '~/wm/packages/disposal/types/DisposalBulkCheckoutFormData';

const buildDisposalBulkCheckoutPayloadDto = (
  billingInfo: BillingInfo,
  assetTypeCounts: DisposalAssetTypeCountFormData[],
  customerToken: CreditCardFormData | undefined,
  coupon: DisposalCheckoutCouponFormData | undefined,
  materials: string[],
  siteInformation: DisposalCheckoutSiteInformationFormData,
  phoneNumber: string,
): DisposalCheckoutBulkPayloadDto => ({
  pickupMaterials: materials,
  siteInformation: {
    addressFrom: {
      address: {
        city: billingInfo.billingAddress?.address.city ?? '',
        countryCode: billingInfo.billingAddress?.address.countryCode ?? '',
        countryFallback: billingInfo.billingAddress?.address.countryFallback ?? '',
        line1: billingInfo.billingAddress?.address.line1 ?? '',
        line2: billingInfo.billingAddress?.address.line2 ?? '',
        zip: billingInfo.billingAddress?.address.zip ?? '',
        stateCode: billingInfo.billingAddress?.address.stateCode ?? '',
        stateFallback: billingInfo.billingAddress?.address.stateFallback ?? '',
      },
      name: billingInfo.billingAddress?.name ?? '',
      phone: phoneNumber ?? '',
    },
    assetLocation: siteInformation.assetLocation,
    hasLoadingDock: siteInformation.hasLoadingDock,
    isMultipleFloors: siteInformation.isMultipleFloors,
    isAccessibleByElevator: siteInformation.isAccessibleByElevator,
  },
  assetCountsPayloads: assetTypeCounts.map(assetTypeCount => ({
    disposalAssetType: assetTypeCount.type,
    count: Number(assetTypeCount.count),
    label: assetTypeCount.customizedType,
  })),
  customerPayload: (() => {
    if (typeof customerToken === 'undefined') {
      return undefined;
    }

    switch (customerToken.type) {
      case 'card-on-file':
        return undefined;
      case 'new-card':
        return {
          defaultPaymentMethod: {
            token: customerToken.tokenId!,
          },
        };
    }
  })(),
  couponCode: typeof coupon !== 'undefined' ? coupon.code : undefined,
});

export default buildDisposalBulkCheckoutPayloadDto;
