import { css } from '@emotion/react';
import * as React from 'react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import NotificationCard from '~/wm/packages/notification/packages/notification-card/NotificationCard';
import { NotificationListModel } from '~/wm/packages/notification/hooks/useNotificationList';

export type NotificationListProps = {
  /**
   * Array of user's notifications
   */
  notifications: NotificationListModel;
  /**
   * Trigger when a notification is mark as read
   */
  onMarkAsRead: (notificationId: string) => void;
};

const NotificationList: React.FunctionComponent<NotificationListProps> = ({ notifications, onMarkAsRead }) => {
  const heightRem = 40;
  const widthRem = 26.25;

  return (
    <div
      css={css`
        max-height: ${heightRem}rem;
        width: ${widthRem}rem;

        @media screen and (max-width: 500px) {
          width: 19.6875rem;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.625rem;
          margin-left: 0.625rem;
          margin-right: 0.625rem;
        `}
      >
        <Header size={3}>Notifications</Header>
        {notifications.countUnread > 0 ? (
          <Header
            size={3}
            color={'primary-400'}
          >
            {notifications.countUnread} unread
          </Header>
        ) : (
          <Header
            size={3}
            color={'dark-900-32'}
          >
            {notifications.count}
          </Header>
        )}
      </div>
      <div
        css={css`
          overflow-y: auto;
          word-break: break-word;

          // Header and padding takes 4.375rem of the component's height
          max-height: ${heightRem - 4.375}rem;

          // Small screens need to force a shorter notification window due to bootstrap's tooltip
          @media screen and (max-height: 700px) {
            max-height: 18.75rem;
          }

          border-radius: 0.625rem;
        `}
      >
        {notifications.items.map(notification => (
          <NotificationCard
            key={notification.notificationUniqueId}
            notification={notification}
            onMarkAsRead={onMarkAsRead}
            css={css`
              margin-top: 0.3125rem;
            `}
          />
        ))}
      </div>
    </div>
  );
};

export default NotificationList;
