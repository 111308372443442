import mapBackendFieldKey from '~/neo-ui/packages/form/packages/form-action/packages/form-validation/mapBackendFieldKey';
import { resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { showAlertAction } from '~/legacy-ui/packages/alert/state/action/alertActions';
import apiErrorAction from '~/wm/packages/api/packages/api-error/state/apiErrorAction';
import { css } from '@emotion/react';
import * as React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import { useDispatch } from 'react-redux';
import { WizardPageProps } from '~/neo-ui/packages/wizard/packages/wizard-page/WizardPage';
import { ToolbarControl } from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import WizardPageFooter from '~/neo-ui/packages/wizard/packages/wizard-page/packages/wizard-page-footer/WizardPageFooter';
import WizardPageProgress from '~/neo-ui/packages/wizard/packages/wizard-page/packages/wizard-page-progress/WizardPageProgress';
import WizardPageStepContent from '~/neo-ui/packages/wizard/packages/wizard-page/packages/wizard-page-step-content/WizardPageStepContent';
import WizardPageProgressControl from '~/neo-ui/packages/wizard/packages/wizard-page/packages/wizard-page-footer/packages/wizard-page-progress-control/WizardPageProgressControl';
import WizardPageSubmitControl from '~/neo-ui/packages/wizard/packages/wizard-page/packages/wizard-page-footer/packages/wizard-page-submit-control/WizardPageSubmitControl';
import useWizardPageContext from '~/neo-ui/packages/wizard/packages/wizard-page/context/hooks/useWizardPageContext';

export type WizardPageContentProps<TFormData, TSuccessData> = {
  actionToolbarControl?: ToolbarControl;
  incompleteFields: Set<string>;
  currentStepIndex: number;
} & WizardPageProps<TFormData, TSuccessData>;

const WizardPageContent = <TFormData extends object, TSuccessData>({
  defaultFormData,
  steps,
  AdditionalComponents,
  SummaryComponent,
  SuccessComponent,
  submitLabel,
  submitIcon = 'Cart',
  onSubmit,
  disabledOptions = {
    submit: false,
    progress: false,
  },
  actionToolbarControl,
  incompleteFields,
  currentStepIndex,
  className,
  onPageProgress = () => {},
}: WizardPageContentProps<TFormData, TSuccessData>) => {
  const dispatch = useDispatch();
  const { setCurrentStepIndex } = useWizardPageContext();

  // Indexes of all steps that have been completed at least once
  const [completedStepIndexes, setCompletedStepIndexes] = React.useState(new Set<number>());
  // Is a section of the current step in editing mode
  const [isCurrentStepEditing, setIsCurrentStepEditing] = React.useState(false);

  const [successData, setSuccessData] = React.useState<TSuccessData | undefined>(undefined);

  const isOnLastStep = currentStepIndex === steps.length - 1;

  const currentStep = currentStepIndex < steps.length ? steps[currentStepIndex] : undefined;

  const isCurrentStepComplete =
    currentStep && !isCurrentStepEditing && !currentStep.fields.some(fieldKey => incompleteFields.has(resolveFieldKey(fieldKey)));

  const nextStep = currentStepIndex + 1 < steps.length ? steps[currentStepIndex + 1] : undefined;

  const updateStepState = (stepIndex: number) => {
    if (stepIndex > Math.max(...completedStepIndexes)) {
      setCompletedStepIndexes(prev => new Set([...prev, currentStepIndex]));
    }
    setCurrentStepIndex(stepIndex);
  };

  const proceedControl: ToolbarControl = WizardPageProgressControl({
    isCurrentStepComplete,
    nextStep,
    onClick: () => {
      updateStepState(currentStepIndex + 1);
      if (typeof currentStep !== 'undefined') {
        onPageProgress(currentStep.key);
      }
    },
  });

  const submitControl: ToolbarControl = WizardPageSubmitControl({
    submitLabel,
    submitIcon,
    isCurrentStepComplete,
    isDisabled: disabledOptions.submit,
  });

  return (
    <Form
      className={className}
      submitMethod={'manual'}
      defaultFormData={defaultFormData}
      hideSubmissionButton={true}
      validationErrorMapper={fieldKey => {
        let mappedFieldKey = fieldKey;

        // Go over and process field maps for each step
        for (let validationErrorMapper of steps.map(step => step.validationErrorMapper)) {
          validationErrorMapper = validationErrorMapper ?? mapBackendFieldKey;
          mappedFieldKey = validationErrorMapper(mappedFieldKey);
        }

        return mappedFieldKey;
      }}
      onValidationError={({ globalMessage, fieldErrors }) => {
        // If a step contains a field error, goto it

        // Create set of affected fields for O(1) access
        const fieldKeysWithErrors = new Set<string>(fieldErrors.map(fieldError => resolveFieldKey(fieldError.fieldKey)));

        // Find a step with a field error
        let stepFound;
        for (const step of steps) {
          if (step.fields.some(field => fieldKeysWithErrors.has(resolveFieldKey(field)))) {
            stepFound = step;
            break;
          }
        }

        if (stepFound) {
          // Go to the step
          setCurrentStepIndex(steps.indexOf(stepFound));
        }

        if (fieldErrors.length > 0 && !stepFound) {
          // Couldn't recognize field error, send a generic error to compensate
          if (globalMessage) {
            dispatch(
              showAlertAction({
                children: globalMessage,
                theme: 'danger',
              }),
            );
          } else {
            // Generic global error
            dispatch(apiErrorAction('validation-error'));
          }
        }
      }}
      onSubmit={async formData => {
        if (!isOnLastStep) {
          // Can't submit form prior to summary page.
          // This guard helps prevent accidental submits.
          return;
        }

        const result = await onSubmit(formData);
        if (typeof result !== 'undefined') {
          setSuccessData(result);
        }
      }}
      disableSubmitOnEnter={true}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.625rem;
        `}
      >
        <WizardPageProgress
          currentStepIndex={successData ? steps.length : currentStepIndex}
          steps={steps}
          completedStepIndexes={completedStepIndexes}
          isCurrentStepCompleted={isCurrentStepComplete}
          onClick={updateStepState}
          isDisabled={disabledOptions?.progress}
        />
        {AdditionalComponents &&
          AdditionalComponents.filter(component => component.position === 'top').map(component => component.component)}
        {successData ? (
          <SuccessComponent postSubmitPayload={successData} />
        ) : (
          <WizardPageStepContent
            sections={steps[currentStepIndex].sections}
            onEditing={setIsCurrentStepEditing}
            isDisabled={disabledOptions.progress}
          />
        )}
        {AdditionalComponents &&
          AdditionalComponents.filter(component => component.position === 'bottom').map(component => component.component)}
      </div>
      {!successData && !disabledOptions?.progress && (
        <WizardPageFooter
          actionToolbarControl={actionToolbarControl}
          rightControls={!isOnLastStep ? [proceedControl] : []}
          leftControls={isOnLastStep ? [submitControl] : []}
          SummaryComponent={SummaryComponent}
        />
      )}
    </Form>
  );
};

export default WizardPageContent;
