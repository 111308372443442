import { css, SerializedStyles } from '@emotion/react';
import { FieldArray } from 'formik';
import React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import FormArrayErrorMessage from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormArrayErrorMessage';
import InteractiveItem from '~/neo-ui/packages/interactive-item/InteractiveItem';
import { FieldKeyExpression, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';

export type FormArrayProps<TFormData, TFormDataValue> = {
  /**
   * The FieldKey that references an array from the form
   */
  fieldKey: FieldKeyExpression<TFormData>;

  /**
   * The initial value (by const or method) which provides the
   * initial values for a newly added element (via the addButton or addComponent)
   */
  initialValue?: (() => TFormDataValue) | TFormDataValue;

  /**
   * A method which returns the key for an element/object created in the FormArray
   */
  arrayKey: (element: TFormDataValue, index: number) => string;

  /**
   * Funtion which yields the component/element to render
   */
  children: (element: TFormDataValue, index: number) => React.ReactNode;

  /**
   * the method that gets invoked when an item is removed from the Form Array
   */
  onRemove?: (element: TFormDataValue) => void;

  /**
   * The text to use for the default add button
   */
  addButtonText: string;

  /**
   * Css to apply to the container holding the form inputs
   */
  formInputContainerCss?: SerializedStyles;

  /**
   * boolean to assign padding and align-items (flex) to baseline
   */
  shouldAlignBottom?: boolean;

  /**
   * A component that replaces the Add button.
   *
   * This can be used in the case of a custom component that performs some other
   * behaviour that the current add button does not cover.
   */
  addComponent?: React.ReactNode;
} & Styleable;

const FormArray = <TFormData, TFormDataValue>({
  fieldKey,
  initialValue,
  addButtonText,
  arrayKey,
  children,
  className,
  shouldAlignBottom = false,
  formInputContainerCss,
  addComponent,
  onRemove,
}: FormArrayProps<TFormData, TFormDataValue>) => {
  const { getFormInput } = useFormContext<TFormData>();
  return (
    <FieldArray
      render={arrayHelpers => (
        <div className={className}>
          <div css={formInputContainerCss}>
            <FormArrayErrorMessage fieldKey={fieldKey} />
            {getFormInput<TFormDataValue[]>(fieldKey).value.map((element, index) => (
              <InteractiveItem
                key={arrayKey(element, index)}
                css={css`
                  width: 100%;
                  margin: 0.5rem 0;
                  padding-top: 0.5rem;
                  padding-bottom: 0.5rem;
                  ${!shouldAlignBottom && 'align-items: baseline;'}
                `}
                modifierButtonProps={{
                  icon: 'Remove',
                  onClick: () => {
                    arrayHelpers.remove(index);
                    if (typeof onRemove !== 'undefined') {
                      onRemove(element);
                    }
                  },
                }}
              >
                <div
                  css={css`
                    flex-grow: 1;
                  `}
                >
                  {children(element, index)}
                </div>
              </InteractiveItem>
            ))}
          </div>
          {typeof addComponent !== 'undefined' ? (
            addComponent
          ) : (
            <InteractiveItem
              modifierButtonProps={{
                icon: 'Add',
                theme: 'primary',
              }}
              onClick={() => arrayHelpers.push(initialValue instanceof Function ? initialValue() : initialValue)}
            >
              <div>
                <Label
                  color={'primary-400'}
                  heightSize={'md'}
                >
                  {addButtonText}
                </Label>
              </div>
            </InteractiveItem>
          )}
        </div>
      )}
      name={resolveFieldKey(fieldKey)}
    />
  );
};

export default FormArray;
