import ConsoleRenderBadgeFormatter from '~/neo-ui/packages/table/packages/console/packages/filter/ConsoleRenderBadgeFormatter';
import { Filter } from '~/neo-ui/packages/table/packages/console/types';
import { css } from '@emotion/react';
import { Fragment } from 'react';

export type FilterBadgeBarProps = {
  filters: Filter[];
  parameters: Map<string, Map<string, string[]>>;
};

const FilterBadgeBar = ({ filters, parameters }: FilterBadgeBarProps) => (
  <div
    css={css`
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    `}
  >
    {filters.map(filter => (
      <Fragment key={filter.key}>{ConsoleRenderBadgeFormatter(filter, parameters)}</Fragment>
    ))}
  </div>
);

export default FilterBadgeBar;
