import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import React from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import { InitiativeOverviewDashboardDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Theme, { themeToColor } from '~/neo-ui/packages/color/Theme';
import { Currency, formatCurrencyByCurrencyInfo } from '~/extensions/packages/currency/formatCurrency';
import useInitiativeDashboardContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/initiative-dashboard/context/hooks/useInitiativeDashboardContext';
import useInitiativeStatusUpdate from '~/wm/packages/strategy/packages/initiative/hooks/useInitiativeStatusUpdate';
import useStrategyDashboardUrlContext from '~/wm/packages/strategy/packages/strategy-dashboard/context/hooks/useStrategyDashboardUrlContext';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useInitiativeDashboardMutationContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/initiative-dashboard/context/hooks/useInitiativeDashboardMutationContext';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Box from '~/neo-ui/packages/box/Box';
import InitiativeTicketDisplay from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-ticket-display/InitiativeTicketDisplay';
import InitiativeStatusDropdown from '~/wm/packages/strategy/packages/initiative/packages/initiative-status-dropdown/InitiativeStatusDropdown';

export type InitiativeDashboardOverviewProps = {
  initiative: InitiativeOverviewDashboardDto;
  enabledInitiativeTickets: boolean;
};

const lineClampAmount: number = 2;

type TotalFees = {
  total: string;
  oneTimeFeesTotal: string;
  recurringFeeAnnualTotal: string;
  recurringFeeMonthlyTotal: string;
};

const getFormattedCostSuffix = (type: string): string => {
  switch (type) {
    case 'Annual':
      return ' / year';
    case 'Monthly':
      return ' / month';
    default:
      return '';
  }
};

const InitiativeDashboardOverview: React.FunctionComponent<InitiativeDashboardOverviewProps> = ({
  initiative,
  enabledInitiativeTickets,
}) => {
  const { initiatives, onFilterStatusUpdate } = useInitiativeDashboardContext();

  const currency: Currency = {
    code: initiative.budgetOverview.budgetCurrency.codeAlpha,
    subunitsPerUnit: initiative.budgetOverview.budgetCurrency.subunitRatio,
  };

  const formattedTotalFees: TotalFees = {
    total: `${formatCurrencyByCurrencyInfo(initiative.budgetOverview.costSubunitsTotal, currency)}${getFormattedCostSuffix(
      initiative.budgetOverview.totalType,
    )}`,
    oneTimeFeesTotal: formatCurrencyByCurrencyInfo(initiative.budgetOverview.costSubunitsOneTimeFeesTotal, currency),
    recurringFeeAnnualTotal: formatCurrencyByCurrencyInfo(initiative.budgetOverview.costSubunitsAnnualTotal, currency),
    recurringFeeMonthlyTotal: formatCurrencyByCurrencyInfo(initiative.budgetOverview.costSubunitsMonthlyTotal, currency),
  };

  const containsOtherFees =
    [
      initiative.budgetOverview.costSubunitsAnnualTotal,
      initiative.budgetOverview.costSubunitsMonthlyTotal,
      initiative.budgetOverview.costSubunitsOneTimeFeesTotal,
    ].filter(fee => fee > 0).length > 1;

  const { triggerInitiativeDashboardReload } = useInitiativeDashboardMutationContext();
  const { roadmapUrl } = useStrategyDashboardUrlContext();
  const { organizationId } = useOrganizationContext();
  const { updateStatus, isUpdating } = useInitiativeStatusUpdate();
  const { statusAvailabilities } = useInitiativeDashboardContext();

  return (
    <Box
      borderRadius={'radius400'}
      padding={'padding200'}
      boxShadow={'shadow100'}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
        `}
      >
        <InitiativeStatusDropdown
          initiativeStatusAvailabilities={statusAvailabilities}
          selectedStatus={initiative.status}
          onStatusChange={status => {
            updateStatus(initiative.initiativeId, organizationId, status, () => {
              if (initiatives.length === 1) {
                onFilterStatusUpdate(status);
              }

              triggerInitiativeDashboardReload();
            });
          }}
          size={'xs'}
          disabled={isUpdating}
          buttonCss={css`
            width: 7.25rem;
          `}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          `}
        >
          <Header
            size={4}
            weight={'bold'}
          >
            {initiative.title}
          </Header>
          <Label
            css={css`
              display: -webkit-box;
              -webkit-line-clamp: ${lineClampAmount};
              -webkit-box-orient: vertical;
              overflow: hidden;
            `}
            color={'dark-900-64'}
          >
            {typeof initiative.executiveSummary !== 'undefined' ? (
              initiative.executiveSummary
            ) : (
              <div
                css={css`
                  height: ${1.3125 * lineClampAmount}rem;
                `}
              />
            )}
          </Label>
        </div>
        <div
          css={css`
            margin-top: auto;
            display: flex;
            align-items: center;
            gap: 2rem;
            white-space: nowrap;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              white-space: nowrap;
              gap: 0.5rem;
            `}
          >
            <Label
              size={'md'}
              bold={true}
            >
              {formattedTotalFees.total}
            </Label>
            {containsOtherFees && (
              <Tooltip
                backgroundColor={'light-000'}
                content={
                  <div>
                    {initiative.budgetOverview.costSubunitsOneTimeFeesTotal > 0 && (
                      <Label size={'sm'}>{formattedTotalFees.oneTimeFeesTotal} one-time fees</Label>
                    )}
                    {initiative.budgetOverview.costSubunitsMonthlyTotal > 0 && (
                      <Label size={'sm'}>{formattedTotalFees.recurringFeeMonthlyTotal} monthly recurring fees</Label>
                    )}
                    {initiative.budgetOverview.costSubunitsAnnualTotal > 0 && (
                      <Label size={'sm'}>{formattedTotalFees.recurringFeeAnnualTotal} annually recurring fees</Label>
                    )}
                  </div>
                }
              >
                <Icon
                  icon={'Info'}
                  sizePx={12}
                  css={css`
                    display: flex;
                    margin-right: 0.25rem;
                    margin-top: -0.05125rem;
                  `}
                />
              </Tooltip>
            )}
          </div>
          <Icon
            icon={initiative.priority.icon as IconType}
            color={themeToColor(initiative.priority.theme as Theme, '400')}
            sizePx={16}
          />
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.5rem;
            `}
          >
            <Icon
              icon={'Asset'}
              color={'dark-700'}
              sizePx={16}
            />
            <Label
              size={'md'}
              bold={true}
            >
              {initiative.amountOfAssets} assets
            </Label>
          </div>
          {typeof initiative.ticketLinkState !== 'undefined' && enabledInitiativeTickets && (
            <InitiativeTicketDisplay ticketLinkState={initiative.ticketLinkState} />
          )}
          <ButtonLink
            size={'sm'}
            iconLeft={'Roadmap'}
            iconRight={'ArrowRight'}
            css={css`
              margin-left: auto;
            `}
            anchor={{
              href: `${roadmapUrl.replace('__ORGANIZATION_ID__', organizationId)}#${initiative.initiativeId}`,
            }}
          >
            View On Roadmap
          </ButtonLink>
        </div>
      </div>
    </Box>
  );
};

export default InitiativeDashboardOverview;
