import { ScopeKey } from '@AssetManagementClient/BeastClient/Beast/Settings/Scope.gen';
import React from 'react';
import HardwareSettings from '~/wm/packages/hardware/packages/settings/HardwareSettings';
import Organization from '~/wm/packages/organization/model/Organization';
import OrganizationLock from '~/wm/packages/organization/packages/organization-lock/OrganizationLock';

export type OrganizationHardwareSettingsPageProps = {
  organization: Organization;
  appSettingsUrl: string;
  currencyCharacter: string;
  hardwareConfigCriteriaReleased: boolean;
  lmBudgetForecastEnabled: boolean;
  lmLastCheckinDateEnabled: boolean;
};

const OrganizationHardwareSettingsPage: React.FunctionComponent<OrganizationHardwareSettingsPageProps> = ({
  organization,
  appSettingsUrl,
  currencyCharacter,
  hardwareConfigCriteriaReleased,
  lmBudgetForecastEnabled,
  lmLastCheckinDateEnabled,
}) => {
  const scopeKey: ScopeKey = { id: 'Organization', value: organization.id };

  return (
    <OrganizationLock organizationIds={[organization.id]}>
      <HardwareSettings
        currencyCharacter={currencyCharacter}
        scopeKey={scopeKey}
        appSettingsUrl={appSettingsUrl}
        hardwareConfigCriteriaReleased={hardwareConfigCriteriaReleased}
        lmBudgetForecastEnabled={lmBudgetForecastEnabled}
        lmLastCheckinDateEnabled={lmLastCheckinDateEnabled}
      />
    </OrganizationLock>
  );
};

export default OrganizationHardwareSettingsPage;
