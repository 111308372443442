import { ScorecardDto, ScorecardOverviewDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Dto/Model.gen';
import { createContext } from 'react';
import { OpenScorecardProps } from '~/wm/packages/strategy/packages/scorecard/context/ScorecardProvider';

export type ScorecardContextData = {
  organizationName: string;
  scorecard: ScorecardDto;
  scorecardList: ScorecardOverviewDto[];
  openScorecard: OpenScorecardProps;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ScorecardContext = createContext<ScorecardContextData | undefined>(undefined);

export default ScorecardContext;
