import Button from '~/neo-ui/packages/button/Button';
import tooltipStyles from '~/neo-ui/packages/tooltip/styles/tooltipStyles';
import InitiativeEngagementActionLinkModal from '~/wm/packages/strategy/packages/initiative/packages/initiative-engagement-action-link-modal/InitiativeEngagementActionLinkModal';
import * as React from 'react';
import { useState } from 'react';
import { InitiativeTemplateDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/InitiativeTemplate/Dto/Model.gen';
import Size from '~/neo-ui/model/Size';

export type EngagementActionInitiativeLinkCreateButtonProps = {
  size: Exclude<Size, 'xl'>;
  onClick?: () => void;
  onLinkExistingInitiative: (initiativeId: string) => void;
  onLinkNewInitiative: (selectedTemplate: InitiativeTemplateDto | undefined) => void;
};

const EngagementActionInitiativeLinkCreateButton = ({
  size,
  onClick,
  onLinkExistingInitiative,
  onLinkNewInitiative,
}: EngagementActionInitiativeLinkCreateButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  return (
    <div>
      <Button
        size={size}
        iconLeft={'Initiative'}
        theme={isSaved ? 'primary' : undefined}
        preventOnClickPropagation={true}
        css={tooltipStyles('Create Initiative link')}
        onClick={() => {
          setIsOpen(true);
          if (typeof onClick !== 'undefined') {
            onClick();
          }
        }}
      />
      {isOpen && (
        <InitiativeEngagementActionLinkModal
          isOpen={isOpen}
          isLoading={false}
          onDismiss={() => {
            setIsOpen(false);
          }}
          onExistingInitiativeSave={initiativeId => {
            setIsSaved(true);
            onLinkExistingInitiative(initiativeId);
          }}
          onNewInitiativeSave={selectedTemplate => {
            setIsSaved(true);
            onLinkNewInitiative(selectedTemplate);
          }}
        />
      )}
    </div>
  );
};

export default EngagementActionInitiativeLinkCreateButton;
