import Button from '~/neo-ui/packages/button/Button';
import Window from '~/neo-ui/packages/window/Window';
import * as React from 'react';
import { EngagementNoteDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Dto/Model.gen';
import Preformatted from '~/neo-ui/packages/text/packages/preformatted/Preformatted';

export type EngagementNoteArchivedViewWindowProps = {
  note: EngagementNoteDto;
  isOpen: boolean;
  onDismiss: () => void;
  isUnarchivingNote: boolean;
  onNoteUnarchive: (engagementNoteId: string) => void;
};

const EngagementNoteArchivedViewWindow = ({
  note,
  isOpen,
  onDismiss,
  isUnarchivingNote,
  onNoteUnarchive,
}: EngagementNoteArchivedViewWindowProps) => (
  <Window
    title={typeof note.title !== 'undefined' ? note.title : 'Untitled Note'}
    isOpen={isOpen}
    onDismiss={onDismiss}
    footerProps={{
      rightControls: [
        {
          expanded: (
            <Button
              iconLeft={'Archive'}
              onClick={() => {
                onNoteUnarchive(note.engagementNoteId);
              }}
              loading={isUnarchivingNote}
            >
              Unarchive This Note
            </Button>
          ),
        },
      ],
    }}
  >
    <Preformatted text={note.description} />
  </Window>
);

export default EngagementNoteArchivedViewWindow;
