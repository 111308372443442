import * as React from 'react';
import { ReactNode } from 'react';
import Form from '~/neo-ui/packages/form/Form';
import autoEmailReportFormValidationSchema from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/validation/autoEmailReportFormValidationSchema';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import { AutoEmailReportDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';
import useAutoEmailReportEdit from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-edit/hooks/useAutoEmailReportEdit';
import AutoEmailReportFormData, {
  buildAutoEmailFormReportQuery,
  generateColumnKeys,
} from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/types/AutoEmailReportFormData';
import { convertToMapNestedArrays } from '~/extensions/packages/iterable/keyedNestedArrayConverter';
import useAutoEmailReportListContext from '~/wm/packages/organization/packages/auto-email-report/context/hooks/useAutoEmailReportListContext';
import { ColumnAvailabilityDto } from '@AssetManagementClient/BeastClient/Search/Model/Console/Dto.gen';

export type AutoEmailReportEditFormProps = {
  autoEmailReport: AutoEmailReportDto;
  onAutoEmailReportEdit: () => void;
  children: ReactNode;
};

const AutoEmailReportEditForm = ({ autoEmailReport, onAutoEmailReportEdit, children }: AutoEmailReportEditFormProps) => {
  const { organizationId } = useOrganizationContext();
  const { autoEmailReportAvailability } = useAutoEmailReportListContext();
  const { editAutoEmailReport } = useAutoEmailReportEdit({
    onSuccess: onAutoEmailReportEdit,
  });

  const onSubmit = React.useCallback(
    async (data: AutoEmailReportFormData, isRequestActive: () => boolean) =>
      editAutoEmailReport(
        {
          autoEmailReportId: autoEmailReport.autoEmailReportId,
          organizationId,
          autoEmailReportViewUpdatePayload: {
            autoEmailReportTemplateId: data.autoEmailReportTemplateId,
            title: data.title,
            reportQuery: buildAutoEmailFormReportQuery(
              data.consoleState,
              Object.keys(data.columnKeys).filter(key => data.columnKeys[key]),
            ),
            saveAsTemplate: data.saveAsTemplate,
          },
        },
        isRequestActive,
      ),
    [autoEmailReport.autoEmailReportId, editAutoEmailReport, organizationId],
  );

  const defaultFormData = React.useMemo<AutoEmailReportFormData>(
    () => ({
      title: autoEmailReport.title,
      consoleState: {
        search: undefined,
        pagination: { perPageSize: 0, pageNumber: 0 },
        parameters: convertToMapNestedArrays(autoEmailReport.reportQuery.parameters),
        sort:
          autoEmailReport.reportQuery.sort.length === 0
            ? undefined
            : {
                key: { value: autoEmailReport.reportQuery.sort[0].field.value },
                order: 'ascending',
              },
      },
      columnKeys: generateColumnKeys(
        autoEmailReportAvailability.columns
          .filter(column => column.availability !== ColumnAvailabilityDto.Mandatory)
          .map(column => column.key.value),
        false,
        autoEmailReport.reportQuery.selectedColumns?.map(column => column.value) ?? [],
      ),
      autoEmailReportTemplateId: undefined,
      saveAsTemplate: false,
    }),
    [
      autoEmailReport.reportQuery.parameters,
      autoEmailReport.reportQuery.selectedColumns,
      autoEmailReport.reportQuery.sort,
      autoEmailReport.title,
      autoEmailReportAvailability.columns,
    ],
  );

  return (
    <Form
      submitMethod={'manual'}
      hideSubmissionButton={true}
      hideSubmitStatusIndicator={true}
      defaultFormData={defaultFormData}
      validationSchema={autoEmailReportFormValidationSchema}
      onSubmit={onSubmit}
    >
      {children}
    </Form>
  );
};

export default AutoEmailReportEditForm;
