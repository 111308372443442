import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import ToolTip from '~/sp-ui/tool-tip';

export type ItemSelectionFooterProps = {
  subject: 'plan' | 'addon';
  ctaLabel: string;
  ctaUrl: string;
  termLength: number;
  activationFee: number;
};
const ItemSelectionFooter = (props: ItemSelectionFooterProps) => {
  const { subject, ctaLabel, ctaUrl, termLength, activationFee } = props;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        margin-bottom: 6rem;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
          border: 0.063rem solid ${colorToCode('light-400')};
          background-color: ${colorToCode('light-100')};
          padding: 1rem;
          width: 100%;
          margin-bottom: 1rem;
        `}
      >
        <div
          css={css`
            background-color: ${colorToCode('dark-050')};
            height: 1.563rem;
            width: 1.563rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <Icon
            color={'light-000'}
            icon={'Phone_sp_account'}
          />
        </div>
        <span
          css={css`
            padding: 0 0.5rem;
          `}
        >
          Need help choosing {subject === 'plan' ? 'a' : 'an'} {subject}? Let’s have a chat to find the best {subject} for you.{' '}
          <a href={ctaUrl}>
            <span
              css={css`
                text-decoration: underline;
              `}
            >
              {ctaLabel}
            </span>
          </a>
        </span>
      </div>
      {activationFee > 0 ? (
        <div
          css={css`
            color: ${colorToCode('dark-100')};
            display: flex;
          `}
        >
          <span>*All prices are listed in USD, for a {termLength}-month term, and before taxes and one time</span>
          <ToolTip
            content={
              <p css={{ maxWidth: '15rem', fontSize: '14px' }}>
                {
                  'Includes 3 one-on-one onboarding/consulting sessions with a dedicated Partner Development Representative to ensure a successful experience with our product.'
                }
              </p>
            }
          >
            {({ anchorProps, setAnchorRef }) => {
              return (
                <span
                  css={css`
                    text-decoration: underline;
                    margin-left: 0.25rem;
                    margin-right: 0.25rem;
                    cursor: pointer;
                  `}
                  ref={setAnchorRef}
                  {...anchorProps}
                >
                  activation
                </span>
              );
            }}
          </ToolTip>
          <span>({formatCurrency(activationFee, 'USD', 2)})</span>
        </div>
      ) : (
        <div
          css={css`
            color: ${colorToCode('dark-100')};
            display: flex;
          `}
        >
          <span>*All prices are listed in USD, for a 12-month term, and before taxes.</span>
        </div>
      )}
    </div>
  );
};

export default ItemSelectionFooter;
