import { SettingsValueNested } from '@AssetManagementClient/BeastClient/Beast/Settings/Model.gen';
import { Enum } from '@AssetManagementClient/BeastClient/Goods/DeviceType/DeviceTypeFactoryNested.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import { camelToPascal } from '~/extensions/packages/casing/camelPascalConversion';
import Card from '~/legacy-ui/packages/card/Card';
import FormTextInput from '~/neo-ui/packages/form/packages/form-input/packages/form-text-input/FormTextInput';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import RecommendationCriterion, {
  RecommendationCriterionProps,
} from '~/wm/packages/hardware/packages/settings/packages/recommendation-criterion/RecommendationCriterion';
import Icon from '~/neo-ui/packages/icon/Icon';

export type HardwareReplacementCardProps = {
  replacementValueFieldKey: FieldKeyExpression<SettingsValueNested.HardwareReplacementSettings>;
  currencyCharacter: string;
  hardwareType: Enum;

  availableCriteria: RecommendationCriterionProps[];
};

const HardwareReplacementCard: React.FunctionComponent<HardwareReplacementCardProps> = ({
  replacementValueFieldKey,
  currencyCharacter,
  hardwareType,
  availableCriteria,
}) => (
  <div
    css={css`
      margin-top: 0.625rem;
    `}
  >
    <Card backgroundColor={'secondary-050'}>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 0.9375rem;
        `}
      >
        <Icon
          icon={hardwareTypeToDisplayDetails(hardwareType).hardwareTypeIcon}
          sizePx={16}
        />
        <Header
          css={css`
            margin-left: 0.625rem;
          `}
          size={4}
        >
          {hardwareTypeToDisplayDetails(hardwareType).hardwareTypeTitle}
        </Header>
      </div>
      <div
        css={css`
          margin-bottom: 1.25rem;
        `}
      >
        <p
          css={css`
            font-weight: bold;
            margin-bottom: 0.625rem;
          `}
        >
          Value
        </p>
        <FormTextInput
          fieldKey={replacementValueFieldKey}
          placeholder="Money value"
          prependCharacter={currencyCharacter}
        />
      </div>
      <p
        css={css`
          font-weight: bold;
          margin-bottom: 0.625rem;
        `}
      >
        Recommendation criteria
      </p>
      {availableCriteria.map(criterion => (
        <div
          key={criterion.title}
          css={css`
            > * {
              margin-bottom: 0.875rem;
            }
          `}
        >
          <RecommendationCriterion
            recommendationValueFieldKey={criterion.recommendationValueFieldKey}
            icon={criterion.icon}
            title={criterion.title}
            description={criterion.description}
            type={criterion.type}
            thresholdLabel={criterion.thresholdLabel}
            thresholdUnit={criterion.thresholdUnit}
            selectOptions={criterion.selectOptions}
            css={css`
              flex-grow: 1;
            `}
          />
        </div>
      ))}
    </Card>
  </div>
);

type HardwareTypeDisplayDefinition = {
  hardwareTypeTitle: string;
  hardwareTypeIcon: IconType;
};

const hardwareTypeToDisplayDetails = (status: Enum): HardwareTypeDisplayDefinition => {
  // Because of camelization it breaks these enums.
  // TODO https://warrantymaster.myjetbrains.com/youtrack/issue/WM-1555
  switch (camelToPascal(status) as Enum) {
    case Enum.Server:
      return {
        hardwareTypeTitle: 'Servers',
        hardwareTypeIcon: 'DeviceServer',
      };
    case Enum.Workstation:
      return {
        hardwareTypeTitle: 'Workstations',
        hardwareTypeIcon: 'DeviceWorkstation',
      };
    case Enum.Imaging:
      return {
        hardwareTypeTitle: 'Imaging',
        hardwareTypeIcon: 'DeviceImaging',
      };
    case Enum.Mobile:
      return {
        hardwareTypeTitle: 'Mobiles',
        hardwareTypeIcon: 'DeviceMobile',
      };
    case Enum.Network:
      return {
        hardwareTypeTitle: 'Networking',
        hardwareTypeIcon: 'DeviceNetwork',
      };
    case Enum.Storage:
      return {
        hardwareTypeTitle: 'Storage',
        hardwareTypeIcon: 'Storage',
      };
    case Enum.Virtual:
      return {
        hardwareTypeTitle: 'VMs',
        hardwareTypeIcon: 'DeviceVirtualMachine',
      };
  }
};

export default HardwareReplacementCard;
