import { css } from '@emotion/react';
import * as React from 'react';
import Info from '~/legacy-ui/packages/info/Info';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type SkuItemProps = {
  header: string;
  title: string;
  titleColor: Color;
  titleStrikethrough?: boolean;
  description: string;
  descriptionColor?: Color;
  footer?: string;
  className?: string;
};
const SkuItem = ({
  header,
  title,
  titleColor,
  titleStrikethrough = false,
  description,
  descriptionColor,
  footer,
  className,
}: SkuItemProps) => (
  <div className={className}>
    <span
      css={css`
        color: ${colorToCode('dark-900-32')};
        font-size: 0.75rem;
      `}
    >
      {header}
    </span>
    <Info
      title={title}
      titleSize={'lg'}
      titleColor={titleColor}
      titleStrikethrough={titleStrikethrough}
      descriptionColor={descriptionColor}
      description={description}
    >
      {footer && (
        <span
          css={css`
            color: ${colorToCode('dark-900-32')};
            font-size: 0.75rem;
          `}
        >
          {footer}
        </span>
      )}
    </Info>
  </div>
);

export default SkuItem;
