import PlanInclusion from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-selection/packages/plan-inclusion/PlanInclusion';
import PlanCardGrid from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-selection/packages/plan-card-grid/PlanCardGrid';
import { css } from '@emotion/react';
import useSelfUpgradeViewModelGet from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-selection/hooks/useSelfUpgradeViewModelGet';
import Spinner from '~/neo-ui/spinner/Spinner';
import { useEffect } from 'react';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import useAddonSelectionGet from '~/wm/packages/subscription/packages/purchase-subscription/packages/addon-selection/hooks/useAddonSelectionGet';

export type PlanSelectionProps = {
  preselectProduct: string;
};

const PlanSelection = ({ preselectProduct }: PlanSelectionProps) => {
  const { isLoading, selfUpgradeViewModelGetDto } = useSelfUpgradeViewModelGet(preselectProduct);
  const { formik } = useMultiStepForm<PurchaseSubscriptionForm>();
  const { addonSelection } = useAddonSelectionGet(
    selfUpgradeViewModelGetDto.data?.subscriptionPlanId ?? '',
    formik.values.selectedPlanLimit ?? formik.values.defaultPlanLimit ?? 1,
  );
  const { setFieldValue } = formik;

  useEffect(() => {
    // To update plan-related fields used by the container
    const isGrow = selfUpgradeViewModelGetDto.data?.isGrow ?? false;
    setFieldValue('isGrow', isGrow, false);
    setFieldValue('productLogoUrl', selfUpgradeViewModelGetDto.data?.logoUrl, false);
    setFieldValue('productLogoLabel', selfUpgradeViewModelGetDto.data?.logoLabel, false);
    setFieldValue('termLength', selfUpgradeViewModelGetDto.data?.termInMonth ?? 12, false);
    setFieldValue('subscriptionPlanId', selfUpgradeViewModelGetDto.data?.subscriptionPlanId, false);
    if (isGrow) {
      setFieldValue('defaultPlanLimit', selfUpgradeViewModelGetDto.data?.cards[0].planLimit ?? 0, false);
      if (formik.values.planSubtotal === 0) {
        // Only set field on initial load since we don't want to override the value when navigating back to plan selection
        setFieldValue('planSubtotal', selfUpgradeViewModelGetDto.data?.cards[0].amount ?? 0, false);
      }
    }
  }, [
    formik.values.planSubtotal,
    selfUpgradeViewModelGetDto.data?.cards,
    selfUpgradeViewModelGetDto.data?.isGrow,
    selfUpgradeViewModelGetDto.data?.logoLabel,
    selfUpgradeViewModelGetDto.data?.logoUrl,
    selfUpgradeViewModelGetDto.data?.subscriptionPlanId,
    selfUpgradeViewModelGetDto.data?.termInMonth,
    setFieldValue,
  ]);

  useEffect(() => {
    // To update addon-related fields used by the container
    setFieldValue('hasAddonSelectionStep', addonSelection?.showSelectionPage ?? false, false);

    // Price of owned addons needs to be calculated on page load to avoid surprises if partner goes straight to checkout.
    const ownedAddons = addonSelection?.addonOptions.filter(option => {
      return option.isOwned;
    });
    const ownedAddonSubtotal = ownedAddons?.map(addon => addon.price).reduce((a: number, b: number) => a + b, 0) ?? 0;

    const selectedAddons = addonSelection?.addonOptions.filter(option => {
      return formik.values.selectedAddonIds.includes(option.addonId);
    });
    const selectedAddonTotal = selectedAddons?.map(addon => addon.price).reduce((a: number, b: number) => a + b, 0) ?? 0;
    setFieldValue('addonSubtotal', ownedAddonSubtotal + selectedAddonTotal, false);
    setFieldValue('ownedAddonIds', ownedAddons?.map(addon => addon.addonId) ?? [], false);
  }, [
    addonSelection?.addonOptions,
    addonSelection?.showSelectionPage,
    formik.values.addonSubtotal,
    formik.values.selectedAddonIds,
    setFieldValue,
  ]);

  if (!selfUpgradeViewModelGetDto.data || isLoading) {
    return (
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </div>
    );
  }
  const { inclusions, cards, additionalInformation } = selfUpgradeViewModelGetDto.data;

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
      `}
    >
      <div
        css={css`
          max-width: 75.25rem;
          width: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <span
            css={css`
              font-size: 2.25rem;
              font-style: normal;
              font-weight: 400;
              line-height: 133%;
              margin-bottom: 2.063rem;
            `}
          >
            Select your plan
          </span>
          <div
            css={css`
              margin-bottom: 2rem;
            `}
          >
            <PlanInclusion inclusions={inclusions} />
          </div>
          {additionalInformation && (
            <span
              css={css`
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 2rem;
              `}
            >
              {additionalInformation}
            </span>
          )}
        </div>
        <div
          css={css`
            margin-bottom: 2.688rem;
          `}
        >
          <PlanCardGrid cards={cards} />
        </div>
      </div>
    </div>
  );
};

export default PlanSelection;
