import React from 'react';
import EmptyStateInfo from '~/neo-ui/packages/empty-state-info/EmptyStateInfo';
import { PdContactSectionProps } from '~/wm/packages/subscription/packages/partner-development/packages/pd-contact-info/packages/pd-contact-section/PdContactSection';

export interface SubscriptionExpiredProps {
  children: React.ReactElement<PdContactSectionProps>;
}

const SubscriptionOfferExpired: React.FunctionComponent<SubscriptionExpiredProps> = ({ children }) => (
  <React.Fragment>
    <EmptyStateInfo
      imageSource={'/i/graphic/account/coupon/warning.png'}
      imageWidth={'8.125rem'}
      title={'Your custom offer has expired'}
      description={''}
      titleColor={'warning-400'}
    />
    {children}
  </React.Fragment>
);

export default SubscriptionOfferExpired;
