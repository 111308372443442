import { ScorecardItemDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardItem/Dto/Model.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import { DropResult } from '@hello-pangea/dnd';
import { DragDropZone, DropZone } from '~/neo-ui/packages/drag-and-drop/DragAndDrop';
import useScorecardItemOrderUpdate from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/hooks/useScorecardItemOrderUpdate';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ScorecardItemsDropZoneProps = {
  scorecardId: string;
  scorecardCategoryId?: string;
  scorecardItems: ScorecardItemDto[];
  onReorder: (scorecardItems: ScorecardItemDto[]) => void;
  children: React.ReactNode;
};

const ScorecardItemsDropZone = ({ scorecardId, scorecardCategoryId, scorecardItems, onReorder, children }: ScorecardItemsDropZoneProps) => {
  const { updateScorecardItemOrder } = useScorecardItemOrderUpdate(scorecardId, scorecardCategoryId);

  const onDragEnd = (result: DropResult) => {
    // Do nothing if not moved or dropped to same source
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    if (result.destination) {
      const orderedScorecardItems = [...scorecardItems];
      const item = scorecardItems.find(item => item.scorecardItemId === result.draggableId)!;
      orderedScorecardItems.splice(result.source.index, 1);
      orderedScorecardItems.splice(result.destination.index, 0, item);

      // Format the data
      const orderedScorecardItemIds = orderedScorecardItems.map(scorecardItem => scorecardItem.scorecardItemId);

      updateScorecardItemOrder(orderedScorecardItemIds);
      onReorder(orderedScorecardItems);
    }
  };
  return (
    <DragDropZone onDragEnd={onDragEnd}>
      <DropZone
        droppableId={`1`}
        containerCssStates={{
          hoverCss: css`
            background-color: ${colorToCode('secondary-050')} !important;
          `,
        }}
      >
        {children}
      </DropZone>
    </DragDropZone>
  );
};

export default ScorecardItemsDropZone;
