import { OrganizationKeyContactDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationKeyContact/Dto/Model.gen';
import useOrganizationKeyContactRemove from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/useOrganizationKeyContactRemove';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import React from 'react';

export type OrganizationKeyContactRemoveWindowProps = {
  organizationKeyContact: OrganizationKeyContactDto;
  isOpen: boolean;
  onDismiss: () => void;
};

const OrganizationKeyContactRemoveModal = ({ organizationKeyContact, isOpen, onDismiss }: OrganizationKeyContactRemoveWindowProps) => {
  const { isRemovingOrganizationKeyContact, removeOrganizationKeyContact } = useOrganizationKeyContactRemove({ onSuccess: onDismiss });
  const { organizationId } = useOrganizationContext();

  return (
    <ModalConfirmation
      header={{
        title: 'Remove key contact from this client?',
        icon: 'Trash',
      }}
      isOpen={isOpen}
      theme={'negative'}
      footer={{
        confirmButton: {
          icon: 'Trash',
          label: 'Remove',
          loading: isRemovingOrganizationKeyContact,
          onClick: () => {
            removeOrganizationKeyContact({
              organizationId,
              organizationContactId: organizationKeyContact.organizationKeyContactId,
            });
          },
        },
        dismissButton: {
          icon: 'Cancel',
          label: 'Close',
          disabled: isRemovingOrganizationKeyContact,
          onClick: onDismiss,
        },
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 1rem;
            align-items: center;
          `}
        >
          <Icon icon={'Administrator'} />
          <Label bold={true}>{organizationKeyContact.fullName}</Label>
        </div>

        <div
          css={css`
            display: flex;
            gap: 1rem;
            align-items: center;
          `}
        >
          <Icon
            color={'dark-900-64'}
            icon={'Email'}
          />
          <Label color={'dark-900-64'}>{organizationKeyContact.email}</Label>
        </div>
      </div>
    </ModalConfirmation>
  );
};

export default OrganizationKeyContactRemoveModal;
