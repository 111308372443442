import * as React from 'react';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import PopoverWithPopup from '~/neo-ui/packages/popover/PopoverWithPopup';
import SelectOptionGroup from '~/neo-ui/packages/select/model/SelectOptionGroup';
import Icon from '~/neo-ui/packages/icon/Icon';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import useDownloadContractListPdf from '~/wm/packages/strategy/packages/contract/packages/contract-list/hooks/useDownloadContractListPdf';
import SelectList from '~/neo-ui/packages/list/packages/select-list/SelectList';
import useDownloadContractListSpreadsheet from '~/wm/packages/strategy/packages/contract/packages/contract-list/hooks/useDownloadContractListSpreadsheet';
import useDownloadContractListCsv from '~/wm/packages/strategy/packages/contract/packages/contract-list/hooks/useDownloadContractListCsv';

export type ContractListShareButtonProps = {
  organizationId: string;
  organizationName: string;
};

type DownloadAction = 'download-xlsx' | 'download-csv' | 'download-pdf';

const ContractListDownloadButton = ({ organizationId, organizationName }: ContractListShareButtonProps) => {
  const { downloadContractListPdf } = useDownloadContractListPdf();
  const { downloadContractListSpreadsheet } = useDownloadContractListSpreadsheet();
  const { downloadContractListCsv } = useDownloadContractListCsv();

  const downloadOptions = [
    {
      options: [
        {
          value: 'download-xlsx',
          label: 'Excel spreadsheet',
          labelColor: 'positive-700',
          prependIcon: 'XLSX',
          iconSizePx: 22,
        },
      ],
    },
    {
      options: [
        {
          value: 'download-csv',
          label: 'CSV file',
          labelColor: 'secondary-400',
          prependIcon: 'CSV',
          iconSizePx: 22,
        },
      ],
    },
    {
      options: [
        {
          value: 'download-pdf',
          label: 'PDF file',
          labelColor: 'negative-700',
          prependIcon: 'PDF',
          iconSizePx: 22,
        },
      ],
    },
  ] as SelectOptionGroup<DownloadAction>[];

  return (
    <PopoverWithPopup
      header={
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 5px;
          `}
        >
          <span>Download...</span>
          <Icon
            icon={'Download'}
            color={'dark-900'}
          />
        </div>
      }
      disabledTooltipContent={'Upgrade to download'}
      popoverAlign={'left-end'}
      css={css`
        width: clamp(12.5rem, 29.375rem, 90vw);
      `}
    >
      {(closeModal: () => void) => (
        <div
          css={css`
            padding: 0.625rem 0.75rem;
          `}
        >
          <Label
            className={'download-header'}
            size={'xlg'}
            color={'secondary-400'}
            bold={true}
            css={css`
              padding-bottom: 0.3125rem;
            `}
          >
            Download
          </Label>
          <Label
            muted={true}
            css={css`
              margin-bottom: 10px;
            `}
          >
            Export all contracts for {organizationName} in one of the following file formats.
          </Label>
          <SelectList
            options={downloadOptions}
            selectedOptionValue={undefined}
            bgColor={'secondary-050'}
            horizontalDividers={false}
            boldOptions={true}
            optionPadding={'0.625rem'}
            onOptionSelected={(option: SelectOption) => {
              switch (option.value) {
                case 'download-csv':
                  downloadContractListCsv(organizationId);
                  closeModal();
                  break;
                case 'download-xlsx':
                  downloadContractListSpreadsheet(organizationId);
                  closeModal();
                  break;
                case 'download-pdf':
                  downloadContractListPdf(organizationId);
                  closeModal();
                  break;
              }
            }}
            isHorizontal={true}
            usesInversion={true}
          />
        </div>
      )}
    </PopoverWithPopup>
  );
};

export default ContractListDownloadButton;
