import { css, SerializedStyles } from '@emotion/react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';

export type ButtonMarketingType = 'free' | 'essential' | 'pro' | 'unlock';

export type ButtonMarketingDefinition = {
  /**
   * The icon of the button
   */
  icon: IconType;
  /**
   * The label of the button
   */
  label: string;
  /**
   * The percentage width of the background gradient.
   *
   * To determine this number using the mockups.
   *
   * Find the width of the button. Then find how much of a percentage increase it is to be the same width as the gradient map provided by the design team.
   *
   * This way the background gradient will scale properly with the button if the size changes.
   */
  backgroundSizeWidthPercent: number;
  /**
   * Where the animation starts
   */
  startingKeyframe: SerializedStyles;
  /**
   * Should be the same as the ending keyframe in order to have the button stay in the same state after the animation
   */
  endingKeyframe: SerializedStyles;
};

const useButtonMarketingDefinition = (type: ButtonMarketingType): ButtonMarketingDefinition => definitionByButtonMarketingType[type];

// WARNING: These starting and ending keyframes are specifically set to start at 100 -/+1% to avoid overflowing the gradient to borders with opacity
const definitionByButtonMarketingType: Record<ButtonMarketingType, ButtonMarketingDefinition> = {
  free: {
    icon: 'Favorite',
    label: 'Upgrade',
    backgroundSizeWidthPercent: 656.12,
    startingKeyframe: css`
      background: linear-gradient(
          90deg,
          #30991e 0%,
          #61c950 12%,
          #54cdc3 23%,
          #80eaef 54%,
          #80eaef 61%,
          #f8d472 73%,
          #f8d472 89%,
          #e9b018 100%
        )
        99% 50%;
    `,
    endingKeyframe: css`
      background-position: 1% 50%;
    `,
  },
  essential: {
    icon: 'SubscriptionUpgrade',
    label: 'Upgrade',
    backgroundSizeWidthPercent: 622.47,
    startingKeyframe: css`
      background: linear-gradient(
          90deg,
          #30991e 0%,
          #61c950 13%,
          #5dca72 17%,
          #54cdc3 33%,
          #80eaef 50%,
          #58d2da 67%,
          #473c9a 83%,
          #473c9a 100%
        )
        99% 50%;
    `,
    endingKeyframe: css`
      background-position: 1% 50%;
    `,
  },
  pro: {
    icon: 'Upgraded',
    label: 'Manage plan',
    backgroundSizeWidthPercent: 559.71,
    startingKeyframe: css`
      background: linear-gradient(
          90deg,
          #006bbf 0%,
          #5ba4dd 16%,
          #afdcff 37%,
          #86bfeb 48%,
          #5c80d3 58%,
          #8c56d3 69%,
          #c44ddb 83%,
          #00599e 100%
        )
        99% 50%;
    `,
    endingKeyframe: css`
      background-position: 1% 50%;
    `,
  },
  unlock: {
    icon: 'Unlocked',
    label: 'Unlock for',
    backgroundSizeWidthPercent: 511.43,
    startingKeyframe: css`
      background: linear-gradient(90deg, #473c9a 0%, #473c9a 13%, #006bbf 37%, #58d2da 71%, #006bbf 86%, #3c3382 100%) 99% 50%;
    `,
    endingKeyframe: css`
      background-position: 1% 50%;
    `,
  },
};

export default useButtonMarketingDefinition;
