import React from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import useInitiativeDelete from '~/wm/packages/strategy/packages/initiative/packages/initiative-delete/hooks/useInitiativeDelete';
import { MutationSuccessEffectParams } from '~/extensions/packages/hooks/getMutationSuccessEffectDefinition';

export type InitiativeDeleteModalProps = {
  initiativeId: string;
  isOpen: boolean;
  onDismiss: () => void;
  onDeleteEffect?: MutationSuccessEffectParams;
  onDeleteSuccess?: () => void;
};

const InitiativeDeleteModal: React.FunctionComponent<InitiativeDeleteModalProps> = ({
  initiativeId,
  isOpen,
  onDismiss,
  onDeleteEffect,
  onDeleteSuccess,
}) => {
  const { deleteInitiative, isDeletingInitiative } = useInitiativeDelete({
    onSuccessEffect: onDeleteEffect,
    onSuccess: onDeleteSuccess,
  });

  return (
    <ModalConfirmation
      header={{
        title: 'Delete this Initiative?',
        icon: 'Trash',
      }}
      isOpen={isOpen}
      theme={'negative'}
      footer={{
        confirmButton: {
          icon: 'Trash',
          label: 'Delete',
          disabled: false,
          loading: isDeletingInitiative,
          onClick: () => {
            deleteInitiative(initiativeId);
          },
        },
        dismissButton: {
          icon: 'Cancel',
          label: 'Cancel',
          disabled: false,
          onClick: onDismiss,
        },
      }}
    >
      Deleting this Initiative can't be undone.
    </ModalConfirmation>
  );
};

export default InitiativeDeleteModal;
