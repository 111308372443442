import * as React from 'react';
import { Themeable } from '~/neo-ui/model/capacity';
import Dropdown, { DropdownOption } from '~/neo-ui/packages/dropdown/Dropdown';
import FormErrorMessage from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';
import SelectGroup, { ButtonSelectOption } from '~/neo-ui/packages/select/packages/select-group/SelectGroup';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { css } from '@emotion/react';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';

export type FiscalQuarterSelectProps<T> = {
  defaultValue:
    | {
        year: number;
        quarter: number;
      }
    | undefined;
  onSelect: (year: number, quarter: number) => void;
  fieldKeys?: {
    year: FieldKeyExpression<T>;
    quarter: FieldKeyExpression<T>;
  };
  shouldExpand?: boolean;
} & Themeable;

const FiscalQuarterSelect = <T,>({ defaultValue, onSelect, fieldKeys, shouldExpand, theme }: FiscalQuarterSelectProps<T>) => {
  const { currentFiscalQuarter } = useRegionalSettingsInfoContext();
  const yearOptions = Array.from(Array(7).keys())
    .map(number => number + currentFiscalQuarter.year - 1)
    .reduce(
      (final: DropdownOption[], year) => [
        ...(final ? final : []),
        {
          label: `${year}`,
          value: `${year}`,
        },
      ],
      [],
    );

  const yearOption =
    typeof defaultValue === 'undefined' ? undefined : yearOptions.find(option => Number(option.value) === defaultValue.year)!;
  const [selectedYear, setSelectedYear] = React.useState(yearOption);

  const year = {
    min: Number(yearOptions[0].value),
    max: Number(yearOptions[yearOptions.length - 1].value),
    selected: typeof defaultValue !== 'undefined' ? Number(defaultValue.year) : undefined,
  };

  const quarterOptions = Array.from(Array(4).keys())
    .map(number => number + 1)
    .reduce(
      (final: ButtonSelectOption[], quarter) => [
        ...(final ? final : []),
        {
          label: `Q${quarter}`,
          value: `${quarter}`,
          selectedTheme: theme,
          disabled:
            (year.min === year.selected && quarter < currentFiscalQuarter.quarter) ||
            (year.max === year.selected && quarter > currentFiscalQuarter.quarter),
        },
      ],
      [],
    );

  const quarterOption =
    typeof defaultValue === 'undefined'
      ? undefined
      : quarterOptions.find((option: ButtonSelectOption) => Number(option.value) === defaultValue.quarter)!;
  const [selectedQuarter, setSelectedQuarter] = React.useState(quarterOption);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 0.625rem;
      `}
    >
      <div>
        <Dropdown
          placeholder={'--'}
          selectedOption={
            typeof selectedYear === 'undefined' ? undefined : selectedYear
            // typeof selectedValue !== 'undefined' ? yearOptions.find(option => Number(option.value) === selectedValue.year) : undefined
          }
          options={yearOptions}
          onOptionSelected={option => {
            setSelectedYear(option);
            const currentSelected = {
              year: Number(option.value),
              quarter: Number(typeof defaultValue !== 'undefined' ? defaultValue.quarter : currentFiscalQuarter.quarter),
            };

            if (
              (year.min === currentSelected.year && currentSelected.quarter < currentFiscalQuarter.quarter) ||
              (year.max === currentSelected.year && currentSelected.quarter > currentFiscalQuarter.quarter)
            ) {
              const nextAvailableQuarterOption = quarterOptions.filter(quarterOption =>
                year.min === currentSelected.year
                  ? Number(quarterOption.value) >= currentFiscalQuarter.quarter
                  : Number(quarterOption.value) <= currentFiscalQuarter.quarter,
              )[0];

              setSelectedQuarter(nextAvailableQuarterOption);
              currentSelected.quarter = Number(nextAvailableQuarterOption.value);
            }
            onSelect(Number(option.value), currentSelected.quarter);
          }}
        />
        {typeof fieldKeys !== 'undefined' && <FormErrorMessage fieldKey={fieldKeys.year} />}
      </div>

      <div
        css={css`
          ${shouldExpand && 'flex-grow: 1;'}
        `}
      >
        <SelectGroup
          options={quarterOptions}
          selectedOptionValue={selectedQuarter?.value}
          onOptionSelected={selectedOption => {
            setSelectedQuarter(selectedOption);
            onSelect(
              typeof selectedYear === 'undefined' ? currentFiscalQuarter.year : Number(selectedYear.value),
              Number(selectedOption.value),
            );
          }}
          shouldExpand={shouldExpand}
        />
        {typeof fieldKeys !== 'undefined' && <FormErrorMessage fieldKey={fieldKeys.quarter} />}
      </div>
    </div>
  );
};

export default FiscalQuarterSelect;
