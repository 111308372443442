import { css } from '@emotion/react';
import * as React from 'react';
import PdContactSection from '~/wm/packages/subscription/packages/partner-development/packages/pd-contact-info/packages/pd-contact-section/PdContactSection';
import useSubscriptionPdContact from '~/wm/packages/subscription/packages/subscription-pd-contact/hooks/useSubscriptionPdContact';

export type PdContactSectionProps = {
  /**
   * Provide a reason to contact the PD team member. e.g., "To talk about enterprise solutions"
   */
  reasonToContact: string;
};

const SubscriptionPdContactSection: React.FunctionComponent<PdContactSectionProps> = ({ reasonToContact }) => {
  const { pdDisplayInfo } = useSubscriptionPdContact();

  if (typeof pdDisplayInfo === 'undefined') {
    return (
      <div
        css={css`
          height: 4.425rem;
        `}
      />
    );
  }

  return (
    <PdContactSection
      pdDisplayInfo={pdDisplayInfo}
      reasonToContact={reasonToContact}
    />
  );
};

export default SubscriptionPdContactSection;
