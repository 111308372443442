import { useContext } from 'react';
import EngagementListPageInitiativeLinkContext from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/context/EngagementListPageInitiativeLinkContext';

const useEngagementListPageInitiativeLinkContext = () => {
  const engagementListPageInitiativeLinkContext = useContext(EngagementListPageInitiativeLinkContext);
  if (!engagementListPageInitiativeLinkContext) {
    throw new Error('Tried to use EngagementListPageInitiativeLinkContext outside of an EngagementListPageInitiativeLinkProvider');
  }

  return engagementListPageInitiativeLinkContext;
};

export default useEngagementListPageInitiativeLinkContext;
