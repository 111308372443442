import { useContext } from 'react';
import AutoEmailReportEmailTemplateContext from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/context/AutoEmailReportEmailTemplateContext';

const useAutoEmailReportEmailTemplateContext = () => {
  const autoEmailReportEmailTemplateContext = useContext(AutoEmailReportEmailTemplateContext);
  if (!autoEmailReportEmailTemplateContext) {
    throw new Error('Tried to use AutoEmailReportEmailTemplateContext outside of an AutoEmailReportEmailTemplateProvider');
  }

  return autoEmailReportEmailTemplateContext;
};

export default useAutoEmailReportEmailTemplateContext;
