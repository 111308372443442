import React from 'react';
import { PieChart, Pie } from 'recharts';

export type PieGraphProps = {
  dataPoints: PieGraphDataPoint[];
  width: number;
  height: number;
  startAngle?: number;
  endAngle?: number;
  innerRadius?: number | string;
  outerRadius?: number | string;
  paddingAngle?: number;
  blendStroke?: boolean;
  shouldAnimate?: boolean;
};

export type PieGraphDataPoint = {
  value: number;
  fill: string;
};

const PieGraph: React.FunctionComponent<React.PropsWithChildren<PieGraphProps>> = ({
  dataPoints,
  width,
  height,
  startAngle,
  endAngle,
  innerRadius,
  outerRadius,
  paddingAngle,
  blendStroke,
  children,
  shouldAnimate = true,
}) => (
  <PieChart
    width={width}
    height={height}
  >
    <Pie
      dataKey="value"
      data={dataPoints}
      startAngle={startAngle}
      endAngle={endAngle}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      paddingAngle={paddingAngle}
      blendStroke={blendStroke}
      isAnimationActive={shouldAnimate}
    >
      {children}
    </Pie>
  </PieChart>
);

export default PieGraph;
