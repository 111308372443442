import { useSelector } from 'react-redux';
import AlertState from './model/AlertState';
import useAlert from '~/neo-ui/packages/toast/hooks/useAlert';
import { useEffect } from 'react';

export type AlertHookProps<TRootState> = {
  alertStateSelector: (state: TRootState) => AlertState;
};

const AlertHook = <TRootState,>({ alertStateSelector }: AlertHookProps<TRootState>) => {
  const { alertData } = useSelector(alertStateSelector);
  const sendAlert = useAlert();

  useEffect(() => {
    if (typeof alertData !== 'undefined') {
      sendAlert({
        toastType: 'error',
        title: alertData?.heading ?? 'Something went wrong',
        description: alertData?.children ?? `Try refreshing the page or contact support if the problem persists.`,
        icon: 'Danger',
        theme: 'negative',
        buttonLeft: {
          label: 'Refresh',
          iconLeft: 'Reload',
          onClick: () => window.location.reload(),
        },
        isNonDismissible: true,
      });
    }
    // Only send a new alert if the heading changes (reduce duplicates)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertData?.heading]);

  return null;
};

export default AlertHook;
