import { css } from '@emotion/react';
import * as React from 'react';
import Header from '~/neo-ui/packages/text/packages/header/Header';

export type AssetInfoProps = {
  assetName: string;
  modelNumber: string | undefined;
  serialNumber: string;
};

const AssetInfo: React.FunctionComponent<AssetInfoProps> = ({ assetName, modelNumber, serialNumber }) => (
  <div
    css={css`
      align-items: center;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
  >
    <Header
      className="primary-lighter"
      css={css`
        font-weight: bold;
        margin-bottom: 0.3125rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      `}
      size={3}
      color={'primary-400'}
    >
      {assetName}
    </Header>
    {modelNumber && (
      <Header
        css={css`
          margin-bottom: 0.3125rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        `}
        size={4}
      >
        {modelNumber}
      </Header>
    )}
    <p
      className="muted-darker"
      css={css`
        margin-bottom: 0;
        color: #57585c;
        font-size: 0.875rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      `}
    >
      {serialNumber}
    </p>
  </div>
);

export default AssetInfo;
