import { UseEngagementActionListMutationOptions } from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListMutation';
import useInitiativeAssessmentQuestionListMutation from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-assessment-section/context/hooks/useInitiativeAssessmentQuestionListMutation';
import { initiativeLinkAssessmentEvaluationQuestionUnlink } from '@BeastClient/BeastClientMsp.gen';

export type InitiativeAssessmentQuestionUnlinkPayload = {
  initiativeLinkId: string;
  organizationId: string;
};

export type UseInitiativeAssessmentQuestionUnlink = {
  unlinkInitiative: (unlinkPayload: InitiativeAssessmentQuestionUnlinkPayload) => void;
  isUnlinkingInitiative: boolean;
};

const useInitiativeAssessmentQuestionUnlink = (options?: UseEngagementActionListMutationOptions): UseInitiativeAssessmentQuestionUnlink => {
  const [unlinkInitiative, isUnlinkingInitiative] = useInitiativeAssessmentQuestionListMutation(
    (unlinkPayload: InitiativeAssessmentQuestionUnlinkPayload) =>
      initiativeLinkAssessmentEvaluationQuestionUnlink({
        initiativeLinkId: unlinkPayload.initiativeLinkId,
        organizationId: unlinkPayload.organizationId,
      }),
    options,
  );

  return {
    unlinkInitiative,
    isUnlinkingInitiative,
  };
};

export default useInitiativeAssessmentQuestionUnlink;
