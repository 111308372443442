import { useContext } from 'react';
import ScorecardUrlContext from '~/wm/packages/strategy/packages/scorecard/context/ScorecardUrlContext';

const useScorecardUrlContext = () => {
  const scorecardUrlContext = useContext(ScorecardUrlContext);
  if (!scorecardUrlContext) {
    throw new Error('Tried to use ScorecardUrlContext outside of an ScorecardUrlProvider');
  }

  return scorecardUrlContext;
};

export default useScorecardUrlContext;
