import { css } from '@emotion/react';

const disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles = {
  grid: css`
    display: grid;
    grid-template-columns: min-content auto min-content auto;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    align-items: center;
  `,
  contactNameGridColumns: css`
    grid-column: 1/5;
  `,
  contactEmailGridColumns: css`
    grid-column: 1/3;
  `,
  contactPhoneNumberGridColumns: css`
    grid-column: 3/5;
  `,
  formField: css`
    display: flex;
    flex-direction: column;
  `,
  sectionSpace: css`
    padding-top: 0.5rem;
  `,
  formFieldErrorMessageSpace: css`
    // Negate the size of the grid to mimic a regular form field error message
    margin-top: -0.5rem;
  `,
  notesGridColumns: css`
    grid-column: 1/5;
  `,
};

export default disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles;
