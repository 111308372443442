import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import ContractDeleteModal from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-list-table/packages/contract-delete-button/packages/contract-delete-modal/ContractDeleteModal';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';

export type ContractDeleteButtonProps = {
  contractId: string;
  organizationId: string;
  disabled: boolean;
};

const ContractDeleteButton: React.FunctionComponent<ContractDeleteButtonProps> = ({ contractId, organizationId, disabled }) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  return (
    <React.Fragment>
      <Button
        theme={'danger'}
        iconRight={'Delete'}
        onClick={() => {
          setModalOpen(true);
          trackEventProcessor('contract_delete_open', {});
        }}
        disabled={disabled}
      >
        Delete
      </Button>
      <ContractDeleteModal
        contractId={contractId}
        organizationId={organizationId}
        isOpen={isModalOpen}
        onDismiss={() => {
          setModalOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export default ContractDeleteButton;
