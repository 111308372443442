import * as React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { organizationEngagementNoteDashboardListGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import usePanelTabGroupContext from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/hooks/usePanelTabGroupContext';
import { EngagementType } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/EngagementDashboard';
import EngagementNotePanelTabContext from './EngagementNotePanelTabContext';
import EngagementNotePanelTabMutationContext from './EngagementNotePanelTabMutationContext';
import { EngagementNoteDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Dto/Model.gen';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';

const EngagementNotePanelTabProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const { callApi } = useApi();

  const { organizationId } = useOrganizationContext();
  const { currentPageByTab, currentTabId } = usePanelTabGroupContext<EngagementType>();

  const [notes, setNotes] = React.useState<EngagementNoteDto[]>();
  const [hasNextPage, setHasNextPage] = React.useState<boolean>(false);

  const reload = React.useCallback(async () => {
    await (async () => {
      const currentPage = currentPageByTab.get(currentTabId);

      if (typeof currentPage === 'undefined') {
        throw new Error('Current page or filter is undefined from tab context');
      }

      const response = await callApi(() =>
        organizationEngagementNoteDashboardListGet({
          organizationId,
          paginationPayload: {
            pageNumber: currentPage,
          },
        }),
      );
      if (!response) {
        return;
      }

      setNotes(response.engagementNotes);
      setHasNextPage(response.hasNextPage);
    })();
  }, [callApi, currentPageByTab, currentTabId, organizationId]);

  React.useEffect(() => {
    reload();
  }, [reload]);

  if (typeof notes === 'undefined') {
    return null;
  }

  return (
    <EngagementNotePanelTabMutationContext.Provider
      value={{
        triggerEngagementNotePanelTabReload: () => reload(),
      }}
    >
      <EngagementNotePanelTabContext.Provider
        value={{
          notes,
          hasNextPage,
        }}
      >
        {children}
      </EngagementNotePanelTabContext.Provider>
    </EngagementNotePanelTabMutationContext.Provider>
  );
};

export default EngagementNotePanelTabProvider;
