import { css } from '@emotion/react';
import React from 'react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import Color from '~/neo-ui/packages/color/Color.gen';
import { Styleable } from '~/neo-ui/model/capacity';
import Label from '~/neo-ui/packages/text/packages/label/Label';

export type DmiInfoProps = {
  description: string | undefined;
  icon?: IconType;
  iconColor?: Color;
} & Styleable;

const DmiInfo = ({ description, icon, iconColor, className }: DmiInfoProps) => (
  <div
    className={className}
    css={css`
      display: flex;
    `}
  >
    {typeof icon !== 'undefined' && (
      <Icon
        icon={icon}
        color={iconColor}
        sizePx={25}
        css={css`
          margin-right: 0.75rem;
        `}
      />
    )}
    {typeof description !== 'undefined' && <Label>{description}</Label>}
  </div>
);

export default DmiInfo;
