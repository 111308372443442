import { useContext } from 'react';
import EngagementNotePanelTabContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/context/EngagementNotePanelTabContext';

const useEngagementNotePanelTabContext = () => {
  const engagementNotePanelTabContext = useContext(EngagementNotePanelTabContext);
  if (!engagementNotePanelTabContext) {
    throw new Error('Tried to use EngagementNotePanelTabContext outside of an EngagementNotePanelTabProvider');
  }

  return engagementNotePanelTabContext;
};

export default useEngagementNotePanelTabContext;
