import { useState, useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { initiativeTemplateCreate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { InitiativeTemplateCreatePayloadDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/InitiativeTemplate/Dto/Model.gen';

export type UseInitiativeTemplateCreate = (onTemplateSave: () => void) => {
  isCreating: boolean;
  createTemplate: (createPayload: InitiativeTemplateCreatePayloadDto) => void;
};

const useInitiativeTemplateCreate: UseInitiativeTemplateCreate = onTemplateSave => {
  const { callApi } = useApi();
  const [isLoading, setIsLoading] = useState(false);

  const createTemplate = useCallback(
    async (createPayload: InitiativeTemplateCreatePayloadDto) => {
      setIsLoading(true);
      const response = await callApi(() =>
        initiativeTemplateCreate({
          createPayload,
        }),
      );
      if (!response) {
        setIsLoading(false);
        return;
      }

      onTemplateSave();
      setIsLoading(false);
    },
    [callApi, onTemplateSave],
  );

  return {
    isCreating: isLoading,
    createTemplate,
  };
};

export default useInitiativeTemplateCreate;
