import * as React from 'react';
import { parseISO } from 'date-fns';
import { TimezoneFormat, formatDate } from '~/extensions/packages/date/formatDate';
import ModalAction from '~/neo-ui/packages/modal/packages/modal-action/ModalAction';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import DisposalOrderSummarySectionAssetTableSelectable from '../disposal-order-summary-section-asset-table-selectable/DisposalOrderSummarySectionAssetTableSelectable';
import DisposalOrderSummarySectionAssetOrganizationAssignButton from './packages/disposal-order-summary-section-asset-organization-assign-button/DisposalOrderSummarySectionAssetOrganizationAssignButton';
import DisposalOrderSummarySectionAssetOrganizationAssignSelect from './packages/disposal-order-summary-section-asset-organization-assign-select/DisposalOrderSummarySectionAssetOrganizationAssignSelect';
import { DisposalOrderAssetDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderAsset/Dto/Model.gen';
import useDisposalOrderAssetSelectContext from '../context/hooks/useDisposalOrderAssetSelectContext';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import useOrganizationBasicInfoList from '~/wm/packages/disposal/hooks/useOrganizationBasicInfoList';

export type DisposalOrderSummarySectionAssetOrganizationAssignModalProps = {
  disposalOrderId: string;
  disposalOrderAssets: DisposalOrderAssetDto[];
  disposalAssetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[];
  completedAt: string;
  isOpen: boolean;
  onAssign: () => void;
  onCancel: () => void;
};

const DisposalOrderSummarySectionAssetOrganizationAssignModal = ({
  disposalOrderId,
  disposalOrderAssets,
  completedAt,
  isOpen,
  onAssign,
  onCancel,
  disposalAssetTypeAvailabilities,
}: DisposalOrderSummarySectionAssetOrganizationAssignModalProps) => {
  const { organizations } = useOrganizationBasicInfoList();

  const { clearDisposalOrderAssetIds, disposalOrderAssetIds } = useDisposalOrderAssetSelectContext();

  const [organizationId, setOrganizationId] = React.useState<string | undefined>();

  const isDisabled: boolean = disposalOrderAssetIds.size === 0;
  const isClientSelected: boolean = typeof organizationId !== 'undefined';

  if (typeof organizations === 'undefined') {
    return null;
  }

  const clearActionModal = () => {
    clearDisposalOrderAssetIds();
    setOrganizationId(undefined);
    onAssign();
  };

  return (
    <ModalAction
      header={{
        title: 'Assign these assets to clients',
        icon: 'Client',
      }}
      theme={'primary'}
      footer={{
        confirmationButtonAlignment: 'right',
        closeButton: {
          icon: 'ActionClose',
          label: 'Close',
          onClick: () => {
            onCancel();
            clearActionModal();
          },
        },
        label: {
          active: <Label>{disposalOrderAssetIds.size} selected</Label>,
          disabled: <Label muted={true}>Select items</Label>,
        },
        buttonModifier: {
          icon: 'ActionClose',
          disabled: isDisabled,
          onClick: clearActionModal,
        },
        controls: [
          <DisposalOrderSummarySectionAssetOrganizationAssignSelect
            key={'modal-footer-button-modifier-organization-select'}
            organizations={organizations}
            onOrganizationSelected={organizationId => {
              setOrganizationId(organizationId);
            }}
          />,
          <DisposalOrderSummarySectionAssetOrganizationAssignButton
            key={`modal-footer-button-modifier-button-done`}
            disposalOrderId={disposalOrderId}
            onDone={clearActionModal}
            isDisabled={isDisabled || !isClientSelected}
            // NOTE: the done button is disabled until an organization and disposal order assets are selected
            organizationId={organizationId as string}
          />,
        ],
      }}
      isOpen={isOpen}
      onDismiss={onCancel}
    >
      <React.Fragment>
        <Label
          size={'lg'}
          color={'dark-900-64'}
        >
          These assets will appear in the Certificate of Disposal for the clients you choose. Disposal order <b>{disposalOrderId}</b> placed{' '}
          {formatDate(parseISO(completedAt), {
            format: 'MMMM dd, yyyy',
            timezone: TimezoneFormat.Local,
          })}
          .
        </Label>
        <DisposalOrderSummarySectionAssetTableSelectable
          disposalOrderAssets={disposalOrderAssets}
          disposalAssetTypeAvailabilities={disposalAssetTypeAvailabilities}
        />
      </React.Fragment>
    </ModalAction>
  );
};

export default DisposalOrderSummarySectionAssetOrganizationAssignModal;
