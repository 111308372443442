import { css } from '@emotion/react';
import React, { ReactNode } from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';

type FilterHeaderEmptyProps = {
  title: string;
  prependIcon?: IconType;
  dropdownIcon?: ReactNode;
};

const FilterHeaderEmpty: React.FunctionComponent<FilterHeaderEmptyProps> = ({ title, prependIcon, dropdownIcon }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding-inline-start: 0.5rem;
    `}
  >
    {prependIcon && (
      <Icon
        icon={prependIcon}
        sizePx={16}
      />
    )}
    <div
      css={css`
        padding-left: 0.8rem;
        padding-right: 0.4375rem;
      `}
    >
      {title}
    </div>
    {dropdownIcon && dropdownIcon}
  </div>
);
export default FilterHeaderEmpty;
