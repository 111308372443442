import { ConsoleSearch } from '~/neo-ui/packages/table/packages/console/buildConsoleRequest';

const columnsParameter = 'Columns';
const searchParameter = 'Search';
const reservedParameterName = new Set([columnsParameter, searchParameter]);

const buildMapFromUrlParameters = (urlParams: URLSearchParams, ignoredUrlParameters: Set<string>): Map<string, Map<string, string[]>> => {
  const parametersMap = new Map<string, Map<string, string[]>>();
  const allIgnoredParameters = new Set([...ignoredUrlParameters, ...reservedParameterName]);
  urlParams.forEach((value, key) => {
    if (allIgnoredParameters.has(key)) {
      return;
    }
    const tagMap = new Map<string, string[]>();
    value.split(';').forEach(tagGroup => {
      const splitTag = tagGroup.split(':', 2);
      if (splitTag.length === 0) {
        return;
      }
      tagMap.set(splitTag[0], splitTag.length < 2 ? [] : splitTag[1].split(','));
    });
    parametersMap.set(key, tagMap);
  });

  return parametersMap;
};

export const getUrlParams = (ignoredUrlParameters: string[]) => {
  if (!('URLSearchParams' in window)) {
    // Supported by modern browsers
    return new Map<string, Map<string, string[]>>();
  }

  // noinspection UnnecessaryLocalVariableJS
  const urlParams = new URLSearchParams(window.location.search);
  if (!urlParams) {
    return new Map<string, Map<string, string[]>>();
  }
  return buildMapFromUrlParameters(urlParams, new Set(ignoredUrlParameters));
};

export const getSearchFromUrl = (defaultColumns: Set<string> | undefined): ConsoleSearch | undefined => {
  if (!('URLSearchParams' in window)) {
    // Supported by modern browsers
    return undefined;
  }

  const urlParams = new URLSearchParams(window.location.search);
  if (!urlParams) {
    return undefined;
  }

  const searchString = urlParams.get(searchParameter);

  return searchString === null
    ? undefined
    : {
        columns: Array.from(defaultColumns ?? []).map(x => ({ value: x })),
        value: searchString,
      };
};

export const getDefaultColumnsFromUrl = () => {
  if (!('URLSearchParams' in window)) {
    // Supported by modern browsers
    return;
  }

  // noinspection UnnecessaryLocalVariableJS
  const urlParams = new URLSearchParams(window.location.search);

  const columns = urlParams.get(columnsParameter)?.split(',');
  return typeof columns === 'undefined' ? undefined : new Set(columns);
};
