import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import ContractCreateWindow from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-create-button/packages/contract-create-window/ContractCreateWindow';
import ContractCreateForm from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-create-button/packages/contract-create-form/ContractCreateForm';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';

export type ContractCreateButtonProps = {
  organizationId: string;
};

const ContractCreateButton = ({ organizationId }: ContractCreateButtonProps) => {
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  const [isDisplayingWindow, setDisplayModal] = React.useState(false);

  return (
    <React.Fragment>
      <Button
        iconLeft={'Add'}
        theme={'primary'}
        onClick={() => {
          setDisplayModal(true);
          trackEventProcessor('contract_create_open', {});
        }}
      >
        New Contract
      </Button>
      {isDisplayingWindow && (
        <ContractCreateForm
          organizationId={organizationId}
          onContractCreate={() => setDisplayModal(false)}
        >
          <ContractCreateWindow
            organizationId={organizationId}
            isOpen={isDisplayingWindow}
            onDismiss={() => setDisplayModal(false)}
          />
        </ContractCreateForm>
      )}
    </React.Fragment>
  );
};

export default ContractCreateButton;
