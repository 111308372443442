import { InitiativeAvailabilitiesDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import { createContext } from 'react';

export type InitiativeAvailabilitiesContextData = {
  availabilities: InitiativeAvailabilitiesDto;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const InitiativeAvailabilitiesContext = createContext<InitiativeAvailabilitiesContextData | undefined>(undefined);

export default InitiativeAvailabilitiesContext;
