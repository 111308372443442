import useScorecardMutation, { UseScorecardMutationOptions } from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardMutation';
import { scorecardCategoryUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseScorecardCategoryUpdate = {
  updateScorecardCategory: (updatePayload: { label: string }) => void;
  isUpdatingScorecardCategory: boolean;
};

const useScorecardCategoryUpdate = (
  scorecardId: string,
  scorecardCategoryId: string,
  options?: UseScorecardMutationOptions,
): UseScorecardCategoryUpdate => {
  const [updateScorecardCategory, isUpdatingScorecardCategory] = useScorecardMutation(
    (updatePayload: { label: string }) =>
      scorecardCategoryUpdate({
        scorecardId,
        scorecardCategoryId,
        label: updatePayload.label,
      }),
    options,
  );

  return {
    updateScorecardCategory,
    isUpdatingScorecardCategory,
  };
};

export default useScorecardCategoryUpdate;
