import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import FormInputInternal from '~/neo-ui/packages/form/packages/form-input/FormInputInternal';
import SingleSelect from '~/neo-ui/packages/select/packages/single-select/SingleSelect';
import * as React from 'react';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { Styleable } from '~/neo-ui/model/capacity';
import { GroupBase } from 'react-select';

export type FormSingleSelectInputProps<TFormData, TFormDataValue extends string = string> = {
  fieldKey: FieldKeyExpression<TFormData>;
  options: SelectOption<TFormDataValue>[] | GroupBase<SelectOption<TFormDataValue>>[];
  placeholder?: string;
  truncateSelected?: number;
  onChange?: (value: TFormDataValue) => void;

  /**
   * Given a value being sent by the input, map it
   * to something else when propagating to form data.
   */
  toFormData?: (inputValue: string) => TFormDataValue;
  shouldUseMenuPortal?: boolean;
} & Styleable;

const FormSingleSelectInput = <TFormData, TFormDataValue extends string = string>({
  fieldKey,
  options,
  placeholder = 'Select...',
  truncateSelected = -1,
  onChange,
  toFormData,
  shouldUseMenuPortal = true,
}: FormSingleSelectInputProps<TFormData, TFormDataValue>) => {
  const { setFormInput } = useFormContext<TFormData>();
  const [selectedInput, setSelectedInput] = React.useState<SelectOption<TFormDataValue> | undefined>(undefined);
  return (
    <FormInputInternal
      fieldKey={fieldKey}
      inputProps={{}}
    >
      {({ inputProps }) => (
        <SingleSelect
          {...inputProps}
          placeholder={placeholder}
          options={options}
          onOptionSelected={selectedItem => {
            const selectedValue = toFormData ? toFormData(selectedItem.value) : selectedItem.value;
            setSelectedInput(selectedItem);
            setFormInput<string>(fieldKey, selectedItem.value);
            if (typeof onChange !== 'undefined') {
              onChange(selectedValue);
            }
          }}
          selectedOption={selectedInput}
          style={'default'}
          controlContainerHeight={'1.875rem'}
          truncateSelected={truncateSelected}
          shouldUseMenuPortal={shouldUseMenuPortal}
        />
      )}
    </FormInputInternal>
  );
};

export default FormSingleSelectInput;
