import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { contractListPdf } from '@AssetManagementClient/AssetManagementClientMsp.gen';

type UseDownloadContractListPdf = () => {
  downloadContractListPdf: (organizationId: string) => void;
};

export const useDownloadContractListPdf: UseDownloadContractListPdf = () => {
  const { callApi } = useApi();

  const downloadContractListPdf = React.useCallback(
    async (organizationId: string) => {
      const stream = await callApi(() => contractListPdf({ organizationId }));

      if (!stream) {
        return;
      }
    },
    [callApi],
  );

  return { downloadContractListPdf };
};

export default useDownloadContractListPdf;
