import { Response } from '@SubscriptionClient/Subscription/Packages/Billing/Controller/CustomerCreditCardDetailsGetControllerNested.gen';
import { ComponentPropsWithoutRef } from 'react';
import theme from '~/sp-ui/theme';
import {
  AmexCreditCardIcon,
  DinersClubCreditCardIcon,
  DiscoverCreditCardIcon,
  GeneralCreditCardIcon,
  JCBCreditCardIcon,
  MastercardCreditCardIcon,
  UnionPayCreditCardIcon,
  VisaCreditCardIcon,
} from '~/sp-ui/icons';

type CardIconComponent = React.NamedExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & React.RefAttributes<SVGSVGElement>
>;

type CreditCardIcons = {
  [key: string]: CardIconComponent;
};

export interface ReadonlyPaymentMethodProps extends ComponentPropsWithoutRef<'div'> {
  data: Exclude<Response['cardDetails'], undefined>;
}

const ReadonlyPaymentMethod = (props: ReadonlyPaymentMethodProps) => {
  const { data, ...rest } = props;

  const cardIcons: CreditCardIcons = {
    visa: VisaCreditCardIcon,
    mastercard: MastercardCreditCardIcon,
    amex: AmexCreditCardIcon,
    discover: DiscoverCreditCardIcon,
    diners: DinersClubCreditCardIcon,
    jcb: JCBCreditCardIcon,
    unionpay: UnionPayCreditCardIcon,
  };

  const CardIcon: CardIconComponent = cardIcons[data.cardBrand.toLowerCase()] || GeneralCreditCardIcon;

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      }}
      {...rest}
    >
      <CardIcon
        css={{
          width: '2.938rem',
          height: '2rem',
          border: `1px solid ${theme.palette.grey1}`,
          borderRadius: `4px`,
        }}
      />
      <span
        css={[
          theme.typography.body,
          {
            marginLeft: '0.5rem',
          },
        ]}
      >
        {data.cardLabel}
      </span>
    </div>
  );
};

export default ReadonlyPaymentMethod;
