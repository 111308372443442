import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLockedEntities } from '~/legacy-ui/packages/lock/state/selector/selectLockState';
import DisableAfterElementOverlay from '../../../../../neo-ui/packages/overlay/DisableAfterElementOverlay';
import entityLockAction from '../../state/action/lock/entityLockAction';

export type LockConfiguration = {
  /**
   * If set, entities are locked by default until released.
   */
  lockByDefault: boolean;
};

export type EntitySuspenseProps = {
  bucket: string;
  dependencies: string[];

  children: React.ReactNode;
} & LockConfiguration;

/**
 * Ensures dependencies are available
 */
const EntityLock = ({ bucket, dependencies, lockByDefault = false, children }: EntitySuspenseProps) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (lockByDefault && dependencies.length) {
      dispatch(
        entityLockAction({
          entities: dependencies.map(entityId => ({ bucket, entityId })),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check lock state
  const lockedEntities = useSelector(selectLockedEntities(bucket));

  if (lockedEntities && dependencies.some(dep => lockedEntities.has(dep))) {
    // Waiting for lock to be released
    return <DisableAfterElementOverlay>{children}</DisableAfterElementOverlay>;
  }

  return <>{children}</>;
};

export default EntityLock;
