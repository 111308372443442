import { AutoEmailReportDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';
import useAutoEmailReportListContext from '~/wm/packages/organization/packages/auto-email-report/context/hooks/useAutoEmailReportListContext';
import Box from '~/neo-ui/packages/box/Box';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import { Filter } from '~/neo-ui/packages/table/packages/console/types';
import React from 'react';
import { convertToMapNestedArrays } from '~/extensions/packages/iterable/keyedNestedArrayConverter';
import FilterBadgeBar from '~/neo-ui/packages/table/packages/console/filter-display/FilterBadgeBar';
import { Styleable } from '~/neo-ui/model/capacity';

export type AutoEmailReportSettingsParametersProps = {
  autoEmailReport: AutoEmailReportDto;
} & Styleable;

const AutoEmailReportSettingsParameters = ({ autoEmailReport, className }: AutoEmailReportSettingsParametersProps) => {
  const { autoEmailReportAvailability } = useAutoEmailReportListContext();

  // Get the parameters and filters into their supported formats then
  // filter filters by parameters that have been set
  const parameters = convertToMapNestedArrays(autoEmailReport.reportQuery.parameters);
  const selectedFilters = autoEmailReportAvailability.filters
    .filter(filter => parameters.has(filter.key.value))
    .map<Filter>(filter => ({
      key: filter.key.value,
      label: filter.label,
      tags: new Map(Object.entries(filter.tags)),
      order: filter.order,
      render: filter.render,
      isEnabled: filter.isEnabled,
    }));

  return (
    <Box
      padding={'padding200'}
      borderRadius={'radius400'}
      className={className}
    >
      <Label
        bold={true}
        css={css`
          margin-bottom: 0.5rem;
        `}
        color={autoEmailReport.isScheduled ? undefined : 'dark-900-32'}
      >
        Parameters
      </Label>
      <FilterBadgeBar
        filters={selectedFilters}
        parameters={parameters}
      />
    </Box>
  );
};

export default AutoEmailReportSettingsParameters;
