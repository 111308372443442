import { useContext } from 'react';
import ContractTemplateAvailabilitiesContext from '~/wm/packages/strategy/packages/contract/packages/contract-template/context/ContractTemplateAvailabilitiesContext';

const useContractTemplateAvailabilitiesContext = () => {
  const contractTemplateAvailabilitiesContext = useContext(ContractTemplateAvailabilitiesContext);
  if (!contractTemplateAvailabilitiesContext) {
    throw new Error('Tried to use ContractTemplateAvailabilitiesContext outside of an contractTemplateAvailabilitiesProvider');
  }

  return contractTemplateAvailabilitiesContext;
};

export default useContractTemplateAvailabilitiesContext;
