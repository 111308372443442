import Button from '../../../../../../../../../../../sp-ui/buttons/Button';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import { useEffect, useState } from 'react';
import { DiscountIcon } from '~/sp-ui/icons';
import TextField from '~/sp-ui/inputs/textfield';
import AppliedCouponsSection from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/coupon-section/AppliedCouponsSection';

const CouponSection = () => {
  const { formik } = useMultiStepForm<PurchaseSubscriptionForm>();
  const { setFieldValue } = formik;
  const [isInStartingState, setIsInStartingState] = useState(!formik.values.couponBreakdown || formik.values.couponBreakdown?.length === 0);
  const [couponInputState, setCouponInputState] = useState<string>('');

  useEffect(() => {
    setFieldValue('isLoadingCoupon', false);
    if (formik.values.couponCodeError) {
      const couponInputState = formik.values.couponCodes?.pop() ?? '';
      setCouponInputState(couponInputState);
      setFieldValue('couponCodes', [...formik.values.couponCodes]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.couponCodeError, setFieldValue]);

  const submitCouponAction = () => {
    if (couponInputState.length > 0) {
      setFieldValue('isLoadingCoupon', true);
      setFieldValue('couponCodeError', undefined);
      setFieldValue('couponCodes', [...formik.values.couponCodes, couponInputState]);
      setCouponInputState('');
    }
  };

  const startingSection = (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
      }}
    >
      <DiscountIcon />
      <div
        css={{
          color: '#0575CC',
          fontSize: '1rem',
          textDecorationLine: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => setIsInStartingState(false)}
      >
        Enter a coupon or promo code
      </div>
    </div>
  );

  const inputSection = (
    <div css={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <TextField
        label={'Coupon or promo code'}
        type="text"
        onChange={e => {
          setCouponInputState(e.target.value);
          setFieldValue('couponCodeError', undefined);
        }}
        value={couponInputState}
        disabled={formik.values.isLoadingCoupon}
        autoFocus={true}
        errorSubtext={formik.values.couponCodeError}
        css={{ width: '100%', marginRight: '0.5rem', marginBottom: `${formik.values.couponCodeError ? '1rem' : '0rem'}` }}
      />
      <Button
        onClick={submitCouponAction}
        loading={formik.values.isLoadingCoupon}
        variant={'secondary'}
        size={'small'}
        css={{ height: '1.5rem', marginBottom: `${formik.values.couponCodeError ? '2.5rem' : '0.25rem'}` }}
      >
        Apply
      </Button>
    </div>
  );

  return isInStartingState ? (
    startingSection
  ) : (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
      }}
    >
      {inputSection}
      <AppliedCouponsSection />
    </div>
  );
};

export default CouponSection;
