import * as React from 'react';

export type OutsideClickHandlerProps = {
  /**
   * Nodes that are considered inside the component
   */
  refs: React.RefObject<Element>[];

  /**
   * Fired when you click outside this component
   */
  onClickOutside: () => void;
};

/**
 * A utility that allows you to process actions when a user clicks outside of the provided component.
 */
const OutsideClickHandler: React.FunctionComponent<React.PropsWithChildren<OutsideClickHandlerProps>> = ({
  refs,
  onClickOutside,
  children,
}) => {
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        // No target definitely means you didn't click the element(s)
        !event.target ||
        // The target isn't within one of the refs
        !refs.some(ref => ref.current && ref.current.contains(event.target as Node))
      ) {
        onClickOutside();
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside, refs]);

  return <>{children}</>;
};

export default OutsideClickHandler;
