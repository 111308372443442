import * as React from 'react';
import useScorecardContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardContext';
import useDownloadScorecardPdf from '~/wm/packages/strategy/packages/scorecard/hooks/useDownloadScorecardPdf';
import { css } from '@emotion/react';
import CardButton from '~/legacy-ui/packages/card/packages/card-button/CardButton';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';

export type ScorecardShareSectionProps = {
  organizationId: string;
  scorecardPreviewUrl: string;
};

const ScorecardShareSection = ({ organizationId, scorecardPreviewUrl }: ScorecardShareSectionProps) => {
  const { scorecard } = useScorecardContext();
  const { downloadScorecardPdf } = useDownloadScorecardPdf();
  const { trackEvent } = useEventTracking(TrackingServices.EventProcessor);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
      `}
    >
      <CardButton
        title={'Start presenting'}
        description={'Collaborate live with an interactive Scorecard'}
        icon={'Present'}
        onClick={() => {
          trackEvent('scorecard_presented', {
            obfuscatedClientId: organizationId,
            scorecardId: scorecard.scorecardId,
          });

          window.open(scorecardPreviewUrl.replace('__ORGANIZATION_ID__', organizationId), '_blank');
        }}
        disabled={!scorecard.isLatestSchedule}
        actionComponent={
          <div
            css={css`
              display: flex;
              gap: 0.3125rem;
              margin-left: auto;
            `}
          >
            <Label color={'primary-400'}>Open</Label>
            <Icon
              color={'primary-400'}
              icon={'GoExternal'}
            />
          </div>
        }
      />
      <CardButton
        title={'Download PDF'}
        description={'Get a white-labeled PDF of your Scorecard to send to your client'}
        icon={'PDF'}
        onClick={() => {
          trackEvent('scorecard_downloaded', {
            obfuscatedClientId: organizationId,
            scorecardId: scorecard.scorecardId,
          });

          downloadScorecardPdf(scorecard.scorecardId);
        }}
      />
    </div>
  );
};

export default ScorecardShareSection;
