// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


/**
 * All SLA types possible are defined here
 * Note: this enum is also referenced by Beast
 * 
 * See {67EC8F57-2CC6-47D3-AED5-B68F230D850C}
 * 
 * Ensure all referenced enums are up to date if adding new entries.
 */
export enum Enum
{
  FourHour = "FourHour",
  NextBusinessDay = "NextBusinessDay",
  ReplacementInclAccidentalDamage = "ReplacementInclAccidentalDamage",
}


