import { createContext } from 'react';
import {
  EngagementActionDto,
  EngagementActionStatusAvailabilityDto,
  EngagementActionStatusCountDto,
} from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import { ActionCreateAnimation } from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/EngagementActionListProvider';

export type EngagementActionListContextData = {
  hasNextPage: boolean;
  actions: EngagementActionDto[];
  statusAvailabilities: EngagementActionStatusAvailabilityDto[];
  statusCounts: EngagementActionStatusCountDto[];
  addCreateAnimation: (actionId: string) => void;
  completeCreateAnimation: (actionId: string) => void;
  actionCreateAnimationCache: ActionCreateAnimation[];
  clearCreateAnimationCache: () => void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const EngagementActionListContext = createContext<EngagementActionListContextData | undefined>(undefined);

export default EngagementActionListContext;
