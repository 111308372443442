import { useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { disposalOrderShippingLabelGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseDisposalCheckoutDisposal = () => {
  downloadDisposalOrderShippingLabel: (disposalOrderId: string) => void;
};

const useDisposalOrderShippingLabelGet: UseDisposalCheckoutDisposal = () => {
  const { callApi } = useApi();

  const downloadDisposalOrderShippingLabel = useCallback(
    async (disposalOrderId: string) => {
      await (async () => {
        const response = await callApi(() => disposalOrderShippingLabelGet({ disposalOrderId }));

        if (!response) {
          return;
        }
      })();
    },
    [callApi],
  );

  return {
    downloadDisposalOrderShippingLabel,
  };
};

export default useDisposalOrderShippingLabelGet;
