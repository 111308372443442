import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { MultiSelectStaticSelection } from '~/neo-ui/packages/select/packages/multi-select-static/MultiSelectStatic';
import Popover from '~/neo-ui/packages/popover/Popover';
import SplitMultiSelect from '~/neo-ui/packages/select/packages/split-multi-select-static/SplitMultiSelect';
import * as React from 'react';
import { Filter } from '~/neo-ui/packages/table/packages/console/types';
import { RenderFilterNested } from '@AssetManagementClient/BeastClient/Search/Model/Query/Field/Filter/Render.gen';
import { SplitMultiSelectHeaderFormatterType } from '~/neo-ui/packages/filter/packages/filter-split-multi-select/SplitMultiSelectHeaderMenuFormatter';
import Theme from '~/neo-ui/packages/color/Theme';
import Testable from '~/neo-ui/packages/testable/Testable';
import { pascalCaseToSpinalCase } from '~/extensions/packages/casing/pascalSpinalConversion';

export type SplitMultiSelectFilterProps = {
  filter: Filter;
  render: RenderFilterNested.SplitMultiSelect;
  parameterValue: Map<string, string[]> | undefined;
  setValue: (key: string, values: Map<string, string[]>) => void;
  headerFormatter: SplitMultiSelectHeaderFormatterType;
};

const SplitMultiSelectFilter: React.FunctionComponent<SplitMultiSelectFilterProps> = ({
  filter,
  render,
  parameterValue,
  setValue,
  headerFormatter,
}) => {
  /**
   * Extract all the filter values from the (URL) parameters
   * */
  // selection values
  let selectedOptionsPositive: SelectOption[] = [];
  let selectionTypePositive: string | undefined;
  let selectedOptionsNegative: SelectOption[] = [];
  let selectionTypeNegative: string | undefined;

  const toSelectOption = (paramVal: string) => {
    const filterDisplayOption = render.options.find(option => option.filterValue.key === paramVal);
    if (filterDisplayOption === undefined) {
      return { label: paramVal, value: paramVal };
    }

    return {
      label: filterDisplayOption.filterValue.label,
      value: filterDisplayOption.filterValue.key,
      legacyIconUrl: filterDisplayOption.iconUrl,
      prependIcon: filterDisplayOption.iconName,
    } as SelectOption;
  };

  // Access the url params long as url has parameters
  if (typeof parameterValue !== 'undefined' && parameterValue.size > 0) {
    // selected item options from url
    selectedOptionsPositive = (parameterValue.get(render.positiveOperator.filterValue.key) ?? []).map(toSelectOption);
    selectedOptionsNegative = (parameterValue.get(render.negativeOperator.filterValue.key) ?? []).map(toSelectOption);

    // selected selection type from url
    const selectionTypePositiveRaw = parameterValue.get(render.positiveSelectionTypeLabel) ?? undefined;
    const selectionTypeNegativeRaw = parameterValue.get(render.negativeSelectionTypeLabel) ?? undefined;

    // extract the selection type string
    if (selectionTypePositiveRaw !== undefined && selectionTypePositiveRaw.length > 0) {
      selectionTypePositive = selectionTypePositiveRaw[0];
    }
    if (selectionTypeNegativeRaw !== undefined && selectionTypeNegativeRaw.length > 0) {
      selectionTypeNegative = selectionTypeNegativeRaw[0];
    }
  }

  /**
   * Create onChange function from given setValue function
   */
  const setSplitMultiSelectOptions = React.useCallback(
    (selectedPositive: MultiSelectStaticSelection<string>, selectedNegative: MultiSelectStaticSelection<string>) => {
      const changedValues = new Map<string, string[]>();
      if (typeof selectedPositive.selectedOptions !== 'undefined' && selectedPositive.selectedOptions?.length > 0) {
        changedValues.set(render.positiveOperator.filterValue.key, selectedPositive.selectedOptions);
      }
      if (
        typeof selectedPositive.matchType !== 'undefined' &&
        typeof selectedPositive.selectedOptions !== 'undefined' &&
        selectedPositive.selectedOptions?.length > 0
      ) {
        changedValues.set(render.positiveSelectionTypeLabel, [selectedPositive.matchType]);
      }
      if (typeof selectedNegative.selectedOptions !== 'undefined' && selectedNegative.selectedOptions?.length > 0) {
        changedValues.set(render.negativeOperator.filterValue.key, selectedNegative.selectedOptions);
      }
      if (
        typeof selectedNegative.matchType !== 'undefined' &&
        typeof selectedNegative.selectedOptions !== 'undefined' &&
        selectedNegative.selectedOptions?.length > 0
      ) {
        changedValues.set(render.negativeSelectionTypeLabel, [selectedNegative.matchType]);
      }
      setValue(filter.key, changedValues);
    },
    [
      filter.key,
      render.positiveSelectionTypeLabel,
      render.negativeSelectionTypeLabel,
      render.positiveOperator.filterValue.key,
      render.negativeOperator.filterValue.key,
      setValue,
    ],
  );

  /**
   * Filter preview
   */
  const filterHeaderCallback = headerFormatter(
    selectedOptionsPositive,
    selectedOptionsNegative,
    filter.label,
    render.positiveOperator.filterValue.label,
    render.negativeOperator.filterValue.label,
    render.positiveOperator.colorTheme as Theme,
    render.negativeOperator.colorTheme as Theme,
  );

  /** ***************
   * Main component *
   ******************/
  return (
    <div>
      <Testable testId={`asset-console-filter-${pascalCaseToSpinalCase(filter.key)}-popover`}>
        <Popover header={filterHeaderCallback}>
          <SplitMultiSelect
            allOptions={render.options?.map(option => ({
              label: option.filterValue.label,
              value: option.filterValue.key,
              legacyIconUrl: option.iconUrl,
            }))}
            allSelectionTypes={render.selectionTypes}
            selectedOptionsPositive={selectedOptionsPositive}
            selectedOptionsNegative={selectedOptionsNegative}
            selectionTypePositive={selectionTypePositive}
            selectionTypeNegative={selectionTypeNegative}
            onChange={setSplitMultiSelectOptions}
            leftHeaderLabel={render.positiveOptionLabel}
            rightHeaderLabel={render.negativeOptionLabel}
            placeholderSearchString={render.optionsSearchPlaceholder}
            leftTheme={render.positiveOperator.colorTheme as Theme}
            rightTheme={render.negativeOperator.colorTheme as Theme}
          />
        </Popover>
      </Testable>
    </div>
  );
};
export default SplitMultiSelectFilter;
