import usePeopleDashboardMutation, {
  UsePeopleDashboardMutationOptions,
} from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/usePeopleDashboardMutation';
import { organizationAccountTeamMemberEdit } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { AccountTeamMemberRoleAssignPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationAccountTeamMember/Packages/AccountTeamMember/Packages/AccountTeamMemberRole/Dto/Model.gen';

type OrganizationAccountTeamMemberEditPayload = {
  organizationId: string;
  roleAssignPayload: AccountTeamMemberRoleAssignPayloadDto;
};

export type UseOrganizationAccountTeamMemberAdd = {
  editOrganizationAccountTeamMember: (editPayload: OrganizationAccountTeamMemberEditPayload) => void;
  isEditingOrganizationAccountTeamMember: boolean;
};

const useOrganizationAccountTeamMemberEdit = (options?: UsePeopleDashboardMutationOptions): UseOrganizationAccountTeamMemberAdd => {
  const [editOrganizationAccountTeamMember, isEditingOrganizationAccountTeamMember] = usePeopleDashboardMutation(
    (editPayload: OrganizationAccountTeamMemberEditPayload) =>
      organizationAccountTeamMemberEdit({
        organizationId: editPayload.organizationId,
        roleAssignPayload: editPayload.roleAssignPayload,
      }),
    options,
  );

  return {
    editOrganizationAccountTeamMember,
    isEditingOrganizationAccountTeamMember,
  };
};

export default useOrganizationAccountTeamMemberEdit;
