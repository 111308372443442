import { useDispatch } from 'react-redux';
import { AlertData } from '../Alert';
import { hideAlertAction, showAlertAction } from '../state/action/alertActions';

export type AlertDispatch = {
  showAlert: (info: AlertData) => void;
  hideAlert: () => void;
};
/**
 * Alert hook.
 *
 * Returns a function to show the alert with specified properties.
 */
const useAlert = (): AlertDispatch => {
  const dispatch = useDispatch();
  return {
    showAlert: (info: AlertData) => dispatch(showAlertAction(info)),
    hideAlert: () => dispatch(hideAlertAction()),
  };
};

export default useAlert;
