import ServiceRequest from '~/wm/packages/warranty/packages/service-request/model/ServiceRequest';
import Box from '~/neo-ui/packages/box/Box';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import { Styleable } from '~/neo-ui/model/capacity';
import ServiceRequestInfoBlock from '~/wm/packages/warranty/packages/service-request/packages/service-request-info-block/ServiceRequestInfoBlock';
import DisposalOrderInfoProvider from '~/wm/packages/disposal/packages/order-info/context/DisposalOrderInfoProvider';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color from '~/neo-ui/packages/color/Color.gen';
import useCoverageTransferDisposalOrderInfoGet from '~/wm/packages/warranty/packages/coverage/packages/coverage-transfer/hooks/useCoverageTransferDisposalOrderInfoGet';
import ServiceRequestDisposalCertificateButton from '~/wm/packages/warranty/packages/service-request/packages/service-request-disposal-certificate-button/ServiceRequestDisposalCertificateButton';
import { Enum } from '@WarrantyClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderType/Model/DisposalOrderTypeFactoryNested.gen';
import Testable from '~/neo-ui/packages/testable/Testable';
import DisposalOrderShipmentSection from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/DisposalOrderShipmentSection';

export type ServiceRequestNextStepsSectionProps = {
  serviceRequest: ServiceRequest;
  warrantyTransferUrl: string;
  hasDisposalLocationAccess: boolean;
  isCoverageTransferred?: boolean;
  isCoverageTransferAllowed: boolean;
} & Styleable;

const ServiceRequestDisposalSection = ({
  serviceRequest,
  warrantyTransferUrl,
  hasDisposalLocationAccess,
  isCoverageTransferred,
  isCoverageTransferAllowed,
  className,
}: ServiceRequestNextStepsSectionProps) => {
  const { disposalOrderInfoResponse } = useCoverageTransferDisposalOrderInfoGet(serviceRequest.serviceRequestId);

  if (!isCoverageTransferAllowed) {
    return null;
  }

  // Return empty state if the response has not returned yet
  if (typeof disposalOrderInfoResponse === 'undefined') {
    return (
      <Box
        padding={'padding200'}
        boxShadow={'shadow0'}
        borderRadius={'radius400'}
        className={className}
      />
    );
  }

  const disposalOrderSummary = disposalOrderInfoResponse.disposalOrderSummary;

  // Guard against if we have a disposalOrderSummary, and it's not workstation assurance
  if (typeof disposalOrderSummary !== 'undefined' && disposalOrderSummary.orderType !== Enum.WorkstationAssurance) {
    return null;
  }

  // If a disposal order already exists for this service request, show its shipment information
  if (typeof disposalOrderSummary !== 'undefined' && disposalOrderSummary.orderType.valueOf === Enum.WorkstationAssurance.valueOf) {
    return (
      <DisposalOrderInfoProvider
        disposalOrderId={disposalOrderSummary.disposalOrderId}
        defaultDisposalOrderSummary={disposalOrderSummary}
      >
        <Box
          padding={'padding200'}
          boxShadow={'shadow0'}
          borderRadius={'radius400'}
          className={className}
        >
          <LayoutSection
            title={
              typeof disposalOrderSummary.completedAt === 'undefined' ? 'This asset is ready for Disposal' : 'This asset has been Disposed'
            }
            titleSize={4}
            titleWeight={'bold'}
            description={'The replacement asset is now covered by a ScalePad Warranty.'}
          >
            <div>
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  gap: 0.8125rem;
                  align-items: center;
                `}
              >
                <Icon
                  icon={disposalOrderSummary.status.displayIcon as IconType}
                  color={disposalOrderSummary.status.displayColor as Color}
                  sizePx={30}
                />
                <div>
                  <Label
                    size={'md'}
                    bold={true}
                    color={disposalOrderSummary.status.displayColor as Color}
                  >
                    {disposalOrderSummary.status.displayLabel}
                  </Label>
                  <Label
                    size={'md'}
                    muted={true}
                  >
                    {disposalOrderSummary.status.displayDescription}
                  </Label>
                </div>
              </div>
              {typeof disposalOrderSummary.completedAt !== 'undefined' && (
                <ServiceRequestDisposalCertificateButton
                  css={css`
                    margin-top: 1rem;
                    margin-left: 2.6875rem;
                  `}
                  organizationName={serviceRequest.asset.orgName}
                  assetSerialNumber={serviceRequest.asset.serialNumber}
                />
              )}
            </div>
            {!disposalOrderSummary.availableOperations.cannotModifyShipping && <DisposalOrderShipmentSection />}
          </LayoutSection>
        </Box>
      </DisposalOrderInfoProvider>
    );
  }

  // No disposal summary but warranty coverage successfully transferred did not include a disposal to track
  if (isCoverageTransferred) {
    return (
      <Box
        padding={'padding200'}
        boxShadow={'shadow0'}
        borderRadius={'radius400'}
        className={className}
      >
        <LayoutSection
          title={"This asset's coverage has been transferred"}
          titleSize={4}
          titleWeight={'bold'}
          description={'The replacement asset is now covered by a ScalePad Warranty.'}
        >
          <React.Fragment />
        </LayoutSection>
      </Box>
    );
  }

  return (
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
      className={className}
    >
      <LayoutSection
        title={'Next steps'}
        titleSize={4}
        titleWeight={'bold'}
        description={'Take these steps when you receive the replacement asset.'}
      >
        <ServiceRequestInfoBlock
          title={'Transfer coverage'}
          description={'The replacement asset is covered by this asset’s warranty.'}
          icon={'Transfer'}
          iconColor={'secondary-400'}
        />
        {hasDisposalLocationAccess && (
          <ServiceRequestInfoBlock
            title={'Disposal'}
            description={'Send the old asset for secure disposal.'}
            icon={'Disposal'}
            iconColor={'secondary-400'}
          />
        )}
        <Testable testId={'service-request-disposal-get-started-button'}>
          <Button
            size={'sm'}
            iconRight={'GoRight'}
            theme={'secondary'}
            anchor={{ href: warrantyTransferUrl }}
          >
            Get started
          </Button>
        </Testable>
      </LayoutSection>
    </Box>
  );
};

export default ServiceRequestDisposalSection;
