import { createContext } from 'react';

export type ScorecardMutationContextData = {
  triggerScorecardReload: (isRequestActive?: () => boolean) => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ScorecardMutationContext = createContext<ScorecardMutationContextData>({
  triggerScorecardReload: async () => {},
});

export default ScorecardMutationContext;
