import { css } from '@emotion/react';
import Dropdown, { DropdownOption } from '~/neo-ui/packages/dropdown/Dropdown';
import Button from '~/neo-ui/packages/button/Button';
import Icon from '~/neo-ui/packages/icon/Icon';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import * as React from 'react';
import useEngagementActionListContext from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListContext';
import { stringToEngagementActionStatusFilter } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/types/EngagementActionStatusFilter';
import usePanelTabGroupContext from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/hooks/usePanelTabGroupContext';
import { EngagementType } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/EngagementDashboard';
import EngagementActionDashboardCreateControls from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-action-dashboard-panel-tab/packages/engagement-action-dashboard-create-controls/EngagementActionDashboardCreateControls';
import EngagementActionCreateForm from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/packages/engagement-action-create-form/EngagementActionCreateForm';
import useStrategyDashboardUrlContext from '~/wm/packages/strategy/packages/strategy-dashboard/context/hooks/useStrategyDashboardUrlContext';
import useEngagementActionCreate from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionCreate';
import EngagementActionDashboardCard from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-action-dashboard-panel-tab/packages/engagement-action-dashboard-card/EngagementActionDashboardCard';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import { InitiativeCreateSource } from '~/wm/packages/strategy/model/strategyDataCollectionSources';

export type EngagementActionDashboardPanelTabProps = {
  initiativeCreatedFrom: InitiativeCreateSource;
};

const EngagementActionDashboardPanelTab = ({ initiativeCreatedFrom }: EngagementActionDashboardPanelTabProps) => {
  const { organizationId } = useOrganizationContext();
  const { actions, statusAvailabilities, statusCounts, hasNextPage, clearCreateAnimationCache } = useEngagementActionListContext();
  const { createEngagementAction } = useEngagementActionCreate();
  const { engagementActionListUrl } = useStrategyDashboardUrlContext();

  const { currentFilterValueByTab, currentPageByTab, currentTabId, setCurrentTabPage, setCurrentTabFilterValue } =
    usePanelTabGroupContext<EngagementType>();

  const statusOptions: DropdownOption[] = statusAvailabilities.map(statusAvailability => {
    const statusCount = statusCounts.find(statusCount => statusCount.id === statusAvailability.id)?.count ?? 0;

    return {
      label: `${statusAvailability.displayLabel} (${statusCount})`,
      value: statusAvailability.id,
      disabled: statusCount === 0,
    };
  });
  const selectedFilterStatus = currentFilterValueByTab.get(currentTabId);

  if (typeof selectedFilterStatus === 'undefined') {
    throw new Error('Current filter is undefined from tab context');
  }

  const selectedFilterOption = statusOptions.find(statusOption => statusOption.value === selectedFilterStatus);

  const currentPage = currentPageByTab.get(currentTabId);

  if (typeof currentPage === 'undefined') {
    throw new Error('Current page is undefined from tab context');
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 1rem;
          align-items: center;
        `}
      >
        <Dropdown
          buttonCss={css`
            width: 12.5rem;
          `}
          selectedOption={selectedFilterOption}
          options={statusOptions}
          onOptionSelected={option => {
            const statusOption = statusOptions.find(
              statusOption => statusOption.value === stringToEngagementActionStatusFilter(option.value),
            );

            if (typeof statusOption === 'undefined') {
              throw new Error('Station option is undefined');
            }

            if (statusOption.value !== stringToEngagementActionStatusFilter('Upcoming')) {
              clearCreateAnimationCache();
            }

            setCurrentTabFilterValue(statusOption.value);
            setCurrentTabPage(1);
          }}
        />
        <div
          css={css`
            margin-left: auto;
            display: flex;
            gap: 1rem;
          `}
        >
          <div
            css={css`
              display: flex;
            `}
          >
            <Button
              onClick={() => {
                setCurrentTabPage(currentPage - 1);
              }}
              disabled={currentPage === 1}
            >
              <Icon icon="ArrowLeft" />
            </Button>
            <Button
              onClick={() => {
                setCurrentTabPage(currentPage + 1);
              }}
              disabled={!hasNextPage}
            >
              <Icon icon="ArrowRight" />
            </Button>
          </div>
          <ButtonLink
            anchor={{
              href: engagementActionListUrl.replace('__ORGANIZATION_ID__', organizationId),
            }}
            iconRight={'ArrowRight'}
          >
            View All
          </ButtonLink>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
        `}
      >
        <EngagementActionCreateForm
          organizationId={organizationId}
          createEngagementAction={createEngagementAction}
        >
          <EngagementActionDashboardCreateControls initiativeCreatedFrom={initiativeCreatedFrom} />
        </EngagementActionCreateForm>

        {actions.map(action => (
          <div
            key={action.engagementActionId}
            /**
             * Used to cut off the animation when the action mark/unmarked as completed
             *
             * We want to cut off the animation at the edge of the widget without affecting the
             * overflow causing the assign users dropdown menu to trigger the widget to overflow.
             *
             * Ref: 6f2e61e2-7079-4094-96a6-dd5d9f635f25
             */
            css={css`
              margin: -1.25rem;
              padding: 1.25rem;
              overflow-x: hidden;
            `}
          >
            <EngagementActionDashboardCard
              action={action}
              initiativeCreatedFrom={initiativeCreatedFrom}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default EngagementActionDashboardPanelTab;
