import { css } from '@emotion/react';
import * as React from 'react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import ToggleSwitch from '~/neo-ui/packages/toggle/packages/toggle-switch/ToggleSwitch';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Spinner from '~/neo-ui/spinner/Spinner';

export type SettingsToggleProps = {
  onCustomize: () => void;
  onResetToDefault: () => void;
  isUsingDefault: boolean;
  isToggling: boolean;
};

const CustomizeSettingsToggle: React.FunctionComponent<SettingsToggleProps> = ({
  onCustomize,
  onResetToDefault,
  isToggling,
  isUsingDefault,
}) => {
  const { isSubmitting } = useFormContext();

  return (
    <div
      css={css`
        flex: 1;
        display: flex;
        align-items: center;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-right: 0.625rem;
        `}
      >
        <ToggleSwitch
          onChange={() => (isUsingDefault ? onCustomize() : onResetToDefault())}
          checked={!isUsingDefault}
          theme="success"
        />
      </div>
      {isToggling ? (
        <Spinner
          color={isUsingDefault ? undefined : 'positive-400'}
          css={css`
            width: 1rem;
            height: 1rem;
          `}
        />
      ) : (
        <>
          {isSubmitting && (
            <Spinner
              color={'positive-400'}
              css={css`
                width: 1rem;
                height: 1rem;
                margin-right: 0.3125rem;
              `}
            />
          )}
          <span
            css={css`
              font-weight: bold;
              color: ${!isUsingDefault || isSubmitting ? colorToCode('positive-400') : 'unset'};
            `}
          >
            {isSubmitting ? 'Saving customized settings…' : !isUsingDefault ? 'Customized' : 'Using defaults'}
          </span>
        </>
      )}
    </div>
  );
};

export default CustomizeSettingsToggle;
