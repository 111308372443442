import * as React from 'react';
import BannerUpgrade from '~/neo-ui/packages/banner/packages/banner-upgrade/BannerUpgrade';
import { css } from '@emotion/react';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';

export type DisposalUpgradeBannerProps = {
  growUrl: string;
};

const DisposalUpgradeBanner: React.FunctionComponent<DisposalUpgradeBannerProps> = ({ growUrl }) => {
  const { trackEvent } = useEventTracking(TrackingServices.EventProcessor);

  return (
    <BannerUpgrade
      title={'Upgrade to access free IT Asset Disposal.'}
      description={
        <div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.25rem;
            `}
          >
            {"It looks like you're on a non-current plan!"}
            <Tooltip
              backgroundColor={'light-000'}
              content={"Non-current plan means you're not on term and/or on older generation pricing."}
              placement={'top-start'}
              css={css`
                position: absolute;
              `}
            >
              <Icon
                icon={'Info'}
                sizePx={16}
                color={'dark-900-24'}
                css={css`
                  display: flex;
                `}
              />
            </Tooltip>
          </div>
          {
            "With an account upgrade, we'll waive all per asset disposal and data destruction fees so you can process unlimited free disposals."
          }
        </div>
      }
      imageSource={'/i/marketing/disposal/image.png'}
      callToAction={{
        text: 'Get started',
        link: growUrl,
        onClick: () => trackEvent('disposal_upgrade_banner_upgrade_clicked', {}),
      }}
      css={css`
        margin-bottom: unset;
      `}
      boxShadow={'shadow200'}
    />
  );
};

export default DisposalUpgradeBanner;
