import { css } from '@emotion/react';
import * as React from 'react';
import Theme from '~/neo-ui/packages/color/Theme';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import { Styleable } from '~/neo-ui/model/capacity';

export type ModalActionHeaderProps = {
  icon?: IconType;
  title: string;
  theme: Theme;
} & Styleable;

const ModalActionHeader: React.FunctionComponent<ModalActionHeaderProps> = ({ icon, title, theme, className }) => {
  const { themeMap } = useTheme(theme);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
      className={className}
    >
      {typeof icon !== 'undefined' && (
        <Icon
          icon={icon}
          sizePx={40}
          color={themeMap.foregroundAccent}
        />
      )}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        `}
      >
        <Header
          size={3}
          color={themeMap.foregroundAccent}
          weight={'bold'}
        >
          {title}
        </Header>
      </div>
    </div>
  );
};

export default ModalActionHeader;
