import React from 'react';
import { css } from '@emotion/react';
import Image from '~/neo-ui/packages/image/Image';
import Header from '~/neo-ui/packages/text/packages/header/Header';

const WarrantyCartEmptyState: React.FunctionComponent = () => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      `}
    >
      <Image
        css={css`
          width: 7.5rem;
          height: 7.5rem;
        `}
        source={'/i/graphic/hardware/covered/laptop-desktop-network/secondary.png'}
      />
      <Header
        size={3}
        weight={'light'}
      >
        No warranties available to buy
      </Header>
      <div>Buy warranties to extend your coverage of networking assets.</div>
    </div>
  );
};

export default WarrantyCartEmptyState;
