import usePeopleDashboardMutation, {
  UsePeopleDashboardMutationOptions,
} from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/usePeopleDashboardMutation';
import { organizationAccountTeamMemberRemove } from '@AssetManagementClient/AssetManagementClientMsp.gen';

type OrganizationTeamMemberRemovePayload = {
  organizationId: string;
  organizationAccountTeamMemberId: string;
};

export type UseOrganizationAccountTeamMemberRemove = {
  removeOrganizationAccountTeamMember: (removePayload: OrganizationTeamMemberRemovePayload) => void;
  isRemovingOrganizationAccountTeamMember: boolean;
};

const useOrganizationAccountTeamMemberRemove = (options?: UsePeopleDashboardMutationOptions): UseOrganizationAccountTeamMemberRemove => {
  const [removeOrganizationAccountTeamMember, isRemovingOrganizationAccountTeamMember] = usePeopleDashboardMutation(
    (createPayload: OrganizationTeamMemberRemovePayload) =>
      organizationAccountTeamMemberRemove({
        organizationId: createPayload.organizationId,
        organizationAccountTeamMemberId: createPayload.organizationAccountTeamMemberId,
      }),
    options,
  );

  return {
    removeOrganizationAccountTeamMember,
    isRemovingOrganizationAccountTeamMember,
  };
};

export default useOrganizationAccountTeamMemberRemove;
