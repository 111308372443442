import * as React from 'react';
import DisposalOrderAssetSelectContext from './DisposalOrderAssetSelectContext';
import useDisposalOrderAssetSelect from '../hooks/useDisposalOrderAssetSelect';

export type DisposalOrderAssetSelectProviderProps = {
  onClearAssetIds?: () => void;
};

const DisposalOrderAssetSelectProvider: React.FunctionComponent<React.PropsWithChildren<DisposalOrderAssetSelectProviderProps>> = ({
  onClearAssetIds,
  children,
}) => {
  const { disposalOrderAssetIds, clearDisposalOrderAssetIds, updateSelectedDisposalOrderAssetIds } = useDisposalOrderAssetSelect();

  return (
    <DisposalOrderAssetSelectContext.Provider
      value={{
        disposalOrderAssetIds,
        clearDisposalOrderAssetIds: () => {
          clearDisposalOrderAssetIds();
          if (typeof onClearAssetIds !== 'undefined') {
            onClearAssetIds();
          }
        },
        updateSelectedDisposalOrderAssetIds,
      }}
    >
      {children}
    </DisposalOrderAssetSelectContext.Provider>
  );
};

export default DisposalOrderAssetSelectProvider;
