import { css } from '@emotion/react';
import { AddressBusiness } from '@SubscriptionClient/Common/Location/Model/Address.gen';
import { Discriminant } from '@SubscriptionClient/Primitives/Results/SubscriptionUpgradeControllerNested/Response_/SubscriptionUpgradeError_/ResultNested.gen';
import { subscriptionUpgrade } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { produce } from 'immer';
import * as React from 'react';
import useAlert from '~/legacy-ui/packages/alert/hooks/useAlert';
import { getCountryByIso2 } from '~/neo-ui/model/Country';
import {
  CreditCardFormData,
  PaymentVersion,
} from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import WizardContainer from '~/neo-ui/packages/wizard/packages/wizard-container/WizardContainer';
import useApi from '~/wm/packages/api/hook/useApi';
import { ApiServerErrorResponseDto } from '~/wm/packages/api/packages/api-error/model/ApiServerErrorResponseDto';
import useBillingInfo from '~/wm/packages/subscription/packages/billing/hooks/useBillingInfo';
import SubscriptionConfirmation from '~/wm/packages/subscription/packages/manage/wizard/step/confirmation/SubscriptionConfirmation';
import useSubscriptionUpgradeAvailabilities from '~/wm/packages/subscription/packages/upgrade/packages/upgrade-availabilities/useSubscriptionUpgradeAvailabilities';
import getUpgradeBillingInfoStep from '~/wm/packages/subscription/packages/upgrade/wizard/step/getUpgradeBillingInfoStep';
import getUpgradeSubscriptionAgreementStep from '~/wm/packages/subscription/packages/upgrade/wizard/step/getUpgradeSubscriptionAgreementStep';
import { SubscriptionAgreementData } from '~/wm/packages/subscription/packages/manage/wizard/step/getSubscriptionAgreementStep';
import { SubscriptionUpdateSuccessDto } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/Success.gen';
import SubscriptionUpdateSuccess from '~/wm/packages/subscription/packages/grow/wizard/success/SubscriptionUpdateSuccess';
import { SelectedProductRegion } from '@SubscriptionClient/Subscription/Packages/Upgrade/Model.gen';

export type ProductTierIds = Record<string, string | undefined>;
export type ProductRegions = Record<string, string | undefined>;

export interface UpgradeFormData extends SubscriptionAgreementData {
  billingAddress: AddressBusiness;
  customerToken: CreditCardFormData | undefined;
}

export type UpgradeWizardProps = {
  /**
   * The account's company name
   */
  accountCompanyName: string;

  /**
   *  Used as a default for the country select if there's no billing info on file
   */
  geoLookupCountryIso3: string | undefined;

  /**
   *  Url of subscription agreement page
   */
  subscriptionAgreementUrl: string;

  /**
   * Version of the Payment system
   */
  paymentVersion: PaymentVersion;

  /**
   * The abbreviation of the product that should be preselected for the partner in the form
   */
  preselectProduct: string | undefined;
};

const SubscriptionUpgradeWizard: React.FunctionComponent<UpgradeWizardProps> = ({
  accountCompanyName,
  geoLookupCountryIso3,
  subscriptionAgreementUrl,
  paymentVersion,
  preselectProduct,
}) => {
  const { callApi } = useApi();

  const { billingInfo } = useBillingInfo();
  const { upgradeAvailabilities } = useSubscriptionUpgradeAvailabilities(preselectProduct);

  const [successInfo, setSuccessInfo] = React.useState<SubscriptionUpdateSuccessDto | undefined>();

  const { hideAlert } = useAlert();

  if (!billingInfo || !upgradeAvailabilities) {
    return null;
  }

  const defaultTierIds: ProductTierIds = {};
  const defaultProductRegions: ProductRegions = {};
  upgradeAvailabilities.productAvailabilityPayloads.forEach(availableTiers => {
    defaultTierIds[availableTiers.productEnum.toString()] = '';
  });

  const defaultFormData: UpgradeFormData = {
    selectedTierIds: defaultTierIds,
    selectedProductRegions: defaultProductRegions,
    billingAddress:
      billingInfo.billingAddress && billingInfo.billingAddress.address
        ? produce(billingInfo.billingAddress, draft => {
            // Fallback to best defaults for country/state codes
            draft.address.countryFallback = getCountryByIso2(draft.address.countryCode)?.iso3Code ?? 'USA';

            draft.address.stateFallback = draft.address.stateCode ?? draft.address.stateFallback;
          })
        : {
            address: {
              city: '',
              countryCode: '',
              countryFallback: geoLookupCountryIso3 ?? 'USA',
              line1: '',
              line2: '',
              stateCode: '',
              stateFallback: '',
              zip: '',
            },
            name: accountCompanyName,
            phone: '',
          },
    customerToken:
      // We technically can provide the default billing info,
      // but there is a bug on upgrade that we're avoiding
      // by not showing existing card info: WM-3982
      //
      // Further remarks: Unsure if this affects my implementation in Disposal, but I'm leaving a
      // GUID to reference that for whoever that looks at this can double check. 9b951c9c-6a6b-4e55-b5fd-dc032a2a5f1e
      //
      //   billingInfo.paymentInfo
      // ? {
      //     type: 'card-on-file',
      //     last4: billingInfo.paymentInfo.defaultCard.last4Digits,
      //     brand: billingInfo.paymentInfo.defaultCard.brand,
      //   }
      // :
      undefined,
  };

  const subscriptionAgreementLink: AnchorLocation = {
    href: subscriptionAgreementUrl,
    openInNewTab: true,
  };

  return (
    <WizardContainer
      css={css`
        margin-top: 0.9375rem;
      `}
      theme={'secondary-600'}
      defaultFormData={defaultFormData}
      steps={[
        getUpgradeSubscriptionAgreementStep(upgradeAvailabilities, subscriptionAgreementLink),
        getUpgradeBillingInfoStep(billingInfo.paymentMethodEnum, paymentVersion),
      ]}
      summaryHeaderLabel={"Here's your new subscription"}
      summaryHeaderDescription={'Manage it at any time from Billing.'}
      summaryLabel={'subscription details'}
      submitLabel={'Pay now and start subscription'}
      onSubmit={async data => {
        if (typeof data.customerToken === 'undefined') {
          // If the card initializes the token must be specified. This is here as an safety integrity check.
          // This case should be impossible.
          throw new Error('No credit card form data exists at time of submission.');
        }
        const selectedTiers = Array<string>();
        Object.keys(data.selectedTierIds).forEach(product => {
          const tierId = data.selectedTierIds[product];
          if (tierId !== undefined && tierId !== '') {
            selectedTiers.push(tierId);
          }
        });
        const selectedRegions = Array<SelectedProductRegion>();
        Object.keys(data.selectedProductRegions).map(product => {
          const isProductAdded = data.selectedTierIds[product] !== '';
          const regionValue = data.selectedProductRegions[product];
          // If the product is being adopted, send the configurations
          if (regionValue !== undefined && isProductAdded && regionValue !== 'UNSELECTED') {
            selectedRegions.push({
              product,
              selectedRegionValue: regionValue,
            });
          }
        });

        const response = await callApi(
          () =>
            subscriptionUpgrade({
              payload: {
                billingPayload: {
                  billingAddress: data.billingAddress,
                  customerPayload: (() => {
                    if (typeof data.customerToken === 'undefined') {
                      return undefined;
                    }

                    switch (data.customerToken.type) {
                      case 'card-on-file':
                        return undefined;
                      case 'new-card':
                        return {
                          defaultPaymentMethod: {
                            token: data.customerToken.tokenId!,
                          },
                        };
                    }
                  })(),
                },
                targetProductTierIds: selectedTiers,
                selectedProductRegions: selectedRegions,
              },
            }),
          error => {
            throw error;
          },
        );
        if (!response) {
          return 'error';
        }

        if (response.case === Discriminant.Error) {
          const error: ApiServerErrorResponseDto = {
            GlobalMessage: response.value.messagePublic,
            Errors: response.value.fieldErrors.reduce<{
              [index: string]: string[];
            }>(
              (errors, fieldError) => ({
                ...errors,
                [fieldError.fieldKey]: [fieldError.messagePublic],
              }),
              {},
            ),
            // The abstraction is not layered properly that's why
            // we have to specify 400 here - WM-3861
            Status: 400,
          };
          throw error;
        }
        setSuccessInfo(response.value.successDto);

        // This is needed because alerts don't auto-hide - WM-3861
        hideAlert();
        return 'success';
      }}
      ConfirmationComponent={({ isConfirmed, toggleConfirmation }) => (
        <SubscriptionConfirmation
          finalPriceBeforeTaxesCents={undefined}
          billingPeriodEnum={upgradeAvailabilities?.billing.periodEnum}
          hasDiscount={!!upgradeAvailabilities.appliedDiscount.credit || !!upgradeAvailabilities.appliedDiscount.rate}
          isConfirmed={isConfirmed}
          toggleConfirmation={toggleConfirmation}
        />
      )}
      SuccessComponent={() => <SubscriptionUpdateSuccess successDto={successInfo} />}
    />
  );
};

export default SubscriptionUpgradeWizard;
