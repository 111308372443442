import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import ServiceRequest, { convertBeastServiceRequest } from '~/wm/packages/warranty/packages/service-request/model/ServiceRequest';
import { DistributorServiceContactInfo as WarrantyClientWarrantyDistributorModelDistributorServiceContactInfo } from '@WarrantyClient/Warranty/Distributor/Model.gen';
import { Discriminant } from '@WarrantyClient/Primitives/Results/ServiceRequestGetControllerNested/Response_/ServiceRequestGetError_/ResultNested.gen';
import { serviceRequestGet } from '@WarrantyClient/WarrantyClientMsp.gen';

const useServiceRequestGet = (serviceRequestId: string) => {
  const [serviceRequest, setServiceRequest] = useState<ServiceRequest>();
  const [contactInfo, setContactInfo] = useState<WarrantyClientWarrantyDistributorModelDistributorServiceContactInfo>();
  const [isCoverageTransferred, setIsCoverageTransferred] = useState<boolean>();
  const [isCoverageTransferAllowed, setCoverageTransferAllowed] = useState<boolean>(false);

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const result = await callApi(() => serviceRequestGet({ serviceRequestId }));

      if (!result || result.case === Discriminant.Error) {
        return;
      }
      setServiceRequest(convertBeastServiceRequest(result.value.serviceRequest));
      setContactInfo(result.value.serviceContactInfo);
      setIsCoverageTransferred(result.value.isCoverageTransferred);
      setCoverageTransferAllowed(result.value.isCoverageTransferAllowed);
    })();
  }, [callApi, serviceRequestId]);

  return {
    serviceRequest,
    contactInfo,
    isCoverageTransferred,
    isCoverageTransferAllowed,
  };
};

export default useServiceRequestGet;
