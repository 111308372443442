import React, { useCallback } from 'react';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';

import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import SingleSelect from '~/neo-ui/packages/select/packages/single-select/SingleSelect';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import InputNumber from '~/neo-ui/packages/input/packages/input-number/InputNumber';

const rangeOperators = [
  {
    label: 'Between',
    value: 'between',
  },
  {
    label: 'Under',
    value: 'max',
  },
  {
    label: 'Over',
    value: 'min',
  },
];

export type RangeFilterProps = {
  /**
   * Display settings
   */
  placeholderMin?: string;
  placeholderMax?: string;
  label?: string;
  unit?: string;

  /**
   * RangeInput values
   */
  minValue: number | undefined; // given values from parent
  maxValue: number | undefined;
  minLimit: number | undefined;
  maxLimit: number | undefined;

  /**
   * State and actions
   */
  onChange?: (minValue: number | undefined, maxValue: number | undefined) => void; // used to notify if values change
  disabled?: boolean;
  shouldFocus?: boolean;
} & Styleable &
  Themeable; // TODO: find out how to apply themes here

const RangeInput: React.FunctionComponent<RangeFilterProps> = ({
  placeholderMin = '0',
  placeholderMax = '∞',
  label,
  unit,
  minValue,
  maxValue,
  minLimit,
  maxLimit,
  onChange = () => {},
  disabled = false,
  className,
  shouldFocus,
}) => {
  // Determine the default operator to use
  let defaultOperator = 'max';
  if (typeof minValue !== 'undefined' && typeof maxValue !== 'undefined') {
    defaultOperator = 'between';
  } else if (typeof maxValue === 'undefined' && typeof minValue !== 'undefined') {
    defaultOperator = 'min';
  }
  /**
   * State
   */
  const [internalRangeOperator, setInternalRangeOperator] = React.useState<SelectOption>(
    rangeOperators.find(operator => operator.value === defaultOperator) ?? rangeOperators[0],
  );

  const [internalMinValue, setInternalMinValue] = React.useState<number | undefined>(minValue);

  const [internalMaxValue, setInternalMaxValue] = React.useState<number | undefined>(maxValue);

  /**
   * Update the range operator when changed
   */
  const handleOperatorSelection = useCallback(
    (selection: SelectOption | undefined) => {
      if (typeof selection !== 'undefined' && selection !== internalRangeOperator) {
        setInternalRangeOperator(selection);
      }
    },
    [internalRangeOperator],
  );

  /**
   * Update the minimum value when changed
   */
  React.useEffect(() => {
    if (typeof minValue === 'undefined') {
      return;
    }
    if (typeof minLimit !== 'undefined' && minValue < minLimit) {
      setInternalMinValue(minLimit);
    } else {
      setInternalMinValue(minValue);
    }
  }, [minLimit, minValue]);

  /**
   * Update the maximum value when changed
   */
  React.useEffect(() => {
    if (typeof maxValue === 'undefined') {
      return;
    }
    if (typeof maxLimit !== 'undefined' && maxValue > maxLimit) {
      setInternalMaxValue(maxLimit);
    } else {
      setInternalMaxValue(maxValue);
    }
  }, [maxLimit, maxValue]);

  /**
   * Notify parent of internal value changes
   */
  React.useEffect(() => {
    const actualMinimum = internalRangeOperator.value === 'between' || internalRangeOperator.value === 'min' ? internalMinValue : undefined;
    const actualMaximum = internalRangeOperator.value === 'between' || internalRangeOperator.value === 'max' ? internalMaxValue : undefined;
    if (actualMinimum === minValue && actualMaximum === maxValue) {
      return;
    }
    onChange(actualMinimum, actualMaximum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalRangeOperator, internalMinValue, internalMaxValue]);

  /**
   * Autofill 'between' operator values if only one is specified.
   * Triggered when switching between operators, or setting the min / max value
   */
  React.useEffect(() => {
    if (internalRangeOperator.value === 'between') {
      if (typeof internalMaxValue === 'undefined' && typeof internalMinValue !== 'undefined') {
        setInternalMaxValue(maxLimit);
      } else if (typeof internalMaxValue !== 'undefined' && typeof internalMinValue === 'undefined') {
        setInternalMinValue(minLimit);
      }
    }
  }, [internalRangeOperator, internalMaxValue, internalMinValue, maxLimit, minLimit]);

  const shouldMinHaveFocus = React.useCallback(
    () => (shouldFocus as boolean) && (internalRangeOperator.value === 'between' || internalRangeOperator.value === 'min'),
    [shouldFocus, internalRangeOperator.value],
  );

  const shouldMaxHaveFocus = React.useCallback(
    () => (shouldFocus as boolean) && internalRangeOperator.value === 'max',
    [shouldFocus, internalRangeOperator.value],
  );

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
      className={className}
    >
      {label && (
        <Label
          css={css`
            padding: 0.625rem 0.625rem 0.625rem 0;
            white-space: nowrap;
          `}
          bold={true}
        >
          {label}
        </Label>
      )}
      <div
        css={css`
          display: flex;
          flex-direction: row;
          flex-grow: 1;
        `}
      >
        <SingleSelect
          options={rangeOperators}
          selectedOption={internalRangeOperator}
          onOptionSelected={operator => handleOperatorSelection(operator)}
          css={css`
            margin-right: 0.313rem;
            width: 105px;
          `}
          // v this makes the RangeInput div element the parent element of the select menu - fixes issues with click handling
          shouldUseMenuPortal={false}
        />
        {(internalRangeOperator.value === 'between' || internalRangeOperator.value === 'min') && (
          <InputNumber
            className={`${className ?? 'range'}-min`}
            placeholder={placeholderMin}
            setInputNumber={setInternalMinValue}
            value={internalMinValue}
            minLimit={minLimit}
            maxLimit={maxLimit}
            disabled={disabled}
            css={css`
              flex: 1 1 100px;
              margin-right: 0.313rem;
            `}
            hasDefaultFocus={shouldMinHaveFocus}
          />
        )}
        {internalRangeOperator.value === 'between' && (
          <Label
            heightSize={'md'}
            css={css`
              margin-right: 0.313rem;
            `}
          >
            &
          </Label>
        )}
        {(internalRangeOperator.value === 'between' || internalRangeOperator.value === 'max') && (
          <InputNumber
            className={`${className ?? 'range'}-max`}
            placeholder={placeholderMax}
            setInputNumber={setInternalMaxValue}
            value={internalMaxValue}
            minLimit={minLimit}
            maxLimit={maxLimit}
            disabled={disabled}
            css={css`
              margin-right: 0.313rem;
              flex: 1 1 100px;
            `}
            hasDefaultFocus={shouldMaxHaveFocus}
          />
        )}
        {unit && (
          <Label
            heightSize={'md'}
            css={css`
              white-space: nowrap;
            `}
          >
            {unit}
          </Label>
        )}
      </div>
    </div>
  );
};

export default RangeInput;
