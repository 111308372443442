import { createContext } from 'react';
import { Enum as UserPermissionEnum } from '@SsoClient/BeastClient/Beast/Identity/Model/UserPermission/UserPermissionFactoryNested.gen';
import { AccountAccessFlags } from '~/wm/hooks/useAccount';
import { FeatureAccessDto } from '@SsoClient/BeastClient/Beast/Feature/Packages/Access/Dto/Model.gen';
import { FeaturePermissionDto } from '@SsoClient/BeastClient/Beast/Feature/Packages/Permission/Dto/Model.gen';

export type UserHasPermission = (permissionToCheck: UserPermissionEnum) => boolean;
export type UserHasAccess = (accessToCheck: AccountAccessFlags) => boolean;
export type UserContextData = {
  id: string;
  hubUserId: string;
  fullName: string;
  getUserAccess: UserHasAccess;
  permissions: UserPermissionEnum[];
  getUserPermission: (permissionToCheck: UserPermissionEnum) => boolean;
  /**
   * Check if the user has a specific permission or is an AccountAdmin
   */
  hasPermission: UserHasPermission;
  status: string;
  email: string;
  canReceiveEmail: boolean;
  featureAccessList: FeatureAccessAvailabilities;
  featurePermissionList: FeaturePermissionAvailabilities;
};

export type UserAccess = { [key in AccountAccessFlags]: boolean };

/**
 * Duplicated in backend A90DF74F-D5BE-48C2-A16D-823469028FA8
 */
export type UserPermissions =
  | 'AccountAdmin'
  | 'ManageAppSettings'
  | 'ManageBilling'
  | 'PlaceOrders'
  | 'ManageIntegration'
  | 'ImpersonateUser'
  | 'ManageFeatures'
  | 'DevOps'
  | 'ManageProcurement'
  | 'ManageSubscription'
  | 'Finance'
  | 'GenerateSatisfactionCoupon'
  | 'GenerateIrIncentiveCoupon'
  | 'Success'
  | 'ManageSales'
  | 'PerformanceExecutive'
  | 'PerformanceDashboard'
  | 'MarketingOperations'
  | 'GenerateDisposalIncentiveCoupon'
  | 'ManagePricing'
  | 'ManageOrder'
  | 'ManageOption'
  | 'HandleSupport'
  | 'ManageDisposalOrder';

export type UserStatus = 'Confirmed' | 'Invited' | 'SignedUp' | 'Disabled' | 'Deleted' | 'Canceled';

export type FeatureAccessAvailabilities = { [featureAccessKey: string]: FeatureAccessDto };
export type FeaturePermissionAvailabilities = { [featurePermissionsKey: string]: FeaturePermissionDto };

// eslint-disable-next-line @typescript-eslint/naming-convention
const UserContext = createContext<UserContextData>({
  id: '',
  hubUserId: '',
  fullName: '',
  getUserAccess: () => false,
  permissions: [],
  getUserPermission: () => false,
  /**
   * Check if the user has a specific permission or is an AccountAdmin
   */
  hasPermission: () => false,
  status: '',
  email: '',
  canReceiveEmail: false,
  featureAccessList: {},
  featurePermissionList: {},
});

export default UserContext;
