import { SupportedIntegrationDto } from '@BeastClient/Beast/Integration/Dto/Model.gen';

export type IntegrationEnhanced = {
  integration: SupportedIntegrationDto;
  formerNamesJoined: string;
  supportsTwoWaySync: number;
  supportsSoftware: boolean;
  supportsContactDeviceLink: boolean;
};

export function camelToLabel(str: string): string {
  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^[a-z]/, m => m.toUpperCase())
    .trim();
}

export function toEnhancedIntegration(integration: SupportedIntegrationDto): IntegrationEnhanced {
  return {
    integration,
    formerNamesJoined: integration.formerNames.join(', '),
    supportsTwoWaySync: (integration.supportsPurchaseDateTwoWaySync ? 1 : 0) + (integration.supportsWarrantyExpiryDateTwoWaySync ? 2 : 0),
    supportsSoftware: integration.features.hasOwnProperty('software'),
    supportsContactDeviceLink: integration.features.hasOwnProperty('contactDeviceLink'),
  };
}
