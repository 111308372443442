import * as React from 'react';
import { formatDateFriendly, formatTimeFriendly } from '~/extensions/packages/date/formatDate';
import TextBlock from '~/neo-ui/packages/text/packages/text-block/TextBlock';

export type DisposalOrderBulkPickupSectionPickupAvailabilityScheduleProps = {
  startDateTime: Date;
  endDateTime: Date;
  pickupNotes: string | undefined;
};

const DisposalOrderBulkPickupSectionPickupAvailabilitySchedule = ({
  startDateTime,
  endDateTime,
  pickupNotes,
}: DisposalOrderBulkPickupSectionPickupAvailabilityScheduleProps) => (
  <TextBlock
    size={'sm'}
    title={
      <span>
        Between <b>{formatDateFriendly(startDateTime)}</b> and <b>{formatDateFriendly(endDateTime)}</b> from{' '}
        <b>{formatTimeFriendly(startDateTime)}</b> to <b>{formatTimeFriendly(endDateTime)}</b>
      </span>
    }
    titleColor={'secondary-400'}
    icon={'DateTime'}
    iconColor={'secondary-400'}
    description={pickupNotes}
  />
);

export default DisposalOrderBulkPickupSectionPickupAvailabilitySchedule;
