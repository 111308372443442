import { produce } from 'immer';
import { AvailableFilters, Filter, Metadata, TableState } from '~/neo-ui/packages/table/packages/filter-table/FilterTable';

const handleFilterChange = <T>(
  newSelected: string[],
  { linking, options, value }: Filter,
  metadata: Metadata,
  tableState: TableState<T>,
): TableState<T> => {
  const defaultTableState = produce(tableState, draft => {
    draft.filters!.selectedFilters = produce(draft.filters!.selectedFilters, (draft: Map<string, Set<string>>) => {
      if (newSelected.length === 0) {
        // When there are no values selected, the API expects such filters
        // to not be in the map (rather than be present with no values).
        // Same logic here: {ececef23-8d1a-4543-9e82-6b6175339158}
        draft.delete(value);
      } else {
        draft.set(value, new Set(newSelected));
      }
    });
  });

  // if there are no links from this filter then don't do anything
  if (!linking) {
    return defaultTableState;
  }

  const selectedValues =
    newSelected.length === 0
      ? // if nothing is selected === everything is selected
        options.map(option => option.value)
      : newSelected;

  // the values that the linked filter should show now due to the selection
  const linkedValues = ([] as string[]).concat(
    ...selectedValues.map(selectedValue => Array.from(linking.linkedFilterOptions.get(selectedValue) ?? new Set<string>())),
  );

  if (linkedValues.length === 0) {
    // no links
    return defaultTableState;
  }

  const linkedFilter = metadata.filterSet.filters.find(filter => filter.value === linking.filterValue)!;

  // what other selected filters also link to `linkedFilter`
  const otherLinkingFilters = metadata.filterSet.filters.filter(
    filter =>
      tableState.filters!.selectedFilters.has(filter.value) && filter.value !== value && filter.linking?.filterValue === linkedFilter.value,
  );

  // what values of the `linkedFilter` are linked by other filters
  const otherLinkedValues = ([] as string[]).concat(
    ...otherLinkingFilters.map(filter => {
      const linkedSelectedValues = Array.from(tableState.filters!.selectedFilters.get(filter.value)!);
      return ([] as string[]).concat(
        ...linkedSelectedValues.map(selectedValue =>
          Array.from(filter.linking?.linkedFilterOptions.get(selectedValue) ?? new Set<string>()),
        ),
      );
    }),
  );

  const newOptionsValues = linkedFilter.options.filter(option =>
    linkedValues.some(linkedVal => {
      // combine options from all the linkedValues
      let include = linkedVal.includes(option.value);
      // perform an intersection if other links exist
      if (include && otherLinkedValues && otherLinkedValues.length > 0) {
        include &&= otherLinkedValues.includes(option.value);
      }
      return include;
    }),
  );

  const optionValues = newOptionsValues.map(optionValue => optionValue.value);

  // only keep selected values of the `linkedFilter` that are in the new available options
  const updatedLinkedFilterValues = Array.from(tableState.filters!.selectedFilters.get(linkedFilter.value) ?? new Set<string>()).filter(
    selectedValue => optionValues.includes(selectedValue),
  );

  const selectedFilters = produce(tableState.filters!.selectedFilters, (draft: Map<string, Set<string>>) => {
    if (newSelected.length === 0) {
      // When there are no values selected, the API expects such filters
      // to not be in the map (rather than be present with no values).
      // Same logic here: {ececef23-8d1a-4543-9e82-6b6175339158}
      draft.delete(value);
    } else {
      draft.set(value, new Set(newSelected));
      if (updatedLinkedFilterValues.length > 0) {
        draft.set(linkedFilter.value, new Set(updatedLinkedFilterValues));
      } else {
        draft.delete(linkedFilter.value);
      }
    }
  });

  const availableFilters = produce(tableState.filters!.availableFilters, (draft: AvailableFilters) => {
    draft.set(linkedFilter.value, {
      ...linkedFilter,
      options: newOptionsValues,
    });
  });

  return produce(tableState, draft => {
    draft.filters!.selectedFilters = selectedFilters;
    draft.filters!.availableFilters = availableFilters;
  });
};

export default handleFilterChange;
