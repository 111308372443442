import { Request as RenameRequest } from '@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Rename/Controller/OrganizationRenameControllerNested.gen';
import { organizationRename } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { Discriminant } from '@AssetManagementClient/Primitives/Results/OrganizationRenameControllerNested/Response_/OrganizationRenameError_/ResultNested.gen';
import { OperationDefinition } from '~/legacy-ui/packages/operation/model/buildOperation';
import translateToGlobalError from '~/wm/packages/api/packages/api-error/convert/translateToGlobalError';
import callApiOrError from '~/wm/packages/api/packages/api-result/callApiOrError';
import Organization from '~/wm/packages/organization/model/Organization';
import linkingDescription from '../../../../../operation/linkingDescription';
import linkingProgress from '../../../../../operation/linkingProgress';

type RenameContext = {
  targetOrganization: Organization;
  newName: string;
};

const organizationRenameOperation: OperationDefinition<RenameContext> = {
  label: 'Renaming Linked Client',
  description: linkingDescription,
  action: ({ targetOrganization, newName }) => {
    const renameRequest: RenameRequest = {
      organizationIdToRename: targetOrganization.id,
      name: newName,
    };
    return translateToGlobalError(
      () => callApiOrError(() => organizationRename(renameRequest)),
      response => (response.case === Discriminant.Error ? { message: response.value.message } : undefined),
    );
  },
  sensitivity: {
    type: 'confirmation-required',
    theme: 'primary',
    confirmLabel: 'Apply',
  },
  actions: ({ targetOrganization, newName }) => [
    {
      label: `Rename to  ${newName}`,
      description: `You'll see this instead of ${targetOrganization.name}.`,
    },
  ],
  icon: 'Edit',
  progress: ({ targetOrganization }) => linkingProgress({ parentOrganizationId: targetOrganization.id }),
};

export default organizationRenameOperation;
