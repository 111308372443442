import { css } from '@emotion/react';
import { SubscriptionBillingPeriodEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription.gen';
import { ProductDto } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/PricingModel.gen';
import * as React from 'react';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import Info from '~/legacy-ui/packages/info/Info';
import getBillingPeriodDisplay from '~/wm/packages/subscription/packages/billing/operation/getBillingPeriodDisplay';
import ProductChargeItem from '~/wm/packages/subscription/packages/plan/packages/product-summary/ProductChargeItem';
import { ProductConsumptionDto } from '@SubscriptionClient/Subscription/Packages/Consumption/Model.gen';
import ConsumptionMeter from '~/wm/packages/subscription/packages/plan/packages/consumption-meter/ConsumptionMeter';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ProductSummaryProps = {
  productsDto: ProductDto[] | undefined;
  productConsumptionDto?: ProductConsumptionDto[] | undefined;
};

const ProductSummary = ({ productsDto, productConsumptionDto }: ProductSummaryProps) => {
  if (productsDto === undefined) {
    return <div />;
  }
  return (
    <div>
      {productsDto.map((product: ProductDto) => {
        const productConsumption = productConsumptionDto?.find(productConsumption => productConsumption.productEnum === product.uniqueId);
        return (
          <div key={product.uniqueId}>
            <div
              css={css`
                display: flex;
                align-items: center;
                margin-bottom: 0.625rem;
                justify-content: space-between;
              `}
            >
              <img
                css={css`
                  height: 2.5rem;
                  padding-top: 0.5rem;
                  padding-bottom: 0.5rem;
                  flex-shrink: 0;
                `}
                src={product.icon}
              />
              {product.monthlyPrice > 0 && (
                <Info
                  css={css`
                    margin-top: 0.375rem;
                  `}
                  title={`${formatCurrency(product.monthlyPrice, 'USD')}/${
                    getBillingPeriodDisplay(SubscriptionBillingPeriodEnum.Monthly).noun
                  }`}
                  titleSize={'md'}
                  titleColor={'secondary-400'}
                />
              )}
            </div>
            {!productConsumption && (
              <ul>
                {product.inclusions.map((inclusion, index) => (
                  <li
                    css={css`
                      margin-bottom: 0.313rem;
                    `}
                    key={index}
                  >
                    {inclusion}
                  </li>
                ))}
              </ul>
            )}
            {productConsumption && (
              <ul>
                {productConsumption.productSummaryLabel && (
                  <li
                    css={css`
                      margin-bottom: 0.313rem;
                    `}
                  >
                    {productConsumption.productSummaryLabel}
                  </li>
                )}
                {productConsumption.productConsumptionDetailDtos.map((productConsumptionDetail, index) => (
                  <li
                    css={css`
                      margin-bottom: 0.313rem;
                    `}
                    key={index}
                  >
                    {productConsumptionDetail.productConsumptionLabel}
                    <ConsumptionMeter
                      currentConsumption={productConsumptionDetail.productUnitUsage}
                      maximumLimit={productConsumptionDetail.productUnitLimit}
                      css={css`
                        margin-top: 0.3125rem;
                        margin-bottom: 0.938rem;
                      `}
                    />
                  </li>
                ))}
                {productConsumption.expansionConsumptions.map((expansionInclusion, index) => (
                  <li
                    css={css`
                      margin-bottom: 0.313rem;
                    `}
                    key={index}
                  >
                    {expansionInclusion}
                  </li>
                ))}
              </ul>
            )}
            <div>
              <hr
                css={css`
                  margin-top: 0.625rem;
                  margin-bottom: 1rem;
                  color: ${colorToCode('dark-900-12')};
                `}
              />
            </div>
          </div>
        );
      })}
      <div>
        {productsDto.map((product: ProductDto) => (
          <div key={[product.uniqueId].join('.')}>
            <ProductChargeItem
              productLabel={product.label}
              chargeDtos={product.chargeDtos}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductSummary;
