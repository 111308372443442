import { css } from '@emotion/react';
import { Enum } from '@SubscriptionClient/BeastClient/Beast/Msp/Logic/Subscription/Expansion/SubscriptionExpansionFactoryNested.gen';
import { ProductSkuInfo } from '@SubscriptionClient/Subscription/Packages/Pricing/Model.gen';
import * as React from 'react';
import formatCurrency, { CurrencyCode } from '~/extensions/packages/currency/formatCurrency';
import { Styleable } from '~/neo-ui/model/capacity';
import SkuItem from '~/wm/packages/subscription/packages/plan/packages/sku-item/SkuItem';

export type ProductSkuSummaryProps = {
  productSkuInfo: ProductSkuInfo;
  currencyCode: CurrencyCode;
  displayCurrencyCode: boolean;
} & Styleable;

const ProductSkuSummary = ({ productSkuInfo, currencyCode, displayCurrencyCode, className }: ProductSkuSummaryProps) => {
  const productUnitSections = productSkuInfo.productUnitPayloads.map((productUnit, index) => (
    <SkuItem
      key={index}
      header={'Includes up to'}
      title={productUnit.maxUnitCount.toLocaleString('en-US')}
      titleColor={'primary-400'}
      description={productUnit.label}
      /*
          {...(typeof hardwareCount !== 'undefined' && {
             footer: `${hardwareCount.toLocaleString()} currently synced`,
          })}
          */
    />
  ));
  return (
    <div
      className={className}
      css={css`
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-right: -0.625rem;
      `}
    >
      <div
        css={css`
          margin-right: 0.625rem;
        `}
      >
        <SkuItem
          header="Plan Price"
          title={formatCurrency(productSkuInfo.skuPriceMonthly, currencyCode)}
          titleColor={'secondary-400'}
          description={'Per month'}
          {...(displayCurrencyCode && { footer: currencyCode })}
        />
      </div>
      {productUnitSections}
      {productSkuInfo.expansions.some(expansion => expansion.expansionEnum === Enum.Sam) && (
        <div
          css={css`
            margin-right: 0.625rem;
          `}
        >
          <SkuItem
            header={'Plus'}
            title={'Unlimited'}
            titleColor={'primary-400'}
            description={'Software assets'}
          />
        </div>
      )}
    </div>
  );
};

export default ProductSkuSummary;
