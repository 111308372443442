import { createContext } from 'react';

export type EmailContextData = {
  supportEmail: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const EmailContext = createContext<EmailContextData | undefined>(undefined);

export default EmailContext;
