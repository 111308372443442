import Badge from '~/neo-ui/packages/badge/Badge';
import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import Icon from '~/neo-ui/packages/icon/Icon';
import * as React from 'react';
import usePanelTabGroupContext from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/hooks/usePanelTabGroupContext';
import { EngagementType } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/EngagementDashboard';
import EngagementActionTable from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-action-list-panel-tab/packages/engagement-action-table/EngagementActionTable';
import EngagementActionCreateForm from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/packages/engagement-action-create-form/EngagementActionCreateForm';
import EngagementActionCreateWindow from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-action-list-panel-tab/packages/engagement-action-create-window/EngagementActionCreateWindow';
import { useThemes } from '~/neo-ui/packages/color/hooks/useTheme';
import Theme from '~/neo-ui/packages/color/Theme';
import ToggleTileHorizontal from '~/neo-ui/packages/toggle/packages/toggle-tile/packages/toggle-tile-horizontal/ToggleTileHorizontal';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useEngagementActionListContext from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListContext';
import useEngagementActionCreate from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionCreate';
import { InitiativeCreateSource } from '~/wm/packages/strategy/model/strategyDataCollectionSources';

export type EngagementActionListPanelTabProps = {
  initiativeCreatedFrom: InitiativeCreateSource;
};

const EngagementActionListPanelTab = ({ initiativeCreatedFrom }: EngagementActionListPanelTabProps) => {
  const { actions, statusAvailabilities, statusCounts, hasNextPage } = useEngagementActionListContext();

  const { organizationId } = useOrganizationContext();
  const { createEngagementAction } = useEngagementActionCreate();

  const [isCreatingAction, setCreatingAction] = React.useState(false);

  const { currentFilterValueByTab, currentPageByTab, currentTabId, setCurrentTabPage, setCurrentTabFilterValue } =
    usePanelTabGroupContext<EngagementType>();

  const { mapOfThemeMaps } = useThemes(new Set(statusAvailabilities.map(status => status.displayColorTheme as Theme)));

  const currentPage = currentPageByTab.get(currentTabId);

  if (typeof currentPage === 'undefined') {
    throw new Error('Current page is undefined from tab context');
  }

  return (
    <React.Fragment>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 1rem;
          `}
        >
          {statusAvailabilities.map(status => {
            const statusCount = statusCounts.find(statusCount => statusCount.id === status.id);

            const count = statusCount?.count ?? 0;

            const themeMap = mapOfThemeMaps.get(status.displayColorTheme as Theme);

            const isSelected = currentFilterValueByTab.get(currentTabId) === status.displayLabel;

            return (
              <ToggleTileHorizontal
                key={status.id}
                css={css`
                  flex-grow: 1;
                  min-width: 0;
                  background-color: ${colorToCode(isSelected ? 'primary-050' : 'light-000')};
                `}
                title={status.displayLabel}
                type={'single'}
                isSelected={isSelected}
                disabled={false}
                onClick={() => {
                  setCurrentTabFilterValue(status.displayLabel);
                  setCurrentTabPage(1);
                }}
                appendComponent={
                  status.displayCount && (
                    <Badge
                      textColor={themeMap!.foregroundContrast}
                      bgColor={themeMap!.foregroundAccent}
                      fontWeight={'700'}
                    >
                      {count}
                    </Badge>
                  )
                }
              />
            );
          })}
        </div>

        <div
          css={css`
            display: flex;
          `}
        >
          <Button
            size={'sm'}
            theme={'primary'}
            onClick={() => {
              setCreatingAction(true);
            }}
          >
            New Action Item
          </Button>

          <div
            css={css`
              margin-left: auto;
              display: flex;
              gap: 1rem;
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <Button
                size={'sm'}
                onClick={() => {
                  setCurrentTabPage(currentPage - 1);
                }}
                disabled={currentPage === 1}
              >
                <Icon icon="ArrowLeft" />
              </Button>
              <Button
                size={'sm'}
                onClick={() => {
                  setCurrentTabPage(currentPage + 1);
                }}
                disabled={!hasNextPage}
              >
                <Icon icon="ArrowRight" />
              </Button>
            </div>
          </div>
        </div>

        <EngagementActionTable
          actions={actions}
          initiativeCreatedFrom={initiativeCreatedFrom}
        />
      </div>
      {isCreatingAction && (
        <EngagementActionCreateForm
          organizationId={organizationId}
          createEngagementAction={createEngagementAction}
        >
          <EngagementActionCreateWindow
            isOpen={isCreatingAction}
            onDismiss={() => {
              setCreatingAction(false);
            }}
            initiativeCreatedFrom={initiativeCreatedFrom}
          />
        </EngagementActionCreateForm>
      )}
    </React.Fragment>
  );
};

export default EngagementActionListPanelTab;
