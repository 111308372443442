import { useCallback, useState } from 'react';

export type UseSelection<T> = {
  /**
   * Currently selected items in the selection
   */
  selectedItems: Set<T>;
  /**
   * Select a single item
   * @param item to be selected
   */
  selectItem: (item: T) => void;
  /**
   * Deselect a single item
   * @param item to be deselected
   */
  deselectItem: (item: T) => void;
  /**
   * Select many items
   * @param items to be selected
   * @param itemClicked which item was clicked to track lastItem
   */
  selectItems: (items: T[], itemClicked: T | undefined) => void;
  /**
   * Deselect many items
   * @param items to be deselected
   * @param itemClicked which item was clicked to track lastItem
   */
  deselectItems: (items: T[], itemClicked: T | undefined) => void;
  /**
   * Clear entire selection
   */
  clearSelectedItems: () => void;
  /**
   * Is this item selected
   * @param item to check
   */
  isItemSelected: (item: T) => boolean;
  /**
   * Last item that was selected or deselected
   */
  lastItem: T | undefined;

  /**
   * Select one item at a time (overwrites last item(s) with this one)
   * @param item
   */
  selectSingleItem: (item: T) => void;

  /**
   * Overwrite selected options with selection
   * @param items to overwrite selected items with
   * @param itemClicked which item was clicked to track lastItem
   */
  overwriteSelectedItems: (items: T[], itemClicked: T | undefined) => void;

  /**
   * Check if two sets of items are equal sets
   * @param items1 First set of items to be compared
   * @param items2 Second set of items to be compared
   */
  areSetsEqual: (items1: Set<T>, items2: Set<T>) => boolean;
};

const useItemSelection = <T,>(selectedIds?: Set<T>): UseSelection<T> => {
  const [selectedItems, setSelectedItems] = useState<Set<T>>(typeof selectedIds !== 'undefined' ? selectedIds : new Set());
  const [lastItem, setLastItem] = useState<T | undefined>(undefined);

  return {
    selectItem: useCallback((itemToSelect: T) => {
      setSelectedItems(items => new Set(items.add(itemToSelect)));
      setLastItem(itemToSelect);
    }, []),

    deselectItem: useCallback((itemToDeselect: T) => {
      setSelectedItems(items => {
        items.delete(itemToDeselect);
        return new Set(items);
      });
      setLastItem(itemToDeselect);
    }, []),

    selectItems: useCallback((itemsToSelect: T[], item: T | undefined) => {
      setSelectedItems(items => {
        itemsToSelect.forEach(itemToSelect => items.add(itemToSelect));
        return new Set(items);
      });
      setLastItem(item);
    }, []),

    deselectItems: useCallback((itemsToDeselect: T[], item: T | undefined) => {
      setSelectedItems(items => {
        itemsToDeselect.forEach(itemToDeselect => items.delete(itemToDeselect));
        return new Set(items);
      });
      setLastItem(item);
    }, []),

    selectedItems,

    lastItem,

    clearSelectedItems: useCallback(() => {
      setSelectedItems(new Set());
      setLastItem(undefined);
    }, []),

    isItemSelected: useCallback(item => selectedItems.has(item), [selectedItems]),

    selectSingleItem: useCallback((itemToSelect: T) => {
      setSelectedItems(new Set([itemToSelect]));
    }, []),

    overwriteSelectedItems: useCallback((itemsToSelect: T[], item: T | undefined) => {
      setSelectedItems(new Set(itemsToSelect));
      setLastItem(item);
    }, []),

    areSetsEqual: useCallback((items1: Set<T>, items2: Set<T>) => {
      if (items1.size !== items2.size) {
        return false;
      }

      for (const optionValue of items1) {
        if (!items2.has(optionValue)) {
          return false;
        }
      }

      return true;
    }, []),
  };
};

export default useItemSelection;
