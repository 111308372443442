import React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import useDisposalOrderPickupSchedule from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderPickupSchedule';
import disposalOrderPickupFormValidationSchema from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-shipment-section-schedule/packages/disposal-order-pickup-form/validation/disposalOrderPickupFormValidationSchema';
import { DisposalOrderPickupDetailsDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Dto/Model.gen';
import { parseISO } from 'date-fns';

export type DisposalOrderPickupFormData = {
  contactName: string;
  contactEmail: string;
  contactPhoneNumber: string;
  pickupNotes: string;
  requestedDate: Date;
};

type DisposalOrderPickupFormProps = {
  disposalOrderId: string;
  onCancelEdit: () => void;
  disposalOrderPickupDetails: DisposalOrderPickupDetailsDto | undefined;
  children: React.ReactNode;
};

const DisposalOrderPickupForm = ({ disposalOrderId, onCancelEdit, disposalOrderPickupDetails, children }: DisposalOrderPickupFormProps) => {
  const { schedulePickup } = useDisposalOrderPickupSchedule();

  const onSubmit = React.useCallback(
    async (data: DisposalOrderPickupFormData) => {
      // Convert the date chosen by the user into the date in their local timezone
      // Otherwise the date converts based on UTC, and we don't get an accurate date!
      const dateInTimezone = parseISO(data.requestedDate.toString());

      schedulePickup({
        payload: {
          disposalOrderId,
          contactName: data.contactName,
          contactEmail: data.contactEmail,
          contactPhoneNumber: data.contactPhoneNumber,
          pickupNotes: data.pickupNotes,
          requestedDate: new Date(dateInTimezone.getFullYear(), dateInTimezone.getMonth(), dateInTimezone.getDate(), 9).toISOString(),
        },
      });

      onCancelEdit();
    },
    [disposalOrderId, onCancelEdit, schedulePickup],
  );

  const toFormData = React.useCallback(
    (): DisposalOrderPickupFormData => ({
      contactEmail: disposalOrderPickupDetails?.contactEmail ?? '',
      contactName: disposalOrderPickupDetails?.contactName ?? '',
      contactPhoneNumber: disposalOrderPickupDetails?.contactPhone ?? '',
      pickupNotes: '',
      // Enable Required error validation by casting
      requestedDate: '' as unknown as Date,
    }),
    [disposalOrderPickupDetails?.contactEmail, disposalOrderPickupDetails?.contactName, disposalOrderPickupDetails?.contactPhone],
  );

  const defaultFormData = React.useMemo(() => toFormData(), [toFormData]);

  return (
    <Form
      submitMethod={'manual'}
      defaultFormData={defaultFormData}
      onSubmit={onSubmit}
      disableSubmitOnEnter={true}
      disableOverwriteDefaultFormDataOnSave={true}
      hideSubmissionButton={true}
      validationSchema={disposalOrderPickupFormValidationSchema}
    >
      {children}
    </Form>
  );
};

export default DisposalOrderPickupForm;
