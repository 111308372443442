import Size from '~/neo-ui/model/Size';

export type BadgeMarketingSize = Extract<Size, 'xs' | 'sm'>;

export type BadgeMarketingSizeDetails = {
  heightRem: number;
  paddingRem: number;
  borderRadiusRem: number;
  iconLabelGapRem: number;
};

const useBadgeMarketingSizeDetails = (size: BadgeMarketingSize): BadgeMarketingSizeDetails => sizeDetailsBySize[size];

const sizeDetailsBySize: Record<BadgeMarketingSize, BadgeMarketingSizeDetails> = {
  xs: {
    heightRem: 1,
    paddingRem: 0.25,
    borderRadiusRem: 0.375,
    iconLabelGapRem: 0.25,
  },
  sm: {
    heightRem: 1.5,
    paddingRem: 0.375,
    borderRadiusRem: 0.25,
    iconLabelGapRem: 0.5,
  },
};

export default useBadgeMarketingSizeDetails;
