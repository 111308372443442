import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import { css } from '@emotion/react';
import useEngagementListPageTicketCreateContext from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/context/hooks/useEngagementListPageTicketCreateContext';

export type EngagementActionTicketCreateButtonProps = {
  action: EngagementActionDto;
};

const EngagementActionTableTicketCreateButton = ({ action }: EngagementActionTicketCreateButtonProps) => {
  const { setSelectedAction } = useEngagementListPageTicketCreateContext();

  return (
    <ButtonLink
      onClick={() => {
        setSelectedAction(action);
      }}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <Icon
          icon={'ActionNew'}
          color={'primary-500'}
        />
      </div>
    </ButtonLink>
  );
};

export default EngagementActionTableTicketCreateButton;
