import useMutationInternal, {
  UseMutationInternal,
  UseMutationInternalApiCall,
  UseMutationInternalOptions,
} from '~/extensions/packages/hooks/useMutationInternal';
import useEngagementNoteListPanelTabMutationContext from './useEngagementNoteListPanelTabMutationContext';

export type UseOrganizationEngagementNoteListPanelTabMutationOptions = UseMutationInternalOptions;

const useEngagementNoteListPanelTabMutation = <TArg = Record<string, unknown>>(
  apiCall: UseMutationInternalApiCall<TArg>,
  options?: UseOrganizationEngagementNoteListPanelTabMutationOptions,
): UseMutationInternal<TArg> => {
  const { triggerEngagementNoteListPanelTabReload } = useEngagementNoteListPanelTabMutationContext();

  return useMutationInternal(apiCall, triggerEngagementNoteListPanelTabReload, options);
};

export default useEngagementNoteListPanelTabMutation;
