import { PageEnum, PageFilter } from '@AssetManagementClient/AssetManagement/Packages/Share/Packages/Send/Model.gen';
import * as React from 'react';
import ShareButton from '~/neo-ui/packages/button/packages/action-button/packages/share-button/ShareButton';
import ShareProps from '~/wm/packages/insight-share/model/ShareProps';
import InsightShareModal from '~/wm/packages/insight-share/packages/insight-share-modal/InsightShareModal';

export type InsightShareButtonProps = {
  filters?: PageFilter[];
} & ShareProps;

export const getPageDefinition = (page: PageEnum): { label: string; labelSentenceCase: string } => {
  switch (page) {
    case PageEnum.HamConsole:
      return { label: 'Hardware', labelSentenceCase: 'hardware' };
    case PageEnum.SamConsole:
      return { label: 'Software', labelSentenceCase: 'software' };
    case PageEnum.AssetConsoleHardware:
      return { label: 'Hardware', labelSentenceCase: 'hardware' };
  }
};

const InsightShareButton: React.FunctionComponent<InsightShareButtonProps> = ({
  userEmail,
  inviteMembersUrl,
  canInviteMembers,
  pageEnum,
  frontendScope,
  filters,
}) => {
  const [isExpanded, setExpanded] = React.useState(false);

  return (
    <div>
      <ShareButton
        onClick={() => setExpanded(true)}
        tooltipText={`Share ${getPageDefinition(pageEnum).labelSentenceCase} search`}
      />
      <InsightShareModal
        isOpen={isExpanded}
        onDismiss={() => {
          setExpanded(false);
        }}
        userEmail={userEmail}
        page={pageEnum}
        frontendScope={frontendScope}
        inviteMembersUrl={inviteMembersUrl}
        canInviteMembers={canInviteMembers}
        filters={filters}
      />
    </div>
  );
};

export default InsightShareButton;
