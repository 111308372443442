import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import * as React from 'react';
import Color from '~/neo-ui/packages/color/Color.gen';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { Styleable } from '~/neo-ui/model/capacity';
import Header from '~/neo-ui/packages/text/packages/header/Header';

export type TextBlockSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type TextBlockDisplayDetails = {
  title: (children: React.ReactNode, color?: Color) => React.ReactNode;
  description: (children: React.ReactNode) => React.ReactNode;
  icon: (icon: IconType, color?: Color) => React.ReactNode;
  gapRem: number;
};

const getTextBlockDisplayDetails: (size: TextBlockSize) => TextBlockDisplayDetails = (size: TextBlockSize) => {
  switch (size) {
    case 'xs':
      return {
        title: (children, color) => (
          <Label
            bold={true}
            color={color}
          >
            {children}
          </Label>
        ),
        description: children => (
          <Label
            size={'sm'}
            color={'dark-900-64'}
          >
            {children}
          </Label>
        ),
        icon: (icon, color) => (
          <Icon
            icon={icon}
            sizePx={16}
            color={color}
          />
        ),
        gapRem: 0.5,
      };
    case 'sm':
      return {
        title: (children, color) => (
          <Label
            bold={true}
            color={color}
          >
            {children}
          </Label>
        ),
        description: children => <Label color={'dark-900-64'}>{children}</Label>,
        icon: (icon, color) => (
          <Icon
            icon={icon}
            color={color}
            sizePx={24}
          />
        ),
        gapRem: 1,
      };
    case 'md':
      return {
        title: (children, color) => (
          <Header
            size={4}
            color={color}
          >
            {children}
          </Header>
        ),
        description: children => <Label color={'dark-900-64'}>{children}</Label>,
        icon: (icon, color) => (
          <Icon
            icon={icon}
            sizePx={24}
            color={color}
          />
        ),
        gapRem: 1,
      };
    case 'lg':
      return {
        title: (children, color) => (
          <Header
            size={3}
            color={color}
          >
            {children}
          </Header>
        ),
        description: children => (
          <Label
            size={'lg'}
            color={'dark-900-64'}
          >
            {children}
          </Label>
        ),
        icon: (icon, color) => (
          <Icon
            icon={icon}
            sizePx={32}
            color={color}
          />
        ),
        gapRem: 1,
      };
    case 'xl':
      return {
        title: (children, color) => (
          <Header
            size={2}
            weight={'bold'}
            color={color}
          >
            {children}
          </Header>
        ),
        description: children => (
          <Label
            size={'lg'}
            color={'dark-900-64'}
          >
            {children}
          </Label>
        ),
        icon: (icon, color) => (
          <Icon
            icon={icon}
            sizePx={40}
            color={color}
          />
        ),
        gapRem: 1,
      };
  }
};

export type TextBlockProps = {
  size?: TextBlockSize;
  title?: string | React.ReactNode;
  titleColor?: Color;
  description?: string | React.ReactNode;
  icon?: IconType;
  iconColor?: Color;
} & Styleable;

/**
 * A common text element that can be used to display a title, description, and icon.
 *
 * The title and description will fill any available space in the parent container.
 */
const TextBlock = ({ size = 'sm', title, titleColor, description, icon, iconColor, className }: TextBlockProps) => {
  const displayDetails = getTextBlockDisplayDetails(size);
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: ${displayDetails.gapRem}rem;
      `}
      className={className}
    >
      {typeof icon !== 'undefined' && displayDetails.icon(icon, iconColor)}
      <div
        css={css`
          flex-grow: 1;
        `}
      >
        {typeof title !== 'undefined' && displayDetails.title(title, titleColor)}
        {typeof description !== 'undefined' && displayDetails.description(description)}
      </div>
    </div>
  );
};

export default TextBlock;
