import { createContext } from 'react';
import WarrantyCartFilterFormData from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-filters/packages/warranty-cart-filter-form/WarrantyCartFilterFormData';

export type WarrantyCartFiltersContextData = {
  filter: WarrantyCartFilterFormData | undefined;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
};

const WarrantyCartFiltersContext = createContext<WarrantyCartFiltersContextData | undefined>(undefined);

export default WarrantyCartFiltersContext;
