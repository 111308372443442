import { css } from '@emotion/react';
import { BillingInfo as BillingInfoModel } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import * as React from 'react';
import formatBillingAddress from '~/wm/packages/subscription/packages/billing/operation/formatBillingAddress';
import BillingInfo from '~/wm/packages/subscription/packages/billing/packages/billing-info/BillingInfo';

export type SubscriptionGrowBillingSummaryProps = {
  billingInfo: BillingInfoModel;
};

const SubscriptionGrowBillingSummary: React.FunctionComponent<SubscriptionGrowBillingSummaryProps> = ({ billingInfo }) => {
  const billingAddressFormatted = formatBillingAddress(billingInfo.billingAddress!);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        text-align: left;
      `}
    >
      <BillingInfo
        billingAddressFormatted={billingAddressFormatted}
        billingName={billingInfo.billingAddress!.name}
        paymentMethod={billingInfo.paymentMethodEnum}
        creditCardLast4Digits={billingInfo.paymentInfo?.defaultCard?.last4Digits}
      />
    </div>
  );
};

export default SubscriptionGrowBillingSummary;
