import Popup from '~/neo-ui/packages/popup/Popup';
import { css } from '@emotion/react';
import * as React from 'react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import InputTitle from '~/neo-ui/packages/input/packages/input-title/InputTitle';
import Button from '~/neo-ui/packages/button/Button';
import { ScorecardCategoryAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardCategory/Dto/Model.gen';
import useScorecardCategoryCreate from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/hooks/useScorecardCategoryCreate';
import useScroll from '~/extensions/packages/scroll/hooks/useScroll';
import SelectList from '~/neo-ui/packages/list/packages/select-list/SelectList';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';

export type ScorecardCategoryCreateButtonProps = {
  scorecardId: string;
  scorecardCategoryAvailabilities: ScorecardCategoryAvailabilityDto[];
};

const ScorecardCategoryCreateButton = ({ scorecardId, scorecardCategoryAvailabilities }: ScorecardCategoryCreateButtonProps) => {
  const { scrollToBottom } = useScroll();
  const { createScorecardCategory, isCreatingScorecardCategory } = useScorecardCategoryCreate(scorecardId, {
    onSuccess: () => {
      setIsOpen(false);
      scrollToBottom();
    },
  });
  const [isOpen, setIsOpen] = React.useState(false);

  const options: SelectOption[] = scorecardCategoryAvailabilities.map(option => ({
    label: option.label,
    value: option.uniqueId,
  }));

  const [selectedCategory, setSelectedCategory] = React.useState(options[0] ?? { label: 'General Items', value: '' });
  const [label, setLabel] = React.useState(options[0] ? options[0].label : 'General Items');

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      backgroundColor={'light-000'}
      placement={'right-start'}
      title={'Add Category'}
      titleColor={'primary-400'}
      showCloseButton={true}
      css={css`
        min-width: 37.5rem;
      `}
      content={
        <React.Fragment>
          <InputTitle
            size={'lg'}
            theme={'secondary'}
            placeholder={'Name…'}
            value={label}
            onChange={value => {
              setLabel(value);
              setSelectedCategory({
                label: 'General Items',
                value: '',
              });
            }}
          />
          <Header
            size={7}
            color={'dark-050'}
            css={css`
              margin-top: 1rem;
              margin-bottom: 0.625rem;
            `}
          >
            Default Category
          </Header>
          <SelectList
            options={[{ options }]}
            selectedOptionValue={selectedCategory}
            onOptionSelected={option => {
              setSelectedCategory(option);
              setLabel(option.label ?? 'General Items');
            }}
          />
        </React.Fragment>
      }
      footer={
        <Button
          iconLeft={'Done'}
          theme={'primary'}
          loading={isCreatingScorecardCategory}
          onClick={() =>
            createScorecardCategory(
              selectedCategory.value === ''
                ? {
                    label,
                    scorecardCategoryUniqueId: undefined,
                  }
                : {
                    label: undefined,
                    scorecardCategoryUniqueId: selectedCategory.value,
                  },
            )
          }
        >
          Done
        </Button>
      }
    >
      <Button
        iconLeft={'ScorecardCategory'}
        onClick={() => setIsOpen(prev => !prev)}
      >
        Add Category…
      </Button>
    </Popup>
  );
};

export default ScorecardCategoryCreateButton;
