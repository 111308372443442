import * as React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import {
  organizationAccountTeamMemberListGet,
  organizationContactListGet,
  organizationKeyContactListGet,
} from '@AssetManagementClient/AssetManagementClientMsp.gen';
import PeopleDashboardMutationContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/context/PeopleDashboardMutationContext';
import PeopleDashboardContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/context/PeopleDashboardContext';
import { OrganizationAccountTeamMemberDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationAccountTeamMember/Dto/Model.gen';
import { AccountTeamMemberRoleAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationAccountTeamMember/Packages/AccountTeamMember/Packages/AccountTeamMemberRole/Dto/Model.gen';
import { OrganizationKeyContactDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationKeyContact/Dto/Model.gen';
import { OrganizationContactDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Contact/Dto/Model.gen';

export type PeopleDashboardProvider = {
  organizationId: string;
};

const PeopleDashboardProvider: React.FunctionComponent<React.PropsWithChildren<PeopleDashboardProvider>> = ({
  organizationId,
  children,
}) => {
  const [organizationAccountTeamMembers, setOrganizationAccountTeamMembers] = React.useState<OrganizationAccountTeamMemberDto[]>();
  const [roleAvailabilities, setRoleAvailabilities] = React.useState<AccountTeamMemberRoleAvailabilityDto[]>();
  const [organizationKeyContacts, setOrganizationKeyContacts] = React.useState<OrganizationKeyContactDto[]>();
  const [organizationContacts, setOrganizationContacts] = React.useState<OrganizationContactDto[]>();

  const { callApi } = useApi();

  const reload = React.useCallback(
    async (isRequestActive?: () => boolean) => {
      await (async () => {
        const responseOrganizationAccountTeamMembers = callApi(() =>
          organizationAccountTeamMemberListGet({
            organizationId,
          }),
        );

        const responseOrganizationKeyContacts = callApi(() =>
          organizationKeyContactListGet({
            organizationId,
          }),
        );

        const responseOrganizationContacts = callApi(() =>
          organizationContactListGet({
            organizationId,
          }),
        );

        const results = await Promise.all([
          responseOrganizationAccountTeamMembers,
          responseOrganizationKeyContacts,
          responseOrganizationContacts,
        ]);

        if (!results[0] || !results[1] || !results[2]) {
          return;
        }

        if (!isRequestActive || isRequestActive()) {
          setOrganizationAccountTeamMembers(results[0].organizationAccountTeamMembers);
          setRoleAvailabilities(results[0].roleAvailabilities);
          setOrganizationKeyContacts(results[1].organizationKeyContacts);
          setOrganizationContacts(results[2].organizationContacts);
        }
      })();
    },
    [callApi, organizationId],
  );

  React.useEffect(() => {
    reload();
  }, [reload]);

  if (
    typeof organizationAccountTeamMembers === 'undefined' ||
    typeof roleAvailabilities === 'undefined' ||
    typeof organizationKeyContacts === 'undefined' ||
    typeof organizationContacts === 'undefined'
  ) {
    return null;
  }

  return (
    <PeopleDashboardMutationContext.Provider
      value={{
        triggerPeopleDashboardReload: isRequestActive => reload(isRequestActive),
      }}
    >
      <PeopleDashboardContext.Provider
        value={{
          organizationAccountTeamMembers,
          roleAvailabilities,
          organizationKeyContacts,
          organizationContacts,
        }}
      >
        {children}
      </PeopleDashboardContext.Provider>
    </PeopleDashboardMutationContext.Provider>
  );
};

export default PeopleDashboardProvider;
