import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { mobileBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';

export type PresentationBodyProps = {
  children: ReactNode;
};

export const presentationBodyMarginRem = { desktop: 1.875, mobile: 1.25 };

const PresentationBody = ({ children }: PresentationBodyProps) => (
  <main
    css={css`
      height: 100%;
      flex-grow: 1;
      margin: ${presentationBodyMarginRem.desktop}rem;

      @media (max-width: ${mobileBreakpointRem}rem) {
        margin: ${presentationBodyMarginRem.mobile}rem;
      }
    `}
  >
    {children}
  </main>
);

export default PresentationBody;
