import React, { useCallback } from 'react';
import Button from '~/neo-ui/packages/button/Button';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color from '~/neo-ui/packages/color/Color.gen';
import { DevicesByTypeCountDto } from '@WarrantyClient/BeastClient/Beast/Warranties/Dto/DevicesByTypeCount.gen';
import { Enum as DeviceType } from '@WarrantyClient/BeastClient/Goods/DeviceType/DeviceTypeFactoryNested.gen';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import { Enum as WarrantyType } from '@WarrantyClient/BeastClient/Goods/WarrantyType/WarrantyTypeFactoryNested.gen';

const getItemColor = (btnType: DeviceType, selectedType: DeviceType): Color => (selectedType === btnType ? 'light-050' : 'dark-900');

const WarrantyCartDeviceTypeSwitch: React.FunctionComponent = () => {
  const { selectedDeviceType, availableAssetTypes, setSelectedDeviceType, warrantyType } = useWarrantyCartContext();

  const getButtonCss = useCallback(
    (idx: number): string => {
      switch (idx) {
        case 0:
          return '15px 0 0 15px';
        case availableAssetTypes.length - 1:
          return '0 15px 15px 0';
        default:
          return '0';
      }
    },
    [availableAssetTypes],
  );

  if (warrantyType === WarrantyType.WorkstationAssurance) {
    return null;
  }

  return (
    <div
      css={css`
        margin-top: 1.5rem;
      `}
    >
      {availableAssetTypes
        .sort((a, b) => a.order - b.order)
        .map((btn: DevicesByTypeCountDto, idx) => (
          <Button
            key={btn.name}
            css={css`
              height: 4.5rem;
              padding-top: 0.5rem;
              border-radius: ${getButtonCss(idx)};
              width: 11rem;
            `}
            iconLeft={btn.displayIcon as IconType}
            size={'md'}
            theme={'primary'}
            iconSizeOverride={'lg'}
            invertColorMap={selectedDeviceType !== btn.deviceType}
            iconColorOverride={getItemColor(btn.deviceType, selectedDeviceType)}
            onClick={() => setSelectedDeviceType(btn.deviceType)}
          >
            <div
              css={css`
                text-align: left;
                padding-left: 0.1rem;
              `}
            >
              <Header
                size={3}
                weight="bold"
                color={getItemColor(btn.deviceType, selectedDeviceType)}
              >
                {btn.totalDevices}
              </Header>
              <Header
                size={5}
                color={getItemColor(btn.deviceType, selectedDeviceType)}
              >
                {btn.totalDevices === 1 ? btn.name : btn.namePlural}
              </Header>
            </div>
          </Button>
        ))}
    </div>
  );
};

export default WarrantyCartDeviceTypeSwitch;
