import WizardPageStep from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageStep';
import { FieldKeyExpression, FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { BillingInfo as BillingInfoType } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import DisposalBulkCheckoutFormData from '~/wm/packages/disposal/types/DisposalBulkCheckoutFormData';
import SiteInformationSection from '~/wm/packages/disposal/wizard/section/SiteInformationSection';
import ReturnAddressSection from '~/wm/packages/disposal/wizard/section/ReturnAddressSection';
import MaterialsSection from '~/wm/packages/disposal/wizard/section/MaterialsSection';
import { DisposalOrderPickupMaterialTypeDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderPickup/Packages/DisposalOrderPickupMaterial/Dto/Model.gen';

const returnShippingAddressFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values =>
  values.shipmentReturnAddress.billingAddress;

const addressIsValidFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values => values.certification.addressIsValid;

const assetLocationFieldKey = (formData: FieldKeyExpressionSegment<DisposalBulkCheckoutFormData>) => formData.siteInformation.assetLocation;

const hasLoadingDockFieldKey = (formData: FieldKeyExpressionSegment<DisposalBulkCheckoutFormData>) =>
  formData.siteInformation.hasLoadingDock;

const isAccessibleByElevatorFieldKey = (formData: FieldKeyExpressionSegment<DisposalBulkCheckoutFormData>) =>
  formData.siteInformation.isAccessibleByElevator;

const isMultipleFloorsFieldKey = (formData: FieldKeyExpressionSegment<DisposalBulkCheckoutFormData>) =>
  formData.siteInformation.isMultipleFloors;

const locationCertifiedFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values => values.certification.locationCertified;

const materialsFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values => values.materials;

const phoneNumberFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values => values.phoneNumber;

const phoneNumberIsValidKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values => values.isPhoneNumberValid;

const getDisposalBulkShippingStep = (
  billingInfo: BillingInfoType,
  pickupMaterialTypeAvailabilities: DisposalOrderPickupMaterialTypeDto[],
): WizardPageStep<DisposalBulkCheckoutFormData> => ({
  header: {
    label: 'Shipping',
    icon: 'Shipping',
  },
  fields: [returnShippingAddressFieldKey, addressIsValidFieldKey, locationCertifiedFieldKey, phoneNumberFieldKey],
  sections: [
    ({ isEditingIndex, onEditing }) =>
      ReturnAddressSection({
        billingInfo,
        returnShippingAddressFieldKey,
        addressIsValidFieldKey,
        locationCertifiedFieldKey,
        isEditingIndex,
        onEditing,
        phoneNumberFieldKey,
        phoneNumberIsValidKey,
      }),
    () =>
      SiteInformationSection({
        assetLocationFieldKey,
        hasLoadingDockFieldKey,
        isMultipleFloorsFieldKey,
        isAccessibleByElevatorFieldKey,
      }),
    () => MaterialsSection({ materialsFieldKey, pickupMaterialTypeAvailabilities }),
  ],
  onCompleteStateChange: () => true,
  key: 'Shipping',
});

export default getDisposalBulkShippingStep;
