import { useContext } from 'react';
import PanelTabGroupContext, {
  PanelTabGroupContextData,
} from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/PanelTabGroupContext';
const usePanelTabGroupContext = <T>(): PanelTabGroupContextData<T> => {
  const panelTabGroup = useContext(PanelTabGroupContext);
  if (!panelTabGroup) {
    throw new Error('Tried to use PanelTabGroupContext outside of an PanelTabGroupProvider');
  }

  return panelTabGroup;
};

export default usePanelTabGroupContext;
