import { createContext } from 'react';
import { OrganizationAccountTeamMemberDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationAccountTeamMember/Dto/Model.gen';
import { AccountTeamMemberRoleAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationAccountTeamMember/Packages/AccountTeamMember/Packages/AccountTeamMemberRole/Dto/Model.gen';
import { OrganizationKeyContactDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationKeyContact/Dto/Model.gen';
import { OrganizationContactDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Contact/Dto/Model.gen';

export type PeopleDashboardContextData = {
  organizationAccountTeamMembers: OrganizationAccountTeamMemberDto[];
  roleAvailabilities: AccountTeamMemberRoleAvailabilityDto[];
  organizationKeyContacts: OrganizationKeyContactDto[];
  organizationContacts: OrganizationContactDto[];
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const PeopleDashboardContext = createContext<PeopleDashboardContextData | undefined>(undefined);

export default PeopleDashboardContext;
