import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { scorecardPdf } from '@AssetManagementClient/AssetManagementClientMsp.gen';

type UseDownloadScorecardPdf = () => {
  downloadScorecardPdf: (scorecardId: string) => void;
};

export const useDownloadScorecardPdf: UseDownloadScorecardPdf = () => {
  const { callApi } = useApi();

  const downloadScorecardPdf = React.useCallback(
    async (scorecardId: string) => {
      const stream = await callApi(() => scorecardPdf({ scorecardId }));

      if (!stream) {
        return;
      }
    },
    [callApi],
  );

  return { downloadScorecardPdf };
};

export default useDownloadScorecardPdf;
