/* eslint-disable @typescript-eslint/naming-convention */
import { CSSProperties, ComponentPropsWithoutRef, FC, forwardRef, memo, useMemo } from 'react';
import theme from '../theme';

export type TextProps<T extends keyof JSX.IntrinsicElements = 'span'> = ComponentPropsWithoutRef<T> & {
  element?: T;
  variant?: keyof typeof theme.typography;
  color?: keyof typeof theme.palette;
  weight?: CSSProperties['fontWeight'];
  align?: CSSProperties['textAlign'];
  decoration?: CSSProperties['textDecoration'];
};

const Text = memo(
  forwardRef<Element, TextProps>((props, ref) => {
    const { element, variant = 'body', color, weight, align, decoration, ...rest } = props;

    const Element = useMemo(() => {
      if (element) {
        return element;
      }

      switch (variant) {
        case 'h1':
          return 'h1';
        case 'h2':
          return 'h2';
        case 'h3':
          return 'h3';
        default:
          return 'span';
      }
    }, [variant, element]);

    return (
      <Element
        ref={ref as never}
        css={[
          { all: 'unset' },
          theme.typography[variant],
          color && {
            color: theme.palette[color],
          },
          weight && {
            fontWeight: weight,
          },
          align && {
            textAlign: align,
          },
          decoration && {
            textDecoration: decoration,
          },
        ]}
        {...rest}
      />
    );
  }),
);

type Ref<T extends keyof JSX.IntrinsicElements = 'span'> = {
  ref?: JSX.IntrinsicElements[T]['ref'];
};

export default Text as <T extends keyof JSX.IntrinsicElements = 'span'>(props: TextProps<T> & Ref<T>) => ReturnType<FC<TextProps<T>>>;
