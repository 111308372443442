import * as React from 'react';
import { CurrencyCode } from '~/extensions/packages/currency/formatCurrency';
import ValidationErrorMapper from '~/neo-ui/packages/form/packages/form-action/packages/form-validation/ValidationErrorMapper';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import { WizardContainerStep } from '~/neo-ui/packages/wizard/packages/wizard-container/packages/step/WizardContainerStep';
import {
  SelfServeType,
  SubscriptionAgreementEnum,
  SubscriptionAgreementInfo,
} from '~/wm/packages/subscription/packages/manage/wizard/model/SubscriptionAgreementInfo';
import { SubscriptionAgreementStepFieldKeys } from '~/wm/packages/subscription/packages/manage/wizard/model/SubscriptionAgreementStepFieldKeys';
import ProductTierStepSection from '~/wm/packages/subscription/packages/manage/wizard/step/section/ProductTierStepSection';
import SubscriptionAgreementStepSummary from '~/wm/packages/subscription/packages/manage/wizard/step/summary/SubscriptionAgreementStepSummary';
import { resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { Enum as SubscriptionClientBeastClientBeastMspModelSubscriptionProductProductFactoryNestedEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription/Product/ProductFactoryNested.gen';
import { ProductAvailabilityPayload } from '@SubscriptionClient/Subscription/Packages/Upgrade/Model.gen';
import SubscriptionDetailsStepSection from '~/wm/packages/subscription/packages/manage/wizard/step/section/SubscriptionDetailsStepSection';
import { css } from '@emotion/react';
import Card from '~/legacy-ui/packages/card/Card';
import { ProductRegions, ProductTierIds } from '~/wm/packages/subscription/packages/upgrade/wizard/SubscriptionUpgradeWizard';

export interface SubscriptionAgreementData {
  selectedTierIds: ProductTierIds;
  selectedProductRegions: ProductRegions;
}

const getSubscriptionAgreementStep = <T extends SubscriptionAgreementData>(
  header: { label: string; description: string },
  subscriptionAgreementInfo: SubscriptionAgreementInfo,
  currencyCode: CurrencyCode,
  subscriptionAgreementStepFieldKeys: SubscriptionAgreementStepFieldKeys<T>,
  subscriptionAgreementLink: AnchorLocation,
  validationErrorMapper: ValidationErrorMapper,
  productAvailabilities: ProductAvailabilityPayload[],
  isNewSubscription: boolean,
  invalidTiers: string[],
): WizardContainerStep<T> => {
  const customSections: React.ReactNode[] = [];
  const sections: React.ReactNode[] = [];

  productAvailabilities.sort((a, b) => (a.displayPriority > b.displayPriority ? 1 : -1));

  const availableProducts = productAvailabilities.map(productAvailability => productAvailability.productEnum);

  if (subscriptionAgreementInfo.case === SubscriptionAgreementEnum.SelfServe && subscriptionAgreementInfo.type === SelfServeType.Grow) {
    customSections.push(
      <Card
        padding={'sm'}
        css={css`
          width: 32.5rem;
          margin-bottom: 0.625rem;
          background-color: #d9ebff;
        `}
      >
        <p
          css={css`
            margin-bottom: 0rem;
          `}
        >
          To grow your subscription, please increase the capacity of a product or add a new product.
        </p>
      </Card>,
    );
  }

  productAvailabilities.map(productAvailabilities => {
    const fieldKey = subscriptionAgreementStepFieldKeys.productTierIdFieldKeys.find(
      key => resolveFieldKey<T>(key) === productAvailabilities.productEnum.toString(),
    );
    sections.push(
      <ProductTierStepSection<T>
        productAvailabilities={productAvailabilities}
        agreementInfo={subscriptionAgreementInfo}
        currencyCode={currencyCode}
        fieldKey={fieldKey!}
        availableProducts={availableProducts}
        invalidTiers={invalidTiers}
        // Enterprise edition logic below is temporary until the concept of subscription plan is overhauled
        enterpriseEditionAvailable={
          productAvailabilities.productEnum ===
          SubscriptionClientBeastClientBeastMspModelSubscriptionProductProductFactoryNestedEnum.ScalePad
        }
      />,
    );
  });

  sections.push(
    <SubscriptionDetailsStepSection
      subscriptionAgreementInfo={subscriptionAgreementInfo}
      subscriptionAgreementLink={subscriptionAgreementLink}
      currencyCode={currencyCode}
    />,
  );

  return {
    stepLabel: 'subscription agreement',
    header,
    summary: {
      label: 'Subscription details',
      content: (
        <SubscriptionAgreementStepSummary
          subscriptionAgreementInfo={subscriptionAgreementInfo}
          subscriptionAgreementLink={subscriptionAgreementLink}
          isNewSubscription={isNewSubscription}
        />
      ),
    },
    customSections,
    sections,
    fields: [...subscriptionAgreementStepFieldKeys.productTierIdFieldKeys],
    validationErrorMapper,
  };
};

export default getSubscriptionAgreementStep;
