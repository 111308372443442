import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Button from '~/neo-ui/packages/button/Button';

export type ExploreProductsBannerProps = {
  productSquareIcons: string[];
  manageSubscriptionBaseUrl: string;
};
const ExploreProductsBanner = ({ productSquareIcons, manageSubscriptionBaseUrl }: ExploreProductsBannerProps) => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          gridGap: '1rem',
          borderRadius: '6px',
          alignItems: 'center',
          padding: '2rem',
          maxWidth: '37.5rem', // 600px
          color: colorToCode('light-000'),
          backgroundColor: '#0A1C2B', // Dark-Matter
        }}
      >
        <div>
          <h4>More from ScalePad</h4>
          <p>Explore our product suite and see what you're missing. Prepare to take your MSP adventure to the next level!</p>
          {productSquareIcons.map((productIcon, index) => (
            <img
              key={index}
              src={productIcon}
              css={{ height: '1.5rem', marginRight: '1rem' }}
            />
          ))}
        </div>

        <Button
          size={'md'}
          anchor={{ href: manageSubscriptionBaseUrl }}
          css={{ width: '9rem' }}
        >
          See all products
        </Button>
      </div>
    </div>
  );
};

export default ExploreProductsBanner;
