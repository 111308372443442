import { css } from '@emotion/react';
import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import Button from '~/neo-ui/packages/button/Button';
import Organization from '~/wm/packages/organization/model/Organization';
import OrganizationLink from '~/wm/packages/organization/packages/linking/model/OrganizationLink';
import OrganizationLinkList from '../../../link-list/OrganizationLinkList';

export type OrganizationLinkUnlinkProps = {
  parentOrganization: Organization;
  linkedOrganizations: OrganizationLink[];
  onRename: () => void;
  onUnlink: (organizationToUnlink: OrganizationLink) => void;
};

const OrganizationLinkUnlink: React.FunctionComponent<OrganizationLinkUnlinkProps> = ({
  parentOrganization,
  linkedOrganizations,
  onRename,
  onUnlink,
}) => {
  const Subtitle: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => (
    <p
      css={css`
        font-size: 0.75rem;
        color: #57585c;
        margin-bottom: 0.625rem;
        line-height: 0.875rem;
      `}
    >
      {children}
    </p>
  );

  return (
    <Card>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <div>
          <Subtitle>Name</Subtitle>
          <p
            css={css`
              font-size: 1.125rem;
              font-weight: 500;
              margin-bottom: 0.938rem;
              line-height: 1.625rem;
            `}
          >
            {parentOrganization.name}
          </p>
        </div>
        <Button
          iconLeft={'Edit'}
          css={css`
            max-height: 2.25rem;
            margin-left: 0.625rem;
          `}
          disabled={false}
          onClick={onRename}
        >
          Rename
        </Button>
      </div>
      <div>
        <Subtitle>Links</Subtitle>
        <OrganizationLinkList
          parentOrganization={parentOrganization}
          linkedOrganizations={linkedOrganizations}
          onUnlink={onUnlink}
        />
      </div>
    </Card>
  );
};

export default OrganizationLinkUnlink;
