import { DmiTrendDto } from '@AssetManagementClient/AssetManagement/Packages/Dmi/Controller/Dto.gen';
import { parseISO } from 'date-fns';
import React from 'react';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import DmiTrendChart, { DmiChartData } from '~/wm/packages/strategy/packages/dmi/packages/dmi-trend/packages/dmi-trend-chart/DmiTrendChart';

export type DmiTrendProps = {
  dateToDmiScore: { [key in string]: DmiTrendDto } | undefined;
};

const DmiTrend: React.FunctionComponent<DmiTrendProps> = ({ dateToDmiScore }) => {
  if (typeof dateToDmiScore === 'undefined') {
    return <div />;
  }
  const scoreData: DmiChartData[] = [];
  for (const [key, value] of Object.entries(dateToDmiScore)) {
    scoreData.push({
      xLabel: formatDate(parseISO(key), {
        format: 'MMM dd',
        timezone: TimezoneFormat.Local,
      }),
      dmiScore: value.displayScore,
    });
  }

  return <DmiTrendChart scoreData={scoreData} />;
};

export default DmiTrend;
