import { useContext } from 'react';
import DisposalOrderAssetSelectContext from '../DisposalOrderAssetSelectContext';

const useDisposalOrderAssetSelectContext = () => {
  const disposalOrderAssetSelectContext = useContext(DisposalOrderAssetSelectContext);

  if (!disposalOrderAssetSelectContext) {
    throw new Error('Tried to use DisposalOrderAssetSelectContext outside of an DisposalOrderAssetSelectProvider');
  }

  return disposalOrderAssetSelectContext;
};

export default useDisposalOrderAssetSelectContext;
