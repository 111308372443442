import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Styleable } from '~/neo-ui/model/capacity';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import InputText from '~/neo-ui/packages/input/packages/input-text/InputText';

export type AccountPhoneNumberProps = {
  initialValue: string | undefined;
  setFormValue: (value: string) => void;
  setIsValidPhone: (value: boolean) => void;
} & Styleable;

export const validatePhoneNumber = (value: string | undefined): string | undefined => {
  if (typeof value === 'undefined') {
    return undefined;
  }
  if ((value.match(/\d/g) || []).length > 10) {
    return 'Enter a valid 10 digits number';
  }
  if (!value.match(/^[\d()+\-\s]*$/g)) {
    return 'Only digits, braces, plus and dashes allowed';
  }
  return undefined;
};

const AccountPhoneNumber: React.FunctionComponent<AccountPhoneNumberProps> = ({
  initialValue,
  setFormValue,
  setIsValidPhone,
  className,
}) => {
  const [value, setValue] = useState<string>(initialValue ?? '');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    const validationResult = validatePhoneNumber(value);
    setErrorMessage(validationResult);
    setFormValue(value);
    setIsValidPhone(typeof validationResult === 'undefined');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const inputBorderBottomStyle = useMemo(() => (typeof errorMessage !== 'undefined' ? '2px solid red' : 'none'), [errorMessage]);

  return (
    <div
      className={className}
      css={css`
        width: 20rem;
      `}
    >
      <Label
        css={css`
          margin-bottom: 0.625rem;
        `}
        bold={true}
      >
        Phone number (optional)
      </Label>
      <InputText
        css={css`
          border-bottom: ${inputBorderBottomStyle};
        `}
        onTextChange={event => setValue(event.target.value)}
        value={value}
        placeholder={'Phone Number'}
        onClear={() => setValue('')}
      />
      <Label
        color={'negative-600'}
        bold={true}
      >
        {errorMessage}
      </Label>
    </div>
  );
};

export default AccountPhoneNumber;
