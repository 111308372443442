import { useEffect, useRef, useState } from 'react';
import Spinner from '~/neo-ui/spinner/Spinner';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import PaymentSummary from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/subscription-summary/PaymentSummary';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import usePurchaseCheckoutSummaryViewModel from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/hooks/usePurchaseCheckoutSummaryViewModel';
import useApi from '~/wm/packages/api/hook/useApi';
import { purchasePlan } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { Discriminant } from '@SubscriptionClient/Primitives/Results/PurchasePlanControllerNested/Response_/PublicError_/ResultNested.gen';
import Message from '~/sp-ui/message';
import ExpenseLineItem from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/subscription-summary/ExpenseLineItem';
import DiscountLineItem from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/subscription-summary/DiscountLineItem';

const SubscriptionSummary = (props: { actionLabel: string; isReadyToPay: boolean }) => {
  const { goNext, formik } = useMultiStepForm<PurchaseSubscriptionForm>();
  const [error, setError] = useState<string | undefined>(undefined);
  const [isPurchasing, setIsPurchasing] = useState<boolean>(false);
  const { isReadyToPay, actionLabel } = props;
  const { isGrow } = formik.values;
  const purchaseCheckoutSummaryRequest = useRef({
    estimateProduct: {
      subscriptionPlanId: formik.values.subscriptionPlanId!,
      addonIds: formik.values.selectedAddonIds.concat(formik.values.ownedAddonIds),
      quantity: formik.values.selectedPlanLimit ?? formik.values.defaultPlanLimit!,
      couponCodes: formik.values.couponCodes ? formik.values.couponCodes : [],
    },
  });
  const { callApi } = useApi();
  const { data, purchaseCheckoutSummaryLoading } = usePurchaseCheckoutSummaryViewModel(
    purchaseCheckoutSummaryRequest.current,
    formik.values.isBillingAddressSet || isReadyToPay,
  );
  const { setFieldValue } = formik;
  const refMessageError = useRef<HTMLDivElement>(null);

  useEffect(() => {
    purchaseCheckoutSummaryRequest.current = {
      estimateProduct: {
        ...purchaseCheckoutSummaryRequest.current.estimateProduct,
        couponCodes: formik.values.couponCodes ? formik.values.couponCodes : [],
      },
    };
  }, [formik.values.couponCodes]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setFieldValue('purchasePrice', {
      planPrice: data.checkoutSummary.planPrice,
      totalBeforeTax: data.checkoutSummary.totalBeforeTax,
      totalAfterTax: data.checkoutSummary.totalAfterTax,
      totalDiscountedAmount: data.checkoutSummary.discountSummary.totalAmount,
      activationPrice: data.checkoutSummary.activationPrice,
      nextEstimatedAmountWithoutTax: data.checkoutSummary.nextEstimatedAmountWithoutTax,
      tax: data.checkoutSummary.tax,
    });
    setFieldValue('nextPaymentDayLabel', data.productAdoptionCheckoutSummary.nextPaymentDayLabel);
    setFieldValue('nextPaymentDate', data.productAdoptionCheckoutSummary.nextPaymentDate);
    setFieldValue('isLoadingCoupon', false);

    const couponValidationResults = data.couponValidationResults;
    const invalidCouponResult = couponValidationResults.find(x => !x.isValid);
    if (invalidCouponResult) {
      setFieldValue('couponCodeError', invalidCouponResult.errorMessage);
    } else {
      setFieldValue('couponBreakdown', data.checkoutSummary.discountSummary.couponAmounts);
    }
  }, [data, setFieldValue]);

  if (!data || purchaseCheckoutSummaryLoading || isPurchasing) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          margin-top: 1rem;
        `}
      >
        <Spinner />
      </div>
    );
  }
  const moveToError = () => {
    if (refMessageError) {
      refMessageError.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  const handlePurchase = async () => {
    setIsPurchasing(true);
    const response = await callApi(() =>
      purchasePlan({
        purchasePlan: {
          subscriptionPlanId: formik.values.subscriptionPlanId!,
          addonIds: formik.values.selectedAddonIds.concat(formik.values.ownedAddonIds),
          quantity: formik.values.selectedPlanLimit ?? formik.values.defaultPlanLimit!,
          couponCodes: formik.values.couponCodes,
        },
      }),
    );
    setIsPurchasing(false);
    if (!response) {
      setError('We were unable to complete your purchase due to a system error. Please try again.');
      moveToError();
      return;
    }
    if (response.case === Discriminant.Error) {
      setError(response.value.messagePublic);
      moveToError();
    } else {
      goNext();
    }
  };
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
      `}
    >
      <div>
        <img
          src={data.productAdoptionCheckoutSummary.productLogoUrl}
          css={css`
            height: 1.5rem;
            object-fit: contain;
            object-position: 0 0;
            margin-bottom: 1rem;
          `}
          alt={data.productAdoptionCheckoutSummary.productLabel}
        />
      </div>
      <ExpenseLineItem
        itemName={data.productAdoptionCheckoutSummary.planName}
        itemTooltips={data.productAdoptionCheckoutSummary.productFeatureInclusions}
        price={data.checkoutSummary.planPrice}
        displayChangePlan={isReadyToPay}
      />

      {data.checkoutSummary.addons.map((addon, index) => (
        <ExpenseLineItem
          key={index}
          itemName={addon.addonName}
          itemTooltips={[addon.marketingToolTip]}
          price={addon.addonPrice}
          displayChangePlan={false}
        />
      ))}
      {data.checkoutSummary.activationPrice > 0 && (
        <ExpenseLineItem
          itemName={'Activation (billed once)'}
          itemTooltips={[data.productAdoptionCheckoutSummary.activationTooltip]}
          price={data.checkoutSummary.activationPrice}
          displayChangePlan={false}
        />
      )}
      {data.checkoutSummary.discountSummary.totalAmount > 0 && (
        <DiscountLineItem
          itemName={data.checkoutSummary.discountSummary.label}
          discountTooltipItems={data.checkoutSummary.discountSummary.discountItems}
          totalAmount={data.checkoutSummary.discountSummary.totalAmount}
        />
      )}
      <div
        css={css`
          margin: 0.5rem 0;
          border-bottom: 0.063rem solid ${colorToCode('light-600')};
        `}
      />
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(2, auto);
          justify-content: space-between;
          text-align: left;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: ${colorToCode('dark-900')};
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <span>Total before taxes</span>
        </div>
        <span
          css={css`
            text-align: end;
          `}
        >
          {formatCurrency(data.checkoutSummary.totalBeforeTax, 'USD', 2)}
        </span>
      </div>
      <div>
        {isReadyToPay && (
          <div
            css={css`
              margin-top: 2rem;
            `}
          >
            <PaymentSummary
              totalAfterTax={formatCurrency(data.checkoutSummary.totalAfterTax, 'USD', 2)}
              nextEstimatedAmountWithoutTax={formatCurrency(data.checkoutSummary.nextEstimatedAmountWithoutTax, 'USD', 2)}
              nextPaymentDayLabel={data.productAdoptionCheckoutSummary.nextPaymentDayLabel}
              nextPaymentDate={data.productAdoptionCheckoutSummary.nextPaymentDate}
              isGrow={isGrow}
            />
            {error && (
              <Message
                ref={refMessageError}
                css={{ marginTop: 32 }}
                text={error}
                type="error"
              />
            )}
            <button
              ref={formik.values.submitPayment}
              type={'button'}
              css={css`
                cursor: pointer;
                margin-top: 2rem;
                background-color: ${colorToCode('primary-400')};
                color: ${colorToCode('light-000')};
                padding: 1rem 2rem;
                font-size: 1rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.25rem;

                &:hover {
                  cursor: pointer;
                  background-color: ${colorToCode('primary-500')};
                  box-shadow: inset 0 0 0 1px ${colorToCode('primary-500')}, 0 1px 4px 0 ${colorToCode('primary-500')};
                }
              `}
              onClick={handlePurchase}
            >
              {actionLabel}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionSummary;
