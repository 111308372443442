import { css } from '@emotion/react';
import * as React from 'react';
import Theme from '~/neo-ui/packages/color/Theme';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';

export type ModalConfirmationHeaderProps = {
  icon?: IconType;
  title: string;
  theme: Theme;
};

const ModalConfirmationHeader: React.FunctionComponent<ModalConfirmationHeaderProps> = ({ icon, title, theme }) => {
  const { themeMap } = useTheme(theme);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
      `}
    >
      {icon && (
        <Icon
          icon={icon}
          color={themeMap.foregroundAccent}
          sizePx={32}
        />
      )}
      <Header
        size={2}
        color={themeMap.foregroundAccent}
      >
        {title}
      </Header>
    </div>
  );
};
export default ModalConfirmationHeader;
