import { initiativeAssetAddBulk } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { InitiativeAssetSourcesPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import { BudgetQuarterDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Packages/BudgetQuarter/Dto/Model.gen';
import { useCallback, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import routes from '~/router/types/routes';

type UseInitiativeAssetAddBulk = () => {
  isAdding: boolean;
  assetAddBulk: (
    initiativeId: string,
    organizationId: string,
    shouldRedirect: boolean,
    assetSources: InitiativeAssetSourcesPayloadDto,
    targetQuarter?: BudgetQuarterDto,
  ) => void;
};

const useInitiativeAssetAddBulk: UseInitiativeAssetAddBulk = () => {
  const { callApi } = useApi();
  const [isLoading, setLoading] = useState(false);

  const assetAddBulk = useCallback(
    async (
      initiativeId: string,
      organizationId: string,
      shouldRedirect: boolean,
      assetSources: InitiativeAssetSourcesPayloadDto,
      targetQuarter?: BudgetQuarterDto,
    ) => {
      setLoading(true);
      const response = await callApi(() =>
        initiativeAssetAddBulk({
          initiativeId,
          assetSources,
          targetQuarter,
        }),
      );

      if (!response) {
        return;
      }

      setLoading(false);
      if (shouldRedirect) {
        window.location.href = `${routes.ORGANIZATION_STRATEGY_ROADMAP_VIEW.build([organizationId])}#${initiativeId}`;
      }
    },
    [callApi],
  );

  return {
    isAdding: isLoading,
    assetAddBulk,
  };
};

export default useInitiativeAssetAddBulk;
