import { scorecardGet, scorecardGetId, scorecardList } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { ScorecardDto, ScorecardOverviewDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Dto/Model.gen';
import * as React from 'react';
import ScorecardContext from '~/wm/packages/strategy/packages/scorecard/context/ScorecardContext';
import ScorecardMutationContext from '~/wm/packages/strategy/packages/scorecard/context/ScorecardMutationContext';
import useApi from '~/wm/packages/api/hook/useApi';

export type ScorecardProviderProps = {
  initialScorecardId?: string;
  organizationId: string;
  organizationName: string;
  initialScorecard?: ScorecardDto;
  initialScorecardList?: ScorecardOverviewDto[];
  children: React.ReactNode;
};

export type OpenScorecardProps = (scorecardId: string, isRequestActive?: () => boolean) => void;

const ScorecardProvider = ({
  initialScorecardId,
  organizationId,
  organizationName,
  initialScorecard,
  initialScorecardList,
  children,
}: ScorecardProviderProps) => {
  const [scorecard, setScorecard] = React.useState<ScorecardDto | undefined>(initialScorecard);
  const [scorecards, setScorecards] = React.useState<ScorecardOverviewDto[] | undefined>(initialScorecardList);
  const { callApi } = useApi();

  // Get scorecard + Dmi + scorecard overviews
  const reload = React.useCallback(
    async (isRequestActive?: () => boolean) => {
      const scorecardResponse =
        typeof initialScorecardId !== 'undefined'
          ? await callApi(() => scorecardGetId({ scorecardId: initialScorecardId }))
          : await callApi(() => scorecardGet({ organizationId }));

      const scorecardListResponse = await callApi(() => scorecardList({ organizationId }));

      const scorecardOverviews = scorecardListResponse?.scorecards ?? [];

      if (!scorecardResponse) {
        return;
      }

      // if a function is provided to determine whether to propagate
      // a state update (to prevent race conditions), then it will
      // ensure not to update state. otherwise, each call updates state.
      if (!isRequestActive || isRequestActive()) {
        setScorecard(scorecardResponse?.scorecardInfo);
        setScorecards(scorecardOverviews);
      }
    },
    [callApi, initialScorecardId, organizationId],
  );

  // Get the Scorecard
  const openScorecard = React.useCallback(
    async (scorecardId: string, isRequestActive?: () => boolean) => {
      const scorecardResponse =
        typeof scorecardId !== 'undefined'
          ? await callApi(() => scorecardGetId({ scorecardId }))
          : await callApi(() => scorecardGet({ organizationId }));

      if (!scorecardResponse) {
        return;
      }

      // if a function is provided to determine whether to propagate
      // a state update (to prevent race conditions), then it will
      // ensure not to update state. otherwise, each call updates state.
      if (!isRequestActive || isRequestActive()) {
        setScorecard(scorecardResponse?.scorecardInfo);
      }
    },
    [callApi, organizationId],
  );

  // Load scorecard + Dmi + scorecard overviews
  React.useEffect(() => {
    if (typeof initialScorecard === 'undefined' && typeof initialScorecardList === 'undefined') {
      reload();
    }
  }, [reload, initialScorecard, initialScorecardList]);

  if (!(scorecard && scorecards)) {
    return null;
  }

  return (
    <ScorecardMutationContext.Provider
      value={{
        triggerScorecardReload: isRequestActive => reload(isRequestActive),
      }}
    >
      <ScorecardContext.Provider
        value={{
          organizationName,
          scorecard,
          scorecardList: scorecards,
          openScorecard,
        }}
      >
        {children}
      </ScorecardContext.Provider>
    </ScorecardMutationContext.Provider>
  );
};

export default ScorecardProvider;
