import * as React from 'react';
import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { isIframe } from '~/extensions/packages/iframe/isIframe';
import { Link } from 'react-router-dom';
import { Styleable } from '~/neo-ui/model/capacity';

export type AnchorSize = 'sm' | 'md' | 'lg' | 'xlg';

const getAnchorFontSizeRem = (size: AnchorSize): number => {
  switch (size) {
    case 'sm':
      return 0.75;
    case 'md':
      return 0.875;
    case 'lg':
      return 1;
    case 'xlg':
      return 1.125;
  }
};

export type AnchorHeightSize = 'sm' | 'md';

const getAnchorHeightSizeRem = (height: AnchorHeightSize): number => {
  switch (height) {
    case 'sm':
      return 1.875;
    case 'md':
      return 2.25;
  }
};

export type AnchorProps = {
  href: string;
  color?: Color;
  size?: AnchorSize;
  heightSize?: AnchorHeightSize;
  shouldOpenInNewTab?: boolean;
  stopPropagation?: boolean;
  mailTo?: boolean;
} & Styleable;

const Anchor = ({
  href,
  color = 'primary-400',
  shouldOpenInNewTab,
  stopPropagation,
  size = 'md',
  heightSize,
  mailTo = false,
  className,
  children,
}: PropsWithChildren<AnchorProps>) =>
  isIframe() || typeof WM.account === 'undefined' || !WM.account.isEnceladusAllowed || mailTo ? (
    <a
      className={className}
      css={css`
        color: ${colorToCode(color)};
        font-size: ${getAnchorFontSizeRem(size)}rem;

        ${heightSize && `line-height: ${getAnchorHeightSizeRem(heightSize)}rem;`}
        &:hover {
          color: ${colorToCode(color)};
        }
      `}
      href={`${mailTo ? 'mailto:' : ''}${href}`}
      target={shouldOpenInNewTab ? '_blank' : undefined}
      onClick={event => {
        if (stopPropagation) {
          event.stopPropagation();
        }
      }}
    >
      {children}
    </a>
  ) : (
    <Link
      className={className}
      to={href}
      target={shouldOpenInNewTab ? '_blank' : undefined}
      onClick={event => {
        if (stopPropagation) {
          event.stopPropagation();
        }
      }}
      css={css`
        color: ${colorToCode(color)};
        font-size: ${getAnchorFontSizeRem(size)}rem;

        ${heightSize && `line-height: ${getAnchorHeightSizeRem(heightSize)}rem;`}
        &:hover {
          color: ${colorToCode(color)};
        }
      `}
    >
      {children}
    </Link>
  );

export default Anchor;
