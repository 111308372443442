import { Themeable } from '~/neo-ui/model/capacity';
import React, { ReactNode } from 'react';
import { css } from '@emotion/react';

type FilterHeaderProps = {
  selectedValues: string[];
  operator: string | undefined;
  emptyDisplay: ReactNode;
  selectedDisplay: ReactNode;
  fullWidth?: boolean;
} & Themeable;

const FilterHeader: React.FunctionComponent<FilterHeaderProps> = ({ selectedValues, operator, emptyDisplay, selectedDisplay }) => {
  let header: ReactNode;

  if (selectedValues.length === 0 || typeof operator === 'undefined') {
    header = emptyDisplay;
  } else {
    header = selectedDisplay;
  }

  return (
    <div
      css={css`
        & > * {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        width: 100%;
      `}
    >
      {header}
    </div>
  );
};

export default FilterHeader;
