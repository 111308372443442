import React from 'react';
import Select, { GroupBase, SelectComponentsConfig } from 'react-select';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { Styleable } from '~/neo-ui/model/capacity';
import { StylesProps } from 'react-select/dist/declarations/src/styles';
import { CSSObject } from '@emotion/styled';

// Fix breaking change from https://github.com/JedWatson/react-select/releases/tag/react-select%405.7.0
type StylesConfigFunction<Props> = (
  base: CSSObject & {
    label?: string;
  },
  props: Props,
) => CSSObject & {
  label?: string;
};

export type SingleSelectInternalProps<T extends string = string> = {
  menuOpen: boolean;
  setMenuOpen: (isOpen: boolean) => void;
  options: SelectOption<T>[] | GroupBase<SelectOption<T>>[];
  selectedOption: SelectOption<T> | undefined | null;
  defaultOption?: SingleSelectInternalProps<T>['selectedOption'];
  onOptionSelected: (option: SelectOption<T>) => void;
  placeholder?: React.ReactNode;
  /**
   * Replace react select's menu with our own that requires a ref
   */
  components: SelectComponentsConfig<SelectOption<T>, false, GroupBase<SelectOption<T>>>;
  isSearchable?: boolean;
  disabled?: boolean;
  shouldUseMenuPortal?: boolean;
  styles?: {
    [K in keyof StylesProps<SelectOption<T>, false, GroupBase<SelectOption<T>>>]?: StylesConfigFunction<
      StylesProps<SelectOption<T>, false, GroupBase<SelectOption<T>>>[K]
    >;
  };
  /**
   * Indicate if option description should be displayed on the control button
   */
  shouldControlRenderValue?: boolean;
} & Styleable;

const SingleSelectInternal = <T extends string>({
  menuOpen = false,
  setMenuOpen,
  options,
  selectedOption,
  defaultOption,
  onOptionSelected,
  placeholder,
  components,
  isSearchable = true,
  shouldUseMenuPortal = true,
  disabled = false,
  styles,
  className,
  shouldControlRenderValue,
}: SingleSelectInternalProps<T>) => {
  // Hacky solution to prevent LayoutHeader from covering dropdown menu
  const [body, setBody] = React.useState<HTMLElement>();
  React.useEffect(() => {
    setBody(document.body);
  }, []);

  return (
    <Select
      onMenuOpen={() => setMenuOpen(true)}
      onMenuClose={() => setMenuOpen(false)}
      isDisabled={disabled}
      className={className}
      menuPortalTarget={shouldUseMenuPortal ? body : undefined}
      menuIsOpen={menuOpen}
      options={options}
      defaultValue={defaultOption ?? selectedOption}
      value={selectedOption}
      onChange={selection => {
        setMenuOpen(false);
        return selection === null || selection === selectedOption ? selection : onOptionSelected(selection);
      }}
      placeholder={placeholder}
      isSearchable={isSearchable}
      components={components}
      menuPlacement={'auto'}
      controlShouldRenderValue={shouldControlRenderValue}
      styles={{
        ...styles,
        /**
         * Default the z-index of the menu portal to 1050 because our other portals sit on that
         * same z-index.
         */
        menuPortal: (provided: Record<string, unknown>) => ({
          ...provided,
          zIndex: 1050,
        }),
      }}
    />
  );
};

export default SingleSelectInternal;
