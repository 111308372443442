import { BudgetCurrencyDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Dto/Model.gen';
import { ScorecardItemDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardItem/Dto/Model.gen';
import * as React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import ScorecardItemFormData from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/form/ScorecardItemFormData';
import scorecardItemFormValidationSchema from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/form/validation/scorecardItemFormValidationSchema';
import useScorecardItemUpdate from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/hooks/useScorecardItemUpdate';
import categoryCustomKey from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/model/categoryCustomKey';

export type ScorecardItemUpdateFormProps = {
  scorecardItem: ScorecardItemDto;
  currency: BudgetCurrencyDto;
  onScorecardUpdateFailed: () => void;
  children: React.ReactNode;
};

const ScorecardItemUpdateForm = ({ scorecardItem, currency, onScorecardUpdateFailed, children }: ScorecardItemUpdateFormProps) => {
  const { updateScorecardItem } = useScorecardItemUpdate(scorecardItem.scorecardId, scorecardItem.scorecardItemId, {
    onFailure: onScorecardUpdateFailed,
  });

  const onSubmit = React.useCallback(
    async (data: ScorecardItemFormData) => {
      updateScorecardItem({
        category: {
          categoryUniqueId: data.category,
          categoryCustom: data.customCategory,
        },
        score: {
          value: parseInt(data.scoreValue, 10),
          description: data.scoreDescription ?? '',
        },
        investment: {
          costSubunits:
            typeof data.investmentCostUnits === 'undefined' || isNaN(parseInt(String(data.investmentCostUnits), 10))
              ? undefined
              : data.investmentCostUnits * currency.subunitRatio,
          description: data.investmentDescription ?? '',
          isInvestmentUnknown: data.isInvestmentUnknown,
        },
        priority: {
          priority: data.priority,
        },
      });
    },
    [currency.subunitRatio, updateScorecardItem],
  );

  const toFormData = React.useCallback(
    (scorecardItem: ScorecardItemDto): ScorecardItemFormData => ({
      category: scorecardItem.category.uniqueId,
      customCategory: scorecardItem.category.uniqueId === categoryCustomKey ? scorecardItem.category.label : undefined,
      priority: scorecardItem.priority.key.toString(),
      scoreValue: scorecardItem.score.value.toString(),
      scoreDescription: scorecardItem.score.description,
      investmentCostUnits:
        typeof scorecardItem.investment.costSubunits === 'undefined'
          ? undefined
          : scorecardItem.investment.costSubunits / currency.subunitRatio,
      investmentDescription: scorecardItem.investment.description,
      isInvestmentUnknown: scorecardItem.investment.isInvestmentUnknown,
    }),
    [currency.subunitRatio],
  );

  const defaultFormData = React.useMemo(() => toFormData(scorecardItem), [scorecardItem, toFormData]);

  return (
    <Form
      submitMethod={'auto'}
      defaultFormData={defaultFormData}
      validationSchema={scorecardItemFormValidationSchema}
      onSubmit={onSubmit}
      disableOverwriteDefaultFormDataOnSave={true}
      hideSubmitStatusIndicator={true}
    >
      {children}
    </Form>
  );
};

export default ScorecardItemUpdateForm;
