import { getIn } from 'formik';
import { ChangeEvent } from 'react';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export interface TextFieldProps {
  name: string;
  label?: string;
  id?: string;
  placeholder?: string;
  errorMsg?: string;
  resetValidation?: () => void;
  sizePx?: number;
}

const TextField = ({ name, label, id, placeholder, errorMsg, resetValidation, sizePx }: TextFieldProps) => {
  const { formik } = useMultiStepForm<PurchaseSubscriptionForm>();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(name, e.target.value);
    if (!!resetValidation) {
      resetValidation();
    }
  };
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      {label && (
        <p
          css={{
            marginBottom: '0rem',
            color: 'black',
          }}
        >
          {label}
        </p>
      )}
      <input
        type="text"
        id={id}
        placeholder={placeholder}
        onChange={handleOnChange}
        value={getIn(formik.values, name)}
        css={css`
          font-size: 1rem;
          ${sizePx &&
          `
            width: ${sizePx}px;
          `}
          height: 2.5rem;
          padding: 0.625rem;
          border-radius: 0.25rem;
          border: 1px solid ${errorMsg ? colorToCode('negative-400') : colorToCode('light-900')};
          background-color: ${errorMsg ? '#FFE8EC' : colorToCode('light-000')};
          :focus {
            border: 2px solid colorToCode('primary-400');
          }
        `}
      />
      {errorMsg && (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            margin-top: 0.5rem;
          `}
        >
          <Icon
            css={css`
              margin-top: 3px;
            `}
            icon={'Negative'}
            color={'negative-400'}
            sizePx={14}
          />
          <Label
            css={{
              marginLeft: '0.25rem',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '150%',
            }}
            color="negative-400"
            size={'sm'}
          >
            {errorMsg}
          </Label>
        </div>
      )}
    </div>
  );
};

export default TextField;
