import { ApiKeyPublicDto } from '@SsoClient/Sso/ApiKeyPublic/Dto.gen';
import * as React from 'react';
import DataTable, { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import TableCell from '~/neo-ui/packages/table/packages/table-cell/TableCell';
import { formatDateFriendly, formatTimeFriendly } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';

type ApiKeyTableProps = {
  apiKeyList: ApiKeyPublicDto[];
};

const ApiKeyTable = ({ apiKeyList }: ApiKeyTableProps) => {
  const apiKeyColumns: DataTableColumn<ApiKeyPublicDto>[] = React.useMemo(
    () => [
      {
        Header: 'Name',
        fieldKey: row => row.name,
        renderCell: row => <TableCell label={row.name} />,
        sortType: 'string',
      },
      {
        Header: 'Creation date',
        fieldKey: row => row.createdAt,
        renderCell: row => (
          <TableCell
            label={formatDateFriendly(parseISO(row.createdAt))}
            description={formatTimeFriendly(parseISO(row.createdAt))}
          />
        ),
      },
    ],
    [],
  );

  return (
    <DataTable
      title={'Your secret keys'}
      columns={apiKeyColumns}
      data={apiKeyList}
      EmptyStatePlaceholder={'No API keys available'}
      enableClientSorted={true}
      defaultSort={{
        key: data => data.createdAt,
        order: 'descending',
      }}
      sortableColumnKeys={['name', 'createdAt']}
    />
  );
};
export default ApiKeyTable;
