import { brandingMspGet } from '@AssetManagementClient/AssetManagementClientPublic.gen';
import { BrandingMspDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Branding/Dto/Model.gen';
import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

export type UseBrandingMspGet = { brandingMsp: BrandingMspDto | undefined };

const useBrandingMspGet = (): UseBrandingMspGet => {
  const [brandingMsp, setBrandingMsp] = useState<BrandingMspDto>();
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => brandingMspGet({}));

      if (!response) {
        return;
      }

      setBrandingMsp(response.branding);
    })();
  }, [callApi]);
  return { brandingMsp };
};

export default useBrandingMspGet;
