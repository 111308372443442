/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import callApi from '~/wm/packages/dashboard/packages/dashboard-grid/hook/callApi';
import { ComponentPayload } from '~/wm/packages/dashboard/packages/widget/Widget';
import { WidgetDto } from '@DashboardClient/Dashboard/Packages/Widget/Packages/Dto/Model.gen';
import { ComponentProps } from '~/wm/packages/dashboard/packages/dashboard-grid/DashboardGrid';

const useComponentProps = (widgetAvailability: WidgetDto[]) => {
  const [componentsProps, setComponentsProps] = React.useState<ComponentProps[]>();
  const reloadComponentProps = React.useCallback(async () => {
    const componentsEndpoints = widgetAvailability
      ?.map(widget =>
        widget.components.map(widgetComponent => ({
          id: widget.key,
          endpointUrl: widgetComponent.endpointUrl,
        })),
      )
      .flat();
    const responses = await Promise.all(
      componentsEndpoints.map(componentEndpoint => {
        if (!componentEndpoint.endpointUrl) {
          return undefined;
        }
        return callApi<any, ComponentPayload>('POST', componentEndpoint.endpointUrl, {}).catch(() => undefined);
      }),
    );
    const widgetComponentPayloads = componentsEndpoints.map((componentEndpoint, index) => ({
      id: componentEndpoint.id,
      payload: responses[index],
    }));
    setComponentsProps(widgetComponentPayloads);
  }, [widgetAvailability]);

  React.useEffect(() => {
    reloadComponentProps();
  }, [reloadComponentProps]);

  return { componentsProps, reloadComponentProps };
};
export default useComponentProps;
