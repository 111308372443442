import Window from '~/neo-ui/packages/window/Window';
import Button from '~/neo-ui/packages/button/Button';
import TableSelectable from '~/neo-ui/packages/table/packages/table-selectable/TableSelectable';
import usePeopleDashboardContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/context/hooks/usePeopleDashboardContext';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import { css } from '@emotion/react';
import useOrganizationKeyContactAdd from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/useOrganizationKeyContactAdd';
import { OrganizationContactDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Contact/Dto/Model.gen';
import { useMemo, useState } from 'react';
import { TableClientSideColumn } from '~/neo-ui/packages/table/packages/table-client-side/TableClientSide';

type OrganizationKeyContactAddWindowProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

const OrganizationKeyContactAddWindow = ({ isOpen, onDismiss }: OrganizationKeyContactAddWindowProps) => {
  const { organizationId } = useOrganizationContext();
  const { organizationContacts, organizationKeyContacts } = usePeopleDashboardContext();

  const { addOrganizationKeyContact, isAddingOrganizationKeyContact } = useOrganizationKeyContactAdd();

  const [selectedContactIds, setSelectedContactIds] = useState<Set<string>>(new Set());
  const [currentPage, setCurrentPage] = useState(0);

  // Filter out already added contacts
  const filteredOrganizationContacts = useMemo(
    () =>
      organizationContacts.filter(
        contact => !organizationKeyContacts.find(keyContact => keyContact.organizationContactId === contact.organizationContactId),
      ),
    [organizationContacts, organizationKeyContacts],
  );

  const contactColumns: TableClientSideColumn<OrganizationContactDto>[] = useMemo(
    () => [
      {
        fieldKey: contact => contact.fullName,
        header: 'Name',
        renderCell: ({ original: contact }) => contact.fullName,
      },
      {
        fieldKey: contact => contact.title,
        header: 'Role',
        renderCell: ({ original: contact }) => contact.title,
      },
      {
        fieldKey: contact => contact.email,
        header: 'Email',
        renderCell: ({ original: contact }) => contact.email,
      },
    ],
    [],
  );

  const addKeyContacts = () => {
    const userIds = Array.from(selectedContactIds);
    addOrganizationKeyContact({
      organizationId,
      organizationContactIds: userIds,
    });
    setSelectedContactIds(new Set());
    onDismiss();
  };

  return (
    <Window
      maxWidthRem={39.5}
      titleIcon={'Add'}
      title={`Add Key Contacts`}
      isOpen={isOpen}
      onDismiss={onDismiss}
      footerProps={{
        leftControls: [
          ...(selectedContactIds.size > 0
            ? [
                {
                  expanded: (
                    <Button
                      iconLeft={'Cancel'}
                      onClick={() => {
                        setSelectedContactIds(new Set());
                      }}
                    >
                      {selectedContactIds.size} key contact
                      {selectedContactIds.size === 1 ? '' : 's'} selected
                    </Button>
                  ),
                },
              ]
            : []),
        ],
        rightControls: [
          {
            expanded: (
              <Button
                theme={'primary'}
                onClick={() => {
                  addKeyContacts();
                }}
                loading={isAddingOrganizationKeyContact}
              >
                Done
              </Button>
            ),
          },
          {
            expanded: (
              <Button
                onClick={() => {
                  onDismiss();
                }}
                disabled={isAddingOrganizationKeyContact}
              >
                Cancel
              </Button>
            ),
          },
        ],
      }}
    >
      <TableSelectable
        data={filteredOrganizationContacts}
        dataKey={'organizationContactId'}
        columns={contactColumns}
        dataTableProps={{
          pagination: { pageSize: 15, pageIndex: currentPage },
          onPageChange: pagination => setCurrentPage(pagination.pageIndex),
          children: ({ Table, Pagination, GlobalFilter }) => (
            <div>
              <div
                css={css`
                  display: flex;
                  gap: 0.5rem;
                  margin-bottom: 1rem;
                `}
              >
                <GlobalFilter placeholder={'Search name, role, or email'} />
                <Pagination />
              </div>
              <Table />
            </div>
          ),
        }}
        updateSelectedIds={setSelectedContactIds}
        selectedIds={selectedContactIds}
      />
    </Window>
  );
};

export default OrganizationKeyContactAddWindow;
