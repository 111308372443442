import { insightsCreate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { AssetScopeGroupIdentifier } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/AssetScope/Model.gen';
import { PolicySetting } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Model/InsightCreatePayloadNested.gen';
import { useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

type CreateInsightFunction = (
  title: string,
  description: string,
  assetGroupIdentifier: AssetScopeGroupIdentifier,
  forecastDays: number | undefined,
  policySettings: PolicySetting[],
) => Promise<boolean>;

type UseInsightCreate = () => {
  createInsight: CreateInsightFunction;
};

const useInsightCreate: UseInsightCreate = () => {
  const { callApi } = useApi();

  const createInsight = useCallback<CreateInsightFunction>(
    async (title, description, assetGroupIdentifier, forecastDays, policySettings) => {
      const response = await callApi(() =>
        insightsCreate({
          title,
          description,
          assetScopeApplicableTo: assetGroupIdentifier,
          policySettings,
          forecastDays,
        }),
      );

      if (!response) {
        return false;
      }
      return true;
    },
    [callApi],
  );

  return {
    createInsight,
  };
};

export default useInsightCreate;
