import { createContext } from 'react';

export type EngagementNoteListPanelTabMutationContextData = {
  triggerEngagementNoteListPanelTabReload: (isRequestActive?: () => boolean) => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const EngagementNoteListPanelTabMutationContext = createContext<EngagementNoteListPanelTabMutationContextData>({
  // If there's no provider, it's a graceful no-op
  triggerEngagementNoteListPanelTabReload: async () => {},
});

export default EngagementNoteListPanelTabMutationContext;
