import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import EmptyStateInfo from '~/neo-ui/packages/empty-state-info/EmptyStateInfo';

const OrganizationsSummaryEmptyState: React.FunctionComponent<{
  issuesPageUrl: string;
}> = ({ issuesPageUrl }) => (
  <EmptyStateInfo
    imageSource={'/i/graphic/software/filter/secondary.png'}
    imageWidth={'7rem'}
    title={'No clients found'}
    description={'If you think your integrations may have encountered a problem, check the Issues section.'}
    CallToAction={
      <Button
        size="lg"
        anchor={{ href: issuesPageUrl }}
      >
        Check for Issues
      </Button>
    }
  />
);

export default OrganizationsSummaryEmptyState;
