/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/no-unsafe-return */
import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';
import { formatRequest, formatResponse } from '@WarrantyClient/WarrantyClientWmManagement.gen';
import { sleep } from '~/extensions/packages/sleep/sleep';

const callApi = async <TRequest, TResponse>(method: Method, path: string, request: TRequest, attemptCount = 1): Promise<TResponse> => {
  try {
    const axiosRequest = createAxiosRequest(method, path, formatRequest(request), false);
    const response = await axios(axiosRequest);
    return formatResponse(response.data);
  } catch (error) {
    const err = error as AxiosError;
    const csrfToken = err.response?.headers['X-XSRF-TOKEN'.toLowerCase()] as string;
    if (typeof csrfToken !== 'undefined' && attemptCount < 6) {
      localStorage.setItem('XSRF-TOKEN', csrfToken);

      // Sleep for exponential back-off in case the service is down
      await sleep(Math.random() * 10 * Math.pow(3, attemptCount - 1) + 5);

      return callApi(method, path, request, attemptCount + 1);
    }
    throw error;
  }
};

const createAxiosRequest = <TRequest,>(
  callMethod: Method,
  path: string,
  request: TRequest,
  isStreamRequest: boolean,
): AxiosRequestConfig => {
  const csrfToken = localStorage.getItem('XSRF-TOKEN');
  const requestConfig: AxiosRequestConfig = {
    method: callMethod,
    url: path,
    data: request,
    headers: {
      ...(csrfToken !== null && { 'X-XSRF-TOKEN': csrfToken }),
    },
  };
  if (isStreamRequest) {
    requestConfig.responseType = 'stream';
    requestConfig.timeout = 99000; // milliseconds
  }
  return requestConfig;
};

export default callApi;
