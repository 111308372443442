import { css, SerializedStyles } from '@emotion/react';
import * as React from 'react';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd';
import { Styleable } from '~/neo-ui/model/capacity';

type DraggableContext = (provided: DraggableProvided) => React.ReactNode;

export type ContainerDisplaySettings = {
  /**
   * Used when an element is being dragged
   */
  dragCss?: SerializedStyles;
  /**
   * Used for an element that is being combined with another element
   */
  combineCss?: SerializedStyles;
  /**
   * When element is "stationary", the css (toggled) when element is not dragging
   */
  stationaryCss?: SerializedStyles;
  /**
   * Node to display if combining
   */
  combineNode?: React.ReactNode;
};

export type DraggableElementProps = {
  draggableId: string;
  index: number;
  containerCssStates?: ContainerDisplaySettings;
  onClick?: () => void;
  isDragDisabled?: boolean;
  /**
   * Indicate if the draggable element is using a custom drag handler
   */
  isUsingCustomizeDragHandler?: boolean;
  /**
   * Type draggableContext can be used to pass draggable properties to children components
   * Example usage: adding custom drag handler to children components
   */
  children?: DraggableContext | React.ReactNode;
} & Styleable;

const DraggableElement: React.FunctionComponent<DraggableElementProps> = ({
  draggableId,
  index,
  children,
  className,
  containerCssStates,
  onClick,
  isDragDisabled = false,
  isUsingCustomizeDragHandler = false,
}) => (
  <Draggable
    key={draggableId}
    draggableId={draggableId}
    index={index}
    isDragDisabled={isDragDisabled}
  >
    {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
      <div
        onClick={e => {
          e.stopPropagation();
          if (onClick) {
            onClick();
          }
        }}
        ref={provided.innerRef}
        className={className}
        css={css`
          user-select: none;

          /* On Combining With Style */
          ${Boolean(snapshot.combineTargetFor) && containerCssStates?.combineCss};

          /* On Drag Style */
          ${snapshot.isDragging ? containerCssStates?.dragCss : containerCssStates?.stationaryCss};
        `}
        {...provided.draggableProps}
        {...(!isUsingCustomizeDragHandler && provided.dragHandleProps)}
      >
        {Boolean(snapshot.combineTargetFor) && typeof containerCssStates?.combineNode !== 'undefined'
          ? containerCssStates?.combineNode
          : typeof children === 'function'
          ? children(provided)
          : children}
      </div>
    )}
  </Draggable>
);

export default DraggableElement;
