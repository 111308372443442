import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color from '~/neo-ui/packages/color/Color.gen';

export type ServiceRequestInfoBlockProps = {
  title: string;
  titleColor?: Color;
  description: string;
  icon: IconType;
  iconColor?: Color;
};

const ServiceRequestInfoBlock = ({ title, titleColor, description, icon, iconColor }: ServiceRequestInfoBlockProps) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      gap: 0.8125rem;
    `}
  >
    <Icon
      icon={icon}
      sizePx={22}
      color={iconColor}
    />
    <div>
      <Label
        bold={true}
        color={titleColor}
        css={css`
          margin-bottom: 0.3125rem;
        `}
      >
        {title}
      </Label>
      <Label muted={true}>{description}</Label>
    </div>
  </div>
);

export default ServiceRequestInfoBlock;
