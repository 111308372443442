import { Styleable } from '~/neo-ui/model/capacity';
import MultiSelectStatic, { MultiSelectStaticSelection } from '~/neo-ui/packages/select/packages/multi-select-static/MultiSelectStatic';
import React from 'react';
import Popover from '~/neo-ui/packages/popover/Popover';
import { RenderFilterNested } from '@AssetManagementClient/BeastClient/Search/Model/Query/Field/Filter/Render.gen';
import { Filter } from '~/neo-ui/packages/table/packages/console/types';
import { FilterValue } from '@AssetManagementClient/BeastClient/Search/Model/Filter.gen';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import Theme from '~/neo-ui/packages/color/Theme';
import Testable from '~/neo-ui/packages/testable/Testable';
import { pascalCaseToSpinalCase } from '~/extensions/packages/casing/pascalSpinalConversion';
import { MultiSelectHeaderConsoleFormatterType } from '~/neo-ui/packages/filter/packages/filter-multi-select/packages/filter-multi-select-static/MultiSelectHeaderConsoleFormatter';

export type MultiSelectFilterProps = {
  filter: Filter;
  render: RenderFilterNested.MultiSelect;
  parameterValue: Map<string, string[]> | undefined;
  setValue: (key: string, values: Map<string, string[]>) => void;
  headerFormatter: MultiSelectHeaderConsoleFormatterType;
} & Styleable;

const MultiSelectFilter: React.FunctionComponent<MultiSelectFilterProps> = ({
  filter,
  render,
  parameterValue,
  setValue,
  headerFormatter,
}) => {
  const allOptions = render.options?.map(
    (option: FilterValue) =>
      ({
        label: option.label,
        value: option.key,
      } as SelectOption),
  );

  let selectedValues = [] as FilterValue[];
  let selectionType: string | undefined;
  if (typeof parameterValue !== 'undefined' && parameterValue.size > 0) {
    const values = new Set(parameterValue.get(render.displayOptions!.filterValue.key) ?? []);
    selectedValues = render.options.filter(v => values.has(v.key));
    const selectionTypeRaw = parameterValue.get(render.selectionTypeName);
    if (typeof selectionTypeRaw !== 'undefined' && selectionTypeRaw.length > 0) {
      selectionType = selectionTypeRaw[0];
    }
  }

  const onChange = React.useCallback(
    (selection: MultiSelectStaticSelection<string>) => {
      if (selection.selectedOptions === undefined || selection.selectedOptions === null) {
        return;
      }

      setValue(
        filter.key,
        selection.selectedOptions?.length === 0
          ? new Map<string, string[]>()
          : new Map([[render.displayOptions!.filterValue.key, selection.selectedOptions]]),
      );
    },
    [filter.key, render.displayOptions, setValue],
  );

  const headerCallback = headerFormatter(
    filter.label,
    render.displayOptions?.filterValue?.label ?? '',
    selectedValues,
    render.displayOptions?.colorTheme as Theme,
  );

  /** ***************
   * Main component *
   ******************/
  return (
    <div>
      <Testable testId={`asset-console-filter-${pascalCaseToSpinalCase(filter.key)}-popover`}>
        <Popover
          header={headerCallback}
          disabled={!filter.isEnabled}
        >
          <MultiSelectStatic
            key={filter.label + 'multi-select'}
            headerLabel={filter.label}
            allOptions={allOptions}
            selectedOptions={selectedValues.map(v => ({
              value: v.key,
              label: v.label,
            }))}
            onChange={onChange}
            allSelectionTypes={render.optionSelectionTypes}
            selectionType={selectionType}
          />
        </Popover>
      </Testable>
    </div>
  );
};

export default MultiSelectFilter;
