import * as React from 'react';
import { Fragment, FunctionComponent, useMemo, useState } from 'react';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { Column } from '~/neo-ui/packages/table/packages/console/types';
import { ConsoleRow } from '@AssetManagementClient/BeastClient/Search/Model/Console.gen';
import { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { getMinSizeInRem } from '~/neo-ui/packages/table/packages/console/ConsoleUtilities';
import AssetConsoleWindow from '~/wm/packages/asset/packages/console/packages/asset-console/AssetConsoleWindow';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/AssetScope/Logic/AssetScopeFactoryNested.gen';
import ConsoleRowCheckboxHeader from '~/neo-ui/packages/table/packages/console/checkboxes/ConsoleRowCheckboxHeader';
import ConsoleRowCheckbox from '~/neo-ui/packages/table/packages/console/checkboxes/ConsoleRowCheckbox';
import HardwareTable, { getHardwareTableDisplayColumns } from '~/wm/packages/hardware/packages/hardware-table-section/HardwareTable';
import { css } from '@emotion/react';

export type HardwareTableSelectableSectionProps = {
  scope: FrontendScope;
  displayColumns: Column[];
  displayedAssetIds: string[];

  setDisplayedAssetIds: (assetIds: string[]) => void;
  selectedAssetIds: Set<string>;
  lastSelectedAssetId: string | undefined;
  selectAssetIds: (items: string[], itemClicked: string | undefined) => void;
  deselectAssetIds: (items: string[], itemClicked: string | undefined) => void;
  selectAssetId: (item: string) => void;
  deselectAssetId: (item: string) => void;
  clearSelectedAssetIds: () => void;
  onDisplayChange: (assetIds: string[]) => void;
  isAddAssetWindowOpen: boolean;
  onAddAssetWindowDismiss: () => void;
  canSelectDisplayColumns?: boolean;
};

const HardwareTableSelectableSection: FunctionComponent<HardwareTableSelectableSectionProps> = ({
  scope,
  displayColumns,
  displayedAssetIds,
  setDisplayedAssetIds,

  selectedAssetIds,
  lastSelectedAssetId,
  selectAssetIds,
  deselectAssetIds,
  selectAssetId,
  deselectAssetId,
  clearSelectedAssetIds,

  onDisplayChange,
  isAddAssetWindowOpen,
  onAddAssetWindowDismiss,
  canSelectDisplayColumns,
}) => {
  const [assetRows, setAssetRows] = useState<ConsoleRow[]>([]);
  const columnsWithoutCheckbox: DataTableColumn<ConsoleRow>[] = useMemo(
    () => getHardwareTableDisplayColumns(displayColumns),
    [displayColumns],
  );

  const columns: DataTableColumn<ConsoleRow>[] = useMemo(
    () => [
      {
        fieldKey: 'checkboxes',
        Header: (
          <ConsoleRowCheckboxHeader
            allCurrentIds={displayedAssetIds}
            selectedAssetIds={selectedAssetIds}
            onSelect={selectAssetIds}
            onDeselect={clearSelectedAssetIds}
          />
        ),
        renderCell: (row: ConsoleRow) => (
          <ConsoleRowCheckbox
            key={`row-${row.id}`}
            row={row}
            allDisplayedRows={assetRows}
            lastSelectedId={lastSelectedAssetId}
            onDeselectMany={deselectAssetIds}
            onSelectMany={selectAssetIds}
            onSelect={selectAssetId}
            onDeselect={deselectAssetId}
            isSelected={[...selectedAssetIds].includes(row.id)}
          />
        ),
        width: '2.5rem',
      },
      ...columnsWithoutCheckbox,
    ],
    [
      assetRows,
      clearSelectedAssetIds,
      columnsWithoutCheckbox,
      deselectAssetId,
      deselectAssetIds,
      displayedAssetIds,
      lastSelectedAssetId,
      selectAssetId,
      selectAssetIds,
      selectedAssetIds,
    ],
  );

  return (
    <Fragment>
      <HardwareTable
        scope={scope}
        assetRows={assetRows}
        columns={columns}
        sortableColumnKeys={displayColumns.filter(column => column.isSortable).map(column => column.key)}
        displayedAssetIds={displayedAssetIds}
        onAssetRowsUpdated={assetRows => setAssetRows(assetRows)}
        minWidthRem={displayColumns.reduce((sum, current) => sum + getMinSizeInRem(current.size), 0)}
      />
      <AssetConsoleWindow
        isOpen={isAddAssetWindowOpen}
        onDismiss={() => {
          history.pushState(null, '', window.location.pathname);
          onAddAssetWindowDismiss();
        }}
        assetType={Enum.Hardware}
        frontendScope={scope}
        onAssetSelected={assetIds => {
          history.pushState(null, '', window.location.pathname);
          setDisplayedAssetIds([...displayedAssetIds, ...assetIds]);
          onDisplayChange([...displayedAssetIds, ...assetIds]);
        }}
        canSelectDisplayColumns={canSelectDisplayColumns}
        css={css`
          height: 100vh;
        `}
      />
    </Fragment>
  );
};

export default HardwareTableSelectableSection;
