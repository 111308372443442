import { createContext } from 'react';

export type AutoEmailReportUrlContextData = {
  autoEmailReportEmailTemplateEditUrl: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const AutoEmailReportUrlContext = createContext<AutoEmailReportUrlContextData | undefined>(undefined);

export default AutoEmailReportUrlContext;
