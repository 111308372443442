import { css } from '@emotion/react';
import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import ModalInternal from '~/neo-ui/packages/modal/ModalInternal';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type SummaryModalProps = {
  header: React.ReactNode;

  isOpen: boolean;
  onDismiss?: () => void;

  callToAction?: { label: string; url: string; openInNewTab: boolean };

  children?: React.ReactNode;
};

/**
 * @deprecated deprecated by ModalConfirmation
 */
const SummaryModal: React.FunctionComponent<SummaryModalProps> = ({
  header,

  isOpen = false,
  onDismiss = () => {},

  callToAction,

  children,
}) => (
  <ModalInternal
    header={
      <div
        css={css`
          background-color: ${colorToCode('light-100')};
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: 1rem 1rem;
          border-bottom: 1px solid #e9ecef;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          margin: -1.25rem -1.25rem 0 -1.25rem;
        `}
      >
        <ButtonModifier
          icon={'Cancel'}
          onClick={onDismiss}
          css={css`
            flex-shrink: 0;
            margin-right: 1rem;
          `}
        />
        <div
          css={css`
            flex: 1;
          `}
        >
          {header}
        </div>
      </div>
    }
    maxWidthRem={31.25}
    isOpen={isOpen}
    onDismiss={onDismiss}
  >
    <div>
      {children}
      {callToAction && (
        <div>
          <Button
            css={css`
              margin-top: 1.875rem;
            `}
            anchor={{
              href: callToAction.url,
              openInNewTab: callToAction.openInNewTab,
            }}
            iconRight={'ArrowRight'}
          >
            {callToAction.label}
          </Button>
        </div>
      )}
    </div>
  </ModalInternal>
);

export default SummaryModal;
