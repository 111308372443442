import WarrantyTreeImpactOrderPotentialCard from '~/wm/packages/warranty/packages/tree-impact/packages/warranty-tree-impact-order-potential-card/WarrantyTreeImpactOrderPotentialCard';
import WarrantyTreeImpactOrderSuccessSummaryCard from './packages/warranty-tree-impact-order-success-summary-card/WarrantyTreeImpactOrderSuccessSummaryCard';

export type TreeImpactSectionProps = {
  totalOrderUnits: number;
  treeImpactHelpGuideUrl: string;
  isCheckoutSubmitted: boolean;
};

const TreeImpactSection = ({
  totalOrderUnits: totalOrderUnitsProp,
  treeImpactHelpGuideUrl,
  isCheckoutSubmitted,
}: TreeImpactSectionProps) => {
  // This is here because numbers become strings? (from php)
  const totalOrderUnits = typeof totalOrderUnitsProp === 'string' ? Number(totalOrderUnitsProp) : totalOrderUnitsProp;

  return isCheckoutSubmitted ? (
    <WarrantyTreeImpactOrderSuccessSummaryCard
      totalOrderUnits={totalOrderUnits}
      helpGuideUrl={treeImpactHelpGuideUrl}
    />
  ) : (
    <WarrantyTreeImpactOrderPotentialCard
      totalOrderUnits={totalOrderUnits}
      helpGuideUrl={treeImpactHelpGuideUrl}
    />
  );
};

export default TreeImpactSection;
