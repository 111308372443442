import { Fragment, useState } from 'react';
import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import Button from '~/neo-ui/packages/button/Button';
import TicketUnlinkModal from '~/wm/packages/integration/packages/ticket/packages/ticket-unlink-modal/TicketUnlinkModal';
import useEngagementActionTicketUnlink from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionTicketUnlink';

export type EngagementActionTicketUnlinkButtonProps = {
  action: EngagementActionDto;
  disabled: boolean;
};

const EngagementActionTicketUnlinkButton = ({ action, disabled }: EngagementActionTicketUnlinkButtonProps) => {
  const [isTicketUnlinkConfirmationWindowOpened, setIsTicketUnlinkConfirmationWindowOpened] = useState(false);
  const { unlinkEngagementActionTicket, isUnlinkingTicket } = useEngagementActionTicketUnlink({
    onSuccess: () => {
      setIsTicketUnlinkConfirmationWindowOpened(false);
    },
  });

  return (
    <Fragment>
      <Button
        theme={'danger'}
        iconLeft={'ClientUnlink'}
        preventOnClickPropagation={true}
        disabled={isUnlinkingTicket || disabled}
        loading={isUnlinkingTicket}
        onClick={() => {
          setIsTicketUnlinkConfirmationWindowOpened(true);
        }}
      >
        Unlink PSA Ticket
      </Button>
      {isTicketUnlinkConfirmationWindowOpened && (
        <TicketUnlinkModal
          isOpen={isTicketUnlinkConfirmationWindowOpened}
          isUnlinkingTicket={isUnlinkingTicket}
          onUnlinkTicket={() => {
            unlinkEngagementActionTicket(action.engagementActionId);
          }}
          onDismiss={() => {
            setIsTicketUnlinkConfirmationWindowOpened(false);
          }}
        />
      )}
    </Fragment>
  );
};

export default EngagementActionTicketUnlinkButton;
