import Form from '~/neo-ui/packages/form/Form';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { BudgetQuarterDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Packages/BudgetQuarter/Dto/Model.gen';

import useInitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesContext';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useDownloadRoadmapPdfNew from '~/wm/packages/strategy/packages/roadmap-page/hooks/useDownloadRoadmapPdfNew';
import RoadmapPdfDownloadFormValidationSchema from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap-share-button/packages/roadmap-pdf-download-form/validation/RoadmapPdfDownloadFormValidationSchema';
import { Quarter } from '~/extensions/packages/date/packages/fiscal-quarter/FiscalQuarter';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';
import useRoadmapFeatureFlagsContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapFeatureFlagsContext';

export type RoadmapPdfDownloadFormData = {
  includedStatuses: string[];
  includedPriority: string[];
  startingFiscalQuarter: BudgetQuarterDto;
  endFiscalQuarter: BudgetQuarterDto;
  shouldIncludeUnscheduledInitiative: boolean;
  shouldIncludeBudgets: boolean;
  shouldIncludeAssets: boolean;
  sortColumn: string;
  shouldIncludeCoverPage: boolean;
  shouldIncludeOverview: boolean;
};

export type RoadmapPdfDownloadFormProps = {
  onDownload: () => void;
  children?: ReactNode;
};

const getDefaultEndQuarter = (currentQuarter: Quarter): Quarter => {
  switch (currentQuarter) {
    case 1:
      return 4;
    case 2:
      return 1;
    case 3:
      return 2;
    case 4:
      return 3;
  }
};

const RoadmapPdfDownloadForm = ({ onDownload, children }: RoadmapPdfDownloadFormProps) => {
  const { availabilities } = useInitiativeAvailabilitiesContext();
  const { downloadRoadmapPdfNew } = useDownloadRoadmapPdfNew(onDownload);
  const { organizationId } = useOrganizationContext();
  const { currentFiscalQuarter } = useRegionalSettingsInfoContext();
  const { isFeatureFlagEnabled } = useRoadmapFeatureFlagsContext();

  const pdfOverviewEnabled = isFeatureFlagEnabled('lm-roadmaps-pdf-overview');

  const onSubmit = useCallback(
    async (data: RoadmapPdfDownloadFormData) =>
      downloadRoadmapPdfNew({
        organizationId,
        includedStatuses: data.includedStatuses,
        shouldIncludeAssets: data.shouldIncludeAssets,
        shouldIncludeBudgets: data.shouldIncludeBudgets,
        includedFiscalQuarterRange: {
          starting: {
            year: data.startingFiscalQuarter.year,
            quarter: data.startingFiscalQuarter.quarter,
          },
          end: {
            year: data.endFiscalQuarter.year,
            quarter: data.endFiscalQuarter.quarter,
          },
        },
        includedPriorities: data.includedPriority,
        shouldIncludeUnscheduled: data.shouldIncludeUnscheduledInitiative,
        sortingColumn: data.sortColumn,
        shouldIncludeCoverPage: data.shouldIncludeCoverPage,
        shouldIncludeOverview: data.shouldIncludeOverview,
      }),
    [downloadRoadmapPdfNew, organizationId],
  );

  const toFormData = useCallback(
    (): RoadmapPdfDownloadFormData => ({
      includedStatuses: availabilities.statusAvailabilities.map(status => status.id),
      includedPriority: availabilities.priorityAvailabilities.map(priority => priority.key),
      startingFiscalQuarter: {
        year: currentFiscalQuarter.year,
        quarter: currentFiscalQuarter.quarter,
      },
      endFiscalQuarter: {
        year: currentFiscalQuarter.quarter === 1 ? currentFiscalQuarter.year : currentFiscalQuarter.year + 1,
        quarter: getDefaultEndQuarter(currentFiscalQuarter.quarter),
      },
      shouldIncludeAssets: true,
      shouldIncludeBudgets: true,
      shouldIncludeUnscheduledInitiative: false,
      sortColumn: availabilities.roadmapPdfSortingColumnsAvailabilities.at(-1)?.id ?? '',
      shouldIncludeCoverPage: pdfOverviewEnabled,
      shouldIncludeOverview: pdfOverviewEnabled,
    }),
    [
      availabilities.priorityAvailabilities,
      availabilities.roadmapPdfSortingColumnsAvailabilities,
      availabilities.statusAvailabilities,
      currentFiscalQuarter.quarter,
      currentFiscalQuarter.year,
      pdfOverviewEnabled,
    ],
  );

  const defaultFormData = useMemo(() => toFormData(), [toFormData]);

  return (
    <Form
      submitMethod={'manual'}
      defaultFormData={defaultFormData}
      validationSchema={RoadmapPdfDownloadFormValidationSchema(currentFiscalQuarter)}
      onSubmit={onSubmit}
      hideSubmissionButton={true}
    >
      {children}
    </Form>
  );
};

export default RoadmapPdfDownloadForm;
