import useDisposalOrderInfoMutation, {
  UseDisposalOrderInfoMutationOptions,
} from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderInfoMutation';
import { disposalOrderPackagePurchase } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { Request } from '@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPackagePurchaseControllerNested.gen';

export type UseDisposalOrderPackagePurchase = () => {
  packagePurchase: (request: Request, isRequestActive?: () => boolean) => void;
  isPurchasingPackage: boolean;
};

const useDisposalOrderPackagePurchase: UseDisposalOrderPackagePurchase = (options?: UseDisposalOrderInfoMutationOptions) => {
  const [packagePurchase, isPurchasingPackage] = useDisposalOrderInfoMutation(
    (request: Request) => disposalOrderPackagePurchase(request),
    options,
  );

  return { packagePurchase, isPurchasingPackage };
};

export default useDisposalOrderPackagePurchase;
