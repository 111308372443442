import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import useScorecardCreate from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardCreate';
import useScorecardUrlContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardUrlContext';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';

const ScorecardCreateButton = () => {
  const { scorecardIndividualViewUrl } = useScorecardUrlContext();
  const { organizationId } = useOrganizationContext();
  const { createScorecard, isCreatingScorecard } = useScorecardCreate({
    onSuccessEffect: {
      type: 'redirect',
      url: response =>
        scorecardIndividualViewUrl
          .replace('__ORGANIZATION_ID__', organizationId)
          .replace('__SCORECARD_ID__', response.scorecardInfo!.scorecardId),
    },
  });

  return (
    <Button
      size={'md'}
      iconLeft={'Add'}
      theme={'primary'}
      onClick={() => createScorecard(organizationId)}
      preventOnClickPropagation={true}
      loading={isCreatingScorecard}
      disabled={isCreatingScorecard}
    >
      New Scorecard
    </Button>
  );
};

export default ScorecardCreateButton;
