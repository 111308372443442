import { Styleable } from '~/neo-ui/model/capacity';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Theme from '~/neo-ui/packages/color/Theme';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Header from '~/neo-ui/packages/text/packages/header/Header';

export type PanelTabProps = {
  isTabSelected: boolean;
  title: string;
  titleIcon?: IconType;
  theme?: Theme;
  onSelect?: () => void;
} & Styleable;

const PanelTab = ({ isTabSelected, title, titleIcon, theme = 'primary', onSelect, className }: PanelTabProps) => {
  const { themeMap } = useTheme(theme);

  const colorMap = {
    textColorNonActive: 'dark-100' as Color,
    textColorActive: themeMap.foregroundAccent,

    iconColorNonActive: 'dark-700' as Color,
    iconColorActive: themeMap.foregroundAccent,
  };

  return (
    <button
      className={className}
      css={css`
        background-color: transparent;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-width: 0.1875rem !important;
        border-bottom-style: solid !important;
        border-bottom-color: ${isTabSelected ? colorToCode(colorMap.textColorActive) : 'transparent'};
        padding: 0.5rem 0.5rem;
        // Using text-shadow instead of font-weight to prevent tab width from growing
        text-shadow: ${isTabSelected ? '0.03125rem 0 0' : 'unset'};
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &:focus {
          // important required to override bootstrap
          outline: none !important;
        }

        // interaction color styles

        &:not(:disabled) {
          &:hover {
            text-decoration: none;
          }
        }
      `}
      onClick={() => {
        if (typeof onSelect !== 'undefined') {
          onSelect();
        }
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;
        `}
      >
        {titleIcon && (
          <Icon
            color={isTabSelected ? colorMap.iconColorActive : colorMap.iconColorNonActive}
            icon={titleIcon}
            sizePx={16}
          />
        )}
        <Header
          size={5}
          weight={'bold'}
          color={isTabSelected ? colorMap.textColorActive : colorMap.textColorNonActive}
        >
          {title}
        </Header>
      </div>
    </button>
  );
};

export default PanelTab;
