import WizardPageStep from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageStep';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import WarrantyTransferFormData from '~/wm/packages/warranty/packages/coverage/packages/coverage-transfer/types/WarrantyTransferFormData';
import OriginalSection from '~/wm/packages/warranty/packages/coverage/packages/coverage-transfer/wizard/section/OriginalSection';
import ServiceRequest from '~/wm/packages/warranty/packages/service-request/model/ServiceRequest';
import ReplacementSection from '~/wm/packages/warranty/packages/coverage/packages/coverage-transfer/wizard/section/ReplacementSection';

const serialNumberFieldKey: FieldKeyExpression<WarrantyTransferFormData> = values => values.replacementAsset.serialNumber;

const modelNumberFieldKey: FieldKeyExpression<WarrantyTransferFormData> = values => values.replacementAsset.modelNumber;

const nameFieldKey: FieldKeyExpression<WarrantyTransferFormData> = values => values.replacementAsset.name;

const manufacturerFieldKey: FieldKeyExpression<WarrantyTransferFormData> = values => values.replacementAsset.manufacturer;

const getWarrantyTransferAssetStep = (serviceRequest: ServiceRequest): WizardPageStep<WarrantyTransferFormData> => ({
  header: {
    label: 'Transfer Coverage',
    icon: 'Transfer',
  },
  fields: [serialNumberFieldKey, modelNumberFieldKey, manufacturerFieldKey],
  sections: [
    () => OriginalSection({ serviceRequest, currentStepIndex: 0 }),
    () =>
      ReplacementSection({
        serialNumberFieldKey,
        modelNumberFieldKey,
        nameFieldKey,
        manufacturerFieldKey,
      }),
  ],
  onCompleteStateChange: () => true,
  key: 'Transfer Coverage',
});

export default getWarrantyTransferAssetStep;
