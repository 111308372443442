export const forEach = <T>(iterable: Iterable<T>, f: (t: T) => void) => {
  for (const el of iterable) {
    f(el);
  }
};

export const count = <T>(iterable: Iterable<T>) => {
  let n = 0;
  forEach(iterable, _ => n++);
  return n;
};

export const map = function* <T, U>(iterable: Iterable<T>, f: (t: T) => U) {
  for (const el of iterable) {
    yield f(el);
  }
};

export const flatMap = function* <T, U>(iterable: Iterable<T>, f: (t: T) => Iterable<U>) {
  for (const el of iterable) {
    yield* f(el);
  }
};

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A function that takes the the array type V as an input, and returns a value of type K.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export const groupBy = <K, V>(list: V[], keyGetter: (input: V) => K): Map<K, V[]> => {
  const map = new Map<K, V[]>();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};
