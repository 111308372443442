import { ConsoleState } from '~/neo-ui/packages/table/packages/console/types';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { ReportQueryDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';
import { convertToKeyedNestedArrays } from '~/extensions/packages/iterable/keyedNestedArrayConverter';
import { OrderEnum } from '@AssetManagementClient/BeastClient/Primitives/Query/Sorting/OrderNested.gen';

type AutoEmailReportFormData = {
  title: string;
  consoleState: ConsoleState;
  columnKeys: {
    [key in string]: boolean;
  };
  autoEmailReportTemplateId: string | undefined;
  saveAsTemplate: boolean;
};

export const autoEmailReportTitleFieldKey: FieldKeyExpression<AutoEmailReportFormData> = values => values.title;

export const autoEmailReportConsoleStateFieldKey: FieldKeyExpression<AutoEmailReportFormData> = values => values.consoleState;

export const autoEmailReportConsoleStateSortFieldKey: FieldKeyExpression<AutoEmailReportFormData> = values => values.consoleState.sort;

export const autoEmailReportColumnKeysFieldKey: FieldKeyExpression<AutoEmailReportFormData> = values => values.columnKeys;

export const autoEmailReportTemplateFieldKey: FieldKeyExpression<AutoEmailReportFormData> = values => values.autoEmailReportTemplateId;

export const autoEmailReportSaveAsTemplateFieldKey: FieldKeyExpression<AutoEmailReportFormData> = values => values.saveAsTemplate;

/**
 * Columns that are not included in the auto email report by default
 */
export const autoEmailReportExcludedColumns = ['Processor', 'Memory', 'Storage'];

export const buildAutoEmailFormReportQuery = (consoleState: ConsoleState, columnKeys: string[]): ReportQueryDto => ({
  parameters: convertToKeyedNestedArrays(consoleState.parameters),
  search: undefined,
  sort:
    typeof consoleState.sort !== 'undefined'
      ? [
          {
            field: { value: consoleState.sort.key.value },
            ordering: OrderEnum.Ascending,
          },
        ]
      : [],
  selectedColumns: columnKeys.map(columnKey => ({ value: columnKey })),
});

export const generateColumnKeys = (
  defaultColumns: string[],
  areDefaultColumnsSelected: boolean,
  inverseColumns: string[],
): AutoEmailReportFormData['columnKeys'] => {
  const columnKeys: AutoEmailReportFormData['columnKeys'] = {};

  // Set default columns to their default value
  defaultColumns.forEach(defaultColumn => {
    columnKeys[defaultColumn] = areDefaultColumnsSelected;
  });

  // Set inverse columns to the opposite of the default value
  inverseColumns.forEach(inverseColumn => {
    columnKeys[inverseColumn] = !areDefaultColumnsSelected;
  });

  return columnKeys;
};

export default AutoEmailReportFormData;
