import { StrategyDashboardProps } from '~/wm/packages/strategy/packages/strategy-dashboard/StrategyDashboard';
import StrategyDashboardUrlProvider from '~/wm/packages/strategy/packages/strategy-dashboard/context/StrategyDashboardUrlProvider';
import { css } from '@emotion/react';
import { Fragment, useState } from 'react';
import DmiContainerExpandable from '~/wm/packages/strategy/packages/dmi/packages/dmi-container-expandable/DmiContainerExpandable';
import ActiveUsersProvider from '~/wm/packages/account/packages/user/context/ActiveUsersProvider';
import PeopleDashboardProvider from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/context/PeopleDashboardProvider';
import PeopleDashboard from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/PeopleDashboard';
import DmiContainer from '~/wm/packages/strategy/packages/dmi/packages/dmi-container/DmiContainer';
import DmiTrend from '~/wm/packages/strategy/packages/dmi/packages/dmi-trend/DmiTrend';
import AssetCountListContainer from '~/wm/packages/strategy/packages/asset/packages/asset-count-list-container/AssetCountListContainer';
import EngagementDashboard from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/EngagementDashboard';
import InitiativeDashboardProvider from '~/wm/packages/strategy/packages/strategy-dashboard/packages/initiative-dashboard/context/InitiativeDashboardProvider';
import StrategyInitiativeDashboardContainer from '~/wm/packages/strategy/packages/strategy-dashboard/packages/initiative-dashboard/StrategyInitiativeDashboardContainer';
import StrategyInsightDashboardContainer from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-dashboard/StrategyInsightDashboardContainer';
import { Enum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import LayoutFooter from '~/neo-ui/packages/layout/packages/footer/LayoutFooter';
import InsightCreateButton from '~/wm/packages/strategy/packages/insight/packages/insight-create-button/InsightCreateButton';
import OrganizationProvider from '~/wm/packages/organization/context/OrganizationProvider';
import { useStrategyDashboard } from '~/wm/packages/strategy/packages/strategy-dashboard/hooks/useStrategyDashboard';
import usePolling from '~/extensions/packages/polling/hooks/usePolling';
import useFeatureAvailabilitiesContext from '~/wm/packages/feature/context/hooks/useFeatureAvailabilitiesContext';
import FeatureAccessBannerClientFocus from '~/wm/packages/feature/packages/feature-access-banner/packages/feature-access-banner-client-focus/FeatureAccessBannerClientFocus';
import StrategySaasAssetDashboardContainer from '~/wm/packages/strategy/packages/strategy-dashboard/packages/saas-asset-dashboard/StrategySaasAssetDashboardContainer';

const StrategyDashboardContent = ({
  frontendScope,
  organizationId = '',
  organizationName = '',
  insightUrl,
  assetUrl,
  dmiGuideUrl,
  upgradeUrl,
  growUrl,
  hardwareConsoleUrl,
  peopleConsoleUrl,
  membersUrl,
  roadmapUrl,
  engagementActionListUrl,
  hasManageMembersPermission = false,
  hasAccessToNewConsole,
  canFullyAccessInsights,
  engagementNoteListUrl,
  enabledInitiativeTickets,
  enabledSaasM365,
}: StrategyDashboardProps) => {
  const { presentationalInsightDashboard, presentationalScore, assetTypeCounts, dateToDmiScore, reload } =
    useStrategyDashboard(frontendScope);
  usePolling(reload, presentationalInsightDashboard?.refreshIntervalMs ?? 60000);
  const [isInsightsLoaded, setIsInsightsContainerLoaded] = useState(false);

  const { hasFeatureAccessOrganization } = useFeatureAvailabilitiesContext();
  const hasFeatureAccessClientFocus =
    hasFeatureAccessOrganization('client-focus', organizationId) && frontendScope.type === Enum.Organization;

  const hasAccessToRoadmapV2 =
    hasFeatureAccessOrganization('scorecard-roadmap', organizationId) && frontendScope.type === Enum.Organization;

  return (
    <OrganizationProvider
      organizationId={organizationId}
      organizationName={organizationName}
    >
      <StrategyDashboardUrlProvider
        upgradeUrl={upgradeUrl}
        growUrl={growUrl}
        roadmapUrl={roadmapUrl}
        engagementActionListUrl={engagementActionListUrl}
        engagementNoteListUrl={engagementNoteListUrl}
      >
        <div>
          {typeof organizationId !== 'undefined' && organizationId !== '' && (
            <FeatureAccessBannerClientFocus
              upgradeUrl={upgradeUrl}
              growUrl={growUrl}
            />
          )}
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              gap: ${hasFeatureAccessClientFocus ? '1rem;' : '0.625rem;'};
            `}
          >
            <div
              css={css`
                margin-right: 1rem;
                margin-bottom: 1.875rem;
                min-width: 13.75rem;
                flex-grow: 1;
                flex-basis: 5%;
              `}
            >
              {hasFeatureAccessClientFocus ? (
                <Fragment>
                  <DmiContainerExpandable
                    score={presentationalScore}
                    guideUrl={dmiGuideUrl}
                    dateToDmiScore={dateToDmiScore}
                    isInsightsLoaded={isInsightsLoaded}
                    css={css`
                      margin-bottom: 1rem;
                    `}
                  />
                  <ActiveUsersProvider>
                    <PeopleDashboardProvider organizationId={organizationId}>
                      <PeopleDashboard
                        peopleConsoleUrl={peopleConsoleUrl}
                        membersUrl={membersUrl}
                        hasManageMembersPermission={hasManageMembersPermission}
                        css={css`
                          margin-bottom: 1rem;
                        `}
                      />
                    </PeopleDashboardProvider>
                  </ActiveUsersProvider>
                </Fragment>
              ) : (
                <Fragment>
                  <DmiContainer
                    score={presentationalScore}
                    guideUrl={dmiGuideUrl}
                  />
                  {typeof dateToDmiScore !== 'undefined' && isInsightsLoaded && (
                    <div
                      css={css`
                        display: flex;
                        height: 270px;
                        margin-top: 30px;
                      `}
                    >
                      <DmiTrend dateToDmiScore={dateToDmiScore} />
                    </div>
                  )}
                </Fragment>
              )}
              <div
                css={css`
                  margin-top: 2rem;
                `}
              >
                <AssetCountListContainer
                  assetUrl={assetUrl}
                  assetCounts={assetTypeCounts?.hardwareTypeStats}
                  total={assetTypeCounts?.total}
                  hasAccessToNewConsole={hasAccessToNewConsole}
                />
              </div>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                flex-grow: 3;
                flex-basis: 50%;
                align-content: flex-start;
                padding-bottom: 40px;
                gap: ${hasFeatureAccessClientFocus ? '1rem;' : '0.625rem;'};
              `}
            >
              {hasFeatureAccessClientFocus && (
                <ActiveUsersProvider>
                  <EngagementDashboard />
                </ActiveUsersProvider>
              )}
              {hasAccessToRoadmapV2 && (
                <InitiativeDashboardProvider organizationId={organizationId}>
                  <StrategyInitiativeDashboardContainer
                    css={css`
                      margin-top: 1rem;
                    `}
                    enabledInitiativeTickets={enabledInitiativeTickets}
                  />
                </InitiativeDashboardProvider>
              )}
              {enabledSaasM365 && frontendScope.type === Enum.Organization && <StrategySaasAssetDashboardContainer />}
              <StrategyInsightDashboardContainer
                insightUrl={insightUrl}
                presentationInsightDashboard={presentationalInsightDashboard}
                scope={frontendScope}
                renderCompleteHandler={() => setIsInsightsContainerLoaded(true)}
                canFullyAccessInsights={canFullyAccessInsights}
                hardwareConsoleUrl={hardwareConsoleUrl}
                peopleConsoleUrl={peopleConsoleUrl}
                hasAccessToNewConsole={hasAccessToNewConsole}
                enabledLmClientFocus={hasFeatureAccessClientFocus}
              />
            </div>
          </div>
          <div>
            {canFullyAccessInsights && !hasFeatureAccessClientFocus && (
              <LayoutFooter
                leftControls={[
                  {
                    expanded: (
                      <InsightCreateButton
                        disabled={false}
                        refreshAction={reload}
                      />
                    ),
                  },
                ]}
              />
            )}
          </div>
        </div>
      </StrategyDashboardUrlProvider>
    </OrganizationProvider>
  );
};

export default StrategyDashboardContent;
