import ProductSelectionCard from '~/wm/packages/subscription/packages/product-selection/packages/product-selection-card/ProductSelectionCard';
import { ExploreProduct } from '@SubscriptionClient/BeastClient/Beast/Msp/Billing/ExploreProducts/Dto.gen';
import { mobileBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';

export type ProductSelectionGridProps = {
  exploreProducts: ExploreProduct[];
};

const ProductSelectionGrid = ({ exploreProducts }: ProductSelectionGridProps) => (
  <div
    css={{
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      gridGap: '2rem',
      margin: '1rem 1rem',
      [`@media (max-width: ${mobileBreakpointRem}rem)`]: {
        display: 'flex',
        flexDirection: 'column',
      },
    }}
  >
    {exploreProducts.map((product, index) => (
      <ProductSelectionCard
        exploreProduct={product}
        key={index}
      />
    ))}
  </div>
);

export default ProductSelectionGrid;
