/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ComponentUniqueIds } from '~/wm/packages/dashboard/packages/widgetComponents.gen';

const widgetsComponentsMap: Map<ComponentUniqueIds, React.FunctionComponent<any>> = new Map();

export const getComponentByUniqueId = (componentUniqueId: ComponentUniqueIds): React.FunctionComponent<any> | undefined =>
  widgetsComponentsMap.get(componentUniqueId);

export const registerComponents = (componentsMap: Map<ComponentUniqueIds, React.FunctionComponent<any>>) => {
  componentsMap.forEach((value, key) => {
    widgetsComponentsMap.set(key, value);
  });
};
