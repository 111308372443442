// noinspection DuplicatedCode
import { css } from '@emotion/react';
import { parseISO } from 'date-fns';
import Card from '~/legacy-ui/packages/card/Card';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Spinner from '~/neo-ui/spinner/Spinner';
import useBillingInfo from '~/wm/packages/subscription/packages/billing/hooks/useBillingInfo';
import BillingSummary from '~/wm/packages/subscription/packages/billing/packages/billing-summary/BillingSummary';
import useSubscriptionGrowAvailabilities from '~/wm/packages/subscription/packages/grow/packages/grow-availabilities/hooks/useSubscriptionGrowAvailabilities';
import ProductPlanSummary from '~/wm/packages/subscription/packages/plan/packages/product-plan-summary/ProductPlanSummary';
import usePricingDtoGet from '~/wm/packages/subscription/packages/pricing-dto/hooks/usePricingDtoGet';
import useSubscriptionDetailsGet from '~/wm/packages/subscription/packages/subscription-details/hooks/useSubscriptionDetailsGet';
import SubscriptionPdContactBar from '~/wm/packages/subscription/packages/subscription-pd-contact/packages/subscription-pd-contact-bar/SubscriptionPdContactBar';
import TermSummary from '~/wm/packages/subscription/packages/term/packages/term-summary/TermSummary';
import SubscriptionGrowCard from '../grow/packages/grow-card/SubscriptionGrowCard';
import useSubscriptionGroupDetails from '../subscription-details/hooks/useSubscriptionGroupDetails';
import useTermDtoGet from '~/wm/packages/subscription/packages/term/packages/term-dto/useTermDtoGet';

export type ProductSubscriptionPageProps = {
  isChargebeeEnabled: boolean;
  editBillingUrl: string;
  subscriptionAgreementUrl: string;
  subscriptionGrowUrl: string;
  subscriptionOfferUrl: string;
};

const ProductSubscriptionPage = ({
  isChargebeeEnabled,
  editBillingUrl,
  subscriptionAgreementUrl,
  subscriptionGrowUrl,
  subscriptionOfferUrl,
}: ProductSubscriptionPageProps) => {
  const { billingInfo } = useBillingInfo();
  const { subscriptionDetails } = useSubscriptionDetailsGet();
  const { subscriptionGroupDetails } = useSubscriptionGroupDetails();
  const { growAvailabilities } = useSubscriptionGrowAvailabilities(undefined);
  const { pricingDto } = usePricingDtoGet();

  const { termDto } = useTermDtoGet();

  if (!billingInfo || !subscriptionGroupDetails) {
    return <Spinner />;
  }

  if (!billingInfo.billingAddressDto || (!billingInfo.paymentMethodEnum && !billingInfo.paymentInfo)) {
    return null;
  }

  const subscriptionGroupPricing = subscriptionGroupDetails.subscriptionDetails;
  const subscriptionPricing = subscriptionDetails?.subscriptionPricing;

  const hasDiscount = !!pricingDto && !!pricingDto.discountsDto ? pricingDto.discountsDto.length > 0 : false;

  const term = termDto
    ? {
        startsAt: termDto.validFrom ? parseISO(termDto.validFrom) : undefined,
        endsAt: parseISO(termDto.validUntil),
        durationMonths: termDto.lengthMonths,
        renewalBehavior: termDto.termEndBehavior,
      }
    : subscriptionPricing?.term
    ? {
        startsAt: subscriptionPricing.term.startsAt ? parseISO(subscriptionPricing.term.startsAt) : undefined,
        endsAt: parseISO(subscriptionPricing.term.endsAt),
        durationMonths: subscriptionPricing.term.durationMonths,
        renewalBehavior: subscriptionPricing.term.renewalBehaviorEnum,
      }
    : undefined;

  return (
    <div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Header size={2}>Subscription</Header>
        {growAvailabilities && <SubscriptionPdContactBar />}
      </div>
      {subscriptionGroupPricing && (
        <Label
          muted={true}
          css={css`
            margin-top: 0.625rem;
          `}
        >
          All amounts in USD
        </Label>
      )}
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          margin-top: 1.25rem;

          /* https://coryrylan.com/blog/css-gap-space-with-flexbox */
          margin-right: -1.25rem;
          margin-bottom: -1.25rem;

          > * {
            flex: 1;
            margin-right: 1.25rem;
            margin-bottom: 1.25rem;
          }
        `}
      >
        {subscriptionGroupDetails?.subscriptionDetails && <ProductPlanSummary.V2 data={subscriptionGroupDetails} />}
        {subscriptionGroupPricing && (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              ${!hasDiscount ? '> * {height: 100%;}' : ''};
            `}
          >
            {term && subscriptionGroupPricing && (
              <Card
                padding={'xl'}
                shadow={'md'}
              >
                <TermSummary
                  subscriptionAgreementLink={{
                    href: subscriptionAgreementUrl,
                    openInNewTab: true,
                  }}
                  billingPeriod={subscriptionGroupPricing.billingPeriod}
                  term={term}
                />
              </Card>
            )}
            {hasDiscount && (
              <div
                css={css`
                  margin-top: 1.25rem;
                  flex: 1;

                  > * {
                    height: 100%;
                  }

                  > div > * {
                    height: 100%;
                  }
                `}
              >
                {!isChargebeeEnabled && (
                  <SubscriptionGrowCard
                    subscriptionGrowUrl={subscriptionGrowUrl}
                    subscriptionOfferUrl={subscriptionOfferUrl}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {subscriptionGroupPricing && !hasDiscount && (
        <div
          css={css`
            margin-top: 1.25rem;
          `}
        >
          {!isChargebeeEnabled && (
            <SubscriptionGrowCard
              subscriptionGrowUrl={subscriptionGrowUrl}
              subscriptionOfferUrl={subscriptionOfferUrl}
            />
          )}
        </div>
      )}
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          margin-top: 1.25rem;

          /* https://coryrylan.com/blog/css-gap-space-with-flexbox */
          margin-right: -1.25rem;
          margin-bottom: -1.25rem;

          > * {
            flex: 1;
            margin-right: 1.25rem;
            margin-bottom: 1.25rem;
          }
        `}
      >
        {billingInfo.billingAddressDto && (
          <Card
            padding={'xl'}
            shadow={'md'}
          >
            <BillingSummary
              billingAddress={billingInfo.billingAddressDto}
              billingPaymentMethod={billingInfo.paymentMethodEnum}
              editBillingLink={{ href: editBillingUrl, openInNewTab: false }}
            />
          </Card>
        )}
      </div>
    </div>
  );
};

export default ProductSubscriptionPage;
