import { css } from '@emotion/react';
import * as React from 'react';

export type InputRadioProps<T> = {
  itemIdentifier: string;
  item: T;
  isSelected: boolean;
  onSelected: (selectedItem: T) => void;
  disabled?: boolean;
};

const InputRadio = <T,>({ itemIdentifier, item, isSelected, onSelected, disabled = false }: InputRadioProps<T>) => (
  <input
    id={itemIdentifier}
    type={'radio'}
    disabled={disabled}
    checked={isSelected}
    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        onSelected(item);
      }
    }}
    // fixing the width keeps the radio buttons the same size across browsers
    css={css`
      width: 1rem;
      cursor: pointer;
    `}
  />
);

export default InputRadio;
