import { SubscriptionSummaryItem } from '@SubscriptionClient/BeastClient/Beast/Msp/Billing/PaymentSummary/Dto.gen';
import ConsumptionMeter from '~/wm/packages/subscription/packages/plan/packages/consumption-meter/ConsumptionMeter';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';

export type SubscriptionSummaryCardItemSectionProps = {
  summaryItems: SubscriptionSummaryItem[];
  billingFrequency: string;
  currencyCode: string;
};

export const getItemBillingFrequencyLabel = (billingFrequency: string) => {
  switch (billingFrequency) {
    case 'Month':
      return '/mo';
    case 'Year':
      return '/yr';
    default:
      throw new Error('Unsupported billing frequency');
  }
};

const SubscriptionSummaryCardItemSection = ({ summaryItems, billingFrequency, currencyCode }: SubscriptionSummaryCardItemSectionProps) => {
  return (
    <div>
      {summaryItems.map((item, index) => (
        <div
          key={index}
          css={{}}
        >
          <div css={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
            <span css={{ fontWeight: 'bold' }}>{item.name}</span>
            {item.salePrice !== 0 && (
              <span>
                {formatCurrency(item.salePrice, currencyCode, 2, true)}
                {item.isRecurringPayment ? getItemBillingFrequencyLabel(billingFrequency) : ''}
              </span>
            )}
          </div>
          {item.summaryItemDetails && (
            <ul>
              {item.summaryItemDetails.map((detail, index) => (
                <li
                  key={index}
                  css={{ marginBottom: '0.5rem' }}
                >
                  {detail.description}
                  {detail.itemConsumption && (
                    <ConsumptionMeter
                      currentConsumption={detail.itemConsumption.productUnitUsage}
                      maximumLimit={detail.itemConsumption.productUnitLimit}
                    />
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default SubscriptionSummaryCardItemSection;
