import { css } from '@emotion/react';
import { useState } from 'react';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import CheckoutAccordion from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/CheckoutAccordion';
import CheckoutState from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/repeat-checkout-state/CheckoutState';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import Text from '~/sp-ui/text';
import Message from '~/sp-ui/message';

const PlanCheckout = () => {
  const { formik } = useMultiStepForm<PurchaseSubscriptionForm>();
  const { isGrow } = formik.values;
  const submitButtonLabels: string[] = ['Save and continue', 'Save and continue', isGrow ? 'Update subscription' : 'Pay now'];
  const getActiveIndex = () => (!!formik.values.billingAddress?.address.zip ? (!!formik.values.paymentCard ? 2 : 1) : 0);
  const [actionLabel, setActionLabel] = useState<string>(submitButtonLabels[getActiveIndex()]);
  const [active, setActive] = useState(getActiveIndex());
  const [edited, setEdited] = useState<
    {
      isEdited: boolean;
    }[]
  >([{ isEdited: !!formik.values.billingAddress?.address.zip }, { isEdited: !!formik.values.paymentCard }, { isEdited: false }]);
  const paymentSubmitAction = (setupIntent: string) => {
    if (!setupIntent) {
      return;
    }
    // Set accordion item Edited
    const newEdited = [...edited];
    newEdited[active].isEdited = true;
    setEdited(newEdited);
    // Set next accordion item active
    setActive(2);
    setActionLabel(submitButtonLabels[2]);
  };

  const billingSubmitAction = () => {
    // Set accordion item Edited
    const newEdited = [...edited];
    newEdited[active].isEdited = true;
    setEdited(newEdited);
    // Set next accordion item active
    const nextActive = !!formik.values.paymentCard ? 2 : 1;
    setActive(nextActive);
    setActionLabel(submitButtonLabels[nextActive]);
  };

  const changeEditedState = (index: number) => {
    const newEdited = [...edited];
    newEdited[index].isEdited = false;
    if (active === 1) {
      newEdited[active].isEdited = !!formik.values.paymentCard;
    }
    if (active === 0) {
      newEdited[active].isEdited = !!formik.values.billingAddress?.address.zip;
    }
    setEdited(newEdited);
    setActive(index);
    setActionLabel(submitButtonLabels[index]);
  };

  const handleCheckoutStateClick = () => {
    switch (active) {
      case 0:
        formik.values.submitBillingAddress.current?.click();
        break;
      case 1:
        formik.values.submitCard.current?.click();
        break;
      case 2:
        formik.values.submitPayment.current?.click();
        break;
    }
  };

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      `}
    >
      <div
        css={css`
          width: 100%;
          max-width: 75rem;
        `}
      >
        <span
          css={css`
            font-size: 2.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 133%;
          `}
        >
          {isGrow ? 'Manage subscription' : 'Checkout'}
        </span>
        <div
          css={css`
            display: grid;
            grid-template-columns: 60% 40%;
            gap: 2rem;
            margin-top: 2rem;
          `}
        >
          <CheckoutAccordion
            active={active}
            paymentSubmitAction={paymentSubmitAction}
            billingSubmitAction={billingSubmitAction}
            changeEditedState={changeEditedState}
            actionLabel={actionLabel}
            edited={edited}
          />
          {formik.values.purchasePrice && (
            <div
              css={css`
                position: sticky;
                align-self: start;
                top: 4.5rem;
              `}
            >
              <CheckoutState
                active={active}
                action={handleCheckoutStateClick}
                actionLabel={actionLabel}
                totalBeforeTax={formatCurrency(formik.values.purchasePrice?.totalBeforeTax, 'USD', 2)}
                totalAfterTax={formatCurrency(formik.values.purchasePrice?.totalAfterTax, 'USD', 2)}
                tax={formatCurrency(formik.values.purchasePrice?.tax, 'USD', 2)}
                nextEstimatedAmountWithoutTax={formatCurrency(formik.values.purchasePrice?.nextEstimatedAmountWithoutTax, 'USD', 2)}
                nextPaymentDayLabel={formik.values.nextPaymentDayLabel}
              />
              <Message
                css={css`
                  width: 27.5rem;
                  margin-top: 1rem;
                `}
                type="info"
              >
                <div css={{ display: 'flex', alignItems: 'start' }}>
                  <Text variant="small">
                    Questions or concerns? Let us reach out to you at a time that works best.{' '}
                    <a
                      css={css`
                        text-decoration: underline;
                      `}
                      href="https://www.scalepad.com/contact"
                      target="_blank"
                    >
                      Schedule a call with a representative
                    </a>
                  </Text>
                </div>
              </Message>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanCheckout;
