import { date, object, string } from 'yup';

/**
 * Duped in backend: 64f1d911-a335-4e12-8284-d0bfc451b3be
 */
const contactStringLengthMax = 50;

/**
 * Duped in backend: db6eb077-1ff7-4842-8cad-7a7c932d31bb
 */
const pickupNotesLengthMax = 75;

/**
 * Duped in backend: 4c70578e-c820-446a-a4a6-84777bf65790
 */
const contactPhoneMaxLength = 10;

const disposalOrderPickupFormValidationSchema =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object().shape<any>({
    contactName: string()
      .required('Required.')
      .matches(/^[a-zA-Z\-.]+\s([\s\-.a-zA-Z]+)+$/, 'Please include a first and last name.')
      .max(contactStringLengthMax, 'Contact name may not be longer than 50 characters.'),
    contactEmail: string().required('Required.').max(contactStringLengthMax, 'Contact email may not be longer than 50 characters.'),
    contactPhoneNumber: string()
      .required('Required.')
      .matches(
        /^\+?\d{1,3}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}$/,
        'Please enter a valid 10 digit phone number without extensions (e.g. 888 456 7890)',
      )
      .test(
        'is-phone-length-correct',
        'Phone number must be no more than 10 digits.',
        phone => (phone?.replace(/\D/g, '') || []).length <= contactPhoneMaxLength,
      ),

    pickupNotes: string().max(pickupNotesLengthMax, 'Pickup notes may not be longer than 75 characters.'),
    requestedDate: date()
      .required('Required.')
      .test('is_weekday', 'Date must not be Saturday or Sunday.', date => date?.getDay() !== 0 && date?.getDay() !== 6)
      .min(new Date(), 'Date must be in the future.'),
  });

export default disposalOrderPickupFormValidationSchema;
