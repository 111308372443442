import { Fragment, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { Column, PaginationState, SortState } from '~/neo-ui/packages/table/packages/console/types';
import { ConsoleRow } from '@AssetManagementClient/BeastClient/Search/Model/Console.gen';
import DataTable, { DataTableColumn, PaginationMetadata } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { getMinSizeInRem } from '~/neo-ui/packages/table/packages/console/ConsoleUtilities';
import { pascalToCamel } from '~/extensions/packages/casing/camelPascalConversion';
import { RenderCellData } from '@AssetManagementClient/BeastClient/Renderer/Model/Data.gen';
import RenderCell from '~/neo-ui/packages/table/packages/render-cell-data/RenderCell';
import { css } from '@emotion/css';
import { Response } from '@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Console/HardwareConsoleIdLookupControllerNested.gen';
import { v4 as uuidv4 } from 'uuid';
import { resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import useHardwareConsoleIdLookUp from '~/wm/packages/hardware/packages/hardware-table-section/context/hooks/useHardwareConsoleIdLookUp';
import * as React from 'react';

export type HardwareTableProps = {
  scope: FrontendScope;
  assetRows: ConsoleRow[];
  columns: DataTableColumn<ConsoleRow>[];
  sortableColumnKeys: string[];
  displayedAssetIds: string[];
  minWidthRem?: number;
  onAssetRowsUpdated: (assetRows: ConsoleRow[]) => void;
};

export const getHardwareTableDisplayColumns = (displayColumns: Column[]): DataTableColumn<ConsoleRow>[] =>
  displayColumns
    .sort((a, b) => a.order - b.order)
    .map(
      (column): DataTableColumn<ConsoleRow> => ({
        fieldKey: column.key,
        Header: column.label,
        width: `${getMinSizeInRem(column.size)}rem`,
        renderCell: row => {
          const data = row.data.data[pascalToCamel(column.key)] as RenderCellData | undefined;
          if (typeof data === 'undefined') {
            return null;
          }
          return RenderCell({ data });
        },
        ellipsizeTextContent: true,
      }),
    );

const HardwareTable: FunctionComponent<HardwareTableProps> = ({
  scope,
  assetRows,
  columns,
  displayedAssetIds,
  sortableColumnKeys,
  minWidthRem,
  onAssetRowsUpdated: onAssetRowUpdated,
}) => {
  const { getHardwareConsole } = useHardwareConsoleIdLookUp();
  const [paginationState, setPaginationState] = useState<PaginationMetadata>({
    totalPages: 0,
    totalResults: 0,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [currentTableSort, setTableSort] = useState<SortState | undefined>(undefined);

  const currentFetchToken = useRef<string>();
  const populateRows = useCallback(
    async (scope: FrontendScope, pagination: PaginationState, sort: SortState | undefined, assetIds: string[], fetchToken: string) => {
      const rowResponse: Response | undefined = await getHardwareConsole(scope, pagination, sort, assetIds);
      if (typeof rowResponse !== 'undefined' && currentFetchToken.current === fetchToken) {
        onAssetRowUpdated(rowResponse.result.results);
        setPaginationState({ totalPages: rowResponse.result.metadata.totalPages, totalResults: rowResponse.result.metadata.totalResult });
      }
    },
    [getHardwareConsole, onAssetRowUpdated],
  );

  useEffect(() => {
    const fetchToken = uuidv4();
    currentFetchToken.current = fetchToken;

    if (typeof displayedAssetIds === 'undefined' || displayedAssetIds.length === 0) {
      onAssetRowUpdated([]);
      setPaginationState({ totalPages: 0, totalResults: 0 });
      return;
    }

    populateRows(scope, { pageNumber: currentPage, perPageSize: 50 }, currentTableSort, displayedAssetIds, fetchToken);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, displayedAssetIds, currentTableSort]);

  return (
    <Fragment>
      {assetRows.length > 0 && (
        <DataTable
          data={assetRows}
          columns={columns}
          EmptyStatePlaceholder={'There is no asset selected for the Table'}
          sortableColumnKeys={sortableColumnKeys}
          onPageChange={pagination => {
            setCurrentPage(pagination.pageNumber);
          }}
          onSortChange={sort => {
            if (typeof sort !== 'undefined') {
              setTableSort({ key: { value: resolveFieldKey(sort.key) }, order: sort.order });
            }
          }}
          defaultPagination={{ pageNumber: 0, perPageSize: 50 }}
          paginationMetadata={paginationState}
          tableClass={css`
            ${typeof minWidthRem !== 'undefined' ? `min-width: ${minWidthRem}rem;` : ''}
            table-layout: fixed;
          `}
        />
      )}
    </Fragment>
  );
};

export default HardwareTable;
