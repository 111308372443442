import * as React from 'react';
import useBillingInfo from '~/wm/packages/subscription/packages/billing/hooks/useBillingInfo';
import { PaymentVersion } from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';
import DisposalUrlProvider from '~/wm/packages/disposal/context/DisposalUrlProvider';
import DisposalTreeImpactAccountSummaryProvider from '~/wm/packages/disposal/packages/tree-impact/context/DisposalTreeImpactAccountSummaryProvider';
import useDisposalCheckoutDataGet from '~/wm/packages/disposal/hooks/useDisposalCheckoutDataGet';
import EmailProvider from '~/wm/packages/product/context/EmailProvider';
import DisposalSection from '~/wm/packages/disposal/packages/disposal-section/DisposalSection';
import DisposalFeatureFlagProvider from '~/wm/packages/disposal/context/DisposalFeatureFlagProvider';

export type DisposalPageProps = {
  paymentVersion: PaymentVersion;
  brandName: string;

  // URLs
  disposalOrderInfoUrl: string;
  disposalTermsOfServiceUrl: string;
  termsOfServiceUrl: string;
  disposalOrderListUrl: string;
  invoiceUrl: string;
  organizationDashboardUrl: string;
  hardwareViewUrl: string;
  treeImpactHelpGuideUrl: string;
  growUrl: string;
  featureRequestUrl: string;

  // Banner
  showPermissionsBanner: boolean;
  showLocationRestrictionBanner: boolean;

  // Support Email
  supportEmail: string;

  // Bulk
  hasDisposalBulkLocationAccess: boolean;

  // Increase min assets for full service
  enableImproveProfit: boolean;
};

const DisposalPage = ({
  paymentVersion,
  brandName,
  disposalOrderInfoUrl,
  disposalTermsOfServiceUrl,
  disposalOrderListUrl,
  invoiceUrl,
  termsOfServiceUrl,
  organizationDashboardUrl,
  hardwareViewUrl,
  treeImpactHelpGuideUrl,
  showPermissionsBanner,
  showLocationRestrictionBanner,
  growUrl,
  featureRequestUrl,
  supportEmail,
  hasDisposalBulkLocationAccess,
  enableImproveProfit,
}: DisposalPageProps) => {
  const { billingInfo } = useBillingInfo();
  const { disposalCheckoutData } = useDisposalCheckoutDataGet();

  if (!billingInfo || !disposalCheckoutData) {
    return null;
  }

  return (
    <EmailProvider supportEmail={supportEmail}>
      <DisposalUrlProvider
        disposalOrderInfoUrl={disposalOrderInfoUrl}
        organizationDashboardUrl={organizationDashboardUrl}
        hardwareViewUrl={hardwareViewUrl}
        growUrl={growUrl}
        featureRequestUrl={featureRequestUrl}
        disposalOrderListUrl={disposalOrderListUrl}
        invoiceUrl={invoiceUrl}
        disposalTermsOfServiceUrl={disposalTermsOfServiceUrl}
        treeImpactHelpGuideUrl={treeImpactHelpGuideUrl}
        termsOfServiceUrl={termsOfServiceUrl}
      >
        <DisposalTreeImpactAccountSummaryProvider>
          <DisposalFeatureFlagProvider enableImproveProfit={enableImproveProfit}>
            <DisposalSection
              disposalCheckoutData={disposalCheckoutData}
              billingInfo={billingInfo}
              paymentVersion={paymentVersion}
              brandName={brandName}
              hasDisposalBulkLocationAccess={hasDisposalBulkLocationAccess}
              showLocationRestrictionBanner={showLocationRestrictionBanner}
              showPermissionsBanner={showPermissionsBanner}
              showLegacyBanner={!disposalCheckoutData.canCheckoutForFree}
              disposalSettings={disposalCheckoutData.disposalSettings}
            />
          </DisposalFeatureFlagProvider>
        </DisposalTreeImpactAccountSummaryProvider>
      </DisposalUrlProvider>
    </EmailProvider>
  );
};

export default DisposalPage;
