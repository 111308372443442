import { css } from '@emotion/react';
import * as React from 'react';
import Image from '~/neo-ui/packages/image/Image';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Color from '~/neo-ui/packages/color/Color.gen';

export type EmptyStateInfoProps = {
  imageSource: string;
  imageWidth: string;
  title: string;
  description?: string;
  titleColor?: Color;
  CallToAction?: React.ReactNode;
};

/**
 * Provides clear guidance when there is no information to display.
 */
const EmptyStateInfo: React.FunctionComponent<EmptyStateInfoProps> = ({
  imageSource,
  imageWidth,
  title,
  description,
  CallToAction,
  titleColor,
}) => (
  <div
    css={css`
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
  >
    <Image
      css={css`
        width: ${imageWidth};
      `}
      source={imageSource}
    />
    <Header
      css={css`
        margin-top: 1.625rem;
        text-align: center;
        margin-bottom: 1.25rem;
      `}
      size={3}
      color={titleColor ?? 'secondary-400'}
    >
      {title}
    </Header>
    {description && (
      <p
        css={css`
          text-align: center;
          margin-bottom: 1.875rem;
        `}
      >
        {description}
      </p>
    )}
    {CallToAction && (
      <div
        css={css`
          margin-bottom: 1.25rem;
        `}
      >
        {CallToAction}
      </div>
    )}
  </div>
);

export default EmptyStateInfo;
