import * as React from 'react';
import { ReactNode } from 'react';
import Form from '~/neo-ui/packages/form/Form';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import autoEmailReportRecipientAddFormValidationSchema from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-recipients/packages/auto-email-report-recipient-add-form/validation/autoEmailReportRecipientAddFormValidationSchema';
import useAutoEmailReportRecipientAdd from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportRecipientAdd';

export type AutoEmailReportRecipientAddFormData = {
  name: string;
  email: string;
};

export type AutoEmailReportRecipientAddFormProps = {
  autoEmailReportId: string;
  children: ReactNode;
};

const AutoEmailReportRecipientAddForm = ({ autoEmailReportId, children }: AutoEmailReportRecipientAddFormProps) => {
  const { organizationId } = useOrganizationContext();

  const { addAutoEmailReportRecipient } = useAutoEmailReportRecipientAdd();

  const onSubmit = React.useCallback(
    async (data: AutoEmailReportRecipientAddFormData, isRequestActive: () => boolean) => {
      addAutoEmailReportRecipient(
        {
          organizationId,
          autoEmailReportId,
          autoEmailReportRecipientAddPayload: {
            fullName: data.name,
            emailAddress: data.email,
          },
        },
        isRequestActive,
      );
    },
    [addAutoEmailReportRecipient, autoEmailReportId, organizationId],
  );

  const defaultFormData = React.useMemo<AutoEmailReportRecipientAddFormData>(
    () => ({
      name: '',
      email: '',
    }),
    [],
  );

  return (
    <Form
      submitMethod={'manual'}
      hideSubmissionButton={true}
      hideSubmitStatusIndicator={true}
      defaultFormData={defaultFormData}
      validationSchema={autoEmailReportRecipientAddFormValidationSchema}
      onSubmit={onSubmit}
    >
      {children}
    </Form>
  );
};

export default AutoEmailReportRecipientAddForm;
