import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { ReactNode } from 'react';
import PanelTabGroup, { PanelTabGroupProps } from '~/neo-ui/packages/panel/packages/PanelTabGroup/PanelTabGroup';
import PresentationHeader, {
  presentationHeaderMaxHeightRem,
} from '~/neo-ui/packages/layout/packages/layout-presentation/packages/presentation-header/PresentationHeader';
import PresentationBody, {
  presentationBodyMarginRem,
} from '~/neo-ui/packages/layout/packages/layout-presentation/packages/presentation-body/PresentationBody';

export type PresentationPageProps<T> = {
  title?: ReactNode;
  bodyPanelTabProps: PanelTabGroupProps<T>;
};

const PresentationPage = <T,>({ title, bodyPanelTabProps }: PresentationPageProps<T>) => {
  return (
    <div
      css={css`
        height: 100vh;
        overflow-y: hidden;
        background-color: ${colorToCode('light-000')};
      `}
    >
      <PresentationHeader>{title}</PresentationHeader>
      <div
        css={css`
          display: flex;
          height: 100%;
          overflow: hidden;
        `}
      >
        <PresentationBody>
          <PanelTabGroup
            {...bodyPanelTabProps}
            panelContentCss={css`
              overflow: auto;
              height: 100%;
              max-height: calc(100vh - ${presentationHeaderMaxHeightRem}rem - ${presentationBodyMarginRem.desktop}rem - 3.4375rem);
            `}
            css={css`
              height: 100%;
            `}
          />
        </PresentationBody>
      </div>
    </div>
  );
};

export default PresentationPage;
