import * as React from 'react';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export interface ListGroupItemProps {
  className?: string;
  liClassName?: string;
}

/**
 * @deprecated from usage of php classes, replace with using flex box
 */
const ListGroupItem: React.FunctionComponent<React.PropsWithChildren<ListGroupItemProps>> = ({ className = '', liClassName, children }) => (
  <div
    className={`${liClassName} d-flex align-items-center`}
    css={css`
      background-color: ${colorToCode('blank')};
      border: none;
      padding: 0;
    `}
  >
    <div className={`mr-2 flex-grow-1 ${className}`}>{children}</div>
  </div>
);

export default ListGroupItem;
