import { CurrencyInformationDto as CurrencyInformationDto } from '@WarrantyClient/BeastClient/Beast/Settings/Dto/Packages/Warranty.gen';
import { useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { listSettings } from '@WarrantyClient/WarrantyClientMsp.gen';

export type UseWarrantiesListSettings = () => {
  currencyInformation: CurrencyInformationDto;
  currencyInformationLoading: boolean;
};

const initialCurrencyInformation: CurrencyInformationDto = {
  displayCurrency: 'USD',
  exchangeRate: 1,
  subunitRatio: 100,
};

const useWarrantiesListSettings: UseWarrantiesListSettings = () => {
  const [currencyInformation, setCurrencyInformation] = useState<CurrencyInformationDto>(initialCurrencyInformation);
  const [currencyInformationLoading, setCurrencyInformationLoading] = useState<boolean>(false);
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      setCurrencyInformationLoading(true);
      const response = await callApi(() => listSettings({}));
      if (!response) {
        return;
      }
      setCurrencyInformation(response.currencyInformation);
      setCurrencyInformationLoading(false);
    })();
  }, [callApi]);

  return {
    currencyInformation,
    currencyInformationLoading,
  };
};

export default useWarrantiesListSettings;
