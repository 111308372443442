import * as React from 'react';
import FormInputInternal from '~/neo-ui/packages/form/packages/form-input/FormInputInternal';
import { FieldKeyExpression, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { FormCheckboxDisplay } from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckBoxDisplay';
import IconType from '~/neo-ui/packages/icon/IconType.gen';

export type FormCheckboxInputProps<T> = {
  fieldKey: FieldKeyExpression<T>;
  disabled?: boolean;
  description?: string | React.JSX.Element;
  iconLeft?: IconType;

  /**
   * Force checked state (warning: purely display, not bound to data)
   */
  checked?: boolean;
  /**
   * Override onChange handler for form input
   *
   * You must implement form input state management yourself
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Executes when form input is updated
   */
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
};

/**
 * Checkbox input that belongs as a child inside of a Form
 */
const FormCheckboxInput = <T,>({
  fieldKey,
  disabled = false,
  description,
  iconLeft,
  checked,
  onChange,
  onInput,
}: FormCheckboxInputProps<T>) => {
  const inputId = resolveFieldKey(fieldKey);

  return (
    <FormInputInternal
      fieldKey={fieldKey}
      inputProps={{
        type: 'checkbox',
        className: 'form-check-input',
      }}
    >
      {({ inputProps }) => (
        <FormCheckboxDisplay
          id={inputId}
          disabled={disabled}
          onInput={onInput}
          propsForHtmlInputComponent={inputProps}
          onChange={onChange}
          checked={checked}
          description={description}
          iconLeft={iconLeft}
        />
      )}
    </FormInputInternal>
  );
};

export default FormCheckboxInput;
