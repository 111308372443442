import * as React from 'react';
import EmailContext from '~/wm/packages/product/context/EmailContext';

export type EmailProviderProps = {
  supportEmail: string;
};

const EmailProvider: React.FunctionComponent<React.PropsWithChildren<EmailProviderProps>> = ({ supportEmail, children }) => (
  <EmailContext.Provider
    value={{
      supportEmail,
    }}
  >
    {children}
  </EmailContext.Provider>
);

export default EmailProvider;
