import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { ErrorIcon, SuccessIcon } from '~/sp-ui/icons';

const IntegrationDataTypesDisplay = ({
  dataTypes,
}: {
  dataTypes: {
    [key: string]: {
      isSupported: boolean;
      fields: string[];
    };
  };
}) => {
  return (
    <table
      css={css`
        border: 0.0625rem solid ${colorToCode('dark-900-12')};
        overflow: auto;
        margin-bottom: 0;
      `}
    >
      <thead>
        <tr
          css={css`
            background-color: ${colorToCode('light-000')};
            color: ${colorToCode('dark-900')};
            height: 2.5rem;
            text-align: center;
          `}
        >
          <th
            css={css`
              user-select: none;
              border-top: 0;
              padding: 0.5rem;
            `}
          />
          {Object.keys(dataTypes).map(type => (
            <th
              css={css`
                user-select: none;
                border-top: 0;
                border-bottom: 0.0625rem solid ${colorToCode('dark-900-12')};
                vertical-align: middle;
                padding: 0.5rem;
                min-width: 10rem;
              `}
              key={type}
            >
              <Header size={5}>
                {type
                  .replace(/([A-Z])/g, ' $1')
                  .replace(/^[a-z]/, m => m.toUpperCase())
                  .trim()}
              </Header>
            </th>
          ))}
        </tr>
      </thead>
      <tbody
        css={css`
          tr {
            height: 2.5rem;
          }

          td:first-of-type {
            user-select: none;
            border-top: 0;
            background-color: ${colorToCode('light-000')};
            color: ${colorToCode('dark-900')};
            border-right: 0.0625rem solid ${colorToCode('dark-900-12')};
            vertical-align: top;
            text-align: right;
          }

          td {
            border-top: 0;
            padding: 0.5rem;
          }
        `}
      >
        <tr>
          <td>
            <Header size={5}>Supported?</Header>
          </td>
          {Object.keys(dataTypes).map(type => (
            <td
              key={type}
              css={css`
                background-color: ${dataTypes[type].isSupported ? colorToCode('positive-100') : undefined};
              `}
            >
              {dataTypes[type].isSupported ? (
                <SuccessIcon
                  css={css`
                    display: block;
                    margin: 0 auto;
                  `}
                />
              ) : (
                <ErrorIcon
                  css={css`
                    display: block;
                    margin: 0 auto;
                  `}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td>
            <Header size={5}>Available fields</Header>
          </td>
          {Object.keys(dataTypes).map(type => (
            <td
              key={type}
              css={css`
                background-color: ${dataTypes[type].isSupported ? colorToCode('positive-100') : undefined};
                text-align: center;
              `}
            >
              <ul
                css={css`
                  padding: 0;
                  margin: 0 auto;
                  list-style-type: none;
                  display: inline-block;
                `}
              >
                {dataTypes[type].fields.map(field => (
                  <li key={field}>{field}</li>
                ))}
              </ul>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default IntegrationDataTypesDisplay;
