import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { css } from '@emotion/react';
import WizardPageStep from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageStep';
import WizardPageProgressSegment, {
  WizardPageProgressState,
} from '~/neo-ui/packages/wizard/packages/wizard-page/packages/wizard-page-progress/packages/wizard-page-progress-segment/WizardPageProgressSegment';
import WizardPageProgressSegmentSuccess from '~/neo-ui/packages/wizard/packages/wizard-page/packages/wizard-page-progress/packages/wizard-page-progress-segment-success/WizardPageProgressSegmentSuccess';
import LayoutHeader from '~/neo-ui/packages/layout/packages/header/LayoutHeader';

export type WizardPageProgressProps<T> = {
  currentStepIndex: number;
  steps: WizardPageStep<T>[];
  completedStepIndexes: Set<number>;
  isCurrentStepCompleted: boolean | undefined;
  onClick: (stepIndex: number) => void;
  isDisabled?: boolean;
} & Styleable;

const WizardPageProgress = <T,>({
  currentStepIndex,
  steps,
  completedStepIndexes,
  isCurrentStepCompleted,
  onClick,
  isDisabled,
  className,
}: WizardPageProgressProps<T>) => {
  // The states for each segment of the progress bar
  const segmentStates: WizardPageProgressState[] = steps.map((_, index) => {
    if (isDisabled) {
      return 'disabled';
    }

    if (steps.length === currentStepIndex) {
      return 'completed-disabled';
    }

    if (index === currentStepIndex) {
      return 'current';
    }

    if (!isCurrentStepCompleted && !completedStepIndexes.has(index)) {
      return 'incomplete';
    }

    if (completedStepIndexes.has(index)) {
      return 'completed';
    }

    return 'disabled';
  });

  return (
    <LayoutHeader
      layoutBarComponent={
        <div
          className={className}
          css={css`
            display: flex;
          `}
        >
          {steps.map((_, stepIndex) => (
            <WizardPageProgressSegment
              key={stepIndex}
              stepIndex={stepIndex}
              currentStepIndex={currentStepIndex}
              isCurrentStepCompleted={isCurrentStepCompleted}
              segmentStates={segmentStates}
              steps={steps}
              onClick={onClick}
            />
          ))}
          <WizardPageProgressSegmentSuccess isActive={currentStepIndex === steps.length} />
        </div>
      }
    />
  );
};

export default WizardPageProgress;
