import Testable from '~/neo-ui/packages/testable/Testable';
import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import { mobileBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import Header, { HeaderProps } from '~/neo-ui/packages/text/packages/header/Header';
import Button from '~/neo-ui/packages/button/Button';
import React from 'react';
import BoxShadow from '~/neo-ui/packages/style/BoxShadow';
import { Styleable } from '~/neo-ui/model/capacity';
import Image from '~/neo-ui/packages/image/Image';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import Badge from '~/neo-ui/packages/badge/Badge';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import Graphic from '~/neo-ui/model/Graphic';
import CallToAction from '~/neo-ui/model/CallToAction';

export type BannerInternalProps = {
  /**
   * Props for the title
   */
  titleProps: HeaderProps;
  /**
   * Props for the description
   */
  descriptionProps: HeaderProps;
  /**
   * The image or icon displayed to the left of the banner
   */
  graphic: BannerGraphic;
  /**
   * Box shadow of the containing box
   */
  boxShadow: BoxShadow;
  /**
   * Background color of the containing Box
   */
  backgroundColor: Color;
  /**
   * Optional badge to be displayed with the header
   */
  badge?: string;
  /**
   * Optional call to action of the banner
   */
  callToAction?: BannerCallToAction;
  /**
   * Identifier
   */
  id?: string;
  /**
   * Enable/disable showing a button to close the banner
   */
  closeable?: boolean;
  /**
   * Function that occurs on close
   */
  onClose?: () => void;
  /**
   * Open banner in a new tab
   */
  openInNewTab?: boolean;
} & Styleable;

/**
 * Define the graphic shown to the left of the banner. Either an icon or an image, not both
 */
export type BannerGraphic = {
  verticalAlignment?: 'start' | 'end' | 'center';
} & Graphic;

/**
 * Call to action allows either a link (url redirect) or an onClick action, not both
 */
export type BannerCallToAction = {
  verticalAlignment?: 'start' | 'end' | 'center';
} & CallToAction;

/**
 * **FOR INTERNAL COMPONENT USE ONLY**
 * A base-level banner to build reusable components off of
 */
const BannerInternal = ({
  titleProps,
  descriptionProps,
  graphic,
  boxShadow,
  backgroundColor,
  badge,
  callToAction,
  className,
  id,
  closeable = false,
  onClose,
  openInNewTab = false,
}: BannerInternalProps) => {
  const [showBanner, setShowBanner] = React.useState(true);

  return showBanner ? (
    <Testable testId={'upgrade-banner'}>
      <Box
        className={className}
        padding={'padding300'}
        boxShadow={boxShadow}
        borderRadius={'radius400'}
        css={css`
          margin-bottom: 1.875rem;
          background-color: ${colorToCode(backgroundColor)};
        `}
      >
        {closeable && (
          <ButtonModifier
            icon={'Cancel'}
            onClick={() => {
              if (onClose) {
                onClose();
              }
              setShowBanner(false);
            }}
            css={css`
              position: relative;
              top: -1rem;
              left: -1rem;
            `}
          />
        )}
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 1.25rem;
            margin-top: ${closeable ? '-1rem' : '0'};
          `}
        >
          {typeof graphic.imageSource !== 'undefined' ? (
            <Image
              css={css`
                align-self: ${graphic.verticalAlignment ?? 'center'};
                max-width: 6.25rem;
                max-height: 3.75rem;
                @media (max-width: ${mobileBreakpointRem}rem) {
                  display: none;
                }
              `}
              source={graphic.imageSource}
            />
          ) : (
            typeof graphic.icon !== 'undefined' && (
              <Icon
                css={css`
                  align-self: ${graphic.verticalAlignment ?? 'center'};
                  @media (max-width: ${mobileBreakpointRem}rem) {
                    display: none;
                  }
                `}
                icon={graphic.icon.icon}
                sizePx={60}
                color={graphic.icon.color}
              />
            )
          )}
          <div
            css={css`
              flex: 1;
            `}
          >
            <div
              css={css`
                display: inline-flex;
                justify-content: space-between;
              `}
            >
              <Header
                css={css`
                  @media (min-width: ${mobileBreakpointRem}rem) {
                    margin-bottom: 0.625rem;
                  }
                `}
                {...titleProps}
              />
              {badge && (
                <div
                  css={css`
                    margin-left: 0.625rem;
                  `}
                >
                  <Badge height={'1.25rem'}>{badge}</Badge>
                </div>
              )}
            </div>
            <Header {...descriptionProps} />
          </div>
          {callToAction && (
            <Testable testId={'banner-call-to-action'}>
              <Button
                id={id}
                theme={typeof callToAction.theme !== 'undefined' ? callToAction.theme : 'positive'}
                iconLeft={callToAction.iconLeft}
                // Preserve old functionality of defaulting to GoRight if no icon is provided
                iconRight={
                  typeof callToAction.iconRight === 'undefined' && typeof callToAction.iconLeft === 'undefined'
                    ? 'GoRight'
                    : callToAction.iconRight ?? 'GoRight'
                }
                anchor={
                  callToAction.link
                    ? {
                        href: callToAction.link,
                        openInNewTab,
                      }
                    : undefined
                }
                onClick={callToAction.onClick}
                css={css`
                  align-self: ${callToAction.verticalAlignment ?? 'center'};
                `}
              >
                {callToAction.text}
              </Button>
            </Testable>
          )}
        </div>
      </Box>
    </Testable>
  ) : (
    <div />
  );
};

export default BannerInternal;
