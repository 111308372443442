import * as React from 'react';
import { useDispatch } from 'react-redux';
import EntityLock from '~/legacy-ui/packages/lock/packages/entity-lock/EntityLock';
import progressStatusAction from '~/neo-ui/packages/progress/state/action/status/progressStatusAction';
import organizationBucketKey from '../../model/organizationBucketKey';
import linkingProgress from '../linking/operation/linkingProgress';

export type OrganizationSuspenseProps = {
  organizationIds: string[];
  children: React.ReactNode;
};

/**
 * Wait for organizations to become available
 */
const OrganizationLock = ({ organizationIds, children }: OrganizationSuspenseProps) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    // On load, request organization linking progress
    for (const organizationId of organizationIds) {
      dispatch(progressStatusAction(linkingProgress({ parentOrganizationId: organizationId })));
    }
  }, [dispatch, organizationIds]);

  return (
    <EntityLock
      bucket={organizationBucketKey}
      dependencies={organizationIds}
      lockByDefault={true}
    >
      {children}
    </EntityLock>
  );
};

export default OrganizationLock;
