// eslint-disable-next-line consistent-default-export-name/default-export-match-filename
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import WarrantyCartFilterFormData from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-filters/packages/warranty-cart-filter-form/WarrantyCartFilterFormData';

const warrantyCartFilterFormFields = {
  selectedItems: (value: FieldKeyExpressionSegment<WarrantyCartFilterFormData>) => value.selectedItems,
  outOfWarrantyAssets: (value: FieldKeyExpressionSegment<WarrantyCartFilterFormData>) => value.outOfWarrantyAssets,
  assetsBetweenYears: (value: FieldKeyExpressionSegment<WarrantyCartFilterFormData>) => value.assetsBetweenYears,
  assetsBetweenYearsFrom: (value: FieldKeyExpressionSegment<WarrantyCartFilterFormData>) => value.assetsBetweenYearsFrom,
  assetsBetweenYearsTo: (value: FieldKeyExpressionSegment<WarrantyCartFilterFormData>) => value.assetsBetweenYearsTo,
};

export default warrantyCartFilterFormFields;
