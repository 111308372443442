import MultiSelectStatic, { MultiSelectStaticSelection } from '~/neo-ui/packages/select/packages/multi-select-static/MultiSelectStatic';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { Styleable } from '~/neo-ui/model/capacity';
import { css } from '@emotion/react';
import React from 'react';
import Theme from '~/neo-ui/packages/color/Theme';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type SplitMultiSelectProps<T extends string> = {
  /**
   * Display
   */
  leftHeaderLabel?: string; // overrides the left header label
  rightHeaderLabel?: string; // overrides the right header label
  placeholderSearchString?: string;
  leftTheme?: Theme;
  rightTheme?: Theme;

  /**
   * Values
   */
  allOptions: SelectOption<T>[]; // options to select from each list
  allSelectionTypes?: string[];
  selectedOptionsPositive: SelectOption<T>[] | undefined; // external control of positive selected options (left side)
  selectedOptionsNegative: SelectOption<T>[] | undefined; // external control of negative selected options (right side)
  selectionTypePositive?: string; // external control of selection type (any or all) on the left filter menu
  selectionTypeNegative?: string; // external control of selection type (any or all) on the right filter menu

  /**
   * Actions
   */
  // optional parent callback for responding to selection changes
  onChange?: (selectedPositive: MultiSelectStaticSelection<T>, selectedNegative: MultiSelectStaticSelection<T>) => void;
} & Styleable;

const SplitMultiSelect = <T extends string>({
  className,
  leftHeaderLabel,
  rightHeaderLabel,
  leftTheme,
  rightTheme,
  placeholderSearchString,
  allOptions,
  allSelectionTypes = ['any', 'all'],
  selectedOptionsPositive,
  selectedOptionsNegative,
  selectionTypePositive,
  selectionTypeNegative,
  onChange,
}: SplitMultiSelectProps<T>) => {
  /**
   * Create OnChange function for each multiselect section
   */
  const onChangePositive = React.useCallback(
    (positiveSelection: MultiSelectStaticSelection<T>) => {
      if (onChange) {
        onChange(positiveSelection, {
          selectedOptions: selectedOptionsNegative?.map(negativeOption => negativeOption.value),
          matchType: selectionTypeNegative,
        });
      }
    },
    [selectedOptionsNegative, selectionTypeNegative, onChange],
  );
  const onChangeNegative = React.useCallback(
    (negativeSelection: MultiSelectStaticSelection<T>) => {
      if (onChange) {
        onChange(
          {
            selectedOptions: selectedOptionsPositive?.map(positiveOption => positiveOption.value),
            matchType: selectionTypePositive,
          },
          negativeSelection,
        );
      }
    },
    [selectedOptionsPositive, selectionTypePositive, onChange],
  );

  /** ***************
   * Main component *
   ******************/
  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: row;
      `}
    >
      <MultiSelectStatic
        allOptions={allOptions}
        allSelectionTypes={allSelectionTypes}
        selectedOptions={selectedOptionsPositive}
        selectionType={selectionTypePositive}
        headerLabel={leftHeaderLabel}
        placeholderSearchString={placeholderSearchString}
        theme={leftTheme ?? 'secondary'}
        onChange={onChangePositive}
        focusSearch={true}
      />
      <div
        css={css`
          border-right: ${colorToCode('dark-900-12')} solid 1px;
        `}
      />
      <MultiSelectStatic
        allOptions={allOptions}
        allSelectionTypes={allSelectionTypes}
        selectedOptions={selectedOptionsNegative}
        selectionType={selectionTypeNegative}
        headerLabel={rightHeaderLabel}
        placeholderSearchString={placeholderSearchString}
        theme={rightTheme ?? 'negative'}
        onChange={onChangeNegative}
        focusSearch={false}
      />
    </div>
  );
};

export default SplitMultiSelect;
