import { css } from '@emotion/react';
import * as React from 'react';
import ScorecardItemsCard from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-items-card/ScorecardItemsCard';
import ScorecardSummaryCard from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-summary-card/ScorecardSummaryCard';
import ScorecardUpdateForm from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-update-form/ScorecardUpdateForm';
import DisableElementOverlay from '~/neo-ui/packages/overlay/DisableElementOverlay';
import useAlert from '~/neo-ui/packages/toast/hooks/useAlert';
import ScorecardCategorySection from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/packages/scorecard-category-section/ScorecardCategorySection';
import useScorecardContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardContext';
import { ScaleOption } from '~/neo-ui/packages/select/packages/scale-select/ScaleSelect';
import Color from '~/neo-ui/packages/color/Color.gen';
import ScorecardCategoryArrangeWindow from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/packages/scorecard-category-arrange/packages/scorecard-category-arrange-window/ScorecardCategoryArrangeWindow';

export type ScorecardProps = {
  onScorecardIsSubmitting: (isSubmitting: boolean) => void;
};

const Scorecard = ({ onScorecardIsSubmitting }: ScorecardProps) => {
  const { scorecard } = useScorecardContext();
  const [canEdit, setCanEdit] = React.useState(true);
  const [isArranging, setIsArranging] = React.useState(false);
  const sendAlert = useAlert();

  const onScorecardUpdateFailed = React.useCallback(() => {
    setCanEdit(false);
    sendAlert({
      title: 'Something went wrong',
      description: `Try refreshing the page or contact support if the problem persists.`,
      icon: 'Danger',
      theme: 'negative',
      buttonLeft: {
        label: 'Refresh the page',
        iconLeft: 'Reload',
        onClick: () => window.location.reload(),
      },
      isNonDismissible: true,
    });
  }, [sendAlert]);

  const scoreDisplayAvailabilities: ScaleOption[] = scorecard.scoreDisplayAvailabilities.map(score => ({
    value: score.value.toString(),
    color: `${score.theme}-300` as Color,
    borderColor: `${score.theme}-400` as Color,
    activeIcon: 'Success',
  }));

  return (
    <DisableElementOverlay disabled={!canEdit}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1.875rem;
        `}
      >
        <ScorecardUpdateForm
          onScorecardUpdateFailed={onScorecardUpdateFailed}
          scorecard={scorecard}
          onFormIsSubmitting={onScorecardIsSubmitting}
        >
          <ScorecardSummaryCard scorecard={scorecard} />
        </ScorecardUpdateForm>
        {typeof scorecard.scorecardCategories !== 'undefined' ? (
          scorecard.scorecardCategories.map(scorecardCategory => (
            <React.Fragment key={scorecardCategory.scorecardCategoryId}>
              <ScorecardCategorySection
                scorecardCategory={scorecardCategory}
                scorecardId={scorecard.scorecardId}
                organizationId={scorecard.organizationId}
                currency={scorecard.currency}
                scoreDisplayAvailabilities={scoreDisplayAvailabilities}
                priorityDisplayAvailabilities={scorecard.priorityDisplayAvailabilities}
                onScorecardUpdateFailed={() => setCanEdit(false)}
                onArrangeOpen={() => setIsArranging(true)}
              />
              <ScorecardCategoryArrangeWindow
                isOpen={isArranging}
                onDismiss={() => setIsArranging(false)}
                scorecardId={scorecard.scorecardId}
                scoreDisplayAvailabilities={scoreDisplayAvailabilities}
                priorityDisplayAvailabilities={scorecard.priorityDisplayAvailabilities}
              />
            </React.Fragment>
          ))
        ) : (
          <ScorecardItemsCard
            scorecardId={scorecard.scorecardId}
            organizationId={scorecard.organizationId}
            scorecardItems={scorecard.scorecardItems}
            currency={scorecard.currency}
            scoreDisplayAvailabilities={scoreDisplayAvailabilities}
            priorityDisplayAvailabilities={scorecard.priorityDisplayAvailabilities}
            onScorecardUpdateFailed={() => setCanEdit(false)}
          />
        )}
      </div>
    </DisableElementOverlay>
  );
};

export default Scorecard;
