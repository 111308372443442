import { SettingsValueNested } from '@AssetManagementClient/BeastClient/Beast/Settings/Model.gen';
import { css } from '@emotion/react';
import * as React from 'react';

import FormSelectInput, { FormSelectOption } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import FormTextInput from '~/neo-ui/packages/form/packages/form-input/packages/form-text-input/FormTextInput';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import DataTable, { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { HardwareReplacementReportAssetTypeSettingsAvailabilitiesDto } from '@AssetManagementClient/BeastClient/Beast/HardwareReplacement/Dto/Model.gen';
import { camelToPascal } from '~/extensions/packages/casing/camelPascalConversion';

export type ReportAssetTypeAvailabilityDto = {
  hardwareTypeLabel: string;
  replacementValue: number;
  replacementAgeRange: number[];
};

export type HardwareSettingsFormV2Props = {
  availabilities: HardwareReplacementReportAssetTypeSettingsAvailabilitiesDto;
  currencyCharacter: string;
  disabled?: boolean;
};

const HardwareReplacementSettingsFormV2: React.FunctionComponent<HardwareSettingsFormV2Props> = ({
  availabilities,
  currencyCharacter,
  disabled = false,
}) => {
  const reportAssetTypes = Object.keys(availabilities.reportAssetTypeAvailabilities).map(key => ({
    hardwareTypeLabel: key,
    replacementValue: 0,
    replacementAgeRange: availabilities.reportAssetTypeAvailabilities[key].replacementAgeRange,
  }));

  const disabledValue = 'DISABLED';

  const formTableColumns: DataTableColumn<ReportAssetTypeAvailabilityDto>[] = [
    {
      Header: 'Hardware type',
      fieldKey: reportAssetTypeAvailability => reportAssetTypeAvailability.hardwareTypeLabel,
      renderCell: reportAssetTypeAvailability => (
        <div
          css={css`
            display: flex;
            align-items: center;
            height: 1.875rem;
          `}
        >
          {/* Because of camelization it breaks these keys.  */}
          {/* TODO https://warrantymaster.myjetbrains.com/youtrack/issue/WM-1555 */}
          {camelToPascal(reportAssetTypeAvailability.hardwareTypeLabel)}
        </div>
      ),
    },
    {
      Header: 'Replacement value',
      fieldKey: reportAssetTypeAvailability => reportAssetTypeAvailability.replacementValue,
      renderCell: reportAssetTypeAvailability => (
        <FormTextInput
          fieldKey={(settings: FieldKeyExpressionSegment<SettingsValueNested.HardwareReplacementReportAssetTypeSettings>) =>
            settings.value.reportAssetTypePreferences[reportAssetTypeAvailability.hardwareTypeLabel].replacementValue
          }
          placeholder="Money value"
          prependCharacter={currencyCharacter}
          disabled={disabled}
        />
      ),
    },
    {
      Header: 'Maximum replacement age',
      fieldKey: reportAssetTypeAvailability => reportAssetTypeAvailability.replacementAgeRange,
      renderCell: reportAssetTypeAvailability => (
        <FormSelectInput<SettingsValueNested.HardwareReplacementReportAssetTypeSettings, number | undefined>
          fieldKey={settings =>
            settings.value.reportAssetTypePreferences[reportAssetTypeAvailability.hardwareTypeLabel].mspRetirementValues.assetAgeThreshold
          }
          disabled={disabled}
          fromFormData={value => (typeof value === 'undefined' ? disabledValue : value.toString())}
          toFormData={value => (value === disabledValue ? undefined : parseInt(value, 10))}
          options={[
            ...availabilities.reportAssetTypeAvailabilities[reportAssetTypeAvailability.hardwareTypeLabel].replacementAgeRange.map(
              (age): FormSelectOption => ({
                label: `${age === 0 ? disabledValue : `${age} years`}`,
                value: age.toString(),
              }),
            ),
          ]}
        />
      ),
    },
  ];

  return (
    <div>
      <DataTable
        columns={formTableColumns}
        data={reportAssetTypes}
        EmptyStatePlaceholder={''}
      />
    </div>
  );
};

export default HardwareReplacementSettingsFormV2;
