import { camelToPascal } from '~/extensions/packages/casing/camelPascalConversion';
import ValidationErrorMapper from '~/neo-ui/packages/form/packages/form-action/packages/form-validation/ValidationErrorMapper';
import { FieldKeyExpression, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';

/**
 * Helper function to create a mapping between fields
 */
export const createValidationErrorMap =
  <T, U>(fieldMappings: [apiServerFieldKey: FieldKeyExpression<T>, frontendFieldKey: FieldKeyExpression<U>][]): ValidationErrorMapper =>
  apiServerField => {
    const apiServerFieldsToFrontendField = fieldMappings.map<[string, string]>(([apiServerFieldKeyCamelCase, frontendFieldKey]) => [
      resolveFieldKey(apiServerFieldKeyCamelCase).split('.').map(camelToPascal).join('.'),
      resolveFieldKey(frontendFieldKey),
    ]);

    const map = apiServerFieldsToFrontendField.find(([mappedApiServerField]) => apiServerField === mappedApiServerField);

    if (map) {
      const [_, frontendField] = map;
      return frontendField;
    }

    // Left unchanged if we couldn't find a map (assume it's a one-to-one)
    return apiServerField;
  };
