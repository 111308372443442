import { useState, useCallback, useEffect } from 'react';
import { organizationBasicInfoList } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { OrganizationBasicInfoDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Dto/Model.gen';
import useApi from '~/wm/packages/api/hook/useApi';

export type UseOrganizationBasicInfoList = () => {
  organizations: OrganizationBasicInfoDto[] | undefined;
};

const useOrganizationBasicInfoList: UseOrganizationBasicInfoList = () => {
  const { callApi } = useApi();
  const [organizations, setOrganizations] = useState<OrganizationBasicInfoDto[] | undefined>(undefined);

  const reload = useCallback(async () => {
    const response = await callApi(() => organizationBasicInfoList({}));

    if (!response) {
      return;
    }

    setOrganizations(response.organizations);
  }, [callApi]);

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    organizations,
  };
};

export default useOrganizationBasicInfoList;
