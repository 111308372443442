import * as React from 'react';
import FormSelectInput, { FormSelectOption } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import { css } from '@emotion/react';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import InsightUnitFormatter from '~/wm/packages/strategy/packages/insight/packages/insight-create-button/insight-create-modal/policy-input/unit/InsightUnitFormatter';
import {
  PolicyFormDto,
  PolicyFormUnitDto,
} from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Dto/Model/CreateForm.gen';
import { InsightFormData } from '~/wm/packages/strategy/packages/insight/packages/insight-create-button/insight-create-modal/InsightFormData';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { UnitTypeEnum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Dto/Model/CreateForm/PolicyFormUnitDtoNested.gen';
import Step from '~/wm/packages/strategy/packages/insight/packages/insight-create-button/insight-create-modal/Step';

type PolicyInputProps = {
  index: number;
  policiesData: PolicyFormDto[];
  policyOptions: FormSelectOption[];
};

export type PolicyInputRef = {
  reset: () => void;
};

const PolicyInput: React.ForwardRefRenderFunction<PolicyInputRef, PolicyInputProps> = ({ index, policiesData, policyOptions }, ref) => {
  const { setFormInput } = useFormContext<InsightFormData>();
  const [unit, setUnit] = React.useState<PolicyFormUnitDto>();
  const [step, setStep] = React.useState<number>(0);
  const [currentPolicyData, setCurrentPolicyData] = React.useState<PolicyFormDto>();
  const [policyOperatorsOptions, setPolicyOperatorsOptions] = React.useState<FormSelectOption[]>([]);

  React.useImperativeHandle(ref, () => ({
    reset: () => {
      setStep(0);
    },
  }));

  const onPolicyChange = (value: string) => {
    const policyData = policiesData.find(policy => policy.key === value);
    if (typeof policyData === 'undefined') {
      return;
    }
    setCurrentPolicyData(policyData);
    const policyOperators =
      policyData.operators.map(operator => ({
        label: operator.label,
        value: operator.key,
      })) ?? [];

    if (typeof policyData.defaultOperator !== 'undefined' || policyData.defaultOperator === '') {
      setFormInput<string>(
        (row: FieldKeyExpressionSegment<InsightFormData>) => row.policies[index].policyOperatorEnum,
        policyData.defaultOperator,
      );
    } else {
      setFormInput<string>(
        (row: FieldKeyExpressionSegment<InsightFormData>) => row.policies[index].policyOperatorEnum,
        policyOperators[0].value,
      );
    }
    switch (policyData.unit.key) {
      case UnitTypeEnum.Boolean:
        setFormInput<string>(
          (row: FieldKeyExpressionSegment<InsightFormData>) => row.policies[index].value,
          policyData.unit.defaultOption ? 'true' : 'false',
        );
        break;
      default:
        setFormInput<string>((row: FieldKeyExpressionSegment<InsightFormData>) => row.policies[index].value, '');
        break;
    }
    setPolicyOperatorsOptions(policyOperators);
    setUnit(policyData.unit);
    setStep(1);
  };

  return (
    <>
      <div>
        <FormSelectInput
          fieldKey={(row: FieldKeyExpressionSegment<InsightFormData>) => row.policies[index].policyEnum}
          options={policyOptions}
          onSelect={onPolicyChange}
        />
      </div>
      <Step
        currentStep={step}
        displayStep={1}
      >
        <div
          css={css`
            display: flex;
            gap: 0.375rem;
            margin-top: 0.375rem;
          `}
        >
          <div
            css={css`
              flex-basis: 35%;
            `}
          >
            <FormSelectInput
              fieldKey={(row: FieldKeyExpressionSegment<InsightFormData>) => row.policies[index].policyOperatorEnum}
              options={policyOperatorsOptions}
            />
          </div>
          <div
            css={css`
              flex-basis: 65%;
            `}
          >
            {InsightUnitFormatter(
              unit,
              (row: FieldKeyExpressionSegment<InsightFormData>) => row.policies[index].value,
              undefined,
              currentPolicyData?.label,
            )}
          </div>
        </div>
      </Step>
    </>
  );
};

export default React.forwardRef(PolicyInput);
