import { useMemo } from 'react';
import {
  FieldValueMapDisplay,
  ValueMappingDisplaySettingsDto,
} from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';
import { pascalToCamel } from '~/extensions/packages/casing/camelPascalConversion';

export type UseMappingDataProcessingProps = {
  payloadType: string;
  payloadSectionsDictionary: { [key in string]: ValueMappingDisplaySettingsDto };
};

export type SelectedMappingsKey = `selectedMappings.${string}.${string}.${string}`;

export type SelectionInfo = {
  isChildSelected: boolean;
  parentMappingKey: SelectedMappingsKey | null;
};

const useMappingDataProcessing = ({ payloadType, payloadSectionsDictionary }: UseMappingDataProcessingProps) => {
  // To help sort out the cross-section mapping keys and selection logic
  const formKeyToSelectionInfo = useMemo(() => {
    const optionIdToSelectionInfo = new Map<SelectedMappingsKey, SelectionInfo>();
    Object.entries(payloadSectionsDictionary).forEach(([sectionId, sectionInformation]) => {
      sectionInformation.mapFromOptions.forEach((mapFromOption: FieldValueMapDisplay) => {
        const hasByChildKey =
          Object.values(payloadSectionsDictionary[sectionId].selectedMappings).find(
            selectedMapping =>
              selectedMapping.id === mapFromOption.id &&
              selectedMapping.selectedValue === payloadSectionsDictionary[sectionId].byChildMapToOption,
          ) !== undefined;
        const childSectionKey = pascalToCamel(mapFromOption.childSectionKey ?? '');

        const isChildOptionSelected =
          (mapFromOption?.childOptions &&
            mapFromOption.childOptions.some((childOption: FieldValueMapDisplay) =>
              Object.keys(payloadSectionsDictionary[childSectionKey].selectedMappings).includes(childOption.id + '-'),
            )) ??
          false;

        const mappingKey = `selectedMappings.${payloadType}.${sectionId}.${mapFromOption.id + '-'}` as const;

        mapFromOption.childOptions?.forEach((childOption: FieldValueMapDisplay) => {
          if (childOption.id !== undefined && mapFromOption.childSectionKey !== undefined) {
            const childMappingKey = `selectedMappings.${payloadType}.${childSectionKey}.${childOption.id + '-'}` as const;
            optionIdToSelectionInfo.set(childMappingKey, {
              isChildSelected: false,
              parentMappingKey: mappingKey,
            });
          }
        });

        optionIdToSelectionInfo.set(mappingKey, {
          isChildSelected: hasByChildKey && isChildOptionSelected,
          parentMappingKey: null,
        });
      });
    });
    return optionIdToSelectionInfo;
  }, [payloadType, payloadSectionsDictionary]);

  return { formKeyToSelectionInfo };
};
export default useMappingDataProcessing;
