import { css } from '@emotion/react';
import { SubscriptionGrowAvailableActionEnum } from '@SubscriptionClient/Subscription/Packages/Grow/Model.gen';
import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import Button from '~/neo-ui/packages/button/Button';
import useSubscriptionGrowAction from '~/wm/packages/subscription/packages/grow/packages/grow-action/hooks/useSubscriptionGrowAction';
import SubscriptionPdContactSection from '~/wm/packages/subscription/packages/subscription-pd-contact/packages/subscription-pd-contact-section/SubscriptionPdContactSection';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type SubscriptionGrowCallToActionCardProps = {
  subscriptionGrowUrl: string;
  subscriptionOfferUrl: string;
};

const SubscriptionGrowCard: React.FunctionComponent<SubscriptionGrowCallToActionCardProps> = ({
  subscriptionGrowUrl,
  subscriptionOfferUrl,
}) => {
  const { isLoading, growAction } = useSubscriptionGrowAction();

  if (isLoading) {
    return null;
  }

  const growUrl = () => {
    if (!growAction) {
      return null;
    }
    switch (growAction.enum) {
      case SubscriptionGrowAvailableActionEnum.Offer:
        return subscriptionOfferUrl;
      case SubscriptionGrowAvailableActionEnum.SelfServe:
        return subscriptionGrowUrl;
    }
  };

  return growAction ? (
    <Card
      padding={'xl'}
      shadow={'md'}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          color: ${colorToCode('secondary-400')};
        `}
      >
        <div
          css={css`
            flex: 1;
          `}
        >
          <div>
            <div
              css={css`
                font-size: 1.125rem;
                font-weight: bold;
                margin-bottom: 0.5rem;
              `}
            >
              {growAction.title}
            </div>
            <p
              css={css`
                margin: 0;
              `}
            >
              {growAction.text}
            </p>
          </div>
        </div>
        <Button
          theme={'positive'}
          iconRight={'GoRight'}
          anchor={{
            href: growUrl() ?? '',
            openInNewTab: false,
          }}
        >
          {growAction.callToActionLabel}
        </Button>
      </div>
    </Card>
  ) : (
    <Card
      padding={'md'}
      shadow={'md'}
    >
      <SubscriptionPdContactSection reasonToContact="To talk about your subscription" />
    </Card>
  );
};

export default SubscriptionGrowCard;
