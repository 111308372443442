import Popup from '~/neo-ui/packages/popup/Popup';
import * as React from 'react';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import { css } from '@emotion/react';
import InputTitle from '~/neo-ui/packages/input/packages/input-title/InputTitle';
import Button from '~/neo-ui/packages/button/Button';
import useScorecardItemRemove from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/hooks/useScorecardItemRemove';
import useScorecardItemUpdate from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/hooks/useScorecardItemUpdate';
import { ScorecardItemDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardItem/Dto/Model.gen';
import Testable from '~/neo-ui/packages/testable/Testable';

export type ScorecardItemManageButtonProps = {
  scorecardItem: ScorecardItemDto;
  scorecardCategoryId: string;
};

const ScorecardItemManageButton = ({ scorecardItem, scorecardCategoryId }: ScorecardItemManageButtonProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [newLabel, setNewLabel] = React.useState(scorecardItem.category.label);

  const { removeScorecardItem, isRemovingScorecardItem } = useScorecardItemRemove(scorecardItem.scorecardId, scorecardCategoryId);

  const { updateScorecardItem, isUpdatingScorecardItem } = useScorecardItemUpdate(
    scorecardItem.scorecardId,
    scorecardItem.scorecardItemId,
    {
      onSuccess: () => setIsOpen(false),
    },
  );

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      backgroundColor={'light-000'}
      placement={'bottom-start'}
      title={'Manage Item'}
      titleColor={'secondary-400'}
      showCloseButton={true}
      css={css`
        min-width: 37.5rem;
      `}
      content={
        <InputTitle
          theme={'secondary'}
          placeholder={'Enter a name…'}
          value={newLabel}
          onChange={value => setNewLabel(value)}
        />
      }
      footer={
        <React.Fragment>
          <Button
            iconLeft={'Done'}
            theme={'secondary'}
            loading={isUpdatingScorecardItem}
            onClick={() =>
              updateScorecardItem({
                category: {
                  categoryUniqueId: 'custom',
                  categoryCustom: newLabel,
                },
                investment: scorecardItem.investment,
                priority: { priority: scorecardItem.priority.key },
                score: scorecardItem.score,
              })
            }
          >
            Done
          </Button>
          <Testable testId={'scorecard-item-remove-button'}>
            <Button
              iconLeft={'Remove'}
              theme={'negative'}
              onClick={() =>
                removeScorecardItem({
                  scorecardItemId: scorecardItem.scorecardItemId,
                })
              }
              loading={isRemovingScorecardItem}
            >
              Remove
            </Button>
          </Testable>
        </React.Fragment>
      }
    >
      <Testable testId={'scorecard-item-edit-button'}>
        <ButtonModifier
          icon={'Edit'}
          onClick={() => setIsOpen(prev => !prev)}
        />
      </Testable>
    </Popup>
  );
};

export default ScorecardItemManageButton;
