import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import { css } from '@emotion/react';
import React from 'react';
import useItemSelection from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/hooks/useItemSelection';
import ConsoleSingleSelectOption from '~/neo-ui/packages/select/packages/console-single-select/packages/ConsoleSingleSelectOption/ConsoleSingleSelectOption';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Theme from '~/neo-ui/packages/color/Theme';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';

export type ConsoleSingleSelectProps<T extends string> = {
  /**
   * Display
   */
  selectedIcon?: IconType;
  description?: string;

  /**
   * Values
   */
  allOptions: SelectOption<T>[]; // options to select from each list
  allSelectionTypes?: string[];
  selectedOption: SelectOption<T> | undefined; // external control of the selected option

  /**
   * Actions
   */
  // optional parent callback for responding to selection changes
  onChange?: (selected?: SelectOption<T>) => void;
  theme?: Theme;
} & Styleable &
  Themeable;

const ConsoleSingleSelect = <T extends string>({
  className,
  description,
  allOptions,
  selectedOption,
  selectedIcon,
  onChange,
  theme = 'secondary',
}: ConsoleSingleSelectProps<T>) => {
  const themeMap = useTheme(theme).themeMap;
  /**
   * Selection management
   */
  const {
    selectSingleItem: selectOption,
    deselectItem: deselectOption,
    isItemSelected: isOptionSelected,
    selectedItems: internalSelectedOptions,
    areSetsEqual: isSame,
  } = useItemSelection<T>();

  /**
   * Update selected option if externally changed
   */
  React.useEffect(() => {
    if (typeof selectedOption === 'undefined') {
      return;
    }
    const externalSelectedOption = selectedOption.value;

    if (isSame(new Set([externalSelectedOption]), internalSelectedOptions)) {
      return;
    }
    selectOption(externalSelectedOption); // update internal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, isSame, selectOption]);

  /**
   * Update parent based on internal changes
   */
  const onSelectOption = (chosenByUser: T) => {
    selectOption(chosenByUser);

    if (typeof selectedOption?.value === 'undefined') {
      return;
    }
    const externalSelectedOption = selectedOption.value;
    if (externalSelectedOption === chosenByUser) {
      return;
    }
    if (onChange !== undefined) {
      onChange({ label: chosenByUser, value: chosenByUser }); // update external
    }
  };

  const onDeselectOption = (deselectedByUser: T) => {
    deselectOption(deselectedByUser);
    if (onChange !== undefined) {
      onChange(undefined);
    } // update external
  };

  /** *************************
   * Handle displayed options *
   ****************************/
  const createVisibleOptions = (): JSX.Element[] => {
    const optionsToDisplay: JSX.Element[] = [];
    // Fill display options after they have been filtered
    allOptions.forEach(opt => {
      optionsToDisplay.push(
        ConsoleSingleSelectOption({
          key: `${className ? `${className}-` : ''}console-single-select-${opt.value}`,
          option: opt,
          onSelectOption,
          onDeselectOption,
          isSelected: isOptionSelected(opt.value),
          selectedIcon,
        }),
      );
    });
    return optionsToDisplay;
  };

  /** ***************
   * Main component *
   ******************/
  return (
    <div
      className={className}
      key={`${className ? `${className}-` : ''}single-select-holder`}
      css={css`
        padding: 10px;
      `}
    >
      {description && (
        <Label
          size={'sm'}
          color={themeMap.foregroundAccent}
          css={css`
            padding-block-end: 10px;
          `}
        >
          {description}
        </Label>
      )}
      <div
        className={'Options'}
        key={`${className ? `${className}-` : ''}single-select-options`}
        css={css`
          max-height: 30rem;
          overflow-y: auto;
          width: 100%;
        `}
      >
        {createVisibleOptions()}
      </div>
    </div>
  );
};

export default ConsoleSingleSelect;
