import { Styleable } from '~/neo-ui/model/capacity';
import * as React from 'react';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import useClipboard from '~/extensions/packages/clipboard/hooks/useClipboard';
import AccountTeamMemberEditWindow from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/packages/people-dashboard-dropdown/packages/people-dashboard-dropdown-account-team-member/packages/account-team-member-edit-window/AccountTeamMemberEditWindow';
import { OrganizationAccountTeamMemberDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationAccountTeamMember/Dto/Model.gen';
import formatUserNameDisplay from '~/wm/packages/account/packages/user/packages/active-users-search/formatUserNameDisplay';

export type PeopleDashboardDropdownOptionAccountTeamMemberProps = {
  organizationAccountTeamMember: OrganizationAccountTeamMemberDto;
  hasManageMembersPermission: boolean;
  onClickEdit: () => void;
} & Styleable;

const PeopleDashboardDropdownOptionAccountTeamMember: React.FunctionComponent<PeopleDashboardDropdownOptionAccountTeamMemberProps> = ({
  organizationAccountTeamMember,
  hasManageMembersPermission,
  onClickEdit,
}) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const { copyToClipboard } = useClipboard();
  const copyEmail = React.useCallback(() => {
    if (typeof organizationAccountTeamMember.email !== 'undefined') {
      copyToClipboard(organizationAccountTeamMember.email);
    }
  }, [copyToClipboard, organizationAccountTeamMember.email]);

  return (
    <React.Fragment>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 1rem;
          `}
        >
          <Icon
            icon={'Administrator'}
            color={'dark-900'}
          />
          <Label
            size={'md'}
            css={css`
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
            color={'dark-900'}
          >
            {formatUserNameDisplay(organizationAccountTeamMember)}
          </Label>
          {typeof organizationAccountTeamMember !== 'undefined' && (
            <Icon
              css={css`
                margin-left: auto;
                cursor: pointer;
              `}
              icon={'Edit'}
              color={hasManageMembersPermission ? 'primary-400' : 'dark-900-32'}
              onClick={() => {
                if (hasManageMembersPermission) {
                  setModalOpen(true);
                  onClickEdit();
                }
              }}
              preventOnClickPropagation={true}
            />
          )}
        </div>

        <Label
          size={'sm'}
          color={'dark-900-64'}
        >
          {organizationAccountTeamMember.role ?? 'Role'}
        </Label>

        {typeof organizationAccountTeamMember.email !== 'undefined' && (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.3125rem;
              cursor: pointer;

              .tooltip-target {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            `}
            onClick={() => {
              copyEmail();
            }}
          >
            <Tooltip
              content={organizationAccountTeamMember.email}
              backgroundColor={'light-000'}
              placement={'top'}
            >
              <Label
                css={css`
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                `}
                size={'sm'}
                color={'dark-900-64'}
              >
                {organizationAccountTeamMember.email}
              </Label>
            </Tooltip>

            <Icon
              css={css`
                margin-left: auto;
              `}
              icon={'Copy'}
              color={'primary-400'}
            />
          </div>
        )}
      </div>
      {isModalOpen && typeof organizationAccountTeamMember !== 'undefined' && (
        <AccountTeamMemberEditWindow
          organizationAccountTeamMember={organizationAccountTeamMember}
          isOpen={isModalOpen}
          onDismiss={() => {
            setModalOpen(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PeopleDashboardDropdownOptionAccountTeamMember;
