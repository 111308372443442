import { useCallback, useEffect } from 'react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';

const debouncify = (action: () => Promise<void>, debounceMs: number) => {
  let timeout: NodeJS.Timeout | null;
  return (): Promise<void> =>
    new Promise(resolve => {
      const later = () => {
        timeout = null;
        action().then(resolve);
      };
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(later, debounceMs);
    });
};

export type FormAutoSubmitHandlerProps<T> = {
  disabled?: boolean;
  debounceMs?: number;
  onSubmitting?: (formData: T) => void;
  onSubmitted?: () => void;
};

const FormAutoSubmitHandler = <T,>({ disabled = false, debounceMs = 500, onSubmitting, onSubmitted }: FormAutoSubmitHandlerProps<T>) => {
  const { dirty, formData, submitForm, setSubmitting } = useFormContext<T>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(debouncify(submitForm, debounceMs), [debounceMs, submitForm]);

  // Trigger auto-submit on value change
  useEffect(() => {
    (async () => {
      if (dirty && !disabled) {
        setSubmitting(true);

        if (onSubmitting) {
          onSubmitting(formData);
        }
        await debouncedSubmit();
        if (onSubmitted) {
          onSubmitted();
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, debouncedSubmit, formData]);

  return null;
};

export default FormAutoSubmitHandler;
