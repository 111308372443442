import { RegionalSettingsAvailabilities } from '@AssetManagementClient/BeastClient/Beast/RegionalSettings/Model/Availabilities.gen';
import { SettingsValueNested } from '@AssetManagementClient/BeastClient/Beast/Settings/Model.gen';
import * as React from 'react';
import FormSelectInput, { FormSelectOption } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import { css } from '@emotion/react';

export type FormatSettingsFormProps = {
  availabilities: RegionalSettingsAvailabilities | undefined;
  disabled?: boolean;
};

const FormatSettingsForm: React.FunctionComponent<FormatSettingsFormProps> = ({ availabilities, disabled = false }) => (
  <div>
    <LayoutSection
      title="Display formats"
      description="Customize how prices and dates appear to match your client’s region."
      titleSize={4}
      titleWeight={'bold'}
      css={css`
        margin-bottom: 0.625rem;
      `}
    >
      <LayoutSection
        title="Display currency"
        description="This currency is used to display prices for this client's reports. Transactions are always processed in USD."
        titleSize={5}
        titleWeight={'bold'}
        css={css`
          margin-bottom: 0.9375rem;
        `}
      >
        <FormSelectInput
          fieldKey={(settings: FieldKeyExpressionSegment<SettingsValueNested.RegionalSettings>) => settings.value.displayCurrency}
          disabled={disabled}
          options={
            availabilities?.displayCurrencies.map(
              ({ codeAlpha, name, symbol }): FormSelectOption => ({
                label: `${codeAlpha} - ${name} (${symbol})`,
                value: codeAlpha,
              }),
            ) ?? []
          }
        />
      </LayoutSection>
      <LayoutSection
        title="Date"
        description="This date is used in reports."
        titleSize={5}
        titleWeight={'bold'}
      >
        <FormSelectInput
          fieldKey={(settings: FieldKeyExpressionSegment<SettingsValueNested.RegionalSettings>) => settings.value.dateFormat}
          disabled={disabled}
          options={
            availabilities?.dateFormats.map(
              ({ enum: value, label }): FormSelectOption => ({
                label,
                value,
              }),
            ) ?? []
          }
        />
      </LayoutSection>
    </LayoutSection>
    <LayoutSection
      title="Fiscal year"
      description="Choose when the fiscal year begins (Q1)."
      titleSize={4}
      titleWeight={'bold'}
      css={css`
        margin-top: 1rem;
        margin-bottom: 0.625rem;
      `}
    >
      <LayoutSection
        title="Starting month"
        titleSize={5}
        titleWeight={'bold'}
      >
        <FormSelectInput
          fieldKey={(settings: FieldKeyExpressionSegment<SettingsValueNested.RegionalSettings>) => settings.value.fiscalYearStartingMonth}
          disabled={disabled}
          options={
            availabilities?.fiscalYearStartingMonthOptions.map(({ value, displayLabel }) => ({
              label: displayLabel,
              value: value.toString(),
            })) ?? []
          }
        />
      </LayoutSection>
    </LayoutSection>
  </div>
);

export default FormatSettingsForm;
