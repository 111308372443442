import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import * as React from 'react';
import { css } from '@emotion/react';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import ToggleTileInternal, { ToggleTileProps } from '~/neo-ui/packages/toggle/packages/toggle-tile/ToggleTileInternal';
import TextBlock from '~/neo-ui/packages/text/packages/text-block/TextBlock';

const ToggleTileHorizontal = ({
  className,
  isSelected = false,
  icon,
  title,
  description,
  onClick,
  tooltipContent,
  appendComponent,
  type,
  alert,
  disabled,
  boxShadow,
}: ToggleTileProps) => (
  <ToggleTileInternal
    className={className}
    isSelected={isSelected}
    onClick={onClick}
    type={type}
    disabled={disabled}
    boxShadow={boxShadow}
    css={css`
      gap: 1rem;
    `}
  >
    {toggle => (
      <React.Fragment>
        {toggle}
        <div
          css={css`
            flex-grow: 1;
          `}
        >
          <TextBlock
            title={title}
            description={description}
            icon={icon}
            titleColor={disabled ? 'dark-900-64' : isSelected ? 'primary-400' : undefined}
            iconColor={disabled ? 'dark-900-64' : isSelected ? 'primary-400' : undefined}
          />
          {typeof alert !== 'undefined' && (
            <Label
              color={alert.color ?? 'secondary-400'}
              bold={true}
              css={css`
                margin-top: 0.25rem;
                margin-left: 2.5rem;
              `}
            >
              {alert.text}
            </Label>
          )}
        </div>
        {tooltipContent && (
          <Tooltip
            backgroundColor={'light-000'}
            content={tooltipContent}
            placement={'top'}
            tooltipCss={css`
              width: 16rem;
            `}
          >
            <Icon
              icon={'Info'}
              sizePx={16}
              color={'dark-900-32'}
              css={css`
                display: flex;
              `}
            />
          </Tooltip>
        )}
        {typeof appendComponent !== 'undefined' && appendComponent}
      </React.Fragment>
    )}
  </ToggleTileInternal>
);

export default ToggleTileHorizontal;
