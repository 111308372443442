import * as React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { ActiveUser } from '@SsoClient//BeastClient/Beast/Identity/Model/User.gen';
import ActiveUsersContext from './ActiveUsersContext';
import ActiveUsersMutationContext from './ActiveUsersMutationContext';
import { identityUserActiveListGet } from '@SsoClient/SsoClientMsp.gen';

const ActiveUsersProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const [users, setUsers] = React.useState<ActiveUser[]>();

  const { callApi } = useApi();

  const reload = React.useCallback(
    async (isRequestActive?: () => boolean) => {
      await (async () => {
        const response = await callApi(() => identityUserActiveListGet({}));

        if (!response) {
          return;
        }

        if (!isRequestActive || isRequestActive()) {
          setUsers(response.activeUsers);
        }
      })();
    },
    [callApi],
  );

  React.useEffect(() => {
    reload();
  }, [reload]);

  if (typeof users === 'undefined') {
    return null;
  }

  return (
    <ActiveUsersMutationContext.Provider
      value={{
        triggerActiveUsersReload: isRequestActive => reload(isRequestActive),
      }}
    >
      <ActiveUsersContext.Provider
        value={{
          users,
        }}
      >
        {children}
      </ActiveUsersContext.Provider>
    </ActiveUsersMutationContext.Provider>
  );
};

export default ActiveUsersProvider;
