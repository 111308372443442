import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { ScaleType } from 'recharts/types/util/types';

export type Dot = {
  x: number | string;
  y: number;
};
export type AreaLineChartProps = {
  dots: Dot[];
  areaColor: Color;
  strokeColor: Color;
  xAxisLabel?: string;
  yAxisLabel?: string;
  type?: 'number' | 'category';
  scale?: ScaleType;
  tickFormatter?: (t: number) => string;
  tooltipFormatter?: (t: number) => string;
} & Styleable;

const AreaLineChart: React.FunctionComponent<AreaLineChartProps> = ({
  dots,
  areaColor = 'light-400',
  strokeColor = 'light-900',
  xAxisLabel = 'x',
  yAxisLabel = 'y',
  type = 'number',
  scale = 'auto',
  tickFormatter,
  tooltipFormatter,
}) => {
  let xType = type;

  if (dots.length === 0) {
    return <div />;
  }
  if (dots.length === 1) {
    dots.push(dots[0]);
    xType = 'category';
  }

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
    >
      <AreaChart
        data={dots}
        margin={{ top: 30, right: 35, left: 0, bottom: 10 }}
      >
        <XAxis
          dataKey="x"
          name={xAxisLabel}
          type={xType}
          scale={scale}
          domain={['dataMin', 'dataMin']}
          tickFormatter={tickFormatter}
        />
        <YAxis />
        <Tooltip
          labelFormatter={tooltipFormatter}
          wrapperStyle={{ outline: 'none' }}
        />
        <Area
          type="monotone"
          dataKey="y"
          name={yAxisLabel}
          fillOpacity={1}
          fill={colorToCode(areaColor)}
          stroke={colorToCode(strokeColor)}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaLineChart;
