import { initiativeTemplateDelete } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import { useCallback } from 'react';

export type UseInitiativeTemplateDelete = () => {
  /**
   * Delete an Initiative template from the Account list
   * @param initiativeTemplateId Initiative template to delete
   */
  deleteTemplate: (initiativeTemplateId: string) => void;
};

const useInitiativeTemplateDelete: UseInitiativeTemplateDelete = () => {
  const { callApi } = useApi();

  const deleteTemplate = useCallback(
    async (initiativeTemplateId: string) => {
      await callApi(() => initiativeTemplateDelete({ initiativeTemplateId }));
    },
    [callApi],
  );

  return { deleteTemplate };
};

export default useInitiativeTemplateDelete;
