import { HardwareReplacementSettingsAvailabilities } from '@AssetManagementClient/BeastClient/Beast/HardwareReplacement/Model/Availabilities.gen';
import { SettingsValueNested } from '@AssetManagementClient/BeastClient/Beast/Settings/Model.gen';
import { Enum, Enum as HardwareTypeEnum } from '@AssetManagementClient/BeastClient/Goods/DeviceType/DeviceTypeFactoryNested.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import { camelToPascal } from '~/extensions/packages/casing/camelPascalConversion';

import FormSelectInput, { FormSelectOption } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import FormTextInput from '~/neo-ui/packages/form/packages/form-input/packages/form-text-input/FormTextInput';
import { FieldKeyExpression, FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import HardwareReplacementCard from '~/wm/packages/hardware/packages/hardware-replacement-card/HardwareReplacementCard';
import { RecommendationCriterionProps } from '~/wm/packages/hardware/packages/settings/packages/recommendation-criterion/RecommendationCriterion';
import DataTable, { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';

export type HardwareTypeAvailabilityDto = {
  hardwareTypeEnum: HardwareTypeEnum;
  replacementValue: number;
  replacementAgeRange: number[];
  supportMemoryThreshold: boolean;
  supportStorageThreshold: boolean;
};

export type HardwareSettingsFormProps = {
  availabilities: HardwareReplacementSettingsAvailabilities;
  currencyCharacter: string;
  disabled?: boolean;
  hardwareConfigCriteriaReleased: boolean;
};

/**
 * @deprecated by HardwareReplacementSettingsFormV2
 * Should be removed when lm-budget-forecast is released
 */
const HardwareReplacementSettingsForm: React.FunctionComponent<HardwareSettingsFormProps> = ({
  availabilities,
  currencyCharacter,
  disabled = false,
  hardwareConfigCriteriaReleased,
}) => {
  const hardwareTypes = Object.keys(availabilities.hardwareTypeAvailabilities ?? {}).sort() as HardwareTypeEnum[];

  const hardwareTypeAvailabilities: HardwareTypeAvailabilityDto[] = hardwareTypes
    .map(type => {
      const typeEnum: HardwareTypeEnum = type;
      const availability = availabilities.hardwareTypeAvailabilities[typeEnum];
      return {
        hardwareTypeEnum: typeEnum,
        replacementValue: 0,
        replacementAgeRange: availability.replacementAgeRange,
        supportMemoryThreshold: availability.supportStorageThreshold,
        supportStorageThreshold: availability.supportStorageThreshold,
      };
    })
    .sort();

  const disabledValue = 'DISABLED';

  const formTableColumns: DataTableColumn<HardwareTypeAvailabilityDto>[] = [
    {
      Header: 'Hardware type',
      fieldKey: hardwareTypeAvailability => hardwareTypeAvailability.hardwareTypeEnum,
      renderCell: hardwareTypeAvailability => (
        <div
          css={css`
            display: flex;
            align-items: center;
            height: 1.875rem;
          `}
        >
          {((): string => {
            switch (
              // Because of camelization it breaks these enums.
              // TODO https://warrantymaster.myjetbrains.com/youtrack/issue/WM-1555
              camelToPascal(hardwareTypeAvailability.hardwareTypeEnum) as HardwareTypeEnum
            ) {
              // This will come from the factory in the future.
              case Enum.Imaging:
                return 'Imaging';
              case Enum.Mobile:
                return 'Mobile';
              case Enum.Network:
                return 'Network';
              case Enum.Server:
                return 'Server';
              case Enum.Storage:
                return 'Storage';
              case Enum.Virtual:
                return 'Virtual';
              case Enum.Workstation:
                return 'Workstation';
            }
          })()}
        </div>
      ),
    },
    {
      Header: 'Replacement value',
      fieldKey: hardwareTypeAvailability => hardwareTypeAvailability.replacementValue,
      renderCell: hardwareTypeAvailability => (
        <FormTextInput
          fieldKey={(settings: FieldKeyExpressionSegment<SettingsValueNested.HardwareReplacementSettings>) =>
            settings.value.deviceTypePreferences[hardwareTypeAvailability.hardwareTypeEnum].replacementValue
          }
          placeholder="Money value"
          prependCharacter={currencyCharacter}
          disabled={disabled}
        />
      ),
    },
    {
      Header: 'Maximum replacement age',
      fieldKey: hardwareTypeAvailability => hardwareTypeAvailability.replacementAgeRange,
      renderCell: hardwareTypeAvailability => {
        const fieldKey: FieldKeyExpression<SettingsValueNested.HardwareReplacementSettings> = settings =>
          settings.value.deviceTypePreferences[hardwareTypeAvailability.hardwareTypeEnum].mspRetirementValues.assetAgeThreshold;

        return !availabilities ||
          availabilities.hardwareTypeAvailabilities[hardwareTypeAvailability.hardwareTypeEnum].replacementAgeRange.length === 0 ? (
          <div />
        ) : (
          <FormSelectInput<SettingsValueNested.HardwareReplacementSettings, number | undefined>
            fieldKey={fieldKey}
            disabled={disabled}
            fromFormData={value => (typeof value === 'undefined' ? disabledValue : value.toString())}
            toFormData={value => (value === disabledValue ? undefined : parseInt(value, 10))}
            options={[
              ...[
                {
                  label: 'Disabled',
                  value: disabledValue,
                },
              ],
              ...availabilities.hardwareTypeAvailabilities[hardwareTypeAvailability.hardwareTypeEnum].replacementAgeRange.map(
                (age): FormSelectOption => ({
                  label: `${age} years`,
                  value: age.toString(),
                }),
              ),
            ]}
          />
        );
      },
    },
  ];

  const availableCriteriaByHardwareType: Map<Enum, RecommendationCriterionProps[]> = new Map<Enum, RecommendationCriterionProps[]>();

  hardwareTypes.map(hardwareType => {
    const inputs: RecommendationCriterionProps[] = [];
    if (availabilities.hardwareTypeAvailabilities[hardwareType].replacementAgeRange.length > 0) {
      inputs.push({
        recommendationValueFieldKey: (settings: FieldKeyExpressionSegment<SettingsValueNested.HardwareReplacementSettings>) =>
          settings.value.deviceTypePreferences[hardwareType].mspRetirementValues.assetAgeThreshold,
        type: 'select',
        selectOptions: [
          ...availabilities.hardwareTypeAvailabilities[hardwareType].replacementAgeRange.map(
            (age): FormSelectOption => ({
              label: `${age} years`,
              value: age.toString(),
            }),
          ),
        ],
        title: 'Age',
        description: 'Warranty expiry date applies if later than this age',
        icon: 'Clock',
        thresholdLabel: 'Max.',
      });
    }

    if (availabilities.hardwareTypeAvailabilities[hardwareType].supportMemoryThreshold) {
      inputs.push({
        recommendationValueFieldKey: (settings: FieldKeyExpressionSegment<SettingsValueNested.HardwareReplacementSettings>) =>
          settings.value.deviceTypePreferences[hardwareType].mspRetirementValues.assetMemoryThreshold,
        type: 'threshold',
        title: 'Memory',
        icon: 'Memory',
        thresholdLabel: 'Less than',
        thresholdUnit: 'GB',
      });
    }

    if (availabilities.hardwareTypeAvailabilities[hardwareType].supportStorageThreshold) {
      inputs.push({
        recommendationValueFieldKey: (settings: FieldKeyExpressionSegment<SettingsValueNested.HardwareReplacementSettings>) =>
          settings.value.deviceTypePreferences[hardwareType].mspRetirementValues.assetStorageThreshold,
        type: 'threshold',
        title: 'Storage',
        icon: 'Storage',
        thresholdLabel: 'Less than',
        thresholdUnit: 'GB',
      });
    }

    availableCriteriaByHardwareType.set(hardwareType, inputs);
  });

  return (
    <div>
      {hardwareConfigCriteriaReleased ? (
        [...availableCriteriaByHardwareType.entries()].map(([hardwareType, availableCriteria]) => (
          <HardwareReplacementCard
            key={hardwareType}
            replacementValueFieldKey={(settings: FieldKeyExpressionSegment<SettingsValueNested.HardwareReplacementSettings>) =>
              settings.value.deviceTypePreferences[hardwareType].replacementValue
            }
            currencyCharacter={'$'}
            hardwareType={hardwareType}
            availableCriteria={availableCriteria}
          />
        ))
      ) : (
        <DataTable
          columns={formTableColumns}
          data={hardwareTypeAvailabilities}
          EmptyStatePlaceholder={''}
        />
      )}
    </div>
  );
};

export default HardwareReplacementSettingsForm;
