import { createContext } from 'react';
import { ActiveUser } from '@SsoClient/BeastClient/Beast/Identity/Model/User.gen';

export type ActiveUsersContextData = {
  users: ActiveUser[];
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ActiveUsersContext = createContext<ActiveUsersContextData | undefined>(undefined);

export default ActiveUsersContext;
