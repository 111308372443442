import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import * as React from 'react';
import useAccount from '~/wm/hooks/useAccount';
import InitiativeViewFeesOneTime from '~/wm/packages/strategy/packages/initiative/packages/initiative-view/packages/initiative-view-fees-section/packages/initiative-view-fees-one-time/InitiativeViewFeesOneTime';
import InitiativeViewFeesRecurring from '~/wm/packages/strategy/packages/initiative/packages/initiative-view/packages/initiative-view-fees-section/packages/initiative-view-fees-recurring/InitiativeViewFeesRecurring';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import Box from '~/neo-ui/packages/box/Box';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

const InitiativeViewFeesSection = () => {
  const { currencyCharacter, currencyCode } = useAccount();

  return (
    <Box
      padding={'padding300'}
      borderRadius={'radius300'}
      css={css`
        background-color: ${colorToCode('light-100')};
      `}
    >
      <LayoutSection
        title={'Budget'}
        titleSize={4}
        titleWeight={'bold'}
      >
        <div>
          <Label
            muted={true}
            size={'md'}
          >
            {`Displayed in ${currencyCode} (${currencyCharacter})`}
          </Label>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            gap: 2rem;
          `}
        >
          <InitiativeViewFeesOneTime />
          <InitiativeViewFeesRecurring />
        </div>
      </LayoutSection>
    </Box>
  );
};

export default InitiativeViewFeesSection;
