import { css } from '@emotion/react';
import * as React from 'react';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import OutsideClickHandler from '~/neo-ui/packages/interactions/packages/outside-click-handler/OutsideClickHandler';
import { NotificationListModel } from '~/wm/packages/notification/hooks/useNotificationList';
import NotificationList from '~/wm/packages/notification/packages/notification-list/NotificationList';

export type NotificationTooltipProps = {
  notifications: NotificationListModel;
  onMarkAsRead: (notificationId: string) => void;

  isOpen: boolean;
  onClose: () => void;
};

const NotificationTooltip: React.FunctionComponent<React.PropsWithChildren<NotificationTooltipProps>> = ({
  notifications,
  onMarkAsRead,
  isOpen,
  onClose,
  children,
}) => {
  const contentRef = React.useRef(null);
  const tooltipContentRef = React.useRef(null);

  return (
    <OutsideClickHandler
      refs={[contentRef, tooltipContentRef]}
      onClickOutside={() => onClose()}
    >
      <Tooltip
        isActive={isOpen}
        backgroundColor={'light-000'}
        placement={'bottom'}
        content={
          <div
            ref={tooltipContentRef}
            css={css`
              margin-top: 0.25rem;
            `}
          >
            <NotificationList
              notifications={notifications}
              onMarkAsRead={onMarkAsRead}
            />
          </div>
        }
      >
        <div ref={contentRef}>{children}</div>
      </Tooltip>
    </OutsideClickHandler>
  );
};

export default NotificationTooltip;
