import { getCountries, getSubdivisions } from '~/neo-ui/model/Country';
import TextField from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/form-inputs/TextField';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import { PurchaseSubscriptionForm, BillingInfo } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import useApi from '~/wm/packages/api/hook/useApi';
import { subscriptionBillingAddress } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { Request as AccountBillingAddressUpsertRequest } from '@SubscriptionClient/Subscription/Packages/Upgrade/Controller/AccountBillingAddressUpsertNested.gen';
import { Discriminant } from '@SubscriptionClient/Primitives/Results/EmptyResponse_/PublicError_/ResultNested.gen';
import { useState } from 'react';
import { object, string, ValidationError } from 'yup';
import Message from '~/sp-ui/message';
import Dropdown from '~/sp-ui/inputs/dropdown';
import Button from '~/sp-ui/buttons/Button';

export const billingAddressSchema = object().shape({
  name: string().max(250, 'Must be 250 characters or less.').required('Business Name cannot be empty.'),
  address: object({
    line1: string().max(150, 'Must be 150 characters or less.').required('Address cannot be empty.'),
    line2: string().max(150, 'Must be 150 characters or less.').ensure(),
    city: string().max(50, 'Must be 50 characters or less.').required('City cannot be empty.'),
    zip: string().max(20, 'Must be 20 characters or less.').required('ZIP/Postal Code cannot be empty.'),
    stateCode: string().required('State/Province/Region cannot be empty.'),
  }),
});

const lowercaseStrings = (items: string[]): string[] => {
  return items.map(item => {
    return item.charAt(0).toLowerCase() + item.slice(1);
  });
};

const BillingSection = (props: { submitLabel: string; onSubmit: () => void }) => {
  const { callApi } = useApi();
  const { submitLabel, onSubmit } = props;
  const { formik } = useMultiStepForm<PurchaseSubscriptionForm>();
  const [billingError, setBillingError] = useState<string | undefined>(undefined);
  const errorInitState = {
    'address.line1': undefined,
    'address.line2': undefined,
    'address.city': undefined,
    'address.zip': undefined,
    'address.stateCode': undefined,
    'address.countryCode': undefined,
    name: undefined,
  };
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({ ...errorInitState });
  const [isCountrySelected, setIsCountrySelected] = useState<boolean>(false);

  const resetValidation = (field: string) => {
    const newErrors = { ...errors };
    newErrors[field] = undefined;
    setErrors({ ...newErrors });
  };

  const validateBillingAddress = (billingAddress: BillingInfo): boolean => {
    try {
      billingAddressSchema.validateSync(billingAddress, { abortEarly: false });
    } catch (ex) {
      const error = ex as ValidationError;
      const newError = { ...errors };
      error.inner.map((errorItem: ValidationError) => {
        newError[errorItem.path] = errorItem.message;
      });
      setErrors(newError);
      return false;
    }
    return true;
  };

  const handleBillingSubmit = async () => {
    if (!formik.values.billingAddress) {
      return;
    }

    // Validation
    const newError = { ...errorInitState };
    setErrors(newError);
    if (!validateBillingAddress(formik.values.billingAddress)) {
      return;
    }
    const country = getCountries({ byAlpha2: formik.values.billingAddress?.address.countryFallback });
    const request: AccountBillingAddressUpsertRequest = {
      address: {
        ...formik.values.billingAddress.address,
        stateFallback: '',
        countryFallback: '',
        countryCode: country.length > 0 ? country[0].alpha2 : '',
        stateCode: formik.values.billingAddress.address.stateCode,
      },
      name: formik.values.billingAddress.name,
    };

    const response = await callApi(() => subscriptionBillingAddress(request));

    if (!response) {
      setBillingError('We were unable to save your billing address due to a system error. Please try again later.');
    } else if (response.case === Discriminant.Error) {
      const error = response.value;
      if (error.fieldErrors && error.fieldErrors.length > 0) {
        const newError = { ...errors };
        error.fieldErrors.map(field => {
          const key = lowercaseStrings(field.key.split('.')).join('.');
          newError[key] = field.message;
        });
        setErrors(newError);
      }
      setBillingError(error.messagePublic);
    } else {
      setBillingError(undefined);
      formik.setFieldValue('isBillingAddressSet', true);
      formik.setFieldValue('isTaxSet', true);
      onSubmit();
    }
  };
  const provinceStates = getSubdivisions({ countryCode: formik.values.billingAddress?.address.countryFallback });
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        backgroundColor: 'white',
        borderRadius: '0.25rem',
        marginTop: '1rem',
      }}
    >
      <TextField
        name="billingAddress.name"
        label={'Business Name'}
        id="billingAddress-name"
        errorMsg={errors.name}
        resetValidation={() => resetValidation('name')}
        sizePx={574}
      />
      <div
        css={{
          marginTop: '1rem',
        }}
      >
        <TextField
          name="billingAddress.address.line1"
          label={'Address'}
          id="billingAddress-address.line1"
          placeholder="Address line 1"
          errorMsg={errors['address.line1']}
          resetValidation={() => resetValidation('address.line1')}
          sizePx={574}
        />
        <div
          css={{
            marginTop: '0.5rem',
          }}
        >
          <TextField
            name="billingAddress.address.line2"
            id="billingAddress-address.line2"
            placeholder="Address line 2 (optional)"
            errorMsg={errors['address.line2']}
            resetValidation={() => resetValidation('address.line2')}
            sizePx={574}
          />
        </div>
      </div>

      <div
        css={{
          marginTop: '1rem',
          width: '20rem',
        }}
      >
        <Dropdown
          value={formik.values.billingAddress?.address.countryFallback}
          placeholder="– Select a country –"
          name="countryCode"
          label="Country"
          options={getCountries()
            .sort((a, b) => {
              const topCountries = ['US', 'CA'];

              if (topCountries.includes(a.alpha2) && !topCountries.includes(b.alpha2)) {
                return -1;
              } else if (!topCountries.includes(a.alpha2) && topCountries.includes(b.alpha2)) {
                return 1;
              } else if (topCountries.includes(a.alpha2) && topCountries.includes(b.alpha2)) {
                return a.alpha2.localeCompare(b.alpha2);
              }

              return a.name.localeCompare(b.name);
            })
            .map(({ name, alpha2 }) => ({
              label: name,
              value: alpha2,
            }))}
          onChange={option => {
            setIsCountrySelected(!!option.value);
            formik.setFieldValue('billingAddress.address.countryFallback', option.value);
            formik.setFieldValue('billingAddress.address.stateCode', '');
            formik.setFieldValue('billingAddress.address.zip', '');
          }}
          css={{ maxWidth: '320px' }}
          compact={true}
          errorSubtext={errors['address.countryFallback']}
        />
      </div>
      <div
        css={{
          marginTop: '1rem',
        }}
      >
        <TextField
          name="billingAddress.address.city"
          label={'City'}
          id="billingAddress-address-city"
          errorMsg={errors['address.city']}
          resetValidation={() => resetValidation('address.city')}
          sizePx={320}
        />
      </div>
      <div
        css={{
          marginTop: '1rem',
        }}
      >
        <Dropdown
          value={formik.values.billingAddress?.address.stateCode}
          name="stateCode"
          label="State/Province/Region"
          options={provinceStates.map(({ name, subdivisionCode }) => {
            return {
              label: name,
              value: subdivisionCode,
            };
          })}
          onChange={option => {
            formik.setFieldValue('billingAddress.address.stateCode', option.value);
          }}
          placeholder={isCountrySelected ? '– Select –' : undefined}
          disabled={!isCountrySelected}
          css={{ maxWidth: '320px' }}
          compact={true}
          errorSubtext={errors['address.stateCode']}
        />
      </div>
      <div
        css={{
          marginTop: '1rem',
        }}
      >
        <TextField
          name="billingAddress.address.zip"
          label={'ZIP/Postal Code'}
          id="billingAddress-address-zip"
          errorMsg={errors['address.zip']}
          resetValidation={() => resetValidation('address.zip')}
          sizePx={120}
        />
      </div>

      {billingError && (
        <Message
          css={{ marginTop: 32 }}
          text={billingError}
          type="error"
        />
      )}

      <Button
        ref={formik.values.submitBillingAddress}
        css={{ marginTop: '2rem', width: '13.313rem' }}
        onClick={handleBillingSubmit}
      >
        {submitLabel}
      </Button>
    </div>
  );
};
export default BillingSection;
