import * as React from 'react';
import ContractEditWindow from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-list-table/packages/contract-edit-vendor-table-cell/packages/contract-edit-window-form-wrapper/packages/contract-edit-window/ContractEditWindow';
import ContractEditForm from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-list-table/packages/contract-edit-vendor-table-cell/packages/contract-edit-window-form-wrapper/packages/contract-edit-form/ContractEditForm';
import useContract from '~/wm/packages/strategy/packages/contract/packages/contract-list/hooks/useContract';

export type ContractEditWindowFormWrapperProps = {
  contractId: string;
  organizationId: string;
  onDismiss: () => void;
  isOpen: boolean;
  onSave?: () => void;
};

const ContractEditWindowFormWrapper: React.FunctionComponent<ContractEditWindowFormWrapperProps> = ({
  contractId,
  organizationId,
  onDismiss,
  isOpen,
  onSave,
}) => {
  const { isLoadingContract, contract } = useContract(organizationId, contractId);

  if (isLoadingContract || typeof contract === 'undefined') {
    return null;
  }

  return (
    <ContractEditForm
      contract={contract}
      onContractUpdate={() => {
        if (typeof onSave !== 'undefined') {
          onSave();
        }
        onDismiss();
      }}
    >
      <ContractEditWindow
        organizationId={organizationId}
        contractId={contractId}
        isOpen={isOpen}
        onDismiss={() => onDismiss()}
      />
    </ContractEditForm>
  );
};

export default ContractEditWindowFormWrapper;
