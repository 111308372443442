import * as React from 'react';
import EngagementActionDataCollectionContext from './EngagementActionDataCollectionContext';

export type EngagementActionDataCollectionProviderProps = {
  createdFrom: string;
  completedFrom: string;
  updatedFrom: string;
};

const EngagementActionDataCollectionProvider: React.FunctionComponent<
  React.PropsWithChildren<EngagementActionDataCollectionProviderProps>
> = ({ createdFrom, completedFrom, updatedFrom, children }) => (
  <EngagementActionDataCollectionContext.Provider
    value={{
      createdFrom,
      completedFrom,
      updatedFrom,
    }}
  >
    {children}
  </EngagementActionDataCollectionContext.Provider>
);

export default EngagementActionDataCollectionProvider;
