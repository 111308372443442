import { css } from '@emotion/react';
import * as React from 'react';
import ListItem from '~/legacy-ui/packages/list/packages/list-item/ListItem';
import Workflow from '~/legacy-ui/packages/operation/model/Workflow';
import Card from '~/legacy-ui/packages/card/Card';
import { themeToColor } from '~/neo-ui/packages/color/Theme';

const sensitivityWorkflow: Workflow = async ({ operation, showModal, substituteContext }, next) => {
  if (operation.sensitivity === 'none') {
    return next();
  }
  const sensitivity = operation.sensitivity;
  return new Promise(resolve =>
    showModal({
      title: substituteContext(operation.label),
      description: substituteContext<React.ReactNode>(operation.description),
      onConfirm: () => next().then(resolve),
      theme: sensitivity.theme,
      footerButtons: {
        confirm: {
          icon: operation.icon,
          text: substituteContext(sensitivity.confirmLabel),
        },
      },
      children: operation.actions.length ? (
        <div
          css={css`
            > *:not(:last-child) {
              margin-bottom: 0.625rem;
            }
          `}
        >
          {substituteContext(operation.actions).map((action, index) => (
            <Card
              key={index}
              padding="sm"
              backgroundColor={themeToColor(sensitivity.theme, '400')}
              shadow="none"
            >
              <ListItem
                theme={'light-000'}
                title={action.label}
                description={action.description}
                icon={operation.icon}
              />
            </Card>
          ))}
        </div>
      ) : null,
    }),
  );
};

export default sensitivityWorkflow;
