import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type LabelSize = 'sm' | 'md' | 'lg' | 'xlg';

const getLabelFontSizeRem = (size: LabelSize): number => {
  switch (size) {
    case 'sm':
      return 0.75;
    case 'md':
      return 0.875;
    case 'lg':
      return 1;
    case 'xlg':
      return 1.125;
  }
};

export type LabelHeightSize = 'sm' | 'md';

const getLabelHeightSizeRem = (height: LabelHeightSize): number => {
  switch (height) {
    case 'sm':
      return 1.875;
    case 'md':
      return 2.25;
  }
};

export type LabelProps = {
  size?: LabelSize;
  heightSize?: LabelHeightSize;
  bold?: boolean;
  color?: Color;
  muted?: boolean;
  children: React.ReactNode;
} & Styleable;

const Label: React.FunctionComponent<LabelProps> = ({
  size = 'md',
  heightSize,
  bold = false,
  color,
  muted = false,
  className,
  children,
}) => (
  <span
    className={className}
    css={css`
      display: block;
      font-size: ${getLabelFontSizeRem(size)}rem;
      ${heightSize && `line-height: ${getLabelHeightSizeRem(heightSize)}rem;`}
      margin: 0;
      ${color || muted ? `color: ${colorToCode(color ?? (muted ? 'dark-900-64' : 'dark-900'))};` : ''}
      ${bold ? 'font-weight: bold;' : ''}
    `}
  >
    {children}
  </span>
);

export default Label;
