import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type SingleSelectStyleProps = {
  height?: string;
};

export const customStylesDefault = ({ height = undefined }: SingleSelectStyleProps) => ({
  menu: (provided: Record<string, unknown>) => ({
    ...provided,
    minWidth: '16.875rem',
    padding: '0.625rem',
    borderRadius: '0.625rem',
  }),

  control: (provided: Record<string, unknown>) => ({
    ...provided,
    ...(typeof height !== 'undefined' && {
      minHeight: height,
      height,
    }),
    border: `0.0675rem solid ${colorToCode('dark-900-12')}`,
    borderRadius: '0.375rem',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&:hover': {
      border: `0.0675rem solid ${colorToCode('secondary-300')}`,
      boxShadow: '0 0.0675rem 0.25rem -0.0675rem rgb(0 0 0 / 15%)',
      color: `${colorToCode('dark-700')}`,
      cursor: 'pointer',
    },
  }),

  valueContainer: (provided: Record<string, unknown>) => ({
    ...provided,
    ...(typeof height !== 'undefined' && {
      height,
    }),
    padding: '0 0.375rem',
  }),

  input: (provided: Record<string, unknown>) => ({
    ...provided,
    margin: '0',
  }),

  indicatorsContainer: (provided: Record<string, unknown>) => ({
    ...provided,
    height: '100%',
    padding: '0',
    margin: '0',
    alignItems: 'center',
    justifyContent: 'center',
  }),
});
