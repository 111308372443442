import * as React from 'react';
import { assertNeverOrThrow } from '~/extensions/packages/types/assertNever';
import useOperation from '~/legacy-ui/packages/operation/hooks/useOperation';
import Organization from '~/wm/packages/organization/model/Organization';
import OrganizationLink from '~/wm/packages/organization/packages/linking/model/OrganizationLink';
import OrganizationLinkRename from './packages/rename/OrganizationLinkRename';
import organizationUnlinkOperation from './packages/unlink/operation/organizationUnlinkOperation';
import OrganizationLinkUnlink from './packages/unlink/OrganizationLinkUnlink';

export type OrganizationLinkManageProps = {
  parentOrganization: Organization;
  linkedOrganizations: OrganizationLink[];
};

export type ManageStates = 'Rename' | 'Unlink';

const OrganizationLinkManage: React.FunctionComponent<OrganizationLinkManageProps> = ({ parentOrganization, linkedOrganizations }) => {
  const [manageState, setManageState] = React.useState<ManageStates>('Unlink');
  const unlinkOrganization = useOperation(organizationUnlinkOperation);

  return (
    <>
      {((): React.ReactNode => {
        switch (manageState) {
          case 'Unlink':
            return (
              <OrganizationLinkUnlink
                parentOrganization={parentOrganization}
                linkedOrganizations={linkedOrganizations}
                onRename={() => setManageState('Rename')}
                onUnlink={organization =>
                  unlinkOrganization({
                    targetOrganization: organization,
                    parentOrganization,
                  })
                }
              />
            );

          case 'Rename':
            return (
              <OrganizationLinkRename
                parentOrganization={parentOrganization}
                linkedOrganizations={linkedOrganizations}
                onCancel={() => setManageState('Unlink')}
              />
            );
        }
        assertNeverOrThrow(manageState);
      })()}
    </>
  );
};

export default OrganizationLinkManage;
