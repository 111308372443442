import { ReactNode, useCallback, useMemo } from 'react';
import Form from '~/neo-ui/packages/form/Form';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useAutoEmailReportEmailTemplateUpdate from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/hooks/useAutoEmailReportEmailTemplateUpdate';
import useAutoEmailReportEmailTemplateContext from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/context/hooks/useAutoEmailReportEmailTemplateContext';
import autoEmailReportEmailTemplateEditFormValidationSchema from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/auto-email-report-email-template-edit-form/validation/autoEmailReportEmailTemplateEditFormValidationSchema';

export type AutoEmailReportEmailTemplateEditFormData = {
  replyToUserId: string;
  emailSubject: string;
  emailBody: string;
};

export type AutoEmailReportEmailTemplateEditFormProps = {
  children: ReactNode;
};

const AutoEmailReportEmailTemplateEditForm = ({ children }: AutoEmailReportEmailTemplateEditFormProps) => {
  const { organizationId } = useOrganizationContext();
  const { autoEmailReportEmailTemplate } = useAutoEmailReportEmailTemplateContext();
  const { updateAutoEmailReportEmailTemplate } = useAutoEmailReportEmailTemplateUpdate();

  const onSubmit = useCallback(
    async (data: AutoEmailReportEmailTemplateEditFormData, isRequestActive: () => boolean) => {
      updateAutoEmailReportEmailTemplate(
        {
          organizationId,
          autoEmailReportId: autoEmailReportEmailTemplate.autoEmailReportId,
          autoEmailReportEmailTemplateUpdatePayload: {
            replyToUserId: data.replyToUserId,
            subject: data.emailSubject,
            body: data.emailBody,
          },
        },
        isRequestActive,
      );
    },
    [autoEmailReportEmailTemplate.autoEmailReportId, organizationId, updateAutoEmailReportEmailTemplate],
  );

  const defaultFormData = useMemo<AutoEmailReportEmailTemplateEditFormData>(
    () => ({
      replyToUserId: autoEmailReportEmailTemplate.replyToUserId,
      emailSubject: autoEmailReportEmailTemplate.subject,
      emailBody: autoEmailReportEmailTemplate.body,
    }),
    [autoEmailReportEmailTemplate.body, autoEmailReportEmailTemplate.replyToUserId, autoEmailReportEmailTemplate.subject],
  );

  return (
    <Form
      submitMethod={'manual'}
      hideSubmissionButton={true}
      hideSubmitStatusIndicator={true}
      defaultFormData={defaultFormData}
      validationSchema={autoEmailReportEmailTemplateEditFormValidationSchema}
      onSubmit={onSubmit}
    >
      {children}
    </Form>
  );
};

export default AutoEmailReportEmailTemplateEditForm;
