import { MultiSelectOption } from '~/neo-ui/packages/select/packages/multi-select/MultiSelect';
import RoadmapListViewTable from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-list-view/packages/roadmap-list-view-table/RoadmapListViewTable';
import { css } from '@emotion/react';
import useRoadmapInitiativesContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapInitiativesContext';
import { buildRoadmapInitiativeKey } from '~/wm/packages/strategy/packages/roadmap-page/builder/buildRoadmapInitiativesByFiscalQuarter';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';

export type RoadmapListViewProps = {
  selectedStatuses: MultiSelectOption[];
  selectedPriorities: MultiSelectOption[];
  showUnscheduled: boolean;
};

const RoadmapListView = ({ selectedStatuses, selectedPriorities, showUnscheduled }: RoadmapListViewProps) => {
  const { initiativesByFiscalQuarter, displayFiscalQuarters } = useRoadmapInitiativesContext();
  const { currentFiscalQuarter } = useRegionalSettingsInfoContext();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        // Set overflow to auto to allow the table to scroll, the parent container has overflow-y: hidden
        overflow: auto;
      `}
    >
      {showUnscheduled && (
        <RoadmapListViewTable
          displayYear={displayFiscalQuarters[0].year}
          currentYear={currentFiscalQuarter.year}
          displayInitiatives={initiativesByFiscalQuarter.get(buildRoadmapInitiativeKey(undefined)) ?? []}
          isUnscheduled={true}
          selectedStatuses={selectedStatuses}
          selectedPriorities={selectedPriorities}
        />
      )}
      <RoadmapListViewTable
        displayYear={displayFiscalQuarters[0].year}
        currentYear={currentFiscalQuarter.year}
        displayInitiatives={displayFiscalQuarters
          .map(fiscalQuarter => [...(initiativesByFiscalQuarter.get(buildRoadmapInitiativeKey(fiscalQuarter)) ?? [])])
          .flat()}
        selectedStatuses={selectedStatuses}
        selectedPriorities={selectedPriorities}
      />
    </div>
  );
};

export default RoadmapListView;
