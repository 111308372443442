import { camelToPascal } from '~/extensions/packages/casing/camelPascalConversion';
import { resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { TableState } from '~/neo-ui/packages/table/packages/filter-table/FilterTable';
import { SearchPayload } from '@AssetManagementClient/BeastClient/Primitives/Query/Search.gen';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { PaginationPayload } from '@AssetManagementClient/BeastClient/Primitives/Query/Pagination.gen';
import { Order } from '@AssetManagementClient/BeastClient/Primitives/Query/Sorting.gen';
import { OrderEnum } from '@AssetManagementClient/BeastClient/Primitives/Query/Sorting/OrderNested.gen';

// Code generation doesn't give a fully accurate representation of the request table.
export type QueryRequest<TFilterEnum> = {
  filters: Filter<TFilterEnum>[];
  pagination: PaginationPayload;
  frontendScope: FrontendScope;
  sort: Order[];
  searchPayload: SearchPayload | undefined;
};

export type Filter<TFilterEnum> = {
  include: boolean;
  fieldKey: TFilterEnum;
  values: string[];
};
const buildApiQuery = <T, TFilterEnum extends string>(
  frontendScope: FrontendScope,
  { filters, pagination, sort }: TableState<T>,
): QueryRequest<TFilterEnum> => ({
  filters: filters
    ? [...filters.selectedFilters.entries()].map(
        ([filterValue, selectedOptionValues]): Filter<TFilterEnum> => ({
          fieldKey: filterValue as TFilterEnum,
          include: true,
          values: [...selectedOptionValues],
        }),
      )
    : [],
  pagination: pagination
    ? {
        pageNumber: pagination.pageNumber,
        size: pagination.perPageSize,
        pageId: '',
      }
    : {
        // empty pagination payload
        pageNumber: 0,
        size: 50,
        pageId: '',
      },
  frontendScope,
  sort: sort
    ? [
        {
          field: {
            value: resolveFieldKey(sort.key)
              .split('.')
              .map(
                // Field keys are PascalCase in the backend
                camelToPascal,
              )
              .join('.'),
          },
          ordering: ((): OrderEnum => {
            switch (sort.order) {
              case 'ascending':
                return OrderEnum.Ascending;
              case 'descending':
                return OrderEnum.Descending;
            }
          })(),
        },
      ]
    : [],
  searchPayload: filters?.searchQuery ? { searchQuery: filters.searchQuery, fields: [] } : undefined,
});

export default buildApiQuery;
