import { useState } from 'react';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';
import { MultiSelectOption } from '~/neo-ui/packages/select/packages/multi-select/MultiSelect';
import useRoadmapInitiativeTableDefinition from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-list-view/packages/roadmap-list-view-table/hooks/useRoadmapInitiativeTableDefinition';
import { RoadmapInitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import getRoadmapInitiativesFiltered from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-list-view/packages/roadmap-list-view-table/getRoadmapInitiativesFiltered';
import InitiativeSaveWindow from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-window/InitiativeSaveWindow';
import useRoadmapInitiativesContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapInitiativesContext';
import TableClientSide from '~/neo-ui/packages/table/packages/table-client-side/TableClientSide';
import useRoadmapFeatureFlagsContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapFeatureFlagsContext';

export type RoadmapListViewTableProps = {
  displayInitiatives: RoadmapInitiativeDto[];
  displayYear: number;
  currentYear: number;
  hideOrganizationColumn?: boolean;
  isUnscheduled?: boolean;
  selectedStatuses: MultiSelectOption[];
  selectedPriorities: MultiSelectOption[];
};

const RoadmapListViewTable = ({
  displayInitiatives,
  displayYear,
  currentYear,
  hideOrganizationColumn = true,
  isUnscheduled = false,
  selectedStatuses,
  selectedPriorities,
}: RoadmapListViewTableProps) => {
  const { isFeatureFlagEnabled } = useRoadmapFeatureFlagsContext();
  const { onInitiativeSave } = useRoadmapInitiativesContext();
  const [openInitiativeId, setOpenInitiativeId] = useState<string>();
  const { columns } = useRoadmapInitiativeTableDefinition(
    hideOrganizationColumn,
    initiative => {
      setOpenInitiativeId(initiative.initiativeId);
    },
    isUnscheduled,
    isFeatureFlagEnabled('lm-initiative-tickets'),
  );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <Label
        size={'sm'}
        bold={true}
        color={'light-000'}
        css={css`
          display: flex;
          align-items: center;
          gap: 0.5rem;

          padding: 0.5rem;
          background-color: ${colorToCode(isUnscheduled ? 'dark-900-32' : 'primary-500')};
        `}
      >
        {!isUnscheduled && currentYear === displayYear && (
          <Icon
            icon={'CurrentCalendarQuarter'}
            color={'light-000'}
          />
        )}
        {isUnscheduled ? 'Not scheduled' : displayYear}
      </Label>
      <TableClientSide
        data={getRoadmapInitiativesFiltered({
          initiatives: displayInitiatives,
          selectedStatuses,
          selectedPriorities,
          selectedOrganizations: [],
        })}
        columns={columns}
      >
        {({ Table }) => <Table />}
      </TableClientSide>
      {typeof openInitiativeId !== 'undefined' && (
        <InitiativeSaveWindow
          isOpen={typeof openInitiativeId !== 'undefined'}
          onDismiss={() => setOpenInitiativeId(undefined)}
          initiativeId={openInitiativeId}
          onSaveInitiative={() => {
            onInitiativeSave(openInitiativeId);
            setOpenInitiativeId(undefined);
          }}
        />
      )}
    </div>
  );
};

export default RoadmapListViewTable;
