import GlobalError from '~/neo-ui/packages/error/model/GlobalError';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import buildProgress, { BuildProgress, ProgressDefinition } from '../packages/progress/model/buildProgress';
import buildSensitivity, { BuildSensitivity, SensitivityDefinition } from '../packages/sensitivity/model/buildSensitivity';

/**
 * Represents something that may be contextual or static
 */
export type Contextable<T, TContext> = T | ((context: TContext) => T);

export type ActionItem = {
  label: string;
  description: string;
};

export type OperationDescription<TContext> = {
  /**
   * Main label to identify the operation
   */
  label: Contextable<string, TContext>;

  /**
   * Short description of the operation
   */
  description: Contextable<React.ReactNode, TContext>;

  /**
   * The action performed when the operation is triggered.
   *
   * May fail, providing a global error.
   */
  action: (context: TContext) => Promise<GlobalError | undefined>;

  /**
   * Specific action details associated with the operation
   */
  actions?: Contextable<ActionItem[], TContext>;

  /**
   * Custom icon associated with the operation
   */
  icon?: IconType;
};

/**
 * For defining the spec
 */
export type OperationDefinition<TContext> = OperationDescription<TContext> & {
  sensitivity: SensitivityDefinition<TContext>;
  progress?: ProgressDefinition<TContext>;
};

/**
 * For engine consumption
 */
export type BuildOperation<TContext> = Required<OperationDescription<TContext>> & {
  sensitivity: BuildSensitivity<TContext>;
  progress?: BuildProgress<TContext>;
};

/**
 * Convert definition to a consumable structure, populating with all default values
 */
const buildOperation = <TContext>(operation: OperationDefinition<TContext>): BuildOperation<TContext> => ({
  label: operation.label,
  description: operation.description,
  action: operation.action,
  actions: operation.actions ?? [],
  icon: operation.icon ?? 'Success',
  sensitivity: buildSensitivity(operation.sensitivity),
  progress: buildProgress(operation.progress),
});

export default buildOperation;
