export type MutationSuccessEffectDefinition<TResponse> = {
  action: (response: TResponse, isRequestActive?: () => boolean) => Promise<void>;
  suspendLoadingState: boolean;
};

export type MutationSuccessEffectParams<TResponse = unknown> =
  /**
   * Upon a successful mutation, reload the page
   */
  | { type: 'reload-page' }
  /**
   * Upon a successful mutation, redirect to a new URL
   */
  | { type: 'redirect'; url: string | ((response: TResponse) => string) }
  /**
   * Upon a successful mutation, reload the entity
   */
  | { type: 'reload-entity' }
  /**
   * Nothing happens when the mutation successfully completed
   */
  | { type: 'none' };

const getMutationSuccessEffectDefinition = <TResponse>(
  mutationSuccessEffectParams: MutationSuccessEffectParams<TResponse>,
  triggerEntityReload: (isRequestActive?: () => boolean) => Promise<void>,
): MutationSuccessEffectDefinition<TResponse> => {
  switch (mutationSuccessEffectParams.type) {
    case 'reload-entity':
      return {
        action: (_, isRequestActive) => triggerEntityReload(isRequestActive),
        suspendLoadingState: false,
      };
    case 'reload-page':
      return {
        action: async () => window.location.reload(),
        suspendLoadingState: true,
      };
    case 'redirect':
      return {
        action: async response => {
          window.location.href =
            typeof mutationSuccessEffectParams.url === 'string'
              ? mutationSuccessEffectParams.url
              : mutationSuccessEffectParams.url(response);
        },
        suspendLoadingState: true,
      };
    case 'none':
      return {
        action: async () => {},
        suspendLoadingState: false,
      };
  }
};

export default getMutationSuccessEffectDefinition;
