import { Response } from '@SubscriptionClient/Subscription/Packages/Billing/Controller/CustomerCreditCardDetailsGetControllerNested.gen';
import { subscriptionBillingCustomerPaymentCardGet } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { useCallback, useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

export type CreditCardDetails = {
  cardLabel: string;
  cardBrand: string;
};
const useCreditCardDetails = () => {
  const [isLoading, setLoading] = useState(true);
  const [creditCardResponse, setCreditCardResponse] = useState<Response>();
  const { callApi } = useApi();

  const refresh = useCallback(async () => {
    const response = await callApi(() => subscriptionBillingCustomerPaymentCardGet({}));
    setLoading(false);

    if (!response) {
      return;
    }
    setCreditCardResponse(response);
  }, [callApi]);

  useEffect(() => {
    (async () => {
      await refresh();
    })();
  }, [refresh]);

  return {
    isLoading,
    creditCardResponse,
    refresh,
  };
};

export type UseCreditCardDetails = ReturnType<typeof useCreditCardDetails>;

export default useCreditCardDetails;
