import { useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { assetConsoleSpreadsheet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import buildConsoleRequest from '~/neo-ui/packages/table/packages/console/buildConsoleRequest';
import { ConsoleState } from '~/neo-ui/packages/table/packages/console/types';
import { FieldKey as AssetManagementClientBeastClientPrimitivesQueryFieldKey } from '@AssetManagementClient/BeastClient/Primitives/Query.gen';
import { AssetFilterFrame } from '~/wm/packages/asset/packages/console/hooks/useAssetConsoleRequest';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { Enum as AssetScopeFactoryEnum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/AssetScope/Logic/AssetScopeFactoryNested.gen';
import { Enum as SpreadsheetTypeFactoryEnum } from '@AssetManagementClient/Document/Spreadsheet/Model/SpreadsheetTypeFactoryNested.gen';

type UseDownloadAssetSpreadsheet = (
  frontendScope: FrontendScope,
  assetType: AssetScopeFactoryEnum,
) => {
  downloadAssetSpreadsheet: (
    consoleState: ConsoleState,
    selectedColumns: Set<string> | undefined,
    context: object,
    spreadSheetType?: SpreadsheetTypeFactoryEnum,
  ) => void;
};

export const useDownloadAssetSpreadsheet: UseDownloadAssetSpreadsheet = (
  frontendScope: FrontendScope,
  assetType: AssetScopeFactoryEnum,
) => {
  const { callApi } = useApi();
  const downloadAssetSpreadsheet = useCallback(
    async (
      consoleState: ConsoleState,
      selectedColumns: Set<string> | undefined,
      context: object,
      spreadsheetType = SpreadsheetTypeFactoryEnum.Xlsx,
    ) => {
      const stream = await callApi(() =>
        assetConsoleSpreadsheet({
          query: buildConsoleRequest(consoleState),
          selectedColumns: Array.from(selectedColumns ?? new Set<string>()).map<AssetManagementClientBeastClientPrimitivesQueryFieldKey>(
            value => ({ value }),
          ),
          allColumns: typeof selectedColumns === 'undefined',
          scope: frontendScope,
          forecast: (context as AssetFilterFrame).forecast,
          assetType,
          spreadsheetType,
        }),
      );

      if (!stream) {
        return;
      }
    },
    [callApi, frontendScope, assetType],
  );

  return { downloadAssetSpreadsheet };
};

export default useDownloadAssetSpreadsheet;
