import * as React from 'react';
import { DraggableElement, DropZone } from '~/neo-ui/packages/drag-and-drop/DragAndDrop';
import Card from '~/legacy-ui/packages/card/Card';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';
import ScaleSelect, { ScaleOption } from '~/neo-ui/packages/select/packages/scale-select/ScaleSelect';
import { ScorecardItemsByScorecardCategory } from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/packages/scorecard-category-arrange/packages/scorecard-category-arrange-window/ScorecardCategoryArrangeWindow';
import { css } from '@emotion/react';

export type ScorecardCategoryArrangeItemDropZoneProps = {
  scorecardItemsByScorecardCategory: ScorecardItemsByScorecardCategory;
  scoreDisplayAvailabilities: ScaleOption[];
  children: React.ReactNode;
};

const ScorecardCategoryArrangeItemDropZone = ({
  scorecardItemsByScorecardCategory,
  scoreDisplayAvailabilities,
  children,
}: ScorecardCategoryArrangeItemDropZoneProps) => (
  <DropZone
    key={scorecardItemsByScorecardCategory.id}
    droppableId={scorecardItemsByScorecardCategory.id}
    settings={{ type: 'item' }}
    containerCssStates={{}}
  >
    {children}
    {scorecardItemsByScorecardCategory.scorecardItems.map((item, index) => (
      <DraggableElement
        key={item.id}
        index={index}
        draggableId={item.id}
        css={css`
          margin-bottom: 0.3125rem;
          margin-left: 1.25rem;
        `}
      >
        <Card
          key={`scorecard-item-${item.id}`}
          backgroundColor={'light-200'}
          shadow={'none'}
          padding={'sm'}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 0.625rem;
            `}
          >
            <Label>{item.label}</Label>
            <div
              css={css`
                display: flex;
                gap: 1rem;
              `}
            >
              <ScaleSelect
                scaleOptions={scoreDisplayAvailabilities}
                selectedScaleOption={scoreDisplayAvailabilities.find(option => option.value === item.score)}
                isPresentation={true}
                size={'sm'}
              />
              <Icon
                icon={'DragVertically'}
                color={'dark-900-24'}
                sizePx={20}
              />
            </div>
          </div>
        </Card>
      </DraggableElement>
    ))}
  </DropZone>
);

export default ScorecardCategoryArrangeItemDropZone;
