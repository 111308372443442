import React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import { css } from '@emotion/react';

const DownloadKitButton: React.FunctionComponent = () => {
  return (
    <Button
      css={css`
        padding: 0.45rem 0.8rem;
        border-radius: 2rem;
        margin-right: 0.5rem;
      `}
      anchor={{
        href: '/assets/reports/warranty/ScalePad-Marketplace-Sell-Through-Kit.zip',
        download: 'ScalePad-Marketplace-Sell-Through-Kit.zip',
      }}
      iconLeft={'Download'}
      size={'md'}
      theme={'primary'}
      invertColorMap={true}
    >
      Download Sell-Through Kit
    </Button>
  );
};

export default DownloadKitButton;
