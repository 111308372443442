import {
  DisposalAssetTypeAvailabilityDto,
  DisposalAssetTypeCountDto,
} from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';

export type GetDisposalAssetTypeCountBreakdown = (
  assetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[],
  assetTypeCounts: DisposalAssetTypeCountDto[],
) => string;

const getDisposalAssetTypeCountBreakdown: GetDisposalAssetTypeCountBreakdown = (assetTypeAvailabilities, assetTypeCounts) =>
  assetTypeCounts
    .map(({ disposalAssetType, count, label }) => {
      const assetTypeAvailability = assetTypeAvailabilities.find(
        assetTypeAvailability => assetTypeAvailability.disposalAssetType === disposalAssetType,
      );

      if (typeof assetTypeAvailability === 'undefined') {
        throw new Error(`Could not find asset type availability for ${disposalAssetType}.`);
      }

      return `${count} ${
        typeof label === 'undefined' ? (count === 1 ? assetTypeAvailability?.labelSingular : assetTypeAvailability?.label) : label
      }`;
    })
    .join(', ');

export default getDisposalAssetTypeCountBreakdown;
