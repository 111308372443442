import { assertNeverOrThrow } from '~/extensions/packages/types/assertNever';
import Theme from '~/neo-ui/packages/color/Theme';
import { Contextable } from '../../../model/buildOperation';

/**
 * For defining the spec
 */
export type SensitivityDefinition<TContext> =
  | 'none'
  | {
      type: 'confirmation-required';

      /**
       * Theme associated with the level of sensitivity (how sensitive is this action)
       */
      theme?: Theme;

      /**
       * Short label to describe the action of confirming
       */
      confirmLabel?: Contextable<string, TContext>;
    };

/**
 * For engine consumption
 */
export type BuildSensitivity<TContext> = Required<SensitivityDefinition<TContext>>;

/**
 * Convert definition to a consumable structure, populating with all default values
 */
const buildSensitivity = <TContext>(action: SensitivityDefinition<TContext>): BuildSensitivity<TContext> =>
  action === 'none'
    ? action
    : ((): BuildSensitivity<TContext> => {
        switch (action.type) {
          case 'confirmation-required':
            return {
              type: action.type,
              theme: action.theme || 'warning',
              confirmLabel: action.confirmLabel || 'Confirm',
            };
          default:
            assertNeverOrThrow(action.type);
        }
        return 'none';
      })();

export default buildSensitivity;
