import * as React from 'react';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { SimplePrerequisiteDto } from '@AssetManagementClient/Dashboard/Sdk/SharedComponent/Model/Prerequisite/SimplePrerequisiteComponentNested.gen';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

const SimplePrerequisiteState: React.FunctionComponent<SimplePrerequisiteDto> = ({
  icon,
  externalSourceImageUrl,
  description,
  ctaLabel,
  ctaUrl,
}) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 0.75rem;
      margin: 0 1rem 0 1rem;
      overflow: hidden;
    `}
  >
    <Icon
      icon={icon as IconType}
      color={'dark-050'}
      sizePx={32}
    />
    {externalSourceImageUrl && (
      <img
        src={externalSourceImageUrl}
        alt={''}
        css={css`
          height: 1.25rem;
          margin: 0.625rem 0 0 0;
        `}
      />
    )}
    <div
      css={css`
        margin: 1.25rem 0 1.25rem 0;
        font-size: 0.75rem;
        font-weight: 400;
      `}
    >
      {description}
    </div>
    <a
      href={ctaUrl}
      css={css`
        color: ${colorToCode('dark-050')};
        font-size: 0.875rem;
        font-weight: 400;
        text-decoration: underline;
        &:hover {
          color: ${colorToCode('dark-050')};
        }
      `}
    >
      {ctaLabel}
    </a>
  </div>
);

export default SimplePrerequisiteState;
