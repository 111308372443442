import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import * as React from 'react';
import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Styleable } from '~/neo-ui/model/capacity';
import { isIframe } from '~/extensions/packages/iframe/isIframe';
import { Link } from 'react-router-dom';

export type AnchorWrapperProps = AnchorLocation & {
  children: ReactNode;
} & Styleable;

/**
 * Wraps a child component with an unstyled anchor tag
 */
const AnchorWrapper = ({ href, openInNewTab, download, children, className }: AnchorWrapperProps) =>
  isIframe() || typeof WM.account === 'undefined' || !WM.account.isEnceladusAllowed || typeof download !== 'undefined' ? (
    <a
      href={href}
      download={download}
      target={openInNewTab ? '_blank' : undefined}
      className={className}
      css={css`
        text-decoration: none;
        color: inherit;
        &:hover {
          text-decoration: none;
          color: inherit;
        }
      `}
    >
      {children}
    </a>
  ) : (
    <Link
      to={href}
      target={openInNewTab ? '_blank' : undefined}
      className={className}
      css={css`
        text-decoration: none;
        color: inherit;
        &:hover {
          text-decoration: none;
          color: inherit;
        }
      `}
    >
      {children}
    </Link>
  );

export default AnchorWrapper;
