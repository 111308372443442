import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import useDownloadInitiativePdf from '~/wm/packages/strategy/packages/initiative/hooks/useDownloadInitiativePdf';

export type InitiativePdfDownloadButtonProps = {
  initiativeId: string;
};

const InitiativePdfDownloadButton: React.FunctionComponent<InitiativePdfDownloadButtonProps> = ({ initiativeId }) => {
  const { downloadInitiativePdf } = useDownloadInitiativePdf();

  return (
    <Button
      iconRight={'Download'}
      theme={'secondary'}
      onClick={() => {
        downloadInitiativePdf(initiativeId);
      }}
    >
      Download Initiative PDF
    </Button>
  );
};

export default InitiativePdfDownloadButton;
