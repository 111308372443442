import * as React from 'react';
import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import usePanelTabGroupContext from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/hooks/usePanelTabGroupContext';
import { EngagementType } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/EngagementDashboard';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import Icon from '~/neo-ui/packages/icon/Icon';
import EngagementNoteCreateForm from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/packages/engagement-note-create-form/EngagementNoteCreateForm';
import useEngagementNotePanelTabContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/context/hooks/useEngagementNotePanelTabContext';
import EngagementNoteCreateFormModule from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/packages/engagement-note-create-form-module/EngagementNoteCreateFormModule';
import useStrategyDashboardUrlContext from '~/wm/packages/strategy/packages/strategy-dashboard/context/hooks/useStrategyDashboardUrlContext';
import EngagementNoteSectionDashboard from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/packages/engagement-note-section-dashboard/EngagementNoteSectionDashboard';
import useEngagementNoteDashboardCreate from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/hooks/useEngagementNoteDashboardCreate';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import engagementDataCollectionSources from '~/wm/packages/organization/packages/engagement/model/engagementDataCollectionSources';

const EngagementNoteDashboardPanelTab = () => {
  const { organizationId } = useOrganizationContext();
  const { notes, hasNextPage } = useEngagementNotePanelTabContext();
  const { engagementNoteListUrl } = useStrategyDashboardUrlContext();

  const [isCreatingNote, setCreatingNote] = React.useState(false);

  const { createEngagementNote } = useEngagementNoteDashboardCreate();

  const { currentPageByTab, currentTabId, setCurrentTabPage } = usePanelTabGroupContext<EngagementType>();

  const currentPage = currentPageByTab.get(currentTabId);

  if (typeof currentPage === 'undefined') {
    throw new Error('Current page is undefined from tab context');
  }

  return isCreatingNote ? (
    <EngagementNoteCreateForm
      organizationId={organizationId}
      onCreate={createEngagementNote}
      createdFrom={engagementDataCollectionSources.engagementNoteCreationSource.clientDashboard}
    >
      <EngagementNoteCreateFormModule
        onCancel={() => {
          setCreatingNote(false);
        }}
        onSubmit={() => {
          setCreatingNote(false);
          setCurrentTabPage(1);
        }}
      />
    </EngagementNoteCreateForm>
  ) : (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 1rem;
          align-items: center;
        `}
      >
        <ButtonLink
          iconLeft={'Add'}
          onClick={() => setCreatingNote(!isCreatingNote)}
        >
          Create New Notes
        </ButtonLink>
        <div
          css={css`
            margin-left: auto;
            display: flex;
            gap: 1rem;
          `}
        >
          <div
            css={css`
              display: flex;
            `}
          >
            <Button
              onClick={() => {
                setCurrentTabPage(currentPage - 1);
              }}
              disabled={currentPage === 1}
            >
              <Icon icon="ArrowLeft" />
            </Button>
            <Button
              onClick={() => {
                setCurrentTabPage(currentPage + 1);
              }}
              disabled={!hasNextPage}
            >
              <Icon icon="ArrowRight" />
            </Button>
          </div>
          <ButtonLink
            iconRight={'ArrowRight'}
            anchor={{
              href: engagementNoteListUrl.replace('__ORGANIZATION_ID__', organizationId),
            }}
          >
            View All
          </ButtonLink>
        </div>
      </div>
      <EngagementNoteSectionDashboard notes={notes} />
    </div>
  );
};

export default EngagementNoteDashboardPanelTab;
