import React from 'react';
import WarrantyCartFilterForm from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-filters/packages/warranty-cart-filter-form/WarrantyCartFilterForm';
import WarrantyCartFilterFormControl from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-filters/packages/warranty-cart-filter-form-control/WarrantyCartFilterFormControl';
import WarrantyCartSelectedCount from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-filters/packages/warranty-cart-selected-count/WarrantyCartSelectedCount';

const WarrantyCartFilters: React.FunctionComponent = () => {
  return (
    <>
      <WarrantyCartSelectedCount />
      <WarrantyCartFilterForm>
        <WarrantyCartFilterFormControl />
      </WarrantyCartFilterForm>
    </>
  );
};

export default WarrantyCartFilters;
