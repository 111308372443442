import { Styleable } from '~/neo-ui/model/capacity';
import * as React from 'react';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import useClipboard from '~/extensions/packages/clipboard/hooks/useClipboard';
import { OrganizationKeyContactDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationKeyContact/Dto/Model.gen';
import OrganizationKeyContactRemoveModal from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/packages/people-dashboard-dropdown/packages/people-dashboard-dropdown-organization-key-contact/packages/organization-key-contact-remove-modal/OrganizationKeyContactRemoveModal';

export type PeopleDashboardDropdownOptionOrganizationKeyContactProps = {
  organizationKeyContact: OrganizationKeyContactDto;
} & Styleable;

const PeopleDashboardDropdownOptionOrganizationKeyContact: React.FunctionComponent<
  PeopleDashboardDropdownOptionOrganizationKeyContactProps
> = ({ organizationKeyContact }) => {
  const [isModalDeleteOpen, setModalDeleteOpen] = React.useState(false);

  const { copyToClipboard } = useClipboard();
  const copyEmail = React.useCallback(() => {
    if (typeof organizationKeyContact.email !== 'undefined') {
      copyToClipboard(organizationKeyContact.email);
    }
  }, [copyToClipboard, organizationKeyContact.email]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <Icon
          icon={'User'}
          color={organizationKeyContact.isActive ? 'positive-400' : 'dark-900-32'}
        />
        <Label
          size={'md'}
          css={css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
          color={organizationKeyContact.isActive ? 'dark-900' : 'dark-900-32'}
        >
          {organizationKeyContact.fullName}
        </Label>

        <Icon
          css={css`
            margin-left: auto;
            cursor: pointer;
          `}
          icon={'Trash'}
          color={'negative-400'}
          onClick={() => {
            setModalDeleteOpen(true);
          }}
          preventOnClickPropagation={true}
        />
      </div>

      <Label
        size={'sm'}
        color={organizationKeyContact.isActive ? 'dark-900-64' : 'dark-900-32'}
      >
        {organizationKeyContact.isActive ? organizationKeyContact.title ?? 'Role' : 'Deactivated'}
      </Label>

      {typeof organizationKeyContact.email !== 'undefined' && (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.3125rem;
            cursor: pointer;

            .tooltip-target {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          `}
          onClick={() => {
            copyEmail();
          }}
        >
          <Tooltip
            content={organizationKeyContact.email}
            backgroundColor={'light-000'}
            placement={'top'}
          >
            <Label
              css={css`
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
              size={'sm'}
              color={organizationKeyContact.isActive ? 'dark-900-64' : 'dark-900-32'}
            >
              {organizationKeyContact.email}
            </Label>
          </Tooltip>

          <Icon
            css={css`
              margin-left: auto;
            `}
            icon={'Copy'}
            color={'primary-400'}
          />
        </div>
      )}
      {isModalDeleteOpen && (
        <OrganizationKeyContactRemoveModal
          organizationKeyContact={organizationKeyContact}
          isOpen={isModalDeleteOpen}
          onDismiss={() => {
            setModalDeleteOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default PeopleDashboardDropdownOptionOrganizationKeyContact;
