/* eslint-disable @typescript-eslint/naming-convention */
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { Stripe, StripeElements, StripeElementsOptions, loadStripe, type Appearance } from '@stripe/stripe-js';
import { useEffect, useMemo, useRef, useState } from 'react';
import Button from '~/sp-ui/buttons/Button';
import Message from '~/sp-ui/message';
import { type UseCreditCardDetails } from '../hooks/useCreditCardDetails';
import useSetupIntentApi from '../hooks/useSetupIntentApi';
import CardCvc from './ui/CardCvc';
import CardExpiry from './ui/CardExpiry';
import CardNumber from './ui/CardNumber';
import ReadonlyPaymentMethod from './ui/ReadonlyPaymentMethod';
import SectionLoadingIndicator from './ui/SectionLoadingIndicator';

const stripePromise = loadStripe(WM.stripePublicKey);

const appearance: Appearance = {
  theme: 'flat',
  labels: 'above',
  variables: {
    fontFamily: 'Roboto',
    fontSizeBase: '16px',
  },
};

interface PaymentMethodSectionProps {
  creditCardDetails: UseCreditCardDetails;
  onSubmit: (
    elements: StripeElements,
    stripe: Stripe,
    clientSecret: string,
    setFormSubmissionError: (x: string | undefined) => void,
  ) => Promise<void> | void;
  isEditing: boolean;
}

const PaymentMethodSection = (props: PaymentMethodSectionProps) => {
  const { onSubmit, isEditing, creditCardDetails } = props;

  const { isLoading: isSetupIntentLoading, clientSecret, startSetupIntent } = useSetupIntentApi();
  const [validState, setValidState] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const isValid = useMemo(() => Object.values(validState).every(value => value), [validState]);
  const [formSubmissionError, setFormSubmissionError] = useState<string>();
  const { creditCardResponse, isLoading: isCreditCardLoading } = creditCardDetails;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasLoadedRef = useRef(false);

  useEffect(() => {
    if (hasLoadedRef.current) {
      return;
    }

    if (!isSetupIntentLoading && !isCreditCardLoading) {
      hasLoadedRef.current = true;
    }
  }, [isSetupIntentLoading, isCreditCardLoading]);

  const setValid = (key: keyof typeof validState, valid: boolean) =>
    setValidState(previous => ({
      ...previous,
      [key]: valid,
    }));

  if ((isSetupIntentLoading || isCreditCardLoading) && !hasLoadedRef.current) {
    return <SectionLoadingIndicator />;
  }

  if (!isEditing) {
    if (creditCardResponse?.cardDetails) {
      return (
        <ReadonlyPaymentMethod
          data={creditCardResponse.cardDetails}
          css={{ paddingInline: '48px' }}
        />
      );
    }

    return null;
  }

  const options: StripeElementsOptions = {
    clientSecret: clientSecret!,
    appearance,
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap',
      },
    ],
  };

  return (
    <Elements
      stripe={stripePromise}
      options={options}
    >
      <ElementsConsumer>
        {({ elements, stripe }) => {
          /** Not using formik here as these stripe fields validate and track themselves */
          return (
            <form
              css={{
                paddingInlineStart: '48px',
                display: 'grid',
                gridTemplateColumns: 'min-content 1fr',
                placeContent: 'start',
                gap: '1rem',
              }}
              onSubmit={async e => {
                e.preventDefault();
                setIsSubmitting(true);

                if (!elements || !stripe || !clientSecret) {
                  setIsSubmitting(false);
                  setFormSubmissionError(undefined);
                  return;
                }

                await onSubmit(elements, stripe, clientSecret, setFormSubmissionError);
                await startSetupIntent();
                setIsSubmitting(false);
              }}
            >
              <CardNumber
                css={{ gridColumn: '1 / -1' }}
                onChange={e => setValid('cardNumber', e.complete)}
              />
              <CardExpiry onChange={e => setValid('cardExpiry', e.complete)} />
              <CardCvc onChange={e => setValid('cardCvc', e.complete)} />

              {formSubmissionError && (
                <Message
                  text={formSubmissionError}
                  type="error"
                  css={{ gridColumn: '1 / -1', marginBlock: '1rem' }}
                />
              )}

              <Button
                css={{ gridColumn: '1 / -1', justifySelf: 'start' }}
                type="submit"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
              >
                Save and continue
              </Button>
            </form>
          );
        }}
      </ElementsConsumer>
    </Elements>
  );
};

export default PaymentMethodSection;
