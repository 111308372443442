import { css } from '@emotion/react';
import * as React from 'react';
import { Fragment, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import TextBlock from '~/neo-ui/packages/text/packages/text-block/TextBlock';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { v4 as uuidv4 } from 'uuid';
import AnimationHeight from '~/neo-ui/packages/animation/packages/animation-height/AnimationHeight';
import Divider from '~/neo-ui/packages/divider/Divider';
import Button from '~/neo-ui/packages/button/Button';
import DisposalOrderBulkPickupSectionImageList from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-bulk-pickup-section/packages/disposal-order-bulk-pickup-section-image-list/DisposalOrderBulkPickupSectionImageList';
import Icon from '~/neo-ui/packages/icon/Icon';
import DisableElementOverlay from '~/neo-ui/packages/overlay/DisableElementOverlay';
import DisposalOrderBulkPickupSectionImageUploadPreview from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-bulk-pickup-section/packages/disposal-order-bulk-pickup-section-image-upload-preview/DisposalOrderBulkPickupSectionImageUploadPreview';
import fileToWebp from '~/extensions/packages/image/fileToWebp';
import Spinner from '~/neo-ui/spinner/Spinner';
import blobToBase64 from '~/extensions/packages/image/blobToBase64';

export type DisposalOrderBulkPickupSectionImageUploadProps = {
  disposalOrderId: string;
  onImageUploadSuccess: () => void;
  imageCount: number;
  canUploadImage: boolean;
};

export type ImageType = {
  blob: Blob;
  id: string;
  preview: string;
  contentBase64: string;
  fileName: string;
};

const DisposalOrderBulkPickupSectionImageUpload = ({
  disposalOrderId,
  onImageUploadSuccess,
  imageCount,
  canUploadImage,
}: DisposalOrderBulkPickupSectionImageUploadProps) => {
  const [filesToUpload, setFilesToUpload] = useState<ImageType[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setIsPending(true);
    acceptedFiles.map(async file => {
      const webpFile = await fileToWebp({ file });
      const contentBase64 = await blobToBase64(webpFile.blob);
      setFilesToUpload(prevState => [
        ...prevState,
        {
          blob: webpFile.blob,
          id: uuidv4(),
          preview: URL.createObjectURL(file),
          contentBase64: contentBase64.split(',')[1],
          fileName: webpFile.fileName,
        },
      ]);
      setIsPending(false);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    maxFiles: 5,
    multiple: true,
    // 5MB
    maxSize: 5242880,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
    noDrag: false,
  });

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <TextBlock
        title={'Upload shipment photos'}
        description={'The photos of your shipment will provide visual documentation for the courier.'}
        size={'sm'}
      />
      <DisableElementOverlay disabled={!canUploadImage}>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            max-width: 18.25rem;
            min-height: 8.5rem;
            border: 0.125rem dotted ${isDragActive ? colorToCode('primary-500') : colorToCode('dark-900-32')};
            border-radius: 0.25rem;
            padding: 1.5rem;
            text-align: center;
            background-color: ${isDragActive ? colorToCode('primary-200') : colorToCode('dark-900-12')};
            align-items: center;
          `}
        >
          <Icon
            icon={'Upload'}
            sizePx={24}
          />
          <div
            {...getRootProps()}
            css={css`
              display: flex;
              flex-direction: column;
              gap: 0.75rem;
            `}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Label size={'sm'}>Drop the files here ...</Label>
            ) : (
              <Label size={'sm'}>
                Drag your file(s) here, or <span css={{ color: 'blue', textDecoration: 'underline' }}>browse</span>
              </Label>
            )}
            <Label
              size={'sm'}
              color={'dark-900-64'}
            >
              Max 5MB files are allowed
            </Label>
          </div>
        </div>
      </DisableElementOverlay>
      <Label
        size={'sm'}
        color={'dark-900-64'}
        css={css`
          margin-top: 0.5rem;
        `}
      >
        {`Only support .jpg and .png files. Maximum 5 photos. ${imageCount} photo${imageCount === 1 ? '' : 's'} uploaded.`}
      </Label>
      {fileRejections.length > 0 &&
        fileRejections.map(fileRejection => (
          <Label
            key={fileRejection.file.name}
            size={'sm'}
            color={'negative-400'}
          >
            {fileRejection.file.name} cannot be uploaded:{' '}
            {fileRejection.errors.reduce((finalError, error) => `${finalError}, ${error.message}`, '')}
          </Label>
        ))}
      {filesToUpload.map(file => (
        <DisposalOrderBulkPickupSectionImageUploadPreview
          key={file.id}
          disposalOrderId={disposalOrderId}
          imageToUpload={file}
          canUploadImage={canUploadImage}
          onImageUploadSuccess={onImageUploadSuccess}
          onImageDelete={() => setFilesToUpload(prevState => prevState.filter(image => file.id !== image.id))}
        />
      ))}
      {isPending && <Spinner size={'sm'} />}
      {imageCount > 0 && (
        <Fragment>
          <TextBlock
            title={'Uploaded shipment photos'}
            description={`${imageCount} photo${imageCount === 1 ? '' : 's'} uploaded`}
            size={'sm'}
          />
          <AnimationHeight isExpanded={isExpanded}>
            <Divider />
            {isExpanded && (
              <div
                css={css`
                  margin: 0.75rem 0;
                  min-height: 12.5rem;
                `}
              >
                <DisposalOrderBulkPickupSectionImageList disposalOrderId={disposalOrderId} />
              </div>
            )}
            <Divider />
          </AnimationHeight>
          <Button
            theme={'caution'}
            iconRight={isExpanded ? 'ArrowUp' : 'ArrowDown'}
            onClick={() => setIsExpanded(prev => !prev)}
            css={css`
              margin-top: ${isExpanded ? '1rem' : '0'};
              width: fit-content;
            `}
          >
            {!isExpanded ? 'Show photos' : 'Hide photos'}
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default DisposalOrderBulkPickupSectionImageUpload;
