import { ticketCreateFieldAvailabilitiesGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import TicketFieldAvailabilitiesContext from './TicketFieldAvailabilitiesContext';
import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';

export type InitiativeAvailabilitiesProviderProps = { organizationId: string; children: ReactNode };

const TicketFieldAvailabilitiesProvider = ({ organizationId, children }: InitiativeAvailabilitiesProviderProps) => {
  const [ticketFieldOptions, setTicketFieldOptions] = useState<IntegrationFieldOptionDto[]>([]);
  const [isOrganizationRegisteredForTickets, setOrganizationRegisteredForTickets] = useState<boolean>(false);
  const [hasTicketIntegration, setHasTicketIntegration] = useState<boolean>(false);
  const [integrationUrl, setIntegrationUrl] = useState<string | undefined>();

  const { callApi } = useApi();

  const reload = useCallback(
    async (isRequestActive?: () => boolean) => {
      await (async () => {
        const response = await callApi(() => ticketCreateFieldAvailabilitiesGet({ organizationId }));

        if (!response) {
          return;
        }

        // if a function is provided to determine whether to propagate
        // a state update (to prevent race conditions), then it will
        // ensure not to update state. otherwise, each call updates state.
        if (!isRequestActive || isRequestActive()) {
          if (response.fieldOptions !== undefined) {
            setTicketFieldOptions(response.fieldOptions);
          }
          setOrganizationRegisteredForTickets(response.status.organizationIsRegistered);
          setHasTicketIntegration(response.status.hasPsaIntegration);
          setIntegrationUrl(response.integrationUrl);
        }
      })();
    },
    [callApi, organizationId],
  );

  // Load availabilities
  useEffect(() => {
    reload();
  }, [reload]);

  return (
    <TicketFieldAvailabilitiesContext.Provider
      value={{
        ticketFieldOptions,
        isOrganizationRegisteredForTickets,
        hasTicketIntegration,
        integrationUrl,
      }}
    >
      {children}
    </TicketFieldAvailabilitiesContext.Provider>
  );
};

export default TicketFieldAvailabilitiesProvider;
