import React from 'react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';

import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';
import { Enum as InputTypeEnum } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Credential/InputTypeNested.gen';
import RenderFormIntegrationFieldOptionInputSelect from '~/wm/packages/integration/packages/render-form-integration-field-option/packages/render-form-integration-field-option-input-select/RenderFormIntegrationFieldOptionInputSelect';
import RenderFormIntegrationFieldOptionInputText from '~/wm/packages/integration/packages/render-form-integration-field-option/packages/render-form-integration-field-option-input-text/RenderFormIntegrationFieldOptionInputText';

export type RenderFormFieldInputProps<TFormData> = {
  fieldKey: FieldKeyExpression<TFormData>;
  data: IntegrationFieldOptionDto;
};

const RenderFormIntegrationFieldOption = <TFormData,>({ fieldKey, data }: RenderFormFieldInputProps<TFormData>) => {
  switch (data.inputType.type) {
    case InputTypeEnum.Select:
      return (
        <RenderFormIntegrationFieldOptionInputSelect
          fieldKey={fieldKey}
          data={data.inputType}
          values={data?.values ?? []}
          disabled={typeof data.values === 'undefined' || data.values.length === 0}
        />
      );
    case InputTypeEnum.Text:
      return (
        <RenderFormIntegrationFieldOptionInputText
          fieldKey={fieldKey}
          data={data.inputType}
        />
      );
    /**
     * There are more input types that can be added here.
     * However, the current implementation only supports Select and Text.
     * Therefore, we are returning null for the rest of the input types.
     *
     * Please see:
     * - the InputTypeEnum for more input types (if interested)
     * - see 124a16b3-69e2-4305-9322-43b9b0d6f3ba to link to related backend code
     */
    default:
      return null;
  }
};

export default RenderFormIntegrationFieldOption;
