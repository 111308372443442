const useScroll = () => ({
  scrollToTop: () => {
    if (typeof window.scroll === 'function') {
      window.scroll({ top: 0, behavior: 'smooth' });
    } else {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  },
  scrollToBottom: () => {
    if (typeof window.scroll === 'function') {
      window.scroll({ top: document.body.scrollHeight, behavior: 'smooth' });
    } else {
      document.body.scrollTop = document.documentElement.scrollTop = document.body.scrollHeight;
    }
  },
});

export default useScroll;
