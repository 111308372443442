import * as React from 'react';
import useNotificationCenter from '~/wm/packages/notification/hooks/useNotificationCenter';
import ActionButton from '~/neo-ui/packages/button/packages/action-button/ActionButton';
import NotificationTooltip from '~/wm/packages/notification/packages/notification-tooltip/NotificationTooltip';

export type NotificationBellButtonProps = unknown;

const NotificationBellButton: React.FunctionComponent<NotificationBellButtonProps> = () => {
  const { notifications, markAsRead } = useNotificationCenter();

  const [isOpen, setIsOpen] = React.useState(false);

  const actionButtonTheme =
    notifications.countUnread > 0
      ? ({
          activeBackgroundColor: 'primary-300',
          inactiveBackgroundColor: 'primary-400',
          activeBorderColor: 'primary-400',
          inactiveBorderColor: 'primary-400',
          activeIconColor: 'primary-400',
          inactiveIconColor: 'light-000',
        } as const)
      : ({
          activeBackgroundColor: 'light-000',
          inactiveBackgroundColor: 'light-000',
          activeBorderColor: 'light-800',
          inactiveBorderColor: 'light-800',
          activeIconColor: 'light-800',
          inactiveIconColor: 'light-800',
        } as const);

  return (
    <NotificationTooltip
      notifications={notifications}
      onMarkAsRead={markAsRead}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <ActionButton
        icon={'Notification'}
        activeBackgroundColor={actionButtonTheme.activeBackgroundColor}
        inactiveBackgroundColor={actionButtonTheme.inactiveBackgroundColor}
        activeBorderColor={actionButtonTheme.activeBorderColor}
        inactiveBorderColor={actionButtonTheme.inactiveBorderColor}
        activeIconColor={actionButtonTheme.activeIconColor}
        inactiveIconColor={actionButtonTheme.inactiveIconColor}
        isActive={isOpen}
        onClick={() => {
          setIsOpen(isOpen => !isOpen);
        }}
        badgeNumber={notifications.countUnread > 0 ? notifications.countUnread : undefined}
      />
    </NotificationTooltip>
  );
};

export default NotificationBellButton;
