/* eslint-disable @typescript-eslint/no-explicit-any,no-console */
import { css } from '@emotion/react';
import * as React from 'react';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';
import { boxShadowToCode } from '~/neo-ui/packages/style/BoxShadow';
import { getComponentByUniqueId } from '~/wm/packages/dashboard/packages/componentRegistrarWidget';
import { ComponentUniqueIds } from '~/wm/packages/dashboard/packages/widgetComponents.gen';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ComponentPayload = {
  enum: string;
  data: any;
  componentUniqueId: ComponentUniqueIds;
};

export type WidgetProps = {
  title?: string;
  icon?: string;
  tooltip?: string;
  ctaLabel?: string;
  ctaUrl?: string;
  component: ComponentPayload | undefined;
};

const Widget: React.FunctionComponent<WidgetProps> = ({ title, tooltip, ctaLabel, ctaUrl, component }) => {
  let loadedComponent: ComponentPayload | undefined = component;
  if (typeof loadedComponent === 'undefined') {
    loadedComponent = {
      enum: 'PreReqResponse',
      data: {
        icon: 'Warning',
        description:
          process.env.NODE_ENV === 'production'
            ? 'There was a problem loading this widget. We are actively working on resolving the issue.'
            : 'WARNING!! The widget component failed to load. This needs to be fixed!',
      },
      componentUniqueId: 'simple-prerequisite',
    };
  }

  const ContainerComponent = getComponentByUniqueId(loadedComponent.componentUniqueId)!;

  return (
    <div
      css={css`
        background-color: ${colorToCode('light-000')};
        box-shadow: ${boxShadowToCode('shadow100')};
        border-radius: 6px 6px 6px 6px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        word-break: break-word;
        &:hover {
          box-shadow: ${boxShadowToCode('shadow200')};
        }
      `}
    >
      {(title || tooltip) && (
        <div
          css={css`
            background-color: ${colorToCode('dark-400')};
            border-radius: 6px 6px 0 0;
            display: flex;
            height: 2.875rem;
            min-height: 2.875rem;
            color: #ffffff;
            font-size: 0.875rem;
          `}
        >
          <div
            css={css`
              display: flex;
              flex: 1;
              flex-basis: auto;
              margin: 0 1rem 0 1rem;
              align-items: center;
            `}
          >
            {title && (
              <b
                css={css`
                  margin-right: 0.5rem;
                `}
              >
                {title}
              </b>
            )}
            {tooltip && (
              <Tooltip
                backgroundColor={'light-000'}
                placement={'top'}
                content={tooltip}
              >
                <Icon
                  icon={'Info'}
                  sizePx={14}
                  color={'light-800'}
                />
              </Tooltip>
            )}
          </div>
        </div>
      )}
      <div
        css={css`
          display: flex;
          flex: 1;
          flex-basis: auto;
          min-height: 0;
          justify-content: center;
          align-items: center;
          overflow: auto;
        `}
      >
        {<ContainerComponent {...loadedComponent.data} />}
      </div>
      <div
        css={css`
          border-radius: 0 0 6px 6px;
          display: flex;
          flex-direction: column-reverse;
          width: 100%;
          left: 0;
          bottom: 0;
          align-items: center;
          font-size: 0.75rem;
        `}
      >
        {loadedComponent.enum !== 'PreReqResponse' && ctaUrl && (
          <div
            css={css`
              display: inline-block;
              width: 100%;
              height: 2rem;
            `}
          >
            <hr
              css={css`
                margin: 0 0 0 0;
              `}
            />
            <div
              css={css`
                float: right;
                padding-right: 1rem;
                text-align: right;
                width: 100%;
                margin: 0.5rem -0.5rem;
              `}
            >
              <a href={ctaUrl}>
                {ctaLabel ?? ctaUrl}
                <Icon
                  icon={'ArrowChosen'}
                  sizePx={16}
                  color={'primary-300'}
                  css={css`
                    margin: -0.2rem 0.2rem;
                  `}
                />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Widget;
