import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { RadioListOption } from '~/neo-ui/packages/list/packages/radio-list/packages/radio-list-item/RadioListItem';
import RadioList from '~/neo-ui/packages/list/packages/radio-list/RadioList';
import { useState, useEffect } from 'react';
import { ProductRegionConfiguration } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/ProductAdoption.gen';
import { RegionConfigOption } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/ProductAdoption/ProductRegionConfigurationNested.gen';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { SubscriptionAgreementData } from '~/wm/packages/subscription/packages/manage/wizard/step/getSubscriptionAgreementStep';
import { ProductRegions } from '~/wm/packages/subscription/packages/upgrade/wizard/SubscriptionUpgradeWizard';
import { toFieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';

export type ProductAdoptionRegionSelectorProps = {
  regionConfig: ProductRegionConfiguration;
};

const ProductAdoptionRegionSelector = ({ regionConfig }: ProductAdoptionRegionSelectorProps) => {
  const options: RadioListOption[] = [];
  regionConfig.regionConfigOptions.map((configOption: RegionConfigOption) => {
    const newOption: RadioListOption = {
      label: configOption.label,
      value: configOption.value,
      selectedTheme: 'secondary',
    };
    options.push(newOption);
  });
  const { formData, setFormInput } = useFormContext<SubscriptionAgreementData>();
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);

  useEffect(() => {
    const current = formData.selectedProductRegions;
    if (selectedOption === undefined) {
      // Workaround to adding new field keys by setting products to be complete if their region is also complete
      current[regionConfig.productEnum.toString()] = 'UNSELECTED';
    } else if (selectedOption !== formData.selectedProductRegions[regionConfig.productEnum.toString()]) {
      current[regionConfig.productEnum.toString()] = selectedOption;
      setFormInput<ProductRegions>(toFieldKeyExpression('selectedProductRegions'), current);
    }
  }, [formData.selectedProductRegions, regionConfig.productEnum, selectedOption, setFormInput]);

  return (
    <div
      css={css`
        border-radius: 4px;
        border: 1px solid ${colorToCode('dark-900-12')};
        background: ${colorToCode('light-100')};
        padding: 24px;
        font-size: 0.75rem;
        margin: 30px 0px 30px 0px;
      `}
    >
      <p
        css={css`
          font-size: 16px;
        `}
      >
        {regionConfig.title}
      </p>
      <div
        css={css`
          margin-bottom: 16px;
        `}
      >
        {regionConfig.description}
      </div>
      {!selectedOption ? (
        <Label
          size={'sm'}
          bold={false}
          color={'negative-400'}
          css={css`
            margin-bottom: 16px;
          `}
        >
          This field is required
        </Label>
      ) : null}
      <RadioList
        css={css`
          margin-bottom: 16px;
        `}
        options={options}
        selectedOptionValue={selectedOption}
        onOptionSelected={option => setSelectedOption(option)}
      />
      <div>{regionConfig.footnote}</div>
    </div>
  );
};
export default ProductAdoptionRegionSelector;
