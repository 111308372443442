import { createContext } from 'react';
import { DisposalOrderSummaryDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Dto/Model.gen';

export type DisposalOrderInfoContextData = {
  disposalOrderSummary: DisposalOrderSummaryDto | undefined;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const DisposalOrderInfoContext = createContext<DisposalOrderInfoContextData | undefined>(undefined);

export default DisposalOrderInfoContext;
