import { ContractAssetInfo } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-link-assets-button/modal/ContractAssetInfo';
import { ContractAssetDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Asset/Dto/Model.gen';

const buildContractAssetInfoFromContractAssetDto = (contractAssets: ContractAssetDto[]): ContractAssetInfo[] =>
  contractAssets.map(contractAsset => ({
    contractAssetId: contractAsset.contractAssetId,
    assetId: contractAsset.assetId,
    name: contractAsset.name ?? '-',
    serialNumber: contractAsset.serialNumber ?? '-',
    modelNumber: contractAsset.modelNumber ?? '-',
    manufacturerName: contractAsset.manufacturerName ?? '-',
    user: contractAsset.user ?? '-',
    purchaseDate:
      typeof contractAsset.purchaseDate !== 'undefined'
        ? {
            date: contractAsset.purchaseDate.date,
            age: contractAsset.purchaseDate.age,
          }
        : undefined,
    warrantyExpiration:
      typeof contractAsset.warrantyExpiration !== 'undefined'
        ? {
            expirationDate: contractAsset.warrantyExpiration.expirationDate,
            isExpired: contractAsset.warrantyExpiration.isExpired,
          }
        : undefined,
    isDeleted: false,
  }));

export default buildContractAssetInfoFromContractAssetDto;
