import React from 'react';
import Icon, { IconProps } from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';

export type DropdownToggleIconProps = {
  isExpanded: boolean;
  iconClosedDropdown?: IconType; // The icon to display when the dropdown is closed
  iconOpenDropdown?: IconType; // The icon to display with the dropdown is open
} & Omit<IconProps, 'icon'>;

const DropdownToggleIcon: React.FunctionComponent<DropdownToggleIconProps> = ({
  isExpanded,
  iconClosedDropdown = 'ArrowRight',
  iconOpenDropdown = 'ArrowDown',
  ...otherIconProps
}) => {
  return (
    <Icon
      icon={isExpanded ? iconOpenDropdown : iconClosedDropdown}
      {...otherIconProps}
    />
  );
};
export default DropdownToggleIcon;
