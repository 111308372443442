import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import FilterPreviewSplitMultiSelect from '~/neo-ui/packages/filter/packages/filter-headers/filter-header/packages/filter-header-selected-split-multi-select/FilterPreviewSplitMultiSelect';
import FilterHeader from '~/neo-ui/packages/filter/packages/filter-headers/filter-header/FilterHeader';
import * as React from 'react';
import FilterHeaderEmptySplitMultiSelect from '~/neo-ui/packages/filter/packages/filter-headers/filter-header/packages/filter-header-selected-split-multi-select/packages/filter-header-empty-split-multi-select/FilterHeaderEmptySplitMultiSelect';
import { SplitMultiSelectHeaderFormatterType } from '~/neo-ui/packages/filter/packages/filter-split-multi-select/SplitMultiSelectHeaderMenuFormatter';
import Theme from '~/neo-ui/packages/color/Theme';

export const splitMultiSelectHeaderConsoleFormatter: SplitMultiSelectHeaderFormatterType =
  (
    selectedOptionsPositive: SelectOption[],
    selectedOptionsNegative: SelectOption[],
    label: string,
    _operatorLabelPositive: string | undefined,
    _operatorLabelNegative: string | undefined,
    positiveTheme?: Theme,
    negativeTheme?: Theme,
  ) =>
  (isOpen: boolean) => {
    const splitFilterPreview = (
      <FilterPreviewSplitMultiSelect
        selectedPositiveValues={selectedOptionsPositive.map(sop => sop.label)}
        selectedNegativeValues={selectedOptionsNegative.map(son => son.label)}
        negativeOperatorLabel={'NOT'}
        positiveTheme={positiveTheme ?? 'secondary'}
        negativeTheme={negativeTheme ?? 'negative'}
        isOpen={isOpen}
      />
    );

    const splitFilterHeaderEmpty = (
      <FilterHeaderEmptySplitMultiSelect
        label={label}
        isOpen={isOpen}
      />
    );

    return (
      <FilterHeader
        selectedValues={selectedOptionsPositive.concat(selectedOptionsNegative).map(v => v.label)}
        operator={''}
        emptyDisplay={splitFilterHeaderEmpty}
        selectedDisplay={splitFilterPreview}
      />
    );
  };
