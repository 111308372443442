import Button from '~/neo-ui/packages/button/Button';
import Testable from '~/neo-ui/packages/testable/Testable';
import { useState } from 'react';
import AutoEmailReportDeleteModal from './packages/auto-email-report-delete-modal/AutoEmailReportDeleteModal';

export type AutoEmailReportDeleteButtonProps = {
  autoEmailReportId: string;
  reportTitle: string;
  onDelete: () => void;
};

const AutoEmailReportDeleteButton = ({ autoEmailReportId, reportTitle, onDelete }: AutoEmailReportDeleteButtonProps) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <Testable testId={'auto-email-report-delete-button'}>
      <Button
        iconLeft={'Trash'}
        theme={'negative'}
        onClick={() => setModalOpen(true)}
      >
        Delete Report
      </Button>
      <AutoEmailReportDeleteModal
        autoEmailReportId={autoEmailReportId}
        reportTitle={reportTitle}
        isOpen={isModalOpen}
        onDismiss={() => setModalOpen(false)}
        onDelete={onDelete}
      />
    </Testable>
  );
};

export default AutoEmailReportDeleteButton;
