import * as React from 'react';
import { FunctionComponent, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import EngagementActionListMutationContext from './EngagementActionListMutationContext';
import EngagementActionListContext from './EngagementActionListContext';
import {
  EngagementActionDto,
  EngagementActionStatusAvailabilityDto,
  EngagementActionStatusCountDto,
} from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import usePanelTabGroupContext from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/hooks/usePanelTabGroupContext';
import { EngagementType } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/EngagementDashboard';
import {
  organizationEngagementActionInitiativeList,
  organizationEngagementActionListDashboardGet,
  organizationEngagementActionListGet,
} from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useEngagementListPageTicketCreateContext from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/context/hooks/useEngagementListPageTicketCreateContext';
import useEngagementListPageInitiativeLinkContext from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/context/hooks/useEngagementListPageInitiativeLinkContext';
import usePollingResponseDetectChange from '~/extensions/packages/polling/hooks/usePollingResponseDetectChange';
import useApi from '~/wm/packages/api/hook/useApi';

export type ActionCreateAnimation = {
  actionId: string;
  hasAnimated: boolean;
};

export type EngagementActionGetSource =
  | EngagementActionGetSourceList
  | EngagementActionGetSourceDashboard
  | EngagementActionGetSourceInitiative;
export type EngagementActionGetSourceList = { type: 'List' };
export type EngagementActionGetSourceDashboard = { type: 'Dashboard' };
export type EngagementActionGetSourceInitiative = { type: 'Initiative'; id: string };

export type EngagementActionListProviderProps = {
  engagementActionGetSource: EngagementActionGetSource;
};

const EngagementActionListProvider: FunctionComponent<PropsWithChildren<EngagementActionListProviderProps>> = ({
  engagementActionGetSource,
  children,
}) => {
  const { callApi } = useApi();

  /**
   * WARNING: Very specific state to enable windows to not close
   *
   * Read more about it with this GUID: 32c335c4-5722-4c59-af22-e48551f59474
   */
  const { selectedAction: selectedActionTicketCreation } = useEngagementListPageTicketCreateContext();
  const { selectedAction: selectedActionInitiativeLinking } = useEngagementListPageInitiativeLinkContext();

  const { organizationId } = useOrganizationContext();
  const { currentPageByTab, currentFilterValueByTab, currentTabId } = usePanelTabGroupContext<EngagementType>();

  const [actionCreateAnimationCache, setActionCreateAnimationCache] = React.useState<ActionCreateAnimation[]>([]);
  const [actions, setActions] = useState<EngagementActionDto[]>();
  const [statusAvailabilities, setStatusAvailabilities] = useState<EngagementActionStatusAvailabilityDto[]>();
  const [statusCounts, setStatusCount] = useState<EngagementActionStatusCountDto[]>();
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [shouldPoll, setShouldPoll] = useState(false);

  const fetchActionDashboardGetResponse = useCallback(async () => {
    const currentPage = currentPageByTab.get(currentTabId);
    const selectedFilterStatus = currentFilterValueByTab.get(currentTabId);

    if (typeof currentPage === 'undefined' || typeof selectedFilterStatus === 'undefined') {
      throw new Error('Current page or filter is undefined from tab context');
    }

    switch (engagementActionGetSource.type) {
      case 'List':
        return organizationEngagementActionListGet({
          organizationId,
          paginationPayload: {
            pageNumber: currentPage,
            filterStatus: selectedFilterStatus,
          },
        });
      case 'Dashboard':
        return organizationEngagementActionListDashboardGet({
          organizationId,
          paginationPayload: {
            pageNumber: currentPage,
            filterStatus: selectedFilterStatus,
            cachedActionIds: actionCreateAnimationCache.map(animation => animation.actionId),
          },
        });
      case 'Initiative':
        return organizationEngagementActionInitiativeList({
          organizationId,
          initiativeId: engagementActionGetSource.id,
        });
    }
  }, [actionCreateAnimationCache, currentFilterValueByTab, currentPageByTab, currentTabId, engagementActionGetSource, organizationId]);

  const reload = useCallback(async () => {
    const actionDashboardGetResponse = await callApi(() => fetchActionDashboardGetResponse());

    if (!actionDashboardGetResponse) {
      return;
    }

    setActions(actionDashboardGetResponse.actions);
    setStatusAvailabilities(actionDashboardGetResponse.statusAvailabilities);
    setStatusCount(actionDashboardGetResponse.statusCounts);
    setHasNextPage(actionDashboardGetResponse.hasNextPage);
    setShouldPoll(actionDashboardGetResponse.hasPendingTicketCreation);
  }, [callApi, fetchActionDashboardGetResponse]);

  useEffect(() => {
    reload();
  }, [reload, selectedActionTicketCreation, selectedActionInitiativeLinking]);

  usePollingResponseDetectChange(() => fetchActionDashboardGetResponse(), 'hasPendingTicketCreation', reload, 3000, shouldPoll);

  const addCreateAnimation = (actionId: string) => {
    setActionCreateAnimationCache(prev => [{ actionId, hasAnimated: false }, ...prev]);
  };

  const completeCreateAnimation = (actionId: string) => {
    setActionCreateAnimationCache(prev =>
      prev.map(createAnimation => {
        if (createAnimation.actionId === actionId) {
          return { ...createAnimation, hasAnimated: true };
        }
        return createAnimation;
      }),
    );
  };

  const clearCreateAnimationCache = () => {
    setActionCreateAnimationCache([]);
  };

  if (typeof actions === 'undefined' || typeof statusAvailabilities === 'undefined' || typeof statusCounts === 'undefined') {
    return null;
  }

  return (
    <EngagementActionListMutationContext.Provider
      value={{
        triggerEngagementActionListReload: () => reload(),
      }}
    >
      <EngagementActionListContext.Provider
        value={{
          actions,
          statusAvailabilities,
          statusCounts,
          hasNextPage,
          addCreateAnimation,
          completeCreateAnimation,
          actionCreateAnimationCache,
          clearCreateAnimationCache,
        }}
      >
        {children}
      </EngagementActionListContext.Provider>
    </EngagementActionListMutationContext.Provider>
  );
};

export default EngagementActionListProvider;
