import {
  HardwareDetails,
  HardwareSource,
  Property,
} from '@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Details/Model.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import DataTable, { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';

export type HardwareSourcesTableProps = {
  sources: HardwareSource[];
  resultantHardwareAsset: HardwareDetails;
};

const HardwareSourcesTable: React.FunctionComponent<HardwareSourcesTableProps> = ({ sources, resultantHardwareAsset }) => {
  const columns = React.useMemo<DataTableColumn<HardwareSource>[]>(
    () => [
      {
        Header: 'Source',
        fieldKey: asset => asset.sourceLabel,
        renderCell: asset => {
          const Container: React.FunctionComponent<React.PropsWithChildren> = ({ children }) =>
            asset.sourceUrl ? (
              <a
                href={asset.sourceUrl}
                target={'_blank'}
              >
                {children}
              </a>
            ) : (
              <>{children}</>
            );
          return (
            <Container>
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                `}
              >
                {asset.sourceLogo && (
                  <Icon
                    css={css`
                      margin-right: 0.4375rem;
                    `}
                    icon={asset.sourceLogo as IconType}
                  />
                )}
                <span>{asset.sourceLabel}</span>
              </div>
            </Container>
          );
        },
        Footer: <b>Final summary</b>,
      },
      {
        Header: 'ID',
        fieldKey: asset => asset.sourceId,
        renderCell: asset => asset.sourceId ?? '-',
        Footer: '',
      },
      {
        Header: 'Serial',
        fieldKey: asset => asset.row.serialNumber,
        renderCell: asset => <SourceCell property={asset.row.serialNumber} />,
        Footer: resultantHardwareAsset.serialNumber,
      },
      {
        Header: 'Name',
        fieldKey: asset => asset.row.name,
        renderCell: asset => <SourceCell property={asset.row.name} />,
        Footer: resultantHardwareAsset.name,
      },
      {
        Header: 'Client',
        fieldKey: asset => asset.row.organizationName,
        renderCell: asset => <SourceCell property={asset.row.organizationName} />,
        Footer: resultantHardwareAsset.organizationName,
      },
      {
        Header: 'Location',
        fieldKey: asset => asset.row.locationName,
        renderCell: asset => <SourceCell property={asset.row.locationName} />,
        Footer: resultantHardwareAsset.locationName,
      },
      {
        Header: 'User',
        fieldKey: asset => asset.row.lastLoginUser,
        renderCell: asset => <SourceCell property={asset.row.lastLoginUser} />,
        Footer: resultantHardwareAsset.lastLoginUser,
      },
      {
        Header: 'Type',
        fieldKey: asset => asset.row.hardwareType,
        renderCell: asset => <SourceCell property={asset.row.hardwareType} />,
        Footer: resultantHardwareAsset.hardwareType.label,
      },
      {
        Header: 'Manufacturer',
        fieldKey: asset => asset.row.manufacturerName,
        renderCell: asset => <SourceCell property={asset.row.manufacturerName} />,
        Footer: resultantHardwareAsset.manufacturerName,
      },
      {
        Header: 'Model',
        fieldKey: asset => asset.row.modelDisplay,
        renderCell: asset => <SourceCell property={asset.row.modelDisplay} />,
        Footer: resultantHardwareAsset.modelDisplay,
      },
      {
        Header: 'MAC',
        fieldKey: asset => asset.row.macAddresses,
        renderCell: asset => <SourceCell property={asset.row.macAddresses} />,
        Footer: resultantHardwareAsset.macAddresses.map(macAddress => (
          <React.Fragment key={macAddress}>
            {macAddress}
            <br />
          </React.Fragment>
        )),
      },
      {
        Header: 'Purchased',
        fieldKey: asset => asset.row.purchaseDate,
        renderCell: asset => <SourceCell property={asset.row.purchaseDate} />,
        Footer: resultantHardwareAsset.purchaseDate ?? '',
      },
      {
        Header: 'Warranty expiry',
        fieldKey: asset => asset.row.expiryDate,
        renderCell: asset => <SourceCell property={asset.row.expiryDate} />,
        Footer: resultantHardwareAsset.expiryDate ?? '',
      },
    ],
    [resultantHardwareAsset],
  );

  return (
    <DataTable
      title={'Sources'}
      description={'Review which integrations are providing data for this asset'}
      columns={columns}
      data={sources}
      EmptyStatePlaceholder={'No sources found.'}
    />
  );
};

export const SourceCell: React.FunctionComponent<{
  property: Property;
}> = ({ property }) =>
  typeof property.text !== 'undefined' ? (
    <span>
      {property.text?.split('\n').map(row => (
        <React.Fragment key={row}>
          <Label
            {...(!property.matchesResolvedHardware && {
              css: css`
                text-decoration: line-through;
              `,
              muted: true,
            })}
          >
            {row}
          </Label>
        </React.Fragment>
      ))}
    </span>
  ) : (
    <span>-</span>
  );

export default HardwareSourcesTable;
