import WizardPageStep from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageStep';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { BillingInfo as BillingInfoType } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import DisposalSelfCheckoutFormData from '~/wm/packages/disposal/types/DisposalSelfCheckoutFormData';
import ReturnAddressSection from '~/wm/packages/disposal/wizard/section/ReturnAddressSection';

const returnShippingAddressFieldKey: FieldKeyExpression<DisposalSelfCheckoutFormData> = values =>
  values.shipmentReturnAddress.billingAddress;

const addressIsValidFieldKey: FieldKeyExpression<DisposalSelfCheckoutFormData> = values => values.certification.addressIsValid;

const locationCertifiedFieldKey: FieldKeyExpression<DisposalSelfCheckoutFormData> = values => values.certification.locationCertified;

const phoneNumberFieldKey: FieldKeyExpression<DisposalSelfCheckoutFormData> = values => values.phoneNumber;

const phoneNumberIsValidKey: FieldKeyExpression<DisposalSelfCheckoutFormData> = values => values.isPhoneNumberValid;

const getDisposalSelfShippingStep = (
  billingInfo: BillingInfoType,
  dropOffLocationsUrl: string,
): WizardPageStep<DisposalSelfCheckoutFormData> => ({
  header: {
    label: 'Shipping',
    icon: 'Shipping',
  },
  fields: [returnShippingAddressFieldKey, addressIsValidFieldKey, locationCertifiedFieldKey, phoneNumberFieldKey],
  sections: [
    ({ isEditingIndex, onEditing }) =>
      ReturnAddressSection({
        billingInfo,
        dropOffLocationsUrl,
        returnShippingAddressFieldKey,
        addressIsValidFieldKey,
        locationCertifiedFieldKey,
        isEditingIndex,
        onEditing,
        phoneNumberFieldKey,
        phoneNumberIsValidKey,
      }),
  ],
  onCompleteStateChange: () => true,
  key: 'Shipping',
});

export default getDisposalSelfShippingStep;
