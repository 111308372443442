import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { coverageTransferDisposalOrderInfoGet } from '@WarrantyClient/WarrantyClientMsp.gen';
import { Response } from '@WarrantyClient/Warranty/Coverage/Packages/Transfer/Packages/DisposalOrder/CoverageTransferDisposalOrderInfoGetControllerNested.gen';

export type UseCoverageTransferDisposalOrderInfoGet = (serviceRequestId: string) => {
  disposalOrderInfoResponse: Response | undefined;
};

const useCoverageTransferDisposalOrderInfoGet: UseCoverageTransferDisposalOrderInfoGet = serviceRequestId => {
  const [disposalOrderInfo, setDisposalOrderInfo] = useState<Response>();
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() =>
        coverageTransferDisposalOrderInfoGet({
          serviceRequestId,
        }),
      );

      if (!response) {
        return;
      }

      setDisposalOrderInfo(response);
    })();
  }, [callApi, serviceRequestId]);

  return {
    disposalOrderInfoResponse: disposalOrderInfo,
  };
};

export default useCoverageTransferDisposalOrderInfoGet;
