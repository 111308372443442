import * as React from 'react';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import FormErrorMessage from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import ToggleSwitch from '~/neo-ui/packages/toggle/packages/toggle-switch/ToggleSwitch';
import FormDataMapper from '../../../../model/FormDataMapper';

export type FormToggleSwitchInputProps<TFormData, TFormDataValue> = {
  fieldKey: FieldKeyExpression<TFormData>;
  disabled?: boolean;
  formDataMapper?: FormDataMapper<TFormDataValue, boolean>;
  fixedErrorPosition?: boolean;
} & Themeable &
  Styleable;

const FormToggleSwitchInput = <TFormData, TFormDataValue>({
  theme = 'positive',
  fieldKey,
  disabled,
  formDataMapper,
  fixedErrorPosition = false,
  className,
}: FormToggleSwitchInputProps<TFormData, TFormDataValue>) => {
  const { getFormInput, setFormInput } = useFormContext<TFormData>();
  const checked = formDataMapper
    ? formDataMapper.fromFormData(getFormInput<TFormDataValue>(fieldKey).value)
    : getFormInput<boolean>(fieldKey).value;

  return (
    <div className={className}>
      <ToggleSwitch
        checked={checked}
        onChange={checked => {
          setFormInput<boolean | TFormDataValue>(fieldKey, formDataMapper ? formDataMapper.toFormData(checked) : checked);
        }}
        disabled={disabled}
        theme={theme}
      />
      <FormErrorMessage
        fieldKey={fieldKey}
        fixedPosition={fixedErrorPosition}
      />
    </div>
  );
};

export default FormToggleSwitchInput;
