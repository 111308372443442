import Testable from '~/neo-ui/packages/testable/Testable';
import WizardPageSubmitButton from '~/neo-ui/packages/wizard/packages/wizard-page/packages/wizard-page-submit-button/WizardPageSubmitButton';
import * as React from 'react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';

export type WizardPageSubmitControlProps = {
  submitLabel: string;
  submitIcon: IconType;
  isCurrentStepComplete: boolean | undefined;
  isDisabled?: boolean;
};

const WizardPageSubmitControl = ({ submitLabel, submitIcon, isCurrentStepComplete, isDisabled }: WizardPageSubmitControlProps) => ({
  expanded: (
    <Testable testId={'wizard-submit-button'}>
      <WizardPageSubmitButton
        label={submitLabel}
        icon={submitIcon}
        disabled={!isCurrentStepComplete || isDisabled}
      />
    </Testable>
  ),
});

export default WizardPageSubmitControl;
