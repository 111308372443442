import { AutoEmailReportDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';
import Box from '~/neo-ui/packages/box/Box';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import React from 'react';
import Badge from '~/neo-ui/packages/badge/Badge';
import useAutoEmailReportListContext from '~/wm/packages/organization/packages/auto-email-report/context/hooks/useAutoEmailReportListContext';

export type AutoEmailReportSettingsColumnsProps = {
  autoEmailReport: AutoEmailReportDto;
};

const AutoEmailReportSettingsColumns = ({ autoEmailReport }: AutoEmailReportSettingsColumnsProps) => {
  const { autoEmailReportAvailability } = useAutoEmailReportListContext();

  return (
    <Box
      padding={'padding200'}
      borderRadius={'radius400'}
    >
      <Label
        bold={true}
        color={autoEmailReport.isScheduled ? undefined : 'dark-900-32'}
        css={css`
          margin-bottom: 0.5rem;
        `}
      >
        Columns
      </Label>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
        `}
      >
        <Badge
          borderRadius={'radius200'}
          bgColor={'light-300'}
          textColor={'dark-700'}
        >
          Asset Name
        </Badge>
        <Badge
          borderRadius={'radius200'}
          bgColor={'light-300'}
          textColor={'dark-700'}
        >
          Serial
        </Badge>
        {autoEmailReport.reportQuery.selectedColumns?.map(column => (
          <Badge
            key={column.value}
            borderRadius={'radius200'}
          >
            {autoEmailReportAvailability.columns.find(columnAvailability => columnAvailability.key.value === column.value)?.label ??
              column.value}
          </Badge>
        ))}
      </div>
    </Box>
  );
};

export default AutoEmailReportSettingsColumns;
