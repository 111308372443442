// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.
import { TermInfo as SubscriptionClientSubscriptionPackagesPricingModelTermInfo, BillingInfo as SubscriptionClientSubscriptionPackagesPricingModelBillingInfo } from "@SubscriptionClient/Subscription/Packages/Pricing/Model.gen"
import { ProductAvailabilityPayload as SubscriptionClientSubscriptionPackagesUpgradeModelProductAvailabilityPayload, SelectedProductRegion as SubscriptionClientSubscriptionPackagesUpgradeModelSelectedProductRegion } from "@SubscriptionClient/Subscription/Packages/Upgrade/Model.gen"


export interface SubscriptionGrowAvailabilities
{
  /**
   * Availability info for each available product
   */
  productAvailabilityPayloads: SubscriptionClientSubscriptionPackagesUpgradeModelProductAvailabilityPayload[];
  /**
   * The term the subscription can grow into (null if they have one already)
   */
  term: SubscriptionClientSubscriptionPackagesPricingModelTermInfo | undefined;
  /**
   * Billing options available
   */
  billing: SubscriptionClientSubscriptionPackagesPricingModelBillingInfo;
}


export interface SubscriptionGrowAvailableAction
{
  /**
   * The type of action that is available
   */
  enum: SubscriptionGrowAvailableActionEnum;
  /**
   * Title for the action, e.g. "Grow your subscription"
   */
  title: string;
  /**
   * Main text for the action, e.g. "Get set up for the future with more assets"
   */
  text: string;
  /**
   * The call to action label, e.g. "Get started"
   */
  callToActionLabel: string;
}


export enum SubscriptionGrowAvailableActionEnum
{
  SelfServe = "SelfServe",
  Offer = "Offer",
}



export interface SubscriptionGrowPayload
{
  /**
   * Ids of the tiers to be grown to per product
   */
  productTierIds: string[];
  /**
   * The selected regions needed to configure the newly adopted products
   */
  selectedProductRegions: SubscriptionClientSubscriptionPackagesUpgradeModelSelectedProductRegion[];
}

