import useActiveUsersContext from '~/wm/packages/account/packages/user/context/hooks/useActiveUsersContext';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import calculateUserTheme from '~/wm/packages/account/packages/user/hooks/calculateUserTheme';
import { Styleable } from '~/neo-ui/model/capacity';
import MultiSelectSearch from '~/neo-ui/packages/select/packages/multi-select-search/MultiSelectSearch';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { UserStatus } from '@AssetManagementClient/BeastClient/Beast/Identity/Model/User.gen';
import formatUserNameDisplay from '~/wm/packages/account/packages/user/packages/active-users-search/formatUserNameDisplay';

export type ActiveUserSearchOption = {
  userId: string;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string;
  status: UserStatus;
};

export type ActiveUsersSearchProps = {
  inputLabel?: {
    label: string;
    icon: IconType;
  };
  menuCssPosition?: 'absolute' | 'relative';
  selectedUserIds: string[];
  onSelectedUsersChange: (selectedUsers: ActiveUserSearchOption[]) => void;
} & Styleable;

const ActiveUsersSearch = ({
  inputLabel,
  menuCssPosition = 'absolute',
  selectedUserIds,
  onSelectedUsersChange,
  className,
}: ActiveUsersSearchProps) => {
  const { users } = useActiveUsersContext();

  const userOptions: ActiveUserSearchOption[] = users.map(user => ({
    userId: user.userId,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    status: user.status,
  }));

  const selectedUserIdsRef = useRef(selectedUserIds);
  const selectedActiveUsers = users.filter(user => selectedUserIdsRef.current.includes(user.userId));
  const selectedUserOptions: ActiveUserSearchOption[] = selectedActiveUsers.map(user => ({
    userId: user.userId,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    status: user.status,
  }));

  const [availableUserSearchOptions, setAvailableUserSearchOptions] = React.useState<ActiveUserSearchOption[]>(userOptions);
  const [selectedUserSearchOptions, setSelectedUserSearchOptions] = React.useState<ActiveUserSearchOption[]>(selectedUserOptions);

  // Emit an event every time the selected users change
  useEffect(() => {
    onSelectedUsersChange(selectedUserSearchOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserSearchOptions]);

  // Reset the selected users if the initial selected user ids change
  useEffect(() => {
    if (JSON.stringify(selectedUserIds) === JSON.stringify(selectedUserIdsRef.current)) {
      return;
    }

    const selectedActiveUsers = users.filter(user => selectedUserIds.includes(user.userId));
    const selectedUserOptions: ActiveUserSearchOption[] = selectedActiveUsers.map(user => ({
      userId: user.userId,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      status: user.status,
    }));

    setSelectedUserSearchOptions(selectedUserOptions);

    selectedUserIdsRef.current = selectedUserIds;
  }, [selectedUserIds, users]);

  return (
    <MultiSelectSearch
      className={className}
      labelKey={'userId'}
      inputLabel={inputLabel}
      menuCssPosition={menuCssPosition}
      selected={selectedUserSearchOptions}
      options={availableUserSearchOptions}
      onResultsSelected={setSelectedUserSearchOptions}
      onClearFilter={() => {
        setAvailableUserSearchOptions(userOptions);
      }}
      onRemoveSelected={option => {
        setSelectedUserSearchOptions(selectedUserSearchOptions.filter(user => user.userId !== option.userId));
      }}
      onSearch={query => {
        const filteredUsers = userOptions.filter(user => {
          const searchAgainstString = `${user.firstName} ${user.lastName} ${user.email}`.toLowerCase();
          return searchAgainstString.includes(query.toLowerCase());
        });

        setAvailableUserSearchOptions(filteredUsers);
      }}
      renderSearchRow={user => (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
          `}
        >
          <Label
            bold={true}
            color={'dark-700'}
          >
            {formatUserNameDisplay(user)}
          </Label>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Label
              size={'sm'}
              color={'dark-700'}
            >
              {user.email}
              {user.status === UserStatus.Invited && ` (Invited)`}
            </Label>
          </div>
        </div>
      )}
      renderToken={{
        label: option => formatUserNameDisplay(option),
        calculateStyles: option => {
          const userTheme = calculateUserTheme(option.userId);

          return {
            backgroundColorRest: userTheme.backgroundColorRest,
            textColorRest: userTheme.textColorRest,
          };
        },
      }}
    />
  );
};
export default ActiveUsersSearch;
