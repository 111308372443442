import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { subscriptionDetailsPlanGet } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { SubscriptionDetailsPlanDto } from '@SubscriptionClient/BeastClient/Beast/Msp/Dto/Subscription/Pricing/Offer/Model.gen';

export type UseSubscriptionDetailsPlan = { detailsPlan: SubscriptionDetailsPlanDto | undefined };

const useSubscriptionDetailsPlan = (): UseSubscriptionDetailsPlan => {
  const [detailsPlan, setDetailsPlan] = React.useState<SubscriptionDetailsPlanDto>();
  const { callApi } = useApi();

  React.useEffect(() => {
    (async () => {
      const response = await callApi(() => subscriptionDetailsPlanGet({}));
      if (!response) {
        return;
      }
      setDetailsPlan(response.detailsPlan);
    })();
  }, [callApi]);

  return { detailsPlan };
};

export default useSubscriptionDetailsPlan;
