import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import InsightInformation from '~/wm/packages/strategy/packages/initiative/packages/add-to-initiative/types/InsightInformation';
import { InitiativeAssetsAddedSource, InitiativeCreateSource } from '~/wm/packages/strategy/model/strategyDataCollectionSources';
import { Enum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import RegionalSettingsInfoProvider from '~/wm/packages/settings/packages/regional-settings/context/RegionalSettingsInfoProvider';
import InitiativeListSelectModal from '~/wm/packages/strategy/packages/initiative/packages/initiative-list-select-modal/InitiativeListSelectModal';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import useInitiativeCreate from '~/wm/packages/strategy/packages/initiative/hooks/useInitiativeCreate';
import useInitiativeAssetAddBulk from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/hooks/useInitiativeAssetAddBulk';
import useInitiativeList from '~/wm/packages/strategy/packages/initiative/hooks/useInitiativeList';
import { InitiativeAssetSourcesPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import InitiativeAssetInfoCard from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/packages/initiative-asset-info-card/InitiativeAssetInfoCard';

export type AddToInitiativeButtonProps = {
  organizationId: string;
  insightInformation?: InsightInformation;
  insights?: string[];
  disabled?: boolean;
  createdFrom: InitiativeCreateSource;
  assetsAddedFrom: InitiativeAssetsAddedSource;
  selectedIds?: Set<string>;
};

const getInitiativeCreateModalHeader = (assetSources: InitiativeAssetSourcesPayloadDto, insightInformation?: InsightInformation) => {
  if (
    typeof assetSources.assetIds === 'undefined' ||
    assetSources.assetIds.length === 0 ||
    (typeof assetSources.assetIds !== 'undefined' && assetSources.assetIds.length === 1)
  ) {
    // We know there's only a single asset
    return {
      title: 'Take action on this asset',
      description: 'Add this asset to one of this client’s existing Initiatives, or create a new one.',
      assetInfoCard: <InitiativeAssetInfoCard assetCount={1} />,
    };
  }

  // We don't know the exact # of assets
  return {
    title: 'Take action on these assets',
    description: 'Add assets from this Insight to one of this client’s existing Initiatives, or create a new one.',
    assetInfoCard:
      typeof insightInformation !== 'undefined' ? (
        <InitiativeAssetInfoCard
          assetCount={insightInformation.assetCount}
          insightName={insightInformation.name}
        />
      ) : undefined,
  };
};

const AddToInitiativeButton: React.FunctionComponent<AddToInitiativeButtonProps> = ({
  organizationId,
  insightInformation,
  insights,
  disabled,
  createdFrom,
  assetsAddedFrom,
  selectedIds,
}) => {
  const [isDisplayingModal, setDisplayModal] = React.useState(false);

  const { isCreating, createInitiative } = useInitiativeCreate();
  const { isAdding, assetAddBulk } = useInitiativeAssetAddBulk();
  const { initiatives } = useInitiativeList(organizationId);

  const assetSources: InitiativeAssetSourcesPayloadDto = {
    assetIds: selectedIds ? Array.from(selectedIds) : [],
    assetIdsFrom: selectedIds ? assetsAddedFrom : undefined,
    insightIds: insights ?? [],
    insightIdsFrom: assetsAddedFrom,
  };
  const displayHeader = getInitiativeCreateModalHeader(assetSources, insightInformation);

  if (typeof initiatives === 'undefined') {
    return null;
  }

  return (
    <React.Fragment>
      <RegionalSettingsInfoProvider
        frontendScope={{
          type: Enum.Organization,
          organizationId,
        }}
      >
        <Button
          iconLeft={'InitiativeAdd'}
          theme={'primary'}
          onClick={() => setDisplayModal(true)}
          disabled={disabled}
        >
          Add to Initiative…
        </Button>
        <InitiativeListSelectModal
          allowRedirect={true}
          title={displayHeader.title}
          description={
            <div>
              {' '}
              <Label
                css={css`
                  margin-bottom: 1.25rem;
                `}
                size={'md'}
              >
                {displayHeader.description}
              </Label>
              {displayHeader.assetInfoCard && <div> {displayHeader.assetInfoCard} </div>}
            </div>
          }
          insightInformation={insightInformation}
          isLoading={isCreating || isAdding}
          isOpen={isDisplayingModal}
          onDismiss={() => setDisplayModal(false)}
          initiativeSelectOptions={initiatives.map(initiative => ({
            initiativeId: initiative.id,
            name: initiative.name,
            budgetQuarter: initiative.budgetQuarter,
          }))}
          onExistingInitiativeSave={(selectedInitiativeId, shouldRedirect) => {
            assetAddBulk(selectedInitiativeId, organizationId, shouldRedirect, assetSources);
          }}
          onNewInitiativeSave={(selectedTemplate, shouldRedirect) => {
            createInitiative(
              organizationId,
              shouldRedirect,
              createdFrom,
              selectedTemplate
                ? {
                    id: selectedTemplate.initiativeTemplateId,
                    name: selectedTemplate.name,
                  }
                : undefined,
              assetSources,
            );
          }}
        />
      </RegionalSettingsInfoProvider>
    </React.Fragment>
  );
};

export default AddToInitiativeButton;
