import { css } from '@emotion/react';
import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import useScorecardItemCreate from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/hooks/useScorecardItemCreate';
import Testable from '~/neo-ui/packages/testable/Testable';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Icon from '~/neo-ui/packages/icon/Icon';

export type ScorecardItemCreateCardProps = {
  scorecardId: string;
  scorecardCategoryId?: string;
  scorecardCategoryLabel?: string;
};

const ScorecardItemCreateCard = ({ scorecardId, scorecardCategoryId, scorecardCategoryLabel }: ScorecardItemCreateCardProps) => {
  const { createScorecardItem } = useScorecardItemCreate();

  const onCreate = React.useCallback(async () => {
    createScorecardItem({ scorecardId, scorecardCategoryId });
  }, [createScorecardItem, scorecardId, scorecardCategoryId]);

  return (
    <Testable testId={'scorecard-item-create-card'}>
      <Card
        padding={'xs'}
        shadow={'none'}
        backgroundColor={'light-100'}
        onClick={() => onCreate()}
        css={css`
          border: 0.0625rem dashed ${colorToCode('dark-900-24')};
          margin-top: 0.625rem;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Icon
            icon={'ScorecardItem'}
            color={'primary-400'}
            sizePx={12}
            css={css`
              margin-left: 0.375rem;
              margin-right: 1.125rem;
            `}
          />
          <Label color={'primary-400'}>Add Item to {scorecardCategoryLabel}</Label>
          <Icon
            icon={'ArrowDown'}
            color={'primary-400'}
            css={css`
              margin-left: 0.375rem;
            `}
          />
        </div>
      </Card>
    </Testable>
  );
};

export default ScorecardItemCreateCard;
