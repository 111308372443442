import { useCallback } from 'react';
import { DisposalDynamicPricingDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Dto/Model.gen';

export type UseDisposalDynamicPricingCalculator = ({
  assetCountUntilFreeShipping,
  shippingCostSubunits,
  marginalCostSubunitsPerAsset,
}: UseDisposalDynamicPricingCalculatorProps) => {
  disposalCostSubunits: number;
  shippingCostSubunits: number;
  totalCostSubunits: number;
};

export type UseDisposalDynamicPricingCalculatorProps = {
  assetCount: number;
} & DisposalDynamicPricingDto;

const useDisposalDynamicPricingCalculator: UseDisposalDynamicPricingCalculator = ({
  assetCount,
  assetCountUntilFreeShipping,
  shippingCostSubunits,
  marginalCostSubunitsPerAsset,
}) => {
  // Calculate price of shipping
  const shipping = assetCountUntilFreeShipping > assetCount ? shippingCostSubunits : 0;

  // Calculate the price of the disposal
  const calculateDisposalCostSubunits = useCallback(() => {
    let total = 0;
    let lastPriceSubunits = 0;

    /**
     * For every asset, search if there is a new marginal price for it
     * If there is a price use that
     * If there is not a price use the last known price
     *
     * Add it to the total
     */
    for (let i = 1; i <= assetCount; i++) {
      lastPriceSubunits = marginalCostSubunitsPerAsset[i] ? marginalCostSubunitsPerAsset[i] : lastPriceSubunits;

      total += lastPriceSubunits;
    }

    return total;
  }, [assetCount, marginalCostSubunitsPerAsset]);

  const disposalCostSubunits = calculateDisposalCostSubunits();

  return {
    shippingCostSubunits: shipping,
    disposalCostSubunits,
    totalCostSubunits: disposalCostSubunits + shipping,
  };
};

export default useDisposalDynamicPricingCalculator;
