import { DmiTrendDto } from '@AssetManagementClient/AssetManagement/Packages/Dmi/Controller/Dto.gen';
import { DmiScoreDto } from '@AssetManagementClient/AssetManagement/Packages/Dmi/Model.gen';
import {
  assetHardwareStatsDeviceTypeScopedGet,
  dmiScoreScope,
  dmiTrendGet,
  strategyDashboardGet,
} from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { useState, useCallback, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { Response as AssetTypeCountsResponse } from '@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Stats/Controller/ScopedHardwareTypeStatsGetControllerNested.gen';
import { InsightDashboardDto } from '@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Model.gen';

export type UseStrategyDashboard = {
  presentationalInsightDashboard: InsightDashboardDto | undefined;
  presentationalScore: DmiScoreDto | undefined;
  assetTypeCounts: AssetTypeCountsResponse | undefined;
  dateToDmiScore: { [key in string]: DmiTrendDto } | undefined;
  reload: () => void;
};

export const useStrategyDashboard = (scope: FrontendScope): UseStrategyDashboard => {
  const [insightDashboard, setInsightDashboard] = useState<InsightDashboardDto | undefined>(undefined);
  const [dmiScore, setDmiScore] = useState<DmiScoreDto | undefined>(undefined);
  const [assetTypeCounts, setAssetTypeCounts] = useState<AssetTypeCountsResponse | undefined>(undefined);

  const [dateToDmiScore, setDateToDmiScore] = useState<{ [key in string]: DmiTrendDto } | undefined>(undefined);

  const { callApi } = useApi();

  const reload = useCallback(async () => {
    const dashboardResponse = await callApi(() =>
      strategyDashboardGet({
        frontendScope: scope,
      }),
    );
    const dmiScoreResponse = await callApi(() =>
      dmiScoreScope({
        scope,
      }),
    );
    const assetTypeCountsResponse = await callApi(() =>
      assetHardwareStatsDeviceTypeScopedGet({
        frontendScope: scope,
      }),
    );

    setInsightDashboard(dashboardResponse?.dashboard);
    setDmiScore(dmiScoreResponse?.score);
    setAssetTypeCounts(assetTypeCountsResponse);
  }, [callApi, scope]);

  useEffect(() => {
    reload();
  }, [reload]);

  useEffect(() => {
    (async () => {
      const response = await callApi(() =>
        dmiTrendGet({
          scope,
        }),
      );
      if (!response) {
        return;
      }
      setDateToDmiScore(response.trendData);
    })();
  }, [scope, callApi]);

  return {
    presentationalInsightDashboard: insightDashboard,
    presentationalScore: dmiScore,
    dateToDmiScore,
    assetTypeCounts,
    reload,
  };
};
