import { css } from '@emotion/react';
import { ButtonInternalColorMap, ButtonInternalDisplayDetails } from '~/neo-ui/packages/button/ButtonInternal';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ButtonInternalStylesProps = {
  iconCount: number;
  displayDetails: ButtonInternalDisplayDetails;
  colorMap: ButtonInternalColorMap;
};

const buttonInternalStyles = ({ iconCount, displayDetails, colorMap }: ButtonInternalStylesProps) => css`
  // white space styles
  margin: 0;
  ${typeof displayDetails.paddingRem === 'undefined' ? '' : `padding:${displayDetails.paddingRem}rem;`}

  // text styles
  font-weight: 400;
  font-size: ${displayDetails.fontSizeRem}rem;
  text-align: center;

  // shape styles
  appearance: none;
  height: ${displayDetails.heightRem}rem;
  // min-width is set to height to allow square shape for icon-only buttons
  min-width: ${displayDetails.heightRem}rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: all 150ms;

  // color styles
  box-shadow: inset 0 0 0 1px ${colorToCode(colorMap.borderColorRest)};
  background-color: ${colorToCode(colorMap.backgroundColorRest)};
  color: ${colorToCode(colorMap.textColorRest)};

  &.focus,
  &:focus {
    // important required to override bootstrap
    outline: none !important;
  }

  // disabled styles
  &[disabled],
  &.disabled {
    cursor: default;
    opacity: 0.2;
    user-select: none;
    pointer-events: none;
    tab-index: -1;
  }

  // interaction color styles
  &:not(:disabled) {
    &:hover {
      text-decoration: none;

      background-color: ${colorToCode(colorMap.backgroundColorHover)};
      color: ${colorToCode(colorMap.textColorHover)};
      box-shadow: inset 0 0 0 1px ${colorToCode(colorMap.borderColorHover)}, 0 1px 4px 0 ${colorToCode(colorMap.borderColorHover)};
    }

    &:focus {
      background-color: ${colorToCode(colorMap.backgroundColorFocus)};
      color: ${colorToCode(colorMap.textColorFocus)};
      box-shadow: inset 0 0 0 1px ${colorToCode(colorMap.borderColorFocus)}, 0 0 0 1px ${colorToCode(colorMap.borderColorFocus)};
    }

    &:active {
      background-color: ${colorToCode(colorMap.backgroundColorActive)};
      color: ${colorToCode(colorMap.textColorActive)};
      box-shadow: inset 0 0 0 1px ${colorToCode(colorMap.borderColorActive)};
    }
  }

  // children styles to allow ellipsis truncation
  > .button-children {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    // explicit width is needed for ellipsis truncation, width is 100% minus icons
    width: calc(100% - ${iconCount * (displayDetails.iconSizePx + 10)}px);
  }

  // children style for spacing
  > div {
    // display: inline-flex does not allow ellipsis truncation!
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.625rem;
    &:last-of-type {
      margin-right: 0;
    }
  }

  // Display inline-block property is not set by the user agent stylesheet in the case that the button is an <a/> tag.
  display: inline-block;
`;

export default buttonInternalStyles;
