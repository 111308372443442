import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import TreeImpactOrderSummaryCard from '~/wm/packages/tree-impact/packages/tree-impact-order-summary-card/TreeImpactOrderSummaryCard';

export type DisposalTreeImpactOrderSummaryCardProps = {
  serviceOrderId: string;
  helpGuideUrl: string;
} & Styleable;

const DisposalTreeImpactOrderSummaryCard: React.FunctionComponent<DisposalTreeImpactOrderSummaryCardProps> = ({
  serviceOrderId,
  helpGuideUrl,
  className,
}) => (
  <TreeImpactOrderSummaryCard
    className={className}
    serviceId={'disposal'}
    serviceOrderId={serviceOrderId}
    helpGuideUrl={helpGuideUrl}
  />
);

export default DisposalTreeImpactOrderSummaryCard;
