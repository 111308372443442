import { produce } from 'immer';
import { useEffect } from 'react';
import buildConsoleFilters from '~/neo-ui/packages/table/packages/async-filter-table/builder/buildConsoleFilters';
import { ConsoleFilters, Metadata, TableState } from '../filter-table/FilterTable';

export const searchContextUrlParam = 'SearchContext';
export const searchQueryUrlParam = 'SearchQuery';
const optionSeparator = '.';

const useUrlTableState = <T>(
  onUrlStateProcessed: (tableState: TableState<T>) => void,
  onSearchQueryProcessed: (query: string) => void,
  consoleFilterUpdate: (consoleFilters: ConsoleFilters) => void,
  metadata: Metadata | undefined,
  defaultTableState: TableState<T>,
  tableState: TableState<T> | undefined,
) => {
  // Initialize state based on URL query
  useEffect(() => {
    if (!('URLSearchParams' in window)) {
      // Supported by modern browsers
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);

    if (!metadata) {
      return;
    }

    // Enrich the default table state with the URL query params
    onUrlStateProcessed(
      produce(defaultTableState, draft => {
        // Set filter state from url
        (() => {
          const allAvailableFilters = [...(metadata.filterSet.scopedFilters ?? []), ...metadata.filterSet.filters];

          if (!draft.filters || (allAvailableFilters.length === 0 && !metadata.supportsGlobalSearch)) {
            // No filtering, ignore query (tolerant for backwards compatibility)
            return;
          }

          const urlSearchContextValue = urlParams.get(searchContextUrlParam);
          if (metadata.filterSet.searchContexts && urlSearchContextValue) {
            draft.filters.selectedSearchContextValue = urlSearchContextValue;
          }

          const urlSearchQueryValue = urlParams.get(searchQueryUrlParam);
          if (urlSearchQueryValue !== null) {
            draft.filters.searchQuery = urlSearchQueryValue;
            onSearchQueryProcessed(urlSearchQueryValue);
          }

          draft.filters.selectedFilters = new Map(
            allAvailableFilters
              .map<[string, Set<string>]>(filter => {
                const urlParam = urlParams.get(filter.value)?.trim();
                const paramValues = urlParam && urlParam.length > 0 ? urlParam.split(optionSeparator) : filter.defaultOptions ?? [];

                return [filter.value, new Set(paramValues)];
              })
              // When there are no values selected, the API expects such filters
              // to not be in the map (rather than be present with no values).
              // Same logic here: {ececef23-8d1a-4543-9e82-6b6175339158}
              .filter(([_, values]) => values.size > 0),
          );
        })();
      }),
    );

    if (typeof tableState !== 'undefined') {
      consoleFilterUpdate(buildConsoleFilters(tableState));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata?.filterSet]);

  // Update URL and console filter in accordance with query state
  useEffect(() => {
    if (!('URLSearchParams' in window)) {
      // Supported by modern browsers
      return;
    }

    if (!metadata?.filterSet || !tableState) {
      return;
    }

    consoleFilterUpdate(buildConsoleFilters(tableState));

    const urlParams = new URLSearchParams(window.location.search);
    if (tableState.filters?.selectedSearchContextValue) {
      urlParams.set(searchContextUrlParam, tableState.filters.selectedSearchContextValue);
    } else {
      urlParams.delete(searchContextUrlParam);
    }

    if (tableState.filters?.searchQuery) {
      urlParams.set(searchQueryUrlParam, tableState.filters.searchQuery);
    } else {
      urlParams.delete(searchQueryUrlParam);
    }

    [...(metadata.filterSet.scopedFilters ?? []), ...metadata.filterSet.filters].forEach(filter => {
      const filterOption = tableState.filters?.selectedFilters.get(filter.value);
      if (filterOption && filterOption.size > 0) {
        urlParams.set(filter.value, [...filterOption].join(optionSeparator));
      } else {
        urlParams.delete(filter.value);
      }
    });

    const urlParamQueryString = urlParams.toString();
    const newRelativePathQuery =
      window.location.pathname + (urlParamQueryString.length > 0 ? '?' + urlParamQueryString : '') + window.location.hash;

    history.replaceState(null, '', newRelativePathQuery);
  }, [consoleFilterUpdate, metadata?.filterSet, tableState]);
};

export default useUrlTableState;
