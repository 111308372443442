import { contractSync } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useContractListMutation, {
  UseContractListMutationOptions,
} from '~/wm/packages/strategy/packages/contract/packages/contract-list/hooks/useContractListMutation';
import { ContractSyncPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Dto/Model.gen';

export type UseContractSync = (options?: UseContractListMutationOptions) => {
  syncContracts: (contractSyncPayload: ContractSyncPayloadDto, isRequestActive?: () => boolean) => void;
  isSyncingContracts: boolean;
};

const useContractSync: UseContractSync = options => {
  const [syncContracts, isSyncingContracts] = useContractListMutation(
    (contractSyncPayload: ContractSyncPayloadDto) => contractSync({ contractSyncPayload }),
    options,
  );

  return { syncContracts, isSyncingContracts };
};

export default useContractSync;
