import React, { ReactNode } from 'react';
import RoadmapFeatureFlagsContext, {
  RoadmapFeatureFlag,
} from '~/wm/packages/strategy/packages/roadmap-page/context/RoadmapFeatureFlagsContext';

// eslint-disable-next-line @typescript-eslint/ban-types
export type RoadmapFeatureFlagsProviderProps = {
  enabledLmRoadmapsPdfOverview: boolean;
  enabledInitiativeTickets: boolean;
  children: ReactNode;
};

const RoadmapFeatureFlagsProvider = ({
  enabledLmRoadmapsPdfOverview,
  enabledInitiativeTickets,
  children,
}: RoadmapFeatureFlagsProviderProps) => {
  const isFeatureFlagEnabled = (flag: RoadmapFeatureFlag): boolean => {
    switch (flag) {
      case 'lm-roadmaps-pdf-overview':
        return enabledLmRoadmapsPdfOverview;
      case 'lm-initiative-tickets':
        return enabledInitiativeTickets;
      default:
        return false;
    }
  };

  return (
    <RoadmapFeatureFlagsContext.Provider
      value={{
        isFeatureFlagEnabled,
      }}
    >
      {children}
    </RoadmapFeatureFlagsContext.Provider>
  );
};

export default RoadmapFeatureFlagsProvider;
