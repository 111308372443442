import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { SaasAssetDto } from '@BeastClient/Beast/Saas/Packages/SaasAsset/Dto/Model.gen';
import { saasSassAssetDashboardList } from '@BeastClient/BeastClientMsp.gen';

export type UseSaasAssetDashboardList = (
  organizationId: string,
  shouldIncludeIsTrialFreeProduct: boolean,
) => {
  saasAssetList: SaasAssetDto[] | undefined;
};

const useSaasAssetDashboardList: UseSaasAssetDashboardList = (organizationId, shouldIncludeIsTrialFreeProduct) => {
  const [saasAssetList, setSaasAssetList] = useState<SaasAssetDto[]>();
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() =>
        saasSassAssetDashboardList({
          organizationId,
          shouldIncludeIsTrialFreeProduct,
        }),
      );
      if (!response) {
        return;
      }
      setSaasAssetList(response.saasAssetList);
    })();
  }, [callApi, organizationId, shouldIncludeIsTrialFreeProduct]);

  return {
    saasAssetList,
  };
};

export default useSaasAssetDashboardList;
