import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import SelectGroup from '~/neo-ui/packages/select/packages/select-group/SelectGroup';
import React from 'react';
import Theme from '~/neo-ui/packages/color/Theme';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';

type SelectionTypeButtonProps = {
  theme: Theme;
  allSelectionTypes: string[];
  selectedType: string;
  handleSelectedTypeChange: (typeOption: string) => void;
};

const SelectionTypeButton = ({ theme, allSelectionTypes, selectedType, handleSelectedTypeChange }: SelectionTypeButtonProps) => {
  const { themeMap } = useTheme(theme);

  const typeOptions = allSelectionTypes.map(opt => ({
    label: opt,
    value: opt,
    disabled: false,
    selectedTheme: theme,
  }));
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
      `}
    >
      <Label
        heightSize={'md'}
        color={themeMap.foregroundAccent}
        css={css`
          padding-right: 0.313rem;
        `}
      >
        Matching
      </Label>
      {typeOptions.length > 1 ? (
        <SelectGroup
          options={typeOptions}
          selectedOptionValue={selectedType}
          onOptionSelected={opt => handleSelectedTypeChange(opt.value)}
          css={css`
            height: 9px;
          `}
        />
      ) : (
        <Label
          heightSize={'md'}
          color={themeMap.foregroundAccent}
          bold={true}
        >
          {allSelectionTypes[0] ?? 'any'}
        </Label>
      )}
    </div>
  );
};

export default SelectionTypeButton;
