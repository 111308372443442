import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';

export type ToggleItemProps = {
  onToggle?: () => void;
  checked: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
} & Styleable;

const ToggleItem = ({ onToggle = () => {}, checked, disabled = false, children, className }: ToggleItemProps) => (
  <label
    css={css`
      display: flex;
      align-items: center;
      margin: 0;
    `}
    className={className}
  >
    <input
      type={'checkbox'}
      onChange={onToggle}
      checked={checked}
      disabled={disabled}
    />
    {children}
  </label>
);

export default ToggleItem;
