import { IntegrationSetupPageSubSection } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/page-layout/IntegrationSetupPageSubSection';
import React from 'react';
import { ShowLessOrMoreButton } from '~/wm/packages/integration/packages/scalepad-account/packages/add-integration-page/ShowLessOrMoreButton';
import { IntegrationSetupPageSubSectionHeaderProps } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/page-layout/IntegrationSetupPageSubSectionHeader';
import Box from '~/neo-ui/packages/box/Box';
import { Styleable } from '~/neo-ui/model/capacity';
import { css } from '@emotion/react';
import AnimationHeight from '~/neo-ui/packages/animation/packages/animation-height/AnimationHeight';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

type IntegrationSetupPageSubSectionContainerProps = IntegrationSetupPageSubSectionHeaderProps & { children: React.ReactNode } & Styleable;

export const IntegrationSetupPageCustomizeSection: React.FunctionComponent<IntegrationSetupPageSubSectionContainerProps> = ({
  title,
  description,
  children,
  className,
}) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  return (
    <Box
      borderRadius={'radius300'}
      padding={'padding200'}
      className={className}
      css={css`
        :hover {
          ${isExpanded ? '' : `background-color: ${colorToCode('light-050')};`}
        }
      `}
    >
      <IntegrationSetupPageSubSection
        title={title}
        description={description}
        buttonDisplay={
          <ShowLessOrMoreButton
            expandedText={'Done'}
            collapsedText={'Customize'}
            isExpanded={isExpanded}
            onClick={() => {} /* Do nothing */}
          />
        }
        isExpanded={isExpanded}
        onClick={e => {
          setIsExpanded(s => !s);
          e.stopPropagation();
        }}
      >
        <AnimationHeight isExpanded={isExpanded}>
          <div
            css={css`
              padding-top: 1.5rem;
            `}
          >
            {children}
          </div>
        </AnimationHeight>
      </IntegrationSetupPageSubSection>
    </Box>
  );
};
