import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type SectionSubtitleProps = {
  subtitle: string;
} & Styleable;

const SectionSubtitle: React.FunctionComponent<React.PropsWithChildren<SectionSubtitleProps>> = ({ className, subtitle, children }) => (
  <div className={className}>
    <div
      css={css`
        color: ${colorToCode('dark-900-32')};
        font-size: 12px;
        font-weight: bolder;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 0.3125rem;
      `}
    >
      {subtitle}
    </div>
    <div>{children}</div>
  </div>
);

export default SectionSubtitle;
