import React from 'react';
import Window from '~/neo-ui/packages/window/Window';
import Button from '~/neo-ui/packages/button/Button';
import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';
import TicketCreateFormModule from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/packages/ticket-create-form-module/TicketCreateFormModule';

export type EngagementActionCreateTicketWindowProps = {
  fieldOptions: IntegrationFieldOptionDto[];
  isOpen: boolean;
  hasError?: boolean;
  isCreatingTicket: boolean;
  onDismiss: () => void;
  onCreateTicket: () => void;
};

const EngagementActionCreateTicketWindow = ({
  fieldOptions,
  isOpen,
  hasError,
  isCreatingTicket,
  onDismiss,
  onCreateTicket,
}: EngagementActionCreateTicketWindowProps) => (
  <Window
    title={'Create PSA Ticket'}
    isOpen={isOpen}
    onDismiss={onDismiss}
    titleIcon={'IntegrationTicket'}
    footerProps={{
      rightControls: [
        {
          expanded: (
            <Button
              theme={'primary'}
              onClick={() => {
                onCreateTicket();
              }}
              loading={isCreatingTicket}
              disabled={isCreatingTicket}
            >
              Save Ticket
            </Button>
          ),
        },
        {
          expanded: (
            <Button
              onClick={onDismiss}
              disabled={isCreatingTicket}
            >
              Cancel
            </Button>
          ),
        },
      ],
    }}
  >
    <TicketCreateFormModule
      hasError={hasError}
      fieldOptions={fieldOptions}
    />
  </Window>
);

export default EngagementActionCreateTicketWindow;
