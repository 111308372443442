import * as React from 'react';
import { useState } from 'react';
import { css } from '@emotion/react';
import CardButton from '~/legacy-ui/packages/card/packages/card-button/CardButton';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import Testable from '~/neo-ui/packages/testable/Testable';
import RoadmapPdfDownloadWindow from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap-share-button/packages/roadmap-pdf-download-modal/RoadmapPdfDownloadWindow';

export type RoadmapShareSectionProps = {
  organizationId: string;
  roadmapPreviewUrl: string;
  onDismiss: () => void;
};

const RoadmapShareSection = ({ organizationId, roadmapPreviewUrl, onDismiss }: RoadmapShareSectionProps) => {
  const { trackEvent } = useEventTracking(TrackingServices.EventProcessor);
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
      `}
    >
      <Testable testId={'roadmap-share-popup-start-presenting-button'}>
        <CardButton
          title={'Start presenting'}
          description={'Collaborate live with an interactive Roadmap'}
          icon={'Present'}
          onClick={() => {
            trackEvent('roadmap_presented', {
              obfuscatedClientId: organizationId,
            });

            window.open(roadmapPreviewUrl.replace('__ORGANIZATION_ID__', organizationId), '_blank');
          }}
          actionComponent={
            <div
              css={css`
                display: flex;
                gap: 0.3125rem;
                margin-left: auto;
                align-items: center;
              `}
            >
              <Label color={'primary-400'}>Open</Label>
              <Icon
                color={'primary-400'}
                icon={'GoExternal'}
              />
            </div>
          }
        />
      </Testable>

      <Testable testId={'roadmap-share-popup-download-pdf-button'}>
        <CardButton
          title={'Download PDF'}
          description={'Get a white-labeled PDF of your Roadmap to send to your client'}
          icon={'PDF'}
          onClick={() => {
            onDismiss();
            setDownloadModalOpen(true);
          }}
        />
      </Testable>

      <RoadmapPdfDownloadWindow
        onDismiss={() => {
          setDownloadModalOpen(false);
        }}
        isOpen={isDownloadModalOpen}
      />
    </div>
  );
};

export default RoadmapShareSection;
