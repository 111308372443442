import { InputTypeNested } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Credential.gen';
import { css } from '@emotion/react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import FormTextInput from '~/neo-ui/packages/form/packages/form-input/packages/form-text-input/FormTextInput';
import FormTextareaInput from '~/neo-ui/packages/form/packages/form-input/packages/form-textarea-input/FormTextareaInput';

import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';

export type RenderFormIntegrationFieldOptionInputTextProps<TFormData> = {
  fieldKey: FieldKeyExpression<TFormData>;
  data: InputTypeNested.Text;
};

const RenderFormIntegrationFieldOptionInputText = <TFormData,>({
  fieldKey,
  data,
}: RenderFormIntegrationFieldOptionInputTextProps<TFormData>) => {
  const { getFormInput } = useFormContext<TFormData>();
  const value = getFormInput<string>(fieldKey).value;

  const { maxLength } = data.validation;
  const isInput = (maxLength ?? 0) <= 100;
  const isTextArea = (maxLength ?? 0) > 100;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
      `}
    >
      {isInput && (
        <FormTextInput
          fieldKey={fieldKey}
          maxLength={maxLength}
        />
      )}
      {isTextArea && (
        <FormTextareaInput
          fieldKey={fieldKey}
          optimizePerformance={false}
          maxLength={maxLength}
        />
      )}
      {typeof maxLength !== 'undefined' && maxLength > 0 && (
        <Label
          color={value.length > maxLength ? 'negative-400' : undefined}
          css={css`
            text-align: right;
          `}
        >
          {value?.length ?? 0}/{maxLength}
        </Label>
      )}
    </div>
  );
};

export default RenderFormIntegrationFieldOptionInputText;
