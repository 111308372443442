import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import * as React from 'react';
import { css } from '@emotion/react';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import ToggleTileInternal, { ToggleTileProps } from '~/neo-ui/packages/toggle/packages/toggle-tile/ToggleTileInternal';

const ToggleTileVertical = ({
  isSelected = false,
  icon,
  title,
  onClick,
  tooltipContent,
  appendComponent,
  type,
  alert,
  disabled,
}: ToggleTileProps) => (
  <ToggleTileInternal
    isSelected={isSelected}
    onClick={onClick}
    type={type}
    disabled={disabled}
    css={css`
      width: 8.5rem;
      flex-direction: column;
      position: relative;
      gap: 0.8125rem;
    `}
  >
    {toggle => (
      <React.Fragment>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: 0;
            width: 100%;
            padding: 0.5rem;
          `}
        >
          <div>{typeof appendComponent !== 'undefined' && appendComponent}</div>
          <div
            css={css`
              display: flex;
              gap: 0.25rem;
            `}
          >
            {alert && (
              <Tooltip
                backgroundColor={'light-000'}
                placement={'top'}
                content={<Label color={alert.color ?? 'secondary-400'}>{alert.text}</Label>}
              >
                <Icon
                  icon={'Caution'}
                  sizePx={16}
                  color={alert.color ?? 'secondary-400'}
                  css={css`
                    display: flex;
                  `}
                />
              </Tooltip>
            )}
            {tooltipContent && (
              <Tooltip
                backgroundColor={'light-000'}
                content={tooltipContent}
                placement={'top'}
                tooltipCss={css`
                  width: 16rem;
                `}
              >
                <Icon
                  icon={'Info'}
                  sizePx={16}
                  color={'dark-900-32'}
                  css={css`
                    display: flex;
                  `}
                />
              </Tooltip>
            )}
          </div>
        </div>
        {typeof icon !== 'undefined' && (
          <Icon
            icon={icon}
            sizePx={40}
            color={disabled ? 'dark-900-64' : isSelected ? 'primary-400' : undefined}
          />
        )}
        <Label
          bold={true}
          color={disabled ? 'dark-900-64' : isSelected ? 'primary-400' : undefined}
          css={css`
            text-align: center;
          `}
        >
          {title}
        </Label>
        {toggle}
      </React.Fragment>
    )}
  </ToggleTileInternal>
);

export default ToggleTileVertical;
