import { DmiScoreDto } from '@AssetManagementClient/AssetManagement/Packages/Dmi/Model.gen';
import React from 'react';
import Testable from '~/neo-ui/packages/testable/Testable';
import { css } from '@emotion/react';
import DmiScore from '~/wm/packages/strategy/packages/dmi/packages/dmi-score/DmiScore';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { parseISO } from 'date-fns';
import DmiInfo from '~/wm/packages/strategy/packages/dmi/packages/dmi-info/DmiInfo';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import Box from '~/neo-ui/packages/box/Box';
import { Styleable } from '~/neo-ui/model/capacity';
import Divider from '~/neo-ui/packages/divider/Divider';
import AnimationHeight from '~/neo-ui/packages/animation/packages/animation-height/AnimationHeight';
import DmiTrend from '~/wm/packages/strategy/packages/dmi/packages/dmi-trend/DmiTrend';
import { DmiTrendDto } from '@AssetManagementClient/AssetManagement/Packages/Dmi/Controller/Dto.gen';

export type DmiContainerExpandableProps = {
  score: DmiScoreDto | undefined;
  guideUrl: string;
  dateToDmiScore: { [key in string]: DmiTrendDto } | undefined;
  isInsightsLoaded: boolean;
} & Styleable;

const DmiContainerExpandable = ({ score, guideUrl, dateToDmiScore, isInsightsLoaded, className }: DmiContainerExpandableProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  if (typeof score === 'undefined') {
    return <div />;
  }

  return (
    <Testable testId={'DMI-container'}>
      <Box
        borderRadius={'radius300'}
        padding={'padding100'}
        className={className}
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <Testable testId={'DMI-score'}>
          <DmiScore
            dmiScore={score.scoreOn10000}
            displayDmiScore={score.scoreDisplay}
            maxDmiScore={10000}
            themeColor={score.band.color as Color}
            bandLabel={score.band.label}
            lastUpdated={score.updatedAt !== undefined ? parseISO(score.updatedAt) : undefined}
            size={200}
          />
        </Testable>
        <ButtonLink
          iconRight={isExpanded ? 'ArrowUp' : 'ArrowDown'}
          onClick={() => setIsExpanded(prev => !prev)}
        >
          DMI Insights
        </ButtonLink>
        <AnimationHeight isExpanded={isExpanded}>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <Divider />
            <Testable testId={'DMI-info'}>
              <DmiInfo
                description={score.band.description?.text}
                css={css`
                  padding: 1rem;
                  color: ${colorToCode('dark-900-64')};
                `}
              />
            </Testable>
            <ButtonLink
              iconRight={'GoRight'}
              anchor={{
                href: guideUrl,
                openInNewTab: true,
              }}
              size={'sm'}
            >
              Learn more about DMI
            </ButtonLink>
            {typeof dateToDmiScore !== 'undefined' && isInsightsLoaded && (
              <div
                css={css`
                  display: flex;
                  height: 16.875rem;
                  margin-top: 1.875rem;
                  width: 100%;
                `}
              >
                <DmiTrend dateToDmiScore={dateToDmiScore} />
              </div>
            )}
          </div>
        </AnimationHeight>
      </Box>
    </Testable>
  );
};

export default DmiContainerExpandable;
