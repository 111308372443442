import * as React from 'react';
import { forwardRef, useEffect, useState } from 'react';
import useThemeInputTitle from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/hooks/useThemeInputTitle';
import useStylesInputTitle from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/hooks/useStylesInputTitle';
import { InputTitleSizes, inputTitleSizeToInputTitleDisplayDetails } from '~/neo-ui/packages/input/packages/input-title/InputTitle';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';

export type TableClientSideFilterInputProps = {
  value: string;
  onChange: (value: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  placeholder?: string;
  debounceMs?: number;

  /**
   * Size of the input text, 'xs' and 'xl' is unsupported
   *
   * Default is `md`
   */
  size?: InputTitleSizes;
} & Styleable &
  Themeable;

/**
 * Input for client side filtering
 * Should only be used in TableClientSide
 */
const TableClientSideFilterInput = forwardRef<HTMLInputElement, TableClientSideFilterInputProps>(
  (
    {
      value: initialValue,
      onChange,
      onFocus,
      onBlur,
      placeholder = 'Find in list...',
      debounceMs = 500,
      size = 'md',
      className,
      theme,
    }: TableClientSideFilterInputProps,
    ref,
  ) => {
    const { themeMap } = useThemeInputTitle(theme);
    const displayDetails = inputTitleSizeToInputTitleDisplayDetails(size);
    const { stylesInputTitle } = useStylesInputTitle(themeMap, displayDetails);

    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value);
      }, debounceMs);

      return () => clearTimeout(timeout);
    }, [debounceMs, onChange, value]);

    return (
      <input
        ref={ref}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value ?? ''}
        placeholder={placeholder}
        onChange={e => setValue(String(e.target.value))}
        css={stylesInputTitle}
        className={className}
      />
    );
  },
);

export default TableClientSideFilterInput;
