import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import * as React from 'react';
import useWizardPageContext from '~/neo-ui/packages/wizard/packages/wizard-page/context/hooks/useWizardPageContext';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import Box from '~/neo-ui/packages/box/Box';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import DisposalAssetTypeCountInput from './packages/disposal-asset-type-count-input/DisposalAssetTypeCountInput';
import FormArray from '~/neo-ui/packages/form/packages/form-input/packages/form-array/FormArray';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import DisposalAssetTypeCountFormData from '~/wm/packages/disposal/types/DisposalAssetTypeCountFormData';
import useDisposalCart from '~/wm/packages/disposal/hooks/useDisposalCart';
import DisposalSelfCheckoutFormData from '~/wm/packages/disposal/types/DisposalSelfCheckoutFormData';
import DisposalBulkCheckoutFormData from '~/wm/packages/disposal/types/DisposalBulkCheckoutFormData';
import SingleSelect from '~/neo-ui/packages/select/packages/single-select/SingleSelect';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import DisposalAssetCustomTypeCountInput from '~/wm/packages/disposal/wizard/section/packages/assets-section-asset-type-counts/packages/disposal-asset-type-count-input/DisposalAssetCustomTypeCountInput';
export type AssetsSectionAssetTypeCountsProps<T> = {
  assetTypeCountFieldKey: FieldKeyExpression<T>;
  disposalAssetTypeCountAvailabilities: DisposalAssetTypeAvailabilityDto[];
  assetCountMin: number;
};

const AssetsSectionAssetTypeCounts = <T,>({
  assetTypeCountFieldKey,
  disposalAssetTypeCountAvailabilities,
  assetCountMin,
}: AssetsSectionAssetTypeCountsProps<T>) => {
  const { setFieldCompleted } = useWizardPageContext();
  const { getFormInput, setFormInput, formData } = useFormContext<T>();
  const { trackEvent } = useEventTracking(TrackingServices.EventProcessor);
  const { updateDisposalCart } = useDisposalCart();
  const assetTypeCounts = getFormInput<DisposalAssetTypeCountFormData[]>(assetTypeCountFieldKey).value;

  const assetTypeCountsRef = React.useRef(assetTypeCounts);

  const isOtherAssetTypeCountInvalid =
    assetTypeCounts.filter(
      assetTypeCount =>
        assetTypeCount.allowMultipleCartEntries &&
        (typeof assetTypeCount.customizedType === 'undefined' ||
          assetTypeCount.customizedType === '' ||
          assetTypeCount.customizedType.length > 25),
    ).length > 0;

  const isAssetTypeCountInvalid =
    // All asset type counts must be valid positive numbers
    assetTypeCounts.filter(assetTypeCount => isNaN(assetTypeCount.count) || assetTypeCount.count < 1).length > 0 ||
    // There must be at least the minimum number of assets
    assetTypeCounts.reduce((sum, assetTypeCount) => sum + parseInt(String(assetTypeCount.count), 10), 0) < assetCountMin ||
    isOtherAssetTypeCountInvalid;

  React.useEffect(() => {
    setFieldCompleted(assetTypeCountFieldKey, !isAssetTypeCountInvalid);
  }, [assetTypeCountFieldKey, isAssetTypeCountInvalid, setFieldCompleted]);

  React.useEffect(() => {
    if (isAssetTypeCountInvalid || assetTypeCountsRef.current === assetTypeCounts) {
      return;
    }

    const disposalCartInfo = formData as DisposalSelfCheckoutFormData | DisposalBulkCheckoutFormData;

    updateDisposalCart(formData as DisposalSelfCheckoutFormData | DisposalBulkCheckoutFormData);

    const isSameAssetTypeCount: (lhs: DisposalAssetTypeCountFormData, rhs: DisposalAssetTypeCountFormData) => boolean = (lhs, rhs) =>
      lhs.type === rhs.type;

    const newAssetTypeCount = assetTypeCounts.filter(
      assetTypeCount => !assetTypeCountsRef.current.some(refAssetTypeCount => isSameAssetTypeCount(assetTypeCount, refAssetTypeCount)),
    )[0];

    if (typeof newAssetTypeCount !== 'undefined') {
      trackEvent('disposal_asset_type_added_to_cart', {
        scope: disposalCartInfo.type,
        disposalAssetType: newAssetTypeCount.type,
      });
    }

    assetTypeCountsRef.current = assetTypeCounts;
  }, [assetTypeCounts, formData, isAssetTypeCountInvalid, trackEvent, updateDisposalCart]);

  const options: SelectOption[] = disposalAssetTypeCountAvailabilities
    .filter(
      assetTypeCount =>
        !assetTypeCounts
          .filter(type => !type.allowMultipleCartEntries)
          .map(type => type.type)
          .includes(assetTypeCount.disposalAssetType),
    )
    .map(option => ({
      label: option.label,
      value: option.disposalAssetType,
      prependIcon: option.icon as IconType,
      labelColor: 'dark-900',
    }));

  return (
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
      `}
    >
      <Header
        size={4}
        weight={'bold'}
      >
        What are you disposing of?
      </Header>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 0.625rem;
        `}
      >
        <Label
          color={'dark-900-64'}
          size={'sm'}
        >
          Add assets in a supported location. Please include any power cords where possible. Feel free to include the assets' peripherals as
          well.
        </Label>
        <Tooltip
          backgroundColor={'light-000'}
          content={
            'Asset Disposal is currently supported for assets in the United States of America. Full Service Disposal is available everywhere except Hawaii and Alaska.'
          }
          placement={'top'}
          tooltipCss={css`
            width: 16rem;
          `}
        >
          <Icon
            icon={'Info'}
            sizePx={16}
            color={'dark-900-32'}
            css={css`
              display: flex;
            `}
          />
        </Tooltip>
      </div>
      <FormArray
        css={css`
          width: 100%;
        `}
        fieldKey={assetTypeCountFieldKey}
        arrayKey={element => element.type}
        initialValue={(): DisposalAssetTypeCountFormData => ({
          type: '',
          customizedType: undefined,
          count: 1,
          allowMultipleCartEntries: false,
        })}
        addButtonText={'Add asset type…'}
        shouldAlignBottom={true}
        addComponent={
          <SingleSelect
            style={'stack-add'}
            placeholder={
              <Label
                color={'primary-400'}
                css={css`
                  align-items: center;
                  display: flex;
                  gap: 0.75rem;
                  margin-left: 0.5rem;
                `}
              >
                <Icon
                  icon={'Add'}
                  color={'primary-400'}
                  sizePx={12}
                />
                Add…
              </Label>
            }
            options={options}
            selectedOption={null}
            isSearchable={false}
            shouldControlRenderValue={false}
            disabled={options.length === 0}
            onOptionSelected={option => {
              const newAssetTypeArray: DisposalAssetTypeCountFormData[] = [
                ...assetTypeCounts,
                {
                  type: option.value,
                  customizedType: undefined,
                  count: 1,
                  allowMultipleCartEntries:
                    disposalAssetTypeCountAvailabilities.find(
                      assetTypeAvailability => assetTypeAvailability.disposalAssetType === option.value,
                    )?.allowMultipleCartEntries ?? false,
                },
              ];
              setFormInput<DisposalAssetTypeCountFormData[]>(assetTypeCountFieldKey, newAssetTypeArray);
            }}
          />
        }
      >
        {(element, index) =>
          element.allowMultipleCartEntries ? (
            <DisposalAssetCustomTypeCountInput
              key={element.type}
              assetTypeCountsIndex={index}
              disposalAssetTypeCount={element}
              disposalAssetTypeCountAvailabilities={disposalAssetTypeCountAvailabilities}
              css={css`
                margin-left: 0.375rem;
              `}
            />
          ) : (
            <DisposalAssetTypeCountInput
              key={element.type}
              assetTypeCountsIndex={index}
              disposalAssetTypeCount={element}
              disposalAssetTypeCountAvailabilities={disposalAssetTypeCountAvailabilities}
              css={css`
                margin-left: 0.375rem;
              `}
            />
          )
        }
      </FormArray>
    </Box>
  );
};

export default AssetsSectionAssetTypeCounts;
