import * as React from 'react';
import DisableElementOverlay from '~/neo-ui/packages/overlay/DisableElementOverlay';
import { WizardPageSectionProps } from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageSection';

export type WizardPageStepContentProps = {
  onEditing: (isEditing: boolean) => void;
  sections: React.ComponentType<WizardPageSectionProps>[];
  isDisabled?: boolean;
};

const WizardPageStepContent = ({ onEditing, sections, isDisabled = false }: WizardPageStepContentProps) => {
  const [isEditingIndex, setIsEditingIndex] = React.useState<number | undefined>(undefined);

  return (
    <React.Fragment>
      {sections.map((Section, index) => (
        <DisableElementOverlay
          key={index}
          disabled={(typeof isEditingIndex !== 'undefined' && isEditingIndex !== index) || isDisabled}
        >
          <Section
            isEditingIndex={isEditingIndex}
            onEditing={isEditing => {
              setIsEditingIndex(isEditing ? index : undefined);
              onEditing(isEditing);
            }}
          />
        </DisableElementOverlay>
      ))}
    </React.Fragment>
  );
};

export default WizardPageStepContent;
