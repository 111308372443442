import * as React from 'react';
import Window from '~/neo-ui/packages/window/Window';
import Button from '~/neo-ui/packages/button/Button';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import RadioList from '~/neo-ui/packages/list/packages/radio-list/RadioList';
import { RadioListOption } from '~/neo-ui/packages/list/packages/radio-list/packages/radio-list-item/RadioListItem';
import AccountTeamMemberDeleteModal from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/packages/people-dashboard-dropdown/packages/people-dashboard-dropdown-account-team-member/packages/account-team-member-delete-modal/AccountTeamMemberDeleteModal';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import InputTitle from '~/neo-ui/packages/input/packages/input-title/InputTitle';
import usePeopleDashboardContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/context/hooks/usePeopleDashboardContext';
import { OrganizationAccountTeamMemberDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationAccountTeamMember/Dto/Model.gen';
import useOrganizationAccountTeamMemberRoleAdd from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/useOrganizationAccountTeamMemberRoleAdd';
import DisableElementOverlay from '~/neo-ui/packages/overlay/DisableElementOverlay';
import useOrganizationAccountTeamMemberEdit from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/useOrganizationAccountTeamMemberEdit';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import { AccountTeamMemberRoleAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationAccountTeamMember/Packages/AccountTeamMember/Packages/AccountTeamMemberRole/Dto/Model.gen';
import AccountTeamMemberRoleDeleteModal from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/packages/people-dashboard-dropdown/packages/people-dashboard-dropdown-account-team-member/packages/account-team-member-role-delete-modal/AccountTeamMemberRoleDeleteModal';
import formatUserNameDisplay from '~/wm/packages/account/packages/user/packages/active-users-search/formatUserNameDisplay';

type AccountTeamMemberEditWindowProps = {
  organizationAccountTeamMember: OrganizationAccountTeamMemberDto;
  isOpen: boolean;
  onDismiss: () => void;
};

const AccountTeamMemberEditWindow: React.FunctionComponent<AccountTeamMemberEditWindowProps> = ({
  organizationAccountTeamMember,
  isOpen,
  onDismiss,
}) => {
  const { organizationId } = useOrganizationContext();

  const { addOrganizationAccountTeamMemberRole, isAddingOrganizationAccountTeamMemberRole } = useOrganizationAccountTeamMemberRoleAdd();

  const { editOrganizationAccountTeamMember, isEditingOrganizationAccountTeamMember } = useOrganizationAccountTeamMemberEdit();

  const [isDeleteMemberModalOpen, setDeleteMemberModalOpen] = React.useState(false);

  const [isAddingRole, setAddingRole] = React.useState(false);
  const [newPositionName, setNewPositionName] = React.useState('');
  const isValidNewPositionName = newPositionName;

  const { roleAvailabilities } = usePeopleDashboardContext();

  const roleAvailabilityOptions: RadioListOption[] = roleAvailabilities.map(roleAvailability => ({
    label: roleAvailability.roleLabel,
    value: roleAvailability.accountTeamMemberRoleId,
    selectedTheme: 'primary',
    hideRemoveButton: roleAvailability.scopeValue !== 'Account',
  }));

  const [selectedRoleAvailabilityRemoval, setRoleAvailabilityRemoval] = React.useState<AccountTeamMemberRoleAvailabilityDto>();

  const [selectedOption, setSelectedOption] = React.useState(
    roleAvailabilityOptions.find(
      roleAvailabilityOption => roleAvailabilityOption.value === organizationAccountTeamMember.accountTeamMemberRoleId,
    )?.value,
  );

  const addRole = (newPositionName: string) => {
    if (!isValidNewPositionName) {
      return;
    }

    addOrganizationAccountTeamMemberRole(newPositionName);
    setNewPositionName('');
  };

  const saveRole = () => {
    if (typeof selectedOption !== 'undefined') {
      editOrganizationAccountTeamMember({
        organizationId,
        roleAssignPayload: {
          accountTeamMemberRoleId: selectedOption,
          accountTeamMemberId: organizationAccountTeamMember.accountTeamMemberId,
        },
      });
      onDismiss();
    }
  };

  return (
    <React.Fragment>
      <Window
        maxWidthRem={39.5}
        titleIcon={'Edit'}
        title={`Edit Member's Role`}
        isOpen={isOpen}
        onDismiss={onDismiss}
        footerProps={{
          leftControls: [
            {
              expanded: (
                <Button
                  iconLeft={'Trash'}
                  theme={'danger'}
                  onClick={() => {
                    setDeleteMemberModalOpen(true);
                  }}
                  disabled={isEditingOrganizationAccountTeamMember}
                >
                  Remove Member
                </Button>
              ),
            },
          ],
          rightControls: [
            {
              expanded: (
                <Button
                  theme={'primary'}
                  onClick={() => {
                    saveRole();
                  }}
                  loading={isEditingOrganizationAccountTeamMember}
                >
                  Save
                </Button>
              ),
            },
            {
              expanded: (
                <Button
                  onClick={() => {
                    onDismiss();
                  }}
                  disabled={isEditingOrganizationAccountTeamMember}
                >
                  Cancel
                </Button>
              ),
            },
          ],
        }}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 1rem;
            `}
          >
            <Icon icon={'Administrator'} />
            <Label>{formatUserNameDisplay(organizationAccountTeamMember)}</Label>
          </div>

          {typeof organizationAccountTeamMember.email !== 'undefined' && (
            <div
              css={css`
                display: flex;
                gap: 1rem;
              `}
            >
              <Icon icon={'Email'} />
              <Label>{organizationAccountTeamMember.email}</Label>
            </div>
          )}
        </div>

        <hr
          css={css`
            color: ${colorToCode('dark-900-12')};
          `}
        />

        <RadioList
          css={css`
            // To center the radios with the add new role button
            margin-left: 0.25rem;
          `}
          options={roleAvailabilityOptions}
          selectedOptionValue={selectedOption}
          onOptionSelected={option => setSelectedOption(option)}
          onOptionRemove={option => {
            setRoleAvailabilityRemoval(
              roleAvailabilities.find(roleAvailabilityOption => roleAvailabilityOption.accountTeamMemberRoleId === option),
            );
          }}
        />

        <DisableElementOverlay disabled={isAddingRole}>
          <div
            css={css`
              margin-top: 1rem;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 0.5rem;
              cursor: pointer;
            `}
            onClick={() => {
              setAddingRole(true);
            }}
          >
            <ButtonModifier
              css={css`
                margin-right: unset;
                min-width: unset;
                height: min-content;
              `}
              icon={'Add'}
            />
            <Label size={'md'}>Add New Role</Label>
          </div>
        </DisableElementOverlay>

        {isAddingRole && (
          <div
            css={css`
              margin-top: 1.875rem;
              display: flex;
              align-items: center;
              gap: 0.625rem;
            `}
          >
            <InputTitle
              size={'sm'}
              value={newPositionName}
              onChange={value => {
                setNewPositionName(value);
              }}
              isInvalid={!isValidNewPositionName}
              placeholder={'Enter a new role'}
            />

            <ButtonModifier
              css={css`
                margin-right: unset;
                min-width: unset;
                height: min-content;
              `}
              icon={'Cancel'}
              onClick={() => {
                setAddingRole(false);
              }}
              disabled={isAddingOrganizationAccountTeamMemberRole}
            />

            <ButtonModifier
              css={css`
                margin-right: unset;
                min-width: unset;
                height: min-content;
              `}
              theme={'primary'}
              icon={'Done'}
              disabled={isAddingOrganizationAccountTeamMemberRole}
              onClick={() => {
                addRole(newPositionName);
              }}
            />
          </div>
        )}
      </Window>
      {isDeleteMemberModalOpen && (
        <AccountTeamMemberDeleteModal
          organizationAccountTeamMember={organizationAccountTeamMember}
          isOpen={isDeleteMemberModalOpen}
          onDismiss={() => {
            setDeleteMemberModalOpen(false);
          }}
          onDelete={() => {
            onDismiss();
          }}
        />
      )}
      {selectedRoleAvailabilityRemoval && (
        <AccountTeamMemberRoleDeleteModal
          role={selectedRoleAvailabilityRemoval}
          isOpen={typeof selectedRoleAvailabilityRemoval !== 'undefined'}
          onDismiss={() => {
            setRoleAvailabilityRemoval(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default AccountTeamMemberEditWindow;
