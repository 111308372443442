import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Icon from '~/neo-ui/packages/icon/Icon';
import { RoadmapInitiativeViewMode } from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/RoadmapV2';
import { fiscalQuarterToColor } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/initiative-dashboard/packages/budget-quarter-container/BudgetQuarterContainer';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import RoadmapInitiativeTile from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-initiative-tile/RoadmapInitiativeTile';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import DropZone from '~/neo-ui/packages/drag-and-drop/packages/drop-zone/DropZone';
import DraggableElement from '~/neo-ui/packages/drag-and-drop/packages/draggable-element/DraggableElement';
import InitiativeDraftCreateButton from '~/wm/packages/strategy/packages/initiative/packages/initiative-draft/packages/initiative-draft-create-button/InitiativeDraftCreateButton';
import FiscalQuarter from '~/extensions/packages/date/packages/fiscal-quarter/FiscalQuarter';
import { buildRoadmapInitiativeKey } from '~/wm/packages/strategy/packages/roadmap-page/builder/buildRoadmapInitiativesByFiscalQuarter';
import { RoadmapInitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import { boxShadowToCode } from '~/neo-ui/packages/style/BoxShadow';
import useRoadmapInitiativesContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapInitiativesContext';
import { formatCurrencyByCurrencyInfo } from '~/extensions/packages/currency/formatCurrency';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';

export type RoadmapQuarterColumnProps = {
  initiatives: RoadmapInitiativeDto[];
  fiscalQuarter?: FiscalQuarter;
  isCurrentQuarter?: boolean;
  roadmapInitiativeViewMode: RoadmapInitiativeViewMode;
  isFiltered: boolean;
};

const RoadmapQuarterColumn = ({
  initiatives,
  fiscalQuarter,
  isCurrentQuarter,
  roadmapInitiativeViewMode,
  isFiltered,
}: RoadmapQuarterColumnProps) => {
  const { highlightedInitiativeIds } = useRoadmapInitiativesContext();
  const { currencyInfo } = useRegionalSettingsInfoContext();
  const isUnscheduled = typeof fiscalQuarter === 'undefined';

  return (
    <DropZone
      droppableId={buildRoadmapInitiativeKey(fiscalQuarter)}
      containerCssStates={{
        hoverCss: css`
          background-color: ${colorToCode(`primary-050`)} !important;
          border: 1px solid ${colorToCode('primary-500')} !important;
        `,
      }}
      css={css`
        display: flex;
        flex-direction: column;
        background-color: ${colorToCode('light-100')};
        border-radius: 0.5rem;
        min-height: 20rem;
        height: 100%;
        width: 100%;
        min-width: 18.125rem;
        align-self: flex-start;
        flex-grow: 1;
      `}
      settings={{ direction: 'vertical', isCombineEnabled: false }}
    >
      <div
        css={css`
          display: flex;
          align-items: baseline;
          padding: 1rem 1rem 0 1rem;
          gap: 0.5rem;
          color: ${colorToCode(fiscalQuarterToColor(fiscalQuarter?.quarter))};
          background-color: inherit;
          top: 0;
        `}
      >
        {isCurrentQuarter && (
          <Icon
            icon={'CurrentCalendarQuarter'}
            color={fiscalQuarterToColor(fiscalQuarter?.quarter)}
          />
        )}
        <Header
          size={4}
          weight={'bold'}
        >
          {isUnscheduled ? 'Not Scheduled' : `Q${fiscalQuarter.quarter}`}
        </Header>
        {!isUnscheduled && <Label size={'lg'}>{fiscalQuarter.year}</Label>}
        <div
          css={css`
            margin-left: auto;
          `}
        >
          <InitiativeDraftCreateButton
            type={'button-link'}
            fiscalQuarter={fiscalQuarter}
          />
        </div>
      </div>

      <Header
        size={6}
        color={'dark-900-64'}
        css={css`
          padding: 0 1rem;
        `}
      >
        {formatCurrencyByCurrencyInfo(
          initiatives.reduce((acc, cur) => acc + cur.sumBudgetSubunits, 0),
          {
            code: currencyInfo.countryCode,
            subunitsPerUnit: currencyInfo.subunitRatio,
          },
        )}{' '}
        |{' '}
        {formatCurrencyByCurrencyInfo(
          initiatives.reduce((acc, cur) => acc + cur.sumRecurringFees.sumRecurringMonthlySubunits, 0),
          {
            code: currencyInfo.countryCode,
            subunitsPerUnit: currencyInfo.subunitRatio,
          },
        )}
        /m |{' '}
        {formatCurrencyByCurrencyInfo(
          initiatives.reduce((acc, cur) => acc + cur.sumRecurringFees.sumRecurringYearlySubunits, 0),
          {
            code: currencyInfo.countryCode,
            subunitsPerUnit: currencyInfo.subunitRatio,
          },
        )}
        /y
      </Header>

      {initiatives.length > 0 ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            overflow-y: auto;
          `}
        >
          {initiatives.map((initiative, index) => (
            <DraggableElement
              key={initiative.initiativeId}
              index={index}
              draggableId={initiative.initiativeId}
              css={css`
                background-color: ${colorToCode(highlightedInitiativeIds.includes(initiative.initiativeId) ? 'primary-050' : 'light-000')};
                border-radius: 0.5rem;
                ${highlightedInitiativeIds.includes(initiative.initiativeId)
                  ? `border: 0.0625rem solid ${colorToCode('primary-500')};`
                  : ``}
                padding: 1rem 0.5rem;
                margin: 0.5rem 1rem;
                box-shadow: ${boxShadowToCode('shadow100')}; // 0 0.031rem 0.063rem 0 ${colorToCode('dark-900-24')};
                transition: all 300ms ease-in-out;

                &:hover {
                  cursor: pointer;
                  background-color: ${colorToCode('dark-900-08')};
                }
              `}
            >
              <RoadmapInitiativeTile
                key={initiative.initiativeId}
                initiative={initiative}
                roadmapInitiativeViewMode={roadmapInitiativeViewMode}
              />
            </DraggableElement>
          ))}
        </div>
      ) : (
        <div
          css={css`
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1.5rem;
          `}
        >
          <Label muted={true}>{isFiltered ? 'No results found for your selected filters.' : 'No initiatives created.'}</Label>
        </div>
      )}
    </DropZone>
  );
};

export default RoadmapQuarterColumn;
