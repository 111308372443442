import * as React from 'react';
import {
  IntegrationToolTypeDto,
  IntegrationVendorDto,
} from '@AssetManagementClient/BeastClient/Beast/Integration/Controller/IntegrationPage.gen';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import VendorAccordion from '~/wm/packages/integration/packages/scalepad-account/packages/add-integration-page/VendorAccordion';
import { produce } from 'immer';

// props
export type AddIntegrationPageDisplayProps = {
  toolTypeToIntegrationVendorDictionary: {
    [key: string]: IntegrationVendorDto[];
  };
  toolTypesDisplayInfo: { [key: string]: IntegrationToolTypeDto };
  forceExpansion: boolean;
};

const IntegrationList: React.FunctionComponent<AddIntegrationPageDisplayProps> = ({
  toolTypeToIntegrationVendorDictionary,
  toolTypesDisplayInfo,
  forceExpansion,
}) => {
  const visibleToolTypes = Object.keys(toolTypeToIntegrationVendorDictionary);
  return (
    <div
      css={css`
        background-color: ${colorToCode('light-200')};
        gap: 0.5rem;
        display: flex;
        flex-flow: column;
        row-gap: 2rem;
        padding: 2rem 0rem;
      `}
    >
      {visibleToolTypes.length === 0 || Object.keys(toolTypesDisplayInfo).length === 0 ? (
        // Placeholder empty state
        <div>No results found. Try a different search.</div>
      ) : (
        visibleToolTypes
          .sort((a, b) => toolTypesDisplayInfo[a].displayOrder - toolTypesDisplayInfo[b].displayOrder)
          .map(toolType => {
            const vendors = produce(toolTypeToIntegrationVendorDictionary[toolType], draft =>
              draft.sort((a, b) => a.displayPriority - b.displayPriority),
            );
            return (
              <VendorAccordion
                forceExpansion={forceExpansion}
                // The vendor list in the key forces the component to remount every time the vendor list changes.
                // That forces the component to re-evaluate whether it should display the "show more" button. Kind of a hack but it works.
                key={toolType + vendors.toString()}
                vendors={vendors}
                toolTypeLabel={toolType}
              />
            );
          })
      )}
    </div>
  );
};
export default IntegrationList;
