import { DisposalCartUpsertPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalCart/Dto.gen';
import disposalBulkCheckoutFormData from '~/wm/packages/disposal/types/DisposalBulkCheckoutFormData';

const buildDisposalCartUpsertPayloadDtoByBulkWizard = (disposalCartInfo: disposalBulkCheckoutFormData): DisposalCartUpsertPayloadDto => ({
  pickupSiteInformation: {
    assetLocation: disposalCartInfo.siteInformation.assetLocation,
    hasLoadingDock: disposalCartInfo.siteInformation.hasLoadingDock,
    isMultipleFloors: disposalCartInfo.siteInformation.isMultipleFloors,
    isAccessibleByElevator: disposalCartInfo.siteInformation.isAccessibleByElevator,
  },
  pickupMaterials: disposalCartInfo.materials,
  assetCountsPayload: disposalCartInfo.assetTypeCounts.map(typeCount => ({
    disposalAssetType: typeCount.type,
    count: Number(typeCount.count),
    label: typeCount.customizedType,
  })),
  packageAmount: 0,
});

export default buildDisposalCartUpsertPayloadDtoByBulkWizard;
