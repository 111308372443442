import * as React from 'react';
import { Themeable } from '~/neo-ui/model/capacity';
import Badge from '~/neo-ui/packages/badge/Badge';

export type RangeFilterBadgeProps = {
  unit: string;
  minValue?: number;
  maxValue?: number;
} & Themeable;

const RangeFilterBadge = ({ unit, minValue, maxValue }: RangeFilterBadgeProps) => {
  let label = '';

  // "greater than"
  if (minValue !== undefined && maxValue === undefined) {
    label = `Over ${minValue} ${unit}`;
  }
  // "less than"
  else if (minValue === undefined && maxValue !== undefined) {
    label = `Under ${maxValue} ${unit}`;
  }
  // "between"
  else if (minValue !== undefined && maxValue !== undefined) {
    label = `${minValue} - ${maxValue} ${unit}`;
  }

  return (
    <Badge
      borderRadius={'radius200'}
      bgColor={'secondary-600'}
    >
      {label}
    </Badge>
  );
};

export default RangeFilterBadge;
