import { useCallback, useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import {
  userOrganizationStarAdd,
  userOrganizationStarList,
  userOrganizationStarRemove,
} from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { UserOrganizationStarInfoDto } from '@AssetManagementClient/BeastClient/Beast/Identity/Packages/User/Packages/OrganizationStar/Dto.gen';

export type UseOrganizationStar = (isFreeEdition: boolean) => {
  starredOrganizations: Map<string, UserOrganizationStarInfoDto> | undefined;
  addStar: (organizationId: string) => Promise<void>;
  removeStar: (organizationId: string) => void;
};

const useOrganizationStar: UseOrganizationStar = isFreeEdition => {
  const [organizationStars, setOrganizationStars] = useState<Map<string, UserOrganizationStarInfoDto>>();

  const { callApi } = useApi();

  const addStar = useCallback(
    async (organizationId: string) => {
      const response = await callApi(() => userOrganizationStarAdd({ organizationId }));
      if (!response) {
        return;
      }
      setOrganizationStars(prev => {
        const newMap = new Map(prev);
        newMap.set(organizationId, response.userOrganizationStarInfo);
        return newMap;
      });
    },
    [callApi],
  );

  const removeStar = useCallback(
    async (organizationId: string) => {
      const response = await callApi(() => userOrganizationStarRemove({ organizationId }));
      if (!response) {
        return;
      }
      setOrganizationStars(prev => {
        const newMap = new Map(prev);
        newMap.delete(organizationId);
        return newMap;
      });
    },
    [callApi],
  );

  useEffect(() => {
    (async () => {
      if (isFreeEdition) {
        return;
      }
      const response = await callApi(() => userOrganizationStarList({}));
      if (!response) {
        return;
      }
      setOrganizationStars(
        new Map(response.userOrganizationStarList.map(organizationStar => [organizationStar.organizationId, organizationStar])),
      );
    })();
  }, [callApi, isFreeEdition]);

  return {
    starredOrganizations: organizationStars,
    addStar,
    removeStar,
  };
};

export default useOrganizationStar;
