import { InsightDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Dto/Model.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import List from '~/legacy-ui/packages/list/List';
import ListItem from '~/legacy-ui/packages/list/packages/list-item/ListItem';
import { Styleable } from '~/neo-ui/model/capacity';
import DataTable, { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import TableCell from '~/neo-ui/packages/table/packages/table-cell/TableCell';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import getInsightConsoleUrl from '~/wm/packages/strategy/packages/insight-console/getInsightConsoleUrl';
import useInsightList from '~/wm/packages/strategy/packages/insight-console/hooks/useInsightList';
import InsightDeleteButton from '~/wm/packages/strategy/packages/insight/packages/insight-delete-button/InsightDeleteButton';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Testable from '~/neo-ui/packages/testable/Testable';
import Spinner from '~/neo-ui/spinner/Spinner';

export type InsightListProps = {
  insightUrl: string;
  scope: FrontendScope;
} & Styleable;

const InsightList: React.FunctionComponent<InsightListProps> = ({ insightUrl, scope }) => {
  const { insights, refreshInsightList } = useInsightList();
  const insightColumns = React.useMemo<DataTableColumn<InsightDto>[]>(
    () => [
      {
        Header: 'Name & description',
        fieldKey: insight => insight.title,
        renderCell: insight => (
          <TableCell
            label={insight.title}
            labelColor={'primary-400'}
            labelUrl={getInsightConsoleUrl(scope, insight.id, insightUrl)}
            description={insight.description}
            descriptionMuted={true}
          />
        ),
      },
      {
        Header: 'Assets type',
        fieldKey: insight => insight.assetScopes,
        renderCell: insight => (
          <List
            items={insight.assetScopes}
            renderItem={assetScope => (
              <ListItem
                title={assetScope.assetScopeValueLabel}
                titleMuted={true}
                key={assetScope.assetScopeLabel}
              />
            )}
          />
        ),
      },
      {
        Header: 'Policies',
        fieldKey: insight => insight.policies,
        renderCell: insight => (
          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <div>
              <ul
                css={css`
                  padding: 0;
                  margin: 0;
                `}
              >
                {insight.policies.map(policy => (
                  <ListItem
                    title={'● ' + policy.policyName + ' ' + policy.operatorLabel + ' ' + policy.displayValue}
                    titleMuted={true}
                    key={insight.id + policy.policyName + policy.operatorLabel + policy.displayValue}
                  />
                ))}
              </ul>
            </div>
            <div>
              <InsightDeleteButton
                insightId={insight.id}
                insightName={insight.title}
                refreshAction={refreshInsightList}
              />
            </div>
          </div>
        ),
      },
    ],
    [scope, insightUrl, refreshInsightList],
  );

  if (typeof insights === 'undefined') {
    return <Spinner />;
  }

  return (
    <div>
      <div
        css={css`
          margin-bottom: 3rem;
        `}
      >
        <div
          css={css`
            margin: 0.3125rem;
          `}
        >
          <Testable testId={'insight-list-header'}>
            <Header
              size={2}
              weight={'bold'}
            >
              Manage your insights
            </Header>
          </Testable>
        </div>
        <Label
          css={css`
            margin: 0.3125rem 0.3125rem 1.25rem;
            border-bottom: 1px solid ${colorToCode('dark-900-12')};
          `}
          size={'md'}
          muted={true}
        >
          You'll see these insights in each client's Strategy view.
        </Label>
        <DataTable
          makeRowKey={insight => insight.id}
          columns={insightColumns}
          data={insights}
          EmptyStatePlaceholder={'No insights yet.'}
          enableClientSorted={true}
          defaultSort={{
            key: insight => insight.title,
            order: 'descending',
          }}
          sortableColumnKeys={['title', 'assetScopes']}
          isVerticalAligned={false}
        />
      </div>
    </div>
  );
};

export default InsightList;
