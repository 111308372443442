import { css } from '@emotion/react';
import * as React from 'react';

/**
 * Removes border from children elements to give a form group look
 * @param children joins FormTextInput, FormNumberInput, and Button together
 */
const JoinedInputGroup: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => (
  <div
    css={css`
      > * {
        > * {
          > input {
            border-radius: 0;
          }
        }

        &:first-of-type > * {
          > input {
            border-radius: 0.375rem 0.375rem 0 0;
          }
        }

        &:last-child > * {
          > input {
            border-radius: 0 0 0.375rem 0.375rem;
          }
        }
      }

      > button {
        border-radius: 0 0 0.375rem 0.375rem;
        width: 100%;
      }
    `}
  >
    {children}
  </div>
);

export default JoinedInputGroup;
