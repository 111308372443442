import { FieldKeyExpression, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { FieldArray } from 'formik';
import FormInputInternal from '~/neo-ui/packages/form/packages/form-input/FormInputInternal';
import { FormCheckboxDisplay } from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckBoxDisplay';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { css } from '@emotion/react';

export type FormCheckBoxGroupOption = { label: string; value: string; icon?: IconType; disabled?: boolean };

export type FormCheckboxGroupInputProps<TFormData, TFormDataValue = string[]> = {
  fieldKey: FieldKeyExpression<TFormData>;
  options: FormCheckBoxGroupOption[];

  /**
   * Given the form data, map to the input value
   */
  fromFormData?: (formDataValue: TFormDataValue[]) => string[];

  /**
   * Given a value being sent by the input, map it
   * to something else when propagating to form data.
   */
  toFormData?: (inputValue: string[]) => TFormDataValue[];

  display?: 'Horizontal' | 'Vertical';
};
/**
 * Checkbox group input that belongs as a child inside of a Form
 */
const FormCheckboxGroupInput = <TFormData, TFormDataValue = string[]>({
  fieldKey,
  options,
  toFormData,
  fromFormData,
  display = 'Horizontal',
}: FormCheckboxGroupInputProps<TFormData, TFormDataValue>) => {
  const inputId = resolveFieldKey(fieldKey);
  const { getFormInput, setFormInput } = useFormContext<TFormData>();
  const selectedOptions = fromFormData
    ? fromFormData(getFormInput(fieldKey).value as TFormDataValue[])
    : (getFormInput(fieldKey).value as string[]);

  return (
    <FormInputInternal
      fieldKey={fieldKey}
      inputProps={{
        type: 'checkbox',
        className: 'form-check-input',
      }}
    >
      {({ inputProps }) => (
        <FieldArray
          name={inputId}
          render={arrayHelpers => (
            <div
              css={css`
                display: flex;
                gap: 1.5rem;
                flex-direction: ${display === 'Vertical' ? 'column;' : 'row;'};
              `}
            >
              {options.map(option => (
                <FormCheckboxDisplay
                  key={option.value}
                  id={`${inputId}-${option.value}`}
                  disabled={option.disabled}
                  propsForHtmlInputComponent={inputProps}
                  onChange={e => {
                    const index = selectedOptions.indexOf(option.value);
                    if (e.target.checked) {
                      arrayHelpers.push(option.value);
                      setFormInput<TFormDataValue[]>(
                        fieldKey,
                        toFormData
                          ? toFormData([...selectedOptions, option.value])
                          : ([...selectedOptions, option.value] as TFormDataValue[]),
                      );
                    } else {
                      arrayHelpers.remove(index);
                      setFormInput<TFormDataValue[]>(
                        fieldKey,
                        toFormData
                          ? toFormData([...selectedOptions.filter(selectedOption => selectedOption !== option.value)])
                          : ([...selectedOptions.filter(selectedOption => selectedOption !== option.value)] as TFormDataValue[]),
                      );
                    }
                  }}
                  checked={selectedOptions.includes(option.value)}
                  description={option.label}
                  iconLeft={option.icon}
                />
              ))}
            </div>
          )}
        />
      )}
    </FormInputInternal>
  );
};

export default FormCheckboxGroupInput;
