import { shareAvailableRecipientsGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import InsightShareRecipient from '~/wm/packages/insight-share/model/InsightShareRecipient';

const useShareAvailableRecipients = () => {
  const { callApi } = useApi();
  const [availableRecipients, setAvailableRecipients] = useState<InsightShareRecipient[]>([]);

  useEffect(() => {
    (async () => {
      const response = await callApi(() => shareAvailableRecipientsGet({}));
      if (!response) {
        return;
      }
      const recipients = response.recipients.map(
        recipient =>
          ({
            userId: recipient.userId,
            firstName: recipient.firstName,
            lastName: recipient.lastName,
            fullName: `${recipient.firstName} ${recipient.lastName}`,
            email: recipient.email,
            isBouncing: recipient.isBouncing,
          } as InsightShareRecipient),
      );
      setAvailableRecipients(recipients);
    })();
  }, [callApi]);

  return { availableRecipients };
};

export default useShareAvailableRecipients;
