import Box from '~/neo-ui/packages/box/Box';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import * as React from 'react';
import Image from '~/neo-ui/packages/image/Image';
import { Styleable } from '~/neo-ui/model/capacity';
import { mobileBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import Button from '~/neo-ui/packages/button/Button';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type TreeImpactInfoFooterProps = {
  helpGuideUrl: string;
} & Styleable;

const TreeImpactInfoFooter: React.FunctionComponent<TreeImpactInfoFooterProps> = ({ helpGuideUrl, className }) => (
  <Box
    className={className}
    background={'foregroundLowered'}
    padding={'padding200'}
    borderRadius={'radius400'}
    css={css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1rem;
      background-color: ${colorToCode('positive-050')};
    `}
  >
    <Label
      muted={true}
      color={'positive-600'}
      css={css`
        flex-grow: 4;
      `}
    >
      We've partnered with veritree to pledge trees with every order.
    </Label>
    <div
      css={css`
        display: flex;
        gap: 1rem;
        align-items: center;
        @media (max-width: ${mobileBreakpointRem}rem) {
          width: 100%;
          justify-content: space-between;
        }
      `}
    >
      <Image
        source={'/i/brand/Veritree/logo.svg'}
        css={css`
          max-height: 1.875rem;
        `}
      />
      <Button
        iconRight={'Share'}
        anchor={{ href: helpGuideUrl, openInNewTab: true }}
      >
        Learn More
      </Button>
    </div>
  </Box>
);

export default TreeImpactInfoFooter;
