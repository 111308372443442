import { SVGProps, forwardRef, memo } from 'react';

const AmexIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="24"
        viewBox="0 0 36 24"
        fill="none"
        {...props}
      >
        <rect
          width="36"
          height="24"
          rx="3"
          fill="#1976D2"
        />
        <path
          d="M6.38411 8.34766L3.08682 15.6444H7.03414L7.52349 14.481H8.64205L9.1314 15.6444H13.4763V14.7565L13.8634 15.6444H16.111L16.4981 14.7377V15.6444H25.5343L26.6331 14.5113L27.6619 15.6444L32.3031 15.6538L28.9954 12.0164L32.3031 8.34766H27.7339L26.6643 9.45991L25.6679 8.34766H15.8377L14.9936 10.231L14.1296 8.34766H10.1906V9.2054L9.75236 8.34766H6.38411ZM7.1479 9.38381H9.072L11.2591 14.3318V9.38381H13.3669L15.0562 12.9315L16.613 9.38381H18.7103V14.6197H17.4341L17.4237 10.5169L15.5632 14.6197H14.4217L12.5508 10.5169V14.6197H9.92551L9.4278 13.4459H6.73888L6.24221 14.6187H4.8356L7.1479 9.38381ZM19.8778 9.38381H25.0668L26.6539 11.0981L28.2921 9.38381H29.8792L27.4678 12.0154L29.8792 14.6166H28.2201L26.6331 12.8824L24.9865 14.6166H19.8778V9.38381ZM8.08391 10.2697L7.19802 12.3608H8.96878L8.08391 10.2697ZM21.1592 10.4679V11.4237H23.99V12.4891H21.1592V13.5325H24.3344L25.8098 11.9956L24.397 10.467L21.1592 10.4679Z"
          fill="white"
        />
      </svg>
    );
  }),
);

export default AmexIcon;
