import * as React from 'react';
import { RenderCellDataNested } from '@AssetManagementClient/BeastClient/Renderer/Model/Data.gen';
import TableCell from '~/neo-ui/packages/table/packages/table-cell/TableCell';
import RenderCell from '~/neo-ui/packages/table/packages/render-cell-data/RenderCell';

export type RenderStackedCellProps = {
  data: RenderCellDataNested.StackedData;
};

const RenderStackedCell: React.FunctionComponent<RenderStackedCellProps> = ({ data }) => {
  const label = <RenderCell data={data.label} />;
  const description = <RenderCell data={data.description} />;
  return (
    <TableCell
      label={label}
      description={description}
      descriptionMuted={true}
    />
  );
};

export default RenderStackedCell;
