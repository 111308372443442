import { ForwardedRef, forwardRef } from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';
import CircleNumber from '~/sp-ui/circular-number/CircleNumber';
import theme from '~/sp-ui/theme';

const SectionHeader = forwardRef(
  (
    {
      title,
      stepNumber,
      onEdit,
      isEditing,
    }: {
      title: string;
      stepNumber: number;
      onEdit?: () => void;
      isEditing: boolean;
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={ref}
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CircleNumber
          number={stepNumber}
          css={{ marginRight: '14px' }}
          variant={isEditing ? 'active' : 'disabled'}
        />
        <span css={{ flex: 1 }}>{title}</span>

        {!isEditing && onEdit && (
          <button
            type="button"
            onClick={onEdit}
            css={{
              all: 'unset',
              fontSize: '16px',
              color: theme.palette.primary1,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon
              icon="Edit"
              color="primary-400"
            />
            &nbsp;<span css={{ textDecoration: 'underline' }}>Edit</span>
          </button>
        )}
      </div>
    );
  },
);

export default SectionHeader;
