import useMutationInternal, {
  UseMutationInternal,
  UseMutationInternalApiCall,
  UseMutationInternalOptions,
} from '~/extensions/packages/hooks/useMutationInternal';
import useContractListMutationContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/context/hooks/useContractListMutationContext';

export type UseContractListMutationOptions<TResponse = unknown> = UseMutationInternalOptions<TResponse>;

const useContractListMutation = <TArg = Record<string, unknown>, TResponse = unknown>(
  apiCall: UseMutationInternalApiCall<TArg, TResponse>,
  options?: UseContractListMutationOptions<TResponse>,
): UseMutationInternal<TArg> => {
  const { triggerContractListReload } = useContractListMutationContext();

  return useMutationInternal(apiCall, triggerContractListReload, options);
};

export default useContractListMutation;
