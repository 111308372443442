import { treeImpactDisposalService } from './packages/tree-impact-disposal-service/TreeImpactDisposalService';
import { treeImpactWarrantyService } from './packages/tree-impact-warranty-service/TreeImpactWarrantyService';

export type ServiceId = 'disposal' | 'warranty';

export type TreeImpactImageSource =
  | '/i/graphic/services/impact/internal/disposal.svg'
  | '/i/graphic/services/impact/internal/warranties.svg'
  | '/i/graphic/services/impact/internal/trees-confirmed.svg'
  | '/i/graphic/services/impact/internal/trees-pledged-total.svg'
  | '/i/graphic/services/impact/internal/trees-potential.svg'
  | '/i/graphic/services/impact/internal/trees-pledged-potential.svg';

export type TreeImpactRelatedService = {
  serviceId: ServiceId;
  serviceDisplayName: string;

  // descriptions display on intro card
  serviceIntroDescriptionLegacy: string;
  serviceIntroDescription: string;
  getTreesPerOrderUnitDescription: (treesPerOrderUnit: number) => string;

  // images + descriptions display on account summary card
  accountSummaryUnitsImageSource: TreeImpactImageSource;
  accountSummaryTreesPlantedImageSource: TreeImpactImageSource;

  getAccountSummaryUnitsDescription: (totalOrdersUnits: number) => string;
  getAccountSummaryTreesPlantedDescription: (totalTreePlanted: number) => string;

  // images + descriptions display on order summary card
  summaryServiceOrderUnitImageSource: TreeImpactImageSource;
  summaryAccountTreesPlantedImageSource: TreeImpactImageSource;
  summaryServiceOrderTreesPlantedImageSource: TreeImpactImageSource;

  getSummaryServiceOrderUnitDescription: (totalOrderUnits: number) => string;
  getSummaryAccountTreesPlantedDescription: (totalTreePlanted: number) => string;
  getSummaryServiceOrderTreesPlantedDescription: (totalTreePlanted: number) => string;

  // images + description display on order potential card
  potentialServiceOrderUnitImageSource: TreeImpactImageSource;
  potentialAccountTreesPlantedImageSource: TreeImpactImageSource;
  potentialServiceOrderTreesPlantedImageSource: TreeImpactImageSource;

  getPotentialServiceOrderTreesPlantedDescription: (totalTreePlanted: number) => string;
  getPotentialAccountTreesPlantedDescription: (totalTreePlanted: number) => string;
  getPotentialServiceOrderUnitDescription: (totalOrderUnits: number) => string;

  // calculate, how many trees we are going to plant in scope of current order
  getPotentialTreesCount: (totalOrderUnits: number, treesPerUnit: number, treesLimit?: number) => number;
};

const treeImpactRelatedServiceList = [treeImpactDisposalService, treeImpactWarrantyService];

const treeImpactRelatedServiceByServiceId = new Map(treeImpactRelatedServiceList.map(service => [service.serviceId, service]));

export const getTreeImpactRelatedService = (serviceId: ServiceId) => treeImpactRelatedServiceByServiceId.get(serviceId)!;

export default TreeImpactRelatedService;
