import React, { useCallback, useState } from 'react';
import FiltersMutationContext from '~/wm/packages/warranty/packages/warranty-cart/context/FiltersMutationContext';
import WarrantyCartFilterFormData from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-filters/packages/warranty-cart-filter-form/WarrantyCartFilterFormData';
import WarrantyCartFiltersContext from '~/wm/packages/warranty/packages/warranty-cart/context/WarrantyCartFiltersContext';

const WarrantyCartFiltersProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const [filter, setFilter] = useState<WarrantyCartFilterFormData | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const onFilterChanged = useCallback(async (arg: WarrantyCartFilterFormData) => setFilter(arg), []);

  const clearFilter = useCallback(async () => setFilter(undefined), []);

  return (
    <FiltersMutationContext.Provider value={{ onFilterChanged, clearFilter }}>
      <WarrantyCartFiltersContext.Provider value={{ filter, searchQuery, setSearchQuery }}>{children}</WarrantyCartFiltersContext.Provider>
    </FiltersMutationContext.Provider>
  );
};

export default WarrantyCartFiltersProvider;
