import { autoEmailReportViewUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useAutoEmailReportListMutation, {
  UseAutoEmailReportListMutationOptions,
} from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportListMutation';
import { AutoEmailReportViewUpdatePayloadDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';

type AutoEmailReportEditViewPayload = {
  autoEmailReportId: string;
  organizationId: string;
  autoEmailReportViewUpdatePayload: AutoEmailReportViewUpdatePayloadDto;
};

export type UseAutoEmailReportEditView = {
  editAutoEmailReport: (autoEmailReportEditViewPayload: AutoEmailReportEditViewPayload, isRequestActive?: () => boolean) => void;
  isEditingAutoEmailReport: boolean;
};

const useAutoEmailReportEdit = (options?: UseAutoEmailReportListMutationOptions): UseAutoEmailReportEditView => {
  const [editAutoEmailReport, isEditingAutoEmailReport] = useAutoEmailReportListMutation(
    ({
      autoEmailReportId,
      organizationId,
      autoEmailReportViewUpdatePayload: { reportQuery, title, saveAsTemplate, autoEmailReportTemplateId },
    }: AutoEmailReportEditViewPayload) =>
      autoEmailReportViewUpdate({
        autoEmailReportId,
        organizationId,
        updatePayload: {
          autoEmailReportTemplateId,
          reportQuery,
          title,
          saveAsTemplate,
        },
      }),
    options,
  );

  return {
    editAutoEmailReport,
    isEditingAutoEmailReport,
  };
};

export default useAutoEmailReportEdit;
