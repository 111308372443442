import { SVGProps, forwardRef, memo } from 'react';

const DinersClubIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="24"
        viewBox="0 0 36 24"
        fill="none"
        {...props}
      >
        <rect
          x="0.5"
          y="0.5"
          width="35"
          height="23"
          rx="2.5"
          fill="white"
        />
        <rect
          x="0.5"
          y="0.5"
          width="35"
          height="23"
          rx="2.5"
          stroke="#DDDDDD"
        />
        <path
          d="M28.0777 12.0695C28.0777 7.29711 24.0943 3.99842 19.7299 4H15.9741C11.5575 3.99842 7.92233 7.29812 7.92233 12.0695C7.92233 16.4345 11.5576 20.0209 15.9741 19.9999H19.7299C24.0941 20.0209 28.0777 16.4335 28.0777 12.0695Z"
          fill="#0079BE"
        />
        <path
          d="M15.9973 4.67627C11.9617 4.67752 8.69142 7.95533 8.69037 12.0007C8.69138 16.0455 11.9616 19.3229 15.9973 19.3242C20.0339 19.3229 23.3049 16.0455 23.3054 12.0007C23.3048 7.95524 20.0339 4.67752 15.9973 4.67627Z"
          fill="white"
        />
        <path
          d="M11.3786 11.9808C11.3824 10.004 12.6145 8.31829 14.3519 7.64844V16.3122C12.6145 15.6427 11.3824 13.9579 11.3786 11.9808ZM17.6671 16.3144V7.64844C19.4052 8.3167 20.6393 10.0034 20.6424 11.9811C20.6393 13.9594 19.4052 15.6449 17.6671 16.3144Z"
          fill="#0079BE"
        />
      </svg>
    );
  }),
);

export default DinersClubIcon;
