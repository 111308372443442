// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


export enum PageEnum
{
  HamConsole = "HamConsole",
  SamConsole = "SamConsole",
  AssetConsoleHardware = "AssetConsoleHardware",
}



export interface PageFilter
{
  key: string;
  values: string[];
}

