import { EngagementNoteDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Dto/Model.gen';
import { differenceInCalendarDays, differenceInCalendarYears, parseISO } from 'date-fns';

type EngagementNoteSections = {
  pinned: EngagementNoteDto[];
  pastWeek: EngagementNoteDto[];
  pastMonth: EngagementNoteDto[];
  pastMonthRemainingThisYear: EngagementNoteDto[];
  pastRemainingYears: EngagementNoteDto[];
};

/**
 * Takes a list of engagement notes and returns an object with the notes grouped by date and pinned status.
 */
const useEngagementNoteDateSections = (notes: EngagementNoteDto[]): EngagementNoteSections => {
  const currentDate = new Date();

  const initialResult: EngagementNoteSections = {
    pinned: [],
    pastWeek: [],
    pastMonth: [],
    pastMonthRemainingThisYear: [],
    pastRemainingYears: [],
  };

  return notes.reduce((result, note) => {
    const parsedDate = parseISO(note.createdAt);
    const dayDifference = differenceInCalendarDays(currentDate, parsedDate);
    const yearDifference = differenceInCalendarYears(currentDate, parsedDate);

    if (note.isPinned) {
      result.pinned.push(note);
    } else if (dayDifference >= 0 && dayDifference <= 7) {
      result.pastWeek.push(note);
    } else if (dayDifference >= 0 && dayDifference <= 30) {
      result.pastMonth.push(note);
    } else if (yearDifference === 0) {
      result.pastMonthRemainingThisYear.push(note);
    } else if (yearDifference > 0) {
      result.pastRemainingYears.push(note);
    }

    return result;
  }, initialResult);
};

export default useEngagementNoteDateSections;
