import useScorecardMutationContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardMutationContext';
import useMutationInternal, {
  UseMutationInternal,
  UseMutationInternalApiCall,
  UseMutationInternalOptions,
} from '~/extensions/packages/hooks/useMutationInternal';

export type UseScorecardMutationOptions<TResponse = unknown> = UseMutationInternalOptions<TResponse>;

const useScorecardMutation = <TArg = Record<string, unknown>, TResponse = unknown>(
  apiCall: UseMutationInternalApiCall<TArg, TResponse>,
  options?: UseScorecardMutationOptions<TResponse>,
): UseMutationInternal<TArg> => {
  const { triggerScorecardReload } = useScorecardMutationContext();

  return useMutationInternal(apiCall, triggerScorecardReload, options);
};

export default useScorecardMutation;
