import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { disposalOrderList } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { DisposalOrderOverviewDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Dto/Model.gen';

export type UseDisposalOrderList = () => {
  disposalOrderOverviews: DisposalOrderOverviewDto[] | undefined;
};

const useDisposalOrderList: UseDisposalOrderList = () => {
  const [disposalOrderOverviews, setDisposalOrderOverviews] = useState<DisposalOrderOverviewDto[]>();
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => disposalOrderList({}));
      if (!response) {
        return;
      }
      setDisposalOrderOverviews(response.disposalOrderOverviews);
    })();
  }, [callApi]);

  return {
    disposalOrderOverviews,
  };
};

export default useDisposalOrderList;
