import * as React from 'react';
import Box from '~/neo-ui/packages/box/Box';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { DisposalOrderPickupMaterialTypeDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderPickup/Packages/DisposalOrderPickupMaterial/Dto/Model.gen';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import DisposalBulkCheckoutFormData from '~/wm/packages/disposal/types/DisposalBulkCheckoutFormData';
import useWizardPageContext from '~/neo-ui/packages/wizard/packages/wizard-page/context/hooks/useWizardPageContext';
import useDisposalCart from '~/wm/packages/disposal/hooks/useDisposalCart';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import FormToggleTileVerticalGroup, {
  FormToggleTileVerticalGroupOption,
} from '~/neo-ui/packages/form/packages/form-input/packages/form-toggle-tile-vertical-group/FormToggleTileVerticalGroup';

export type MaterialsSectionProps = {
  materialsFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData>;
  pickupMaterialTypeAvailabilities: DisposalOrderPickupMaterialTypeDto[];
};

const MaterialsSection = ({ materialsFieldKey, pickupMaterialTypeAvailabilities }: MaterialsSectionProps) => {
  const { setFieldCompleted } = useWizardPageContext();
  const { getFormInput, formData } = useFormContext<DisposalBulkCheckoutFormData>();
  const { updateDisposalCart } = useDisposalCart();
  const materials = getFormInput<string[]>(materialsFieldKey).value;
  const materialsRef = React.useRef(materials);

  const availableMaterials = Object.fromEntries(pickupMaterialTypeAvailabilities.map(material => [material.uniqueId, material.label]));

  const isMaterialValid =
    materials.length <= pickupMaterialTypeAvailabilities.length && materials.every(material => availableMaterials[material]);

  React.useEffect(() => {
    setFieldCompleted(materialsFieldKey, isMaterialValid);
  }, [availableMaterials, isMaterialValid, materials, materialsFieldKey, pickupMaterialTypeAvailabilities.length, setFieldCompleted]);

  React.useEffect(() => {
    if (materialsRef.current === materials || !isMaterialValid) {
      return;
    }
    updateDisposalCart(formData);
    materialsRef.current = materials;
  }, [formData, isMaterialValid, materials, updateDisposalCart]);

  const pickupMaterialSelectOptions: FormToggleTileVerticalGroupOption[] = pickupMaterialTypeAvailabilities.map(pickupMaterial => ({
    value: pickupMaterial.uniqueId,
    icon: pickupMaterial.icon as IconType,
    description: pickupMaterial.description,
    title: pickupMaterial.label,
    tooltipContent: pickupMaterial.description,
  }));

  return (
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
    >
      <LayoutSection
        title={'How can we help?'}
        titleSize={4}
        titleWeight={'bold'}
        description={'Based on your situation, let us know how we can help pack and load your assets.'}
      >
        <FormToggleTileVerticalGroup
          type={'multi'}
          fieldKey={materialsFieldKey}
          options={pickupMaterialSelectOptions}
        />
      </LayoutSection>
    </Box>
  );
};

export default MaterialsSection;
