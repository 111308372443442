import useInitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesContext';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import {
  BudgetLineItemFormData,
  InitiativeSaveFormData,
  RecurringLineItemFormData,
} from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import useAccount from '~/wm/hooks/useAccount';
import * as React from 'react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import useInitiativeTemplateDelete from '~/wm/packages/strategy/packages/initiative/packages/initiative-template/hooks/useInitiativeTemplateDelete';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import useInitiativeTemplateCreate from '~/wm/packages/strategy/packages/initiative/packages/initiative-template/hooks/useInitiativeTemplateCreate';
import useInitiativeTemplateUpdate from '~/wm/packages/strategy/packages/initiative/packages/initiative-template/hooks/useInitiativeTemplateUpdate';
import SelectOptionGroup from '~/neo-ui/packages/select/model/SelectOptionGroup';
import Button from '~/neo-ui/packages/button/Button';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import { css } from '@emotion/react';
import SelectList from '~/neo-ui/packages/list/packages/select-list/SelectList';
import useInitiativeAvailabilitiesMutationContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesMutationContext';

const newInitiativeTemplateId = 'NEW_TEMPLATE';

const InitiativeSaveTemplateSaveButton = () => {
  const { availabilities } = useInitiativeAvailabilitiesContext();
  const { triggerInitiativeAvailabilitiesReload } = useInitiativeAvailabilitiesMutationContext();
  const { formData } = useFormContext<InitiativeSaveFormData>();
  const { subunitRatio } = useAccount();

  const costUnitsToCostSubunitsOneTimeFees = (rows: BudgetLineItemFormData[]) =>
    rows.map(row => ({ label: row.label, costType: row.costType, costSubunits: row.costUnits * subunitRatio }));
  const costUnitsToCostSubunitsRecurringFees = (rows: RecurringLineItemFormData[]) =>
    rows.map(row => ({ label: row.label, costType: row.costType, frequency: row.frequency, costSubunits: row.costUnits * subunitRatio }));

  // Changes the template: name controls name of the template
  const newTemplate = useMemo(
    () => ({
      initiativeTemplateId: newInitiativeTemplateId,
      name: formData.name,
      isRecommended: false,
    }),
    [formData.name],
  );

  const newTemplateOptionGroup = useMemo(
    () => ({
      title: `SAVE A NEW TEMPLATE`,
      options: [
        {
          label: newTemplate.name === '' ? 'Untitled Initiative' : newTemplate.name,
          value: newTemplate.initiativeTemplateId,
        },
      ],
    }),
    [newTemplate],
  );

  const { deleteTemplate } = useInitiativeTemplateDelete();

  const [selectedTemplate, setSelectedTemplate] = useState<SelectOption>();
  const [isExpanded, setExpanded] = useState<boolean>(false);

  const [filteredAccountTemplates, setFilteredAccountTemplates] = useState(availabilities.templatesAccountAvailabilities);

  useEffect(() => {
    if (typeof availabilities.templatesAccountAvailabilities !== 'undefined' && availabilities.templatesAccountAvailabilities.length <= 0) {
      setSelectedTemplate(newTemplateOptionGroup.options[0]);
    }
    setFilteredAccountTemplates(availabilities.templatesAccountAvailabilities);
  }, [availabilities.templatesAccountAvailabilities, newTemplateOptionGroup]);

  // Reset all states
  const resetModal = () => {
    setSelectedTemplate(undefined);
    setExpanded(false);
  };

  const { isCreating, createTemplate } = useInitiativeTemplateCreate(resetModal);
  const { isUpdating, updateTemplate } = useInitiativeTemplateUpdate(resetModal);

  if (typeof filteredAccountTemplates === 'undefined') {
    return null;
  }

  const templateListGroup: SelectOptionGroup[] =
    filteredAccountTemplates.length > 0
      ? [
          {
            title: `YOUR SAVED TEMPLATES`,
            options: filteredAccountTemplates.map(template => ({
              label: template.name === '' ? 'Untitled Initiative' : template.name,
              value: template.initiativeTemplateId,
              prependIcon: 'InitiativeTemplate',
              actionButton: {
                children: 'Delete',
                theme: 'negative',
                iconRight: 'Trash',
                preventOnClickPropagation: true,
                disableTransparentBackground: true,
                onClick: async () => {
                  setSelectedTemplate(undefined);
                  deleteTemplate(template.initiativeTemplateId);
                  setFilteredAccountTemplates(prev => prev?.filter(prev => prev.initiativeTemplateId !== template.initiativeTemplateId));
                  await triggerInitiativeAvailabilitiesReload();
                },
              },
            })),
          },
          newTemplateOptionGroup,
        ]
      : [newTemplateOptionGroup];

  return (
    <Fragment>
      <Button
        iconLeft={'Save'}
        onClick={() => {
          setExpanded(true);
        }}
        css={css`
          margin-right: 0.3125rem;
        `}
      >
        Save template…
      </Button>
      <ModalConfirmation
        header={{
          title: 'Save template',
          icon: 'InitiativeTemplate',
        }}
        isOpen={isExpanded}
        theme={'primary'}
        footer={{
          dismissButton: {
            icon: 'Cancel',
            label: 'Close',
            onClick: resetModal,
          },
          confirmButton: {
            icon: 'Success',
            label: 'Save template',
            disabled: typeof selectedTemplate === 'undefined',
            loading: isUpdating || isCreating,
            onClick: async () => {
              if (typeof selectedTemplate !== 'undefined') {
                if (selectedTemplate.value === newInitiativeTemplateId) {
                  createTemplate({
                    name: formData.name,
                    executiveSummary: formData.executiveSummary,
                    budgetLineItems: costUnitsToCostSubunitsOneTimeFees(formData.budgetLineItems),
                    recurringLineItems: costUnitsToCostSubunitsRecurringFees(formData.recurringLineItems),
                  });
                  await triggerInitiativeAvailabilitiesReload();
                } else {
                  updateTemplate({
                    initiativeTemplateId: selectedTemplate.value,
                    name: formData.name,
                    executiveSummary: formData.executiveSummary,
                    budgetLineItems: costUnitsToCostSubunitsOneTimeFees(formData.budgetLineItems),
                    recurringLineItems: costUnitsToCostSubunitsRecurringFees(formData.recurringLineItems),
                  });
                  await triggerInitiativeAvailabilitiesReload();
                }
              }
            },
          },
        }}
      >
        {filteredAccountTemplates && (
          <div>
            <div>
              This Initiative’s action, title, executive summary, and budget will be saved as a template you can reuse for any Initiative.
            </div>
            <hr
              css={css`
                margin-top: 0.625rem;
                margin-bottom: 0;
                width: 100%;
              `}
            />
            <div
              css={css`
                display: flex;
                flex-direction: column;
                max-height: 25rem;
                overflow-y: auto;
                margin-top: 0.625rem;
              `}
            >
              <SelectList
                options={templateListGroup}
                selectedOptionValue={selectedTemplate}
                onOptionSelected={setSelectedTemplate}
              />
            </div>
          </div>
        )}
      </ModalConfirmation>
    </Fragment>
  );
};

export default InitiativeSaveTemplateSaveButton;
