import * as React from 'react';
import { DisposalOrderSummaryDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Dto/Model.gen';
import DisposalOrderInfoProvider from '~/wm/packages/disposal/packages/order-info/context/DisposalOrderInfoProvider';
import DisposalOrderSummaryBulkSection from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-summary-section/packages/disposal-order-summary-bulk-section/DisposalOrderSummaryBulkSection';
import DisposalOrderBulkPickupSection from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-bulk-pickup-section/DisposalOrderBulkPickupSection';

export type DisposalSuccessSectionProps = {
  disposalOrderSummary: DisposalOrderSummaryDto;
  disposalOrderListUrl: string;
  invoiceUrl?: string;
};

const DisposalBulkSuccessSection = ({ disposalOrderSummary, disposalOrderListUrl, invoiceUrl }: DisposalSuccessSectionProps) => (
  <DisposalOrderInfoProvider
    disposalOrderId={disposalOrderSummary.disposalOrderId}
    defaultDisposalOrderSummary={disposalOrderSummary}
  >
    <DisposalOrderSummaryBulkSection
      disposalOrderListUrl={disposalOrderListUrl}
      invoiceUrl={invoiceUrl}
      disposalAssetTypeAvailabilities={disposalOrderSummary.assetTypeAvailabilities}
    />
    <DisposalOrderBulkPickupSection />
  </DisposalOrderInfoProvider>
);

export default DisposalBulkSuccessSection;
