import Button from '~/neo-ui/packages/button/Button';
import { Fragment, useState } from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import useEngagementActionDelete from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionDelete';

export type EngagementActionDeleteButtonProps = {
  engagementActionId: string;
  onClose: () => void;
};

const EngagementActionDeleteButton = ({ engagementActionId, onClose }: EngagementActionDeleteButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { deleteEngagementAction, isDeleting } = useEngagementActionDelete();

  return (
    <Fragment>
      <Button
        iconLeft={'Trash'}
        onClick={() => setIsOpen(true)}
      >
        Delete action item
      </Button>
      <ModalConfirmation
        theme={'negative'}
        isOpen={isOpen}
        header={{ title: 'Are you sure you want to delete this action item?', icon: 'Trash' }}
        footer={{
          confirmButton: {
            icon: 'Trash',
            label: 'Delete',
            loading: isDeleting,
            onClick: () => {
              deleteEngagementAction(engagementActionId);
              onClose();
            },
          },
          dismissButton: {
            icon: 'Cancel',
            label: 'Cancel',
            loading: isDeleting,
            onClick: () => setIsOpen(false),
          },
        }}
      >
        This action cannot be undone. If there are any linked PSA tickets or initiatives, they will be unlinked. You will need to delete
        those manually.
      </ModalConfirmation>
    </Fragment>
  );
};

export default EngagementActionDeleteButton;
