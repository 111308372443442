import { Request as SubscriptionUpgradeRequest } from '@SubscriptionClient/Subscription/Packages/Upgrade/Controller/SubscriptionUpgradeControllerNested.gen';
import { ProductAvailabilityPayload, SubscriptionUpgradeAvailabilities } from '@SubscriptionClient/Subscription/Packages/Upgrade/Model.gen';
import { CaseEnum } from '@SubscriptionClient/Subscription/Packages/Upgrade/Model/SubscriptionUpgradeAvailabilitiesNested.gen';
import { parseISO } from 'date-fns';
import { createValidationErrorMap } from '~/neo-ui/packages/form/packages/form-action/packages/form-validation/createValidationErrorMap';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import {
  SelfServeType,
  SubscriptionAgreementEnum,
} from '~/wm/packages/subscription/packages/manage/wizard/model/SubscriptionAgreementInfo';
import getSubscriptionAgreementStep from '~/wm/packages/subscription/packages/manage/wizard/step/getSubscriptionAgreementStep';
import { UpgradeFormData } from '~/wm/packages/subscription/packages/upgrade/wizard/SubscriptionUpgradeWizard';
import { toFieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';

const getUpgradeSubscriptionAgreementStep = (
  availabilities: SubscriptionUpgradeAvailabilities,
  subscriptionAgreementLink: AnchorLocation,
) => {
  const productAvailabilityPayloads: ProductAvailabilityPayload[] = availabilities.productAvailabilityPayloads;
  const productFieldKeys = productAvailabilityPayloads.map(productAvailabilities =>
    toFieldKeyExpression<UpgradeFormData>(productAvailabilities.productEnum),
  );
  const term = availabilities.term
    ? {
        startsAt: parseISO(availabilities.term.startsAt!),
        endsAt: parseISO(availabilities.term.endsAt),
        durationMonths: availabilities.term.durationMonths!,
        renewalBehavior: availabilities.term.renewalBehaviorEnum,
      }
    : undefined;

  const invalidTiers: string[] = [];

  return getSubscriptionAgreementStep<UpgradeFormData>(
    availabilities.case === CaseEnum.Preset
      ? {
          label: "Here's your subscription",
          description: 'Based on the number of hardware assets you manage.',
        }
      : {
          label: 'Build your subscription',
          description: 'Choose a subscription that includes the number of hardware assets you manage.',
        },
    availabilities.case === CaseEnum.Preset
      ? {
          case: SubscriptionAgreementEnum.Preset,
          term,
          discount: availabilities.appliedDiscount,
          billing: availabilities.billing,
          pricingDto: availabilities.presetPricingDto,
        }
      : {
          case: SubscriptionAgreementEnum.SelfServe,
          term,
          discount: availabilities.appliedDiscount,
          billing: availabilities.billing,
          type: SelfServeType.Upgrade,
        },
    'USD',
    {
      productTierIdFieldKeys: productFieldKeys,
    },
    subscriptionAgreementLink,
    createValidationErrorMap<SubscriptionUpgradeRequest, UpgradeFormData>([]),
    productAvailabilityPayloads,
    true,
    invalidTiers,
  );
};

export default getUpgradeSubscriptionAgreementStep;
