import React from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';

export type IntegrationSetupInputTitleProps = {
  title: string;
  description?: string;
};

export const IntegrationFormInputTitle = ({ title, description }: IntegrationSetupInputTitleProps) => (
  <React.Fragment>
    <Label
      size={'md'}
      bold={true}
      css={css`
        padding: 0.125rem;
      `}
    >
      {title}
    </Label>
    {description && (
      <Label
        size={'sm'}
        color={'dark-900-64'}
      >
        {description}
      </Label>
    )}
  </React.Fragment>
);
export default IntegrationFormInputTitle;
