import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import WarrantyCartOpportunitiesFormData from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-opportunity/packages/warranty-cart-opportunity-form/WarrantyCartOpportunitiesFormData';

const warrantyCartOpportunitiesFormFields = {
  markup: (value: FieldKeyExpressionSegment<WarrantyCartOpportunitiesFormData>) => value.markup,
  durationMonth: (value: FieldKeyExpressionSegment<WarrantyCartOpportunitiesFormData>) => value.durationMonth,
  coTermination: (value: FieldKeyExpressionSegment<WarrantyCartOpportunitiesFormData>) => value.coTermination,
};

export default warrantyCartOpportunitiesFormFields;
