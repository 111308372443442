import Button from '~/neo-ui/packages/button/Button';
import { useEffect, useState } from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Size from '~/neo-ui/model/Size';
import { css } from '@emotion/react';

export type OrganizationStarButtonProps = {
  isStarred: boolean;
  onStar: (isStarred: boolean) => void;
  size?: Exclude<Size, 'xl'>;
} & Styleable;

const OrganizationStarButton = ({ isStarred, onStar, size = 'sm', className }: OrganizationStarButtonProps) => {
  const [isStar, setIsStar] = useState(isStarred);

  useEffect(() => {
    setIsStar(isStarred);
  }, [isStarred]);

  return (
    <Button
      css={css`
        padding: unset;
      `}
      iconLeft={isStar ? 'Favorite' : 'FavoriteNo'}
      theme={isStar ? 'positive' : undefined}
      onClick={() => {
        setIsStar(!isStar);
        onStar(!isStar);
      }}
      size={size}
      className={className}
    />
  );
};

export default OrganizationStarButton;
