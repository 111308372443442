import { DisposalOrderAssetDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderAsset/Dto/Model.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { TableClientSideColumn } from '~/neo-ui/packages/table/packages/table-client-side/TableClientSide';
import { Fragment } from 'react';

const getAssetTypeIcon = (assetType: string | undefined, assetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[]): IconType =>
  (assetTypeAvailabilities.find(availability => availability.label === assetType)?.icon ?? 'Workstation') as IconType;

const getDisposalOrderAssetTableColumns = (
  disposalAssetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[],
): TableClientSideColumn<DisposalOrderAssetDto>[] => [
  {
    fieldKey: disposalOrderAsset => disposalOrderAsset.assetType,
    header: 'Type',
    renderCell: ({ original: disposalOrderAsset }) => (
      <Icon icon={getAssetTypeIcon(disposalOrderAsset.assetType, disposalAssetTypeAvailabilities)} />
    ),
  },
  {
    fieldKey: disposalOrderAsset => disposalOrderAsset.displayName,
    header: 'Name',
    renderCell: ({ original: disposalOrderAsset }) => disposalOrderAsset.displayName ?? '-',
  },
  {
    fieldKey: disposalOrderAsset => disposalOrderAsset.manufacturerDisplay,
    header: 'Manufacturer & Model',
    renderCell: ({ original: disposalOrderAsset }) => (
      <Fragment>
        {typeof disposalOrderAsset.modelDisplay === 'undefined' && typeof disposalOrderAsset.manufacturerDisplay === 'undefined' && '-'}
        {typeof disposalOrderAsset.manufacturerDisplay !== 'undefined' && (
          <Label size={'md'}>{disposalOrderAsset.manufacturerDisplay}</Label>
        )}
        {typeof disposalOrderAsset.modelDisplay !== 'undefined' && <Label size={'sm'}>{disposalOrderAsset.modelDisplay}</Label>}
      </Fragment>
    ),
  },
  {
    fieldKey: disposalOrderAsset => disposalOrderAsset.serialNumber,
    header: 'Serial',
    renderCell: ({ original: disposalOrderAsset }) => <Label size={'md'}>{disposalOrderAsset.serialNumber}</Label>,
  },
];

export default getDisposalOrderAssetTableColumns;
