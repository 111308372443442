import { css } from '@emotion/react';
import { useEffect, type PropsWithChildren, useState } from 'react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { type MultiStepFormContextType } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import Icon from '~/neo-ui/packages/icon/Icon';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import { Button } from '~/sp-ui/buttons';
import theme from '~/sp-ui/theme';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import ItemSelectionFooter from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-selection/packages/plan-selection-footer/ItemSelectionFooter';

const PurchaseSubscriptionContainer = (
  props: PropsWithChildren<{
    context: MultiStepFormContextType<PurchaseSubscriptionForm>;
  }>,
) => {
  const { children, context } = props;
  const { goToPage, goNext, currentStepIndex, formik } = context;
  const [subtotal, setSubtotal] = useState(0);

  useEffect(() => {
    // Hide navigation to home if user's progressed past plan selection
    const breadcrumb = document.getElementById('wm-nav-breadcrumb');
    const content = document.getElementById('wm-content');
    const body = document.body;
    if (currentStepIndex === 0 && breadcrumb !== null) {
      breadcrumb.style.display = 'block';
    } else if (breadcrumb !== null) {
      breadcrumb.style.display = 'none';
    }

    // Set background to have monochromatic wave if on plan or addon selection, otherwise have solid background
    if (currentStepIndex <= 1) {
      if (body !== null) {
        body.style.backgroundColor = '#FFFFFF';
      }
      if (content !== null) {
        content.style.backgroundColor = '#FFFFFF';
      }
    } else {
      if (body !== null) {
        body.style.backgroundColor = '#FAFAFA';
      }
      if (content !== null) {
        content.style.backgroundColor = '#FAFAFA';
      }
    }
  }, [currentStepIndex]);

  useEffect(() => {
    const subtotal = formik.values.activationFee + formik.values.planSubtotal + formik.values.addonSubtotal;
    setSubtotal(subtotal);
  }, [formik.values.activationFee, formik.values.addonSubtotal, formik.values.planSubtotal]);

  const enableContinueToNextStep = () => {
    if (formik.values.isGrow) {
      const isPlanGrown = formik.values.selectedPlanLimit !== undefined;
      const isAddonGrown = formik.values.selectedAddonIds.length !== 0;
      return isPlanGrown || isAddonGrown;
    }

    return formik.values.subscriptionPlanId !== undefined && formik.values.selectedPlanLimit !== undefined;
  };

  const shouldProceedToCheckout = () => {
    return context.currentStepIndex !== 0 || !formik.values.hasAddonSelectionStep || formik.values.isGrow;
  };

  return (
    <>
      {/* Background and arrow to previous step for addon and summary step */}
      {(context.currentStepIndex === 1 || context.currentStepIndex === 2) && (
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
          `}
        >
          <div
            css={css`
              max-width: 1200px;
              width: 100%;
            `}
          >
            <div
              css={css`
                margin-bottom: 1rem;
                font-family: Roboto, serif;
              `}
            >
              <Icon
                icon={'ArrowLeft'}
                sizePx={18}
                css={css`
                  float: left;
                  margin-top: 0.188rem;
                `}
                color={'primary-400'}
              />
              <button
                type="button"
                css={css`
                  border: none;
                  border-radius: 0;
                  background-color: #fafafa;
                  text-decoration: underline;
                  cursor: pointer;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 1.5rem;
                  color: ${colorToCode('primary-400')};
                  &:focus {
                    outline: none;
                  }
                `}
                onClick={() => {
                  formik.setFieldValue('setupIntent', undefined);
                  goToPage(context.currentStepIndex === 2 && formik.values.hasAddonSelectionStep ? 1 : 0);
                }}
              >
                {context.currentStepIndex === 2 && formik.values.hasAddonSelectionStep
                  ? 'Back to addon selection'
                  : 'Back to plan selection'}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Shared header for background for plan and addon selection*/}
      {context.currentStepIndex <= 1 && (
        <>
          <div
            css={css`
              width: 100%;
              position: absolute;
              height: 37.5rem;
              background-color: ${colorToCode('light-100')};
              top: 0;
              z-index: -1;
            `}
          />
          <div
            css={css`
              position: absolute;
              top: 37.5rem;
              left: 0;
              width: 100%;
              overflow: hidden;
              line-height: 0;
              z-index: -1;
            `}
          >
            <svg
              css={css`
                position: relative;
                display: block;
                width: calc(100% + 1.3px);
                height: 2.875rem;
                transform: rotateY(180deg);
              `}
              data-name="Layer"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                css={css`
                  fill: ${colorToCode('light-100')};
                `}
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              />
            </svg>
          </div>
        </>
      )}
      {/* Shared logo and selection toggle (if applicable) */}
      {context.currentStepIndex <= 1 && (
        <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {formik.values.productLogoLabel && (
            <img
              src={formik.values.productLogoUrl}
              css={css`
                height: 2.75rem;
                object-fit: contain;
                object-position: 0 0;
                margin-bottom: 2.25rem;
              `}
              alt={formik.values.productLogoLabel}
            />
          )}
          {formik.values.isGrow && formik.values.hasAddonSelectionStep && (
            <div css={{ marginBottom: '2.25rem' }}>
              <Button
                css={{ width: '6rem', borderRadius: '0.375rem 0 0 0.375rem', border: `0.063rem solid ${theme.palette.primary1}` }}
                type={'submit'}
                size={'small'}
                variant={context.currentStepIndex === 0 ? 'primary' : 'secondary'}
                onClick={() => goToPage(0)}
              >
                Plans
              </Button>
              <Button
                css={{ width: '6rem', borderRadius: '0 0.375rem 0.375rem 0', border: `0.063rem solid ${theme.palette.primary1}` }}
                type={'submit'}
                size={'small'}
                variant={context.currentStepIndex === 1 ? 'primary' : 'secondary'}
                onClick={() => goToPage(1)}
              >
                Addons
              </Button>
            </div>
          )}
        </div>
      )}
      <div
        css={css`
          position: relative;
        `}
      >
        {children}
      </div>
      {context.currentStepIndex <= 1 && (
        <ItemSelectionFooter
          subject={context.currentStepIndex === 1 ? 'addon' : 'plan'}
          ctaLabel={'Get in touch'}
          ctaUrl={'https://www.scalepad.com/contact/'}
          termLength={formik.values.termLength}
          activationFee={context.currentStepIndex === 0 ? formik.values.activationFee : 0}
        />
      )}
      {/* Sticky bar for selection stages to continue to next step */}
      {context.currentStepIndex <= 1 && enableContinueToNextStep() && (
        <div
          css={{
            position: 'fixed',
            bottom: '0',
            right: '0',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            height: 'min-content',
            width: '100%',
            backgroundColor: `${colorToCode('light-000')}`,
            boxShadow: `0px 1px 4px 0px ${colorToCode('dark-200')}`,
            zIndex: '10',
          }}
        >
          <div css={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end' }}>
            <span css={{ color: `${theme.palette.grey2}` }}>Subtotal</span>
            <h4>{formatCurrency(subtotal, 'USD', 2)}</h4>
          </div>
          <Button
            css={{ margin: '1rem' }}
            type={'submit'}
            size={'small'}
            disabled={!enableContinueToNextStep()}
            onClick={() => {
              if (shouldProceedToCheckout()) {
                goToPage(2);
              } else {
                goNext();
              }
            }}
          >
            {shouldProceedToCheckout() ? 'Proceed to subscription summary' : 'Continue'}
          </Button>
        </div>
      )}
    </>
  );
};

export default PurchaseSubscriptionContainer;
