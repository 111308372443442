import useMutationInternal, {
  UseMutationInternal,
  UseMutationInternalApiCall,
  UseMutationInternalOptions,
} from '~/extensions/packages/hooks/useMutationInternal';
import useEngagementActionListMutationContext from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListMutationContext';

export type UseEngagementActionListMutationOptions = UseMutationInternalOptions;

const useEngagementActionListMutation = <TArg = Record<string, unknown>>(
  apiCall: UseMutationInternalApiCall<TArg>,
  options?: UseEngagementActionListMutationOptions,
): UseMutationInternal<TArg> => {
  const { triggerEngagementActionListReload } = useEngagementActionListMutationContext();

  return useMutationInternal(apiCall, triggerEngagementActionListReload, options);
};

export default useEngagementActionListMutation;
