import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type TileHorizontalListItemProps = {
  icon?: IconType;
  title: string;
  number?: number;
  color?: Color;
  onClick?: () => void;
  iconSizePx?: number;
  labelFontSize?: string;
  numberFontSize?: string;
} & Styleable;

const TileHorizontalListItem: React.FunctionComponent<TileHorizontalListItemProps> = ({
  icon,
  title,
  number,
  color = 'secondary-400',
  onClick,
  className,
  iconSizePx = 16,
  labelFontSize = '0.875rem',
  numberFontSize = '1.125rem',
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <div
      className={className}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      css={css`
        ${isHovered &&
        onClick &&
        css`
          background-color: ${colorToCode('secondary-050')};
        `}
        border-radius: 0.5rem;
        padding: 0.3125rem;
        ${onClick &&
        css`
          transition: all 200ms ease;
          line-height: 0;
          &:hover {
            cursor: pointer;
          }
        `}
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 5px;
          margin-right: 5px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {icon && (
            <Icon
              icon={icon}
              color={color}
              css={css`
                margin-right: 0.625rem;
              `}
              sizePx={iconSizePx}
            />
          )}
          <span
            css={css`
              font-size: ${labelFontSize};
              color: ${colorToCode(color)};
            `}
          >
            {title}
          </span>
        </div>

        {typeof number !== 'undefined' && (
          <span
            css={css`
              font-size: ${numberFontSize};
              color: ${colorToCode(color)};
              margin-left: 1.25rem;
            `}
          >
            {number.toLocaleString()}
          </span>
        )}
      </div>
    </div>
  );
};

export default TileHorizontalListItem;
