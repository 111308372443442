import * as React from 'react';
import { ErrorMessage } from 'formik';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { FieldKeyExpression, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { css } from '@emotion/react';
import Color from '~/neo-ui/packages/color/Color.gen';

export type FormErrorMessageLabelProps = {
  validationErrorColor?: Color;
  fixedPosition?: boolean;
};

export type FormErrorMessageProps<T> = {
  fieldKey: FieldKeyExpression<T>;
} & FormErrorMessageLabelProps;

const FormErrorMessage = <T,>({ fieldKey, fixedPosition, validationErrorColor }: FormErrorMessageProps<T>) => (
  <ErrorMessage
    name={resolveFieldKey(fieldKey)}
    component={'div'}
  >
    {errorMessage => (
      <Label
        css={css`
          margin-top: 0.23rem;
          font-size: 80%;
          font-weight: 400;
          ${fixedPosition ? 'position: absolute;' : ''}
        `}
        color={validationErrorColor}
        size={'sm'}
      >
        {errorMessage}
      </Label>
    )}
  </ErrorMessage>
);

export const FormErrorMessageLabel = ({
  errorMessage,
  validationErrorColor = 'negative-400',
  fixedPosition = false,
}: FormErrorMessageLabelProps & { errorMessage: string }) => (
  <Label
    css={css`
      margin-top: 0.23rem;
      font-size: 80%;
      font-weight: 400;
      ${fixedPosition ? 'position: absolute;' : ''}
    `}
    color={validationErrorColor}
    size={'sm'}
  >
    {errorMessage}
  </Label>
);

export default FormErrorMessage;
