import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export const itemPadding = '0.5rem';
const getMultiSelectSearchStyles = () => css`
  div.dropdown-menu > a {
    padding-bottom: ${itemPadding};

    &:last-child {
      padding-bottom: 0;
    }

    &:hover {
      background-color: transparent;
    }

    &.dropdown-item {
      /* Configure padding */
      padding: ${itemPadding};

      user-select: none;

      /* Add pointer cursor when hovering a menu item */

      &:hover {
        cursor: pointer;
      }

      /* Dropdown hover/active state */

      &.active,
      &:active,
      &:hover {
        color: unset;
        background-color: ${colorToCode('light-200')};
      }

      .rbt-highlight-text {
        font-weight: inherit;
        background-color: transparent;
      }
    }
  }

  .rbt-aux {
    align-items: flex-start;
    margin-top: 0.625rem;
  }

  .rbt-input-multi {
    height: fit-content !important;

    .rbt-token {
      padding: 4px 10px;

      &.rbt-token-removeable {
        padding-right: 21px;
      }

      .rbt-token-remove-button:hover {
        border: unset;
        box-shadow: unset;
        color: unset;
      }
    }
  }
`;

export default getMultiSelectSearchStyles;
