import { useContext } from 'react';
import DisposalUrlContext from '~/wm/packages/disposal/context/DisposalUrlContext';

const useDisposalUrlContext = () => {
  const disposalUrlContext = useContext(DisposalUrlContext);
  if (!disposalUrlContext) {
    throw new Error('Tried to use DisposalUrlContext outside of an DisposalUrlProvider');
  }

  return disposalUrlContext;
};

export default useDisposalUrlContext;
