import Button from '~/neo-ui/packages/button/Button';
import { css } from '@emotion/react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import * as React from 'react';
import { useRef } from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import { ButtonInternalRef } from '~/neo-ui/packages/button/ButtonInternal';
import { useOnClickOutside } from '~/extensions/packages/hooks/useOnClickOutside';

export type PeopleDashboardDropdownProps<T> = {
  buttonContent: {
    icon: IconType;
    title: React.ReactNode;
    description?: React.ReactNode;
    onClick: (isOpen: boolean) => void;
  };
  menuContent: {
    isMenuOpen: boolean;
    preOptionsSection: React.ReactNode;
    options: T[];
    option: (option: T) => React.ReactNode;
    postOptionsSection: React.ReactNode;
  };
} & Styleable;

const PeopleDashboardDropdown = <T,>({ buttonContent, menuContent, className }: PeopleDashboardDropdownProps<T>) => {
  const closeDropdown = () => buttonContent.onClick(false);
  const buttonRef = React.createRef<ButtonInternalRef>();
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside([buttonRef, dropdownRef], closeDropdown);

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <Button
        ref={buttonRef}
        css={css`
          position: relative;

          /**
           * CSS resets so we can control the button content dynamism in this component
           */
          height: unset;
          width: 100%;

          .button-children {
            white-space: unset;
          }
        `}
        onClick={() => {
          buttonContent.onClick(!menuContent.isMenuOpen);
        }}
        preventOnClickPropagation={true}
        className={`${className ? className : ''} dropdown`}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.625rem;
            width: 100%;
          `}
        >
          <Icon
            sizePx={16}
            icon={buttonContent.icon}
          />
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 0.125rem;
              text-align: left;
            `}
          >
            {buttonContent.title}
            {buttonContent.description}
          </div>
          <Icon
            css={css`
              margin-left: auto;
            `}
            sizePx={16}
            icon={'ArrowDown'}
          />
        </div>
      </Button>
      <div
        css={css`
          width: 100%;
          padding: 1rem;
          // Override the dropdown menu's cursor
          cursor: default;
        `}
        ref={dropdownRef}
        className={`dropdown-menu ${menuContent.isMenuOpen ? 'show' : ''}`}
        aria-labelledby="dropdownMenuButton"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div
          onClick={e => {
            e.stopPropagation();
          }}
          className={className}
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            max-width: 100%;
          `}
        >
          {menuContent.preOptionsSection}
          <hr
            css={css`
              color: ${colorToCode('dark-900-24')};
              margin: unset;
            `}
          />
          {menuContent.options.map((option, index) => (
            <React.Fragment key={index}>
              {menuContent.option(option)}
              <hr
                css={css`
                  color: ${colorToCode('dark-900-24')};
                  margin: unset;
                `}
              />
            </React.Fragment>
          ))}
          {menuContent.postOptionsSection}
        </div>
      </div>
    </div>
  );
};

export default PeopleDashboardDropdown;
