import Form from '~/neo-ui/packages/form/Form';
import React from 'react';
import {
  CreditCardFormData,
  CreditCardFormDataNewCard,
  PaymentVersion,
} from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import { DisposalDynamicPricingDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Dto/Model.gen';
import DisposalSelfCheckoutFormData from '../../../types/DisposalSelfCheckoutFormData';
import { Coupon } from '~/wm/packages/coupon/Coupon';
import DisposalCheckoutCouponFormData from '~/wm/packages/disposal/types/DisposalCheckoutCouponFormData';
import DisposalCoupon from '~/wm/packages/disposal/wizard/packages/disposal-payment-form/packages/disposal-coupon/DisposalCoupon';
import DisposalCreditCard from '~/wm/packages/disposal/wizard/packages/disposal-payment-form/packages/disposal-credit-card/DisposalCreditCard';

export type DisposalPaymentFormData = {
  customerToken: CreditCardFormData | undefined;
  coupon: Coupon | undefined;
};

type DisposalPaymentFormProps = {
  /**
   * Version of the Payment system
   */
  paymentVersion: PaymentVersion;
  /**
   * The credit card form data that the wizard form stores
   * to maintain the credit card change while navigating steps
   */
  creditCardFormData: CreditCardFormData | undefined;
  /**
   * The coupon form data that the wizard form stores
   * to maintain the coupon "applied" while navigating steps
   */
  coupon: DisposalCheckoutCouponFormData | undefined;
  /**
   * The DisposalDynamicPricingDto helps determine the total price when
   * validating a coupon.
   *
   * All coupons require a minimum order amount to apply the coupon at checkout.
   * Therefore, we must provide this total price to validate if this order meets that criteria.
   */
  disposalDynamicPricing: DisposalDynamicPricingDto;
  /**
   * Used for calculating the dynamic pricing for the DisposalOrder.
   * This gets combined with the DisposalDynamicPricingDto to get the final cost.
   */
  assetCount: number;
  /**
   * Emits whether the DisposalPaymentForm is entering or leaving
   * an editing state
   */
  onEditing: (isEditing: boolean, field: FieldKeyExpression<DisposalSelfCheckoutFormData>) => void;
  /**
   * Emits a new credit card after the user
   * enters a valid credit card and clicks "Done"
   */
  onNewCreditCard: (creditCard: CreditCardFormDataNewCard) => void;
  /**
   * Emits a new coupon code after the user
   * enters a valid coupon code and clicks "Apply"
   */
  onCouponValidated: (coupon: Coupon) => void;
  /**
   * Invoked when a coupon is set to unapply
   */
  onCouponRemoved: () => void;
};

const creditCardFieldKey: FieldKeyExpression<DisposalPaymentFormData> = values => values.customerToken;

const couponCodeFieldKey: FieldKeyExpression<DisposalPaymentFormData> = values => values.coupon.code;

const couponFieldKey: FieldKeyExpression<DisposalPaymentFormData> = values => values.coupon;

/**
 * Specifically handles adding a new credit card for the disposal wizard's
 * payment step.
 */
const DisposalPaymentForm: React.FunctionComponent<DisposalPaymentFormProps> = ({
  disposalDynamicPricing,
  paymentVersion,
  creditCardFormData,
  coupon,
  assetCount,
  onEditing,
  onNewCreditCard,
  onCouponValidated,
  onCouponRemoved,
}) => {
  const [isEditingCreditCard, setEditingCreditCard] = React.useState(typeof creditCardFormData === 'undefined');

  const [isEditingCouponCode, setEditingCouponCode] = React.useState(false);

  const onSubmit = React.useCallback(
    async (data: DisposalPaymentFormData) => {
      if (typeof data.customerToken !== 'undefined' && data.customerToken?.type === 'new-card') {
        onNewCreditCard(data.customerToken);
        setEditingCreditCard(false);
      }

      if (typeof data.coupon !== 'undefined') {
        onCouponValidated(data.coupon);
        setEditingCouponCode(false);
      }
    },
    [onCouponValidated, onNewCreditCard],
  );

  const toFormData = React.useCallback(
    (): DisposalPaymentFormData => ({
      customerToken: creditCardFormData,
      coupon: undefined,
    }),
    [creditCardFormData],
  );

  const defaultFormData = React.useMemo(() => toFormData(), [toFormData]);

  React.useEffect(
    () => onEditing(isEditingCreditCard || isEditingCouponCode, isEditingCouponCode ? couponCodeFieldKey : creditCardFieldKey),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Form
      submitMethod={'manual'}
      onSubmit={onSubmit}
      disableSubmitOnEnter={true}
      disableOverwriteDefaultFormDataOnSave={true}
      hideSubmissionButton={true}
      defaultFormData={defaultFormData}
      css={css`
        width: 100%;
      `}
    >
      {isEditingCouponCode || isEditingCreditCard ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 1rem;
          `}
        >
          <DisposalCreditCard
            creditCardFieldKey={creditCardFieldKey}
            creditCardFormData={creditCardFormData}
            isEditing={isEditingCreditCard}
            onEditing={isEditing => {
              onEditing(isEditing, creditCardFieldKey);
              setEditingCreditCard(isEditing);
            }}
            paymentVersion={paymentVersion}
          />
          <DisposalCoupon
            initialCoupon={coupon}
            disposalDynamicPricing={disposalDynamicPricing}
            couponCodeFieldKey={couponCodeFieldKey}
            couponFieldKey={couponFieldKey}
            assetCount={assetCount}
            isEditing={isEditingCouponCode}
            onEditing={isEditing => {
              onEditing(isEditing, couponFieldKey);
              setEditingCouponCode(isEditing);
            }}
            onCouponRemoved={onCouponRemoved}
          />
        </div>
      ) : (
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 1rem;
            `}
          >
            <DisposalCreditCard
              creditCardFieldKey={creditCardFieldKey}
              creditCardFormData={creditCardFormData}
              isEditing={isEditingCreditCard}
              onEditing={isEditing => {
                onEditing(isEditing, creditCardFieldKey);
                setEditingCreditCard(isEditing);
              }}
              paymentVersion={paymentVersion}
            />
            <DisposalCoupon
              initialCoupon={coupon}
              disposalDynamicPricing={disposalDynamicPricing}
              couponCodeFieldKey={couponCodeFieldKey}
              couponFieldKey={couponFieldKey}
              assetCount={assetCount}
              isEditing={isEditingCouponCode}
              onEditing={isEditing => {
                onEditing(isEditing, couponFieldKey);
                setEditingCouponCode(isEditing);
              }}
              onCouponRemoved={onCouponRemoved}
            />
          </div>
          <div
            css={css`
              display: flex;
              gap: 0.625rem;
            `}
          >
            <Button
              iconLeft={'Edit'}
              onClick={() => {
                onEditing(true, creditCardFieldKey);
                setEditingCreditCard(true);
              }}
            >
              Use a different card...
            </Button>
            {typeof coupon === 'undefined' && (
              <Button
                iconLeft={'Ticket'}
                onClick={() => {
                  onEditing(true, couponFieldKey);
                  setEditingCouponCode(true);
                }}
              >
                Add a coupon...
              </Button>
            )}
          </div>
        </div>
      )}
    </Form>
  );
};

export default DisposalPaymentForm;
