import React, { useMemo } from 'react';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import { Enum as DeviceType } from '@WarrantyClient/BeastClient/Goods/DeviceType/DeviceTypeFactoryNested.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

const WarrantyCartWorkstationsReminder: React.FunctionComponent = () => {
  const { selectedDeviceType, getSelectedAssetsCount, duration } = useWarrantyCartContext();

  const required = useMemo((): number => {
    const selected = (+duration / 12) * getSelectedAssetsCount();
    return 3 - selected;
  }, [getSelectedAssetsCount, duration]);

  return selectedDeviceType === DeviceType.Workstation && required > 0 ? (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 0.3rem;
      `}
    >
      <Icon
        icon={'Info'}
        color={'primary-800'}
        sizePx={16}
      />
      <div
        css={css`
          color: ${colorToCode('primary-800')};
        `}
      >
        Select {required} more asset{required !== 1 ? 's' : ''} or increase the coverage length to minimize your costs
      </div>
    </div>
  ) : null;
};

export default WarrantyCartWorkstationsReminder;
