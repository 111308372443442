import { subscriptionPricingDetailsGet } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import { useEffect, useState } from 'react';
import { Response as SubscriptionGroupPricingDetails } from '@SubscriptionClient/BeastClient/Beast/Msp/Controller/Subscription/Details/SubscriptionGroupPricingDetailsGetNested.gen';

const useSubscriptionGroupDetails = () => {
  const [isLoading, setLoading] = useState(true);
  const [subscriptionGroupDetails, setSubscriptionGroupDetails] = useState<SubscriptionGroupPricingDetails>();

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => subscriptionPricingDetailsGet({}));
      setLoading(false);

      if (!response) {
        return;
      }

      setSubscriptionGroupDetails(response);
    })();
  }, [callApi]);

  return { isLoading, subscriptionGroupDetails };
};

export default useSubscriptionGroupDetails;
