import { ScopeKey } from '@AssetManagementClient/BeastClient/Beast/Settings/Scope.gen';

import * as React from 'react';
import SettingsConfigurable from '~/wm/packages/settings/packages/configurable/SettingsConfigurable';
import HardwareReplacementSettingsFormV2 from '~/wm/packages/hardware/packages/settings/configurable/HardwareReplacementSettingsFormV2';
import { HardwareReplacementReportAssetTypeSettingsAvailabilitiesDto } from '@AssetManagementClient/BeastClient/Beast/HardwareReplacement/Dto/Model.gen';
import hardwareReplacementReportAssetTypeSettingsValidationSchema from '~/wm/packages/hardware/packages/settings/validation/hardwareReplacementReportAssetTypeSettingsValidationSchema';

export type HardwareReplacementConfigurableV2Props = {
  availabilities: HardwareReplacementReportAssetTypeSettingsAvailabilitiesDto;
  scopeKey: ScopeKey;
  currencyCharacter: string;
};

const HardwareReplacementConfigurableV2: React.FunctionComponent<HardwareReplacementConfigurableV2Props> = ({
  availabilities,
  scopeKey,
  currencyCharacter,
}) => (
  <SettingsConfigurable
    settingsKey={{
      scopeKey,
      /** Referenced in beast: {9305184B-BCF4-43AA-84F7-51001B7AE4BF} */
      configurableId: 'hardware-replacement-report-asset-type',
    }}
    validationSchema={hardwareReplacementReportAssetTypeSettingsValidationSchema(availabilities)}
    label="Replacement"
    description="Customize each hardware asset type's value and recommendation criteria."
  >
    {({ isUsingDefault }) => (
      <HardwareReplacementSettingsFormV2
        availabilities={availabilities}
        currencyCharacter={currencyCharacter}
        disabled={isUsingDefault}
      />
    )}
  </SettingsConfigurable>
);

export default HardwareReplacementConfigurableV2;
