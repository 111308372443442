import { useState } from 'react';

export type UseDisposalOrderAssetSelect = {
  /**
   * All DisposalOrderAssetIds selected
   */
  disposalOrderAssetIds: Set<string>;

  /**
   * Replace DisposalOrderAssetIds with what is given
   * @param disposalOrderAssetIds
   */
  updateSelectedDisposalOrderAssetIds: (disposalOrderAssetIds: Set<string>) => void;

  /**
   * clear all DisposalOrderAssetIds selected
   */
  clearDisposalOrderAssetIds: () => void;
};

/**
 * Maintain a Set of selected DisposalOrderAssetIds
 */
const useDisposalOrderAssetSelect = (): UseDisposalOrderAssetSelect => {
  const [disposalOrderAssetIds, setDisposalOrderAssetIds] = useState<Set<string>>(new Set());

  return {
    disposalOrderAssetIds,

    clearDisposalOrderAssetIds: () => setDisposalOrderAssetIds(() => new Set()),

    updateSelectedDisposalOrderAssetIds: disposalOrderAssetIds => {
      setDisposalOrderAssetIds(new Set([...disposalOrderAssetIds]));
    },
  };
};

export default useDisposalOrderAssetSelect;
