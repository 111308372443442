import * as React from 'react';
import BannerInternal, { BannerCallToAction } from '~/neo-ui/packages/banner/BannerInternal';

export type BannerNoticeProps = {
  title: string;
  description: React.ReactNode;
  imageSource: string;
  badge?: string;
  callToAction?: BannerCallToAction;
  closeable?: boolean;
  onClose?: () => void;
};

/**
 * A banner providing information for use cases on the page
 */
const BannerNotice = ({ title, description, imageSource, badge, callToAction, closeable, onClose }: BannerNoticeProps) => (
  <BannerInternal
    titleProps={{
      children: title,
      size: 4,
      weight: 'bold',
      color: 'dark-900',
    }}
    descriptionProps={{
      children: description,
      size: 5,
      weight: 'light',
      color: 'dark-900',
    }}
    backgroundColor={'secondary-050'}
    boxShadow={'shadow0'}
    graphic={{ imageSource }}
    badge={badge}
    callToAction={callToAction}
    closeable={closeable}
    onClose={onClose}
  />
);

export default BannerNotice;
