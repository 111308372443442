import { css, SerializedStyles } from '@emotion/react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type BadgeMarketingType = 'pro' | 'vcio';

export type BadgeMarketingDefinition = {
  /**
   * The color applied to the label and icon
   */
  color: Color;
  /**
   * The inverted color applied to the label and icon
   */
  invertedColor: Color;
  /**
   * The icon of the badge
   */
  icon: IconType;
  /**
   * The label of the badge
   */
  label: string;
  /**
   * The background of the badge
   */
  background: SerializedStyles;
};

const useBadgeMarketingDefinition = (type: BadgeMarketingType): BadgeMarketingDefinition => definitionByBadgeMarketingType[type];

const definitionByBadgeMarketingType: Record<BadgeMarketingType, BadgeMarketingDefinition> = {
  pro: {
    icon: 'Upgraded',
    label: 'Pro',
    color: 'light-000',
    invertedColor: 'secondary-500',
    background: css`
      background: linear-gradient(180deg, ${colorToCode('secondary-400')} 0%, ${colorToCode('primary-400')} 100%);
    `,
  },
  vcio: {
    icon: 'StatusUpgrade',
    label: 'vCIO',
    color: 'light-000',
    invertedColor: 'excellent-500',
    background: css`
      background: linear-gradient(180deg, ${colorToCode('secondary-400')} 0%, #c44ddb 100%);
    `,
  },
};

export default useBadgeMarketingDefinition;
