import { Themeable } from '~/neo-ui/model/capacity';
import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';

type FilterHeaderForecastProps = {
  selectedValue?: SelectOption;
  defaultDisplay: ReactNode;
  selectedDisplay: ReactNode;
} & Themeable;

const FilterHeaderForecast: React.FunctionComponent<FilterHeaderForecastProps> = ({ selectedValue, defaultDisplay, selectedDisplay }) => {
  let header: ReactNode;

  if (typeof selectedValue === 'undefined' || selectedValue?.value === 'now') {
    header = defaultDisplay;
  } else {
    header = selectedDisplay;
  }

  return (
    <div
      css={css`
        & > * {
          display: flex;
          align-items: center;
        }
      `}
    >
      {header}
    </div>
  );
};

export default FilterHeaderForecast;
