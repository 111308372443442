import Button from '~/neo-ui/packages/button/Button';
import Window from '~/neo-ui/packages/window/Window';
import * as React from 'react';
import EngagementNoteFieldsFormModule from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/packages/engagement-note-fields-form-module/EngagementNoteFieldsFormModule';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { EngagementNoteFormDataType } from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/types/EngagementNoteFormDataType';
import usePanelTabGroupContext from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/hooks/usePanelTabGroupContext';
import { EngagementListType } from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/EngagementListPage';

export type EngagementNoteCreateWindowProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

const EngagementNoteCreateWindow = ({ isOpen, onDismiss }: EngagementNoteCreateWindowProps) => {
  const { submitForm, isSubmitting, isValid, addAfterSubmitListener, dirty } = useFormContext<EngagementNoteFormDataType>();

  const { setCurrentTabPage, setCurrentTabFilterValue } = usePanelTabGroupContext<EngagementListType>();

  React.useEffect(
    () =>
      addAfterSubmitListener(async () => {
        onDismiss();
        // 1-1 Bound in Beast: 5a0e8fc0-d5e1-48cc-bbad-f98e14da530d
        setCurrentTabFilterValue('Live');
        setCurrentTabPage(1);
      }),
    [addAfterSubmitListener, onDismiss, setCurrentTabFilterValue, setCurrentTabPage],
  );

  return (
    <Window
      title={'Create Note'}
      isOpen={isOpen}
      onDismiss={onDismiss}
      footerProps={{
        leftControls: [
          {
            expanded: (
              <Button
                iconLeft={'Archive'}
                disabled={true}
              >
                Archive This Note
              </Button>
            ),
          },
        ],
        rightControls: [
          {
            expanded: (
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty}
                size={'sm'}
                theme={'primary'}
                onClick={() => {
                  submitForm();
                }}
              >
                Save
              </Button>
            ),
          },
          {
            expanded: (
              <Button
                size={'sm'}
                disabled={isSubmitting}
                onClick={onDismiss}
              >
                Cancel
              </Button>
            ),
          },
        ],
      }}
    >
      <EngagementNoteFieldsFormModule />
    </Window>
  );
};

export default EngagementNoteCreateWindow;
