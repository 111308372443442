import { AddressBusiness } from '@WarrantyClient/Common/Location/Model/Address.gen';
import { ServiceRequest as WarrantyClientWarrantyServiceRequestModelServiceRequest } from '@WarrantyClient/Warranty/ServiceRequest/Model.gen';
import { StatusEnum } from '@WarrantyClient/Warranty/ServiceRequest/Model/ServiceRequestNested.gen';
import { Enum as ServiceTypeEnum } from '@WarrantyClient/Warranty/ServiceRequest/Model/ServiceRequestTypeFactoryNested.gen';
import { Enum, Enum as SlaEnum } from '@WarrantyClient/Warranty/Sla/Model/SlaTypeFactoryNested.gen';
import { parseISO } from 'date-fns';
import { assertNeverOrThrow } from '~/extensions/packages/types/assertNever';
import Color from '~/neo-ui/packages/color/Color.gen';
import { StringKey as WarrantyClientPrimitivesUniqueKeyStringKey } from '@WarrantyClient/Primitives/UniqueKey.gen';
import IconType from '~/neo-ui/packages/icon/IconType.gen';

export type Asset = {
  orgName: string;
  assetName: string;
  modelNumber: string | undefined;
  serialNumber: string;
  siteAddress: AddressBusiness;
};

type ServiceRequest = {
  state: { label: string; color?: Color; icon: IconType };
  status: StatusEnum;
  serviceRequestId: string;
  submittedByName: string | undefined;
  submittedAt: Date;
  ownedByName: string | undefined;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  description: string;
  note: string;
  serviceType?: { enum: ServiceTypeEnum; label: string };
  problemType?: {
    uniqueId: WarrantyClientPrimitivesUniqueKeyStringKey;
    display: string;
  };
  sla: { enum: SlaEnum; label: string };
  coveragePlanLabel: string;
  asset: Asset;
  shippingAddress: AddressBusiness | undefined;
  trackingNumber: string | undefined;
};

export const convertBeastServiceRequest = (request: WarrantyClientWarrantyServiceRequestModelServiceRequest): ServiceRequest => ({
  state:
    request.status === StatusEnum.Complete
      ? {
          label: 'Closed',
          color: 'positive-400',
          icon: 'ServiceRequestServerNetwork',
        }
      : !request.trackingNumber || request.status === StatusEnum.Submitted
      ? {
          label: 'Submitted',
          color: 'secondary-400',
          icon: 'ServiceRequestServerNetwork',
        }
      : request.status === StatusEnum.InProgress
      ? {
          label: 'Open',
          color: 'info-400',
          icon: 'ServiceRequestServerNetworkOpen',
        }
      : assertNeverOrThrow(request.status),
  status: request.status,
  serviceRequestId: request.serviceRequestId,
  submittedByName: request.ownedByUserName,
  submittedAt:
    // TODO WM-1555
    parseISO(request.submittedAt),
  ownedByName: request.ownedByUserName,
  contactName: request.siteContactInformation.fullName,
  contactEmail: request.siteContactInformation.email,
  contactPhone: request.siteContactInformation.phone,
  description: request.description,
  note: request.notes,
  serviceType:
    typeof request.serviceType !== 'undefined'
      ? {
          enum: request.serviceType,
          label: ((): string => {
            switch (request.serviceType) {
              case ServiceTypeEnum.OnSite:
                return 'On site';
              case ServiceTypeEnum.PartsOnly:
                return 'Parts Only';
              case ServiceTypeEnum.DepotExpress:
                return 'Depot Express';
            }
          })(),
        }
      : undefined,
  problemType:
    typeof request.problemType !== 'undefined'
      ? {
          uniqueId: request.problemType.uniqueId,
          display: request.problemType.displayValue,
        }
      : undefined,
  sla: {
    enum: request.slaType,
    label: ((): string => {
      switch (request.slaType) {
        case Enum.FourHour:
          return '4-hour response';
        case Enum.NextBusinessDay:
          return 'Next business day response';
        case Enum.ReplacementInclAccidentalDamage:
          return 'Advanced exchange incl. accidental damage';
      }
    })(),
  },
  coveragePlanLabel: request.coveragePlanLabel,
  asset: {
    orgName: request.assetInformation.organizationName,
    assetName: request.assetInformation.name,
    modelNumber: request.assetInformation.modelNumber,
    serialNumber: request.assetInformation.serialNumber,
    siteAddress: request.assetInformation.siteAddress,
  },
  shippingAddress: request.shippingAddress,
  trackingNumber: request.trackingNumber,
});

export default ServiceRequest;
