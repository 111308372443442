import * as React from 'react';
import { useEffect, useState } from 'react';
import { FieldKeyExpression, FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { css } from '@emotion/react';
import { useOnClickOutside } from '~/extensions/packages/hooks/useOnClickOutside';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import Button from '~/neo-ui/packages/button/Button';
import { ButtonInternalRef } from '~/neo-ui/packages/button/ButtonInternal';
import usePanelTabGroupContext from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/hooks/usePanelTabGroupContext';
import { EngagementType } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/EngagementDashboard';
import { stringToEngagementActionStatusFilter } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/types/EngagementActionStatusFilter';
import FormDatepickerInput from '~/neo-ui/packages/form/packages/form-input/packages/form-datepicker-input/FormDatepickerInput';
import ActiveUsersSearch from '~/wm/packages/account/packages/user/packages/active-users-search/ActiveUsersSearch';
import FormTextareaInputInvisible from '~/neo-ui/packages/form/packages/form-input/packages/form-textarea-input/packages/form-textarea-input-invisible/FormTextareaInputInvisible';
import Box from '~/neo-ui/packages/box/Box';
import AnimationPresence from '~/neo-ui/packages/animation/packages/animate-presence/AnimationPresence';
import EngagementActionDashboardCreateControlsToolbar from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-action-dashboard-panel-tab/packages/engagement-action-dashboard-create-controls/packages/engagement-action-dashboard-create-controls-toolbar/EngagementActionDashboardCreateControlsToolbar';
import useActiveUsersContext from '~/wm/packages/account/packages/user/context/hooks/useActiveUsersContext';
import {
  descriptionFieldKeyId,
  titlefieldKeyId,
} from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/TicketCreateForm';
import { TicketFieldValues } from '~/wm/packages/integration/packages/ticket/types/TicketFieldValues';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import { EngagementActionCreateFormDataType } from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/types/EngagementActionCreateFormDataType';
import formatTicketTitleEngagementAction from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/format/formatTicketTitleEngagementAction';
import formatTicketDescriptionEngagementAction from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/format/formatTicketDescriptionEngagementAction';
import useTicketFieldAvailabilitiesContext from '~/wm/packages/integration/packages/ticket/context/hooks/useTicketFieldAvailabilitiesContext';
import { parseISO } from 'date-fns';
import { EngagementActionInitiativeLinkPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import { InitiativeCreateSource } from '~/wm/packages/strategy/model/strategyDataCollectionSources';

const descriptionFieldKey: FieldKeyExpression<EngagementActionCreateFormDataType> = values => values.description;
const dueAtFieldKey: FieldKeyExpression<EngagementActionCreateFormDataType> = values => values.dueAt;
const assignedUserIdsFieldKey: FieldKeyExpression<EngagementActionCreateFormDataType> = values => values.assignedUserIds;
const ticketFieldValuesFieldKey: FieldKeyExpression<EngagementActionCreateFormDataType> = values => values.ticketFieldValues;
const initiativeLinkFieldValuesFieldKey: FieldKeyExpression<EngagementActionCreateFormDataType> = values => values.initiativeLinkPayload;

export type EngagementActionDashboardCreateControlsProps = {
  initiativeCreatedFrom: InitiativeCreateSource;
  /**
   * Specific to when this component is used in an Initiative to prevent
   * showing Initiative related controls
   */
  isInitiative?: boolean;
};

const EngagementActionDashboardCreateControls = ({
  initiativeCreatedFrom,
  isInitiative = false,
}: EngagementActionDashboardCreateControlsProps) => {
  const { organizationName, organizationId } = useOrganizationContext();
  const { ticketFieldOptions } = useTicketFieldAvailabilitiesContext();

  const { setCurrentTabFilterValue, setCurrentTabPage } = usePanelTabGroupContext<EngagementType>();

  const [shouldCreateTicketCardStayOpened, setShouldCreateTicketCardStayOpened] = useState(false);
  const [isCreatingAction, setCreatingAction] = useState(false);
  const [isUpdatingaction, setUpdatingAction] = useState(true);
  const [isDisplayingControls, setDisplayControls] = useState(false);
  const [isEditingTicket, setEditingTicket] = useState(false);
  const [isEditingInitiativeLink, setEditingInitiativeLink] = useState(false);
  const { users } = useActiveUsersContext();

  const { submitForm, isValid, setFormInput, addAfterSubmitListener, getFormInput } = useFormContext<EngagementActionCreateFormDataType>();
  const actionDescription = getFormInput<string>(descriptionFieldKey).value;
  const dueAt =
    getFormInput<string>(dueAtFieldKey).value !== '' ? new Date(parseISO(getFormInput<string>(dueAtFieldKey).value)) : undefined;
  const assignedUserIds = getFormInput<string[]>(assignedUserIdsFieldKey).value;
  const ticketFieldValues = getFormInput<TicketFieldValues>(ticketFieldValuesFieldKey).value;

  const previousTicketFieldValueRef = React.useRef<TicketFieldValues>(ticketFieldValues);
  const buttonRef = React.createRef<ButtonInternalRef>();
  const cardRef = React.createRef<HTMLDivElement>();

  const handleSubmitForm = React.useCallback(() => {
    if (!isValid || isEditingTicket || isEditingInitiativeLink) {
      return;
    }

    setUpdatingAction(false);
    submitForm();
  }, [isEditingInitiativeLink, isEditingTicket, isValid, submitForm]);

  useOnClickOutside([buttonRef, cardRef], () => {
    setShouldCreateTicketCardStayOpened(false);
    handleSubmitForm();
  });

  const resetFields = React.useCallback(() => {
    if (!isCreatingAction || isUpdatingaction) {
      return;
    }
    setFormInput<string>(descriptionFieldKey, '');
    setFormInput<string>(dueAtFieldKey, '');
    setFormInput<string[]>(assignedUserIdsFieldKey, []);
    setFormInput<TicketFieldValues>(ticketFieldValuesFieldKey, {});
    setFormInput<EngagementActionInitiativeLinkPayloadDto | undefined>(initiativeLinkFieldValuesFieldKey, undefined);
  }, [isCreatingAction, isUpdatingaction, setFormInput]);

  // Reset fields and navigate to the upcoming tab when the form is submitted
  useEffect(
    () =>
      addAfterSubmitListener(async () => {
        resetFields();
        setCurrentTabPage(1);
        setCurrentTabFilterValue(stringToEngagementActionStatusFilter('Upcoming'));
        if (!shouldCreateTicketCardStayOpened) {
          setCreatingAction(false);
        }
        setDisplayControls(false);
      }),
    [addAfterSubmitListener, resetFields, setCurrentTabFilterValue, setCurrentTabPage, setFormInput, shouldCreateTicketCardStayOpened],
  );

  // Reset fields when the create action control is hidden
  useEffect(() => {
    if (!isCreatingAction) {
      resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatingAction]);

  const selectedUsers = users.filter(user => getFormInput<string[]>(assignedUserIdsFieldKey).value.includes(user.userId));
  const hasTicketFieldValues = Object.keys(ticketFieldValues).length > 0;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        z-index: 1;
      `}
    >
      <Button
        ref={buttonRef}
        css={css`
          text-align: left;
          scroll-margin-top: 10rem;
        `}
        iconLeft={'Add'}
        onClick={() => {
          handleSubmitForm();
          if (!isCreatingAction) {
            buttonRef.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
          setShouldCreateTicketCardStayOpened(true);
          setCreatingAction(true);
        }}
      >
        Create action items
      </Button>
      <AnimationPresence
        isPresent={isCreatingAction}
        presenceDirection={'bottom'}
        presenceAnimationType={'spring'}
      >
        <div ref={cardRef}>
          <Box
            theme={'none'}
            background={'foregroundAccent'}
            padding={'padding200'}
            borderRadius={'radius400'}
            boxShadow={'shadow100'}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 1rem;
              `}
            >
              <Button
                size={'xs'}
                iconLeft={'Done'}
                disabled={true}
              />

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 1rem;
                  flex: 1 1 100%;
                `}
              >
                <EngagementActionDashboardCreateControlsToolbar
                  isInitiative={isInitiative}
                  isDisplayingControls={isDisplayingControls}
                  onDeleteAction={() => {
                    resetFields();
                    setCreatingAction(false);
                    setDisplayControls(false);
                  }}
                  onCreatingInitiativeLink={() => {
                    setEditingInitiativeLink(true);
                  }}
                  onCreateTicket={() => {
                    setEditingTicket(false);
                    previousTicketFieldValueRef.current = ticketFieldValues;
                    setShouldCreateTicketCardStayOpened(false);
                  }}
                  onCreatingTicket={() => {
                    setEditingTicket(true);
                    if (!hasTicketFieldValues) {
                      setFormInput<TicketFieldValues>(ticketFieldValuesFieldKey, {
                        [titlefieldKeyId]: formatTicketTitleEngagementAction(actionDescription, ticketFieldOptions),
                        [descriptionFieldKeyId]: formatTicketDescriptionEngagementAction(
                          actionDescription,
                          selectedUsers,
                          dueAt,
                          organizationName,
                          ticketFieldOptions,
                        ),
                      });
                    }
                  }}
                  onCancelTicket={() => {
                    setEditingTicket(false);
                    setFormInput<TicketFieldValues>(ticketFieldValuesFieldKey, previousTicketFieldValueRef.current);
                  }}
                  onLinkExistingInitiative={initiativeId => {
                    setFormInput<EngagementActionInitiativeLinkPayloadDto | undefined>(initiativeLinkFieldValuesFieldKey, {
                      initiativeId,
                      createDefaultPayload: undefined,
                    });
                    setEditingInitiativeLink(false);
                  }}
                  onLinkNewInitiative={selectedTemplate => {
                    setFormInput<EngagementActionInitiativeLinkPayloadDto | undefined>(initiativeLinkFieldValuesFieldKey, {
                      initiativeId: undefined,
                      createDefaultPayload: {
                        organizationId,
                        createdFrom: initiativeCreatedFrom,
                        initiativeTemplateId: selectedTemplate?.initiativeTemplateId,
                      },
                    });
                    setEditingInitiativeLink(false);
                  }}
                />
                {/* Remount the component to clear the inputs */}
                {isCreatingAction && (
                  <React.Fragment>
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        gap: 0.3125rem;
                      `}
                    >
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                          gap: 0.3125rem;
                          width: 100%;
                        `}
                      >
                        <FormTextareaInputInvisible
                          hasDefaultFocus={true}
                          hasDefaultSelect={true}
                          optimizePerformance={false}
                          fieldKey={descriptionFieldKey}
                          showFormError={false}
                          onChange={value => {
                            if (hasTicketFieldValues) {
                              setFormInput<string>(
                                ({ ticketFieldValues }: FieldKeyExpressionSegment<EngagementActionCreateFormDataType>) =>
                                  ticketFieldValues[titlefieldKeyId],
                                formatTicketTitleEngagementAction(value, ticketFieldOptions),
                              );
                            }
                          }}
                          css={css`
                            width: 100%;
                          `}
                        />

                        <FormDatepickerInput
                          size={'sm'}
                          css={css`
                            margin-left: auto;
                          `}
                          fieldKey={dueAtFieldKey}
                          showFormError={false}
                          onChange={value => {
                            if (hasTicketFieldValues) {
                              const newDescription = formatTicketDescriptionEngagementAction(
                                actionDescription,
                                selectedUsers,
                                value !== '' ? new Date(parseISO(value)) : undefined,
                                organizationName,
                                ticketFieldOptions,
                              );
                              setFormInput<string>(
                                ({ ticketFieldValues }: FieldKeyExpressionSegment<EngagementActionCreateFormDataType>) =>
                                  ticketFieldValues[descriptionFieldKeyId],
                                newDescription,
                              );
                            }
                          }}
                        />
                      </div>
                      <Button
                        size={'xs'}
                        theme={'primary'}
                        iconLeft={'ActionMore'}
                        onClick={() => {
                          setDisplayControls(prev => !prev);
                        }}
                      />
                    </div>
                    <div
                      css={css`
                        display: flex;
                        gap: 0.5rem;
                        align-items: center;
                        white-space: nowrap;
                      `}
                    >
                      <ActiveUsersSearch
                        css={css`
                          width: 100%;
                        `}
                        inputLabel={{
                          icon: 'Users',
                          label: 'Assign to:',
                        }}
                        selectedUserIds={assignedUserIds}
                        onSelectedUsersChange={selectedUsers => {
                          const selectedUserIds = selectedUsers.map(user => user.userId);
                          setFormInput<string[]>(assignedUserIdsFieldKey, selectedUserIds);
                          const newSelectedUsers = users.filter(user => selectedUserIds.includes(user.userId));

                          if (hasTicketFieldValues) {
                            const newDescription = formatTicketDescriptionEngagementAction(
                              actionDescription,
                              newSelectedUsers,
                              dueAt,
                              organizationName,
                              ticketFieldOptions,
                            );
                            setFormInput<string>(
                              ({ ticketFieldValues }: FieldKeyExpressionSegment<EngagementActionCreateFormDataType>) =>
                                ticketFieldValues[descriptionFieldKeyId],
                              newDescription,
                            );
                          }
                        }}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </Box>
        </div>
      </AnimationPresence>
    </div>
  );
};

export default EngagementActionDashboardCreateControls;
