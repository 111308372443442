import { SettingsValueNested } from '@AssetManagementClient/BeastClient/Beast/Settings/Model.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import ListItem from '~/legacy-ui/packages/list/packages/list-item/ListItem';
import { Styleable } from '~/neo-ui/model/capacity';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import FormNumberInput from '~/neo-ui/packages/form/packages/form-input/packages/form-number-input/FormNumberInput';
import FormSelectInput, { FormSelectOption } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import ToggleItem from '~/neo-ui/packages/toggle/packages/toggle-item/ToggleItem';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';

export type RecommendationCriterionProps = {
  recommendationValueFieldKey: FieldKeyExpression<SettingsValueNested.HardwareReplacementSettings>;
  icon: IconType;
  title: string;
  description?: string;
  type: 'threshold' | 'select';
  thresholdLabel?: string;
  thresholdUnit?: string;
  selectOptions?: FormSelectOption[];
} & Styleable;

const RecommendationCriterion: React.FunctionComponent<RecommendationCriterionProps> = ({
  recommendationValueFieldKey,
  icon,
  title,
  description,
  type,
  thresholdLabel,
  thresholdUnit,
  selectOptions,
  className,
}) => {
  const { getFormInput, setFormInput } = useFormContext<SettingsValueNested.HardwareReplacementSettings>();

  const recommendationValueFormInput = getFormInput<SettingsValueNested.HardwareReplacementSettings>(recommendationValueFieldKey);

  const [isEnabled, setEnabled] = React.useState(typeof recommendationValueFormInput.value !== 'undefined');

  React.useEffect(() => {
    // This is a workaround to resolve an issue with form-dto conversion.
    // Please see WM-2940.
    if (typeof recommendationValueFormInput.value === 'undefined') {
      setFormInput<string | undefined>(recommendationValueFieldKey, type === 'select' ? '' : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ToggleItem
      onToggle={() => {
        if (isEnabled) {
          // Disable & clear input value
          setEnabled(false);
          setFormInput<string>(recommendationValueFieldKey, '');
        } else {
          setEnabled(true);
        }
      }}
      checked={isEnabled}
    >
      <div
        className={className}
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 1rem;
        `}
      >
        <ListItem
          title={title}
          titleMuted={true}
          description={description}
          descriptionMuted={true}
          size="sm"
          icon={icon}
        />
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <span
            css={css`
              color: ${!isEnabled ? colorToCode('light-700') : colorToCode('dark-900-32')};
              font-weight: bold;
              margin-right: 0.625rem;
            `}
          >
            {thresholdLabel}
          </span>
          <div
            css={css`
              width: 8.125rem;
            `}
          >
            {type === 'threshold' ? (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 0.3125rem;
                `}
              >
                <FormNumberInput
                  css={css`
                    flex: auto;
                  `}
                  fieldKey={recommendationValueFieldKey}
                  disabled={!isEnabled}
                />
                <Label>{thresholdUnit}</Label>
              </div>
            ) : type === 'select' ? (
              <FormSelectInput
                fieldKey={recommendationValueFieldKey}
                options={selectOptions ? selectOptions : []}
                disabled={!isEnabled}
                toFormData={value => (value ? parseInt(value, 10) : undefined)}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </ToggleItem>
  );
};

export default RecommendationCriterion;
