import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import * as React from 'react';
import { useState } from 'react';
import { css } from '@emotion/react';
import FormDatepickerInput from '~/neo-ui/packages/form/packages/form-input/packages/form-datepicker-input/FormDatepickerInput';

import FormToggleSwitchInput from '~/neo-ui/packages/form/packages/form-input/packages/form-toggle-switch-input/FormToggleSwitchInput';
import FormNumberInput from '~/neo-ui/packages/form/packages/form-input/packages/form-number-input/FormNumberInput';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';

export type ContractUpsertRenewSectionProps<T> = {
  nextDueFieldKey: FieldKeyExpression<T>;
  endDateFieldKey: FieldKeyExpression<T>;
  autoRenewFieldKey: FieldKeyExpression<T>;
  noticeDaysFieldKey: FieldKeyExpression<T>;
  budgetPastEndDateFieldKey: FieldKeyExpression<T>;
};

const ContractUpsertRenewSection = <T,>({
  nextDueFieldKey,
  endDateFieldKey,
  autoRenewFieldKey,
  noticeDaysFieldKey,
  budgetPastEndDateFieldKey,
}: ContractUpsertRenewSectionProps<T>) => {
  const formFieldCss = css`
    display: flex;
    gap: 1rem;
    align-items: center;
    width: calc(100% / 3);
  `;
  const [selectedEndDate, setSelectedEndDate] = useState(false);

  const handleEndDateChange = (endDate: string) => {
    const convertedDate = new Date(endDate);

    // Check if the date is valid
    if (convertedDate instanceof Date && !isNaN(convertedDate.getTime())) {
      setSelectedEndDate(true); // Set to true if it's a valid date
    } else {
      setSelectedEndDate(false); // Set to false if the date is invalid
    }
  };
  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-bottom: 1.5rem;
        `}
      >
        <Label
          size={'lg'}
          bold={true}
        >
          Dates and Renew
        </Label>
        <Tooltip
          content={
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
              `}
            >
              <div>
                <Label bold={true}>Next due</Label> → When is payment next due for this contract?
              </div>
              <div>
                <Label bold={true}>End date</Label> → For contracts with a term with an end date
              </div>
              <div>
                <Label bold={true}>Alert Lead Time (days)</Label> → How many days notice are required to cancel?
              </div>
            </div>
          }
        >
          <Icon
            icon={'Info'}
            sizePx={16}
            css={css`
              display: flex;
            `}
          />
        </Tooltip>
      </div>

      <div
        css={css`
          display: flex;
          gap: 2rem;
        `}
      >
        <div css={formFieldCss}>
          <Label
            size={'md'}
            bold={true}
            css={css`
              display: flex;
            `}
          >
            Next Due
            <Label
              size={'md'}
              bold={true}
              color={'negative-400'}
            >
              *
            </Label>
          </Label>
          <FormDatepickerInput
            css={css`
              flex-grow: 1;
              position: relative;
            `}
            fieldKey={nextDueFieldKey}
            fixedErrorPosition={true}
          />
        </div>
        <div css={formFieldCss}>
          <Label
            size={'md'}
            bold={true}
            css={css`
              display: flex;
            `}
          >
            End Date
          </Label>
          <FormDatepickerInput
            css={css`
              flex-grow: 1;
              position: relative;
            `}
            fieldKey={endDateFieldKey}
            fixedErrorPosition={true}
            onChange={handleEndDateChange}
          />
        </div>
        {selectedEndDate ? (
          <div css={formFieldCss}>
            <Label
              size={'md'}
              bold={true}
            >
              Auto Renew?
            </Label>
            <FormToggleSwitchInput
              fieldKey={autoRenewFieldKey}
              css={css`
                position: relative;
              `}
              fixedErrorPosition={true}
            />
          </div>
        ) : (
          <div css={formFieldCss} />
        )}
      </div>
      {selectedEndDate ? (
        <div
          css={css`
            display: flex;
            gap: 2rem;
            margin-top: 1.5rem;
          `}
        >
          <div css={formFieldCss}>
            <Label
              size={'md'}
              bold={true}
            >
              Alert Lead Time (days)
            </Label>
            <FormNumberInput
              css={css`
                flex-grow: 1;
                position: relative;
              `}
              fieldKey={noticeDaysFieldKey}
              min={1}
              fixedErrorPosition={true}
            />
          </div>
          <div css={formFieldCss}>
            <Label
              size={'md'}
              bold={true}
              css={css`
                display: flex;
              `}
            >
              Include in budget forecast past End Date
            </Label>
            <FormToggleSwitchInput
              css={css`
                flex-grow: 1;
                position: relative;
              `}
              fieldKey={budgetPastEndDateFieldKey}
              fixedErrorPosition={true}
            />
          </div>
          <div css={formFieldCss} />
        </div>
      ) : (
        <div
          css={css`
            display: flex;
            gap: 2rem;
            margin-top: 5rem;
          `}
        />
      )}
    </div>
  );
};

export default ContractUpsertRenewSection;
