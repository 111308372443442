import { SuccessIcon } from '~/sp-ui/icons';
import routes from '~/router/types/routes';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

const SubscriptionPurchasedStep = () => (
  <div css={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
    <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <SuccessIcon
        css={{
          height: '64px',
          width: '64px',
        }}
      />
      <div
        css={{
          color: '#2F3037',
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontSize: '2.25rem',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '2.8125rem',
          margin: '2rem 0 1rem',
        }}
      >
        Thank you for your purchase
      </div>
      <div
        css={{
          color: '#2F3037',
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '1.5rem',
          marginBottom: '4rem',
        }}
      >
        Please check your email for a confirmation of your new subscription
      </div>
      <a
        href={routes.ACCOUNT_HOME.route}
        css={{
          color: `${colorToCode('primary-400')}`,
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '1.5rem',
          textDecorationLine: 'underline',
        }}
      >
        Go to my ScalePad Account
      </a>
    </div>
  </div>
);

export default SubscriptionPurchasedStep;
