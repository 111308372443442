import useAutoEmailReportListMutation, {
  UseAutoEmailReportListMutationOptions,
} from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportListMutation';
import { autoEmailReportDelete } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseAutoEmailReportDelete = {
  deleteAutoEmailReport: (autoEmailReportId: string, isRequestActive?: () => boolean) => void;
  isDeletingAutoEmailReport: boolean;
};

const useAutoEmailReportDelete = (organizationId: string, options?: UseAutoEmailReportListMutationOptions): UseAutoEmailReportDelete => {
  const [deleteAutoEmailReport, isDeletingAutoEmailReport] = useAutoEmailReportListMutation(
    (autoEmailReportId: string) =>
      autoEmailReportDelete({
        autoEmailReportId,
        organizationId,
      }),
    options,
  );

  return {
    deleteAutoEmailReport,
    isDeletingAutoEmailReport,
  };
};

export default useAutoEmailReportDelete;
