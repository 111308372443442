import { organizationEngagementActionMarkCompleted } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementActionListMutation, {
  UseEngagementActionListMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListMutation';
import useEngagementActionDataCollectionContext from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionDataCollectionContext';

type EngagementActionMarkCompletePayload = {
  engagementActionId: string;
};

export type UseEngagementActionMarkComplete = {
  markCompleted: (engagementActionId: string) => void;
  isMarkingCompleted: boolean;
};

const useEngagementActionMarkCompleted = (options?: UseEngagementActionListMutationOptions): UseEngagementActionMarkComplete => {
  const { completedFrom } = useEngagementActionDataCollectionContext();

  const [markCompleted, isMarkingCompleted] = useEngagementActionListMutation(
    (engagementActionId: string) =>
      organizationEngagementActionMarkCompleted({
        engagementActionId,
        completedFrom,
      }),
    options,
  );

  return {
    markCompleted,
    isMarkingCompleted,
  };
};

export default useEngagementActionMarkCompleted;
