import { differenceInCalendarDays, format, formatDistanceToNow, subDays, subSeconds } from 'date-fns';
import Color from '~/neo-ui/packages/color/Color.gen';

export enum TimezoneFormat {
  Local,
  Utc,
}

export interface DateFormat {
  /**
   * Note: These are the various formats we have used. They correspond 1-to-1 to date-fns formats.
   * We likely want to standardize more here or make configurable in regional settings.
   */
  format:
    | 'MM-dd-yyyy'
    | 'H:MM'
    | 'yyyy-MM-dd'
    | 'yyyy/MM/dd'
    | 'dd/MM/yyyy'
    | 'MMM dd, yyyy'
    | 'hh:mm a'
    | 'MMM dd'
    | 'MMMM dd'
    | 'MMMM yyyy'
    | 'MMM yyyy'
    | 'MMMM, yyyy'
    | 'MMMM dd, yyyy'
    | 'MMM'
    | 'MMMM'
    | 'MMM do'
    | 'MMM do, yyyy'
    | 'MM/dd/yyyy';
  timezone: TimezoneFormat;
}

/**
 * Useful for configuring the formatter for test envs
 */
let alwaysUtc = false;
export const forceUtcAlways = () => {
  alwaysUtc = true;
};

export const resetUtcAlways = () => {
  alwaysUtc = false;
};

let currentDateResolver = () => new Date();
export const setCurrentDateResolver = (resolver: () => Date) => {
  currentDateResolver = resolver;
};

export const formatDate = (date: Date, options: DateFormat) =>
  format(applyTimezone(date, alwaysUtc ? TimezoneFormat.Utc : options.timezone), options.format);

const applyTimezone = (date: Date, timezone: TimezoneFormat): Date => {
  switch (timezone) {
    case TimezoneFormat.Local:
      return date;
    case TimezoneFormat.Utc:
      return undoTimeZoneConversion(date);
  }
};

// accounts for js automatic time zone adjustment
const undoTimeZoneConversion = (date: Date) => {
  const newDate = new Date(date);
  newDate.setTime(newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000);
  return newDate;
};

export const formatDateFriendly = (date: Date): string => {
  const numDaysAgo = differenceInCalendarDays(currentDateResolver(), date);
  if (numDaysAgo === 0) {
    return 'Today';
  } else if (numDaysAgo === 1) {
    return 'Yesterday';
  }
  return formatDate(date, {
    format: 'MMM dd, yyyy',
    timezone: TimezoneFormat.Local,
  });
};

export type DateFriendlyExtendedDefinition = {
  display: string;
  color: Color;
};

export const getDateFriendlyExtendedDefinition = (date: Date, shouldUseColor: boolean): DateFriendlyExtendedDefinition => {
  const numDaysAgo = differenceInCalendarDays(date, currentDateResolver());

  // Present
  if (numDaysAgo === 0) {
    return {
      display: 'Today',
      color: shouldUseColor ? 'dark-700' : 'primary-400',
    };
  }

  // Future
  if (numDaysAgo > 0) {
    if (numDaysAgo === 1) {
      return {
        display: 'Tomorrow',
        color: shouldUseColor ? 'dark-700' : 'primary-400',
      };
    }

    if (numDaysAgo >= 7) {
      return {
        display: `In ${Math.abs(numDaysAgo)} Days`,
        color: 'dark-700',
      };
    }

    if (date.getFullYear() === new Date().getFullYear()) {
      return {
        display: formatDate(date, {
          format: 'MMM do',
          timezone: TimezoneFormat.Local,
        }),
        color: 'dark-700',
      };
    }

    return {
      display: formatDate(date, {
        format: 'MMM do, yyyy',
        timezone: TimezoneFormat.Local,
      }),
      color: 'dark-700',
    };
  }

  // Past
  if (numDaysAgo > 0) {
    if (numDaysAgo === 1) {
      return {
        display: 'Yesterday',
        color: shouldUseColor ? 'dark-700' : 'negative-400',
      };
    }

    if (numDaysAgo <= 7) {
      return {
        display: `${Math.abs(numDaysAgo)} Days Ago`,
        color: shouldUseColor ? 'dark-700' : 'negative-400',
      };
    }

    if (date.getFullYear() === new Date().getFullYear()) {
      return {
        display: formatDate(date, {
          format: 'MMM do',
          timezone: TimezoneFormat.Local,
        }),
        color: shouldUseColor ? 'dark-700' : 'negative-400',
      };
    }

    return {
      display: formatDate(date, {
        format: 'MMM do, yyyy',
        timezone: TimezoneFormat.Local,
      }),
      color: shouldUseColor ? 'dark-700' : 'negative-400',
    };
  }

  return {
    display: formatDate(date, {
      format: 'MMM do, yyyy',
      timezone: TimezoneFormat.Local,
    }),
    color: 'dark-700',
  };
};

export const formatTimeFriendly = (date: Date): string =>
  formatDate(date, {
    format: 'hh:mm a',
    timezone: TimezoneFormat.Local,
  });

export const formatDateTimeFriendly = (date: Date): string => `${formatDateFriendly(date)} at ${formatTimeFriendly(date)}`;

/**
 * Prints `just now` is within ten seconds
 *
 * Prints time relative to Date.now if within one day
 *
 * Otherwise prints explicit date time
 */
export const formatRelativeOrDateTimeFriendly = (date: Date): string =>
  subDays(Date.now(), 1) > date
    ? formatDateTimeFriendly(date)
    : subSeconds(Date.now(), 10) > date
    ? `${formatDistanceToNow(date)} ago`
    : 'just now';

export const formatDateTimeToAge = (dateTime: Date): string => {
  const now = new Date();
  let diff = Math.floor((now.getTime() - dateTime.getTime()) / 1000);

  if (diff < 0) {
    diff = Math.abs(diff);
    if (diff < 59) {
      return `in ${diff} seconds`;
    } else if (diff < 120) {
      return 'in 1 minute';
    } else if (diff < 3600) {
      return `in ${Math.floor(diff / 60)} minutes`;
    } else if (diff < 7200) {
      return 'in 1 hour';
    } else if (diff < 86400) {
      return `in ${Math.floor(diff / 3600)} hours`;
    } else if (diff < 172800) {
      return 'in 1 day';
    } else if (diff < 31729726) {
      return `in ${Math.floor(diff / 86400)} days`;
    } else {
      if (Math.floor(diff / 31729726) === 1) {
        return 'in 1 year';
      } else {
        return `in ${Math.floor(diff / 31556926)} years`;
      }
    }
  } else {
    if (diff < 10) {
      return 'just now';
    } else if (diff < 59) {
      return `${diff} seconds ago`;
    } else if (diff < 120) {
      return '1 minute ago';
    } else if (diff < 3600) {
      return `${Math.floor(diff / 60)} minutes ago`;
    } else if (diff < 7200) {
      return '1 hour ago';
    } else if (diff < 86400) {
      return `${Math.floor(diff / 3600)} hours ago`;
    } else if (diff < 172800) {
      return '1 day ago';
    } else if (diff < 5259488) {
      return `${Math.floor(diff / 86400)} days ago`;
    } else if (diff < 63113852) {
      return `${Math.floor(diff / 2629744)} months ago`;
    } else {
      if (Math.floor(diff / 31729726) === 1) {
        return '1 year ago';
      } else {
        return `${Math.floor(diff / 31556926)} years ago`;
      }
    }
  }
};
