import * as React from 'react';
import DisposalOrderShipmentSection from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/DisposalOrderShipmentSection';
import DisposalOrderInfoProvider from '~/wm/packages/disposal/packages/order-info/context/DisposalOrderInfoProvider';
import { DisposalOrderSummaryDtoNested } from '@WarrantyClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Dto/Model.gen';
import DisposalOrderSummarySelfSection from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-summary-section/packages/disposal-order-summary-self-section/DisposalOrderSummarySelfSection';

export type DisposalSuccessSectionProps = {
  disposalOrderSummary: DisposalOrderSummaryDtoNested.ItadSelf;
  invoiceUrl?: string;
  disposalOrderListUrl: string;
};

const DisposalSelfSuccessSection = ({ disposalOrderSummary, invoiceUrl, disposalOrderListUrl }: DisposalSuccessSectionProps) => (
  <DisposalOrderInfoProvider
    disposalOrderId={disposalOrderSummary.disposalOrderId}
    defaultDisposalOrderSummary={disposalOrderSummary}
  >
    <DisposalOrderSummarySelfSection
      disposalOrderListUrl={disposalOrderListUrl}
      invoiceUrl={invoiceUrl}
      disposalAssetTypeAvailabilities={disposalOrderSummary.assetTypeAvailabilities}
    />
    <DisposalOrderShipmentSection />
  </DisposalOrderInfoProvider>
);

export default DisposalSelfSuccessSection;
