import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import ButtonModifier, { ButtonModifierProps } from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type InteractiveItemProps = {
  modifierButtonProps?: ButtonModifierProps;
  fixedMarginTopRem?: number;
  onClick?: () => void;
} & Styleable;

/**
 * An item that provides an interaction via a modifier button
 *
 * i.e. used to add or remove from a list of items
 */
const InteractiveItem: React.FunctionComponent<React.PropsWithChildren<InteractiveItemProps>> = ({
  className,
  modifierButtonProps,
  fixedMarginTopRem,
  onClick,
  children,
}) => (
  <div
    onClick={onClick}
    css={css`
      display: flex;
      padding: 0 0.625rem;
      border-radius: 0.625rem;
      background-color: ${colorToCode('light-100')};
      ${onClick && 'cursor: pointer;'}
      ${!fixedMarginTopRem ? 'align-items: center;' : 'align-items: unset'}
    `}
    className={className}
  >
    {modifierButtonProps && (
      <ButtonModifier
        {...modifierButtonProps}
        css={css`
          ${fixedMarginTopRem && `margin-top: ${fixedMarginTopRem}rem;`}
        `}
      />
    )}
    {children}
  </div>
);

export default InteractiveItem;
