import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { TicketLinkStateDto } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Packages/TicketLink/Dto/Model.gen';
import { Enum as AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Packages/TicketLink/Dto/Model/TicketLinkStateDtoNested.gen';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';

export type EngagementActionTableTicketDisplayProps = {
  ticketLinkState: TicketLinkStateDto;
};

const EngagementActionTableTicketDisplay = ({ ticketLinkState }: EngagementActionTableTicketDisplayProps) => {
  switch (ticketLinkState.type) {
    case AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Created:
      return (
        <ButtonLink
          size={'sm'}
          anchor={{ href: ticketLinkState.ticketLink.externalUrl, openInNewTab: true }}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.5rem;
            `}
          >
            <Icon
              icon={'IntegrationTicket'}
              color={'dark-700'}
            />
            <Label
              size={'md'}
              color={'dark-700'}
            >
              {ticketLinkState.ticketLink.ticketNumber}
            </Label>
          </div>
        </ButtonLink>
      );
    case AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Pending:
      return (
        <div
          css={css`
            display: flex;
            gap: 0.5rem;
            align-items: center;
            justify-content: center;
          `}
        >
          <Icon
            icon={'IntegrationTicket'}
            color={'dark-900-64'}
          />
          <Label
            size={'md'}
            color={'dark-900-64'}
            bold={true}
          >
            ...
          </Label>
        </div>
      );
    case AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Error:
      return (
        <div
          css={css`
            display: flex;
            gap: 0.5rem;
            align-items: center;
            justify-content: center;
          `}
        >
          <Icon
            icon={'IntegrationTicket'}
            color={'negative-500'}
          />
          <Label
            size={'md'}
            color={'negative-500'}
            bold={true}
          >
            !
          </Label>
        </div>
      );
    default:
      return null;
  }
};

export default EngagementActionTableTicketDisplay;
