import { css } from '@emotion/react';
import { SubscriptionBillingPeriodEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription.gen';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import getBillingPeriodDisplay from '~/wm/packages/subscription/packages/billing/operation/getBillingPeriodDisplay';
import {
  SubscriptionAgreementInfoTerm,
  SubscriptionAgreementInfoTermV2,
} from '~/wm/packages/subscription/packages/manage/wizard/model/SubscriptionAgreementInfo';
import TermInfo from '~/wm/packages/subscription/packages/term/packages/term-info/TermInfo';

export type TermSummaryProps = {
  term?: SubscriptionAgreementInfoTerm | SubscriptionAgreementInfoTermV2 | undefined;
  billingPeriod: SubscriptionBillingPeriodEnum;
  subscriptionAgreementLink: AnchorLocation;
} & Styleable;

const TermSummary = ({ term, billingPeriod, subscriptionAgreementLink, className }: TermSummaryProps) => (
  <div
    className={className}
    css={css`
      display: flex;
      flex-direction: column;
      text-align: left;
    `}
  >
    <div
      css={css`
        font-size: 1.125rem;
        font-weight: bold;
        margin-bottom: 1.25rem;
      `}
    >
      Subscription term
    </div>
    <div
      css={css`
        margin-bottom: 0.625rem;
      `}
    >
      {typeof term !== 'undefined' ? (
        <TermInfo
          term={term}
          billingPeriod={billingPeriod}
        />
      ) : (
        <p
          css={css`
            margin: 0;
            font-size: 0.875rem;
          `}
        >
          {`Billed ${getBillingPeriodDisplay(billingPeriod).adverb}`}
        </p>
      )}
    </div>
    <ButtonLink
      css={css`
        width: fit-content;
      `}
      size={'sm'}
      anchor={subscriptionAgreementLink}
      iconRight={'GoExternal'}
      leftAlign={true}
    >
      Read the ScalePad Subscription Agreement
    </ButtonLink>
  </div>
);

export default TermSummary;
