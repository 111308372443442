import { ConsoleRow } from '@AssetManagementClient/BeastClient/Search/Model/Console.gen';
import DataTable, { DataTableColumn, SortState } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { Column } from '~/neo-ui/packages/table/packages/console/types';
import * as React from 'react';
import ConsoleEmptyState from '~/neo-ui/packages/table/packages/console/empty-state/ConsoleEmptyState';
import { css as classCss } from '@emotion/css';
import { getMinSizeInRem } from '~/neo-ui/packages/table/packages/console/ConsoleUtilities';
import { pascalToCamel } from '~/extensions/packages/casing/camelPascalConversion';
import { RenderCellData } from '@AssetManagementClient/BeastClient/Renderer/Model/Data.gen';
import RenderCell from '~/neo-ui/packages/table/packages/render-cell-data/RenderCell';
import ConsoleRowCheckboxHeader from '~/neo-ui/packages/table/packages/console/checkboxes/ConsoleRowCheckboxHeader';
import ConsoleRowCheckbox from '~/neo-ui/packages/table/packages/console/checkboxes/ConsoleRowCheckbox';
import { useContext } from 'react';
import { ColumnAvailabilityDto } from '@AssetManagementClient/BeastClient/Search/Model/Console/Dto.gen';
import ConsoleContext from '~/neo-ui/packages/table/packages/console/contexts/ConsoleContext';

type DataTableWrapperProps = {
  data: {
    consoleRows?: ConsoleRow[];
    consoleIds?: string[];
  };
  metadataColumns: Column[] | undefined;
  onSortChange: (sortState: SortState<ConsoleRow> | undefined) => void;
  selectedColumns: Set<string>;
  hasCheckboxes: boolean;
  noResultsText: string | undefined;
};
export const DataTableWrapper: React.FunctionComponent<DataTableWrapperProps> = ({
  data,
  metadataColumns,
  selectedColumns,
  onSortChange,
  hasCheckboxes,
  noResultsText,
}) => {
  const {
    selectedConsoleItems: {
      selectedItems: selectedAssetIds,
      lastItem: lastAssetId,
      selectItem: selectAssetId,
      deselectItem: deselectAssetId,
      selectItems: selectAssetIds,
      deselectItems: deselectAssetIds,
      clearSelectedItems: clearSelectedAssetIds,
      isItemSelected: isAssetIdSelected,
    },
  } = useContext(ConsoleContext);

  const displayedColumns: Column[] = React.useMemo(
    () =>
      metadataColumns
        ?.filter(
          column =>
            column.availability === ColumnAvailabilityDto.Mandatory ||
            (typeof selectedColumns !== 'undefined' && selectedColumns.has(column.key)),
        )
        .sort((a, b) => a.order - b.order) ?? [],
    [metadataColumns, selectedColumns],
  );

  const columnsWithoutCheckbox: DataTableColumn<ConsoleRow>[] = React.useMemo(
    () =>
      displayedColumns.map(
        (column): DataTableColumn<ConsoleRow> => ({
          fieldKey: column.key,
          Header: column.label,
          width: `${getMinSizeInRem(column.size)}rem`,
          renderCell: row => {
            const data = row.data.data[pascalToCamel(column.key)] as RenderCellData | undefined;
            if (typeof data === 'undefined') {
              return null;
            }
            return RenderCell({ data });
          },
          ellipsizeTextContent: true,
        }),
      ),
    [displayedColumns],
  );

  const columnsWithCheckbox: DataTableColumn<ConsoleRow>[] = React.useMemo(
    () =>
      hasCheckboxes
        ? [
            {
              fieldKey: 'checkboxes',
              Header: (
                <ConsoleRowCheckboxHeader
                  allCurrentIds={data.consoleIds ?? new Array<string>()}
                  selectedAssetIds={selectedAssetIds}
                  onSelect={selectAssetIds}
                  onDeselect={clearSelectedAssetIds}
                />
              ),
              renderCell: (row: ConsoleRow) => (
                <ConsoleRowCheckbox
                  key={`row-${row.id}`}
                  row={row}
                  allDisplayedRows={data.consoleRows ? data.consoleRows : ([] as ConsoleRow[])}
                  lastSelectedId={lastAssetId}
                  onDeselectMany={deselectAssetIds}
                  onSelectMany={selectAssetIds}
                  onSelect={selectAssetId}
                  onDeselect={deselectAssetId}
                  isSelected={isAssetIdSelected(row.id)}
                />
              ),
              width: '2.5rem',
            },
            ...columnsWithoutCheckbox,
          ]
        : columnsWithoutCheckbox,
    [
      hasCheckboxes,
      data.consoleIds,
      data.consoleRows,
      columnsWithoutCheckbox,
      selectedAssetIds,
      selectAssetIds,
      clearSelectedAssetIds,
      lastAssetId,
      deselectAssetIds,
      selectAssetId,
      deselectAssetId,
      isAssetIdSelected,
    ],
  );

  return (
    <DataTable
      data={data.consoleRows ?? 'loading'}
      columns={columnsWithCheckbox}
      EmptyStatePlaceholder={() => <ConsoleEmptyState text={noResultsText} />}
      sortableColumnKeys={metadataColumns?.filter(column => column.isSortable).map(column => column.key)}
      onSortChange={onSortChange}
      tableClass={classCss`
              min-width: ${displayedColumns.reduce((sum, current) => sum + getMinSizeInRem(current.size), 0)}rem;
              table-layout: fixed;
            `}
    />
  );
};
