type KeyedNestedArrays<T> = {
  [key in string]: { [key in string]: T[] };
};
type MapNestedArrays<T> = Map<string, Map<string, T[]>>;

/**
 * Converts a KeyedNestedArrays object to a MapNestedArrays object.
 *
 * Keys are PascalCased.
 */
export const convertToMapNestedArrays = <T>(input: KeyedNestedArrays<T>): MapNestedArrays<T> =>
  new Map(
    Object.entries(input).map(([outerKey, innerObject]) => [
      outerKey.charAt(0).toUpperCase() + outerKey.slice(1),
      new Map(Object.entries(innerObject).map(([innerKey, values]) => [innerKey.charAt(0).toUpperCase() + innerKey.slice(1), values])),
    ]),
  );

/**
 * Converts a MapNestedArrays object to a KeyedNestedArrays object.
 */
export const convertToKeyedNestedArrays = <T>(map: MapNestedArrays<T>): KeyedNestedArrays<T> =>
  Object.fromEntries(
    Array.from(map).map(([outerKey, innerMap]) => [
      outerKey,
      Object.fromEntries(Array.from(innerMap).map(([innerKey, values]) => [innerKey, values])),
    ]),
  );
