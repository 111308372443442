import { ScorecardItemDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardItem/Dto/Model.gen';
import { BudgetCurrencyDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Dto/Model.gen';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import ScorecardItemFormData from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/form/ScorecardItemFormData';
import * as React from 'react';
import { getCurrencyCharacterByCurrencyCode } from '~/extensions/packages/currency/formatCurrency';
import { FieldKeyExpression, FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import FormTitleInput from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/FormTitleInput';
import FormTextareaInput from '~/neo-ui/packages/form/packages/form-input/packages/form-textarea-input/FormTextareaInput';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';

export type ScorecardItemRecommendationSectionProps = {
  scorecardItem: ScorecardItemDto;
  currency: BudgetCurrencyDto;
};

const ScorecardItemRecommendationSection = ({ scorecardItem, currency }: ScorecardItemRecommendationSectionProps) => {
  const { setFormInput } = useFormContext<ScorecardItemFormData>();
  const [isInvestmentUnknown, setIsInvestmentUnknown] = React.useState(scorecardItem.investment.isInvestmentUnknown);

  const currencyIcon = getCurrencyCharacterByCurrencyCode(currency.codeAlpha);

  // IsInvestmentUnknown
  const isInvestmentUnknownFieldKey: FieldKeyExpression<ScorecardItemFormData> = values => values.isInvestmentUnknown;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
      `}
    >
      <Header
        size={7}
        color={'dark-050'}
      >
        Recommendation
      </Header>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 0.625rem;
        `}
      >
        <InputCheckbox
          checked={!isInvestmentUnknown}
          description={'Investment'}
          checkboxColor={'secondary-400'}
          onClick={() => {
            setFormInput<boolean>(isInvestmentUnknownFieldKey, !isInvestmentUnknown);
            setIsInvestmentUnknown(prev => !prev);
          }}
        />
        {!isInvestmentUnknown && (
          <FormTitleInput
            fieldKey={(values: FieldKeyExpressionSegment<ScorecardItemFormData>) => values.investmentCostUnits}
            prependCharacter={currencyIcon}
            placeholder={'Estimated investment…'}
            // Allows pasting complete currency-type numbers into the input
            // e.g., if I write $12,123.20 => 12123.20
            mapValue={costString => costString.replace(/[^\d.]/g, '')}
            css={css`
              width: 100%;
            `}
          />
        )}
      </div>{' '}
      <FormTextareaInput
        fieldKey={(values: FieldKeyExpressionSegment<ScorecardItemFormData>) => values.investmentDescription}
        placeholder={"Quickly describe why you recommend this investment as remediation for the category's score."}
      />
    </div>
  );
};

export default ScorecardItemRecommendationSection;
