import * as React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import useContractUpdate from '~/wm/packages/strategy/packages/contract/packages/contract-list/hooks/useContractUpdate';
import { ContractDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Dto/Model.gen';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';
import useContractListContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/context/hooks/useContractListContext';
import ContractEditFormValidationSchema from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-list-table/packages/contract-edit-vendor-table-cell/packages/contract-edit-window-form-wrapper/packages/contract-edit-form/validation/contractEditFormValidationSchema';
import { ContractAssetInfo } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-link-assets-button/modal/ContractAssetInfo';
import buildContractAssetInfoFromContractAssetDto from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-link-assets-button/builder/buildContractAssetInfoFromContractAssetDto';

export type ContractEditBasicFormData = {
  vendor: string;
  account: string | undefined;
  status: string;
  impact: string;
  description: string | undefined;
  location: string | undefined;
  isThirdParty: boolean;
};

export type ContractEditBillingCostCalculationFormData = {
  costSubunits: number;
  perSeatCostSubunits: number | undefined;
  numberOfSeats: number | undefined;
};

export type ContractEditBillingFormData = {
  cycle: string;
  costType: string;
  costVariables: ContractEditBillingCostCalculationFormData;
  nextDue: string;
  endDate: string | undefined;
  isAutoRenew: boolean;
  shouldBudgetPastEndDate: boolean;
};

export type ContractEditAlertFormData = {
  noticeDays: number;
};

export type ContractEditAssetFormData = {
  assets: ContractAssetInfo[];
};

export type ContractEditFormData = {
  basic: ContractEditBasicFormData;
  billing: ContractEditBillingFormData;
  alert: ContractEditAlertFormData;
  asset: ContractEditAssetFormData;
};

type ContractEditFormProps = {
  contract: ContractDto;
  onContractUpdate: () => void;
};

const ContractEditForm: React.FunctionComponent<React.PropsWithChildren<ContractEditFormProps>> = ({
  contract,
  onContractUpdate,
  children,
}) => {
  const { updateContract } = useContractUpdate({
    onSuccess: () => {
      onContractUpdate();
    },
  });

  const { contractBillingCurrency } = useContractListContext();

  const toFormData = React.useCallback(
    (): ContractEditFormData => ({
      basic: {
        vendor: contract.basicSummary.title,
        account: contract.basicSummary.category,
        status: contract.basicSummary.status.uniqueId,
        impact: contract.basicSummary.impact,
        description: contract.basicSummary.description,
        location: contract.basicSummary.location,
        isThirdParty: contract.basicSummary.isThirdParty ?? false,
      },
      billing: {
        cycle: contract.billingSummary.cycle.uniqueId,
        costVariables: {
          numberOfSeats: contract.billingSummary.numberOfSeats,
          costSubunits: contract.billingSummary.costSubunits / contractBillingCurrency.subunitRatio,
          perSeatCostSubunits:
            typeof contract.billingSummary.perSeatCostSubunits === 'undefined'
              ? undefined
              : contract.billingSummary.perSeatCostSubunits / contractBillingCurrency.subunitRatio,
        },
        costType: contract.billingSummary.costType,
        nextDue: formatDate(parseISO(`${contract.billingSummary.nextDueAt}`), {
          format: 'yyyy-MM-dd',
          timezone: TimezoneFormat.Utc,
        }),
        endDate:
          typeof contract.billingSummary.endAt !== 'undefined'
            ? formatDate(parseISO(`${contract.billingSummary.endAt}`), {
                format: 'yyyy-MM-dd',
                timezone: TimezoneFormat.Utc,
              })
            : undefined,
        isAutoRenew: contract.billingSummary.isAutoRenew ?? false,
        shouldBudgetPastEndDate: contract.billingSummary.shouldBudgetPastEndDate,
      },
      alert: {
        noticeDays: contract.alertSummary.notifyDaysBeforeEndDate,
      },
      asset: {
        assets: buildContractAssetInfoFromContractAssetDto(contract.assetsSummary),
      },
    }),
    [contract, contractBillingCurrency.subunitRatio],
  );

  const defaultFormData = React.useMemo(() => toFormData(), [toFormData]);

  const onSubmit = React.useCallback(
    async (data: ContractEditFormData, isRequestActive: () => boolean) => {
      updateContract(
        {
          contractId: contract.basicSummary.contractId,
          organizationId: contract.basicSummary.organizationInfo.organizationId,
          contractUpdatePayload: {
            basicUpsertPayload: {
              title: data.basic.vendor,
              category: data.basic.account,
              impact: data.basic.impact,
              status: data.basic.status,
              location: data.basic.location,
              description: data.basic.description,
              isThirdParty: data.basic.isThirdParty,
            },
            billingUpsertPayload: {
              cycle: data.billing.cycle,
              costSubunits: data.billing.costVariables.costSubunits * contractBillingCurrency.subunitRatio,
              perSeatCostSubunits:
                typeof data.billing.costVariables.perSeatCostSubunits === 'undefined'
                  ? undefined
                  : data.billing.costVariables.perSeatCostSubunits * contractBillingCurrency.subunitRatio,
              costType: data.billing.costType,
              numberOfSeats: data.billing.costVariables.numberOfSeats,
              startAt: new Date(`${data.billing.nextDue}`).toUTCString(),
              isAutoRenew: data.billing.isAutoRenew,
              endAt:
                typeof data.billing.endDate !== 'undefined' && data.billing.endDate !== ''
                  ? new Date(`${data.billing.endDate}`).toUTCString()
                  : undefined,
              shouldBudgetPastEndDate: data.billing.shouldBudgetPastEndDate,
            },
            alertUpsertPayload: {
              notifyDaysBeforeEndDate: data.alert.noticeDays,
            },
            assetUpsertPayload: {
              assetIds: data.asset.assets.map(asset => asset.assetId),
            },
          },
        },
        isRequestActive,
      );
    },
    [
      contract.basicSummary.contractId,
      contract.basicSummary.organizationInfo.organizationId,
      contractBillingCurrency.subunitRatio,
      updateContract,
    ],
  );
  return (
    <Form
      submitMethod={'manual'}
      hideSubmissionButton={true}
      defaultFormData={defaultFormData}
      validationSchema={ContractEditFormValidationSchema}
      onSubmit={onSubmit}
      hideSubmitStatusIndicator={true}
    >
      {children}
    </Form>
  );
};

export default ContractEditForm;
