import { CurrencyInformationDto } from '@WarrantyClient/BeastClient/Beast/Settings/Dto/Packages/Warranty.gen';
import { hideCalcCostConstant } from '~/wm/packages/warranty/packages/warranty-cart/constants/costCalculationConstants';
import { formatCurrencyByCurrencyInfo } from '~/extensions/packages/currency/formatCurrency';

export const formatCostOutputUsingCurrency = (amountInCents: number, currencySettings: CurrencyInformationDto | undefined): string => {
  if (typeof currencySettings === 'undefined') {
    throw new Error('Unable to get currency information');
  }

  if (amountInCents === hideCalcCostConstant) {
    return '-';
  }
  return formatCurrencyByCurrencyInfo(amountInCents * currencySettings.exchangeRate, {
    code: currencySettings.displayCurrency,
    subunitsPerUnit: currencySettings.subunitRatio,
  });
};
