import { object, string, lazy } from 'yup';
import { mapRules } from '~/extensions/packages/validation/mapRules';
import { HardwareReplacementReportAssetTypeSettingsAvailabilitiesDto } from '@AssetManagementClient/BeastClient/Beast/HardwareReplacement/Dto/Model.gen';

const reportAssetTypePreferenceValidationSchema = object({
  replacementValue: string()
    .matches(/^\+?(0|[1-9]\d*)$/, 'Please enter a valid number')
    .min(0, 'Replacement value may not be less than zero')
    .test(
      'max value',
      'Please enter a valid replacement value',
      value => value !== null && typeof value !== 'undefined' && parseInt(value, 10) <= 2147483647,
    )
    .required('Please enter a replacement value'),
});

const hardwareReplacementReportAssetTypeSettingsValidationSchema = (
  availabilities: HardwareReplacementReportAssetTypeSettingsAvailabilitiesDto,
) =>
  object().shape({
    value: object().shape({
      reportAssetTypePreferences: lazy(() =>
        object(mapRules(availabilities.reportAssetTypeAvailabilities, reportAssetTypePreferenceValidationSchema)),
      ),
    }),
  });

export default hardwareReplacementReportAssetTypeSettingsValidationSchema;
