import React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Anchor from '~/neo-ui/packages/anchor/Anchor';
import routes from '~/router/types/routes';
import DownloadKitButton from '~/wm/packages/warranty/packages/warranty-list/packages/download-kit-button/DownloadKitButton';
import { Enum as WarrantyTypeEnum } from '@WarrantyClient/BeastClient/Goods/WarrantyType/WarrantyTypeFactoryNested.gen';

export type WarrantyListHeaderProps = {
  devicesCountTotal: number;
  clientRowsCount: number;
  warrantyType: WarrantyTypeEnum | undefined;
} & Styleable;

const WarrantyListHeader: React.FunctionComponent<WarrantyListHeaderProps> = ({ devicesCountTotal, clientRowsCount, warrantyType }) => {
  const clientText: string = clientRowsCount === 1 ? 'client' : 'clients';

  const defaultHeader = (
    <>
      <Anchor
        href={routes.ASSET_HARDWARE_INDEX.build()}
        size={'xlg'}
      >
        {devicesCountTotal} warranties
      </Anchor>
      <span>
        {' '}
        available in {clientRowsCount} {clientText}
      </span>
    </>
  );

  const infrastructureHeader = (
    <span>
      Infrastructure Protection available in {clientRowsCount} {clientText}
    </span>
  );

  const warrantyHeader = (
    <span>
      Workstation Assurance available in {clientRowsCount} {clientText}
    </span>
  );

  const content = () => {
    switch (warrantyType) {
      case WarrantyTypeEnum.InfrastructureProtection:
        return infrastructureHeader;
      case WarrantyTypeEnum.WorkstationAssurance:
        return warrantyHeader;
      default:
        return defaultHeader;
    }
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <Header size={4}>{content()}</Header>
      <DownloadKitButton />
    </div>
  );
};

export default WarrantyListHeader;
