import * as React from 'react';
import FormTextboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-textbox-input/FormTextboxInput';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';

export type FormTextInputProps<T> = {
  fieldKey: FieldKeyExpression<T>;
  placeholder?: string;
  /**
   * @see FormTextboxInputProps.optimizePerformance
   */
  optimizePerformance?: boolean;
  prependCharacter?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  isInvalid?: boolean;
  showFormError?: boolean;
  fixedErrorPosition?: boolean;
  maxLength?: number;
} & Styleable &
  Themeable;

const FormTextInput = <T,>({
  fieldKey,
  placeholder,
  optimizePerformance = false,
  prependCharacter,
  disabled = false,
  isInvalid = false,
  showFormError = true,
  fixedErrorPosition = false,
  onChange,
  maxLength,
  theme,
  className,
}: FormTextInputProps<T>) => (
  <FormTextboxInput
    fieldKey={fieldKey}
    type={'text'}
    placeholder={placeholder}
    optimizePerformance={optimizePerformance}
    prependCharacter={prependCharacter}
    disabled={disabled}
    onChange={onChange}
    isInvalid={isInvalid}
    theme={theme}
    showFormError={showFormError}
    fixedErrorPosition={fixedErrorPosition}
    maxLength={maxLength}
    className={className}
  />
);

export default FormTextInput;
