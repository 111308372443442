import { tabletBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import Button from '~/sp-ui/buttons/Button';

export type SubscriptionSummaryCardCtaSection = {
  isFreeSubscription: boolean;
  manageSubscriptionUrl: string;
};

const SubscriptionSummaryCardCtaSection = ({ isFreeSubscription, manageSubscriptionUrl }: SubscriptionSummaryCardCtaSection) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        [`@media (max-width: ${tabletBreakpointRem}rem)`]: { alignItems: 'start' },
      }}
    >
      <Button
        size="small"
        variant="primary"
        css={{ padding: '4px 8px', minWidth: '7rem', maxWidth: '8.25rem' }}
        anchor={{ href: manageSubscriptionUrl }}
      >
        {isFreeSubscription ? 'Upgrade Now' : 'Manage your plan'}
      </Button>
    </div>
  );
};

export default SubscriptionSummaryCardCtaSection;
