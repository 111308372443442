import * as React from 'react';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import ScorecardItemAutomationRefreshModal from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/packages/scorecard-item-automation-refresh-modal/ScorecardItemAutomationRefreshModal';
import { ScorecardItemAutomationDataDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardItem/Dto/Model.gen';

export type ScorecardItemAutomationRefreshButtonProps = {
  scorecardId: string;
  scorecardItemId: string;
  organizationId: string;
  scorecardItemAutomationData: ScorecardItemAutomationDataDto;
};

const ScorecardItemAutomationRefreshButton = ({
  scorecardId,
  scorecardItemId,
  organizationId,
  scorecardItemAutomationData,
}: ScorecardItemAutomationRefreshButtonProps) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <div>
      <ButtonLink
        iconLeft={'Reload'}
        theme={'secondary'}
        onClick={() => setModalOpen(true)}
      >
        Update
      </ButtonLink>
      <ScorecardItemAutomationRefreshModal
        scorecardId={scorecardId}
        scorecardItemId={scorecardItemId}
        organizationId={organizationId}
        scorecardItemAutomationData={scorecardItemAutomationData}
        isOpen={isModalOpen}
        onDismiss={() => setModalOpen(false)}
        onConfirm={() => setModalOpen(false)}
      />
    </div>
  );
};

export default ScorecardItemAutomationRefreshButton;
