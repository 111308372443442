import { HardwareReplacementSettingsAvailabilities } from '@AssetManagementClient/BeastClient/Beast/HardwareReplacement/Model/Availabilities.gen';
import { ScopeKey } from '@AssetManagementClient/BeastClient/Beast/Settings/Scope.gen';

import * as React from 'react';
import HardwareReplacementSettingsForm from '~/wm/packages/hardware/packages/settings/configurable/HardwareReplacementSettingsForm';
import hardwareSettingsValidationSchema from '~/wm/packages/hardware/packages/settings/validation/hardwareSettingsValidationSchema';
import SettingsConfigurable from '~/wm/packages/settings/packages/configurable/SettingsConfigurable';

export type HardwareReplacementConfigurableProps = {
  availabilities: HardwareReplacementSettingsAvailabilities;
  scopeKey: ScopeKey;
  currencyCharacter: string;
  hardwareConfigCriteriaReleased: boolean;
};

/**
 * @deprecated by HardwareReplacementConfigurableV2
 * Should be removed when lm-budget-forecast is released
 */
const HardwareReplacementConfigurable: React.FunctionComponent<HardwareReplacementConfigurableProps> = ({
  availabilities,
  scopeKey,
  currencyCharacter,
  hardwareConfigCriteriaReleased,
}) => (
  <SettingsConfigurable
    settingsKey={{
      scopeKey,
      /** Referenced in beast: {949afc53-55e8-4926-8490-38477be4aeaa} */
      configurableId: 'hardware-replacement',
    }}
    validationSchema={hardwareSettingsValidationSchema(availabilities)}
    label="Replacement"
    /* This text is duplicated in the old PHP form, see {7f6820ad-0760-4b1c-a99f-9c749432171e} */
    description="Customize each hardware asset type's value and recommendation criteria."
  >
    {({ isUsingDefault }) => (
      <HardwareReplacementSettingsForm
        availabilities={availabilities}
        currencyCharacter={currencyCharacter}
        disabled={isUsingDefault}
        hardwareConfigCriteriaReleased={hardwareConfigCriteriaReleased}
      />
    )}
  </SettingsConfigurable>
);

export default HardwareReplacementConfigurable;
