import { TicketLinkStateDto } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Packages/TicketLink/Dto/Model.gen';
import { Enum as AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Packages/TicketLink/Dto/Model/TicketLinkStateDtoNested.gen';
import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import Button from '~/neo-ui/packages/button/Button';
import TicketUnlinkModal from '~/wm/packages/integration/packages/ticket/packages/ticket-unlink-modal/TicketUnlinkModal';
import { useState } from 'react';
import InitiativeTicketDisplay from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-ticket-display/InitiativeTicketDisplay';

export type InitiativeSaveTicketSectionStatesProps = {
  ticketLinkState: TicketLinkStateDto;
  onUnlinkTicket: () => void;
};

const InitiativeSaveTicketSectionStates = ({ ticketLinkState, onUnlinkTicket }: InitiativeSaveTicketSectionStatesProps) => {
  const [isUnlinkModalOpen, setIsUnlinkModalOpen] = useState(false);

  switch (ticketLinkState.type) {
    case AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Created:
      return (
        <Box
          theme={'none'}
          background={'foregroundAccent'}
          padding={'padding100'}
          css={css`
            display: flex;
          `}
        >
          <InitiativeTicketDisplay ticketLinkState={ticketLinkState} />
          <div
            css={css`
              margin-left: auto;
              display: flex;
              gap: 1rem;
            `}
          >
            <div>
              <ButtonLink
                theme={'negative'}
                onClick={() => {
                  setIsUnlinkModalOpen(true);
                }}
              >
                Unlink Ticket
              </ButtonLink>
              <TicketUnlinkModal
                isOpen={isUnlinkModalOpen}
                isUnlinkingTicket={false}
                onUnlinkTicket={() => {
                  onUnlinkTicket();
                  setIsUnlinkModalOpen(false);
                }}
                onDismiss={() => {
                  setIsUnlinkModalOpen(false);
                }}
              />
            </div>
            <Button
              iconRight={'GoRight'}
              anchor={{ href: ticketLinkState.ticketLink.externalUrl, openInNewTab: true }}
            >
              View Ticket
            </Button>
          </div>
        </Box>
      );
    case AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Pending:
      return (
        <Box
          theme={'none'}
          background={'foregroundAccent'}
          padding={'padding100'}
        >
          <div
            css={css`
              display: flex;
              gap: 1rem;
              flex-direction: column;
            `}
          >
            <InitiativeTicketDisplay ticketLinkState={ticketLinkState} />
            <Label
              size={'md'}
              color={'dark-900-64'}
            >
              PSA ticket is being created, check back later.
            </Label>
          </div>
        </Box>
      );
    case AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Error:
      return (
        <Box
          theme={'none'}
          background={'foregroundAccent'}
          padding={'padding100'}
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 1rem;
              flex-direction: column;
            `}
          >
            <InitiativeTicketDisplay ticketLinkState={ticketLinkState} />
            <Label
              size={'md'}
              color={'negative-500'}
            >
              PSA ticket creation failed. Please delete this ticket and try again.
            </Label>
          </div>
          <Button
            css={css`
              margin-left: auto;
            `}
            theme={'negative'}
            onClick={() => {
              onUnlinkTicket();
            }}
          >
            Delete Ticket
          </Button>
        </Box>
      );
    default:
      return null;
  }
};

export default InitiativeSaveTicketSectionStates;
