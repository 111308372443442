import React from 'react';
import WarrantyCartLoader from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-loader/WarrantyCartLoader';
import { css } from '@emotion/react';

export type WarrantyCartOverlayLoaderProps = {
  showLoader: boolean;
};

const WarrantyCartOverlayLoader: React.FunctionComponent<WarrantyCartOverlayLoaderProps> = ({ showLoader }) => {
  return showLoader ? (
    <div
      css={css`
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #f8f8fc6b;
        z-index: 2;
      `}
    >
      <WarrantyCartLoader />
    </div>
  ) : null;
};

export default WarrantyCartOverlayLoader;
