import { createContext } from 'react';

import { ContractTemplateAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Template/Dto/Model.gen';

export type ContractTemplateAvailabilitiesContextData = {
  contractTemplateAvailabilities: ContractTemplateAvailabilityDto[];
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ContractTemplateAvailabilitiesContext = createContext<ContractTemplateAvailabilitiesContextData | undefined>(undefined);

export default ContractTemplateAvailabilitiesContext;
