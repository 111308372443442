import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import * as React from 'react';
import { ConsoleState } from '~/neo-ui/packages/table/packages/console/types';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import PopoverWithPopup from '~/neo-ui/packages/popover/PopoverWithPopup';
import Divider from '~/neo-ui/packages/divider/Divider';
import DownloadMenuSection from '~/neo-ui/packages/table/packages/console/download/DownloadMenuSection';
import SelectOptionGroup from '~/neo-ui/packages/select/model/SelectOptionGroup';
import { Enum as SpreadsheetTypeFactoryEnum } from '@AssetManagementClient/Document/Spreadsheet/Model/SpreadsheetTypeFactoryNested.gen';

export type DownloadConsoleSpreadsheetProps = {
  canDownload?: boolean;
  consoleState: ConsoleState;
  selectedColumns: Set<string> | undefined;
  filterFrame: object;
  onDownload: (
    consoleState: ConsoleState,
    selectedColumns: Set<string> | undefined,
    context: object,
    spreadsheetType: SpreadsheetTypeFactoryEnum,
  ) => void;
  legacyDownloadUrl: string;
  shouldShowLegacyDownload: boolean;

  tooManyAssetsForNonLegacyXlsx: boolean;
};

export type DownloadAction = 'download-view-csv' | 'download-view-xlsx' | 'download-all-csv' | 'download-all-xlsx' | 'download-legacy';

const DownloadConsoleSpreadsheet = ({
  canDownload,
  consoleState,
  selectedColumns,
  filterFrame,
  onDownload,
  legacyDownloadUrl,
  tooManyAssetsForNonLegacyXlsx,
  shouldShowLegacyDownload,
}: DownloadConsoleSpreadsheetProps) => {
  const iconSize = 22;

  const excelColor = 'positive-700';

  const downloadViewOptions = [
    {
      options: [
        {
          value: 'download-view-csv' as DownloadAction,
          label: 'CSV file',
          labelColor: 'secondary-400',
          prependIcon: 'CSV' as IconType,
          iconSizePx: iconSize,
          disabled: false,
        },
      ],
    },
    {
      options: [
        {
          value: 'download-view-xlsx',
          label: 'Excel spreadsheet',
          labelColor: excelColor,
          prependIcon: 'XLSX' as IconType,
          iconSizePx: iconSize,
          disabled: tooManyAssetsForNonLegacyXlsx,
        },
      ],
    },
  ] as SelectOptionGroup<DownloadAction>[];

  const downloadAllOptions = [
    {
      options: [
        {
          value: 'download-all-csv',
          label: 'CSV file',
          labelColor: 'secondary-400',
          prependIcon: 'CSV' as IconType,
          iconSizePx: iconSize,
          disabled: false,
        },
      ],
    },
    {
      options: [
        {
          value: 'download-all-xlsx',
          label: 'Excel spreadsheet',
          labelColor: excelColor,
          prependIcon: 'XLSX' as IconType,
          iconSizePx: iconSize,
          disabled: tooManyAssetsForNonLegacyXlsx,
        },
      ],
    },
  ] as SelectOptionGroup<DownloadAction>[];

  const downloadLegacyOption = [
    {
      options: [
        {
          value: 'download-legacy',
          label: 'Excel spreadsheet',
          labelColor: excelColor,
          prependIcon: 'XLSX' as IconType,
          iconSizePx: 22,
          disabled: false, // we are not capping the legacy download at 5000 assets
        },
      ],
    },
  ] as SelectOptionGroup<DownloadAction>[];

  const optionSelectedCallback = (option: SelectOption<DownloadAction>, callback: () => void) => {
    switch (option.value) {
      case 'download-view-csv':
        onDownload(consoleState, selectedColumns, filterFrame, SpreadsheetTypeFactoryEnum.Csv);
        break;
      case 'download-view-xlsx':
        onDownload(consoleState, selectedColumns, filterFrame, SpreadsheetTypeFactoryEnum.Xlsx);
        break;
      case 'download-all-csv':
        onDownload(consoleState, undefined, filterFrame, SpreadsheetTypeFactoryEnum.Csv);
        break;
      case 'download-all-xlsx':
        onDownload(consoleState, undefined, filterFrame, SpreadsheetTypeFactoryEnum.Xlsx);
        break;
      case 'download-legacy':
        window.location.href = legacyDownloadUrl;
        break;
    }
    callback();
  };

  const spreadsheetDownloadHeader = (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 5px;
      `}
    >
      <span>Spreadsheet...</span>
      <Icon
        icon={'Download'}
        color={'dark-900'}
      />
    </div>
  );

  const excelWarningText = (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding-top: 0.3125rem;
        padding-bottom: 0.625rem;
      `}
    >
      <Icon
        css={css`
          margin-right: 0.625rem;
        `}
        icon={'XLSX'}
        color={excelColor}
        sizePx={iconSize}
      />
      <Label
        size={'md'}
        color={excelColor}
        heightSize={'sm'}
      >
        Excel spreadsheets are available for up to 5,000 results
      </Label>
    </div>
  );
  return (
    <PopoverWithPopup
      header={spreadsheetDownloadHeader}
      disabled={!canDownload}
      disabledTooltipContent={'Upgrade to download'}
      popoverAlign={'left-end'}
      css={css`
        width: clamp(12.5rem, 29.375rem, 90vw);
      `}
    >
      {(closeModal: () => void) => (
        <div
          css={css`
            padding: 0.625rem 0.75rem;
          `}
        >
          <Label
            className={'download-header'}
            size={'xlg'}
            color={'secondary-400'}
            bold={true}
            css={css`
              padding-bottom: 0.3125rem;
              display: flex;
              align-items: flex-start;
            `}
          >
            Download
          </Label>
          {tooManyAssetsForNonLegacyXlsx && excelWarningText}
          <DownloadMenuSection
            heading={'These results with visible columns'}
            description={"Get a spreadsheet with the columns you've selected."}
            assetDownloadOptions={downloadViewOptions}
            onOptionSelected={option => optionSelectedCallback(option, closeModal)}
          />
          <Divider
            css={css`
              margin: 1rem 0;
            `}
          />
          <DownloadMenuSection
            heading={'These results with all available columns'}
            description={'Get a spreadsheet with every possible column included.'}
            assetDownloadOptions={downloadAllOptions}
            onOptionSelected={option => optionSelectedCallback(option, closeModal)}
          />
          {shouldShowLegacyDownload && (
            <Divider
              css={css`
                margin: 1rem 0;
              `}
            />
          )}
          {shouldShowLegacyDownload && (
            <DownloadMenuSection
              heading={'Download the previous Hardware export'}
              description={"This legacy export doesn't use your customized filters or columns."}
              assetDownloadOptions={downloadLegacyOption}
              onOptionSelected={option => optionSelectedCallback(option, closeModal)}
            />
          )}
        </div>
      )}
    </PopoverWithPopup>
  );
};
export default DownloadConsoleSpreadsheet;
