import { autoEmailReportScheduleUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useAutoEmailReportListMutation, {
  UseAutoEmailReportListMutationOptions,
} from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportListMutation';
import { AutoEmailReportSchedulePayloadDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';

type AutoEmailReportScheduleUpdatePayload = {
  organizationId: string;
  autoEmailReportId: string;
  autoEmailReportScheduleUpdatePayload: AutoEmailReportSchedulePayloadDto;
};

export type UseAutoEmailReportScheduleUpdate = {
  updateAutoEmailReportSchedule: (
    autoEmailReportScheduleUpdatePayload: AutoEmailReportScheduleUpdatePayload,
    isRequestActive?: () => boolean,
  ) => void;
  isUpdatingAutoEmailReportSchedule: boolean;
};

const useAutoEmailReportScheduleUpdate = (options?: UseAutoEmailReportListMutationOptions): UseAutoEmailReportScheduleUpdate => {
  const [updateAutoEmailReportSchedule, isUpdatingAutoEmailReportSchedule] = useAutoEmailReportListMutation(
    (autoEmailReportScheduleUpdatePayload: AutoEmailReportScheduleUpdatePayload) =>
      autoEmailReportScheduleUpdate({
        autoEmailReportId: autoEmailReportScheduleUpdatePayload.autoEmailReportId,
        organizationId: autoEmailReportScheduleUpdatePayload.organizationId,
        updatePayload: autoEmailReportScheduleUpdatePayload.autoEmailReportScheduleUpdatePayload,
      }),
    options,
  );

  return {
    updateAutoEmailReportSchedule,
    isUpdatingAutoEmailReportSchedule,
  };
};

export default useAutoEmailReportScheduleUpdate;
