import { SettingsValueNested } from '@AssetManagementClient/BeastClient/Beast/Settings/Model.gen';
import { css } from '@emotion/react';
import { WarrantyCoverageSettingsAvailabilities } from '@WarrantyClient/BeastClient/Beast/WarrantyCoverageSettings/Model/Availabilities.gen';
import * as React from 'react';
import FormSelectInput, { FormSelectOption } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';

export type WarrantyCoverageDueSoonThresholdSettingsFormProps = {
  availabilities: WarrantyCoverageSettingsAvailabilities | undefined;
  disabled?: boolean;
};

const WarrantyCoverageDueSoonThresholdSettingsForm: React.FunctionComponent<WarrantyCoverageDueSoonThresholdSettingsFormProps> = ({
  availabilities,
  disabled = false,
}) => (
  <div
    css={css`
      max-width: 10rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
  >
    <FormSelectInput
      fieldKey={(settings: FieldKeyExpressionSegment<SettingsValueNested.WarrantyCoverageDueSoonThresholdSettings>) =>
        settings.value.dueSoonThreshold
      }
      disabled={disabled}
      options={
        availabilities?.availableDueSoonThresholdDays.map(
          (days): FormSelectOption => ({
            // translate one year worth of days to a '1 year` label
            label: days === 365 ? `1 year` : `${days} days`,
            value: days.toString(),
          }),
        ) ?? []
      }
    />
    <div
      css={css`
        font-weight: bold;
      `}
    >
      &nbsp;before
    </div>
  </div>
);

export default WarrantyCoverageDueSoonThresholdSettingsForm;
