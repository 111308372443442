/**
 * Size of the screen when our app enters landscape mode for tablets or smaller pc screens
 */
export const tabletBreakpointRem = 80; // 1280px

/**
 * Size of the screen when our app enters "mobile" mode for tablets and phones
 */
export const mobileBreakpointRem = 48; // 786px

/**
 * Size of the screen when our app should make compromises for phones
 */
export const smallBreakpointRem = 36; // 576px
