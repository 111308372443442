import { Request as SubscriptionGrowRequest } from '@SubscriptionClient/Subscription/Packages/Grow/Controller/SubscriptionGrowControllerNested.gen';
import { SubscriptionGrowAvailabilities } from '@SubscriptionClient/Subscription/Packages/Grow/Model.gen';
import { parseISO } from 'date-fns';
import { createValidationErrorMap } from '~/neo-ui/packages/form/packages/form-action/packages/form-validation/createValidationErrorMap';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import {
  SelfServeType,
  SubscriptionAgreementEnum,
} from '~/wm/packages/subscription/packages/manage/wizard/model/SubscriptionAgreementInfo';
import getSubscriptionAgreementStep from '~/wm/packages/subscription/packages/manage/wizard/step/getSubscriptionAgreementStep';
import { toFieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { ProductAvailabilityPayload } from '@SubscriptionClient/Subscription/Packages/Upgrade/Model.gen';
import { ProductRegions, ProductTierIds } from '~/wm/packages/subscription/packages/upgrade/wizard/SubscriptionUpgradeWizard';

export type GrowFormData = {
  selectedTierIds: ProductTierIds;
  selectedProductRegions: ProductRegions;
};

const getGrowSubscriptionAgreementStep = (availabilities: SubscriptionGrowAvailabilities, subscriptionAgreementLink: AnchorLocation) => {
  const productAvailabilityPayloads: ProductAvailabilityPayload[] = availabilities.productAvailabilityPayloads;
  const productFieldKeys = productAvailabilityPayloads.map(productAvailabilities =>
    toFieldKeyExpression<GrowFormData>(productAvailabilities.productEnum),
  );

  const invalidGrowTiers: string[] = [];
  availabilities.productAvailabilityPayloads.map(payload => {
    if (payload.enabledByDefault && payload.availableTiers.length > 0) {
      invalidGrowTiers.push(payload.availableTiers[0].tierId);
    }
  });

  return getSubscriptionAgreementStep<GrowFormData>(
    {
      label: 'Grow your subscription',
      description: 'Set your company up for the future by including more hardware assets.',
    },
    {
      case: SubscriptionAgreementEnum.SelfServe,
      discount: { credit: undefined, rate: undefined },
      term: availabilities.term
        ? {
            startsAt: parseISO(availabilities.term.startsAt!),
            endsAt: parseISO(availabilities.term.endsAt),
            durationMonths: availabilities.term.durationMonths!,
            renewalBehavior: availabilities.term.renewalBehaviorEnum,
          }
        : undefined,
      billing: availabilities.billing,
      type: SelfServeType.Grow,
    },
    'USD',
    {
      productTierIdFieldKeys: productFieldKeys,
    },
    subscriptionAgreementLink,
    createValidationErrorMap<SubscriptionGrowRequest, GrowFormData>([]),
    productAvailabilityPayloads,
    false,
    invalidGrowTiers,
  );
};

export default getGrowSubscriptionAgreementStep;
