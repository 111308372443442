import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import AutoEmailReportControls from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-controls/AutoEmailReportControls';
import AutoEmailReportRecipients from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-recipients/AutoEmailReportRecipients';
import AutoEmailReportPreview from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-preview/AutoEmailReportPreview';
import { AutoEmailReportDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';
import AutoEmailReportEditButton from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-edit/packages/auto-email-report-edit-button/AutoEmailReportEditButton';
import AutoEmailReportSettingsParameters from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-settings/packages/auto-email-report-settings-parameters/AutoEmailReportSettingsParameters';
import AutoEmailReportSettingsSorting from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-settings/packages/auto-email-report-settings-sorting/AutoEmailReportSettingsSorting';
import AutoEmailReportSettingsColumns from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-settings/packages/auto-email-report-settings-columns/AutoEmailReportSettingsColumns';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type AutoEmailReportProps = {
  autoEmailReport: AutoEmailReportDto;
};

const AutoEmailReport = ({ autoEmailReport }: AutoEmailReportProps) => (
  <Box
    borderRadius={'radius300'}
    padding={'padding400'}
    css={css`
      display: flex;
      flex-direction: column;
      gap: 2.25rem;
      transition: all 200ms ease-in-out;
      ${!autoEmailReport.isScheduled &&
      css`
        background-color: ${colorToCode('light-200')};
        box-shadow: 0 0 0 0.0625rem ${colorToCode('light-500')};
      `}
    `}
  >
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
      `}
    >
      <Header
        size={3}
        weight={'bold'}
        color={!autoEmailReport.isScheduled ? 'dark-900-32' : undefined}
      >
        {autoEmailReport.title}
      </Header>
    </div>
    <AutoEmailReportControls autoEmailReport={autoEmailReport} />
    <Box
      background={'foregroundAccent'}
      theme={'none'}
      padding={'padding200'}
      css={
        autoEmailReport.isScheduled
          ? undefined
          : css`
              background-color: ${colorToCode('light-200')};
            `
      }
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1rem;
        `}
      >
        <Header
          size={4}
          weight={'bold'}
          color={!autoEmailReport.isScheduled ? 'dark-900-32' : undefined}
        >
          Report Settings
        </Header>
        <AutoEmailReportEditButton autoEmailReport={autoEmailReport} />
      </div>
      <AutoEmailReportSettingsParameters
        autoEmailReport={autoEmailReport}
        css={css`
          margin-bottom: 1.5rem;
        `}
      />
      <div
        css={css`
          display: flex;
          gap: 1.5rem;
        `}
      >
        <AutoEmailReportSettingsSorting autoEmailReport={autoEmailReport} />
        <AutoEmailReportSettingsColumns autoEmailReport={autoEmailReport} />
      </div>
    </Box>
    <div
      css={css`
        display: flex;
        gap: 1.75rem;
      `}
    >
      <AutoEmailReportRecipients
        autoEmailReport={autoEmailReport}
        css={css`
          flex-basis: 100%;
        `}
      />
      <AutoEmailReportPreview
        autoEmailReport={autoEmailReport}
        css={css`
          flex-basis: 100%;
        `}
      />
    </div>
  </Box>
);

export default AutoEmailReport;
