import * as React from 'react';
import DisposalTreeImpactAccountSummaryContext from '~/wm/packages/disposal/packages/tree-impact/context/DisposalTreeImpactAccountSummaryContext';
import useTreeImpactSummaryGet from '~/wm/packages/tree-impact/hooks/useTreeImpactSummaryGet';

const DisposalTreeImpactAccountSummaryProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const { treeImpactSummary } = useTreeImpactSummaryGet('disposal');

  if (!treeImpactSummary) {
    return null;
  }

  return (
    <DisposalTreeImpactAccountSummaryContext.Provider
      value={{
        treesPerOrderUnit: treeImpactSummary.treesPerOrderUnit,
        totalTreesPlanted: treeImpactSummary.totalTreesPlanted,
        totalOrdersUnits: treeImpactSummary.totalOrdersUnits,
      }}
    >
      {children}
    </DisposalTreeImpactAccountSummaryContext.Provider>
  );
};

export default DisposalTreeImpactAccountSummaryProvider;
