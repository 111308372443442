import { disposalOrderInfo as disposalOrderInfoGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import * as React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import DisposalOrderInfoMutationContext from '~/wm/packages/disposal/packages/order-info/context/DisposalOrderInfoMutationContext';
import DisposalOrderInfoContext from '~/wm/packages/disposal/packages/order-info/context/DisposalOrderInfoContext';
import { DisposalOrderSummaryDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Dto/Model.gen';

export type DisposalOrderInfoProviderProps = {
  defaultDisposalOrderSummary?: DisposalOrderSummaryDto;
  disposalOrderId: string;
  children: React.ReactNode;
};

const DisposalOrderInfoProvider = ({ defaultDisposalOrderSummary, disposalOrderId, children }: DisposalOrderInfoProviderProps) => {
  const [disposalOrderSummary, setDisposalOrderSummary] = React.useState(defaultDisposalOrderSummary);
  const { callApi } = useApi();

  const reload = React.useCallback(
    async (isRequestActive?: () => boolean) => {
      const disposalOrderInfoResponse = await callApi(() =>
        disposalOrderInfoGet({
          disposalOrderId,
        }),
      );

      if (!disposalOrderInfoResponse) {
        return;
      }

      // if a function is provided to determine whether to propagate
      // a state update (to prevent race conditions), then it will
      // ensure not to update state. otherwise, each call updates state.
      if (!isRequestActive || isRequestActive()) {
        setDisposalOrderSummary(disposalOrderInfoResponse?.disposalOrderSummary);
      }
    },
    [callApi, disposalOrderId],
  );

  // Load initial disposal order info
  React.useEffect(
    () => {
      if (typeof defaultDisposalOrderSummary === 'undefined') {
        reload();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (typeof disposalOrderSummary === 'undefined') {
    return null;
  }

  return (
    <DisposalOrderInfoMutationContext.Provider
      value={{
        triggerDisposalOrderInfoReload: isRequestActive => reload(isRequestActive),
      }}
    >
      <DisposalOrderInfoContext.Provider
        value={{
          disposalOrderSummary,
        }}
      >
        {children}
      </DisposalOrderInfoContext.Provider>
    </DisposalOrderInfoMutationContext.Provider>
  );
};

export default DisposalOrderInfoProvider;
