import { PageFilter } from '@AssetManagementClient/AssetManagement/Packages/Share/Packages/Send/Model.gen';
import { ConsoleFilters } from '~/neo-ui/packages/table/packages/filter-table/FilterTable';

const buildPageFilter = (consoleFilter: ConsoleFilters | undefined) => ({
  filters: Object.entries(consoleFilter ?? {}).map(
    ([filterKey, filterValues]): PageFilter => ({
      key: filterKey,
      values: filterValues,
    }),
  ),
});

export default buildPageFilter;
