import { EngagementNoteDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Dto/Model.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import useGroupByMonth from '~/extensions/packages/date/packages/month/useGroupByMonth';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import useEngagementNoteDateSections from '~/wm/packages/organization/packages/engagement/packages/engagement-note/hooks/useEngagementNoteDateSections';
import useEngagementNoteDashboardPin from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/hooks/useEngagementNoteDashboardPin';
import useEngagementNoteDashboardArchive from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/hooks/useEngagementNoteDashboardArchive';
import useEngagementNoteDashboardUpdate from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/hooks/useEngagementNoteDashboardUpdate';
import EngagementNoteDashboardCard from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/packages/engagement-note-dashboard-card/EngagementNoteDashboardCard';
import useEngagementNoteDashboardUnpin from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/hooks/useEngagementNoteDashboardUnpin';
import useEngagementNoteDashboardUnarchive from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/hooks/useEngagementNoteDashboardUnarchive';

export type EngagementNoteSectionDashboardProps = {
  notes: EngagementNoteDto[];
};

const EngagementNoteSectionDashboard = ({ notes }: EngagementNoteSectionDashboardProps) => {
  const { pinned, pastWeek, pastMonth, pastMonthRemainingThisYear, pastRemainingYears } = useEngagementNoteDateSections(notes);
  const { pinNote, isPinningNote } = useEngagementNoteDashboardPin();
  const { unpinNote, isUnpinningNote } = useEngagementNoteDashboardUnpin();
  const { archiveNote, isArchivingNote } = useEngagementNoteDashboardArchive();
  const { unarchiveNote, isUnarchivingNote } = useEngagementNoteDashboardUnarchive();
  const { updateEngagementNote } = useEngagementNoteDashboardUpdate();

  // Convert our 2023-01 string to a date object
  const convertYearMonthStringToDate = (yearMonthString: string): Date => {
    const [year, month] = yearMonthString.split('-');
    const yearNumber = parseInt(year, 10);
    const monthNumber = parseInt(month, 10) - 1;
    return new Date(yearNumber, monthNumber);
  };

  const withinYearByMonth = useGroupByMonth(pastMonthRemainingThisYear, 'createdAt');

  const beyondYearByMonth = useGroupByMonth(pastRemainingYears, 'createdAt');

  const generateEngagementNoteCard = (note: EngagementNoteDto) => (
    <EngagementNoteDashboardCard
      key={note.engagementNoteId}
      note={note}
      isArchivingNote={isArchivingNote || isUnarchivingNote}
      isPinningNote={isPinningNote || isUnpinningNote}
      onNoteUpdate={updateEngagementNote}
      onNoteArchive={archiveNote}
      onNoteUnarchive={unarchiveNote}
      onNotePin={pinNote}
      onNoteUnpin={unpinNote}
    />
  );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      `}
    >
      {/* Pinned */}
      {pinned.length > 0 && (
        <React.Fragment>
          <Header
            size={7}
            weight={'bold'}
            color={'light-600'}
          >
            Pinned
          </Header>
          {pinned.map(generateEngagementNoteCard)}
        </React.Fragment>
      )}

      {/* Previous 7 Days */}
      {pastWeek.length > 0 && (
        <React.Fragment>
          <Header
            size={7}
            weight={'bold'}
            color={'light-600'}
          >
            Previous 7 Days
          </Header>
          {pastWeek.map(generateEngagementNoteCard)}
        </React.Fragment>
      )}

      {/* Previous 30 Days */}
      {pastMonth.length > 0 && (
        <React.Fragment>
          <Header
            size={7}
            weight={'bold'}
            color={'light-600'}
          >
            Previous 30 Days
          </Header>
          {pastMonth.map(generateEngagementNoteCard)}
        </React.Fragment>
      )}

      {/* Within This Year */}
      {Object.keys(withinYearByMonth).map(yearMonth => {
        const notes = withinYearByMonth[yearMonth];

        return (
          <React.Fragment key={yearMonth}>
            <Header
              size={7}
              weight={'bold'}
              color={'light-600'}
            >
              {formatDate(convertYearMonthStringToDate(yearMonth), {
                format: 'MMMM',
                timezone: TimezoneFormat.Local,
              })}
            </Header>
            {notes.map(generateEngagementNoteCard)}
          </React.Fragment>
        );
      })}

      {/* Last year and beyond */}
      {Object.keys(beyondYearByMonth).map(yearMonth => {
        const notes = beyondYearByMonth[yearMonth];

        return (
          <React.Fragment key={yearMonth}>
            <Header
              size={7}
              weight={'bold'}
              color={'light-600'}
            >
              {formatDate(convertYearMonthStringToDate(yearMonth), {
                format: 'MMMM, yyyy',
                timezone: TimezoneFormat.Local,
              })}
            </Header>
            {notes.map(generateEngagementNoteCard)}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default EngagementNoteSectionDashboard;
