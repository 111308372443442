import { SVGProps, forwardRef, memo } from 'react';

const EditIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
      >
        <title>Icon/Action/Edit</title>
        <g
          id="Assets"
          stroke="none"
          strokeWidth="1"
          fill="currentcolor"
          fillRule="evenodd"
        >
          <g
            id="Icon/Action/Edit"
            fill="rgba(5, 117, 204, 1)"
          >
            <path
              d="M9,4 L12,7 L4,15 L1,15 L1,12 L9,4 Z M11.2928932,1.70710678 C11.6834175,1.31658249 12.3165825,1.31658249 12.7071068,1.70710678 L14.2928932,3.29289322 C14.6834175,3.68341751 14.6834175,4.31658249 14.2928932,4.70710678 L13,6 L10,3 Z"
              id="L1"
            />
          </g>
        </g>
      </svg>
    );
  }),
);

export default EditIcon;
