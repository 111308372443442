import React, { useCallback } from 'react';
import Form from '~/neo-ui/packages/form/Form';
import WarrantyCartFilterFormData from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-filters/packages/warranty-cart-filter-form/WarrantyCartFilterFormData';
import useFiltersMutationContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useFiltersMutationContext';
import warrantyCartFilterFormValidationSchema from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-filters/packages/warranty-cart-filter-form/warrantyCartFilterFormValidationSchema';

export type WarrantyCartFilterFormProps = {
  children: React.ReactNode;
};

const defaultFormData: WarrantyCartFilterFormData = {
  selectedItems: false,
  outOfWarrantyAssets: false,
  assetsBetweenYears: false,
  assetsBetweenYearsFrom: 3,
  assetsBetweenYearsTo: 5,
};

const WarrantyCartFilterForm: React.FunctionComponent<WarrantyCartFilterFormProps> = ({ children }) => {
  const { onFilterChanged } = useFiltersMutationContext();

  const onSubmit = useCallback((data: WarrantyCartFilterFormData) => onFilterChanged(data), [onFilterChanged]);

  return (
    <Form
      submitMethod={'auto'}
      defaultFormData={defaultFormData}
      onSubmit={onSubmit}
      disableOverwriteDefaultFormDataOnSave={true}
      hideSubmitStatusIndicator={true}
      validationSchema={warrantyCartFilterFormValidationSchema}
    >
      {children}
    </Form>
  );
};

export default WarrantyCartFilterForm;
