import { css } from '@emotion/react';
import * as React from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import useContractTemplateCreate from '~/wm/packages/strategy/packages/contract/packages/contract-template/packages/hooks/useContractTemplateCreate';
import InputText from '~/neo-ui/packages/input/packages/input-text/InputText';

export type ContractTemplateCreateModalProps = {
  contractId: string;
  isOpen: boolean;
  onDismiss: () => void;
};

const ContractTemplateCreateModal = ({ contractId, isOpen, onDismiss }: ContractTemplateCreateModalProps) => {
  const { isCreatingContractTemplate, createTemplateContract } = useContractTemplateCreate();

  const [contractTemplateName, setContractTemplateName] = React.useState<string>('Untitled');
  const resetModal = () => {
    onDismiss();
  };

  const confirmModal = () => {
    createTemplateContract({
      sourceContractId: contractId,
      nameDisplay: contractTemplateName.trim(),
    });
    if (!isCreatingContractTemplate) {
      onDismiss();
    }
  };

  return (
    <ModalConfirmation
      header={{
        title: 'Save contract as template',
        icon: 'Save',
      }}
      theme={'secondary'}
      footer={{
        confirmButton: {
          icon: 'Save',
          label: 'Save ',
          loading: isCreatingContractTemplate,
          disabled: contractTemplateName.trim().length === 0,
          onClick: confirmModal,
        },
        dismissButton: {
          icon: 'Cancel',
          label: 'Cancel',
          disabled: isCreatingContractTemplate,
          onClick: resetModal,
        },
      }}
      isOpen={isOpen}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.625rem;
        `}
      >
        You may save any contract you selected as a template. When you do this, the contract details are saved and then you can later pull
        those details when adding a new contract or subscription. You must provide a name for the template so you can easily retrieve it
        later.
        <div
          css={css`
            display: flex;
            gap: 0.25rem;
            align-items: center;
          `}
        >
          <Label bold={true}>Template name:</Label>
          <div
            css={css`
              flex-grow: 1;
            `}
          >
            <InputText
              onTextChange={event => {
                setContractTemplateName(event.target.value);
              }}
              value={contractTemplateName}
              placeholder={'Enter template name...'}
            />
            {(typeof contractTemplateName === 'undefined' || contractTemplateName.trim().length === 0) && (
              <Label
                css={css`
                  margin-top: 0.23rem;
                  font-size: 80%;
                  font-weight: 400;
                `}
                color={'negative-400'}
                size={'sm'}
              >
                Template name is required
              </Label>
            )}
          </div>
        </div>
      </div>
    </ModalConfirmation>
  );
};

export default ContractTemplateCreateModal;
