// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


export interface IntegrationSyncLog
{
  /**
   * Individual log entries for this integration
   */
  logEntries: IntegrationSyncLogEntry[];
}


export interface IntegrationSyncLogEntry
{
  /**
   * The resultant state of the sync after this log
   */
  syncResult: IntegrationSyncResult;
  /**
   * If the sync failed, provides some extra context
   */
  failureReason: string | undefined;
  /**
   * The time at which the sync began
   */
  syncStartedAt: string;
  /**
   * The time at which the sync completed
   */
  syncCompletedAt: string;
  /**
   * Log info regarding hardware synced during the sync
   */
  hardware: SyncedAssetLog;
  /**
   * Log info regarding software synced during the sync
   */
  software: SyncedAssetLog;
}


export enum IntegrationSyncResult
{
  Succeeded = "Succeeded",
  Failed = "Failed",
}



export interface SyncedAssetLog
{
  /**
   * Total number of assets that came in during the sync
   */
  totalSynced: number;
  /**
   * Asset count difference yielded by the sync
   */
  totalSyncedDelta: number;
}

