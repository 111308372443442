// Reprensents a read-only percentage that is guaranteed to be between 0 and 100.
// Negative or over 100 values will be cropped upon construction
export class Percentage {
  get value(): number {
    return this.percentageCropped;
  }

  // Builds a percentage from a progress per million
  public static fromPerMillion(progressPerMillion: number): Percentage {
    return Percentage.fromRatio(progressPerMillion, 1000000);
  }

  // Builds a percentage from a ratio. E.g. buildRatio(2, 10) will return a Percentage of value 20
  private static fromRatio(value: number, total: number): Percentage {
    const percentage = (value / total) * 100;
    return new Percentage(percentage);
  }

  private readonly percentageCropped: number;

  constructor(percentage: number) {
    this.percentageCropped = this.cropPercentage(percentage);
  }

  private cropPercentage(percentage: number) {
    return percentage < 0 ? 0 : percentage > 100 ? 100 : percentage;
  }
}
