export const updateUrlWithUrlParams = (urlParams: URLSearchParams) => {
  const urlParamQueryString = urlParams.toString();
  const newRelativePathQuery =
    window.location.pathname + (urlParamQueryString === '' ? '' : '?' + urlParamQueryString) + window.location.hash;
  history.replaceState(null, '', newRelativePathQuery);
};

const updateUrlParameter = (key: string, value: string | undefined): void => {
  const currentParameters = new URLSearchParams(window.location.search);
  if (typeof value === 'undefined') {
    currentParameters.delete(key);
  } else {
    currentParameters.set(key, value);
  }

  updateUrlWithUrlParams(currentParameters);
};

export default updateUrlParameter;
