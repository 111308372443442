import { css } from '@emotion/react';
import useDisposalOrderPickupImageList from '~/wm/packages/disposal/packages/disposal-provider/hooks/useDisposalOrderPickupImageList';
import Image from '~/neo-ui/packages/image/Image';
import * as React from 'react';

export type DisposalOrderBulkPickupSectionImageListProps = {
  disposalOrderId: string;
};

const DisposalOrderBulkPickupSectionImageList = ({ disposalOrderId }: DisposalOrderBulkPickupSectionImageListProps) => {
  const { disposalOrderPickupImageList } = useDisposalOrderPickupImageList(disposalOrderId);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
      `}
    >
      {disposalOrderPickupImageList.map(image => (
        <Image
          key={image.id}
          source={`data:image/png;base64, ${image.content}`}
          css={css`
            flex-grow: 1;
            max-width: 12.5rem;
            max-height: 12.5rem;
            object-fit: contain;
          `}
        />
      ))}
    </div>
  );
};

export default DisposalOrderBulkPickupSectionImageList;
