import React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import { createValidationErrorMap } from '~/neo-ui/packages/form/packages/form-action/packages/form-validation/createValidationErrorMap';
import { Request as DisposalAddressValidateRequest } from '@AssetManagementClient/AssetManagement/Packages/Disposal/DisposalAddressValidateControllerNested.gen';
import buildDisposalAddressValidatePayloadDto from '~/wm/packages/disposal/builder/buildDisposalAddressValidatePayloadDto';
import { AddressBusiness } from '@SubscriptionClient/Common/Location/Model/Address.gen';
import useDisposalAddressValidate from '~/wm/packages/disposal/hooks/useDisposalAddressValidate';
import DisposalAddress from '~/wm/packages/disposal/packages/disposal-address/DisposalAddress';

export type DisposalAddressFormData = {
  shipmentReturnAddress: AddressBusiness | undefined;
  globalErrors: undefined;
};

type DisposalAddressFormProps = {
  address: AddressBusiness | undefined;
  isAddressValidated: boolean;
  onAddressDataReady?: (isReady: boolean, isValidated: boolean, addressBusiness?: AddressBusiness) => void;
};

const DisposalAddressForm: React.FunctionComponent<DisposalAddressFormProps> = ({ address, isAddressValidated, onAddressDataReady }) => {
  const { disposalAddressValidate, isValidatingAddress } = useDisposalAddressValidate();

  const onSubmit = React.useCallback(
    async (data: DisposalAddressFormData) => {
      if (typeof data.shipmentReturnAddress === 'undefined') {
        return;
      }

      const disposalAddressValidatePayloadDto = buildDisposalAddressValidatePayloadDto(data.shipmentReturnAddress);

      const updatedAddressBusiness = await disposalAddressValidate(disposalAddressValidatePayloadDto);

      if (typeof onAddressDataReady !== 'undefined' && typeof updatedAddressBusiness !== 'undefined') {
        onAddressDataReady(true, true, updatedAddressBusiness);
      }

      return updatedAddressBusiness;
    },
    [disposalAddressValidate, onAddressDataReady],
  );

  const toFormData = React.useCallback(
    (_formDisposalCheckoutData: AddressBusiness | undefined): DisposalAddressFormData => ({
      globalErrors: undefined,
      shipmentReturnAddress: typeof address !== 'undefined' && address.address.countryCode !== 'US' ? undefined : address,
    }),
    [address],
  );

  const defaultFormData = React.useMemo(() => toFormData(address), [address, toFormData]);

  const validationErrorMapperForAddressValidation = createValidationErrorMap<DisposalAddressValidateRequest, DisposalAddressFormData>([
    [request => request.addressBusiness, formData => formData.globalErrors],
    [request => request.addressBusiness.name, formData => formData.shipmentReturnAddress.name],
    [request => request.addressBusiness.phone, formData => formData.shipmentReturnAddress.phone],
    [request => request.addressBusiness.address.line1, formData => formData.shipmentReturnAddress.address.line1],
    [request => request.addressBusiness.address.line2, formData => formData.shipmentReturnAddress.address.line2],
    [request => request.addressBusiness.address.city, formData => formData.shipmentReturnAddress.address.city],
    [request => request.addressBusiness.address.stateCode, formData => formData.shipmentReturnAddress.address.stateCode],
    [request => request.addressBusiness.address.countryCode, formData => formData.shipmentReturnAddress.address.countryCode],
    [request => request.addressBusiness.address.zip, formData => formData.shipmentReturnAddress.address.zip],
  ]);

  return (
    <Form
      submitMethod={'manual'}
      defaultFormData={defaultFormData}
      onSubmit={onSubmit}
      disableSubmitOnEnter={true}
      disableOverwriteDefaultFormDataOnSave={true}
      hideSubmissionButton={true}
      validationErrorMapper={validationErrorMapperForAddressValidation}
    >
      <DisposalAddress
        isAddressValidated={isAddressValidated}
        isValidatingAddress={isValidatingAddress}
        onEditing={isEditing => {
          if (typeof onAddressDataReady !== 'undefined') {
            onAddressDataReady(!isEditing, false, undefined);
          }
        }}
      />
    </Form>
  );
};

export default DisposalAddressForm;
