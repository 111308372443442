import ServiceRequest from '~/wm/packages/warranty/packages/service-request/model/ServiceRequest';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import Box from '~/neo-ui/packages/box/Box';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import formatBillingAddress from '~/wm/packages/subscription/packages/billing/operation/formatBillingAddress';
import { css } from '@emotion/react';

export type ServiceRequestShippingInfoSectionProps = {
  serviceRequest: ServiceRequest;
};

const ServiceRequestShippingInfoSection = ({ serviceRequest }: ServiceRequestShippingInfoSectionProps) =>
  typeof serviceRequest.shippingAddress === 'undefined' ? null : (
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
    >
      <LayoutSection
        title={'Shipping address'}
        titleSize={4}
        titleWeight={'bold'}
        description={'Replacement parts will be shipped to this address.'}
      >
        <div>
          <Label
            bold={true}
            css={css`
              margin-bottom: 0.3125rem;
            `}
          >
            {serviceRequest.asset.orgName}
          </Label>
          <Label>{formatBillingAddress(serviceRequest.shippingAddress)}</Label>
        </div>
      </LayoutSection>
    </Box>
  );

export default ServiceRequestShippingInfoSection;
