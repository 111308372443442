import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';

export type PreformattedProps = {
  text: string;
} & Styleable;

const Preformatted: React.FunctionComponent<PreformattedProps> = ({ text, className }) => (
  <span
    className={className}
    css={css`
      // reduce height of br to mimic a paragraph margin
      br + br {
        display: block;
        margin: 0.25rem 0;
      }
    `}
  >
    {typeof text === 'undefined'
      ? ''
      : text.split('\n').map((line, index) => (
          <React.Fragment key={`${line}${index}`}>
            {
              // remove empty array elements when more than one `\n` in a row are split
              line && (
                <span
                  css={css`
                    // Allow bullet points and indents to remain formatted correctly
                    white-space: break-spaces;
                  `}
                >
                  {line}
                </span>
              )
            }
            <br />
          </React.Fragment>
        ))}
  </span>
);

export default Preformatted;
