import { organizationEngagementNotePin } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementNoteListPanelTabMutation, {
  UseOrganizationEngagementNoteListPanelTabMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/context/hooks/useEngagementNoteListPanelTabMutation';

export type UseEngagementNoteListPin = {
  pinNote: (engagementNoteId: string) => void;
  isPinningNote: boolean;
};

const useEngagementNoteListPin = (options?: UseOrganizationEngagementNoteListPanelTabMutationOptions): UseEngagementNoteListPin => {
  const [pinNote, isPinningNote] = useEngagementNoteListPanelTabMutation(
    (engagementNoteId: string) =>
      organizationEngagementNotePin({
        engagementNoteId,
      }),
    options,
  );

  return {
    pinNote,
    isPinningNote,
  };
};

export default useEngagementNoteListPin;
