import { useContextOrThrow } from '~/extensions/packages/hooks/useContextOrThrow';
import EngagementNotePanelTabMutationContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/context/EngagementNotePanelTabMutationContext';
import { EngagementNoteDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Dto/Model.gen';
import {
  EngagementNoteStatusAvailabilityDto,
  EngagementNoteStatusCountDto,
} from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Packages/EngagementNoteStatus/Dto/Model.gen';

export type EngagementNotePanelTabContextData = {
  notes: EngagementNoteDto[];
  statusAvailabilities: EngagementNoteStatusAvailabilityDto[];
  statusCounts: EngagementNoteStatusCountDto[];
  hasNextPage: boolean;
};

const useEngagementNotePanelTabMutationContext = () => useContextOrThrow(EngagementNotePanelTabMutationContext);

export default useEngagementNotePanelTabMutationContext;
