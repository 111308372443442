import { Styleable } from '~/neo-ui/model/capacity';
import * as React from 'react';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';

export type PeopleDashboardDropdownMenuHeaderOrganizationKeyContactProps = {
  peopleConsoleUrl: string;
} & Styleable;

const PeopleDashboardDropdownMenuHeaderOrganizationKeyContact: React.FunctionComponent<
  PeopleDashboardDropdownMenuHeaderOrganizationKeyContactProps
> = ({ peopleConsoleUrl }) => (
  <ButtonLink
    iconRight={'ArrowRight'}
    rightAlign={true}
    iconColorOverride={'dark-900'}
    anchor={{ href: peopleConsoleUrl }}
    css={css`
      display: flex;
      align-items: center;
      gap: 1rem;
      cursor: pointer;
    `}
  >
    <Label
      bold={true}
      color={'dark-900'}
      css={css`
        text-align: start;
      `}
    >
      See All End Users
    </Label>
  </ButtonLink>
);

export default PeopleDashboardDropdownMenuHeaderOrganizationKeyContact;
