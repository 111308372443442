import useEngagementActionListMutation, {
  UseEngagementActionListMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListMutation';
import { organizationEngagementActionMarkIncomplete } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseEngagementActionMarkIncomplete = {
  markIncomplete: (engagementActionId: string) => void;
  isMarkingIncomplete: boolean;
};

const useEngagementActionMarkIncomplete = (options?: UseEngagementActionListMutationOptions): UseEngagementActionMarkIncomplete => {
  const [markIncomplete, isMarkingIncomplete] = useEngagementActionListMutation(
    (engagementActionId: string) =>
      organizationEngagementActionMarkIncomplete({
        engagementActionId,
      }),
    options,
  );

  return {
    markIncomplete,
    isMarkingIncomplete,
  };
};

export default useEngagementActionMarkIncomplete;
