import * as React from 'react';
import { FunctionComponent, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import InitiativeAssessmentQuestionListMutationContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-assessment-section/context/InitiativeAssessmentQuestionListMutationContext';
import { initiativeAssessmentEvaluationQuestionList } from '@BeastClient/BeastClientMsp.gen';
import { AssessmentEvaluationQuestionOverviewDto } from '@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Dto/Model.gen';
import InitiativeAssessmentQuestionListContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-assessment-section/context/InitiativeAssessmentQuestionListContext';

export type InitiativeAssessmentQuestionListProviderProps = {
  initiativeId: string;
};

const InitiativeAssessmentQuestionListProvider: FunctionComponent<PropsWithChildren<InitiativeAssessmentQuestionListProviderProps>> = ({
  initiativeId,
  children,
}) => {
  const { callApi } = useApi();

  const { organizationId } = useOrganizationContext();
  const [assessmentQuestions, setAssessmentQuestions] = useState<AssessmentEvaluationQuestionOverviewDto[] | undefined>(undefined);

  const reload = useCallback(async () => {
    const response = await (() => {
      return callApi(() =>
        initiativeAssessmentEvaluationQuestionList({
          organizationId,
          initiativeId,
        }),
      );
    })();

    if (!response) {
      return;
    }

    setAssessmentQuestions(response.assessmentEvaluationQuestionOverview);
  }, [callApi, initiativeId, organizationId]);

  useEffect(() => {
    reload();
  }, [reload]);

  if (typeof assessmentQuestions === 'undefined') {
    return null;
  }

  return (
    <InitiativeAssessmentQuestionListMutationContext.Provider
      value={{
        triggerInitiativeAssessmentQuestionListReload: () => reload(),
      }}
    >
      <InitiativeAssessmentQuestionListContext.Provider
        value={{
          assessmentQuestions,
        }}
      >
        {children}
      </InitiativeAssessmentQuestionListContext.Provider>
    </InitiativeAssessmentQuestionListMutationContext.Provider>
  );
};

export default InitiativeAssessmentQuestionListProvider;
