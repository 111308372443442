import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import Padding, { paddingToCode } from '~/neo-ui/packages/style/Padding';

export type FormCheckboxDisplayProps = {
  id: string;
  disabled?: boolean;
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void;

  propsForHtmlInputComponent: React.ComponentProps<'input'>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  description?: string | React.JSX.Element;
  iconLeft?: IconType;
  noCheckboxMargin?: boolean;
  paddingTop?: Padding;
  cssForLabel?: SerializedStyles;
};

export const FormCheckboxDisplay: React.FunctionComponent<FormCheckboxDisplayProps> = ({
  id,
  disabled,
  onInput,
  propsForHtmlInputComponent,
  onChange,
  checked,
  description,
  iconLeft,
  noCheckboxMargin = false,
  paddingTop,
  cssForLabel,
}) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 0.5rem;
      padding-top: ${paddingToCode(paddingTop ?? 'padding0')};
    `}
    className="form-check"
  >
    <input
      // Doesn't work to be propagated automatically via inputProps
      type="checkbox"
      id={id}
      disabled={disabled}
      onInput={onInput}
      css={css`
        ${noCheckboxMargin ? 'margin: 0' : 'margin-top: 0'};
      `}
      {...propsForHtmlInputComponent}
      // Override these props only if specified
      {...(typeof onChange !== 'undefined' && { onChange })}
      {...(typeof checked !== 'undefined' && { checked })}
    />
    {iconLeft && (
      <Icon
        className="form-check-icon"
        icon={iconLeft}
      />
    )}
    {description && (
      <label
        className="form-check-label"
        htmlFor={id}
        css={css`
          ${cssForLabel}
        `}
      >
        {description}
      </label>
    )}
  </div>
);
