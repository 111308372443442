import { RangeFilterHeader } from '~/neo-ui/packages/filter/packages/filter-range/RangeFilterHeader';
import * as React from 'react';

export type RangeValueHeaderFormatterType = (
  minValue: number | undefined,
  maxValue: number | undefined,
  unitLabel: string,
  label: string,
) => (isOpen: boolean) => JSX.Element;
export const rangeValueHeaderFormatter: RangeValueHeaderFormatterType =
  (minValue: number | undefined, maxValue: number | undefined, unitLabel: string, label: string) => (isOpen: boolean) =>
    (
      <RangeFilterHeader
        unit={unitLabel}
        minValue={minValue}
        maxValue={maxValue}
        label={label}
        isOpen={isOpen}
      />
    );
