import React from 'react';
import QuickLinks, { AccountNavLinkDto } from '~/wm/packages/sp-account/packages/home-page/packages/quick-links/QuickLinks';
import ExploreProducts, { ProductExploreDto } from '~/wm/packages/sp-account/packages/home-page/packages/explore-products/ExploreProducts';
import HeroImage from './packages/HeroImage';
import Text from '~/sp-ui/text/Text';
import { keyframes } from '@emotion/react';

interface HomePageProps {
  userFirstName: string;
  accountNavLinkDtos: AccountNavLinkDto[];
  productExploreDtos: ProductExploreDto[];
}

const floatIn = keyframes({
  ['0%']: {
    transform: 'translate(-20px, 280px)',
  },

  ['20%']: {
    transform: 'translate(-15px, 224px)',
  },

  ['40%']: {
    transform: 'translate(-10px, 168px)',
  },

  ['60%']: {
    transform: 'translate(0, 112px)',
  },

  ['80%']: {
    transform: 'translate(5px, 56px)',
  },

  ['100%']: {
    transform: 'translate(0, 0)',
  },
});

const hovering = keyframes({
  ['0%']: {
    transform: 'translate(0, 0)',
  },
  ['25%']: {
    transform: 'translate(5px, -2.5px)',
  },
  ['50%']: {
    transform: 'translate(0px, -2.5px)',
  },
  ['75%']: {
    transform: 'translate(5px, 0px)',
  },
  ['100%']: {
    transform: 'translate(0, 0)',
  },
});

const HomePage: React.FunctionComponent<HomePageProps> = ({ userFirstName, accountNavLinkDtos, productExploreDtos }) => {
  const userName = typeof userFirstName === 'undefined' ? 'Invited Member' : userFirstName;

  const getCurrentDate = () =>
    new Date().toLocaleDateString('en-us', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    });

  const getDayTimePeriod = () => {
    const hour = new Date().getHours();
    return hour < 12 ? 'morning' : hour < 18 ? 'afternoon' : 'evening';
  };

  return (
    <React.Fragment>
      <HeroImage>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            columnGap: '76px',
          }}
        >
          <img
            src="/assets/sign-in-up-banners/sph-homepage-balloon.svg"
            css={[
              { gridRow: '1 / 3' },
              {
                animationName: `${floatIn}, ${hovering}`,
                animationDelay: '0s, 8s',
                animationDuration: '8s, 10s',
                animationTimingFunction: 'linear, linear',
                animationIterationCount: '1, infinite',
              },
            ]}
          />

          <Text
            variant="h1"
            color="white"
            css={{
              alignSelf: 'end',
              fontWeight: 700,
            }}
          >
            {'Good ' + getDayTimePeriod() + ', ' + userName}
          </Text>

          <Text
            variant="h2"
            color="white"
            css={{
              alignSelf: 'start',
            }}
          >
            {getCurrentDate()}
          </Text>
        </div>
      </HeroImage>

      <div
        css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 180px)',
          width: '100%',
          maxWidth: '1200px',
          margin: '4rem auto 0 auto',
          gap: '1rem',
          paddingInline: '32px',
          placeContent: 'center',
        }}
      >
        <QuickLinks accountNavLinkDtos={accountNavLinkDtos} />
        <ExploreProducts productExploreDtos={productExploreDtos} />
      </div>
    </React.Fragment>
  );
};

export default HomePage;
