import { css } from '@emotion/react';
import * as React from 'react';

import { assertNeverOrThrow } from '~/extensions/packages/types/assertNever';
import { ListCardProps } from '../../ListCard';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export interface ListCardGroupProps {
  type: 'stacked' | 'linked';
  bgColor?: Color;
  children: ((bgColor: Color) => React.ReactElement<ListCardProps>) | ((bgColor: Color) => React.ReactElement<ListCardProps>[]);
}

/**
 * @deprecated from usage of php classes, replace with using flex box
 */
class ListCardGroup extends React.PureComponent<ListCardGroupProps> {
  public render() {
    const { children, bgColor = 'light-500', type } = this.props;

    const spaceBetweenCards = '5px';
    return (
      <div
        className={`list-card-group`}
        css={
          type === 'linked'
            ? css`
                .list-card {
                  position: relative;
                }

                > *:not(:last-child) {
                  .list-card,
                  &.list-card {
                    margin-bottom: ${spaceBetweenCards};
                    &:after {
                      content: '';
                      position: absolute;
                      bottom: -${spaceBetweenCards};
                      left: 15px;
                      background: ${colorToCode(bgColor)};
                      width: 10px;
                      height: ${spaceBetweenCards};
                    }
                  }
                }
              `
            : type === 'stacked'
            ? css`
                .list-card {
                  &:first-child {
                    border-top-left-radius: $border-radius;
                    border-top-right-radius: $border-radius;
                  }
                  &:last-child {
                    border-bottom-left-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;
                  }
                }
              `
            : assertNeverOrThrow(type)
        }
      >
        {children(bgColor)}
      </div>
    );
  }
}

export default ListCardGroup;
