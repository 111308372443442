import * as React from 'react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import useDisposalOrderInfoContext from '~/wm/packages/disposal/packages/order-info/context/hooks/useDisposalOrderInfoContext';
import Box from '~/neo-ui/packages/box/Box';
import DisposalOrderShipmentModule from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-shipment-module/DisposalOrderShipmentModule';
import Spinner from '~/neo-ui/spinner/Spinner';
import DisposalOrderPackageModule from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-package-module/DisposalOrderPackageModule';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderType/Model/DisposalOrderTypeFactoryNested.gen';

const DisposalOrderShipmentSection = () => {
  const { disposalOrderSummary } = useDisposalOrderInfoContext();

  if (typeof disposalOrderSummary === 'undefined') {
    return <Spinner />;
  }

  if (typeof disposalOrderSummary.completedAt !== 'undefined') {
    return null;
  }

  const shouldHideShipmentSection =
    typeof disposalOrderSummary?.availableOperations === 'undefined' || disposalOrderSummary?.availableOperations.cannotModifyShipping;

  if (shouldHideShipmentSection) {
    return null;
  }

  const hasPickupDetails =
    typeof disposalOrderSummary !== 'undefined' &&
    (disposalOrderSummary?.orderType === Enum.ItadSelf || disposalOrderSummary?.orderType === Enum.WorkstationAssurance) &&
    typeof disposalOrderSummary.disposalOrderPickupDetails !== 'undefined';

  return (
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
        `}
      >
        <div>
          <Header
            size={4}
            css={css`
              padding-bottom: 0.375rem;
            `}
            weight={'bold'}
            color={'dark-900'}
          >
            {hasPickupDetails ? 'Your pickup is scheduled' : 'Complete your disposal'}
          </Header>

          {!shouldHideShipmentSection && (
            <Label color={'dark-900-64'}>
              {hasPickupDetails
                ? `Attach a shipping label to each package.`
                : `Attach a shipping label to each package, choose a shipping option,
              then we’ll take care of the rest.`}
            </Label>
          )}
        </div>
        <DisposalOrderPackageModule />
        <DisposalOrderShipmentModule />
      </div>
    </Box>
  );
};

export default DisposalOrderShipmentSection;
