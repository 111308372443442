// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.

import axios, { AxiosRequestConfig, Method, AxiosError } from 'axios';
import { sleep } from '~/extensions/packages/sleep/sleep';
import { clientConfig } from '~/client/clientConfig';

const streamSaverPromise = typeof document !== 'undefined'
  // Importing the streamSaver library uses window which crashes in server-side rendering contexts
  ? import('streamsaver')
  : undefined;


//#region ApiCalls


//#region CaseConverters

export function formatRequest(input: any)
{
  const output : any = {};
  let key;
  let updatedKey;
  let value;
  if (input instanceof Array)
  {
    return input.map(function(value)
    {
      if (typeof value === 'object')
      {
        value = formatRequest(value);
      }
      return value;
    });
  }
  else
  {
    for (key in input)
    {
      if (input.hasOwnProperty(key))
      {
        value = input[key];
        if (value instanceof Array || (value !== null && typeof value !== 'undefined' && value.constructor === Object))
        {
          value = formatRequest(value);
        }

        updatedKey = key.includes('-')
          // This is likely not a property but a custom dictionary key (workaround for WM-1555)
          ? key
          : camelToPascal(key);
        output[updatedKey] = value;
      }
    }
  }
  return output;
}

const camelToPascal = (key: string) => (key.charAt(0).toUpperCase() + key.slice(1) || key).toString();
const pascalToCamel = (key: string) => (key.charAt(0).toLowerCase() + key.slice(1) || key).toString();

const formatKey = (key: string) => key.includes('-')
  // This is likely not a property but a custom dictionary key (workaround for WM-1555)
  ? key
  : pascalToCamel(key);

type ValueTransformer = (value: any) => any;

/**
 * It is our convention to use undefined throughout the codebase to reduce
 * confusion and ambiguity between undefined and null.
 *
 * Therefore, we map nulls from the API to undefined for use in Typescript.
 */
const transformNullToUndefined: ValueTransformer = (value) =>
    value === null ? undefined : value;

/**
 * Transformations applied to all json values before passing to the consumer
 */
const valueTransformations: ValueTransformer[]  = [
  transformNullToUndefined
];

const formatValue = <T>(value: T) =>
    // Apply all transformations
    valueTransformations.reduce((value, transform) => transform(value), value);

export function formatResponse(input: any): any
{
  const output: any = {};
  if (input instanceof Array)
  {
    return input.map((value) =>
        typeof value === 'object' && value !== null
            // It's an object or array, recurse
            ? formatResponse(value)
            : formatValue(value));
  }
  else
  {
    for (const key in input)
    {
      if (input.hasOwnProperty(key))
      {
        const value = input[key];

        // Format the key
        const formattedKey = formatKey(key);

        // Format the value
        const formattedValue = typeof value === 'object' && value !== null
          // It's an object or array, recurse
          ? formatResponse(value)
          : formatValue(value);

        // Build output object
        output[formattedKey] = formattedValue;
      }
    }
  }
  return output;
}

//#endregion CaseConverters


const prefix = () => clientConfig.origin + "/api/Warranty"

async function callApi<TRequest, TResponse>(method: Method, path: string, request: TRequest, attemptCount = 1): Promise<TResponse>
{
  try {
    const axiosRequest = createAxiosRequest(method, path, formatRequest(request), false);
    const response = await axios(axiosRequest);
    return formatResponse(response.data);
  } catch(error) {
    const err = error as AxiosError;
    const csrfToken = err.response?.headers['X-XSRF-TOKEN'.toLowerCase()];
    if (typeof csrfToken !== 'undefined' && attemptCount < 6)
    {
      localStorage.setItem('XSRF-TOKEN', csrfToken);

      // Sleep for exponential back-off in case the service is down
      await sleep(Math.random() * 10 * Math.pow(3, attemptCount - 1) + 5);

      return callApi(method, path, request, attemptCount + 1);
    }
    throw error;
  }
}

async function callStreamApi<TRequest>(method: Method, path: string, request: TRequest, attemptCount = 1): Promise<void>
{
  const csrfToken = localStorage.getItem('XSRF-TOKEN');
  const fetchResponse = await fetch(prefix() + path, {
    method: method,
    headers: {
      ...((csrfToken !== null) && { 'X-XSRF-TOKEN': csrfToken }),
      'Content-Type': 'application/json',
    },
    // Fetch requires this to be a string
    body: JSON.stringify(formatRequest(request)),
  });

  if (!fetchResponse.ok) {
    const csrfToken = fetchResponse.headers.get('X-XSRF-TOKEN'.toLowerCase());
    if (typeof csrfToken !== 'undefined' && attemptCount < 6) {
      // Sleep for exponential back-off in case the service is down
      await sleep(Math.random() * 10 * Math.pow(3, attemptCount - 1) + 5);
      return callStreamApi(method, path, request, attemptCount + 1);
    }
  }

  // Download stream
  const readableStream = fetchResponse.body;
  // Regex to get the filename from the Content-Disposition header
  const fileNameRegex = `(?<=filename=").*?(?=")`;
  const fileName = fetchResponse.headers.get('Content-Disposition')?.match(fileNameRegex)![0];
  const decodedFilename = decodeURIComponent(fileName!);
  if (window.WritableStream && readableStream?.pipeTo) {
    // We can assert StreamSaver exists now as this is a client-side context
    const streamSaver = (await streamSaverPromise!).default;
    // Override mitm.html location from jimmywarting.github.io to self-hosted file in php
    streamSaver.mitm = '/stream-saver/mitm.html';
    const fileStream = streamSaver.createWriteStream(decodedFilename);
    return readableStream.pipeTo(fileStream);
  }
}

function createAxiosRequest<TRequest>(callMethod: Method, path: string, request: TRequest, isStreamRequest: boolean): AxiosRequestConfig
{
  const csrfToken = localStorage.getItem('XSRF-TOKEN');
  const requestConfig: AxiosRequestConfig =
  {
    method: callMethod,
    url: prefix() + path,
    data: request,
    headers: {
      ...((csrfToken !== null) && {'X-XSRF-TOKEN': csrfToken})
    }
  };
  if (isStreamRequest)
  {
    requestConfig.responseType = 'stream';
    requestConfig.timeout = 99000; // milliseconds
  }
  return requestConfig;
}

//#endregion ApiCalls


//#region Imports

import { Request as WarrantyClientWarrantyWarrantyCartControllerWarrantyCartGetControllerNestedRequest, Response as WarrantyClientWarrantyWarrantyCartControllerWarrantyCartGetControllerNestedResponse } from "@WarrantyClient/Warranty/WarrantyCart/Controller/WarrantyCartGetControllerNested.gen"
import { Request as WarrantyClientWarrantyWarrantyCartControllerRequest, Response as WarrantyClientWarrantyWarrantyCartControllerResponse } from "@WarrantyClient/Warranty/WarrantyCart/Controller.gen"
import { Request as WarrantyClientWarrantyWarrantyCartPackagesEstimateAddControllerRequest, Response as WarrantyClientWarrantyWarrantyCartPackagesEstimateAddControllerResponse } from "@WarrantyClient/Warranty/WarrantyCart/Packages/Estimate/Add/Controller.gen"
import { Request as WarrantyClientWarrantyWarrantyCartPackagesEstimateGetControllerRequest, Response as WarrantyClientWarrantyWarrantyCartPackagesEstimateGetControllerResponse } from "@WarrantyClient/Warranty/WarrantyCart/Packages/Estimate/Get/Controller.gen"
import { Request as WarrantyClientWarrantyWarrantyCartPackagesEstimatePdfProposalEstimatePdfProposalControllerNestedRequest } from "@WarrantyClient/Warranty/WarrantyCart/Packages/Estimate/Pdf/Proposal/EstimatePdfProposalControllerNested.gen"
import { Request as WarrantyClientWarrantyWarrantyCartPackagesEstimateSelectAllControllerRequest, Response as WarrantyClientWarrantyWarrantyCartPackagesEstimateSelectAllControllerResponse } from "@WarrantyClient/Warranty/WarrantyCart/Packages/Estimate/SelectAll/Controller.gen"
import { Request as WarrantyClientWarrantyWarrantyCartPackagesEstimateUpsertControllerRequest, Response as WarrantyClientWarrantyWarrantyCartPackagesEstimateUpsertControllerResponse } from "@WarrantyClient/Warranty/WarrantyCart/Packages/Estimate/Upsert/Controller.gen"
import { Request as WarrantyClientWarrantyWarrantyCartPackagesWarrantyCartSettingsControllerWarrantyCartSettingsControllerNestedRequest, Response as WarrantyClientWarrantyWarrantyCartPackagesWarrantyCartSettingsControllerWarrantyCartSettingsControllerNestedResponse } from "@WarrantyClient/Warranty/WarrantyCart/Packages/WarrantyCartSettings/Controller/WarrantyCartSettingsControllerNested.gen"
import { Request as WarrantyClientWarrantyWarrantyCheckoutpackagesCouponCodeValidateRequest, Response as WarrantyClientWarrantyWarrantyCheckoutpackagesCouponCodeValidateResponse } from "@WarrantyClient/Warranty/WarrantyCheckout/packages/CouponCodeValidate.gen"
import { Request as WarrantyClientWarrantyWarrantyCheckoutpackagesCoverageCheckoutRequest, Response as WarrantyClientWarrantyWarrantyCheckoutpackagesCoverageCheckoutResponse } from "@WarrantyClient/Warranty/WarrantyCheckout/packages/CoverageCheckout.gen"
import { EmptyRequest as WarrantyClientBeastClientCommonControllerControllerEmptyRequest } from "@WarrantyClient/BeastClient/Common/Controller/Controller.gen"
import { Response as WarrantyClientWarrantyWarrantyCheckoutpackagesCoverageAllowCheckoutResponse } from "@WarrantyClient/Warranty/WarrantyCheckout/packages/CoverageAllowCheckout.gen"
import { EmptyRequest as WarrantyClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest } from "@WarrantyClient/ApiServer/Packages/AspNetCore/Controller/Request.gen"
import { Response as WarrantyClientWarrantyCoveragePackagesSettingsPackagesAvailabilitiesControllerWarrantyCoverageSettingsAvailabilitiesGetControllerNestedResponse } from "@WarrantyClient/Warranty/Coverage/Packages/Settings/Packages/Availabilities/Controller/WarrantyCoverageSettingsAvailabilitiesGetControllerNested.gen"
import { Request as WarrantyClientWarrantyCoveragePackagesListControllerCoverageListControllerNestedRequest, Response as WarrantyClientWarrantyCoveragePackagesListControllerCoverageListControllerNestedResponse } from "@WarrantyClient/Warranty/Coverage/Packages/List/Controller/CoverageListControllerNested.gen"
import { Request as WarrantyClientWarrantyCoveragePackagesListControllerCoverageListSpreadsheetControllerNestedRequest } from "@WarrantyClient/Warranty/Coverage/Packages/List/Controller/CoverageListSpreadsheetControllerNested.gen"
import { Request as WarrantyClientWarrantyCoveragePackagesTransferControllerCoverageTransferControllerNestedRequest, Response as WarrantyClientWarrantyCoveragePackagesTransferControllerCoverageTransferControllerNestedResponse } from "@WarrantyClient/Warranty/Coverage/Packages/Transfer/Controller/CoverageTransferControllerNested.gen"
import { Request as WarrantyClientWarrantyCoveragePackagesTransferControllerCoverageTransferDisposalOrderCertificatePdfGetControllerNestedRequest } from "@WarrantyClient/Warranty/Coverage/Packages/Transfer/Controller/CoverageTransferDisposalOrderCertificatePdfGetControllerNested.gen"
import { Request as WarrantyClientWarrantyCoveragePackagesTransferPackagesDisposalOrderCoverageTransferDisposalOrderInfoGetControllerNestedRequest, Response as WarrantyClientWarrantyCoveragePackagesTransferPackagesDisposalOrderCoverageTransferDisposalOrderInfoGetControllerNestedResponse } from "@WarrantyClient/Warranty/Coverage/Packages/Transfer/Packages/DisposalOrder/CoverageTransferDisposalOrderInfoGetControllerNested.gen"
import { EmptyRequest as WarrantyClientCommonControllerControllerEmptyRequest, EmptyResponse as WarrantyClientCommonControllerControllerEmptyResponse } from "@WarrantyClient/Common/Controller/Controller.gen"
import { Response as WarrantyClientWarrantyWarrantiesListControllerpackagesWarrantyListSettingsControllerWarrantyListSettingsControllerNestedResponse } from "@WarrantyClient/Warranty/WarrantiesList/Controller/packages/WarrantyListSettings/Controller/WarrantyListSettingsControllerNested.gen"
import { Response as WarrantyClientWarrantyProblemControllerProblemTypeAvailabilitiesControllerNestedResponse } from "@WarrantyClient/Warranty/Problem/Controller/ProblemTypeAvailabilitiesControllerNested.gen"
import { Request as WarrantyClientWarrantyServiceRequestPackagesAvailabilityControllerServiceRequestAvailabilityControllerNestedRequest } from "@WarrantyClient/Warranty/ServiceRequest/Packages/Availability/Controller/ServiceRequestAvailabilityControllerNested.gen"
import { Result as WarrantyClientPrimitivesResultsServiceRequestAvailabilityControllerNestedResponse_ServiceRequestAvailabilityError_Result } from "@WarrantyClient/Primitives/Results/ServiceRequestAvailabilityControllerNested/Response_/ServiceRequestAvailabilityError_.gen"
import { Request as WarrantyClientWarrantyServiceRequestPackagesGetControllerServiceRequestGetControllerNestedRequest } from "@WarrantyClient/Warranty/ServiceRequest/Packages/Get/Controller/ServiceRequestGetControllerNested.gen"
import { Result as WarrantyClientPrimitivesResultsServiceRequestGetControllerNestedResponse_ServiceRequestGetError_Result } from "@WarrantyClient/Primitives/Results/ServiceRequestGetControllerNested/Response_/ServiceRequestGetError_.gen"
import { Request as WarrantyClientWarrantyServiceRequestPackagesGetControllerServiceRequestGetAdminControllerNestedRequest } from "@WarrantyClient/Warranty/ServiceRequest/Packages/Get/Controller/ServiceRequestGetAdminControllerNested.gen"
import { Result as WarrantyClientPrimitivesResultsServiceRequestGetAdminControllerNestedResponse_ServiceRequestGetError_Result } from "@WarrantyClient/Primitives/Results/ServiceRequestGetAdminControllerNested/Response_/ServiceRequestGetError_.gen"
import { Request as WarrantyClientWarrantyServiceRequestPackagesListControllerServiceRequestListControllerNestedRequest, Response as WarrantyClientWarrantyServiceRequestPackagesListControllerServiceRequestListControllerNestedResponse } from "@WarrantyClient/Warranty/ServiceRequest/Packages/List/Controller/ServiceRequestListControllerNested.gen"
import { Request as WarrantyClientWarrantyServiceRequestPackagesListControllerServiceRequestListAdminControllerNestedRequest, Response as WarrantyClientWarrantyServiceRequestPackagesListControllerServiceRequestListAdminControllerNestedResponse } from "@WarrantyClient/Warranty/ServiceRequest/Packages/List/Controller/ServiceRequestListAdminControllerNested.gen"
import { Request as WarrantyClientWarrantyServiceRequestPackagesSubmitControllerServiceRequestPublicSubmitControllerNestedRequest } from "@WarrantyClient/Warranty/ServiceRequest/Packages/Submit/Controller/ServiceRequestPublicSubmitControllerNested.gen"
import { Result as WarrantyClientPrimitivesResultsServiceRequestPublicSubmitControllerNestedResponse_ServiceRequestSubmitError_Result } from "@WarrantyClient/Primitives/Results/ServiceRequestPublicSubmitControllerNested/Response_/ServiceRequestSubmitError_.gen"
import { Request as WarrantyClientWarrantyServiceRequestPackagesSubmitControllerServiceRequestSubmitControllerNestedRequest } from "@WarrantyClient/Warranty/ServiceRequest/Packages/Submit/Controller/ServiceRequestSubmitControllerNested.gen"
import { Result as WarrantyClientPrimitivesResultsServiceRequestSubmitControllerNestedResponse_ServiceRequestSubmitError_Result } from "@WarrantyClient/Primitives/Results/ServiceRequestSubmitControllerNested/Response_/ServiceRequestSubmitError_.gen"
import { Response as WarrantyClientWarrantyCoveragePackagesSuccessSuccessWarrantyCoverageAssetOrphanListGetControllerNestedResponse } from "@WarrantyClient/Warranty/Coverage/Packages/Success/SuccessWarrantyCoverageAssetOrphanListGetControllerNested.gen"
import { Request as WarrantyClientWarrantyCoveragePackagesSuccessSuccessWarrantyCoverageLinkControllerNestedRequest } from "@WarrantyClient/Warranty/Coverage/Packages/Success/SuccessWarrantyCoverageLinkControllerNested.gen"
import { Response as WarrantyClientWarrantyCoveragePackagesSuccessSuccessWarrantyCoverageTransferListGetControllerNestedResponse } from "@WarrantyClient/Warranty/Coverage/Packages/Success/SuccessWarrantyCoverageTransferListGetControllerNested.gen"
import { Request as WarrantyClientWarrantyCoveragePackagesSuccessSuccessWarrantyCoverageUnlinkControllerNestedRequest } from "@WarrantyClient/Warranty/Coverage/Packages/Success/SuccessWarrantyCoverageUnlinkControllerNested.gen"
import { Request as WarrantyClientWarrantyWarrantiesListControllerRequest, Response as WarrantyClientWarrantyWarrantiesListControllerResponse } from "@WarrantyClient/Warranty/WarrantiesList/Controller.gen"

//#endregion Imports


//#region Operations

export function cartDevices(request: WarrantyClientWarrantyWarrantyCartControllerWarrantyCartGetControllerNestedRequest): Promise<WarrantyClientWarrantyWarrantyCartControllerWarrantyCartGetControllerNestedResponse>
{
  return callApi<WarrantyClientWarrantyWarrantyCartControllerWarrantyCartGetControllerNestedRequest, WarrantyClientWarrantyWarrantyCartControllerWarrantyCartGetControllerNestedResponse>("POST", "/Cart/Devices", request);
}

export function cartDevicesByType(request: WarrantyClientWarrantyWarrantyCartControllerRequest): Promise<WarrantyClientWarrantyWarrantyCartControllerResponse>
{
  return callApi<WarrantyClientWarrantyWarrantyCartControllerRequest, WarrantyClientWarrantyWarrantyCartControllerResponse>("POST", "/Cart/DevicesByType", request);
}

export function cartEstimateAdd(request: WarrantyClientWarrantyWarrantyCartPackagesEstimateAddControllerRequest): Promise<WarrantyClientWarrantyWarrantyCartPackagesEstimateAddControllerResponse>
{
  return callApi<WarrantyClientWarrantyWarrantyCartPackagesEstimateAddControllerRequest, WarrantyClientWarrantyWarrantyCartPackagesEstimateAddControllerResponse>("POST", "/Cart/Estimate/Add", request);
}

export function cartEstimateGet(request: WarrantyClientWarrantyWarrantyCartPackagesEstimateGetControllerRequest): Promise<WarrantyClientWarrantyWarrantyCartPackagesEstimateGetControllerResponse>
{
  return callApi<WarrantyClientWarrantyWarrantyCartPackagesEstimateGetControllerRequest, WarrantyClientWarrantyWarrantyCartPackagesEstimateGetControllerResponse>("POST", "/Cart/Estimate/Get", request);
}

export function cartEstimatePdfProposal(request: WarrantyClientWarrantyWarrantyCartPackagesEstimatePdfProposalEstimatePdfProposalControllerNestedRequest): Promise<void>
{
  return callStreamApi<WarrantyClientWarrantyWarrantyCartPackagesEstimatePdfProposalEstimatePdfProposalControllerNestedRequest>("POST", "/Cart/Estimate/Pdf/Proposal", request);
}

export function cartEstimateSelectAll(request: WarrantyClientWarrantyWarrantyCartPackagesEstimateSelectAllControllerRequest): Promise<WarrantyClientWarrantyWarrantyCartPackagesEstimateSelectAllControllerResponse>
{
  return callApi<WarrantyClientWarrantyWarrantyCartPackagesEstimateSelectAllControllerRequest, WarrantyClientWarrantyWarrantyCartPackagesEstimateSelectAllControllerResponse>("POST", "/Cart/Estimate/SelectAll", request);
}

export function cartEstimateUpsert(request: WarrantyClientWarrantyWarrantyCartPackagesEstimateUpsertControllerRequest): Promise<WarrantyClientWarrantyWarrantyCartPackagesEstimateUpsertControllerResponse>
{
  return callApi<WarrantyClientWarrantyWarrantyCartPackagesEstimateUpsertControllerRequest, WarrantyClientWarrantyWarrantyCartPackagesEstimateUpsertControllerResponse>("POST", "/Cart/Estimate/Upsert", request);
}

export function cartSettings(request: WarrantyClientWarrantyWarrantyCartPackagesWarrantyCartSettingsControllerWarrantyCartSettingsControllerNestedRequest): Promise<WarrantyClientWarrantyWarrantyCartPackagesWarrantyCartSettingsControllerWarrantyCartSettingsControllerNestedResponse>
{
  return callApi<WarrantyClientWarrantyWarrantyCartPackagesWarrantyCartSettingsControllerWarrantyCartSettingsControllerNestedRequest, WarrantyClientWarrantyWarrantyCartPackagesWarrantyCartSettingsControllerWarrantyCartSettingsControllerNestedResponse>("POST", "/Cart/Settings", request);
}

export function checkoutCouponValidate(request: WarrantyClientWarrantyWarrantyCheckoutpackagesCouponCodeValidateRequest): Promise<WarrantyClientWarrantyWarrantyCheckoutpackagesCouponCodeValidateResponse>
{
  return callApi<WarrantyClientWarrantyWarrantyCheckoutpackagesCouponCodeValidateRequest, WarrantyClientWarrantyWarrantyCheckoutpackagesCouponCodeValidateResponse>("POST", "/Checkout/Coupon/Validate", request);
}

export function checkoutCoveragePurchase(request: WarrantyClientWarrantyWarrantyCheckoutpackagesCoverageCheckoutRequest): Promise<WarrantyClientWarrantyWarrantyCheckoutpackagesCoverageCheckoutResponse>
{
  return callApi<WarrantyClientWarrantyWarrantyCheckoutpackagesCoverageCheckoutRequest, WarrantyClientWarrantyWarrantyCheckoutpackagesCoverageCheckoutResponse>("POST", "/Checkout/Coverage/Purchase", request);
}

export function checkoutIsCheckoutAllowed(request: WarrantyClientBeastClientCommonControllerControllerEmptyRequest): Promise<WarrantyClientWarrantyWarrantyCheckoutpackagesCoverageAllowCheckoutResponse>
{
  return callApi<WarrantyClientBeastClientCommonControllerControllerEmptyRequest, WarrantyClientWarrantyWarrantyCheckoutpackagesCoverageAllowCheckoutResponse>("POST", "/Checkout/IsCheckoutAllowed", request);
}

export function coverageAvailabilities(request: WarrantyClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<WarrantyClientWarrantyCoveragePackagesSettingsPackagesAvailabilitiesControllerWarrantyCoverageSettingsAvailabilitiesGetControllerNestedResponse>
{
  return callApi<WarrantyClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, WarrantyClientWarrantyCoveragePackagesSettingsPackagesAvailabilitiesControllerWarrantyCoverageSettingsAvailabilitiesGetControllerNestedResponse>("POST", "/Coverage/Availabilities", request);
}

export function coverageList(request: WarrantyClientWarrantyCoveragePackagesListControllerCoverageListControllerNestedRequest): Promise<WarrantyClientWarrantyCoveragePackagesListControllerCoverageListControllerNestedResponse>
{
  return callApi<WarrantyClientWarrantyCoveragePackagesListControllerCoverageListControllerNestedRequest, WarrantyClientWarrantyCoveragePackagesListControllerCoverageListControllerNestedResponse>("POST", "/Coverage/List", request);
}

export function coverageListSpreadsheet(request: WarrantyClientWarrantyCoveragePackagesListControllerCoverageListSpreadsheetControllerNestedRequest): Promise<void>
{
  return callStreamApi<WarrantyClientWarrantyCoveragePackagesListControllerCoverageListSpreadsheetControllerNestedRequest>("POST", "/Coverage/List/Spreadsheet", request);
}

export function coverageTransfer(request: WarrantyClientWarrantyCoveragePackagesTransferControllerCoverageTransferControllerNestedRequest): Promise<WarrantyClientWarrantyCoveragePackagesTransferControllerCoverageTransferControllerNestedResponse>
{
  return callApi<WarrantyClientWarrantyCoveragePackagesTransferControllerCoverageTransferControllerNestedRequest, WarrantyClientWarrantyCoveragePackagesTransferControllerCoverageTransferControllerNestedResponse>("POST", "/Coverage/Transfer", request);
}

export function coverageTransferDisposalOrderCertificatePdfGet(request: WarrantyClientWarrantyCoveragePackagesTransferControllerCoverageTransferDisposalOrderCertificatePdfGetControllerNestedRequest): Promise<void>
{
  return callStreamApi<WarrantyClientWarrantyCoveragePackagesTransferControllerCoverageTransferDisposalOrderCertificatePdfGetControllerNestedRequest>("POST", "/Coverage/Transfer/Disposal/Order/Certificate/Pdf/Get", request);
}

export function coverageTransferDisposalOrderInfoGet(request: WarrantyClientWarrantyCoveragePackagesTransferPackagesDisposalOrderCoverageTransferDisposalOrderInfoGetControllerNestedRequest): Promise<WarrantyClientWarrantyCoveragePackagesTransferPackagesDisposalOrderCoverageTransferDisposalOrderInfoGetControllerNestedResponse>
{
  return callApi<WarrantyClientWarrantyCoveragePackagesTransferPackagesDisposalOrderCoverageTransferDisposalOrderInfoGetControllerNestedRequest, WarrantyClientWarrantyCoveragePackagesTransferPackagesDisposalOrderCoverageTransferDisposalOrderInfoGetControllerNestedResponse>("POST", "/Coverage/Transfer/Disposal/Order/Info/Get", request);
}

export function listSettings(request: WarrantyClientCommonControllerControllerEmptyRequest): Promise<WarrantyClientWarrantyWarrantiesListControllerpackagesWarrantyListSettingsControllerWarrantyListSettingsControllerNestedResponse>
{
  return callApi<WarrantyClientCommonControllerControllerEmptyRequest, WarrantyClientWarrantyWarrantiesListControllerpackagesWarrantyListSettingsControllerWarrantyListSettingsControllerNestedResponse>("POST", "/List/Settings", request);
}

export function serviceRequestAvailability(request: WarrantyClientWarrantyServiceRequestPackagesAvailabilityControllerServiceRequestAvailabilityControllerNestedRequest): Promise<WarrantyClientPrimitivesResultsServiceRequestAvailabilityControllerNestedResponse_ServiceRequestAvailabilityError_Result>
{
  return callApi<WarrantyClientWarrantyServiceRequestPackagesAvailabilityControllerServiceRequestAvailabilityControllerNestedRequest, WarrantyClientPrimitivesResultsServiceRequestAvailabilityControllerNestedResponse_ServiceRequestAvailabilityError_Result>("POST", "/ServiceRequest/Availability", request);
}

export function serviceRequestGet(request: WarrantyClientWarrantyServiceRequestPackagesGetControllerServiceRequestGetControllerNestedRequest): Promise<WarrantyClientPrimitivesResultsServiceRequestGetControllerNestedResponse_ServiceRequestGetError_Result>
{
  return callApi<WarrantyClientWarrantyServiceRequestPackagesGetControllerServiceRequestGetControllerNestedRequest, WarrantyClientPrimitivesResultsServiceRequestGetControllerNestedResponse_ServiceRequestGetError_Result>("POST", "/ServiceRequest/Get", request);
}

export function serviceRequestList(request: WarrantyClientWarrantyServiceRequestPackagesListControllerServiceRequestListControllerNestedRequest): Promise<WarrantyClientWarrantyServiceRequestPackagesListControllerServiceRequestListControllerNestedResponse>
{
  return callApi<WarrantyClientWarrantyServiceRequestPackagesListControllerServiceRequestListControllerNestedRequest, WarrantyClientWarrantyServiceRequestPackagesListControllerServiceRequestListControllerNestedResponse>("POST", "/ServiceRequest/List", request);
}

export function serviceRequestSubmit(request: WarrantyClientWarrantyServiceRequestPackagesSubmitControllerServiceRequestSubmitControllerNestedRequest): Promise<WarrantyClientPrimitivesResultsServiceRequestSubmitControllerNestedResponse_ServiceRequestSubmitError_Result>
{
  return callApi<WarrantyClientWarrantyServiceRequestPackagesSubmitControllerServiceRequestSubmitControllerNestedRequest, WarrantyClientPrimitivesResultsServiceRequestSubmitControllerNestedResponse_ServiceRequestSubmitError_Result>("POST", "/ServiceRequest/Submit", request);
}

export function successWarrantyCoverageAssetOrphanListGet(request: WarrantyClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<WarrantyClientWarrantyCoveragePackagesSuccessSuccessWarrantyCoverageAssetOrphanListGetControllerNestedResponse>
{
  return callApi<WarrantyClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, WarrantyClientWarrantyCoveragePackagesSuccessSuccessWarrantyCoverageAssetOrphanListGetControllerNestedResponse>("POST", "/Success/Warranty/Coverage/Asset/Orphan/List/Get", request);
}

export function successWarrantyCoverageLink(request: WarrantyClientWarrantyCoveragePackagesSuccessSuccessWarrantyCoverageLinkControllerNestedRequest): Promise<WarrantyClientCommonControllerControllerEmptyResponse>
{
  return callApi<WarrantyClientWarrantyCoveragePackagesSuccessSuccessWarrantyCoverageLinkControllerNestedRequest, WarrantyClientCommonControllerControllerEmptyResponse>("POST", "/Success/Warranty/Coverage/Link", request);
}

export function successWarrantyCoverageTransferListGet(request: WarrantyClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<WarrantyClientWarrantyCoveragePackagesSuccessSuccessWarrantyCoverageTransferListGetControllerNestedResponse>
{
  return callApi<WarrantyClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, WarrantyClientWarrantyCoveragePackagesSuccessSuccessWarrantyCoverageTransferListGetControllerNestedResponse>("POST", "/Success/Warranty/Coverage/Transfer/List/Get", request);
}

export function successWarrantyCoverageUnlink(request: WarrantyClientWarrantyCoveragePackagesSuccessSuccessWarrantyCoverageUnlinkControllerNestedRequest): Promise<WarrantyClientCommonControllerControllerEmptyResponse>
{
  return callApi<WarrantyClientWarrantyCoveragePackagesSuccessSuccessWarrantyCoverageUnlinkControllerNestedRequest, WarrantyClientCommonControllerControllerEmptyResponse>("POST", "/Success/Warranty/Coverage/Unlink", request);
}

export function warrantiesList(request: WarrantyClientWarrantyWarrantiesListControllerRequest): Promise<WarrantyClientWarrantyWarrantiesListControllerResponse>
{
  return callApi<WarrantyClientWarrantyWarrantiesListControllerRequest, WarrantyClientWarrantyWarrantiesListControllerResponse>("POST", "/Warranties/List", request);
}


//#endregion Operations


