import { HardwareDetails } from '@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Details/Model.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import ListItem from '~/legacy-ui/packages/list/packages/list-item/ListItem';
import Button from '~/neo-ui/packages/button/Button';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type HardwareInfoHeaderProps = {
  resultantHardwareAsset: HardwareDetails;
  hardwareEditLink: string;
  permissionToEditAsset: boolean;
};

const HardwareInfoHeader: React.FunctionComponent<HardwareInfoHeaderProps> = ({
  resultantHardwareAsset,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  hardwareEditLink,
  permissionToEditAsset,
}) => {
  const hardwareEditUrl: AnchorLocation = {
    href: hardwareEditLink.replace('__ASSET_OBFUSCATED_ID__', resultantHardwareAsset.obfuscatedId),
  };
  return (
    <div
      css={css`
        margin-bottom: 1.875rem;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <div
          css={css`
            display: flex;
          `}
        >
          <Icon
            css={css`
              flex-shrink: 0;
            `}
            icon={resultantHardwareAsset.hardwareType.icon as IconType}
            sizePx={36}
          />
          <Header
            // TODO colors standardization WM-232
            css={css`
              margin-left: 0.9375rem;
              color: #2c2d33;
            `}
            size={2}
          >
            {resultantHardwareAsset.manufacturerName && (
              <span
                css={css`
                  color: ${colorToCode('dark-300')};
                `}
              >
                {resultantHardwareAsset.manufacturerName}&nbsp;
              </span>
            )}
            {resultantHardwareAsset.modelDisplay && <span>{resultantHardwareAsset.modelDisplay}</span>}
            {!resultantHardwareAsset.manufacturerName && !resultantHardwareAsset.modelDisplay && (
              <span>{resultantHardwareAsset.hardwareType.label}</span>
            )}
          </Header>
        </div>
        <div
          css={css`
            display: flex;
          `}
        >
          <Button
            size={'md'}
            css={css`
              margin-right: 0.625rem;
            `}
            anchor={hardwareEditUrl}
            {...(permissionToEditAsset && { iconRight: 'Edit' })}
          >
            {permissionToEditAsset ? (
              'Edit Info'
            ) : (
              <div>
                <i
                  className="fa-star wm-star-yellow"
                  css={css`
                    margin-right: 0.3125rem;
                  `}
                />
                <span className="wm-text-gray-blue">Edit with Pro Edition</span>
              </div>
            )}
          </Button>
        </div>
      </div>
      <div
        css={css`
          display: inline-flex;
          margin-top: 1.25rem;

          > *:not(:last-child) {
            margin-right: 1.875rem;
          }
        `}
      >
        <ListItem
          title={'User'}
          description={resultantHardwareAsset.lastLoginUser ? resultantHardwareAsset.lastLoginUser : 'Unknown'}
          descriptionMuted={!resultantHardwareAsset.lastLoginUser}
        />
        <ListItem
          title={'Serial'}
          description={resultantHardwareAsset.serialNumber}
          descriptionMuted={!resultantHardwareAsset.serialNumber}
        />
        <ListItem
          title={'Location'}
          description={resultantHardwareAsset.locationName ? resultantHardwareAsset.locationName : 'Unknown'}
          descriptionMuted={!resultantHardwareAsset.locationName}
        />
        <ListItem
          title={'Age'}
          description={resultantHardwareAsset.assetAgeFormatted ? resultantHardwareAsset.assetAgeFormatted : 'Unknown'}
          descriptionMuted={!resultantHardwareAsset.assetAgeFormatted}
        />
      </div>
    </div>
  );
};

export default HardwareInfoHeader;
