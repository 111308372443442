import { useContext } from 'react';
import ContractStatusSyncContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/context/ContractStatusSyncContext';

const useContractStatusSyncContext = () => {
  const useContractStatusSyncContext = useContext(ContractStatusSyncContext);
  if (!useContractStatusSyncContext) {
    throw new Error('Tried to use ContractStatusSyncContext outside of an useContractStatusSyncContextProvider');
  }

  return useContractStatusSyncContext;
};

export default useContractStatusSyncContext;
