import SearchInputText from '~/neo-ui/packages/input/packages/search-input-text/SearchInputText';
import * as React from 'react';
import { IntegrationVendorDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Controller/IntegrationPage.gen';
import { css } from '@emotion/react';
import { simpleFuzzySearch } from '~/neo-ui/packages/select/packages/multi-select-static/packages/multi-select-static-option-search-box/simpleFuzzySearch';

export type SearchBarProps = {
  setFilterStates: React.Dispatch<React.SetStateAction<Map<string, (integrationVendor: IntegrationVendorDto) => boolean>>>;
};
export const SearchBar: React.FunctionComponent<SearchBarProps> = ({ setFilterStates }) => {
  const [searchText, setSearchText] = React.useState('');

  return (
    <SearchInputText
      css={css`
        margin-bottom: 0.5rem;
      `}
      onTextChange={e => {
        // simpleFuzzySearch is pretty hacky - in the future we could replace it with something else
        const fuzzySearchPredicate = simpleFuzzySearch(e.target.value);
        setSearchText(e.target.value);
        setFilterStates(oldFilterStates => {
          const newFilterStates = new Map(oldFilterStates);
          if (e.target.value === '') {
            newFilterStates.delete('search');
            return newFilterStates;
          }
          newFilterStates.set('search', integrationVendor =>
            [integrationVendor.vendor, integrationVendor.brandName, ...integrationVendor.brandNamesFormer].some(fuzzySearchPredicate),
          );
          return newFilterStates;
        });
      }}
      value={searchText}
      onClear={() => {
        setSearchText('');
        setFilterStates(oldFilterStates => {
          const newFilterStates = new Map(oldFilterStates);
          newFilterStates.delete('search');
          return newFilterStates;
        });
      }}
    />
  );
};
