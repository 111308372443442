import useInitiativeMutationContext from '~/wm/packages/strategy/packages/initiative/context/hooks/useInitiativeMutationContext';
import { UseScorecardMutationOptions } from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardMutation';
import useMutationInternal, { UseMutationInternal, UseMutationInternalApiCall } from '~/extensions/packages/hooks/useMutationInternal';

export type UseInitiativeMutationOptions = UseScorecardMutationOptions & {
  shouldLoadWarrantyCosts?: boolean;
};

const useInitiativeMutation = <TArg = Record<string, unknown>>(
  apiCall: UseMutationInternalApiCall<TArg>,
  options?: UseInitiativeMutationOptions,
): UseMutationInternal<TArg> => {
  const { triggerInitiativeReload } = useInitiativeMutationContext();

  return useMutationInternal(
    apiCall,
    isRequestActive => triggerInitiativeReload(isRequestActive, options?.shouldLoadWarrantyCosts ?? false),
    options,
  );
};

export default useInitiativeMutation;
