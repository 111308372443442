import Window from '~/neo-ui/packages/window/Window';
import Button from '~/neo-ui/packages/button/Button';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import AutoEmailReportDeleteButton from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-delete/AutoEmailReportDeleteButton';
import React from 'react';
import AutoEmailReportFormData, {
  autoEmailReportTitleFieldKey,
} from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/types/AutoEmailReportFormData';
import AutoEmailReportFormInputs from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/AutoEmailReportFormInputs';

export type AutoEmailReportEditWindowProps = {
  autoEmailReportId: string;
  isOpen: boolean;
  onDismiss: () => void;
};

const AutoEmailReportEditWindow = ({ autoEmailReportId, isOpen, onDismiss }: AutoEmailReportEditWindowProps) => {
  const { submitForm, isSubmitting, getFormInput } = useFormContext<AutoEmailReportFormData>();
  const title = getFormInput<string>(autoEmailReportTitleFieldKey).value;

  return (
    <Window
      title={'Edit Scheduled Report'}
      isOpen={isOpen}
      onDismiss={onDismiss}
      footerProps={{
        leftControls: [
          {
            expanded: (
              <AutoEmailReportDeleteButton
                autoEmailReportId={autoEmailReportId}
                reportTitle={title}
                onDelete={onDismiss}
              />
            ),
          },
        ],
        rightControls: [
          {
            expanded: (
              <Button
                iconLeft={'Done'}
                theme={'primary'}
                loading={isSubmitting}
                onClick={submitForm}
              >
                Save
              </Button>
            ),
          },
        ],
      }}
    >
      <AutoEmailReportFormInputs />
    </Window>
  );
};

export default AutoEmailReportEditWindow;
