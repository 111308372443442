import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Size from '~/neo-ui/model/Size';
import Button from '~/neo-ui/packages/button/Button';
import Theme from '~/neo-ui/packages/color/Theme';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Testable from '~/neo-ui/packages/testable/Testable';
import Tooltip, { TooltipProps } from '~/neo-ui/packages/tooltip/Tooltip';
import ButtonGroup from '~/neo-ui/packages/button/packages/button-group/ButtonGroup';

export type ButtonSelectOption<T extends string = string> = {
  label?: string;
  value: T;
  icon?: IconType;
  selectedTheme?: Theme;
  disabled?: boolean;
  tooltip?: Omit<TooltipProps, 'children'>;
};

export type ButtonSelectProps<T extends string = string> = {
  options: ButtonSelectOption<T>[];
  selectedOptionValue: T | undefined;
  onOptionSelected: (option: ButtonSelectOption<T>) => void;
  size?: Exclude<Size, 'xl'>;
  shouldExpand?: boolean;
  preventOnClickPropagation?: boolean;
} & Styleable;

export type ButtonSelectGroupProps<T extends string = string> = {
  option: ButtonSelectOption<T>;
  onOptionSelected: (option: ButtonSelectOption<T>) => void;
} & Omit<ButtonSelectProps, 'options' | 'className' | 'onOptionSelected'>;

const ButtonSelectGroup = <T extends string = string>({
  option,
  selectedOptionValue,
  onOptionSelected,
  size = 'md',
  shouldExpand = false,
  preventOnClickPropagation = false,
}: ButtonSelectGroupProps<T>) => (
  <Button
    css={css`
      ${shouldExpand ? 'flex: 1; text-align: center;' : ''}
    `}
    size={size}
    theme={selectedOptionValue === option.value ? option.selectedTheme ?? 'primary' : undefined}
    onClick={() => onOptionSelected(option)}
    {...(typeof option.disabled !== 'undefined' && {
      disabled: option.disabled,
    })}
    preventOnClickPropagation={preventOnClickPropagation}
    iconLeft={option.icon}
    iconColorOverride={selectedOptionValue === option.value ? 'light-000' : 'dark-400'}
  >
    {option.label && option.label}
  </Button>
);

const SelectGroup = <T extends string = string>({
  options,
  selectedOptionValue,
  onOptionSelected,
  size = 'md',
  shouldExpand = false,
  preventOnClickPropagation = false,
  className,
}: ButtonSelectProps<T>) => {
  return (
    <Testable testId={'select-group'}>
      <ButtonGroup
        className={className}
        css={css`
          ${shouldExpand ? 'display: flex;' : ''}
        `}
      >
        {options.map(option => (
          <Testable
            key={option.value}
            testId={`select-group-button-${option.value}`}
          >
            {typeof option.tooltip !== 'undefined' ? (
              <Tooltip
                backgroundColor={option.tooltip.backgroundColor}
                content={option.tooltip.content}
                placement={option.tooltip.placement}
              >
                <ButtonSelectGroup
                  option={option}
                  onOptionSelected={onOptionSelected}
                  selectedOptionValue={selectedOptionValue}
                  preventOnClickPropagation={preventOnClickPropagation}
                  size={size}
                  shouldExpand={shouldExpand}
                />
              </Tooltip>
            ) : (
              <ButtonSelectGroup
                option={option}
                onOptionSelected={onOptionSelected}
                selectedOptionValue={selectedOptionValue}
                preventOnClickPropagation={preventOnClickPropagation}
                size={size}
                shouldExpand={shouldExpand}
              />
            )}
          </Testable>
        ))}
      </ButtonGroup>
    </Testable>
  );
};

export default SelectGroup;
