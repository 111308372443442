import * as React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import { EngagementNoteDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Dto/Model.gen';
import engagementNoteFormValidationSchema from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/validation/engagementNoteFormValidationSchema';

export type EngagementNoteUpdatePayload = {
  engagementNoteId: string;
  title: string | undefined;
  description: string;
};

export type EngagementNoteFormProps = {
  note: EngagementNoteDto;
  onEdit: (payload: EngagementNoteUpdatePayload) => void;
};

export type EngagementNoteFormData = {
  title: string | undefined;
  body: string;
};

const EngagementNoteEditForm = ({ note, children, onEdit }: React.PropsWithChildren<EngagementNoteFormProps>) => {
  const onSubmit = React.useCallback(
    async (data: EngagementNoteFormData) => {
      onEdit({
        engagementNoteId: note.engagementNoteId,
        title: data.title,
        description: data.body,
      });
    },
    [note.engagementNoteId, onEdit],
  );

  const defaultFormData = React.useMemo<EngagementNoteFormData>(
    () => ({
      title: note.title,
      body: note.description,
    }),
    [note.description, note.title],
  );

  return (
    <Form
      submitMethod={'manual'}
      hideSubmissionButton={true}
      hideSubmitStatusIndicator={true}
      defaultFormData={defaultFormData}
      validationSchema={engagementNoteFormValidationSchema}
      onSubmit={onSubmit}
      disableOverwriteDefaultFormDataOnSave={true}
    >
      {children}
    </Form>
  );
};

export default EngagementNoteEditForm;
