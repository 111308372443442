import { useContext } from 'react';
import InitiativeDashboardContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/initiative-dashboard/context/InitiativeDashboardContext';

const useInitiativeDashboardContext = () => {
  const intiativeDashboardContext = useContext(InitiativeDashboardContext);
  if (!intiativeDashboardContext) {
    throw new Error('Tried to use InitiativeDashboardContext outside of an InitiativeDashboardContext');
  }

  return intiativeDashboardContext;
};

export default useInitiativeDashboardContext;
