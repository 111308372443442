import WarrantyCartOpportunitiesFormData from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-opportunity/packages/warranty-cart-opportunity-form/WarrantyCartOpportunitiesFormData';
import { createContext } from 'react';

export type OpportunitiesMutationContextData = {
  triggerUpsert: (arg: WarrantyCartOpportunitiesFormData) => Promise<void>;
};

const OpportunitiesMutationContext = createContext<OpportunitiesMutationContextData>({
  triggerUpsert: async (_arg: WarrantyCartOpportunitiesFormData) => {},
});

export default OpportunitiesMutationContext;
