import { initiativeAssetMoveBulk } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useInitiativeMutation, {
  UseInitiativeMutationOptions,
} from '~/wm/packages/strategy/packages/initiative/hooks/useInitiativeMutation';
import { InitiativeAssetMovePayloadDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/InitiativeAsset/Dto/Model.gen';

export type UseInitiativeAssetMoveBulk = {
  moveAssets: (moveBulkPayload: InitiativeAssetMovePayloadDto) => void;
  isMovingAssets: boolean;
};

const useInitiativeAssetMoveBulk = (options?: UseInitiativeMutationOptions): UseInitiativeAssetMoveBulk => {
  const [moveAssets, isMovingAssets] = useInitiativeMutation(
    (initiativeAssetMovePayload: InitiativeAssetMovePayloadDto) =>
      initiativeAssetMoveBulk({
        initiativeAssetMovePayload,
      }),
    options,
  );

  return {
    moveAssets,
    isMovingAssets,
  };
};

export default useInitiativeAssetMoveBulk;
