import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import {
  BudgetLineItemFormData,
  InitiativeSaveFormData,
} from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import { CostTypeEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Model/BudgetLineItemNested.gen';
import { formatCurrencyByCurrencyInfo } from '~/extensions/packages/currency/formatCurrency';
import useAccount from '~/wm/hooks/useAccount';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import React from 'react';
import Divider from '~/neo-ui/packages/divider/Divider';
import Box from '~/neo-ui/packages/box/Box';
import FeesViewSection from '~/wm/packages/strategy/packages/initiative/packages/initiative-view/packages/initiative-view-fees-section/packages/fees-view-section/FeesViewSection';

const InitiativeViewFeesOneTime = () => {
  const { currencyCode, subunitRatio } = useAccount();
  const { getFormInput, formData } = useFormContext<InitiativeSaveFormData>();

  const budgetLineItems = getFormInput<BudgetLineItemFormData[]>(formData => formData.budgetLineItems).value;

  const totalCost = budgetLineItems.reduce(
    (sum, lineItem) =>
      sum +
      (isNaN(lineItem.costUnits)
        ? 0
        : Number(lineItem.costUnits) *
          // Multiply by the number of assets if the cost type is per asset
          (lineItem.costType === CostTypeEnum.PerAsset ? formData.assetIds.length : 1)),
    0,
  );

  const formattedTotalCost = formatCurrencyByCurrencyInfo(totalCost * subunitRatio, {
    code: currencyCode,
    subunitsPerUnit: subunitRatio,
  });

  return (
    <Box
      borderRadius={'radius300'}
      background={'theme'}
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 0.5rem 1rem;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 1rem;
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 0.5rem;
          `}
        >
          <Header
            size={4}
            weight={'bold'}
          >
            One-time fees
          </Header>
        </div>
        <FeesViewSection
          lineItems={formData.budgetLineItems.map(lineItem => ({ costSubunits: lineItem.costUnits, label: lineItem.label }))}
        />
      </div>
      <div
        css={css`
          margin-top: auto;
          margin-bottom: 0.5rem;
        `}
      >
        <Divider />
        <div
          css={css`
            display: flex;
            flex-direction: row;
            margin-top: 1rem;
            justify-content: flex-end;
            align-self: flex-end;
          `}
        >
          <Header
            size={5}
            weight={'bold'}
          >
            Total one-time fees
          </Header>
          <Header
            size={5}
            weight={'bold'}
            css={css`
              margin-left: auto;
            `}
          >
            {formattedTotalCost}
          </Header>
        </div>
      </div>
    </Box>
  );
};

export default InitiativeViewFeesOneTime;
