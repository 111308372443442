import {
  Request as SelectAllControllerRequest,
  Response as SelectAllControllerResponse,
} from '@WarrantyClient/Warranty/WarrantyCart/Packages/Estimate/SelectAll/Controller.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import { useCallback } from 'react';
import { cartEstimateSelectAll } from '@WarrantyClient/WarrantyClientMsp.gen';

export type UseWarrantyCartSelectAllAssets = () => {
  selectAllAssets: (request: SelectAllControllerRequest) => Promise<SelectAllControllerResponse | undefined>;
};

const useWarrantyCartSelectAllAssets: UseWarrantyCartSelectAllAssets = () => {
  const { callApi } = useApi();
  const selectAll = useCallback(
    (request: SelectAllControllerRequest): Promise<SelectAllControllerResponse | undefined> =>
      callApi(() => cartEstimateSelectAll(request)),
    [callApi],
  );
  return { selectAllAssets: selectAll };
};

export default useWarrantyCartSelectAllAssets;
