import * as React from 'react';
import { ClassNames, SerializedStyles } from '@emotion/react';
import { neoColorToCode } from '~/neo-ui/packages/color/NeoColor';
import Testable from '~/neo-ui/packages/testable/Testable';
import ModalOverlay from '~/neo-ui/packages/modal/ModalOverlay';
import useModalTriggerState from '~/neo-ui/packages/modal/hooks/useModalTriggerState';

export type ModalInternalProps = {
  /**
   * Optional header, places contents into a div
   */
  header?: React.ReactNode;
  /**
   * Optional footer, places contents into a styled div
   */
  footer?: React.ReactNode;
  onDismiss?: () => void;
  isOpen: boolean;
  /**
   * Determines whether the modal can be dismissed by
   * pressing the screen outside its bounds
   */
  isStatic?: boolean;
  maxWidthRem?: number;
  disableHorizontalRule?: boolean;
  containerCss?: SerializedStyles;
};

/**
 * **Only for internal use of components**
 *
 * ModalInternal is a pop-out component that allows the user to confirm an action that may be destructive
 * or require special attention
 *
 * A Modal should be small height and provide Cancel/Proceed type buttons in the footer.
 * If scrolling is required the entire modal * will scroll on the viewport
 */
const ModalInternal: React.FunctionComponent<React.PropsWithChildren<ModalInternalProps>> = ({
  header,
  children,
  footer,
  isOpen,
  onDismiss = () => {},
  isStatic = false,
  maxWidthRem = 31.25,
  disableHorizontalRule = false,
  containerCss,
}) => {
  const { overlayTriggerState } = useModalTriggerState({
    isOpen,
    onDismiss,
  });

  return (
    <ClassNames>
      {({ css }) => (
        <ModalOverlay
          overlayTriggerState={overlayTriggerState}
          isDismissable={!isStatic}
          maxWidthRem={maxWidthRem}
          wrapClassName={css`
            @media (min-width: 526px) and (max-width: 620px) {
              max-width: 97%;
            }
          `}
          contentClassName={css`
            padding: 1.25rem;
            gap: 0.625rem;
            display: flex;
            flex-direction: column;
            ${containerCss}
          `}
        >
          {header && (
            <Testable testId={'modal-internal-header'}>
              <div>{header}</div>
            </Testable>
          )}
          <div>{children}</div>
          {footer && (
            <Testable testId={'modal-internal-footer'}>
              <div
                css={css`
                  padding-top: 0.9367rem;
                  ${!disableHorizontalRule && `border-top: 0.0625rem solid ${neoColorToCode('dark-lighter', 0.15)};`}
                `}
              >
                {footer}
              </div>
            </Testable>
          )}
        </ModalOverlay>
      )}
    </ClassNames>
  );
};

export default ModalInternal;
