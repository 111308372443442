import React from 'react';
import { Label } from 'recharts';
import { formatRelativeOrDateTimeFriendly } from '~/extensions/packages/date/formatDate';
import { PieGraphDataPoint } from '~/neo-ui/packages/chart/packages/pie/PieGraph';
import Tachometer from '~/neo-ui/packages/chart/packages/pie/packages/tachometer/Tachometer';
import { css } from '@emotion/react';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type DmiScoreProps = {
  themeColor: Color;
  displayDmiScore: string;
  dmiScore: number;
  maxDmiScore: number;
  size: number;
  bandLabel: string;
  lastUpdated: Date | undefined;
  showLastUpdated?: boolean;
};

const DmiScore: React.FunctionComponent<DmiScoreProps> = ({
  displayDmiScore,
  dmiScore,
  maxDmiScore,
  themeColor,
  size,
  bandLabel,
  lastUpdated,
  showLastUpdated = true,
}) => {
  const dataPoints: PieGraphDataPoint[] = [];
  dataPoints.push({
    value: dmiScore,
    fill: colorToCode(themeColor),
  });
  dataPoints.push({
    value: maxDmiScore - dmiScore,
    fill: colorToCode('secondary-800'),
  });
  const width = size;
  const height = size;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <Tachometer
        dataPoints={dataPoints}
        width={width}
        height={height}
      >
        <Label
          content={() => (
            <image
              href={'/assets/logo/dmi-wordmark-light.svg'}
              width={width / 4}
              height={height / 10}
              x={0.375 * width}
              y={height / 4}
            />
          )}
        />
        <Label
          value={displayDmiScore}
          position="center"
          fontSize={`${width * 0.015}rem`}
          fill={colorToCode(themeColor)}
          fontWeight={900}
        />
        <Label
          value={bandLabel}
          dy={width * 0.64}
          fontSize={`${width * 0.004375}rem`}
          fill={colorToCode(themeColor)}
          fontWeight={'bold'}
        />
        {showLastUpdated && (
          <Label
            value={lastUpdated === undefined ? 'Working on it...' : `Updated ${formatRelativeOrDateTimeFriendly(lastUpdated)}`}
            dy={width * 0.75}
            fontSize={`${width * 0.003125}rem`}
            fill={colorToCode('dark-900-32')}
          />
        )}
      </Tachometer>
    </div>
  );
};

export default DmiScore;
