import { useState, useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { AddressBusinessDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Dto/Model.gen';
import { disposalAddressValidate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { ApiServerErrorResponseDto } from '~/wm/packages/api/packages/api-error/model/ApiServerErrorResponseDto';
import { Discriminant } from '@AssetManagementClient/Primitives/Results/DisposalAddressValidateControllerNested/Response_/ApiError_/ResultNested.gen';

export type UseDisposalAddressValidate = () => {
  disposalAddressValidate: (disposalAddressValidatePayload: AddressBusinessDto) => Promise<AddressBusinessDto | undefined>;
  isValidatingAddress: boolean;
};

const useDisposalAddressValidate: UseDisposalAddressValidate = () => {
  const [isLoading, setLoading] = useState(false);
  const { callApi } = useApi();

  const validateAddress = useCallback(
    async (disposalAddressValidatePayload: AddressBusinessDto): Promise<AddressBusinessDto | undefined> => {
      setLoading(true);

      const updatedAddressBusinessDto = await (async (): Promise<AddressBusinessDto | undefined> => {
        const response = await callApi(() =>
          disposalAddressValidate({
            addressBusiness: disposalAddressValidatePayload,
          }),
        );

        if (!response) {
          return undefined;
        }

        if (response.case === Discriminant.Error) {
          const error: ApiServerErrorResponseDto = {
            GlobalMessage: response.value.messagePublic,
            Errors: response.value.fieldErrors.reduce<{
              [index: string]: string[];
            }>(
              (errors, fieldError) => ({
                ...errors,
                [fieldError.fieldKey]: [fieldError.messagePublic],
              }),
              {},
            ),
            // The abstraction is not layered properly that's why
            // we have to specify 400 here - WM-3861
            Status: 400,
          };

          setLoading(false);
          throw error;
        }

        if (response.case === Discriminant.Ok) {
          return response.value.addressBusiness;
        }

        return undefined;
      })();

      setLoading(false);

      return updatedAddressBusinessDto;
    },
    [callApi],
  );

  return {
    disposalAddressValidate: validateAddress,
    isValidatingAddress: isLoading,
  };
};

export default useDisposalAddressValidate;
