import React from 'react';
import PieGraph, { PieGraphDataPoint } from '~/neo-ui/packages/chart/packages/pie/PieGraph';

export type TachometerProps = {
  dataPoints: PieGraphDataPoint[];
  width: number;
  height: number;
  innerRadius?: number;
  outerRadius?: number;
  shouldAnimate?: boolean;
};

const Tachometer: React.FunctionComponent<React.PropsWithChildren<TachometerProps>> = ({
  dataPoints,
  width,
  height,
  children,
  innerRadius = 80,
  shouldAnimate = true,
}) => (
  <PieGraph
    dataPoints={dataPoints}
    width={width}
    height={height}
    startAngle={200}
    endAngle={-20}
    innerRadius={`${innerRadius}%`}
    outerRadius="100%"
    blendStroke={true}
    shouldAnimate={shouldAnimate}
  >
    {children}
  </PieGraph>
);

export default Tachometer;
