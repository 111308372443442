import { TableState } from '~/neo-ui/packages/table/packages/filter-table/FilterTable';
import { resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { camelToPascal } from '~/extensions/packages/casing/camelPascalConversion';
import { FieldKey } from '@AssetManagementClient/BeastClient/Primitives/Query.gen';
import { QueryPayloadDto } from '@AssetManagementClient/BeastClient/Primitives/Query/Request/Dto.gen';
import { OrderEnum } from '@AssetManagementClient/BeastClient/Primitives/Query/Sorting/OrderNested.gen';

export type FilterKey = {
  include: boolean;
  fieldKey: FieldKey;
  values: string[];
};

/**
 * Builds the portion of the ApiQueryKey the frontend is responsible for
 */
const buildFrontendApiQueryKey = <T>({ filters, pagination, sort }: TableState<T>): QueryPayloadDto => ({
  filters: filters
    ? [...filters.selectedFilters.entries()].map(
        ([filterValue, selectedOptionValues]): FilterKey => ({
          fieldKey: { value: filterValue },
          include: true,
          values: [...selectedOptionValues],
        }),
      )
    : [],
  pagination: pagination
    ? {
        pageNumber: pagination.pageNumber,
        size: pagination.perPageSize,
        pageId: '',
      }
    : {
        // empty pagination payload
        pageNumber: 0,
        size: 50,
        pageId: '',
      },
  sort: sort
    ? [
        {
          field: {
            value: resolveFieldKey(sort.key)
              .split('.')
              .map(
                // Field keys are PascalCase in the backend
                camelToPascal,
              )
              .join('.'),
          },
          ordering: ((): OrderEnum => {
            switch (sort.order) {
              case 'ascending':
                return OrderEnum.Ascending;
              case 'descending':
                return OrderEnum.Descending;
            }
          })(),
        },
      ]
    : [],
  searchPayload: filters?.searchQuery ? { searchQuery: filters.searchQuery, fields: [] } : undefined,
});
export default buildFrontendApiQueryKey;
