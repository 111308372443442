import Icon from '~/neo-ui/packages/icon/Icon';
import React from 'react';
import { css } from '@emotion/react';
import { Styleable } from '~/neo-ui/model/capacity';
import Color from '~/neo-ui/packages/color/Color.gen';

type FilterHeaderMenuDropdownIconProps = {
  color: Color;
} & Styleable;

const FilterHeaderMenuDropdownIcon: React.FunctionComponent<FilterHeaderMenuDropdownIconProps> = ({ color }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      padding-inline: 3px;
    `}
  >
    <Icon
      icon={'Dropdown'}
      color={color}
    />
    &nbsp;
  </div>
);

export default FilterHeaderMenuDropdownIcon;
