import { useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { cartSettings } from '@WarrantyClient/WarrantyClientMsp.gen';
import { WarrantyCartSettingsDto as WarrantyClientBeastClientBeastSettingsDtoPackagesWarrantyWarrantyCartSettingsDto } from '@WarrantyClient/BeastClient/Beast/Settings/Dto/Packages/Warranty.gen';

export type UseSettingsGet = (organizationId: string) => {
  settingsLoading: boolean;
  settings?: WarrantyClientBeastClientBeastSettingsDtoPackagesWarrantyWarrantyCartSettingsDto;
};

const useSettingsGet: UseSettingsGet = (organizationId: string) => {
  const [settings, setSettings] = useState<WarrantyClientBeastClientBeastSettingsDtoPackagesWarrantyWarrantyCartSettingsDto>();
  const [settingsLoading, setSettingsLoading] = useState<boolean>(true);
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      setSettingsLoading(true);
      const response = await callApi(() => cartSettings({ organizationId }));
      if (!response) {
        return;
      }
      setSettings(response.settings);
      setSettingsLoading(false);
    })();
  }, [callApi, organizationId]);

  return {
    settings,
    settingsLoading,
  };
};

export default useSettingsGet;
