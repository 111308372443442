import * as React from 'react';
import DisposalOrderShipmentSection from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/DisposalOrderShipmentSection';
import DisposalOrderInfoProvider from '~/wm/packages/disposal/packages/order-info/context/DisposalOrderInfoProvider';
import { DisposalOrderSummaryDtoNested } from '@WarrantyClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Dto/Model.gen';
import DisposalOrderSummaryWorkstationAssuranceSection from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-summary-section/packages/disposal-order-summary-workstation-assurance-section/DisposalOrderSummaryWorkstationAssuranceSection';

export type DisposalSuccessSectionProps = {
  disposalOrderSummary: DisposalOrderSummaryDtoNested.WorkstationAssurance;
};

const DisposalWorkstationAssuranceSuccessSection = ({ disposalOrderSummary }: DisposalSuccessSectionProps) => (
  <DisposalOrderInfoProvider
    disposalOrderId={disposalOrderSummary.disposalOrderId}
    defaultDisposalOrderSummary={disposalOrderSummary}
  >
    <DisposalOrderShipmentSection />
    <DisposalOrderSummaryWorkstationAssuranceSection disposalOrderSummary={disposalOrderSummary} />
  </DisposalOrderInfoProvider>
);

export default DisposalWorkstationAssuranceSuccessSection;
