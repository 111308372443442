import Color from '~/neo-ui/packages/color/Color.gen';
import AssessmentCriterionLabelEnum from '~/wm/packages/assessment/types/AssessmentCriterionLabelEnum';

export type AssessmentCriterionLabelDetails = {
  label: string;
  backgroundColor: Color;
  textColor: Color;
  iconColor: Color;
};

const getDetailsByAssessmentCriterionLabelEnum = (
  assessmentCriterionLabelEnum: AssessmentCriterionLabelEnum,
  criterionDisplayLabel: string,
): AssessmentCriterionLabelDetails => {
  switch (assessmentCriterionLabelEnum) {
    case 'Satisfactory':
    case 'PartialYes':
    case 'Yes':
      return {
        label: criterionDisplayLabel,
        backgroundColor: 'positive-100',
        textColor: 'positive-800',
        iconColor: 'positive-300',
      };
    case 'NeedsAttention':
    case 'Partial':
      return {
        label: criterionDisplayLabel,
        backgroundColor: 'caution-100',
        textColor: 'caution-800',
        iconColor: 'caution-300',
      };
    case 'AtRisk':
    case 'PartialNo':
    case 'No':
      return {
        label: criterionDisplayLabel,
        backgroundColor: 'negative-100',
        textColor: 'negative-800',
        iconColor: 'negative-300',
      };
    case 'AcceptableRisk':
      return {
        label: criterionDisplayLabel,
        backgroundColor: 'primary-100',
        textColor: 'primary-800',
        iconColor: 'primary-300',
      };
    case 'NotApplicable':
    case 'Unknown':
      return {
        label: criterionDisplayLabel,
        backgroundColor: 'light-200',
        textColor: 'dark-000',
        iconColor: 'light-700',
      };
    default:
      return {
        label: criterionDisplayLabel,
        backgroundColor: 'light-200',
        textColor: 'dark-000',
        iconColor: 'light-700',
      };
  }
};

export default getDetailsByAssessmentCriterionLabelEnum;
