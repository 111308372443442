import { contractSyncToggle } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useContractStatusSyncMutation, {
  UseContractStatusSyncMutationOptions,
} from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/hooks/useContractStatusSyncMutation';

export type ContractSyncTogglePayload = {
  organizationId: string;
  enableAutoSync: boolean;
  includeAgreementItems: boolean;
};

export type UseContractSyncToggle = (options?: UseContractStatusSyncMutationOptions) => {
  toggleSync: (payload: ContractSyncTogglePayload) => void;
  isTogglingSync: boolean;
};

const useContractSyncToggle: UseContractSyncToggle = options => {
  const [toggleSync, isTogglingSync] = useContractStatusSyncMutation(
    ({ organizationId, enableAutoSync, includeAgreementItems }: ContractSyncTogglePayload) =>
      contractSyncToggle({
        organizationId,
        enableAutoSync,
        includeAgreementItems,
      }),
    options,
  );

  return { toggleSync, isTogglingSync };
};

export default useContractSyncToggle;
