import { contractTemplateCreate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { UseContractListMutationOptions } from '~/wm/packages/strategy/packages/contract/packages/contract-list/hooks/useContractListMutation';
import { ContractTemplateCreatePayloadDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Template/Dto/Model.gen';
import useContractTemplateAvailabilitiesMutation from '~/wm/packages/strategy/packages/contract/packages/contract-template/packages/hooks/useContractTemplateAvailabilitiesMutation';

export type UseContractTemplateCreate = {
  createTemplateContract: (contractTemplateCreatePayload: ContractTemplateCreatePayloadDto, isRequestActive?: () => boolean) => void;
  isCreatingContractTemplate: boolean;
};

const useContractTemplateCreate = (options?: UseContractListMutationOptions): UseContractTemplateCreate => {
  const [createTemplateContract, isCreatingContractTemplate] = useContractTemplateAvailabilitiesMutation(
    (contractTemplateCreatePayload: ContractTemplateCreatePayloadDto) => contractTemplateCreate({ contractTemplateCreatePayload }),
    options,
  );

  return {
    createTemplateContract,
    isCreatingContractTemplate,
  };
};

export default useContractTemplateCreate;
