import * as React from 'react';
import { RenderCellDataNested } from '@AssetManagementClient/BeastClient/Renderer/Model/Data.gen';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';

export type RenderDateCellProps = {
  data: RenderCellDataNested.DateData;
};

const RenderDateCell: React.FunctionComponent<RenderDateCellProps> = ({ data }) => {
  const formattedDate =
    typeof data.date !== 'undefined'
      ? formatDate(parseISO(data.date), {
          format: 'yyyy-MM-dd',
          timezone: TimezoneFormat.Utc,
        })
      : '-';
  return <span>{formattedDate}</span>;
};

export default RenderDateCell;
