import ContractListProvider from '~/wm/packages/strategy/packages/contract/packages/contract-list/context/ContractListProvider';
import ContractListTable from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-list-table/ContractListTable';
import * as React from 'react';
import ContractCreateButton from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-create-button/ContractCreateButton';
import ContractListDownloadButton from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-list-share-button/ContractListDownloadButton';
import ContractTemplateAvailabilitiesProvider from '~/wm/packages/strategy/packages/contract/packages/contract-template/context/ContractTemplateAvailabilitiesProvider';
import ContractSyncButton from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/packages/contract-sync-button/ContractSyncButton';
import ContractSyncStatusProvider from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/context/ContractSyncStatusProvider';
import ContractStatusSyncDisplay from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-status-sync-display/ContractStatusSyncDisplay';
import LayoutHeader from '~/neo-ui/packages/layout/packages/header/LayoutHeader';

export type ContractListPageProps = {
  organizationId: string;
  organizationName: string;
};

const ContractListPage = ({ organizationId, organizationName }: ContractListPageProps) => (
  <ContractTemplateAvailabilitiesProvider>
    <ContractSyncStatusProvider organizationId={organizationId}>
      <ContractListProvider
        organizationId={organizationId}
        organizationName={organizationName}
      >
        <LayoutHeader
          leftControls={[
            {
              expanded: <ContractSyncButton organizationId={organizationId} />,
            },
            {
              expanded: <ContractCreateButton organizationId={organizationId} />,
            },
          ]}
          rightControls={[
            {
              expanded: (
                <ContractListDownloadButton
                  organizationId={organizationId}
                  organizationName={organizationName}
                />
              ),
            },
            {
              expanded: <ContractStatusSyncDisplay />,
            },
          ]}
        />
        <ContractListTable />
      </ContractListProvider>
    </ContractSyncStatusProvider>
  </ContractTemplateAvailabilitiesProvider>
);

export default ContractListPage;
