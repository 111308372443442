import getMutationSuccessEffectDefinition, {
  MutationSuccessEffectParams,
} from '~/extensions/packages/hooks/getMutationSuccessEffectDefinition';
import { useMemo, useState, useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

export type UseMutationInternalExecute<TArg> = (arg: TArg, isRequestActive?: () => boolean) => void;

export type UseMutationInternal<TArg> = [execute: UseMutationInternalExecute<TArg>, isLoading: boolean];

export type UseMutationInternalApiCall<TArg, TResponse = unknown> = (arg: TArg) => Promise<TResponse>;

export type UseMutationInternalOptions<TResponse = unknown> = {
  /**
   * Triggered after the mutation and its effect are successfully completed.
   */
  onSuccess?: () => void;

  onSuccessEffect?: MutationSuccessEffectParams<TResponse>;

  onFailure?: () => void;
};

const useMutationInternal = <TArg = Record<string, unknown>, TResponse = unknown>(
  apiCall: UseMutationInternalApiCall<TArg, TResponse>,
  onEntityReload: (isRequestActive?: () => boolean) => Promise<void>,
  options?: UseMutationInternalOptions<TResponse>,
): UseMutationInternal<TArg> => {
  const onSuccess = options?.onSuccess;

  const reloadEffect = useMemo<MutationSuccessEffectParams<TResponse>>(
    () => ({
      type: 'reload-entity',
    }),
    [],
  );

  const onSuccessEffect: MutationSuccessEffectParams<TResponse> = options?.onSuccessEffect ?? reloadEffect;
  const [isLoading, setLoading] = useState(false);
  const { callApi } = useApi();
  const { action, suspendLoadingState } = getMutationSuccessEffectDefinition<TResponse>(onSuccessEffect, onEntityReload);

  const execute = useCallback<UseMutationInternalExecute<TArg>>(
    async (arg, isRequestActive) => {
      setLoading(true);

      await (async () => {
        const response = await callApi(() => apiCall(arg), undefined, false, options?.onFailure);

        if (!response) {
          return;
        }

        if (isRequestActive && !isRequestActive()) {
          // Request cancelled
          return;
        }

        await action(response, isRequestActive);

        if (onSuccess) {
          onSuccess();
        }
      })();

      if (!suspendLoadingState) {
        setLoading(false);
      }
    },
    [suspendLoadingState, callApi, options?.onFailure, action, onSuccess, apiCall],
  );

  return [execute, isLoading];
};

export default useMutationInternal;
