import { PageFilter } from '@AssetManagementClient/AssetManagement/Packages/Share/Packages/Send/Model.gen';
import React from 'react';

type HamConsoleSelectedFilters = {
  [index: string]: string[];
};

declare const window: {
  hamSelectedFilters?: HamConsoleSelectedFilters;
};

const useHardwareConsoleFilters = (): { filters: PageFilter[] | undefined } => {
  const [filters, setFilters] = React.useState<HamConsoleSelectedFilters>();

  // This is needed to respond to state updates accordingly in React
  React.useEffect(() => {
    const handleFilterChange = () => {
      // This forces a re-render due to the change
      setFilters({ ...window.hamSelectedFilters });
    };
    document.addEventListener('hamOnFilterChange', handleFilterChange, false);
    setFilters(window.hamSelectedFilters);

    return () => {
      // Unregister (cleanup)
      document.removeEventListener('hamOnFilterChange', handleFilterChange);
    };
  }, []);

  return {
    filters: Object.entries(filters ?? {}).map(
      ([filterKey, filterValues]): PageFilter => ({
        key: filterKey,
        values: filterValues,
      }),
    ),
  };
};

export default useHardwareConsoleFilters;
