import * as React from 'react';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';
import { range } from '~/neo-ui/packages/table/packages/table-selectable/packages/selectable-checkbox/Range';

export type SelectableCheckboxProps = {
  id: string;
  ids: string[];
  lastId: string | undefined;
  onSelectId: (id: string) => void;
  onDeselectId: (id: string) => void;
  onSelectIds: (ids: string[], idClicked: string | undefined) => void;
  onDeselectIds: (ids: string[], idClicked: string | undefined) => void;
  isSelected: boolean;
  disabled?: boolean;
};

/**
 * A checkbox used to select every row of a table
 */
const SelectableCheckbox = ({
  id,
  ids,
  lastId,
  onSelectId,
  onDeselectId,
  onSelectIds,
  onDeselectIds,
  isSelected,
  disabled = false,
}: SelectableCheckboxProps) => {
  const rangeResult = range(ids, lastId, id, x => x);

  return (
    <InputCheckbox
      checked={isSelected}
      disabled={disabled}
      onClick={e =>
        e.shiftKey
          ? isSelected
            ? onDeselectIds(rangeResult, id)
            : onSelectIds(rangeResult, id)
          : isSelected
          ? onDeselectId(id)
          : onSelectId(id)
      }
    />
  );
};

export default SelectableCheckbox;
