export const countWeekDaysBetween = (startDate: Date, endDate: Date): number => {
  const tempDate = new Date(startDate.getTime());
  const businessDayCount = Array.from({
    length: (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24),
  }).reduce((currentCount: number) => {
    let count = currentCount;
    if (tempDate.getUTCDay() % 6 !== 0) {
      count++;
    }
    tempDate.setDate(tempDate.getDate() + 1);
    return count;
  }, 0);

  return businessDayCount + 1;
};

export const isDateAtLeastFiveBusinessDaysApart = (dateA: Date | null | undefined, dateB: Date | null | undefined) => {
  if (typeof dateA === 'undefined' || dateA === null) {
    return true;
  }

  if (typeof dateB === 'undefined' || dateB === null) {
    return true;
  }

  return countWeekDaysBetween(dateA, dateB) >= 5;
};

export const isDateAtLeastSevenBusinessDaysInFuture = (date: Date | null | undefined) => {
  if (typeof date === 'undefined' || date === null) {
    return true;
  }

  let dayCount = 0;
  const fourBusinessDaysInFuture = new Date();
  fourBusinessDaysInFuture.setHours(0, 0, 0, 0);

  while (dayCount < 7) {
    fourBusinessDaysInFuture.setDate(fourBusinessDaysInFuture.getDate() + 1);
    if (fourBusinessDaysInFuture.getDay() !== 6 && fourBusinessDaysInFuture.getDay() !== 0) {
      dayCount++;
    }
  }

  return date.getTime() >= fourBusinessDaysInFuture.getTime();
};

export const isTimeLessThanOther = (timeA: string | null | undefined, timeB: string | null | undefined): boolean => {
  if (typeof timeA === 'undefined' || timeA === null) {
    return true;
  }

  if (typeof timeB === 'undefined' || timeB === null) {
    return true;
  }

  const startingTimeSplit = timeA.split(':');
  const endingTimeSplit = timeB.split(':');

  const dateA = new Date();
  dateA.setHours(Number(startingTimeSplit[0]), Number(startingTimeSplit[1]));

  const dateB = new Date();
  dateB.setHours(Number(endingTimeSplit[0]), Number(endingTimeSplit[1]));

  return dateA.getTime() < dateB.getTime();
};

export const isDateLessThanOther = (dateA: Date | null | undefined, dateB: Date | null | undefined): boolean => {
  if (typeof dateA === 'undefined' || dateA === null) {
    return true;
  }

  if (typeof dateB === 'undefined' || dateB === null) {
    return true;
  }

  return dateA.getTime() < dateB.getTime();
};

export const isDateOutsideWinterHolidayDateRange = (date: Date | null | undefined, startYear: number): boolean => {
  if (typeof date === 'undefined' || date === null) {
    return true;
  }

  const winterHolidaysStart = new Date(startYear, 11, 19);

  const winterHolidaysEnd = new Date(startYear, 0, 7);

  return !(date.getTime() >= winterHolidaysStart.getTime() || date.getTime() <= winterHolidaysEnd.getTime());
};

export const isDateAfterPickupBlockDate = (date: Date | null | undefined): boolean => {
  if (typeof date === 'undefined' || date === null) {
    return true;
  }

  const pickupsBlockedUntil = new Date(2024, 0, 18);

  return date.getTime() >= pickupsBlockedUntil.getTime();
};
