import { Filter as AssetManagementClientBeastClientPrimitivesQueryRequestSoftwareFilterFactoryNestedEnum_QueryRequestNestedFilter } from '@AssetManagementClient/BeastClient/Primitives/Query/Request/SoftwareFilterFactoryNested/Enum_/QueryRequestNested.gen';
import { SearchPayload as AssetManagementClientBeastClientPrimitivesQuerySearchSearchPayload } from '@AssetManagementClient/BeastClient/Primitives/Query/Search.gen';
import { Order as AssetManagementClientBeastClientPrimitivesQuerySortingOrder } from '@AssetManagementClient/BeastClient/Primitives/Query/Sorting.gen';
import { Enum as AssetManagementClientBeastClientSoftwareSearchModelQuerySearchContextSoftwareSearchContextFactoryNestedEnum } from '@AssetManagementClient/BeastClient/Software/Search/Model/Query/SearchContext/SoftwareSearchContextFactoryNested.gen';
import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import useDownloadSoftwareSpreadsheet from '~/neo-ui/packages/button/packages/action-button/hooks/useDownloadSoftwareSpreadsheet';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';

export type DownloadSoftwareSpreadsheetProps = {
  searchContext: AssetManagementClientBeastClientSoftwareSearchModelQuerySearchContextSoftwareSearchContextFactoryNestedEnum;
  frontendScope: FrontendScope;
  filters: AssetManagementClientBeastClientPrimitivesQueryRequestSoftwareFilterFactoryNestedEnum_QueryRequestNestedFilter[];
  sorts: AssetManagementClientBeastClientPrimitivesQuerySortingOrder[];
  searchPayload?: AssetManagementClientBeastClientPrimitivesQuerySearchSearchPayload;
  tooltipContent?: string;
};

const DownloadSoftwareSpreadsheet: React.FunctionComponent<DownloadSoftwareSpreadsheetProps> = ({
  searchContext,
  frontendScope,
  filters,
  sorts,
  searchPayload,
  tooltipContent,
}) => {
  const { downloadSoftwareSpreadsheet } = useDownloadSoftwareSpreadsheet();
  return (
    <Tooltip
      backgroundColor={'primary-400'}
      content={tooltipContent}
      placement={'bottom'}
    >
      <Button
        iconLeft={'Download'}
        theme={'primary'}
        onClick={() => {
          downloadSoftwareSpreadsheet(searchContext, frontendScope, filters, sorts, searchPayload);
        }}
      />
    </Tooltip>
  );
};

export default DownloadSoftwareSpreadsheet;
