import React from 'react';
import { css } from '@emotion/react';
import { Filter } from '~/neo-ui/packages/table/packages/console/types';
import { RenderFilterNested } from '@AssetManagementClient/BeastClient/Search/Model/Query/Field/Filter/Render.gen';
import Popover from '~/neo-ui/packages/popover/Popover';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import SelectGroup, { ButtonSelectOption } from '~/neo-ui/packages/select/packages/select-group/SelectGroup';
import FilterHeaderEmptyMultiSelect from '../filter-headers/filter-header/packages/filter-header-selected-multi-select/packages/filter-header-empty-multi-select/FilterHeaderEmptyMultiSelect';
import FilterHeader from '../filter-headers/filter-header/FilterHeader';

export type ButtonSelectFilterProps = {
  filter: Filter;
  render: RenderFilterNested.ButtonSelect;
  parameterValue: Map<string, string[]> | undefined;
  setValue: (key: string, values: Map<string, string[]>) => void;
};

const ButtonSelectFilter: React.FunctionComponent<ButtonSelectFilterProps> = ({ filter, render, parameterValue, setValue }) => {
  const values = parameterValue?.get(render.operator) ?? [];

  const headerCallback = (isOpen: boolean) => {
    const headerEmpty = (
      <FilterHeaderEmptyMultiSelect
        label={filter.label}
        isOpen={isOpen}
      />
    );

    return (
      <FilterHeader
        selectedValues={values}
        operator={'bac'}
        emptyDisplay={headerEmpty}
        selectedDisplay={<div />}
      />
    );
  };

  return (
    <div
      css={css`
        display: inline-block;
      `}
    >
      <Popover
        header={headerCallback}
        disabled={!filter.isEnabled}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Label
            css={css`
              margin-right: 0.625rem;
            `}
            bold={true}
          >
            {filter.label}
          </Label>
          <SelectGroup
            options={render.options
              ?.map(option => ({
                label: option.label,
                value: option.key,
              }))
              .map(x => ({
                ...x,
                disabled: !filter.isEnabled || render.disabledOptions.includes(x.value),
              }))}
            selectedOptionValue={values.length === 0 ? '' : values[0]}
            onOptionSelected={(selectedOption: ButtonSelectOption) =>
              setValue(filter.key, new Map([[render.operator, [selectedOption.value]]]))
            }
          />
        </div>
      </Popover>
    </div>
  );
};

export default ButtonSelectFilter;
