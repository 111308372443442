import { css } from '@emotion/react';
import EngagementNoteFieldsFormModule from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/packages/engagement-note-fields-form-module/EngagementNoteFieldsFormModule';
import Button from '~/neo-ui/packages/button/Button';
import * as React from 'react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { EngagementNoteFormData } from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/packages/engagement-note-create-form/EngagementNoteCreateForm';

export type EngagementNoteCreateFormModuleProps = {
  onCancel: () => void;
  onSubmit: () => void;
};

const EngagementNoteCreateFormModule = ({ onCancel, onSubmit }: EngagementNoteCreateFormModuleProps) => {
  const { submitForm, isSubmitting, isValid, dirty, addAfterSubmitListener } = useFormContext<EngagementNoteFormData>();

  React.useEffect(
    () =>
      addAfterSubmitListener(async () => {
        onSubmit();
      }),
    [addAfterSubmitListener, onSubmit],
  );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <EngagementNoteFieldsFormModule />
      <div
        css={css`
          display: flex;
          gap: 0.5rem;
          margin-left: auto;
        `}
      >
        <Button
          size={'sm'}
          disabled={isSubmitting}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          loading={isSubmitting}
          disabled={!isValid || !dirty}
          size={'sm'}
          theme={'primary'}
          onClick={() => {
            submitForm();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EngagementNoteCreateFormModule;
