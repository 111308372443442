import Button from '~/neo-ui/packages/button/Button';
import { Fragment, useState } from 'react';
import AutoEmailReportCreateForm from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-create/packages/auto-email-report-create-form/AutoEmailReportCreateForm';
import AutoEmailReportCreateWindow from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-create/packages/auto-email-report-create-window/AutoEmailReportCreateWindow';
import useToastContext from '~/neo-ui/packages/toast/context/hooks/useToastContext';

export type AutoEmailReportCreateButtonProps = {
  scrollToRef: React.RefObject<HTMLDivElement>;
};

const AutoEmailReportCreateButton = ({ scrollToRef }: AutoEmailReportCreateButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { addToast } = useToastContext();

  return (
    <Fragment>
      <Button
        iconLeft={'Add'}
        theme={'primary'}
        onClick={() => setIsOpen(prev => !prev)}
      >
        Create New Report
      </Button>

      {isOpen && (
        <AutoEmailReportCreateForm
          onAutoEmailReportCreate={() => {
            setIsOpen(false);
            addToast({
              toastUniqueId: 'auto-email-report-created',
              toastType: 'alert',
              toastContent: {
                theme: 'success',
                title: 'Auto Email Report Created',
                description: 'Your new report has been created. You can now edit it and add recipients.',
                icon: 'Done',
              },
            });
            if (scrollToRef.current !== null) {
              scrollToRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          }}
        >
          <AutoEmailReportCreateWindow
            isOpen={isOpen}
            onDismiss={() => setIsOpen(false)}
          />
        </AutoEmailReportCreateForm>
      )}
    </Fragment>
  );
};

export default AutoEmailReportCreateButton;
