import React from 'react';
import { css } from '@emotion/react';
import CardPrice, {
  Inclusion,
} from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-selection/packages/card/card-price/CardPrice';
import CardImage from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-selection/packages/card/card-image/CardImage';

export type PlanCardGridProps = {
  planLimit: number;
  title: string;
  amount: number;
  inclusions: Inclusion[];
  isRecommended: boolean;
  selectedItemIconTooltip: string;
  action: () => void;
  isActionDisabled?: boolean;
};
const customPlanProps = {
  imageAlt: 'Custom plan',
  imageUrl: '/i/scalepad-account/stars.png',
  title: 'Custom plan',
  description: 'Need more licences to support your MSP? Let’s chat to find the best solution for you.',
  action: () => {
    window.location.assign('https://www.scalepad.com/contact/');
  },
  inclusions: [
    { title: 'Plan tailored to your MSP’s needs', tooltip: 'Plan tailored to your MSP’s needs' },
    { title: 'Dedicated account representative', tooltip: 'Dedicated account representative' },
    { title: 'Access to add-on modules for applicable apps', tooltip: 'Access to add-on modules for applicable apps' },
  ] as Inclusion[],
};
const PlanCardGrid = (props: { cards: PlanCardGridProps[] }) => {
  const cards = props.cards;
  return (
    <div
      css={css`
        display: grid;
        align-items: start;
        grid-template-columns: repeat(auto-fit, 23.75rem);
        gap: 2rem;
      `}
    >
      {cards.map((card, index) => (
        <CardPrice
          key={index}
          {...card}
        />
      ))}
      <CardImage {...customPlanProps} />
    </div>
  );
};

export default PlanCardGrid;
