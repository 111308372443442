import { useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { DisposalOrderPickupImageDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderPickup/Packages/DisposalOrderPickupImage/Dto/Model.gen';
import { disposalOrderPickupImageList } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseDisposalOrderPickupImageList = (disposalOrderId: string) => {
  disposalOrderPickupImageList: DisposalOrderPickupImageDto[];
};

const useDisposalOrderPickupImageList: UseDisposalOrderPickupImageList = disposalOrderId => {
  const [imageList, setImageList] = useState<DisposalOrderPickupImageDto[]>([]);
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      if (!disposalOrderId) {
        return;
      }
      const response = await callApi(() =>
        disposalOrderPickupImageList({
          disposalOrderId,
        }),
      );

      if (!response) {
        return;
      }

      setImageList(response.disposalOrderPickupImages);
    })();
  }, [callApi, disposalOrderId]);

  return {
    disposalOrderPickupImageList: imageList,
  };
};

export default useDisposalOrderPickupImageList;
