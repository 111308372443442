import { SubscriptionUpgradeAvailabilities } from '@SubscriptionClient/Subscription/Packages/Upgrade/Model.gen';
import { subscriptionUpgradeAvailabilities } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

const useSubscriptionUpgradeAvailabilities = (preselectProduct: string | undefined) => {
  const [isLoading, setLoading] = useState(true);

  const [upgradeAvailabilities, setUpgradeAvailabilities] = useState<SubscriptionUpgradeAvailabilities>();

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() =>
        subscriptionUpgradeAvailabilities({
          preselectProductAbbreviation: preselectProduct,
        }),
      );
      setLoading(false);

      if (!response) {
        return;
      }

      setUpgradeAvailabilities(response.availabilities);
    })();
  }, [callApi, preselectProduct]);

  return { isLoading, upgradeAvailabilities };
};

export default useSubscriptionUpgradeAvailabilities;
