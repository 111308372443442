import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import buildConsoleRequest from '~/neo-ui/packages/table/packages/console/buildConsoleRequest';
import { assetContactConsoleOrganizationPdf } from '@BeastClient/BeastClientMsp.gen';
import { defaultConsoleState } from '~/neo-ui/packages/table/packages/console/types';

type UseDownloadContactReportM365Pdf = (organizationId: string) => {
  downloadContactReportM365: () => void;
  isLoading: boolean;
};

export const useDownloadContactReportM365Pdf: UseDownloadContactReportM365Pdf = organizationId => {
  const { callApi } = useApi();
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const downloadContactReportM365 = React.useCallback(async () => {
    setLoading(true);

    /**
     * Hardcoded columns for Microsoft 365 Contact Report, order is determined on backend
     */
    const m365ReportColumns = [
      'Microsoft365Status',
      'Microsoft365ProductName',
      'Microsoft365AuthenticationStatus',
      'Microsoft365AuthenticationMethod',
      'Microsoft365LastActiveDate',
    ];

    await callApi(() =>
      assetContactConsoleOrganizationPdf({
        query: buildConsoleRequest(defaultConsoleState),
        organizationId,
        forecast: undefined,
        selectedColumns: m365ReportColumns.map(value => ({ value })),
        allColumns: false,
        pdfName: 'Microsoft 365 User Report',
      }),
    );

    setLoading(false);
  }, [callApi, organizationId]);

  return { downloadContactReportM365, isLoading };
};

export default useDownloadContactReportM365Pdf;
