import * as React from 'react';
import LifecycleReportDownloadModal, {
  LifecycleReportDownloadModalProps,
} from '~/neo-ui/packages/table/packages/console/download/packages/lifecycle-report-download-button/packages/lifecycle-report-download-modal/LifecycleReportDownloadModal';
import LifecycleReportCustomizationSettingsForm from '~/neo-ui/packages/table/packages/console/download/packages/lifecycle-report-download-button/packages/lifecycle-report-customization-settings-form/LifecycleReportCustomizationSettingsForm';
import useLifecycleReportSettings from '~/neo-ui/packages/table/packages/console/download/hooks/useLifecycleReportSettings';

export type LifecycleReportCustomizationSettingsFormWrapperProps = {
  organizationId: string;
  isModalOpen: boolean;
  onDismiss: () => void;
  lmLastCheckinDateEnabled: boolean;
} & Pick<LifecycleReportDownloadModalProps, 'lifecycleReportDownloadLocation'>;

const LifecycleReportCustomizationSettingsFormWrapper: React.FunctionComponent<LifecycleReportCustomizationSettingsFormWrapperProps> = ({
  organizationId,
  isModalOpen,
  onDismiss,
  lifecycleReportDownloadLocation,
  lmLastCheckinDateEnabled,
}) => {
  const { lifecycleReportSettings } = useLifecycleReportSettings(organizationId);

  if (typeof lifecycleReportSettings === 'undefined') {
    return null;
  }

  return (
    <LifecycleReportCustomizationSettingsForm
      showCpu={lifecycleReportSettings.showCpuColumn}
      showRam={lifecycleReportSettings.showRamColumn}
      showStorage={lifecycleReportSettings.showStorageColumn}
      showLastCheckinDate={lifecycleReportSettings.showLastCheckInDateColumn}
    >
      <LifecycleReportDownloadModal
        organizationId={organizationId}
        isOpen={isModalOpen}
        onDismiss={onDismiss}
        lifecycleReportDownloadLocation={lifecycleReportDownloadLocation}
        lmLastCheckinDateEnabled={lmLastCheckinDateEnabled}
      />
    </LifecycleReportCustomizationSettingsForm>
  );
};

export default LifecycleReportCustomizationSettingsFormWrapper;
