import { css } from '@emotion/react';
import { Enum as PaymentMethodEnum } from '@SubscriptionClient/BeastClient/Beast/Accounting/Model/Payment/Method/PaymentMethodNested.gen';
import * as React from 'react';
import { getCountryByIso3 } from '~/neo-ui/model/Country';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { CreditCardFormData } from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';
import BillingInfo from '~/wm/packages/subscription/packages/billing/packages/billing-info/BillingInfo';
import { BillingInfoStepFieldKeys } from '../../model/BillingInfoStepFieldKeys';

export type BillingInfoStepSummaryProps<T> = {
  paymentMethod: PaymentMethodEnum;
  billingInfoStepFieldKeys: BillingInfoStepFieldKeys<T>;
};

const BillingInfoStepSummary = <T,>({ paymentMethod, billingInfoStepFieldKeys }: BillingInfoStepSummaryProps<T>) => {
  const { getFormInput } = useFormContext<T>();

  const addressLineTwo =
    getFormInput(billingInfoStepFieldKeys.billingStepSectionFieldKeys.addressLineTwoFieldKey).value !== ``
      ? ` ${getFormInput<string>(billingInfoStepFieldKeys.billingStepSectionFieldKeys.addressLineTwoFieldKey).value}`
      : '';

  const addressLineOne = getFormInput<string>(billingInfoStepFieldKeys.billingStepSectionFieldKeys.addressLineOneFieldKey).value;

  const city = getFormInput<string>(billingInfoStepFieldKeys.billingStepSectionFieldKeys.cityFieldKey).value;

  const stateCode = getFormInput<string>(billingInfoStepFieldKeys.billingStepSectionFieldKeys.stateCodeFieldKey).value;

  const countryIso3 = getFormInput<string>(billingInfoStepFieldKeys.billingStepSectionFieldKeys.countryIso3FieldKey).value;

  const postalCode = getFormInput<string>(billingInfoStepFieldKeys.billingStepSectionFieldKeys.postalCodeFieldKey).value;

  const addressFields = [`${addressLineOne} ${addressLineTwo}`, city, stateCode, getCountryByIso3(countryIso3)?.name, postalCode];

  const billingAddress = addressFields.filter(field => typeof field !== 'undefined' && field.trim().length > 0).join(', ');

  const creditCardInfo = getFormInput<CreditCardFormData | undefined>(billingInfoStepFieldKeys.creditCardStepSectionFieldKey).value;

  const companyName = `${getFormInput<string>(billingInfoStepFieldKeys.billingStepSectionFieldKeys.companyNameFieldKey).value}`;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        text-align: left;
      `}
    >
      <BillingInfo
        billingName={companyName}
        billingAddressFormatted={billingAddress}
        paymentMethod={paymentMethod}
        creditCardLast4Digits={creditCardInfo?.type === 'card-on-file' ? creditCardInfo.last4 : undefined}
      />
    </div>
  );
};

export default BillingInfoStepSummary;
