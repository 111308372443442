import ToggleSwitch from '~/neo-ui/packages/toggle/packages/toggle-switch/ToggleSwitch';
import { useState, useEffect } from 'react';
import useAccountAuthenticationSettingsGet from '~/wm/packages/account/packages/user/hooks/useAccountAuthenticationSettingsGet';
import useAccountAuthenticationEnforceSsoUpdate from '~/wm/packages/account/packages/user/hooks/useAccountAuthenticationEnforceSsoUpdate';

const EnforceSsoToggle = () => {
  const { accountAuthenticationSettings } = useAccountAuthenticationSettingsGet();
  const { updateEnforceSso } = useAccountAuthenticationEnforceSsoUpdate();
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (accountAuthenticationSettings) {
      setIsEnabled(accountAuthenticationSettings.enforceSso);
    }
  }, [accountAuthenticationSettings]);

  const handleToggle = () => {
    updateEnforceSso(!isEnabled);
    setIsEnabled(prev => !prev);
  };

  if (typeof accountAuthenticationSettings === 'undefined') {
    return <div />;
  }

  return (
    <div
      css={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1rem',
        alignItems: 'center',
      }}
    >
      <ToggleSwitch
        checked={isEnabled}
        onChange={handleToggle}
        theme={'primary'}
      />
      <div>Enforce SSO</div>
    </div>
  );
};

export default EnforceSsoToggle;
