import { ValueDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model/IntegrationFieldOptionDtoNested.gen';
import { FormSelectOption } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';

const buildFromSelectOptions = (fieldInputSelectOptions: ValueDto[]): FormSelectOption[] =>
  fieldInputSelectOptions.map(option => ({
    label: option.name,
    value: option.id,
  }));

export default buildFromSelectOptions;
