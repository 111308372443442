import { createContext } from 'react';

export type ActiveUsersMutationContextData = {
  triggerActiveUsersReload: (isRequestActive?: () => boolean) => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ActiveUsersMutationContext = createContext<ActiveUsersMutationContextData>({
  // If there's no provider, it's a graceful no-op
  triggerActiveUsersReload: async () => {},
});

export default ActiveUsersMutationContext;
