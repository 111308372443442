import * as React from 'react';
import { css } from '@emotion/react';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import { ButtonInternalOnClick } from '~/neo-ui/packages/button/ButtonInternal';

type ShowLessOrMoreButtonProps = {
  collapsedText: string;
  expandedText: string;
  isExpanded: boolean;
  onClick: ButtonInternalOnClick;
};

export const ShowLessOrMoreButton: React.FunctionComponent<ShowLessOrMoreButtonProps> = ({
  onClick,
  isExpanded,
  expandedText,
  collapsedText,
}) => (
  <ButtonLink
    onClick={onClick}
    iconRight={isExpanded ? 'ArrowUp' : 'ArrowDown'}
    css={css`
      width: fit-content;
      flex-shrink: 0;

      // Override calculated with style in buttonInternalStyles.tsx
      > .button-children {
        width: auto;
      }
    `}
  >
    {isExpanded ? expandedText : collapsedText}
  </ButtonLink>
);
