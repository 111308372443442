import React from 'react';
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Radius } from '~/neo-ui/packages/chart/RadiusType';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type DmiTrendChartProps = { scoreData: DmiChartData[] };

// Note: This is a prototype. It will be cleaned and refactored in a future task.
// Todo: Separate container component (fetch data and pass to chart component) and chart component
// Todo: Create the y-axis based on backend data (both cut-off values and band colors)
// Todo: Find a way to redo the stacked y-axis
// Todo: Modify the endpoint to return a different DTO containing only needed information

export type DmiChartData = {
  xLabel?: string;
  dmiScore?: number;
  dmiPoor?: number;
  dmiFair?: number;
  dmiGood?: number;
  dmiVeryGood?: number;
  dmiExcellent?: number;
};

const DmiTrendChart: React.FunctionComponent<DmiTrendChartProps> = ({ scoreData }) => {
  const isEmptyState = scoreData.length === 0;
  const barDataPoint: DmiChartData = {
    xLabel: isEmptyState ? 'Trend available Soon'.toUpperCase() : undefined,
    dmiPoor: 579,
    dmiFair: 90,
    dmiGood: 70,
    dmiVeryGood: 60,
    dmiExcellent: 50,
  };
  let data: DmiChartData[];
  if (isEmptyState) {
    data = [barDataPoint, {}];
  } else {
    data = [barDataPoint].concat(scoreData);
  }

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
    >
      <ComposedChart
        data={data}
        margin={{ top: 5, left: -35, right: 0, bottom: 0 }}
      >
        <CartesianGrid stroke="" />
        <XAxis
          dataKey="xLabel"
          padding={{ left: 4, right: 22 }}
          fontSize={8}
          tickLine={false}
          tick={{ fill: colorToCode('dark-300') }}
          interval={isEmptyState ? 'preserveStart' : undefined}
        />
        <YAxis
          type="number"
          domain={[300, 850]}
          tickLine={false}
          ticks={[300, 580, 670, 740, 800, 850]}
          axisLine={false}
          allowDataOverflow={true}
          fontSize={8}
          interval={0}
          tick={{ fill: colorToCode('dark-300') }}
        />
        <Bar
          dataKey="dmiPoor"
          stackId="a"
          barSize={6}
          fill={colorToCode('negative-400')}
          isAnimationActive={false}
        />
        <Bar
          dataKey="dmiFair"
          stackId="a"
          barSize={6}
          fill={colorToCode('warning-400')}
          isAnimationActive={false}
        />
        <Bar
          dataKey="dmiGood"
          stackId="a"
          barSize={6}
          fill={colorToCode('caution-400')}
          isAnimationActive={false}
        />
        <Bar
          dataKey="dmiVeryGood"
          stackId="a"
          barSize={6}
          fill={colorToCode('positive-400')}
          isAnimationActive={false}
        />
        <Bar
          dataKey="dmiExcellent"
          stackId="a"
          barSize={6}
          fill={colorToCode('excellent-400')}
          isAnimationActive={false}
          radius={[10, 10, 0, 0] as Radius}
        />

        <Line
          type="monotone"
          dataKey="dmiScore"
          stroke="#282C73"
          dot={false}
          strokeWidth={4}
          isAnimationActive={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default DmiTrendChart;
