import { scorecardUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { ScorecardUpdatePayload } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Model.gen';
import useScorecardMutation, { UseScorecardMutationOptions } from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardMutation';

export type UseScorecardUpdate = {
  updateScorecard: (updatePayload: ScorecardUpdatePayload, isRequestActive: () => boolean) => void;
};

const useScorecardUpdate = (organizationId: string, scorecardId: string, options?: UseScorecardMutationOptions): UseScorecardUpdate => {
  const [updateScorecard] = useScorecardMutation<ScorecardUpdatePayload>(
    (updatePayload: ScorecardUpdatePayload) =>
      scorecardUpdate({
        organizationId,
        scorecardId,
        updatePayload,
      }),
    options,
  );

  return {
    updateScorecard,
  };
};

export default useScorecardUpdate;
