import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type Bar = {
  value: number;
  color: string;
};

export type StackedBarChartProps = {
  height: string;
  bars: Bar[];
} & Styleable;

/**
 * A bar chart that stacks values end to end with customized colors.
 */
const StackedBarChart: React.FunctionComponent<StackedBarChartProps> = ({ height, bars, className }) => (
  <div
    className={className}
    css={css`
      display: flex;
      width: 100%;
      height: ${height};
      /* This is the unknown color for lifecycle status (see 37c45aa1-28f2-4ada-ac5a-21f49ae3ef38) */
      background-color: ${colorToCode('light-300')};
      border-radius: 0.3125rem;
      > *:first-child {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
      > *:last-child {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0.3125rem;
      }
    `}
  >
    {bars
      .filter(bar => bar.value > 0)
      .map(({ value, color }, index) => (
        <div
          key={index}
          css={css`
            flex: ${value};
            background-color: ${color};
          `}
        />
      ))}
  </div>
);

export default StackedBarChart;
