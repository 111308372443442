import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { css } from '@emotion/react';
import React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import ToggleItem from '~/neo-ui/packages/toggle/packages/toggle-item/ToggleItem';
import BoxShadow, { boxShadowToCode } from '~/neo-ui/packages/style/BoxShadow';

/**
 * single: only one tile can be selected using a radio
 * multi: multiple tiles can be selected using a checkbox
 */
export type ToggleTileType = 'single' | 'multi';

export type ToggleTileProps = {
  icon?: IconType;
  title: string;
  description?: string | React.ReactNode;
  tooltipContent?: React.ReactNode;
  appendComponent?: React.ReactNode;
  alert?: { text: string; color?: Color };
} & ToggleTileInternalProps;

export type ToggleTileInternalProps = {
  type: ToggleTileType;
  isSelected?: boolean;
  disabled?: boolean;
  onClick: () => void;
  boxShadow?: BoxShadow;
} & Styleable;

const ToggleTileInternal = ({
  className,
  type,
  isSelected = false,
  disabled = false,
  onClick,
  children,
  boxShadow,
}: ToggleTileInternalProps & {
  children: (toggle: React.ReactNode) => React.ReactNode;
}) => {
  const toggle = (() => {
    switch (type) {
      case 'single':
        return (
          <div
            css={css`
              height: 1.25rem;
              width: 1.25rem;
              flex: 0 0 1.25rem;
              background-color: ${colorToCode('light-000')};
              border-radius: 100%;
              border-style: solid;
              border-width: ${disabled ? '0' : isSelected ? '0.3125' : '0.0625'}rem;
              border-color: ${colorToCode(disabled ? 'dark-900-08' : isSelected ? 'primary-400' : 'dark-900-12')};
            `}
          />
        );
      case 'multi':
        return (
          <ToggleItem
            checked={isSelected}
            disabled={disabled}
          />
        );
    }
  })();

  return (
    <div
      className={className}
      css={css`
        transition: all 100ms ease-in-out;
        background-color: ${colorToCode(isSelected ? 'primary-050' : 'light-100')};
        padding: 1rem;
        display: flex;
        align-items: center;
        cursor: pointer;

        border-radius: 0.625rem;
        border: 0.0625rem solid transparent;
        box-shadow: ${boxShadowToCode(boxShadow)};

        ${isSelected && `border: 0.0625rem solid ${colorToCode('primary-400')}`};

        &:hover {
          ${!disabled && !isSelected && `border: 0.0625rem solid ${colorToCode('light-400')}`};
        }
      `}
      onClick={disabled ? undefined : onClick}
    >
      {children(toggle)}
    </div>
  );
};

export default ToggleTileInternal;
