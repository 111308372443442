import { useContext } from 'react';
import WarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/WarrantyCartContext';

const useWarrantyCartContext = () => {
  const warrantyCartContext = useContext(WarrantyCartContext);
  if (!warrantyCartContext) {
    throw new Error('Tried to use WarrantyCartContext outside of an WarrantyCartProvider');
  }

  return warrantyCartContext;
};

export default useWarrantyCartContext;
