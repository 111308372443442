import { SVGProps, forwardRef, memo } from 'react';

const MinusIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M6 11H18V13H6V11Z"
          fill="currentcolor"
        />
      </svg>
    );
  }),
);

export default MinusIcon;
