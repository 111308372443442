import { SubscriptionPricingDto } from '@SubscriptionClient/BeastClient/Beast/Msp/Dto/Subscription/Pricing/Get/Model.gen';
import { SubscriptionBillingPeriodEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription.gen';
import { ComponentPropsWithoutRef } from 'react';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import getBillingPeriodDisplay from '~/wm/packages/subscription/packages/billing/operation/getBillingPeriodDisplay';
import ConsumptionMeter from '~/wm/packages/subscription/packages/plan/packages/consumption-meter/ConsumptionMeter';
import PricingAmount from '~/wm/packages/subscription/packages/plan/packages/product-plan-summary/PricingAmount';
import Button from '~/neo-ui/packages/button/Button';

type Props = Pick<
  SubscriptionPricingDto,
  'consumption' | 'originalAmount' | 'saleAmount' | 'productLogoUrl' | 'productSummary' | 'extendedFeatureDescriptions' | 'manageUrl'
>;
export type BasePlanDetailsProps = Omit<ComponentPropsWithoutRef<'div'>, keyof Props> & Props;

const frequencySuffix = getBillingPeriodDisplay(SubscriptionBillingPeriodEnum.Monthly).short;

const BasePlanDetails = (props: BasePlanDetailsProps) => {
  const { consumption, originalAmount, productLogoUrl, manageUrl, productSummary, saleAmount, extendedFeatureDescriptions, ...rest } =
    props;

  return (
    <div
      css={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
      {...rest}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '0.5rem',
        }}
      >
        <img
          src={productLogoUrl}
          css={{ height: '1.5rem' }}
        />
        <Button anchor={{ href: manageUrl }}>Manage your plan</Button>
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: '1.875rem',
          verticalAlign: 'middle',
        }}
      >
        <span>Your plan</span>

        {!!saleAmount && (
          <PricingAmount
            saleAmount={saleAmount}
            originalAmount={originalAmount}
            saleAmountFormatter={amount => `${formatCurrency(amount)}/${frequencySuffix}`}
            originalAmountFormatter={amount => `${formatCurrency(amount)}/${frequencySuffix}`}
          />
        )}
      </div>

      <ul
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.313rem',
          margin: '0rem 0rem 0rem 0.375rem',
        }}
      >
        {productSummary && <li>{productSummary}</li>}
        {consumption?.meteredUsages.map(({ description, limit, usage }, index) => (
          <li key={index}>
            {description}
            <ConsumptionMeter
              css={{
                margin: '0.313rem 0 0.675rem 0',
              }}
              currentConsumption={usage}
              maximumLimit={limit}
            />
          </li>
        ))}
        {extendedFeatureDescriptions?.map((description, index) => (
          <li key={index}>{description}</li>
        ))}
      </ul>
    </div>
  );
};

export default BasePlanDetails;
