import * as React from 'react';
import { IntegrationVendorDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Controller/IntegrationPage.gen';
import VendorCard from '~/wm/packages/integration/packages/scalepad-account/packages/add-integration-page/VendorCard';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { ShowLessOrMoreButton } from '~/wm/packages/integration/packages/scalepad-account/packages/add-integration-page/ShowLessOrMoreButton';
import { useIsOverflow } from '~/wm/packages/integration/packages/scalepad-account/packages/add-integration-page/hooks/useIsOverflow';

// props
export type VendorAccordionProps = {
  toolTypeLabel: string;
  vendors: IntegrationVendorDto[];
  forceExpansion: boolean;
};

const VendorAccordion: React.FunctionComponent<VendorAccordionProps> = ({ toolTypeLabel, vendors, forceExpansion }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const derivedExpansionState = isExpanded || forceExpansion;

  const accordionRef = React.useRef<HTMLDivElement>(null);

  const isAccordionOverflowing = useIsOverflow(accordionRef);

  return (
    <div
      key={toolTypeLabel}
      css={css`
        display: flex;
        flex-flow: column;
        row-gap: 0.5rem;
      `}
    >
      <h2
        css={css`
          font-weight: 700;
          font-size: 1.125rem;
          display: flex;
          margin-bottom: 0; //Override default h2 margin
        `}
      >
        {toolTypeLabel}
      </h2>
      <div
        css={css`
          background-color: ${colorToCode('light-200')};
          height: ${derivedExpansionState ? 'auto' : '6.5rem'};
          gap: 0.5rem;
          display: flex;
          flex-flow: row wrap;
          transition: max-height 0.5s ease-in-out;
          overflow: hidden;
        `}
        ref={accordionRef}
      >
        {vendors.map(vendor => (
          <VendorCard
            integrationVendor={vendor}
            // Sometimes, vendors can have the same name, but if they have the same URL, we should really change that in the backend
            key={vendor.urlSlug}
          />
        ))}
      </div>
      {!forceExpansion && (isAccordionOverflowing || derivedExpansionState) && (
        <ShowLessOrMoreButton
          onClick={() => setIsExpanded(prev => !prev)}
          isExpanded={isExpanded}
          collapsedText={`Show more`}
          expandedText={`Show less`}
        />
      )}
    </div>
  );
};

export default VendorAccordion;
