import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import Button from '~/neo-ui/packages/button/Button';
import React from 'react';
import { RoadmapPdfDownloadFormData } from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap-share-button/packages/roadmap-pdf-download-form/RoadmapPdfDownloadForm';

const RoadmapPdfDownloadButton = () => {
  const { isSubmitting, submitForm } = useFormContext<RoadmapPdfDownloadFormData>();

  return (
    <Button
      disabled={isSubmitting}
      theme={'primary'}
      onClick={submitForm}
      loading={isSubmitting}
    >
      Download
    </Button>
  );
};

export default RoadmapPdfDownloadButton;
