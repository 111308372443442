import React, { useEffect } from 'react';
import FormCheckboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckboxInput';
import warrantyCartFilterFormFields from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-filters/packages/warranty-cart-filter-form/WarrantyCartFilterFormFields';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import WarrantyCartFilterFormData from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-filters/packages/warranty-cart-filter-form/WarrantyCartFilterFormData';
import useFiltersMutationContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useFiltersMutationContext';
import FormNumberInput from '~/neo-ui/packages/form/packages/form-input/packages/form-number-input/FormNumberInput';
import { css } from '@emotion/react';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';

const WarrantyCartFilterFormControl: React.FunctionComponent = () => {
  const { dirty, touched } = useFormContext<WarrantyCartFilterFormData>();
  const { clearFilter } = useFiltersMutationContext();
  const { getSelectedAssetsCount } = useWarrantyCartContext();

  useEffect(() => {
    if (!dirty && touched) {
      clearFilter();
    }
  }, [dirty, touched, clearFilter]);

  return (
    <>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        `}
      >
        <FormCheckboxInput
          fieldKey={warrantyCartFilterFormFields.selectedItems}
          description={<b>Selected items</b>}
          disabled={getSelectedAssetsCount() === 0}
        />
        <FormCheckboxInput
          fieldKey={warrantyCartFilterFormFields.outOfWarrantyAssets}
          description={<b>Out of warranty assets</b>}
        />
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 0.5rem;
        `}
      >
        <FormCheckboxInput
          fieldKey={warrantyCartFilterFormFields.assetsBetweenYears}
          description={<b>Assets between</b>}
        />
        <FormNumberInput
          css={css`
            width: 4rem;
          `}
          className={'form-control-num'}
          fieldKey={warrantyCartFilterFormFields.assetsBetweenYearsFrom}
          min={0}
        />
        <b>and</b>
        <FormNumberInput
          css={css`
            width: 4rem;
          `}
          className={'form-control-num'}
          fieldKey={warrantyCartFilterFormFields.assetsBetweenYearsTo}
          min={1}
        />
        <b>years old</b>
      </div>
    </>
  );
};

export default WarrantyCartFilterFormControl;
