import { ThemeMapInputTitle } from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/hooks/useThemeInputTitle';
import { css, SerializedStyles } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { InputTitleDisplayDetails } from '~/neo-ui/packages/input/packages/input-title/InputTitle';

type UseStylesInputTitle = {
  stylesInputTitle: SerializedStyles;
};

/**
 * Any changes to these styles should also be reflected in PHP
 * a081877b-4c73-4f1e-bbd0-99860cf401c4
 */
const useStylesInputTitle = (themeMap: ThemeMapInputTitle, displayDetails: InputTitleDisplayDetails): UseStylesInputTitle => ({
  stylesInputTitle: css`
    box-sizing: border-box;

    color: ${colorToCode(themeMap.textColorRest)};
    font-size: ${displayDetails.fontSizeRem}rem;
    font-weight: ${displayDetails.fontWeight};

    outline: none;
    appearance: none;
    width: 100%;
    min-height: ${displayDetails.heightRem}rem;

    text-overflow: ellipsis;

    border-radius: 0.25rem 0.25rem 0.0625rem 0.0625rem;
    border-left: ${colorToCode(themeMap.surroundingLineColorRest)} solid 0.0625rem;
    border-top: ${colorToCode(themeMap.surroundingLineColorRest)} solid 0.0625rem;
    border-right: ${colorToCode(themeMap.surroundingLineColorRest)} solid 0.0625rem;
    border-bottom: ${colorToCode(themeMap.underlineColorRest)} solid 0.0625rem;
    background-color: ${colorToCode(themeMap.backgroundColorRest)};

    padding-left: ${displayDetails.paddingLeftRemInput}rem;
    padding-right: ${displayDetails.paddingRightRemInput}rem;

    ::placeholder {
      color: ${colorToCode(themeMap.textColorPlaceholderRest)};
    }

    &:hover:not(:focus):not(:disabled) {
      border-left: ${colorToCode(themeMap.surroundLineColorHover)} solid 0.0625rem;
      border-top: ${colorToCode(themeMap.surroundLineColorHover)} solid 0.0625rem;
      border-right: ${colorToCode(themeMap.surroundLineColorHover)} solid 0.0625rem;
      border-bottom: ${colorToCode(themeMap.underlineColorHover)} solid 0.0625rem;

      color: ${colorToCode(themeMap.textColorHover)};

      ::placeholder {
        color: ${colorToCode(themeMap.textColorPlaceholderHover)};
      }
    }

    &:focus {
      background-color: ${colorToCode(themeMap.backgroundColorFocus)};
      // Fake border using box shadow
      box-shadow: inset 0 0 0 0.125rem ${colorToCode(themeMap.borderColorFocus)};
      border-color: ${colorToCode(themeMap.borderColorFocus)};

      color: ${colorToCode(themeMap.textColorFocus)};

      ::placeholder {
        color: ${colorToCode(themeMap.textColorPlaceholderFocus)};
      }
    }

    &:disabled {
      background-color: ${colorToCode(themeMap.backgroundColorDisabled)};
      border-color: ${colorToCode(themeMap.borderColorDisabled)};

      color: ${colorToCode(themeMap.textColorDisabled)};

      ::placeholder {
        color: ${colorToCode(themeMap.textColorPlaceholderDisabled)};
      }
    }
  `,
});

export default useStylesInputTitle;
