import { SVGProps, forwardRef, memo } from 'react';

const CheckmarkIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
      >
        <title>image/icon/Success</title>
        <desc>Created with Sketch.</desc>
        <g
          id="image/icon/Success"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M13.2689447,3.31768175 C13.6457787,2.91393107 14.2785676,2.89211077 14.6823183,3.26894473 C15.0860689,3.6457787 15.1078892,4.27856757 14.7310553,4.68231825 L6.33105527,13.6823183 C5.95362903,14.0867035 5.3196044,14.1078611 4.91605887,13.7295372 L1.31605887,10.3545372 C0.913146599,9.97680695 0.892732541,9.34397114 1.2704628,8.94105887 C1.64819305,8.5381466 2.28102886,8.51773254 2.68394113,8.8954628 L5.55278093,11.5850001 L13.2689447,3.31768175 Z"
            id="iconBackground"
            fill="currentcolor"
          />
        </g>
      </svg>
    );
  }),
);

export default CheckmarkIcon;
