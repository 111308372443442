import { SubscriptionBillingPeriodEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription.gen';
import { TermRenewalBehaviorEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription/Pricing/Term/TermNested.gen';
import { AppliedDiscountInfo } from '@SubscriptionClient/Subscription/Packages/Pricing/Model.gen';
import { PricingDto } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/PricingModel.gen';
import { TermEndAction } from '@SubscriptionClient/BeastClient/Billing/Subscription/Model/Domain/Subscription/TermNested.gen';

export type SubscriptionAgreementInfo = Preset | SelfServe;

export enum SubscriptionAgreementEnum {
  Preset = 'Preset',
  SelfServe = 'SelfServe',
}

export enum SelfServeType {
  Grow = 'Grow',
  Upgrade = 'Upgrade',
}

export type SelfServe = {
  case: SubscriptionAgreementEnum.SelfServe;
  discount: AppliedDiscountInfo;
  term?: SubscriptionAgreementInfoTerm;
  billing: SubscriptionAgreementInfoBilling;
  type: SelfServeType;
};

export type Preset = {
  case: SubscriptionAgreementEnum.Preset;
  discount: AppliedDiscountInfo;
  term?: SubscriptionAgreementInfoTerm;
  billing: SubscriptionAgreementInfoBilling;
  pricingDto: PricingDto;
};

export type SubscriptionAgreementInfoTerm = {
  durationMonths?: number;
  startsAt?: Date;
  endsAt: Date;
  renewalBehavior: TermRenewalBehaviorEnum;
};

export type SubscriptionAgreementInfoTermV2 = {
  durationMonths?: number;
  startsAt?: Date;
  endsAt: Date;
  renewalBehavior: TermEndAction;
};

export type SubscriptionAgreementInfoBilling = {
  periodEnum: SubscriptionBillingPeriodEnum;
};
