import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { treeImpactSummaryGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { TreeImpactSummaryDto } from '@AssetManagementClient/BeastClient/Beast/TreeImpact/Dto/Model.gen';

const useTreeImpactSummaryGet = (serviceId: string) => {
  const [isLoading, setLoading] = useState(true);

  const [treeImpactSummary, setTreeImpactSummary] = useState<TreeImpactSummaryDto>();

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => treeImpactSummaryGet({ serviceId }));
      setLoading(false);

      if (!response) {
        return;
      }

      setTreeImpactSummary(response.treeImpactSummary);
    })();
  }, [callApi, serviceId]);

  return { isLoading, treeImpactSummary };
};

export default useTreeImpactSummaryGet;
