import { Request as UnlinkRequest } from '@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Unlink/Controller/OrganizationUnlinkControllerNested.gen';
import { organizationUnlink } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { OperationDefinition } from '~/legacy-ui/packages/operation/model/buildOperation';
import translateToGlobalError from '~/wm/packages/api/packages/api-error/convert/translateToGlobalError';
import callApiOrError from '~/wm/packages/api/packages/api-result/callApiOrError';
import Organization from '~/wm/packages/organization/model/Organization';
import OrganizationLink from '../../../../../model/OrganizationLink';
import linkingProgress from '../../../../../operation/linkingProgress';

type UnlinkContext = {
  targetOrganization: OrganizationLink;
  parentOrganization: Organization;
};

const organizationUnlinkOperation: OperationDefinition<UnlinkContext> = {
  label: 'Unlinking clients',
  description: ({ targetOrganization, parentOrganization }) =>
    `You’re unlinking ${targetOrganization.name} from ${parentOrganization.name}. When unlinking is finished, you'll find ${targetOrganization.name} in the client list.`,
  action: ({ targetOrganization }) => {
    const unlinkRequest: UnlinkRequest = {
      linkedOrganizationId: targetOrganization.id,
    };
    return translateToGlobalError(() => callApiOrError(() => organizationUnlink(unlinkRequest)));
  },
  sensitivity: {
    type: 'confirmation-required',
    theme: 'negative',
    confirmLabel: 'Apply',
  },
  actions: ({ targetOrganization }) => [
    {
      label: `Unlink ${targetOrganization.name}`,
      description: "You'll see it as a separate client",
    },
  ],
  icon: 'LinkBroken',
  progress: ({ parentOrganization }) => linkingProgress({ parentOrganizationId: parentOrganization.id }),
};

export default organizationUnlinkOperation;
