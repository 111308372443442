import { ScorecardDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Dto/Model.gen';
import * as React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import useScorecardUpdate from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardUpdate';
import scorecardUpdateFormValidationSchema from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-update-form/validation/scorecardUpdateFormValidationSchema';

export type ScorecardFormData = {
  title: string;
  executiveSummary: string | undefined;
  includeDmi: boolean;
  scheduleYear: number;
  scheduleMonth: number;
};

export type ScorecardUpdateFormProps = {
  scorecard: ScorecardDto;
  onFormIsSubmitting: (isSubmitting: boolean) => void;
  onScorecardUpdateFailed: () => void;
  children: React.ReactNode;
};

const ScorecardUpdateForm = ({ scorecard, onFormIsSubmitting, onScorecardUpdateFailed, children }: ScorecardUpdateFormProps) => {
  const { updateScorecard } = useScorecardUpdate(scorecard.organizationId, scorecard.scorecardId, {
    onSuccess: () => onFormIsSubmitting(false),
    onFailure: () => onScorecardUpdateFailed(),
  });
  const onSubmit = React.useCallback(
    async (data: ScorecardFormData, isRequestActive: () => boolean) => {
      onFormIsSubmitting(true);
      updateScorecard(
        {
          title: data.title,
          executiveSummary: data.executiveSummary ?? '',
          includeDmi: data.includeDmi,
          schedule: { year: data.scheduleYear, month: data.scheduleMonth },
        },
        isRequestActive,
      );
    },
    [onFormIsSubmitting, updateScorecard],
  );

  const toFormData = React.useCallback(
    (scorecard: ScorecardDto): ScorecardFormData => ({
      title: scorecard.title,
      executiveSummary: scorecard.executiveSummary,
      includeDmi: scorecard.includeDmi,
      scheduleMonth: scorecard.schedule.month,
      scheduleYear: scorecard.schedule.year,
    }),
    [],
  );

  const defaultFormData = React.useMemo(() => toFormData(scorecard), [scorecard, toFormData]);

  return (
    <Form
      submitMethod={'auto'}
      defaultFormData={defaultFormData}
      validationSchema={scorecardUpdateFormValidationSchema}
      onSubmit={onSubmit}
      disableOverwriteDefaultFormDataOnSave={true}
      hideSubmitStatusIndicator={true}
    >
      {children}
    </Form>
  );
};

export default ScorecardUpdateForm;
