import * as React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color from '~/neo-ui/packages/color/Color.gen';

const SingleSelectDropdownIndicator = <T extends string>(
  props: DropdownIndicatorProps<SelectOption<T>, false>,
  sizePx: number,
  isOpen: boolean,
  iconOpen: IconType = 'ArrowUp',
  iconClosed: IconType = 'ArrowDown',
  color: Color,
) => {
  const { DropdownIndicator } = components;

  return (
    <DropdownIndicator {...props}>
      {isOpen ? (
        <Icon
          icon={iconOpen}
          sizePx={sizePx}
          color={color}
        />
      ) : (
        <Icon
          icon={iconClosed}
          sizePx={sizePx}
          color={color}
        />
      )}
    </DropdownIndicator>
  );
};

export default SingleSelectDropdownIndicator;
