import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import FormTitleInput from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/FormTitleInput';
import FormTextareaInput from '~/neo-ui/packages/form/packages/form-input/packages/form-textarea-input/FormTextareaInput';
import { css } from '@emotion/react';
import React from 'react';
import { EngagementNoteFormDataType } from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/types/EngagementNoteFormDataType';

const titleFieldKey: FieldKeyExpression<EngagementNoteFormDataType> = values => values.title;

const bodyFieldKey: FieldKeyExpression<EngagementNoteFormDataType> = values => values.body;

const EngagementNoteFieldsFormModule = () => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      gap: 1rem;
    `}
  >
    <FormTitleInput
      fieldKey={titleFieldKey}
      placeholder={'Title'}
      size={'lg'}
      showFormError={false}
    />
    <FormTextareaInput
      textAreaCss={css`
        min-height: 20.3125rem;
      `}
      fieldKey={bodyFieldKey}
      placeholder={'Body'}
      showFormError={false}
      optimizePerformance={false}
    />
  </div>
);

export default EngagementNoteFieldsFormModule;
