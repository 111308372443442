import { css } from '@emotion/react';
import * as React from 'react';
import { HeaderGroup } from 'react-table';
import { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type TableFooterProps<T extends Record<string, unknown>> = {
  footerGroups: HeaderGroup<T>[];
  hasBackgroundColor?: boolean;
  isDisplayDataOnly?: boolean;
  isVerticallyAligned?: boolean;
};

const TableFooter = <T extends Record<string, unknown>>({
  footerGroups,
  hasBackgroundColor = false,
  isDisplayDataOnly = false,
  isVerticallyAligned,
}: TableFooterProps<T>) => (
  <tfoot
    css={css`
      background-color: ${hasBackgroundColor ? colorToCode('light-000') : colorToCode('light-300')};
    `}
  >
    {footerGroups.map(group => (
      // eslint-disable-next-line react/jsx-key
      <tr {...group.getFooterGroupProps()}>
        {group.headers.map(column => (
          // eslint-disable-next-line react/jsx-key
          <td
            {...column.getFooterProps()}
            css={css`
              padding: 0.5rem;
              ${isVerticallyAligned && 'vertical-align: middle !important;'}
              ${isDisplayDataOnly ? '&:last-child { text-align: right; > div { justify-content: flex-end; }}' : ''}
              border-top: 1px solid ${colorToCode('dark-900-12')} !important;
              ${(column as unknown as DataTableColumn<T>).ellipsizeTextContent
                ? `
                            white-space: nowrap;
                            overflow: hidden;
                            max-width: 0;
                            text-overflow: ellipsis;`
                : ''}
            `}
          >
            {column.render('Footer')}
          </td>
        ))}
      </tr>
    ))}
  </tfoot>
);

export default TableFooter;
