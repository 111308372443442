import React from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import { css } from '@emotion/react';
import useTicketFieldAvailabilitiesContext from '~/wm/packages/integration/packages/ticket/context/hooks/useTicketFieldAvailabilitiesContext';

export type TicketOrganizationRegistrationRequiredModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

const TicketOrganizationRegistrationRequiredModal = ({ isOpen, onDismiss }: TicketOrganizationRegistrationRequiredModalProps) => {
  const { organizationName } = useOrganizationContext();
  const { integrationUrl } = useTicketFieldAvailabilitiesContext();

  return (
    <ModalConfirmation
      isOpen={isOpen}
      theme={'primary'}
      header={{
        title: 'Could not locate client in ConnectWise Manage',
      }}
      footer={{
        confirmationButtonAlignment: 'left',
        confirmButton:
          typeof integrationUrl !== 'undefined'
            ? {
                label: 'Open ConnectWise Manage',
                icon: 'GoExternal',
                anchor: {
                  href: integrationUrl,
                  openInNewTab: true,
                },
              }
            : undefined,
        dismissButton: {
          label: 'Close',
          icon: 'ActionClose',
          onClick: onDismiss,
        },
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        `}
      >
        <Label>It looks like this client isn't in your ConnectWise Manage account, which is required to create a ticket.</Label>

        <Label>Add {organizationName} as a company in ConnectWise Manage to begin creating tickets.</Label>

        <Label>If this issue persists, please contact support.</Label>
      </div>
    </ModalConfirmation>
  );
};

export default TicketOrganizationRegistrationRequiredModal;
