import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import Button from '~/neo-ui/packages/button/Button';
import * as React from 'react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Spinner from '~/neo-ui/spinner/Spinner';

export type WizardPageSubmitButtonProps = {
  disabled?: boolean;
  label: string;
  icon: IconType;
};

const WizardPageSubmitButton = ({ disabled: manuallyDisabled, label, icon }: WizardPageSubmitButtonProps) => {
  const { isSubmitting, submitForm } = useFormContext();

  const disabled = isSubmitting || manuallyDisabled;

  return (
    <Button
      disabled={disabled}
      theme={disabled ? undefined : 'positive'}
      onClick={submitForm}
      iconLeft={icon}
    >
      {!isSubmitting ? label : <Spinner size={'sm'} />}
    </Button>
  );
};

export default WizardPageSubmitButton;
