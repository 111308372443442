import { css } from '@emotion/react';
import { Enum as PaymentMethodEnum } from '@SubscriptionClient/BeastClient/Beast/Accounting/Model/Payment/Method/PaymentMethodNested.gen';
import * as React from 'react';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import BillingInfo from '~/wm/packages/subscription/packages/billing/packages/billing-info/BillingInfo';
import { BillingAddressDto } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import formatBillingAddressDto from '~/wm/packages/subscription/packages/billing/operation/formatBillingAddressDto';

export type BillingSummaryProps = {
  billingAddress: BillingAddressDto;
  billingPaymentMethod: PaymentMethodEnum;
  editBillingLink: AnchorLocation;
};

const BillingSummary: React.FunctionComponent<BillingSummaryProps> = ({ billingAddress, billingPaymentMethod, editBillingLink }) => (
  <div>
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <div
        css={css`
          font-size: 1.125rem;
          font-weight: bold;
          margin-bottom: 1.25rem;
        `}
      >
        Billed to
      </div>
      <ButtonLink
        size={'sm'}
        anchor={editBillingLink}
      >
        Edit billing info
      </ButtonLink>
    </div>
    <BillingInfo
      billingName={billingAddress.companyName}
      paymentMethod={billingPaymentMethod}
      billingAddressFormatted={formatBillingAddressDto(billingAddress)}
    />
  </div>
);

export default BillingSummary;
