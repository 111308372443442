import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Card from '~/legacy-ui/packages/card/Card';
import FriendlyDateTimeLine from '~/neo-ui/packages/date/packages/friendly-date-time-line/FriendlyDateTimeLine';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Notification from '~/wm/packages/notification/model/Notification';
import ToastContent from '~/neo-ui/packages/toast/packages/toast-content/ToastContent';

export type NotificationCardProps = {
  /**
   * One of the user's notifications
   */
  notification: Notification;
  /**
   * Trigger when a notification is mark as read
   */
  onMarkAsRead: (notificationId: string) => void;
} & Styleable;

const NotificationCard: React.FunctionComponent<NotificationCardProps> = ({ notification, onMarkAsRead, className }) => (
  <Card
    padding={'md'}
    shadow={'none'}
    backgroundColor={notification.isRead ? 'light-200' : 'primary-400'}
    className={className ? className : ''}
  >
    <Label
      size={'sm'}
      muted={true}
      color={notification.isRead ? 'dark-900' : 'light-000'}
      css={css`
        margin-bottom: 0.9375rem;
      `}
    >
      <FriendlyDateTimeLine date={notification.sentAt} />
    </Label>
    <ToastContent
      title={notification.content.title}
      description={notification.content.description}
      icon={notification.content.icon}
      fontColorOverride={notification.isRead ? undefined : 'light-000'}
      buttonLeft={{
        children: notification.content.callToAction.label,
        iconRight: 'GoRight',
        anchor: {
          href: notification.content.callToAction.url,
          openInNewTab: false,
        },
      }}
      buttonRight={
        notification.isRead
          ? undefined
          : {
              children: 'Mark read',
              iconRight: 'Success',
              onClick: () => onMarkAsRead(notification.notificationUniqueId),
            }
      }
    />
  </Card>
);

export default NotificationCard;
