import { Enum as PaymentMethodEnum } from '@SubscriptionClient/BeastClient/Beast/Accounting/Model/Payment/Method/PaymentMethodNested.gen';
import * as React from 'react';
import ValidationErrorMapper from '~/neo-ui/packages/form/packages/form-action/packages/form-validation/ValidationErrorMapper';
import { WizardContainerStep } from '~/neo-ui/packages/wizard/packages/wizard-container/packages/step/WizardContainerStep';
import BillingStepSection from '~/wm/packages/subscription/packages/manage/wizard/step/section/BillingStepSection';
import CreditCardStepSection from '~/wm/packages/subscription/packages/manage/wizard/step/section/CreditCardStepSection';
import { BillingInfoStepFieldKeys } from '../model/BillingInfoStepFieldKeys';
import BillingInfoStepSummary from './summary/BillingInfoStepSummary';
import { PaymentVersion } from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';

const getBillingInfoStep = <T,>(
  header: { label: string; description: string },
  paymentMethod: PaymentMethodEnum,
  paymentVersion: PaymentVersion,
  billingInfoStepFieldKeys: BillingInfoStepFieldKeys<T>,
  validationErrorMapper: ValidationErrorMapper,
): WizardContainerStep<T> => ({
  stepLabel: 'billing info',
  header,
  summary: {
    label: 'Billed to',
    content: (
      <BillingInfoStepSummary
        paymentMethod={paymentMethod}
        billingInfoStepFieldKeys={billingInfoStepFieldKeys}
      />
    ),
  },
  sections: [
    // eslint-disable-next-line react/jsx-key
    <BillingStepSection billingStepSectionFieldKeys={billingInfoStepFieldKeys.billingStepSectionFieldKeys} />,
    // eslint-disable-next-line react/jsx-key
    <CreditCardStepSection
      creditCardFieldKey={billingInfoStepFieldKeys.creditCardStepSectionFieldKey}
      paymentVersion={paymentVersion}
    />,
  ],
  fields: [...Object.values(billingInfoStepFieldKeys.billingStepSectionFieldKeys), billingInfoStepFieldKeys.creditCardStepSectionFieldKey],
  validationErrorMapper,
});

export default getBillingInfoStep;
