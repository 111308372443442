import { BillingAddressDto } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import { ComponentPropsWithoutRef } from 'react';
import { getCountries, getSubdivisions } from '~/neo-ui/model/Country';

export interface ReadonlyBillingAddressProps extends ComponentPropsWithoutRef<'div'> {
  billingAddress: BillingAddressDto;
}

const ReadonlyBillingAddress = (props: ReadonlyBillingAddressProps) => {
  const { billingAddress, ...rest } = props;

  const [provinceState] =
    !!billingAddress.provinceStateCode && !!billingAddress.countryCode
      ? getSubdivisions({ subdivisionCode: billingAddress.provinceStateCode, countryCode: billingAddress.countryCode })
      : [];

  const [country] = !!billingAddress.countryCode ? getCountries({ byAlpha2: billingAddress.countryCode }) : [];

  return (
    <div
      css={{ display: 'flex', flexDirection: 'column' }}
      {...rest}
    >
      <span>{billingAddress.companyName}</span>
      <span>{billingAddress.addressLine1}</span>
      {!!billingAddress.addressLine2 && <span>{billingAddress.addressLine2}</span>}
      <span>
        {billingAddress.city} {provinceState?.name} {billingAddress.postalZipCode}
      </span>
      <span>{country?.name}</span>
    </div>
  );
};

export default ReadonlyBillingAddress;
