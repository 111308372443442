import { autoEmailReportRecipientAdd } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useAutoEmailReportListMutation, {
  UseAutoEmailReportListMutationOptions,
} from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportListMutation';
import { AutoEmailReportRecipientCreatePayloadDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Packages/Recipient/Dto/Model.gen';

type AutoEmailReportRecipientAddPayload = {
  organizationId: string;
  autoEmailReportId: string;
  autoEmailReportRecipientAddPayload: AutoEmailReportRecipientCreatePayloadDto;
};

export type UseAutoEmailReportRecipientAdd = {
  addAutoEmailReportRecipient: (
    autoEmailReportRecipientAddPayload: AutoEmailReportRecipientAddPayload,
    isRequestActive?: () => boolean,
  ) => void;
  isAddingAutoEmailReportRecipient: boolean;
};

const useAutoEmailReportRecipientAdd = (options?: UseAutoEmailReportListMutationOptions): UseAutoEmailReportRecipientAdd => {
  const [addAutoEmailReportRecipient, isAddingAutoEmailReportRecipient] = useAutoEmailReportListMutation(
    (autoEmailReportRecipientAddPayload: AutoEmailReportRecipientAddPayload) =>
      autoEmailReportRecipientAdd({
        autoEmailReportId: autoEmailReportRecipientAddPayload.autoEmailReportId,
        organizationId: autoEmailReportRecipientAddPayload.organizationId,
        createPayload: autoEmailReportRecipientAddPayload.autoEmailReportRecipientAddPayload,
      }),
    options,
  );

  return {
    addAutoEmailReportRecipient,
    isAddingAutoEmailReportRecipient,
  };
};

export default useAutoEmailReportRecipientAdd;
