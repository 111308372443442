import { SVGProps, forwardRef, memo } from 'react';

const PlusIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 11V6H11V11H6V13H11V18H13V13H18V11H13Z"
          fill="currentcolor"
        />
      </svg>
    );
  }),
);

export default PlusIcon;
