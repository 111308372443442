import { TableClientSideColumn } from '~/neo-ui/packages/table/packages/table-client-side/TableClientSide';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import * as React from 'react';
import FeatureBooleanLabel from '~/wm/packages/documentation/packages/supported-integrations/packages/FeatureBooleanLabel';
import TwoWaySyncLabel from '~/wm/packages/documentation/packages/supported-integrations/packages/TwoWaySyncLabel';
import {
  camelToLabel,
  IntegrationEnhanced,
} from '~/wm/packages/documentation/packages/supported-integrations/packages/IntegrationDocumentationUtil';
import routes from '~/router/types/routes';
import Badge from '~/neo-ui/packages/badge/Badge';

export function getIntegrationListColumns(
  types: string[],
  supportedIntegrationsDetailsUrlTemplate: string,
): TableClientSideColumn<IntegrationEnhanced>[] {
  const typeColumns: TableClientSideColumn<IntegrationEnhanced>[] = Array.from(types).map(type => {
    return {
      fieldKey: integration => integration.integration.types[type].isSupported,
      header: camelToLabel(type),
      renderCell: ({ original: integration }) => {
        return (
          <FeatureBooleanLabel
            value={integration.integration.types[type].isSupported}
            fields={integration.integration.types[type].fields}
          />
        );
      },
      enableSorting: true,
      enableGlobalFilter: false,
      widthRem: 6.25,
    };
  });

  return [
    {
      fieldKey: integration => integration.integration.name,
      header: 'Name',
      renderCell: ({ original: integration }) => {
        return (
          <Label>
            <a href={supportedIntegrationsDetailsUrlTemplate.replace('__INTEGRATION_ID__', integration.integration.enum)}>
              {integration.integration.name}
              {integration.integration.isDevMode && (
                <div
                  css={css`
                    display: inline-block;
                    margin-left: 0.5rem;
                  `}
                >
                  <Badge height={'1.25rem'}>BETA</Badge>
                </div>
              )}
            </a>
          </Label>
        );
      },
      enableSorting: true,
      enableGlobalFilter: true,
    },
    {
      fieldKey: integration => integration.formerNamesJoined,
      header: 'Former names',
      renderCell: ({ original: integration }) => {
        return (
          <ul
            css={css`
              margin-bottom: 0;
              list-style-type: none;
              padding: 0;
            `}
          >
            {integration.integration.formerNames.map(formerName => (
              <li key={formerName}>
                <Label>{formerName}</Label>
              </li>
            ))}
          </ul>
        );
      },
      enableSorting: false,
      enableGlobalFilter: true,
    },
    {
      fieldKey: integration => integration.supportsTwoWaySync,
      header: 'Two-way Sync',
      renderCell: ({ original: integration }) => {
        return (
          <TwoWaySyncLabel
            supportsPurchaseDate={integration.integration.supportsPurchaseDateTwoWaySync}
            supportsWarrantyExpiryDate={integration.integration.supportsWarrantyExpiryDateTwoWaySync}
          />
        );
      },
      enableSorting: true,
      enableGlobalFilter: false,
      widthRem: 6.25,
    },
    {
      fieldKey: integration => integration.supportsContactDeviceLink,
      header: 'Supports Person Assigned field',
      renderCell: ({ original: integration }) => {
        return <FeatureBooleanLabel value={integration.supportsContactDeviceLink} />;
      },
      enableSorting: true,
      enableGlobalFilter: false,
      widthRem: 6.25,
    },
    ...typeColumns,
    {
      fieldKey: integration => integration.supportsSoftware,
      header: 'Software',
      renderCell: ({ original: integration }) => {
        return <FeatureBooleanLabel value={integration.supportsSoftware} />;
      },
      enableSorting: true,
      enableGlobalFilter: false,
      widthRem: 6.25,
    },
    {
      fieldKey: integration => integration.integration.supportsMultipleSetups,
      header: (
        <Tooltip
          content={<p>Must be enabled by Success the first time a partner wants to add more than 1 instance of an integration</p>}
          placement={'bottom'}
        >
          <span
            css={css`
              text-decoration: underline dotted;
            `}
          >
            Allows multiple
          </span>
        </Tooltip>
      ),
      renderCell: ({ original: integration }) => {
        return <FeatureBooleanLabel value={integration.integration.supportsMultipleSetups} />;
      },
      enableSorting: true,
      enableGlobalFilter: false,
      widthRem: 6.25,
    },
    {
      fieldKey: integration => integration.integration.helpCenterUrlPath,
      header: 'Help Center',
      renderCell: ({ original: integration }) => {
        return (
          <Label>
            <a href={routes.HELP_CENTER_ARTICLE.build([integration.integration.helpCenterUrlPath])}>Link</a>
          </Label>
        );
      },
      enableSorting: false,
      widthRem: 6.25,
    },
  ];
}
