// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


export interface AreaChartDto
{
  /**
   * A list of all the data points on the chart
   */
  dots: Dot[];
  /**
   * The color of the area on the area chart
   */
  areaColor: string;
  /**
   * The color of the chart line that follows the dots
   */
  strokeColor: string;
  /**
   * The label for the y-axis
   */
  yAxisLabel: string;
  /**
   * The type of x-axis (Numeric, DateTime, Category)
   */
  xAxisType: AxisType;
}


export enum AxisType
{
  Numeric = "Numeric",
  DateTime = "DateTime",
  Category = "Category",
}



export interface Dot
{
  x: string;
  y: number;
}

