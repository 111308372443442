import { cartEstimateAdd, cartEstimateGet } from '@WarrantyClient/WarrantyClientMsp.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import { useEffect, useState } from 'react';
import { Response as WarrantyClientWarrantyWarrantyCartPackagesEstimateGetControllerResponse } from '@WarrantyClient/Warranty/WarrantyCart/Packages/Estimate/Get/Controller.gen';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { Enum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import { Enum as WarrantyType } from '@WarrantyClient/BeastClient/Goods/WarrantyType/WarrantyTypeFactoryNested.gen';

export type UseEstimateCartGet = {
  cartEstimate: WarrantyClientWarrantyWarrantyCartPackagesEstimateGetControllerResponse | undefined;
  isEstimateLoading: boolean;
};

const createScope = (organizationId: string): FrontendScope => {
  return {
    type: Enum.Organization,
    organizationId,
  };
};
const useEstimateCartGet = (
  organizationId: string,
  warrantyType: WarrantyType | undefined,
  expandLocation: boolean,
  deviceId: string | undefined,
): UseEstimateCartGet => {
  const [cartEstimate, setCartEstimate] = useState<WarrantyClientWarrantyWarrantyCartPackagesEstimateGetControllerResponse>();
  const [isEstimateLoading, setIsEstimateLoading] = useState(true);
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      setIsEstimateLoading(true);
      const response = await callApi(() =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        deviceId
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            cartEstimateAdd({ organizationId, warrantyType, scope: createScope(organizationId), deviceId })
          : cartEstimateGet({ organizationId, warrantyType, scope: createScope(organizationId), expandLocation }),
      );
      if (!response) {
        return;
      }
      setCartEstimate(response);
      setIsEstimateLoading(false);
    })();
  }, [callApi, deviceId, expandLocation, organizationId, warrantyType]);

  return {
    cartEstimate,
    isEstimateLoading,
  };
};

export default useEstimateCartGet;
