import { CostTypeEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Model/BudgetLineItemNested.gen';
import { css } from '@emotion/react';
import { getIn } from 'formik';
import { produce } from 'immer';
import React from 'react';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { useFormInput } from '~/neo-ui/packages/form/hooks/useFormInput';
import FormTitleInput from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/FormTitleInput';
import InteractiveItem from '~/neo-ui/packages/interactive-item/InteractiveItem';
import SelectGroup from '~/neo-ui/packages/select/packages/select-group/SelectGroup';
import { FieldKeyExpressionSegment, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Testable from '~/neo-ui/packages/testable/Testable';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { FrequencyEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Recurring/Model/RecurringLineItemNested.gen';
import FormNumberInput from '~/neo-ui/packages/form/packages/form-input/packages/form-number-input/FormNumberInput';
import {
  InitiativeSaveFormData,
  RecurringLineItemFormData,
} from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import useProposalBuilderGrid from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/hooks/useProposalBuilderGrid';

export type FeesRecurringProps = {
  lineItem: RecurringLineItemFormData;
  lineItemIndex: number;
  onRemove: () => void;
  currencyIcon: string;
  allowFocus: boolean;
};

const FeesRecurringRow: React.FunctionComponent<FeesRecurringProps> = ({ lineItem, lineItemIndex, onRemove, currencyIcon, allowFocus }) => {
  const [recurringLineItemsFormInput, _, { setValue: setRecurringLineItemsFormData, setTouched: setTouchedRecurringLineItemsFormData }] =
    useFormInput<InitiativeSaveFormData, RecurringLineItemFormData[]>(formData => formData.recurringLineItems);
  const recurringLineItems = recurringLineItemsFormInput.value;

  const setCurrentLineItem = (setter: (lineItem: RecurringLineItemFormData) => RecurringLineItemFormData) =>
    setRecurringLineItemsFormData(
      produce(recurringLineItems, draft => {
        draft[lineItemIndex] = setter(draft[lineItemIndex]);
      }),
    );

  // Error display logic
  const { errors } = useFormContext();
  const labelError = getIn(
    errors,
    resolveFieldKey((values: FieldKeyExpressionSegment<InitiativeSaveFormData>) => values.recurringLineItems[lineItemIndex].label),
  );
  const costUnitsError = getIn(
    errors,
    resolveFieldKey((values: FieldKeyExpressionSegment<InitiativeSaveFormData>) => values.recurringLineItems[lineItemIndex].costUnits),
  );
  const errorExists = typeof labelError !== 'undefined' || typeof costUnitsError !== 'undefined';

  const { gridFrs } = useProposalBuilderGrid('md');

  return (
    <Testable testId={'fees-recurring-section-row'}>
      <InteractiveItem
        css={css`
          align-items: unset;
          padding-bottom: 0.625rem;
          margin-bottom: 0.625rem;

          // Grow and shrink line item controls on focus
          ${errorExists ? 'max-height: 4rem' : 'max-height: 3rem'};
          overflow: hidden;

          :focus-within {
            max-height: unset;
          }
        `}
      >
        <ButtonModifier
          icon={'Remove'}
          onClick={() => {
            setTouchedRecurringLineItemsFormData(false);
            onRemove();
          }}
          css={css`
            margin-top: 0.7rem;
          `}
        />
        <div
          css={css`
            margin-top: 0.375rem;
            display: grid;
            grid-template-columns: ${gridFrs.outerFr};
            gap: 0.625rem;
            flex-grow: 1;
          `}
        >
          <div
            css={css`
              display: grid;
              gap: 0.625rem;
              grid-template-columns: ${gridFrs.innerLeftFr};
            `}
          >
            <FormTitleInput
              optimizePerformance={true}
              placeholder={'Line item...'}
              fieldKey={(values: FieldKeyExpressionSegment<InitiativeSaveFormData>) => values.recurringLineItems[lineItemIndex].label}
              hasDefaultFocus={allowFocus}
              hasDefaultSelect={allowFocus}
            />

            {lineItem.costType === CostTypeEnum.PerAsset && (
              <Label
                muted={true}
                css={css`
                  margin-top: 0.5rem;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                `}
              >
                per asset
              </Label>
            )}

            {lineItem.costType === CostTypeEnum.Fixed && (
              <Label
                muted={true}
                css={css`
                  margin-top: 0.5rem;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                `}
              >
                flat fee
              </Label>
            )}
          </div>
          <div
            css={css`
              display: flex;
              gap: 0.625rem;
            `}
          >
            <FormNumberInput
              placeholder={'Estimated cost...'}
              fieldKey={(values: FieldKeyExpressionSegment<InitiativeSaveFormData>) => values.recurringLineItems[lineItemIndex].costUnits}
              prependCharacter={currencyIcon}
              css={css`
                flex-grow: 1;
              `}
            />
            <Label
              muted={true}
              css={css`
                margin-top: 0.5rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              `}
            >
              recurring {lineItem.frequency.toLowerCase()}
            </Label>
          </div>
          <SelectGroup
            size={'sm'}
            options={[
              {
                value: CostTypeEnum.Fixed,
                label: 'Flat fee',
              },
              {
                value: CostTypeEnum.PerAsset,
                label: 'Per asset',
              },
            ]}
            selectedOptionValue={lineItem.costType}
            onOptionSelected={selectedOption => {
              // Switch line item cost type
              setCurrentLineItem(lineItem =>
                produce(lineItem, draft => {
                  draft.costType = selectedOption.value;
                }),
              );
            }}
          />

          <SelectGroup
            size={'sm'}
            options={[
              {
                value: FrequencyEnum.Monthly,
                label: 'Monthly',
              },
              {
                value: FrequencyEnum.Yearly,
                label: 'Yearly',
              },
            ]}
            selectedOptionValue={lineItem.frequency}
            onOptionSelected={selectedOption => {
              // Switch line item cost type
              setCurrentLineItem(lineItem =>
                produce(lineItem, draft => {
                  draft.frequency = selectedOption.value;
                }),
              );
            }}
          />
        </div>
      </InteractiveItem>
    </Testable>
  );
};
export default FeesRecurringRow;
