/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-misused-promises */
import useComponentProps from '~/wm/packages/dashboard/packages/dashboard-grid/hook/useComponentProps';
import usePolling from '~/extensions/packages/polling/hooks/usePolling';
import { WidgetDto } from '@DashboardClient/Dashboard/Packages/Widget/Packages/Dto/Model.gen';

const useReloadComponentProps = (widgetAvailability: WidgetDto[], reloadInterval: number) => {
  const { componentsProps, reloadComponentProps } = useComponentProps(widgetAvailability);

  // Poll for latest components props
  usePolling(reloadComponentProps, reloadInterval);

  return componentsProps;
};
export default useReloadComponentProps;
