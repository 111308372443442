import { ValueDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model/IntegrationFieldOptionDtoNested.gen';
import { InputTypeNested } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Credential.gen';
import FormSelectInput from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';

import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import buildFromSelectOptions from '~/wm/packages/integration/packages/render-form-integration-field-option/packages/render-form-integration-field-option-input-select/builder/buildFromSelectOptions';

export type RenderFormIntegrationFieldOptionInputSelectProps<TFormData> = {
  fieldKey: FieldKeyExpression<TFormData>;
  data: InputTypeNested.Select;
  values: ValueDto[];
  disabled?: boolean;
};

const RenderFormIntegrationFieldOptionInputSelect = <TFormData,>({
  fieldKey,
  values,
  disabled = false,
}: RenderFormIntegrationFieldOptionInputSelectProps<TFormData>) => {
  const options = buildFromSelectOptions(values);

  return (
    <FormSelectInput
      fieldKey={fieldKey}
      options={options}
      disabled={disabled}
    />
  );
};

export default RenderFormIntegrationFieldOptionInputSelect;
