import Text from '~/sp-ui/text';
import { LineItemTooltip } from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/subscription-summary/LineItemTooltip';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';

export type ExpenseLineItemProps = {
  itemName: string;
  itemTooltips: string[];
  price: number;
  displayChangePlan: boolean;
};

const ExpenseLineItem = ({ itemName, itemTooltips, price, displayChangePlan }: ExpenseLineItemProps) => {
  const { goToPage, formik } = useMultiStepForm<PurchaseSubscriptionForm>();

  const renderSingleInclusion = (inclusion: string) => {
    return <Text css={{ maxWidth: '288px', display: 'block', whiteSpace: 'wrap', overflow: 'hidden' }}>{inclusion}</Text>;
  };

  const renderMultipleInclusions = (inclusions: string[]) => {
    return (
      <ul css={{ padding: 0, margin: 0, listStylePosition: 'inside' }}>
        {inclusions.map((inclusion, index) => (
          <li key={index}>{inclusion}</li>
        ))}
      </ul>
    );
  };

  return (
    <div css={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px', fontWeight: '400', marginBottom: '0.5rem' }}>
      <div css={{ alignItems: 'center' }}>
        <span>{itemName}</span>
        {itemTooltips.length > 0 && (
          <LineItemTooltip css={{ width: '1rem', height: '1rem', marginLeft: '0.5rem' }}>
            {itemTooltips.length === 1 ? renderSingleInclusion(itemTooltips[0]) : renderMultipleInclusions(itemTooltips)}
          </LineItemTooltip>
        )}
        {displayChangePlan && (
          <span
            css={{ cursor: 'pointer', marginLeft: '0.5rem', textDecoration: 'underline', color: `${colorToCode('primary-400')}` }}
            onClick={() => {
              formik.setFieldValue('setupIntent', undefined);
              formik.setFieldValue('couponCodeError', undefined);
              goToPage(0);
            }}
          >
            Change Plan
          </span>
        )}
      </div>
      <span>{formatCurrency(price, 'USD', 2)}</span>
    </div>
  );
};

export default ExpenseLineItem;
