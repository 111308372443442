import * as React from 'react';
import CoverageTransferWizard from '~/wm/packages/warranty/packages/coverage/packages/coverage-transfer/wizard/CoverageTransferWizard';
import useBillingInfo from '~/wm/packages/subscription/packages/billing/hooks/useBillingInfo';
import useServiceRequestGet from '~/wm/packages/warranty/packages/service-request/hooks/useServiceRequestGet';
import EmailProvider from '~/wm/packages/product/context/EmailProvider';

export type CoverageTransferPageProps = {
  serviceRequestId: string;
  disposalOrderListUrl: string;
  hasDisposalLocationAccess: boolean;
  supportEmail: string;
};

const CoverageTransferPage = ({
  serviceRequestId,
  disposalOrderListUrl,
  hasDisposalLocationAccess,
  supportEmail,
}: CoverageTransferPageProps) => {
  const { billingInfo } = useBillingInfo();
  const { serviceRequest } = useServiceRequestGet(serviceRequestId);

  if (typeof serviceRequest === 'undefined' || typeof billingInfo === 'undefined') {
    return null;
  }

  return (
    <EmailProvider supportEmail={supportEmail}>
      <CoverageTransferWizard
        serviceRequest={serviceRequest}
        billingInfo={billingInfo}
        disposalOrderListUrl={disposalOrderListUrl}
        hasDisposalLocationAccess={hasDisposalLocationAccess}
      />
    </EmailProvider>
  );
};

export default CoverageTransferPage;
