import { HardwareDetails } from '@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Details/Model.gen';
import { Enum as HardwareStatusEnum } from '@AssetManagementClient/BeastClient/Beast/Asset/Model/Device/HardwareStatusState/HardwareStatusStateFactoryNested.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import ListItem from '~/legacy-ui/packages/list/packages/list-item/ListItem';
import { ButtonProps } from '~/neo-ui/packages/button/Button';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import HardwareInfoCard from '~/wm/packages/hardware/packages/hardware-info-card/HardwareInfoCard';
import Color from '~/neo-ui/packages/color/Color.gen';

export type HardwareWarrantyInfoProps = {
  resultantHardwareAsset: HardwareDetails;
  warrantiesCartLink: string;
  getServiceLink: string;
};

const HardwareWarrantyInfo: React.FunctionComponent<HardwareWarrantyInfoProps> = ({
  resultantHardwareAsset,
  warrantiesCartLink,
  getServiceLink,
}) => {
  let actionButtonTexts: string[] | undefined = [];
  let actionButtons: ButtonProps[] | undefined = [];
  if (resultantHardwareAsset.assetAccountObfuscatedId && resultantHardwareAsset.isCoveredByInAppWarranty) {
    actionButtonTexts.push('Get service');
    actionButtons.push({
      size: 'md',
      iconLeft: 'GetService',
      theme: 'positive',
      anchor: {
        href: getServiceLink.replace('__ASSET_ACCOUNT_OBFUSCATED_ID__', resultantHardwareAsset.assetAccountObfuscatedId),
      },
    });
  }
  if (resultantHardwareAsset.canBuyWarranties) {
    actionButtonTexts.push('Buy warranty');
    actionButtons.push({
      size: 'md',
      iconLeft: 'Buy',
      theme: 'primary',
      anchor: {
        href: warrantiesCartLink.replace('__ORGANIZATION_ID__', resultantHardwareAsset.organizationId),
      },
    });
  }
  if (actionButtons.length <= 0) {
    actionButtons = undefined;
    actionButtonTexts = undefined;
  }

  return (
    <HardwareInfoCard
      padding={'lg'}
      header={{
        title: 'Warranty',
        icon: hardwareStatusToDisplayDetails(resultantHardwareAsset.warrantyExpiryStatus).icon,
        color: hardwareStatusToDisplayDetails(resultantHardwareAsset.warrantyExpiryStatus).iconColor,
      }}
      actionButtons={actionButtons}
      actionButtonTexts={actionButtonTexts}
    >
      <div
        css={css`
          > *:not(:last-child) {
            margin-bottom: 1.25rem;
          }
        `}
      >
        <ListItem
          title="Asset purchased"
          description={resultantHardwareAsset.purchaseDate ? resultantHardwareAsset.purchaseDate : 'Unknown'}
          descriptionMuted={!resultantHardwareAsset.purchaseDate}
        />
        <ListItem
          title={hardwareStatusToDisplayDetails(resultantHardwareAsset.warrantyExpiryStatus).infoTitle}
          description={resultantHardwareAsset.expiryDate ? resultantHardwareAsset.expiryDate : 'Unknown'}
          descriptionMuted={!resultantHardwareAsset.expiryDate}
          theme={hardwareStatusToDisplayDetails(resultantHardwareAsset.warrantyExpiryStatus).theme}
        />
      </div>
    </HardwareInfoCard>
  );
};

type HardwareStatusDisplayDefinition = {
  infoTitle: string;
  theme: Color | undefined;
  iconColor: Color;
  icon: IconType | undefined;
};

const hardwareStatusToDisplayDetails = (status: HardwareStatusEnum): HardwareStatusDisplayDefinition => {
  switch (status) {
    case HardwareStatusEnum.In:
      return {
        infoTitle: 'Warranty expires',
        theme: undefined,

        iconColor: 'positive-400',
        icon: 'CoverageSupported',
      };
    case HardwareStatusEnum.Soon:
      return {
        infoTitle: 'Warranty expires soon',
        theme: 'warning-400',

        iconColor: 'warning-400',
        icon: 'CoverageEndingSoon',
      };
    case HardwareStatusEnum.Out:
      return {
        infoTitle: 'Warranty expired',
        theme: 'negative-400',

        iconColor: 'negative-400',
        icon: 'CoverageExpired',
      };
    case HardwareStatusEnum.Unknown:
      return {
        infoTitle: 'Warranty expiry',
        theme: undefined,

        iconColor: 'light-700',
        icon: undefined,
      };
  }
};

export default HardwareWarrantyInfo;
