import { FeatureAccessOrganizationDto } from '@BeastClient/Beast/Feature/Packages/Access/Packages/AccessOrganization/Dto.gen';
import { createContext } from 'react';
import { FeatureAccessAvailabilities, FeaturePermissionAvailabilities } from '~/wm/packages/feature/hooks/useFeatureAvailabilities';

export type FeatureAccessKey =
  | 'scorecard-roadmap'
  | 'client-focus'
  | 'enceladus'
  | 'plan-essential'
  | 'plan-pro'
  | 'disposal'
  | 'infrastructure-protection';

export type FeaturePermissionKey =
  | 'assessment-full-access'
  | 'budget-forecast-full-access'
  | 'business-review-full-access'
  | 'account-team-and-key-contract-full-access'
  | 'account-team-and-key-contact-view'
  | 'action-note-and-items-full-access'
  | 'contract-full-access'
  | 'console-full-columns'
  | 'custom-hardware-report-download'
  | 'custom-hardware-report-schedule'
  | 'custom-insight-full-access'
  | 'custom-insight-view'
  | 'disposal-purchase'
  | 'disposal-view'
  | 'dmi-full-access'
  | 'hardware-lifecycle-report-download'
  | 'infrastructure-protection-purchase'
  | 'infrastructure-protection-view'
  | 'infrastructure-protection-discount-purchase'
  | 'infrastructure-protection-discount-view'
  | 'organization-star-full-access'
  | 'qbr-full-access'
  | 'roadmap-full-access'
  | 'sam-full-access'
  | 'scheduled-hardware-lifecycle-report-full-access'
  | 'scheduled-hardware-lifecycle-report-view'
  | 'two-way-sync-full-access'
  | 'two-way-sync-view'
  | 'user-console-full-access'
  | 'workstation-assurance-purchase'
  | 'workstation-assurance-view'
  | 'workstation-assurance-discount-purchase'
  | 'workstation-assurance-discount-view';

export type FeatureAccountCheck<T extends string> = (featureKey: T) => boolean;
export type FeatureOrganizationCheck<T extends string> = (featureKey: T, organizationId: string) => boolean;

export type FeatureAvailabilitiesContextData = {
  featureAccessAvailabilities: FeatureAccessAvailabilities | undefined;
  /**
   * Check if the feature is available across the entire account
   */
  hasFeatureAccessAccount: FeatureAccountCheck<FeatureAccessKey>;
  /**
   * Check if the feature is available to the organization or the entire account
   */
  hasFeatureAccessOrganization: FeatureOrganizationCheck<FeatureAccessKey>;
  featurePermissionAvailabilities: FeaturePermissionAvailabilities | undefined;
  /**
   * Check if the feature permission is available across the entire account
   */
  hasFeaturePermissionAccount: FeatureAccountCheck<FeaturePermissionKey>;
  /**
   * Check if the feature permission is available to the organization or the entire account
   */
  hasFeaturePermissionOrganization: FeatureOrganizationCheck<FeaturePermissionKey>;
  /**
   * The unlocked organization, if any
   */
  unlockedFeatureAccessOrganization: FeatureAccessOrganizationDto | undefined;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const FeatureAvailabilitiesContext = createContext<FeatureAvailabilitiesContextData>({
  featureAccessAvailabilities: {},
  hasFeatureAccessAccount: () => false,
  hasFeatureAccessOrganization: () => false,
  featurePermissionAvailabilities: {},
  hasFeaturePermissionAccount: () => false,
  hasFeaturePermissionOrganization: () => false,
  unlockedFeatureAccessOrganization: undefined,
});

export default FeatureAvailabilitiesContext;
