import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { InitiativeSaveFormData } from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import React, { useMemo } from 'react';
import { FrequencyEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Recurring/Model/RecurringLineItemNested.gen';
import { CostTypeEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Model/BudgetLineItemNested.gen';
import { formatCurrencyByCurrencyInfo } from '~/extensions/packages/currency/formatCurrency';
import useAccount from '~/wm/hooks/useAccount';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Divider from '~/neo-ui/packages/divider/Divider';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';
import Box from '~/neo-ui/packages/box/Box';
import FeesViewSection from '~/wm/packages/strategy/packages/initiative/packages/initiative-view/packages/initiative-view-fees-section/packages/fees-view-section/FeesViewSection';

const InitiativeViewFeesRecurring = () => {
  const { formData } = useFormContext<InitiativeSaveFormData>();
  const { currencyCode, subunitRatio } = useAccount();

  const totalCostRecurringFees = useMemo<Record<FrequencyEnum, string>>(() => {
    // Calculate totals of monthly and yearly recurring fees
    const totalCostRecurringFees = formData.recurringLineItems.reduce<Record<FrequencyEnum, number>>(
      (record, line) => {
        const amount = isNaN(line.costUnits)
          ? 0
          : line.costType === CostTypeEnum.Fixed
          ? Number(line.costUnits)
          : Number(line.costUnits) * formData.assetIds.length;

        return {
          ...record,
          [line.frequency]: record[line.frequency] + amount,
        };
      },
      { Monthly: 0, Yearly: 0 },
    );

    const currency = {
      code: currencyCode,
      subunitsPerUnit: subunitRatio,
    };

    // Format total as a currency
    return {
      Monthly: formatCurrencyByCurrencyInfo(totalCostRecurringFees.Monthly * subunitRatio, currency),
      Yearly: formatCurrencyByCurrencyInfo(totalCostRecurringFees.Yearly * subunitRatio, currency),
    };
  }, [currencyCode, formData.assetIds.length, formData.recurringLineItems, subunitRatio]);

  return (
    <Box
      borderRadius={'radius300'}
      background={'theme'}
      css={css`
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
        padding: 0.5rem 1rem;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 1rem;
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 0.5rem;
          `}
        >
          <Header
            size={4}
            weight={'bold'}
          >
            Recurring fees
          </Header>
          <Tooltip
            content={
              <Label>New ongoing costs incurred as part of this Initiative that aren't part of the client's one-time investment.</Label>
            }
          >
            <Icon
              icon={'Info'}
              sizePx={16}
              css={css`
                display: flex;
              `}
            />
          </Tooltip>
        </div>
        <FeesViewSection
          lineItems={formData.recurringLineItems.map(lineItem => ({ costSubunits: lineItem.costUnits, label: lineItem.label }))}
        />
      </div>
      <div
        css={css`
          margin-top: auto;
        `}
      >
        <Divider />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
            `}
          >
            <Header
              size={5}
              weight={'bold'}
            >
              Monthly Fees
            </Header>
            <Header
              size={5}
              weight={'bold'}
              css={css`
                margin-left: auto;
              `}
            >
              {totalCostRecurringFees.Monthly}
            </Header>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: row;
            `}
          >
            <Header
              size={5}
              weight={'bold'}
            >
              Annual Fees
            </Header>
            <Header
              size={5}
              weight={'bold'}
              css={css`
                margin-left: auto;
              `}
            >
              {totalCostRecurringFees.Yearly}
            </Header>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default InitiativeViewFeesRecurring;
