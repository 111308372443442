import useEngagementNotePanelTabMutation, {
  UseOrganizationEngagementNotePanelTabMutationOptions,
} from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/context/hooks/useEngagementNotePanelTabMutation';
import { organizationEngagementNoteUnpin } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseEngagementNoteDashboardPin = {
  unpinNote: (engagementNoteId: string) => void;
  isUnpinningNote: boolean;
};

const useEngagementNoteDashboardUnpin = (options?: UseOrganizationEngagementNotePanelTabMutationOptions): UseEngagementNoteDashboardPin => {
  const [unpinNote, isUnpinningNote] = useEngagementNotePanelTabMutation(
    (engagementNoteId: string) =>
      organizationEngagementNoteUnpin({
        engagementNoteId,
      }),
    options,
  );

  return {
    unpinNote,
    isUnpinningNote,
  };
};

export default useEngagementNoteDashboardUnpin;
