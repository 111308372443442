import { useContext } from 'react';
import WizardContainerContext, {
  WizardContainerContextValue,
} from '~/neo-ui/packages/wizard/packages/wizard-container/context/WizardContainerContext';

const useWizardContainerContext = (): WizardContainerContextValue => {
  const wizardContext = useContext(WizardContainerContext);

  if (!wizardContext) {
    throw new Error('Called useWizardContainerContext outside of a WizardContainerContext');
  }

  return wizardContext;
};

export default useWizardContainerContext;
