import * as React from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import useInsightDelete from '~/wm/packages/strategy/packages/insight/hooks/useInsightDelete';

export type InsightDeleteModalProps = {
  isOpen: boolean;
  insightId: string;
  insightName: string;
  onDismiss: () => void;
  refreshAction: () => void;
};

const InsightDeleteModal: React.FunctionComponent<InsightDeleteModalProps> = ({
  isOpen,
  insightId,
  insightName,
  onDismiss,
  refreshAction,
}) => {
  const { deleteInsight, isDeletingInsight, isDeleted } = useInsightDelete();
  const triggerDelete = async () => {
    const deleteFinished = await deleteInsight(insightId);
    if (deleteFinished) {
      refreshAction();
    }
  };

  return (
    <ModalConfirmation
      header={{ title: 'Delete custom insight?', icon: 'Trash' }}
      theme={'negative'}
      footer={{
        confirmButton: {
          icon: 'Trash',
          label: 'Delete',
          disabled: false,
          loading: isDeletingInsight,
          onClick: triggerDelete,
        },
        dismissButton: {
          icon: 'Cancel',
          label: 'Cancel',
          onClick: onDismiss,
        },
      }}
      isOpen={isOpen && !isDeleted}
    >
      Your {insightName} insight will be permanently deleted. Your data won't be affected.
    </ModalConfirmation>
  );
};

export default InsightDeleteModal;
