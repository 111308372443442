import * as React from 'react';
import useResizeObserver from '@react-hook/resize-observer';

// https://www.robinwieruch.de/react-custom-hook-check-if-overflow/
export const useIsOverflow = <T extends HTMLElement>(target: React.RefObject<T>) => {
  const [isOverflow, setIsOverflow] = React.useState<boolean | undefined>(undefined);

  React.useLayoutEffect(() => {
    setIsOverflow(target?.current ? target.current.scrollHeight > target.current.clientHeight : false);
  }, [target]);

  useResizeObserver(target, _ => {
    setIsOverflow(target?.current ? target.current.scrollHeight > target.current.clientHeight : false);
  });

  return isOverflow;
};
