import { useContext } from 'react';
import PeopleDashboardContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/context/PeopleDashboardContext';

const usePeopleDashboardContext = () => {
  const peopleDashboardContext = useContext(PeopleDashboardContext);
  if (!peopleDashboardContext) {
    throw new Error('Tried to use PeopleDashboardContext outside of an PeopleDashboardContext');
  }

  return peopleDashboardContext;
};

export default usePeopleDashboardContext;
