import { InsightStateDtoNested } from '@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Model.gen';
import { Styleable } from '~/neo-ui/model/capacity';
import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import { css } from '@emotion/react';
import Testable from '~/neo-ui/packages/testable/Testable';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Image from '~/neo-ui/packages/image/Image';
import Color from '~/neo-ui/packages/color/Color.gen';

export type StrategyInsightDashboardInitializingProps = {
  insightTitle: string;
  initializingState: InsightStateDtoNested.Initializing;
} & Styleable;

/**
 * Insights are never in a state of initializing from the backend, so this component is never used.
 */
const StrategyInsightDashboardInitializing: React.FunctionComponent<StrategyInsightDashboardInitializingProps> = ({
  insightTitle,
  className,
  initializingState,
}) => (
  <Card
    shadow="sm"
    className={className}
    padding={'sm'}
    backgroundColor={'secondary-050'}
    css={css`
      width: 12.5rem;
      height: 12.5rem;
    `}
  >
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      `}
    >
      <div
        css={css`
          height: 100%;
        `}
      >
        <Testable testId={'insight-item-title'}>
          <Header
            size={5}
            color={initializingState.foregroundColor as Color}
            css={css`
              margin-top: 0.3125rem;
            `}
          >
            {insightTitle}
          </Header>
        </Testable>
        <Label
          size={'md'}
          muted={true}
        >
          Getting info...
        </Label>
        <Image
          css={css`
            width: 80px;
            height: 80px;
            position: absolute;
            bottom: 0.625rem;
          `}
          source={'/i/graphic/strategy/insight/secondary.png'}
        />
      </div>
    </div>
  </Card>
);

export default StrategyInsightDashboardInitializing;
