import { css } from '@emotion/react';
import { parseISO } from 'date-fns';
import * as React from 'react';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import DataTable, { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import TableCell from '~/neo-ui/packages/table/packages/table-cell/TableCell';
import useDisposalOrderList from '~/wm/packages/disposal/packages/order-list/hooks/useDisposalOrderList';
import { DisposalOrderOverviewDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Dto/Model.gen';
import Color from '~/neo-ui/packages/color/Color.gen';
import useDisposalOrderPdfs from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderPdfs';
import Button from '~/neo-ui/packages/button/Button';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import DisposalTreeImpactAccountSummaryCard from '~/wm/packages/disposal/packages/tree-impact/packages/disposal-tree-impact-account-summary-card/DisposalTreeImpactAccountSummaryCard';
import DisposalTreeImpactAccountSummaryProvider from '~/wm/packages/disposal/packages/tree-impact/context/DisposalTreeImpactAccountSummaryProvider';
import DisposalTreeImpactIntroCard from '~/wm/packages/disposal/packages/tree-impact/packages/disposal-tree-impact-intro-card/DisposalTreeImpactIntroCard';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import Testable from '~/neo-ui/packages/testable/Testable';

export type DisposalOrderListPageProps = {
  disposalOrderInfoViewUrl: string;
  treeImpactHelpGuideUrl: string;
};

const DisposalOrderListPage: React.FunctionComponent<DisposalOrderListPageProps> = ({
  disposalOrderInfoViewUrl,
  treeImpactHelpGuideUrl,
}) => {
  const { disposalOrderOverviews } = useDisposalOrderList();
  const { downloadDisposalOrderPdfs } = useDisposalOrderPdfs();
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  const disposalOrderListColumns = React.useMemo<DataTableColumn<DisposalOrderOverviewDto>[]>(
    () => [
      {
        fieldKey: disposalOrderOverview => disposalOrderOverview.disposalOrderId,
        Header: 'Order id',
        renderCell: disposalOrderOverview => (
          <TableCell
            label={disposalOrderOverview.disposalOrderId}
            labelUrl={disposalOrderInfoViewUrl.replace('__ORDER_ID__', disposalOrderOverview.disposalOrderId)}
          />
        ),
      },
      {
        fieldKey: disposalOrderOverview => disposalOrderOverview.createdAt,
        Header: 'Created at',
        renderCell: disposalOrderOverview => (
          <TableCell
            label={formatDate(parseISO(disposalOrderOverview.createdAt), {
              format: 'yyyy-MM-dd',
              timezone: TimezoneFormat.Utc,
            })}
          />
        ),
      },
      {
        fieldKey: disposalOrderOverview => disposalOrderOverview.assetCount,
        Header: 'Assets',
        renderCell: disposalOrderOverview => <TableCell label={disposalOrderOverview.assetCount} />,
      },
      {
        fieldKey: disposalOrderOverview => disposalOrderOverview.totalCostInUsdDisplay,
        Header: 'Total costs',
        renderCell: disposalOrderOverview => <TableCell label={disposalOrderOverview.totalCostInUsdDisplay} />,
      },
      {
        fieldKey: disposalOrderOverview => disposalOrderOverview.status.displayLabel,
        Header: 'Status',
        renderCell: disposalOrderOverview => (
          <TableCell
            label={disposalOrderOverview.status.displayLabel}
            labelColor={disposalOrderOverview.status.displayColor as Color}
            description={disposalOrderOverview.status.displayDescription}
            descriptionMuted={true}
            icon={disposalOrderOverview.status.displayIcon as IconType}
            iconColor={disposalOrderOverview.status.displayColor as Color}
          />
        ),
      },
      {
        fieldKey: disposalOrderOverview => disposalOrderOverview.completedAt,
        Header: 'Certificates of Disposal',
        renderCell: disposalOrderOverview =>
          typeof disposalOrderOverview.completedAt !== 'undefined' ? (
            <Button
              iconLeft={'PageZip'}
              onClick={() => {
                downloadDisposalOrderPdfs(disposalOrderOverview.disposalOrderId);

                trackEventProcessor('certificate_of_disposal_downloaded', {
                  disposalOrderId: disposalOrderOverview.disposalOrderId,
                });
              }}
              theme={'primary'}
              disabled={disposalOrderOverview.hasError}
            >
              Download ZIP
            </Button>
          ) : (
            <TableCell label={'-'} />
          ),
      },
    ],
    [disposalOrderInfoViewUrl, downloadDisposalOrderPdfs, trackEventProcessor],
  );

  if (typeof disposalOrderOverviews === 'undefined') {
    return null;
  }

  return (
    <div>
      <Testable testId={'disposal-order-list-page'}>
        <div
          css={css`
            display: flex;
            margin: 0.3125rem;
          `}
        >
          <h2>Disposal orders</h2>
        </div>
        <Testable testId={'disposal-order-list-table'}>
          <DataTable
            columns={disposalOrderListColumns}
            data={disposalOrderOverviews}
            EmptyStatePlaceholder={'No Disposal order yet for your account'}
          />
        </Testable>
        {disposalOrderOverviews.length > 0 ? (
          <DisposalTreeImpactAccountSummaryCard
            css={css`
              margin-top: 1.25rem;
            `}
            helpGuideUrl={treeImpactHelpGuideUrl}
          />
        ) : (
          <DisposalTreeImpactAccountSummaryProvider>
            <DisposalTreeImpactIntroCard helpGuideUrl={treeImpactHelpGuideUrl} />
          </DisposalTreeImpactAccountSummaryProvider>
        )}
      </Testable>
    </div>
  );
};

export default DisposalOrderListPage;
