import * as React from 'react';
import useScorecardContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardContext';
import BannerNotice from '~/neo-ui/packages/banner/packages/banner-notice/BannerNotice';

const ScorecardWelcomeBanner = () => {
  const { scorecard, organizationName } = useScorecardContext();

  if (scorecard.hasBeenEdited) {
    return null;
  }

  return (
    <BannerNotice
      title={`Welcome to your Scorecard for ${organizationName}. We’ve started you off with 4 categories based on key Insights.`}
      description={
        'Scorecards allow you to get aligned with clients on the current state of their environment and locate the key areas of opportunity. Feel free to adjust and add additional Scorecard categories.'
      }
      imageSource={'/i/graphic/strategy/scorecard/secondary.png'}
    />
  );
};

export default ScorecardWelcomeBanner;
