import Button from '~/neo-ui/packages/button/Button';
import Window from '~/neo-ui/packages/window/Window';
import * as React from 'react';
import EngagementNoteFieldsFormModule from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/packages/engagement-note-fields-form-module/EngagementNoteFieldsFormModule';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { EngagementNoteDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Dto/Model.gen';
import { EngagementNoteFormDataType } from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/types/EngagementNoteFormDataType';

export type EngagementNoteEditWindowProps = {
  note: EngagementNoteDto;
  isOpen: boolean;
  isArchivingNote: boolean;
  onDismiss: () => void;
  onNoteArchive: (engagementNoteId: string) => void;
  onNoteUnarchive: (engagementNoteId: string) => void;
};

const EngagementNoteEditWindow = ({
  note,
  isOpen,
  isArchivingNote,
  onDismiss,
  onNoteArchive,
  onNoteUnarchive,
}: EngagementNoteEditWindowProps) => {
  const { submitForm, isSubmitting, isValid, addAfterSubmitListener } = useFormContext<EngagementNoteFormDataType>();

  React.useEffect(
    () =>
      addAfterSubmitListener(async () => {
        onDismiss();
      }),
    [addAfterSubmitListener, onDismiss],
  );

  return (
    <Window
      title={'Edit Note'}
      isOpen={isOpen}
      onDismiss={onDismiss}
      footerProps={{
        leftControls: [
          {
            expanded: (
              <Button
                iconLeft={'Archive'}
                onClick={() => {
                  if (note.isArchived) {
                    onNoteUnarchive(note.engagementNoteId);
                  } else {
                    onNoteArchive(note.engagementNoteId);
                  }
                }}
                loading={isArchivingNote}
              >
                Archive This Note
              </Button>
            ),
          },
        ],
        rightControls: [
          {
            expanded: (
              <Button
                loading={isSubmitting}
                disabled={!isValid || isArchivingNote}
                size={'sm'}
                theme={'primary'}
                onClick={() => {
                  submitForm();
                }}
              >
                Save
              </Button>
            ),
          },
          {
            expanded: (
              <Button
                size={'sm'}
                disabled={isSubmitting || isArchivingNote}
                onClick={onDismiss}
              >
                Cancel
              </Button>
            ),
          },
        ],
      }}
    >
      <EngagementNoteFieldsFormModule />
    </Window>
  );
};

export default EngagementNoteEditWindow;
