import * as React from 'react';
import { useIntegrationVendors } from '~/wm/packages/integration/packages/scalepad-account/packages/add-integration-page/hooks/useIntegrationVendors';
import IntegrationList from '~/wm/packages/integration/packages/scalepad-account/packages/add-integration-page/IntegrationList';
import {
  IntegrationToolTypeDto,
  IntegrationVendorDto,
} from '@AssetManagementClient/BeastClient/Beast/Integration/Controller/IntegrationPage.gen';
import { css, Global } from '@emotion/react';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import FilterIntegrationType from '~/wm/packages/integration/packages/scalepad-account/packages/add-integration-page/packages/filters/packages/filter-integration-type/FilterIntegrationType';
import { SearchBar } from '~/wm/packages/integration/packages/scalepad-account/packages/add-integration-page/SearchBar';
import FilterIntegrationDataTypes from '~/wm/packages/integration/packages/scalepad-account/packages/add-integration-page/packages/filters/packages/filter-integration-data-types/FilterIntegrationDataTypes';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

type FilterFunction = (integrationVendor: IntegrationVendorDto) => boolean;

const AddIntegrationPage: React.FunctionComponent = () => {
  const defaultFilters = new Map<string, FilterFunction>([
    ['integrationType', () => true],
    ['publisher', () => true],
    ['integrationDataTypes', () => true],
  ]);

  const [filterStates, setFilterStates]: [Map<string, FilterFunction>, React.Dispatch<React.SetStateAction<Map<string, FilterFunction>>>] =
    React.useState(defaultFilters);

  const integrationVendorResponse = useIntegrationVendors();

  if (typeof integrationVendorResponse === 'undefined') {
    return <></>;
  }

  const {
    integrationVendorDtos: integrationVendors,
    integrationToolTypeDtos: integrationToolTypes,
    integrationDataTypeDtos: integrationDataTypes,
  } = integrationVendorResponse;

  const convertIntegrationVendorsToDisplayDict = (vendors: IntegrationVendorDto[] | undefined) => {
    if (vendors === undefined) {
      return {} as { [key: string]: IntegrationVendorDto[] };
    }
    return vendors.reduce(
      (dict, integrationVendor) => ({
        ...dict,
        [integrationVendor.toolType]: [...(dict[integrationVendor.toolType] || []), integrationVendor],
      }),
      {} as { [key: string]: IntegrationVendorDto[] },
    );
  };

  const convertIntegrationToolTypesToDisplayDict = (toolTypes: IntegrationToolTypeDto[] | undefined) => {
    if (toolTypes === undefined) {
      return {} as { [key: string]: IntegrationToolTypeDto };
    }
    return toolTypes.reduce((dictionary, toolTypes) => {
      // eslint-disable-next-line no-param-reassign
      dictionary[toolTypes.label] = toolTypes;
      return dictionary;
    }, {} as { [key: string]: IntegrationToolTypeDto });
  };

  let visibleIntegrationVendors = [...integrationVendors];

  filterStates.forEach((filterFunction, _filterKey) => {
    visibleIntegrationVendors = [...visibleIntegrationVendors.filter(integrationVendor => filterFunction(integrationVendor))];
  });

  const allSupportedToolTypes = Object.values(integrationToolTypes)
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map(
      toolTypeDto =>
        ({
          label: toolTypeDto.label,
          value: toolTypeDto.label,
        } as SelectOption),
    );
  const allSupportedDataTypes = Object.values(integrationDataTypes)
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map(
      dataTypeDto =>
        ({
          label: dataTypeDto.label,
          value: dataTypeDto.label,
        } as SelectOption),
    );

  const toolTypeToIntegrationVendorDictionary = convertIntegrationVendorsToDisplayDict(visibleIntegrationVendors);

  const toolTypesDisplayInfo = convertIntegrationToolTypesToDisplayDict(integrationToolTypes);

  return (
    <article>
      <Global
        styles={css`
          #wm-content {
            background-color: ${colorToCode('light-200')};
          }
        `}
      />

      {/* Search bar */}
      <SearchBar setFilterStates={setFilterStates} />
      {/* Filter bar */}
      <div
        css={css`
          display: flex;
          row-gap: 0.625rem;
          column-gap: 0.375rem;
          margin-bottom: 1.25rem;
          flex-wrap: wrap;
        `}
      >
        <FilterIntegrationType
          name={'Type'}
          allOptions={allSupportedToolTypes}
          filterStatePayload={{
            filterKey: 'integrationType',
            setFilterFunction: setFilterStates,
          }}
        />
        <FilterIntegrationDataTypes
          name={'Data types'}
          allOptions={allSupportedDataTypes}
          filterStatePayload={{
            filterKey: 'integrationDataTypes',
            setFilterFunction: setFilterStates,
          }}
        />
        {/*  Product filter can go here when the integration center supports more than one product (see FilterProductType.tsx) */}
      </div>
      <IntegrationList
        forceExpansion={filterStates.has('search')}
        toolTypeToIntegrationVendorDictionary={toolTypeToIntegrationVendorDictionary}
        toolTypesDisplayInfo={toolTypesDisplayInfo}
      />
    </article>
  );
};

export default AddIntegrationPage;
