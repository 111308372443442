import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { InputTitleSizes } from '~/neo-ui/packages/input/packages/input-title/InputTitle';
import InputNumberStepper from '~/neo-ui/packages/input/packages/input-number-stepper/InputNumberStepper';
import FormInputTextInternal from '~/neo-ui/packages/form/packages/form-input/FormInputTextInternal';
import useFormInputBuilder from '~/neo-ui/packages/form/packages/form-input/hooks/useFormInputBuilder';

export type FormNumberStepperInputProps<T> = {
  fieldKey: FieldKeyExpression<T>;
  placeholder?: string;
  prependCharacter?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  isInvalid?: boolean;
  size?: InputTitleSizes;
} & Styleable;

const FormNumberStepperInput = <T,>({
  fieldKey,
  placeholder,
  prependCharacter,
  disabled = false,
  min,
  max,
  isInvalid = false,
  size,
  className,
}: FormNumberStepperInputProps<T>) => {
  const { touched, error, inputValue } = useFormInputBuilder<T>(fieldKey);
  const value = Number(inputValue);
  const isValueInvalid = isNaN(value) || value < 1 || (typeof error !== 'undefined' && touched);

  return (
    <FormInputTextInternal
      fieldKey={fieldKey}
      className={className}
    >
      {({ field, setFieldValue }) => (
        <InputNumberStepper
          value={Number(field.value)}
          placeholder={placeholder}
          prependCharacter={prependCharacter}
          disabledInput={disabled}
          disabledButtonRemove={(typeof min !== 'undefined' && value <= min) || disabled}
          disabledButtonAdd={(typeof max !== 'undefined' && value >= max) || disabled}
          isInvalid={isInvalid || isValueInvalid}
          size={size}
          onChange={value => {
            if (isNaN(value)) {
              return;
            }

            setFieldValue(value.toString());
          }}
          onRemoveClick={() => {
            // Guard against surpassing min value.
            if (typeof min !== 'undefined' && value <= min) {
              return;
            }

            setFieldValue((isValueInvalid || value === min ? 1 : value - 1).toString());
          }}
          onAddClick={() => {
            // Guard against surpassing max value.
            if (typeof max !== 'undefined' && value >= max) {
              return;
            }

            setFieldValue((isValueInvalid ? 1 : value + 1).toString());
          }}
          className={className}
        />
      )}
    </FormInputTextInternal>
  );
};

export default FormNumberStepperInput;
