import { css } from '@emotion/react';
import * as React from 'react';
import { CurrencyCode } from '~/extensions/packages/currency/formatCurrency';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import FormSliderInput from '~/neo-ui/packages/form/packages/form-input/packages/form-slider-input/FormSliderInput';
import SubscriptionPdContactSection from '~/wm/packages/subscription/packages/subscription-pd-contact/packages/subscription-pd-contact-section/SubscriptionPdContactSection';
import {
  SelfServeType,
  SubscriptionAgreementEnum,
  SubscriptionAgreementInfo,
} from '~/wm/packages/subscription/packages/manage/wizard/model/SubscriptionAgreementInfo';
import ProductSkuSummary from '~/wm/packages/subscription/packages/plan/packages/sku-summary/ProductSkuSummary';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';
import { FieldKeyExpression, toFieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { ProductAvailabilityPayload } from '@SubscriptionClient/Subscription/Packages/Upgrade/Model.gen';
import ProductChargeItem from '~/wm/packages/subscription/packages/plan/packages/product-summary/ProductChargeItem';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import { ProductTierIds } from '~/wm/packages/subscription/packages/upgrade/wizard/SubscriptionUpgradeWizard';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import Testable from '~/neo-ui/packages/testable/Testable';
import useWizardContainerContext from '~/neo-ui/packages/wizard/packages/wizard-container/context/hooks/useWizardContainerContext';
import { Enum as SubscriptionClientBeastClientBeastMspModelSubscriptionProductProductFactoryNestedEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription/Product/ProductFactoryNested.gen';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { SubscriptionAgreementData } from '~/wm/packages/subscription/packages/manage/wizard/step/getSubscriptionAgreementStep';
import ProductAdoptionRegionSelector from '~/wm/packages/subscription/packages/manage/wizard/step/section/ProductConfiguration/ProductAdoptionRegionSelector';

export type ProductTierStepSectionProps<T> = {
  productAvailabilities: ProductAvailabilityPayload;
  agreementInfo: SubscriptionAgreementInfo;
  currencyCode: CurrencyCode;
  fieldKey: FieldKeyExpression<T>;
  availableProducts: SubscriptionClientBeastClientBeastMspModelSubscriptionProductProductFactoryNestedEnum[];
  invalidTiers: string[];
  enterpriseEditionAvailable?: boolean;
};

const ProductTierStepSection = <T extends SubscriptionAgreementData>({
  productAvailabilities,
  agreementInfo,
  currencyCode,
  fieldKey,
  availableProducts,
  invalidTiers,
  enterpriseEditionAvailable,
}: ProductTierStepSectionProps<T>) => {
  const { formData, setFormInput } = useFormContext<T>();

  const { setFieldCompleted } = useWizardContainerContext();

  const enabledByDefault = productAvailabilities.enabledByDefault;
  const [productEnabled, setProductEnabled] = React.useState(enabledByDefault || productAvailabilities.isPreselected);

  const [selectedTier, setSelectedTier] = React.useState(() => {
    switch (agreementInfo.case) {
      case SubscriptionAgreementEnum.Preset:
        return productAvailabilities.availableTiers[0];
      case SubscriptionAgreementEnum.SelfServe: {
        let availableTiers = null;
        if (productAvailabilities.availableTiers.length > 0) {
          availableTiers =
            agreementInfo.type === SelfServeType.Grow ? productAvailabilities.availableTiers[0] : productAvailabilities.availableTiers[1];
        }
        setFormInput<string | undefined>(fieldKey, availableTiers?.tierId);
        return availableTiers;
      }
    }
  });

  const selectableTiers = productAvailabilities.availableTiers.map(tier => tier.tierId);
  if (enterpriseEditionAvailable) {
    selectableTiers.push('');
  }

  const productLearnMoreLink: AnchorLocation = {
    href: productAvailabilities.productLearnMoreLink,
    openInNewTab: true,
  };

  const validateForm = React.useCallback(() => {
    const tiersToGrow: string[] = [];
    availableProducts.map(productEnum => {
      const selectedProductTierId = formData.selectedTierIds[productEnum.toString()];
      if (selectedProductTierId && !invalidTiers.includes(selectedProductTierId)) {
        tiersToGrow.push(selectedProductTierId);
      }
    });

    const isAllRegionSelected = availableProducts.every(
      productEnum =>
        formData.selectedProductRegions[productEnum.toString()] !== 'UNSELECTED' || formData.selectedTierIds[productEnum.toString()] === '',
    );

    if (tiersToGrow.length > 0 && isAllRegionSelected) {
      // Every field needs to be set to completed to enable proceed button
      availableProducts.forEach(productEnum => {
        setFieldCompleted(toFieldKeyExpression(productEnum), true);
      });
    } else {
      availableProducts.forEach(productEnum => {
        setFieldCompleted(toFieldKeyExpression(productEnum), false);
      });
    }
  }, [availableProducts, formData, invalidTiers, setFieldCompleted]);

  React.useEffect(() => {
    if (selectedTier !== null && productEnabled && selectedTier.tierId !== formData.selectedTierIds[selectedTier.productEnum.toString()]) {
      const current = formData.selectedTierIds;
      current[selectedTier.productEnum.toString()] = selectedTier.tierId;
      setFormInput<ProductTierIds>(toFieldKeyExpression('selectedTierIds'), current);
    }
    validateForm();
  }, [formData.selectedTierIds, productEnabled, selectedTier, setFormInput, validateForm]);

  return (
    <Testable testId={'wizard-product-tier'}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          text-align: left;
        `}
      >
        <img
          src={productAvailabilities.icon}
          css={css`
            height: 3rem;
            align-self: flex-start;
            padding: 0 0 0.5rem 0;
          `}
        />
        {productAvailabilities.productAdoptionInfo.defaultEditionLabel && (
          <span
            css={css`
              color: ${colorToCode('dark-900-32')};
              padding: 0 0 0.85rem 0;
            `}
          >
            {' '}
            {productAvailabilities.productAdoptionInfo.defaultEditionLabel}
          </span>
        )}
        {productAvailabilities.productAdoptionInfo.productAdditionSummary && (
          <div
            css={css`
              padding: 0 0 0.85rem 0;
            `}
          >
            {productAvailabilities.productAdoptionInfo.productAdditionSummary}
          </div>
        )}
        {!enabledByDefault && (
          <div
            css={css`
              display: flex;
              padding: 0 0 0.4rem 0;
            `}
          >
            <Testable testId={`${productAvailabilities.productEnum.toString()}-upgrade-checkbox`}>
              <InputCheckbox
                css={css`
                  align-items: flex-start;
                  padding-top: 0.25rem;
                `}
                checked={productEnabled}
                onClick={() => {
                  setProductEnabled(!productEnabled);
                  if (productEnabled) {
                    if (formData !== undefined) {
                      const current = formData.selectedTierIds;
                      current[productAvailabilities.productEnum.toString()] = '';
                      setFormInput<ProductTierIds>(toFieldKeyExpression('selectedTierIds'), current);
                    }
                  } else {
                    if (formData !== undefined) {
                      const current = formData.selectedTierIds;
                      let defaultTier = '';
                      if (productAvailabilities.availableTiers.length > 0) {
                        defaultTier =
                          agreementInfo.case === SubscriptionAgreementEnum.SelfServe && agreementInfo.type === SelfServeType.Grow
                            ? productAvailabilities.availableTiers[0].tierId
                            : productAvailabilities.availableTiers[1].tierId;
                      }
                      current[productAvailabilities.productEnum.toString()] = defaultTier;
                      setFormInput<ProductTierIds>(toFieldKeyExpression('selectedTierIds'), current);
                    }
                  }
                }}
              />
            </Testable>
            <span>{productAvailabilities.productAdoptionInfo.checkboxLabel}</span>
          </div>
        )}
        {!productEnabled && (
          <ButtonLink
            css={css`
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              max-width: 9.15rem;
            `}
            size={'sm'}
            anchor={productLearnMoreLink}
            iconRight={'GoExternal'}
            leftAlign={true}
          >
            Learn more
          </ButtonLink>
        )}
        {productEnabled && (
          <div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 1.25rem;
              `}
            >
              {agreementInfo.case === SubscriptionAgreementEnum.SelfServe && (
                <FormSliderInput
                  fieldKey={fieldKey}
                  values={selectableTiers}
                  onChange={tierId => {
                    const tier = productAvailabilities.availableTiers.find(tier => tier.tierId === tierId);
                    if (tier !== undefined) {
                      setSelectedTier(tier);
                    }
                  }}
                  css={css`
                    margin-top: 1.75rem;
                  `}
                />
              )}
            </div>
            {selectedTier ? (
              <ProductSkuSummary
                productSkuInfo={selectedTier}
                currencyCode={currencyCode}
                displayCurrencyCode={true}
              />
            ) : (
              enterpriseEditionAvailable && <SubscriptionPdContactSection reasonToContact={'To talk about enterprise solutions'} />
            )}
            {productAvailabilities.productRegionConfiguration ? (
              <ProductAdoptionRegionSelector regionConfig={productAvailabilities.productRegionConfiguration} />
            ) : null}
            {productAvailabilities.chargeItems.length ? (
              <div>
                <hr
                  css={css`
                    margin-top: 0.625rem;
                    margin-bottom: 1rem;
                    color: ${colorToCode('dark-900-12')};
                  `}
                />
              </div>
            ) : null}
            <ProductChargeItem
              productLabel={productAvailabilities.label}
              chargeDtos={productAvailabilities.chargeItems}
            />
          </div>
        )}
      </div>
    </Testable>
  );
};

export default ProductTierStepSection;
