// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


/**
 * Duplicated in frontend 420B178C-4554-4195-8EF3-A55E8530E0B1
 */
export enum Enum
{
  Sam = "Sam",
  ProFeatures = "ProFeatures",
  CustomerSuccess = "CustomerSuccess",
  Air = "Air",
}


