import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { GroupBase } from 'react-select';

export const shouldShowSearch = <T extends string = string>(
  selectOptionsOrGroupBase: SelectOption<T>[] | GroupBase<SelectOption<T>>[],
): boolean => {
  const minOptionsToShowSearch = 5;

  if (selectOptionsOrGroupBase.length === 0) {
    return false;
  }
  if (isGroupBaseArray(selectOptionsOrGroupBase)) {
    return (
      (selectOptionsOrGroupBase as GroupBase<SelectOption<T>>[]).reduce((acc, o) => o.options.length + acc, 0) > minOptionsToShowSearch
    );
  }
  return (selectOptionsOrGroupBase as SelectOption<T>[]).length > minOptionsToShowSearch;
};

const isGroupBaseArray = <T extends string = string>(options: SelectOption<T>[] | GroupBase<SelectOption<T>>[]) =>
  (options[0] as GroupBase<SelectOption<T>>).options !== undefined;
