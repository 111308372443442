import { css } from '@emotion/react';
import * as React from 'react';
import ListCardGroup from '~/legacy-ui/packages/list/packages/list-card/packages/list-card-group/ListCardGroup';
import Button from '~/neo-ui/packages/button/Button';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Organization from '~/wm/packages/organization/model/Organization';
import OrganizationLinkItem from '~/wm/packages/organization/packages/linking/packages/link-item/OrganizationLinkItem';
import OrganizationLink from '../../model/OrganizationLink';

export type OrganizationLinkListProps = {
  parentOrganization: Organization;
  linkedOrganizations: OrganizationLink[];
  onUnlink: (organizationToUnlink: OrganizationLink) => void;
};

const OrganizationLinkList: React.FunctionComponent<OrganizationLinkListProps> = ({
  parentOrganization,
  linkedOrganizations,
  onUnlink,
}) => (
  <ListCardGroup
    type="linked"
    bgColor="light-200"
  >
    {_ => (
      <>
        {linkedOrganizations
          .sort((a, b) => (a.id === parentOrganization.id ? -1 : b.id === parentOrganization.id ? 1 : 0))
          .map(organization =>
            organization.id === parentOrganization.id ? (
              <OrganizationLinkItem
                key={organization.id}
                actionElement={
                  <Tooltip
                    content="You can't unlink a client from itself"
                    css={css`
                      width: 178px;
                    `}
                  >
                    <Button
                      disabled={true}
                      key={organization.id}
                      iconLeft={'LinkBroken'}
                      theme={'negative'}
                      size={'sm'}
                    />
                  </Tooltip>
                }
                organization={organization}
              />
            ) : (
              <OrganizationLinkItem
                key={organization.id}
                actionElement={
                  <Button
                    key={organization.id}
                    iconLeft={'LinkBroken'}
                    theme={'negative'}
                    size={'sm'}
                    onClick={() => onUnlink(organization)}
                  />
                }
                organization={organization}
              />
            ),
          )}
      </>
    )}
  </ListCardGroup>
);

export default OrganizationLinkList;
