import { useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { coverageListSpreadsheet } from '@WarrantyClient/WarrantyClientMsp.gen';

type UseDownloadAssetSpreadsheet = () => {
  downloadCoveredAssetsSpreadsheet: () => void;
};

export const useDownloadCoveredAssetsSpreadsheet: UseDownloadAssetSpreadsheet = () => {
  const { callApi } = useApi();
  const downloadCoveredAssetsSpreadsheet = useCallback(async () => {
    const stream = await callApi(() => coverageListSpreadsheet({}));

    if (!stream) {
      return;
    }
  }, [callApi]);

  return { downloadCoveredAssetsSpreadsheet };
};

export default useDownloadCoveredAssetsSpreadsheet;
