import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import PeopleDashboardDropdownOptionAccountTeamMember from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/packages/people-dashboard-dropdown/packages/people-dashboard-dropdown-account-team-member/packages/people-dashboard-dropdown-option-account-team-member/PeopleDashboardDropdownOptionAccountTeamMember';
import * as React from 'react';
import DisableElementOverlay from '~/neo-ui/packages/overlay/DisableElementOverlay';
import Button from '~/neo-ui/packages/button/Button';
import AccountTeamMemberAddWindow from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/packages/people-dashboard-dropdown/packages/people-dashboard-dropdown-account-team-member/packages/account-team-member-add-window/AccountTeamMemberAddWindow';
import PeopleDashboardDropdown from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/packages/people-dashboard-dropdown/PeopleDashboardDropdown';
import usePeopleDashboardContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/context/hooks/usePeopleDashboardContext';
import PeopleDashboardDropdownMenuHeaderAccountTeamMember from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/packages/people-dashboard-dropdown/packages/people-dashboard-dropdown-account-team-member/packages/people-dashboard-dropdown-menu-header-account-team-member/PeopleDashboardDropdownMenuHeaderAccountTeamMember';
import useActiveUsersContext from '~/wm/packages/account/packages/user/context/hooks/useActiveUsersContext';
import formatUserNameDisplay from '~/wm/packages/account/packages/user/packages/active-users-search/formatUserNameDisplay';

export type PeopleDashboardDropdownAccountTeamMemberProps = {
  hasManageMembersPermission: boolean;
  membersUrl: string;
};

const PeopleDashboardDropdownAccountTeamMember = ({
  hasManageMembersPermission,
  membersUrl,
}: PeopleDashboardDropdownAccountTeamMemberProps) => {
  const { users } = useActiveUsersContext();
  const { organizationAccountTeamMembers } = usePeopleDashboardContext();
  const [isAddingAccountTeamMember, setAddingAccountTeamMember] = React.useState(false);
  const [isMenuOpen, setMenuOpen] = React.useState(false);

  return (
    <PeopleDashboardDropdown
      buttonContent={{
        icon: 'Administrator',
        title: (
          <Label
            color={'dark-700'}
            bold={true}
            size={'md'}
          >
            Account Team
          </Label>
        ),
        description:
          organizationAccountTeamMembers.length > 0 ? (
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <Label
                color={'primary-500'}
                bold={true}
                size={'md'}
              >
                {formatUserNameDisplay(organizationAccountTeamMembers[0])}
              </Label>
              <Label
                color={'dark-900-64'}
                size={'md'}
              >
                and {organizationAccountTeamMembers.length - 1} other
                {organizationAccountTeamMembers.length - 1 === 1 ? '' : 's'}
              </Label>
            </div>
          ) : (
            <Label
              color={'dark-900-64'}
              size={'md'}
            >
              No members assigned
            </Label>
          ),
        onClick: isOpen => {
          setMenuOpen(isOpen);
        },
      }}
      menuContent={{
        isMenuOpen,
        preOptionsSection: (
          <PeopleDashboardDropdownMenuHeaderAccountTeamMember
            membersUrl={membersUrl}
            disabled={!hasManageMembersPermission}
          />
        ),
        options: organizationAccountTeamMembers,
        option: option => (
          <PeopleDashboardDropdownOptionAccountTeamMember
            organizationAccountTeamMember={option}
            hasManageMembersPermission={hasManageMembersPermission}
            onClickEdit={() => {
              setMenuOpen(false);
            }}
          />
        ),
        postOptionsSection: (
          <React.Fragment>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 0.75rem;
              `}
            >
              {hasManageMembersPermission && (
                <DisableElementOverlay disabled={users.length < 1}>
                  {users.length < 1 && (
                    <Label
                      color={'dark-900'}
                      size={'sm'}
                    >
                      We haven't found any team members added yet. To assign members to this client, please add your team members.
                    </Label>
                  )}
                </DisableElementOverlay>
              )}
              <Button
                iconLeft={'Add'}
                size={'xs'}
                disabled={users.length < 1 || !hasManageMembersPermission}
                onClick={() => {
                  setAddingAccountTeamMember(true);
                  setMenuOpen(false);
                }}
                preventOnClickPropagation={true}
              >
                Assign new members
              </Button>
            </div>
            {isAddingAccountTeamMember && (
              <AccountTeamMemberAddWindow
                isOpen={isAddingAccountTeamMember}
                onDismiss={() => {
                  setAddingAccountTeamMember(false);
                }}
              />
            )}
          </React.Fragment>
        ),
      }}
    />
  );
};

export default PeopleDashboardDropdownAccountTeamMember;
