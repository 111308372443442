import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

/**
 * A set of colors synced between frontend and backend
 *
 * **IMPORTANT** If you add a new color here, you must add it to the backend as well
 * In the backend see B4DB32A0-1F1D-4DBC-A1D7-447A457B2707
 */
type ColorApp =
  | 'Caution100'
  | 'Caution300'
  | 'Excellent400'
  | 'Excellent200'
  | 'Info700'
  | 'Primary200'
  | 'Primary500'
  | 'Primary800'
  | 'Positive200'
  | 'Positive400'
  | 'Positive600'
  | 'Positive700'
  | 'Negative200'
  | 'Negative600'
  | 'Secondary200'
  | 'Secondary400'
  | 'Warning400'
  | 'Warning600'
  | 'Dark700'
  | 'Dark90064';

/**
 * Maps a ColorApp to a Color
 *
 * In the future a single ColorApp could have multiple Colors
 */
const colorAppMap: Record<ColorApp, Color> = {
  Caution100: 'caution-100',
  Caution300: 'caution-300',
  Excellent400: 'excellent-400',
  Excellent200: 'excellent-200',
  Info700: 'info-700',
  Primary200: 'primary-200',
  Primary500: 'primary-500',
  Primary800: 'primary-800',
  Positive200: 'positive-200',
  Positive400: 'positive-400',
  Positive600: 'positive-600',
  Positive700: 'positive-700',
  Negative200: 'negative-200',
  Negative600: 'negative-600',
  Secondary200: 'secondary-200',
  Secondary400: 'secondary-400',
  Warning400: 'warning-400',
  Warning600: 'warning-600',
  Dark700: 'dark-700',
  Dark90064: 'dark-900-64',
};

/**
 * Convert a ColorApp to a rgba color to be used in css
 * @param colorApp
 */
export const colorAppToCode = (colorApp: ColorApp) => colorToCode(colorAppMap[colorApp]);

/**
 * Convert a ColorApp to a Color
 * @param colorApp
 */
export const colorAppToColor = (colorApp: ColorApp) => colorAppMap[colorApp];

export default ColorApp;
