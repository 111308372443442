import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { TicketLinkStateDto } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Packages/TicketLink/Dto/Model.gen';
import Color from '~/neo-ui/packages/color/Color.gen';
import { Styleable } from '~/neo-ui/model/capacity';
import { Enum as AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Packages/TicketLink/Dto/Model/TicketLinkStateDtoNested.gen';

export type InitiativeTicketDisplayProps = {
  ticketLinkState: TicketLinkStateDto;
  createdStateColor?: Color;
} & Styleable;

const InitiativeTicketDisplay = ({ ticketLinkState, createdStateColor = 'dark-700', className }: InitiativeTicketDisplayProps) => {
  switch (ticketLinkState.type) {
    case AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Created:
      return (
        <div
          css={css`
            display: flex;
            gap: 0.5rem;
            align-items: center;
          `}
          className={className}
        >
          <Icon
            icon={'IntegrationTicket'}
            color={createdStateColor}
          />
          <Label
            size={'md'}
            color={createdStateColor}
          >
            {ticketLinkState.ticketLink.ticketNumber}
          </Label>
        </div>
      );
    case AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Pending:
      return (
        <div
          css={css`
            display: flex;
            gap: 0.5rem;
            align-items: center;
          `}
          className={className}
        >
          <Icon
            icon={'IntegrationTicket'}
            color={'dark-900-64'}
          />
          <Label
            size={'md'}
            color={'dark-900-64'}
          >
            Pending...
          </Label>
        </div>
      );
    case AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Error:
      return (
        <div
          css={css`
            display: flex;
            gap: 0.5rem;
            align-items: center;
          `}
          className={className}
        >
          <Icon
            icon={'IntegrationTicket'}
            color={'negative-500'}
          />
          <Label
            size={'md'}
            color={'negative-500'}
          >
            Failed
          </Label>
        </div>
      );
    default:
      return null;
  }
};

export default InitiativeTicketDisplay;
