import { borderRadiusToCode } from '~/neo-ui/packages/style/BorderRadius';
import { paddingToCode } from '~/neo-ui/packages/style/Padding';
import { boxShadowToCode } from '~/neo-ui/packages/style/BoxShadow';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

type SingleSelectConsoleStyleProps = {
  height?: string;
  isItemSelected?: boolean;
  isOpen?: boolean;
};

export const customStylesConsole = ({ height = undefined, isItemSelected = true, isOpen = false }: SingleSelectConsoleStyleProps) => {
  const consoleMenu = (provided: Record<string, unknown>) => ({
    ...provided,
    marginTop: '0.313rem',
    right: 0,
    minWidth: '100%',
    padding: `${paddingToCode('padding100')}`,
    borderRadius: `${borderRadiusToCode('radius300')}`, // 8px
    boxShadow: `${boxShadowToCode('shadow400')}`,
  });

  const consoleControl = (provided: Record<string, unknown>) => ({
    ...provided,
    ...(typeof height !== 'undefined' && {
      minHeight: height,
      height,
    }),
    borderRadius: `0.313rem 0.313rem 0.125rem 0.125rem;`, // 8px
    border: `0.125rem solid ${colorToCode('dark-900-12')}`,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&:hover': {
      border: `0.125rem solid ${colorToCode('secondary-300')}`,
      boxShadow: `${boxShadowToCode('shadow100')}`,
      color: `${colorToCode('secondary-700')}`,
      cursor: 'pointer',
    },
    ...(isItemSelected && {
      background: `${colorToCode('secondary-400')}`,
      color: `${colorToCode('light-000-64')}`,
    }),
    ...(isOpen &&
      !isItemSelected && {
        background: `${colorToCode('secondary-050')}`,
      }),
  });

  const consolePlaceholder = (provided: Record<string, unknown>) => ({
    ...provided,
    color: `${colorToCode('dark-700')}`,
    position: 'absolute' as const,
    paddingInlineStart: '1.625rem',
  });

  const consoleOption = (provided: Record<string, unknown>) => ({
    ...provided,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&:hover': {
      backgroundColor: `${colorToCode('secondary-050')}`,
    },
  });

  const consoleValueContainer = (provided: Record<string, unknown>) => ({
    ...provided,
    ...(typeof height !== 'undefined' && {
      height,
    }),
    padding: '0 0 0 0.5rem',
  });

  const consoleSingleValue = (provided: Record<string, unknown>) => ({
    ...provided,
    ...(isOpen && {
      color: `${colorToCode('light-000-64')}`,
      position: 'absolute' as const,
      paddingInlineStart: '1.625rem',
    }),
  });

  const consoleInput = (provided: Record<string, unknown>) => ({
    ...provided,
    ...(isItemSelected && {
      color: `${colorToCode('light-000')}`,
    }),
  });

  return {
    menu: consoleMenu,
    control: consoleControl,
    placeholder: consolePlaceholder,
    option: consoleOption,
    valueContainer: consoleValueContainer,
    singleValue: consoleSingleValue,
    input: consoleInput,
  };
};
