import * as React from 'react';
import { RenderCellDataNested } from '@AssetManagementClient/BeastClient/Renderer/Model/Data.gen';
import RenderCell from '~/neo-ui/packages/table/packages/render-cell-data/RenderCell';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import TableCell from '~/neo-ui/packages/table/packages/table-cell/TableCell';

export type RenderTooltipCellProps = {
  data: RenderCellDataNested.TooltipData;
};

const RenderTooltipCell: React.FunctionComponent<RenderTooltipCellProps> = ({ data: { children, content } }) => {
  const childrenCell = <RenderCell data={children} />;
  const contentCell = <RenderCell data={content} />;
  const label = <Tooltip content={contentCell}>{childrenCell}</Tooltip>;
  return <TableCell label={label} />;
};

export default RenderTooltipCell;
