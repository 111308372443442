import React from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { AccountTeamMemberRoleAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationAccountTeamMember/Packages/AccountTeamMember/Packages/AccountTeamMemberRole/Dto/Model.gen';
import useOrganizationAccountTeamMemberRoleDelete from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/useOrganizationAccountTeamMemberRoleDelete';

export type AccountTeamMemberDeleteRoleModalProps = {
  role: AccountTeamMemberRoleAvailabilityDto;
  isOpen: boolean;
  onDismiss: () => void;
};

const AccountTeamMemberRoleDeleteModal: React.FunctionComponent<AccountTeamMemberDeleteRoleModalProps> = ({ role, isOpen, onDismiss }) => {
  const { deleteOrganizationAccountTeamMemberRole, isDeletingOrganizationAccountTeamMemberRole } =
    useOrganizationAccountTeamMemberRoleDelete();

  const deleteRole = () => {
    deleteOrganizationAccountTeamMemberRole(role.accountTeamMemberRoleId);
    onDismiss();
  };

  return (
    <ModalConfirmation
      header={{
        title: 'Delete this role for all clients?',
        icon: 'Trash',
      }}
      isOpen={isOpen}
      theme={'negative'}
      footer={{
        confirmButton: {
          icon: 'Trash',
          label: 'Remove',
          loading: isDeletingOrganizationAccountTeamMemberRole,
          onClick: deleteRole,
        },
        dismissButton: {
          icon: 'Cancel',
          label: 'Close',
          disabled: isDeletingOrganizationAccountTeamMemberRole,
          onClick: onDismiss,
        },
      }}
    >
      <Label size={'md'}>
        Deleting this role will update all associated client profiles, and all members currently assigned to this role will be impacted.
      </Label>
    </ModalConfirmation>
  );
};

export default AccountTeamMemberRoleDeleteModal;
