import useApi from '~/wm/packages/api/hook/useApi';
import { initiativeEdit } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { InitiativeEditPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';

export type UseInitiativeSave = {
  saveInitiative: (savePayload: SavePayload) => Promise<string | undefined>;
};

type SavePayload = InitiativeEditPayloadDto;

const useInitiativeSave = (onInitiativeSave: (initiativeId: string) => void): UseInitiativeSave => {
  const { callApi } = useApi();

  const saveInitiative = async (savePayload: SavePayload) => {
    const response = await callApi(() => initiativeEdit({ editPayload: { ...savePayload } }));

    if (!response) {
      return undefined;
    }

    onInitiativeSave(response.initiativeId);

    return response.initiativeId;
  };

  return { saveInitiative };
};

export default useInitiativeSave;
