import WarrantyCartCoTerminationCostCalculator from '~/wm/packages/warranty/packages/warranty-cart/calculators/coTermination/WarrantyCartCoTerminationCostCalculator';
import { WarrantyCartSelectedAsset } from '~/wm/packages/warranty/packages/warranty-cart/types/WarrantyCartSelectedAsset';
import { hideCalcCostConstant } from '~/wm/packages/warranty/packages/warranty-cart/constants/costCalculationConstants';
import {
  calculatePeriodDurationInMonths,
  getCoTerminationEndDate,
} from '~/wm/packages/warranty/packages/warranty-cart/calculators/warrantyCartCalculators';
import { RenewalOptionSellingPriceDto } from '@WarrantyClient/BeastClient/Beast/Estimate/Dto/Cart/PaginatedGet.gen';
import { WarrantyCartPriceCalculatorType } from '~/wm/packages/warranty/packages/warranty-cart/types/WarrantyCartCalculatorType';

const calculateCost = (
  assetAccountId: string | undefined,
  availablePlans: RenewalOptionSellingPriceDto[],
  selectedPlanId: string | undefined,
  selectedUnits: WarrantyCartSelectedAsset[],
  duration: number,
) => {
  const selectedUnit = selectedUnits.find(p => p.assetAccountId === assetAccountId);
  if (!selectedUnit) {
    return hideCalcCostConstant;
  }
  const selectedPlan = selectedPlanId ? availablePlans.find(p => p.planId === selectedPlanId) : availablePlans[0];

  const selectedAssetsStartDates = selectedUnits.map(p => p.renewalStart);
  const endDate = getCoTerminationEndDate(selectedAssetsStartDates, duration);
  const warrantyCoverageDuration = calculatePeriodDurationInMonths(new Date(selectedUnit.renewalStart), endDate);

  return (selectedPlan?.price ?? 0) * warrantyCoverageDuration;
};

export const warrantyCartStaticCoTerminationCostCalculator: WarrantyCartCoTerminationCostCalculator = {
  calculateCost,
  calculatorType: WarrantyCartPriceCalculatorType.Static,
};
