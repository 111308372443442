import WarrantyCartCostCalculator from '~/wm/packages/warranty/packages/warranty-cart/calculators/regular/WarrantyCartCostCalculator';
import { RenewalOptionSellingPriceDto } from '@WarrantyClient/BeastClient/Beast/Estimate/Dto/Cart/PaginatedGet.gen';
import { WarrantyCartPriceCalculatorType } from '~/wm/packages/warranty/packages/warranty-cart/types/WarrantyCartCalculatorType';

const calculateRegularCost = (
  availablePlans: RenewalOptionSellingPriceDto[],
  selectedPlanId: string | undefined,
  _selectedAssetsCount: number,
  duration: number,
): number => {
  const selectedPlan = selectedPlanId ? availablePlans.find(p => p.planId === selectedPlanId) : availablePlans[0];

  return (selectedPlan?.price ?? 0) * duration;
};

export const warrantyCartStaticCostCalculator: WarrantyCartCostCalculator = {
  calculatorType: WarrantyCartPriceCalculatorType.Static,
  calculateCost: calculateRegularCost,
};
