import OrganizationProvider from '~/wm/packages/organization/context/OrganizationProvider';
import AutoEmailReportEmailTemplateProvider from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/context/AutoEmailReportEmailTemplateProvider';
import AutoEmailReportEmailTemplateEditSection from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/auto-email-report-email-template-edit-section/AutoEmailReportEmailTemplateEditSection';
import AutoEmailReportEmailTemplateEditForm from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/auto-email-report-email-template-edit-form/AutoEmailReportEmailTemplateEditForm';
import { css } from '@emotion/react';
import AutoEmailReportEmailTemplateVariableSection from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/auto-email-report-email-template-variable-section/AutoEmailReportEmailTemplateVariableSection';

export type AutoEmailReportEmailTemplateEditPageProps = {
  autoEmailReportId: string;
  organizationId: string;
  organizationName: string;
  accountEmailTemplateEditUrl: string;
};

const AutoEmailReportEmailTemplateEditPage = ({
  autoEmailReportId,
  organizationId,
  organizationName,
  accountEmailTemplateEditUrl,
}: AutoEmailReportEmailTemplateEditPageProps) => (
  <OrganizationProvider
    organizationId={organizationId}
    organizationName={organizationName}
  >
    <AutoEmailReportEmailTemplateProvider autoEmailReportId={autoEmailReportId}>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 2rem;
        `}
      >
        <div
          css={css`
            flex: 1;
          `}
        >
          <AutoEmailReportEmailTemplateEditForm>
            <AutoEmailReportEmailTemplateEditSection accountEmailTemplateEditUrl={accountEmailTemplateEditUrl} />
          </AutoEmailReportEmailTemplateEditForm>
        </div>
        <div
          css={css`
            flex: 1;
          `}
        >
          <AutoEmailReportEmailTemplateVariableSection />
        </div>
      </div>
    </AutoEmailReportEmailTemplateProvider>
  </OrganizationProvider>
);

export default AutoEmailReportEmailTemplateEditPage;
