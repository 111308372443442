import { PageEnum, PageFilter } from '@AssetManagementClient/AssetManagement/Packages/Share/Packages/Send/Model.gen';
import * as React from 'react';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { ConsoleState } from '~/neo-ui/packages/table/packages/console/types';
import InsightShareModal from '~/wm/packages/insight-share/packages/insight-share-modal/InsightShareModal';
import Button from '~/neo-ui/packages/button/Button';
import { popoverButtonStyles } from '~/neo-ui/packages/popover/Popover';

export type ConsoleShareButtonProps = {
  consoleState: ConsoleState;
  selectedColumns: Set<string> | undefined;
  consoleShareProps: ConsoleShareButtonFrontendProps;
};

export type ConsoleShareButtonFrontendProps = {
  userEmail: string;
  inviteMembersUrl: string;
  canInviteMembers: boolean;
  frontendScope: FrontendScope;
  page: PageEnum;
};
const ConsoleShareButton: React.FunctionComponent<ConsoleShareButtonProps> = ({ consoleShareProps }) => {
  const [isModalShown, setIsModalShown] = React.useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  if (!urlParams) {
    return <div />;
  }

  const pageFilters = new Array<PageFilter>();
  urlParams.forEach((value, key) => {
    pageFilters.push({ key, values: [value] });
  });

  return (
    <div>
      <Button
        iconRight={'ShareUrl'}
        size={'md'}
        iconSizeOverride={'lg'}
        onClick={() => setIsModalShown(true)}
        css={[popoverButtonStyles]}
      >
        Share...
      </Button>
      <InsightShareModal
        isOpen={isModalShown}
        onDismiss={() => {
          setIsModalShown(false);
        }}
        userEmail={consoleShareProps.userEmail}
        page={consoleShareProps.page}
        frontendScope={consoleShareProps.frontendScope}
        inviteMembersUrl={consoleShareProps.inviteMembersUrl}
        canInviteMembers={consoleShareProps.canInviteMembers}
        filters={pageFilters}
      />
    </div>
  );
};

export default ConsoleShareButton;
