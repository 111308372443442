import * as Yup from 'yup';
import { ScorecardFormData } from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-update-form/ScorecardUpdateForm';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const scorecardUpdateFormValidationSchema: Yup.ObjectSchema<any> = Yup.object().shape<Partial<ScorecardFormData>>({
  title: Yup.string()
    .required('Required.')
    // Duped in backend: c7abcc52-e5db-41d5-b36d-b396f5dd5f5c
    .max(25, 'The title may only be 25 characters.'),
  executiveSummary: Yup.string()
    // Duped in backend: c7abcc52-e5db-41d5-b36d-b396f5dd5f5c
    .max(10000, 'The executive summary is too long.'),
  includeDmi: Yup.bool(),
});

export default scorecardUpdateFormValidationSchema;
