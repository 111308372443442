import { SVGProps, forwardRef, memo } from 'react';

const MastercardIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="24"
        viewBox="0 0 36 24"
        fill="none"
        {...props}
      >
        <rect
          x="0.5"
          y="0.5"
          width="35"
          height="23"
          rx="2.5"
          fill="white"
        />
        <rect
          x="0.5"
          y="0.5"
          width="35"
          height="23"
          rx="2.5"
          stroke="#DDDDDD"
        />
        <path
          d="M21.4889 5.71094H14.4889V18.2887H21.4889V5.71094Z"
          fill="#FF5F00"
        />
        <path
          d="M14.9333 11.9999C14.9333 9.55545 16.0667 7.22212 17.9778 5.71101C14.5111 2.97767 9.46668 3.57767 6.73335 7.06656C4.02223 10.5332 4.62223 15.5555 8.11112 18.2888C11.0222 20.5777 15.0889 20.5777 18 18.2888C16.0667 16.7777 14.9333 14.4443 14.9333 11.9999Z"
          fill="#EB001B"
        />
        <path
          d="M30.9333 11.9999C30.9333 16.4221 27.3556 19.9999 22.9333 19.9999C21.1333 19.9999 19.4 19.3999 18 18.2888C21.4667 15.5555 22.0667 10.5332 19.3333 7.04434C18.9333 6.55545 18.4889 6.08879 18 5.71101C21.4667 2.97767 26.5111 3.57767 29.2222 7.06656C30.3333 8.46656 30.9333 10.1999 30.9333 11.9999Z"
          fill="#F79E1B"
        />
      </svg>
    );
  }),
);

export default MastercardIcon;
