import { createContext } from 'react';

export type AutoEmailReportListMutationContextData = {
  triggerAutoEmailReportListReload: (isRequestActive?: () => boolean) => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const AutoEmailReportListMutationContext = createContext<AutoEmailReportListMutationContextData>({
  triggerAutoEmailReportListReload: async () => {},
});

export default AutoEmailReportListMutationContext;
