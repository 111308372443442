import { css } from '@emotion/react';
import React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Size from '~/neo-ui/model/Size';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ScaleOption<T extends string = string> = {
  value: T;
  color: Color;
  borderColor: Color;
  activeColor?: Color;
  activeIcon?: IconType;
};

export type ScaleSelectProps<T extends string = string> = {
  scaleOptions: ScaleOption<T>[];
  selectedScaleOption: ScaleOption | undefined;
  size?: Exclude<Size, 'xs' | 'lg' | 'xl'>;
  onScaleChange?: (option: ScaleOption<T>) => void | undefined;
  isPresentation?: boolean;
} & Styleable;

type ButtonDisplayDetails = {
  diameterRem: number;
  borderWidthRem: number;
  iconSizePx: number | undefined;
  borderRadius: string;
  gapRem: number;
  activeSizeRem: number;
  activeBorderSizeRem: number;
};

const ScaleSelect = <T extends string>({
  scaleOptions,
  selectedScaleOption,
  size = 'md',
  onScaleChange,
  isPresentation = false,
  className,
}: ScaleSelectProps<T>) => {
  const buttonSizeDetails = buttonSizeToButtonDisplayDetails(size);
  const scaleIndex =
    typeof selectedScaleOption !== 'undefined' ? scaleOptions.findIndex(option => option.value === selectedScaleOption.value) : -1;

  const innerCircleDiameterRem = buttonSizeDetails.diameterRem - buttonSizeDetails.borderWidthRem * 4;

  return (
    <div
      className={className}
      css={css`
        display: flex;
        gap: ${buttonSizeDetails.gapRem}rem;
      `}
    >
      {scaleOptions.map((option, index) => (
        <div
          className={className}
          key={option.value}
          css={css`
            position: relative;
          `}
        >
          {!isPresentation && index === scaleIndex && (
            <div
              css={css`
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: ${buttonSizeDetails.activeSizeRem}rem;
                height: ${buttonSizeDetails.activeSizeRem}rem;
                top: -${buttonSizeDetails.activeBorderSizeRem}rem;
                left: -${buttonSizeDetails.activeBorderSizeRem}rem;
                background-color: ${colorToCode('dark-900-32')};
                border-radius: ${buttonSizeDetails.borderRadius};
              `}
            >
              <Icon
                icon={option.activeIcon!}
                color={'light-000'}
                sizePx={buttonSizeDetails.iconSizePx}
              />
            </div>
          )}
          <div
            onClick={onScaleChange ? () => onScaleChange(option) : undefined}
            css={css`
              width: ${buttonSizeDetails.diameterRem}rem;
              height: ${buttonSizeDetails.diameterRem}rem;

              :hover {
                // Show the border color of unused score dots
                ${!isPresentation && index > scaleIndex && `border-color: ${colorToCode('light-400')};`}

                // Show the inner hover dot where it is not the scale index
                div:nth-of-type(1) {
                  ${!isPresentation && index !== scaleIndex && 'visibility: visible;'}
                }
              }

              ${index > scaleIndex
                ? `background-color: ${colorToCode(`light-700`)};
                   border: ${buttonSizeDetails.borderWidthRem}rem solid transparent;`
                : `background-color: ${colorToCode(selectedScaleOption!.color)};
                   border: ${buttonSizeDetails.borderWidthRem}rem solid ${colorToCode(selectedScaleOption!.borderColor)};
               `}
              ${!isPresentation && `cursor: pointer;`}
              border-radius: ${buttonSizeDetails.borderRadius};
              background-clip: padding-box;
            `}
          >
            {/* Inner hover dot */}
            <div
              css={css`
                visibility: hidden;
                right: 0;
                bottom: 0;
                top: 0;
                left: 0;
                margin: auto;
                position: absolute;
                width: ${innerCircleDiameterRem}rem;
                height: ${innerCircleDiameterRem}rem;
                border-radius: ${buttonSizeDetails.borderRadius};
                pointer-events: none;
                background-color: ${colorToCode('light-000-64')};
              `}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export const buttonSizeToButtonDisplayDetails = (buttonSize: Exclude<Size, 'xs' | 'lg' | 'xl'>): ButtonDisplayDetails => {
  switch (buttonSize) {
    case 'sm':
      return {
        diameterRem: 1.5,
        borderWidthRem: 0.1875,
        iconSizePx: 11,
        borderRadius: '50%',
        gapRem: 0.625,
        activeSizeRem: 1.625,
        activeBorderSizeRem: 0.0625,
      };
    case 'md':
      return {
        diameterRem: 1.875,
        borderWidthRem: 0.25,
        iconSizePx: 14,
        borderRadius: '50%',
        gapRem: 0.25,
        activeSizeRem: 2.25,
        activeBorderSizeRem: 0.1875,
      };
  }
};

export default ScaleSelect;
