import React from 'react';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';
import { EstimateCartStoreGetUnitDto } from '@WarrantyClient/BeastClient/Beast/Estimate/Dto/Cart/PaginatedGet.gen';
import { css } from '@emotion/react';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';

export type WarrantyCartDeviceSelectorProps = {
  unit: EstimateCartStoreGetUnitDto;
};

const WarrantyCartDeviceSelector: React.FunctionComponent<WarrantyCartDeviceSelectorProps> = ({ unit }) => {
  const { getIsUnitSelected, selectUnit } = useWarrantyCartContext();

  if (!unit.renewalOptions.length) {
    return null;
  }

  return (
    <InputCheckbox
      css={css`
        margin-left: 0.3rem;
      `}
      checked={getIsUnitSelected(unit.assetAccountId || '')}
      onClick={() => selectUnit(unit)}
    />
  );
};

export default WarrantyCartDeviceSelector;
