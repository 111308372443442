import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import Button from '~/neo-ui/packages/button/Button';
import Spinner from '~/neo-ui/spinner/Spinner';
import React from 'react';

const InitiativeSaveSubmitButton = () => {
  const { isSubmitting, submitForm } = useFormContext();

  return (
    <Button
      disabled={isSubmitting}
      theme={'primary'}
      onClick={submitForm}
      iconLeft={'Save'}
    >
      {isSubmitting ? <Spinner size={'sm'} /> : 'Save changes'}
    </Button>
  );
};

export default InitiativeSaveSubmitButton;
