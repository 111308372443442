import { SettingsValueNested } from '@AssetManagementClient/BeastClient/Beast/Settings/Model.gen';
import {
  Enum as AssetManagementClientBeastClientGoodsDeviceTypeDeviceTypeFactoryNestedEnum,
  Enum,
} from '@AssetManagementClient/BeastClient/Goods/DeviceType/DeviceTypeFactoryNested.gen';
import { css } from '@emotion/react';
import { WarrantyCoverageSettingsAvailabilities } from '@WarrantyClient/BeastClient/Beast/WarrantyCoverageSettings/Model/Availabilities.gen';
import { Enum as HardwareTypeEnum } from '@WarrantyClient/BeastClient/Goods/DeviceType/DeviceTypeFactoryNested.gen';
import * as React from 'react';
import { camelToPascal } from '~/extensions/packages/casing/camelPascalConversion';
import FormCheckboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckboxInput';
import FormSelectInput, { FormSelectOption } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';

import FormTextInput from '~/neo-ui/packages/form/packages/form-input/packages/form-text-input/FormTextInput';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { AvailabilityRange } from '@WarrantyClient/BeastClient/Beast/WarrantyCoverageSettings/Model/Availabilities/WarrantyCoverageSettingsAvailabilitiesNested.gen';
import { ServiceLevelAgreement } from '@WarrantyClient/BeastClient/Beast/Renewal/Model/Option.gen';
import DataTable, { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';

export type WarrantyCoverageCartSettingsAvailabilitiesDto = {
  hardwareTypeEnum: HardwareTypeEnum;
  markupAvailability: AvailabilityRange;
  availableCoverageLengthYears: number[];
  availableSlas: ServiceLevelAgreement[];
};
export type WarrantyCoverageOpportunitySettingsFormProps = {
  availabilities: WarrantyCoverageSettingsAvailabilities | undefined;
  disabled?: boolean;
};

const WarrantyCoverageOpportunitySettingsForm: React.FunctionComponent<WarrantyCoverageOpportunitySettingsFormProps> = ({
  availabilities,
  disabled = false,
}) => {
  if (typeof availabilities === 'undefined') {
    return null;
  }
  const hardwareTypes = Object.keys(availabilities.deviceTypeAvailabilities).sort() as HardwareTypeEnum[];

  const warrantyCoverageCartSettingsAvailabilities: WarrantyCoverageCartSettingsAvailabilitiesDto[] = hardwareTypes.map(type => {
    const typeEnum = type;
    const deviceTypeAvailabilities = availabilities.deviceTypeAvailabilities[typeEnum];
    return {
      hardwareTypeEnum: typeEnum,
      markupAvailability: availabilities.markupAvailability,
      availableCoverageLengthYears: deviceTypeAvailabilities.availableCoverageLengthYears,
      availableSlas: deviceTypeAvailabilities.availableSlas,
    };
  });

  const formTableColumns: DataTableColumn<WarrantyCoverageCartSettingsAvailabilitiesDto>[] = [
    {
      Header: 'Hardware type',
      fieldKey: availabilities => availabilities.hardwareTypeEnum,
      renderCell: availabilities => (
        <div
          css={css`
            display: flex;
            align-items: center;
            height: 1.875rem;
          `}
        >
          {((): string => {
            switch (
              // Because of camelization it breaks these enums.
              // TODO https://warrantymaster.myjetbrains.com/youtrack/issue/WM-1555
              camelToPascal(availabilities.hardwareTypeEnum) as AssetManagementClientBeastClientGoodsDeviceTypeDeviceTypeFactoryNestedEnum
            ) {
              // This will come from the factory in the future.
              case Enum.Imaging:
                return 'Imaging';
              case Enum.Mobile:
                return 'Mobile';
              case Enum.Network:
                return 'Network';
              case Enum.Server:
                return 'Server';
              case Enum.Storage:
                return 'Storage';
              case Enum.Virtual:
                return 'Virtual';
              case Enum.Workstation:
                return 'Workstation';
            }
          })()}
        </div>
      ),
    },
    {
      Header: 'Markup',
      fieldKey: availabilities => availabilities.markupAvailability,
      renderCell: availabilities => (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.3125rem;
          `}
        >
          <FormTextInput
            css={css`
              flex: auto;
            `}
            fieldKey={(settings: FieldKeyExpressionSegment<SettingsValueNested.WarrantyCoverageOpportunitySettings>) =>
              settings.value.warrantyCoverageOpportunityPreferences[availabilities.hardwareTypeEnum].markupPercentage
            }
            placeholder="Markup percentage"
            disabled={disabled}
          />
          <Label>%</Label>
        </div>
      ),
    },
    {
      Header: 'Coverage length',
      fieldKey: availabilities => availabilities.availableCoverageLengthYears,
      renderCell: availabilities => (
        <FormSelectInput
          fieldKey={(settings: FieldKeyExpressionSegment<SettingsValueNested.WarrantyCoverageOpportunitySettings>) =>
            settings.value.warrantyCoverageOpportunityPreferences[availabilities.hardwareTypeEnum].coverageLength
          }
          disabled={disabled}
          options={
            availabilities.availableCoverageLengthYears.map(
              (length): FormSelectOption => ({
                label: length === 1 ? `${length} year` : `${length} years`,
                value: length.toString(),
              }),
            ) ?? []
          }
        />
      ),
    },
    {
      Header: 'Service-level agreement',
      fieldKey: availabilities => availabilities.availableSlas,
      renderCell: availabilities =>
        availabilities.availableSlas.length === 1 ? (
          <div
            css={css`
              padding: 5px 10px !important;
            `}
          >
            {availabilities.availableSlas[0].name}
          </div>
        ) : (
          <FormSelectInput
            fieldKey={(settings: FieldKeyExpressionSegment<SettingsValueNested.WarrantyCoverageOpportunitySettings>) =>
              settings.value.warrantyCoverageOpportunityPreferences[availabilities.hardwareTypeEnum].serviceLevelAgreement
            }
            disabled={disabled}
            options={
              availabilities.availableSlas.map(
                ({ enum: value, name }): FormSelectOption => ({
                  label: name,
                  value,
                }),
              ) ?? []
            }
          />
        ),
    },
  ];

  return (
    <div>
      <DataTable
        columns={formTableColumns}
        data={warrantyCoverageCartSettingsAvailabilities}
        EmptyStatePlaceholder={''}
      />
      <div>
        <div
          css={css`
            margin-top: 1.25rem;
            margin-left: 0.437rem;
          `}
        >
          <div>
            <FormCheckboxInput
              fieldKey={(settings: FieldKeyExpressionSegment<SettingsValueNested.WarrantyCoverageOpportunitySettings>) =>
                settings.value.coterminationEnabled
              }
              disabled={disabled}
              description="Co-termination"
            />

            <Label
              muted={true}
              css={css`
                font-size: 0.75rem;
              `}
            >
              All warranties in your cart will match the longest coverage length of the hardware types you added
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarrantyCoverageOpportunitySettingsForm;
