import React, { useContext } from 'react';
import { CategoryPayload } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Asset/Model/Dto/InsightOptionPayloadNested.gen';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import SingleSelectConsole from '~/neo-ui/packages/select/packages/single-select/packages/single-select-console/SingleSelectConsole';
import ConsoleContext from '~/neo-ui/packages/table/packages/console/contexts/ConsoleContext';
import { produce } from 'immer';
import { areConsoleParameterEquals, ConsoleState, defaultConsoleState } from '~/neo-ui/packages/table/packages/console/types';
import {
  InsightOptionPayload,
  InsightOptionPayload as AssetManagementClientBeastClientBeastAssetManagementPackagesAssetModelDtoInsightOptionPayload,
} from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Asset/Model/Dto.gen';
import { DataPointFilterSetting } from '@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Model.gen';

export type AssetInsightDropdownProps = {
  options?: CategoryPayload[];
  onInsightSelected?: (selectedInsight: InsightOptionPayload | undefined) => void;
};

export const getParametersFromDatapointFilterSettings = (settings: DataPointFilterSetting[]): Map<string, Map<string, string[]>> => {
  const parameters = new Map<string, Map<string, string[]>>();
  settings.forEach(setting => {
    const values = parameters.get(setting.key.value) ?? new Map<string, string[]>();
    values.set(setting.operator, setting.values);
    parameters.set(setting.key.value, values);
  });
  return parameters;
};

const AssetInsightDropdown = ({ options, onInsightSelected }: AssetInsightDropdownProps) => {
  /**
   * Dropdown SelectOption helpers
   */
  const createSelectOption = React.useCallback(
    (insightCategoryPayload: AssetManagementClientBeastClientBeastAssetManagementPackagesAssetModelDtoInsightOptionPayload) =>
      ({
        label: insightCategoryPayload.title,
        labelHighlightedColor: 'secondary-400',
        value: insightCategoryPayload.insightId,
        description: insightCategoryPayload.description,
        descriptionMuted: true,
        descriptionColor: 'dark-900-64',
        descriptionHighlightedColor: 'dark-900-64',
        hoverBackgroundColor: 'secondary-100',
        selectedBackgroundColor: 'secondary-050',
        selectedIcon: 'Chosen',
        selectedIconColor: 'secondary-400',
      } as SelectOption),
    [],
  );

  /**
   * State management
   */
  const { consoleState, setConsoleState, setColumns, defaultColumns, columns } = useContext(ConsoleContext);

  const insightOptions = options?.flatMap(option => option.insights) ?? [];

  React.useEffect(() => {
    const insightOption =
      typeof consoleState.search !== 'undefined' && consoleState.search.value.trim() !== ''
        ? undefined
        : insightOptions.find(insight => {
            const insightParameters = getParametersFromDatapointFilterSettings(insight.dataPointFilterSettings);
            const insightColumns =
              insight.columns.length === 0 ? new Set(defaultColumns) : new Set(insight.columns.map(column => column.value));
            return (
              typeof columns !== 'undefined' &&
              columns.size === insightColumns.size &&
              Array.from(columns).filter(x => !insightColumns.has(x)).length === 0 &&
              areConsoleParameterEquals(insightParameters, consoleState.parameters)
            );
          });
    const newOption = typeof insightOption === 'undefined' ? null : createSelectOption(insightOption);
    if ((newOption === null && selectedOption === null) || newOption?.value === selectedOption?.value) {
      return;
    }
    setSelectedOption(newOption);

    if (typeof onInsightSelected !== 'undefined') {
      onInsightSelected(insightOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consoleState.parameters, consoleState.search, columns, insightOptions]);

  const populateState = (payload: AssetManagementClientBeastClientBeastAssetManagementPackagesAssetModelDtoInsightOptionPayload) => {
    setConsoleState(consoleState =>
      produce(consoleState, (draft: ConsoleState) => {
        draft.parameters = getParametersFromDatapointFilterSettings(payload.dataPointFilterSettings);

        // Clear search and pagination when insight changes
        draft.search = undefined;
        draft.pagination.pageNumber = defaultConsoleState.pagination.pageNumber;
      }),
    );

    // Set columns
    let insightColumns = new Set<string>(payload.columns.map(fieldKey => fieldKey.value));
    if (insightColumns.size === 0 && defaultColumns !== undefined) {
      insightColumns = defaultColumns;
    }
    setColumns(insightColumns);
  };

  const selectInsight = (selected: SelectOption) => {
    if (!options) {
      setSelectedOption(null);
      return;
    }

    const selectedInsight = insightOptions.find(insightOption => insightOption.insightId === selected.value);

    if (!selectedInsight) {
      setSelectedOption(null);
      if (typeof onInsightSelected !== 'undefined') {
        onInsightSelected(undefined);
      }
      return;
    }
    populateState(selectedInsight);
    if (typeof onInsightSelected !== 'undefined') {
      onInsightSelected(selectedInsight);
    }
  };

  const [selectedOption, setSelectedOption] = React.useState<SelectOption | null>(null);

  /**
   * Dropdown options and selection
   */
  if (typeof options === 'undefined') {
    return <div />;
  }

  /** *************
   * Main        *
   **************/
  return (
    <SingleSelectConsole
      options={options.map(category => ({
        label: category.name,
        options: category.insights.map(createSelectOption),
      }))}
      onOptionSelected={selected => selectInsight(selected)}
      selectedOption={selectedOption}
      placeholder={menuOpen => (menuOpen ? 'Find an Insight...' : 'Filter by Insight...')}
      prependIcon={'Insight'}
      shouldUseMenuPortal={false}
    />
  );
};

export default AssetInsightDropdown;
