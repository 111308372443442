import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { initiativeList } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { InitiativeOverviewListDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';

export type UseInitiativeList = (organizationId: string) => {
  initiatives: InitiativeOverviewListDto[] | undefined;
};

const useInitiativeList: UseInitiativeList = organizationId => {
  const [initiatives, setInitiatives] = useState<InitiativeOverviewListDto[]>();
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => initiativeList({ organizationId }));
      if (!response) {
        return;
      }
      setInitiatives(response.initiatives);
    })();
  }, [callApi, organizationId]);

  return {
    initiatives,
  };
};

export default useInitiativeList;
