import { useContext } from 'react';
import EngagementActionListContext from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/EngagementActionListContext';

const useEngagementActionListContext = () => {
  const engagementActionListContext = useContext(EngagementActionListContext);
  if (!engagementActionListContext) {
    throw new Error('Tried to use EngagementActionListContext outside of an EngagementActionListProvider');
  }

  return engagementActionListContext;
};

export default useEngagementActionListContext;
