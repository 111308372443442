import { keyframes } from '@emotion/react';
import { SVGProps, forwardRef, memo } from 'react';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SpinnerIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        css={{
          animation: `${spin} 1s linear infinite`,
        }}
        {...props}
      >
        <path
          d="M0 12.0006C0 12.0004 0 12.0007 0 12.0006C3.1231e-05 12.7827 0.0765229 13.5666 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C10.4336 23.9235 11.2177 24 12 24V21C11.4133 21 10.8252 20.9426 10.2442 20.8271C8.49836 20.4798 6.89472 19.6226 5.63604 18.364C4.37737 17.1053 3.5202 15.5016 3.17294 13.7558C3.05737 13.1748 3 12.5867 3 12L0 12.0006Z"
          fill="url(#paint0_linear_2526_6807)"
        />
        <path
          d="M12 21C13.1762 21 14.3469 20.7694 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 11.1716 21.6716 10.5 22.5 10.5C23.3284 10.5 24 11.1716 24 12C24 14.3734 23.2962 16.6934 21.9776 18.6668C20.6591 20.6402 18.7849 22.1783 16.5922 23.0866C15.1292 23.6925 13.5683 24 12 24V21Z"
          fill="url(#paint1_linear_2526_6807)"
        />
        <path
          d="M6.99987 4.51677C8.47991 3.52784 10.22 3 12 3C12.8284 3 13.5 2.32843 13.5 1.5C13.5 0.671573 12.8284 0 12 0C9.62663 0 7.30655 0.703787 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.307521 8.87064 6.26205e-05 10.4314 0 11.9996C0 11.9995 0 11.9997 0 11.9996L3 12C3.00001 10.8238 3.2306 9.65307 3.68509 8.55585C4.36628 6.91131 5.51983 5.5057 6.99987 4.51677Z"
          fill="currentColor"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2526_6807"
            x1="3"
            y1="12"
            x2="12"
            y2="21"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="currentColor" />
            <stop
              offset="1"
              stopColor="currentColor"
              stopOpacity="0.5"
            />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2526_6807"
            x1="12"
            y1="22.5"
            x2="22"
            y2="12.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              stopColor="currentColor"
              stopOpacity="0.5"
            />
            <stop
              offset="1"
              stopColor="currentColor"
              stopOpacity="0"
            />
          </linearGradient>
        </defs>
      </svg>
    );
  }),
);

export default SpinnerIcon;
