import { organizationEngagementNotePin } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementNotePanelTabMutation, {
  UseOrganizationEngagementNotePanelTabMutationOptions,
} from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/context/hooks/useEngagementNotePanelTabMutation';

export type UseEngagementNoteDashboardPin = {
  pinNote: (engagementNoteId: string) => void;
  isPinningNote: boolean;
};

const useEngagementNoteDashboardPin = (options?: UseOrganizationEngagementNotePanelTabMutationOptions): UseEngagementNoteDashboardPin => {
  const [pinNote, isPinningNote] = useEngagementNotePanelTabMutation(
    (engagementNoteId: string) =>
      organizationEngagementNotePin({
        engagementNoteId,
      }),
    options,
  );

  return {
    pinNote,
    isPinningNote,
  };
};

export default useEngagementNoteDashboardPin;
