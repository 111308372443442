import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import * as React from 'react';
import { css } from '@emotion/react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import formatBillingAddress from '~/wm/packages/subscription/packages/billing/operation/formatBillingAddress';
import { AddressBusiness } from '@SubscriptionClient/BeastClient/Common/Location/Model/Address.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import useWizardPageContext from '~/neo-ui/packages/wizard/packages/wizard-page/context/hooks/useWizardPageContext';
import Button from '~/neo-ui/packages/button/Button';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';

export type OrderSummaryReturnAddressModuleProps<T> = {
  header: string;
  description: string;
  shipmentReturnAddressFieldKey: FieldKeyExpression<T>;
};

const OrderSummaryReturnAddressModule = <T,>({
  header,
  description,
  shipmentReturnAddressFieldKey,
}: OrderSummaryReturnAddressModuleProps<T>) => {
  const { getFormInput } = useFormContext<T>();
  const { setCurrentStepIndex } = useWizardPageContext();
  const { trackEvent } = useEventTracking(TrackingServices.EventProcessor);

  const shipmentReturnAddress = getFormInput<AddressBusiness>(shipmentReturnAddressFieldKey).value;

  const shipmentReturnAddressDisplay = formatBillingAddress(shipmentReturnAddress, true);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Label
          size={'lg'}
          bold={true}
        >
          {header}
        </Label>
        <Button
          iconLeft={'Edit'}
          size={'sm'}
          onClick={() => {
            trackEvent('disposal_shipping_step_navigate', {
              context: 'disposal_summary_step_return_address_edit_button_clicked',
            });
            setCurrentStepIndex(1);
          }}
        >
          Edit
        </Button>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <Label
          size={'md'}
          bold={true}
        >
          {shipmentReturnAddress.name}
        </Label>
        <Label
          size={'md'}
          color={'dark-900-64'}
        >
          {shipmentReturnAddressDisplay}
        </Label>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 0.5rem;
        `}
      >
        <Icon
          icon={'Calendar'}
          sizePx={14}
          color={'info-800'}
        />
        <Label
          size={'md'}
          color={'info-800'}
        >
          {description}
        </Label>
      </div>
    </div>
  );
};

export default OrderSummaryReturnAddressModule;
