import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';
import { titlefieldKeyId } from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/TicketCreateForm';
import { InputTypeNested } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Credential.gen';
import { BudgetQuarterFormData } from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';

const formatTicketTitleInitiative = (
  name: string,
  budgetQuarter: BudgetQuarterFormData | undefined,
  fieldOptions: IntegrationFieldOptionDto[],
): string => {
  const ticketTitleFieldOption = fieldOptions.find(option => option.key === titlefieldKeyId);
  const ticketTitleFieldOptionTextLength = ticketTitleFieldOption
    ? (ticketTitleFieldOption.inputType as InputTypeNested.Text).validation.maxLength
    : undefined;

  const title = `Lifecycle Manager Initiative: ${
    typeof budgetQuarter !== 'undefined' ? `Q${budgetQuarter.quarter} ${budgetQuarter.year} ` : ''
  } ${name}`;

  return typeof ticketTitleFieldOptionTextLength !== 'undefined' && title.length > ticketTitleFieldOptionTextLength
    ? `${title.slice(0, ticketTitleFieldOptionTextLength - 1)}…`
    : title;
};

export default formatTicketTitleInitiative;
