import { activitySearch } from '@BeastClient/BeastClientMsp.gen';
import { ActivitySearchResult } from '@BeastClient/Activity/Search/Model/Query.gen';
import { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PaginationMetadata } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import useApi from '~/wm/packages/api/hook/useApi';
import { Filter } from '@BeastClient/Beast/Activity/Module/Search/Controller/ActivitySearchNested.gen';
import { QueryPayloadDto } from '@BeastClient/Primitives/Query/Request/Dto.gen';

export const useActivityLog = (query: QueryPayloadDto) => {
  const [activityEntries, setActivityEntries] = useState<ActivitySearchResult[] | undefined>(undefined);

  const [paginationMetadata, setPaginationMetadata] = useState<PaginationMetadata | 'unknown'>('unknown');

  const [availableFilters, setAvailableFilters] = useState<Filter[]>([]);

  const { callApi } = useApi();

  const currentFetchToken = useRef<string>();

  useEffect(() => {
    (async () => {
      const fetchToken = uuidv4();
      currentFetchToken.current = fetchToken;

      const response = await callApi(() =>
        activitySearch({
          query,
        }),
      );
      if (!response) {
        return;
      }
      setAvailableFilters(response.availableFilters);
      if (currentFetchToken.current === fetchToken) {
        setActivityEntries(response.results);
        setPaginationMetadata({
          totalResults: response.paginationMetadata.totalResult,
          totalPages: response.paginationMetadata.totalPages,
        });
      }
    })();
  }, [callApi, query]);

  return { activityEntries, paginationMetadata, availableFilters };
};
