import React from 'react';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';
import FormCheckboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckboxInput';
import warrantyCartOpportunitiesFormFields from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-opportunity/packages/warranty-cart-opportunity-form/warrantyCartOpportunitiesFormFields';

const WarrantyCartOpportunityCoTerminateCheckbox: React.FunctionComponent = () => {
  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <FormCheckboxInput
        fieldKey={warrantyCartOpportunitiesFormFields.coTermination}
        description={'Co-terminate'}
      />
      <Tooltip
        backgroundColor={'light-000'}
        content={'With co-termination, coverage will end on the same day for all the hardware assets you’re buying warranties for.'}
        placement={'top'}
      >
        <Icon
          icon={'InfoLight'}
          sizePx={16}
          color={'dark-900-24'}
          css={css`
            margin-left: 0.625rem;
          `}
        />
      </Tooltip>
    </div>
  );
};

export default WarrantyCartOpportunityCoTerminateCheckbox;
