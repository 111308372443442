import useWizardPageContext from '~/neo-ui/packages/wizard/packages/wizard-page/context/hooks/useWizardPageContext';
import { useEffect } from 'react';
import { ToolbarControl } from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';

const useWizardPageStepActionUpdate = (action?: ToolbarControl) => {
  const { setActionToolbarControl } = useWizardPageContext();

  useEffect(
    () => setActionToolbarControl(action),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

export default useWizardPageStepActionUpdate;
