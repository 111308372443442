import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { showAlertAction } from '~/legacy-ui/packages/alert/state/action/alertActions';
import Card from '~/legacy-ui/packages/card/Card';
import { Styleable } from '~/neo-ui/model/capacity';
import Button from '~/neo-ui/packages/button/Button';
import Form from '~/neo-ui/packages/form/Form';
import FormSubmitButton from '~/neo-ui/packages/form/packages/form-action/packages/form-submission/FormSubmitButton';
import mapBackendFieldKey from '~/neo-ui/packages/form/packages/form-action/packages/form-validation/mapBackendFieldKey';
import StepProgress from '~/neo-ui/packages/progress/packages/step-progress/StepProgress';
import { FieldKeyExpression, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { WizardContainerSummary } from '~/neo-ui/packages/wizard/packages/wizard-container/packages/summary/WizardContainerSummary';
import apiErrorAction from '~/wm/packages/api/packages/api-error/state/apiErrorAction';
import WizardContainerContext from './context/WizardContainerContext';
import { WizardContainerStep } from './packages/step/WizardContainerStep';
import Testable from '~/neo-ui/packages/testable/Testable';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { useEffect, ComponentType, useState, Fragment } from 'react';

export type WizardContainerProps<T> = Styleable & {
  theme?: Color;

  /**
   * Data the form starts with before any updates.
   * If changed, the form is reinitialized.
   */
  defaultFormData: T;

  /**
   * All steps of the wizard
   */
  steps: WizardContainerStep<T>[];

  /**
   * Header label used in the summary page
   */
  summaryHeaderLabel: string;

  /**
   * Header description used in the summary page
   */
  summaryHeaderDescription: string;

  /**
   * Used to refer to the summary in context.
   *
   * Example label: "the coolest label"
   * In context: "review the coolest step"
   */
  summaryLabel: string;

  /**
   * Optional summaries displayed in the summary page
   */
  additionalSummaries?: WizardContainerSummary[];

  /**
   * Optional, confirmation on the summary page
   */
  ConfirmationComponent?: ComponentType<{
    isConfirmed: boolean;
    toggleConfirmation: () => void;
  }>;

  /**
   * Optional, success component to display after submission
   */
  SuccessComponent?: ComponentType;

  /**
   * Label used for submit button
   */
  submitLabel: string;

  onSubmit: (formData: T) => Promise<'success' | 'error'>;
};

const WizardContainer = <T extends object>({
  theme = 'blank',
  defaultFormData,
  steps,
  summaryHeaderLabel,
  summaryHeaderDescription,
  summaryLabel,
  additionalSummaries,
  ConfirmationComponent,
  SuccessComponent,
  submitLabel,
  onSubmit,
  className,
}: WizardContainerProps<T>) => {
  const dispatch = useDispatch();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const [hasConfirmation, setHasConfirmation] = useState<boolean | undefined>(
    typeof ConfirmationComponent !== 'undefined' ? false : undefined,
  );

  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);

  const totalStepsIncludingSummary = steps.length + 1;

  const isOnLastStep = currentStepIndex === steps.length - 1;
  const isOnSummary = currentStepIndex === steps.length;

  const currentStep = currentStepIndex < steps.length ? steps[currentStepIndex] : undefined;

  // This allows steps to be disabled when they are not complete.
  // Could be reconciled with form validation in the future
  const [incompleteFields, setIncompleteFields] = useState<Set<string>>(new Set());

  const isIncompleteFieldValid = (isComplete: boolean, key: string) =>
    (!isComplete && incompleteFields.has(key)) || (isComplete && !incompleteFields.has(key));

  const setFieldCompleted = <T,>(fieldKey: FieldKeyExpression<T>, isComplete: boolean) => {
    const key = resolveFieldKey(fieldKey);
    if (isIncompleteFieldValid(isComplete, key)) {
      return;
    }
    setIncompleteFields(() => {
      if (isComplete) {
        return new Set([...incompleteFields].filter(field => field !== key));
      } else {
        return new Set([...incompleteFields, key]);
      }
    });
  };

  const isCurrentStepComplete = currentStep && !currentStep.fields.some(fieldKey => incompleteFields.has(resolveFieldKey(fieldKey)));

  const nextStep = currentStepIndex + 1 < steps.length ? steps[currentStepIndex + 1] : undefined;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStepIndex]);

  if (submittedSuccessfully && SuccessComponent) {
    return <SuccessComponent />;
  }

  return (
    <Form
      css={css`
        max-width: 43.75rem;
      `}
      className={className}
      submitMethod={'manual'}
      defaultFormData={defaultFormData}
      hideSubmissionButton={true}
      validationErrorMapper={fieldKey => {
        let mappedFieldKey = fieldKey;

        // Go over and process field maps for each step
        for (let validationErrorMapper of steps.map(step => step.validationErrorMapper)) {
          validationErrorMapper = validationErrorMapper ?? mapBackendFieldKey;
          mappedFieldKey = validationErrorMapper(mappedFieldKey);
        }

        return mappedFieldKey;
      }}
      onValidationError={({ globalMessage, fieldErrors }) => {
        // If a step contains a field error, goto it

        // Create set of affected fields for O(1) access
        const fieldKeysWithErrors = new Set<string>(fieldErrors.map(fieldError => resolveFieldKey(fieldError.fieldKey)));

        // Find a step with a field error
        let stepFound;
        for (const step of steps) {
          if (step.fields.some(field => fieldKeysWithErrors.has(resolveFieldKey(field)))) {
            stepFound = step;
            break;
          }
        }

        if (stepFound) {
          // Go to the step
          setCurrentStepIndex(steps.indexOf(stepFound));
        }

        if (fieldErrors.length > 0 && !stepFound) {
          // Couldn't recognize field error, send a generic error to compensate
          if (globalMessage) {
            dispatch(
              showAlertAction({
                children: globalMessage,
                theme: 'danger',
              }),
            );
          } else {
            // Generic global error
            dispatch(apiErrorAction('validation-error'));
          }
        }

        // Unconfirm the wizard
        setHasConfirmation(false);
      }}
      onSubmit={async formData => {
        if (typeof hasConfirmation !== 'undefined' && !hasConfirmation) {
          // If using confirmation, the wizard must be in the confirmed state
          // before submission is processed.
          return;
        }

        if (!isOnSummary) {
          // Can't submit form prior to summary page.
          // This guard helps prevent accidental submits.
          return;
        }

        const result = await onSubmit(formData);
        if (result === 'success') {
          setSubmittedSuccessfully(true);
        }
      }}
      disableSubmitOnEnter={true}
    >
      <WizardContainerContext.Provider value={{ setFieldCompleted }}>
        <Card
          backgroundColor={theme}
          padding={'xxl'}
          shadow={'lg'}
          css={css`
            width: 38.75rem;
            padding-top: 1.875rem;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
            `}
          >
            <span
              css={css`
                font-size: 2.125rem;
                font-weight: bold;
                margin-bottom: 0.75rem;
                color: ${colorToCode('light-000')};
              `}
            >
              {isOnSummary ? summaryHeaderLabel : currentStep!.header.label}
            </span>
            <span
              css={css`
                font-size: 1.125rem;
                margin-bottom: 1.375rem;
                color: ${colorToCode('light-000')};
              `}
            >
              {isOnSummary ? summaryHeaderDescription : currentStep!.header.description}
            </span>
            <div
              css={css`
                margin-bottom: 1.25rem;
              `}
            >
              <StepProgress
                theme={'secondary-200'}
                currentStepIndex={currentStepIndex}
                totalSteps={totalStepsIncludingSummary}
              />
            </div>
            <Fragment>
              {steps.map((step, stepIndex) => (
                <div
                  key={stepIndex}
                  css={css`
                    ${stepIndex === currentStepIndex ? '' : 'display:none'}
                  `}
                >
                  {step.customSections &&
                    step.customSections.map((customSection, customSectionIndex) => <div key={customSectionIndex}>{customSection}</div>)}
                  {step.sections.map((section, sectionIndex) => (
                    <Card
                      key={sectionIndex}
                      padding={'xl'}
                      css={css`
                        width: 32.5rem;
                        margin-bottom: 0.625rem;
                      `}
                    >
                      {section}
                    </Card>
                  ))}
                </div>
              ))}
              {isOnSummary && (
                <div>
                  {steps.map((step, stepIndex) => (
                    <Card
                      key={stepIndex}
                      padding={'xl'}
                      css={css`
                        width: 32.5rem;
                        margin-bottom: 0.625rem;
                        padding-top: 1.25rem;
                      `}
                    >
                      <div
                        css={css`
                          display: flex;
                          justify-content: space-between;
                          margin-bottom: 1rem;
                        `}
                      >
                        <span
                          css={css`
                            font-size: 1.125rem;
                            font-weight: bold;
                          `}
                        >
                          {step.summary.label}
                        </span>
                        <Button
                          css={css`
                            margin-right: -0.625rem;
                          `}
                          size={'sm'}
                          onClick={() => setCurrentStepIndex(stepIndex)}
                        >
                          Edit
                        </Button>
                      </div>
                      {step.summary.content}
                    </Card>
                  ))}
                </div>
              )}
            </Fragment>
            {isOnSummary ? (
              <Fragment>
                {additionalSummaries && (
                  <div
                    css={css`
                      text-align: left;
                    `}
                  >
                    {additionalSummaries.map((summary, summaryIndex) => (
                      <Card
                        key={summaryIndex}
                        padding={'xl'}
                        css={css`
                          width: 32.5rem;
                          margin-bottom: 0.625rem;
                          padding-top: 1.25rem;
                        `}
                      >
                        <div
                          css={css`
                            margin-bottom: 1rem;
                          `}
                        >
                          <span
                            css={css`
                              font-size: 1.125rem;
                              font-weight: bold;
                            `}
                          >
                            {summary.label}
                          </span>
                        </div>
                        {summary.content}
                      </Card>
                    ))}
                  </div>
                )}
                {ConfirmationComponent && typeof hasConfirmation !== 'undefined' && (
                  <div
                    css={css`
                      margin-top: 1.25rem;
                      color: ${colorToCode('light-000')};
                      text-align: left;
                      align-self: flex-start;
                    `}
                  >
                    <ConfirmationComponent
                      isConfirmed={hasConfirmation}
                      toggleConfirmation={() => setHasConfirmation(hasConfirmation => !hasConfirmation)}
                    />
                  </div>
                )}
                <div
                  css={css`
                    margin-top: 1.875rem;
                  `}
                >
                  <Testable testId={'wizard-submit-button'}>
                    <FormSubmitButton
                      disabled={typeof hasConfirmation === 'undefined' ? false : !hasConfirmation}
                      label={submitLabel}
                    />
                  </Testable>
                </div>
              </Fragment>
            ) : (
              <div
                css={css`
                  margin-top: 1.25rem;
                `}
              >
                <Testable testId={'wizard-proceed-button'}>
                  <Button
                    theme={'positive'}
                    iconRight={'ArrowRight'}
                    size={'lg'}
                    disabled={!isCurrentStepComplete}
                    onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
                  >
                    {isOnLastStep ? `Review ${summaryLabel}` : `Enter ${nextStep!.stepLabel}`}
                  </Button>
                </Testable>
              </div>
            )}
          </div>
        </Card>
      </WizardContainerContext.Provider>
    </Form>
  );
};

export default WizardContainer;
