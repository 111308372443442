import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import WarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/WarrantyCartContext';
import { cartEstimateGet, cartEstimateUpsert } from '@WarrantyClient/WarrantyClientMsp.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import { Request as SelectAllControllerRequest } from '@WarrantyClient/Warranty/WarrantyCart/Packages/Estimate/SelectAll/Controller.gen';
import { Response as WarrantyClientWarrantyWarrantyCartPackagesEstimateGetControllerResponse } from '@WarrantyClient/Warranty/WarrantyCart/Packages/Estimate/Get/Controller.gen';
import { Request as WarrantyClientWarrantyWarrantyCartPackagesEstimateUpsertRequest } from '@WarrantyClient/Warranty/WarrantyCart/Packages/Estimate/Upsert/Controller.gen';
import {
  AssetTypePreferenceDto,
  WarrantyCartSettingsDto,
  WarrantyCartSettingsDto as WarrantyClientBeastClientBeastSettingsDtoPackagesWarrantyWarrantyCartSettingsDto,
} from '@WarrantyClient/BeastClient/Beast/Settings/Dto/Packages/Warranty.gen';
import OpportunitiesMutationContext from '~/wm/packages/warranty/packages/warranty-cart/context/OpportunitiesMutationContext';
import WarrantyCartOpportunitiesFormData from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-opportunity/packages/warranty-cart-opportunity-form/WarrantyCartOpportunitiesFormData';
import {
  EstimateCartStoreGetFilterDto as WarrantyClientBeastClientBeastEstimateDtoCartPaginatedGetEstimateCartStoreGetFilterDto,
  EstimateCartStoreGetUnitDto,
  RenewalOptionSellingPriceDto,
} from '@WarrantyClient/BeastClient/Beast/Estimate/Dto/Cart/PaginatedGet.gen';
import { WarrantyCartSelectedPlan } from '~/wm/packages/warranty/packages/warranty-cart/types/WarrantyCartSelectedPlan';
import { Enum as DeviceType } from '@WarrantyClient/BeastClient/Goods/DeviceType/DeviceTypeFactoryNested.gen';
import { WarrantyCartSelectedAsset } from '~/wm/packages/warranty/packages/warranty-cart/types/WarrantyCartSelectedAsset';
import {
  addPeriodToDate,
  calculateDeviceCost,
  calculatePeriodDurationInMonths,
  getCoTerminationEndDate,
  warrantyCartFormatDateFromDate,
} from '~/wm/packages/warranty/packages/warranty-cart/calculators/warrantyCartCalculators';
import { hideCalcCostConstant } from '~/wm/packages/warranty/packages/warranty-cart/constants/costCalculationConstants';
import { formatCostOutputUsingCurrency } from '~/wm/packages/warranty/packages/warranty-cart/functions/warrantyCartFormatters';
import { DevicesByTypeCountDto } from '@WarrantyClient/BeastClient/Beast/Warranties/Dto/DevicesByTypeCount.gen';
import { Enum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import { Enum as WarrantyType } from '@WarrantyClient/BeastClient/Goods/WarrantyType/WarrantyTypeFactoryNested.gen';
import { EstimateCartUnitDto } from '@WarrantyClient/BeastClient/Beast/Estimate/Dto/Cart/Get.gen';
import { Enum as WarrantyClientBeastClientBeastRenewalModelDistributorDistributorFactoryNestedEnum } from '@WarrantyClient/BeastClient/Beast/Renewal/Model/Distributor/DistributorFactoryNested.gen';
import useWarrantyCartSelectAllAssets from '~/wm/packages/warranty/packages/warranty-cart/hooks/useWarrantyCartSelectAllAssets';
import WarrantyCartOverlayLoader from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-loader/WarrantyCartOverlayLoader';

export type WarrantyCartProviderProps = {
  organizationId: string;
  organizationName: string;
  deviceType: DeviceType;
  availableAssetTypes: DevicesByTypeCountDto[];
  settings: WarrantyCartSettingsDto | undefined;
  warrantyType: WarrantyType | undefined;
};

export type OpportunityState = {
  markupPercentage: number;
  coTermination: boolean;
  duration: string;
};

const getSettingsForDeviceType = (
  selectedType: DeviceType,
  settings: WarrantyClientBeastClientBeastSettingsDtoPackagesWarrantyWarrantyCartSettingsDto,
): AssetTypePreferenceDto => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  return settings.opportunityPreferencesDto.preferences[selectedType.toString().toLowerCase()];
};

const isNotExists = (estimateResponse: WarrantyClientWarrantyWarrantyCartPackagesEstimateGetControllerResponse | undefined): boolean =>
  typeof estimateResponse !== 'undefined' && !estimateResponse.isEstimateExists;

// For some reason Microsoft Surface devices not return device type. need to fix
const wrongDeviceType = (
  estimateResponse: WarrantyClientWarrantyWarrantyCartPackagesEstimateGetControllerResponse | undefined,
  deviceType: DeviceType,
) =>
  typeof estimateResponse !== 'undefined' &&
  estimateResponse.estimate?.units?.some(unit => unit.deviceType !== deviceType && typeof unit.deviceType !== 'undefined');

const isInvalidType = (warrantyType: WarrantyType | undefined, deviceType: DeviceType): boolean =>
  (warrantyType === WarrantyType.WorkstationAssurance && deviceType !== DeviceType.Workstation) ||
  (warrantyType !== WarrantyType.WorkstationAssurance && deviceType === DeviceType.Workstation);

const WarrantyCartProvider: React.FunctionComponent<React.PropsWithChildren<WarrantyCartProviderProps>> = ({
  organizationId,
  organizationName,
  deviceType,
  availableAssetTypes,
  settings,
  warrantyType,
  children,
}) => {
  const { callApi } = useApi();
  const { selectAllAssets } = useWarrantyCartSelectAllAssets();

  const [selectedDeviceType, setSelectedDeviceType] = useState<DeviceType>(deviceType);
  const [totalCost, setTotalCost] = useState<number>(0);

  const [opportunity, setOpportunity] = useState<OpportunityState>({
    coTermination: false,
    duration: '0',
    markupPercentage: 0,
  });

  const [selectedUnits, setSelectedUnits] = useState<WarrantyCartSelectedAsset[]>([]);
  const [selectedPlans, setSelectedPlans] = useState<WarrantyCartSelectedPlan[]>([]);

  const [estimateId, setEstimateId] = useState<string | undefined>(undefined);
  const [apiUpsertLoading, setApiUpsertLoading] = useState<boolean>(false);

  const [continuousRenewalPolicyVisible, setContinuousRenewalPolicyVisible] = useState<boolean>(false);

  const [totalAssetsCount, setTotalAssetsCount] = useState<number>(0);
  const [isDevicesLoading, setIsDevicesLoading] = useState<boolean>(false);

  const displayEmptyStateImage: boolean = useMemo((): boolean => {
    return availableAssetTypes.find(p => p.deviceType === selectedDeviceType)?.totalDevices === 0;
  }, [availableAssetTypes, selectedDeviceType]);

  const getSelectedAssetsCount = useCallback(() => selectedUnits.length, [selectedUnits]);

  const getSelectedAccountIds = useCallback(() => selectedUnits.map(p => p.assetAccountId), [selectedUnits]);

  const getCoTerminationDuration = useCallback(
    (startDate: Date) => {
      const selectedStartDates = selectedUnits.map(p => p.renewalStart);
      const endDate = getCoTerminationEndDate(selectedStartDates, +opportunity.duration);
      return calculatePeriodDurationInMonths(startDate, endDate);
    },
    [opportunity.duration, selectedUnits],
  );

  const formatCostOutput = useCallback(
    (amountInCents: number): string => formatCostOutputUsingCurrency(amountInCents, settings?.currencyInformation),
    [settings],
  );

  const getCurrency = useCallback((): string => settings?.currencyInformation.displayCurrency ?? '', [settings]);

  const getIsUnitSelected = useCallback(
    (assetAccountId: string): boolean => selectedUnits.some(p => p.assetAccountId === assetAccountId),
    [selectedUnits],
  );

  const getUnitSelectedPlan = useCallback(
    (assetAccountId: string): string | undefined => {
      return selectedPlans.find(p => p.assetAccountId === assetAccountId)?.selectedPlan;
    },
    [selectedPlans],
  );

  const getAssetCost = useCallback(
    (assetAccountId: string | undefined, renewalOptions: RenewalOptionSellingPriceDto[]): number => {
      if (!assetAccountId) {
        return hideCalcCostConstant;
      }
      const selectedPlan = selectedPlans.find(p => p.assetAccountId === assetAccountId);

      return calculateDeviceCost(
        selectedDeviceType,
        opportunity.coTermination,
        assetAccountId,
        renewalOptions,
        selectedPlan?.selectedPlan,
        selectedUnits,
        +opportunity.duration,
      );
    },
    [selectedPlans, opportunity.coTermination, selectedDeviceType, selectedUnits, opportunity.duration],
  );

  const getCoverageEnds = useCallback(
    (assetAccountId: string | undefined, date: string): string => {
      if (opportunity.coTermination) {
        if (selectedUnits.some(p => p.assetAccountId === assetAccountId)) {
          const selectedStartDates = selectedUnits.map(p => p.renewalStart);
          return warrantyCartFormatDateFromDate(getCoTerminationEndDate(selectedStartDates, +opportunity.duration));
        }
        return '-';
      }
      return warrantyCartFormatDateFromDate(addPeriodToDate(new Date(date), +opportunity.duration));
    },
    [opportunity.duration, opportunity.coTermination, selectedUnits],
  );

  // API area
  const estimateCartGetApi = useCallback(() => {
    return callApi(() =>
      cartEstimateGet({ organizationId, warrantyType, scope: { type: Enum.Organization, organizationId }, expandLocation: false }),
    );
  }, [callApi, organizationId, warrantyType]);

  const estimateCartUpsertApi = useCallback(
    async (
      coTermination: boolean,
      durationMonths: number,
      markup: number,
      assets: WarrantyCartSelectedAsset[],
      selectedPlans: WarrantyCartSelectedPlan[],
    ): Promise<void> => {
      setOpportunity({ coTermination, duration: `${durationMonths}`, markupPercentage: markup });
      setSelectedUnits(assets);

      const requestObject: WarrantyClientWarrantyWarrantyCartPackagesEstimateUpsertRequest = {
        organizationId,
        warrantyType,
        coTerminationEnabled: coTermination,
        durationMonths,
        markupPercentage: markup,
        units: assets.map(unit => ({
          assetAccountId: unit.assetAccountId,
          renewalOptionPlanId: selectedPlans.find(plan => plan.assetAccountId === unit.assetAccountId)!.selectedPlan,
          distributorEnum: unit.distributor,
          markupPercentage: markup,
          durationMonths,
        })),
      };
      setApiUpsertLoading(true);
      const response = await callApi(() => cartEstimateUpsert(requestObject));
      setApiUpsertLoading(false);
      if (response) {
        setTotalCost(response?.estimateResponse.totalUsdSubunits ?? 0);
        if (response.estimateResponse.units.length) {
          setEstimateId(response.estimateResponse.estimateId);
        } else {
          setEstimateId(undefined);
        }
      }
    },
    [callApi, organizationId, warrantyType],
  );

  // when user press select all
  const selectAllHandler = useCallback(
    async (
      searchQuery: string,
      filterQuery: WarrantyClientBeastClientBeastEstimateDtoCartPaginatedGetEstimateCartStoreGetFilterDto | undefined,
    ) => {
      setIsDevicesLoading(true);
      const request: SelectAllControllerRequest = {
        organizationId,
        deviceType,
        markupPercentage: opportunity.markupPercentage,
        durationMonths: +opportunity.duration,
        coterminationEnabled: opportunity.coTermination,
        selectedPlans: selectedPlans.map(plan => ({ assetAccountId: plan.assetAccountId, pricingPlanId: plan.selectedPlan })),
        search: searchQuery,
        filter: filterQuery,
      };
      const response = await selectAllAssets(request);

      const estimatedSelectedPlans: WarrantyCartSelectedPlan[] =
        response?.cartSaved.units.map(unit => ({
          assetAccountId: unit.assetAccountId,
          selectedPlan: unit.renewalOptionPlanId,
        })) ?? [];

      const estimatesSelectedUnits: WarrantyCartSelectedAsset[] =
        response?.cartSaved.units.map(unit => ({
          assetAccountId: unit.assetAccountId,
          distributor: unit.distributorEnum,
          renewalStart: unit.renewalStart,
        })) ?? [];
      setSelectedPlans(estimatedSelectedPlans);
      setSelectedUnits(estimatesSelectedUnits);
      setTotalCost(response?.cartSaved?.totalUsdSubunits ?? 0);
      setEstimateId(response?.cartSaved?.estimateId);
      setIsDevicesLoading(false);
    },
    [
      deviceType,
      opportunity.coTermination,
      opportunity.duration,
      opportunity.markupPercentage,
      organizationId,
      selectAllAssets,
      selectedPlans,
    ],
  );

  // End API area

  // Invokes when estimate with plans exists
  const updatePlansWithEstimateValues = useCallback((units: EstimateCartUnitDto[]) => {
    setSelectedPlans(prev => {
      const convertedUnits = units.map(
        (unit: EstimateCartUnitDto): WarrantyCartSelectedPlan => ({
          assetAccountId: unit.assetAccountId,
          selectedPlan: unit.renewalOptionPlanId,
        }),
      );
      const selectedPlansMap = new Map(prev.map(selectedPlan => [selectedPlan.assetAccountId, selectedPlan]));
      convertedUnits.forEach(unit => {
        selectedPlansMap.set(unit.assetAccountId, unit);
      });
      return Array.from(selectedPlansMap.values());
    });
  }, []);

  // Initialization
  useEffect(() => {
    (async () => {
      if (typeof settings === 'undefined') {
        return;
      }

      const opportunitiesSettings = getSettingsForDeviceType(selectedDeviceType, settings);
      const estimateCartGetResponse = await estimateCartGetApi();

      if (typeof estimateCartGetResponse === 'undefined') {
        return;
      }

      if (isInvalidType(warrantyType, selectedDeviceType)) {
        return;
      }

      if (isNotExists(estimateCartGetResponse) || wrongDeviceType(estimateCartGetResponse, selectedDeviceType)) {
        setSelectedUnits([]);
        await estimateCartUpsertApi(
          settings.coTerminationEnabled,
          opportunitiesSettings.coverageDuration,
          opportunitiesSettings.markupPercentage,
          [],
          [],
        );
      } else {
        if (typeof estimateCartGetResponse.estimate !== 'undefined') {
          const estimateCart = estimateCartGetResponse.estimate;
          setSelectedUnits(
            estimateCart.units.map(unit => ({
              assetAccountId: unit.assetAccountId,
              distributor: unit.distributorEnum,
              renewalStart: unit.renewalStart,
            })),
          );
          setTotalCost(estimateCart.totalUsdSubunits);
          setOpportunity({
            coTermination: estimateCart.coterminationEnabled,
            duration: estimateCart.durationMonths.toString(),
            markupPercentage: estimateCart.markupPercentage,
          });
          setEstimateId(estimateCart.estimateId);
          // Override default plans with an estimate values
          updatePlansWithEstimateValues(estimateCart.units);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateCartGetApi, estimateCartUpsertApi, organizationId, selectedDeviceType, settings, warrantyType]);

  // Triggers on opportunities form mutated
  const onOpportunitiesUpdates = (opportunitiesFormData: WarrantyCartOpportunitiesFormData) =>
    estimateCartUpsertApi(
      opportunitiesFormData.coTermination,
      +opportunitiesFormData.durationMonth,
      opportunitiesFormData.markup,
      selectedUnits,
      selectedPlans,
    );

  // Triggers when user select/unselect table item
  const selectUnit = useCallback(
    async (unit: EstimateCartStoreGetUnitDto): Promise<void> => {
      if (selectedUnits.some(p => p.assetAccountId === unit.assetAccountId)) {
        const filteredUnits = selectedUnits.filter(p => p.assetAccountId !== unit.assetAccountId);
        await estimateCartUpsertApi(
          opportunity.coTermination,
          +opportunity.duration,
          opportunity.markupPercentage,
          filteredUnits,
          selectedPlans,
        );
      } else {
        let distributor: WarrantyClientBeastClientBeastRenewalModelDistributorDistributorFactoryNestedEnum;
        const currentPlan = selectedPlans.find(plan => plan.assetAccountId === unit.assetAccountId);
        const newPlans = [...selectedPlans];
        if (typeof currentPlan === 'undefined') {
          newPlans.push({ assetAccountId: unit.assetAccountId, selectedPlan: unit.renewalOptions[0].planId });
          setSelectedPlans(newPlans);
          distributor = unit.renewalOptions[0].distributorEnum;
        } else {
          distributor =
            unit.renewalOptions.find(p => p.planId === currentPlan.selectedPlan)?.distributorEnum ?? unit.renewalOptions[0].distributorEnum;
        }
        const newUnits = [
          ...selectedUnits,
          {
            assetAccountId: unit.assetAccountId,
            distributor,
            renewalStart: unit.renewalStart,
          },
        ];
        await estimateCartUpsertApi(opportunity.coTermination, +opportunity.duration, opportunity.markupPercentage, newUnits, newPlans);
      }
    },
    [opportunity, estimateCartUpsertApi, selectedUnits, selectedPlans],
  );

  // Triggers when user selects another one plan
  const setUnitSelectedPlan = useCallback(
    async (assetAccountId: string, planId: string, callApi: boolean) => {
      const idx = selectedPlans.findIndex(p => p.assetAccountId === assetAccountId);
      const newSelectedPlans = [...selectedPlans];
      if (idx > -1) {
        newSelectedPlans[idx].selectedPlan = planId;
      } else {
        newSelectedPlans.push({ assetAccountId, selectedPlan: planId });
      }
      setSelectedPlans(newSelectedPlans);
      // we have to call upsert only when user changed plan
      if (callApi) {
        await estimateCartUpsertApi(
          opportunity.coTermination,
          +opportunity.duration,
          opportunity.markupPercentage,
          selectedUnits,
          newSelectedPlans,
        );
      }
    },
    [opportunity, estimateCartUpsertApi, selectedPlans, selectedUnits],
  );

  const deselectAll = useCallback(async () => {
    setIsDevicesLoading(true);
    await estimateCartUpsertApi(opportunity.coTermination, +opportunity.duration, opportunity.markupPercentage, [], selectedPlans);
    setIsDevicesLoading(false);
  }, [estimateCartUpsertApi, opportunity, selectedPlans]);

  return (
    <OpportunitiesMutationContext.Provider
      value={{ triggerUpsert: (formData: WarrantyCartOpportunitiesFormData) => onOpportunitiesUpdates(formData) }}
    >
      <WarrantyCartContext.Provider
        value={{
          displayCurrency: settings?.currencyInformation.displayCurrency ?? '',
          durationOptions: settings?.durationOptions ?? [],
          organizationName,
          organizationId,
          selectedDeviceType,
          setSelectedDeviceType,
          availableAssetTypes,
          totalCost,
          markupPercentage: opportunity.markupPercentage,
          coTermination: opportunity.coTermination,
          duration: opportunity.duration,
          getAssetCost,
          getCoverageEnds,
          getIsUnitSelected,
          selectUnit,
          formatCostOutput,
          getCurrency,
          getUnitSelectedPlan,
          setUnitSelectedPlan,
          getSelectedAssetsCount,
          estimateId,
          getCoTerminationDuration,
          apiUpsertLoading,
          getSelectedAccountIds,
          selectAll: selectAllHandler,
          deselectAll,
          displayEmptyStateImage,
          continuousRenewalPolicyVisible,
          setContinuousRenewalPolicyVisible,
          warrantyType,
          totalAssetsCount,
          setTotalAssetsCount,
        }}
      >
        {children}
        <WarrantyCartOverlayLoader showLoader={isDevicesLoading} />
      </WarrantyCartContext.Provider>
    </OpportunitiesMutationContext.Provider>
  );
};

export default WarrantyCartProvider;
