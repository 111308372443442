import { ColumnDto } from '@AssetManagementClient/BeastClient/Search/Model/Console/Dto.gen';
import FormCheckboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckboxInput';
import AutoEmailReportFormData, {
  autoEmailReportTemplateFieldKey,
} from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/types/AutoEmailReportFormData';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import React from 'react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';

export type AutoEmailReportFormInputColumnProps = { column: ColumnDto };

const AutoEmailReportFormInputColumn = ({ column }: AutoEmailReportFormInputColumnProps) => {
  const { setFormInput } = useFormContext<AutoEmailReportFormData>();

  const autoEmailReportColumnKeyFieldKey: FieldKeyExpression<AutoEmailReportFormData> = values => values.columnKeys[column.key.value];

  return (
    <FormCheckboxInput
      fieldKey={autoEmailReportColumnKeyFieldKey}
      description={column.label}
      onInput={() => setFormInput<undefined>(autoEmailReportTemplateFieldKey, undefined)}
    />
  );
};

export default AutoEmailReportFormInputColumn;
