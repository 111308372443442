import { DiscountLineItem } from '@SubscriptionClient/BeastClient/Beast/Msp/Dto/Subscription/Pricing/Get/Model.gen';
import { ComponentPropsWithoutRef } from 'react';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import Info from '~/legacy-ui/packages/info/Info';
import PricingAmount from '~/wm/packages/subscription/packages/plan/packages/product-plan-summary/PricingAmount';

export type DiscountItemProps = Omit<ComponentPropsWithoutRef<'div'>, keyof DiscountLineItem> & DiscountLineItem;

const DiscountItem = (props: DiscountItemProps) => {
  const { name, amount, description, ...rest } = props;

  return (
    <div
      css={{ paddingLeft: '2.5rem' }}
      {...rest}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <Info
          title={name}
          titleSize="md"
          titleColor="dark-700"
          titleFontWeight="normal"
          description={description}
          descriptionColor="dark-050"
        />
        <PricingAmount.Unstriked
          amount={amount}
          color="positive-400"
          formatter={amount => `-${formatCurrency(amount)}`}
        />
      </div>
    </div>
  );
};

export default DiscountItem;
