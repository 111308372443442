import * as React from 'react';
import { AnimatePresence as AnimatePresenceInternal, motion } from 'framer-motion';

export type TransitionType = 'spring' | 'smooth';
export type TransitionDirection = 'left' | 'right' | 'top' | 'bottom';

export type AnimationPresenceProps = {
  isPresent: boolean;
  presenceAnimationType?: TransitionType;
  presenceDirection?: TransitionDirection;
  awayAnimationType?: TransitionType;
  awayDirection?: TransitionDirection;
};

const getExitAnimation = (direction: TransitionDirection, type: TransitionType) => {
  switch (direction) {
    case 'top':
      return {
        scaleY: 0,
        opacity: 0,
        y: '-50%',
        transition: { type },
      };
    case 'bottom':
      return {
        scaleY: 0,
        opacity: 0,
        y: '50%',
        transition: { type },
      };
    case 'left':
      return {
        scaleX: 0,
        opacity: 0,
        x: '-50%',
        transition: { type },
      };
    case 'right':
      return {
        scaleX: 0,
        opacity: 0,
        x: '50%',
        transition: { type },
      };
    default:
      return {
        scaleY: 0,
        opacity: 0,
        y: '-50%',
        transition: { type },
      };
  }
};

const getHiddenAnimation = (direction: TransitionDirection) => {
  switch (direction) {
    case 'top':
      return {
        scaleX: 1,
        scaleY: 1,
        opacity: 0,
        y: '-50%',
      };
    case 'bottom':
      return {
        scaleX: 1,
        scaleY: 1,
        opacity: 0,
        y: '50%',
      };
    case 'left':
      return {
        scaleX: 1,
        scaleY: 1,
        opacity: 0,
        x: '-50%',
      };
    case 'right':
      return {
        scaleX: 1,
        scaleY: 1,
        opacity: 0,
        x: '50%',
      };
    default:
      return {
        scaleX: 1,
        scaleY: 1,
        opacity: 0,
        y: '50%',
      };
  }
};

/**
 * Animates the presence of children
 */
const AnimationPresence: React.FunctionComponent<React.PropsWithChildren<AnimationPresenceProps>> = ({
  isPresent,
  presenceAnimationType = 'smooth',
  presenceDirection = 'top',
  awayAnimationType = 'smooth',
  awayDirection = 'top',
  children,
}) => {
  const animationVariants = {
    exit: getExitAnimation(awayDirection, awayAnimationType),
    hidden: getHiddenAnimation(presenceDirection),
    visible: {
      scaleX: 1,
      scaleY: 1,
      opacity: 1,
      x: 0,
      y: 0,
      transition: { type: presenceAnimationType },
    },
  };

  return (
    <AnimatePresenceInternal>
      {isPresent && (
        <motion.div
          variants={animationVariants}
          initial={'hidden'}
          animate={'visible'}
          exit={'exit'}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresenceInternal>
  );
};

export default AnimationPresence;
