import { BillingInfo as BillingInfoType } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import useWizardPageStepActionUpdate from '~/neo-ui/packages/wizard/packages/wizard-page/hooks/useWizardPageStepActionUpdate';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import useWizardPageContext from '~/neo-ui/packages/wizard/packages/wizard-page/context/hooks/useWizardPageContext';
import * as React from 'react';
import { AddressBusiness } from '@WarrantyClient/Common/Location/Model/Address.gen';
import Box from '~/neo-ui/packages/box/Box';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import DisposalAddressForm from '~/wm/packages/disposal/packages/disposal-address/packages/disposal-address-form/DisposalAddressForm';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { WizardPageSectionProps } from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageSection';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import FormCheckboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckboxInput';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';
import AccountPhoneNumber from '~/wm/packages/disposal/wizard/section/AccountPhoneNumber';

export type ReturnAddressSectionProps<T> = {
  billingInfo: BillingInfoType;
  dropOffLocationsUrl?: string;
  returnShippingAddressFieldKey: FieldKeyExpression<T>;
  addressIsValidFieldKey: FieldKeyExpression<T>;
  locationCertifiedFieldKey: FieldKeyExpression<T>;
  phoneNumberFieldKey: FieldKeyExpression<T>;
  phoneNumberIsValidKey: FieldKeyExpression<T>;
} & WizardPageSectionProps;

const ReturnAddressSection = <T,>({
  billingInfo,
  dropOffLocationsUrl,
  returnShippingAddressFieldKey,
  addressIsValidFieldKey,
  locationCertifiedFieldKey,
  onEditing,
  phoneNumberFieldKey,
  phoneNumberIsValidKey,
}: ReturnAddressSectionProps<T>) => {
  useWizardPageStepActionUpdate();

  const { getFormInput, setFormInput } = useFormContext<T>();
  const { setFieldCompleted } = useWizardPageContext();

  const [isLocationCertified, setIsLocationCertified] = React.useState<boolean>(getFormInput(locationCertifiedFieldKey).value);

  const isAddressValidated = getFormInput(addressIsValidFieldKey).value;

  const formPhoneNumber = getFormInput(phoneNumberFieldKey);
  const setFormPhoneNumber = (value: string): void => {
    setFormInput<string>(phoneNumberFieldKey, value);
  };

  const isPhoneNumberValid = getFormInput(phoneNumberIsValidKey);

  const setIsValidPhone = (value: boolean): void => {
    setFormInput<boolean>(phoneNumberIsValidKey, value);
  };

  React.useEffect(() => {
    setFieldCompleted(locationCertifiedFieldKey, isLocationCertified);
    setFieldCompleted(phoneNumberFieldKey, isPhoneNumberValid.value);
  }, [isLocationCertified, isPhoneNumberValid.value, locationCertifiedFieldKey, phoneNumberFieldKey, setFieldCompleted]);

  const updateFormsReturnShippingAddress = (updatedAddressBusiness: AddressBusiness) => {
    setFormInput<AddressBusiness>(returnShippingAddressFieldKey, updatedAddressBusiness);
    setFieldCompleted(returnShippingAddressFieldKey, true);
    onEditing(false);
  };

  const onLocationCertifiedChanged = (value: boolean) => {
    setIsLocationCertified(value);
    setFormInput<boolean>(locationCertifiedFieldKey, value);
  };

  return (
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
    >
      <LayoutSection
        title={typeof dropOffLocationsUrl === 'undefined' ? 'Return address' : 'Pickup & return address'}
        titleSize={4}
        titleWeight={'bold'}
        description={
          <Label muted={true}>After you’ve placed your order, you'll be able to set your preferred pickup dates and times.</Label>
        }
      >
        <DisposalAddressForm
          address={isAddressValidated ? getFormInput(returnShippingAddressFieldKey).value : billingInfo.billingAddress}
          isAddressValidated={isAddressValidated}
          onAddressDataReady={(isReady, isValidated, addressBusiness) => {
            onEditing(!isReady);
            setFormInput<boolean>(addressIsValidFieldKey, isValidated);
            setFieldCompleted(returnShippingAddressFieldKey, isReady);
            if (typeof addressBusiness !== 'undefined') {
              updateFormsReturnShippingAddress(addressBusiness);
            }
          }}
        />
        <AccountPhoneNumber
          initialValue={formPhoneNumber.value}
          setFormValue={setFormPhoneNumber}
          setIsValidPhone={setIsValidPhone}
        />
        <div
          css={css`
            display: flex;
          `}
        >
          <FormCheckboxInput
            fieldKey={locationCertifiedFieldKey}
            onChange={e => onLocationCertifiedChanged(e.target.checked)}
            checked={isLocationCertified}
            description={'All the assets are at this location'}
          />
          <Tooltip
            css={css`
              max-width: 12.5rem;
            `}
            backgroundColor={'light-000'}
            content={'If you need to dispose of assets at multiple locations, please place separate orders.'}
            placement={'top-start'}
          >
            <Icon
              icon={'Info'}
              sizePx={16}
              color={'dark-900-24'}
              css={css`
                margin-left: 0.625rem;
              `}
            />
          </Tooltip>
          {!isPhoneNumberValid.value && (
            <Tooltip
              css={css`
                max-width: 12.5rem;
              `}
              backgroundColor={'light-000'}
              content={'Please, fix phone number first'}
              placement={'top-start'}
            >
              <Icon
                icon={'Warning'}
                sizePx={16}
                color={'negative-600'}
                css={css`
                  margin-left: 0.625rem;
                `}
              />
            </Tooltip>
          )}
        </div>
      </LayoutSection>
    </Box>
  );
};

export default ReturnAddressSection;
