import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { PricingDto } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/PricingModel.gen';
import { subscriptionSelfServePricingDtoGet } from '@SubscriptionClient/SubscriptionClientMsp.gen';

const useSelfServePricingDtoGet = (productTierIds: string[]) => {
  const [isLoading, setLoading] = useState(true);
  const [pricingDto, setPricingDto] = useState<PricingDto>();

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
        subscriptionSelfServePricingDtoGet({
          productTierIds,
        }),
      );
      setLoading(false);

      if (!response) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setPricingDto(response.pricingDto);
    })();
  }, [callApi, productTierIds]);

  return { isLoading, pricingDto };
};

export default useSelfServePricingDtoGet;
