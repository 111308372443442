import WizardPageStep from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageStep';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import DisposalBulkCheckoutFormData from '~/wm/packages/disposal/types/DisposalBulkCheckoutFormData';
import BulkOrderSummaryWithoutBillingSection from '~/wm/packages/disposal/wizard/section/BulkOrderSummaryWithoutBillingSection';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import { DisposalOrderPickupMaterialTypeDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderPickup/Packages/DisposalOrderPickupMaterial/Dto/Model.gen';

const assetTypeCountsFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values => values.assetTypeCounts;

const materialsFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values => values.materials;

const shipmentReturnAddressFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values =>
  values.shipmentReturnAddress.billingAddress;

const getDisposalBulkSummaryStep = (
  disposalTermsOfServiceUrl: string,
  termsOfServiceUrl: string,
  brandName: string,
  disposalAssetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[],
  disposalOrderPickupMaterialTypeAvailabilities: DisposalOrderPickupMaterialTypeDto[],
): WizardPageStep<DisposalBulkCheckoutFormData> => ({
  header: {
    label: 'Summary',
    icon: 'List',
  },
  fields: [],
  sections: [
    () =>
      BulkOrderSummaryWithoutBillingSection({
        disposalTermsOfServiceUrl,
        termsOfServiceUrl,
        brandName,
        assetTypeCountsFieldKey,
        materialsFieldKey,
        shipmentReturnAddressFieldKey,
        disposalAssetTypeAvailabilities,
        disposalOrderPickupMaterialTypeAvailabilities,
      }),
  ],
  onCompleteStateChange: () => true,
  key: 'Billing',
});

export default getDisposalBulkSummaryStep;
