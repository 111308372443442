import Button from '~/neo-ui/packages/button/Button';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import React from 'react';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import useAutoEmailReportEmailTemplateContext from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/context/hooks/useAutoEmailReportEmailTemplateContext';
import FormSelectInput, { FormSelectOption } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import { AutoEmailReportEmailTemplateEditFormData } from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/auto-email-report-email-template-edit-form/AutoEmailReportEmailTemplateEditForm';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import FormTitleInput from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/FormTitleInput';
import FormTextareaInput from '~/neo-ui/packages/form/packages/form-input/packages/form-textarea-input/FormTextareaInput';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import Anchor from '~/neo-ui/packages/anchor/Anchor';
import { css } from '@emotion/react';

const replyToUserFieldKey: FieldKeyExpression<AutoEmailReportEmailTemplateEditFormData> = values => values.replyToUserId;
const emailSubjectFieldKey: FieldKeyExpression<AutoEmailReportEmailTemplateEditFormData> = values => values.emailSubject;
const emailBodyFieldKey: FieldKeyExpression<AutoEmailReportEmailTemplateEditFormData> = values => values.emailBody;

export type AutoEmailReportEmailTemplateEditSectionProps = {
  accountEmailTemplateEditUrl: string;
};

const AutoEmailReportEmailTemplateEditSection = ({ accountEmailTemplateEditUrl }: AutoEmailReportEmailTemplateEditSectionProps) => {
  const { organizationName } = useOrganizationContext();
  const { submitForm, isSubmitting } = useFormContext<AutoEmailReportEmailTemplateEditFormData>();
  const { emailReplyToUserAvailabilities } = useAutoEmailReportEmailTemplateContext();
  const replyToUserOptions: FormSelectOption[] = emailReplyToUserAvailabilities.map(userAvailability => ({
    label: `${userAvailability.fullName} <${userAvailability.emailAddress}> ${userAvailability.emailIsBouncing ? '(Bouncing)' : ''}`,
    value: userAvailability.userId,
  }));

  return (
    <LayoutSection
      title={'Email template'}
      titleSize={3}
      description={
        <div>
          <Label>{`These are your customized settings just for ${organizationName}.`}</Label>
          <Anchor href={accountEmailTemplateEditUrl}>{'Change your defaults in app Settings.'}</Anchor>
        </div>
      }
      descriptionColor={'dark-900'}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        `}
      >
        <Label bold={true}>Reply-To</Label>
        <FormSelectInput
          options={replyToUserOptions}
          fieldKey={replyToUserFieldKey}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        `}
      >
        <Label bold={true}>Subject</Label>
        <FormTitleInput fieldKey={emailSubjectFieldKey} />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        `}
      >
        <Label bold={true}>Body</Label>
        <FormTextareaInput fieldKey={emailBodyFieldKey} />
      </div>

      <Button
        theme={'positive'}
        loading={isSubmitting}
        onClick={() => submitForm()}
      >
        Save settings
      </Button>
    </LayoutSection>
  );
};

export default AutoEmailReportEmailTemplateEditSection;
