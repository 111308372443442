import { css } from '@emotion/react';
import React, { ReactNode } from 'react';
import { ConsoleState, Filter } from '~/neo-ui/packages/table/packages/console/types';
import ConsoleRenderFilterFormatter from '~/neo-ui/packages/table/packages/console/packages/filter/ConsoleRenderFilterFormatter';

export type FilterBarProps = {
  filters: Filter[];
  consoleState: ConsoleState;
  onFilterChange: (key: string, value: Map<string, string[]>) => void;
};

const drawFilters = (filters: Filter[], renderFunction: (filter: Filter) => ReactNode) =>
  filters &&
  Array.from(filters.values() ?? [])
    .sort((a, b) => a.order - b.order)
    .map(renderFunction);

export const FilterBar: React.FunctionComponent<FilterBarProps> = ({ filters, consoleState, onFilterChange }) => {
  const renderFilter = React.useCallback(
    (filter: Filter) => ConsoleRenderFilterFormatter(filter, onFilterChange, consoleState),
    [consoleState, onFilterChange],
  );

  return (
    <div
      css={css`
        display: flex;
        row-gap: 0.625rem;
        column-gap: 0.375rem;
        margin-bottom: 1.25rem;
        flex-wrap: wrap;
      `}
    >
      {drawFilters(filters, renderFilter)}
    </div>
  );
};
