import { useDispatch } from 'react-redux';
import { AlertModalData } from '../AlertModal';
import { showModalAction } from '../state/action/ModalAction';

/**
 * Modal hook.
 *
 * Returns a function to show the modal with specified properties.
 */
const useModal = () => {
  const dispatch = useDispatch();
  return (info: AlertModalData) => dispatch(showModalAction(info));
};

export default useModal;
