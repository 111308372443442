import * as React from 'react';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';

export type SelectableCheckboxHeaderProps = {
  ids: string[];
  selectedIds: Set<string>;
  onSelectIds: (items: string[], itemClicked: string | undefined) => void;
  onDeselectIds: (items: string[], itemClicked: string | undefined) => void;
};

/**
 * A checkbox used to select a single row of a table
 */
const SelectableCheckboxHeader = ({ ids, selectedIds, onSelectIds, onDeselectIds }: SelectableCheckboxHeaderProps) => {
  const [isSelected, setIsSelected] = React.useState(false);
  React.useEffect(() => setIsSelected(selectedIds.size === ids.length), [ids.length, selectedIds]);

  return (
    <InputCheckbox
      checked={isSelected}
      onClick={() => (isSelected ? onDeselectIds(ids, ids[0]) : onSelectIds(ids, ids[0]))}
      indeterminate={selectedIds.size > 0 && selectedIds.size !== ids.length}
    />
  );
};

export default SelectableCheckboxHeader;
