import * as React from 'react';
import { DisposalOrderSummaryDtoNested } from '@WarrantyClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Dto/Model.gen';
import Box from '~/neo-ui/packages/box/Box';
import DisposalWorkstationAssuranceSuccessSection from '~/wm/packages/disposal/wizard/success/packages/disposal-workstation-assurance-success-section/DisposalWorkstationAssuranceSuccessSection';

export type CoverageTransferSuccessProps = {
  disposalOrderListUrl: string;
  hasDisposalLocationAccess: boolean;
  disposalOrderSummary: DisposalOrderSummaryDtoNested.WorkstationAssurance;
};

const CoverageTransferSuccess = ({ disposalOrderSummary, hasDisposalLocationAccess }: CoverageTransferSuccessProps) => {
  if (!hasDisposalLocationAccess) {
    window.location.href = '/';
  }

  return hasDisposalLocationAccess ? (
    <>
      {typeof disposalOrderSummary !== 'undefined' && (
        <DisposalWorkstationAssuranceSuccessSection disposalOrderSummary={disposalOrderSummary} />
      )}
    </>
  ) : (
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
    >
      The transfer is complete. You will be redirected to your Dashboard in a moment.
    </Box>
  );
};

export default CoverageTransferSuccess;
