import { PrerequisiteTone } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Rules/Packages/Prerequisite/Model.gen';
import Color from '~/neo-ui/packages/color/Color.gen';

export const prerequisiteToneToDisplayDefinition = (tone: PrerequisiteTone): Color => {
  switch (tone) {
    case PrerequisiteTone.Positive:
      return 'positive-400';
    case PrerequisiteTone.Neutral:
      return 'secondary-400';
    case PrerequisiteTone.Negative:
      return 'negative-400';
  }
};
