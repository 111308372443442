import { object, string, array, lazy, mixed, number, boolean } from 'yup';
import { Response } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Controller/Field/InsightCreateFormMetadataNested.gen';
import { UnitTypeEnum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Dto/Model/CreateForm/PolicyFormUnitDtoNested.gen';
import {
  InsightFormData,
  InsightPolicyFormData,
  PolicyValueType,
} from '~/wm/packages/strategy/packages/insight/packages/insight-create-button/insight-create-modal/InsightFormData';
import { PolicyFormUnitDtoNested } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Dto/Model/CreateForm.gen';

const insightFormDataValidationSchema = (insightFormMetadata: Response | undefined) => {
  const getPolicyFormDto = (policyEnum: string | undefined) => insightFormMetadata?.policies.find(policy => policy.key === policyEnum);

  const getPolicyUnit = (policyEnum: string | undefined) => getPolicyFormDto(policyEnum)?.unit.key;

  const getMinLimit = (selectedPolicyEnum: string | undefined): number => {
    const selectedPolicy = getPolicyFormDto(selectedPolicyEnum);
    const minLimit = selectedPolicy !== undefined && (selectedPolicy.unit as PolicyFormUnitDtoNested.Integer).minLimit;
    return typeof minLimit === 'number' ? minLimit : Number.MIN_SAFE_INTEGER;
  };

  const getMaxLimit = (selectedPolicyEnum: string | undefined): number => {
    const selectedPolicy = getPolicyFormDto(selectedPolicyEnum);
    const maxLimit = selectedPolicy !== undefined && (selectedPolicy.unit as PolicyFormUnitDtoNested.Integer).maxLimit;
    return typeof maxLimit === 'number' ? maxLimit : Number.MAX_SAFE_INTEGER;
  };

  return typeof insightFormMetadata === 'undefined'
    ? undefined
    : object<InsightFormData>({
        title: string().required('The name is required.'),
        description: string().required('The description is required.'),
        assetScope: string()
          .required('You must select an asset type.')
          .matches(/^[a-z]+-[a-z]+$/i, 'The selected asset type is invalid.'),
        policies: array()
          .of(
            lazy((policy: InsightPolicyFormData) =>
              object<InsightPolicyFormData>({
                key: string(),
                policyEnum: string().required('You must select a policy.'),
                policyOperatorEnum: string().required('You must select an operator for the policy.'),
                value: mixed<PolicyValueType>()
                  .required('You must provide a value for the policy.')
                  .when('policyEnum', {
                    is: (value: string) => getPolicyFormDto(value) !== undefined && getPolicyUnit(value) === UnitTypeEnum.Integer,
                    then: number()
                      .required('You must provide a positive integer.')
                      .typeError('This value must be a number.')
                      .integer('This value must be an integer.')
                      .min(getMinLimit(policy.policyEnum), `This value must be greater than or equal to ${getMinLimit(policy.policyEnum)}.`)
                      .max(getMaxLimit(policy.policyEnum), `This value must be less than or equal to ${getMaxLimit(policy.policyEnum)}.`),
                  })
                  .when('policyEnum', {
                    is: (value: string) => getPolicyFormDto(value) !== undefined && getPolicyUnit(value) === UnitTypeEnum.Boolean,
                    then: boolean().required().typeError('The selected value is invalid'),
                  }),
              }).required(),
            ),
          )
          .required()
          .min(1, 'You must select at least one policy')
          .max(insightFormMetadata.policies.length)
          .test('unique policies', 'You can only use one of each policy with one of each operator', value => {
            const selectedPolicies =
              value?.map((policy: InsightPolicyFormData) => `${policy.policyEnum ?? ''}-${policy.policyOperatorEnum ?? ''}`) ?? [];
            return new Set(selectedPolicies).size === selectedPolicies.length;
          }),
      });
};
export default insightFormDataValidationSchema;
