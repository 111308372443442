import { useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { disposalCartUpsert } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import buildDisposalCartUpsertPayloadDtoBySelfWizard from '../builder/buildDisposalCartUpsertPayloadDtoBySelfWizard';
import DisposalSelfCheckoutFormData, { DisposalType } from '~/wm/packages/disposal/types/DisposalSelfCheckoutFormData';
import DisposalBulkCheckoutFormData from '~/wm/packages/disposal/types/DisposalBulkCheckoutFormData';
import buildDisposalCartUpsertPayloadDtoByBulkWizard from '~/wm/packages/disposal/builder/buildDisposalCartUpsertPayloadDtoByBulkWizard';

export type UseDisposalCart = () => {
  updateDisposalCart: (disposalCartInfo: DisposalSelfCheckoutFormData | DisposalBulkCheckoutFormData) => void;
};

const useDisposalCart: UseDisposalCart = () => {
  const { callApi } = useApi();

  const updateDisposalCart = useCallback(
    async (disposalCartInfo: DisposalSelfCheckoutFormData | DisposalBulkCheckoutFormData): Promise<void> => {
      const disposalCartUpsertPayload = (() => {
        switch (disposalCartInfo.type) {
          case DisposalType.Self:
            return buildDisposalCartUpsertPayloadDtoBySelfWizard(disposalCartInfo);
          case DisposalType.Bulk:
            return buildDisposalCartUpsertPayloadDtoByBulkWizard(disposalCartInfo);
        }
      })();

      const response = await callApi(() => disposalCartUpsert({ disposalCartUpsertPayload }));

      if (!response) {
        return undefined;
      }
    },
    [callApi],
  );

  return {
    updateDisposalCart,
  };
};

export default useDisposalCart;
