import RoadmapQuarterPagination from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-pagination/packages/roadmap-quarter-pagination/RoadmapQuarterPagination';
import RoadmapYearPagination from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-pagination/packages/roadmap-year-pagination/RoadmapYearPagination';
import { Fragment } from 'react';
import { RoadmapViewMode } from '~/wm/packages/strategy/packages/roadmap-page/RoadmapPage';

export type RoadmapPaginationProps = { roadmapViewMode: RoadmapViewMode };

/**
 * Roadmap view mode gives quarterly pagination
 * List view mode gives yearly pagination
 */
const RoadmapPagination = ({ roadmapViewMode }: RoadmapPaginationProps) => {
  switch (roadmapViewMode) {
    case 'Roadmap':
      return <RoadmapQuarterPagination />;
    case 'List':
      return <RoadmapYearPagination />;
    default:
      return <Fragment />;
  }
};

export default RoadmapPagination;
