import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import * as React from 'react';
import { ContractAvailabilitiesDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Dto/Model.gen';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { css } from '@emotion/react';
import FormTextInput from '~/neo-ui/packages/form/packages/form-input/packages/form-text-input/FormTextInput';
import FormSelectInput from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import { ContractAssetInfo } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-link-assets-button/modal/ContractAssetInfo';
import ContractLinkAssetsButton from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-link-assets-button/ContractLinkAssetsButton';
import FormTextareaInput from '~/neo-ui/packages/form/packages/form-input/packages/form-textarea-input/FormTextareaInput';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';

export type ContractUpsertBasicInfoSectionProps<T> = {
  initialSelectedAssets: ContractAssetInfo[];
  organizationId: string;
  contractAvailabilities: ContractAvailabilitiesDto;
  nameFieldKey: FieldKeyExpression<T>;
  accountFieldType: FieldKeyExpression<T>;
  statusFieldKey: FieldKeyExpression<T>;
  locationFieldKey: FieldKeyExpression<T>;
  impactFieldKey: FieldKeyExpression<T>;
  thirdPartyFieldKey: FieldKeyExpression<T>;
  assetsFieldKey: FieldKeyExpression<T>;
  descriptionFieldKey: FieldKeyExpression<T>;
  isSyncedFromPsa: boolean;
};

const ContractUpsertBasicInfoSection = <T,>({
  initialSelectedAssets,
  organizationId,
  contractAvailabilities,
  nameFieldKey,
  accountFieldType,
  statusFieldKey,
  locationFieldKey,
  impactFieldKey,
  thirdPartyFieldKey,
  assetsFieldKey,
  descriptionFieldKey,
  isSyncedFromPsa,
}: ContractUpsertBasicInfoSectionProps<T>) => {
  const [selectedAssets, setSelectedAssets] = React.useState<ContractAssetInfo[]>(initialSelectedAssets);

  const { setFormInput } = useFormContext<T>();

  const formFieldCss = css`
    width: calc(100% / 3);
    display: flex;
    gap: 1rem;
    align-items: center;
  `;

  const dropdownOptionsStatus = contractAvailabilities?.statusAvailabilities.map(status => ({
    label: status.displayName,
    value: status.uniqueId,
  }));

  const dropdownOptionsImpact = contractAvailabilities?.impactAvailabilities.map(impact => ({
    label: impact,
    value: impact,
  }));

  const dropDownOptionThirdParty = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];

  return (
    <div>
      <Label
        size={'lg'}
        bold={true}
        css={css`
          margin-bottom: 1.5rem;
        `}
      >
        Basic Info
      </Label>

      <div
        css={css`
          display: flex;
          gap: 2rem;
        `}
      >
        <div css={formFieldCss}>
          <Label
            size={'md'}
            bold={true}
            css={css`
              display: flex;
            `}
          >
            Name
            <Label
              size={'md'}
              bold={true}
              color={'negative-400'}
            >
              *
            </Label>
          </Label>
          <FormTextInput
            css={css`
              flex-grow: 1;
              position: relative;
            `}
            fieldKey={nameFieldKey}
            placeholder={'Enter a name...'}
            fixedErrorPosition={true}
          />
        </div>
        <div css={formFieldCss}>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.5rem;
            `}
          >
            <Label
              size={'md'}
              bold={true}
              css={css`
                display: flex;
              `}
            >
              Account
            </Label>
            <Tooltip content={<Label>Use this field to indicate category or department</Label>}>
              <Icon
                icon={'Info'}
                sizePx={16}
                css={css`
                  display: flex;
                `}
              />
            </Tooltip>
          </div>
          <FormTextInput
            css={css`
              flex-grow: 1;
              position: relative;
            `}
            fieldKey={accountFieldType}
            placeholder={'Enter the name of a category or department'}
            fixedErrorPosition={true}
          />
        </div>
        <div css={formFieldCss}>
          <Label
            size={'md'}
            bold={true}
          >
            Status
          </Label>
          <FormSelectInput
            css={css`
              flex-grow: 1;
            `}
            disabled={isSyncedFromPsa}
            fieldKey={statusFieldKey}
            options={dropdownOptionsStatus}
          />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          gap: 2rem;
          margin-top: 1.5rem;
        `}
      >
        <div css={formFieldCss}>
          <Label
            size={'md'}
            bold={true}
          >
            Location
          </Label>
          <FormTextInput
            css={css`
              flex-grow: 1;
              position: relative;
            `}
            fieldKey={locationFieldKey}
            placeholder={'Enter a location...'}
            fixedErrorPosition={true}
          />
        </div>

        <div css={formFieldCss}>
          <Label
            size={'md'}
            bold={true}
          >
            Impact
          </Label>
          <FormSelectInput
            css={css`
              flex-grow: 1;
            `}
            fieldKey={impactFieldKey}
            options={dropdownOptionsImpact}
          />
        </div>

        <div css={formFieldCss}>
          <div
            css={css`
              display: flex;
              align-items: center;
              flex-display: row;
              gap: 0.5rem;
            `}
          >
            <Label
              size={'md'}
              bold={true}
            >
              3rd Party
            </Label>
            <Tooltip content={<Label>Contracts not managed by your MSP</Label>}>
              <Icon
                icon={'Info'}
                sizePx={16}
                css={css`
                  display: flex;
                `}
              />
            </Tooltip>
          </div>
          <FormSelectInput
            css={css`
              flex-grow: 1;
            `}
            fieldKey={thirdPartyFieldKey}
            options={dropDownOptionThirdParty}
          />
          <ContractLinkAssetsButton
            organizationId={organizationId}
            selectedAssets={selectedAssets}
            onSelectedAssets={selectedAssets => {
              setSelectedAssets(selectedAssets);
              setFormInput<ContractAssetInfo[]>(assetsFieldKey, selectedAssets);
            }}
            css={css`
              flex-grow: 1;
            `}
          />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          gap: 1rem;
          margin-top: 1.5rem;
        `}
      >
        <Label
          size={'md'}
          bold={true}
        >
          Description
        </Label>
        <FormTextareaInput
          css={css`
            flex-grow: 1;
            position: relative;
          `}
          fieldKey={descriptionFieldKey}
          placeholder={'Enter a description...'}
          optimizePerformance={false}
        />
      </div>
    </div>
  );
};

export default ContractUpsertBasicInfoSection;
