import { scorecardCategoryCreate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useScorecardMutation, { UseScorecardMutationOptions } from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardMutation';

export type UseScorecardCategoryCreate = {
  createScorecardCategory: (createPayload: { scorecardCategoryUniqueId?: string; label?: string }) => void;
  isCreatingScorecardCategory: boolean;
};

const useScorecardCategoryCreate = (scorecardId: string, options?: UseScorecardMutationOptions): UseScorecardCategoryCreate => {
  const [createScorecardCategory, isCreatingScorecardCategory] = useScorecardMutation(
    (createPayload: { scorecardCategoryUniqueId?: string; label?: string }) =>
      scorecardCategoryCreate({
        scorecardId,
        scorecardCategoryUniqueId: createPayload.scorecardCategoryUniqueId,
        label: createPayload.label,
      }),
    options,
  );

  return {
    createScorecardCategory,
    isCreatingScorecardCategory,
  };
};

export default useScorecardCategoryCreate;
