/**
 * Duplicates in Beast: de3cd7f5-038d-473d-b5db-a22a495c490b
 */
const engagementActionStatusFilter = ['Upcoming', 'Overdue', 'Completed'] as const;

export type EngagementActionStatusFilter = (typeof engagementActionStatusFilter)[number];

const isEngagementActionStatusFilter = (filter: string): filter is EngagementActionStatusFilter =>
  (engagementActionStatusFilter as readonly string[]).includes(filter);

export const stringToEngagementActionStatusFilter = (filter: string): EngagementActionStatusFilter => {
  if (isEngagementActionStatusFilter(filter)) {
    return filter;
  }

  throw new Error(`Invalid EngagementActionStatusFilter filter: ${filter}`);
};
