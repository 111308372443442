import * as React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { ContractSyncStatusDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Sync/Dto/Model.gen';
import { contractSyncGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import ContractSyncStatusMutationContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/context/ContractSyncStatusMutationContext';
import ContractStatusSyncContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/context/ContractStatusSyncContext';

export type ContractSyncStatusProviderProps = {
  organizationId: string;
  children: React.ReactNode;
};

const ContractSyncStatusProvider = ({ organizationId, children }: ContractSyncStatusProviderProps) => {
  const [contractSyncStatus, setContractSyncStatus] = React.useState<ContractSyncStatusDto | undefined>();
  const [isLoadingContractSyncStatus, setLoadingContractSyncStatus] = React.useState<boolean>(false);

  const { callApi } = useApi();

  // Get contract sync status
  const reload = React.useCallback(
    async (isRequestActive?: () => boolean) => {
      setLoadingContractSyncStatus(true);
      const contractSyncStatusResponse = await callApi(() => contractSyncGet({ organizationId }));

      if (!contractSyncStatusResponse) {
        return;
      }

      // if a function is provided to determine whether to propagate
      // a state update (to prevent race conditions), then it will
      // ensure not to update state. otherwise, each call updates state.
      if (!isRequestActive || isRequestActive()) {
        setContractSyncStatus(contractSyncStatusResponse.status);
      }

      setLoadingContractSyncStatus(false);
    },
    [callApi, organizationId],
  );

  React.useEffect(() => {
    reload();
  }, [reload]);

  return (
    <ContractSyncStatusMutationContext.Provider
      value={{
        triggerContractSyncStatusReload: isRequestActive => reload(isRequestActive),
      }}
    >
      <ContractStatusSyncContext.Provider
        value={{
          contractSyncStatus,
          isLoadingContractSyncStatus,
        }}
      >
        {children}
      </ContractStatusSyncContext.Provider>
    </ContractSyncStatusMutationContext.Provider>
  );
};

export default ContractSyncStatusProvider;
