import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import FormErrorMessage from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';
import useFormInputBuilder from '~/neo-ui/packages/form/packages/form-input/hooks/useFormInputBuilder';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Color from '~/neo-ui/packages/color/Color.gen';

/**
 * This represents props of a generic HTML input
 */
export type InputProps = Record<string, unknown> & {
  value?: string | number | undefined;
  className?: string;
};

export type FormInputTextProps<T> = {
  fieldKey: FieldKeyExpression<T>;
  children: (params: { field: InputProps; setFieldValue: (value: string) => void }) => React.ReactNode;
  inputProps?: InputProps;
  validationErrorColor?: Color;
  optimizePerformance?: boolean;
  mapValue?: (value: string) => string;
  onChange?: (value: string) => void;
  showError?: boolean;
  fixedErrorPosition?: boolean;
} & Styleable;

/**
 * @see FormInputInternal
 * Comparable to the other primitive, but adds additional possibilities of manipulation & performance optimizations
 * for text inputs.
 */
const FormInputTextInternal = <T,>({
  fieldKey,
  children,
  inputProps,
  validationErrorColor = 'negative-400',
  optimizePerformance,
  onChange,
  mapValue,
  className,
  showError = true,
  fixedErrorPosition = false,
}: FormInputTextProps<T>) => {
  const { field, setFieldValue } = useFormInputBuilder(fieldKey, {
    optimizePerformance,
    mapValue,
    onChange,
  });

  return (
    <div className={className}>
      {children({
        field: {
          ...inputProps,
          className: inputProps?.className ? ` ${inputProps.className}` : '',
          ...field,
        },
        setFieldValue,
      })}
      {showError && (
        <FormErrorMessage
          fieldKey={fieldKey}
          validationErrorColor={validationErrorColor}
          fixedPosition={fixedErrorPosition}
        />
      )}
    </div>
  );
};

export default FormInputTextInternal;
