import { useContext } from 'react';
import WarrantyCartFiltersContext from '~/wm/packages/warranty/packages/warranty-cart/context/WarrantyCartFiltersContext';

const useWarrantyCartFiltersContext = () => {
  const context = useContext(WarrantyCartFiltersContext);
  if (!context) {
    throw new Error('Tried to use WarrantyCartFiltersContext outside of an WarrantyCartFilterProvider');
  }

  return context;
};

export default useWarrantyCartFiltersContext;
