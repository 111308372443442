import * as React from 'react';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { WidgetDto } from '@DashboardClient/Dashboard/Packages/Widget/Packages/Dto/Model.gen';
import { ComponentPayload } from '~/wm/packages/dashboard/packages/widget/Widget';
import useReloadComponentProps from '~/wm/packages/dashboard/packages/dashboard-grid/hook/useReloadComponentProps';
import { buildWidgets } from '~/wm/packages/dashboard/packages/dashboard-grid/hook/buildWidgets';

export type DashboardGridProps = {
  layouts: Layouts;
  widgetAvailability: WidgetDto[];
  numColumns?: { [key: string]: number } | undefined;
  breakpoints?: { [key: string]: number } | undefined;
  onLayoutChange?: (currentLayout: Layout[], allLayouts: Layouts) => void;
  onBreakpointChange?: (newBreakpoint: string, newCols: number) => void;
};

export type ComponentProps = {
  id: string;
  payload: ComponentPayload | undefined;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ResponsiveGridLayout = WidthProvider(Responsive);

const DashboardGrid: React.FunctionComponent<DashboardGridProps> = ({
  layouts,
  widgetAvailability,
  numColumns,
  breakpoints,
  onLayoutChange,
  onBreakpointChange,
}) => {
  const componentsProps = useReloadComponentProps(widgetAvailability, 30000);
  if (!componentsProps || componentsProps.length === 0) {
    return null;
  }
  const widgets = buildWidgets(widgetAvailability, componentsProps);
  return (
    <ResponsiveGridLayout
      layouts={layouts}
      breakpoints={breakpoints}
      autoSize={true}
      cols={numColumns}
      isDraggable={false}
      isResizable={false}
      onLayoutChange={onLayoutChange}
      onBreakpointChange={onBreakpointChange}
      margin={[20, 20]}
    >
      {widgets.map(widget => (
        <div key={widget.key}>{widget}</div>
      ))}
    </ResponsiveGridLayout>
  );
};

export default DashboardGrid;
