import ToggleSwitch from '~/neo-ui/packages/toggle/packages/toggle-switch/ToggleSwitch';
import { Fragment, useState } from 'react';
import Dropdown from '~/neo-ui/packages/dropdown/Dropdown';
import { css } from '@emotion/react';
import useAutoEmailReportListContext from '~/wm/packages/organization/packages/auto-email-report/context/hooks/useAutoEmailReportListContext';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';
import { formatDateFriendly } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';
import { AutoEmailReportDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';
import useAutoEmailReportScheduleUpdate from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportScheduleUpdate';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';

export type AutoEmailReportControlsProps = {
  autoEmailReport: AutoEmailReportDto;
};

const AutoEmailReportControls = ({ autoEmailReport }: AutoEmailReportControlsProps) => {
  const { autoEmailReportAvailability } = useAutoEmailReportListContext();
  const [isEnabled, setIsEnabled] = useState(autoEmailReport.isScheduled);

  const { updateAutoEmailReportSchedule, isUpdatingAutoEmailReportSchedule } = useAutoEmailReportScheduleUpdate();
  const { organizationId } = useOrganizationContext();

  const selectedReportFrequency = {
    label: autoEmailReportAvailability.availableEmailFrequencies.find(frequency => frequency.id === autoEmailReport.emailFrequency)!
      .labelDisplay,
    value: autoEmailReportAvailability.availableEmailFrequencies.find(frequency => frequency.id === autoEmailReport.emailFrequency)!.id,
  };

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 2.25rem;
      `}
    >
      <ToggleSwitch
        onChange={isScheduled => {
          setIsEnabled(prev => !prev);
          updateAutoEmailReportSchedule({
            autoEmailReportId: autoEmailReport.autoEmailReportId,
            organizationId,
            autoEmailReportScheduleUpdatePayload: {
              autoEmailReportFrequency: selectedReportFrequency.value,
              isScheduled,
            },
          });
        }}
        checked={isEnabled}
        showLabel={true}
        theme={'positive'}
        disabled={autoEmailReport.autoEmailReportRecipients.length === 0 || isUpdatingAutoEmailReportSchedule}
      />
      <Dropdown
        options={autoEmailReportAvailability.availableEmailFrequencies.map(frequency => ({
          label: frequency.labelDisplay,
          value: frequency.id,
        }))}
        selectedOption={selectedReportFrequency}
        onOptionSelected={option => {
          updateAutoEmailReportSchedule({
            autoEmailReportId: autoEmailReport.autoEmailReportId,
            organizationId,
            autoEmailReportScheduleUpdatePayload: {
              autoEmailReportFrequency: option.value,
              isScheduled: isEnabled,
            },
          });
        }}
      />
      <Label
        muted={true}
        css={css`
          display: flex;
          align-items: center;
          gap: 0.5rem;
        `}
      >
        {autoEmailReport.autoEmailReportRecipients.length === 0
          ? 'To get started, add some recipients below.'
          : autoEmailReport.nextSendAt && (
              <Fragment>
                <Icon
                  icon={'Calendar'}
                  color={'dark-900-32'}
                />{' '}
                Next report will send on {formatDateFriendly(parseISO(autoEmailReport.nextSendAt))}
              </Fragment>
            )}
      </Label>
    </div>
  );
};

export default AutoEmailReportControls;
