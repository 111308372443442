import { date, object, string } from 'yup';
import {
  isDateAfterPickupBlockDate,
  isDateAtLeastFiveBusinessDaysApart,
  isDateAtLeastSevenBusinessDaysInFuture,
  isDateLessThanOther,
  isDateOutsideWinterHolidayDateRange,
  isTimeLessThanOther,
} from './packages/disposal-order-bulk-pickup-availability-schedule-validation-functions/DisposalOrderBulkPickupAvailabilityScheduleValidationFunctions';

const disposalOrderBulkPickupAvailabilityScheduleFormValidationSchema =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object().shape<any>({
    siteContactName: string().required('Enter a name.'),
    siteContactEmail: string().email('Enter an email.').required('Enter an email.'),
    siteContactPhone: string().required('Enter a phone number.'),
    startingDate: date()
      .required('Required.')
      .min(new Date(), 'Date must be at least four business days in the future.')
      // Duplicated in Backend: d914e035-15fa-4fcf-8fad-aade898d3937
      .test('is_7_business_days_in_future', 'Date must be at least 7 business days in the future.', startingDate =>
        isDateAtLeastSevenBusinessDaysInFuture(startingDate),
      )
      // Duplicated in Backend: 957a2c49-84b4-4117-8757-a4c511c67a34
      .test('is_greater_than_starting_date', 'Starting date must be before Ending date.', function (startingDate) {
        const { endingDate } = this.parent;
        return isDateLessThanOther(startingDate, endingDate as unknown as Date);
      })
      // Duplicated in Backend: 2884ee7b-af3a-4497-b8d1-a7a3c12bcc47
      .test('is_5_business_days_apart', 'Starting date must be at least 5 business days before Ending date', function (startingDate) {
        const { endingDate } = this.parent;
        return isDateAtLeastFiveBusinessDaysApart(startingDate, endingDate as unknown as Date);
      })
      // Duplicated in Backend: B69E9B1D-7241-4DE7-BAA3-607608534342
      .test('is_start_after_winter_holidays', 'Next available pickup date is January 8th.', startingDate =>
        isDateOutsideWinterHolidayDateRange(startingDate, startingDate!.getFullYear()),
      )
      // Duplicated in Backend: 1f6f8b9c-8b9e-4b9e-9b9e-8b9e4b9e8b9e
      .test('is_start_after_pickup_block_date', 'Next available pickup date is January 18th', startingDate =>
        isDateAfterPickupBlockDate(startingDate),
      ),
    endingDate: date()
      .required('Required.')
      // Duplicated in Backend: 957a2c49-84b4-4117-8757-a4c511c67a34
      .test('is_greater_than_starting_date', 'Ending date must be after Starting date.', function (endingDate) {
        const { startingDate } = this.parent;
        return isDateLessThanOther(startingDate as unknown as Date, endingDate);
      })
      // Duplicated in Backend: 2884ee7b-af3a-4497-b8d1-a7a3c12bcc47
      .test('is_5_business_days_apart', 'Ending date must be at least 5 business days after Starting date', function (endingDate) {
        const { startingDate } = this.parent;
        return isDateAtLeastFiveBusinessDaysApart(startingDate as Date, endingDate);
      })
      // Duplicated in Backend: B69E9B1D-7241-4DE7-BAA3-607608534342
      .test('is_end_before_winter_holidays', 'Last available pickup date is December 18th.', function (endingDate) {
        const { startingDate } = this.parent;
        return isDateOutsideWinterHolidayDateRange(endingDate, (startingDate as Date).getFullYear());
      }) // Duplicated in Backend: 1f6f8b9c-8b9e-4b9e-9b9e-8b9e4b9e8b9e
      .test('is_end_after_pickup_block_date', 'Next available pickup date is January 18th', endingDate =>
        isDateAfterPickupBlockDate(endingDate),
      ),
    // Duplicated in Backend: 957a2c49-84b4-4117-8757-a4c511c67a34
    startingTime: string()
      .required('Required.')
      .test('timeTest', 'The Starting time must be before the Ending time', function (startingTime) {
        const { endingTime } = this.parent;
        return isTimeLessThanOther(startingTime, endingTime as string);
      }),
    // Duplicated in Backend: 957a2c49-84b4-4117-8757-a4c511c67a34
    endingTime: string()
      .required('Required.')
      .test('timeTest', 'The Ending time must be after the Starting time', function (endingTime) {
        const { startingTime } = this.parent;
        return isTimeLessThanOther(startingTime as string, endingTime);
      }),
  });

export default disposalOrderBulkPickupAvailabilityScheduleFormValidationSchema;
