import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import * as React from 'react';

export type WizardPageProgressSegmentSuccessProps = {
  isActive: boolean;
};

const WizardPageProgressSegmentSuccess = ({ isActive }: WizardPageProgressSegmentSuccessProps) => (
  <span
    data-testid={`step-progress-success`}
    css={css`
      height: 1.5rem;
      width: 1.5rem;
      background-color: ${isActive ? colorToCode('positive-400') : colorToCode('dark-900-08')};
      border-radius: 50%;
    `}
  >
    <Icon
      icon={'Success'}
      color={isActive ? 'light-000' : 'light-600'}
      css={css`
        margin-top: 0.25rem;
        margin-left: 0.25rem;
      `}
    />
  </span>
);

export default WizardPageProgressSegmentSuccess;
