import { createContext } from 'react';

export type FormContextInternalValue<TFormData, TResponse> = {
  addBeforeSubmitListener: (onBeforeSubmit: (formData: TFormData) => Promise<void>) => () => void;
  addAfterSubmitListener: (onAfterSubmit: (formData: TFormData, formResponse: TResponse) => Promise<void>) => () => void;
};

/**
 * Extra form context provided within our Form component.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const FormContextInternal = createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormContextInternalValue<any, any> | undefined
>(undefined);
