import { Filter } from '~/neo-ui/packages/table/packages/console/types';
import { Enum } from '@AssetManagementClient/BeastClient/Search/Model/Query/Field/Filter/Render/RenderFilterNested.gen';
import { RenderFilter } from '@AssetManagementClient/BeastClient/Search/Model/Query/Field/Filter/Render.gen';
import Theme, { themeToColor } from '~/neo-ui/packages/color/Theme';
import Badge from '~/neo-ui/packages/badge/Badge';
import React from 'react';
import FilterBadgeSplitMultiSelect from '~/neo-ui/packages/filter/packages/filter-headers/filter-header/packages/filter-header-selected-split-multi-select/FilterBadgeSplitMultiSelect';
import RangeFilterBadge from '~/neo-ui/packages/filter/packages/filter-range/RangeFilterBadge';
import FilterBadgeLabel from '~/neo-ui/packages/filter/packages/filter-badge-label/FilterBadgeLabel';

/**
 * Formats a console filter into a read-only badge that represents the selected state
 * of the filter.
 *
 * Do not provide a filter that is not selected.
 */
const ConsoleRenderBadgeFormatter = (filter: Filter, parameters: Map<string, Map<string, string[]>>) => {
  const render = filter.render ?? ({ case: Enum.MultiSelect } as RenderFilter);
  const parameterValue = parameters.get(filter.key)!;
  switch (render.case) {
    case Enum.MultiSelect: {
      const values = new Set(parameterValue.get(render.displayOptions!.filterValue.key) ?? []);
      const selectedValues = render.options.filter(v => values.has(v.key)).map(selectedValue => selectedValue.label);

      return (
        <Badge
          borderRadius={'radius200'}
          bgColor={'secondary-600'}
        >
          <FilterBadgeLabel
            selectedValues={selectedValues}
            operatorLabel={render.displayOptions?.filterValue?.label ?? ''}
            theme={'secondary'}
          />
        </Badge>
      );
    }
    case Enum.SingleSelect: {
      const values = parameterValue?.get(render.operator) ?? [];
      const option = render.options.find(option => option.key === values[0]);
      return <Badge borderRadius={'radius200'}>{option?.label}</Badge>;
    }
    case Enum.ButtonSelect: {
      const values = parameterValue?.get(render.operator) ?? [];
      return (
        <Badge borderRadius={'radius200'}>
          <FilterBadgeLabel
            selectedValues={values}
            operatorLabel={'bac'}
            theme={'secondary'}
          />
        </Badge>
      );
    }
    case Enum.SingleOperatorMultiSelect: {
      const tagName = Array.from(parameterValue.keys())[0];
      const values = new Set(parameterValue.get(tagName) ?? []);
      const selectedOperator = render.operatorOptions.find(o => o.filterValue.key === tagName);
      const selectedValues = render.valueOptions
        .filter(v => values.has(v.filterValue.key))
        .map(selectedValue => selectedValue.filterValue.label);
      return (
        <Badge
          borderRadius={'radius200'}
          bgColor={themeToColor(selectedOperator?.colorTheme as Theme, '600')}
        >
          <FilterBadgeLabel
            selectedValues={selectedValues}
            operatorLabel={selectedOperator?.filterValue.label.toUpperCase()}
            theme={selectedOperator?.colorTheme as Theme}
          />
        </Badge>
      );
    }
    case Enum.RangeValueInput: {
      let rangeMinimum: number | undefined;
      let rangeMaximum: number | undefined;

      // Assign values from the given filter parameters
      if (typeof parameterValue !== 'undefined' && parameterValue.size > 0) {
        // Extract and validate the min and max values
        const rangeMinimumRaw = parameterValue.get(render.minOperatorTag) ?? undefined;
        const rangeMaximumRaw = parameterValue.get(render.maxOperatorTag) ?? undefined;
        if (rangeMinimumRaw !== undefined && rangeMinimumRaw.length > 0) {
          rangeMinimum = +parseFloat(rangeMinimumRaw[0])?.toFixed(render.settings.precision);
        }
        if (rangeMaximumRaw !== undefined && rangeMaximumRaw.length > 0) {
          rangeMaximum = +parseFloat(rangeMaximumRaw[0])?.toFixed(render.settings.precision);
        }
      }

      return (
        <RangeFilterBadge
          maxValue={rangeMaximum}
          minValue={rangeMinimum}
          unit={render.unitLabel}
        />
      );
    }
    case Enum.SplitMultiSelect: {
      const toValue = (paramVal: string) => {
        const filterDisplayOption = render.options.find(option => option.filterValue.key === paramVal);
        if (filterDisplayOption === undefined) {
          return paramVal;
        }

        return filterDisplayOption.filterValue.label;
      };
      const selectedOptionsPositive = (parameterValue.get(render.positiveOperator.filterValue.key) ?? []).map(toValue);
      const selectedOptionsNegative = (parameterValue.get(render.negativeOperator.filterValue.key) ?? []).map(toValue);
      return (
        <FilterBadgeSplitMultiSelect
          selectedPositiveValues={selectedOptionsPositive}
          selectedNegativeValues={selectedOptionsNegative}
          positiveOperatorLabel={render.positiveOperator.filterValue.label}
          negativeOperatorLabel={render.negativeOperator.filterValue.label}
          positiveTheme={(render.positiveOperator.colorTheme ?? 'secondary') as Theme}
          negativeTheme={(render.negativeOperator.colorTheme ?? 'negative') as Theme}
        />
      );
    }
  }
};

export default ConsoleRenderBadgeFormatter;
