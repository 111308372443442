import * as React from 'react';
import Popup from '~/neo-ui/packages/popup/Popup';
import Button from '~/neo-ui/packages/button/Button';
import ScorecardShareSection from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-share-button/packages/scorecard-share-section/ScorecardShareSection';
import useScorecardContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardContext';

export type ScorecardShareButtonProps = {
  organizationId: string;
  scorecardPreviewUrl: string;
};

const ScorecardShareButton = ({ organizationId, scorecardPreviewUrl }: ScorecardShareButtonProps) => {
  const { scorecard } = useScorecardContext();
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      backgroundColor={'light-000'}
      title={scorecard.isLatestSchedule ? 'Share your current Scorecard' : 'Share this Scorecard'}
      titleColor={'primary-400'}
      showCloseButton={true}
      content={
        <ScorecardShareSection
          organizationId={organizationId}
          scorecardPreviewUrl={scorecardPreviewUrl}
        />
      }
    >
      <Button
        iconRight={'Share'}
        onClick={() => setIsOpen(prev => !prev)}
      >
        Share with client…
      </Button>
    </Popup>
  );
};

export default ScorecardShareButton;
