import { useContext } from 'react';
import InitiativeAssessmentQuestionListContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-assessment-section/context/InitiativeAssessmentQuestionListContext';

const useInitiativeAssessmentQuestionListContext = () => {
  const initiativeAssessmentQuestionListContext = useContext(InitiativeAssessmentQuestionListContext);
  if (!initiativeAssessmentQuestionListContext) {
    throw new Error('Tried to use InitiativeAssessmentQuestionListContext outside of an InitiativeAssessmentQuestionListProvider');
  }

  return initiativeAssessmentQuestionListContext;
};

export default useInitiativeAssessmentQuestionListContext;
