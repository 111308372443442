import React from 'react';
import Box from '~/neo-ui/packages/box/Box';
import { css } from '@emotion/react';
import { useOnClickOutside } from '~/extensions/packages/hooks/useOnClickOutside';
import { Styleable } from '~/neo-ui/model/capacity';
import Icon from '~/neo-ui/packages/icon/Icon';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import { useSidebarWidthTracking } from '~/neo-ui/packages/popover/hooks/useSidebarWidthTracking';
import { useWindowDimensions } from '~/extensions/packages/hooks/useWindowDimensions';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Testable from '~/neo-ui/packages/testable/Testable';

export type PopoverAlign =
  | 'bottom left'
  | 'bottom right'
  | 'top left'
  | 'top right'
  | 'right top'
  | 'right bottom'
  | 'left top'
  | 'left bottom';

export const popoverButtonStyles = css`
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  border: 1px solid ${colorToCode('light-500')};
  height: 35.8px;
  line-height: 35.8px;
  background-color: white;
  overflow: hidden;
  box-shadow: none;

  &:hover {
    cursor: pointer;
    border: 0.0675rem solid ${colorToCode('secondary-300')};
    box-shadow: 0 0.0675rem 0.25rem -0.0675rem rgb(0 0 0 / 15%);
    color: ${colorToCode('dark-700')};
  }
`;

export type PopoverProps = {
  header: React.ReactNode | ((isOpen: boolean) => React.ReactNode);
  popoverAlign?: PopoverAlign;
  closesOnClickOutside?: boolean;

  disabled?: boolean;
  disabledTooltipContent?: string;
  children: React.ReactNode | ((close: () => void) => React.ReactNode);
} & Styleable;

const Popover: React.FunctionComponent<PopoverProps> = ({
  header,
  popoverAlign,
  closesOnClickOutside = true,

  disabled = false,
  disabledTooltipContent,
  className,
  children,
}) => {
  const componentRef = React.useRef(null);
  const buttonRef = React.useRef<HTMLDivElement>(null);

  const [isDisplayed, setDisplayed] = React.useState<boolean>(false);

  useOnClickOutside([componentRef], () => {
    if (closesOnClickOutside) {
      setDisplayed(false);
    }
  });

  const [firstPosition, secondPosition] = popoverAlign?.split(' ') ?? [undefined, undefined];

  let positionTop: number | undefined;
  let positionBottom: number | undefined;
  let positionLeft: number | undefined;
  let positionRight: number | undefined;

  const sideBarWidth = useSidebarWidthTracking();
  const windowDimensions = useWindowDimensions();

  if (popoverAlign !== undefined) {
    switch (firstPosition) {
      case 'top':
        positionBottom = typeof buttonRef.current?.clientHeight === 'undefined' ? undefined : buttonRef.current.clientHeight;
        break;
      case 'bottom':
        break;
      case 'left':
        positionRight = typeof buttonRef.current?.clientWidth === 'undefined' ? undefined : buttonRef.current.clientWidth;
        break;
      case 'right':
        positionLeft = typeof buttonRef.current?.clientWidth === 'undefined' ? undefined : buttonRef.current.clientWidth;
        break;
    }

    switch (secondPosition) {
      case 'top':
        positionTop = 0;
        break;
      case 'bottom':
        positionBottom = 0;
        break;
      case 'left':
        break;
      case 'right':
        positionRight = 0;
    }
  } else {
    const rect = buttonRef.current?.getBoundingClientRect();
    const midPoint = sideBarWidth + (windowDimensions.innerWindowWidth - sideBarWidth) / 2;
    if (rect?.left !== undefined && rect.left >= midPoint) {
      positionRight = 0;
    }
  }

  let headerComponent: React.ReactNode;

  if (typeof header === 'function') {
    headerComponent = header(isDisplayed);
  } else if (typeof header === 'string') {
    headerComponent = (
      <div
        css={css`
          padding: 0 10px;
          display: flex;
          align-items: center;
          gap: 5px;
        `}
      >
        {header}
        <Icon
          icon={isDisplayed ? 'ArrowUp' : 'ArrowDown'}
          color={'dark-900'}
        />
      </div>
    );
  } else {
    headerComponent = header;
  }

  const tooltipWrapper = (component: JSX.Element, tooltipContent: string | undefined) => {
    if (!tooltipContent || tooltipContent === '') {
      return component;
    }

    return <Tooltip content={tooltipContent}>{component}</Tooltip>;
  };

  const popoverButtonAndDropDown = (
    <div
      ref={componentRef}
      css={css`
        position: relative;
        cursor: default;
        user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
      `}
    >
      <Testable testId="popover-clickable-element">
        <div
          ref={buttonRef}
          className={'popover-button'}
          css={[popoverButtonStyles]}
          onClick={disabled ? undefined : () => setDisplayed(!isDisplayed)}
        >
          {typeof headerComponent === 'string' ? (
            <div
              css={css`
                padding: 0 10px;
              `}
            >
              {headerComponent}
            </div>
          ) : (
            headerComponent
          )}
          {disabled && (
            <div
              css={css`
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(248, 248, 252, 0.42);
              `}
            />
          )}
        </div>
      </Testable>
      {isDisplayed && (
        <div
          css={css`
            position: absolute;
            z-index: 1;
            bottom: ${typeof positionBottom === 'undefined' ? undefined : `${positionBottom}px`};
            top: ${typeof positionTop === 'undefined' ? undefined : `${positionTop}px`};
            right: ${typeof positionRight === 'undefined' ? undefined : `${positionRight}px`};
            left: ${typeof positionLeft === 'undefined' ? undefined : `${positionLeft}px`};
            margin-top: ${firstPosition === 'bottom' || popoverAlign === undefined ? '5px' : undefined};
            margin-bottom: ${firstPosition === 'top' ? '5px' : undefined};
            margin-left: ${firstPosition === 'right' ? '5px' : undefined};
            margin-right: ${firstPosition === 'left' ? '5px' : undefined};
          `}
        >
          <Box
            className={className}
            padding={'padding0'}
            boxShadow={'shadow300'}
            borderRadius={'radius400'}
          >
            {typeof children === 'function' ? children(() => setDisplayed(false)) : children}
          </Box>
        </div>
      )}
    </div>
  );

  return disabled ? tooltipWrapper(popoverButtonAndDropDown, disabledTooltipContent) : popoverButtonAndDropDown;
};

export default Popover;
