import { EngagementActionAssignedUserDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Packages/EngagementActionUserAssignment/Dto/Model.gen';
import { ActiveUser } from '@SsoClient/BeastClient/Beast/Identity/Model/User.gen';
import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';

import formatUserNameDisplay from '~/wm/packages/account/packages/user/packages/active-users-search/formatUserNameDisplay';
import { descriptionFieldKeyId } from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/TicketCreateForm';
import { InputTypeNested } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Credential.gen';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';

const formatTicketDescriptionEngagementAction = (
  actionDescription: string,
  actionAssignedUsers: EngagementActionAssignedUserDto[] | ActiveUser[],
  actionDueAt: Date | undefined,
  organizationName: string,
  fieldOptions: IntegrationFieldOptionDto[],
): string => {
  const newDueDateText =
    typeof actionDueAt !== 'undefined'
      ? ` ${formatDate(actionDueAt, {
          format: 'MM/dd/yyyy',
          timezone: TimezoneFormat.Local,
        })}`
      : '';
  const newUsersText = actionAssignedUsers.length > 0 ? ` ${actionAssignedUsers.map(user => formatUserNameDisplay(user)).join(', ')}` : '';

  const ticketDescriptionFieldOption = fieldOptions.find(option => option.key === descriptionFieldKeyId);
  const ticketDescriptionFieldOptionLength = ticketDescriptionFieldOption
    ? (ticketDescriptionFieldOption.inputType as InputTypeNested.Text).validation.maxLength
    : undefined;

  const ticketDescription = `${actionDescription}\n\nLifecycle Manager Action Item: ${organizationName}${newDueDateText}${newUsersText}`;

  return typeof ticketDescriptionFieldOptionLength !== 'undefined' && ticketDescription.length > ticketDescriptionFieldOptionLength
    ? `${ticketDescription.slice(0, ticketDescriptionFieldOptionLength - 1)}…`
    : ticketDescription;
};

export default formatTicketDescriptionEngagementAction;
