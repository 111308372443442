import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import useBadgeMarketingDefinition, {
  BadgeMarketingType,
} from '~/neo-ui/packages/badge/packages/badge-marketing/hooks/useBadgeMarketingDefinition';
import useBadgeMarketingSizeDetails, {
  BadgeMarketingSize,
} from '~/neo-ui/packages/badge/packages/badge-marketing/hooks/useBadgeMarketingSizeDetails';
import Badge from '~/neo-ui/packages/badge/Badge';

export type BadgeMarketingProps = {
  type: BadgeMarketingType;
  size?: BadgeMarketingSize;
  shouldInvertColors?: boolean;
} & Styleable;

const BadgeMarketing = ({ type, size = 'sm', shouldInvertColors = false, className }: BadgeMarketingProps) => {
  const { label, icon, background, color, invertedColor } = useBadgeMarketingDefinition(type);
  const { heightRem, paddingRem, iconLabelGapRem, borderRadiusRem } = useBadgeMarketingSizeDetails(size);

  return (
    <Badge
      height={`${heightRem}rem`}
      bgColor={!shouldInvertColors ? undefined : 'light-000'}
      css={css`
        display: inline-flex;
        align-items: center;
        gap: ${iconLabelGapRem}rem;
        align-self: center;
        padding: ${paddingRem}rem;
        border-radius: ${borderRadiusRem}rem;
        min-width: max-content;

        ${!shouldInvertColors && background}
      `}
      className={className}
    >
      <Icon
        icon={icon}
        color={!shouldInvertColors ? color : invertedColor}
        sizePx={12}
      />
      <Label
        size={'sm'}
        color={!shouldInvertColors ? color : invertedColor}
        bold={true}
        css={css`
          text-transform: uppercase;
          letter-spacing: 0.0875rem;
        `}
      >
        {label}
      </Label>
    </Badge>
  );
};
export default BadgeMarketing;
