import * as React from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import CardButton from '~/legacy-ui/packages/card/packages/card-button/CardButton';
import { css } from '@emotion/react';
import useScorecardCategoryWithItemsDelete from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/hooks/useScorecardCategoryWithItemsDelete';
import useScorecardCategoryWithoutItemsDelete from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/hooks/useScorecardCategoryWithoutItemsDelete';

export type ScorecardCategoryDeleteModalProps = {
  scorecardId: string;
  scorecardCategoryId: string;
  isUncategorizedCategory: boolean;
  isOpen: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
};

const ScorecardCategoryDeleteModal = ({
  scorecardId,
  scorecardCategoryId,
  isUncategorizedCategory,
  isOpen,
  onDismiss,
  onConfirm,
}: ScorecardCategoryDeleteModalProps) => {
  const { deleteScorecardCategoryWithItems, isScorecardCategoryWithItemsDeleting } = useScorecardCategoryWithItemsDelete(scorecardId, {
    onSuccess: onConfirm,
  });
  const { deleteScorecardCategoryWithoutItems, isScorecardCategoryWithoutItemsDeleting } = useScorecardCategoryWithoutItemsDelete(
    scorecardId,
    {
      onSuccess: onConfirm,
    },
  );

  const isDeleting = isScorecardCategoryWithItemsDeleting || isScorecardCategoryWithoutItemsDeleting;

  return (
    <ModalConfirmation
      header={{
        title: 'Delete this Category?',
        icon: 'Trash',
      }}
      isOpen={isOpen}
      theme={'danger'}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.625rem;
        `}
      >
        <Label>
          {isUncategorizedCategory
            ? 'Deleting the “General Items” Category will also delete all its Items.'
            : 'If you delete this Category and keep its Items, they’ll be moved to a “General Items” Category.'}
        </Label>

        <CardButton
          title={'Delete all'}
          icon={'Trash'}
          theme={'danger'}
          disabled={isDeleting}
          onClick={() => deleteScorecardCategoryWithItems({ scorecardCategoryId })}
        />
        {!isUncategorizedCategory && (
          <CardButton
            title={'Delete Category and keep Items'}
            icon={'ScorecardCategoryDelete'}
            disabled={isDeleting}
            onClick={() => deleteScorecardCategoryWithoutItems({ scorecardCategoryId })}
          />
        )}
        <CardButton
          title={'Cancel'}
          icon={'Bad'}
          disabled={isDeleting}
          onClick={onDismiss}
        />
      </div>
    </ModalConfirmation>
  );
};

export default ScorecardCategoryDeleteModal;
