import { ComponentPropsWithoutRef, PropsWithChildren, ReactNode, createContext } from 'react';
import { useContextOrThrow } from '~/extensions/packages/hooks/useContextOrThrow';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import ChevronDownIcon from '../icons/ChevronDownIcon';

interface AccordionState {
  items: Record<string, boolean>;
  onToggle: (itemId: string, open?: boolean) => Promise<void> | void;
}

const AccordionContext = createContext<AccordionState>({
  items: {},
  onToggle: () => {
    throw new Error('Accordion context not found');
  },
});

export const useAccordion = () => useContextOrThrow(AccordionContext);

export interface AccordionProps extends AccordionState, ComponentPropsWithoutRef<'div'> {}

const Accordion = (props: PropsWithChildren<AccordionProps>) => {
  const { children, items, onToggle, ...rest } = props;

  return (
    <AccordionContext.Provider value={{ items, onToggle }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '600px',
          gap: '17px',
        }}
        {...rest}
      >
        {children}
      </div>
    </AccordionContext.Provider>
  );
};

export interface AccordionItemProps extends Omit<ComponentPropsWithoutRef<'div'>, 'children'> {
  id: string;
  header: ReactNode;
  children: ReactNode | ((context: AccordionState) => ReactNode);
  className?: string;
  disableTrigger?: boolean;
}

const Item = (props: AccordionItemProps) => {
  const { id, children, className, header, disableTrigger = false, ...rest } = props;
  const context = useAccordion();
  const isOpen = !!context.items[id];

  return (
    <div
      css={[
        {
          borderRadius: '8px',
          border: `1px solid ${colorToCode('light-400')}`,
          backgroundColor: colorToCode('light-000'),
          width: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
        },
      ]}
      id={id}
      className={className}
      {...rest}
    >
      <div
        css={{
          margin: 0,
          paddingTop: '48px',
          paddingInline: '64px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div css={{ flex: '1', fontSize: '1.2rem' }}>{header}</div>
        {!disableTrigger && (
          <button
            onClick={() => context.onToggle(id)}
            css={{
              all: 'unset',
              borderRadius: '50%',
              display: 'flex',
              placeItems: 'center',
              ['&:hover']: {
                cursor: 'pointer',
              },
            }}
          >
            <ChevronDownIcon
              css={[
                {
                  width: '32px',
                  height: '32px',
                  transition: 'transform 200ms linear',
                },
                isOpen && { transform: 'rotate(180deg)' },
              ]}
            />
          </button>
        )}
      </div>
      <div
        css={[
          {
            padding: '32px 64px 48px 64px',
            display: 'grid',
            transition: 'grid-template-rows 200ms ease-in-out, padding-top 200ms ease-in-out',
          },
          isOpen && { gridTemplateRows: '1fr' },
          !isOpen && {
            gridTemplateRows: '0',
            paddingTop: 0,
          },
        ]}
      >
        <div
          css={{
            minHeight: 0,
            overflow: 'hidden',
          }}
        >
          {typeof children === 'function' ? children(context) : children}
        </div>
      </div>
    </div>
  );
};

Accordion.Item = Item;

export default Accordion;
