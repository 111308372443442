import * as React from 'react';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import ScorecardItemRemoveModal from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/packages/scorecard-item-remove-modal/ScorecardItemRemoveModal';

export type ScorecardItemRemoveButtonProps = {
  scorecardId: string;
  scorecardCategoryId?: string;
  scorecardItemId: string;
};

const ScorecardItemRemoveButton = ({ scorecardId, scorecardCategoryId, scorecardItemId }: ScorecardItemRemoveButtonProps) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <React.Fragment>
      <ButtonModifier
        icon={'Remove'}
        onClick={() => setModalOpen(true)}
      />
      <ScorecardItemRemoveModal
        scorecardId={scorecardId}
        scorecardCategoryId={scorecardCategoryId}
        scorecardItemId={scorecardItemId}
        isOpen={isModalOpen}
        onDismiss={() => setModalOpen(false)}
      />
    </React.Fragment>
  );
};

export default ScorecardItemRemoveButton;
