import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Size from '~/neo-ui/model/Size';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { Placement } from '@popperjs/core';

export type ActionButtonProps = {
  /**
   * Icon inside the button
   */
  icon: IconType;

  /**
   * Size of the button
   */
  size?: Exclude<Size, 'lg' | 'xl'>;
  activeBackgroundColor: Color;
  inactiveBackgroundColor?: Color;
  inactiveIconColor?: Color;
  activeIconColor?: Color;
  inactiveBorderColor?: Color;
  activeBorderColor?: Color;

  /**
   * Where the button click leads to
   * Defaults to nothing
   */
  actionUrl?: string;

  /**
   * Opens links in a new tab on click
   */
  opensInNewTab?: boolean;

  /**
   * Adds a number to the button.
   * Useful e.g., if there are new items to address
   */
  badgeNumber?: number;

  /**
   * Optionally show a tooltip on hover
   */
  tooltipContent?: string | React.ReactNode;

  /**
   * Forces the active state
   */
  isActive?: boolean;

  /**
   * Forces a disabled state. Takes precedence over IsActive.
   */
  isDisabled?: boolean;

  onClick?: () => void;

  tooltipSetting?: Placement;
} & Styleable;

/**
 * @deprecated Use Button instead, which implements ButtonInternal
 *
 * Button with action explanations in the tooltip
 *
 * This component needs some refactoring! Might split into a couple components.
 */
const ActionButton: React.FunctionComponent<ActionButtonProps> = ({
  className,
  icon,
  size = 'md',
  activeBackgroundColor,
  inactiveBackgroundColor,
  // If there's no icon colour specified, it should match the active background color
  inactiveIconColor,
  activeIconColor,
  inactiveBorderColor,
  activeBorderColor,
  actionUrl,
  opensInNewTab = false,
  badgeNumber,
  tooltipContent,
  isActive: isActiveOverride,
  isDisabled = false,
  onClick,
  tooltipSetting = 'bottom',
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const isActive = isActiveOverride || isHovered;
  // eslint-disable-next-line no-param-reassign
  inactiveBackgroundColor =
    typeof inactiveBackgroundColor !== 'undefined' ? inactiveBackgroundColor : isDisabled ? 'light-100' : 'light-000';
  // eslint-disable-next-line no-param-reassign
  activeIconColor = typeof activeIconColor !== 'undefined' ? activeIconColor : inactiveBackgroundColor;
  // eslint-disable-next-line no-param-reassign
  inactiveBorderColor = typeof inactiveBorderColor !== 'undefined' ? inactiveBorderColor : isDisabled ? 'light-500' : activeBackgroundColor;
  // eslint-disable-next-line no-param-reassign
  activeBorderColor = typeof activeBorderColor !== 'undefined' ? activeBorderColor : activeBackgroundColor;
  // eslint-disable-next-line no-param-reassign
  inactiveIconColor = typeof inactiveIconColor !== 'undefined' ? inactiveIconColor : isDisabled ? 'light-800' : activeBackgroundColor;

  const buttonSizeDetails = buttonSizeToButtonDisplayDetails(size);

  return (
    <Tooltip
      isActive={typeof tooltipContent !== 'undefined' ? isActive : false}
      backgroundColor={isDisabled ? 'dark-900' : activeBackgroundColor}
      content={
        typeof tooltipContent === 'string' ? (
          <div>
            <p
              css={css`
                margin-bottom: 0;
                ${isDisabled && `color: ${colorToCode('light-050')}`}
              `}
            >
              {tooltipContent}
            </p>
          </div>
        ) : (
          tooltipContent
        )
      }
      placement={tooltipSetting}
    >
      <a
        className={className}
        href={actionUrl}
        {...(opensInNewTab && { target: '_blank' })}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onClick}
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: ${buttonSizeDetails.size};
          height: ${buttonSizeDetails.size};
          ${!isActive || isDisabled
            ? `
          background-color: ${colorToCode(inactiveBackgroundColor)};
          border: ${buttonSizeDetails.borderSize} solid
            ${colorToCode(inactiveBorderColor)};`
            : `
          background-color: ${colorToCode(activeBackgroundColor)};
          border: ${buttonSizeDetails.borderSize} solid
              ${colorToCode(activeBorderColor)};
          `}
          cursor: ${isDisabled ? 'default' : 'pointer'};
          border-radius: ${buttonSizeDetails.borderRadius};
          transition: background-color 85ms ease-in;
          box-shadow: 0 0 12px -5px ${colorToCode(activeBackgroundColor)};

          position: relative;
          ${typeof badgeNumber !== 'undefined'
            ? `&::after {
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${colorToCode('dark-900')};
            position: absolute;
            content: '${badgeNumber}';
            top: -40%;
            right: -30%;
            background-color: ${colorToCode('secondary-050')};
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
              0 2px 2px 0 rgba(38, 45, 115, 0.2);
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
          }`
            : ''}
        `}
      >
        {isActive && !isDisabled ? (
          <Icon
            icon={opensInNewTab ? 'ArrowOut' : icon}
            color={activeIconColor}
            sizePx={buttonSizeDetails.iconSizePx}
          />
        ) : (
          <Icon
            icon={icon}
            color={inactiveIconColor}
            sizePx={buttonSizeDetails.iconSizePx}
          />
        )}
      </a>
    </Tooltip>
  );
};

type ButtonDisplayDetails = {
  size: string;
  borderSize: string;
  iconSizePx: number | undefined;
  borderRadius: string;
};

const buttonSizeToButtonDisplayDetails = (buttonSize: Exclude<Size, 'lg' | 'xl'>): ButtonDisplayDetails => {
  switch (buttonSize) {
    case 'xs':
      return {
        size: '1.125rem',
        borderSize: '0.125rem',
        iconSizePx: 8,
        borderRadius: '0.3125rem',
      };
    case 'sm':
      return {
        size: '1.875rem',
        borderSize: '0.0625rem',
        iconSizePx: undefined,
        borderRadius: '50%',
      };
    case 'md':
      return {
        size: '2.25rem',
        borderSize: '0.1875rem',
        iconSizePx: undefined,
        borderRadius: '50%',
      };
  }
};

export default ActionButton;
