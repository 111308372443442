// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


/**
 * For now there are two types of warranty:
 * Workstation Assurance - for workstations
 * and Infrastructure Protection for the rest of warranty available devices
 */
export enum Enum
{
  InfrastructureProtection = "InfrastructureProtection",
  WorkstationAssurance = "WorkstationAssurance",
}


