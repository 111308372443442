import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import FormErrorMessage from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';
import Slider from '~/neo-ui/packages/slider/Slider';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';

export type FormSliderInputProps<T> = {
  fieldKey: FieldKeyExpression<T>;
  values: string[];
  onChange: (value: string) => void;
} & Styleable;

const FormSliderInput = <T,>({ className, fieldKey, values, onChange }: FormSliderInputProps<T>) => {
  const { setFormInput, getFormInput } = useFormContext<T>();

  const value = getFormInput<string>(fieldKey).value;

  return (
    <div
      className={className}
      css={css`
        max-width: 500px;
      `}
    >
      <Slider
        minValue={0}
        maxValue={values.length === 0 ? 0 : values.length - 1}
        value={values.indexOf(value)}
        onChange={value => {
          setFormInput<string>(fieldKey, values[value]);
          onChange(values[value]);
        }}
      />
      <FormErrorMessage fieldKey={fieldKey} />
    </div>
  );
};

export default FormSliderInput;
