import { useState } from 'react';
import Button from '~/neo-ui/packages/button/Button';
import { Styleable } from '~/neo-ui/model/capacity';
import tooltipStyles from '~/neo-ui/packages/tooltip/styles/tooltipStyles';
import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';
import EngagementActionCreateTicketWindow from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-ticket/packages/engagement-action-create-ticket-window/EngagementActionCreateTicketWindow';
import TicketErrorModalOrSuccess from '~/wm/packages/integration/packages/ticket/packages/ticket-error-modal-or-success/TicketErrorModalOrSuccess';

export type EngagementActionCreateTicketCreateButtonProps = {
  ticketFieldOptions: IntegrationFieldOptionDto[];
  isOrganizationRegisteredForTickets: boolean;
  hasTicketIntegration: boolean;
  disabled?: boolean;
  onClick: () => void;
  onCreateTicket?: () => void;
  onCancelTicket: () => void;
} & Styleable;

const EngagementActionCreateTicketCreateButton = ({
  ticketFieldOptions,
  isOrganizationRegisteredForTickets,
  hasTicketIntegration,
  disabled,
  className,
  onClick,
  onCreateTicket,
  onCancelTicket,
}: EngagementActionCreateTicketCreateButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  return (
    <div>
      <Button
        size={'xs'}
        className={className}
        theme={isSaved ? 'primary' : undefined}
        iconLeft={'IntegrationTicket'}
        preventOnClickPropagation={true}
        disabled={disabled}
        css={tooltipStyles('Create ticket')}
        onClick={() => {
          setIsOpen(true);
          onClick();
        }}
      />
      {isOpen && (
        <TicketErrorModalOrSuccess
          hasTicketIntegration={hasTicketIntegration}
          isOrganizationRegisteredForTickets={isOrganizationRegisteredForTickets}
          ticketFieldOptions={ticketFieldOptions}
          onDismiss={() => setIsOpen(false)}
          successComponent={
            <EngagementActionCreateTicketWindow
              isOpen={isOpen}
              fieldOptions={ticketFieldOptions}
              isCreatingTicket={false}
              onCreateTicket={() => {
                setIsOpen(false);
                setIsSaved(true);
                if (typeof onCreateTicket !== 'undefined') {
                  onCreateTicket();
                }
              }}
              onDismiss={() => {
                setIsOpen(false);
                onCancelTicket();
              }}
            />
          }
        />
      )}
    </div>
  );
};

export default EngagementActionCreateTicketCreateButton;
