import { autoEmailReportCreate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useAutoEmailReportListMutation, {
  UseAutoEmailReportListMutationOptions,
} from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportListMutation';
import { AutoEmailReportCreatePayloadDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';

type AutoEmailReportCreatePayload = {
  organizationId: string;
  autoEmailReportCreatePayload: AutoEmailReportCreatePayloadDto;
};

export type UseAutoEmailReportCreate = {
  createAutoEmailReport: (autoEmailReportCreatePayload: AutoEmailReportCreatePayload, isRequestActive?: () => boolean) => void;
  isCreatingAutoEmailReport: boolean;
};

const useAutoEmailReportCreate = (options?: UseAutoEmailReportListMutationOptions): UseAutoEmailReportCreate => {
  const [createAutoEmailReport, isCreatingAutoEmailReport] = useAutoEmailReportListMutation(
    (autoEmailReportCreatePayload: AutoEmailReportCreatePayload) =>
      autoEmailReportCreate({
        organizationId: autoEmailReportCreatePayload.organizationId,
        createPayload: autoEmailReportCreatePayload.autoEmailReportCreatePayload,
      }),
    options,
  );

  return {
    createAutoEmailReport,
    isCreatingAutoEmailReport,
  };
};

export default useAutoEmailReportCreate;
