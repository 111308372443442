import * as React from 'react';
import useDownloadCoveredAssetsSpreadsheet from '~/wm/packages/warranty/packages/coverage/packages/coverage-list/hooks/useDownloadCoveredAssetsSpreadsheet';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Button from '~/neo-ui/packages/button/Button';

export type CoverageListDownloadButtonProps = {
  brandName: string;
};
const CoverageListDownloadButton: React.FunctionComponent<CoverageListDownloadButtonProps> = ({ brandName }) => {
  const { downloadCoveredAssetsSpreadsheet } = useDownloadCoveredAssetsSpreadsheet();

  return (
    <Tooltip
      backgroundColor={'primary-400'}
      content={`Download spreadsheet of all assets covered by a ${brandName} Warranty`}
      placement={'bottom'}
    >
      <Button
        iconLeft={'Download'}
        theme={'primary'}
        onClick={() => {
          downloadCoveredAssetsSpreadsheet();
        }}
      />
    </Tooltip>
  );
};

export default CoverageListDownloadButton;
