import { useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { TermDto } from '@SubscriptionClient/BeastClient/Beast/Msp/Dto/Subscription/Pricing/Get/Model.gen';
import { subscriptionTermGet } from '@SubscriptionClient/SubscriptionClientMsp.gen';

const useTermDtoGet = () => {
  const [isLoading, setLoading] = useState(true);
  const [termDto, setTermDto] = useState<TermDto | undefined>();

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => subscriptionTermGet({}));
      setLoading(false);

      if (!response) {
        return;
      }

      setTermDto(response.termDto);
    })();
  }, [callApi]);

  return { isLoading, termDto };
};

export default useTermDtoGet;
