import * as React from 'react';
import { BillingInfo as BillingInfoType } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import ServiceRequest from '~/wm/packages/warranty/packages/service-request/model/ServiceRequest';
import WizardPage from '~/neo-ui/packages/wizard/packages/wizard-page/WizardPage';
import { Response as CoverageTransferControllerResponse } from '@WarrantyClient/Warranty/Coverage/Packages/Transfer/Controller/CoverageTransferControllerNested.gen';
import getWarrantyTransferDisposalStep from '~/wm/packages/warranty/packages/coverage/packages/coverage-transfer/wizard/step/getWarrantyTransferDisposalStep';
import WarrantyTransferFormData from '~/wm/packages/warranty/packages/coverage/packages/coverage-transfer/types/WarrantyTransferFormData';
import getWarrantyTransferAssetStep from '~/wm/packages/warranty/packages/coverage/packages/coverage-transfer/wizard/step/getWarrantyTransferAssetStep';
import useCoverageTransfer from '~/wm/packages/warranty/packages/coverage/packages/coverage-transfer/hooks/useCoverageTransfer';
import CoverageTransferSuccess from '~/wm/packages/warranty/packages/coverage/packages/coverage-transfer/wizard/success/CoverageTransferSuccess';
import { DisposalOrderSummaryDtoNested } from '@WarrantyClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Dto/Model.gen';
import { validatePhoneNumber } from '~/wm/packages/disposal/wizard/section/AccountPhoneNumber';

export type CoverageTransferWizardProps = {
  serviceRequest: ServiceRequest;
  billingInfo: BillingInfoType;
  disposalOrderListUrl: string;
  hasDisposalLocationAccess: boolean;
};

const CoverageTransferWizard = ({
  serviceRequest,
  billingInfo,
  disposalOrderListUrl,
  hasDisposalLocationAccess,
}: CoverageTransferWizardProps) => {
  const { coverageTransfer: warrantyTransfer } = useCoverageTransfer();

  const transfer = React.useCallback(
    (data: WarrantyTransferFormData) =>
      warrantyTransfer({
        serviceRequestId: data.serviceRequestId,
        assetReplacementInfo: {
          name: data.replacementAsset.name,
          serialNumber: data.replacementAsset.serialNumber,
          modelNumber: data.replacementAsset.modelNumber,
          manufacturerId: data.replacementAsset.manufacturer,
        },
        addressFrom: hasDisposalLocationAccess
          ? {
              address: {
                city: data.shipmentReturnAddress.billingAddress?.address.city ?? '',
                countryCode: data.shipmentReturnAddress.billingAddress?.address.countryCode ?? '',
                countryFallback: data.shipmentReturnAddress.billingAddress?.address.countryFallback ?? '',
                line1: data.shipmentReturnAddress.billingAddress?.address.line1 ?? '',
                line2: data.shipmentReturnAddress.billingAddress?.address.line2 ?? '',
                zip: data.shipmentReturnAddress.billingAddress?.address.zip ?? '',
                stateCode: data.shipmentReturnAddress.billingAddress?.address.stateCode ?? '',
                stateFallback: data.shipmentReturnAddress.billingAddress?.address.stateFallback ?? '',
              },
              name: data.shipmentReturnAddress.billingAddress?.name ?? '',
              phone: data.phoneNumber ?? '',
            }
          : undefined,
      }),
    [hasDisposalLocationAccess, warrantyTransfer],
  );

  const toFormData = React.useCallback(
    (): WarrantyTransferFormData => ({
      serviceRequestId: serviceRequest.serviceRequestId,
      replacementAsset: {
        serialNumber: '',
        modelNumber: '',
        name: '',
        manufacturer: '',
      },
      shipmentReturnAddress:
        typeof billingInfo.billingAddress !== 'undefined' && billingInfo.billingAddress.address.countryCode !== 'US'
          ? { ...billingInfo, billingAddress: undefined }
          : billingInfo,
      certification: {
        locationCertified: false,
        addressIsValid: false,
      },
      phoneNumber: billingInfo.billingAddress?.phone ?? '',
      isPhoneNumberValid: typeof validatePhoneNumber(billingInfo.billingAddress?.phone) === 'undefined',
    }),
    [billingInfo, serviceRequest.serviceRequestId],
  );

  const defaultFormData = React.useMemo(() => toFormData(), [toFormData]);

  // If the account does not have disposal location access, do not show the disposal step
  const steps = hasDisposalLocationAccess
    ? [getWarrantyTransferAssetStep(serviceRequest), getWarrantyTransferDisposalStep(serviceRequest, billingInfo)]
    : [getWarrantyTransferAssetStep(serviceRequest)];

  return (
    <WizardPage
      defaultFormData={defaultFormData}
      steps={steps}
      submitLabel={hasDisposalLocationAccess ? 'Complete disposal' : 'Complete transfer'}
      submitIcon={'Done'}
      onSubmit={async formData => transfer(formData)}
      SuccessComponent={({ postSubmitPayload }) => (
        <CoverageTransferSuccess
          // Type of the response is lost through generics. Here you go Response, have your type back!
          disposalOrderSummary={
            (postSubmitPayload as CoverageTransferControllerResponse)
              .disposalOrderSummary as DisposalOrderSummaryDtoNested.WorkstationAssurance
          }
          disposalOrderListUrl={disposalOrderListUrl}
          hasDisposalLocationAccess={hasDisposalLocationAccess}
        />
      )}
    />
  );
};

export default CoverageTransferWizard;
