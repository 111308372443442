import ServiceRequest from '~/wm/packages/warranty/packages/service-request/model/ServiceRequest';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import * as React from 'react';
import { formatDateFriendly } from '~/extensions/packages/date/formatDate';
import ServiceRequestInfoBlock from '~/wm/packages/warranty/packages/service-request/packages/service-request-info-block/ServiceRequestInfoBlock';
import Testable from '~/neo-ui/packages/testable/Testable';

export type ServiceRequestInfoSectionProps = {
  serviceRequest: ServiceRequest;
};

const ServiceRequestInfoSection = ({ serviceRequest }: ServiceRequestInfoSectionProps) => (
  <Box
    padding={'padding200'}
    boxShadow={'shadow0'}
    borderRadius={'radius400'}
  >
    <Testable testId="ServiceRequestInfoSection">
      <LayoutSection
        title={'Request info'}
        titleSize={4}
        titleWeight={'bold'}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.625rem;
          `}
        >
          <Header
            size={3}
            weight={'medium'}
            color={'primary-400'}
          >
            {serviceRequest.asset.assetName}
          </Header>
          <Label size={'lg'}>{serviceRequest.asset.modelNumber}</Label>
          <Testable testId={'service-request-serial-number'}>
            <Label
              size={'sm'}
              bold={true}
              css={css`
                display: flex;
                align-items: center;
                gap: 0.3125rem;
                text-transform: uppercase;
              `}
            >
              <Icon
                icon={'Serial'}
                sizePx={12}
              />
              {serviceRequest.asset.serialNumber}
            </Label>
          </Testable>
        </div>
        <hr
          css={css`
            width: 100%;
            margin: 0.3125rem 0;
            border-top: 0.0625rem solid ${colorToCode('dark-900-12')};
          `}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.625rem;
          `}
        >
          <ServiceRequestInfoBlock
            title={serviceRequest.state.label}
            titleColor={serviceRequest.state.color}
            description={serviceRequest.coveragePlanLabel}
            icon={serviceRequest.state.icon}
            iconColor={serviceRequest.state.color}
          />
          <div
            css={css`
              margin-left: 2.1875rem;
            `}
          >
            <Label
              bold={true}
              css={css`
                margin-bottom: 0.3125rem;
              `}
            >
              {typeof serviceRequest.problemType !== 'undefined' ? serviceRequest.problemType.display : serviceRequest.serviceType?.label}
            </Label>
            <Label muted={true}>{serviceRequest.note}</Label>
          </div>
        </div>
        <hr
          css={css`
            width: 100%;
            margin: 0.3125rem 0;
            border-top: 0.0625rem solid ${colorToCode('dark-900-12')};
          `}
        />
        <ServiceRequestInfoBlock
          title={`Assigned to ${serviceRequest.contactName}`}
          description={serviceRequest.contactEmail}
          icon={'People'}
        />
        <ServiceRequestInfoBlock
          title={`Opened ${formatDateFriendly(serviceRequest.submittedAt)}`}
          description={`By ${serviceRequest.ownedByName ? serviceRequest.ownedByName : ''}`}
          icon={'Activity'}
        />
      </LayoutSection>
    </Testable>
  </Box>
);

export default ServiceRequestInfoSection;
