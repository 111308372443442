import * as React from 'react';
import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { getIntegrationListColumns } from '~/wm/packages/documentation/packages/supported-integrations/packages/IntegrationListColumns';
import TableClientSide from '~/neo-ui/packages/table/packages/table-client-side/TableClientSide';
import useSupportedIntegrationsList from '~/wm/packages/documentation/packages/supported-integrations/hooks/useSupportedIntegrationsList';
import DataTypeFilters from '~/wm/packages/documentation/packages/supported-integrations/packages/DataTypeFilters';
import { useEffect, useMemo, useState } from 'react';
import MultiSelect, { MultiSelectOption } from '~/neo-ui/packages/select/packages/multi-select/MultiSelect';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';
import {
  IntegrationEnhanced,
  toEnhancedIntegration,
} from '~/wm/packages/documentation/packages/supported-integrations/packages/IntegrationDocumentationUtil';

type SupportedIntegrationsListPageProps = {
  supportedIntegrationsDetailsUrlTemplate: string;
};

const SupportedIntegrationsListPage = ({ supportedIntegrationsDetailsUrlTemplate }: SupportedIntegrationsListPageProps) => {
  const { integrations } = useSupportedIntegrationsList();

  const [filters, setFilters] = useState<{
    [key: string]: { isSupportedSelected: boolean; selectedFields: string[] };
  }>();
  const [typeFields, setTypeFields] = useState<{ [key: string]: string[] }>();
  const [twoWaySyncSupport, setTwoWaySyncSupport] = useState<MultiSelectOption[]>([]);
  const [supportsSoftware, setSupportsSoftware] = useState(false);
  const [allowsMultiple, setAllowsMultiple] = useState(false);

  const filteredIntegrations = useMemo<IntegrationEnhanced[]>(() => {
    if (integrations === undefined || filters === undefined) {
      return [];
    }
    const activeFilters = Object.keys(filters).filter(type => filters[type].isSupportedSelected);
    const activeTwoWaySyncSupport = new Set(twoWaySyncSupport.map(option => option.value));

    return integrations
      .filter(integration => {
        if (allowsMultiple && !integration.supportsMultipleSetups) {
          return false;
        }

        if (supportsSoftware && !integration.features.hasOwnProperty('software')) {
          return false;
        }

        if (activeTwoWaySyncSupport.has('warrantyExpiryDate') && !integration.supportsWarrantyExpiryDateTwoWaySync) {
          return false;
        }

        if (activeTwoWaySyncSupport.has('purchaseDate') && !integration.supportsPurchaseDateTwoWaySync) {
          return false;
        }

        for (const type of activeFilters) {
          if (!integration.types[type].isSupported) {
            return false;
          }
          for (const field of filters[type].selectedFields) {
            if (!integration.types[type].fields.includes(field)) {
              return false;
            }
          }
        }
        return true;
      })
      .map(toEnhancedIntegration)
      .sort((a, b) => a.integration.name.localeCompare(b.integration.name));
  }, [integrations, filters, twoWaySyncSupport, allowsMultiple, supportsSoftware]);

  useEffect(() => {
    if (integrations === undefined) {
      return;
    }
    const typesWithFields = {} as { [key: string]: string[] };
    for (const integration of integrations) {
      for (const type of Object.keys(integration.types)) {
        if (!typesWithFields[type]) {
          typesWithFields[type] = integration.types[type].fields;
        } else {
          typesWithFields[type] = Array.from(new Set([...typesWithFields[type], ...integration.types[type].fields]));
        }
      }
    }
    setTypeFields(typesWithFields);
    setFilters(
      Object.fromEntries(
        Object.keys(typesWithFields).map(type => [
          type,
          {
            isSupportedSelected: false,
            selectedFields: [],
          },
        ]),
      ),
    );
  }, [integrations]);

  if (typeof integrations === 'undefined' || typeof typeFields === 'undefined') {
    return <div />;
  }

  const columns = getIntegrationListColumns(Object.keys(typeFields), supportedIntegrationsDetailsUrlTemplate);

  return (
    <div>
      <Box
        padding={'padding300'}
        boxShadow={'shadow100'}
        borderRadius={'radius400'}
        css={css`
          margin-bottom: 1.875rem;
          background-color: ${colorToCode('negative-400')};
          color: white;
          text-align: center;
        `}
      >
        <strong>CAREFUL:</strong> This page is for internal usage only, don't share this information with partners or anyone external to
        ScalePad.
      </Box>
      <TableClientSide
        css={css`
          td:has(.boolean-true) {
            background-color: ${colorToCode('positive-100')};
          }

          td:has(.boolean-warning) {
            background-color: ${colorToCode('warning-100')};
          }
        `}
        data={filteredIntegrations}
        columns={columns}
      >
        {({ Table, GlobalFilter }) => (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 1rem;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 1rem;
                align-items: flex-start;
              `}
            >
              <div>
                <GlobalFilter placeholder={'Search by integration name'} />
              </div>
              <MultiSelect
                label={'Two-way Sync support'}
                options={[
                  { label: 'Warranty expiry date', value: 'warrantyExpiryDate' },
                  { label: 'Purchase date', value: 'purchaseDate' },
                ]}
                selectedOptions={twoWaySyncSupport}
                onChange={setTwoWaySyncSupport}
                hasSelectAnyOption={false}
                showSelectCount={false}
              />
              {typeFields && filters && (
                <DataTypeFilters
                  typeFields={typeFields}
                  filters={filters}
                  onFiltersChanged={setFilters}
                />
              )}
              <InputCheckbox
                checked={supportsSoftware}
                description={'Supports software'}
                onClick={() => setSupportsSoftware(!supportsSoftware)}
              />
              <InputCheckbox
                checked={allowsMultiple}
                description={'Allows multiple'}
                onClick={() => setAllowsMultiple(!allowsMultiple)}
              />
            </div>
            <Table />
          </div>
        )}
      </TableClientSide>
    </div>
  );
};

export default SupportedIntegrationsListPage;
