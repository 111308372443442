import { css } from '@emotion/react';
import { CoverageStatusEnum } from '@WarrantyClient/Warranty/Coverage/Model.gen';
import { Request as WarrantyClientWarrantyCoveragePackagesListControllerCoverageListControllerNestedRequest } from '@WarrantyClient/Warranty/Coverage/Packages/List/Controller/CoverageListControllerNested.gen';
import { coverageList } from '@WarrantyClient/WarrantyClientMsp.gen';
import { parseISO } from 'date-fns';
import * as React from 'react';
import List from '~/legacy-ui/packages/list/List';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import useApi from '~/wm/packages/api/hook/useApi';
import AssetCoverage from '../../model/AssetCoverage';
import CoverageListItem from '../coverage-list-item/CoverageListItem';
import Spinner from '~/neo-ui/spinner/Spinner';

export type CoverageListProps = {
  coverageCardUrl: string;
  getServiceUrl: string;
  buyCoverageUrl: string;
};

const CoverageList: React.FunctionComponent<CoverageListProps> = ({ coverageCardUrl, getServiceUrl, buyCoverageUrl }) => {
  const [coveredAssets, setCoveredAssets] = React.useState<AssetCoverage[] | undefined>(undefined);
  const [expiringSoonAssets, setExpiringSoonAssets] = React.useState<AssetCoverage[] | undefined>(undefined);
  const { callApi } = useApi();
  React.useEffect(() => {
    (async () => {
      const serviceRequestListRequest: WarrantyClientWarrantyCoveragePackagesListControllerCoverageListControllerNestedRequest = {};
      const response = await callApi(() => coverageList(serviceRequestListRequest));

      if (!response) {
        setCoveredAssets([]);
        setCoveredAssets([]);
        return;
      }
      const assets = response.assetCoverages.map<AssetCoverage>(assetCoverage => ({
        orgName: assetCoverage.coveredAsset.organizationName,
        orgId: assetCoverage.coveredAsset.organizationId,
        assetName: assetCoverage.coveredAsset.name,
        modelNumber: assetCoverage.coveredAsset.modelNumber,
        serialNumber: assetCoverage.coveredAsset.serialNumber,
        orderNumber: assetCoverage.coverageInformation.coverageCardId.orderNumber,
        coverageCardUrl: coverageCardUrl.replace('__ORDER_NUMBER__', assetCoverage.coverageInformation.coverageCardId.orderNumber),
        getServiceUrl: getServiceUrl.replace('__ASSET_ACCOUNT_OBFUSCATED_ID__', assetCoverage.coveredAsset.assetId),
        coverageEndsAt:
          // TODO WM-1555
          parseISO(assetCoverage.coverageInformation.coveragePeriod.endsAt),
        coverageStatus: assetCoverage.coverageInformation.coveragePeriod.coverageStatusEnum,
        isRenewable: assetCoverage.coverageInformation.isRenewable,
        buyCoverageUrl: `${buyCoverageUrl.replace('__ORGANIZATION_ID__', assetCoverage.coveredAsset.organizationId)}#${
          assetCoverage.coveredAsset.serialNumber
        }`,
      }));
      setCoveredAssets(
        assets
          .filter(asset => asset.coverageStatus === CoverageStatusEnum.In)
          .sort((asset1, asset2) => new Date(asset1.coverageEndsAt).getTime() - new Date(asset2.coverageEndsAt).getTime()),
      );
      setExpiringSoonAssets(
        assets
          .filter(asset => asset.coverageStatus === CoverageStatusEnum.Soon)
          .sort((asset1, asset2) => new Date(asset1.coverageEndsAt).getTime() - new Date(asset2.coverageEndsAt).getTime()),
      );
    })();
  }, [buyCoverageUrl, callApi, coverageCardUrl, getServiceUrl]);

  if (typeof coveredAssets === 'undefined' && typeof expiringSoonAssets === 'undefined') {
    // Here we could have loading placeholders
    return <Spinner />;
  } else {
    return (
      <div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          `}
        >
          <LayoutSection
            title={'ScalePad warranties expiring soon'}
            titleSize={2}
          >
            <List
              items={expiringSoonAssets ? expiringSoonAssets : []}
              renderItem={(coveredListItem: AssetCoverage, index: number) => (
                <CoverageListItem
                  key={index}
                  coverageListItem={coveredListItem}
                />
              )}
              EmptyListPlaceholder="You'll see hardware that expires soon here."
            />
          </LayoutSection>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 1.875rem;
          `}
        >
          <LayoutSection
            title={'Hardware covered by a ScalePad warranty'}
            titleSize={2}
          >
            <List
              items={coveredAssets ? coveredAssets : []}
              renderItem={(coveredListItem: AssetCoverage, index: number) => (
                <CoverageListItem
                  key={index}
                  coverageListItem={coveredListItem}
                />
              )}
              EmptyListPlaceholder="You'll see hardware covered by a ScalePad warranty here."
            />
          </LayoutSection>
        </div>
      </div>
    );
  }
};

export default CoverageList;
