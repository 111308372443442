import { MultiSelectOption } from '~/neo-ui/packages/select/packages/multi-select/MultiSelect';
import { RoadmapInitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import { UserOrganizationInfoDto } from '@AssetManagementClient/BeastClient/Beast/Identity/Packages/User/Packages/OrganizationStar/Dto.gen';

type GetRoadmapInitiativesFilteredProps = {
  initiatives: RoadmapInitiativeDto[];
  selectedStatuses: MultiSelectOption[];
  selectedPriorities: MultiSelectOption[];
  selectedOrganizations: UserOrganizationInfoDto[];
};

const getRoadmapInitiativesFiltered = ({
  initiatives,
  selectedStatuses,
  selectedPriorities,
  selectedOrganizations,
}: GetRoadmapInitiativesFilteredProps) =>
  initiatives.filter(
    initiative =>
      !(
        (selectedStatuses.length !== 0 && !selectedStatuses.some(status => status.value === initiative.status)) ||
        (selectedPriorities.length !== 0 && !selectedPriorities.some(priority => priority.value === initiative.priority.key)) ||
        (selectedOrganizations.length !== 0 &&
          !selectedOrganizations.some(organization => organization.organizationId === initiative.organizationId))
      ),
  );

export default getRoadmapInitiativesFiltered;
