import { initiativeDelete } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useInitiativeMutation, {
  UseInitiativeMutationOptions,
} from '~/wm/packages/strategy/packages/initiative/hooks/useInitiativeMutation';

export type UseInitiativeDelete = {
  deleteInitiative: (initiativeId: string, isRequestActive?: () => boolean) => void;
  isDeletingInitiative: boolean;
};

const useInitiativeDelete = (options?: UseInitiativeMutationOptions): UseInitiativeDelete => {
  const [deleteInitiative, isDeletingInitiative] = useInitiativeMutation(
    (initiativeId: string) =>
      initiativeDelete({
        initiativeId,
      }),
    options,
  );

  return {
    deleteInitiative,
    isDeletingInitiative,
  };
};

export default useInitiativeDelete;
