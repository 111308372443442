import { createContext } from 'react';

export type InitiativeAssetSelectContextData = {
  assetIds: Map<string, string[]>;
  clearAssetIds: () => void;
  selectedAssetIds: Set<string>;
  lastSelectedAssetId: string | undefined;
  selectAssetIds: (items: string[], itemClicked: string | undefined) => void;
  deselectAssetIds: (items: string[], itemClicked: string | undefined) => void;
  selectAssetId: (item: string) => void;
  deselectAssetId: (item: string) => void;
  clearSelectedAssetIds: () => void;
  displayedAssetIds: string[];
  setDisplayedAssetIds: (items: string[]) => void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const InitiativeAssetSelectContext = createContext<InitiativeAssetSelectContextData | undefined>(undefined);

export default InitiativeAssetSelectContext;
