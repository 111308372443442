import Theme from '~/neo-ui/packages/color/Theme';
import Badge from '~/neo-ui/packages/badge/Badge';
import React, { Fragment } from 'react';
import FilterBadgeLabel from '~/neo-ui/packages/filter/packages/filter-badge-label/FilterBadgeLabel';
import { css } from '@emotion/react';

export type FilterBadgeSplitMultiSelectProps = {
  selectedPositiveValues: string[];
  selectedNegativeValues: string[];
  positiveOperatorLabel: string;
  negativeOperatorLabel: string;
  positiveTheme: Theme;
  negativeTheme: Theme;
};

const FilterBadgeSplitMultiSelect = ({
  selectedPositiveValues,
  selectedNegativeValues,
  positiveOperatorLabel,
  negativeOperatorLabel,
  positiveTheme,
  negativeTheme,
}: FilterBadgeSplitMultiSelectProps) => (
  <Fragment>
    {selectedPositiveValues.length >= 1 && (
      <Badge
        borderRadius={'radius200'}
        bgColor={'secondary-600'}
        css={css`
          gap: 0.3125rem;
        `}
      >
        <FilterBadgeLabel
          selectedValues={selectedPositiveValues}
          operatorLabel={positiveOperatorLabel}
          theme={positiveTheme}
        />
      </Badge>
    )}
    {selectedNegativeValues.length >= 1 && (
      <Badge
        borderRadius={'radius200'}
        bgColor={'negative-600'}
        css={css`
          gap: 0.3125rem;
        `}
      >
        <FilterBadgeLabel
          selectedValues={selectedNegativeValues}
          operatorLabel={negativeOperatorLabel}
          theme={negativeTheme}
        />
      </Badge>
    )}
  </Fragment>
);

export default FilterBadgeSplitMultiSelect;
