import { RoadmapInitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import { useMemo } from 'react';
import Anchor from '~/neo-ui/packages/anchor/Anchor';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Theme, { themeToColor } from '~/neo-ui/packages/color/Theme';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import { formatCurrencyByCurrencyInfo } from '~/extensions/packages/currency/formatCurrency';
import RoadmapListViewRecurringFee from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-list-view/packages/roadmap-list-view-recurring-fee/RoadmapListViewRecurringFee';
import useAccount from '~/wm/hooks/useAccount';
import routes from '~/router/types/routes';
import { parseISO } from 'date-fns';
import useInitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesContext';
import { css } from '@emotion/react';
import { TableClientSideColumn } from '~/neo-ui/packages/table/packages/table-client-side/TableClientSide';
import InitiativeTicketDisplay from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-ticket-display/InitiativeTicketDisplay';

type UseRoadmapInitiativeTableDefinition = (
  hideOrganizationColumn: boolean,
  onClickInitiative: (initiative: RoadmapInitiativeDto) => void,
  isUnscheduled: boolean,
  enabledInitiativeTickets: boolean,
) => {
  columns: TableClientSideColumn<RoadmapInitiativeDto>[];
};

const useRoadmapInitiativeTableDefinition: UseRoadmapInitiativeTableDefinition = (
  hideOrganizationColumn,
  onClickInitiative,
  isUnscheduled,
  enabledInitiativeTickets,
) => {
  const { availabilities } = useInitiativeAvailabilitiesContext();
  const { currencyCode, subunitRatio } = useAccount();

  const columns: TableClientSideColumn<RoadmapInitiativeDto>[] = useMemo(() => {
    return [
      ...(hideOrganizationColumn
        ? []
        : [
            {
              fieldKey: initiative => initiative.organizationName,
              header: 'Client',
              renderCell: ({ original: initiative }) => (
                <Anchor href={routes.ORGANIZATION_DASHBOARD.build([initiative.organizationId])}>{initiative.organizationName}</Anchor>
              ),
            } satisfies TableClientSideColumn<RoadmapInitiativeDto>,
          ]),
      {
        fieldKey: initiative => initiative.name,
        header: 'Initiative',
        renderCell: ({ original: initiative }) => (
          <div onClick={() => onClickInitiative(initiative)}>
            <Anchor href={'#'}>{initiative.name}</Anchor>
          </div>
        ),
      },
      ...(isUnscheduled
        ? []
        : [
            {
              fieldKey: initiative => initiative.budgetQuarter,
              header: 'Scheduled',
              renderCell: ({ original: initiative }) =>
                typeof initiative.budgetQuarter === 'undefined' ? '-' : `Q${initiative.budgetQuarter.quarter}`,
              widthRem: 5.8125,
            } satisfies TableClientSideColumn<RoadmapInitiativeDto>,
          ]),
      {
        fieldKey: initiative => initiative.pointOfContactUserName,
        header: 'POC',
        renderCell: ({ original: initiative }) => initiative.pointOfContactUserName ?? '-',
        widthRem: 8.5,
      },
      {
        fieldKey: initiative => initiative.status,
        header: 'Status',
        renderCell: ({ original: initiative }) =>
          availabilities.statusAvailabilities.find(status => status.id === initiative.status)?.displayLabel,
        widthRem: 10.5625,
      },
      {
        fieldKey: initiative => initiative.priority.sortOrder,
        header: 'Priority',
        renderCell: ({ original: initiative }) => (
          <Icon
            icon={initiative.priority.icon as IconType}
            color={themeToColor(initiative.priority.theme as Theme, '400')}
          />
        ),
        widthRem: 4.8125,
      },
      {
        fieldKey: initiative => initiative.assetCount,
        header: 'Assets',
        renderCell: ({ original: initiative }) => initiative.assetCount,
        widthRem: 5,
      },
      {
        fieldKey: initiative => initiative.createdAt,
        header: 'Creation date',
        renderCell: ({ original: initiative }) =>
          formatDate(parseISO(initiative.createdAt), {
            format: 'yyyy-MM-dd',
            timezone: TimezoneFormat.Local,
          }),
        widthRem: 8.5,
      },
      {
        fieldKey: initiative => initiative.sumBudgetSubunits,
        header: 'One-time fees',
        renderCell: ({ original: initiative }) => (
          <div
            css={css`
              text-align: right;
            `}
          >
            {initiative.sumBudgetSubunits === 0
              ? '-'
              : formatCurrencyByCurrencyInfo(initiative.sumBudgetSubunits, {
                  code: currencyCode,
                  subunitsPerUnit: subunitRatio,
                })}
          </div>
        ),
        widthRem: 8.5,
      },
      {
        fieldKey: initiative => initiative.sumRecurringFees.sumRecurringMonthlySubunits,
        header: 'Recurring fees',
        renderCell: ({ original: initiative }) => (
          <RoadmapListViewRecurringFee
            sumRecurringFees={initiative.sumRecurringFees}
            currency={{ code: currencyCode, subunitsPerUnit: subunitRatio }}
          />
        ),
        widthRem: 8.5,
      },
      ...(!enabledInitiativeTickets
        ? []
        : [
            {
              fieldKey: initiative => initiative.ticketLinkState,
              header: 'PSA Ticket',
              renderCell: ({ original: initiative }) => (
                <div
                  css={css`
                    text-align: left;
                  `}
                >
                  {typeof initiative.ticketLinkState !== 'undefined' ? (
                    <InitiativeTicketDisplay
                      ticketLinkState={initiative.ticketLinkState}
                      createdStateColor={'primary-500'}
                      css={css`
                        justify-content: left;
                      `}
                    />
                  ) : (
                    '-'
                  )}
                </div>
              ),
              widthRem: 5.7,
            } satisfies TableClientSideColumn<RoadmapInitiativeDto>,
          ]),
    ];
  }, [
    availabilities.statusAvailabilities,
    currencyCode,
    enabledInitiativeTickets,
    hideOrganizationColumn,
    isUnscheduled,
    onClickInitiative,
    subunitRatio,
  ]);

  return {
    columns,
  };
};

export default useRoadmapInitiativeTableDefinition;
