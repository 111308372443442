import { css } from '@emotion/react';
import * as React from 'react';
import Toolbar from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import LayoutHeaderFooterProps from '~/neo-ui/packages/layout/types/LayoutHeaderFooterProps';
import LayoutBar from '~/neo-ui/packages/layout/packages/bar/LayoutBar';

/**
 * A header that sticks to the bottom of the nav bar.
 */
const LayoutHeaderReact = ({ className, leftControls, rightControls, layoutBarComponent }: LayoutHeaderFooterProps) => (
  <div
    className={className}
    css={css`
      position: sticky;
      top: var(--app-bar-height);
    `}
  >
    {(leftControls || rightControls) && (
      <Toolbar
        leftControls={leftControls}
        rightControls={rightControls}
      />
    )}
    {layoutBarComponent && (
      <LayoutBar
        css={css`
          margin-top: -0.0625rem;
        `}
      >
        {layoutBarComponent}
      </LayoutBar>
    )}
  </div>
);

export default LayoutHeaderReact;
