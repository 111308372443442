import * as React from 'react';
import { css } from '@emotion/react';
import DiscountInfoGroup from '~/wm/packages/subscription/packages/discount-info/packages/discount-info-group/DiscountInfoGroup';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Card from '~/legacy-ui/packages/card/Card';
import { Styleable } from '~/neo-ui/model/capacity';
import { OfferSummaryDto } from '@SubscriptionClient/Subscription/Packages/Offer/Dto/Model.gen';
import DiscountOfferSummaryInfo from '~/wm/packages/subscription/packages/discount-info/packages/discount-offer-summary-info/DiscountOfferSummaryInfo';

export type DiscountOfferSummaryProps = {
  pdImageUrl: string;
  discountPercentage: number;
  daysUntilExpiry: number;
  offerSummaryDto: OfferSummaryDto;
} & Styleable;

const DiscountOfferSummary: React.FunctionComponent<DiscountOfferSummaryProps> = ({
  discountPercentage,
  pdImageUrl,
  daysUntilExpiry,
  offerSummaryDto,
  className,
}) => (
  <Card
    className={className}
    padding={'lg'}
  >
    <div
      css={css`
        margin-left: 0.625rem;
      `}
    >
      <Header
        size={4}
        color={'positive-400'}
        css={css`
          font-weight: bold;
          margin-bottom: 1.25rem;
        `}
      >
        Custom offer from ScalePad
      </Header>
      <DiscountInfoGroup pdImageSource={pdImageUrl}>
        <DiscountOfferSummaryInfo
          discountPercentage={discountPercentage}
          daysUntilExpiry={daysUntilExpiry}
          offerSummaryDto={offerSummaryDto}
        />
      </DiscountInfoGroup>
    </div>
  </Card>
);

export default DiscountOfferSummary;
