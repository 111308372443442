import {
  AdditionalCharge,
  Inclusion as InclusionLabel,
  Plan,
  ProductConsumptionAllotment,
} from '@SubscriptionClient/BeastClient/Beast/Msp/Billing/SelfUpgrade/Dto.gen';
import { Enum as ProductEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription/Product/ProductFactoryNested.gen';
import { subscriptionUpgradeSelfGet } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { useCallback, useEffect, useState } from 'react';
import { Inclusion } from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-selection/packages/card/card-price/CardPrice';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import useApi from '~/wm/packages/api/hook/useApi';
import { PlanCardGridProps } from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-selection/packages/plan-card-grid/PlanCardGrid';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';

export type ProductInclusion = {
  label: string;
  tooltip: string;
};

export type PurchaseViewModelGet = {
  inclusions: ProductInclusion[];
  cards: PlanCardGridProps[];
  termInMonth: number;
  activationFee: number;
  logoUrl: string;
  logoLabel: string;
  additionalInformation?: string;
  isGrow: boolean;
  subscriptionPlanId: string;
};
const useSelfUpgradeViewModelGet = (preselectProduct: string | undefined) => {
  const [isLoading, setLoading] = useState(true);
  const [selfUpgradeViewModelGetDto, setSelfUpgradeViewModelGetDto] = useState<{ data: PurchaseViewModelGet | undefined }>({
    data: undefined,
  });
  const { callApi } = useApi();
  const { formik } = useMultiStepForm<PurchaseSubscriptionForm>();
  const { setFieldValue } = formik;
  const handleAction = useCallback(
    (productEnum: ProductEnum, subscriptionPlanId: string, planLimit: number, planPrice: number, activationFee: number) => {
      setFieldValue('subscriptionPlanId', subscriptionPlanId, false);
      setFieldValue('selectedPlanLimit', planLimit, false);
      setFieldValue('planSubtotal', planPrice, false);
      setFieldValue('productEnum', productEnum, false);
      setFieldValue('activationFee', activationFee, false);
    },
    [setFieldValue],
  );
  useEffect(() => {
    (async () => {
      const response = await callApi(() =>
        subscriptionUpgradeSelfGet({
          preselectProductAbbreviation: preselectProduct,
        }),
      );
      if (!response) {
        return;
      }
      const additionalCharges: AdditionalCharge[] = response.data.additionalCharges;
      const additionalInformationText: string = response.data.additionalInformationText;
      const plans: Plan[] = response.data.planOptions;
      const activationFee = additionalCharges
        .map((charge: AdditionalCharge) => charge.additivePrice)
        .reduce((a: number, b: number) => a + b, 0);
      const selfUpgradeViewModel: PurchaseViewModelGet = {
        inclusions: response.data.productInclusions.map((inclusion: InclusionLabel) => ({
          label: inclusion.label,
          tooltip: inclusion.tooltip,
        })),
        isGrow: response.data.isGrow,
        subscriptionPlanId: response.data.subscriptionPlanId,
        activationFee,
        termInMonth: response.data.contractTermDetails.length,
        logoUrl: response.data.productLogoUrl,
        additionalInformation: additionalInformationText.length > 0 ? response.data.additionalInformationText : undefined,
        logoLabel: response.data.productLabel,
        cards: plans.map(
          (plan: Plan): PlanCardGridProps => ({
            action: () => {
              handleAction(plan.productEnum, response.data.subscriptionPlanId, plan.planLimit, plan.planPrice, activationFee);
            },
            amount: plan.planPrice,
            inclusions: plan.productConsumptionAllotments.map(
              (allotment: ProductConsumptionAllotment): Inclusion => ({
                title: allotment.allottedUnits.toLocaleString('en-US') + ' ' + allotment.unitName,
                tooltip: allotment.unitDescription,
              }),
            ),
            isRecommended: plan.isPreselected,
            isActionDisabled: plan.isCurrentPlan,
            selectedItemIconTooltip: plan.preselectedTooltip,
            title: plan.name,
            planLimit: plan.planLimit,
          }),
        ),
      };
      setSelfUpgradeViewModelGetDto({ data: selfUpgradeViewModel });
      setLoading(false);
    })();
  }, [callApi, preselectProduct, handleAction]);

  return { isLoading, selfUpgradeViewModelGetDto };
};

export default useSelfUpgradeViewModelGet;
