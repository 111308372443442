import * as React from 'react';
import { ScorecardItemAutomationDataDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardItem/Dto/Model.gen';
import { formatRelativeOrDateTimeFriendly } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import useScorecardUrlContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardUrlContext';

import Popup from '~/neo-ui/packages/popup/Popup';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';

export type ScorecardItemAutomationInfoIndicatorProps = {
  organizationId: string;
  scorecardItemAutomationData: ScorecardItemAutomationDataDto;
};

const ScorecardItemAutomationInfoIndicator = ({
  organizationId,
  scorecardItemAutomationData,
}: ScorecardItemAutomationInfoIndicatorProps) => {
  const { insightViewUrl } = useScorecardUrlContext();
  const [isOpen, setIsOpen] = React.useState(false);

  const scorecardItemAutomationInfo: React.ReactNode = (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
      `}
    >
      <Label>{`Last update ${formatRelativeOrDateTimeFriendly(parseISO(scorecardItemAutomationData.refreshedAt))}`}</Label>
      <Label>Update this item to get the latest score & description from your Insight:</Label>
      <ButtonLink
        theme={'primary'}
        iconLeft={'Insight'}
        iconRight={'GoExternal'}
        anchor={{
          href: insightViewUrl
            .replace('__ORGANIZATION_ID__', organizationId)
            .replace('__INSIGHT_ID__', scorecardItemAutomationData.insightId),
          openInNewTab: true,
        }}
        css={css`
          width: fit-content;
        `}
      >
        {scorecardItemAutomationData.insightDisplayLabel}
      </ButtonLink>
    </div>
  );

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement={'left-end'}
      backgroundColor={'light-000'}
      title={'Score from Insights'}
      titleColor={'secondary-400'}
      showCloseButton={true}
      content={scorecardItemAutomationInfo}
    >
      <Icon
        icon={'Info'}
        onClick={() => setIsOpen(prev => !prev)}
        color={'light-800'}
        css={css`
          margin-top: 0.3125rem;
        `}
      />
    </Popup>
  );
};

export default ScorecardItemAutomationInfoIndicator;
