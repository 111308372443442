import { useCallback } from 'react';

export type SubscriptionIdentity = {
  /**
   * Returns whether Lifecycle Manager is in Free Edition
   */
  isLmFree: boolean;
  /**
   * Is the account have some unpaid subscription ?
   */
  isNotPastDue: boolean;
  /**
   * Whether this subscription is billable based on all products
   */
  isBillable: boolean;
};

export type UseAccount = () => {
  id: string;
  getAccountAccess: (accessToCheck: AccountAccessFlags) => boolean;
  isVirginDataSource: boolean;
  /**
   * The current logged-in account's subscription details
   */
  subscriptionIdentity: SubscriptionIdentity;
  currencyCharacter: string;
  subunitRatio: number;
  currencyCode: string;
};

export type AccountAccess = { [key in AccountAccessFlags]: { Type: string; Value: boolean } };

/**
 * Duplicated in backend F25FFF7D-9094-4487-889D-BA5C22075FD5
 */
export type AccountAccessFlags =
  | 'CanEditIntegration'
  | 'CanSync'
  | 'CanSync2Way'
  | 'CanUnblur'
  | 'CanViewIssueCsv'
  | 'CanResolveRenewal'
  | 'CanViewRenewal'
  | 'CanScheduleAccelerate'
  | 'MaxUser'
  | 'MaxDevice'
  | 'CanManualOverride'
  | 'CanManualOverrideDedupe'
  | 'AdvancedDeviceTypesEnabled'
  | 'CanScheduleReports'
  | 'CanUseReactChangelog'
  | 'CanUseReactProgress'
  | 'CanUseBasicVendor'
  | 'CanUsePremiumVendor'
  | 'CanUseOrganizationLinking'
  | 'CanAccessCoverageConsole'
  | 'CanAccessWorkstationCoverage'
  | 'CanAccessIntegrationSyncHistory'
  | 'HasWarrantiesFullAccess'
  | 'CanFullyAccessInitiative'
  | 'CanFullyAccessInsights'
  | 'CanFullyAccessDisposal'
  | 'HasDisposalLocationAccess'
  | 'HasDisposalBulkLocationAccess';

const useAccount: UseAccount = () => {
  const getAccountAccess = useCallback((accessToCheck: AccountAccessFlags) => {
    const accessState = WM.account.access[accessToCheck];

    // If the account's access does not exist or the type is not boolean, assume false
    if (typeof accessState === 'undefined' || accessState.Type !== 'Boolean') {
      return false;
    }

    return accessState.Value;
  }, []);
  return {
    id: WM.account.id,
    getAccountAccess,
    isVirginDataSource: WM.account.isVirginDataSource,
    subscriptionIdentity: {
      isLmFree: WM.account.subscriptionIdentity.isLmFree,
      isNotPastDue: WM.account.subscriptionIdentity.isNotPastDue,
      isBillable: WM.account.subscriptionIdentity.isBillable,
    },
    currencyCharacter: WM.account.currencyCharacter,
    subunitRatio: WM.account.subunitRatio,
    currencyCode: WM.account.currencyCode,
  };
};

export default useAccount;
