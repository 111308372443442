/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import * as React from 'react';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Button from '~/neo-ui/packages/button/Button';
import { FieldKeyExpression, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { Styleable } from '~/neo-ui/model/capacity';
import { disposalCouponValidate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { Request as DisposalCouponValidateRequest } from '@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Coupon/DisposalCouponCodeValidateControllerNested.gen';
import useDisposalDynamicPricingCalculator from '~/wm/packages/disposal/hooks/useDisposalDynamicPricingCalculator';
import { DisposalDynamicPricingDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Dto/Model.gen';
import { Coupon } from '~/wm/packages/coupon/Coupon';
import Chip from '~/neo-ui/packages/chip/Chip';
import FormTitleInput from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/FormTitleInput';

export type DisposalCoupon<T> = {
  initialCoupon: Coupon | undefined;
  couponCodeFieldKey: FieldKeyExpression<T>;
  couponFieldKey: FieldKeyExpression<T>;
  disposalDynamicPricing: DisposalDynamicPricingDto;
  assetCount: number;
  isEditing: boolean;
  onEditing: (isEditing: boolean) => void;
  onCouponRemoved: () => void;
} & Styleable;

const DisposalCoupon = <T,>({
  initialCoupon,
  couponCodeFieldKey,
  couponFieldKey,
  disposalDynamicPricing,
  assetCount,
  isEditing,
  onEditing,
  className,
  onCouponRemoved,
}: DisposalCoupon<T>) => {
  const { submitForm, isSubmitting, setFormInput, setFieldError, getFormInput } = useFormContext<T>();

  const { totalCostSubunits } = useDisposalDynamicPricingCalculator({
    ...disposalDynamicPricing,
    assetCount,
  });

  const validateDisposalCoupon = React.useCallback(
    async (couponCode: string) => {
      setFieldError(resolveFieldKey(couponCodeFieldKey), undefined);
      const request: DisposalCouponValidateRequest = {
        code: couponCode,
        subtotal: totalCostSubunits,
      };

      const result = await disposalCouponValidate(request);

      if (typeof result === 'undefined') {
        throw new Error('Could not get a valid coupon response');
      }

      if (result.case === 'Error') {
        setFieldError(resolveFieldKey(couponCodeFieldKey), result.value.message);
        return;
      }

      if (result.case === 'Ok') {
        setFormInput<Coupon>(couponFieldKey, {
          code: couponCode,
          label: result.value.offer.label,
          offerCostSubunits: result.value.offer.amount,
        });

        await submitForm();
        return;
      }

      throw new Error('Could not get a valid coupon response');
    },
    [couponCodeFieldKey, couponFieldKey, setFieldError, setFormInput, submitForm, totalCostSubunits],
  );

  return isEditing ? (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <Header
        css={css`
          margin-bottom: 0.375rem;
        `}
        size={5}
        weight={'bold'}
      >
        Enter your coupon code
      </Header>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          gap: 1rem;
          align-items: center;
        `}
      >
        <FormTitleInput
          prependIcon={'Ticket'}
          fieldKey={couponCodeFieldKey}
          css={css`
            flex-grow: 1;
          `}
          onChange={value => {
            setFormInput<string>(couponCodeFieldKey, value);
          }}
          placeholder={'Coupon code'}
        />
        <div
          css={css`
            display: flex;
            gap: 0.625rem;
          `}
        >
          <Button
            onClick={() => validateDisposalCoupon(getFormInput(couponCodeFieldKey).value)}
            loading={isSubmitting}
            theme={'primary'}
            iconLeft={'Done'}
          >
            Apply coupon
          </Button>
          <Button
            onClick={() => {
              onEditing(false);
              setFormInput<undefined>(couponCodeFieldKey, undefined);
            }}
            iconRight={'Cancel'}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  ) : typeof initialCoupon !== 'undefined' ? (
    <div>
      <Header size={5}>Coupon</Header>
      <Chip
        css={css`
          margin-top: 0.5rem;
          padding: 0rem 0.188rem;
        `}
        onClick={() => {
          onCouponRemoved();
          setFormInput<undefined>(couponFieldKey, undefined);
        }}
      >
        {initialCoupon.label} ({initialCoupon.code})
      </Chip>
    </div>
  ) : null;
};

export default DisposalCoupon;
