import * as React from 'react';

/**
 * On update of deps, executes a function that contains imperative, possibly effectful code.
 * See https://reactjs.org/docs/hooks-faq.html#can-i-run-an-effect-only-on-updates
 * @param effect
 * @param deps
 */
export const useEffectUpdate = (effect: () => void | (() => void | undefined), deps: React.DependencyList) => {
  const isInitialMount = React.useRef(true);

  React.useEffect(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
        return;
      }
      return effect();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );
};
