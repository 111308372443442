import { createContext } from 'react';

export type StrategyDashboardUrlContextData = {
  upgradeUrl: string;
  growUrl: string;
  roadmapUrl: string;
  engagementActionListUrl: string;
  engagementNoteListUrl: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const StrategyDashboardUrlContext = createContext<StrategyDashboardUrlContextData | undefined>(undefined);

export default StrategyDashboardUrlContext;
