import * as React from 'react';
import { css } from '@emotion/react';
import { RenderCellDataNested } from '@AssetManagementClient/BeastClient/Renderer/Model/Data.gen';

export type RenderColoredCircleCellProps = {
  data: RenderCellDataNested.ColoredCircleData;
};

const RenderColoredCircleCell: React.FunctionComponent<RenderColoredCircleCellProps> = ({ data: { color } }) => (
  <div
    css={css`
      display: inline-block;
      width: 1rem;
      height: 1rem;
      background-color: ${color ?? 'grey'};
      border-radius: 50%;
    `}
  />
);

export default RenderColoredCircleCell;
