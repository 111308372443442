import React from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';

export type TicketUnlinkModalProps = {
  isOpen: boolean;
  isUnlinkingTicket: boolean;
  onUnlinkTicket: () => void;
  onDismiss: () => void;
};

const TicketUnlinkModal = ({ onUnlinkTicket, isOpen, isUnlinkingTicket, onDismiss }: TicketUnlinkModalProps) => (
  <ModalConfirmation
    isOpen={isOpen}
    theme={'negative'}
    header={{
      title: 'Unlink this PSA ticket?',
      icon: 'ClientUnlink',
    }}
    footer={{
      confirmationButtonAlignment: 'left',
      dismissButton: {
        label: 'Cancel',
        disabled: isUnlinkingTicket,
        icon: 'ActionClose',
        onClick: onDismiss,
      },
      confirmButton: {
        label: 'Yes, Unlink',
        loading: isUnlinkingTicket,
        disabled: isUnlinkingTicket,
        icon: 'ClientUnlink',
        onClick: onUnlinkTicket,
      },
    }}
  >
    <Label>
      Once unlinked, the ticket cannot be reconnected. Please note that you will need to create a new ticket after unlinking this one.
    </Label>
  </ModalConfirmation>
);

export default TicketUnlinkModal;
