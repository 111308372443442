import { ReactNode } from 'react';
import FiscalQuarter from '~/extensions/packages/date/packages/fiscal-quarter/FiscalQuarter';
import ButtonType, { ButtonTypeComponent } from '~/neo-ui/packages/button/types/ButtonType';
import useRoadmapInitiativesContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapInitiativesContext';
import { InitiativeCreateSource } from '~/wm/packages/strategy/model/strategyDataCollectionSources';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useInitiativeMutationContext from '~/wm/packages/strategy/packages/initiative/context/hooks/useInitiativeMutationContext';
import useInitiativeDraftCreate from '~/wm/packages/strategy/packages/initiative/packages/initiative-draft/hooks/useInitiativeDraftCreate';

export type InitiativeSaveButtonProps = {
  /**
   * Indicates whether to display the component as a Button or a ButtonLink
   */
  type: ButtonType;
  /**
   * Indicates the default fiscalQuarter for the BudgetQuarter
   */
  fiscalQuarter?: FiscalQuarter;
  children?: ReactNode;
};

const InitiativeDraftCreateButton = ({ type, fiscalQuarter, children }: InitiativeSaveButtonProps) => {
  const { organizationId } = useOrganizationContext();

  const { triggerInitiativeReload } = useInitiativeMutationContext();
  const { setDraftInitiativeId } = useRoadmapInitiativesContext();

  const { createDraft } = useInitiativeDraftCreate((initiativeId: string) => {
    setDraftInitiativeId(initiativeId);
    triggerInitiativeReload();
  });

  const ButtonComponent = ButtonTypeComponent(type);

  return (
    <ButtonComponent
      theme={'primary'}
      iconRight={'Add'}
      onClick={() => {
        createDraft({
          createdFrom: 'roadmap-quick-create' satisfies InitiativeCreateSource,
          organizationId,
          targetQuarter: fiscalQuarter,
        });
      }}
    >
      {children}
    </ButtonComponent>
  );
};

export default InitiativeDraftCreateButton;
