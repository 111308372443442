import { createContext } from 'react';

export type ScorecardUrlContextData = {
  upgradeUrl: string;
  growUrl: string;
  scorecardViewUrl: string;
  scorecardIndividualViewUrl: string;
  insightViewUrl: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ScorecardUrlContext = createContext<ScorecardUrlContextData | undefined>(undefined);

export default ScorecardUrlContext;
