import Workflow from '~/legacy-ui/packages/operation/model/Workflow';
import progressRequestedAction from '~/neo-ui/packages/progress/state/action/requested/progressRequestedAction';

const progressWorkflow: Workflow = async ({ operation, dispatch, context }, next) => {
  const error = await next();

  if (
    // done, no progress to do
    typeof operation.progress === 'undefined' ||
    // action failed, do not initiate progress
    typeof error !== 'undefined'
  ) {
    return error;
  }

  // Begin fetching progress
  const progressDefinition = operation.progress(context);
  dispatch(progressRequestedAction(progressDefinition));

  return undefined;
};

export default progressWorkflow;
