import { Enum as DeviceType } from '@WarrantyClient/BeastClient/Goods/DeviceType/DeviceTypeFactoryNested.gen';
import { warrantyCartStaticCoTerminationCostCalculator } from '~/wm/packages/warranty/packages/warranty-cart/calculators/coTermination/warranty-cart-static-coTermination-cost-calculator/WarrantyCartStaticCoTerminationCostCalculator';
import { warrantyCartDynamicCoTerminationCostCalculator } from '~/wm/packages/warranty/packages/warranty-cart/calculators/coTermination/warranty-cart-dynamic-coTermination-cost-calculator/WarrantyCartDynamicCoTerminationCostCalculator';
import { WarrantyCartSelectedAsset } from '~/wm/packages/warranty/packages/warranty-cart/types/WarrantyCartSelectedAsset';
import { RenewalOptionSellingPriceDto } from '@WarrantyClient/BeastClient/Beast/Estimate/Dto/Cart/PaginatedGet.gen';
import { WarrantyCartPriceCalculatorType } from '~/wm/packages/warranty/packages/warranty-cart/types/WarrantyCartCalculatorType';
import { getCostCalculatorType } from '~/wm/packages/warranty/packages/warranty-cart/calculators/warrantyCartCalculators';

export type WarrantyCartCoTerminationCostCalculator = {
  calculatorType: WarrantyCartPriceCalculatorType;
  calculateCost: (
    assetAccountId: string | undefined,
    availablePlans: RenewalOptionSellingPriceDto[],
    selectedPlanId: string | undefined,
    selectedUnits: WarrantyCartSelectedAsset[],
    duration: number,
  ) => number;
};

const warrantyCartCostCalculatorList = [warrantyCartStaticCoTerminationCostCalculator, warrantyCartDynamicCoTerminationCostCalculator];
const warrantyCartCostCalculatorByType = new Map(warrantyCartCostCalculatorList.map(calc => [calc.calculatorType, calc]));
export const getCoTerminationCalculatorByDeviceType = (deviceType: DeviceType) =>
  warrantyCartCostCalculatorByType.get(getCostCalculatorType(deviceType));

export default WarrantyCartCoTerminationCostCalculator;
