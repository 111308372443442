import Button from '~/neo-ui/packages/button/Button';
import { css } from '@emotion/react';
import { Fragment, useState } from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import useAutoEmailReportTestSend from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportTestSend';
import { Styleable } from '~/neo-ui/model/capacity';

import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';

export type AutoEmailReportTestMessageButtonProps = {
  autoEmailReportId: string;
} & Styleable;

const AutoEmailReportTestMessageButton = ({ autoEmailReportId }: AutoEmailReportTestMessageButtonProps) => {
  const { organizationId } = useOrganizationContext();
  const [isOpen, setIsOpen] = useState(false);
  const { sendAutoEmailReportTest, isSendingAutoEmailReportTest } = useAutoEmailReportTestSend(organizationId, {
    onSuccess: () => setIsOpen(false),
  });

  return (
    <Fragment>
      <Button
        iconLeft={'Mail'}
        onClick={() => setIsOpen(true)}
        css={css`
          align-self: flex-start;
        `}
      >
        Send Test Message
      </Button>
      <ModalConfirmation
        header={{ title: 'Send test message', icon: 'Mail' }}
        footer={{
          confirmButton: {
            label: 'Send now',
            icon: 'Done',
            loading: isSendingAutoEmailReportTest,
            onClick: () => sendAutoEmailReportTest(autoEmailReportId),
          },
          dismissButton: {
            label: 'Cancel',
            icon: 'Cancel',
            loading: isSendingAutoEmailReportTest,
            onClick: () => setIsOpen(false),
          },
        }}
        theme={'primary'}
        isOpen={isOpen}
      >
        The preview will only be sent to you.
      </ModalConfirmation>
    </Fragment>
  );
};

export default AutoEmailReportTestMessageButton;
