import { css } from '@emotion/react';
import { IntegrationSyncLogEntry, IntegrationSyncResult } from '@IntegrationsClient/Integrations/Sync/Packages/SyncLog/Model.gen';
import { integrationSyncLogGet } from '@IntegrationsClient/IntegrationsClientMsp.gen';
import { parseISO } from 'date-fns';
import * as React from 'react';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import AsyncDataTable from '~/neo-ui/packages/table/packages/async-data-table/AsyncDataTable';
import { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import TableCell from '~/neo-ui/packages/table/packages/table-cell/TableCell';
import useApi from '~/wm/packages/api/hook/useApi';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type IntegrationSyncLogTableProps = {
  integrationSetupId: string;
};

/**
 * Table of an integration's sync history
 */
const IntegrationSyncLogTable: React.FunctionComponent<IntegrationSyncLogTableProps> = ({ integrationSetupId }) => {
  const { callApi } = useApi();

  const columns: DataTableColumn<IntegrationSyncLogEntry>[] = React.useMemo(
    () => [
      {
        Header: 'Started',
        fieldKey: row => row.syncStartedAt,
        renderCell: row => (
          <TableCell
            label={formatDate(parseISO(row.syncStartedAt), {
              format: 'yyyy/MM/dd',
              timezone: TimezoneFormat.Local,
            })}
            description={formatDate(parseISO(row.syncStartedAt), {
              format: 'hh:mm a',
              timezone: TimezoneFormat.Local,
            })}
            descriptionMuted={true}
          />
        ),
      },
      {
        Header: 'Ended',
        fieldKey: row => row.syncCompletedAt,
        renderCell: row => (
          <TableCell
            label={formatDate(parseISO(row.syncCompletedAt), {
              format: 'yyyy/MM/dd',
              timezone: TimezoneFormat.Local,
            })}
            description={formatDate(parseISO(row.syncCompletedAt), {
              format: 'hh:mm a',
              timezone: TimezoneFormat.Local,
            })}
            descriptionMuted={true}
          />
        ),
      },
      {
        Header: 'Hardware assets',
        fieldKey: row => row.hardware.totalSynced,
        renderCell: row => (row.hardware.totalSynced ? row.hardware.totalSynced.toLocaleString() : '-'),
      },
      {
        Header: 'Hardware change',
        fieldKey: row => row.hardware.totalSyncedDelta,
        renderCell: row =>
          row.hardware.totalSyncedDelta ? (
            <TableCell
              label={Math.abs(row.hardware.totalSyncedDelta).toLocaleString()}
              icon={row.hardware.totalSyncedDelta > 0 ? 'GoUp' : 'GoDown'}
              iconColor={row.hardware.totalSyncedDelta > 0 ? 'positive-400' : 'negative-400'}
            />
          ) : (
            <div
              css={css`
                height: 0.125rem;
                width: 1rem;
                border-radius: 0.63rem;
                background-color: ${colorToCode('light-700')};
              `}
            />
          ),
      },
      {
        Header: 'Software assets',
        fieldKey: row => row.software.totalSynced,
        renderCell: row => (row.software.totalSynced ? row.software.totalSynced.toLocaleString() : '-'),
      },
      {
        Header: 'Software change',
        fieldKey: row => row.software.totalSyncedDelta,
        renderCell: row =>
          row.software.totalSyncedDelta ? (
            <TableCell
              label={Math.abs(row.software.totalSyncedDelta).toLocaleString()}
              icon={row.software.totalSyncedDelta > 0 ? 'GoUp' : 'GoDown'}
              iconColor={row.software.totalSyncedDelta > 0 ? 'positive-400' : 'negative-400'}
            />
          ) : (
            <div
              css={css`
                height: 0.125rem;
                width: 1rem;
                border-radius: 0.63rem;
                background-color: ${colorToCode('light-700')};
              `}
            />
          ),
      },
      {
        Header: 'Result',
        fieldKey: row => row.syncResult,
        renderCell: row => (
          <TableCell
            label={row.syncResult}
            description={row.failureReason}
            descriptionMuted={true}
            icon={row.syncResult === IntegrationSyncResult.Succeeded ? 'Success' : 'SyncBroken'}
            iconColor={row.syncResult === IntegrationSyncResult.Succeeded ? 'positive-400' : 'negative-400'}
          />
        ),
      },
    ],
    [],
  );

  return (
    <div>
      <AsyncDataTable
        tableTitle={'Sync log'}
        tableDescription={'Each sync will appear here when it finishes.'}
        columns={columns}
        onFetchData={async () => {
          const response = await callApi(() => integrationSyncLogGet({ integrationSetupId }));
          if (!response) {
            return { data: [] };
          }

          return {
            data: response.integrationSyncLog.logEntries,
          };
        }}
        EmptyStatePlaceholder={'No sync log available.'}
      />
    </div>
  );
};

export default IntegrationSyncLogTable;
