import { AddressBusiness } from '@SubscriptionClient/BeastClient/Common/Location/Model/Address.gen';
import { getCountryByIso2 } from '~/neo-ui/model/Country';

const formatBillingAddress = (billingAddress: AddressBusiness, includePhone?: boolean): string => {
  const addressLines = [billingAddress.address.line1, billingAddress.address.line2];

  const billingAddressLines = addressLines.filter(field => typeof field !== 'undefined' && field.length > 0).join(' ');

  const addressFields = [
    billingAddressLines,
    billingAddress.address.city,
    billingAddress.address.stateCode ?? billingAddress.address.stateFallback,
    getCountryByIso2(billingAddress.address.countryCode ?? billingAddress.address.countryFallback)?.name,
    billingAddress.address.zip,
    ...(includePhone ? [billingAddress.phone] : []),
  ];

  return addressFields.filter(field => typeof field !== 'undefined' && field.length > 0).join(', ');
};

export default formatBillingAddress;
