import { QuoteSummary } from '@SubscriptionClient/BeastClient/Billing/Subscription/Quotes/Application/Queries.gen';
import { Discriminant } from '@SubscriptionClient/Primitives/Results/Response_/PublicError_/ResultNested.gen';
import { subscriptionBillingQuote, subscriptionBillingQuoteAccept } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { useCallback, useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

export default function useQuoteSummaryApi(quoteId: string) {
  const [state, setState] = useState({
    quoteSummary: null as QuoteSummary | null,
    error: null as string | null,
    isLoading: true,
  });

  const { callApi } = useApi();

  const refreshData = useCallback(async () => {
    const response = await callApi(() => subscriptionBillingQuote({ quoteId }));

    if (!response || response.case === Discriminant.Error) {
      setState(previous => ({
        ...previous,
        error: response?.value.publicErrorMessage ?? 'Unable to find quote',
        quoteSummary: null,
        isLoading: false,
      }));
      return;
    }

    setState(previous => ({
      ...previous,
      quoteSummary: response.value.quoteSummary!,
      error: null,
      isLoading: false,
    }));
  }, [callApi, quoteId]);

  const acceptQuote = useCallback(
    async (
      callbacks: {
        onSuccess?: () => void | Promise<void>;
        onFailed?: () => void | Promise<void>;
      } = {},
    ) => {
      setState(previous => ({
        ...previous,
        isLoading: true,
      }));

      try {
        await callApi(() => subscriptionBillingQuoteAccept({ quoteId }));
        await callbacks?.onSuccess?.();
      } catch {
        await callbacks?.onFailed?.();
      } finally {
        setState(previous => ({
          ...previous,
          isLoading: false,
        }));
      }
    },
    [callApi, quoteId],
  );

  useEffect(() => {
    (async () => {
      await refreshData();
    })();
  }, [refreshData]);

  return {
    ...state,
    refreshData,
    acceptQuote,
  };
}

export type UseQuoteSummaryApi = ReturnType<typeof useQuoteSummaryApi>;
