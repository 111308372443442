import { useEffect, useState } from 'react';
import { DevicesByTypeCountDto } from '@WarrantyClient/BeastClient/Beast/Warranties/Dto/DevicesByTypeCount.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import { cartDevicesByType } from '@WarrantyClient/WarrantyClientMsp.gen';
import { Enum as WarrantyType } from '@WarrantyClient/BeastClient/Goods/WarrantyType/WarrantyTypeFactoryNested.gen';

const useDevicesByTypes = (organizationId: string, warrantyType: WarrantyType | undefined) => {
  const [availableAssetTypes, setAvailableAssetTypes] = useState<DevicesByTypeCountDto[]>([]);
  const [availableAssetTypesLoading, setAvailableAssetTypesLoading] = useState<boolean>(true);
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      setAvailableAssetTypesLoading(true);
      const response = await callApi(() => cartDevicesByType({ organizationId, warrantyType }));
      if (!response) {
        return;
      }
      setAvailableAssetTypes(response.data);
      setAvailableAssetTypesLoading(false);
    })();
  }, [callApi, organizationId, warrantyType]);

  return {
    availableAssetTypes,
    availableAssetTypesLoading,
  };
};

export default useDevicesByTypes;
