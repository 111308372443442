import { css } from '@emotion/react';
import { SupportedIntegrationDto } from '@BeastClient/Beast/Integration/Dto/Model.gen';

const IntegrationBasicDetailsDisplay = ({ integration }: { integration: SupportedIntegrationDto }) => {
  return (
    <table
      css={css`
        td:nth-child(2) {
          font-weight: bold;
          padding-left: 1rem;
        }

        td:nth-child(1):after {
          content: ':';
        }
      `}
    >
      <tbody>
        <tr>
          <td>Type of integration</td>
          <td>{integration.toolType}</td>
        </tr>
        <tr>
          <td>Published by</td>
          <td>{integration.publisherName}</td>
        </tr>
        {integration.formerNames.length > 0 && (
          <tr>
            <td>Former names</td>
            <td>{integration.formerNames.join(', ')}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default IntegrationBasicDetailsDisplay;
