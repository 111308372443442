import * as React from 'react';
import Window from '~/neo-ui/packages/window/Window';
import Button from '~/neo-ui/packages/button/Button';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { css } from '@emotion/react';
import FormDatepickerInput from '~/neo-ui/packages/form/packages/form-input/packages/form-datepicker-input/FormDatepickerInput';
import ActiveUsersSearch from '~/wm/packages/account/packages/user/packages/active-users-search/ActiveUsersSearch';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import FormTextareaInputInvisible from '~/neo-ui/packages/form/packages/form-input/packages/form-textarea-input/packages/form-textarea-input-invisible/FormTextareaInputInvisible';
import useTicketFieldAvailabilitiesContext from '~/wm/packages/integration/packages/ticket/context/hooks/useTicketFieldAvailabilitiesContext';
import tooltipStyles from '~/neo-ui/packages/tooltip/styles/tooltipStyles';
import {
  descriptionFieldKeyId,
  titlefieldKeyId,
} from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/TicketCreateForm';
import formatTicketTitleEngagementAction from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/format/formatTicketTitleEngagementAction';
import formatTicketDescriptionEngagementAction from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/format/formatTicketDescriptionEngagementAction';
import TicketCreateButton from '~/wm/packages/integration/packages/ticket/packages/ticket-create-button/TicketCreateButton';
import { useState } from 'react';
import useActiveUsersContext from '~/wm/packages/account/packages/user/context/hooks/useActiveUsersContext';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import { TicketFieldValues } from '~/wm/packages/integration/packages/ticket/types/TicketFieldValues';
import { EngagementActionCreateFormDataType } from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/types/EngagementActionCreateFormDataType';
import { parseISO } from 'date-fns';
import { EngagementActionInitiativeLinkPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import { InitiativeCreateSource } from '~/wm/packages/strategy/model/strategyDataCollectionSources';
import EngagementActionInitiativeLinkCreateButton from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-initiative/packages/engagement-action-initiative-link-create-button/EngagementActionInitiativeLinkCreateButton';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';

export type EngagementActionCreateWindowProps = {
  isOpen: boolean;
  onDismiss: () => void;
  initiativeCreatedFrom: InitiativeCreateSource;
};

const descriptionFieldKey: FieldKeyExpression<EngagementActionCreateFormDataType> = values => values.description;
const dueAtFieldKey: FieldKeyExpression<EngagementActionCreateFormDataType> = values => values.dueAt;
const assignedUserIdsFieldKey: FieldKeyExpression<EngagementActionCreateFormDataType> = values => values.assignedUserIds;

const ticketFieldValuesFieldKey: FieldKeyExpression<EngagementActionCreateFormDataType> = values => values.ticketFieldValues;

const initiativeLinkFieldValuesFieldKey: FieldKeyExpression<EngagementActionCreateFormDataType> = values => values.initiativeLinkPayload;

const EngagementActionCreateWindow = ({ isOpen, onDismiss, initiativeCreatedFrom }: EngagementActionCreateWindowProps) => {
  const { 'lm-action-item-link-initiative': enabledInitiativeActionItem } = useFeatureFlagProvider();
  const { isOrganizationRegisteredForTickets, hasTicketIntegration, ticketFieldOptions } = useTicketFieldAvailabilitiesContext();
  const { organizationName, organizationId } = useOrganizationContext();
  const { users } = useActiveUsersContext();

  const [isTicketModalOpen, setTicketModalOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const { submitForm, isSubmitting, isValid, dirty, getFormInput, setFormInput } = useFormContext<EngagementActionCreateFormDataType>();

  const assignedUserIds = getFormInput<string[]>(assignedUserIdsFieldKey).value;
  const actionDescription = getFormInput<string>(descriptionFieldKey).value;
  const dueAt = getFormInput<string>(dueAtFieldKey).value;
  const selectedUsers = users.filter(user => getFormInput<string[]>(assignedUserIdsFieldKey).value.includes(user.userId));

  return (
    <Window
      title={'Create Action'}
      isOpen={isOpen}
      onDismiss={onDismiss}
      footerProps={{
        rightControls: [
          {
            expanded: (
              <Button
                theme={'primary'}
                onClick={() => {
                  submitForm();
                  onDismiss();
                }}
                loading={isSubmitting}
                disabled={!isValid || !dirty}
              >
                Save
              </Button>
            ),
          },
          {
            expanded: (
              <Button
                onClick={() => {
                  onDismiss();
                }}
              >
                Cancel
              </Button>
            ),
          },
        ],
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 1rem;
            align-items: center;
          `}
        >
          {/**
           * Note: this component is disabled because of a design request for this window.
           * It is here so the user is aware of the functionality they will have after creation.
           */}
          <Button
            iconLeft={'Done'}
            disabled={true}
          >
            Mark as Complete
          </Button>
          <FormDatepickerInput fieldKey={dueAtFieldKey} />
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.3125rem;
            width: 100%;
          `}
        >
          <FormTextareaInputInvisible
            css={css`
              width: 100%;
            `}
            textAreaCss={css`
              min-height: 6.438rem;
            `}
            hasDefaultFocus={true}
            hasDefaultSelect={true}
            /**
             * When this is true, this will cause some inputs to not reset properly (when performing a form reset within a form context)
             * This will need to be false to avoid the above behaviour.
             */
            optimizePerformance={false}
            fieldKey={descriptionFieldKey}
            showFormError={false}
          />

          <TicketCreateButton
            isOpen={isTicketModalOpen}
            hasError={false}
            isCreatingTicket={false}
            buttonComponent={
              <Button
                size={'sm'}
                iconLeft={'IntegrationTicket'}
                theme={isSaved ? 'primary' : undefined}
                preventOnClickPropagation={true}
                css={tooltipStyles('Create ticket')}
                onClick={() => {
                  setTicketModalOpen(true);
                }}
              />
            }
            fieldOptions={ticketFieldOptions}
            isOrganizationRegisteredForTickets={isOrganizationRegisteredForTickets}
            hasTicketIntegration={hasTicketIntegration}
            onCreateTicket={fieldValues => {
              setFormInput<TicketFieldValues>(
                ticketFieldValuesFieldKey,
                fieldValues.reduce<TicketFieldValues>((acc, obj) => {
                  acc[obj.key] = obj.value;
                  return acc;
                }, {}),
              );
              setTicketModalOpen(false);
              setIsSaved(true);
            }}
            onDismiss={() => {
              setTicketModalOpen(false);
            }}
            defaultFormData={{
              ticketFieldValues: {
                [titlefieldKeyId]: formatTicketTitleEngagementAction(actionDescription, ticketFieldOptions),
                [descriptionFieldKeyId]: formatTicketDescriptionEngagementAction(
                  actionDescription,
                  selectedUsers,
                  dueAt !== '' ? new Date(parseISO(dueAt)) : undefined,
                  organizationName,
                  ticketFieldOptions,
                ),
              },
            }}
          />

          {enabledInitiativeActionItem && (
            <EngagementActionInitiativeLinkCreateButton
              size={'sm'}
              onLinkExistingInitiative={initiativeId => {
                setFormInput<EngagementActionInitiativeLinkPayloadDto | undefined>(initiativeLinkFieldValuesFieldKey, {
                  initiativeId,
                  createDefaultPayload: undefined,
                });
              }}
              onLinkNewInitiative={selectedTemplate => {
                setFormInput<EngagementActionInitiativeLinkPayloadDto | undefined>(initiativeLinkFieldValuesFieldKey, {
                  initiativeId: undefined,
                  createDefaultPayload: {
                    organizationId,
                    createdFrom: initiativeCreatedFrom,
                    initiativeTemplateId: selectedTemplate?.initiativeTemplateId,
                  },
                });
              }}
            />
          )}
        </div>

        <ActiveUsersSearch
          inputLabel={{
            icon: 'Users',
            label: 'Assign to:',
          }}
          menuCssPosition={'relative'}
          selectedUserIds={assignedUserIds}
          onSelectedUsersChange={selectedUsers => {
            const selectedUserIds = selectedUsers.map(user => user.userId);
            setFormInput<string[]>(assignedUserIdsFieldKey, selectedUserIds);
          }}
        />
      </div>
    </Window>
  );
};

export default EngagementActionCreateWindow;
