import { scorecardDelete } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useScorecardMutation, { UseScorecardMutationOptions } from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardMutation';

export type UseScorecardDelete = {
  deleteScorecard: (scorecardId: string, isRequestActive?: () => boolean) => void;
  isDeletingScorecard: boolean;
};

const useScorecardDelete = (options?: UseScorecardMutationOptions): UseScorecardDelete => {
  const [deleteScorecard, isDeletingScorecard] = useScorecardMutation(
    (scorecardId: string) =>
      scorecardDelete({
        scorecardId,
      }),
    options,
  );

  return {
    deleteScorecard,
    isDeletingScorecard,
  };
};

export default useScorecardDelete;
