import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Spinner from '~/neo-ui/spinner/Spinner';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import {
  AmexCreditCardIcon,
  DinersClubCreditCardIcon,
  DiscoverCreditCardIcon,
  GeneralCreditCardIcon,
  JCBCreditCardIcon,
  MastercardCreditCardIcon,
  UnionPayCreditCardIcon,
  VisaCreditCardIcon,
} from '~/sp-ui/icons';

type CardIconComponent = React.NamedExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & React.RefAttributes<SVGSVGElement>
>;

type CreditCardIcons = {
  [key: string]: CardIconComponent;
};

const cardIcons: CreditCardIcons = {
  visa: VisaCreditCardIcon,
  mastercard: MastercardCreditCardIcon,
  amex: AmexCreditCardIcon,
  discover: DiscoverCreditCardIcon,
  diners: DinersClubCreditCardIcon,
  jcb: JCBCreditCardIcon,
  unionpay: UnionPayCreditCardIcon,
};

const CustomerCard = () => {
  const { formik } = useMultiStepForm<PurchaseSubscriptionForm>();
  if (!formik.values.paymentCard) {
    return (
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </div>
    );
  }

  const CardIcon: CardIconComponent = cardIcons[formik.values.paymentCard.cardBrand.toLowerCase()] || GeneralCreditCardIcon;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
      `}
    >
      <CardIcon
        css={css`
          width: 2.938rem;
          height: 2rem;
          border: 1px solid ${colorToCode('light-600')};
          border-radius: 0.313rem 0.313rem 0.313rem 0.313rem;
        `}
      />
      <span
        css={css`
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem;
          margin-left: 0.5rem;
        `}
      >
        {formik.values.paymentCard.cardLabel}
      </span>
    </div>
  );
};

export default CustomerCard;
