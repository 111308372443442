import { css } from '@emotion/react';
import InputText from '~/neo-ui/packages/input/packages/input-text/InputText';
import React from 'react';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { Styleable } from '~/neo-ui/model/capacity';
import Theme from '~/neo-ui/packages/color/Theme';
import { simpleFuzzySearch } from '~/neo-ui/packages/select/packages/multi-select-static/packages/multi-select-static-option-search-box/simpleFuzzySearch';

type MultiSelectStaticOptionSearchBoxProps<T extends string> = {
  placeholder?: string;
  allOptions: SelectOption<T>[];
  setVisibleOptions: (visibleOptions: SelectOption<T>[]) => void;
  focusSearch?: boolean;
  theme?: Theme;
} & Styleable;

const MultiSelectStaticOptionSearchBox = <T extends string>({
  className,
  placeholder = 'Find in list...',
  allOptions,
  setVisibleOptions,
  focusSearch = true,
  theme,
}: MultiSelectStaticOptionSearchBoxProps<T>) => {
  const [internalSearchText, setInternalSearchText] = React.useState('');

  const filterSearchInput = (searchTerm: string) => {
    const filterFn = simpleFuzzySearch(searchTerm);
    const updatedList = allOptions.filter(opt => filterFn(opt.label));
    setVisibleOptions(updatedList);
  };
  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalSearchText(e.target.value);
    filterSearchInput(e.target.value);
  };
  const resetSearchOptions = () => {
    setInternalSearchText('');
    setVisibleOptions(allOptions);
  };

  return (
    <div
      className={className}
      css={css`
        padding: 0.375rem 0.625rem;
      `}
    >
      <InputText
        onTextChange={onChangeSearchInput}
        value={internalSearchText}
        placeholder={placeholder}
        onClear={resetSearchOptions}
        theme={theme}
        hasDefaultFocus={focusSearch}
      />
    </div>
  );
};

export default MultiSelectStaticOptionSearchBox;
