/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import FormCheckboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckboxInput';
import FormNumberInput from '~/neo-ui/packages/form/packages/form-input/packages/form-number-input/FormNumberInput';
import FormSelectInput from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import FormTextInput from '~/neo-ui/packages/form/packages/form-input/packages/form-text-input/FormTextInput';
import MockForm from '~/neo-ui/packages/form/test/MockForm';
import DataTable from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';

const StyleResetKitchenSink: React.FunctionComponent = () => (
  <div>
    <LayoutSection
      title={'This is H2'}
      titleSize={2}
    >
      <LayoutSection
        title={'This is H3'}
        titleSize={3}
      >
        <LayoutSection
          title={'This is H4'}
          titleSize={4}
        >
          <LayoutSection
            title={'This is H5'}
            titleSize={5}
          >
            here's some content
          </LayoutSection>
        </LayoutSection>
      </LayoutSection>
    </LayoutSection>
    <div>OTHER!</div>
    <MockForm
      defaultValue={{
        debit: '333',
        credit: '3000',
      }}
    >
      <FormTextInput
        fieldKey={(values: FieldKeyExpressionSegment<any>) => values.text}
        prependCharacter="?"
        placeholder="String"
      />
      <FormNumberInput
        fieldKey={(values: FieldKeyExpressionSegment<any>) => values.number}
        placeholder="Number"
      />

      <FormSelectInput
        fieldKey={(values: FieldKeyExpressionSegment<any>) => values.select}
        options={[{ label: 'Option 1', value: 'a' }]}
      />
      <FormCheckboxInput
        fieldKey={(values: FieldKeyExpressionSegment<any>) => values.boolean}
        description={'Is this true'}
      />
    </MockForm>

    <pre>Pre tag</pre>

    <Label muted={true}>Here's a Label. It's muted!</Label>

    <p className={'text-muted'}>Here's a .text-muted p tag.</p>

    <DataTable
      data={[...new Array(5)].map(() => ({
        name: 'A name',
        rating: 10,
      }))}
      columns={[
        {
          Header: 'Name',
          fieldKey: row => row.name,
          renderCell: row => row.name,
        },
        {
          Header: 'Rating',
          fieldKey: row => row.rating,
          renderCell: ({ rating }) => rating,
        },
      ]}
      EmptyStatePlaceholder={'Table empty state placeholder'}
      sortableColumnKeys={['rating', 'name']}
    />
  </div>
);

export default StyleResetKitchenSink;
