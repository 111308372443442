import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import SearchInputText from '~/neo-ui/packages/input/packages/search-input-text/SearchInputText';

export type TableSearchInputProps = {
  onSearch: (query: string) => void;
  defaultValue?: string;
  disabled?: boolean;
} & Styleable;

const TableSearchInput: React.FunctionComponent<TableSearchInputProps> = ({ onSearch, defaultValue = '', className, disabled = false }) => {
  const [query, setQuery] = React.useState(defaultValue);
  React.useEffect(() => {
    setQuery(defaultValue);
  }, [defaultValue]);

  return (
    <SearchInputText
      className={className}
      onTextChange={evt => {
        setQuery(evt.target.value);
        onSearch(evt.target.value);
      }}
      value={query}
      disabled={disabled}
      onClear={() => {
        setQuery('');
        onSearch('');
      }}
    />
  );
};

export default TableSearchInput;
