import { organizationEngagementNoteArchive } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementNotePanelTabMutation, {
  UseOrganizationEngagementNotePanelTabMutationOptions,
} from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/context/hooks/useEngagementNotePanelTabMutation';
import engagementDataCollectionSources from '~/wm/packages/organization/packages/engagement/model/engagementDataCollectionSources';

export type UseEngagementNoteDashboardArchive = {
  archiveNote: (engagementNoteId: string) => void;
  isArchivingNote: boolean;
};

const useEngagementNoteDashboardArchive = (
  options?: UseOrganizationEngagementNotePanelTabMutationOptions,
): UseEngagementNoteDashboardArchive => {
  const [archiveNote, isArchivingNote] = useEngagementNotePanelTabMutation(
    (engagementNoteId: string) =>
      organizationEngagementNoteArchive({
        payload: {
          engagementNoteId,
          archivedFrom: engagementDataCollectionSources.engagementNoteArchivingSource.clientDashboard,
        },
      }),
    options,
  );

  return {
    archiveNote,
    isArchivingNote,
  };
};

export default useEngagementNoteDashboardArchive;
