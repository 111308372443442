import { getTreeImpactRelatedService, ServiceId } from '../../model/TreeImpactRelatedService';
import * as React from 'react';
import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { css } from '@emotion/react';
import useTreeImpactSummaryGet from '~/wm/packages/tree-impact/hooks/useTreeImpactSummaryGet';
import { Styleable } from '~/neo-ui/model/capacity';
import TreeImpactInfoFooter from '~/wm/packages/tree-impact/packages/tree-impact-info-footer/TreeImpactInfoFooter';
import TreeImpactDataSection from '~/wm/packages/tree-impact/packages/tree-impact-data-section/TreeImpactDataSection';

export type TreeImpactAccountSummaryCardProps = {
  serviceId: ServiceId;
  helpGuideUrl: string;
} & Styleable;

const TreeImpactAccountSummaryCard: React.FunctionComponent<TreeImpactAccountSummaryCardProps> = ({
  serviceId,
  helpGuideUrl,
  className,
}) => {
  const { treeImpactSummary } = useTreeImpactSummaryGet(serviceId);

  if (!treeImpactSummary) {
    return null;
  }
  const serviceInfo = getTreeImpactRelatedService(serviceId);

  return (
    <Box
      borderRadius={'radius400'}
      padding={'padding200'}
      className={className}
    >
      <Header
        size={4}
        weight={'bold'}
        css={css`
          margin-top: 0.125rem;
        `}
      >
        Your total impact
      </Header>
      <div
        css={css`
          margin-top: 1.25rem;
          display: flex;
          flex-wrap: wrap;
          gap: 3.219rem;
        `}
      >
        <TreeImpactDataSection
          css={css`
            flex: 1;
          `}
          icon={'Positive'}
          imageSource={serviceInfo.accountSummaryTreesPlantedImageSource}
          description={serviceInfo.getAccountSummaryTreesPlantedDescription(treeImpactSummary.totalTreesPlanted)}
          displayNumber={treeImpactSummary.totalTreesPlanted}
        />
        <TreeImpactDataSection
          css={css`
            flex: 1;
          `}
          icon={'Positive'}
          imageSource={serviceInfo.accountSummaryUnitsImageSource}
          description={serviceInfo.getAccountSummaryUnitsDescription(treeImpactSummary.totalOrdersUnits)}
          displayNumber={treeImpactSummary.totalOrdersUnits}
        />
      </div>
      <TreeImpactInfoFooter
        helpGuideUrl={helpGuideUrl}
        css={css`
          margin: 1.25rem -0.938rem -0.938rem;
        `}
      />
    </Box>
  );
};

export default TreeImpactAccountSummaryCard;
