import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import * as React from 'react';
import Box from '~/neo-ui/packages/box/Box';
import InitiativeSaveFeesRecurring from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-fees-section/packages/initiative-save-fees-recurring/InitiativeSaveFeesRecurring';
import InitiativeSaveFeesOneTime from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-fees-section/packages/initiative-save-fees-one-time/InitiativeSaveFeesOneTime';
import Divider from '~/neo-ui/packages/divider/Divider';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';

const InitiativeSaveFeesSection = () => {
  const { currencyInfo } = useRegionalSettingsInfoContext();

  return (
    <Box
      padding={'padding100'}
      borderRadius={'radius400'}
      boxShadow={'shadow100'}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.935rem;
      `}
    >
      <div>
        <Header
          size={3}
          weight={'bold'}
          css={css`
            margin-bottom: 0.5rem;
          `}
        >
          Budget
        </Header>
        <Label
          muted={true}
          size={'md'}
        >
          {`Displayed in ${currencyInfo.countryCode} (${currencyInfo.currencySymbol})`}
        </Label>
      </div>
      <InitiativeSaveFeesOneTime />
      <Divider />
      <InitiativeSaveFeesRecurring />
    </Box>
  );
};

export default InitiativeSaveFeesSection;
