import useMutationInternal, {
  UseMutationInternal,
  UseMutationInternalApiCall,
  UseMutationInternalOptions,
} from '~/extensions/packages/hooks/useMutationInternal';
import usePeopleDashboardMutationContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/context/hooks/usePeopleDashboardMutationContext';

export type UsePeopleDashboardMutationOptions = UseMutationInternalOptions;
const usePeopleDashboardMutation = <TArg = Record<string, unknown>>(
  apiCall: UseMutationInternalApiCall<TArg>,
  options?: UsePeopleDashboardMutationOptions,
): UseMutationInternal<TArg> => {
  const { triggerPeopleDashboardReload } = usePeopleDashboardMutationContext();

  return useMutationInternal(apiCall, isRequestActive => triggerPeopleDashboardReload(isRequestActive), options);
};

export default usePeopleDashboardMutation;
