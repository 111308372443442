import React from 'react';
import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';

export type CartIconButtonProps = {
  totalCount: number;
  clientUrl: string;
};

const CartIconButton: React.FunctionComponent<CartIconButtonProps> = ({ totalCount, clientUrl }) => {
  return (
    <Button
      css={css`
        margin-left: auto;
        padding: 0.3rem 0.5rem 0 0.5rem;
      `}
      size={'sm'}
      iconLeft={'Cart'}
      anchor={{
        href: clientUrl,
      }}
    >
      {totalCount}
    </Button>
  );
};

export default CartIconButton;
