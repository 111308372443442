import useMutationInternal, {
  UseMutationInternal,
  UseMutationInternalApiCall,
  UseMutationInternalOptions,
} from '~/extensions/packages/hooks/useMutationInternal';
import useInitiativeAssessmentQuestionListMutationContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-assessment-section/context/hooks/useInitiativeAssessmentQuestionListMutationContext';

export type UseInitiativeAssessmentQuestionListMutation = UseMutationInternalOptions;

const useInitiativeAssessmentQuestionListMutation = <TArg = Record<string, unknown>>(
  apiCall: UseMutationInternalApiCall<TArg>,
  options?: UseInitiativeAssessmentQuestionListMutation,
): UseMutationInternal<TArg> => {
  const { triggerInitiativeAssessmentQuestionListReload } = useInitiativeAssessmentQuestionListMutationContext();

  return useMutationInternal(apiCall, triggerInitiativeAssessmentQuestionListReload, options);
};

export default useInitiativeAssessmentQuestionListMutation;
