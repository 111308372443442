import { css } from '@emotion/react';
import React from 'react';
import { Themeable } from '~/neo-ui/model/capacity';
import FilterHeaderDropdownIcon from '~/neo-ui/packages/filter/packages/filter-headers/filter-header-common/filter-header-dropdown-icon/FilterHeaderDropdownIcon';
import Badge from '~/neo-ui/packages/badge/Badge';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

type FilterHeaderEmptySplitMultiSelectProps = {
  label: string;
  isOpen: boolean;
} & Themeable;

const FilterHeaderEmptySplitMultiSelect: React.FunctionComponent<FilterHeaderEmptySplitMultiSelectProps> = ({ label, isOpen }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      border-radius: 0.375rem 0 0 0.375rem;
    `}
  >
    <div
      css={css`
        padding: 0 0.625rem 0 0.813rem;
        flex: 1 0;
      `}
    >
      {label}
    </div>
    <div
      css={css`
        display: flex;
        flex: 1 0;
        align-items: center;
        border: ${colorToCode('dark-900-12')} solid 0.0625rem;
        padding-right: 0.813rem;
      `}
    >
      <div
        css={css`
          font-size: 0.688rem;
          letter-spacing: 0.088rem;
          padding-inline: 0.375rem;
        `}
      >
        <Badge
          bgColor={'negative-100'}
          textColor={'negative-400'}
          borderRadius={'radius200'}
          fontWeight={'bold'}
        >
          NOT
        </Badge>
      </div>
      <div>{label}</div>
    </div>
    <div
      css={css`
        padding: 0 0.438rem;
      `}
    >
      <FilterHeaderDropdownIcon
        isOpen={isOpen}
        color={'dark-900'}
      />
    </div>
  </div>
);

export default FilterHeaderEmptySplitMultiSelect;
