import { useCallback, useEffect, useState } from 'react';

const useDebouncedInput = (func: (arg: string) => void, timeout: number = 2000) => {
  const [input, setInput] = useState<string>('');

  const clearInput = useCallback(() => {
    setInput('');
    func('');
  }, [func]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      func(input);
    }, timeout);
    return () => clearTimeout(timeoutId);
  }, [input, func, timeout]);

  return {
    input,
    setInput,
    clearInput,
  };
};

export default useDebouncedInput;
