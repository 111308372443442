import { PageEnum } from '@AssetManagementClient/AssetManagement/Packages/Share/Packages/Send/Model.gen';
import { assetSoftwareSearchFiltersAvailable, assetSoftwareSearchRow } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { Filter as AssetManagementClientBeastClientBeastSoftwareControllerFilterSoftwareFilterFactoryNestedEnum_SoftwareGetAvailableFiltersNestedFilter } from '@AssetManagementClient/BeastClient/Beast/Software/Controller/Filter/SoftwareFilterFactoryNested/Enum_/SoftwareGetAvailableFiltersNested.gen';
import { Row, Row as RendererRow } from '@AssetManagementClient/BeastClient/Renderer/Model.gen';
import { RenderCellData } from '@AssetManagementClient/BeastClient/Renderer/Model/Data.gen';
import { NoResultsReason } from '@AssetManagementClient/BeastClient/Search/Model/Query/Result.gen';
import {
  Enum as SoftwareFilterEnums,
  Enum as SoftwareFilterEnum,
} from '@AssetManagementClient/BeastClient/Software/Search/Model/Query/Filter/SoftwareFilterFactoryNested.gen';
import { Enum as SearchContextEnum } from '@AssetManagementClient/BeastClient/Software/Search/Model/Query/SearchContext/SoftwareSearchContextFactoryNested.gen';
import * as React from 'react';
import { camelToPascal, pascalToCamel } from '~/extensions/packages/casing/camelPascalConversion';
import Button from '~/neo-ui/packages/button/Button';
import DownloadSoftwareSpreadsheet from '~/neo-ui/packages/button/packages/action-button/packages/download-software-spreadsheet/DownloadSoftwareSpreadsheet';
import Theme from '~/neo-ui/packages/color/Theme';
import EmptyStateInfo from '~/neo-ui/packages/empty-state-info/EmptyStateInfo';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import { AsyncFilterTable, QueryResult } from '~/neo-ui/packages/table/packages/async-filter-table/AsyncFilterTable';
import buildApiQuery from '~/neo-ui/packages/table/packages/async-filter-table/builder/buildApiQuery';
import buildSortingSet from '~/neo-ui/packages/table/packages/async-filter-table/builder/buildSortingSet';
import { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { Filter, FilterOption, Metadata, SearchContext, TableState } from '~/neo-ui/packages/table/packages/filter-table/FilterTable';
import RenderCell from '~/neo-ui/packages/table/packages/render-cell-data/RenderCell';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import useApi from '~/wm/packages/api/hook/useApi';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';

const mapApiFilter = ({
  key,
  label,
  availableValues,
  searchContexts,
  linkedFilters,
  linkedValues,
  order,
  render,
  defaultOptions,
}: AssetManagementClientBeastClientBeastSoftwareControllerFilterSoftwareFilterFactoryNestedEnum_SoftwareGetAvailableFiltersNestedFilter): Filter => ({
  label,
  value: key,
  options: availableValues.map(
    ({ key, label }): FilterOption => ({
      label,
      value: key,
    }),
  ),
  appliesToContexts: searchContexts,
  linking: (() => {
    if (linkedFilters.length === 0) {
      return undefined;
    }

    const filterOptions = new Map<string, Set<string>>();
    const filterValueCamelCase = pascalToCamel(linkedFilters[0]);
    Object.keys(linkedValues).forEach(productKeyCamelCase => {
      // This is a workaround for WM-1555
      const productKeyPascalCase = camelToPascal(productKeyCamelCase);
      filterOptions.set(productKeyPascalCase, new Set(linkedValues[productKeyCamelCase][filterValueCamelCase as SoftwareFilterEnum]));
    });

    return {
      filterValue: linkedFilters[0] as string,
      linkedFilterOptions: filterOptions,
    };
  })(),
  order,
  render,
  defaultOptions,
});

export type SamConsoleRendererProps = {
  softwareAssetManagementGuideUrl: string;
  frontendScope: FrontendScope;
  userEmail: string;
  inviteMembersUrl: string;
  canInviteMembers: boolean;
  canDownload: boolean;
};

const SamConsoleRenderer: React.FunctionComponent<SamConsoleRendererProps> = ({
  softwareAssetManagementGuideUrl,
  frontendScope,
  userEmail,
  inviteMembersUrl,
  canInviteMembers,
  canDownload,
}) => {
  const softwareAssetManagementGuideLink: AnchorLocation = {
    href: softwareAssetManagementGuideUrl,
    openInNewTab: true,
  };
  const { callApi } = useApi();
  type SamColumn = DataTableColumn<RendererRow>;
  const [tableColumns, setTableColumns] = React.useState<SamColumn[] | []>([]);

  const [noResultsReason, setNoResultsReason] = React.useState<NoResultsReason>(NoResultsReason.ZeroResults);

  const fetchSearchAvailabilities = React.useCallback(async (): Promise<Metadata | 'error'> => {
    const response = await callApi(() =>
      assetSoftwareSearchFiltersAvailable({
        frontendScope,
      }),
    );
    if (!response) {
      return 'error';
    }
    const searchContexts = response.searchContexts;
    const availableFilters = response.filters;
    const scopedFilters = availableFilters.filter(filter => filter.isScopedFilter);
    const filters = availableFilters.filter(filter => !filter.isScopedFilter);

    return {
      filterSet: {
        scopedFilters: scopedFilters.map(mapApiFilter),
        filters: filters.map(mapApiFilter),
        searchContexts: searchContexts.map(
          (searchContext): SearchContext => ({
            label: searchContext.label,
            value: searchContext.enum,
            selectedTheme: searchContext.colorTheme as Theme,
            isSortable: searchContext.isSortable,
            isSearchable: searchContext.isSearchable,
          }),
        ),
      },
      sortingSet: buildSortingSet(response.orderings, false),
      supportsGlobalSearch: true,
    };
  }, [callApi, frontendScope]);

  const fetchResults = React.useCallback(
    async (query: TableState<RendererRow>): Promise<QueryResult<RendererRow>> => {
      const response = await callApi(() =>
        assetSoftwareSearchRow({
          query: buildApiQuery(frontendScope, query),
          searchContext: query.filters!.selectedSearchContextValue as SearchContextEnum,
        }),
      );
      if (!response) {
        return {
          data: [],
          paginationMetadata: {
            totalPages: 0,
            totalResults: 0,
          },
        };
      }
      const tableColumns: SamColumn[] = response.columnDefinitions.map<SamColumn>(({ label, key }) => ({
        Header: label,
        fieldKey: key.value,
        renderCell: row => {
          const data = row.data[pascalToCamel(key.value)] as RenderCellData | undefined;
          if (typeof data === 'undefined') {
            return null;
          }
          return RenderCell({ data });
        },
      }));
      setTableColumns(tableColumns);
      const { results, noResultsReason, paginationMetadata } = response;
      if (noResultsReason) {
        setNoResultsReason(noResultsReason);
      }
      return {
        data: results,
        paginationMetadata: {
          totalResults: paginationMetadata.totalResult,
          totalPages: paginationMetadata.totalPages,
        },
      };
    },
    [callApi, frontendScope],
  );

  const emptyStatePlaceHolder = () => {
    const { title, description } = ((): {
      title: string;
      description: string;
    } => {
      switch (noResultsReason) {
        case NoResultsReason.ZeroResults:
          return {
            title: 'No results found',
            description:
              'You can search for supported software installed or not installed on hardware assets synced from supported integrations.',
          };
        case NoResultsReason.FiltersRequired:
          return {
            title: 'Add filters to find hardware',
            description: 'Filter by publisher, category and more to find hardware assets without matching software installed.',
          };
      }
    })();

    return (
      <EmptyStateInfo
        title={title}
        description={description}
        imageSource={'/i/graphic/software/filter/secondary.png'}
        imageWidth={'7rem'}
      />
    );
  };

  const softwareSpreadsheetDownload = (tableState: TableState<Row>): React.ReactNode => {
    const apiQuery = buildApiQuery<Row, SoftwareFilterEnums>(frontendScope, tableState);
    if (tableState === undefined || tableState.filters === undefined) {
      return;
    }
    if (!canDownload) {
      return (
        <Tooltip
          backgroundColor={'dark-900'}
          placement={'bottom'}
          content={'Upgrade to download'}
        >
          <Button
            iconLeft={'Download'}
            disabled={true}
          />
        </Tooltip>
      );
    }
    return (
      <DownloadSoftwareSpreadsheet
        searchContext={tableState.filters.selectedSearchContextValue as SearchContextEnum}
        frontendScope={frontendScope}
        filters={apiQuery.filters}
        sorts={apiQuery.sort}
        searchPayload={apiQuery.searchPayload}
        tooltipContent={'Download spreadsheet of current view'}
      />
    );
  };

  return (
    <AsyncFilterTable
      filtersTitle="Search for software"
      dataTitle="Results"
      columns={tableColumns}
      defaultSort={{
        key: _ => () => 'Organization.Name',
        order: 'ascending',
      }}
      onFetchMetadata={fetchSearchAvailabilities}
      onFetchData={fetchResults}
      perPageSize={50}
      share={{
        userEmail,
        inviteMembersUrl,
        canInviteMembers,
        pageEnum: PageEnum.SamConsole,
        frontendScope,
      }}
      download={softwareSpreadsheetDownload}
      EmptyStatePlaceholder={emptyStatePlaceHolder}
      enableClientPaginated={'block'}
    />
  );
};

export default SamConsoleRenderer;
