import * as React from 'react';
import { PaymentVersion } from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import useWizardContainerContext from '~/neo-ui/packages/wizard/packages/wizard-container/context/hooks/useWizardContainerContext';
import FormCreditCardChanger from '~/wm/packages/subscription/packages/manage/packages/form-credit-card-changer/FormCreditCardChanger';

export type CreditCardStepSectionProps<T> = {
  creditCardFieldKey: FieldKeyExpression<T>;
  paymentVersion: PaymentVersion;
};

const CreditCardStepSection = <T,>({ creditCardFieldKey, paymentVersion }: CreditCardStepSectionProps<T>) => {
  const { setFieldCompleted } = useWizardContainerContext();

  return (
    <FormCreditCardChanger
      creditCardFieldKey={creditCardFieldKey}
      paymentVersion={paymentVersion}
      onCreditCardDataReady={isReady => {
        // If there's credit card data, unlock next step
        setFieldCompleted(creditCardFieldKey, isReady);
      }}
    />
  );
};

export default CreditCardStepSection;
