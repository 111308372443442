import * as React from 'react';
import ScorecardUrlContext from '~/wm/packages/strategy/packages/scorecard/context/ScorecardUrlContext';

export type ScorecardUrlProviderProps = {
  upgradeUrl: string;
  growUrl: string;
  scorecardViewUrl: string;
  scorecardIndividualViewUrl: string;
  insightViewUrl: string;
};

const ScorecardUrlProvider: React.FunctionComponent<React.PropsWithChildren<ScorecardUrlProviderProps>> = ({
  upgradeUrl,
  growUrl,
  scorecardViewUrl,
  scorecardIndividualViewUrl,
  insightViewUrl,
  children,
}) => (
  <ScorecardUrlContext.Provider
    value={{
      upgradeUrl,
      growUrl,
      scorecardViewUrl,
      scorecardIndividualViewUrl,
      insightViewUrl,
    }}
  >
    {children}
  </ScorecardUrlContext.Provider>
);

export default ScorecardUrlProvider;
