import React from 'react';
import SingleOperatorMultiSelect from '~/neo-ui/packages/select/packages/single-operator-multi-select/SingleOperatorMultiSelect';
import { Filter } from '~/neo-ui/packages/table/packages/console/types';
import { RenderFilterNested } from '@AssetManagementClient/BeastClient/Search/Model/Query/Field/Filter/Render.gen';
import Popover from '~/neo-ui/packages/popover/Popover';
import Theme from '~/neo-ui/packages/color/Theme';
import { FilterDisplayOption, FilterOperatorDisplayOption } from '@AssetManagementClient/BeastClient/Search/Model/Filter.gen';
import { MultiSelectOption } from '~/neo-ui/packages/select/packages/multi-select/MultiSelect';
import { MultiSelectStaticSelection } from '~/neo-ui/packages/select/packages/multi-select-static/MultiSelectStatic';
import Testable from '~/neo-ui/packages/testable/Testable';
import { pascalCaseToSpinalCase } from '~/extensions/packages/casing/pascalSpinalConversion';
import { SingleOperatorMultiSelectHeaderConsoleFormatterType } from '~/neo-ui/packages/filter/packages/filter-multi-select/packages/filter-single-operator-multi-select/SingleOperatorMultiSelectHeaderConsoleFormatter';

export type SingleOperatorMultiSelectFilterProps = {
  filter: Filter;
  render: RenderFilterNested.SingleOperatorMultiSelect;
  parameterValue: Map<string, string[]> | undefined;
  setValue: (key: string, values: Map<string, string[]>) => void;
  headerFormatter: SingleOperatorMultiSelectHeaderConsoleFormatterType;
};

const SingleOperatorMultiSelectFilter: React.FunctionComponent<SingleOperatorMultiSelectFilterProps> = ({
  filter,
  render,
  parameterValue,
  setValue,
  headerFormatter,
}) => {
  let selectedOperator: FilterOperatorDisplayOption | undefined;
  let selectedValues = [] as FilterDisplayOption[];
  let selectionType: string | undefined;
  if (typeof parameterValue !== 'undefined' && parameterValue.size > 0) {
    const tagName = Array.from(parameterValue.keys())[0];
    selectedOperator = render.operatorOptions.find(o => o.filterValue.key === tagName);
    if (typeof selectedOperator !== 'undefined') {
      const values = new Set(parameterValue.get(tagName) ?? []);
      selectedValues = render.valueOptions.filter(v => values.has(v.filterValue.key));
    }
  }

  // Access the url params long as url has parameters
  if (typeof parameterValue !== 'undefined' && parameterValue.size > 0) {
    // selected selection type from url
    const selectionTypePositiveRaw = parameterValue.get(render.selectionTypeLabel) ?? undefined;

    // extract the selection type string
    if (selectionTypePositiveRaw !== undefined && selectionTypePositiveRaw.length > 0) {
      selectionType = selectionTypePositiveRaw[0];
    }
  }

  const setMultiSelectOptions = React.useCallback(
    (operator: string, selection: MultiSelectStaticSelection<string>) => {
      const changedValues = new Map<string, string[]>();
      if (typeof selection.selectedOptions !== 'undefined' && selection.selectedOptions?.length > 0) {
        changedValues.set(operator, selection.selectedOptions);
      }
      if (
        typeof selection.matchType !== 'undefined' &&
        typeof selection.selectedOptions !== 'undefined' &&
        selection.selectedOptions?.length > 0
      ) {
        changedValues.set(render.selectionTypeLabel, [selection.matchType]);
      }
      setValue(filter.key, changedValues);
    },
    [filter.key, render.selectionTypeLabel, setValue],
  );

  const headerCallback = headerFormatter(filter.label, selectedValues, selectedOperator);

  /* Single operator multiselect needs to access the key, the prop "key" is special and not passed.
  Thus, we pass a duplicate, keyCopy. See https://reactjs.org/link/special-props */
  return (
    <div>
      <Testable testId={`asset-console-filter-${pascalCaseToSpinalCase(filter.key)}-popover`}>
        <Popover
          header={headerCallback}
          disabled={!filter.isEnabled}
        >
          <SingleOperatorMultiSelect
            key={filter.key}
            keyCopy={filter.key}
            label={filter.label}
            theme={selectedOperator?.colorTheme as Theme}
            operatorOptions={render.operatorOptions.map(option => ({
              value: option.filterValue.key,
              label: option.filterValue.label,
              selectedTheme: option.colorTheme as Theme,
            }))}
            allValueOptions={render.valueOptions.map(
              option =>
                ({
                  value: option.filterValue.key,
                  label: option.filterValue.label,
                  prependIcon: option.iconName,
                  legacyIconUrl: option.iconUrl,
                } as MultiSelectOption),
            )}
            selectedOperatorOption={selectedOperator}
            selectedValueOptions={selectedValues}
            onChange={setMultiSelectOptions}
            allSelectionTypes={render.selectionTypes}
            selectionType={selectionType}
          />
        </Popover>
      </Testable>
    </div>
  );
};

export default SingleOperatorMultiSelectFilter;
