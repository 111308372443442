import { RenderCellDataNested } from '@AssetManagementClient/BeastClient/Renderer/Model/Data.gen';
import * as React from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color from '~/neo-ui/packages/color/Color.gen';

export type RenderIconCellProps = {
  data: RenderCellDataNested.IconData;
};

const RenderIconCell: React.FunctionComponent<RenderIconCellProps> = ({ data: { iconType, color, sizePx, className } }) => (
  <Icon
    icon={iconType as IconType}
    color={color as Color | undefined}
    sizePx={sizePx}
    className={className}
  />
);

export default RenderIconCell;
