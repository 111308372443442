import { HardwareReplacementSettingsAvailabilities } from '@AssetManagementClient/BeastClient/Beast/HardwareReplacement/Model/Availabilities.gen';
import { object, string, lazy } from 'yup';
import { mapRules } from '~/extensions/packages/validation/mapRules';

const deviceTypePreferenceValidationSchema = object({
  replacementValue: string()
    .matches(/^\+?(0|[1-9]\d*)$/, 'Please enter a valid number')
    .min(0, 'Replacement value may not be less than zero')
    .test(
      'max value',
      'Please enter a valid replacement value',
      value => value !== null && typeof value !== 'undefined' && parseInt(value, 10) <= 2147483647,
    )
    .required('Please enter a replacement value'),
  mspRetirementValues: object().shape({
    assetStorageThreshold: string()
      .nullable()
      .matches(/^\+?(0|[1-9]\d*)$/, 'Please enter a valid number')
      .min(0, 'Threshold may not be less than zero')
      .test(
        'max value',
        'This number is too large',
        value => value === null || typeof value === 'undefined' || parseInt(value, 10) <= 2147483647,
      ),
    assetMemoryThreshold: string()
      .nullable()
      .matches(/^\+?(0|[1-9]\d*)$/, 'Please enter a valid number')
      .min(0, 'Threshold may not be less than zero')
      .test(
        'max value',
        'This number is too large',
        value => value === null || typeof value === 'undefined' || parseInt(value, 10) <= 2147483647,
      ),
  }),
});

const hardwareSettingsValidationSchema = (availabilities: HardwareReplacementSettingsAvailabilities) =>
  object().shape({
    value: object().shape({
      deviceTypePreferences: lazy(() => object(mapRules(availabilities.hardwareTypeAvailabilities, deviceTypePreferenceValidationSchema))),
    }),
  });

export default hardwareSettingsValidationSchema;
