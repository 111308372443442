import { Discriminant } from '@SubscriptionClient/Primitives/Results/EmptyResponse_/PublicError_/ResultNested.gen';
import { BillingAddressDto } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import { Request as AccountBillingAddressUpsertRequest } from '@SubscriptionClient/Subscription/Packages/Upgrade/Controller/AccountBillingAddressUpsertNested.gen';
import { subscriptionBillingAddress, subscriptionBillingInfoGet } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { useCallback, useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

export interface BillingAddressFieldErrors {
  'Address.Line1'?: string;
  'Address.City'?: string;
  'Address.StateCode'?: string;
  'Address.CountryCode'?: string;
  'Address.Zip'?: string;
}

const useBillingAddress = () => {
  const [billingAddress, setBillingAddress] = useState<BillingAddressDto>({
    addressLine1: '',
    addressLine2: '',
    city: '',
    companyName: '',
    countryCode: '',
    phoneNumber: '',
    postalZipCode: '',
    provinceStateCode: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState<BillingAddressFieldErrors>({});
  const { callApi } = useApi();

  const fetchBillingAddress = useCallback(
    () =>
      callApi(async () => {
        setIsLoading(true);

        const response = await subscriptionBillingInfoGet({});
        setIsLoading(false);

        if (!response?.billingInfo?.billingAddressDto) {
          return undefined;
        }

        setBillingAddress(response.billingInfo.billingAddressDto);

        return response.billingInfo.billingAddressDto;
      }),
    [callApi],
  );

  const updateBillingAddress = useCallback(
    (address: AccountBillingAddressUpsertRequest) => {
      return callApi(async () => {
        setIsLoading(true);

        const response = await subscriptionBillingAddress(address);

        setIsLoading(false);

        if (response?.case === Discriminant.Error) {
          setError(response.value.messagePublic);

          if (response.value.fieldErrors?.length) {
            setFieldErrors(response.value.fieldErrors.reduce((a, b) => ({ ...a, [b.key]: b.message }), {}));
          }

          return undefined;
        } else {
          const updatedBillingAddress = await fetchBillingAddress();
          setError(null);
          setFieldErrors({});
          return updatedBillingAddress;
        }
      });
    },
    [fetchBillingAddress, callApi],
  );

  useEffect(() => {
    fetchBillingAddress();
  }, [fetchBillingAddress]);

  return {
    updateBillingAddress,
    fetchBillingAddress,
    billingAddress,
    isLoading,
    error,
    fieldErrors,
  };
};

export type UseBillingAddress = ReturnType<typeof useBillingAddress>;

export default useBillingAddress;
