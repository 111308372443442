import { css } from '@emotion/react';
import React from 'react';
import Toolbar from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import LayoutBar from '~/neo-ui/packages/layout/packages/bar/LayoutBar';
import LayoutHeaderFooterProps from '~/neo-ui/packages/layout/types/LayoutHeaderFooterProps';

/**
 * **DO NOT USE** if there is already an element attached to the `bottom: 0`!
 *
 * Use this to apply a LayoutFooter that deals with the restriction Php places on React components
 *
 * A footer that sticks to the bottom of the viewport.
 *
 * This element will cover up space at the bottom of the screen, the ref will place required margin on #wm-content
 */
const LayoutFooterPhp = ({ className, leftControls, rightControls, layoutBarComponent }: LayoutHeaderFooterProps) => {
  /**
   * Adds required margin to the bottom of the page content
   * to prevent information being hidden at the bottom of the screen
   */
  const footerRef = React.useRef<HTMLDivElement>(null);
  const header = document.getElementById('wm-content');

  React.useEffect(() => {
    if (footerRef.current && header) {
      header.style.marginBottom = `calc(${footerRef.current.clientHeight}px + var(--app-bar-height))`;
    }
  }, [footerRef, header]);

  return (
    <div
      ref={footerRef}
      className={className}
      css={css`
        position: fixed;
        bottom: 0;
        left: 12.5rem;
        @media screen and (max-width: 767px) {
          left: 0;
        }
        right: 0;

        z-index: 1039;
      `}
    >
      {layoutBarComponent && (
        <LayoutBar
          css={css`
            margin-bottom: -0.0625rem;
          `}
        >
          {layoutBarComponent}
        </LayoutBar>
      )}
      {(leftControls || rightControls) && (
        <Toolbar
          leftControls={leftControls}
          rightControls={rightControls}
        />
      )}
    </div>
  );
};

export default LayoutFooterPhp;
