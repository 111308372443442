import * as React from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { css } from '@emotion/react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import Color from '~/neo-ui/packages/color/Color.gen';

export type FormErrorMessageProps<T> = {
  fieldKey: FieldKeyExpression<T>;
  validationErrorColor?: Color;
};

const FormArrayErrorMessage = <T,>({ fieldKey, validationErrorColor = 'negative-400' }: FormErrorMessageProps<T>) => {
  const { getFormInput } = useFormContext<T>();
  const metadata = getFormInput(fieldKey);
  return (
    <div>
      {metadata.touched && typeof metadata.error === 'string' && (
        <Label
          css={css`
            margin-top: 0.23rem;
            font-size: 80%;
            font-weight: 400;
          `}
          color={validationErrorColor}
          size={'sm'}
        >
          {metadata.error}
        </Label>
      )}
    </div>
  );
};

export default FormArrayErrorMessage;
