import usePeopleDashboardMutation, {
  UsePeopleDashboardMutationOptions,
} from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/usePeopleDashboardMutation';
import { organizationAccountTeamMemberRoleAdd } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseOrganizationAccountTeamMemberRoleAdd = {
  addOrganizationAccountTeamMemberRole: (roleLabel: string) => void;
  isAddingOrganizationAccountTeamMemberRole: boolean;
};

const useOrganizationAccountTeamMemberRoleAdd = (options?: UsePeopleDashboardMutationOptions): UseOrganizationAccountTeamMemberRoleAdd => {
  const [addOrganizationAccountTeamMemberRole, isAddingOrganizationAccountTeamMemberRole] = usePeopleDashboardMutation(
    (roleLabel: string) =>
      organizationAccountTeamMemberRoleAdd({
        roleLabel,
      }),
    options,
  );

  return {
    addOrganizationAccountTeamMemberRole,
    isAddingOrganizationAccountTeamMemberRole,
  };
};

export default useOrganizationAccountTeamMemberRoleAdd;
