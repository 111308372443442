import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import * as React from 'react';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import InputTitle, { InputTitleSizes } from '~/neo-ui/packages/input/packages/input-title/InputTitle';
import { css } from '@emotion/react';

export type InputNumberStepperProps = {
  value: number;
  placeholder?: string;
  prependCharacter?: string;
  disabledInput?: boolean;
  disabledButtonRemove?: boolean;
  disabledButtonAdd?: boolean;
  isInvalid?: boolean;
  size?: InputTitleSizes;
  onRemoveClick: () => void;
  onAddClick: () => void;
  onChange: (value: number) => void;
} & Styleable &
  Themeable;

const InputNumberStepper = ({
  value,
  placeholder,
  prependCharacter,
  disabledInput = false,
  disabledButtonRemove = false,
  disabledButtonAdd = false,
  isInvalid = false,
  size,
  theme,
  onRemoveClick,
  onAddClick,
  onChange,
  className,
}: InputNumberStepperProps) => (
  <div
    className={className}
    css={css`
      width: 8.5rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    `}
  >
    <ButtonModifier
      icon={'Remove'}
      onClick={() => {
        onRemoveClick();
      }}
      disabled={disabledButtonRemove}
      css={css`
        margin: 0;
      `}
    />
    <InputTitle
      value={value}
      onChange={value => {
        onChange(Number(value));
      }}
      prependCharacter={prependCharacter}
      placeholder={placeholder}
      disabled={disabledInput}
      isInvalid={isInvalid}
      theme={theme}
      size={size}
    />
    <ButtonModifier
      icon={'Add'}
      onClick={() => {
        onAddClick();
      }}
      disabled={disabledButtonAdd}
      css={css`
        margin: 0;
      `}
    />
  </div>
);

export default InputNumberStepper;
