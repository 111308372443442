import * as React from 'react';
import { DropResult, DragDropContext } from '@hello-pangea/dnd';

export interface DragDropZoneProps {
  onDragEnd: (result: DropResult) => void;
}

const DragDropZone: React.FunctionComponent<React.PropsWithChildren<DragDropZoneProps>> = ({ onDragEnd, children }) => (
  <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>
);

export default DragDropZone;
