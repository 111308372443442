// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


/**
 * All service request types possible are defined here
 * Note: this enum is also referenced by Beast
 * 
 * See {13FBCD02-0095-482A-9E97-2D98704401BB}
 * 
 * Ensure all referenced enums are up to date if adding new entries.
 */
export enum Enum
{
  PartsOnly = "PartsOnly",
  OnSite = "OnSite",
  DepotExpress = "DepotExpress",
}


