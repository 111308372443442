import { strategyAssetSearch } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { Enum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import {
  AssetDatapointPurchaseDateDto,
  AssetDatapointWarrantyExpirationDto,
} from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Dto/Model/DataPoint.gen';

export type AssetSearchResponse = {
  id: string;
  name: string;
  serialNumber: string | undefined;
  modelNumber: string | undefined;
  user: string | undefined;
  manufacturerName: string | undefined;
  purchaseInfo: AssetDatapointPurchaseDateDto | undefined;
  warrantyInfo: AssetDatapointWarrantyExpirationDto | undefined;
};

export type UseAssetSearch = (
  onSearchAssets: (assetSearchResponse: AssetSearchResponse[]) => void,
  organizationId: string,
) => {
  searchAssets: (query: string) => Promise<void>;
};

const useAssetSearch: UseAssetSearch = (onSearchAssets, organizationId) => {
  const { callApi } = useApi();

  const searchAssets = React.useCallback(
    async (query: string) => {
      const response = await callApi(() =>
        strategyAssetSearch({
          query,
          scope: { type: Enum.Organization, organizationId },
        }),
      );
      if (!response) {
        return;
      }
      const { results } = response;

      onSearchAssets(results);
    },
    [callApi, onSearchAssets, organizationId],
  );

  return {
    searchAssets,
  };
};

export default useAssetSearch;
