// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


export enum Enum
{
  /**
   * Good to know , something that need to be addressed at some point.
   */
  Low = "Low",
  /**
   * Something to keep an eye on.
   */
  Medium = "Medium",
  /**
   * Requires immediate actions.
   */
  High = "High",
}


