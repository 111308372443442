import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { InitiativeSaveFormData } from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import FormTitleInput from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/FormTitleInput';
import FormTextareaInput from '~/neo-ui/packages/form/packages/form-input/packages/form-textarea-input/FormTextareaInput';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';

const nameFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.name;
const executiveSummaryFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.executiveSummary;

const InitiativeSaveDetailsSection = () => {
  return (
    <Box
      padding={'padding100'}
      borderRadius={'radius400'}
      boxShadow={'shadow100'}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.935rem;
      `}
    >
      <FormTitleInput
        fieldKey={nameFieldKey}
        placeholder={'Enter a title...'}
        size={'lg'}
        optimizePerformance={true}
      />
      <LayoutSection
        title={'Executive summary'}
        titleSize={4}
        titleWeight={'bold'}
      >
        <FormTextareaInput
          fieldKey={executiveSummaryFieldKey}
          placeholder={'Write an executive summary for your client...'}
        />
      </LayoutSection>
    </Box>
  );
};

export default InitiativeSaveDetailsSection;
