import * as React from 'react';
import { useEffect } from 'react';

export const useOnClickOutside = (refs: React.RefObject<HTMLDivElement | HTMLButtonElement>[], onClickOutside: () => void) => {
  const handleClick = (event: Event) => {
    const isNotTargets = refs.every(element => element.current && !element.current.contains(event.target as Node));

    if (isNotTargets) {
      onClickOutside();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  });
};
