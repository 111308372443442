import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { subscriptionPricingDtoGet } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { PricingDto } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/PricingModel.gen';

const usePricingDtoGet = () => {
  const [isLoading, setLoading] = useState(true);
  const [pricingDto, setPricingDto] = useState<PricingDto>();

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => subscriptionPricingDtoGet({}));
      setLoading(false);

      if (!response) {
        return;
      }

      setPricingDto(response.pricingDto);
    })();
  }, [callApi]);

  return { isLoading, pricingDto };
};

export default usePricingDtoGet;
