import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Button from '~/neo-ui/packages/button/Button';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import ButtonGroup from '~/neo-ui/packages/button/packages/button-group/ButtonGroup';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { InitiativeSaveFormData } from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import InitiativeSaveChangeButton from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-change-button/InitiativeSaveChangeButton';
import useInitiativeAssetSelectContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/context/hooks/useInitiativeAssetSelectContext';

export type InitiativeSaveAssetRemoveSelectButtonGroupProps = { initiativeId?: string } & Styleable;

const assetIdsFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.assetIds;

const InitiativeSaveAssetRemoveSelectButtonGroup = ({ initiativeId, className }: InitiativeSaveAssetRemoveSelectButtonGroupProps) => {
  const { setFormInput, formData } = useFormContext<InitiativeSaveFormData>();

  const { selectedAssetIds, clearSelectedAssetIds, displayedAssetIds, setDisplayedAssetIds } = useInitiativeAssetSelectContext();

  const canModifyAssets = selectedAssetIds.size > 0;

  return (
    <ButtonGroup
      css={css`
        display: flex;
        align-items: center;
      `}
      className={className}
    >
      <div
        css={css`
          padding: 0.3125rem 1.25rem;
          min-height: 2.875rem;
          background-color: ${colorToCode(canModifyAssets ? 'primary-400' : 'light-200')};
          border-radius: 0.625rem;
          display: flex;
          align-items: center;
        `}
      >
        <ButtonModifier
          onClick={clearSelectedAssetIds}
          icon={'Bad'}
          disabled={!canModifyAssets}
        />
        {canModifyAssets ? (
          <Label color={'light-000'}>
            {selectedAssetIds.size} asset{selectedAssetIds.size !== 1 && 's'} selected
          </Label>
        ) : (
          <Label muted={true}>Select assets to manage</Label>
        )}
      </div>
      {canModifyAssets ? (
        <div
          css={css`
            padding: 0.3125rem;
            background-color: ${colorToCode('light-200')};
            border-radius: 0.3125rem;
            display: flex;
            align-items: center;
          `}
        >
          <Button
            iconLeft={'Remove'}
            onClick={() => {
              setDisplayedAssetIds(displayedAssetIds.filter(assetId => !selectedAssetIds.has(assetId)));

              const currentAssetIds = formData.assetIds;
              setFormInput<string[]>(
                assetIdsFieldKey,
                currentAssetIds.filter(assetId => !selectedAssetIds.has(assetId)),
              );

              clearSelectedAssetIds();
            }}
          >
            Remove
          </Button>
          <InitiativeSaveChangeButton
            initiativeId={initiativeId}
            initiativeAssetIds={Array.from(selectedAssetIds)}
            onUpdateAssets={() => {
              setDisplayedAssetIds(displayedAssetIds.filter(assetId => !selectedAssetIds.has(assetId)));

              const currentAssetIds = formData.assetIds;
              setFormInput<string[]>(
                assetIdsFieldKey,
                currentAssetIds.filter(assetId => !selectedAssetIds.has(assetId)),
              );

              clearSelectedAssetIds();
            }}
          />
        </div>
      ) : (
        <React.Fragment />
      )}
    </ButtonGroup>
  );
};

export default InitiativeSaveAssetRemoveSelectButtonGroup;
