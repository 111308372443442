import { boolean, object, string } from 'yup';
import { Enum as InputTypeEnum } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Credential/InputTypeNested.gen';
import { SanitizedCredentialFieldDisplaySettingsDto } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/packages/credential-display-modes/packages/credential-form/IntegrationCredentialForm';

// Creates a schema using the different credential fields
const integrationCredentialFormValidationSchema = (credentialFields: { [key in string]: SanitizedCredentialFieldDisplaySettingsDto }) => {
  const inputToSchemaType = (credentialFieldDto: SanitizedCredentialFieldDisplaySettingsDto) => {
    switch (credentialFieldDto.inputType.type) {
      case InputTypeEnum.Toggle:
        return boolean();
      case InputTypeEnum.Select:
        return string();
      case InputTypeEnum.InteractiveOAuth:
        return string();
      case InputTypeEnum.Text:
        return string();
      case InputTypeEnum.TextSecure:
        return string();
      default:
        return string();
    }
  };
  type MixedSchemaType = ReturnType<typeof inputToSchemaType>;

  // Populate the validation schema fields with the credential inputs
  const schemaObject = Object.fromEntries(
    Object.keys(credentialFields).map(credentialFieldKey => [
      credentialFields[credentialFieldKey].name,
      inputToSchemaType(credentialFields[credentialFieldKey]).when([], {
        is: () => credentialFields[credentialFieldKey].isInputRequired,
        then: (schema: MixedSchemaType) => schema.required(credentialFields[credentialFieldKey].label + '  is a required field'),
        otherwise: (schema: MixedSchemaType) => schema.notRequired(),
      }),
    ]),
  );
  const credentialSchema = object().shape(schemaObject);

  // TODO: return the form submission request
  return Object.keys(credentialFields).length > 0 ? credentialSchema : undefined;
};

export default integrationCredentialFormValidationSchema;
