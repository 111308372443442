import { useDispatch } from 'react-redux';
import { showAlertAction } from '~/legacy-ui/packages/alert/state/action/alertActions';
import useModal from '~/legacy-ui/packages/modal/packages/alert-modal/hooks/useModal';
import buildOperation, { Contextable, OperationDefinition } from '../model/buildOperation';
import Workflow, { WorkflowContext } from '../model/Workflow';
import progressWorkflow from '../packages/progress/progressWorkflow';
import sensitivityWorkflow from '../packages/sensitivity/sensitivityWorkflow';

/**
 * Registered workflow handlers in order of precedence
 *
 * Example: execute operation -> handle confirmation -> handle progress -> action
 */
const workflowHandlers: Workflow[] = [sensitivityWorkflow, progressWorkflow];

/**
 * Hook providing the execute function for a specified operation.
 *
 * @param operationDefinition Definition to consume
 * @deprecated provided experimental functionality that we no longer need
 */
const useOperation = <TContext,>(operationDefinition: OperationDefinition<TContext>) => {
  const dispatch = useDispatch();
  const showModal = useModal();
  const operation = buildOperation(operationDefinition);

  return async (context: TContext, onActionInitiated?: () => void) => {
    const substituteContext = <T,>(contextable: Contextable<T, TContext>) =>
      contextable instanceof Function ? contextable(context) : contextable;

    const workflowContext: WorkflowContext<TContext> = {
      operation,
      dispatch,
      showModal,
      context,
      substituteContext,
    };

    // initiate workflows
    const error = await workflowHandlers.reduce((acc, current) => (ctx, next) => acc(ctx, () => current(ctx, next)))(
      workflowContext,
      async () => {
        if (onActionInitiated) {
          onActionInitiated();
        }
        return operation.action(context);
      },
    );

    if (typeof error !== 'undefined') {
      dispatch(
        showAlertAction({
          heading: error.title,
          children: error.message,
          theme: 'danger',
        }),
      );
    }
  };
};

export default useOperation;
