import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import useWarrantyTreeImpactAccountSummaryGet from '../../hooks/useWarrantyTreeImpactAccountSummaryGet';
import Spinner from '~/neo-ui/spinner/Spinner';
import TreeImpactOrderPotentialCard from '~/wm/packages/tree-impact/packages/tree-impact-order-potential-card/TreeImpactOrderPotentialCard';

export type WarrantyTreeImpactOrderPotentialCardProps = {
  totalOrderUnits: number;
  helpGuideUrl: string;
} & Styleable;

const WarrantyTreeImpactOrderPotentialCard: React.FunctionComponent<WarrantyTreeImpactOrderPotentialCardProps> = ({
  totalOrderUnits,
  helpGuideUrl,
  className,
}) => {
  const treeImpactSummary = useWarrantyTreeImpactAccountSummaryGet();

  if (typeof treeImpactSummary === 'undefined') {
    return <Spinner />;
  }

  return (
    <TreeImpactOrderPotentialCard
      className={className}
      serviceId={'warranty'}
      totalOrderUnits={totalOrderUnits}
      treesPerOrderUnit={treeImpactSummary.treesPerOrderUnit}
      totalTreesPlanted={treeImpactSummary.totalTreesPlanted}
      helpGuideUrl={helpGuideUrl}
    />
  );
};

export default WarrantyTreeImpactOrderPotentialCard;
