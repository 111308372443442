import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Button, { ButtonProps } from '~/neo-ui/packages/button/Button';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import * as React from 'react';
import { ReactNode } from 'react';
import { Themeable } from '~/neo-ui/model/capacity';
import Color from '~/neo-ui/packages/color/Color.gen';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';

export type ToastContentProps = {
  /**
   * Short title describing the toast
   */
  title: string;
  /**
   * The body of the toast
   */
  description: ReactNode;
  /**
   * Icon associated with the toast
   */
  icon: IconType;
  /**
   * Button on the right
   */
  buttonRight?: ButtonProps;
  /**
   * Button on the left
   */
  buttonLeft?: ButtonProps;
  /**
   * Override color of the fonts
   */
  fontColorOverride?: Color;
} & Themeable;

const ToastContent = ({ title, description, icon, buttonRight, buttonLeft, fontColorOverride, theme }: ToastContentProps) => {
  const { themeMap } = useTheme(theme);
  const fontColor = fontColorOverride ? fontColorOverride : theme ? themeMap.foregroundAccent : 'dark-900';

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          word-break: break-word;
          gap: 0.3125rem;
          padding: 0.3125rem;
        `}
      >
        <Icon
          icon={icon}
          sizePx={20}
          color={fontColor}
          css={css`
            margin-bottom: 0.3125rem;
          `}
        />
        <Header
          size={5}
          color={fontColor}
        >
          {title}
        </Header>
        <Label color={fontColorOverride ? fontColorOverride : 'dark-300'}>{description}</Label>
      </div>
      {buttonLeft || buttonRight ? (
        <div
          css={css`
            gap: 0.625rem;
            display: flex;
            align-items: center;
          `}
        >
          {buttonLeft && (
            <Button
              {...buttonLeft}
              size={'sm'}
            />
          )}
          {buttonRight && (
            <Button
              {...buttonRight}
              size={'sm'}
              css={css`
                margin-left: auto;
              `}
            />
          )}
        </div>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

export default ToastContent;
