import { deviceReportCustomerPdf } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

type LifecycleReportAdditionalColumnsPayload = {
  showRam: boolean;
  showCpu: boolean;
  showStorage: boolean;
  showLastCheckInDate: boolean;
};

type UseDownloadLifecycleReportPdf = (onSuccess?: () => void) => {
  downloadLifecycleReport: (
    organizationId: string,
    additionalColumns: LifecycleReportAdditionalColumnsPayload | undefined,
    shouldSaveDefaultSettings: boolean,
  ) => void;
  isLoading: boolean;
};

export const useDownloadLifecycleReportPdf: UseDownloadLifecycleReportPdf = onSuccess => {
  const { callApi } = useApi();
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const downloadLifecycleReport = React.useCallback(
    async (
      organizationId: string,
      additionalColumns: LifecycleReportAdditionalColumnsPayload | undefined,
      shouldSaveDefaultSettings: boolean,
    ) => {
      setLoading(true);

      await callApi(() =>
        deviceReportCustomerPdf({
          organizationId,
          additionalColumns,
          shouldSaveAdditionalColumnsAsHardwareLifecycleReportSettingDefaults: shouldSaveDefaultSettings,
        }),
      );

      setLoading(false);
      if (typeof onSuccess !== 'undefined') {
        onSuccess();
      }
    },
    [callApi, onSuccess],
  );

  return { downloadLifecycleReport, isLoading };
};

export default useDownloadLifecycleReportPdf;
