import * as React from 'react';
import useDisposalOrderInfoContext from '~/wm/packages/disposal/packages/order-info/context/hooks/useDisposalOrderInfoContext';
import DisposalOrderShipmentSectionSchedule from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-shipment-section-schedule/DisposalOrderShipmentSectionSchedule';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderType/Model/DisposalOrderTypeFactoryNested.gen';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import DisposalOrderPickupForm from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-shipment-section-schedule/packages/disposal-order-pickup-form/DisposalOrderPickupForm';
import DisposalOrderPickupScheduleSection from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-shipment-section-schedule/packages/disposal-order-pickup-schedule-section/DisposalOrderPickupScheduleSection';
import Icon from '~/neo-ui/packages/icon/Icon';
import { formatDateFriendly } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';
import Button from '~/neo-ui/packages/button/Button';
import useDisposalOrderPickupCancel from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderPickupCancel';
import Anchor from '~/neo-ui/packages/anchor/Anchor';
import ToggleTileHorizontal from '~/neo-ui/packages/toggle/packages/toggle-tile/packages/toggle-tile-horizontal/ToggleTileHorizontal';

type ShipmentTileOptions = 'dropoff' | 'pickup';

const DisposalOrderShipmentModule = () => {
  const { disposalOrderSummary } = useDisposalOrderInfoContext();
  const [isEditing, setIsEditing] = React.useState(false);
  const [selectedShipmentTileOption, setSelectedShipmentTileOption] = React.useState<ShipmentTileOptions>('dropoff');

  const { cancelPickup, isCancelling } = useDisposalOrderPickupCancel();

  if (typeof disposalOrderSummary?.completedAt !== 'undefined') {
    return null;
  }

  /**
   * Should be behind bulk shipping flag but adding it breaks type inference
   * for the lines with this guid. Will still work backwards compatibly considering
   * the order type will be not 'ItadBulk' even if disposalOrderSummary is not undefined.
   *
   * 86b7ee9c-3db7-46a2-af8b-f242a35f9a8d
   */
  if (typeof disposalOrderSummary !== 'undefined' && disposalOrderSummary?.orderType === Enum.ItadBulk) {
    return null;
  }

  return (
    <React.Fragment>
      {typeof disposalOrderSummary !== 'undefined' && disposalOrderSummary.isAllShippingLabelsCreated ? (
        isEditing ? (
          <DisposalOrderShipmentSectionSchedule
            disposalOrderId={disposalOrderSummary.disposalOrderId}
            onCancelEdit={() => {
              setIsEditing(false);
            }}
            disposalOrderPickupDetails={disposalOrderSummary.disposalOrderPickupDetails}
          />
        ) : typeof disposalOrderSummary.disposalOrderPickupDetails === 'undefined' ? (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 1.5rem;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
              `}
            >
              <Label
                bold={true}
                size={'md'}
              >
                Choose a shipping option
              </Label>
              <ToggleTileHorizontal
                type={'single'}
                title={'Drop off'}
                description={
                  <Label
                    color={'dark-700'}
                    size={'sm'}
                  >
                    Bring your package
                    {disposalOrderSummary.packageCount === 1 ? '' : 's'} to your
                    <Anchor
                      href={disposalOrderSummary.shippingCarrierInfo.dropOffLocationsUrl}
                      size={'sm'}
                      shouldOpenInNewTab={true}
                      stopPropagation={true}
                    >
                      {' '}
                      nearest {disposalOrderSummary.shippingCarrierInfo.displayName} location.
                    </Anchor>
                  </Label>
                }
                icon={'Dropoff'}
                onClick={() => {
                  setSelectedShipmentTileOption('dropoff');
                }}
                isSelected={selectedShipmentTileOption === 'dropoff'}
              />
              <ToggleTileHorizontal
                disabled={
                  typeof disposalOrderSummary.availableOperations !== 'undefined' &&
                  !disposalOrderSummary.availableOperations.canSwitchBetweenDropOffAndPickUp
                }
                alert={
                  typeof disposalOrderSummary.availableOperations !== 'undefined' &&
                  !disposalOrderSummary.availableOperations.canSwitchBetweenDropOffAndPickUp
                    ? {
                        text: 'Pickup isn’t available for your shipping address',
                        color: 'caution-400',
                      }
                    : undefined
                }
                type={'single'}
                title={'Pickup'}
                description={`Schedule a pickup with ${disposalOrderSummary.shippingCarrierInfo.displayName}`}
                icon={'Pickup'}
                onClick={() => setSelectedShipmentTileOption('pickup')}
                isSelected={selectedShipmentTileOption === 'pickup'}
              />
            </div>
            {selectedShipmentTileOption === 'pickup' && (
              <DisposalOrderPickupForm
                disposalOrderId={disposalOrderSummary.disposalOrderId}
                onCancelEdit={() => {
                  setIsEditing(false);
                }}
                disposalOrderPickupDetails={disposalOrderSummary.disposalOrderPickupDetails}
              >
                <DisposalOrderPickupScheduleSection
                  onCancelEdit={() => {
                    setIsEditing(false);
                    // Set to dropoff only if the user has not scheduled a pickup
                    if (typeof disposalOrderSummary.disposalOrderPickupDetails === 'undefined') {
                      setSelectedShipmentTileOption('dropoff');
                    }
                  }}
                />
              </DisposalOrderPickupForm>
            )}
          </div>
        ) : (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.5rem;
            `}
          >
            <Icon
              sizePx={24}
              icon={'StatusPositive'}
              color={'positive-400'}
            />
            <div
              css={css`
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
              `}
            >
              <Label
                size={'md'}
                color={'positive-400'}
              >
                <b>{disposalOrderSummary.shippingCarrierInfo.displayName}</b> will arrive for pickup on{' '}
                <b>{formatDateFriendly(parseISO(disposalOrderSummary.disposalOrderPickupDetails.scheduledAt))}</b>
              </Label>
              <Label color={'dark-900-64'}>Please have the packages packed and labeled for pickup</Label>
            </div>
            <div
              css={css`
                margin-left: auto;
                display: flex;
                gap: 0.625rem;
              `}
            >
              <Button
                iconLeft={'Pickup'}
                disabled={isCancelling}
                onClick={() => setIsEditing(true)}
              >
                Reschedule...
              </Button>
              <Button
                iconLeft={'Cancel'}
                loading={isCancelling}
                disabled={isCancelling}
                onClick={() => {
                  setSelectedShipmentTileOption('dropoff');
                  cancelPickup(disposalOrderSummary.disposalOrderId);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        )
      ) : null}
    </React.Fragment>
  );
};

export default DisposalOrderShipmentModule;
