import { SingleSelectStyleProps } from './SingleSelectStyleDefault';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export const customStylesTransparent = ({ height = undefined }: SingleSelectStyleProps) => ({
  menu: (provided: Record<string, unknown>) => ({
    ...provided,
    padding: '0.625rem',
    borderRadius: '0.625rem',
  }),

  control: (provided: Record<string, unknown>) => ({
    ...provided,
    ...(typeof height !== 'undefined' && {
      minHeight: height,
      height,
    }),
    borderStyle: 'none',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&:hover': {
      backgroundColor: colorToCode('light-300'),
      cursor: 'pointer',
    },
  }),
});
