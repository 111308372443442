import { contractDelete } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useContractListMutation, { UseContractListMutationOptions } from './useContractListMutation';

export type UseContractDelete = {
  deleteContract: (contractId: string, isRequestActive?: () => boolean) => void;
  isDeletingContract: boolean;
};

const useContractDelete = (organizationId: string, options?: UseContractListMutationOptions): UseContractDelete => {
  const [deleteContract, isDeletingContract] = useContractListMutation(
    (contractId: string) =>
      contractDelete({
        contractId,
        organizationId,
      }),
    options,
  );

  return {
    deleteContract,
    isDeletingContract,
  };
};

export default useContractDelete;
