import { css, SerializedStyles } from '@emotion/react';
import React, { useRef, useState } from 'react';
import { useOnClickOutside } from '~/extensions/packages/hooks/useOnClickOutside';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import { ButtonProps } from '~/neo-ui/packages/button/Button';
import { ButtonInternalRef, buttonSizeToButtonDisplayDetails } from '~/neo-ui/packages/button/ButtonInternal';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import Testable from '~/neo-ui/packages/testable/Testable';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import ButtonType, { ButtonTypeComponent } from '~/neo-ui/packages/button/types/ButtonType';

export type DropdownOption<T extends string = string> = {
  label?: string;
  value: T;
  icon?: IconType;
  iconColor?: Color;
  disabled?: boolean;
};

export type DropdownProps<T extends string = string> = {
  selectedOption?: DropdownOption<T>;

  /**
   * Options may be a 2d array or 3d array
   * A 3d array will flatten all options into a 2d array with <hr>'s between them
   */
  options: DropdownOption<T>[] | DropdownOption<T>[][];
  onOptionSelected?: (option: DropdownOption<T>) => void;
  disabled?: boolean;
  /**
   * This is useful in cases where you don't want
   * the button press to trigger any upstream onClick event.
   *
   * Common example: You have a button nested within a clickable element.
   */
  preventOnClickPropagation?: boolean;
  /**
   * Placeholder string that shows when there is no selectedOption
   */
  placeholder?: string;
  /**
   * On mouse enter event that emits from the dropdown menu
   */
  onMouseEnterDropdown?: () => void;
  /**
   * On mouse leave event that emits from the dropdown menu
   */
  onMouseLeaveDropdown?: () => void;
  /**
   * Custom css for the button
   */
  buttonCss?: SerializedStyles;
  /**
   * The button used to render the dropdown
   */
  buttonDropdown?: ButtonType;
  dropdownMenuCss?: SerializedStyles;
} & Styleable &
  Themeable &
  Pick<ButtonProps, 'size'>;

const Dropdown = <T extends string = string>({
  selectedOption,
  options,
  theme,
  onOptionSelected,
  disabled = false,
  preventOnClickPropagation = false,
  placeholder = 'Select...',
  className,
  onMouseEnterDropdown,
  onMouseLeaveDropdown,
  buttonCss,
  size: buttonSize = 'md',
  buttonDropdown = 'button',
  dropdownMenuCss,
}: DropdownProps<T>) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggle = () => setIsDropdownOpen(prevState => !prevState);

  const closeDropdown = () => setIsDropdownOpen(false);
  const buttonRef = React.createRef<ButtonInternalRef>();
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside([buttonRef, dropdownRef], closeDropdown);

  const displayDetails = buttonSizeToButtonDisplayDetails(buttonSize);

  // Flatten options into jsx components
  const flattenedOptions = React.useMemo(() => {
    const optionsToButtons = (options: DropdownOption<T>[]) =>
      options.map((option, index) => (
        <Testable
          testId={'dropdown-option'}
          key={index}
        >
          <button
            css={css`
              display: inline-flex;
              flex-direction: row;
              align-items: center;
              gap: 0.58rem;
              padding: 0.25rem 1rem;
              width: 100%;
              clear: both;
              font-weight: 400;
              color: ${colorToCode('dark-500')};
              text-align: inherit;
              white-space: nowrap;
              background-color: transparent;
              border: 0;

              &:first-child {
                @include border-top-radius(0);
              }

              &:last-child {
                @include border-bottom-radius(0);
              }

              @include hover-focus {
                text-decoration: none;
                @include gradient-bg(${colorToCode('light-500')});
              }

              &:active {
                text-decoration: none;
                @include gradient-bg(${colorToCode('primary-050')});
              }

              &:disabled {
                color: ${colorToCode('light-800')};
                pointer-events: none;
                background-color: transparent;
                background-image: none;
              }
            `}
            className="dropdown-item"
            type={'button'}
            onClick={e => {
              if (preventOnClickPropagation) {
                e.stopPropagation();
              }
              if (onOptionSelected) {
                closeDropdown();
                onOptionSelected(option);
              }
            }}
            disabled={option.disabled}
          >
            {option.icon && (
              <Icon
                icon={option.icon}
                color={option.iconColor}
                sizePx={displayDetails.iconSizePx}
              />
            )}
            {option.label && (
              <div
                css={css`
                  font-size: ${displayDetails.fontSizeRem}rem;
                `}
              >
                {option.label}
              </div>
            )}
          </button>
        </Testable>
      ));

    // Flatten option groups into options with hrs between them
    if ((options as DropdownOption<T>[][]).some(element => Array.isArray(element))) {
      return (options as DropdownOption<T>[][])
        .map((group, index) =>
          options.length - 1 <= index ? (
            <div key={index}>{optionsToButtons(group)}</div>
          ) : (
            <div key={index}>
              {optionsToButtons(group)}
              <hr
                css={css`
                  width: 100%;
                  margin: 0.3125rem 0;
                  border-top: 0.0625rem solid ${colorToCode('dark-900-24')};
                `}
              />
            </div>
          ),
        )
        .flat();
    }

    // Return regular array if no groups
    return optionsToButtons(options as DropdownOption<T>[]);
  }, [displayDetails.fontSizeRem, displayDetails.iconSizePx, onOptionSelected, options, preventOnClickPropagation]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const ButtonComponent = ButtonTypeComponent(buttonDropdown);

  return (
    <div
      css={css`
        width: fit-content;
        position: relative;
      `}
      className={className}
    >
      <ButtonComponent
        ref={buttonRef}
        size={buttonSize}
        theme={theme}
        disabled={disabled}
        onClick={(e: { stopPropagation: () => void }) => {
          if (preventOnClickPropagation) {
            e.stopPropagation();
          }
          toggle();
        }}
        iconRight={'ArrowDown'}
        css={[
          css`
            width: inherit;
            height: inherit;
          `,
          buttonCss,
        ]}
      >
        <Testable testId={'dropdown-selected-option'}>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.5rem;
            `}
          >
            {!selectedOption && (
              <div
                css={css`
                  font-size: ${displayDetails.fontSizeRem}rem;
                  color: ${colorToCode('dark-050')};
                `}
              >
                {placeholder}
              </div>
            )}
            {selectedOption?.icon && (
              <Icon
                icon={selectedOption.icon}
                sizePx={displayDetails.iconSizePx}
                color={selectedOption.iconColor}
              />
            )}
            {selectedOption?.label && (
              <div
                css={css`
                  font-size: ${displayDetails.fontSizeRem}rem;
                `}
              >
                {selectedOption.label}
              </div>
            )}
          </div>
        </Testable>
      </ButtonComponent>
      {isDropdownOpen && (
        <div
          onMouseEnter={() => {
            if (onMouseEnterDropdown) {
              onMouseEnterDropdown();
            }
          }}
          onMouseLeave={() => {
            if (onMouseLeaveDropdown) {
              onMouseLeaveDropdown();
            }
          }}
          css={[
            css`
              min-width: unset !important;
              position: absolute;
              z-index: 1000;
              top: 100%;
              left: 0;
              float: left;
              padding: 0.5rem 0;
              margin: 0.125rem 0 0;
              font-size: 0.875rem;
              color: #212529;
              text-align: left;
              list-style: none;
              background-color: #fff;
              background-clip: padding-box;
              border: 0.0625rem solid ${colorToCode('dark-900-12')};
              border-radius: 0.25rem;
            `,
            dropdownMenuCss,
          ]}
          ref={dropdownRef}
        >
          {flattenedOptions}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
