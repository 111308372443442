import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { coverageTransferDisposalOrderCertificatePdfGet } from '@WarrantyClient/WarrantyClientMsp.gen';

type UseDownloadCoverageTransferDisposalOrderCertificatePdf = () => {
  downloadCoverageTransferDisposalCertificatePdf: (organizationName: string, disposalOrderId: string, assetSerialNumber: string) => void;
};

export const useDownloadCoverageTransferDisposalOrderCertificatePdf: UseDownloadCoverageTransferDisposalOrderCertificatePdf = () => {
  const { callApi } = useApi();

  const downloadCoverageTransferDisposalCertificatePdf = React.useCallback(
    async (organizationName: string, disposalOrderId: string, assetSerialNumber: string) => {
      const stream = await callApi(() =>
        coverageTransferDisposalOrderCertificatePdfGet({
          organizationName,
          disposalOrderId,
          assetSerialNumber,
        }),
      );

      if (!stream) {
        return;
      }
    },
    [callApi],
  );

  return { downloadCoverageTransferDisposalCertificatePdf };
};

export default useDownloadCoverageTransferDisposalOrderCertificatePdf;
