import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Spinner from '~/neo-ui/spinner/Spinner';

export type FormSubmitButtonProps = {
  disabled?: boolean;
  label: string;
  icon?: IconType;
};

const FormSubmitButton: React.FunctionComponent<FormSubmitButtonProps> = ({ disabled: manuallyDisabled, label, icon }) => {
  const { isSubmitting, submitForm } = useFormContext();

  const disabled = isSubmitting || manuallyDisabled;

  return (
    <Button
      disabled={disabled}
      size={'lg'}
      theme={'positive'}
      onClick={submitForm}
      {...(icon && { iconLeft: icon })}
    >
      {!isSubmitting ? label : <Spinner />}
    </Button>
  );
};

export default FormSubmitButton;
