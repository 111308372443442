import usePeopleDashboardMutation, {
  UsePeopleDashboardMutationOptions,
} from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/usePeopleDashboardMutation';
import { organizationAccountTeamMemberAdd } from '@AssetManagementClient/AssetManagementClientMsp.gen';

type OrganizationTeamMemberAddPayload = {
  organizationId: string;
  accountTeamMemberIds: string[];
};

export type UseOrganizationAccountTeamMemberAdd = {
  addOrganizationAccountTeamMember: (createPayload: OrganizationTeamMemberAddPayload) => void;
  isAddingOrganizationAccountTeamMember: boolean;
};

const useOrganizationAccountTeamMemberAdd = (options?: UsePeopleDashboardMutationOptions): UseOrganizationAccountTeamMemberAdd => {
  const [addOrganizationAccountTeamMember, isAddingOrganizationAccountTeamMember] = usePeopleDashboardMutation(
    (createPayload: OrganizationTeamMemberAddPayload) =>
      organizationAccountTeamMemberAdd({
        organizationId: createPayload.organizationId,
        userIdsToAdd: createPayload.accountTeamMemberIds,
      }),
    options,
  );

  return {
    addOrganizationAccountTeamMember,
    isAddingOrganizationAccountTeamMember,
  };
};

export default useOrganizationAccountTeamMemberAdd;
