import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Button from '~/sp-ui/buttons/Button';
import { useEffect, useState } from 'react';
import { InfoIconTooltip, SuccessIcon } from '~/sp-ui/icons';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import ToolTip from '~/sp-ui/tool-tip';

export type Inclusion = {
  title: string;
  tooltip: string;
};

export type CardPriceProps = {
  planLimit: number;
  title: string;
  amount: number;
  inclusions: Inclusion[];
  isRecommended: boolean;
  selectedItemIconTooltip: string;
  isActionDisabled?: boolean;
  action: () => void;
};
const CardPrice = (props: CardPriceProps) => {
  const { selectedItemIconTooltip, planLimit, title, amount, inclusions, isRecommended, action, isActionDisabled } = props;
  const { formik } = useMultiStepForm<PurchaseSubscriptionForm>();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    // Since only one card can be selected for a subscription
    setIsSelected(planLimit === formik.values.selectedPlanLimit);
  }, [formik.values.selectedPlanLimit, planLimit]);

  return (
    <div
      css={css`
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        ${isRecommended ? `border: 0.125rem solid ${colorToCode('dark-400')}` : `border: 0.063rem solid ${colorToCode('light-400')}`};
        background-color: ${colorToCode('light-000')};
      `}
    >
      {isRecommended && (
        <div
          css={css`
            border-radius: 0.5rem 0.5rem 0 0;
            background-color: ${colorToCode('dark-400')};
            color: ${colorToCode('light-000')};
            text-align: center;
          `}
        >
          <div
            css={css`
              display: flex;
              text-align: center;
              align-items: center;
              justify-content: center;
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
              .tooltip-target {
                display: flex;
              }
            `}
          >
            <span>Recommended for you</span>
            {selectedItemIconTooltip && (
              <ToolTip content={<p css={{ maxWidth: '15rem', fontSize: '14px' }}>{selectedItemIconTooltip}</p>}>
                {({ anchorProps, setAnchorRef }) => {
                  return (
                    <InfoIconTooltip
                      css={{ height: '1rem' }}
                      ref={setAnchorRef}
                      {...anchorProps}
                    />
                  );
                }}
              </ToolTip>
            )}
          </div>
        </div>
      )}
      <div
        css={css`
          display: inline-block;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-family: 'Roboto', sans-serif;
          padding: 2rem 1.5rem;
          position: relative;
        `}
      >
        <div
          css={css`
            text-align: center;
          `}
        >
          <div
            css={css`
              display: flex;
              width: 20.75rem;
              text-align: center;
              align-items: center;
              flex-direction: column;
            `}
          >
            <span
              css={css`
                font-size: 18px;
                font-weight: 700;
                line-height: normal;
              `}
            >
              {title}
            </span>
            <div
              css={css`
                display: flex;
                align-items: center;
                font-weight: 300;
                line-height: normal;
                padding: 1rem 0;
              `}
            >
              <span
                css={css`
                  font-size: 4rem;
                `}
              >
                {formatCurrency(amount, 'USD')}
              </span>
              <span
                css={css`
                  font-size: 0.875rem;
                `}
              >
                /mo*
              </span>
            </div>
            <Button
              css={css`
                width: 20.75rem;
                padding-inline: 0;
              `}
              iconLeft={<div>{isSelected && !isActionDisabled && <SuccessIcon />}</div>}
              variant={(isRecommended && !isSelected) || isActionDisabled ? 'primary' : isSelected ? 'success' : 'secondary'}
              disabled={isActionDisabled}
              onClick={() => {
                action();
              }}
            >
              {isActionDisabled ? 'Your current plan' : isSelected ? 'Selected' : 'Select plan'}
            </Button>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              text-align: left;
              padding-top: 1rem;
            `}
          >
            {inclusions.map(inclusion => (
              <div
                key={inclusion.title}
                css={css`
                  display: flex;
                  flex-direction: row;
                  text-align: left;
                  align-items: center;
                  line-height: 1.5rem;
                  .tooltip-target {
                    display: flex;
                  }
                `}
              >
                <Icon
                  icon={'Success'}
                  color={'primary-400'}
                />
                <span
                  css={css`
                    padding: 0 0.5rem;
                  `}
                >
                  {inclusion.title}
                </span>
                {inclusion.tooltip && (
                  <ToolTip content={<p css={{ maxWidth: '15rem', fontSize: '14px' }}>{inclusion.tooltip}</p>}>
                    {({ anchorProps, setAnchorRef }) => {
                      return (
                        <InfoIconTooltip
                          css={{ height: '1rem' }}
                          ref={setAnchorRef}
                          {...anchorProps}
                        />
                      );
                    }}
                  </ToolTip>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPrice;
