import { useState, useCallback, useEffect } from 'react';
import { initiativeTemplateAvailability } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import { InitiativeTemplateDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/InitiativeTemplate/Dto/Model.gen';

export type UseInitiativeTemplateAvailability = (insightId?: string) => {
  application: InitiativeTemplateDto[] | undefined;
  account: InitiativeTemplateDto[] | undefined;
  reload: () => void;
};

const useInitiativeTemplateAvailability: UseInitiativeTemplateAvailability = insightId => {
  const { callApi } = useApi();
  const [notEditableTemplates, setNotEditableTemplates] = useState<InitiativeTemplateDto[] | undefined>(undefined);
  const [editableTemplates, setEditableInitiativeTemplates] = useState<InitiativeTemplateDto[] | undefined>(undefined);

  const reload = useCallback(async () => {
    const response = await callApi(() => initiativeTemplateAvailability({ insightId }));

    if (!response) {
      return;
    }

    setNotEditableTemplates(
      response.initiativeTemplatesApplication.map(template => ({
        initiativeTemplateId: template.initiativeTemplateId,
        name: template.name,
        isRecommended: template.isRecommended,
      })),
    );

    setEditableInitiativeTemplates(
      response.initiativeTemplatesAccount.map(template => ({
        initiativeTemplateId: template.initiativeTemplateId,
        name: template.name,
        isRecommended: template.isRecommended,
      })),
    );
  }, [callApi, insightId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    application: notEditableTemplates,
    account: editableTemplates,
    reload,
  };
};

export default useInitiativeTemplateAvailability;
