import { css } from '@emotion/react';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useMemo, useState } from 'react';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import Spinner from '~/neo-ui/spinner/Spinner';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import { subscriptionBillingCustomerPaymentSetup } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { Discriminant } from '@SubscriptionClient/Primitives/Results/SetupCustomerPaymentControllerNested/Response_/PublicError_/ResultNested.gen';
import Message from '~/sp-ui/message';
import CardNumber from '~/wm/packages/subscription/packages/quotes/ui/CardNumber';
import CardExpiry from '~/wm/packages/subscription/packages/quotes/ui/CardExpiry';
import CardCvc from '~/wm/packages/subscription/packages/quotes/ui/CardCvc';
import Button from '~/sp-ui/buttons/Button';

const CardForm = (props: { paymentSubmitAction: (setupIntent: string) => void; clientSecret: string; submitLabel: string }) => {
  const { clientSecret, paymentSubmitAction, submitLabel } = props;
  const stripe: Stripe | null = useStripe();
  const elements: StripeElements | null = useElements();
  const [stripeError, setStripeError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { formik } = useMultiStepForm<PurchaseSubscriptionForm>();
  const [validState, setValidState] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  const setValid = (key: keyof typeof validState, valid: boolean) =>
    setValidState(previous => ({
      ...previous,
      [key]: valid,
    }));
  const isValid = useMemo(() => Object.values(validState).every(value => value), [validState]);
  const handleSubmit = async () => {
    if (!stripe || !elements || !isValid) {
      return;
    }
    setStripeError(undefined);
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      return;
    }
    //  Call to stripe to confirm setupIntent
    const confirmCardSetup = await stripe.confirmCardSetup(clientSecret, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      payment_method: {
        card: cardElement,
      },
    });
    setIsLoading(true);
    if (confirmCardSetup.error) {
      setStripeError(confirmCardSetup.error.message);
    }

    if (confirmCardSetup.setupIntent) {
      const paymentSetup = await subscriptionBillingCustomerPaymentSetup({ setupIntent: confirmCardSetup.setupIntent.id });
      if (!paymentSetup) {
        setIsLoading(false);
        setStripeError('There were errors in your payment method information. Please check the indicated fields and try again.');
        return;
      }
      if (paymentSetup.case === Discriminant.Error) {
        setIsLoading(false);
        setStripeError(paymentSetup.value.messagePublic);
      } else {
        setStripeError(undefined);
        await formik.setFieldValue('paymentCard', paymentSetup.value);
        paymentSubmitAction(confirmCardSetup.setupIntent.id);
      }
    }

    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: 'min-content 1fr',
        placeContent: 'start',
        gap: '1rem',
      }}
    >
      <CardNumber
        css={{ gridColumn: '1 / -1' }}
        onChange={e => setValid('cardNumber', e.complete)}
      />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          grid-column: 1 / -1;
        `}
      >
        <CardExpiry onChange={e => setValid('cardExpiry', e.complete)} />
        <CardCvc
          css={{ marginLeft: '15px' }}
          onChange={e => setValid('cardCvc', e.complete)}
        />
      </div>
      {stripeError && (
        <Message
          text={stripeError}
          type="error"
          css={{ gridColumn: '1 / -1', marginBlock: '1rem' }}
        />
      )}
      <Button
        ref={formik.values.submitCard}
        css={{ gridColumn: '1 / -1', justifySelf: 'start', marginTop: '1.5rem' }}
        onClick={handleSubmit}
      >
        {submitLabel}
      </Button>
    </div>
  );
};
export default CardForm;
