import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import DataTable, { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { Fragment, useMemo, useState } from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import { formatDate, getDateFriendlyExtendedDefinition, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import EngagementActionEditForm from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/packages/engagement-action-edit-form/EngagementActionEditForm';
import EngagementActionEditWindow from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-action-list-panel-tab/packages/engagement-action-edit-window/EngagementActionEditWindow';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Badge from '~/neo-ui/packages/badge/Badge';
import calculateUserTheme from '~/wm/packages/account/packages/user/hooks/calculateUserTheme';
import Icon from '~/neo-ui/packages/icon/Icon';
import EngagementActionTableTicketCreateButton from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-action-list-panel-tab/packages/engagement-action-table/packages/engagement-action-table-ticket-create-button/EngagementActionTableTicketCreateButton';
import formatUserNameDisplay from '~/wm/packages/account/packages/user/packages/active-users-search/formatUserNameDisplay';
import useTicketFieldAvailabilitiesContext from '~/wm/packages/integration/packages/ticket/context/hooks/useTicketFieldAvailabilitiesContext';
import EngagementActionTableTicketDisplay from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-action-list-panel-tab/packages/engagement-action-table/packages/engagement-action-table-ticket-display/EngagementActionTableTicketDisplay';
import useEngagementActionMarkCompleted from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionMarkCompleted';
import useEngagementActionMarkIncomplete from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionMarkIncomplete';
import useEngagementActionUpdate from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionUpdate';
import EngagementActionTableInitiativeLinkButton from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-action-list-panel-tab/packages/engagement-action-table/packages/engagement-action-table-initiative-link-button/EngagementActionTableInitiativeLinkButton';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import routes from '~/router/types/routes';
import { InitiativeCreateSource } from '~/wm/packages/strategy/model/strategyDataCollectionSources';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';

export type EngagementActionTableProps = {
  actions: EngagementActionDto[];
  initiativeCreatedFrom: InitiativeCreateSource;
};

const EngagementActionTable = ({ actions, initiativeCreatedFrom }: EngagementActionTableProps) => {
  const { isOrganizationRegisteredForTickets, hasTicketIntegration, ticketFieldOptions } = useTicketFieldAvailabilitiesContext();
  const { markCompleted, isMarkingCompleted } = useEngagementActionMarkCompleted();
  const { markIncomplete, isMarkingIncomplete } = useEngagementActionMarkIncomplete();
  const [actionSelected, setActionSelected] = useState<EngagementActionDto>();
  const { updateEngagementAction } = useEngagementActionUpdate();
  const { 'lm-action-item-link-initiative': enabledActionItemLinkInitiative } = useFeatureFlagProvider();
  const { organizationId } = useOrganizationContext();

  const getDueDateLabel = (dueDate: Date, isCompleted: boolean) => {
    const { color, display } = getDateFriendlyExtendedDefinition(dueDate, isCompleted);

    return (
      <Label
        css={css`
          padding: 0.5rem;
          white-space: nowrap;
        `}
        size={'md'}
        color={color}
        bold={true}
      >
        {display}
      </Label>
    );
  };

  const engagementActionColumns: DataTableColumn<EngagementActionDto>[] = useMemo(
    () => [
      // Description Column
      {
        fieldKey: action => action.description,
        Header: 'Task',
        renderCell: action => (
          <div
            css={css`
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 1rem;

              // This height will make the row height consistent with the other rows
              height: 6rem;
            `}
          >
            <Tooltip
              backgroundColor={'light-000'}
              content={`Mark item as ${action.isCompleted ? 'incomplete' : 'complete'}`}
              placement={'top'}
            >
              <Button
                size={'xs'}
                iconLeft={'Done'}
                theme={action.isCompleted ? 'success' : undefined}
                disabled={isMarkingCompleted || isMarkingIncomplete}
                loading={isMarkingCompleted || isMarkingIncomplete}
                onClick={() => {
                  if (action.isCompleted) {
                    markIncomplete(action.engagementActionId);
                  } else {
                    markCompleted(action.engagementActionId);
                  }
                }}
              />
            </Tooltip>
            <div
              css={css`
                display: flex;
                width: 100%;
              `}
              onClick={() => {
                setActionSelected(action);
              }}
            >
              <Tooltip
                backgroundColor={'light-000'}
                content={action.description}
                placement={'top'}
                css={css`
                  max-width: 51.6875rem;
                  word-wrap: break-word;
                `}
              >
                <Label
                  css={css`
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                  `}
                  size={'md'}
                  color={'dark-050'}
                >
                  {action.description}
                </Label>
              </Tooltip>
            </div>
          </div>
        ),
      },
      // Due Date Column
      {
        fieldKey: action => action.dueAt,
        Header: 'Due Date',
        width: '6.375rem',
        renderCell: action => {
          const friendlyDueDate =
            typeof action.dueAt !== 'undefined' ? getDateFriendlyExtendedDefinition(new Date(action.dueAt), action.isCompleted) : undefined;

          return typeof friendlyDueDate !== 'undefined' ? (
            <div
              css={css`
                .tooltip-target {
                  width: min-content;
                }
              `}
            >
              <Tooltip
                backgroundColor={'light-000'}
                placement={'top'}
                content={`Due on: ${formatDate(new Date(action.dueAt!), {
                  format: 'MMM do',
                  timezone: TimezoneFormat.Local,
                })}`}
              >
                {getDueDateLabel(new Date(action.dueAt!), action.isCompleted)}
              </Tooltip>
            </div>
          ) : (
            '-'
          );
        },
      },
      // Assigned Users Column
      {
        fieldKey: action => action.assignedUsers,
        Header: 'Assigned to',
        width: '12.5rem',
        renderCell: action => {
          if (action.assignedUsers.length < 1) {
            return '-';
          }

          return (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
              `}
            >
              {action.assignedUsers.slice(0, 3).map((user, index) => {
                if (index > 2) {
                  return;
                }

                const userTheme = calculateUserTheme(user.id);

                return (
                  <div
                    key={user.id}
                    css={css`
                      display: flex;
                      gap: 0.5rem;
                      flex-wrap: nowrap;
                      align-items: center;
                    `}
                  >
                    <Badge
                      bgColor={userTheme.backgroundColorRest}
                      textColor={userTheme.textColorRest}
                      fontWeight={'400'}
                      borderRadius={'radius425'}
                      css={css`
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                      `}
                    >
                      {formatUserNameDisplay(user)}
                      {!action.isCompleted && (
                        <Icon
                          sizePx={12}
                          icon={'ActionClose'}
                          color={'dark-700'}
                          css={css`
                            cursor: pointer;
                          `}
                          onClick={() => {
                            // Unassign user from action
                            updateEngagementAction({
                              engagementActionId: action.engagementActionId,
                              description: action.description,
                              dueAt: action.dueAt,
                              assignedUserIds: action.assignedUsers
                                .filter(assignedUser => assignedUser.id !== user.id)
                                .map(assignedUser => assignedUser.id),
                            });
                          }}
                          preventOnClickPropagation={true}
                        />
                      )}
                    </Badge>
                    {index === 2 && action.assignedUsers.length > 3 && (
                      <Label color={'dark-700'}>+ {action.assignedUsers.length - 3}</Label>
                    )}
                  </div>
                );
              })}
            </div>
          );
        },
      },
      // Initiative link column
      ...(!enabledActionItemLinkInitiative
        ? []
        : [
            {
              fieldKey: action => action.initiativeLinkInfo,
              Header: 'Initiative',
              width: '6.375rem',
              renderCell: action => (
                <div
                  css={css`
                    text-align: center;
                  `}
                >
                  {typeof action.initiativeLinkInfo === 'undefined' ? (
                    <EngagementActionTableInitiativeLinkButton action={action} />
                  ) : (
                    <ButtonLink
                      anchor={{
                        href: routes.ORGANIZATION_STRATEGY_ROADMAP_INITIATIVE_VIEW.build([
                          organizationId,
                          action.initiativeLinkInfo.initiativeId,
                        ]),
                        openInNewTab: false,
                      }}
                      iconLeft={'Initiative'}
                    />
                  )}
                </div>
              ),
            } satisfies DataTableColumn<EngagementActionDto>,
          ]),
      // Ticket Column
      {
        fieldKey: action => action.ticketLinkState,
        Header: 'PSA Ticket',
        width: '6.375rem',
        renderCell: action => (
          <div
            css={css`
              text-align: center;
            `}
          >
            {typeof action.ticketLinkState === 'undefined' ? (
              <EngagementActionTableTicketCreateButton action={action} />
            ) : (
              <EngagementActionTableTicketDisplay ticketLinkState={action.ticketLinkState} />
            )}
          </div>
        ),
      },
    ],
    [
      enabledActionItemLinkInitiative,
      isMarkingCompleted,
      isMarkingIncomplete,
      markCompleted,
      markIncomplete,
      organizationId,
      updateEngagementAction,
    ],
  );

  return (
    <Fragment>
      <DataTable
        columns={engagementActionColumns}
        data={actions}
        EmptyStatePlaceholder={''}
        rowSeparation={'border'}
        hasTableBorder={true}
        getRowOptions={() => ({
          backgroundColor: colorToCode('light-000'),
        })}
      />
      {typeof actionSelected !== 'undefined' && (
        <EngagementActionEditForm action={actionSelected}>
          <EngagementActionEditWindow
            /**
             * We use find so we can get the latest action from the list.
             * Keep in mind.... find can retrun undefined, so we default to the actionSelected to make typescript happy :)
             */
            isOpen={typeof actionSelected !== 'undefined'}
            action={actions.find(actionItem => actionItem.engagementActionId === actionSelected.engagementActionId) || actionSelected}
            ticketFieldOptions={ticketFieldOptions}
            onDismiss={() => {
              setActionSelected(undefined);
            }}
            isOrganizationRegisteredForTickets={isOrganizationRegisteredForTickets}
            hasTicketIntegration={hasTicketIntegration}
            initiativeCreatedFrom={initiativeCreatedFrom}
          />
        </EngagementActionEditForm>
      )}
    </Fragment>
  );
};

export default EngagementActionTable;
