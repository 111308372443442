import Window from '~/neo-ui/packages/window/Window';
import useScorecardContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardContext';
import Button from '~/neo-ui/packages/button/Button';
import * as React from 'react';
import { DropResult } from '@hello-pangea/dnd';
import { DragDropZone } from '~/neo-ui/packages/drag-and-drop/DragAndDrop';
import { ScaleOption } from '~/neo-ui/packages/select/packages/scale-select/ScaleSelect';
import useScorecardCategoryArrange from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/hooks/useScorecardCategoryArrange';
import ScorecardCategoryArrangeCategoryDropZone from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/packages/scorecard-category-arrange/packages/scorecard-category-arrange-category-drop-zone/ScorecardCategoryArrangeCategoryDropZone';
import Dropdown, { DropdownOption } from '~/neo-ui/packages/dropdown/Dropdown';
import { css } from '@emotion/react';
import { PriorityDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Priority/Dto/Model.gen';

export type ScorecardCategoryArrangeWindowProps = {
  isOpen: boolean;
  onDismiss: () => void;
  scorecardId: string;
  scoreDisplayAvailabilities: ScaleOption[];
  priorityDisplayAvailabilities: PriorityDto[];
};

/**
 * Drag and drop outer type for scorecard categories
 */
export type ScorecardItemsByScorecardCategory = {
  id: string;
  label: string;
  scorecardItems: ScorecardItemDisplay[];
};

/**
 * Drag and drop inner type for scorecard items
 */
type ScorecardItemDisplay = {
  id: string;
  label: string;
  score: string;
  priority: number;
};

/**
 * Reorders an array of items based on the start and end index
 * @param array Array of items to reorder
 * @param startIndex
 * @param endIndex
 */
const reorder = <T,>(array: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(array);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Sort options for the arrange dropdown
 */
const sortOptions: DropdownOption[] = [
  { label: 'Sort items manually', value: 'manual', icon: 'SortDescending' },
  {
    label: 'Sort items by name (A-Z)',
    value: 'name-ascending',
    icon: 'SortDescending',
  },
  {
    label: 'Sort items by name (Z-A)',
    value: 'name-descending',
    icon: 'SortAscending',
  },
  {
    label: 'Sort items by score (high-low)',
    value: 'score-descending',
    icon: 'SortDescending',
  },
  {
    label: 'Sort items by score (low-high)',
    value: 'score-ascending',
    icon: 'SortAscending',
  },
  {
    label: 'Sort items by priority (high-low)',
    value: 'priority-descending',
    icon: 'SortDescending',
  },
  {
    label: 'Sort items by priority (low-high)',
    value: 'priority-ascending',
    icon: 'SortAscending',
  },
];

const ScorecardCategoryArrangeWindow = ({
  isOpen,
  onDismiss,
  scorecardId,
  scoreDisplayAvailabilities,
  priorityDisplayAvailabilities,
}: ScorecardCategoryArrangeWindowProps) => {
  const { scorecard } = useScorecardContext();
  const { arrangeScorecardCategory, isArrangingScorecardCategory } = useScorecardCategoryArrange(scorecardId, { onSuccess: onDismiss });

  const [order, setOrder] = React.useState<ScorecardItemsByScorecardCategory[]>(
    scorecard.scorecardCategories.map(category => ({
      id: category.scorecardCategoryId,
      label: category.label,
      scorecardItems: category.scorecardItems.map(item => ({
        id: item.scorecardItemId,
        label: item.category.label,
        score: item.score.value.toString(),
        priority: priorityDisplayAvailabilities.findIndex(priority => priority.key === item.priority.key),
      })),
    })),
  );

  // When the scorecard mutates trigger the arrange order to update
  React.useEffect(
    () =>
      setOrder(
        scorecard.scorecardCategories.map(category => ({
          id: category.scorecardCategoryId,
          label: category.label,
          scorecardItems: category.scorecardItems.map(item => ({
            id: item.scorecardItemId,
            label: item.category.label,
            score: item.score.value.toString(),
            priority: priorityDisplayAvailabilities.findIndex(priority => priority.key === item.priority.key),
          })),
        })),
      ),
    [priorityDisplayAvailabilities, scorecard.scorecardCategories],
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    // If the dropped element is a category, reorder the categories
    if (result.type === 'category') {
      const newOrder = reorder(order, sourceIndex, destIndex);
      setOrder(newOrder);
    }
    // If the dropped element is an item, reorder the items within the category or between categories
    else if (result.type === 'item') {
      setSortOption(sortOptions[0]);

      const itemsByCategoryIdMap = order.reduce((acc: Record<string, ScorecardItemDisplay[]>, category) => {
        acc[category.id] = category.scorecardItems;
        return acc;
      }, {});

      const sourceDroppableId = result.source.droppableId;
      const destDroppableId = result.destination.droppableId;

      const sourceItems = itemsByCategoryIdMap[sourceDroppableId];
      const destItems = itemsByCategoryIdMap[destDroppableId];

      let newOrder = [...order];

      // Order the items within the same category
      if (sourceDroppableId === destDroppableId) {
        const reorderedSubItems = reorder(sourceItems, sourceIndex, destIndex);
        newOrder = newOrder.map(itemsByCategory => ({
          id: itemsByCategory.id,
          label: itemsByCategory.label,
          scorecardItems: itemsByCategory.id === sourceDroppableId ? reorderedSubItems : itemsByCategory.scorecardItems,
        }));
        setOrder(newOrder);
      }
      // Order the items between categories
      else {
        const newSourceItems = [...sourceItems];
        const [draggedItem] = newSourceItems.splice(sourceIndex, 1);

        const newDestItems = [...destItems];
        newDestItems.splice(destIndex, 0, draggedItem);
        newOrder = newOrder.map(itemsByCategory => ({
          id: itemsByCategory.id,
          label: itemsByCategory.label,
          scorecardItems:
            itemsByCategory.id === sourceDroppableId
              ? newSourceItems
              : itemsByCategory.id === destDroppableId
              ? newDestItems
              : itemsByCategory.scorecardItems,
        }));
        setOrder(newOrder);
      }
    }
  };

  const onApply = () => {
    arrangeScorecardCategory({
      orderedScorecardItemIdsByScorecardCategoryId: order.map(scorecardItemsByScorecardCategoryId => ({
        scorecardCategoryId: scorecardItemsByScorecardCategoryId.id,
        scorecardItemIds: scorecardItemsByScorecardCategoryId.scorecardItems.map(item => item.id),
      })),
    });
  };

  const [sortOption, setSortOption] = React.useState<DropdownOption>(sortOptions[0]);

  const onSort = (option: DropdownOption) => {
    setSortOption(option);

    switch (option.value) {
      case 'manual':
        break;
      case 'name-ascending': {
        setOrder(prev =>
          prev.map<ScorecardItemsByScorecardCategory>(category => ({
            label: category.label,
            id: category.id,
            scorecardItems: category.scorecardItems.sort((a, b) => (a.label > b.label ? 1 : -1)),
          })),
        );

        break;
      }
      case 'name-descending': {
        setOrder(prev =>
          prev.map<ScorecardItemsByScorecardCategory>(category => ({
            label: category.label,
            id: category.id,
            scorecardItems: category.scorecardItems.sort((a, b) => (a.label < b.label ? 1 : -1)),
          })),
        );

        break;
      }
      case 'score-descending': {
        setOrder(prev =>
          prev.map<ScorecardItemsByScorecardCategory>(category => ({
            label: category.label,
            id: category.id,
            scorecardItems: category.scorecardItems.sort((a, b) => (a.score < b.score ? 1 : -1)),
          })),
        );

        break;
      }
      case 'score-ascending': {
        setOrder(prev =>
          prev.map<ScorecardItemsByScorecardCategory>(category => ({
            label: category.label,
            id: category.id,
            scorecardItems: category.scorecardItems.sort((a, b) => (a.score > b.score ? 1 : -1)),
          })),
        );

        break;
      }
      case 'priority-descending': {
        setOrder(prev =>
          prev.map<ScorecardItemsByScorecardCategory>(category => ({
            label: category.label,
            id: category.id,
            scorecardItems: category.scorecardItems.sort((a, b) => (a.priority < b.priority ? 1 : -1)),
          })),
        );

        break;
      }
      case 'priority-ascending': {
        setOrder(prev =>
          prev.map<ScorecardItemsByScorecardCategory>(category => ({
            label: category.label,
            id: category.id,
            scorecardItems: category.scorecardItems.sort((a, b) => (a.priority > b.priority ? 1 : -1)),
          })),
        );

        break;
      }
    }
  };

  return (
    <Window
      title={'Arrange Categories & Items'}
      isOpen={isOpen}
      onDismiss={onDismiss}
      footerProps={{
        rightControls: [
          {
            expanded: (
              <Button
                iconLeft={'Done'}
                theme={'secondary'}
                onClick={onApply}
                loading={isArrangingScorecardCategory}
              >
                Apply
              </Button>
            ),
          },
        ],
        leftControls: [
          {
            expanded: (
              <Button
                iconRight={'Bad'}
                onClick={onDismiss}
                disabled={isArrangingScorecardCategory}
              >
                Cancel
              </Button>
            ),
          },
        ],
      }}
    >
      <Dropdown
        options={sortOptions}
        selectedOption={sortOption}
        onOptionSelected={onSort}
        buttonCss={css`
          width: 100%;
          margin-bottom: 0.9375rem;
        `}
      />
      <DragDropZone onDragEnd={onDragEnd}>
        <ScorecardCategoryArrangeCategoryDropZone
          scorecardItemsByScorecardCategories={order}
          scoreDisplayAvailabilities={scoreDisplayAvailabilities}
        />
      </DragDropZone>
    </Window>
  );
};

export default ScorecardCategoryArrangeWindow;
