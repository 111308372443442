import { scorecardItemAutomationRefresh } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useScorecardMutation, { UseScorecardMutationOptions } from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardMutation';

export type UseScorecardItemAutomationRefresh = {
  refreshAutomatedScorecardItem: (automationRefreshPayload: { scorecardId: string; scorecardItemId: string }) => void;
  isRefreshingAutomatedScorecardItem: boolean;
};

const useScorecardItemAutomationRefresh = (options?: UseScorecardMutationOptions): UseScorecardItemAutomationRefresh => {
  const [refreshAutomatedScorecardItem, isRefreshingAutomatedScorecardItem] = useScorecardMutation(
    (automationRefreshPayload: { scorecardId: string; scorecardItemId: string }) =>
      scorecardItemAutomationRefresh({
        scorecardId: automationRefreshPayload.scorecardId,
        scorecardItemId: automationRefreshPayload.scorecardItemId,
      }),
    options,
  );

  return {
    refreshAutomatedScorecardItem,
    isRefreshingAutomatedScorecardItem,
  };
};

export default useScorecardItemAutomationRefresh;
