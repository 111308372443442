import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type InfoTitleSize = 'sm' | 'md' | 'lg';
export type InfoProps = {
  /**
   * Title can be a basic string or, in the case of value info, a representation of value/units in one
   *
   * @deprecated by LayoutSection
   */
  title: string | { value: string; units: string };
  titleSize: InfoTitleSize;
  titleColor?: Color;
  titleStrikethrough?: boolean;
  titleFontWeight?: string;
  description?: string;
  descriptionColor?: Color;
  descriptionMuted?: boolean;
} & Styleable;

const Info: React.FunctionComponent<React.PropsWithChildren<InfoProps>> = ({
  title,
  titleSize,
  titleColor,
  titleStrikethrough = false,
  titleFontWeight = 'bold',
  description,
  descriptionColor,
  descriptionMuted = false,
  className,
  children,
}) => (
  <div className={className}>
    <div
      css={css`
        display: flex;
        align-items: center;
        font-size: 0.875rem;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-bottom: 0;
          width: 100%;
        `}
      >
        <div
          css={css`
            margin-bottom: 0.3125rem;
            ${titleColor ? `color: ${colorToCode(titleColor)};` : ''}
            ${titleStrikethrough ? 'text-decoration: line-through;' : ''}
          `}
        >
          <span
            css={css`
              font-weight: ${titleFontWeight};
              font-size: ${((): number => {
                switch (titleSize) {
                  case 'lg':
                    return 1.5;
                  case 'md':
                    return 1;
                  case 'sm':
                    return 0.875;
                }
              })()}rem;
            `}
          >
            {typeof title === 'object' ? title.value : title}
          </span>
          {typeof title === 'object' && (
            <span
              css={css`
                margin-left: 0.3125rem;
                font-size: 1rem;
              `}
            >
              {title.units}
            </span>
          )}
        </div>
        {description && (
          <Label
            muted={descriptionMuted}
            color={descriptionColor}
            css={css`
              margin: 0;
              font-size: 0.875rem;
            `}
          >
            {description}
          </Label>
        )}
        {children && (
          <div
            css={css`
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            `}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  </div>
);

export default Info;
