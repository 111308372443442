import { createContext } from 'react';
import { ContractAvailabilitiesDto, ContractSummaryDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Dto/Model.gen';
import { ContractBillingCurrencyDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Billing/Dto/Model.gen';

export type ContractListContextData = {
  organizationName: string;
  contracts: ContractSummaryDto[];
  contractAvailabilities: ContractAvailabilitiesDto;
  contractBillingCurrency: ContractBillingCurrencyDto;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ContractListContext = createContext<ContractListContextData | undefined>(undefined);

export default ContractListContext;
