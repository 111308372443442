import { css } from '@emotion/react';
import * as React from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Testable from '~/neo-ui/packages/testable/Testable';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type SelectListItemProps = {
  option: SelectOption;
  selectedOption: SelectOption | undefined;
  selectedBgColor?: Color;
  bgColor?: Color;
  hoverBgColor?: Color;
  boldLabel?: boolean;
  padding?: string;
  onSelect: () => void;
  usesInversion: boolean;
};

const SelectListItem: React.FunctionComponent<SelectListItemProps> = ({
  option,
  selectedOption,
  selectedBgColor = 'primary-400',
  bgColor = 'blank',
  hoverBgColor = 'primary-050',
  boldLabel = false,
  padding = '0.9375rem',
  onSelect,
  usesInversion,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const isSelected = typeof selectedOption !== 'undefined' && selectedOption.value === option.value;

  const nonInversionTextColor = isHovered ? selectedBgColor : !isHovered && !isSelected ? 'dark-900' : 'light-000';

  const defaultTextColor = option.labelColor ?? 'dark-900';
  const inversionTextColor = option.disabled ? defaultTextColor : isHovered ? bgColor : defaultTextColor;
  const textColor = usesInversion ? inversionTextColor : nonInversionTextColor;

  const hoverCss = option.disabled
    ? ''
    : css`&:hover {
    cursor: pointer;
    background-color: ${colorToCode(usesInversion ? defaultTextColor : hoverBgColor)}`;

  const grayOutCss = option.disabled
    ? css`
        opacity: 70%;
        & > * {
          opacity: 70%;
        }
      `
    : '';

  return (
    <Testable testId={`select-list-option`}>
      <div
        css={css`
          background-color: ${isSelected ? colorToCode(selectedBgColor) : colorToCode(bgColor)};
          border-radius: 0.625rem;
          padding: ${padding};
          ${hoverCss};
          ${grayOutCss};
        `}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={
          option.disabled
            ? () => {}
            : () => {
                if (!isSelected) {
                  onSelect();
                }
              }
        }
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {option.prependIcon && (
              <div
                css={css`
                  display: flex;
                  margin-right: 0.875rem;
                `}
              >
                <Icon
                  icon={option.prependIcon}
                  color={textColor}
                  sizePx={option.iconSizePx ?? 12}
                />
              </div>
            )}
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <Label
                size={'md'}
                color={textColor}
                bold={boldLabel}
              >
                {option.label}
              </Label>
              {option.description && (
                <Label
                  size={'sm'}
                  muted={option.descriptionMuted}
                  color={textColor}
                >
                  {option.description}
                </Label>
              )}
            </div>
          </div>
          {option.appendIcons && (
            <div
              css={css`
                display: flex;
                gap: 0.359375rem;
                align-items: center;
              `}
            >
              {option.appendIcons.map(icon => (
                <Icon
                  key={icon}
                  color={textColor}
                  icon={icon}
                  sizePx={12}
                />
              ))}
            </div>
          )}
          {option.actionButton && <ButtonLink {...option.actionButton} />}
        </div>
      </div>
    </Testable>
  );
};
export default SelectListItem;
