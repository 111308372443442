import { SyncPreferencesData } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useSyncPreferencesForm';
import { Control, Controller, UseFormGetValues, UseFormResetField, UseFormSetValue } from 'react-hook-form';
import {
  FieldValueDisplay,
  FieldValueMapDisplay,
  ValueMappingDisplaySettingsDto,
} from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import SwapContent from '~/neo-ui/packages/mapping/SwapContent';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import SingleSelect from '~/neo-ui/packages/select/packages/single-select/SingleSelect';
import pluralize from 'pluralize';
import React, { ReactNode, useState } from 'react';
import { produce } from 'immer';
import SwapContentRow from '~/neo-ui/packages/mapping/packages/SwapContentRow';
import SwapContentCell from '~/neo-ui/packages/mapping/packages/packages/SwapContentCell';
import { pascalToCamel } from '~/extensions/packages/casing/camelPascalConversion';
import { applyToRecordValues } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/utils';
import SwapContentRowNested from '~/neo-ui/packages/mapping/packages/SwapContentRowNested';
import Icon from '~/neo-ui/packages/icon/Icon';
import {
  SelectedMappingsKey,
  SelectionInfo,
} from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/mapping/hooks/useMappingDataProcessing';

export type MappingProps = {
  sectionInformation: ValueMappingDisplaySettingsDto;
  control: Control<SyncPreferencesData>;
  resetField: UseFormResetField<SyncPreferencesData>;
  formKeyToSelectionInfo: Map<string, SelectionInfo>;
  setValue: UseFormSetValue<SyncPreferencesData>;
  getValues: UseFormGetValues<SyncPreferencesData>;
};

const unassignedLabel = 'UnassignedAsAStringPlusSomeExtraText--0qjarcir9?*&^';

export const Mapping: React.FunctionComponent<MappingProps> = ({
  sectionInformation,
  formKeyToSelectionInfo,
  control,
  setValue,
  getValues,
  resetField,
}) => {
  const [savedParentValues, setSavedParentValues] = useState(new Map<SelectedMappingsKey, string | null>());
  const [selectedDepth, setSelectedDepth] = React.useState<number | undefined>(sectionInformation.preferredDepth);

  const optionsAtSelectedDepthAndWithoutUnassigned: SelectOption[] = sectionInformation.mapToOptions
    .filter(({ depth }) => depth === undefined || depth === selectedDepth)
    .filter(x => x.id)
    .map(
      (option: FieldValueDisplay): SelectOption => ({
        value: option.id,
        label: option.label,
        // Unfortunately, we can't check type safety on this one
        prependIcon: option.iconStub as IconType | undefined,
      }),
    );

  const unassignedFieldValueDisplay = sectionInformation.unassignedMapToOption;
  const unassignedOption = unassignedFieldValueDisplay
    ? ({
        // Regrettable workaround since SelectOption doesn't allow null ids
        value: unassignedLabel,
        label: unassignedFieldValueDisplay.label,
        prependIcon: unassignedFieldValueDisplay.iconStub,
      } as SelectOption)
    : undefined;

  const updateParentId = (parentMappingKey: SelectedMappingsKey) => (value: string | null) => {
    if (!parentMappingKey) {
      return;
    }
    if (value === null && savedParentValues.has(parentMappingKey)) {
      // Revert to the saved value
      setValue(parentMappingKey, savedParentValues.get(parentMappingKey) ?? null);
      return;
    }
    if (value !== sectionInformation.byChildMapToOption) {
      setSavedParentValues(
        produce(savedParentValues, draft => {
          draft.set(parentMappingKey, getValues(parentMappingKey));
        }),
      );
    }
    setValue(parentMappingKey, value);
  };

  const depthOptions: SelectOption[] = [...new Set<number | undefined>(sectionInformation.mapToOptions.map(({ depth }) => depth))]
    .filter(x => x !== undefined)
    .map(depth => {
      const exampleValue = sectionInformation.mapToOptions.find(x => x.depth === depth);

      const fallbackLabel = sectionInformation.mapToColumnLabel + ' ' + depth;
      return {
        value: depth!.toString(),
        label: sectionInformation?.depthInfoMap ? sectionInformation.depthInfoMap[depth!].label ?? fallbackLabel : fallbackLabel,
        description: exampleValue?.label ? `Like "${exampleValue?.label}"` : undefined,
      };
    });

  const sectionKey = `selectedMappings.${pascalToCamel(sectionInformation.payloadType)}.${pascalToCamel(sectionInformation.name)}` as const;
  const idSpecificKey = ({ id }: FieldValueMapDisplay, sectionName: string) =>
    `selectedMappings.${pascalToCamel(sectionInformation.payloadType)}.${pascalToCamel(sectionName)}.${id}-` as const;

  const createRightContentSelection: (
    sectionName: string,
    row: FieldValueMapDisplay,
    isEnabled?: boolean,
    disabledDisplay?: string,
  ) => ReactNode = (sectionName, row, isEnabled = true, disabledDisplay) => {
    return (
      <Controller
        key={idSpecificKey(row, sectionName)}
        control={control}
        name={idSpecificKey(row, sectionName)}
        render={({ field: { value, onChange } }) => {
          return (
            <>
              <Label
                css={css`
                  ${!isEnabled && disabledDisplay ? undefined : 'display: none;'}
                  margin-left: 0.5rem;
                `}
              >
                {disabledDisplay}
              </Label>
              <SingleSelect
                selectedOption={optionsAtSelectedDepthAndWithoutUnassigned.find(s => s.value === value) ?? unassignedOption}
                defaultOption={unassignedOption}
                options={
                  unassignedOption
                    ? [...optionsAtSelectedDepthAndWithoutUnassigned, unassignedOption]
                    : optionsAtSelectedDepthAndWithoutUnassigned
                }
                css={css`
                  flex-grow: 1;
                  ${!isEnabled && disabledDisplay ? 'display: none;' : undefined}
                `}
                // React-hook-form doesn't allow setting values to undefined, so we set them to null instead.
                onOptionSelected={option => {
                  const optionValue = option.value === unassignedLabel ? null : option.value;
                  setSavedParentValues(
                    produce(savedParentValues, draft => {
                      draft.set(idSpecificKey(row, sectionName), optionValue);
                    }),
                  );
                  onChange(optionValue);
                }}
              />
            </>
          );
        }}
      />
    );
  };

  return sectionInformation.mapFromOptions.length > 0 ? (
    <>
      {selectedDepth !== undefined && (
        /**
         *  Depth selector, if applicable
         */
        <SwapContentRow
          leftContent={
            <SwapContentCell>
              <div>
                <Label bold={true}>{`Which categories do you want to use for ${pluralize(
                  sectionInformation.integrationsLabel.toLowerCase(),
                )}?`}</Label>
                <Label muted={true}>This affects the available ScalePad categories.</Label>
              </div>
            </SwapContentCell>
          }
          rightContent={
            <SwapContentCell>
              <SingleSelect
                options={depthOptions}
                selectedOption={depthOptions.find(x => x.value === selectedDepth.toString())}
                placeholder={'Select an option…'}
                onOptionSelected={x => {
                  const selDepth = Number(x.value);
                  if (selDepth === sectionInformation.preferredDepth) {
                    resetField(sectionKey);
                  } else {
                    setValue(
                      sectionKey,
                      applyToRecordValues(getValues(sectionKey), (_: string | null) => null),
                    );
                  }
                  setSelectedDepth(selDepth);
                }}
                css={css`
                  padding: 1rem 0 0.5rem 0.5rem;
                  margin-left: auto;
                  min-width: min(16rem, 100%);
                `}
                shouldShowDescriptionOnControlButton={false}
              />
            </SwapContentCell>
          }
          rowStyles={css`
            border-bottom: none;
          `}
        />
      )}

      {/* Main component */}
      <SwapContent
        leftLabel={`Your ${pluralize(sectionInformation.integrationsLabel.toLowerCase())}`}
        rightLabel={`ScalePad ${sectionInformation.mapToColumnLabel.toLowerCase()}`}
      >
        {sectionInformation.mapFromOptions.map(row => {
          const rowKey = idSpecificKey(row, sectionInformation.name);
          if (row.childOptions && row.childOptions.length > 0) {
            const isChildOptionSelected = formKeyToSelectionInfo.get(rowKey)?.isChildSelected ?? false;
            return (
              <SwapContentRowNested
                mainRow={row}
                mainRowSectionKey={sectionInformation.name}
                key={row.id}
                nestedRows={row.childOptions}
                rightContentSelection={createRightContentSelection}
                isChildOptionSelected={isChildOptionSelected}
                updateParentId={updateParentId(rowKey)}
                byChildKey={sectionInformation.byChildMapToOption}
              />
            );
          }

          return (
            <SwapContentRow
              key={row.id}
              leftContent={<SwapContentCell>{row.label}</SwapContentCell>}
              rightContent={
                <SwapContentCell>
                  <Icon
                    icon={'ArrowChosen'}
                    color={'dark-900-32'}
                  />
                  {createRightContentSelection(sectionInformation.name, row)}
                </SwapContentCell>
              }
            />
          );
        })}
      </SwapContent>
    </>
  ) : (
    <></> // If no mapping information is available, display nothing.
  );
};
