import { BudgetQuarterDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Packages/BudgetQuarter/Dto/Model.gen';
import { BudgetLineItemDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Dto/Model.gen';
import { SumRecurringFeesDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Recurring/Dto/Model.gen';
import { PriorityDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Priority/Dto/Model.gen';
import { MultiSelectOption } from '~/neo-ui/packages/select/packages/multi-select/MultiSelect';
import { Fragment, useState } from 'react';
import useInitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesContext';
import { DropdownOption } from '~/neo-ui/packages/dropdown/Dropdown';
import RoadmapFilters from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-filters/RoadmapFilters';
import RoadmapInitiativeView from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-initiative-view/RoadmapInitiativeView';
import RoadmapListView from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-list-view/RoadmapListView';
import { RoadmapViewMode } from '~/wm/packages/strategy/packages/roadmap-page/RoadmapPage';
import { css } from '@emotion/react';
import useRoadmapInitiativesContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapInitiativesContext';
import InitiativeSaveWindow from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-window/InitiativeSaveWindow';
import useInitiativeDelete from '~/wm/packages/strategy/packages/initiative/packages/initiative-delete/hooks/useInitiativeDelete';

/**
 * Convert the old InitiativeOverviewRoadmapDto into a scaled-down type for the new roadmap
 */
export type RoadmapInitiative = {
  initiativeId: string;
  name: string;
  executiveSummary?: string;
  status: string;
  budgetQuarter: BudgetQuarterDto | undefined;
  budgetLineItems: BudgetLineItemDto[];
  sumBudgetSubunits: number;
  sumRecurringFees: SumRecurringFeesDto;
  priority: PriorityDto;
  countAssets: number;
  displayOrder: number;
};

/**
 * The view mode for the roadmap initiative
 */
export type RoadmapInitiativeViewMode = 'Description' | 'Fees';

export type RoadmapV2Props = { roadmapViewMode: RoadmapViewMode };

const RoadmapV2 = ({ roadmapViewMode }: RoadmapV2Props) => {
  const { availabilities } = useInitiativeAvailabilitiesContext();
  const { isDefaultToShowUnscheduled } = useRoadmapInitiativesContext();

  const [selectedViewMode, setSelectedViewMode] = useState<DropdownOption<RoadmapInitiativeViewMode>>({
    value: 'Fees',
    label: 'View fees',
  });
  availabilities.statusAvailabilities.map(availability => ({
    value: availability.id,
    label: availability.displayLabel,
  }));
  const [selectedStatuses, setSelectedStatuses] = useState<MultiSelectOption[]>([]);
  availabilities.priorityAvailabilities.map(availability => ({
    value: availability.key,
    label: availability.label,
  }));
  const [selectedPriorities, setSelectedPriorities] = useState<MultiSelectOption[]>([]);

  const [showUnscheduled, setShowUnscheduled] = useState(isDefaultToShowUnscheduled);

  const { onInitiativeSave, draftInitiativeId, setDraftInitiativeId } = useRoadmapInitiativesContext();
  const { deleteInitiative } = useInitiativeDelete({
    onSuccess: () => {
      setDraftInitiativeId(undefined);
    },
  });

  const roadmapView = (() => {
    switch (roadmapViewMode) {
      case 'Roadmap':
        return (
          <RoadmapInitiativeView
            selectedViewMode={selectedViewMode}
            selectedStatuses={selectedStatuses}
            selectedPriorities={selectedPriorities}
            showUnscheduled={showUnscheduled}
            css={css`
              margin-top: 2.5rem;
            `}
          />
        );
      case 'List':
        return (
          <RoadmapListView
            selectedStatuses={selectedStatuses}
            selectedPriorities={selectedPriorities}
            showUnscheduled={showUnscheduled}
          />
        );
      default:
        return <Fragment />;
    }
  })();

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',

        // hack for fiscal quarter column taking the rest of the screen height
        ...((typeof WM.account === 'undefined' || !WM.account.isEnceladusAllowed) && {
          height: 'calc(100vh - 19.1875rem)',
        }),
      }}
    >
      <RoadmapFilters
        selectedViewMode={selectedViewMode}
        onChangeSelectedViewMode={setSelectedViewMode}
        selectedStatuses={selectedStatuses}
        onChangeSelectedStatuses={setSelectedStatuses}
        selectedPriorities={selectedPriorities}
        onChangeSelectedPriorities={setSelectedPriorities}
        showUnscheduled={showUnscheduled}
        onChangeShowUnscheduled={setShowUnscheduled}
        roadmapViewMode={roadmapViewMode}
      />
      {roadmapView}
      {typeof draftInitiativeId !== 'undefined' && (
        <InitiativeSaveWindow
          isOpen={typeof draftInitiativeId !== 'undefined'}
          onDismiss={() => {
            deleteInitiative(draftInitiativeId);
          }}
          onDeleteSuccessDismiss={() => {
            setDraftInitiativeId(undefined);
          }}
          initiativeId={draftInitiativeId}
          onSaveInitiative={initiativeId => {
            onInitiativeSave(initiativeId);
            setDraftInitiativeId(undefined);
          }}
        />
      )}
    </div>
  );
};

export default RoadmapV2;
