import { Styleable } from '~/neo-ui/model/capacity';
import PanelTabGroupProvider from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/PanelTabGroupProvider';
import PanelTabGroupInternal, { PanelTabGroupInternalProps } from '~/neo-ui/packages/panel/packages/PanelTabGroup/PanelTabGroupInternal';

export type PanelTabGroupProps<T> = {
  initialTabId: T;
  initialFilterValueByTab: Map<T, string | undefined>;
  initialPageByTab: Map<T, number | undefined>;
} & PanelTabGroupInternalProps<T> &
  Styleable;

const PanelTabGroup = <T,>({
  initialTabId,
  initialFilterValueByTab,
  initialPageByTab,
  panelTabs,
  panelContentCss,
  className,
}: PanelTabGroupProps<T>) => (
  <PanelTabGroupProvider
    initialTabId={initialTabId}
    initialFilterValueByTab={initialFilterValueByTab}
    initialPageByTab={initialPageByTab}
  >
    <PanelTabGroupInternal
      panelTabs={panelTabs}
      panelContentCss={panelContentCss}
      className={className}
    />
  </PanelTabGroupProvider>
);

export default PanelTabGroup;
