import { css } from '@emotion/react';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Styleable } from '~/neo-ui/model/capacity';
import Theme from '~/neo-ui/packages/color/Theme';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type InputCheckboxProps = {
  disabled?: boolean;
  description?: React.JSX.Element | string;

  checked: boolean;

  theme?: Theme;
  checkboxColor?: Color;

  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;

  /**
   * If checked is false and indeterminate is true, the checkbox will look like | - | instead of | ✔ | or |  |.
   * see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Input/checkbox#indeterminate_state_checkboxes
   **/
  indeterminate?: boolean;
  preventOnClickPropagation?: boolean;
} & Styleable;
const InputCheckbox: React.FunctionComponent<InputCheckboxProps> = ({
  disabled,
  onClick,
  checked,
  description,
  theme,
  checkboxColor,
  className,
  indeterminate = false,
  preventOnClickPropagation = false,
}) => {
  const inputId = uuidv4();
  const checkboxRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (checkboxRef?.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
      className={className}
    >
      <input
        id={inputId}
        ref={checkboxRef}
        type="checkbox"
        disabled={disabled}
        css={css`
          margin-right: 0.3rem;
          accent-color: ${checkboxColor ? colorToCode(checkboxColor) : 'default'};
        `}
        checked={checked}
        onClick={e => {
          if (preventOnClickPropagation) {
            e.stopPropagation();
          }

          if (typeof onClick !== 'undefined') {
            onClick(e);
          }
        }}
        // Added this line to prevent console warning on checkbox input with checked prop missing onchange handler
        onChange={_ => {}}
      />
      {description && (
        <label
          css={css`
            font-weight: 400;
            margin-bottom: 0;
            ${theme ? `color: ${colorToCode('light-000')};` : ''}
          `}
          htmlFor={inputId}
        >
          {description}
        </label>
      )}
    </div>
  );
};

export default InputCheckbox;
