import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import ScorecardDeleteModal from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-delete/packages/scorecard-delete-modal/ScorecardDeleteModal';
import Testable from '~/neo-ui/packages/testable/Testable';

const ScorecardDeleteButton = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Testable testId={'scorecard-delete-button'}>
        <Button
          size={'md'}
          iconRight={'Trash'}
          theme={'negative'}
          onClick={() => setModalOpen(true)}
        >
          Delete...
        </Button>
      </Testable>
      <ScorecardDeleteModal
        isOpen={isModalOpen}
        onDismiss={() => setModalOpen(false)}
      />
    </React.Fragment>
  );
};

export default ScorecardDeleteButton;
