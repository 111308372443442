import { InitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import { useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { initiativeGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseInitiativeGet = { initiative?: InitiativeDto };

const useInitiativeGet = (initiativeId?: string): UseInitiativeGet => {
  const [initiative, setInitiative] = useState<InitiativeDto>();
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      if (typeof initiativeId === 'undefined') {
        return;
      }
      const response = await callApi(() => initiativeGet({ initiativeId }));
      if (!response) {
        return;
      }
      setInitiative(response.initiativeInfo);
    })();
  }, [callApi, initiativeId]);

  return { initiative };
};

export default useInitiativeGet;
