// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


/**
 * Information on the warranty coverage of an asset
 */
export interface AssetCoverage
{
  /**
   * Asset information
   */
  coveredAsset: CoveredAsset;
  /**
   * Coverage information of the asset
   */
  coverageInformation: Coverage;
}


/**
 * Coverage information of the asset
 */
export interface Coverage
{
  /**
   * Information linked to the coverage card for the asset
   */
  coverageCardId: CoverageCardIdentifier;
  /**
   * Coverage period information for the asset
   */
  coveragePeriod: CoveragePeriod;
  /**
   * Determine if an asset can be renewed
   */
  isRenewable: boolean;
}


/**
 * Encapsulates the information linked to the coverage card for the asset
 * currently it is the estimate
 */
export interface CoverageCardIdentifier
{
  /**
   * Order Number for the asset's warranty coverage
   */
  orderNumber: string;
}


/**
 * Encapsulates the coverage period information for the asset
 */
export interface CoveragePeriod
{
  /**
   * This is the warranty start date, once a purchased warranty has been registered
   */
  startsAt: string;
  /**
   * This is the expiration date, once a purchased warranty has been registered
   */
  endsAt: string;
  /**
   * To determine if asset is covered by Wm renewals
   */
  isCoveredByWmRenewals: boolean;
  /**
   * Coverage status of the asset
   */
  coverageStatusEnum: CoverageStatusEnum;
}


/**
 * Note: this coverage status enum is referenced to the StatusStateFactory.Enum of Beast
 * See {3C278B36-BD99-4E12-BFF8-A88406EB31C1}
 * 
 * Ensure all tables and the referenced enum are up to date if adding new entries.
 */
export enum CoverageStatusEnum
{
  In = "In",
  Out = "Out",
  Soon = "Soon",
  Unknown = "Unknown",
}



export interface CoveredAsset
{
  /**
   * Identifier of the asset the request is submitted for (i.e., AssetAccountObfuscatedId)
   */
  assetId: string;
  /**
   * Serial number of the asset the request is submitted for
   */
  serialNumber: string;
  /**
   * Name of the asset
   */
  name: string;
  /**
   * Model number of the asset
   */
  modelNumber: string | undefined;
  /**
   * Name of the organization that owns the asset
   */
  organizationName: string;
  /**
   * Obfuscated Id of the organization that owns the asset
   */
  organizationId: string;
}

