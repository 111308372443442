import * as React from 'react';
import ToastContext from '~/neo-ui/packages/toast/context/ToastContext';
import ReactDOM from 'react-dom';
import { css } from '@emotion/react';
import Toast, { ToastProps } from '~/neo-ui/packages/toast/Toast';
import { mobileBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import ToastError from '~/neo-ui/packages/toast/packages/toast-error/ToastError';

/**
 * Global provider that allows toasts to appear in the dom
 * Two types of toasts are supported:
 *   - alerts in the bottom-left
 *   - notifications in the top-right
 */
const ToastProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const [toasts, setToasts] = React.useState<ToastProps[]>([]);

  const addToast: (toast: ToastProps) => void = React.useCallback(toast => {
    setToasts(toasts => toasts.concat(toast));
  }, []);

  const removeToast: (id: string) => void = React.useCallback(id => {
    setToasts(toasts => toasts.filter(toast => toast.toastUniqueId !== id));
  }, []);

  const toastContainerElementRef = React.useRef<HTMLDivElement>();

  if (!toastContainerElementRef.current && typeof document !== 'undefined') {
    // Lazy initialization
    const element = document.createElement('div');

    element.setAttribute('tabindex', '-1');

    element.style.position = 'relative';
    element.style.zIndex = '1100';

    document.body.appendChild(element);
    toastContainerElementRef.current = element;
  }

  // Only show one error at a time to prevent screen spam in case of network errors
  const firstError = toasts.find(toast => toast.toastType === 'error');

  // If we are in an iframe, we need to adjust the position of the notifications
  const isIframe = window !== window.top;

  return (
    <ToastContext.Provider
      value={{
        addToast,
        removeToast,
        toasts,
      }}
    >
      {typeof toastContainerElementRef.current !== 'undefined' &&
        ReactDOM.createPortal(
          <div
            css={css`
              z-index: 1100;
            `}
          >
            <div
              css={css`
                width: 25rem;
                position: fixed;
                right: 0.625rem;
                top: calc(var(--app-bar-height, 0rem) + 4.6875rem);
                @media screen and (min-width: ${mobileBreakpointRem}rem) {
                  top: calc(var(--app-bar-height, 0rem) + 6.75rem);
                }
              `}
            >
              {toasts.map(
                toast =>
                  toast.toastType === 'notification' && (
                    <Toast
                      key={toast.toastUniqueId}
                      {...toast}
                    />
                  ),
              )}
            </div>
            <div
              css={css`
                width: 25rem;
                position: fixed;
                bottom: 0.625rem;
                left: 1.25rem;
                @media screen and (min-width: ${mobileBreakpointRem}rem) {
                  left: ${isIframe ? '5.5rem' : '14.375rem'};
                }
              `}
            >
              {firstError && (
                <ToastError
                  key={firstError.toastUniqueId}
                  {...firstError}
                />
              )}
              {toasts.map(
                toast =>
                  toast.toastType === 'alert' && (
                    <Toast
                      key={toast.toastUniqueId}
                      {...toast}
                    />
                  ),
              )}
            </div>
          </div>,
          toastContainerElementRef.current,
        )}
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
