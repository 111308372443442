import * as React from 'react';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import ButtonInternal, { ButtonInternalColorMap, ButtonInternalProps, ButtonInternalRef } from '~/neo-ui/packages/button/ButtonInternal';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';

export type ButtonProps = Omit<ButtonInternalProps, 'colorMap'> & Styleable & Themeable;

/**
 * Regular button to be used in the app
 *
 * Providing an anchor prop will switch the html tag from `button` to `a`
 *
 * Supports theme to apply color variations
 *
 * Implements ButtonInternal as the base component
 */
const Button = React.forwardRef<ButtonInternalRef, ButtonProps>(
  (
    {
      id,
      loading = false,
      size = 'md',
      anchor,
      iconLeft,
      iconRight,
      iconColorOverride,
      iconSizeOverride,
      disabled: isSetToDisabled = false,
      preventOnClickPropagation,
      onClick,
      theme,
      invertColorMap = false,
      className,
      children,
    }: ButtonProps,
    ref,
  ) => {
    const { themeMap } = useTheme(theme);

    const colorMap: ButtonInternalColorMap = {
      backgroundColorRest: themeMap.foregroundAccent,
      backgroundColorHover: themeMap.foregroundRaised,
      backgroundColorActive: themeMap.foregroundLowered,
      backgroundColorFocus: themeMap.foregroundAccent,

      textColorRest: themeMap.foregroundContrast,
      textColorHover: themeMap.foregroundContrast,
      textColorActive: themeMap.foregroundContrast,
      textColorFocus: themeMap.foregroundContrast,

      borderColorRest: 'dark-900-12',
      borderColorHover: 'dark-900-12',
      borderColorActive: 'dark-900-12',
      borderColorFocus: themeMap.backgroundLowered,
    };

    // Regardless of what's set, the button is disabled when loading
    const disabled = isSetToDisabled || loading;

    return (
      <ButtonInternal
        className={className}
        id={id}
        size={size}
        anchor={anchor}
        iconLeft={iconLeft}
        iconRight={iconRight}
        iconColorOverride={iconColorOverride}
        iconSizeOverride={iconSizeOverride}
        preventOnClickPropagation={preventOnClickPropagation}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        colorMap={colorMap}
        invertColorMap={invertColorMap}
        ref={ref}
      >
        {children}
      </ButtonInternal>
    );
  },
);

export default Button;
