import useTreeImpactSummaryGet from '~/wm/packages/tree-impact/hooks/useTreeImpactSummaryGet';

export type UseWarrantyTreeImpactAccountSummaryGet = () =>
  | {
      treesPerOrderUnit: number;
      totalTreesPlanted: number;
      totalOrdersUnits: number;
    }
  | undefined;

const useWarrantyTreeImpactAccountSummaryGet: UseWarrantyTreeImpactAccountSummaryGet = () => {
  const { treeImpactSummary } = useTreeImpactSummaryGet('warranty');

  if (!treeImpactSummary) {
    return undefined;
  }

  return {
    treesPerOrderUnit: treeImpactSummary.treesPerOrderUnit,
    totalTreesPlanted: treeImpactSummary.totalTreesPlanted,
    totalOrdersUnits: treeImpactSummary.totalOrdersUnits,
  };
};

export default useWarrantyTreeImpactAccountSummaryGet;
