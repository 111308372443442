import { disposalOrderPickupImageUpload } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { useCallback, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { DisposalOrderPickupImageUploadPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderPickup/Packages/DisposalOrderPickupImage/Dto/Model.gen';
import { Discriminant } from '@AssetManagementClient/Primitives/Results/DisposalOrderPickupImageUploadControllerNested/Response_/DisposalOrderPickupImageUploadError_/ResultNested.gen';

export type UseDisposalOrderPickupImageUpload = (onImageUploadSuccess: () => void) => {
  uploadPickupImage: (imageUploadPayload: DisposalOrderPickupImageUploadPayloadDto) => void;
  isUploading: boolean;
  isUploadingFailed: boolean | undefined;
};

const useDisposalOrderPickupImageUpload: UseDisposalOrderPickupImageUpload = onImageUploadSuccess => {
  const { callApi } = useApi();
  const [isUploading, setUploading] = useState(false);
  const [isUploadingFailed, setUploadingFailed] = useState<boolean | undefined>(undefined);

  const uploadPickupImage = useCallback(
    async (imageUploadPayload: DisposalOrderPickupImageUploadPayloadDto) => {
      await (async () => {
        setUploading(true);
        const response = await callApi(() => disposalOrderPickupImageUpload({ imageUploadPayload }));

        if (!response) {
          return;
        }

        if (response.case === Discriminant.Ok) {
          setUploadingFailed(false);
          onImageUploadSuccess();
        } else {
          setUploadingFailed(true);
        }
        setUploading(false);
      })();
    },
    [callApi, onImageUploadSuccess],
  );

  return { uploadPickupImage, isUploading, isUploadingFailed };
};

export default useDisposalOrderPickupImageUpload;
