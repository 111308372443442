import useEngagementActionListMutation, {
  UseEngagementActionListMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListMutation';
import { initiativeEngagementActionUnlink } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type EngagementActionInitiativeUnlinkPayload = {
  engagementActionId: string;
  initiativeLinkId: string;
};
export type UseEngagementActionInitiativeUnlink = {
  unlinkInitiative: (unlinkPayload: EngagementActionInitiativeUnlinkPayload) => void;
  isUnlinkingInitiative: boolean;
};

const useEngagementActionInitiativeUnlink = (options?: UseEngagementActionListMutationOptions): UseEngagementActionInitiativeUnlink => {
  const [unlinkInitiative, isUnlinkingInitiative] = useEngagementActionListMutation(
    (unlinkPayload: EngagementActionInitiativeUnlinkPayload) =>
      initiativeEngagementActionUnlink({
        engagementActionId: unlinkPayload.engagementActionId,
        initiativeLinkId: unlinkPayload.initiativeLinkId,
      }),
    options,
  );

  return {
    unlinkInitiative,
    isUnlinkingInitiative,
  };
};

export default useEngagementActionInitiativeUnlink;
