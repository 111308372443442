import WarrantyCartFilterFormData from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-filters/packages/warranty-cart-filter-form/WarrantyCartFilterFormData';
import { createContext } from 'react';

export type FiltersMutationContextData = {
  onFilterChanged: (arg: WarrantyCartFilterFormData) => Promise<void>;
  clearFilter: () => Promise<void>;
};

const FiltersMutationContext = createContext<FiltersMutationContextData>({
  onFilterChanged: async (_arg: WarrantyCartFilterFormData) => {},
  clearFilter: async () => {},
});

export default FiltersMutationContext;
