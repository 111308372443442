import * as Yup from 'yup';
import { EngagementActionCreateFormDataType } from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/types/EngagementActionCreateFormDataType';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const engagementActionCreateFormValidationSchema: Yup.ObjectSchema<any> = Yup.object().shape<Partial<EngagementActionCreateFormDataType>>({
  description: Yup.string()
    .typeError('Description must be text')
    .required('Required.')
    // Duped in Beast: b11203ed-d2e6-4cda-b260-eebec2c14ea2
    .max(10000, 'This description is too long.'),
  dueAt: Yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format'),
  assignedUserIds: Yup.array().of(Yup.string()),
});
export default engagementActionCreateFormValidationSchema;
