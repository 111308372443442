import Button from '~/neo-ui/packages/button/Button';
import { useState } from 'react';
import useTicketFieldAvailabilitiesContext from '~/wm/packages/integration/packages/ticket/context/hooks/useTicketFieldAvailabilitiesContext';
import {
  descriptionFieldKeyId,
  titlefieldKeyId,
} from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/TicketCreateForm';
import TicketCreateButton from '~/wm/packages/integration/packages/ticket/packages/ticket-create-button/TicketCreateButton';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import {
  BudgetLineItemFormData,
  BudgetQuarterFormData,
  InitiativeSaveFormData,
} from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { TicketCreateFieldValueDto } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Dto/Model.gen';
import formatTicketTitleInitiative from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/format/formatTicketTitleInitiative';
import formatTicketDescriptionInitiative from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/format/formatTicketDescriptionInitiative';
import useTotalCostRecurringFeesCalculated from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/hooks/useTotalCostRecurringFeesCalculated';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';
import useTotalCostOneTimeFeesCalculated from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/hooks/useTotalCostOneTimeFeesCalculated';
import useUserContext from '~/wm/hooks/useUserContext';

const ticketFieldValuesFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.ticketFieldValues;

const nameFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.name;
const budgetQuarterFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.budgetQuarter;

const executiveSummaryFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.executiveSummary;

const InitiativeSaveTicketButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { fullName } = useUserContext();
  const { currencyInfo } = useRegionalSettingsInfoContext();
  const { setFormInput, getFormInput, formData } = useFormContext<InitiativeSaveFormData>();

  const { isOrganizationRegisteredForTickets, hasTicketIntegration, ticketFieldOptions } = useTicketFieldAvailabilitiesContext();

  const initiativeTitle = getFormInput<string>(nameFieldKey).value;
  const budgetQuarter = getFormInput<BudgetQuarterFormData | undefined>(budgetQuarterFieldKey).value;
  const executiveSummary = getFormInput<string>(executiveSummaryFieldKey).value;

  const { formattedTotalCostRecurringFeesByFrequency } = useTotalCostRecurringFeesCalculated({
    currencyInfo,
    amountOfAssets: formData.assetIds.length,
    recurringLineItems: formData.recurringLineItems,
  });

  const budgetLineItems = getFormInput<BudgetLineItemFormData[]>(formData => formData.budgetLineItems).value;

  const { formattedTotalCostOneTimeFees } = useTotalCostOneTimeFeesCalculated({
    currencyInfo,
    budgetLineItems,
    amountOfAssets: formData.assetIds.length,
  });

  return (
    <TicketCreateButton
      isOpen={isOpen}
      hasError={false}
      isCreatingTicket={false}
      buttonComponent={
        <Button
          iconLeft={'IntegrationTicket'}
          theme={'primary'}
          onClick={() => setIsOpen(true)}
        >
          Create PSA Ticket
        </Button>
      }
      fieldOptions={ticketFieldOptions}
      isOrganizationRegisteredForTickets={isOrganizationRegisteredForTickets}
      hasTicketIntegration={hasTicketIntegration}
      onCreateTicket={fieldValues => {
        setFormInput<TicketCreateFieldValueDto[]>(ticketFieldValuesFieldKey, fieldValues);
        setIsOpen(false);
      }}
      onDismiss={() => {
        setIsOpen(false);
      }}
      defaultFormData={{
        ticketFieldValues: {
          [titlefieldKeyId]: formatTicketTitleInitiative(initiativeTitle, budgetQuarter, ticketFieldOptions),
          [descriptionFieldKeyId]: formatTicketDescriptionInitiative(
            executiveSummary,
            formattedTotalCostOneTimeFees,
            formattedTotalCostRecurringFeesByFrequency,
            ticketFieldOptions,
            fullName,
          ),
        },
      }}
    />
  );
};

export default InitiativeSaveTicketButton;
