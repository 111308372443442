import { css } from '@emotion/react';
import * as React from 'react';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { Styleable } from '~/neo-ui/model/capacity';

export type StepProgressProps = {
  theme: Color;
  currentStepIndex: number;
  totalSteps: number;
} & Styleable;

const StepProgress: React.FunctionComponent<StepProgressProps> = ({ theme, currentStepIndex, totalSteps, className }) => (
  <div
    css={css`
      display: inline-flex;
      align-items: center;
    `}
    className={className}
  >
    {Array.from({ length: totalSteps }, (_, stepIndex) => (
      <div
        css={css`
          display: inline-flex;
          align-items: center;
        `}
        key={stepIndex}
      >
        <span
          data-testid={`step-progress-${stepIndex}`}
          css={css`
            height: 0.75rem;
            width: 0.75rem;
            background-color: ${stepIndex === currentStepIndex ? colorToCode('light-000') : colorToCode(theme)};
            border-radius: 50%;
          `}
        />
        {stepIndex < totalSteps - 1 && (
          <span
            css={css`
              height: 0.125rem;
              width: 3.125rem;
              background-color: ${colorToCode(theme)};
            `}
          />
        )}
      </div>
    ))}
  </div>
);

export default StepProgress;
