import React, { useState } from 'react';
import RoadmapInitiativesProvider from '~/wm/packages/strategy/packages/roadmap-page/context/RoadmapInitiativesProvider';
import RoadmapUrlProvider from '~/wm/packages/strategy/packages/roadmap-page/context/RoadmapUrlProvider';
import RoadmapShareButton from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap-share-button/RoadmapShareButton';
import OrganizationProvider from '~/wm/packages/organization/context/OrganizationProvider';
import RoadmapFeatureFlagsProvider from '~/wm/packages/strategy/packages/roadmap-page/context/RoadmapFeatureFlagsProvider';
import Testable from '~/neo-ui/packages/testable/Testable';
import LayoutHeader from '~/neo-ui/packages/layout/packages/header/LayoutHeader';
import InitiativeDraftCreateButton from '~/wm/packages/strategy/packages/initiative/packages/initiative-draft/packages/initiative-draft-create-button/InitiativeDraftCreateButton';
import RoadmapV2 from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/RoadmapV2';
import InitiativeAvailabilitiesProvider from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/InitiativeAvailabilitiesProvider';
import RoadmapViewModeButtonGroup from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap-view-mode-button-group/RoadmapViewModeButtonGroup';
import RoadmapPagination from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-pagination/RoadmapPagination';
import { Enum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import RegionalSettingsInfoProvider from '~/wm/packages/settings/packages/regional-settings/context/RegionalSettingsInfoProvider';
import FeatureAccessBannerRoadmap from '~/wm/packages/feature/packages/feature-access-banner/packages/feature-access-banner-roadmap/FeatureAccessBannerRoadmap';

/**
 * The view mode for the roadmap
 */
export type RoadmapViewMode = 'Roadmap' | 'List';

export type RoadmapPageProps = {
  organizationId: string;
  organizationName: string;
  roadmapPreviewUrl: string;
  insightUrl: string;
  upgradeUrl: string;
  growUrl: string;
  organizationRegionalSettingsUrl: string;
  enableEnceladus: boolean;
  enabledLmRoadmapsPdfOverview: boolean;
  enabledInitiativeTickets: boolean;
};

const RoadmapPage: React.FunctionComponent<RoadmapPageProps> = ({
  organizationId,
  organizationName,
  roadmapPreviewUrl,
  insightUrl,
  upgradeUrl,
  growUrl,
  organizationRegionalSettingsUrl,
  enabledLmRoadmapsPdfOverview,
  enabledInitiativeTickets,
}) => {
  const [roadmapViewMode, setRoadmapViewMode] = useState<RoadmapViewMode>('Roadmap');

  let hasFocusInitiativeId = false;
  let currentLocation;
  if (typeof document !== 'undefined') {
    currentLocation = window.location.href;
    hasFocusInitiativeId = currentLocation.includes('#');
  }

  return (
    <OrganizationProvider
      organizationId={organizationId}
      organizationName={organizationName}
    >
      <RegionalSettingsInfoProvider
        frontendScope={{
          type: Enum.Organization,
          organizationId,
        }}
      >
        <InitiativeAvailabilitiesProvider frontendScope={{ organizationId, type: Enum.Organization }}>
          <RoadmapInitiativesProvider
            organizationId={organizationId}
            organizationName={organizationName}
            focusInitiativeId={
              hasFocusInitiativeId && typeof currentLocation !== 'undefined'
                ? `${currentLocation.substring(currentLocation.indexOf('#') + 1)}`
                : undefined
            }
            isPresenting={false}
          >
            <RoadmapFeatureFlagsProvider
              enabledLmRoadmapsPdfOverview={enabledLmRoadmapsPdfOverview}
              enabledInitiativeTickets={enabledInitiativeTickets}
            >
              <RoadmapUrlProvider
                insightUrl={insightUrl}
                upgradeUrl={upgradeUrl}
                growUrl={growUrl}
                organizationRegionalSettingsUrl={organizationRegionalSettingsUrl}
              >
                <LayoutHeader
                  leftControls={[{ expanded: <RoadmapPagination roadmapViewMode={roadmapViewMode} /> }]}
                  rightControls={[
                    {
                      expanded: (
                        <RoadmapShareButton
                          organizationId={organizationId}
                          roadmapPreviewUrl={roadmapPreviewUrl}
                        />
                      ),
                    },
                    {
                      expanded: <InitiativeDraftCreateButton type={'button'}>New Initiative</InitiativeDraftCreateButton>,
                    },
                    {
                      expanded: (
                        <RoadmapViewModeButtonGroup
                          roadmapViewMode={roadmapViewMode}
                          onRoadmapViewModeChange={setRoadmapViewMode}
                        />
                      ),
                    },
                  ]}
                />
                <FeatureAccessBannerRoadmap />
                <Testable testId={'roadmap'}>
                  <RoadmapV2 roadmapViewMode={roadmapViewMode} />
                </Testable>
              </RoadmapUrlProvider>
            </RoadmapFeatureFlagsProvider>
          </RoadmapInitiativesProvider>
        </InitiativeAvailabilitiesProvider>
      </RegionalSettingsInfoProvider>
    </OrganizationProvider>
  );
};

export default RoadmapPage;
