import { ProgressResponse as ApiProgressResponse, Enum } from '@AssetManagementClient/AssetManagement/Packages/Progress/Model.gen';
import { Percentage } from '~/extensions/packages/percentage/percentage';
import { assertNeverOrThrow } from '~/extensions/packages/types/assertNever';
import ProgressResponse from '~/neo-ui/packages/progress/model/ProgressResponse';

const convertApiProgress = (progressResponse: ApiProgressResponse): ProgressResponse => {
  switch (progressResponse.type) {
    case Enum.Starting:
      return { type: 'starting' };
    case Enum.InProgress:
      return {
        type: 'in-progress',
        progress: {
          message: progressResponse.progress.message,
          percentage: new Percentage(progressResponse.progress.percentage),
        },
      };
    case Enum.Done:
      return { type: 'done' };
  }
  assertNeverOrThrow(progressResponse);
};

export default convertApiProgress;
