/* eslint-disable no-console */
import { ComponentPropsWithoutRef } from 'react';
import Button from '~/sp-ui/buttons/Button';
import Message from '~/sp-ui/message';
import Text from '~/sp-ui/text';
import theme from '~/sp-ui/theme';
import formatCurrency from '../../formatCurrency';
import getDaythOfMonth from '../../getDaythOfMonth';
import { type UseQuoteSummaryApi } from '../../useQuoteSummaryApi';

export interface FloatingSideBoxProps extends ComponentPropsWithoutRef<'div'> {
  editingStates: {
    billing: boolean;
    paymentMethod: boolean;
    paymentSummary: boolean;
  };
  quoteSummaryApi: UseQuoteSummaryApi;
  onNextClicked: () => void;
  onPayNowClicked: () => void;
  canPayNow?: boolean;
}

const FloatingSideBox = (props: FloatingSideBoxProps) => {
  const { editingStates, quoteSummaryApi, onNextClicked, onPayNowClicked, canPayNow = true, ...rest } = props;
  const { isLoading: quoteSummaryLoading } = quoteSummaryApi;

  return (
    <div
      css={[
        {
          display: 'grid',
          width: '440px',
          alignSelf: 'start',
          position: 'sticky',
          top: 'calc(40px + 2rem)',
          gap: '1rem',
        },
      ]}
      {...rest}
    >
      <div
        css={[
          {
            display: 'grid',
            padding: '32px 16px',
            gridTemplateColumns: '1fr auto',
            borderRadius: 8,
            backgroundColor: theme.palette.white,
            rowGap: '0.5rem',
          },
          theme.shadows[2],
        ]}
      >
        {(editingStates.billing || editingStates.paymentMethod) &&
          quoteSummaryApi.quoteSummary &&
          (() => {
            const { totalBeforeTaxes, taxAmountInCents } = quoteSummaryApi.quoteSummary;

            return (
              <>
                <Button
                  size="large"
                  onClick={onNextClicked}
                  css={{ gridColumn: '1 / -1' }}
                >
                  Save and continue
                </Button>

                <Text
                  variant="small"
                  css={{ textAlign: 'center', marginBottom: '1rem', gridColumn: '1 / -1', marginBlock: '0.5rem' }}
                >
                  You'll still have a chance to review your subscription before it's final.
                </Text>

                <Text>Total before taxes</Text>
                <Text css={{ justifySelf: 'right' }}>{formatCurrency(totalBeforeTaxes)}</Text>
                {!!taxAmountInCents && (
                  <>
                    <Text>Taxes</Text>
                    <Text css={{ justifySelf: 'right' }}>{!!taxAmountInCents ? formatCurrency(taxAmountInCents) : '-'}</Text>
                    <hr css={{ backgroundColor: theme.palette.grey1, gridColumn: '1 / -1', width: '100%', margin: 0 }} />
                    <Text css={{ fontWeight: 700 }}>Today's payment</Text>
                    <Text css={{ justifySelf: 'right' }}>{formatCurrency(totalBeforeTaxes + taxAmountInCents)}</Text>
                  </>
                )}
              </>
            );
          })()}

        {editingStates.paymentSummary &&
          quoteSummaryApi.quoteSummary &&
          (() => {
            const { totalBeforeTaxes, taxAmountInCents, nextAmountInCentsBeforeTaxes, nextTaxAmountInCents } = quoteSummaryApi.quoteSummary;

            return (
              <>
                <Button
                  size="large"
                  onClick={onPayNowClicked}
                  css={{ gridColumn: '1 / -1' }}
                  loading={quoteSummaryLoading}
                  disabled={quoteSummaryLoading}
                >
                  {canPayNow ? 'Pay now' : 'Enter billing address'}
                </Button>

                <Text
                  variant="small"
                  css={{ textAlign: 'center', marginBottom: '1rem', gridColumn: '1 / -1', marginBlock: '0.5rem' }}
                >
                  {canPayNow && (
                    <>
                      By paying now, you agree to ScalePad's{' '}
                      <a
                        href="/pages/privacy"
                        target="_blank"
                      >
                        privacy policy
                      </a>{' '}
                      and{' '}
                      <a
                        href="/pages/terms"
                        target="_blank"
                      >
                        terms of service
                      </a>
                      .
                    </>
                  )}
                  {!canPayNow && 'You’ll still have a chance to review your subscription before it’s final.'}
                </Text>

                <Text>Total before taxes</Text>
                <Text css={{ justifySelf: 'right' }}>{formatCurrency(totalBeforeTaxes)}</Text>

                {!!taxAmountInCents && (
                  <>
                    <Text>Taxes</Text>
                    <Text css={{ justifySelf: 'right' }}>{!!taxAmountInCents ? formatCurrency(taxAmountInCents) : <>&ndash;</>}</Text>

                    <hr css={{ backgroundColor: theme.palette.grey1, gridColumn: '1 / -1', width: '100%', margin: 0 }} />
                    <Text css={{ fontWeight: 700 }}>Today's payment</Text>
                    <Text css={{ justifySelf: 'right' }}>
                      {canPayNow ? formatCurrency(totalBeforeTaxes + taxAmountInCents) : <>&ndash;</>}
                    </Text>
                  </>
                )}

                {nextAmountInCentsBeforeTaxes + nextTaxAmountInCents > 0 && canPayNow && (
                  <Text
                    variant="small"
                    css={{ marginTop: '1.5rem', gridColumn: '1 / -1' }}
                  >
                    Afterward, your estimated billed amount will be{' '}
                    <Text css={{ fontWeight: 700 }}>{formatCurrency(nextAmountInCentsBeforeTaxes + nextTaxAmountInCents)}</Text> per month
                    on the <Text css={{ fontWeight: 700 }}>{getDaythOfMonth()}</Text> day of each month. This estimate{' '}
                    {nextTaxAmountInCents ? 'includes' : 'does not include'} taxes and is based on your current subscription plan.
                  </Text>
                )}
              </>
            );
          })()}
      </div>

      <Message type="info">
        <div css={{ display: 'flex', alignItems: 'start' }}>
          <Text variant="small">
            Questions or concerns? Let us reach out to you at a time that works best.{' '}
            <a
              href="https://www.scalepad.com/contact"
              target="_blank"
              css={{ textDecoration: 'underline' }}
            >
              Schedule a call with a representative
            </a>
          </Text>
        </div>
      </Message>
    </div>
  );
};

export default FloatingSideBox;
