import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import InitiativeSaveTemplateSaveButton from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-template-section/packages/initiative-save-template-save/InitiativeSaveTemplateSaveButton';
import InitiativeSaveTemplateApplyButton from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-template-section/packages/initiative-save-template-apply/InitiativeSaveTemplateApplyButton';

const InitiativeSaveTemplateSection = () => (
  <Box
    padding={'padding100'}
    borderRadius={'radius400'}
    boxShadow={'shadow100'}
    css={css`
      display: flex;
      flex-wrap: wrap;
      gap: 1.25rem;
    `}
  >
    <div>
      <Header
        size={7}
        color={'dark-050'}
        css={css`
          margin: 0.125rem 0 0.625rem 0.375rem;
        `}
      >
        Template
      </Header>
      <InitiativeSaveTemplateSaveButton />
      <InitiativeSaveTemplateApplyButton />
    </div>
  </Box>
);

export default InitiativeSaveTemplateSection;
