import GlobalError from '~/neo-ui/packages/error/model/GlobalError';
import { ApiResult } from '~/wm/packages/api/packages/api-result/callApiOrError';
import getApiErrorDefinition from '../model/getApiErrorDefinition';

const translateToGlobalError = async <T>(
  apiCall: () => Promise<ApiResult<T>>,
  mapResponse?: (response: T) => GlobalError | undefined,
): Promise<GlobalError | undefined> => {
  const apiResult = await apiCall();

  // Api result error mapping
  if (apiResult.type === 'error') {
    const apiError = getApiErrorDefinition(apiResult.data.type);
    return apiError;
  }

  // Endpoint-specific error mapping
  if (mapResponse) {
    return mapResponse(apiResult.data);
  }

  // No error
  return undefined;
};

export default translateToGlobalError;
