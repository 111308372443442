import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type LayoutBarProps = { children: React.ReactNode } & Styleable;

/**
 * A bar that is meant to attach to the top of a LayoutFooter or the bottom of a LayoutHeader
 *
 * **DO NOT** use this component standalone, it lacks the position styles to format itself properly
 * Use a LayoutHeader or LayoutFooter and access layoutBarComponent prop instead
 */
const LayoutBar = ({ children, className }: LayoutBarProps) => (
  <div
    className={className}
    css={css`
      padding: 0.625rem 1.875rem;
      background-color: ${colorToCode('light-100')};

      border-width: 0.0625rem 0 0.0625rem 0;
      border-style: solid;
      border-color: ${colorToCode('dark-900-24')};
    `}
  >
    {children}
  </div>
);

export default LayoutBar;
