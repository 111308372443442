import { SVGProps, forwardRef, memo } from 'react';

const UnionPayIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="24"
        viewBox="0 0 36 24"
        fill="none"
        {...props}
      >
        <rect
          x="0.5"
          y="0.5"
          width="35"
          height="23"
          rx="2.5"
          fill="white"
        />
        <rect
          x="0.5"
          y="0.5"
          width="35"
          height="23"
          rx="2.5"
          stroke="#DDDDDD"
        />
        <path
          d="M7.99999 20C7.69399 20 7.40499 19.86 7.21499 19.62C7.02599 19.38 6.95599 19.066 7.02699 18.768L10.351 4.768C10.457 4.318 10.86 4 11.323 4H18C18.306 4 18.595 4.14 18.784 4.38C18.974 4.62 19.044 4.934 18.972 5.231L15.649 19.231C15.543 19.682 15.14 20 14.677 20H7.99999Z"
          fill="#E53935"
        />
        <path
          d="M12 20C11.694 20 11.405 19.86 11.215 19.62C11.026 19.38 10.956 19.066 11.027 18.768L14.351 4.768C14.457 4.318 14.86 4 15.323 4H22C22.306 4 22.595 4.14 22.784 4.38C22.974 4.62 23.044 4.934 22.972 5.231L19.649 19.231C19.543 19.682 19.14 20 18.677 20H12Z"
          fill="#3949AB"
        />
        <path
          d="M18 20C17.694 20 17.405 19.86 17.215 19.62C17.026 19.38 16.956 19.066 17.027 18.768L20.351 4.768C20.457 4.318 20.86 4 21.323 4H28C28.306 4 28.595 4.14 28.784 4.38C28.974 4.62 29.044 4.934 28.972 5.231L25.649 19.231C25.543 19.682 25.14 20 24.677 20H18Z"
          fill="#00796B"
        />
      </svg>
    );
  }),
);

export default UnionPayIcon;
