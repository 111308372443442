import { organizationEngagementNoteArchive } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementNoteListPanelTabMutation, {
  UseOrganizationEngagementNoteListPanelTabMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/context/hooks/useEngagementNoteListPanelTabMutation';
import engagementDataCollectionSources from '~/wm/packages/organization/packages/engagement/model/engagementDataCollectionSources';

export type UseEngagementNoteListArchive = {
  archiveNote: (engagementNoteId: string) => void;
  isArchivingNote: boolean;
};

const useEngagementNoteListArchive = (options?: UseOrganizationEngagementNoteListPanelTabMutationOptions): UseEngagementNoteListArchive => {
  const [archiveNote, isArchivingNote] = useEngagementNoteListPanelTabMutation(
    (engagementNoteId: string) =>
      organizationEngagementNoteArchive({
        payload: {
          engagementNoteId,
          archivedFrom: engagementDataCollectionSources.engagementNoteArchivingSource.noteList,
        },
      }),
    options,
  );

  return {
    archiveNote,
    isArchivingNote,
  };
};

export default useEngagementNoteListArchive;
