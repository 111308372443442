// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


export enum Enum
{
  MultiSelect = "MultiSelect",
  ButtonSelect = "ButtonSelect",
  SingleSelect = "SingleSelect",
  SingleOperatorMultiSelect = "SingleOperatorMultiSelect",
  RangeValueInput = "RangeValueInput",
  SplitMultiSelect = "SplitMultiSelect",
}



export interface RangeSettings
{
  minLimit: number | undefined;
  maxLimit: number | undefined;
  /**
   * Number of decimal places to be displayed, defaults to DefaultPrecision
   */
  precision: number;
}

