import Header from '~/neo-ui/packages/text/packages/header/Header';
import { Styleable } from '~/neo-ui/model/capacity';
import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import useAutoEmailReportRecipientRemove from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportRecipientRemove';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import AutoEmailReportRecipientAddForm from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-recipients/packages/auto-email-report-recipient-add-form/AutoEmailReportRecipientAddForm';
import AutoEmailReportRecipientAdd from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-recipients/packages/auto-email-report-recipient-add/AutoEmailReportRecipientAdd';
import { AutoEmailReportDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';

export type AutoEmailReportRecipientsProps = {
  autoEmailReport: AutoEmailReportDto;
} & Styleable;

const AutoEmailReportRecipients = ({ autoEmailReport, className }: AutoEmailReportRecipientsProps) => {
  const { organizationId } = useOrganizationContext();
  const { removeAutoEmailReportRecipient, isRemovingAutoEmailReportRecipient } = useAutoEmailReportRecipientRemove(
    organizationId,
    autoEmailReport.autoEmailReportId,
  );
  return (
    <div
      key={autoEmailReport.autoEmailReportRecipients.length}
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <Header
        size={4}
        weight={'bold'}
      >
        Recipients
      </Header>
      <AutoEmailReportRecipientAddForm autoEmailReportId={autoEmailReport.autoEmailReportId}>
        <table className={'table wm-table-white table-outer-bordered wm-vertical-middle-table table-striped'}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {autoEmailReport.autoEmailReportRecipients.map((recipient, index) => (
              <tr
                key={index}
                css={
                  recipient.isBouncing
                    ? css`
                        // PHP styles override this, important is needed
                        background-color: ${colorToCode('warning-400')} !important;
                        color: ${colorToCode('light-000')};
                      `
                    : undefined
                }
              >
                <td>{recipient.fullName}</td>
                <td>{recipient.emailAddress}</td>
                <td
                  css={css`
                    display: flex;
                    align-items: flex-end;
                    gap: 0.5rem;
                  `}
                >
                  {recipient.isBouncing && (
                    <Tooltip content={'The email you chose may not exist. If it looks correct, please contact our support team.'}>
                      <Icon
                        icon={'Warning'}
                        color={'light-000'}
                      />
                    </Tooltip>
                  )}
                  <Button
                    size={'sm'}
                    iconLeft={'Remove'}
                    loading={isRemovingAutoEmailReportRecipient}
                    onClick={() => removeAutoEmailReportRecipient(recipient.id)}
                  />
                </td>
              </tr>
            ))}
            <AutoEmailReportRecipientAdd />
          </tbody>
        </table>
      </AutoEmailReportRecipientAddForm>
    </div>
  );
};

export default AutoEmailReportRecipients;
