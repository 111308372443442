import { useContext } from 'react';
import EmailContext from '~/wm/packages/product/context/EmailContext';

const useEmailContext = () => {
  const emailContext = useContext(EmailContext);
  if (!emailContext) {
    throw new Error('Tried to use EmailContext outside of an EmailProvider');
  }

  return emailContext;
};

export default useEmailContext;
