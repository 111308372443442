import * as React from 'react';
import { ReactNode } from 'react';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';
import { Themeable } from '~/neo-ui/model/capacity';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

type RangeFilterHeaderProps = {
  unit?: string;
  minValue: number | undefined;
  maxValue: number | undefined;
  label: string;
  isOpen: boolean;
} & Themeable;
export const RangeFilterHeader: React.FunctionComponent<RangeFilterHeaderProps> = ({ unit = '', minValue, maxValue, label, isOpen }) => {
  let header: ReactNode;
  const icon = isOpen ? 'ArrowUp' : 'ArrowDown';
  const { themeMap } = useTheme('secondary');

  // no selection
  if (minValue === undefined && maxValue === undefined) {
    header = (
      <div>
        <div>{label}</div>
        <Icon
          icon={icon}
          color={'dark-900'}
        />
      </div>
    );
  } else {
    let formattedLabel: ReactNode;
    // "greater than"
    if (minValue !== undefined && maxValue === undefined) {
      formattedLabel = (
        <div>
          Over {minValue} {unit}
        </div>
      );
    }
    // "less than"
    else if (minValue === undefined && maxValue !== undefined) {
      formattedLabel = (
        <div>
          Under {maxValue} {unit}
        </div>
      );
    }
    // "between"
    else if (minValue !== undefined && maxValue !== undefined) {
      formattedLabel = (
        <div>
          {minValue} - {maxValue} {unit}
        </div>
      );
    }

    header = (
      <div
        css={css`
          background-color: ${colorToCode(themeMap.foregroundAccent)};
          color: ${colorToCode('light-000')};
          width: auto;
        `}
      >
        {formattedLabel}
        <Icon
          css={css`
            margin-left: auto;
          `}
          icon={icon}
          color={'light-000'}
        />
      </div>
    );
  }
  return (
    <div
      css={css`
        & > * {
          padding: 0 0.625rem 0 0.625rem;
          display: flex;
          align-items: center;
          gap: 0.3125rem;
          justify-content: space-between;
        }
        width: 100%;
      `}
    >
      {header}
    </div>
  );
};
