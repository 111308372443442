import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import FormSelectInput from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import * as React from 'react';
import { ContractAvailabilitiesDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Dto/Model.gen';
import { ContractBillingCurrencyDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Billing/Dto/Model.gen';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { css } from '@emotion/react';
import ContractUpsertBillingCostingInputs, {
  ContractBillingCostCalculationFormData,
} from './packages/contract-upsert-billing-costing-inputs/ContractUpsertBillingCostingInputs';

export type ContractUpsertBillingSectionProps<T> = {
  contractAvailabilities: ContractAvailabilitiesDto;
  contractBillingCurrency: ContractBillingCurrencyDto;
  billingCycleFieldKey: FieldKeyExpression<T>;
  costTypeFieldKey: FieldKeyExpression<T>;
  costFieldKey: FieldKeyExpression<T>;
  perSeatCostFieldKey: FieldKeyExpression<T>;
  numberOfSeatsFieldKey: FieldKeyExpression<T>;
  costVariablesFieldKey: FieldKeyExpression<T>;
  isSyncedFromPsa: boolean;
};

const ContractUpsertBillingSection = <T,>({
  contractAvailabilities,
  contractBillingCurrency,
  billingCycleFieldKey,
  costTypeFieldKey,
  costVariablesFieldKey,
  costFieldKey,
  perSeatCostFieldKey,
  numberOfSeatsFieldKey,
  isSyncedFromPsa,
}: ContractUpsertBillingSectionProps<T>) => {
  const { setFormInput } = useFormContext<T>();

  const formFieldCss = css`
    display: flex;
    gap: 1rem;
    align-items: center;
    width: calc(100% / 3);
  `;

  const dropdownOptionsBillingCycle = contractAvailabilities?.billingCycleTypeAvailabilities.map(billingCycle => ({
    label: billingCycle.displayName,
    value: billingCycle.uniqueId,
  }));
  const dropdownOptionsCostType = contractAvailabilities?.billingCostTypeAvailabilities.map(costType => ({
    label: costType,
    value: costType,
  }));

  return (
    <div>
      <Label
        size={'lg'}
        bold={true}
        css={css`
          margin-bottom: 1.5rem;
        `}
      >
        Billing
      </Label>

      <div
        css={css`
          display: flex;
          gap: 2rem;
          align-items: center;
        `}
      >
        <div css={formFieldCss}>
          <Label
            size={'md'}
            bold={true}
            css={css`
              display: flex;
            `}
          >
            Billing Cycle
            <Label
              size={'md'}
              bold={true}
              color={'negative-400'}
            >
              *
            </Label>
          </Label>
          <FormSelectInput
            disabled={isSyncedFromPsa}
            fieldKey={billingCycleFieldKey}
            options={dropdownOptionsBillingCycle}
            css={css`
              flex-grow: 1;
            `}
          />
        </div>
        <div css={formFieldCss}>
          <Label
            size={'md'}
            bold={true}
            css={css`
              display: flex;
            `}
          >
            Cost Type
            <Label
              size={'md'}
              bold={true}
              color={'negative-400'}
            >
              *
            </Label>
          </Label>
          <FormSelectInput
            fieldKey={costTypeFieldKey}
            options={dropdownOptionsCostType}
            css={css`
              flex-grow: 1;
            `}
          />
        </div>
        <div css={formFieldCss} />
      </div>
      <ContractUpsertBillingCostingInputs
        contractBillingCurrency={contractBillingCurrency}
        isSyncedFromPsa={isSyncedFromPsa}
        costFieldKey={costFieldKey}
        perSeatCostFieldKey={perSeatCostFieldKey}
        costVariablesFieldKey={costVariablesFieldKey}
        numberOfSeatsFieldKey={numberOfSeatsFieldKey}
        onUpdate={costVariables => {
          setFormInput<ContractBillingCostCalculationFormData>(costVariablesFieldKey, costVariables);
        }}
      />
      <Label
        size={'sm'}
        muted={true}
        css={css`
          margin-top: 1.5rem;
        `}
      >
        *Input total cost and seats, we'll calculate cost per seat. Or, enter cost per seat, we'll compute total cost.
      </Label>
    </div>
  );
};

export default ContractUpsertBillingSection;
