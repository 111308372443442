import * as Yup from 'yup';
import ScorecardItemFormData from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/form/ScorecardItemFormData';
import categoryCustomKey from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/model/categoryCustomKey';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const scorecardItemFormValidationSchema: Yup.ObjectSchema<any> = Yup.object().shape<Partial<ScorecardItemFormData>>({
  category: Yup.string().required('A category is required.'),
  customCategory: Yup.string()
    .notRequired()
    .when(['category'], category => {
      if (category === categoryCustomKey) {
        return (
          Yup.string()
            .required('A custom category must be specified.')
            // Duped in backend: 135db38e-1eb6-44ff-a7f8-ff2bd786dd90
            .max(40, 'This custom category may only be 40 characters.')
        );
      }
      return;
    }),
  scoreDescription: Yup.string()
    // Duped in backend: 135db38e-1eb6-44ff-a7f8-ff2bd786dd90
    .max(320, 'This description may only be 320 characters.'),
  investmentDescription: Yup.string().max(
    // Duped in backend: 135db38e-1eb6-44ff-a7f8-ff2bd786dd90
    320,
    'This description may only be 320 characters.',
  ),
  investmentCostUnits: Yup.number()
    .typeError('Investment must be a number.')
    .notRequired()
    .when(['isInvestmentUnknown'], isInvestmentUnknown => {
      if (!isInvestmentUnknown) {
        return Yup.number().required('Required.').min(0, 'Investment must be positive').max(20000000, 'Investment is too large.');
      }
      return;
    }),
  isInvestmentUnknown: Yup.bool().required('Required.'),
});

export default scorecardItemFormValidationSchema;
