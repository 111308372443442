import * as React from 'react';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

const PopupFooter: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => (
  <div
    css={css`
      display: flex;
      gap: 0.625rem;
      background-color: ${colorToCode('light-200')};
      padding: 0.5rem 0.8125rem;
      margin: 0.625rem -0.8125rem -0.5rem;
      border-radius: 0 0 0.625rem 0.625rem;
    `}
  >
    {children}
  </div>
);

export default PopupFooter;
