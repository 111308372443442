import { popoverButtonStyles } from '~/neo-ui/packages/popover/Popover';
import { css } from '@emotion/react';
import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import { Styleable } from '~/neo-ui/model/capacity';

export type FilterResetButtonProps = {
  onClick: () => void;
} & Styleable;
export const FilterResetButton: React.FunctionComponent<FilterResetButtonProps> = ({ onClick, className }) => (
  <Button
    className={className}
    iconLeft={'Reload'}
    size={'md'}
    iconSizeOverride={'lg'}
    css={[
      popoverButtonStyles,
      css`
        flex-shrink: 0;
      `,
    ]}
    onClick={onClick}
  >
    Reset filters
  </Button>
);
