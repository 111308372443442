import { useContext } from 'react';
import WizardPageContext, { WizardPageContextValue } from '~/neo-ui/packages/wizard/packages/wizard-page/context/WizardPageContext';

const useWizardPageContext = (): WizardPageContextValue => {
  const wizardContext = useContext(WizardPageContext);

  if (!wizardContext) {
    throw new Error('Called useWizardPageContext outside of a wizard page context');
  }

  return wizardContext;
};

export default useWizardPageContext;
