import { css } from '@emotion/react';
import { parseISO } from 'date-fns';
import * as React from 'react';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import { BillingDetailsDto, PriceDto } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/PricingModel.gen';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type NextTotalInvoiceSummaryProps = {
  billingDetailsDto?: BillingDetailsDto | undefined;
  priceDto: PriceDto | undefined;
  isFirstPayment?: boolean;
  isInForm?: boolean;
};

const NextTotalInvoiceSummary = ({ billingDetailsDto, priceDto, isFirstPayment, isInForm }: NextTotalInvoiceSummaryProps) => {
  if (!isInForm && (!billingDetailsDto?.nextBillingDate || !priceDto)) {
    return (
      <div>
        <p
          css={css`
            color: ${colorToCode('secondary-400')};
            font-size: 2.125rem;
            font-weight: 700;
            text-align: center;
            line-height: 2.5rem;
          `}
        >
          No next invoice
        </p>
      </div>
    );
  }

  if (!priceDto) {
    return <div />;
  }
  const billingDetailsData = billingDetailsDto as BillingDetailsDto;

  return (
    <div
      css={css`
        font-family: 'Roboto', sans-serif;
        vert-align: top;
      `}
    >
      <div
        css={css`
          padding-top: 1.875rem;
          padding-bottom: 1.875rem;
        `}
      >
        <div
          css={css`
            color: #000000;
            font-weight: bold;
            font-size: 1.125rem;
            text-align: center;
            padding-bottom: 0.625rem;
            line-height: 1.3125rem;
          `}
        >
          {isFirstPayment ? 'Your first payment' : 'Your next payment'}
        </div>
        {priceDto.totalPriceWithoutDiscount !== priceDto.currentTotalPrice ? (
          <div
            css={css`
              color: ${colorToCode('dark-050')};
              font-weight: 400;
              font-size: 0.875rem;
              text-decoration: line-through;
              text-align: center;
              padding-bottom: 0.625rem;
              line-height: 1rem;
            `}
          >
            {formatCurrency(priceDto.totalPriceWithoutDiscount, 'USD')}
          </div>
        ) : (
          <div />
        )}
        <div
          css={css`
            color: ${colorToCode('secondary-400')};
            font-size: 2.125rem;
            font-weight: 700;
            text-align: center;
            padding-bottom: 0.625rem;
            line-height: 2.5rem;
          `}
        >
          {formatCurrency(priceDto?.currentTotalPrice, 'USD')}
        </div>
        <div
          css={css`
            color: ${colorToCode('dark-050')};
            font-weight: 400;
            font-size: 0.875rem;
            text-align: center;
            padding-bottom: 0.625rem;
            line-height: 1rem;
          `}
        >
          {priceDto.nextTotalPrice && formatCurrency(priceDto.nextTotalPrice, 'USD') + '/month afterward'}
        </div>
      </div>
      {!isInForm && billingDetailsData.nextBillingDate && (
        <div
          css={css`
            color: ${colorToCode('dark-050')};
            font-size: 0.75rem;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              line-height: 0.875rem;
            `}
          >
            Next billed on{' '}
            {formatDate(parseISO(billingDetailsData.nextBillingDate), {
              format: 'MMM dd, yyyy',
              timezone: TimezoneFormat.Utc,
            })}
            .
          </div>
          <div
            css={css`
              line-height: 0.875rem;
            `}
          >
            Price includes discounts before applicable taxes. Plan subject to change.
            <p />
          </div>
          <div>
            {billingDetailsData.downgradeAt ? (
              <span>
                Your account will be downgraded on{' '}
                {formatDate(parseISO(billingDetailsData.downgradeAt), {
                  format: 'MMM dd, yyyy',
                  timezone: TimezoneFormat.Utc,
                })}
                . Change your mind?{' '}
                <span
                  css={css`
                    color: ${colorToCode('primary-400')};
                  `}
                >
                  Get in touch
                </span>{' '}
                to keep it active.
              </span>
            ) : (
              <span />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NextTotalInvoiceSummary;
