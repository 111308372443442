import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Dropdown, { DropdownOption } from '~/neo-ui/packages/dropdown/Dropdown';
import { css } from '@emotion/react';
import FormErrorMessage from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';

export type MonthAndYearSelectProps<T> = {
  defaultValue: {
    month: number;
    year: number;
  };
  onSelect: (year: number, month: number) => void;
  fieldKeys?: {
    year: FieldKeyExpression<T>;
    month: FieldKeyExpression<T>;
  };
  shouldExpand?: boolean;
} & Styleable;

/**
 * Allows user to select a year and month
 */
const YearMonthSelect = <T,>({ defaultValue, onSelect, fieldKeys, shouldExpand, className }: MonthAndYearSelectProps<T>) => {
  const getCurrentDate = () => {
    const currentDate = new Date();
    return {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth(),
    };
  };
  const currentDate = getCurrentDate();
  const minYear = currentDate.year - 1;
  const maxYear = currentDate.year + 5;

  // Years
  const yearOptions = Array.from(Array(maxYear - minYear).keys())
    .map(currentOffset => minYear + currentOffset)
    .reduce(
      (final: DropdownOption[], year) => [
        ...(final ? final : []),
        {
          label: `${year}`,
          value: `${year}`,
        },
      ],
      [],
    );
  const yearOption = yearOptions.find(option => Number(option.value) === defaultValue.year);
  const [selectedYear, setSelectedYear] = React.useState(
    yearOption ?? ({ label: `${defaultValue.year}`, value: `${defaultValue.year}` } satisfies DropdownOption),
  );

  // Months
  const generateMonthArrayInFormat = (f: Intl.DateTimeFormatOptions['month']) =>
    Array.from(Array(12), (_, i) => new Date(25e8 * (i + 1)).toLocaleString('en-US', { month: f }));
  const monthOptions = generateMonthArrayInFormat('long')
    .map((month, index) => ({ value: index + 1, display: month }))
    .reduce(
      (final: DropdownOption[], month) => [
        ...(final ? final : []),
        {
          label: `${month.display}`,
          value: `${month.value}`,
        },
      ],
      [],
    );

  const monthOption = monthOptions.find((option: DropdownOption) => Number(option.value) === defaultValue.month)!;
  const [selectedMonth, setSelectedMonth] = React.useState(monthOption);

  return (
    <div
      className={className}
      css={css`
        display: flex;
        align-items: center;
        gap: 0.625rem;
      `}
    >
      <div
        css={css`
          ${shouldExpand && 'flex-grow: 1;'}
        `}
      >
        <Dropdown
          options={monthOptions}
          selectedOption={selectedMonth}
          onOptionSelected={selectedOption => {
            setSelectedMonth(selectedOption);
            onSelect(Number(selectedYear.value), Number(selectedOption.value));
          }}
        />
        {typeof fieldKeys !== 'undefined' && <FormErrorMessage fieldKey={fieldKeys.month} />}
      </div>

      <div
        css={css`
          ${shouldExpand && 'flex-grow: 1;'}
        `}
      >
        <Dropdown
          selectedOption={{
            label: selectedYear.label,
            value: selectedYear.value,
          }}
          options={yearOptions}
          onOptionSelected={selectedOption => {
            setSelectedYear(selectedOption);
            onSelect(Number(selectedOption.value), Number(selectedMonth.value));
          }}
        />
        {typeof fieldKeys !== 'undefined' && <FormErrorMessage fieldKey={fieldKeys.year} />}
      </div>
    </div>
  );
};

export default YearMonthSelect;
