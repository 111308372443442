import * as React from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color from '~/neo-ui/packages/color/Color.gen';

interface SalesPitchItemElementProps {
  icon: IconType;
  iconColor: Color | 'no-color';
  summary: string;
  description: string;
}

const SalesPitchItemElement: React.FunctionComponent<SalesPitchItemElementProps> = ({ summary, description, icon, iconColor }) => (
  <div
    css={css`
      line-height: 1.78;
      margin-bottom: 1.25rem;
      display: flex;
      gap: 1rem;
    `}
  >
    <Icon
      css={css`
        margin-top: 0.25rem;
      `}
      icon={icon}
      color={iconColor}
    />
    <div>
      <span
        css={css`
          font-weight: bold;
        `}
      >
        {summary}
      </span>
      <span> – {description}</span>
    </div>
  </div>
);
export default SalesPitchItemElement;
