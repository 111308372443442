import { organizationEngagementNoteCreate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementNoteListPanelTabMutation, {
  UseOrganizationEngagementNoteListPanelTabMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/context/hooks/useEngagementNoteListPanelTabMutation';
import { EngagementNoteCreatePayload } from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/packages/engagement-note-create-form/EngagementNoteCreateForm';

export type UseEngagementNoteListCreate = {
  createEngagementNote: (createPayload: EngagementNoteCreatePayload) => void;
  isCreatingNote: boolean;
};

const useEngagementNoteListCreate = (options?: UseOrganizationEngagementNoteListPanelTabMutationOptions): UseEngagementNoteListCreate => {
  const [createEngagementNote, isCreatingNote] = useEngagementNoteListPanelTabMutation(
    (createPayload: EngagementNoteCreatePayload) =>
      organizationEngagementNoteCreate({
        organizationId: createPayload.organizationId,
        createPayload: {
          title: createPayload.title,
          description: createPayload.description,
          createdFrom: createPayload.createdFrom,
        },
      }),
    options,
  );

  return {
    createEngagementNote,
    isCreatingNote,
  };
};

export default useEngagementNoteListCreate;
