import * as React from 'react';
import { formatDate, formatTimeFriendly, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import TextBlock from '~/neo-ui/packages/text/packages/text-block/TextBlock';

export type DisposalOrderBulkPickupSectionPickupFinalSchedule = {
  startDateTime: Date;
  endDateTime: Date;
  pickupContactName: string;
  pickupCompanyName: string;
};

const DisposalOrderBulkPickupSectionPickupFinalSchedule = ({
  startDateTime,
  endDateTime,
  pickupContactName,
  pickupCompanyName,
}: DisposalOrderBulkPickupSectionPickupFinalSchedule) => (
  <TextBlock
    size={'sm'}
    title={
      <span>
        <b>{pickupCompanyName}</b> will arrive on{' '}
        <b>
          {formatDate(endDateTime, {
            format: 'MMMM dd',
            timezone: TimezoneFormat.Local,
          })}
        </b>{' '}
        between <b>{formatTimeFriendly(startDateTime)}</b> and <b>{formatTimeFriendly(endDateTime)}</b>
      </span>
    }
    titleColor={'positive-400'}
    icon={'StatusPositive'}
    iconColor={'positive-400'}
    description={`They’ll call ${pickupContactName} 1 hour before they arrive.`}
  />
);

export default DisposalOrderBulkPickupSectionPickupFinalSchedule;
