import { useContext } from 'react';
import DisposalFeatureFlagContext from '~/wm/packages/disposal/context/DisposalFeatureFlagContext';

const useDisposalFeatureFlagContext = () => {
  const disposalFeatureFlagContext = useContext(DisposalFeatureFlagContext);
  if (!disposalFeatureFlagContext) {
    throw new Error('Tried to use DisposalFeatureFlagContext outside of an DisposalFeatureFlagProvider');
  }

  return disposalFeatureFlagContext;
};

export default useDisposalFeatureFlagContext;
