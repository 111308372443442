import { useContext } from 'react';
import StrategyDashboardUrlContext from '~/wm/packages/strategy/packages/strategy-dashboard/context/StrategyDashboardUrlContext';

const useStrategyDashboardUrlContext = () => {
  const strategyDashboardUrlContext = useContext(StrategyDashboardUrlContext);
  if (!strategyDashboardUrlContext) {
    throw new Error('Tried to use StrategyDashboardUrlContext outside of an StrategyDashboardUrlProvider');
  }

  return strategyDashboardUrlContext;
};

export default useStrategyDashboardUrlContext;
