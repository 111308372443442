import Form from '~/neo-ui/packages/form/Form';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { InitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import useInitiativeSave from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/hooks/useInitiativeSave';
import useInitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesContext';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useAccount from '~/wm/hooks/useAccount';
import initiativeSaveFormValidationSchema from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/validation/initiativeSaveFormValidationSchema';
import { InitiativeAssetsAddedSource } from '~/wm/packages/strategy/model/strategyDataCollectionSources';
import { ColumnAvailabilityDto } from '@AssetManagementClient/BeastClient/Search/Model/Console/Dto.gen';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';
import { TicketCreateFieldValueDto } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Dto/Model.gen';
import { CostTypeEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Model/BudgetLineItemNested.gen';
import { FrequencyEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Recurring/Model/RecurringLineItemNested.gen';
import useUserContext from '~/wm/hooks/useUserContext';

export type InitiativeSaveFormData = {
  name: string;
  executiveSummary: string | undefined;
  updatedAt: string | undefined;
  budgetQuarter: BudgetQuarterFormData | undefined;
  // The budget obj schema is referenced manually here 620dde08-da64-4f37-982d-ca70c3478399
  // Changes to the budget schema can affect data wiring.
  budgetLineItems: BudgetLineItemFormData[];
  // The budget obj schema is referenced manually here 620dde08-da64-4f37-982d-ca70c3478399
  // Changes to the budget schema can affect data wiring.
  recurringLineItems: RecurringLineItemFormData[];
  priority: string;
  pointOfContactUserId: string;
  status: string;
  assetIds: string[];
  initiativeTemplateId: string | undefined;
  assetDisplayColumns: string[];
  shouldUnlinkTicket: boolean;
  ticketFieldValues: TicketCreateFieldValueDto[] | undefined;
};

export type BudgetQuarterFormData = {
  year: number;
  quarter: number;
};

export type RecurringLineItemFormData = {
  label: string;
  costUnits: number;
  costType: CostTypeEnum;
  frequency: FrequencyEnum;
};

export type BudgetLineItemFormData = {
  label: string;
  costUnits: number;
  costType: CostTypeEnum;
};

export type InitiativeSaveFormProps = {
  initiative: InitiativeDto;
  onInitiativeSave: (initiativeId: string) => void;
  children?: ReactNode;
};

/**
 * Form for saving an initiative
 */
const InitiativeSaveForm = ({ initiative, onInitiativeSave, children }: InitiativeSaveFormProps) => {
  const { id: userId } = useUserContext();
  const { availabilities } = useInitiativeAvailabilitiesContext();
  const { currentFiscalQuarter } = useRegionalSettingsInfoContext();
  const { saveInitiative } = useInitiativeSave(onInitiativeSave);
  const { organizationId } = useOrganizationContext();
  const { subunitRatio } = useAccount();

  const onSubmit = useCallback(
    async (data: InitiativeSaveFormData) =>
      saveInitiative({
        initiativeId: initiative.initiativeId,
        organizationId,
        name: data.name !== '' ? data.name : 'Untitled initiative',
        executiveSummary: data.executiveSummary ?? '',
        priorityEnum: data.priority,
        pointOfContactUserId: data.pointOfContactUserId,
        targetQuarter: data.budgetQuarter,
        status: data.status,
        oneTimeBudgets: data.budgetLineItems.map(lineItem => ({
          label: lineItem.label,
          costSubunits: lineItem.costUnits * subunitRatio,
          costType: lineItem.costType,
        })),
        recurringBudgets: data.recurringLineItems.map(lineItem => ({
          label: lineItem.label,
          costSubunits: lineItem.costUnits * subunitRatio,
          costType: lineItem.costType,
          frequency: lineItem.frequency,
        })),
        initiativeTemplateId: undefined,
        assetIds: data.assetIds,
        assetIdsFrom: 'initiative-edit-manual' satisfies InitiativeAssetsAddedSource,
        assetDisplayColumns: data.assetDisplayColumns,
        ticketPayload:
          typeof data.ticketFieldValues !== 'undefined' || data.shouldUnlinkTicket
            ? {
                ticketFieldValues: typeof data.ticketFieldValues !== 'undefined' ? data.ticketFieldValues : [],
                shouldUnlinkTicket: data.shouldUnlinkTicket,
              }
            : undefined,
      }),
    [saveInitiative, organizationId, initiative?.initiativeId, subunitRatio],
  );

  const toFormData = useCallback(
    (initiative: InitiativeDto): InitiativeSaveFormData =>
      typeof initiative === 'undefined'
        ? {
            name: '',
            executiveSummary: '',
            updatedAt: '',
            budgetQuarter: undefined,
            budgetLineItems: [],
            recurringLineItems: [],
            priority: 'None',
            pointOfContactUserId: availabilities.pointOfContactAvailabilities.find(availability => availability.id === userId)!.id,
            status: 'New',
            assetIds: [],
            initiativeTemplateId: undefined,
            assetDisplayColumns: availabilities.columnsAvailabilities
              .filter(column => column.availability !== ColumnAvailabilityDto.Available)
              .map(column => column.key.value),
            ticketFieldValues: [],
            shouldUnlinkTicket: false,
          }
        : {
            name: initiative.name === 'Untitled initiative' ? '' : initiative.name,
            executiveSummary: initiative.executiveSummary,
            updatedAt: !!initiative.updatedAt ? initiative.updatedAt : undefined,
            budgetQuarter: initiative.isScheduled ? initiative.budgetQuarter : undefined,
            budgetLineItems: initiative.budget.lineItems.map(lineItem => ({
              label: lineItem.label,
              costUnits: lineItem.costSubunits / initiative.budget.currency.subunitRatio,
              costType: lineItem.costType,
            })),
            recurringLineItems: initiative.budget.recurringLineItems.map(lineItem => ({
              label: lineItem.label,
              costUnits: lineItem.costSubunits / initiative.budget.currency.subunitRatio,
              costType: lineItem.costType,
              frequency: lineItem.frequency,
            })),
            priority: initiative.priority.key,
            pointOfContactUserId: initiative.pointOfContact.id,
            status: initiative.status ?? 'New',
            assetIds: initiative.assets.map(asset => asset.assetId),
            initiativeTemplateId: undefined,
            assetDisplayColumns:
              typeof initiative.assetDisplayColumns !== 'undefined'
                ? initiative.assetDisplayColumns
                : availabilities.columnsAvailabilities
                    .filter(column => column.availability !== ColumnAvailabilityDto.Available)
                    .map(column => column.key.value),
            ticketFieldValues: [],
            shouldUnlinkTicket: false,
          },
    [availabilities.columnsAvailabilities, availabilities.pointOfContactAvailabilities, userId],
  );

  const defaultFormData = useMemo(() => toFormData(initiative), [initiative, toFormData]);

  return (
    <Form
      submitMethod={'manual'}
      defaultFormData={defaultFormData}
      validationSchema={initiativeSaveFormValidationSchema(currentFiscalQuarter)}
      onSubmit={onSubmit}
      hideSubmissionButton={true}
    >
      {children}
    </Form>
  );
};

export default InitiativeSaveForm;
