import { EngagementNoteDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Dto/Model.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import useGroupByMonth from '~/extensions/packages/date/packages/month/useGroupByMonth';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import useEngagementNoteDateSections from '~/wm/packages/organization/packages/engagement/packages/engagement-note/hooks/useEngagementNoteDateSections';
import EngagementNoteListCard from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/packages/engagement-note-list-card/EngagementNoteListCard';
import useEngagementNoteListPin from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/hooks/useEngagementNoteListPin';
import useEngagementNoteListArchive from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/hooks/useEngagementNoteListArchive';
import useEngagementNoteListUpdate from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/hooks/useEngagementNoteListUpdate';
import EngagementNoteListCardArchived from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/packages/engagement-note-list-card-archived/EngagementNoteListCardArchived';
import useEngagementNoteListUnpin from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/hooks/useEngagementNoteListUnpin';
import useEngagementNoteListUnarchive from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/hooks/useEngagementNoteListUnarchive';

export type EngagementNoteListSectionProps = {
  notes: EngagementNoteDto[];
};

const EngagementNoteListSection = ({ notes }: EngagementNoteListSectionProps) => {
  const { pinned, pastWeek, pastMonth, pastMonthRemainingThisYear, pastRemainingYears } = useEngagementNoteDateSections(notes);
  const { pinNote, isPinningNote } = useEngagementNoteListPin();
  const { unpinNote, isUnpinningNote } = useEngagementNoteListUnpin();
  const { archiveNote, isArchivingNote } = useEngagementNoteListArchive();
  const { unarchiveNote, isUnarchivingNote } = useEngagementNoteListUnarchive();
  const { updateEngagementNote } = useEngagementNoteListUpdate();

  // Convert our 2023-01 string to a date object
  const convertYearMonthStringToDate = (yearMonthString: string): Date => {
    const [year, month] = yearMonthString.split('-');
    const yearNumber = parseInt(year, 10);
    const monthNumber = parseInt(month, 10) - 1;
    return new Date(yearNumber, monthNumber);
  };

  const withinYearByMonth = useGroupByMonth(pastMonthRemainingThisYear, 'createdAt');

  const beyondYearByMonth = useGroupByMonth(pastRemainingYears, 'createdAt');

  const generateEngagementNoteCard = (note: EngagementNoteDto) =>
    !note.isArchived ? (
      <EngagementNoteListCard
        key={note.engagementNoteId}
        note={note}
        isArchivingNote={isArchivingNote || isUnarchivingNote}
        isPinningNote={isPinningNote || isUnpinningNote}
        onNoteUpdate={updateEngagementNote}
        onNoteArchive={archiveNote}
        onNoteUnarchive={unarchiveNote}
        onNotePin={pinNote}
        onNoteUnpin={unpinNote}
      />
    ) : (
      <EngagementNoteListCardArchived
        key={note.engagementNoteId}
        note={note}
        isUnarchivingNote={isUnarchivingNote}
        onNoteUnarchive={unarchiveNote}
      />
    );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      `}
    >
      {/* Pinned */}
      {pinned.length > 0 && (
        <React.Fragment>
          <Header
            size={7}
            weight={'bold'}
            color={'light-600'}
          >
            Pinned
          </Header>
          {pinned.map(generateEngagementNoteCard)}
        </React.Fragment>
      )}

      {/* Previous 7 Days */}
      {pastWeek.length > 0 && (
        <React.Fragment>
          <Header
            size={7}
            weight={'bold'}
            color={'light-600'}
          >
            Previous 7 Days
          </Header>
          {pastWeek.map(generateEngagementNoteCard)}
        </React.Fragment>
      )}

      {/* Previous 30 Days */}
      {pastMonth.length > 0 && (
        <React.Fragment>
          <Header
            size={7}
            weight={'bold'}
            color={'light-600'}
          >
            Previous 30 Days
          </Header>
          {pastMonth.map(generateEngagementNoteCard)}
        </React.Fragment>
      )}

      {/* Within This Year */}
      {Object.keys(withinYearByMonth).map(yearMonth => {
        const notes = withinYearByMonth[yearMonth];

        return (
          <React.Fragment key={yearMonth}>
            <Header
              size={7}
              weight={'bold'}
              color={'light-600'}
            >
              {formatDate(convertYearMonthStringToDate(yearMonth), {
                format: 'MMMM',
                timezone: TimezoneFormat.Local,
              })}
            </Header>
            {notes.map(generateEngagementNoteCard)}
          </React.Fragment>
        );
      })}

      {/* Last year and beyond */}
      {Object.keys(beyondYearByMonth).map(yearMonth => {
        const notes = beyondYearByMonth[yearMonth];

        return (
          <React.Fragment key={yearMonth}>
            <Header
              size={7}
              weight={'bold'}
              color={'light-600'}
            >
              {formatDate(convertYearMonthStringToDate(yearMonth), {
                format: 'MMMM, yyyy',
                timezone: TimezoneFormat.Local,
              })}
            </Header>
            {notes.map(generateEngagementNoteCard)}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default EngagementNoteListSection;
