import * as React from 'react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { ContractBillingCurrencyDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Billing/Dto/Model.gen';
import { getCurrencyCharacterByCurrencyCode } from '~/extensions/packages/currency/formatCurrency';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { css } from '@emotion/react';
import { isNaN } from 'formik';
import { ContractCreateBillingCostCalculationFormData } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-create-button/packages/contract-create-form/ContractCreateForm';
import { ContractEditBillingCostCalculationFormData } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-list-table/packages/contract-edit-vendor-table-cell/packages/contract-edit-window-form-wrapper/packages/contract-edit-form/ContractEditForm';
import InputTitle from '~/neo-ui/packages/input/packages/input-title/InputTitle';
import useFormInputBuilder from '~/neo-ui/packages/form/packages/form-input/hooks/useFormInputBuilder';
import { FormErrorMessageLabel } from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';
import SelectGroup, { ButtonSelectOption } from '~/neo-ui/packages/select/packages/select-group/SelectGroup';

export type ContractBillingCostCalculationFormData =
  | ContractEditBillingCostCalculationFormData
  | ContractCreateBillingCostCalculationFormData;

export type ContractUpsertBillingCostingInputProps<T> = {
  contractBillingCurrency: ContractBillingCurrencyDto;
  costFieldKey: FieldKeyExpression<T>;
  perSeatCostFieldKey: FieldKeyExpression<T>;
  numberOfSeatsFieldKey: FieldKeyExpression<T>;
  costVariablesFieldKey: FieldKeyExpression<T>;
  isSyncedFromPsa: boolean;
  onUpdate: (costVariables: ContractBillingCostCalculationFormData) => void;
};

type NumberInputType = 'cost' | 'integer';

const validateNumberInput = (number: string | number | undefined) =>
  typeof number !== 'undefined' && number !== '' && !isNaN(number === '' ? undefined : Number(number));

const mapNumberInput = (numberString: string, numberType: NumberInputType): string => {
  // Allows pasting complete currency-type numbers into the input
  // e.g., if I write $12,123.20 => 12123.20
  const mappedString = numberString.replace(/[^\d.]/g, '');

  switch (numberType) {
    case 'cost':
      // Case: When number is valid + there is more than 2 decimal places => truncate after 2 decimal places
      const isValid = validateNumberInput(mappedString);
      if (isValid && /[.][\d]{3,}$/.test(mappedString)) {
        return Number(mappedString.slice(0, -1)).toFixed(2);
      }
      break;

    case 'integer':
      // Case: When number is valid and not 0 => truncate after 0 decimal places
      if (validateNumberInput(mappedString) && Number(mappedString) !== 0) {
        return Number(mappedString).toFixed(0);
      }
      break;
  }

  return mappedString;
};

const costPerSeatSelectOption = 'costPerSeat';
const totalCostSelectOption = 'totalCost';
const zeroString = '0';

const calculationOptions: ButtonSelectOption[] = [
  {
    label: 'Enter Cost Per Seat',
    value: costPerSeatSelectOption,
  },
  {
    label: 'Enter Total Cost',
    value: totalCostSelectOption,
  },
];

const ContractUpsertBillingCostingInputs = <T,>({
  contractBillingCurrency,
  costVariablesFieldKey,
  costFieldKey,
  perSeatCostFieldKey,
  numberOfSeatsFieldKey,
  isSyncedFromPsa,
  onUpdate,
}: ContractUpsertBillingCostingInputProps<T>) => {
  const { getFormInput } = useFormContext<T>();

  const formFieldCss = css`
    width: calc(100% / 3);
    display: flex;
    gap: 1rem;
    align-items: center;
  `;

  const costingOptionsCss = css`
    flex-basis: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  `;

  const formContainerCss = css`
    display: flex;
    gap: 2rem;
  `;

  const labelCss = css`
    display: flex;
  `;

  const inputTitleSpanCss = css`
    flex-grow: 1;
    position: relative;
  `;

  // Used as cost symbol for cost fields
  const currencyIcon = getCurrencyCharacterByCurrencyCode(contractBillingCurrency.codeAlpha);

  const costVariables = getFormInput<ContractBillingCostCalculationFormData>(costVariablesFieldKey).value;

  // Field Value States
  const [totalCost, setTotalCostState] = React.useState(costVariables.costSubunits.toString());
  const [perSeatCost, setPerSeatCostState] = React.useState((costVariables.perSeatCostSubunits ?? '').toString());
  const [numberOfSeats, setNumberOfSeatsState] = React.useState((costVariables.numberOfSeats ?? '').toString());
  const [hasEnteredBillInfo, setHasEnteredBillInfo] = React.useState(false);
  // form usage state functions
  const setTotalCost = (newTotalCost: string) => {
    setTotalCostState(newTotalCost);
    updateCalculations({
      numberOfSeats: convertToNumberOrZero(numberOfSeats),
      costSubunits: convertToNumberOrZero(newTotalCost),
      perSeatCostSubunits: convertToNullableNumber(perSeatCost),
    });
  };

  const setPerSeatCost = (newPerSeatCost: string) => {
    setPerSeatCostState(newPerSeatCost);
    updateCalculations({
      numberOfSeats: convertToNumberOrZero(numberOfSeats),
      costSubunits: convertToNumberOrZero(totalCost),
      perSeatCostSubunits: convertToNullableNumber(newPerSeatCost),
    });
  };

  const setNumberOfSeats = (newNumberOfSeats: string) => {
    setNumberOfSeatsState(newNumberOfSeats);
    updateCalculations({
      numberOfSeats: convertToNumberOrZero(newNumberOfSeats),
      costSubunits: convertToNumberOrZero(totalCost),
      perSeatCostSubunits: convertToNullableNumber(perSeatCost),
    });
  };
  // Field Errors and Touched states
  const { error: costFieldError } = useFormInputBuilder<T>(costFieldKey);
  const { error: perSeatCostFieldError } = useFormInputBuilder<T>(perSeatCostFieldKey);
  const { error: numberOfSeatsFieldError } = useFormInputBuilder<T>(numberOfSeatsFieldKey);

  // Field input validation states
  const isNumberOfSeatInputValid = validateNumberInput(numberOfSeats);
  const isCostInputValid = validateNumberInput(totalCost);
  const isPerSeatCostInputValid = validateNumberInput(perSeatCost);

  const calculationOption = calculationOptions.find((option: ButtonSelectOption) => option.value === costPerSeatSelectOption)!;
  const [selectedCalculationOption, setSelectedCalculationOption] = React.useState(calculationOption);

  const calculatingTotalCost = () => selectedCalculationOption?.value === costPerSeatSelectOption;

  const calculatingCostPerSeat = () => selectedCalculationOption?.value === totalCostSelectOption;

  const convertToNullableNumber = (value: string): number | undefined => {
    const parsedValue = Number(value);
    return isNaN(parsedValue) ? undefined : parsedValue;
  };

  const convertToNumberOrZero = (value: string): number => {
    const parsedValue = Number(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const updateCalculations = (updateDetails: ContractBillingCostCalculationFormData) => {
    const updateCommand = { ...updateDetails };
    if (calculatingCostPerSeat()) {
      let newPerSeatCostSubunits = zeroString;

      if (
        validateNumberInput(updateDetails.costSubunits) &&
        validateNumberInput(updateDetails.numberOfSeats) &&
        updateDetails.numberOfSeats !== 0
      ) {
        newPerSeatCostSubunits = (Number(updateDetails.costSubunits) / Number(updateDetails.numberOfSeats)).toFixed(2);
      }
      if (Number(newPerSeatCostSubunits) !== updateDetails.perSeatCostSubunits) {
        setPerSeatCostState(newPerSeatCostSubunits);
        updateCommand.perSeatCostSubunits = convertToNullableNumber(newPerSeatCostSubunits);
      }
    } else if (calculatingTotalCost()) {
      let newTotalCost = zeroString;

      if (validateNumberInput(updateDetails.perSeatCostSubunits) && validateNumberInput(updateDetails.numberOfSeats)) {
        newTotalCost = Number(Number(updateDetails.perSeatCostSubunits) * Number(updateDetails.numberOfSeats)).toFixed(2);
      }
      if (Number(newTotalCost) !== updateDetails.costSubunits) {
        setTotalCostState(newTotalCost);
        updateCommand.costSubunits = convertToNumberOrZero(newTotalCost);
      }
    }
    onUpdate(updateCommand);
  };

  return (
    <div>
      <div css={costingOptionsCss}>
        <SelectGroup
          options={calculationOptions}
          selectedOptionValue={selectedCalculationOption?.value}
          onOptionSelected={selectedOption => {
            setSelectedCalculationOption(selectedOption);
          }}
        />
      </div>
      <div css={formContainerCss}>
        <div css={formFieldCss}>
          <Label
            size={'md'}
            bold={true}
            css={labelCss}
          >
            Total Cost
          </Label>
          <span css={inputTitleSpanCss}>
            <InputTitle
              size={'md'}
              prependCharacter={currencyIcon}
              placeholder={'Enter cost…'}
              disabled={isSyncedFromPsa || calculatingTotalCost()}
              isInvalid={!isCostInputValid || typeof costFieldError !== 'undefined'}
              value={mapNumberInput(totalCost.toString(), 'cost')}
              onChange={costString => {
                setHasEnteredBillInfo(true);
                const mappedString = costString.replace(/[^\d.]/g, '');
                const newCostSubunits = mapNumberInput(mappedString, 'cost');
                setTotalCost(newCostSubunits);
              }}
              onClear={() => setTotalCost(zeroString)}
            />
            {typeof costFieldError !== 'undefined' && (
              <FormErrorMessageLabel
                errorMessage={costFieldError}
                fixedPosition={true}
              />
            )}
          </span>
        </div>
        <div css={formFieldCss}>
          <Label
            size={'md'}
            bold={true}
            css={labelCss}
          >
            Number of seats
          </Label>
          <span css={inputTitleSpanCss}>
            <InputTitle
              size={'md'}
              placeholder={'Enter number of seats…'}
              disabled={isSyncedFromPsa}
              isInvalid={hasEnteredBillInfo && (!isNumberOfSeatInputValid || typeof numberOfSeatsFieldError !== 'undefined')}
              value={mapNumberInput(`${numberOfSeats !== undefined ? numberOfSeats : 0}`, 'integer')}
              onChange={numberOfSeatsString => {
                setHasEnteredBillInfo(true);
                const mappedString = numberOfSeatsString.toString().replace(/[^\d.]/g, '');
                if (numberOfSeatsString === '') {
                  setNumberOfSeats(zeroString);
                } else if (validateNumberInput(mappedString) && Number(mappedString) >= 1) {
                  setNumberOfSeats(mapNumberInput(mappedString, 'integer'));
                }
              }}
              onClear={() => setNumberOfSeats(zeroString)}
            />
            {typeof numberOfSeatsFieldError !== 'undefined' && (
              <FormErrorMessageLabel
                errorMessage={numberOfSeatsFieldError}
                fixedPosition={true}
              />
            )}
          </span>
        </div>
        <div css={formFieldCss}>
          <Label
            size={'md'}
            bold={true}
            css={labelCss}
          >
            Cost per seat
          </Label>
          <span css={inputTitleSpanCss}>
            <InputTitle
              size={'md'}
              placeholder={'Enter per seat cost…'}
              prependCharacter={currencyIcon}
              disabled={isSyncedFromPsa || calculatingCostPerSeat()}
              isInvalid={hasEnteredBillInfo && (!isPerSeatCostInputValid || typeof perSeatCostFieldError !== 'undefined')}
              value={mapNumberInput(perSeatCost, 'cost')}
              onChange={perSeatCostString => {
                setHasEnteredBillInfo(true);
                const mappedString = perSeatCostString.toString().replace(/[^\d.]/g, '');
                const isNumberValid = validateNumberInput(mappedString);
                const newPerSeatCostSubunits = isNumberValid ? mapNumberInput(mappedString, 'cost') : mappedString;
                setPerSeatCost(newPerSeatCostSubunits);
              }}
              onClear={() => setPerSeatCost(zeroString)}
            />
            {typeof perSeatCostFieldError !== 'undefined' && (
              <FormErrorMessageLabel
                errorMessage={perSeatCostFieldError}
                fixedPosition={true}
              />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContractUpsertBillingCostingInputs;
