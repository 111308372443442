import { scorecardScorecardItemOrderUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useScorecardMutation, { UseScorecardMutationOptions } from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardMutation';

export type UseScorecardItemOrderUpdate = {
  updateScorecardItemOrder: (orderedScorecardItemIds: string[]) => void;
};

const useScorecardItemOrderUpdate = (
  scorecardId: string,
  scorecardCategoryId?: string,
  options?: UseScorecardMutationOptions,
): UseScorecardItemOrderUpdate => {
  const [updateScorecardItemOrder] = useScorecardMutation(
    (orderedScorecardItemIds: string[]) =>
      scorecardScorecardItemOrderUpdate({
        scorecardId,
        scorecardCategoryId,
        orderedScorecardItemIds,
      }),
    options,
  );

  return {
    updateScorecardItemOrder,
  };
};

export default useScorecardItemOrderUpdate;
