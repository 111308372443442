import { Styleable } from '~/neo-ui/model/capacity';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Button from '~/neo-ui/packages/button/Button';
import Dropdown, { DropdownOption } from '~/neo-ui/packages/dropdown/Dropdown';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import useInitiativeDashboardContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/initiative-dashboard/context/hooks/useInitiativeDashboardContext';
import { InitiativeOverviewDashboardDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import BudgetQuarterContainer from '~/wm/packages/strategy/packages/strategy-dashboard/packages/initiative-dashboard/packages/budget-quarter-container/BudgetQuarterContainer';
import Icon from '~/neo-ui/packages/icon/Icon';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useStrategyDashboardUrlContext from '~/wm/packages/strategy/packages/strategy-dashboard/context/hooks/useStrategyDashboardUrlContext';
import Box from '~/neo-ui/packages/box/Box';
import routes from '~/router/types/routes';

export type StrategyInitiativeDashboardContainerProps = {
  enabledInitiativeTickets: boolean;
} & Styleable;

const StrategyInitiativeDashboardContainer = ({ enabledInitiativeTickets, className }: StrategyInitiativeDashboardContainerProps) => {
  const {
    initiatives,
    statusAvailabilities,
    statusCounts,
    selectedFilterStatus,
    currentPage,
    onFilterStatusUpdate,
    onPageUpdate,
    hasNextPage,
    hasPreviousPage,
  } = useInitiativeDashboardContext();
  const { organizationId } = useOrganizationContext();
  const { roadmapUrl } = useStrategyDashboardUrlContext();

  const statusOptions: DropdownOption[] = [
    {
      label: `All (${statusCounts.reduce((acc, obj) => acc + (obj.count || 0), 0)})`,
      value: 'all',
    },
    ...statusAvailabilities.map(statusAvailability => {
      const statusCount = statusCounts.find(statusCount => statusCount.id === statusAvailability.id)?.count ?? 0;

      return {
        label: `${statusAvailability.displayLabel} (${statusCount})`,
        value: statusAvailability.id,
        disabled: statusCount === 0,
      };
    }),
  ];

  const initiativesByBudgetQuarter: {
    [key: string]: InitiativeOverviewDashboardDto[];
  } = {};
  initiatives.forEach(initiative => {
    const budgetQuarterKey =
      typeof initiative.budgetQuarter !== 'undefined' ? `${initiative.budgetQuarter.year}-${initiative.budgetQuarter.quarter}` : 'N/A';

    if (initiativesByBudgetQuarter[budgetQuarterKey] !== undefined) {
      initiativesByBudgetQuarter[budgetQuarterKey].push(initiative);
    } else {
      initiativesByBudgetQuarter[budgetQuarterKey] = [initiative];
    }
  });

  const hasInitiatives = initiatives.length !== 0;

  return (
    <Box
      boxShadow={'shadow0'}
      padding={'padding300'}
      borderRadius={'radius400'}
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 1rem;
          align-items: center;
        `}
      >
        <Header
          size={3}
          weight={'medium'}
        >
          Initiatives
        </Header>
        <Dropdown
          disabled={!hasInitiatives}
          buttonCss={css`
            width: 12.5rem;
          `}
          selectedOption={
            typeof selectedFilterStatus === 'undefined'
              ? {
                  label: `All`,
                  value: 'all',
                }
              : {
                  label: selectedFilterStatus.displayLabel,
                  value: selectedFilterStatus.id,
                }
          }
          options={statusOptions}
          onOptionSelected={option => {
            onFilterStatusUpdate(option.value === 'all' ? undefined : option.value);
          }}
        />
        <div
          css={css`
            margin-left: auto;
            display: flex;
            gap: 1rem;
          `}
        >
          <div
            css={css`
              display: flex;
            `}
          >
            <Button
              onClick={() => {
                onPageUpdate(currentPage - 1);
              }}
              disabled={!hasPreviousPage}
            >
              <Icon icon="ArrowLeft" />
            </Button>
            <Button
              onClick={() => {
                onPageUpdate(currentPage + 1);
              }}
              disabled={!hasNextPage}
            >
              <Icon icon="ArrowRight" />
            </Button>
          </div>
          {hasInitiatives && (
            <ButtonLink
              iconRight={'ArrowRight'}
              anchor={{
                href: roadmapUrl.replace('__ORGANIZATION_ID__', organizationId),
              }}
            >
              Manage Roadmap
            </ButtonLink>
          )}
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
        `}
      >
        {!hasInitiatives && (
          <Button
            iconRight={'ArrowRight'}
            iconLeft={'Roadmap'}
            anchor={{ href: routes.ORGANIZATION_STRATEGY_ROADMAP_VIEW.build([organizationId]) }}
            css={css`
              text-align: left;
            `}
          >
            Create new Initiatives on Roadmap
          </Button>
        )}
        {Object.entries(initiativesByBudgetQuarter).map(([budgetQuarterString, initiatives]) => (
          <BudgetQuarterContainer
            key={budgetQuarterString}
            budgetQuarter={
              budgetQuarterString !== 'N/A'
                ? {
                    year: Number(budgetQuarterString.split('-')[0]),
                    quarter: Number(budgetQuarterString.split('-')[1]),
                  }
                : undefined
            }
            initiatives={initiatives}
            enabledInitiativeTickets={enabledInitiativeTickets}
          />
        ))}
      </div>
    </Box>
  );
};

export default StrategyInitiativeDashboardContainer;
