import { organizationEngagementNoteUnarchive } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementNotePanelTabMutation, {
  UseOrganizationEngagementNotePanelTabMutationOptions,
} from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/context/hooks/useEngagementNotePanelTabMutation';

export type UseEngagementNoteDashboardUnarchive = {
  unarchiveNote: (engagementNoteId: string) => void;
  isUnarchivingNote: boolean;
};

const useEngagementNoteDashboardUnarchive = (
  options?: UseOrganizationEngagementNotePanelTabMutationOptions,
): UseEngagementNoteDashboardUnarchive => {
  const [unarchiveNote, isUnarchivingNote] = useEngagementNotePanelTabMutation(
    (engagementNoteId: string) =>
      organizationEngagementNoteUnarchive({
        engagementNoteId,
      }),
    options,
  );

  return {
    unarchiveNote,
    isUnarchivingNote,
  };
};

export default useEngagementNoteDashboardUnarchive;
