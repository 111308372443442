import { createContext } from 'react';
import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';

export type TicketFieldAvailabilitiesContextData = {
  ticketFieldOptions: IntegrationFieldOptionDto[];
  hasTicketIntegration: boolean;
  isOrganizationRegisteredForTickets: boolean;
  integrationUrl: string | undefined;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const TicketFieldAvailabilitiesContext = createContext<TicketFieldAvailabilitiesContextData | undefined>(undefined);

export default TicketFieldAvailabilitiesContext;
