import { Request as ProgressRequest } from '@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Link/Packages/Progress/Controller/OrganizationLinkProgressGetControllerNested.gen';
import { Discriminant } from '@AssetManagementClient/Primitives/Results/ProgressResponse_/ProgressError_/ResultNested.gen';
import { organizationLinkProgressGet } from '~/AssetManagementClient/AssetManagementClientMsp.gen';
import { ProgressDefinition } from '~/legacy-ui/packages/operation/packages/progress/model/buildProgress';
import callApiOrError from '~/wm/packages/api/packages/api-result/callApiOrError';
import convertApiProgress from '~/wm/packages/api/packages/progress/model/convertApiProgress';
import organizationBucketKey from '../../../model/organizationBucketKey';

const linkingProgress: ProgressDefinition<{
  parentOrganizationId: string;
}> = ({ parentOrganizationId }) => ({
  progressLabel: 'Modifying linked clients',
  fetchProgress: async () => {
    const progressRequest: ProgressRequest = {
      organizationId: parentOrganizationId,
    };

    const apiResult = await callApiOrError(() => organizationLinkProgressGet(progressRequest));

    if (apiResult.type === 'error') {
      // Error gets handled at upper layer
      return apiResult;
    }

    // Map progress response
    const progressResult = apiResult.data;
    if (progressResult.case === Discriminant.Error) {
      throw new Error();
    }
    return { type: 'success', data: convertApiProgress(progressResult.value) };
  },
  entitiesToLock: [{ bucket: organizationBucketKey, entityId: parentOrganizationId }],
  onCompletion: 'reload-page',
});

export default linkingProgress;
