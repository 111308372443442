import * as React from 'react';
import useContractListContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/context/hooks/useContractListContext';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import DataTable from '~/neo-ui/packages/table/packages/data-table/DataTable';
import getContractColumns from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-list-table/getContractColumns';

const ContractListTable = () => {
  const { contracts, organizationName } = useContractListContext();
  const [currentPage, setCurrentPage] = React.useState(0);

  const contractColumns = getContractColumns();

  return (
    <DataTable
      title={<Header size={2}>Contracts</Header>}
      columns={contractColumns}
      defaultPagination={{ pageNumber: currentPage, perPageSize: 50 }}
      onPageChange={pagination => {
        setCurrentPage(pagination.pageNumber);
      }}
      enableClientPaginated={'block'}
      data={contracts}
      EmptyStatePlaceholder={`There are currently zero contracts for ${organizationName}`}
    />
  );
};

export default ContractListTable;
