import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import CardButton from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-selection/packages/card/packages/card-button/CardButton';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import CouponSection from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/coupon-section/CouponSection';

const CheckoutState = (props: {
  action: () => void;
  actionLabel: string;
  active: number;
  totalAfterTax: string;
  totalBeforeTax: string;
  tax: string;
  nextEstimatedAmountWithoutTax: string;
  nextPaymentDayLabel: string | undefined;
}) => {
  const { action, actionLabel, active, totalBeforeTax, totalAfterTax, tax, nextEstimatedAmountWithoutTax, nextPaymentDayLabel } = props;
  const { formik } = useMultiStepForm<PurchaseSubscriptionForm>();
  const { isGrow } = formik.values;
  return (
    <div
      css={css`
        border: 1px solid ${colorToCode('light-600')};
        border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
        box-shadow: 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.25);
        background-color: ${colorToCode('light-000')};
        padding: 2rem 1rem;
        width: 27.5rem;
        display: flex;
        flex-direction: column;
      `}
    >
      <CardButton
        css={css`
          font-weight: 700;
          font-size: 1.125rem;
          line-height: 1.688rem;
        `}
        isPreselected={true}
        onClick={action}
      >
        {actionLabel}
      </CardButton>
      <div
        css={css`
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          text-align: center;
          margin-top: 1rem;
        `}
      >
        {active === 2 ? (
          <div>
            <span>{isGrow ? 'By updating your subscription' : 'By paying now'}, you agree to ScalePad’s </span>
            <a
              css={css`
                color: ${colorToCode('primary-400')};
                text-decoration: underline;
              `}
              target="_blank"
              href={'https://app.scalepad.com/pages/privacy'}
            >
              privacy policy
            </a>
            <span> and </span>
            <a
              css={css`
                color: ${colorToCode('primary-400')};
                text-decoration: underline;
              `}
              target="_blank"
              href={'https://app.scalepad.com/pages/terms'}
            >
              terms of service
            </a>
            <span>.</span>
          </div>
        ) : (
          <span>You’ll still have a chance to review your subscription before it’s final.</span>
        )}
      </div>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(2, auto);
          justify-content: space-between;
          text-align: left;
          margin-top: 2rem;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          color: ${colorToCode('dark-400')};
          gap: 0.5rem;
        `}
      >
        <span>Total before taxes</span>
        <span
          css={css`
            text-align: end;
          `}
        >
          {totalBeforeTax}
        </span>
        <span>Taxes</span>
        {formik.values.isTaxSet ? (
          <span
            css={css`
              text-align: end;
            `}
          >
            {tax}
          </span>
        ) : (
          <span
            css={css`
              text-align: end;
            `}
          >
            –
          </span>
        )}
      </div>
      <div
        css={css`
          margin: 0.5rem 0;
          border-bottom: 0.063rem solid ${colorToCode('light-600')};
        `}
      />
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(2, auto);
          justify-content: space-between;
          margin-bottom: 2rem;
          text-align: left;
          font-size: 1rem;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          color: ${colorToCode('dark-400')};
          gap: 0.5rem;
        `}
      >
        <span>{isGrow ? 'Your next payment' : 'Today’s payment'}</span>
        {formik.values.isTaxSet ? (
          <span
            css={css`
              text-align: end;
            `}
          >
            {totalAfterTax}
          </span>
        ) : (
          <span>–</span>
        )}
      </div>
      <CouponSection />
      {active === 2 && (
        <span
          css={css`
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin: 1.5rem 0 0 0;
          `}
        >
          Afterward, your estimated billed amount will be <b>{nextEstimatedAmountWithoutTax} USD</b> before tax per month on the{' '}
          <b>{nextPaymentDayLabel}</b> of each month.
        </span>
      )}
    </div>
  );
};

export default CheckoutState;
