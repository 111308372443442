import { disposalOrderPickupCancel } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useDisposalOrderInfoMutation, {
  UseDisposalOrderInfoMutationOptions,
} from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderInfoMutation';

export type UseDisposalOrderPickupCancel = () => {
  cancelPickup: (disposalOrderId: string, isRequestActive?: () => boolean) => void;
  isCancelling: boolean;
};

const useDisposalOrderPickupCancel: UseDisposalOrderPickupCancel = (options?: UseDisposalOrderInfoMutationOptions) => {
  const [cancelPickup, isCancelling] = useDisposalOrderInfoMutation(
    (disposalOrderId: string) => disposalOrderPickupCancel({ disposalOrderId }),
    options,
  );

  return { cancelPickup, isCancelling };
};

export default useDisposalOrderPickupCancel;
