import { warrantyCartStaticCostCalculator } from '~/wm/packages/warranty/packages/warranty-cart/calculators/regular/warranty-cart-static-cost-calculator/WarrantyCartStaticCostCalculator';
import { Enum as DeviceType } from '@WarrantyClient/BeastClient/Goods/DeviceType/DeviceTypeFactoryNested.gen';
import { warrantyCartDynamicCostCalculator } from '~/wm/packages/warranty/packages/warranty-cart/calculators/regular/warranty-cart-dynamic-cost-calculator/WarrantyCartDynamicCostCalculator';
import { RenewalOptionSellingPriceDto } from '@WarrantyClient/BeastClient/Beast/Estimate/Dto/Cart/PaginatedGet.gen';
import { WarrantyCartPriceCalculatorType } from '~/wm/packages/warranty/packages/warranty-cart/types/WarrantyCartCalculatorType';
import { getCostCalculatorType } from '~/wm/packages/warranty/packages/warranty-cart/calculators/warrantyCartCalculators';

export type WarrantyCartCostCalculator = {
  calculatorType: WarrantyCartPriceCalculatorType;
  calculateCost: (
    availablePlans: RenewalOptionSellingPriceDto[],
    selectedPlanId: string | undefined,
    selectedAssetsCount: number,
    duration: number,
  ) => number;
};

const warrantyCartCostCalculatorList = [warrantyCartStaticCostCalculator, warrantyCartDynamicCostCalculator];
const warrantyCartCostCalculatorByType = new Map(warrantyCartCostCalculatorList.map(calc => [calc.calculatorType, calc]));
export const getCalculatorByDeviceType = (deviceType: DeviceType) =>
  warrantyCartCostCalculatorByType.get(getCostCalculatorType(deviceType));
export default WarrantyCartCostCalculator;
