import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import FormCreditCardInput, {
  CreditCardFormData,
  PaymentVersion,
} from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';
import Button from '~/neo-ui/packages/button/Button';
import { css } from '@emotion/react';
import * as React from 'react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { getCreditCardIcon } from '~/wm/packages/subscription/packages/manage/packages/form-credit-card-changer/FormCreditCardChanger';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import Image from '~/neo-ui/packages/image/Image';
import DisableElementOverlay from '~/neo-ui/packages/overlay/DisableElementOverlay';

export type DisposalCreditCard<T> = {
  creditCardFieldKey: FieldKeyExpression<T>;
  creditCardFormData: CreditCardFormData | undefined;
  paymentVersion: PaymentVersion;
  isEditing: boolean;
  onEditing: (isEditing: boolean) => void;
};

const DisposalCreditCard = <T,>({
  creditCardFieldKey,
  creditCardFormData,
  paymentVersion,
  isEditing,
  onEditing,
}: DisposalCreditCard<T>) => {
  const { submitForm, isSubmitting, setFormInput } = useFormContext<T>();

  const creditCardIcon = creditCardFormData ? getCreditCardIcon(creditCardFormData.brand) : undefined;

  const creditCardImageSource =
    creditCardIcon?.type === 'image' ? `/i/image/payment/${encodeURIComponent(creditCardIcon.name)}.svg` : undefined;

  const isCreditCardComplete =
    typeof creditCardFormData !== 'undefined' && creditCardFormData?.type === 'new-card' && !creditCardFormData.isComplete;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      {typeof creditCardFormData !== 'undefined' && (
        <DisableElementOverlay disabled={isEditing}>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
            `}
          >
            {creditCardFormData.type === 'card-on-file' && (
              <Header
                size={5}
                weight={'bold'}
              >
                Saved credit card
              </Header>
            )}
            {creditCardFormData.type === 'new-card' && (
              <Header
                size={5}
                weight={'bold'}
              >
                New credit card
              </Header>
            )}
            <div
              css={css`
                display: flex;
                gap: 0.625rem;
                align-items: center;
              `}
            >
              {creditCardImageSource && (
                <Image
                  css={css`
                    width: 1rem;
                    height: 1rem;
                  `}
                  source={creditCardImageSource}
                />
              )}
              {creditCardFormData.type === 'card-on-file' && (
                <Label>
                  {creditCardIcon?.name} ending in {creditCardFormData?.last4}
                </Label>
              )}
              {creditCardFormData.type === 'new-card' && (
                <Label>{creditCardIcon?.name} — this card will be saved as your account’s payment method when you place your order</Label>
              )}
            </div>
          </div>
        </DisableElementOverlay>
      )}
      {isEditing && (
        <div>
          <Header
            size={5}
            weight={'bold'}
          >
            New credit card
          </Header>
          <Label
            color={'dark-900-64'}
            size={'sm'}
          >
            This card will be used as your account’s new payment method.
          </Label>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              gap: 1rem;
              align-items: center;
            `}
          >
            <FormCreditCardInput
              css={css`
                flex-grow: 1;
              `}
              fieldKey={creditCardFieldKey}
              version={paymentVersion}
            />
            <div
              css={css`
                display: flex;
                gap: 0.625rem;
              `}
            >
              <Button
                onClick={submitForm}
                loading={isSubmitting}
                disabled={isCreditCardComplete}
                theme={'primary'}
                iconLeft={'Done'}
              >
                Save new card
              </Button>
              <Button
                onClick={() => {
                  onEditing(false);
                  setFormInput<null>(creditCardFieldKey, null);
                }}
                iconRight={'Cancel'}
                disabled={isSubmitting || typeof creditCardFormData === 'undefined'}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DisposalCreditCard;
