import { createContext } from 'react';
import { ToastProps } from '~/neo-ui/packages/toast/Toast';

export type ToastContextData = {
  /**
   * Add a toast to the queue
   */
  addToast: (id: ToastProps) => void;
  /**
   * Remove a toast from the queue
   */
  removeToast: (id: string) => void;
  /**
   * All toasts in the queue
   */
  toasts: ToastProps[];
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ToastContext = createContext<ToastContextData | undefined>(undefined);

export default ToastContext;
