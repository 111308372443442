import { PriorityDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Priority/Dto/Model.gen';
import { BudgetCurrencyDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Dto/Model.gen';
import { ScorecardItemDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardItem/Dto/Model.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import DraggableElement from '~/neo-ui/packages/drag-and-drop/packages/draggable-element/DraggableElement';
import FormSubmitIndicator from '~/neo-ui/packages/form/packages/form-submit-indicator/FormSubmitIndicator';
import Icon from '~/neo-ui/packages/icon/Icon';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import ScorecardItemCreateCard from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/packages/scorecard-item-create-card/ScorecardItemCreateCard';
import ScorecardItemRemoveButton from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/packages/scorecard-item-remove-button/ScorecardItemRemoveButton';
import ScorecardItemUpdateForm from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/packages/scorecard-item-update-form/ScorecardItemUpdateForm';
import ScorecardItem from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/ScorecardItem';
import ScorecardItemsDropZone from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-items-drop-zone/ScorecardItemsDropZone';
import Testable from '~/neo-ui/packages/testable/Testable';
import ScorecardItemAutomationRefreshButton from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/packages/scorecard-item-automation-refresh-button/ScorecardItemAutomationRefreshButton';
import ScorecardItemAutomationInfoIndicator from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/packages/scorecard-item-automation-info-indicator/ScorecardItemAutomationInfoIndicator';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { ScaleOption } from '~/neo-ui/packages/select/packages/scale-select/ScaleSelect';

export type ScorecardItemsCardProps = {
  scorecardId: string;
  scorecardCategoryId?: string;
  organizationId: string;
  scorecardItems: ScorecardItemDto[];
  currency: BudgetCurrencyDto;
  scoreDisplayAvailabilities: ScaleOption[];
  priorityDisplayAvailabilities: PriorityDto[];
  onScorecardUpdateFailed: () => void;
};

const ScorecardItemsCard = ({
  scorecardId,
  scorecardCategoryId,
  organizationId,
  scorecardItems,
  currency,
  scoreDisplayAvailabilities,
  priorityDisplayAvailabilities,
  onScorecardUpdateFailed,
}: ScorecardItemsCardProps) => {
  const [orderedScorecardItems, setOrderedScorecardItems] = React.useState(scorecardItems);

  // Refreshes item list when items are added / removed
  React.useEffect(() => setOrderedScorecardItems(scorecardItems), [scorecardItems]);

  return (
    <Card
      css={css`
        padding: 1.25rem;
      `}
    >
      <Header
        size={3}
        css={css`
          margin-bottom: 1.0625rem;
        `}
      >
        Category scores
      </Header>
      <ScorecardItemsDropZone
        scorecardId={scorecardId}
        scorecardCategoryId={scorecardCategoryId}
        scorecardItems={orderedScorecardItems}
        onReorder={items => setOrderedScorecardItems(items)}
      >
        {orderedScorecardItems.map((scorecardItem, index) => (
          <DraggableElement
            key={scorecardItem.scorecardItemId}
            index={index}
            draggableId={scorecardItem.scorecardItemId}
            css={css`
              margin-bottom: 0.625rem;
            `}
            isUsingCustomizeDragHandler={true}
          >
            {({ dragHandleProps }) => (
              <Card
                backgroundColor={'light-200'}
                shadow={'none'}
                padding={'none'}
                key={`scorecard-item-${scorecardItem.scorecardItemId}`}
              >
                <Testable testId={'scorecard-item'}>
                  <ScorecardItemUpdateForm
                    scorecardItem={scorecardItem}
                    currency={currency}
                    onScorecardUpdateFailed={onScorecardUpdateFailed}
                  >
                    <div
                      {...dragHandleProps}
                      css={css`
                        background-color: ${colorToCode('light-300')};
                        border-radius: 0.625rem 0.625rem 0 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 0.625rem;
                        min-height: 2.25rem;
                      `}
                    >
                      <ScorecardItemRemoveButton
                        scorecardId={scorecardItem.scorecardId}
                        scorecardCategoryId={scorecardCategoryId}
                        scorecardItemId={scorecardItem.scorecardItemId}
                      />
                      <Icon
                        icon={'DragVertically'}
                        color={'dark-050'}
                        sizePx={20}
                        css={css`
                          position: absolute;
                          margin-left: auto;
                          margin-right: auto;
                          left: 0;
                          right: 0;
                        `}
                      />
                      <div
                        css={css`
                          display: flex;
                          gap: 0.375rem;
                          align-items: center;
                        `}
                      >
                        <FormSubmitIndicator />
                        {scorecardItem.automationData && (
                          <ScorecardItemAutomationRefreshButton
                            scorecardId={scorecardItem.scorecardId}
                            scorecardItemId={scorecardItem.scorecardItemId}
                            organizationId={organizationId}
                            scorecardItemAutomationData={scorecardItem.automationData}
                          />
                        )}
                        {scorecardItem.automationData && (
                          <ScorecardItemAutomationInfoIndicator
                            organizationId={organizationId}
                            scorecardItemAutomationData={scorecardItem.automationData}
                          />
                        )}
                      </div>
                    </div>
                    <ScorecardItem
                      scorecardItem={scorecardItem}
                      currency={currency}
                      scoreDisplayAvailabilities={scoreDisplayAvailabilities}
                      priorityDisplayAvailabilities={priorityDisplayAvailabilities}
                    />
                  </ScorecardItemUpdateForm>
                </Testable>
              </Card>
            )}
          </DraggableElement>
        ))}
      </ScorecardItemsDropZone>
      <ScorecardItemCreateCard
        scorecardId={scorecardId}
        scorecardCategoryId={scorecardCategoryId}
      />
    </Card>
  );
};

export default ScorecardItemsCard;
