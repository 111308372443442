import { FieldKey } from '@AssetManagementClient/Primitives/Query.gen';
import mapBackendFieldKey from '~/neo-ui/packages/form/packages/form-action/packages/form-validation/mapBackendFieldKey';
import { SortingSet } from '~/neo-ui/packages/table/packages/filter-table/FilterTable';

const buildSortingSet = <T>(orderings: FieldKey[], mapBackendFields: boolean = true): SortingSet =>
  mapBackendFields
    ? {
        sortableColumnKeys: orderings
          .map(ordering => ordering.value)
          // Convert field keys to camel case
          .map(mapBackendFieldKey),
      }
    : {
        sortableColumnKeys: orderings.map(ordering => ordering.value),
        // no need to convert field keys for dynamic console
      };

export default buildSortingSet;
