import { DataPointFilterSetting, InsightDto } from '@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Model.gen';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { Enum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import { Enum as AssetScopeEnum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/AssetScope/Logic/AssetScopeFactoryNested.gen';
import { assertNeverOrThrow } from '~/extensions/packages/types/assertNever';

export const dataPointFilterSettingsToGroupedFilters = (filterSettings?: DataPointFilterSetting[]) => {
  if (filterSettings === undefined || filterSettings.length === 0) {
    return new Map<string, DataPointFilterSetting[]>();
  }
  return filterSettings.reduce((group, filterSetting) => {
    const key = filterSetting.key.value;
    if (!group.has(key)) {
      group.set(key, [filterSetting]);
    } else {
      group.get(key)!.push(filterSetting);
    }
    return group;
  }, new Map<string, DataPointFilterSetting[]>());
};

// Not the best way, but we can clean when we have separate insight dashboard for different asset types.
const getAssetConsoleUrl = (hardwareConsoleUrl: string, peopleConsoleUrl: string, scope: FrontendScope, insight: InsightDto) => {
  let url;
  let baseUrl = '';

  switch (insight.assetScopeEnum) {
    case AssetScopeEnum.Hardware:
      baseUrl = hardwareConsoleUrl;
      break;
    case AssetScopeEnum.EndUser:
      baseUrl = peopleConsoleUrl;
      break;
    case AssetScopeEnum.Contact:
      baseUrl = peopleConsoleUrl;
      break;
    case AssetScopeEnum.DeviceBackup:
    case AssetScopeEnum.Software:
      throw new Error('Unsupported asset type');
    default:
      assertNeverOrThrow(insight.assetScopeEnum);
      break;
  }

  switch (scope.type) {
    case Enum.Organization:
      url = baseUrl.replace('__ORGANIZATION_ID__', scope.organizationId);
      break;
    case Enum.Account:
      url = baseUrl;
      break;
    case Enum.Application:
      throw new Error('Case not supported.');
  }
  const parameters = new URLSearchParams();

  if (insight.columns.length > 0) {
    parameters.set('Columns', insight.columns.join(','));
  }

  const groupedFilters = dataPointFilterSettingsToGroupedFilters(insight.dataPointFilterSettings);

  groupedFilters.forEach((filterSettings, key) => {
    parameters.set(key, filterSettings.map(({ operator, values }) => `${operator}:${values.join(',')}`).join(';'));
  });
  const paramString = parameters.toString();
  return `${url}${paramString === '' ? '' : `?${paramString}`}`;
};

export default getAssetConsoleUrl;
