import { useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { exploreProductsGet } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { ExploreProductGroups } from '@SubscriptionClient/BeastClient/Beast/Msp/Billing/ExploreProducts/Dto.gen';

const useExploreProductsGet = () => {
  const { callApi } = useApi();
  const [exploreProductGroups, setExploreProductGroups] = useState<ExploreProductGroups>();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => exploreProductsGet({}));

      if (!response?.exploreProductGroups) {
        return;
      }

      setExploreProductGroups(response.exploreProductGroups);
    })();
  }, [callApi]);

  return { exploreProductGroups };
};

export default useExploreProductsGet;
