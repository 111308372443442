import { SVGProps, forwardRef, memo } from 'react';

const ErrorIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
      >
        <g clipPath="url(#clip0_2209_3664)">
          <circle
            cx="12"
            cy="12"
            r="12"
            fill="#D72A47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9998 13.414L8 17.4139L6.58578 15.9997L10.5856 11.9998L6.58578 7.99997L8 6.58575L11.9998 10.5856L16 6.58545L17.4142 7.99966L13.4141 11.9998L17.4142 16L16 17.4142L11.9998 13.414Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2209_3664">
            <rect
              width="24"
              height="24"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }),
);

export default ErrorIcon;
