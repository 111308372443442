import { css } from '@emotion/react';
import { mobileBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import { ReactNode } from 'react';

export type PresentationHeaderProps = { children: ReactNode };

export const presentationHeaderMaxHeightRem = 7.5;

const PresentationHeader = ({ children }: PresentationHeaderProps) => (
  <header
    css={css`
      height: ${presentationHeaderMaxHeightRem}rem;

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.875rem 3.125rem 0 3.125rem;

      @media (max-width: ${mobileBreakpointRem}rem) {
        padding: 1.875rem 1.25rem;
      }
    `}
  >
    {children}
  </header>
);

export default PresentationHeader;
