import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import Button from '~/neo-ui/packages/button/Button';
import Size from '~/neo-ui/model/Size';
import { Styleable } from '~/neo-ui/model/capacity';
import tooltipStyles from '~/neo-ui/packages/tooltip/styles/tooltipStyles';
import * as React from 'react';
import { InitiativeCreateSource } from '~/wm/packages/strategy/model/strategyDataCollectionSources';
import InitiativeEngagementActionLinkModal from '~/wm/packages/strategy/packages/initiative/packages/initiative-engagement-action-link-modal/InitiativeEngagementActionLinkModal';
import useInitiativeCreate from '~/wm/packages/strategy/packages/initiative/hooks/useInitiativeCreate';
import useInitiativeEngagementActionLinkBulk from '~/wm/packages/strategy/packages/initiative/packages/initiative-engagement-action-link-modal/hooks/useInitiativeEngagementActionLinkBulk';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useEngagementActionListMutationContext from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListMutationContext';

export type EngagementActionInitiativeLinkButtonProps = {
  action: EngagementActionDto;
  size: Exclude<Size, 'xl'>;
  initiativeCreatedFrom: InitiativeCreateSource;
} & Styleable;

const EngagementActionInitiativeLinkButton = ({
  action,
  size,
  initiativeCreatedFrom,
  className,
}: EngagementActionInitiativeLinkButtonProps) => {
  const { organizationId } = useOrganizationContext();
  const { triggerEngagementActionListReload } = useEngagementActionListMutationContext();

  const [isDisplayingModal, setDisplayModal] = React.useState(false);

  const onSuccess = () => {
    triggerEngagementActionListReload();
    setDisplayModal(false);
  };

  const { createInitiative, isCreating } = useInitiativeCreate(onSuccess);
  const { linkEngagementActions, isLinking } = useInitiativeEngagementActionLinkBulk(onSuccess);

  return (
    <div>
      <Button
        size={size}
        className={className}
        iconLeft={'Initiative'}
        preventOnClickPropagation={true}
        css={tooltipStyles('Create Initiative link')}
        onClick={() => {
          setDisplayModal(true);
        }}
      />
      {isDisplayingModal && (
        <InitiativeEngagementActionLinkModal
          isOpen={isDisplayingModal}
          isLoading={isCreating || isLinking}
          onDismiss={() => {
            setDisplayModal(false);
          }}
          onExistingInitiativeSave={initiativeId => {
            linkEngagementActions(initiativeId, [action.engagementActionId]);
          }}
          onNewInitiativeSave={selectedTemplate => {
            createInitiative(
              organizationId,
              false,
              initiativeCreatedFrom,
              selectedTemplate
                ? {
                    id: selectedTemplate.initiativeTemplateId,
                    name: selectedTemplate.name,
                  }
                : undefined,
              undefined,
              action.engagementActionId,
            );
          }}
        />
      )}
    </div>
  );
};

export default EngagementActionInitiativeLinkButton;
