// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.
import { AddressBusiness as WarrantyClientCommonLocationModelAddressAddressBusiness } from "@WarrantyClient/Common/Location/Model/Address.gen"


/**
 * Information of the asset the service is requested for
 */
export interface Asset
{
  /**
   * Unique identifier for the asset the request is submitted for
   */
  assetId: string;
  /**
   * Serial number of the asset the request is submitted for
   */
  serialNumber: string;
  /**
   * Account identifier for the MSP that owns the asset
   * It is used together with the serial number to find the unique asset in our records
   */
  accountId: string;
  /**
   * Name of the asset
   */
  name: string;
  /**
   * Model number of the asset
   */
  modelNumber: string | undefined;
  /**
   * Name of the organization that owns the asset
   */
  organizationName: string;
  /**
   * Address of the asset's site
   */
  siteAddress: WarrantyClientCommonLocationModelAddressAddressBusiness;
}


/**
 * Note: this distributor enum is referenced by the ServiceRequest and ServiceRequestSync tables
 * as well as the DistributorFactory.Enum of Beast.
 * 
 * See {49C63665-6490-46ED-83EF-91623E186F1C}
 * 
 * Ensure all tables and the referenced enum are up to date if adding new entries.
 */
export enum DistributorEnum
{
  ParkPlace = "ParkPlace",
  Ingram = "Ingram",
  WarrantyMaster = "WarrantyMaster",
  ComputerPlus = "ComputerPlus",
  SpencerTech = "SpencerTech",
}



/**
 * Information of the contact person on site
 */
export interface SiteContact
{
  /**
   * Name of the contact person on site
   */
  fullName: string;
  /**
   * Email of the contact person on site
   */
  email: string;
  /**
   * Phone of the contact person on site
   */
  phone: string;
}


/**
 * All request statuses possible are defined here
 * Note: this enum is also referenced by Beast
 * 
 * See {10885E17-389F-481B-BFBA-B66345EF0D67}
 * 
 * Ensure all referenced enums are up to date if adding new entries.
 */
export enum StatusEnum
{
  InProgress = "InProgress",
  Complete = "Complete",
  Submitted = "Submitted",
}


