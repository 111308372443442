import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import BadgeMarketingPro from '~/neo-ui/packages/badge/packages/badge-marketing/packages/badge-marketing-pro/BadgeMarketingPro';
import AnchorWrapper from '~/neo-ui/packages/anchor/packages/anchor-wrapper/AnchorWrapper';
import Icon from '~/neo-ui/packages/icon/Icon';
import React, { ReactNode } from 'react';
import { enceladusMainBodyPaddingRem } from '~/neo-ui/packages/layout/packages/layout-enceladus/packages/enceladus-body/EnceladusBody';
import useLayoutHeaderContext from '~/neo-ui/packages/layout/packages/layout-enceladus/context/hooks/useLayoutHeaderContext';

export type EnceladusBodyUpgradeBannerProps = { bannerLeftComponent: ReactNode; learnMoreLink?: string };

/**
 * An optional banner that exists between the top of the 'main' html element and the bottom of LayoutHeader
 */
const EnceladusBodyUpgradeBanner = () => {
  const { upgradeBannerProps, setUpgradeBannerProps } = useLayoutHeaderContext();

  return typeof upgradeBannerProps === 'undefined' ? null : (
    <div
      css={css`
        position: sticky;
        z-index: 1040;

        display: flex;
        align-items: center;
        gap: 0.3125rem;
        background: linear-gradient(90deg, ${colorToCode('primary-400')}, ${colorToCode('secondary-400')} 100%);
        color: ${colorToCode('light-000')};
        padding: 1rem;
        margin: -${enceladusMainBodyPaddingRem}rem -${enceladusMainBodyPaddingRem}rem 1.875rem -${enceladusMainBodyPaddingRem}rem;
        top: -${enceladusMainBodyPaddingRem}rem;
      `}
    >
      {upgradeBannerProps.bannerLeftComponent}
      <b>Lifecycle Manager</b>
      <BadgeMarketingPro
        size={'xs'}
        shouldInvertColors={true}
      />
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-left: auto;
        `}
      >
        {upgradeBannerProps.learnMoreLink && (
          <AnchorWrapper
            href={upgradeBannerProps.learnMoreLink}
            css={css`
              border-radius: 0.25rem;
              border: 0.0625rem solid ${colorToCode('light-000')};
              padding: 0.25rem;
              transition: all 150ms;

              &:hover {
                background-color: ${colorToCode('secondary-500')};
              }
            `}
          >
            Learn more
          </AnchorWrapper>
        )}
        <Icon
          icon={'Bad'}
          color={'light-000'}
          onClick={() => setUpgradeBannerProps(undefined)}
          css={css`
            cursor: pointer;
          `}
        />
      </div>
    </div>
  );
};

export default EnceladusBodyUpgradeBanner;
