import { Request as LinkRequest } from '@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Link/Controller/OrganizationLinkControllerNested.gen';
import { organizationLink } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { Discriminant } from '@AssetManagementClient/Primitives/Results/OrganizationLinkControllerNested/Response_/OrganizationLinkError_/ResultNested.gen';
import { OperationDefinition } from '~/legacy-ui/packages/operation/model/buildOperation';
import translateToGlobalError from '~/wm/packages/api/packages/api-error/convert/translateToGlobalError';
import callApiOrError from '~/wm/packages/api/packages/api-result/callApiOrError';
import Organization from '~/wm/packages/organization/model/Organization';
import OrganizationLink from '../../../model/OrganizationLink';
import linkingDescription from '../../../operation/linkingDescription';
import linkingProgress from '../../../operation/linkingProgress';

type AddLinksContext = {
  targetOrganization: Organization;
  selectedOrganizations: OrganizationLink[];
};

const addLinksOperation: OperationDefinition<AddLinksContext> = {
  label: 'Linking clients',
  description: linkingDescription,
  action: async ({ targetOrganization, selectedOrganizations }) => {
    const linkRequest: LinkRequest = {
      organizationIdsToLink: selectedOrganizations.map(org => org.id),
      name: targetOrganization.name,
    };
    return translateToGlobalError(
      () => callApiOrError(() => organizationLink(linkRequest)),
      response => (response.case === Discriminant.Error ? { message: response.value.message } : undefined),
    );
  },
  sensitivity: {
    type: 'confirmation-required',
    theme: 'positive',
    confirmLabel: 'Apply',
  },
  actions: ({ selectedOrganizations, targetOrganization }) =>
    selectedOrganizations.map(org => ({
      label: `Link ${org.name}`,
      description: `Its assets will be included in ${targetOrganization.name}.`,
    })),
  icon: 'Link',
  progress: ({ targetOrganization }) => linkingProgress({ parentOrganizationId: targetOrganization.id }),
};

export default addLinksOperation;
