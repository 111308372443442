import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import ContractLinkAssetsModal from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-link-assets-button/packages/contract-link-assets-modal/ContractLinkAssetsModal';
import { ContractAssetInfo } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-link-assets-button/modal/ContractAssetInfo';
import { Styleable } from '~/neo-ui/model/capacity';

type ContractLinkAssetsButtonProps = {
  organizationId: string;
  selectedAssets: ContractAssetInfo[];
  onSelectedAssets: (selectedAssetIds: ContractAssetInfo[]) => void;
} & Styleable;

const ContractLinkAssetsButton = ({ organizationId, selectedAssets, onSelectedAssets, className }: ContractLinkAssetsButtonProps) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Button
        iconLeft={'Asset'}
        onClick={() => {
          setModalOpen(true);
        }}
        className={className}
      >
        Add Assets
      </Button>
      <ContractLinkAssetsModal
        organizationId={organizationId}
        selectedAssets={selectedAssets}
        isOpen={isModalOpen}
        onDismiss={() => {
          setModalOpen(false);
        }}
        onConfirm={selectedAssetIds => {
          onSelectedAssets(selectedAssetIds);
        }}
      />
    </React.Fragment>
  );
};

export default ContractLinkAssetsButton;
