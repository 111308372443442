import LayoutHeaderFooterProps from '~/neo-ui/packages/layout/types/LayoutHeaderFooterProps';
import LayoutFooterEnceladus from '~/neo-ui/packages/layout/packages/footer/LayoutFooterEnceladus';
import LayoutFooterPhp from '~/neo-ui/packages/layout/packages/footer/LayoutFooterPhp';
import { isIframe } from '~/extensions/packages/iframe/isIframe';
import { css } from '@emotion/react';

/**
 * A header that sticks to the bottom of the page.
 */
const LayoutFooter = ({ className, leftControls, rightControls, layoutBarComponent, toolbarClassName }: LayoutHeaderFooterProps) =>
  typeof WM.account !== 'undefined' && WM.account.isEnceladusAllowed ? (
    <LayoutFooterEnceladus
      layoutBarComponent={layoutBarComponent}
      leftControls={leftControls}
      rightControls={rightControls}
      className={className}
      toolbarClassName={toolbarClassName}
    />
  ) : (
    <LayoutFooterPhp
      layoutBarComponent={layoutBarComponent}
      leftControls={leftControls}
      rightControls={rightControls}
      className={className}
      toolbarClassName={toolbarClassName}
      css={css`
        ${isIframe() ? 'left: 0;' : ''}
      `}
    />
  );

export default LayoutFooter;
