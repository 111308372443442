import { useEffect, useState } from 'react';
import { Response } from '@SubscriptionClient/BeastClient/Beast/Msp/Billing/SelfUpgrade/Controller/PurchasePlanCheckoutSummaryGetControllerNested.gen';
import { subscriptionPurchasePlanCheckoutSummaryGet } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import { Request as SelfUpgradeSummaryGetRequest } from '@SubscriptionClient/Subscription/Packages/Upgrade/Controller/PurchasePlanCheckoutSummaryGetControllerNested.gen';

interface PurchaseCheckoutSummaryViewModelState {
  data?: Response['data'];
  purchaseCheckoutSummaryLoading: boolean;
}

const usePurchaseCheckoutSummaryViewModel = (request: SelfUpgradeSummaryGetRequest, isReadyToPay: boolean) => {
  const [state, setState] = useState<PurchaseCheckoutSummaryViewModelState>({
    data: undefined,
    purchaseCheckoutSummaryLoading: true,
  });
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => subscriptionPurchasePlanCheckoutSummaryGet(request));

      if (!response) {
        return;
      }
      setState({ data: response.data, purchaseCheckoutSummaryLoading: false });
    })();
  }, [callApi, request, isReadyToPay]);

  return state;
};

export default usePurchaseCheckoutSummaryViewModel;
