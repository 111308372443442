import { css } from '@emotion/react';
import * as React from 'react';
import SelectList from '~/neo-ui/packages/list/packages/select-list/SelectList';
import SelectOptionGroup from '~/neo-ui/packages/select/model/SelectOptionGroup';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { BudgetQuarterDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Packages/BudgetQuarter/Dto/Model.gen';

export type InitiativeSelectProps = {
  initiatives: InitiativeSelectOption[];

  selectedInitiative: InitiativeSelectOption | undefined;
  onSelect: (initiative: InitiativeSelectOption | undefined) => void;
};

export type InitiativeSelectOption = {
  initiativeId: string;
  name: string;
  budgetQuarter?: BudgetQuarterDto;
};

const InitiativeSelect: React.FunctionComponent<InitiativeSelectProps> = ({ initiatives, selectedInitiative, onSelect }) => {
  const initiativesById = React.useMemo(() => new Map(initiatives.map(initiative => [initiative.initiativeId, initiative])), [initiatives]);

  const initiativeOptions = React.useMemo(
    () =>
      new Map(
        initiatives.map((initiative): [string, SelectOption] => [
          initiative.initiativeId,
          {
            label: initiative.name,
            value: initiative.initiativeId,
            description:
              typeof initiative.budgetQuarter !== 'undefined'
                ? `Q${initiative.budgetQuarter.quarter}, ${initiative.budgetQuarter.year}`
                : 'Unscheduled',
            descriptionMuted: true,
          },
        ]),
      ),
    [initiatives],
  );

  const initiativeListGroup = React.useMemo<SelectOptionGroup[]>(() => [{ options: [...initiativeOptions.values()] }], [initiativeOptions]);

  return (
    <div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          max-height: 17.1875rem;
          overflow-y: auto;
          margin-top: 0.625rem;
        `}
      >
        <SelectList
          options={initiativeListGroup}
          selectedOptionValue={selectedInitiative ? initiativeOptions.get(selectedInitiative.initiativeId) : undefined}
          onOptionSelected={option => onSelect(initiativesById.get(option.value))}
        />
      </div>
    </div>
  );
};

export default InitiativeSelect;
