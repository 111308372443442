import * as React from 'react';
import LifecycleReportCustomizationSettingsFormWrapper from '~/neo-ui/packages/table/packages/console/download/packages/lifecycle-report-download-button/packages/lifecycle-report-customization-settings-form-wrapper/LifecycleReportCustomizationSettingsFormWrapper';
import { css } from '@emotion/react';
import ActionButton from '~/neo-ui/packages/button/packages/action-button/ActionButton';
import { LifecycleReportDownloadModalProps } from '~/neo-ui/packages/table/packages/console/download/packages/lifecycle-report-download-button/packages/lifecycle-report-download-modal/LifecycleReportDownloadModal';

export type LifecycleReportDownloadButtonProps = {
  organizationId: string;
} & Pick<LifecycleReportDownloadModalProps, 'lifecycleReportDownloadLocation' | 'lmLastCheckinDateEnabled'>;
const LifecycleReportDownloadButton: React.FunctionComponent<LifecycleReportDownloadButtonProps> = ({
  organizationId,
  lifecycleReportDownloadLocation,
  lmLastCheckinDateEnabled,
}) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <React.Fragment>
      <ActionButton
        css={css`
          border-radius: 0.313rem;
          box-shadow: none;
        `}
        icon="PageDownload"
        size={'sm'}
        tooltipContent={'Download lifecycle report'}
        activeBackgroundColor={'dark-300'}
        onClick={() => {
          setModalOpen(true);
        }}
      />
      {isModalOpen && (
        <LifecycleReportCustomizationSettingsFormWrapper
          organizationId={organizationId}
          isModalOpen={isModalOpen}
          onDismiss={() => setModalOpen(false)}
          lifecycleReportDownloadLocation={lifecycleReportDownloadLocation}
          lmLastCheckinDateEnabled={lmLastCheckinDateEnabled}
        />
      )}
    </React.Fragment>
  );
};

export default LifecycleReportDownloadButton;
