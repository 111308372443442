import { useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { Discriminant } from '@AssetManagementClient/Primitives/Results/DisposalCheckoutSelfControllerNested/Response_/ApiError_/ResultNested.gen';
import { Response } from '@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Checkout/Packages/DisposalCheckoutSelf/Controller/DisposalCheckoutSelfControllerNested.gen';
import { disposalCheckoutSelf } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { DisposalCheckoutSelfPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Packages/DisposalCheckoutSelf/Dto/Model.gen';

export type UseDisposalSelfCheckout = () => {
  disposalCheckout: (disposalCheckoutSelfPayload: DisposalCheckoutSelfPayloadDto) => Promise<Response | undefined>;
};

const useDisposalSelfCheckout: UseDisposalSelfCheckout = () => {
  const { callApi } = useApi();

  const checkout = useCallback(
    async (disposalCheckoutSelfPayload: DisposalCheckoutSelfPayloadDto) => {
      const response = await callApi(() => disposalCheckoutSelf({ disposalCheckoutSelfPayload }));

      if (!response) {
        return;
      }

      switch (response.case) {
        case Discriminant.Error:
          // eslint-disable-next-line no-throw-literal
          throw {
            GlobalMessage: response.value.messagePublic,
            Errors: response.value.fieldErrors.reduce<{
              [index: string]: string[];
            }>(
              (errors, fieldError) => ({
                ...errors,
                [fieldError.fieldKey]: [fieldError.messagePublic],
              }),
              {},
            ),
            // The abstraction is not layered properly that's why
            // we have to specify 400 here - WM-3861
            Status: 400,
          };

        case Discriminant.Ok:
          return response.value;
      }

      return;
    },
    [callApi],
  );

  return {
    disposalCheckout: checkout,
  };
};

export default useDisposalSelfCheckout;
