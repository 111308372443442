import { ActivitySearchResult } from '@BeastClient/Activity/Search/Model/Query.gen';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import ActivityLogEntryCard from '~/wm/packages/activity/packages/activity-log/packages/activity-log-entry/packages/activity-log-entry-card/ActivityLogEntryCard';
import ActivityLogEntryModal from '~/wm/packages/activity/packages/activity-log/packages/activity-log-entry/packages/activity-log-entry-modal/ActivityLogEntryModal';

export type ActivityLogEntryProps = {
  entry: ActivitySearchResult;
  disableCallToAction?: boolean;
} & Styleable;

const ActivityLogEntry: React.FunctionComponent<ActivityLogEntryProps> = ({ entry, className, disableCallToAction = false }) => {
  const [isExpanded, setExpanded] = React.useState(false);
  return (
    <div>
      <ActivityLogEntryCard
        className={className}
        entry={entry}
        onClick={() => setExpanded(true)}
        disableCallToAction={disableCallToAction}
      />
      <ActivityLogEntryModal
        entry={entry}
        isOpen={isExpanded}
        onDismiss={() => setExpanded(false)}
        disableCallToAction={disableCallToAction}
      />
    </div>
  );
};

export default ActivityLogEntry;
