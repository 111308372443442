import { SimpleCellAlignmentDefinitionDto } from '@AssetManagementClient/Dashboard/Sdk/SharedComponent/Model/Main/SimpleTableComponentNested.gen';
import { css } from '@emotion/react';

export type TableCellVerticalAlignmentValue = 'top' | 'center' | 'bottom';
export type TableCellHorizontalAlignmentValue = 'left' | 'center' | 'right';
type FlexBoxVerticalAlignmentValue = 'baseline' | 'center' | 'end';
type FlexBoxHorizontalAlignmentValue = 'left' | 'center' | 'right';

const verticalAlignmentMap: {
  [key in TableCellVerticalAlignmentValue]: FlexBoxVerticalAlignmentValue;
} = {
  top: 'baseline',
  center: 'center',
  bottom: 'end',
};

const horizontalAlignmentMap: {
  [key in TableCellHorizontalAlignmentValue]: FlexBoxHorizontalAlignmentValue;
} = {
  left: 'left',
  center: 'center',
  right: 'right',
};

export type SimpleTableCellProps = {
  alignment: SimpleCellAlignmentDefinitionDto;
};

export const SimpleTableCell: React.FunctionComponent<React.PropsWithChildren<SimpleTableCellProps>> = ({ alignment, children }) => (
  <div
    css={css`
      display: flex;

      // Align Horizontally
      ${typeof alignment !== 'undefined' &&
      alignment.horizontal &&
      `justify-content: ${horizontalAlignmentMap[alignment.horizontal as TableCellHorizontalAlignmentValue]};`} // Align Vertically
      ${typeof alignment.vertical !== 'undefined' &&
      `align-items: ${verticalAlignmentMap[alignment.vertical as TableCellVerticalAlignmentValue]};`}
    `}
  >
    {children}
  </div>
);
