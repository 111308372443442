import { BrandingMspDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Branding/Dto/Model.gen';
import { RoadmapInitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import * as React from 'react';
import FiscalQuarter from '~/extensions/packages/date/packages/fiscal-quarter/FiscalQuarter';

export type RoadmapInitiativesContextData = {
  organizationId: string;
  organizationName: string;
  defaultFiscalQuarter: FiscalQuarter;
  focusInitiativeId: string | undefined;
  highlightedInitiativeIds: string[];
  setHighlightedInitiativeIds: React.Dispatch<React.SetStateAction<string[]>>;
  onInitiativeSave: (initiativeId: string) => void;
  branding: BrandingMspDto | undefined;
  fiscalQuarterAvailabilities: FiscalQuarter[];
  displayFiscalQuarters: FiscalQuarter[];
  onDisplayFiscalQuartersChange: (firstFiscalQuarter: FiscalQuarter) => void;
  isPresenting: boolean;
  initiativesByFiscalQuarter: Map<string, RoadmapInitiativeDto[]>;
  setInitiativesByFiscalQuarter: React.Dispatch<React.SetStateAction<Map<string, RoadmapInitiativeDto[]>>>;
  roadmapsFiscalQuarterPageSize: number;
  setRoadmapsFiscalQuarterPageSize: React.Dispatch<React.SetStateAction<number>>;
  isDefaultToShowUnscheduled: boolean;
  draftInitiativeId: string | undefined;
  setDraftInitiativeId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const RoadmapInitiativesContext = React.createContext<RoadmapInitiativesContextData | undefined>(undefined);

export default RoadmapInitiativesContext;
