import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import { getCountries } from '~/neo-ui/model/Country';

const CollapsedBillingSection = () => {
  const { formik } = useMultiStepForm<PurchaseSubscriptionForm>();
  const country = getCountries({ byAlpha2: formik.values.billingAddress?.address.countryFallback });
  return (
    <div
      css={{
        marginTop: '1rem',
      }}
    >
      {formik.values.billingAddress?.name}
      <br />
      {formik.values.billingAddress?.address.line1}
      <br />
      {formik.values.billingAddress?.address.line2 && <span>{formik.values.billingAddress?.address.line2}</span>}
      {formik.values.billingAddress?.address.line2 && <br />}
      {formik.values.billingAddress?.address.city} {formik.values.billingAddress?.address.stateCode}{' '}
      {formik.values.billingAddress?.address.zip}
      <br />
      {country[0].name}
    </div>
  );
};
export default CollapsedBillingSection;
