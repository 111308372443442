import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import * as React from 'react';
import { css } from '@emotion/react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import SummarySelection from '~/wm/packages/disposal/wizard/section/packages/summary-selection/SummarySelection';
import useWizardPageContext from '~/neo-ui/packages/wizard/packages/wizard-page/context/hooks/useWizardPageContext';
import Button from '~/neo-ui/packages/button/Button';
import DisposalBulkCheckoutFormData from '~/wm/packages/disposal/types/DisposalBulkCheckoutFormData';
import { DisposalOrderPickupMaterialTypeDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderPickup/Packages/DisposalOrderPickupMaterial/Dto/Model.gen';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';

export type OrderSummaryMaterialsModuleProps = {
  header: string;
  materialsFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData>;
  disposalOrderPickupMaterialTypeAvailabilities: DisposalOrderPickupMaterialTypeDto[];
};

const OrderSummaryMaterialsModule = ({
  header,
  materialsFieldKey,
  disposalOrderPickupMaterialTypeAvailabilities,
}: OrderSummaryMaterialsModuleProps) => {
  const { getFormInput } = useFormContext<DisposalBulkCheckoutFormData>();
  const { setCurrentStepIndex } = useWizardPageContext();
  const { trackEvent } = useEventTracking(TrackingServices.EventProcessor);

  const pickupMaterials = getFormInput<string[]>(materialsFieldKey).value;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Label
          size={'lg'}
          bold={true}
        >
          {header}
        </Label>
        <Button
          iconLeft={'Edit'}
          size={'sm'}
          onClick={() => {
            trackEvent('disposal_shipping_step_navigate', {
              context: 'disposal_summary_step_materials_edit_button_clicked',
            });
            setCurrentStepIndex(1);
          }}
        >
          Edit
        </Button>
      </div>
      {disposalOrderPickupMaterialTypeAvailabilities.map(pickupMaterialDetail => {
        const pickupMaterial = pickupMaterials.find(material => material === pickupMaterialDetail.uniqueId);
        return (
          <SummarySelection
            key={pickupMaterialDetail.uniqueId}
            isSelected={typeof pickupMaterial !== 'undefined'}
            icon={pickupMaterialDetail.icon as IconType}
            label={pickupMaterialDetail.summary ?? pickupMaterialDetail.label}
          />
        );
      })}
    </div>
  );
};

export default OrderSummaryMaterialsModule;
