import { css } from '@emotion/react';
import Size from '~/neo-ui/model/Size';
import { Styleable } from '~/neo-ui/model/capacity';
import Button from '~/neo-ui/packages/button/Button';
import { TicketLinkStateDto } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Packages/TicketLink/Dto/Model.gen';
import { Enum as AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Packages/TicketLink/Dto/Model/TicketLinkStateDtoNested.gen';

export type EngagementActionTicketButtonProps = {
  size: Exclude<Size, 'xl'>;
  ticketLinkState: TicketLinkStateDto;
} & Styleable;

const EngagementActionTicketButton = ({ size, ticketLinkState, className }: EngagementActionTicketButtonProps) => {
  const buttonCss = css`
    min-width: max-content;
    /**
     * This is here only because the address tag is not being displayed correctly for the icon. 
     * This results in an icon that is vertically centered. Specific to size xs
     *
     * Follow this GUID: ebebbe8c-23be-4e23-98aa-ab06ea6e7666
     */
    ${size === 'xs' &&
    `
        padding-top: 0.125rem;
        padding-left: 0.325rem;
        padding-right: 0.325rem;
    `}
  `;

  switch (ticketLinkState.type) {
    case AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Created:
      return (
        <Button
          css={buttonCss}
          className={className}
          size={size}
          iconLeft={'IntegrationTicket'}
          anchor={{ href: ticketLinkState.ticketLink.externalUrl, openInNewTab: true }}
          theme={'primary'}
          preventOnClickPropagation={true}
        >
          {ticketLinkState.ticketLink.ticketNumber}
        </Button>
      );
    case AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Pending:
      return (
        <Button
          css={buttonCss}
          className={className}
          size={size}
          iconLeft={'IntegrationTicket'}
          preventOnClickPropagation={true}
        >
          Pending...
        </Button>
      );
    case AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Error:
      return (
        <Button
          css={buttonCss}
          className={className}
          size={size}
          iconLeft={'IntegrationTicket'}
          theme={'negative'}
          preventOnClickPropagation={true}
        >
          Failed
        </Button>
      );
    default:
      return null;
  }
};

export default EngagementActionTicketButton;
