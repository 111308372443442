import useScorecardMutation, { UseScorecardMutationOptions } from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardMutation';
import { scorecardCategoryItemsDelete } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseScorecardCategoryWithItemsDelete = {
  deleteScorecardCategoryWithItems: (deletePayload: { scorecardCategoryId: string }) => void;
  isScorecardCategoryWithItemsDeleting: boolean;
};

const useScorecardCategoryWithItemsDelete = (
  scorecardId: string,
  options?: UseScorecardMutationOptions,
): UseScorecardCategoryWithItemsDelete => {
  const [deleteScorecardCategoryWithItems, isScorecardCategoryWithItemsDeleting] = useScorecardMutation(
    (deletePayload: { scorecardCategoryId: string }) =>
      scorecardCategoryItemsDelete({
        scorecardId,
        scorecardCategoryId: deletePayload.scorecardCategoryId,
      }),
    options,
  );

  return {
    deleteScorecardCategoryWithItems,
    isScorecardCategoryWithItemsDeleting,
  };
};

export default useScorecardCategoryWithItemsDelete;
