import Window from '~/neo-ui/packages/window/Window';
import InitiativeSaveForm from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import useInitiativeGet from '~/wm/packages/strategy/packages/initiative/hooks/useInitiativeGet';
import InitiativeSave from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/InitiativeSave';
import InitiativeSaveSubmitButton from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/packages/initiative-save-submit-button/InitiativeSaveSubmitButton';
import InitiativeSaveAssetRemoveSelectButtonGroup from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-assets-remove-select-button-group/InitiativeSaveAssetRemoveSelectButtonGroup';
import InitiativeDeleteButton from '~/wm/packages/strategy/packages/initiative/packages/initiative-delete/packages/initiative-delete-button/InitiativeDeleteButton';
import InitiativePdfDownloadButton from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-pdf-download-button/InitiativePdfDownloadButton';
import Button from '~/neo-ui/packages/button/Button';
import { useState } from 'react';
import InitiativeView from '~/wm/packages/strategy/packages/initiative/packages/initiative-view/InitiativeView';
import TicketFieldAvailabilitiesProvider from '~/wm/packages/integration/packages/ticket/context/TicketFieldAvailabilitiesProvider';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import InitiativeAssetSelectProvider from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/context/InitiativeAssetSelectProvider';

export type InitiativeSaveWindowProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onDeleteSuccessDismiss?: () => void;
  initiativeId: string;
  onSaveInitiative?: (initiativeId: string) => void;
  isPresenting?: boolean;
};

const InitiativeSaveWindow = ({
  isOpen,
  onDismiss,
  onDeleteSuccessDismiss,
  initiativeId,
  onSaveInitiative,
  isPresenting = false,
}: InitiativeSaveWindowProps) => {
  const { organizationId } = useOrganizationContext();
  const { initiative } = useInitiativeGet(initiativeId);
  const [isEditing, setEditing] = useState(!isPresenting);

  /**
   * Do not render if the initiative has not been fetched yet when in updating an existing initiative
   */
  if (typeof initiative === 'undefined') {
    return null;
  }

  return (
    <TicketFieldAvailabilitiesProvider organizationId={organizationId}>
      <InitiativeAssetSelectProvider initialDisplayedAssetIds={initiative?.assets.map(asset => asset.assetId)}>
        <InitiativeSaveForm
          key={initiativeId}
          initiative={initiative}
          onInitiativeSave={initiativeId => {
            if (typeof onSaveInitiative !== 'undefined') {
              onSaveInitiative(initiativeId);
            }

            if (isEditing && isPresenting) {
              setEditing(false);
            }
          }}
        >
          <Window
            isOpen={isOpen}
            onDismiss={onDismiss}
            title={'Initiative save'}
            maxWidthRem={82}
            headerProps={
              typeof initiativeId === 'undefined'
                ? undefined
                : {
                    rightControls: [
                      {
                        expanded: (
                          <InitiativeDeleteButton
                            initiativeId={initiativeId}
                            onDeleteSuccess={typeof onDeleteSuccessDismiss !== 'undefined' ? onDeleteSuccessDismiss : onDismiss}
                          />
                        ),
                      },
                      {
                        expanded: <InitiativePdfDownloadButton initiativeId={initiativeId} />,
                      },
                    ],
                  }
            }
            footerProps={{
              rightControls: [
                { expanded: <InitiativeSaveSubmitButton /> },
                {
                  expanded: (
                    <Button
                      iconLeft={'ActionClose'}
                      onClick={() => {
                        if (!isPresenting || (isPresenting && !isEditing)) {
                          onDismiss();
                        } else {
                          setEditing(false);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  ),
                },
              ],
              ...(isEditing && {
                leftControls: [{ expanded: <InitiativeSaveAssetRemoveSelectButtonGroup initiativeId={initiativeId} /> }],
              }),
            }}
          >
            {isEditing ? (
              <InitiativeSave initiative={initiative} />
            ) : (
              <InitiativeView
                onEdit={() => {
                  setEditing(true);
                }}
              />
            )}
          </Window>
        </InitiativeSaveForm>
      </InitiativeAssetSelectProvider>
    </TicketFieldAvailabilitiesProvider>
  );
};

export default InitiativeSaveWindow;
