import { CostTypeEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Model/BudgetLineItemNested.gen';
import { css } from '@emotion/react';
import { getIn } from 'formik';
import { produce } from 'immer';
import React from 'react';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { useFormInput } from '~/neo-ui/packages/form/hooks/useFormInput';
import FormTitleInput from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/FormTitleInput';
import InteractiveItem from '~/neo-ui/packages/interactive-item/InteractiveItem';
import SelectGroup from '~/neo-ui/packages/select/packages/select-group/SelectGroup';
import { FieldKeyExpression, FieldKeyExpressionSegment, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Testable from '~/neo-ui/packages/testable/Testable';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import FormNumberInput from '~/neo-ui/packages/form/packages/form-input/packages/form-number-input/FormNumberInput';
import { BudgetLineItemFormData } from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import useProposalBuilderGrid, {
  ProposalBuilderGridSize,
} from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/hooks/useProposalBuilderGrid';

export type FeesOneRowProps<T> = {
  fieldKey: FieldKeyExpression<T>;
  lineItem: BudgetLineItemFormData;
  lineItemIndex: number;
  currencyIcon: string;
  allowFocus: boolean;
  gridSize?: ProposalBuilderGridSize;
  onRemove: () => void;
};

const FeesOneTimeRow = <T extends { budgetLineItems: BudgetLineItemFormData[] }>({
  fieldKey,
  lineItem,
  lineItemIndex,
  currencyIcon,
  allowFocus,
  gridSize = 'md',
  onRemove,
}: FeesOneRowProps<T>) => {
  const [budgetLineItemsFormInput, _, { setValue: setBudgetLineItemsFormData, setTouched: setTouchedBudgetLineItemsFormData }] =
    useFormInput<T, BudgetLineItemFormData[]>(fieldKey);

  const budgetLineItems = budgetLineItemsFormInput.value;

  const setCurrentLineItem = (setter: (lineItem: BudgetLineItemFormData) => BudgetLineItemFormData) =>
    setBudgetLineItemsFormData(
      produce(budgetLineItems, draft => {
        draft[lineItemIndex] = setter(draft[lineItemIndex]);
      }),
    );

  // Error display logic
  const { errors, submitCount } = useFormContext();
  const labelError = getIn(
    errors,
    resolveFieldKey((formData: FieldKeyExpressionSegment<T>) => formData.budgetLineItems[lineItemIndex].label),
  );
  const costUnitsError = getIn(
    errors,
    resolveFieldKey((formData: FieldKeyExpressionSegment<T>) => formData.budgetLineItems[lineItemIndex].costUnits),
  );
  const errorExists = (typeof labelError !== 'undefined' || typeof costUnitsError !== 'undefined') && submitCount !== 0;

  const { gridFrs } = useProposalBuilderGrid(gridSize);

  return (
    <Testable testId={'fees-one-time-section-row'}>
      <InteractiveItem
        css={css`
          align-items: unset;
          padding-bottom: 0.625rem;
          margin-bottom: 0.625rem;

          // Grow and shrink line item controls on focus
          ${errorExists ? 'max-height: 4rem' : 'max-height: 3rem'};
          overflow: hidden;

          :focus-within {
            max-height: unset;
          }
        `}
      >
        <ButtonModifier
          icon={'Remove'}
          onClick={() => {
            setTouchedBudgetLineItemsFormData(false);
            onRemove();
          }}
          css={css`
            margin-top: 0.7rem;
          `}
        />
        <div
          css={css`
            margin-top: 0.375rem;
            display: grid;
            grid-template-columns: ${gridFrs.outerFr};
            gap: 0.625rem;
            flex-grow: 1;
          `}
        >
          <div
            css={css`
              display: grid;
              grid-template-columns: ${gridFrs.innerLeftFr};
              gap: 0.625rem;
              flex-grow: 1;
            `}
          >
            <FormTitleInput
              optimizePerformance={true}
              placeholder={'Line item...'}
              fieldKey={(values: FieldKeyExpressionSegment<T>) => values.budgetLineItems[lineItemIndex].label}
              css={css`
                margin-right: 0.625rem;
              `}
              hasDefaultFocus={allowFocus}
              hasDefaultSelect={allowFocus}
            />

            <Label
              muted={true}
              css={css`
                margin-top: 0.5rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              `}
            >
              {lineItem.costType === CostTypeEnum.PerAsset ? 'per asset' : 'flat fee'}
            </Label>
          </div>
          <FormNumberInput
            placeholder={'Estimated cost...'}
            fieldKey={(formData: FieldKeyExpressionSegment<T>) => formData.budgetLineItems[lineItemIndex].costUnits}
            prependCharacter={currencyIcon}
          />
          <SelectGroup
            size={'sm'}
            options={[
              {
                value: CostTypeEnum.Fixed,
                label: 'Flat fee',
              },
              {
                value: CostTypeEnum.PerAsset,
                label: 'Per asset',
              },
            ]}
            selectedOptionValue={lineItem.costType}
            onOptionSelected={selectedOption => {
              // Switch line item cost type
              setCurrentLineItem(lineItem =>
                produce(lineItem, draft => {
                  draft.costType = selectedOption.value;
                }),
              );
            }}
          />
        </div>
      </InteractiveItem>
    </Testable>
  );
};

export default FeesOneTimeRow;
