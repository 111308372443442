import { initiativePdf } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

type UseDownloadInitiativePdf = () => {
  downloadInitiativePdf: (initiativeId: string) => void;
};

export const useDownloadInitiativePdf: UseDownloadInitiativePdf = () => {
  const { callApi } = useApi();

  const downloadInitiativePdf = React.useCallback(
    async (initiativeId: string) => {
      const stream = await callApi(() => initiativePdf({ initiativeId }));

      if (!stream) {
        return;
      }
    },
    [callApi],
  );

  return { downloadInitiativePdf };
};

export default useDownloadInitiativePdf;
