import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useInitiativeList from '~/wm/packages/strategy/packages/initiative/hooks/useInitiativeList';
import InitiativeListSelectModal from '~/wm/packages/strategy/packages/initiative/packages/initiative-list-select-modal/InitiativeListSelectModal';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import * as React from 'react';
import { InitiativeTemplateDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/InitiativeTemplate/Dto/Model.gen';

export type InitiativeEngagementActionLinkModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  onDismiss: () => void;
  onExistingInitiativeSave: (initiativeId: string) => void;
  onNewInitiativeSave: (selectedTemplate: InitiativeTemplateDto | undefined) => void;
};

const InitiativeEngagementActionLinkModal = ({
  isOpen,
  isLoading,
  onDismiss,
  onNewInitiativeSave,
  onExistingInitiativeSave,
}: InitiativeEngagementActionLinkModalProps) => {
  const { organizationId } = useOrganizationContext();
  const { initiatives } = useInitiativeList(organizationId);

  if (typeof initiatives === 'undefined') {
    return null;
  }

  return (
    <InitiativeListSelectModal
      allowRedirect={false}
      title={'Add this action item to an Initiative'}
      description={
        <div>
          {' '}
          <Label
            css={css`
              margin-bottom: 1.25rem;
            `}
            size={'md'}
          >
            Each action item can only be added to one Initiative. You can either add this action item to an existing Initiative for this
            client or create a new one.
          </Label>
        </div>
      }
      isLoading={isLoading}
      isOpen={isOpen}
      onDismiss={onDismiss}
      initiativeSelectOptions={initiatives.map(initiative => ({
        initiativeId: initiative.id,
        name: initiative.name,
        budgetQuarter: initiative.budgetQuarter,
      }))}
      onExistingInitiativeSave={selectedInitiativeId => {
        onExistingInitiativeSave(selectedInitiativeId);
      }}
      onNewInitiativeSave={selectedTemplate => {
        onNewInitiativeSave(selectedTemplate);
      }}
    />
  );
};

export default InitiativeEngagementActionLinkModal;
