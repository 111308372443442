import { autoEmailReportEmailTemplateUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useAutoEmailReportListMutation from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportListMutation';
import { AutoEmailReportEmailTemplateUpdatePayloadDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';
import { UseAutoEmailReportEmailTemplateMutationOptions } from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/hooks/useAutoEmailReportEmailTemplateMutation';

type AutoEmailReportEmailTemplateUpdatePayload = {
  organizationId: string;
  autoEmailReportId: string;
  autoEmailReportEmailTemplateUpdatePayload: AutoEmailReportEmailTemplateUpdatePayloadDto;
};

export type UseAutoEmailReportEmailTemplateUpdate = {
  updateAutoEmailReportEmailTemplate: (
    autoEmailReportScheduleUpdatePayload: AutoEmailReportEmailTemplateUpdatePayload,
    isRequestActive?: () => boolean,
  ) => void;
  isUpdatingAutoEmailReportEmailTemplate: boolean;
};

const useAutoEmailReportEmailTemplateUpdate = (
  options?: UseAutoEmailReportEmailTemplateMutationOptions,
): UseAutoEmailReportEmailTemplateUpdate => {
  const [updateAutoEmailReportEmailTemplate, isUpdatingAutoEmailReportEmailTemplate] = useAutoEmailReportListMutation(
    (autoEmailReportEmailTemplateUpdatePayload: AutoEmailReportEmailTemplateUpdatePayload) =>
      autoEmailReportEmailTemplateUpdate({
        autoEmailReportId: autoEmailReportEmailTemplateUpdatePayload.autoEmailReportId,
        organizationId: autoEmailReportEmailTemplateUpdatePayload.organizationId,
        updatePayload: autoEmailReportEmailTemplateUpdatePayload.autoEmailReportEmailTemplateUpdatePayload,
      }),
    options,
  );

  return {
    updateAutoEmailReportEmailTemplate,
    isUpdatingAutoEmailReportEmailTemplate,
  };
};

export default useAutoEmailReportEmailTemplateUpdate;
