type UserType = { fullName?: string; firstName?: string; lastName?: string; email?: string };
export type FormatUserNameDisplay = (assignedUser: UserType) => string;

const isUndefinedOrWhitespace = (value?: string) => typeof value === 'undefined' || typeof value.match(/^ *$/) !== 'undefined';

const hasName = ({ fullName, firstName, lastName }: UserType) =>
  !isUndefinedOrWhitespace(fullName) || (!isUndefinedOrWhitespace(firstName) && !isUndefinedOrWhitespace(lastName));

const formatUserNameDisplay: FormatUserNameDisplay = assignedUser =>
  hasName(assignedUser)
    ? assignedUser.fullName || `${assignedUser.firstName} ${assignedUser.lastName}`
    : `${typeof assignedUser.email !== 'undefined' ? assignedUser.email.split('@')[0] : ''}`;

export default formatUserNameDisplay;
