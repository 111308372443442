import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { DisposalCheckoutDataDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Dto/Model.gen';
import { disposalCheckoutDataGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';

const useDisposalCheckoutDataGet = () => {
  const [disposalCheckoutData, setDisposalCheckoutData] = useState<DisposalCheckoutDataDto>();

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => disposalCheckoutDataGet({}));

      if (!response) {
        return;
      }

      setDisposalCheckoutData(response.disposalCheckoutData);
    })();
  }, [callApi]);

  return { disposalCheckoutData };
};

export default useDisposalCheckoutDataGet;
