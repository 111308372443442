import Button from '~/neo-ui/packages/button/Button';
import { useState } from 'react';
import AutoEmailReportEditForm from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-edit/packages/auto-email-report-edit-form/AutoEmailReportEditForm';
import AutoEmailReportEditWindow from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-edit/packages/auto-email-report-edit-window/AutoEmailReportEditWindow';
import { AutoEmailReportDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';

export type AutoEmailReportEditButtonProps = {
  autoEmailReport: AutoEmailReportDto;
};

const AutoEmailReportEditButton = ({ autoEmailReport }: AutoEmailReportEditButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Button
        size={'sm'}
        iconLeft={'Edit'}
        onClick={() => setIsOpen(prev => !prev)}
      />
      {isOpen && (
        <AutoEmailReportEditForm
          autoEmailReport={autoEmailReport}
          onAutoEmailReportEdit={() => setIsOpen(false)}
        >
          <AutoEmailReportEditWindow
            autoEmailReportId={autoEmailReport.autoEmailReportId}
            isOpen={isOpen}
            onDismiss={() => setIsOpen(false)}
          />
        </AutoEmailReportEditForm>
      )}
    </div>
  );
};

export default AutoEmailReportEditButton;
