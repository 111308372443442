import React from 'react';
import { css } from '@emotion/react';
import IntegrationFormInputTitle from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/packages/credential-display-modes/packages/credential-form/packages/credential-inputs/packages/IntegrationFormInputTitle';

export type IntegrationSetupInputSectionProps = {
  title: string;
  description?: string;
  children: React.ReactNode;
};

export const IntegrationFormInputSection = ({ title, description, children }: IntegrationSetupInputSectionProps) => (
  <React.Fragment>
    <IntegrationFormInputTitle
      title={title}
      description={description}
    />
    <div
      css={css`
        padding-top: 0.5rem;
        padding-bottom: 1rem;
      `}
    >
      {children}
    </div>
  </React.Fragment>
);
export default IntegrationFormInputSection;
