import * as React from 'react';
import { css } from '@emotion/react';
import FormTextareaInputInternal, {
  FormTextareaInputInternalProps,
} from '~/neo-ui/packages/form/packages/form-input/packages/form-textarea-input/FormTextareaInputInternal';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

/**
 * Text area element to be placed inside a Form component
 */
const FormTextareaInputInvisible = <T,>({
  fieldKey,
  placeholder,
  disabled,
  optimizePerformance,
  theme,
  fixedErrorPosition,
  textAreaCss,
  showFormError,
  hasDefaultFocus,
  hasDefaultSelect,
  onChange,
  className,
}: FormTextareaInputInternalProps<T>) => (
  <FormTextareaInputInternal
    fieldKey={fieldKey}
    optimizePerformance={optimizePerformance}
    fixedErrorPosition={fixedErrorPosition}
    className={className}
    placeholder={placeholder}
    disabled={disabled}
    theme={theme}
    onChange={onChange}
    textAreaCss={css([
      textAreaCss,
      css`
        width: 100%;
        background-color: ${colorToCode('blank')};
        resize: none;
        border: none;
        overflow: auto;
        outline: none;
        box-shadow: none;
      `,
    ])}
    showFormError={showFormError}
    hasDefaultSelect={hasDefaultSelect}
    hasDefaultFocus={hasDefaultFocus}
  />
);

export default FormTextareaInputInvisible;
