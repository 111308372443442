import * as React from 'react';
import { ScorecardItemAutomationDataDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardItem/Dto/Model.gen';
import { formatRelativeOrDateTimeFriendly } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import useScorecardUrlContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardUrlContext';

import Popup from '~/neo-ui/packages/popup/Popup';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import ScorecardItemAutomationRefreshModal from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/packages/scorecard-item-automation-refresh-modal/ScorecardItemAutomationRefreshModal';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Button from '~/neo-ui/packages/button/Button';

export type ScorecardItemInsightButtonProps = {
  scorecardId: string;
  scorecardItemId: string;
  organizationId: string;
  scorecardItemAutomationData: ScorecardItemAutomationDataDto;
};

const ScorecardItemInsightButton = ({
  scorecardId,
  scorecardItemId,
  organizationId,
  scorecardItemAutomationData,
}: ScorecardItemInsightButtonProps) => {
  const { insightViewUrl } = useScorecardUrlContext();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isModalOpen, setModalOpen] = React.useState(false);

  const scorecardItemAutomationInfo: React.ReactNode = (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Header
          size={4}
          weight={'bold'}
          color={'primary-400'}
        >
          Get info from Insights
        </Header>
        <ButtonModifier
          icon={'Bad'}
          onClick={() => setIsOpen(false)}
        />
      </div>
      <Label color={'primary-400'}>
        Last updated {formatRelativeOrDateTimeFriendly(parseISO(scorecardItemAutomationData.refreshedAt))}.
      </Label>
      <Label>Update this item to get the latest score & description from your Insight:</Label>
      <ButtonLink
        theme={'primary'}
        iconLeft={'Insight'}
        iconRight={'GoRight'}
        anchor={{
          href: insightViewUrl
            .replace('__ORGANIZATION_ID__', organizationId)
            .replace('__INSIGHT_ID__', scorecardItemAutomationData.insightId),
          openInNewTab: true,
        }}
        css={css`
          width: fit-content;
        `}
      >
        {scorecardItemAutomationData.insightDisplayLabel}
      </ButtonLink>
      <div
        css={css`
          background-color: ${colorToCode('light-200')};
          padding: 0.5rem 0.8125rem;
          margin: 0 -0.8125rem -0.5rem;
          border-radius: 0 0 0.625rem 0.625rem;
        `}
      >
        <Button
          iconLeft={'Reload'}
          theme={'primary'}
          onClick={() => {
            setModalOpen(true);
            setIsOpen(false);
          }}
        >
          Update now
        </Button>
      </div>
    </div>
  );

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement={'left-end'}
      backgroundColor={'light-000'}
      content={scorecardItemAutomationInfo}
    >
      <ButtonLink
        theme={'primary'}
        iconLeft={'Insight'}
        onClick={() => setIsOpen(prev => !prev)}
      />
      <ScorecardItemAutomationRefreshModal
        scorecardId={scorecardId}
        scorecardItemId={scorecardItemId}
        organizationId={organizationId}
        scorecardItemAutomationData={scorecardItemAutomationData}
        isOpen={isModalOpen}
        onDismiss={() => setModalOpen(false)}
        onConfirm={() => setModalOpen(false)}
      />
    </Popup>
  );
};

export default ScorecardItemInsightButton;
