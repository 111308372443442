import { SVGProps, forwardRef, memo } from 'react';

const VisaIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="24"
        viewBox="0 0 36 24"
        fill="none"
        {...props}
      >
        <rect
          x="0.5"
          y="0.5"
          width="35"
          height="23"
          rx="2.5"
          fill="white"
        />
        <rect
          x="0.5"
          y="0.5"
          width="35"
          height="23"
          rx="2.5"
          stroke="#DDDDDD"
        />
        <path
          d="M21.7367 8C19.9836 8 18.4169 8.90877 18.4169 10.5874C18.4169 12.5127 21.1954 12.6457 21.1954 13.613C21.1954 14.0203 20.7288 14.3849 19.9315 14.3849C18.8003 14.3849 17.9548 13.8755 17.9548 13.8755L17.5929 15.5697C17.5929 15.5697 18.567 16 19.8601 16C21.7767 16 23.2851 15.0467 23.2851 13.3392C23.2851 11.3046 20.495 11.1756 20.495 10.278C20.495 9.95891 20.8781 9.60944 21.6729 9.60944C22.5698 9.60944 23.3014 9.97992 23.3014 9.97992L23.6555 8.34379C23.6555 8.34379 22.8592 8 21.7364 8H21.7367ZM5.69737 8.12358L5.65486 8.37048C5.65486 8.37048 6.39236 8.50542 7.0567 8.77482C7.91208 9.08351 7.97288 9.26343 8.11697 9.8215L9.68662 15.8725H11.7909L15.0325 8.12358H12.9332L10.8502 13.3921L10.0003 8.92607C9.9224 8.41496 9.52745 8.12333 9.04428 8.12333H5.69762L5.69737 8.12358ZM15.8767 8.12358L14.23 15.8725H16.2319L17.8727 8.12358H15.8767ZM27.042 8.12358C26.5593 8.12358 26.3035 8.38209 26.1159 8.83364L23.183 15.8725H25.2823L25.6883 14.6992H28.2459L28.4928 15.8725H30.3451L28.7293 8.12358H27.042ZM27.3151 10.2172L27.9374 13.1249H26.2704L27.3153 10.2172H27.3151Z"
          fill="#1434CB"
        />
      </svg>
    );
  }),
);

export default VisaIcon;
