import * as React from 'react';
import { css } from '@emotion/react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { DisposalOrderPickupFormData } from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-shipment-section-schedule/packages/disposal-order-pickup-form/DisposalOrderPickupForm';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Button from '~/neo-ui/packages/button/Button';
import FormDatepickerInput from '~/neo-ui/packages/form/packages/form-input/packages/form-datepicker-input/FormDatepickerInput';
import FormTextInput from '~/neo-ui/packages/form/packages/form-input/packages/form-text-input/FormTextInput';
import Label from '~/neo-ui/packages/text/packages/label/Label';

export type DisposalOrderPickupScheduleSectionProps = {
  onCancelEdit: () => void;
};

const DisposalOrderPickupScheduleSection = ({ onCancelEdit }: DisposalOrderPickupScheduleSectionProps) => {
  const { submitForm, isSubmitting } = useFormContext<DisposalOrderPickupFormData>();

  const nameFieldKey = (formData: FieldKeyExpressionSegment<DisposalOrderPickupFormData>) => formData.contactName;
  const emailFieldKey = (formData: FieldKeyExpressionSegment<DisposalOrderPickupFormData>) => formData.contactEmail;
  const phoneFieldKey = (formData: FieldKeyExpressionSegment<DisposalOrderPickupFormData>) => formData.contactPhoneNumber;
  const pickupNotesFieldKey = (formData: FieldKeyExpressionSegment<DisposalOrderPickupFormData>) => formData.pickupNotes;
  const requestedDateFieldKey = (formData: FieldKeyExpressionSegment<DisposalOrderPickupFormData>) => formData.requestedDate;

  const formFieldCss = css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: auto auto;
          grid-column-gap: 0.75rem;
          grid-row-gap: 1.5rem;
        `}
      >
        <div
          css={[
            formFieldCss,
            css`
              grid-column: span 2;
            `,
          ]}
        >
          <Label bold={true}>Contact Name</Label>
          <FormTextInput
            fieldKey={nameFieldKey}
            placeholder={'Name'}
          />
        </div>
        <div css={formFieldCss}>
          <Label bold={true}>Contact email address</Label>
          <FormTextInput
            fieldKey={emailFieldKey}
            placeholder={'Email address'}
          />
        </div>
        <div css={formFieldCss}>
          <Label bold={true}>Contact phone number</Label>
          <FormTextInput
            fieldKey={phoneFieldKey}
            placeholder={'Phone'}
          />
        </div>
      </div>

      <div css={formFieldCss}>
        <Label bold={true}>What date works for you?</Label>
        <FormDatepickerInput fieldKey={requestedDateFieldKey} />
      </div>

      <div css={formFieldCss}>
        <Label bold={true}>What else should we know about this pickup?</Label>
        <FormTextInput
          fieldKey={pickupNotesFieldKey}
          placeholder={`For example, "Please knock on the side door"`}
        />
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 0.625rem;
          flex-wrap: wrap;
        `}
      >
        <Button
          iconLeft={'Done'}
          theme={'primary'}
          onClick={submitForm}
          loading={isSubmitting}
        >
          Request pickup
        </Button>
        <Button
          iconLeft={'Cancel'}
          onClick={onCancelEdit}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DisposalOrderPickupScheduleSection;
