import { useContext } from 'react';
import ContractListContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/context/ContractListContext';

const useContractListContext = () => {
  const contractListContext = useContext(ContractListContext);
  if (!contractListContext) {
    throw new Error('Tried to use ContractListContext outside of an ContractListProvider');
  }

  return contractListContext;
};

export default useContractListContext;
