import MultiSelect from '~/neo-ui/packages/select/packages/multi-select/MultiSelect';
import { camelToLabel } from '~/wm/packages/documentation/packages/supported-integrations/packages/IntegrationDocumentationUtil';
import { css } from '@emotion/react';

type DataTypeFiltersProps = {
  typeFields: { [key: string]: string[] };
  filters: { [key: string]: { isSupportedSelected: boolean; selectedFields: string[] } };
  onFiltersChanged: (filters: { [key: string]: { isSupportedSelected: boolean; selectedFields: string[] } }) => void;
};

const DataTypeFilters = ({ typeFields, filters, onFiltersChanged }: DataTypeFiltersProps) => {
  const options = Object.keys(typeFields).map(type => ({ value: type, label: camelToLabel(type) }));
  return (
    <>
      <MultiSelect
        css={css`
          min-width: 9.375rem;
        `}
        label={'Data types'}
        options={Object.keys(typeFields).map(type => ({ value: type, label: camelToLabel(type) }))}
        selectedOptions={options.filter(option => filters[option.value].isSupportedSelected)}
        onChange={options => {
          const newFilters = { ...filters };
          Object.keys(filters).forEach(type => {
            newFilters[type].isSupportedSelected = options.some(option => option.value === type);
            if (!newFilters[type].isSupportedSelected) {
              newFilters[type].selectedFields = [];
            }
          });
          onFiltersChanged(newFilters);
        }}
        hasSelectAnyOption={false}
      />
      {Object.keys(typeFields)
        .filter(type => typeFields[type].length > 0 && filters[type].isSupportedSelected)
        .map(type => (
          <MultiSelect
            css={css`
              min-width: 12.5rem;
            `}
            key={type}
            label={`${camelToLabel(type)} fields`}
            options={typeFields[type].map(field => ({ value: field, label: field }))}
            selectedOptions={filters[type].selectedFields.map(field => ({ value: field, label: field }))}
            onChange={options => {
              const newFilters = { ...filters };
              newFilters[type].selectedFields = options.map(option => option.value);
              onFiltersChanged(newFilters);
            }}
            hasSelectAnyOption={false}
          />
        ))}
    </>
  );
};

export default DataTypeFilters;
