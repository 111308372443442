import { ScorecardCategoryDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardCategory/Dto/Model.gen';
import { BudgetCurrencyDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Dto/Model.gen';
import { ScaleOption } from '~/neo-ui/packages/select/packages/scale-select/ScaleSelect';
import { PriorityDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Priority/Dto/Model.gen';
import ScorecardItemPresentation from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-presentation/packages/scorecard-item-presentation/ScorecardItemPresentation';
import * as React from 'react';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ScorecardCategoryPresentationProps = {
  scorecardCategory: ScorecardCategoryDto;
  currency: BudgetCurrencyDto;
  scoreDisplayAvailabilities: ScaleOption[];
  priorityDisplayAvailabilities: PriorityDto[];
};

const ScorecardCategoryPresentation = ({
  scorecardCategory,
  currency,
  scoreDisplayAvailabilities,
  priorityDisplayAvailabilities,
}: ScorecardCategoryPresentationProps) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
    `}
  >
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 0.625rem;
      `}
    >
      <Header
        size={3}
        weight={'medium'}
        css={css`
          flex-shrink: 0;
          max-width: 80vw;
        `}
      >
        {scorecardCategory.label}
      </Header>
      <hr
        css={css`
          margin: 0;
          width: 100%;
          border-top: 0.0625rem solid ${colorToCode('dark-900-12')};
        `}
      />
    </div>
    {scorecardCategory.scorecardItems.map(scorecardItem => (
      <ScorecardItemPresentation
        key={`scorecard-item-${scorecardItem.scorecardItemId}`}
        scorecardItem={scorecardItem}
        currency={currency}
        scoreDisplayAvailabilities={scoreDisplayAvailabilities}
        priorityDisplayAvailabilities={priorityDisplayAvailabilities}
      />
    ))}
  </div>
);

export default ScorecardCategoryPresentation;
