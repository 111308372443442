import * as React from 'react';
import DisposalFeatureFlagContext from '~/wm/packages/disposal/context/DisposalFeatureFlagContext';

export type DisposalFeatureFlagProviderProps = {
  enableImproveProfit: boolean;
};

const DisposalFeatureFlagProvider: React.FunctionComponent<React.PropsWithChildren<DisposalFeatureFlagProviderProps>> = ({
  enableImproveProfit,
  children,
}) => <DisposalFeatureFlagContext.Provider value={{ enableImproveProfit }}>{children}</DisposalFeatureFlagContext.Provider>;

export default DisposalFeatureFlagProvider;
