import * as React from 'react';
import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';

export type EngagementListPageInitiativeLinkContextData = {
  selectedAction?: EngagementActionDto;
  setSelectedAction: React.Dispatch<React.SetStateAction<EngagementActionDto | undefined>>;
  isModalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const EngagementListPageInitiativeLinkContext = React.createContext<EngagementListPageInitiativeLinkContextData | undefined>(undefined);

export default EngagementListPageInitiativeLinkContext;
