import { useContext } from 'react';
import EngagementListPageTicketCreateContext from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/context/EngagementListPageTicketCreateContext';

const useEngagementListPageTicketCreateContext = () => {
  const engagementListPageTicketCreateContext = useContext(EngagementListPageTicketCreateContext);
  if (!engagementListPageTicketCreateContext) {
    throw new Error('Tried to use EngagementListPageTicketCreateContext outside of an EngagementListPageTicketCreateProvider');
  }

  return engagementListPageTicketCreateContext;
};

export default useEngagementListPageTicketCreateContext;
