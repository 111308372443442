import React, { useCallback } from 'react';
import { Enum as WarrantyTypeEnum } from '@WarrantyClient/BeastClient/Goods/WarrantyType/WarrantyTypeFactoryNested.gen';
import { Styleable } from '~/neo-ui/model/capacity';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';

export type WarrantyListDescriptionProps = {
  warrantyType: WarrantyTypeEnum | undefined;
} & Styleable;

const WarrantyListDescription: React.FunctionComponent<WarrantyListDescriptionProps> = ({ warrantyType }) => {
  const getContent = useCallback((): string | null => {
    switch (warrantyType) {
      case WarrantyTypeEnum.InfrastructureProtection:
        return (
          'Infrastructure Protection provides coverage for your clients’ critical server and networking devices.\n' +
          'No-nonsense quoting and procurement in just clicks, with two straightforward SLA options.'
        );
      case WarrantyTypeEnum.WorkstationAssurance:
        return (
          'Workstation Assurance protects your clients’ workstation fleet, and includes accidental damage coverage on a full replacement advanced exchange service model.\n' +
          'Quote and procure in just clicks.'
        );
      default:
        return null;
    }
  }, [warrantyType]);

  return (
    <Header
      size={5}
      color={'dark-050'}
      css={css`
        font-weight: normal;
      `}
    >
      {getContent()}
    </Header>
  );
};

export default WarrantyListDescription;
