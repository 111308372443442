const engagementDataCollectionSources = {
  engagementActionCreationSource: {
    clientDashboard: 'client-dashboard',
    actionList: 'action-list',
    initiative: 'initiative',
  } as const,
  engagementActionCompletionSource: {
    clientDashboard: 'client-dashboard',
    actionList: 'action-list',
    initiative: 'initiative',
  } as const,
  engagementActionUpdateSource: {
    clientDashboard: 'client-dashboard',
    actionList: 'action-list',
    initiative: 'initiative',
  } as const,
  engagementNoteCreationSource: {
    clientDashboard: 'client-dashboard',
    noteList: 'note-list',
    initiative: 'initiative',
  } as const,
  engagementNoteArchivingSource: {
    clientDashboard: 'client-dashboard',
    noteList: 'note-list',
    initiative: 'initiative',
  } as const,
};

type EngagementActionCreateSourceKey = keyof typeof engagementDataCollectionSources.engagementActionCreationSource;

export type EngagementActionCreateSource =
  (typeof engagementDataCollectionSources.engagementActionCreationSource)[EngagementActionCreateSourceKey];

type EngagementActionCompleteSourceKey = keyof typeof engagementDataCollectionSources.engagementActionCompletionSource;

export type EngagementActionCompleteSource =
  (typeof engagementDataCollectionSources.engagementActionCompletionSource)[EngagementActionCompleteSourceKey];

type EngagementActionUpdateSourceKey = keyof typeof engagementDataCollectionSources.engagementActionUpdateSource;

export type EngagementActionUpdateSource =
  (typeof engagementDataCollectionSources.engagementActionUpdateSource)[EngagementActionUpdateSourceKey];

type EngagementNoteCreateSourceKey = keyof typeof engagementDataCollectionSources.engagementNoteCreationSource;

export type EngagementNoteCreateSource =
  (typeof engagementDataCollectionSources.engagementNoteCreationSource)[EngagementNoteCreateSourceKey];

type EngagementNoteArchiveSourceKey = keyof typeof engagementDataCollectionSources.engagementNoteArchivingSource;

export type EngagementNoteArchiveSource =
  (typeof engagementDataCollectionSources.engagementNoteArchivingSource)[EngagementNoteArchiveSourceKey];

export default engagementDataCollectionSources;
