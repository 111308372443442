import Header from '~/neo-ui/packages/text/packages/header/Header';
import * as React from 'react';
import { css } from '@emotion/react';

export type IntegrationSetupPageSectionProps = {
  title: string;
  children: React.ReactNode;
};

export const IntegrationSetupPageSection: React.FunctionComponent<IntegrationSetupPageSectionProps> = ({ title, children }) => (
  <section
    css={css`
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    `}
  >
    <Header
      size={2}
      weight={'bold'}
    >
      {title}
    </Header>
    {children}
  </section>
);
export default IntegrationSetupPageSection;
