import { css } from '@emotion/react';
import { FunctionComponent, ReactElement } from 'react';
import List, { Orientation } from '~/legacy-ui/packages/list/List';
import { Styleable } from '~/neo-ui/model/capacity';
import LayoutSectionWithLogo from '~/neo-ui/packages/text/packages/layout-section/LayoutSectionWithLogo';
import Box from '~/neo-ui/packages/box/Box';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type StrategyInsightContainerProps = {
  title: string;
  logoSource?: string;
  orientation: Orientation;
  elements: ReactElement[];
} & Styleable;

const StrategyInsightContainer: FunctionComponent<StrategyInsightContainerProps> = ({ title, logoSource, orientation, elements }) => (
  <div
    css={css`
      flex-grow: 1;
      ${orientation === Orientation.Horizontal
        ? css`
            width: 100%;
          `
        : css`
            width: 23.125rem;
          `};
    `}
  >
    <Box
      borderRadius={'radius400'}
      padding={'padding200'}
      css={css`
        padding: 1.5rem 1.25rem;
        background-color: ${colorToCode('secondary-050')};
        ${orientation === Orientation.Horizontal ? '' : 'height: 100%;'}
      `}
    >
      <LayoutSectionWithLogo
        title={title}
        titleSize={3}
        titleWeight={'medium'}
        logoSource={logoSource}
      >
        <List
          orientation={orientation}
          items={elements}
          renderItem={(item: ReactElement, index) => <div key={index}>{item}</div>}
          gap={'0.625rem'}
        />
      </LayoutSectionWithLogo>
    </Box>
  </div>
);

export default StrategyInsightContainer;
