import * as React from 'react';
import OrganizationContext from '~/wm/packages/organization/context/OrganizationContext';

export type OrganizationProviderProps = {
  organizationId: string;
  organizationName: string;
};

const OrganizationProvider: React.FunctionComponent<React.PropsWithChildren<OrganizationProviderProps>> = ({
  organizationId,
  organizationName,
  children,
}) => (
  <OrganizationContext.Provider
    value={{
      organizationId,
      organizationName,
    }}
  >
    {children}
  </OrganizationContext.Provider>
);

export default OrganizationProvider;
