// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


/**
 * All implementation possible are defined here
 * Note: this status enum is also referenced by coverage inside assetCoverage
 * as well as the CoverageStatus.Enum of Warranty service.
 * 
 * See {3C278B36-BD99-4E12-BFF8-A88406EB31C1}
 * 
 * Ensure all tables and the referenced enum are up to date if adding new entries.
 */
export enum Enum
{
  In = "In",
  Out = "Out",
  Soon = "Soon",
  Unknown = "Unknown",
}


