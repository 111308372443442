import { css } from '@emotion/react';
import * as React from 'react';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Image from '~/neo-ui/packages/image/Image';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import useBrandingMspGet from '~/wm/packages/strategy/packages/presentation/hooks/useBrandingMspGet';
import ScorecardPresentation, {
  ScorecardPresentationProps,
} from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-presentation/ScorecardPresentation';
import RoadmapPresentation, { RoadmapPresentationProps } from '~/wm/packages/strategy/packages/roadmap-presentation/RoadmapPresentation';
import PresentationPage from '~/neo-ui/packages/layout/packages/layout-presentation/packages/presentation-page/PresentationPage';
import LayoutPresentationContext from '~/neo-ui/packages/layout/packages/layout-presentation/packages/context/LayoutPresentationContext';

const tabs = ['scorecard', 'roadmap'] as const;
/**
 * Tabs that are available to choose in the navbar
 */
export type StrategyPresentationTab = (typeof tabs)[number];

type TabDetails = {
  key: StrategyPresentationTab;
  route: string;
  displayName: string;
  icon: IconType;
  page: React.ReactNode;
};

/**
 * Details of each tab available
 *
 * Provides props render page, nav link, and routing
 *
 * @deprecated This is a legacy in-house solution to client-side routing. Use enceladusRouter as inspiration instead
 */
const getTabDetails = (
  roadmapProps: RoadmapPresentationProps,
  scorecardProps: ScorecardPresentationProps,
): Record<StrategyPresentationTab, TabDetails> => ({
  roadmap: {
    key: 'roadmap',
    route: '/roadmap/present',
    displayName: 'Roadmap',
    icon: 'Roadmap',
    page: <RoadmapPresentation {...roadmapProps} />,
  },
  scorecard: {
    key: 'scorecard',
    route: '/scorecard/present',
    displayName: 'Scorecard',
    icon: 'Scorecard',
    page: <ScorecardPresentation {...scorecardProps} />,
  },
});

export type StrategyPresentationPageProps = {
  organizationId: string;
  organizationName: string;
  insightUrl: string;
  upgradeUrl: string;
  growUrl: string;
  organizationRegionalSettingsUrl: string;
  /**
   * Determines default page loaded
   */
  defaultTab: StrategyPresentationTab;
  /**
   * Root of the path name, routing will replace everything after the root
   */
  pathNameRoot: string;
  enabledLmRoadmapsPdfOverview: boolean;
  enabledInitiativeTickets: boolean;
};

/**
 * Creates the page for strategy presentation and provides routing to scorecard and roadmap
 *
 * Default tab selects the first loaded page
 *
 * @deprecated This is a legacy in-house solution to client-side routing. Use enceladusRouter as inspiration instead
 */
const StrategyPresentationPage = ({
  organizationId,
  organizationName,
  insightUrl,
  defaultTab,
  pathNameRoot,
  upgradeUrl,
  growUrl,
  organizationRegionalSettingsUrl,
  enabledLmRoadmapsPdfOverview,
  enabledInitiativeTickets,
}: StrategyPresentationPageProps) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);
  const detailsByTab = getTabDetails(
    {
      organizationId,
      organizationName,
      insightUrl,
      upgradeUrl,
      growUrl,
      organizationRegionalSettingsUrl,
      enabledLmRoadmapsPdfOverview,
      enabledInitiativeTickets,
    },
    { organizationId, organizationName },
  );

  const { brandingMsp } = useBrandingMspGet();

  return (
    <LayoutPresentationContext.Provider value={{ isPresentationMode: true }}>
      <PresentationPage
        title={
          <React.Fragment>
            <Header size={1}>{organizationName}</Header>
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 1rem;
              `}
            >
              {brandingMsp && brandingMsp.base64Image && (
                <Image
                  source={`data:image/png;base64,${brandingMsp.base64Image}`}
                  css={css`
                    height: 5.625rem;
                    width: auto;
                  `}
                />
              )}
              {brandingMsp && brandingMsp.website && (
                <ButtonLink
                  size={'sm'}
                  leftAlign={true}
                  rightAlign={true}
                  iconLeft={'Globe'}
                  anchor={{
                    href: brandingMsp.website.url,
                    openInNewTab: true,
                  }}
                />
              )}
            </div>
          </React.Fragment>
        }
        bodyPanelTabProps={{
          initialTabId: selectedTab,
          initialFilterValueByTab: new Map([
            ['roadmap', undefined],
            ['scorecard', undefined],
          ]),
          initialPageByTab: new Map([
            ['roadmap', 1],
            ['scorecard', 1],
          ]),
          panelTabs: [
            {
              id: detailsByTab.scorecard.key,
              title: detailsByTab.scorecard.displayName,
              titleIcon: detailsByTab.scorecard.icon,
              children: detailsByTab.scorecard.page,
              onSelect: () => {
                if (detailsByTab.scorecard.key !== selectedTab) {
                  window.history.replaceState(undefined, '', `${pathNameRoot}${detailsByTab.scorecard.route}`);
                  setSelectedTab(detailsByTab.scorecard.key);
                }
              },
            },
            {
              id: detailsByTab.roadmap.key,
              title: detailsByTab.roadmap.displayName,
              titleIcon: detailsByTab.roadmap.icon,
              children: detailsByTab.roadmap.page,
              onSelect: () => {
                if (detailsByTab.roadmap.key !== selectedTab) {
                  window.history.replaceState(undefined, '', `${pathNameRoot}${detailsByTab.roadmap.route}`);
                  setSelectedTab(detailsByTab.roadmap.key);
                }
              },
            },
          ],
        }}
      />
    </LayoutPresentationContext.Provider>
  );
};

export default StrategyPresentationPage;
