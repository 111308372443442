import Color from '~/neo-ui/packages/color/Color.gen';

type ThemeMapUser = {
  backgroundColorRest: Color;
  textColorRest: Color;
};

const calculateUserTheme = (userId: string): ThemeMapUser => {
  // Convert obfuscated userId to a number we can work with
  let numericUserId = 0;
  for (let i = 0; i < userId.length; i++) {
    numericUserId += userId.charCodeAt(i);
  }

  // Define a collection of themes to choose from
  const userThemes: ThemeMapUser[] = [
    {
      backgroundColorRest: 'positive-100',
      textColorRest: 'dark-700',
    },
    {
      backgroundColorRest: 'info-100',
      textColorRest: 'dark-700',
    },
    {
      backgroundColorRest: 'secondary-100',
      textColorRest: 'dark-700',
    },
    {
      backgroundColorRest: 'negative-100',
      textColorRest: 'dark-700',
    },
    {
      backgroundColorRest: 'caution-100',
      textColorRest: 'dark-700',
    },
    {
      backgroundColorRest: 'warning-100',
      textColorRest: 'dark-700',
    },
    {
      backgroundColorRest: 'excellent-100',
      textColorRest: 'dark-700',
    },
    {
      backgroundColorRest: 'primary-100',
      textColorRest: 'dark-700',
    },
  ];

  // Return a theme based on the userId
  const userThemeIndex = numericUserId % userThemes.length;
  return userThemes[userThemeIndex];
};

export default calculateUserTheme;
