import * as React from 'react';
import { Styleable } from '../../model/capacity';

export type ImageProps = {
  source: string;
  onLoad?: React.ReactEventHandler<HTMLImageElement>;
} & Styleable;

const Image: React.FunctionComponent<ImageProps> = ({ source, onLoad, className }) => (
  <img
    className={className ? className : ''}
    src={source}
    onLoad={onLoad}
  />
);

export default Image;
