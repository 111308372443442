import Box from '~/neo-ui/packages/box/Box';
import PanelTabGroup from '~/neo-ui/packages/panel/packages/PanelTabGroup/PanelTabGroup';
import { css } from '@emotion/react';
import EngagementActionDashboardPanelTab from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-action-dashboard-panel-tab/EngagementActionDashboardPanelTab';
import EngagementActionListProvider from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/EngagementActionListProvider';
import { stringToEngagementActionStatusFilter } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/types/EngagementActionStatusFilter';
import EngagementNoteDashboardPanelTab from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/EngagementNoteDashboardPanelTab';
import EngagementNotePanelTabProvider from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/context/EngagementNotePanelTabProvider';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import TicketFieldAvailabilitiesProvider from '~/wm/packages/integration/packages/ticket/context/TicketFieldAvailabilitiesProvider';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import engagementDataCollectionSources from '~/wm/packages/organization/packages/engagement/model/engagementDataCollectionSources';
import EngagementActionDataCollectionProvider from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/EngagementActionDataCollectionProvider';
import EngagementListPageTicketCreateProvider from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/context/EngagementListPageTicketCreateProvider';
import EngagementListPageInitiativeLinkProvider from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/context/EngagementListPageInitiativeLinkProvider';

export type EngagementType = 'Action' | 'Note';

const EngagementDashboard = () => {
  const { organizationId } = useOrganizationContext();

  return (
    <Box
      borderRadius={'radius400'}
      padding={'padding300'}
    >
      <Header
        css={css`
          margin-bottom: 1rem;
        `}
        size={3}
        weight={'medium'}
      >
        Activities
      </Header>
      <PanelTabGroup<EngagementType>
        initialTabId={'Action'}
        initialFilterValueByTab={
          new Map([
            ['Action', stringToEngagementActionStatusFilter('Upcoming')],
            ['Note', undefined],
          ])
        }
        initialPageByTab={
          new Map([
            ['Action', 1],
            ['Note', 1],
          ])
        }
        panelTabs={[
          {
            id: 'Action',
            title: 'Action items',
            titleIcon: 'ActionItem',
            children: (
              <EngagementActionDataCollectionProvider
                createdFrom={engagementDataCollectionSources.engagementActionCreationSource.clientDashboard}
                completedFrom={engagementDataCollectionSources.engagementActionCompletionSource.clientDashboard}
                updatedFrom={engagementDataCollectionSources.engagementActionUpdateSource.clientDashboard}
              >
                <TicketFieldAvailabilitiesProvider organizationId={organizationId}>
                  <EngagementListPageTicketCreateProvider>
                    <EngagementListPageInitiativeLinkProvider>
                      <EngagementActionListProvider engagementActionGetSource={{ type: 'Dashboard' }}>
                        <EngagementActionDashboardPanelTab initiativeCreatedFrom={'engagement-client-dashboard'} />
                      </EngagementActionListProvider>
                    </EngagementListPageInitiativeLinkProvider>
                  </EngagementListPageTicketCreateProvider>
                </TicketFieldAvailabilitiesProvider>
              </EngagementActionDataCollectionProvider>
            ),
          },
          {
            id: 'Note',
            title: 'Notes',
            titleIcon: 'Note',
            children: (
              <TicketFieldAvailabilitiesProvider organizationId={organizationId}>
                <EngagementNotePanelTabProvider>
                  <EngagementNoteDashboardPanelTab />
                </EngagementNotePanelTabProvider>
              </TicketFieldAvailabilitiesProvider>
            ),
          },
        ]}
      />
    </Box>
  );
};

export default EngagementDashboard;
