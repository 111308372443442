import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import DisposalSelfCheckoutFormData from '~/wm/packages/disposal/types/DisposalSelfCheckoutFormData';
import * as React from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import { Styleable } from '~/neo-ui/model/capacity';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import { assertNeverOrThrow } from '~/extensions/packages/types/assertNever';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import DisposalAssetTypeCountFormData from '~/wm/packages/disposal/types/DisposalAssetTypeCountFormData';
import FormNumberStepperInput from '~/neo-ui/packages/form/packages/form-input/packages/form-number-stepper-input/FormNumberStepperInput';
import { useEffect, useRef } from 'react';

export type DisposalAssetTypeCountInputProps = {
  disposalAssetTypeCount: DisposalAssetTypeCountFormData;
  assetTypeCountsIndex: number;
  disposalAssetTypeCountAvailabilities: DisposalAssetTypeAvailabilityDto[];
} & Styleable;

const DisposalAssetTypeCountInput = ({
  disposalAssetTypeCount,
  assetTypeCountsIndex,
  disposalAssetTypeCountAvailabilities,
  className,
}: DisposalAssetTypeCountInputProps) => {
  const fieldKey = (values: FieldKeyExpressionSegment<DisposalSelfCheckoutFormData>) => values.assetTypeCounts[assetTypeCountsIndex].count;

  const assetCountRef = useRef(disposalAssetTypeCount.count);
  const isAssetCountInvalid = isNaN(disposalAssetTypeCount.count) || disposalAssetTypeCount.count < 1;

  useEffect(() => {
    if (assetCountRef.current === disposalAssetTypeCount.count) {
      return;
    }

    assetCountRef.current = disposalAssetTypeCount.count;
  }, [disposalAssetTypeCount.count, isAssetCountInvalid]);

  const assetTypeCountAvailability = disposalAssetTypeCountAvailabilities.find(
    availability => availability.disposalAssetType === disposalAssetTypeCount.type,
  );

  // Throw in case we cannot find this availability
  if (typeof assetTypeCountAvailability === 'undefined') {
    assertNeverOrThrow(assetTypeCountAvailability as never);
  }

  return (
    <div
      className={className}
      css={css`
        display: flex;
        align-items: center;
        gap: 1rem;
      `}
    >
      <Icon
        icon={assetTypeCountAvailability?.icon as IconType}
        sizePx={24}
      />
      <Label
        bold={true}
        css={css`
          flex-grow: 1;
        `}
      >
        {assetTypeCountAvailability?.label}
      </Label>
      <FormNumberStepperInput
        fieldKey={fieldKey}
        min={1}
        isInvalid={isAssetCountInvalid}
      />
    </div>
  );
};

export default DisposalAssetTypeCountInput;
