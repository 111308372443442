import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import formControlStyles from '~/neo-ui/packages/form/packages/form-input/packages/form-control/formControlStyles';
import { useTextareaInput } from '~/neo-ui/packages/input/packages/input-textarea/hooks/useTextareaInput';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type InputTextareaProps = {
  /**
   * Temporary, lighter coloured text that will disappear on key down
   */
  placeholder?: string;
  /**
   *  Event fired when user changes the value
   */
  onTextChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  /**
   * Event fired when on key down
   */
  onKeyDown?: (e: React.KeyboardEvent) => void;
  tabIndex?: number;
  disabled?: boolean;
} & Styleable;

export const textareaMinHeightRem = 1.875;

/**
 * Simple input text area that provides the value being changed
 *
 * Specifying an onClear will provide a 'clear' button at the right end of the input
 */
const InputTextarea: React.FunctionComponent<InputTextareaProps> = ({
  className,
  placeholder,
  onTextChange,
  value,
  onKeyDown,
  tabIndex,
  disabled = false,
}) => {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  useTextareaInput(textareaRef, value);

  return (
    <textarea
      ref={textareaRef}
      className={`${className} form-control`}
      placeholder={placeholder}
      tabIndex={tabIndex}
      onChange={evt => {
        onTextChange(evt);
      }}
      value={value}
      disabled={disabled}
      css={[
        formControlStyles.app,
        css`
          min-height: ${textareaMinHeightRem}rem;
          background-color: ${colorToCode('light-000')};
          overflow: hidden;
          resize: none;
        `,
      ]}
      onKeyDown={onKeyDown}
    />
  );
};

export default InputTextarea;
