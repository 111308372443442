import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import OutsideClickHandler from '~/neo-ui/packages/interactions/packages/outside-click-handler/OutsideClickHandler';
import { css } from '@emotion/react';
import * as React from 'react';
import { Placement } from '@popperjs/core';
import { Styleable } from '~/neo-ui/model/capacity';
import Color from '~/neo-ui/packages/color/Color.gen';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import PopupFooter from '~/neo-ui/packages/popup/packages/popup-footer/PopupFooter';

export type PopupProps = {
  isOpen: boolean;
  onClose: () => void;
  backgroundColor?: Color;

  /**
   * Default popup content placement
   */
  placement?: Placement;

  /**
   * Customize content of the popup
   */
  content: React.ReactNode;

  /**
   * Optional title of the popup, will be displayed in the top left corner
   */
  title?: string;
  titleColor?: Color;

  /**
   * Optional footer of the popup, will be displayed in the bottom with anything inside it
   */
  footer?: React.ReactNode;

  /**
   * Show close ButtonModifier in the top right corner
   */
  showCloseButton?: boolean;

  children: React.ReactNode;
} & Styleable;

const Popup = ({
  isOpen,
  onClose,
  placement = 'auto',
  backgroundColor = 'dark-300',
  title,
  titleColor,
  footer,
  showCloseButton = false,
  className,
  content,
  children,
}: PopupProps) => {
  const contentRef = React.useRef(null);
  const popupContentRef = React.useRef(null);

  return (
    <OutsideClickHandler
      refs={[contentRef, popupContentRef]}
      onClickOutside={() => onClose()}
    >
      <Tooltip
        placement={placement}
        isActive={isOpen}
        backgroundColor={backgroundColor}
        content={
          <div
            ref={popupContentRef}
            className={className}
            css={css`
              margin-top: 0.25rem;
            `}
          >
            {/* A header exists if the title or close button is provided */}
            {(title || showCloseButton) && (
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 0.625rem;
                `}
              >
                {title && (
                  <Header
                    size={4}
                    weight={'bold'}
                    color={titleColor}
                  >
                    {title}
                  </Header>
                )}
                {showCloseButton && (
                  <ButtonModifier
                    icon={'Bad'}
                    onClick={onClose}
                    css={css`
                      margin: 0;
                    `}
                  />
                )}
              </div>
            )}

            {/* Always show the content */}
            {content}

            {/* Show the footer only if it provided */}
            {footer && <PopupFooter>{footer}</PopupFooter>}
          </div>
        }
      >
        <div ref={contentRef}>{children}</div>
      </Tooltip>
    </OutsideClickHandler>
  );
};

export default Popup;
