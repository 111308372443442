import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import { ProductInclusion } from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-selection/hooks/useSelfUpgradeViewModelGet';
import { InfoIconTooltip } from '~/sp-ui/icons';
import ToolTip from '~/sp-ui/tool-tip';

export type PlanInclusionProps = {
  inclusions: ProductInclusion[];
};
const PlanInclusion = (props: PlanInclusionProps) => {
  const { inclusions } = props;
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <span
        css={css`
          font-family: Roboto, serif;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding-bottom: 2rem;
          text-align: center;
        `}
      >
        All plans include
      </span>
      <ul
        css={css`
          list-style: none;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem;
          column-count: 2;
          column-width: 31.5rem;
          text-align: left;
          margin-bottom: 0;
          padding-left: 0;
        `}
      >
        {inclusions.map((inclusion, index) => (
          <li
            key={index}
            css={css`
              margin-left: 1.875rem;
              break-inside: avoid-column;
            `}
          >
            <div
              key={inclusion.label}
              css={css`
                display: flex;
                flex-direction: row;
                text-align: left;
                //align-items: center;
                line-height: 1.5rem;
                .tooltip-target {
                  display: inline-block;
                  vertical-align: middle;
                }
              `}
            >
              <Icon
                css={css`
                  margin-top: 0.188rem;
                `}
                icon={'Success'}
                color={'primary-400'}
              />
              <span
                css={css`
                  padding-left: 0.5rem;
                `}
              >
                {inclusion.label}
                {inclusion.tooltip && (
                  <ToolTip content={<p css={{ maxWidth: '15rem', fontSize: '14px' }}>{inclusion.tooltip}</p>}>
                    {({ anchorProps, setAnchorRef }) => {
                      return (
                        <InfoIconTooltip
                          css={{ height: '1rem' }}
                          ref={setAnchorRef}
                          {...anchorProps}
                        />
                      );
                    }}
                  </ToolTip>
                )}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlanInclusion;
