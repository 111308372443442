import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export const customStylesStackAdd = () => ({
  menu: (provided: Record<string, unknown>) => ({
    ...provided,
    minWidth: '16.875rem',
    padding: '0.625rem',
    borderRadius: '0.625rem',
  }),

  control: (provided: Record<string, unknown>) => ({
    ...provided,
    boxShadow: 'unset',
    border: `0.125rem dashed ${colorToCode('dark-900-12')}`,
    borderRadius: '0.375rem',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&:hover': {
      border: `0.125rem dashed ${colorToCode('primary-400')}`,
      backgroundColor: `${colorToCode('primary-050')}`,
      color: `${colorToCode('dark-700')}`,
      cursor: 'pointer',
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&:active': {
      border: `0.125rem dashed ${colorToCode('primary-400')}`,
      backgroundColor: `${colorToCode('primary-100')}`,
      color: `${colorToCode('dark-700')}`,
      cursor: 'pointer',
    },
  }),

  valueContainer: (provided: Record<string, unknown>) => ({
    ...provided,
    padding: '0 0.375rem',
  }),

  input: (provided: Record<string, unknown>) => ({
    ...provided,
    margin: '0',
  }),

  indicatorsContainer: (provided: Record<string, unknown>) => ({
    ...provided,
    height: '100%',
    padding: '0',
    margin: '0',
    alignItems: 'center',
    justifyContent: 'center',
  }),
});
