import { HardwareDetails, HardwareSource } from '@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Details/Model.gen';
import { assetHardwareDetailsGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import HardwareConfigurationInfo from '~/wm/packages/hardware/packages/hardware-configuration-info/HardwareConfigurationInfo';
import HardwareInfoHeader from '~/wm/packages/hardware/packages/hardware-info-header/HardwareInfoHeader';
import HardwareSourcesTable from '~/wm/packages/hardware/packages/hardware-sources-table/HardwareSourcesTable';
import HardwareWarrantyInfo from '~/wm/packages/hardware/packages/hardware-warranty-info/HardwareWarrantyInfo';
import Spinner from '~/neo-ui/spinner/Spinner';

export type HardwareViewProps = {
  hardwareAssetId: string;
  warrantiesCartLink: string;
  getServiceLink: string;
  hardwareEditLink: string;
  permissionToEditAsset: boolean;
};

const HardwareView: React.FunctionComponent<HardwareViewProps> = ({
  hardwareAssetId,
  warrantiesCartLink,
  getServiceLink,
  hardwareEditLink,
  permissionToEditAsset,
}) => {
  const { callApi } = useApi();
  const [hardwareDetails, setHardwareDetails] = React.useState<HardwareDetails | undefined>(undefined);
  const [hardwareSources, setHardwareSources] = React.useState<HardwareSource[] | undefined>(undefined);

  React.useEffect(() => {
    (async () => {
      const response = await callApi(() => assetHardwareDetailsGet({ hardwareAssetId }));
      if (!response) {
        return;
      }
      setHardwareDetails(response.hardwareDetails);
      setHardwareSources(response.sources);

      // UX tweak - shows the PHP issue section: see 12c5394a-ce26-4a37-8a78-6879b367be4b
      const issueSectionElement = document.querySelector('.hardware-view-php-fragment');
      if (issueSectionElement) {
        (issueSectionElement as HTMLElement).style.display = 'block';
      }
    })();
  }, [callApi, hardwareAssetId]);

  if (typeof hardwareDetails === 'undefined' || typeof hardwareSources === 'undefined') {
    return <Spinner />;
  }
  return (
    <React.Fragment>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-bottom: 1.25rem;
        `}
      >
        <HardwareInfoHeader
          resultantHardwareAsset={hardwareDetails}
          hardwareEditLink={hardwareEditLink}
          permissionToEditAsset={permissionToEditAsset}
        />
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;

            /* https://coryrylan.com/blog/css-gap-space-with-flexbox */
            margin-right: -1.25rem;
            margin-bottom: -0.625rem;

            > * {
              flex: 1;
              flex-basis: 500px;
              margin-right: 1.25rem;
              margin-bottom: 0.625rem;
            }
          `}
        >
          <HardwareConfigurationInfo resultantHardwareAsset={hardwareDetails} />
          <HardwareWarrantyInfo
            resultantHardwareAsset={hardwareDetails}
            warrantiesCartLink={warrantiesCartLink}
            getServiceLink={getServiceLink}
          />
        </div>
      </div>
      <HardwareSourcesTable
        resultantHardwareAsset={hardwareDetails}
        sources={hardwareSources}
      />
    </React.Fragment>
  );
};

export default HardwareView;
