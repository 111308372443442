import Icon from '~/neo-ui/packages/icon/Icon';
import routes from '~/router/types/routes';

const ConfirmationPage = () => (
  <div css={{ display: 'grid', placeItems: 'center', placeContent: 'center', height: 'calc(100vh - 40px - 35px)' }}>
    <Icon
      icon={'Positive'}
      sizePx={64}
      color={'dark-050'}
    />

    <div
      css={{
        color: '#2F3037',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: '2.25rem',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '2.8125rem',
        margin: '2rem 0 1rem',
      }}
    >
      Thank you for your purchase
    </div>
    <div
      css={{
        color: '#2F3037',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '1.5rem',
        marginBottom: '4rem',
      }}
    >
      Please check your email for a confirmation of your new subscription
    </div>
    <a
      href={routes.ACCOUNT_HOME.route}
      css={{
        color: '#777',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '1.5rem',
        textDecorationLine: 'underline',
      }}
    >
      Go to my ScalePad Account
    </a>
  </div>
);

export default ConfirmationPage;
