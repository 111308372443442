import React from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import { ScorecardItemAutomationDataDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardItem/Dto/Model.gen';
import useScorecardItemAutomationRefresh from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/hooks/useScorecardItemAutomationRefresh';
import useScorecardUrlContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardUrlContext';

export type ScorecardItemAutomationRefreshModalProps = {
  scorecardId: string;
  scorecardItemId: string;
  organizationId: string;
  scorecardItemAutomationData: ScorecardItemAutomationDataDto;
  isOpen: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
};

const ScorecardItemAutomationRefreshModal = ({
  scorecardId,
  scorecardItemId,
  organizationId,
  scorecardItemAutomationData,
  isOpen,
  onDismiss,
  onConfirm,
}: ScorecardItemAutomationRefreshModalProps) => {
  const { refreshAutomatedScorecardItem, isRefreshingAutomatedScorecardItem } = useScorecardItemAutomationRefresh({
    onSuccess: onConfirm,
  });
  const { insightViewUrl } = useScorecardUrlContext();

  return (
    <ModalConfirmation
      header={{
        title: 'Update this category item?',
        icon: 'Reload',
      }}
      isOpen={isOpen}
      theme={'caution'}
      footer={{
        confirmButton: {
          icon: 'Reload',
          label: 'Update now',
          disabled: false,
          loading: isRefreshingAutomatedScorecardItem,
          onClick: () => {
            refreshAutomatedScorecardItem({ scorecardId, scorecardItemId });
          },
        },
        dismissButton: {
          icon: 'Cancel',
          label: 'Cancel',
          disabled: false,
          onClick: onDismiss,
        },
      }}
    >
      <div>This item’s score and description will be replaced with updated information from your Insight:</div>
      <ButtonLink
        theme={'primary'}
        iconLeft={'Insight'}
        iconRight={'GoExternal'}
        anchor={{
          href: insightViewUrl
            .replace('__ORGANIZATION_ID__', organizationId)
            .replace('__INSIGHT_ID__', scorecardItemAutomationData.insightId),
          openInNewTab: true,
        }}
      >
        {scorecardItemAutomationData.insightDisplayLabel}
      </ButtonLink>
    </ModalConfirmation>
  );
};

export default ScorecardItemAutomationRefreshModal;
