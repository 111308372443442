import { ColumnSizeDto } from '@AssetManagementClient/BeastClient/Search/Model/Console/Dto.gen';

export const getMinSizeInRem = (size: ColumnSizeDto): number => {
  switch (size) {
    case ColumnSizeDto.XSmall:
      return 3;
    case ColumnSizeDto.Small:
      return 6;
    case ColumnSizeDto.Medium:
      return 10;
    case ColumnSizeDto.Large:
      return 14;
  }
};
