import * as React from 'react';
import AreaLineChart, { Dot } from '~/neo-ui/packages/chart/packages/line/packages/area/AreaLineChart';
import Color from '~/neo-ui/packages/color/Color.gen';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';
import { AreaChartDto, AxisType } from '@AssetManagementClient/Dashboard/Sdk/SharedComponent/Model/Main/AreaChartComponentNested.gen';

const AreaChartComponent: React.FunctionComponent<AreaChartDto> = ({
  dots,
  areaColor = 'light-200',
  strokeColor = 'light-900',
  yAxisLabel,
  xAxisType,
}) => {
  let formatter;
  let formattedDots: Dot[] = dots;

  // If the x-axis type is DateTime, need to handle conversion
  if (xAxisType === AxisType.DateTime) {
    formattedDots = dots.map(dot => ({
      x: parseISO(`${dot.x}`).getTime(),
      y: dot.y,
    }));
    formatter = (t: number) => {
      const date = new Date(t);
      return formatDate(date, {
        format: 'MMM dd',
        timezone: TimezoneFormat.Local,
      });
    };
  } else if (xAxisType === AxisType.Numeric) {
    formattedDots = dots.map(dot => ({
      x: Number(dot.x),
      y: dot.y,
    }));
  }

  return (
    <AreaLineChart
      dots={formattedDots}
      areaColor={areaColor as Color}
      strokeColor={strokeColor as Color}
      yAxisLabel={yAxisLabel}
      type={xAxisType === AxisType.Category ? 'category' : 'number'}
      tickFormatter={formatter}
      tooltipFormatter={formatter}
    />
  );
};

export default AreaChartComponent;
