import { FunctionComponent, Fragment, useState, useMemo, useEffect } from 'react';
import ProductTileV2, { ProductTileV2Props } from '~/wm/packages/product/packages/product-tile/ProductTileV2';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Button from '~/neo-ui/packages/button/Button';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import ModalInternal from '~/neo-ui/packages/modal/ModalInternal';
import { css } from '@emotion/react';
import { CloseIcon } from '~/sp-ui/icons';

export interface PublicProductDto {
  Icon: string;
  MarketingText: string;
  ProductAbbreviation: string;
  ProductTileDto: ProductTileV2Props;
}

interface PublicProductInfoProps {
  publicProductDto: PublicProductDto;
  isOpenByDefault?: boolean;
}

const PublicProductInfo: FunctionComponent<PublicProductInfoProps> = ({ publicProductDto, isOpenByDefault = false }) => {
  const [openTile, setOpenTile] = useState<boolean>(false);
  const productTile = useMemo(
    () => (
      <ModalInternal
        key={publicProductDto.ProductAbbreviation}
        isOpen={openTile}
        onDismiss={() => setOpenTile(false)}
        maxWidthRem={40}
        containerCss={css`
          border-radius: 0;
          border: none;
          box-shadow: 0 12px 36px 0 ${colorToCode('dark-900-24')};
        `}
      >
        <CloseIcon
          css={{
            position: 'absolute',
            top: '2rem',
            right: '2rem',
            cursor: 'pointer',
          }}
          onClick={() => setOpenTile(false)}
        />
        <ProductTileV2
          ProductLogoWithProductNameUrl={publicProductDto.ProductTileDto.ProductLogoWithProductNameUrl}
          ProductSubtitle={publicProductDto.ProductTileDto.ProductSubtitle}
          ProductSummary={publicProductDto.ProductTileDto.ProductSummary}
          ProductCta={publicProductDto.ProductTileDto.ProductCta}
          ProductTestimonialUrl={publicProductDto.ProductTileDto.ProductTestimonialUrl}
          ProductTestimonialThumbnail={publicProductDto.ProductTileDto.ProductTestimonialThumbnail}
          SalesPitchSubtitle={publicProductDto.ProductTileDto.SalesPitchSubtitle}
          SalesPitch={publicProductDto.ProductTileDto.SalesPitch}
          ContinueToProductLabel={publicProductDto.ProductTileDto.ContinueToProductLabel}
          ContinueToProductUrl={publicProductDto.ProductTileDto.ContinueToProductUrl}
          BookDemoLabel={publicProductDto.ProductTileDto.BookDemoLabel}
          BookDemoUrl={publicProductDto.ProductTileDto.BookDemoUrl}
        />
      </ModalInternal>
    ),
    [publicProductDto, openTile],
  );
  useEffect(() => {
    setOpenTile(isOpenByDefault);
  }, [isOpenByDefault]);
  return (
    <Fragment>
      {openTile && productTile}
      <div>
        <img
          src={publicProductDto.Icon}
          alt={'Product Logo'}
          css={{ height: '2rem' }}
        />
        <Label
          size="lg"
          css={{
            lineHeight: '1.5rem',
            letterSpacing: 0,
            margin: '2rem 0 1rem',
          }}
        >
          {publicProductDto.MarketingText}
        </Label>
        <Button
          theme={'primary'}
          invertColorMap={true}
          css={{
            border: `1px solid ${colorToCode('primary-400')}`,
            padding: '0 0.5625rem 0 0.5625rem',
          }}
          onClick={() => setOpenTile(true)}
        >
          Learn more
        </Button>
      </div>
    </Fragment>
  );
};

export default PublicProductInfo;
