import { css } from '@emotion/react';
import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import Control from '~/legacy-ui/packages/control/Control';
import useOperation from '~/legacy-ui/packages/operation/hooks/useOperation';
import Button from '~/neo-ui/packages/button/Button';
import InputText from '~/neo-ui/packages/input/packages/input-text/InputText';
import Organization from '~/wm/packages/organization/model/Organization';
import OrganizationLink from '~/wm/packages/organization/packages/linking/model/OrganizationLink';
import organizationRenameOperation from './operation/organizationRenameOperation';

export type OrganizationLinkRenameProps = {
  parentOrganization: Organization;
  linkedOrganizations: OrganizationLink[];
  onCancel: () => void;
};

export const showUseNameButton: boolean = false;

const OrganizationLinkRename: React.FunctionComponent<OrganizationLinkRenameProps> = ({
  parentOrganization,
  linkedOrganizations,
  onCancel,
}) => {
  const [selectedOrganization, setSelectedOrganization] = React.useState<Organization | undefined>(parentOrganization);

  const renameOrganization = useOperation(organizationRenameOperation);
  const [organizationNameInput, setOrganizationNameInput] = React.useState(parentOrganization.name);
  const newOrganizationName = organizationNameInput.trim();

  const organizations = linkedOrganizations;
  const OrganizationLinkUseNameButton: React.FunctionComponent = () => (
    <Button
      theme={'primary'}
      iconLeft={'ArrowChosen'}
      css={css`
        margin-left: 0.625rem;
      `}
      size={'sm'}
      disabled={false}
      onClick={() =>
        renameOrganization({
          targetOrganization: parentOrganization,
          newName: newOrganizationName,
        })
      }
    >
      Use name
    </Button>
  );

  const isValidOrganizationName = newOrganizationName !== '' && newOrganizationName !== parentOrganization.name;

  const onOrganizationNameSelected = React.useCallback(
    (name: string) => {
      setOrganizationNameInput(name);
      // check organizations for a name match, if there is one: setSelectedOrganization
      const selectedOrg = organizations.filter(org => org.name === name)[0];
      if (typeof selectedOrg !== 'undefined') {
        setSelectedOrganization(selectedOrg);
      } else {
        setSelectedOrganization(undefined);
      }
    },
    [organizations],
  );

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && isValidOrganizationName) {
      return renameOrganization({
        targetOrganization: parentOrganization,
        newName: newOrganizationName,
      });
    }
    return;
  };

  React.useEffect(
    () => onOrganizationNameSelected(parentOrganization.name),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parentOrganization],
  );

  return (
    <Card>
      <div
        css={css`
          display: flex;
          margin-bottom: 1.5625rem;
        `}
      >
        <InputText
          placeholder="Choose a client name..."
          onTextChange={(e: React.ChangeEvent<HTMLInputElement>) => onOrganizationNameSelected(e.target.value)}
          value={organizationNameInput}
          onKeyDown={onKeyDown}
        />
        <Button
          css={css`
            margin-left: 0.625rem;
            max-height: 2.25rem;
          `}
          disabled={false}
          onClick={onCancel}
        >
          Cancel
        </Button>
        {!selectedOrganization && isValidOrganizationName && <OrganizationLinkUseNameButton />}
      </div>

      <div
        css={css`
          font-size: 0.75rem;
          margin-bottom: 0.625rem;
        `}
      >
        Type any name, or choose a link's name to use
      </div>
      <Control
        items={organizations}
        itemKey="id"
        labelKey="name"
        selected={selectedOrganization}
        currentSelected={parentOrganization}
        itemButton={OrganizationLinkUseNameButton}
        onSelected={(organization: Organization) => onOrganizationNameSelected(organization.name)}
      />
    </Card>
  );
};

export default OrganizationLinkRename;
