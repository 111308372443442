import * as React from 'react';
import SingleSelect from '~/neo-ui/packages/select/packages/single-select/SingleSelect';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { OrganizationBasicInfoDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Dto/Model.gen';

export type DisposalOrderSummarySectionAssetOrganizationAssignSelectProps = {
  onOrganizationSelected: (organizationId: string) => void;
  organizations: OrganizationBasicInfoDto[];
};

const DisposalOrderSummarySectionAssetOrganizationAssignSelect: React.FunctionComponent<
  DisposalOrderSummarySectionAssetOrganizationAssignSelectProps
> = ({ organizations, onOrganizationSelected }) => {
  const [organizationSelected, setOrganizationSelected] = React.useState<SelectOption>();

  const options: SelectOption[] = organizations.map(organization => ({
    label: organization.displayName,
    value: organization.organizationId,
  }));

  return (
    <SingleSelect
      shouldUseMenuPortal={false}
      truncateSelected={105}
      placeholder={'Assign to client…'}
      options={options}
      selectedOption={organizationSelected}
      onOptionSelected={selectedOption => {
        setOrganizationSelected(selectedOption);
        onOrganizationSelected(selectedOption.value);
      }}
    />
  );
};

export default DisposalOrderSummarySectionAssetOrganizationAssignSelect;
