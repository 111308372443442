import { useState, useCallback, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { contractGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { ContractDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Dto/Model.gen';

export type UseContract = (
  organizationId: string,
  contractId: string,
) => {
  contract: ContractDto | undefined;
  isLoadingContract: boolean;
};

const useContract: UseContract = (organizationId, contractId) => {
  const [contract, setContract] = useState<ContractDto>();
  const [isLoadingContract, setLoadingContract] = useState(true);
  const { callApi } = useApi();

  const reload = useCallback(() => {
    (async () => {
      const response = await callApi(() => contractGet({ organizationId, contractId }));
      setLoadingContract(false);
      if (!response) {
        return;
      }

      setContract(response.contract);
    })();
  }, [callApi, contractId, organizationId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    contract,
    isLoadingContract,
  };
};

export default useContract;
