import { useContext } from 'react';
import EngagementNoteListPanelTabContext from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/context/EngagementNoteListPanelTabContext';

const useEngagementNoteListPanelTabContext = () => {
  const engagementNoteListPanelTabContext = useContext(EngagementNoteListPanelTabContext);
  if (!engagementNoteListPanelTabContext) {
    throw new Error('Tried to use EngagementNotePanelTabContext outside of an EngagementNotePanelTabProvider');
  }

  return engagementNoteListPanelTabContext;
};

export default useEngagementNoteListPanelTabContext;
