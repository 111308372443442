import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type HeaderSize = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type HeaderStyles = {
  headerSize: HeaderSize;
  fontSize: string;
  fontWeight: string;
  letterSpacing?: string;
  textTransform?: string;
};

export type HeaderWeight = 'bold' | 'medium' | 'light';

export const headerSizeToStyles = (headerSize: HeaderSize): HeaderStyles => {
  switch (headerSize) {
    case 1:
      return { headerSize: 1, fontSize: '2.25rem', fontWeight: '600' };
    case 2:
      return { headerSize: 2, fontSize: '1.875rem', fontWeight: '400' };
    case 3:
      return { headerSize: 3, fontSize: '1.5rem', fontWeight: '400' };
    case 4:
      return { headerSize: 4, fontSize: '1.125rem', fontWeight: '400' };
    case 5:
      return { headerSize: 5, fontSize: '0.875rem', fontWeight: '600' };
    case 6:
      return {
        headerSize: 6,
        fontSize: '0.6875rem',
        fontWeight: '600',
        letterSpacing: '0.0875rem',
        textTransform: 'uppercase',
      };
    case 7:
      return {
        headerSize: 7,
        fontSize: '0.5625rem',
        fontWeight: '700',
        letterSpacing: '0.0875rem',
        textTransform: 'uppercase',
      };
  }
};

export const headerWeightToStyles = (headerWeight: HeaderWeight): string => {
  switch (headerWeight) {
    case 'bold':
      return 'bold';
    case 'medium':
      return '500';
    case 'light':
      return '400';
  }
};

export type HeaderProps = {
  size: HeaderSize;
  weight?: HeaderWeight;
  color?: Color;
  id?: string;
  children: React.ReactNode;
} & Styleable;

const Header: React.FunctionComponent<React.PropsWithChildren<HeaderProps>> = ({ size, weight, color, id, className, children }) => {
  const HeaderElement: React.FunctionComponent<React.PropsWithChildren<Styleable>> = React.useCallback(
    props => {
      switch (size) {
        case 1:
          return (
            <h1
              id={id}
              css={css`
                margin: 0;
                font-size: 2.25rem;
                font-weight: ${typeof weight === 'undefined' ? '600' : headerWeightToStyles(weight)};
              `}
              {...props}
            />
          );
        case 2:
          return (
            <h2
              id={id}
              css={css`
                margin: 0;
                font-size: 1.875rem;
                font-weight: ${typeof weight === 'undefined' ? '400' : headerWeightToStyles(weight)};
              `}
              {...props}
            />
          );
        case 3:
          return (
            <h3
              id={id}
              css={css`
                margin: 0;
                font-size: 1.5rem;
                font-weight: ${typeof weight === 'undefined' ? '400' : headerWeightToStyles(weight)};
              `}
              {...props}
            />
          );
        case 4:
          return (
            <h4
              id={id}
              css={css`
                margin: 0;
                font-size: 1.125rem;
                font-weight: ${typeof weight === 'undefined' ? '400' : headerWeightToStyles(weight)};
              `}
              {...props}
            />
          );
        case 5:
          return (
            <h5
              id={id}
              css={css`
                margin: 0;
                font-size: 0.875rem;
                font-weight: ${typeof weight === 'undefined' ? '600' : headerWeightToStyles(weight)};
              `}
              {...props}
            />
          );
        case 6:
          return (
            <h6
              id={id}
              css={css`
                margin: 0;
                letter-spacing: 0.0875rem;
                text-transform: uppercase;
                font-size: 0.6875rem;
                font-weight: ${typeof weight === 'undefined' ? '600' : headerWeightToStyles(weight)};
              `}
              {...props}
            />
          );
        case 7:
          return (
            <h6
              id={id}
              css={css`
                margin: 0;
                letter-spacing: 0.0875rem;
                text-transform: uppercase;
                font-size: 0.5625rem;
                font-weight: ${typeof weight === 'undefined' ? '700' : headerWeightToStyles(weight)};
              `}
              {...props}
            />
          );
      }
    },
    [size, id, weight],
  );

  return (
    <HeaderElement
      className={className}
      css={css`
        ${color ? `color: ${colorToCode(color)};` : ''}
      `}
    >
      {children}
    </HeaderElement>
  );
};

export default Header;
