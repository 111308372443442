import * as React from 'react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';

const OrderPaymentFreeCostModule = () => (
  <div
    css={css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
  >
    <Header
      size={4}
      weight={'bold'}
    >
      Cost
    </Header>
    <div
      css={css`
        text-align: right;
      `}
    >
      <Header
        size={4}
        weight={'bold'}
      >
        Free
      </Header>
      <Label color={'positive-400'}>Disposal is free with your subscription</Label>
    </div>
  </div>
);

export default OrderPaymentFreeCostModule;
