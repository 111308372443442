import { InitiativeOverviewDashboardDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import { createContext } from 'react';
import {
  InitiativeStatusCountDto,
  InitiativeStatusDto,
} from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/InitiativeStatus/Dto/Model.gen';

export type InitiativeDashboardContextData = {
  initiatives: InitiativeOverviewDashboardDto[];
  statusAvailabilities: InitiativeStatusDto[];
  statusCounts: InitiativeStatusCountDto[];
  selectedFilterStatus: InitiativeStatusDto | undefined;
  currentPage: number;
  onFilterStatusUpdate: (filterStatus: string | undefined) => void;
  onPageUpdate: (pageNumber: number) => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const InitiativeDashboardContext = createContext<InitiativeDashboardContextData | undefined>(undefined);

export default InitiativeDashboardContext;
