import * as React from 'react';
import { css } from '@emotion/react';
import {
  SimpleTableColumnDefinitionDto,
  SimpleTableDto,
} from '@AssetManagementClient/Dashboard/Sdk/SharedComponent/Model/Main/SimpleTableComponentNested.gen';
import DataTable, { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import Color from '~/neo-ui/packages/color/Color.gen';
import { SimpleTableCell } from './packages/simple-table-cell/SimpleTableCell';
import RenderCell from '~/neo-ui/packages/table/packages/render-cell-data/RenderCell';

const SimpleTableComponent: React.FunctionComponent<SimpleTableDto> = ({ headers, rows }) => {
  const dataTableColumns = React.useMemo<DataTableColumn<SimpleTableColumnDefinitionDto[]>[]>(
    () =>
      headers.map(headerDefinition => {
        const headerCell = (
          <>
            {typeof headerDefinition.iconDefinition !== 'undefined' &&
              typeof headerDefinition.iconDefinition.icon !== 'undefined' &&
              headerDefinition.iconDefinition.icon && (
                <Icon
                  color={headerDefinition.iconDefinition.color as Color}
                  icon={headerDefinition.iconDefinition.icon as IconType}
                />
              )}
            {headerDefinition.value}
          </>
        );

        return {
          fieldKey: headerDefinition.key,
          Header:
            typeof headerDefinition.cellAlignments !== 'undefined' && typeof headerDefinition.cellAlignments.headerCell !== 'undefined' ? (
              <SimpleTableCell alignment={headerDefinition.cellAlignments.headerCell}>{headerCell}</SimpleTableCell>
            ) : (
              headerCell
            ),
          renderCell: columns => {
            const currentColumn = columns.find(column => column.headerKey === headerDefinition.key);
            const dataCell = currentColumn !== undefined ? RenderCell({ data: currentColumn.data }) : <div />;

            return typeof headerDefinition.cellAlignments !== 'undefined' &&
              typeof headerDefinition.cellAlignments.dataCell !== 'undefined' ? (
              <SimpleTableCell alignment={headerDefinition.cellAlignments.dataCell}>{dataCell}</SimpleTableCell>
            ) : (
              dataCell
            );
          },
        };
      }),
    [headers],
  );

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
      `}
    >
      <DataTable
        columns={dataTableColumns}
        data={rows.map(row => row.columns)}
        EmptyStatePlaceholder={''}
      />
    </div>
  );
};

export default SimpleTableComponent;
