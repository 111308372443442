import InputText from '~/neo-ui/packages/input/packages/input-text/InputText';
import React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { boxShadowToCode } from '~/neo-ui/packages/style/BoxShadow';
import ConsoleContext from '~/neo-ui/packages/table/packages/console/contexts/ConsoleContext';

type ConsoleSearchBarProps = {
  placeholder?: string;
  defaultValue?: string;
  handleEnterSearchInput: (inputText: string | undefined) => void;
} & Styleable;

const ConsoleSearchBar = ({
  className,
  placeholder = 'Search results...',
  defaultValue = '',
  handleEnterSearchInput,
}: ConsoleSearchBarProps) => {
  const { consoleState } = React.useContext(ConsoleContext);

  const [internalSearchText, setInternalSearchText] = React.useState<string | undefined>(defaultValue);

  const onChangeSearchInput = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInternalSearchText(e.target.value);
      handleEnterSearchInput(e.target.value);
    },
    [handleEnterSearchInput],
  );
  const resetSearchOptions = React.useCallback(() => {
    setInternalSearchText('');
    handleEnterSearchInput(undefined);
  }, [handleEnterSearchInput]);

  // Clear search with consoleState.search changes
  React.useEffect(() => {
    const consoleSearchValue = consoleState.search?.value;
    const isValidConsoleSearchValue = consoleSearchValue !== undefined;
    const internalInputExists = internalSearchText !== '';

    if (internalInputExists && !isValidConsoleSearchValue) {
      resetSearchOptions();
      return;
    }
    if (isValidConsoleSearchValue && consoleSearchValue !== internalSearchText) {
      setInternalSearchText(consoleSearchValue);
      handleEnterSearchInput(consoleSearchValue);
    }
  }, [handleEnterSearchInput, internalSearchText, resetSearchOptions, consoleState.search]);

  const consoleSearchBarStyling = css`
    box-shadow: none;
    border-radius: 0.313rem 0.313rem 0.125rem 0.125rem;
    border: 2px solid ${colorToCode('dark-900-12')};
    &:hover {
      border: 2px solid ${colorToCode('secondary-300')} !important;
      box-shadow: ${boxShadowToCode('shadow100')} !important;
      color: ${colorToCode('secondary-700')} !important;
    }
  `;
  return (
    <div className={className}>
      <InputText
        onTextChange={onChangeSearchInput}
        value={internalSearchText ?? ''}
        placeholder={placeholder}
        onClear={resetSearchOptions}
        customInputBoxStyle={consoleSearchBarStyling}
      />
    </div>
  );
};

export default ConsoleSearchBar;
