import { BudgetCurrencyDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Dto/Model.gen';
import { ScorecardCategoryDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardCategory/Dto/Model.gen';
import { PriorityDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Priority/Dto/Model.gen';
import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import { css } from '@emotion/react';
import ScorecardItemsDropZone from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-items-drop-zone/ScorecardItemsDropZone';
import DraggableElement from '~/neo-ui/packages/drag-and-drop/packages/draggable-element/DraggableElement';
import Testable from '~/neo-ui/packages/testable/Testable';
import ScorecardItemUpdateForm from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/packages/scorecard-item-update-form/ScorecardItemUpdateForm';
import FormSubmitIndicator from '~/neo-ui/packages/form/packages/form-submit-indicator/FormSubmitIndicator';
import ScorecardItem from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/ScorecardItem';
import ScorecardItemCreateCard from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/packages/scorecard-item-create-card/ScorecardItemCreateCard';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Icon from '~/neo-ui/packages/icon/Icon';
import ScorecardItemManageButton from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/packages/scorecard-item-manage-button/ScorecardItemManageButton';
import ScorecardItemInsightButton from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/packages/scorecard-item-insight-button/ScorecardItemInsightButton';
import { ScaleOption } from '~/neo-ui/packages/select/packages/scale-select/ScaleSelect';

export type ScorecardItemsSectionProps = {
  scorecardId: string;
  scorecardCategory: ScorecardCategoryDto;
  organizationId: string;
  currency: BudgetCurrencyDto;
  scoreDisplayAvailabilities: ScaleOption[];
  priorityDisplayAvailabilities: PriorityDto[];
  onScorecardUpdateFailed: () => void;
};

const ScorecardItemsSection = ({
  scorecardId,
  scorecardCategory,
  organizationId,
  currency,
  scoreDisplayAvailabilities,
  priorityDisplayAvailabilities,
  onScorecardUpdateFailed,
}: ScorecardItemsSectionProps) => {
  const [orderedScorecardItems, setOrderedScorecardItems] = React.useState(scorecardCategory.scorecardItems);

  // Refreshes item list when items are added / removed
  React.useEffect(() => setOrderedScorecardItems(scorecardCategory.scorecardItems), [scorecardCategory.scorecardItems]);

  return (
    <React.Fragment>
      <ScorecardItemsDropZone
        scorecardId={scorecardId}
        scorecardCategoryId={scorecardCategory.scorecardCategoryId}
        scorecardItems={orderedScorecardItems}
        onReorder={items => setOrderedScorecardItems(items)}
      >
        {orderedScorecardItems.map((scorecardItem, index) => (
          <DraggableElement
            key={`${scorecardItem.scorecardItemId}`}
            index={index}
            draggableId={`${scorecardItem.scorecardItemId}`}
            css={css`
              margin-bottom: 0.625rem;
            `}
            isUsingCustomizeDragHandler={true}
          >
            {({ dragHandleProps }) => (
              <Card
                backgroundColor={'light-000'}
                shadow={'sm'}
                padding={'none'}
                key={`scorecard-item-${scorecardItem.scorecardItemId}`}
              >
                <Testable testId={'scorecard-item'}>
                  <ScorecardItemUpdateForm
                    scorecardItem={scorecardItem}
                    currency={currency}
                    onScorecardUpdateFailed={onScorecardUpdateFailed}
                  >
                    <div
                      {...dragHandleProps}
                      css={css`
                        border-radius: 0.625rem 0.625rem 0 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 1rem;
                        min-height: 2.625rem;
                      `}
                    >
                      <div
                        css={css`
                          display: flex;
                          gap: 0.625rem;
                        `}
                      >
                        <Header
                          size={4}
                          weight={'bold'}
                        >
                          {scorecardItem.category.label}
                        </Header>
                        <ScorecardItemManageButton
                          scorecardItem={scorecardItem}
                          scorecardCategoryId={scorecardCategory.scorecardCategoryId}
                        />
                      </div>
                      <div
                        css={css`
                          display: flex;
                          gap: 0.375rem;
                          align-items: center;
                        `}
                      >
                        <FormSubmitIndicator />
                        {scorecardItem.automationData && (
                          <ScorecardItemInsightButton
                            scorecardId={scorecardItem.scorecardId}
                            scorecardItemId={scorecardItem.scorecardItemId}
                            organizationId={organizationId}
                            scorecardItemAutomationData={scorecardItem.automationData}
                          />
                        )}
                        <Icon
                          icon={'DragVertically'}
                          color={'dark-900-32'}
                        />
                      </div>
                    </div>
                    <ScorecardItem
                      scorecardItem={scorecardItem}
                      currency={currency}
                      scoreDisplayAvailabilities={scoreDisplayAvailabilities}
                      priorityDisplayAvailabilities={priorityDisplayAvailabilities}
                    />
                  </ScorecardItemUpdateForm>
                </Testable>
              </Card>
            )}
          </DraggableElement>
        ))}
      </ScorecardItemsDropZone>
      <ScorecardItemCreateCard
        scorecardId={scorecardId}
        scorecardCategoryLabel={scorecardCategory.label}
        scorecardCategoryId={scorecardCategory.scorecardCategoryId}
      />
    </React.Fragment>
  );
};

export default ScorecardItemsSection;
