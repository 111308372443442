import { RenderFilter } from '@AssetManagementClient/BeastClient/Search/Model/Query/Field/Filter/Render.gen';
import { Enum } from '@AssetManagementClient/BeastClient/Search/Model/Query/Field/Filter/Render/RenderFilterNested.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import MultiSelect from '~/neo-ui/packages/select/packages/multi-select/MultiSelect';
import SelectGroup, { ButtonSelectOption } from '~/neo-ui/packages/select/packages/select-group/SelectGroup';
import SingleSelect from '~/neo-ui/packages/select/packages/single-select/SingleSelect';
import { Filter, TableState } from '~/neo-ui/packages/table/packages/filter-table/FilterTable';
import Label from '~/neo-ui/packages/text/packages/label/Label';

const RenderFilterFormatter = <T,>(
  filter: Filter,
  tableState: TableState<T>,
  onChange: (selectedValues: string[]) => void,
): React.ReactNode => {
  const render = filter.render ?? ({ case: Enum.MultiSelect } as RenderFilter);
  const selectedOptions = filter.options.filter(
    option => tableState.filters?.selectedFilters.get(filter.value)?.has(option.value) ?? false,
  );
  switch (render.case) {
    case Enum.MultiSelect: {
      if (!tableState.filters) {
        return null;
      }
      const disabled =
        filter.appliesToContexts &&
        (!tableState.filters.selectedSearchContextValue ||
          !filter.appliesToContexts.includes(tableState.filters.selectedSearchContextValue));
      return (
        <MultiSelect
          css={css`
            display: inline-block;
            width: 12.5rem;
            margin-right: 0.625rem;
            margin-bottom: 0.625rem;
          `}
          disabled={disabled}
          key={filter.value}
          label={filter.label}
          options={
            render.options?.map(option => ({
              label: option.label,
              value: option.key,
            })) ?? filter.options
          }
          selectedOptions={selectedOptions}
          onChange={(selectedOptions: SelectOption[]) => onChange(selectedOptions.map(o => o.value))}
        />
      );
    }
    case Enum.SingleSelect: {
      return (
        <div
          css={css`
            display: inline-block;
            margin-right: 0.625rem;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Label
              css={css`
                margin-right: 0.625rem;
              `}
              bold={true}
            >
              {filter.label}
            </Label>
            <SingleSelect
              options={
                render.options?.map(option => ({
                  label: option.label,
                  value: option.key,
                })) ?? filter.options
              }
              selectedOption={selectedOptions[0]}
              onOptionSelected={selectedOption => onChange([selectedOption.value])}
              style={'default'}
              controlContainerHeight={'2.25rem'}
              css={css`
                width: 12.5rem;
              `}
            />
          </div>
        </div>
      );
    }
    case Enum.ButtonSelect: {
      return (
        <div
          css={css`
            display: inline-block;
            margin-right: 0.625rem;
            margin-bottom: 0.625rem;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Label
              css={css`
                margin-right: 0.625rem;
              `}
              bold={true}
            >
              {filter.label}
            </Label>
            <SelectGroup
              options={(
                render.options?.map(option => ({
                  label: option.label,
                  value: option.key,
                })) ?? filter.options
              ).map(x => ({
                ...x,
                disabled: render.disabledOptions.includes(x.value),
              }))}
              selectedOptionValue={selectedOptions[0].value}
              onOptionSelected={(selectedOption: ButtonSelectOption) => onChange([selectedOption.value])}
            />
          </div>
        </div>
      );
    }
    case Enum.SingleOperatorMultiSelect: {
      throw new Error('This renderer is not available for the legacy console system.');
    }
    case Enum.RangeValueInput: {
      throw new Error('This renderer is not available for the legacy console system.');
    }
    case Enum.SplitMultiSelect: {
      throw new Error('This renderer is not available for the legacy console system.');
    }
  }
};

export default RenderFilterFormatter;
