import { HardwareDueSoonThresholdSettingsAvailabilities } from '@AssetManagementClient/BeastClient/Beast/HardwareSettings/Model/DueSoonThreshold/Availabilities.gen';
import { ScopeKey } from '@AssetManagementClient/BeastClient/Beast/Settings/Scope.gen';

import * as React from 'react';
import HardwareDueSoonThresholdSettingsForm from '~/wm/packages/hardware/packages/settings/configurable/HardwareDueSoonThresholdSettingsForm';
import SettingsConfigurable from '~/wm/packages/settings/packages/configurable/SettingsConfigurable';

export type HardwareDueSoonThresholdConfigurableProps = {
  availabilities: HardwareDueSoonThresholdSettingsAvailabilities;
  scopeKey: ScopeKey;
};

const HardwareDueSoonThresholdConfigurable: React.FunctionComponent<HardwareDueSoonThresholdConfigurableProps> = ({
  availabilities,
  scopeKey,
}) => (
  <SettingsConfigurable
    settingsKey={{ scopeKey, configurableId: 'hardware-due-soon-threshold' }}
    label="Due soon for replacement"
    description='Specify how many days before an age-based recommendation an asset is marked as "Due soon"'
  >
    {({ isUsingDefault }) => (
      <HardwareDueSoonThresholdSettingsForm
        availabilities={availabilities}
        disabled={isUsingDefault}
      />
    )}
  </SettingsConfigurable>
);

export default HardwareDueSoonThresholdConfigurable;
