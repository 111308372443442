import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { organizationsSummaryFeatureUnlockedSearch } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { OrganizationSummarySearchResult } from '@AssetManagementClient/BeastClient/Beast/Organization/Model/OrganizationsSummary.gen';
import { Discriminant } from '@AssetManagementClient/BeastClient/Beast/Organization/Model/OrganizationsSummary/OrganizationSummarySearchResultNested.gen';

export type UseFeatureAccessInfo = {
  featureUnlockedOrganizationSummaryResult: OrganizationSummarySearchResult[];
};

const useOrganizationsSummaryFeatureUnlockedSearch = (): UseFeatureAccessInfo => {
  const [featureUnlockedOrganizationSummaryResult, setFeatureUnlockedOrganizationSummaryResult] = React.useState<
    OrganizationSummarySearchResult[]
  >([]);
  const { callApi } = useApi();

  React.useEffect(() => {
    (async () => {
      const response = await callApi(() => organizationsSummaryFeatureUnlockedSearch({}));

      if (!response) {
        return;
      }

      const uniqueMap = new Set<string>();

      const uniqueOrganizationSummaryResultsMap: OrganizationSummarySearchResult[] = Object.values(
        response.unlockedOrganizationResultsByFeatureId,
      )
        .reduce(
          (unlockedOrganizations, featureUnlockedOrganizations) => unlockedOrganizations.concat(featureUnlockedOrganizations),
          new Array<OrganizationSummarySearchResult>(),
        )
        .reduce((uniqueOrganizationSummaryResult, organizationSummary) => {
          if (organizationSummary.case === Discriminant.Visible) {
            if (!uniqueMap.has(organizationSummary.organization.id)) {
              uniqueMap.add(organizationSummary.organization.id);
              return [...uniqueOrganizationSummaryResult, organizationSummary];
            }
            return uniqueOrganizationSummaryResult;
          }
          return uniqueOrganizationSummaryResult;
        }, new Array<OrganizationSummarySearchResult>());

      setFeatureUnlockedOrganizationSummaryResult(uniqueOrganizationSummaryResultsMap);
    })();
  }, [callApi]);

  return {
    featureUnlockedOrganizationSummaryResult,
  };
};

export default useOrganizationsSummaryFeatureUnlockedSearch;
