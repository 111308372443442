import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import * as React from 'react';
import { useCallback } from 'react';
import { IntegrationVendorDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Controller/IntegrationPage.gen';
import SelectIntegrationPage, {
  SetFilterState,
} from '~/wm/packages/integration/packages/scalepad-account/packages/add-integration-page/packages/filters/SelectIntegrationPage';

export type FilterFeaturesProps = {
  name: string;
  allOptions: SelectOption[];
  filterStatePayload: SetFilterState;
};

const FilterIntegrationDataTypes: React.FunctionComponent<FilterFeaturesProps> = ({ name, allOptions, filterStatePayload }) => {
  const filterAllFunction = useCallback(
    (selectedValues: string[]) => (integrationVendor: IntegrationVendorDto) => {
      if (selectedValues.length === 0) {
        return true;
      }
      return selectedValues.every(selectedValue => integrationVendor.supportedDataTypes.includes(selectedValue));
    },
    [],
  );

  return (
    <SelectIntegrationPage
      name={name}
      allOptions={allOptions}
      filterStatePayload={{
        ...filterStatePayload,
        filterFunction: filterAllFunction,
      }}
      selectionType={'all'}
    />
  );
};
export default FilterIntegrationDataTypes;
