import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import { SuccessIcon } from '~/sp-ui/icons';

const AppliedCouponsSection = () => {
  const { formik } = useMultiStepForm<PurchaseSubscriptionForm>();
  const { setFieldValue } = formik;

  const removeCouponAction = (couponName: string) => {
    setFieldValue(
      'couponCodes',
      formik.values.couponCodes?.filter(coupon => coupon !== couponName),
    );
    setFieldValue('couponCodeError', undefined);
  };

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
      }}
    >
      {formik.values.couponBreakdown?.map((coupon, index) => (
        <div
          key={index}
          css={{
            display: 'flex',
            width: '100%',
            padding: '0.75rem',
            alignItems: 'flex-start',
            gap: '0.625rem',
            flex: '1 0 0',
            borderRadius: '0.3125rem',
            border: '1px solid #38A825',
            background: '#DFF5DB',
            marginBottom: '0.75rem',
          }}
        >
          <SuccessIcon />
          <div
            css={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '0.25rem',
              flex: '1 0 0',
            }}
          >
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '0.25rem',
                flex: '1 0 0',
              }}
            >
              <div>
                <b>{coupon.name}</b> applied
              </div>
              <div>You saved {formatCurrency(coupon.amount)}</div>
            </div>
            <div
              onClick={() => removeCouponAction(coupon.name)}
              css={{
                cursor: 'pointer',
                color: '#0575CC',
                fontFamily: 'Roboto',
                fontSize: '0.875rem',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '150%',
                textDecorationLine: 'underline',
              }}
            >
              Remove
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AppliedCouponsSection;
