import { disposalCheckoutBulk } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { DisposalCheckoutBulkPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Packages/DisposalCheckoutBulk/Dto/Model.gen';
import { Discriminant } from '@AssetManagementClient/Primitives/Results/DisposalCheckoutBulkControllerNested/Response_/ApiError_/ResultNested.gen';
import { Response } from '@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Checkout/Packages/DisposalCheckoutBulk/Controller/DisposalCheckoutBulkControllerNested.gen';

export type UseDisposalBulkCheckout = () => {
  disposalCheckout: (disposalCheckoutBulkPayload: DisposalCheckoutBulkPayloadDto) => Promise<Response | undefined>;
};

const useDisposalBulkCheckout: UseDisposalBulkCheckout = () => {
  const { callApi } = useApi();

  const checkout = useCallback(
    async (disposalCheckoutBulkPayload: DisposalCheckoutBulkPayloadDto) => {
      const response = await callApi(() => disposalCheckoutBulk({ disposalCheckoutBulkPayload }));

      if (!response) {
        return;
      }

      switch (response.case) {
        case Discriminant.Error:
          // eslint-disable-next-line no-throw-literal
          throw {
            GlobalMessage: response.value.messagePublic,
            Errors: response.value.fieldErrors.reduce<{
              [index: string]: string[];
            }>(
              (errors, fieldError) => ({
                ...errors,
                [fieldError.fieldKey]: [fieldError.messagePublic],
              }),
              {},
            ),
            // The abstraction is not layered properly that's why
            // we have to specify 400 here - WM-3861
            Status: 400,
          };

        case Discriminant.Ok:
          return response.value;
      }

      return;
    },
    [callApi],
  );

  return {
    disposalCheckout: checkout,
  };
};

export default useDisposalBulkCheckout;
