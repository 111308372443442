import * as Yup from 'yup';
import { EngagementNoteFormDataType } from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/types/EngagementNoteFormDataType';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const engagementNoteFormValidationSchema: Yup.ObjectSchema<any> = Yup.object().shape<Partial<EngagementNoteFormDataType>>({
  title: Yup.string().notRequired(),
  // Duped in Beast & React: 82f80634-cbfa-4f7a-a20c-33f45c93201a
  body: Yup.string().max(10000, 'This body is too long!').required(),
});

export default engagementNoteFormValidationSchema;
