import useApi from '~/wm/packages/api/hook/useApi';
import { useState, useEffect } from 'react';
import { insightsCreateForm } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { Response } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Controller/Field/InsightCreateFormMetadataNested.gen';

export const useInsightCreateFormMetadata = () => {
  const { callApi } = useApi();

  const [formMetadata, setFormMetadata] = useState<Response | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const response = await callApi(() => insightsCreateForm({}));
      if (!response) {
        return;
      }
      setFormMetadata(response.metadata);
    })();
  }, [callApi]);

  return { formMetadata };
};
