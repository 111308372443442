import { ComponentPropsWithRef, forwardRef } from 'react';
import theme from './../theme';

export interface CircleNumberProps extends ComponentPropsWithRef<'span'> {
  number: number;
  variant?: 'active' | 'neutral' | 'disabled';
}

const CircleNumber = forwardRef<HTMLSpanElement, CircleNumberProps>((props, ref) => {
  const { number, variant = 'neutral', ...rest } = props;

  return (
    <span
      ref={ref}
      css={[
        {
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '16px',
          lineHeight: '24px',
        },
        variant === 'neutral' && {
          border: `1px solid ${theme.palette.grey2}`,
          color: theme.palette.grey2,
          backgroundColor: theme.palette.grey4,
        },
        variant === 'active' && {
          border: `1px solid ${theme.palette.primary1}`,
          color: theme.palette.white,
          backgroundColor: theme.palette.primary1,
        },
        variant === 'disabled' && {
          border: `1px solid ${theme.palette.grey2}`,
          color: theme.palette.white,
          backgroundColor: theme.palette.grey2,
        },
      ]}
      {...rest}
    >
      {number}
    </span>
  );
});

export default CircleNumber;
