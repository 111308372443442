import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ComponentType } from 'react';

export type FeatureFlagProviderProps = {
  reactLaunchDarklyEnabledForComponent?: boolean;
  allowStreaming?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrappedComponent: any;
};
const featureFlagProvider = ({
  reactLaunchDarklyEnabledForComponent = false,
  allowStreaming = true,
  wrappedComponent,
}: FeatureFlagProviderProps) => {
  const component = wrappedComponent as ComponentType;
  const context =
    typeof WM.account === 'undefined'
      ? // anonymousUserContext
        {
          kind: 'user',
          anonymous: true,
          key: 'anonymous-user',
        }
      : {
          key: WM.account.id,
        };

  return reactLaunchDarklyEnabledForComponent
    ? withLDProvider({
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        clientSideID: WM.launchDarklyClientSideId,
        /**
         * Initialize the app with the current account user.
         */
        context,
        /**
         * Indicate if we should subscribe to flag change
         */
        options: {
          streaming: allowStreaming,
        },
        /**
         * Indicates if we should change casing of feature flag keys
         */
        reactOptions: {
          useCamelCaseFlagKeys: false,
        },
      })(component)
    : component;
};

export default featureFlagProvider;
