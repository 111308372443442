/* eslint-disable react-hooks/rules-of-hooks,@typescript-eslint/naming-convention,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call */
import * as React from 'react';
import { css } from '@emotion/react';
import { Layout, Layouts } from 'react-grid-layout';
import DashboardGrid, { DashboardGridProps } from '~/wm/packages/dashboard/packages/dashboard-grid/DashboardGrid';
import { mobileBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import { useWidgetAvailability } from '~/wm/packages/dashboard/packages/dashboard-page/hook/useWidgetAvailability';
import { WidgetSize } from '@DashboardClient/Dashboard/Packages/Widget/Packages/Registration/Model.gen';
import componentsRegistration from '~/wm/packages/dashboard/packages/componentsRegistration';
import DashboardUserPreferenceSection from '~/wm/packages/dashboard/packages/dashboard-page/packages/dashboard-preference/DashboardUserPreferenceSection';

export type DashboardPageProps = {
  userFirstName: string;
  helpGuideUrl: string;
  isNewDashboard: boolean;
  isDashboardBannerClosed: boolean;
  isNewDashboardFullyReleased: boolean;
};

const layoutSpecifications = {
  numColumns: { lg: 12, md: 12, sm: 6, xs: 3, xxs: 1 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
};

const buildLayouts = (
  widgetsSize: {
    id: string;
    size: WidgetSize;
  }[],
): Layouts => {
  const allLayouts: { [columnSize: string]: Layout[] } = {};
  Object.keys(layoutSpecifications.numColumns).forEach((layoutSize, layoutColumns) => {
    const layouts: Layout[] = [];
    let y = 0;
    let x = 0;
    let rowWidth = 0;
    let maxRowHeight = 0;
    widgetsSize.forEach(widgetSize => {
      x = rowWidth;
      rowWidth += widgetSize.size.defaultWidth;
      // calc step for y coordinate
      if (maxRowHeight < widgetSize.size.defaultHeight) {
        maxRowHeight = widgetSize.size.defaultHeight;
      }
      // reset layout coordinates
      if (rowWidth > 12) {
        y += maxRowHeight;
        x = 0;
        rowWidth = widgetSize.size.defaultWidth;
      }
      layouts.push({
        i: widgetSize.id,
        x,
        y,
        w: widgetSize.size.defaultWidth,
        h: widgetSize.size.defaultHeight / 1.8,
        minW: Math.min(layoutColumns, widgetSize.size.minWidth),
        minH: widgetSize.size.minHeight,
        maxW: widgetSize.size.maxWidth,
        maxH: widgetSize.size.maxHeight,
      });
    });
    allLayouts[layoutSize] = layouts;
  });
  return allLayouts;
};

const DashboardPage: React.FunctionComponent<DashboardPageProps> = ({
  userFirstName,
  helpGuideUrl,
  isNewDashboard,
  isDashboardBannerClosed,
  isNewDashboardFullyReleased,
}: DashboardPageProps) => {
  componentsRegistration();
  const userName = typeof userFirstName === 'undefined' ? 'Invited Member' : userFirstName;
  const widgetAvailability = useWidgetAvailability() ?? [];
  const widgetsSize = widgetAvailability
    ?.map(widget =>
      widget.components.length > 0
        ? {
            id: widget.key,
            size: widget.size,
          }
        : [],
    )
    .flat();
  const currentBreakpoint = React.useRef<string>('lg');
  const layouts = buildLayouts(widgetsSize);

  // Local storage logic is temporary for the sake of a proof of concept
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const loadFromLocalStorage: () => Layouts = () => {
    let layoutsStorage = layouts;
    if (global.localStorage) {
      try {
        const item = global.localStorage.getItem('dashboard-layouts');
        layoutsStorage = item ? JSON.parse(item) : layoutsStorage;
      } catch {
        // ignore
      }
    }
    return layoutsStorage;
  };

  // Local storage logic is temporary for the sake of a proof of concept
  const saveToLocalStorage = () => {
    if (global.localStorage) {
      global.localStorage.setItem('dashboard-layouts', JSON.stringify(currentLayouts));
    }
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const onLayoutChange = (currentLayout: Layout[], allLayouts: Layouts) => {
    // Use this callback to trigger updates to the backend!
    // i.e. for saving layout data
    setCurrentLayouts(allLayouts);
    saveToLocalStorage();
  };

  // This is how we can determine the current breakpoint in case we have some special logic depending on the current breakpoint
  const onBreakpointChange = (newBreakpoint: string) => {
    currentBreakpoint.current = newBreakpoint;
    saveToLocalStorage();
  };

  const getDayTimePeriod = () => {
    const date = new Date();
    const hour = date.getHours();
    if (hour < 12) {
      return 'morning';
    } else if (hour < 18) {
      return 'afternoon';
    } else {
      return 'evening';
    }
  };

  const [currentLayouts, setCurrentLayouts] = React.useState<Layouts>(loadFromLocalStorage);

  const dashboardGridProps: DashboardGridProps = {
    layouts,
    widgetAvailability,
    numColumns: layoutSpecifications.numColumns,
    breakpoints: layoutSpecifications.breakpoints,
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    return currentDate.toLocaleDateString('en-us', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <div>
      {!isNewDashboardFullyReleased && (
        <DashboardUserPreferenceSection
          isNewDashboard={isNewDashboard}
          isDashboardBannerClosed={isDashboardBannerClosed}
          helpGuideUrl={helpGuideUrl}
        />
      )}

      <div
        css={css`
          position: relative;
          width: auto;
          min-height: 0;
          @media (max-width: ${mobileBreakpointRem}rem) {
            margin-top: 5rem;
          }
          justify-content: center;
        `}
      >
        <div
          css={css`
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-bottom: 0.625rem;
          `}
        >
          <div
            css={css`
              position: absolute;
              width: 100%;
              z-index: 10;
            `}
          >
            <span
              css={css`
                font-size: 0.875rem;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.6);
              `}
            >
              {getCurrentDate()}
            </span>
          </div>
          <div
            css={css`
              padding-top: 1.875rem;
            `}
          >
            <h3>
              <b>{'Good ' + getDayTimePeriod() + ', ' + userName}</b>
            </h3>
          </div>
        </div>
      </div>
      <DashboardGrid
        {...dashboardGridProps}
        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}
      />
    </div>
  );
};

export default DashboardPage;
