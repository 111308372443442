import { createContext } from 'react';

export type InitiativeAssessmentQuestionListMutationContextData = {
  triggerInitiativeAssessmentQuestionListReload: (isRequestActive?: () => boolean) => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const InitiativeAssessmentQuestionListMutationContext = createContext<InitiativeAssessmentQuestionListMutationContextData>({
  // If there's no provider, it's a graceful no-op
  triggerInitiativeAssessmentQuestionListReload: async () => {},
});

export default InitiativeAssessmentQuestionListMutationContext;
