import DisposalSelfCheckoutFormData from '~/wm/packages/disposal/types/DisposalSelfCheckoutFormData';
import { DisposalCartUpsertPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalCart/Dto.gen';

const buildDisposalCartUpsertPayloadDtoBySelfWizard = (disposalCartInfo: DisposalSelfCheckoutFormData): DisposalCartUpsertPayloadDto => ({
  pickupSiteInformation: undefined,
  pickupMaterials: [],
  assetCountsPayload: disposalCartInfo.assetTypeCounts.map(typeCount => ({
    disposalAssetType: typeCount.type,
    count: Number(typeCount.count),
    label: typeCount.customizedType,
  })),
  packageAmount: Number(disposalCartInfo.packageCount),
});

export default buildDisposalCartUpsertPayloadDtoBySelfWizard;
