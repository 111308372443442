import * as React from 'react';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import WizardPageStep from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageStep';
import IconType from '~/neo-ui/packages/icon/IconType.gen';

export type MarkerProps = {
  icon?: IconType;
  iconColor: Color;
  progressLineColor: Color;
};
export type AvailableMarkerProps = {
  done: MarkerProps;
  hover: MarkerProps;
};

export type WizardPageProgressSegmentProps<T> = {
  stepIndex: number;
  currentStepIndex: number;
  isCurrentStepCompleted: boolean | undefined;
  segmentStates: WizardPageProgressState[];
  steps: WizardPageStep<T>[];
  onClick: (stepIndex: number) => void;
};

/**
 * States the progress bar segments may be in
 */
export type WizardPageProgressState = 'incomplete' | 'disabled' | 'completed' | 'completed-disabled' | 'current';

const progressStateToColors = (progressState: WizardPageProgressState): { iconColor: Color; textColor: Color; segmentColor: Color } => {
  switch (progressState) {
    case 'current':
      return {
        iconColor: 'light-000',
        textColor: 'secondary-400',
        segmentColor: 'secondary-400',
      };
    case 'completed':
      return {
        iconColor: 'light-000',
        textColor: 'secondary-300',
        segmentColor: 'secondary-300',
      };
    case 'completed-disabled':
      return {
        iconColor: 'light-000',
        textColor: 'secondary-200',
        segmentColor: 'secondary-200',
      };
    case 'incomplete':
      return {
        iconColor: 'secondary-100',
        textColor: 'secondary-100',
        segmentColor: 'dark-900-08',
      };
    case 'disabled':
      return {
        iconColor: 'light-600',
        textColor: 'light-600',
        segmentColor: 'dark-900-08',
      };
  }
};

const availableMarkerProps: AvailableMarkerProps = {
  done: {
    icon: 'Done',
    iconColor: 'light-000',
    progressLineColor: 'dark-900-08',
  },
  hover: {
    iconColor: 'secondary-400',
    progressLineColor: 'secondary-200',
  },
};

const WizardPageProgressSegment = <T,>({
  stepIndex,
  currentStepIndex,
  isCurrentStepCompleted,
  segmentStates,
  steps,
  onClick,
}: WizardPageProgressSegmentProps<T>) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const segmentState = segmentStates[stepIndex];
  const colors = progressStateToColors(segmentState);
  const showStepLineComplete = segmentState === 'completed' && currentStepIndex > stepIndex;
  const showDoneMarker =
    segmentState === 'completed' || currentStepIndex > stepIndex || (segmentState === 'current' && isCurrentStepCompleted);

  return (
    <div
      key={stepIndex}
      onClick={() => {
        // Only completed states may be clicked on
        if ((segmentStates[stepIndex] === 'completed' || stepIndex < currentStepIndex) && segmentState === 'completed') {
          setIsHovered(false);
          onClick(stepIndex);
        }
      }}
      onMouseEnter={() => segmentState === 'completed' && setIsHovered(true)}
      onMouseLeave={() => segmentState === 'completed' && setIsHovered(false)}
      css={css`
        flex-grow: 1;
        ${isHovered && 'cursor: pointer;'}
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 0.25rem;
          position: relative;
        `}
      >
        <span
          data-testid={`step-progress-${stepIndex}`}
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            height: 1.5rem;
            width: 1.5rem;
            background-color: ${colorToCode(isHovered ? 'secondary-200' : colors.segmentColor)};
            border-radius: 50%;
          `}
        >
          <Icon
            icon={
              showDoneMarker && typeof availableMarkerProps.done.icon !== 'undefined'
                ? availableMarkerProps.done.icon
                : steps[stepIndex].header.icon
            }
            color={
              showDoneMarker ? availableMarkerProps.done.iconColor : isHovered ? availableMarkerProps.hover.iconColor : colors.iconColor
            }
          />
        </span>
        <span
          css={css`
            height: 0.25rem;
            flex-grow: 1;
            background-color: ${colorToCode(
              // Do not show the completed line until they are done with the current step
              !showStepLineComplete
                ? availableMarkerProps.done.progressLineColor
                : isHovered
                ? availableMarkerProps.hover.progressLineColor
                : colors.segmentColor,
            )};
            transition: background-color 0.3s ease;
          `}
        />
      </div>
      <Label
        size={'lg'}
        color={isHovered ? 'secondary-400' : colors.textColor}
      >
        {steps[stepIndex].header.label}
      </Label>
      <Label
        size={'sm'}
        color={isHovered ? 'secondary-400' : colors.textColor}
      >
        {steps[stepIndex].header.description}
      </Label>
    </div>
  );
};

export default WizardPageProgressSegment;
