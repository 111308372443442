import { SVGProps, forwardRef, memo } from 'react';

const GeneralIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="24"
        viewBox="0 0 36 24"
        fill="none"
        {...props}
      >
        <rect
          x="0.5"
          y="0.5"
          width="35"
          height="23"
          rx="2.5"
          fill="white"
        />
        <rect
          x="0.5"
          y="0.5"
          width="35"
          height="23"
          rx="2.5"
          stroke="#DDDDDD"
        />
        <path
          d="M36 5H0V11H36V5Z"
          fill="#DDDDDD"
        />
        <rect
          x="3"
          y="13"
          width="12"
          height="2"
          fill="#DDDDDD"
        />
      </svg>
    );
  }),
);

export default GeneralIcon;
