import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';
import TicketIntegrationRequiredModal from '~/wm/packages/integration/packages/ticket/packages/ticket-error-modal-or-success/packages/ticket-integration-required-modal/TicketIntegrationRequiredModal';
import TicketOrganizationRegistrationRequiredModal from '~/wm/packages/integration/packages/ticket/packages/ticket-error-modal-or-success/packages/ticket-organization-registration-required-modal/TicketOrganizationRegistrationRequiredModal';
import { Fragment, ReactNode } from 'react';
import TicketIntegrationSetupHelpModal from '~/wm/packages/integration/packages/ticket/packages/ticket-error-modal-or-success/packages/ticket-integration-setup-help-modal/TicketIntegrationSetupHelpModal';

export type TicketErrorModalOrSuccessProps = {
  ticketFieldOptions: IntegrationFieldOptionDto[];
  isOrganizationRegisteredForTickets: boolean;
  hasTicketIntegration: boolean;
  onDismiss: () => void;

  /**
   * The modal or window to show if a ticket can be created, provided by the parent component
   */
  successComponent: ReactNode;
};

/**
 * This component is used to determine which modal to show based on the state of the ticket integration
 * - An error modal will be shown if the organization is not registered for tickets or if there is no ticket integration
 * - The success component will be shown if the organization is registered for tickets and there is a ticket integration
 */
const TicketErrorModalOrSuccess = ({
  ticketFieldOptions,
  isOrganizationRegisteredForTickets,
  hasTicketIntegration,
  onDismiss,
  successComponent,
}: TicketErrorModalOrSuccessProps) => {
  /* Show integration required modal if there isn't an integration setup */
  if (!hasTicketIntegration) {
    return (
      <TicketIntegrationRequiredModal
        isOpen={true}
        onDismiss={onDismiss}
      />
    );
  }

  /* Show register organization modal if this organization cannot have ticket created for it  */
  if (!isOrganizationRegisteredForTickets) {
    return (
      <TicketOrganizationRegistrationRequiredModal
        isOpen={true}
        onDismiss={onDismiss}
      />
    );
  }

  /* Show integration setup help modal if there are no field options */
  if (ticketFieldOptions.length === 0) {
    return (
      <TicketIntegrationSetupHelpModal
        isOpen={true}
        onDismiss={onDismiss}
      />
    );
  }

  /* Show ticket field options window */
  if (isOrganizationRegisteredForTickets && hasTicketIntegration) {
    return <Fragment>{successComponent}</Fragment>;
  }

  /* Fall back to integration required modal */
  return (
    <TicketIntegrationRequiredModal
      isOpen={true}
      onDismiss={onDismiss}
    />
  );
};

export default TicketErrorModalOrSuccess;
