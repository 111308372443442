import Color from '~/neo-ui/packages/color/Color.gen';
import Theme, { removeLegacyTheme } from '~/neo-ui/packages/color/Theme';
import { useMemo } from 'react';

export type ThemeMapInputTitle = {
  /**
   * Color of the icon/prepended character to the input
   */
  iconColor: Color;
  /**
   * Color of the icon/prepended character to the input when disabled
   */
  iconColorDisabled: Color;
  /**
   * Color of the input text when untouched
   */
  textColorRest: Color;
  /**
   * Color of the input text when hovered
   */
  textColorHover: Color;
  /**
   * Color of the input text when focused
   */
  textColorFocus: Color;
  /**
   * Color of the input text when disabled
   */
  textColorDisabled: Color;
  /**
   * Color of the placeholder text when untouched
   */
  textColorPlaceholderRest: Color;
  /**
   * Color of the placeholder text when hovering
   */
  textColorPlaceholderHover: Color;
  /**
   * Color of the placeholder text when focused
   */
  textColorPlaceholderFocus: Color;
  /**
   * Color of the placeholder text when focused
   */
  textColorPlaceholderDisabled: Color;
  /**
   * Background of the input when untouched
   */
  backgroundColorRest: Color;
  /**
   * Background color of the input when focused
   */
  backgroundColorFocus: Color;
  /**
   * Background color of the input when focused
   */
  backgroundColorDisabled: Color;
  /**
   * Left, Top, Right border color of the input when untouched
   */
  surroundingLineColorRest: Color;
  /**
   * Left, Top, Right border color of the input when hovered
   */
  surroundLineColorHover: Color;
  /**
   * Bottom border color of the input when untouched
   */
  underlineColorRest: Color;
  /**
   * Bottom border color of the input when hovered
   */
  underlineColorHover: Color;
  /**
   * Bottom border color of the input when focused
   */
  borderColorFocus: Color;
  /**
   * Bottom border color of the input when focused
   */
  borderColorDisabled: Color;
};

type UseThemeInputTitle = { themeMap: ThemeMapInputTitle };

/**
 * Provides a breakdown of colors to use for an InputTitle
 *
 * @see useTheme - Originally defines standardization for theme colors
 * @see InputTitle - The component that uses this hook for theming
 */
const useThemeInputTitle = (theme?: Theme): UseThemeInputTitle => {
  const themeMap = useMemo(() => themeToThemeMapInputTitle(theme), [theme]);

  return { themeMap };
};

const themeToThemeMapInputTitle = (theme?: Theme): ThemeMapInputTitle => {
  switch (removeLegacyTheme(theme)) {
    case 'primary':
      return {
        iconColor: 'dark-700',
        iconColorDisabled: 'dark-900-24',

        textColorRest: 'dark-700',
        textColorHover: 'primary-400',
        textColorFocus: 'primary-400',
        textColorDisabled: 'dark-900-24',

        textColorPlaceholderRest: 'dark-900-24',
        textColorPlaceholderHover: 'primary-200',
        textColorPlaceholderFocus: 'primary-200',
        textColorPlaceholderDisabled: 'dark-900-24',

        backgroundColorRest: 'light-050',
        backgroundColorFocus: 'primary-050',
        backgroundColorDisabled: 'dark-900-08',

        surroundingLineColorRest: 'dark-900-24',
        surroundLineColorHover: 'primary-400',
        underlineColorRest: 'dark-900-64',
        underlineColorHover: 'primary-400',
        borderColorFocus: 'primary-400',
        borderColorDisabled: 'dark-900-24',
      };
    case 'positive':
      return {
        iconColor: 'dark-700',
        iconColorDisabled: 'dark-900-24',

        textColorRest: 'positive-400',
        textColorHover: 'positive-400',
        textColorFocus: 'positive-400',
        textColorDisabled: 'dark-900-24',

        textColorPlaceholderRest: 'dark-900-24',
        textColorPlaceholderHover: 'positive-200',
        textColorPlaceholderFocus: 'positive-200',
        textColorPlaceholderDisabled: 'dark-900-24',

        backgroundColorRest: 'light-050',
        backgroundColorFocus: 'positive-050',
        backgroundColorDisabled: 'dark-900-08',

        surroundingLineColorRest: 'dark-900-24',
        surroundLineColorHover: 'positive-400',
        underlineColorRest: 'dark-900-64',
        underlineColorHover: 'positive-400',
        borderColorFocus: 'positive-400',
        borderColorDisabled: 'dark-900-24',
      };
    case 'caution':
      return {
        iconColor: 'dark-700',
        iconColorDisabled: 'dark-900-24',

        textColorRest: 'dark-700',
        textColorHover: 'caution-400',
        textColorFocus: 'caution-400',
        textColorDisabled: 'dark-900-24',

        textColorPlaceholderRest: 'dark-900-24',
        textColorPlaceholderHover: 'caution-200',
        textColorPlaceholderFocus: 'caution-200',
        textColorPlaceholderDisabled: 'dark-900-24',

        backgroundColorRest: 'light-050',
        backgroundColorFocus: 'caution-050',
        backgroundColorDisabled: 'dark-900-08',

        surroundingLineColorRest: 'dark-900-24',
        surroundLineColorHover: 'caution-400',
        underlineColorRest: 'dark-900-64',
        underlineColorHover: 'caution-400',
        borderColorFocus: 'caution-400',
        borderColorDisabled: 'dark-900-24',
      };
    case 'warning':
      return {
        iconColor: 'dark-700',
        iconColorDisabled: 'dark-900-24',

        textColorRest: 'dark-700',
        textColorHover: 'warning-400',
        textColorFocus: 'warning-400',
        textColorDisabled: 'dark-900-24',

        textColorPlaceholderRest: 'dark-900-24',
        textColorPlaceholderHover: 'warning-200',
        textColorPlaceholderFocus: 'warning-200',
        textColorPlaceholderDisabled: 'dark-900-24',

        backgroundColorRest: 'light-050',
        backgroundColorFocus: 'warning-050',
        backgroundColorDisabled: 'dark-900-08',

        surroundingLineColorRest: 'dark-900-24',
        surroundLineColorHover: 'warning-400',
        underlineColorRest: 'dark-900-64',
        underlineColorHover: 'warning-400',
        borderColorFocus: 'warning-400',
        borderColorDisabled: 'dark-900-24',
      };
    case 'negative':
      return {
        iconColor: 'dark-700',
        iconColorDisabled: 'dark-900-24',

        textColorRest: 'negative-400',
        textColorHover: 'negative-400',
        textColorFocus: 'negative-400',
        textColorDisabled: 'dark-900-24',

        textColorPlaceholderRest: 'dark-900-24',
        textColorPlaceholderHover: 'negative-200',
        textColorPlaceholderFocus: 'negative-200',
        textColorPlaceholderDisabled: 'dark-900-24',

        backgroundColorRest: 'light-050',
        backgroundColorFocus: 'negative-050',
        backgroundColorDisabled: 'dark-900-08',

        surroundingLineColorRest: 'dark-900-24',
        surroundLineColorHover: 'negative-400',
        underlineColorRest: 'negative-400',
        underlineColorHover: 'negative-400',
        borderColorFocus: 'negative-400',
        borderColorDisabled: 'dark-900-24',
      };
    case 'excellent':
      return {
        iconColor: 'dark-700',
        iconColorDisabled: 'dark-900-24',

        textColorRest: 'dark-700',
        textColorHover: 'excellent-400',
        textColorFocus: 'excellent-400',
        textColorDisabled: 'dark-900-24',

        textColorPlaceholderRest: 'dark-900-24',
        textColorPlaceholderHover: 'excellent-200',
        textColorPlaceholderFocus: 'excellent-200',
        textColorPlaceholderDisabled: 'dark-900-24',

        backgroundColorRest: 'light-050',
        backgroundColorFocus: 'excellent-050',
        backgroundColorDisabled: 'dark-900-08',

        surroundingLineColorRest: 'dark-900-24',
        surroundLineColorHover: 'excellent-400',
        underlineColorRest: 'dark-900-64',
        underlineColorHover: 'excellent-400',
        borderColorFocus: 'excellent-400',
        borderColorDisabled: 'dark-900-24',
      };
    case 'info':
      return {
        iconColor: 'dark-700',
        iconColorDisabled: 'dark-900-24',

        textColorRest: 'dark-700',
        textColorHover: 'info-400',
        textColorFocus: 'info-400',
        textColorDisabled: 'dark-900-24',

        textColorPlaceholderRest: 'dark-900-24',
        textColorPlaceholderHover: 'info-200',
        textColorPlaceholderFocus: 'info-200',
        textColorPlaceholderDisabled: 'dark-900-24',

        backgroundColorRest: 'light-050',
        backgroundColorFocus: 'info-050',
        backgroundColorDisabled: 'dark-900-08',

        surroundingLineColorRest: 'dark-900-24',
        surroundLineColorHover: 'info-400',
        underlineColorRest: 'dark-900-64',
        underlineColorHover: 'info-400',
        borderColorFocus: 'info-400',
        borderColorDisabled: 'dark-900-24',
      };
    // case 'light':
    default:
      return {
        iconColor: 'dark-700',
        iconColorDisabled: 'dark-900-24',

        textColorRest: 'dark-700',
        textColorHover: 'secondary-400',
        textColorFocus: 'secondary-400',
        textColorDisabled: 'dark-900-24',

        textColorPlaceholderRest: 'dark-900-24',
        textColorPlaceholderHover: 'secondary-200',
        textColorPlaceholderFocus: 'secondary-200',
        textColorPlaceholderDisabled: 'dark-900-24',

        backgroundColorRest: 'light-050',
        backgroundColorFocus: 'secondary-050',
        backgroundColorDisabled: 'dark-900-08',

        surroundingLineColorRest: 'dark-900-24',
        surroundLineColorHover: 'secondary-400',
        underlineColorRest: 'dark-900-64',
        underlineColorHover: 'secondary-400',
        borderColorFocus: 'secondary-400',
        borderColorDisabled: 'dark-900-24',
      };
  }
};

export default useThemeInputTitle;
