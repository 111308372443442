import * as React from 'react';
import { ToolbarControl } from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import Testable from '~/neo-ui/packages/testable/Testable';
import LayoutFooter from '~/neo-ui/packages/layout/packages/footer/LayoutFooter';

export type WizardPageFooterProps = {
  actionToolbarControl?: ToolbarControl;
  leftControls?: ToolbarControl[];
  rightControls?: ToolbarControl[];

  /**
   * Summary component to display throughout the Wizard's progress
   *
   * This component is not shown during the 'success' step
   */
  SummaryComponent?: React.ReactNode;
};

const WizardPageFooter = ({ actionToolbarControl, leftControls, rightControls, SummaryComponent }: WizardPageFooterProps) => {
  const actionAndLeftControls = typeof actionToolbarControl !== 'undefined' ? [actionToolbarControl] : [];

  return (
    <React.Fragment>
      <LayoutFooter
        leftControls={[...actionAndLeftControls, ...(typeof leftControls !== 'undefined' ? leftControls : [])]}
        rightControls={rightControls}
        layoutBarComponent={
          typeof SummaryComponent !== 'undefined' && <Testable testId={'wizard-summary-component'}>{SummaryComponent}</Testable>
        }
      />
    </React.Fragment>
  );
};

export default WizardPageFooter;
