import { ActivitySearchResult } from '@BeastClient/Activity/Search/Model/Query.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import { Styleable } from '~/neo-ui/model/capacity';
import Button from '~/neo-ui/packages/button/Button';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import ActivityLogEntryHeader from '~/wm/packages/activity/packages/activity-log/packages/activity-log-entry/packages/activity-log-entry-header/ActivityLogEntryHeader';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ActivityLogEntryCardProps = {
  entry: ActivitySearchResult;
  onClick?: () => void;
  disableCallToAction?: boolean;
} & Styleable;

const ActivityLogEntryCard: React.FunctionComponent<ActivityLogEntryCardProps> = ({
  entry,
  onClick,
  className,
  disableCallToAction = false,
}) => {
  const ctaLink: AnchorLocation = {
    href: entry.contentInfo.callToAction.url,
  };

  return (
    <Card
      onClick={onClick}
      shadow="sm"
      className={className}
    >
      <ActivityLogEntryHeader
        css={css`
          margin-bottom: 0.9375rem;
        `}
        entry={entry}
      />
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding: 0.3125rem;
          @media (max-width: 650px) {
            flex-wrap: wrap;
            .ctaButton {
              margin-top: 0.625rem;
            }
          }
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-right: 0.625rem;
          `}
        >
          <div
            css={css`
              width: 1.25rem;
              height: 1.25rem;
              margin-right: 0.625rem;
            `}
          >
            <Icon
              icon={entry.metaInfo.icon as IconType}
              color="dark-900"
              sizePx={20}
              css={css`
                margin-right: 0.9375rem;
              `}
            />
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <span
              css={css`
                font-size: 1.125rem;
              `}
            >
              {entry.contentInfo.title}
            </span>
            <span
              css={css`
                font-size: 0.875rem;
                color: ${colorToCode('dark-200')};
              `}
            >
              {entry.contentInfo.details}
            </span>
          </div>
        </div>

        {!disableCallToAction && (
          <Button
            iconRight="GoRight"
            size="md"
            anchor={ctaLink}
            className="ctaButton"
            // Using the call-to-action button should not
            // fire parent's onclick (the button was pressed, not the card).
            preventOnClickPropagation={true}
          >
            {entry.contentInfo.callToAction.label}
          </Button>
        )}
      </div>
    </Card>
  );
};

export default ActivityLogEntryCard;
