import React from 'react';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import useAutoEmailReportEmailTemplateContext from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/context/hooks/useAutoEmailReportEmailTemplateContext';

import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';

const AutoEmailReportEmailTemplateVariableSection = () => {
  const { emailTemplateVariableAvailabilities } = useAutoEmailReportEmailTemplateContext();

  return (
    <LayoutSection
      title={'Template Variables'}
      titleSize={3}
      description={`The following variables will be substituted for the appropriate value when the email is sent.`}
      descriptionColor={'dark-900'}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        `}
      >
        {emailTemplateVariableAvailabilities.map(emailTemplateVariable => (
          <div
            key={emailTemplateVariable.substitutionTag}
            css={css`
              display: flex;
              flex-direction: row;
              gap: 0.25rem;
            `}
          >
            <Label
              color={'negative-400'}
              css={css`
                width: 7.5rem;
              `}
            >
              {emailTemplateVariable.substitutionTag}
            </Label>
            <Label color={'dark-050'}>{emailTemplateVariable.description}</Label>
          </div>
        ))}
      </div>
    </LayoutSection>
  );
};

export default AutoEmailReportEmailTemplateVariableSection;
