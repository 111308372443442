import * as React from 'react';
import { css } from '@emotion/react';
import IntegrationSetupPageSubSectionHeader, {
  IntegrationSetupPageSubSectionHeaderProps,
} from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/page-layout/IntegrationSetupPageSubSectionHeader';

export type IntegrationSetupPageSubSectionProps = IntegrationSetupPageSubSectionHeaderProps & {
  iconDisplay?: React.ReactNode;
  buttonDisplay?: React.ReactNode;
  isExpanded?: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  headerMarginBottom?: '0.5rem' | '1rem' | '1.5rem' | '0rem';
};

export const IntegrationSetupPageSubSection: React.FunctionComponent<IntegrationSetupPageSubSectionProps> = ({
  title,
  description,
  descriptionColor,
  iconDisplay,
  buttonDisplay,
  children,
  onClick,
  headerMarginBottom,
}) => (
  <article
    css={css`
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
      align-items: center;
    `}
  >
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 0.5rem;
        `}
        onClick={onClick}
      >
        {iconDisplay}
        <IntegrationSetupPageSubSectionHeader
          title={title}
          description={description}
          descriptionColor={descriptionColor}
          css={css`
            margin-bottom: ${headerMarginBottom ?? '0rem'};
          `}
        />
        {buttonDisplay}
      </div>
      {children}
    </div>
  </article>
);
export default IntegrationSetupPageSubSection;
