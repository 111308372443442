import { Themeable } from '~/neo-ui/model/capacity';
import Badge from '~/neo-ui/packages/badge/Badge';
import Theme from '~/neo-ui/packages/color/Theme';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import FilterHeaderMenuDropdownIcon from '~/neo-ui/packages/filter/packages/filter-headers/filter-header-common/filter-header-dropdown-navigation-icon/FilterHeaderMenuDropdownIcon';
import React from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

type FilterPreviewConsoleSingleSelectProps = {
  selected?: SelectOption;
  theme: Theme;
} & Themeable;

const FilterHeaderPreviewForecast: React.FunctionComponent<FilterPreviewConsoleSingleSelectProps> = ({ selected, theme }) => {
  const { themeMap } = useTheme(theme);

  const forecastValueToBadge = (forecastOption: SelectOption): string => {
    if (forecastOption === undefined) {
      return '';
    }
    if (!isNaN(Number(forecastOption.value))) {
      return (forecastOption.value + ' days').toLocaleUpperCase();
    }
    return forecastOption.value.toUpperCase();
  };

  return (
    <div
      css={css`
        background-color: ${colorToCode(themeMap.foregroundAccent)};
        color: ${colorToCode('light-000')};
        padding-inline-start: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <Icon
        icon={'Forecast'}
        color={'light-900'}
        sizePx={16}
      />
      <div
        css={css`
          padding-left: 0.8rem;
          padding-right: 0.4375rem;
        `}
      >
        Forecast...
      </div>
      {selected?.value && (
        <Badge
          bgColor={'light-000'}
          textColor={themeMap.foregroundRaised}
          borderRadius={'radius200'}
        >
          <div
            css={css`
              padding-left: 0.1rem;
              padding-right: 0.1rem;
            `}
          >
            <Header size={6}>{forecastValueToBadge(selected)}</Header>
          </div>
        </Badge>
      )}
      <FilterHeaderMenuDropdownIcon color={'light-000'} />
    </div>
  );
};
export default FilterHeaderPreviewForecast;
