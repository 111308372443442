/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import { Formik, FormikConfig, FormikProps, FormikValues } from 'formik';
import { ComponentPropsWithRef, Context, FC, PropsWithChildren, ReactElement, createContext, useEffect } from 'react';
import { useContextOrThrow } from '~/extensions/packages/hooks/useContextOrThrow';

export interface MultiStepFormContextType<FormData> {
  formik: FormikProps<FormData>;
  currentStepIndex: number;
  goNext: () => void;
  goPrevious: () => void;
  goToPage: (index: number) => void;
}

export interface MultiStepFormProps<FormData> extends FormikConfig<FormData> {
  stepIndex: number;
  steps: (ReactElement | null)[];
  onNextHandler: () => void;
  onPreviousHandler?: () => void;
  onGoToPageHandler?: (index: number) => void;
  formProps?: Omit<ComponentPropsWithRef<'form'>, 'className'>;
  className?: string;
  Container: FC<
    PropsWithChildren<{
      context: MultiStepFormContextType<FormData>;
    }>
  >;
}

const multiStepFormContext = createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MultiStepFormContextType<any> | undefined
>(undefined);

const emptyFunc = () => undefined;

/**
 * Thin wrapper around formik to support multi stem forms
 * @param props
 * @returns
 */
const MultiStepForm = <FormData extends unknown>(props: MultiStepFormProps<FormData>) => {
  const { onNextHandler, onPreviousHandler, onGoToPageHandler, steps, stepIndex, formProps, className, Container, ...rest } =
    props as unknown as MultiStepFormProps<FormikValues>;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stepIndex]);

  return (
    <Formik {...rest}>
      {formik => (
        <multiStepFormContext.Provider
          value={{
            formik,
            currentStepIndex: stepIndex,
            goNext: onNextHandler,
            goPrevious: onPreviousHandler ?? emptyFunc,
            goToPage: onGoToPageHandler ?? emptyFunc,
          }}
        >
          <form
            className={className}
            {...formProps}
            onSubmit={formik.handleSubmit}
            onReset={formik.handleReset}
          >
            <multiStepFormContext.Consumer>
              {context => context && <Container context={context}>{steps[stepIndex]}</Container>}
            </multiStepFormContext.Consumer>
          </form>
        </multiStepFormContext.Provider>
      )}
    </Formik>
  );
};

export const useMultiStepForm = <FormData extends unknown>() =>
  useContextOrThrow<MultiStepFormContextType<FormData>>(multiStepFormContext as Context<MultiStepFormContextType<FormData>>);

export default MultiStepForm;
