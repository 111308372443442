import * as React from 'react';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';

export type ConsoleRowCheckboxHeaderProps = {
  allCurrentIds: string[];
  selectedAssetIds: Set<string>;
  onSelect: (items: string[], itemClicked: string | undefined) => void;
  onDeselect: () => void;
};

const ConsoleRowCheckboxHeader: React.FunctionComponent<ConsoleRowCheckboxHeaderProps> = ({
  allCurrentIds,
  selectedAssetIds,
  onSelect,
  onDeselect,
}) => {
  const [isSelected, setIsSelected] = React.useState(false);
  React.useEffect(
    () => setIsSelected(allCurrentIds.length !== 0 && selectedAssetIds.size >= allCurrentIds.length),
    [allCurrentIds.length, selectedAssetIds],
  );

  const isIndeterminate = selectedAssetIds.size > 0 && selectedAssetIds.size < allCurrentIds.length;

  return (
    <InputCheckbox
      checked={isSelected}
      onClick={() => (isSelected || isIndeterminate ? onDeselect() : onSelect(allCurrentIds, allCurrentIds[0]))}
      indeterminate={isIndeterminate}
    />
  );
};

export default ConsoleRowCheckboxHeader;
