// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


/**
 * How device with blank serials number should be treated
 */
export enum BlankSerialNumberBehavior
{
  /**
   * All devices with blank serials number will be skipped
   */
  Skipped = "Skipped",
  /**
   * All devices with blank serials number will be synced
   */
  Synced = "Synced",
  /**
   * User determine if they want to get devices with blank serial number synced or not
   */
  DefinedByUser = "DefinedByUser",
}


