// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


export enum Enum
{
  Starting = "Starting",
  InProgress = "InProgress",
  Done = "Done",
}



/**
 * A generic progress concept
 */
export interface Progress
{
  /**
   * Date the information was updated
   */
  updatedAt: string;
  /**
   * Progress percentage
   */
  percentage: number;
  /**
   * Informative message related to the progress
   */
  message: string;
}


/**
 * Error related to a progress
 */
export interface ProgressError
{

}

export declare namespace ProgressResponseNested {


  export interface Starting
  {

    type: Enum.Starting;


  }

  export interface InProgress
  {

    type: Enum.InProgress;

    progress: Progress;
  }

  export interface Done
  {

    type: Enum.Done;


  }
}


/**
 * Progress of an action
 */
export type ProgressResponse =
    ProgressResponseNested.Starting
  | ProgressResponseNested.InProgress
  | ProgressResponseNested.Done;

