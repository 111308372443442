import { PaginationPayload } from '@AssetManagementClient/BeastClient/Primitives/Query/Pagination.gen';
import { FieldKey } from '@AssetManagementClient/Primitives/Query.gen';
import { ConsoleState } from '~/neo-ui/packages/table/packages/console/types';
import { SortingOrder } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { OrderEnum } from '@AssetManagementClient/BeastClient/Primitives/Query/Sorting/OrderNested.gen';

export type ConsoleSearch = {
  columns: FieldKey[];
  value: string;
};

export type Order = {
  field: FieldKey;
  ordering: OrderEnum;
};

export type ConsoleRequest = {
  parameters: { [key: string]: { [key: string]: string[] } };
  pagination: PaginationPayload;
  sort: Order[];
  search: ConsoleSearch | undefined;
};

export const buildOrdering = (sortOrder: SortingOrder): OrderEnum => {
  switch (sortOrder) {
    case 'ascending':
      return OrderEnum.Ascending;
    case 'descending':
      return OrderEnum.Descending;
  }
};

const buildConsoleRequest = (consoleState: ConsoleState): ConsoleRequest => ({
  parameters: Object.fromEntries(Array.from(consoleState.parameters).map(([key, value]) => [key, Object.fromEntries(value)])),
  pagination: {
    // empty pagination payload
    pageNumber: consoleState.pagination.pageNumber,
    size: consoleState.pagination.perPageSize,
    pageId: '',
  },
  sort:
    typeof consoleState.sort === 'undefined'
      ? []
      : [
          {
            field: consoleState.sort.key,
            ordering: buildOrdering(consoleState.sort.order),
          },
        ],
  search: consoleState.search,
});
export default buildConsoleRequest;
