import { css } from '@emotion/react';
import * as React from 'react';

/**
 * Disables the component and the space after it.
 * Practically only necessary for use in React-in-PHP
 * @param children
 * @constructor
 */
const DisableAfterElementOverlay: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => (
  <div
    css={css`
      &:before {
        content: ' ';
        background: #f8f8fc6b;
        position: absolute;
        height: 150%;
        left: 0;
        right: 0;
        z-index: 1000;
      }
    `}
  >
    {children}
  </div>
);

export default DisableAfterElementOverlay;
