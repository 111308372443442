import { organizationEngagementNoteUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { EngagementNoteUpdatePayload } from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/packages/engagement-note-edit-form/EngagementNoteEditForm';
import useEngagementNotePanelTabMutation, {
  UseOrganizationEngagementNotePanelTabMutationOptions,
} from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/context/hooks/useEngagementNotePanelTabMutation';

export type UseEngagementNoteDashboardUpdate = {
  updateEngagementNote: (updatePayload: EngagementNoteUpdatePayload) => void;
  isUpdatingNote: boolean;
};

const useEngagementNoteDashboardUpdate = (
  options?: UseOrganizationEngagementNotePanelTabMutationOptions,
): UseEngagementNoteDashboardUpdate => {
  const [updateEngagementNote, isUpdatingNote] = useEngagementNotePanelTabMutation(
    (updatePayload: EngagementNoteUpdatePayload) =>
      organizationEngagementNoteUpdate({
        updatePayload: {
          engagementNoteId: updatePayload.engagementNoteId,
          title: updatePayload.title,
          description: updatePayload.description,
        },
      }),
    options,
  );

  return {
    updateEngagementNote,
    isUpdatingNote,
  };
};

export default useEngagementNoteDashboardUpdate;
