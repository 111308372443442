import { CSSInterpolation } from '@emotion/css';
import { css } from '@emotion/react';
import {
  FloatingFocusManager,
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTransitionStyles,
  useTypeahead,
} from '@floating-ui/react';
import { ComponentPropsWithoutRef, FocusEventHandler, MouseEventHandler, useId, useMemo, useRef, useState } from 'react';
import ChevronDownIcon from '~/sp-ui/icons/ChevronDownIcon';
import ErrorIcon from '../../icons/ErrorIcon';
import theme from './../../theme';

export interface DropdownOption {
  label: string;
  value: string;
}

export interface DropdownProps extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange' | 'onBlur' | 'onFocus'> {
  name?: string;
  label?: string;
  onChange?: (option: DropdownOption) => void | Promise<void>;
  onBlur?: FocusEventHandler<HTMLElement>;
  onFocus?: FocusEventHandler<HTMLElement>;
  onClick?: MouseEventHandler;
  value?: string;
  infoSubtext?: string;
  errorSubtext?: string;
  disabled?: boolean;
  readonly?: boolean;
  options: DropdownOption[];
  placeholder?: string;
  compact?: boolean;
}

/**
 * Based on https://codesandbox.io/p/sandbox/gallant-sea-rcg43b
 * https://floating-ui.com/docs/react-examples
 */
const Dropdown = (props: DropdownProps) => {
  const {
    name,
    label,
    onChange,
    onBlur,
    onFocus,
    value = '',
    disabled = false,
    readonly = false,
    infoSubtext,
    errorSubtext,
    id,
    onClick,
    options,
    placeholder,
    compact = false,
    ...rest
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const generatedId = useId();
  const componentId = `dropdown-${id ?? generatedId}`;
  const optionsWithPlaceholder = useMemo(() => [{ label: placeholder ?? '', value: '' }, ...options], [options, placeholder]);
  const labelText = useMemo(() => (compact ? label || null : label || '\u00A0'), [label, compact]);
  const subtext = useMemo(() => {
    const text = errorSubtext || infoSubtext;
    return compact ? text || null : text || '\u00A0';
  }, [infoSubtext, errorSubtext, compact]);

  const { refs, floatingStyles, context } = useFloating<HTMLElement>({
    placement: 'bottom-start',
    open: isExpanded,
    onOpenChange: open => {
      if (disabled || readonly) {
        return;
      }
      setIsExpanded(open);
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = useRef<(HTMLElement | null)[]>([]);
  const listContentRef = useRef(optionsWithPlaceholder.map(o => o.label));
  const isTypingRef = useRef(false);

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    loop: true,
  });
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: isExpanded ? setActiveIndex : setSelectedIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping;
    },
  });

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: 200,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([dismiss, role, listNav, typeahead, click]);

  const handleSelect = (index: number) => {
    if (disabled || readonly) {
      return;
    }

    setSelectedIndex(index);
    setIsExpanded(false);
    onChange?.(optionsWithPlaceholder[index]);
  };

  return (
    <div
      id={componentId}
      css={{
        display: 'flex',
        flexDirection: 'column',
      }}
      {...rest}
    >
      <label
        id={`${componentId}-label`}
        htmlFor={`${componentId}-trigger`}
        onClick={() => refs.domReference.current?.focus()}
        css={[{ all: 'unset' }, theme.typography.body]}
      >
        {labelText}
      </label>
      <div
        ref={refs.setReference}
        css={[
          {
            all: 'unset',
            borderRadius: '4px',
            border: `1px solid ${theme.palette.grey2}`,
            backgroundColor: theme.palette.white,
            padding: '8px 9px',
            display: 'flex',
            alignItems: 'center',
            ['&:focus']: {
              borderColor: 'transparent',
              outline: `2px solid ${theme.palette.primary1}`,
            },
            ['&:hover']: {
              cursor: 'pointer',
            },
          },
          theme.typography.body,
          { color: theme.palette.black },
          errorSubtext && {
            backgroundColor: theme.palette.error2,
            borderColor: theme.palette.error1,
            boxShadow: '0px 0px 4px 2px rgba(215, 42, 71, 0.25)',
            ['&:focus']: {
              outline: 'unset',
              borderColor: theme.palette.error1,
            },
          },
          disabled && {
            backgroundColor: theme.palette.grey4,
            borderColor: theme.palette.grey3,
            color: theme.palette.grey2,
            boxShadow: 'none',
            pointerEvents: 'none',
          },
        ]}
        tabIndex={0}
        role="combobox"
        aria-controls={`${componentId}-listbox`}
        aria-expanded={isExpanded ? 'true' : 'false'}
        aria-haspopup="listbox"
        aria-labelledby={`${componentId}-label`}
        {...getReferenceProps({
          id: `${componentId}-trigger`,
          onBlur,
          onFocus,
          title: name,
          name,
          onClick: e => onClick?.(e),
        })}
      >
        <span
          css={[
            { flex: 1 },
            value === '' && {
              color: `${theme.palette.grey2} !important`,
            },
          ]}
        >
          {optionsWithPlaceholder.find(option => option.value === value)?.label}
        </span>
        <ChevronDownIcon css={[{ flexShrink: 0, transition: 'transform 200ms linear' }, isExpanded && { transform: 'rotate(180deg)' }]} />
      </div>
      <span
        id={`${componentId}-subtext`}
        css={[
          { marginTop: '8px', display: 'flex', alignItems: 'start' },
          subtext && { marginTop: 8 },
          compact && !subtext && { marginTop: 'unset' },
          theme.typography.small,
          infoSubtext && {
            color: theme.palette.grey1,
          },
          errorSubtext && {
            color: theme.palette.error1,
          },
          disabled && {
            color: theme.palette.grey2,
          },
        ]}
      >
        {errorSubtext && <ErrorIcon css={{ width: 16, height: 16, flexShrink: 0, marginRight: 4 }} />}
        {subtext}
      </span>

      {isMounted && (
        <FloatingPortal>
          <FloatingFocusManager
            context={context}
            modal={false}
          >
            <div
              ref={refs.setFloating}
              css={[
                css(transitionStyles as CSSInterpolation),
                css(floatingStyles as CSSInterpolation),
                {
                  ['&:focus']: {
                    outline: 'none',
                  },
                },
              ]}
              {...getFloatingProps({
                id: `${componentId}-listbox`,
              })}
            >
              <div
                css={[
                  {
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: theme.palette.white,
                    borderRadius: 4,
                    maxHeight: '500px',
                    overflowY: 'auto',
                  },
                  theme.shadows[2],
                ]}
              >
                {optionsWithPlaceholder.map((option, index) => (
                  <div
                    key={`${option.value}-${index}`}
                    ref={node => {
                      listRef.current[index] = node;
                    }}
                    role="option"
                    tabIndex={index === activeIndex ? 0 : -1}
                    aria-selected={index === selectedIndex && index === activeIndex}
                    css={[
                      theme.typography.body,
                      {
                        paddingInline: 16,
                        paddingBlock: 8,
                        ['&:focus']: {
                          backgroundColor: theme.palette.grey3,
                          outline: 'none',
                        },
                      },
                      index === 0 && { color: theme.palette.grey2 },
                    ]}
                    {...getItemProps({
                      id: `${componentId}-option-${index}`,
                      // Handle pointer select.
                      onClick() {
                        handleSelect(index);
                      },
                      // Handle keyboard select.
                      onKeyDown(event) {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSelect(index);
                        }

                        if (event.key === ' ' && !isTypingRef.current) {
                          event.preventDefault();
                          handleSelect(index);
                        }
                      },
                    })}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
};

export default Dropdown;
