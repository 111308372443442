import React from 'react';
import { MultiSelectOption } from '~/neo-ui/packages/select/packages/multi-select/MultiSelect';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import SelectGroup, { ButtonSelectOption } from '~/neo-ui/packages/select/packages/select-group/SelectGroup';
import { Styleable } from '~/neo-ui/model/capacity';
import { css } from '@emotion/react';
import Theme from '~/neo-ui/packages/color/Theme';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import MultiSelectStatic, { MultiSelectStaticSelection } from '~/neo-ui/packages/select/packages/multi-select-static/MultiSelectStatic';
import { FilterDisplayOption, FilterOperatorDisplayOption } from '@AssetManagementClient/BeastClient/Search/Model/Filter.gen';

export type SingleOperatorMultiSelectProps = {
  /**
   * The key is listed here only for the purposes of testing. Note that if information is passed through the key prop
   * it won't be available to this component. The workaround is to pass the same value in keyCopy.
   * See https://reactjs.org/warnings/special-props.html
   */
  key: string;
  keyCopy: string;
  /**
   * Display
   */
  label: string;
  width?: string;
  theme?: Theme;

  /**
   * Values
   */
  operatorOptions: ButtonSelectOption[];
  allValueOptions: MultiSelectOption[];
  selectedOperatorOption: FilterOperatorDisplayOption | undefined; // external control of selected options
  selectedValueOptions: FilterDisplayOption[];
  allSelectionTypes?: string[];
  selectionType?: string; // external control of selection type (any or all)

  /**
   * Actions
   */
  // optional parent callback for responding to selection changes
  onChange?: (operator: string, selection: MultiSelectStaticSelection<string>) => void;
} & Styleable;

const SingleOperatorMultiSelect: React.FunctionComponent<SingleOperatorMultiSelectProps> = ({
  keyCopy,
  label,
  width,
  theme,
  operatorOptions,
  allValueOptions,
  selectedOperatorOption,
  selectedValueOptions,
  allSelectionTypes,
  selectionType,
  onChange,
  className,
}) => {
  const [internalSelectedOperator, setInternalSelectedOperator] = React.useState<string>(
    selectedOperatorOption?.filterValue.key ?? operatorOptions[0].value,
  );
  const [internalSelectedValues, setInternalSelectedValues] = React.useState<string[]>(
    selectedValueOptions.map(displayOption => displayOption.filterValue.label),
  );
  const [internalSelectionType, setInternalSelectionType] = React.useState<string | undefined>(selectionType ?? allSelectionTypes?.[0]);

  /**
   * OnChange functions for each multiselect section and toggle section
   */
  const onChangeMultiSelectSelection = React.useCallback(
    (multiSelectSelection: MultiSelectStaticSelection<string>) => {
      setInternalSelectedValues(selectedValueOptions.map(displayOption => displayOption.filterValue.label));
      setInternalSelectionType(multiSelectSelection.matchType);

      if (onChange && multiSelectSelection.selectedOptions !== undefined) {
        onChange(internalSelectedOperator, {
          selectedOptions: multiSelectSelection.selectedOptions,
          matchType: multiSelectSelection.matchType,
        });
      }
    },
    [internalSelectedOperator, onChange, selectedValueOptions],
  );

  const onChangeIsOrIsNotSelection = (operator: string) => {
    setInternalSelectedOperator(operator);

    if (onChange && operator !== internalSelectedOperator) {
      onChange(operator, {
        selectedOptions: internalSelectedValues,
        matchType: internalSelectionType,
      });
    }
  };

  React.useEffect(() => {
    const operatorSelected = selectedOperatorOption?.filterValue.label;
    if (typeof operatorSelected === 'undefined' || operatorSelected === internalSelectedOperator) {
      return;
    }
    setInternalSelectedOperator(operatorSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOperatorOption]);

  React.useEffect(() => {
    setInternalSelectedValues(selectedValueOptions.map(displayOption => displayOption.filterValue.label));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValueOptions]);

  React.useEffect(() => {
    if (selectedOperatorOption?.filterValue.label !== internalSelectedOperator) {
      onChangeIsOrIsNotSelection(internalSelectedOperator);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOperatorOption]);

  return (
    <div className={className}>
      <div>
        <Label
          size={'lg'}
          css={css`
            padding: 0.625rem;
          `}
          bold={true}
        >
          {label}
        </Label>
        <SelectGroup
          css={css`
            width: ${width ?? '100%'};
            padding: 0.625rem;
          `}
          shouldExpand={true}
          options={operatorOptions}
          selectedOptionValue={internalSelectedOperator}
          onOptionSelected={(selectedOption: ButtonSelectOption) => onChangeIsOrIsNotSelection(selectedOption.value)}
        />
        <MultiSelectStatic
          key={`${keyCopy}.value`}
          theme={theme}
          allOptions={allValueOptions}
          selectedOptions={selectedValueOptions.map(
            option =>
              ({
                value: option.filterValue.key,
                label: option.filterValue.label,
                prependIcon: option.iconName,
              } as SelectOption),
          )}
          onChange={onChangeMultiSelectSelection}
          allSelectionTypes={allSelectionTypes}
          selectionType={selectionType}
        />
      </div>
    </div>
  );
};

export default SingleOperatorMultiSelect;
