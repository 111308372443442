import { css, SerializedStyles } from '@emotion/react';
import React, { useCallback, useState } from 'react';
import { Styleable } from '../../model/capacity';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import useClickAwayListener from '~/wm/packages/sp-account/packages/app-switcher/useClickAwayListener';

export type TooltipProps = Styleable & {
  backgroundColor?: Color;

  /**
   * Default tooltip content placement
   */
  placement?: Placement;

  /**
   * If set, overrides whether the tooltip is active or not
   */
  isActive?: boolean;

  /**
   * Customize content of the tooltip
   */
  content: React.ReactNode;

  /**
   * @deprecated DO NOT USE. Use `css` instead. `tooltipCss` applies to the regular css classname and is unnecessary
   * CSS for the tooltip
   */
  tooltipCss?: SerializedStyles;

  children: React.ReactNode;
};

/**
 * Tooltip displays additional optional info to the user when a child component is moused over.
 *
 */
const Tooltip = ({
  className,
  placement = 'auto',
  backgroundColor = 'dark-300',
  isActive: isActiveOverride,
  content,
  tooltipCss,
  children,
}: TooltipProps) => {
  const [isActiveState, setActive] = React.useState(false);
  const isActive = typeof isActiveOverride !== 'undefined' ? isActiveOverride : isActiveState;

  const bgColor = backgroundColor;
  const isLightContext = backgroundColor === 'light-000';

  const [triggerElement, setTriggerElement] = useState<HTMLDivElement | null>(null);
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes, update } = usePopper(triggerElement, containerElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 12],
        },
      },
    ],
  });

  const triggerClickHandler = useCallback(async () => {
    setActive(prev => !prev);
    await update?.();
  }, [update]);

  const clickAwayHandler = useCallback(async () => {
    setActive(false);
    await update?.();
  }, [update]);

  useClickAwayListener(clickAwayHandler, triggerElement, containerElement);

  return (
    <React.Fragment>
      <div
        onClick={triggerClickHandler}
        ref={setTriggerElement}
        className={'tooltip-target'}
        onMouseEnter={isActiveOverride ? undefined : () => setActive(true)}
        onMouseLeave={isActiveOverride ? undefined : () => setActive(false)}
      >
        {children}
      </div>
      <div
        ref={setContainerElement}
        style={styles.popper}
        className={className}
        {...attributes.popper}
        css={[
          tooltipCss,
          css`
            z-index: 1040;
            padding: 0.5rem 0.8125rem;
            background-color: ${colorToCode(bgColor)};
            white-space: unset;
            border-radius: 0.625rem;
            transition: transform 200ms, visibility 200ms, opacity 200ms;
            visibility: ${isActive ? 'visible' : 'hidden'};
            opacity: ${isActive ? '1' : '0'};
            transform: ${isActive ? 'scale(1)' : 'scale(0)'};
            ${isLightContext
              ? `box-shadow: 0 12px 32px 0 rgba(0,0,0,0.2); color: ${colorToCode('dark-900')};`
              : `box-shadow: 0px 0px 12px -5px ${colorToCode(bgColor)}; color: ${colorToCode('light-000')};`}
          `,
        ]}
      >
        {content}
      </div>
    </React.Fragment>
  );
};

export default Tooltip;
