import useApi from '~/wm/packages/api/hook/useApi';
import { useCallback, useState } from 'react';
import { authenticationSettingsEnforceSsoUpdate } from '@BeastClient/BeastClientMsp.gen';

export type UseAccountAuthenticationEnforceSsoUpdate = {
  updateEnforceSso: (enforceSso: boolean) => Promise<void>;
  isUpdating: boolean;
};

const useAccountAuthenticationEnforceSsoUpdate = (): UseAccountAuthenticationEnforceSsoUpdate => {
  const { callApi } = useApi();
  const [isLoading, setLoading] = useState(false);

  const updateEnforceSso = useCallback(
    async (enforceSso: boolean) => {
      setLoading(true);

      const response = await callApi(() => authenticationSettingsEnforceSsoUpdate({ enforceSso }));

      if (!response) {
        setLoading(false);
        return;
      }

      setLoading(false);
    },
    [callApi],
  );

  return {
    updateEnforceSso,
    isUpdating: isLoading,
  };
};

export default useAccountAuthenticationEnforceSsoUpdate;
