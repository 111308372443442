// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


/**
 * 2019-11-12 Note to anyone adding or removing permissions:
 * Until there is a system in place to dynamically update custom fields in CRMs (eg CloseIO, HubSpot),
 * it is critical that someone with admin credentials is alerted when permissions are added or removed from here.
 * {CBF6B09B-0ADB-43D7-B6DA-BE17FD183193}
 * 
 * Enum is duplicated in frontend A90DF74F-D5BE-48C2-A16D-823469028FA8
 */
export enum Enum
{
  AccountAdmin = "AccountAdmin",
  ManageAppSettings = "ManageAppSettings",
  ManageBilling = "ManageBilling",
  PlaceOrders = "PlaceOrders",
  ManageIntegration = "ManageIntegration",
  ImpersonateUser = "ImpersonateUser",
  ManageFeatures = "ManageFeatures",
  DevOps = "DevOps",
  ManageProcurement = "ManageProcurement",
  ManageSubscription = "ManageSubscription",
  Finance = "Finance",
  GenerateSatisfactionCoupon = "GenerateSatisfactionCoupon",
  GenerateIrIncentiveCoupon = "GenerateIrIncentiveCoupon",
  Success = "Success",
  ManageSales = "ManageSales",
  PerformanceExecutive = "PerformanceExecutive",
  PerformanceDashboard = "PerformanceDashboard",
  MarketingOperations = "MarketingOperations",
  GenerateDisposalIncentiveCoupon = "GenerateDisposalIncentiveCoupon",
  ManagePricing = "ManagePricing",
  ManageOrder = "ManageOrder",
  ManageOption = "ManageOption",
  HandleSupport = "HandleSupport",
  ManageDisposalOrder = "ManageDisposalOrder",
}


