import * as React from 'react';
import ContractListMutationContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/context/ContractListMutationContext';
import ContractListContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/context/ContractListContext';
import { ContractAvailabilitiesDto, ContractSummaryDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Dto/Model.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import { contractAvailabilitiesGet, contractList } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { ContractBillingCurrencyDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Billing/Dto/Model.gen';

export type ContractListProviderProps = {
  organizationId: string;
  organizationName: string;
  children: React.ReactNode;
};

const ContractListProvider = ({ organizationId, organizationName, children }: ContractListProviderProps) => {
  const [contracts, setContracts] = React.useState<ContractSummaryDto[] | undefined>(undefined);
  const [contractAvailabilities, setContractAvailabilities] = React.useState<ContractAvailabilitiesDto | undefined>(undefined);
  const [contractBillingCurrency, setContractBillingCurrency] = React.useState<ContractBillingCurrencyDto | undefined>(undefined);

  const { callApi } = useApi();

  // Get contract list
  const reload = React.useCallback(
    async (isRequestActive?: () => boolean) => {
      const contractListresponse = await callApi(() => contractList({ organizationId }));

      const contractAvailabilitiesResponse = await callApi(() => contractAvailabilitiesGet({ organizationId }));

      if (!contractListresponse || !contractAvailabilitiesResponse) {
        return;
      }

      // if a function is provided to determine whether to propagate
      // a state update (to prevent race conditions), then it will
      // ensure not to update state. otherwise, each call updates state.
      if (!isRequestActive || isRequestActive()) {
        setContracts(contractListresponse.contractList);
        setContractAvailabilities(contractAvailabilitiesResponse.contractAvailabilities);
        setContractBillingCurrency(contractAvailabilitiesResponse.contractBillingCurrency);
      }
    },
    [callApi, organizationId],
  );

  // Load contract list
  React.useEffect(() => {
    reload();
  }, [reload]);

  if (!contracts || !contractAvailabilities || !contractBillingCurrency) {
    return null;
  }

  return (
    <ContractListMutationContext.Provider
      value={{
        triggerContractListReload: isRequestActive => reload(isRequestActive),
      }}
    >
      <ContractListContext.Provider
        value={{
          organizationName,
          contracts,
          contractAvailabilities,
          contractBillingCurrency,
        }}
      >
        {children}
      </ContractListContext.Provider>
    </ContractListMutationContext.Provider>
  );
};

export default ContractListProvider;
