import Label from '~/neo-ui/packages/text/packages/label/Label';
import Box from '~/neo-ui/packages/box/Box';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';

export type ConsoleEmptyStateProps = {
  text?: string;
};

const ConsoleEmptyState = ({ text = 'No results matches these filters.' }: ConsoleEmptyStateProps) => (
  <Box
    css={css`
      padding: 1.25rem;
      display: flex;
      align-items: center;
    `}
    theme={'secondary'}
    background={'backgroundAccent'}
    borderRadius={'radius200'}
  >
    <Icon
      css={css`
        margin-right: 1rem;
      `}
      icon={'Info'}
      color={'secondary-400'}
      sizePx={60}
    />
    <div>
      <Label
        bold={true}
        size={'xlg'}
        color={'secondary-400'}
      >
        No results found
      </Label>
      <Label muted={true}>{text}</Label>
    </div>
  </Box>
);

export default ConsoleEmptyState;
