import { components, ValueContainerProps } from 'react-select';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import * as React from 'react';
import Theme from '~/neo-ui/packages/color/Theme';
import IconType from '~/neo-ui/packages/icon/IconType.gen';

export type SingleSelectPresentationValueContainerProps<T extends string = string> = {
  props: ValueContainerProps<SelectOption<T>, false>;
  selectRef: React.RefObject<HTMLDivElement>;
  icon: IconType;
  theme: Theme;
  disabled: boolean;
  label: string;
  onClick: () => void;
};

const SingleSelectPresentationValueContainer = <T extends string>({
  props,
  selectRef,
  icon,
  theme,
  disabled,
  label,
  onClick,
}: SingleSelectPresentationValueContainerProps<T>) => {
  const { ValueContainer } = components;

  return (
    <ValueContainer {...props}>
      <div ref={selectRef}>
        <ButtonLink
          theme={theme}
          disabled={disabled}
          iconLeft={icon}
          iconRight={'ArrowDown'}
          onClick={onClick}
          preventOnClickPropagation={true}
        >
          {label}
        </ButtonLink>
      </div>
    </ValueContainer>
  );
};

export default SingleSelectPresentationValueContainer;
