import { Response as AssetManagementClientAssetManagementHardwareSettingsControllerHardwareSettingsAvailabilitiesGetControllerNestedResponse } from '@AssetManagementClient/AssetManagement/Packages/HardwareSettings/Controller/HardwareSettingsAvailabilitiesGetControllerNested.gen';
import {
  hardwareReplacementReportAssetTypeSettingsAvailabilitiesGet,
  hardwareSettingsAvailabilitiesGet,
} from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { ScopeKey } from '@AssetManagementClient/BeastClient/Beast/Settings/Scope.gen';
import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import HardwareDueSoonThresholdConfigurable from '~/wm/packages/hardware/packages/settings/configurable/HardwareDueSoonThresholdConfigurable';
import HardwareLifecycleReportConfigurable from '~/wm/packages/hardware/packages/settings/configurable/HardwareLifecycleReportConfigurable';
import HardwareReplacementConfigurable from '~/wm/packages/hardware/packages/settings/configurable/HardwareReplacementConfigurable';
import SettingsSection from '~/wm/packages/settings/packages/settings-section/SettingsSection';
import { HardwareReplacementReportAssetTypeSettingsAvailabilitiesDto } from '@AssetManagementClient/BeastClient/Beast/HardwareReplacement/Dto/Model.gen';
import { Enum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import HardwareReplacementConfigurableV2 from '~/wm/packages/hardware/packages/settings/configurable/HardwareReplacementConfigurableV2';

export type HardwareSettingsProps = {
  scopeKey: ScopeKey;
  appSettingsUrl?: string;

  /**
   * Ideally would be passed down through context + hooks
   * e.g., `const { currency } = useRegionalSettings();`
   */
  currencyCharacter: string;
  hardwareConfigCriteriaReleased: boolean;
  lmBudgetForecastEnabled: boolean;
  lmLastCheckinDateEnabled: boolean;
};

export type HardwareSettingsAvailabilities = {
  oldAvailabilities: AssetManagementClientAssetManagementHardwareSettingsControllerHardwareSettingsAvailabilitiesGetControllerNestedResponse;
  replacementReportAssetTypeAvailabilities: HardwareReplacementReportAssetTypeSettingsAvailabilitiesDto | undefined;
};

const HardwareSettings: React.FunctionComponent<HardwareSettingsProps> = ({
  scopeKey,
  appSettingsUrl,
  currencyCharacter,
  hardwareConfigCriteriaReleased,
  lmBudgetForecastEnabled,
  lmLastCheckinDateEnabled,
}) => {
  const { callApi } = useApi();

  const [availabilities, setAvailabilities] = React.useState<HardwareSettingsAvailabilities>();

  React.useEffect(() => {
    (async () => {
      if (!lmBudgetForecastEnabled) {
        const response = await callApi(() => hardwareSettingsAvailabilitiesGet({}));
        if (!response) {
          return;
        }
        setAvailabilities({
          oldAvailabilities: response,
          replacementReportAssetTypeAvailabilities: undefined,
        });
      } else {
        const response = await callApi(() => hardwareSettingsAvailabilitiesGet({}));
        const response2 = await callApi(() =>
          hardwareReplacementReportAssetTypeSettingsAvailabilitiesGet({
            scope: scopeKey.id === 'Account' ? { type: Enum.Account } : { type: Enum.Organization, organizationId: scopeKey.value },
          }),
        );

        if (!response || !response2) {
          return;
        }
        setAvailabilities({
          oldAvailabilities: response,
          replacementReportAssetTypeAvailabilities: response2.hardwareReplacementReportAssetTypeSettingsAvailabilities,
        });
      }
    })();
  }, [callApi, lmBudgetForecastEnabled, scopeKey.id, scopeKey.value]);

  if (!availabilities || (lmBudgetForecastEnabled && !availabilities.replacementReportAssetTypeAvailabilities)) {
    return null;
  }

  return (
    <SettingsSection
      title="Hardware"
      titleButtonText={appSettingsUrl ? 'Hardware settings for all clients' : undefined}
      titleButtonIcon={appSettingsUrl ? 'Cog' : undefined}
      titleButtonLink={appSettingsUrl ? appSettingsUrl : undefined}
    >
      {!lmBudgetForecastEnabled && (
        <HardwareReplacementConfigurable
          availabilities={availabilities.oldAvailabilities.replacementSettingsAvailabilities}
          scopeKey={scopeKey}
          currencyCharacter={currencyCharacter}
          hardwareConfigCriteriaReleased={hardwareConfigCriteriaReleased}
        />
      )}
      {lmBudgetForecastEnabled && (
        <HardwareReplacementConfigurableV2
          availabilities={availabilities.replacementReportAssetTypeAvailabilities!}
          scopeKey={scopeKey}
          currencyCharacter={currencyCharacter}
        />
      )}
      <HardwareDueSoonThresholdConfigurable
        availabilities={availabilities.oldAvailabilities.dueSoonThresholdSettingsAvailabilities}
        scopeKey={scopeKey}
      />
      <HardwareLifecycleReportConfigurable
        availabilities={availabilities.oldAvailabilities.lifecycleReportSettingsAvailabilities}
        scopeKey={scopeKey}
        lmLastCheckinDateEnabled={lmLastCheckinDateEnabled}
      />
    </SettingsSection>
  );
};

export default HardwareSettings;
