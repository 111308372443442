import { useMemo } from 'react';
import { FrequencyEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Recurring/Model/RecurringLineItemNested.gen';
import { CostTypeEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Model/BudgetLineItemNested.gen';
import { formatCurrencyByCurrencyInfo } from '~/extensions/packages/currency/formatCurrency';
import { CurrencyInfo } from '~/wm/packages/settings/packages/regional-settings/context/RegionalSettingsInfoContext';
import { RecurringLineItemFormData } from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';

export type UseTotalCostRecurringFeesCalculatedProps = {
  currencyInfo: CurrencyInfo;
  amountOfAssets: number;
  recurringLineItems: RecurringLineItemFormData[];
};

const useTotalCostRecurringFeesCalculated = ({
  currencyInfo,
  amountOfAssets,
  recurringLineItems,
}: UseTotalCostRecurringFeesCalculatedProps) => {
  const formattedTotalCostRecurringFeesByFrequency = useMemo<Record<FrequencyEnum, string>>(() => {
    // Calculate totals of monthly and yearly recurring fees
    const totalCostRecurringFees = recurringLineItems.reduce<Record<FrequencyEnum, number>>(
      (record, line) => {
        const amount = isNaN(line.costUnits)
          ? 0
          : line.costType === CostTypeEnum.Fixed
          ? Number(line.costUnits)
          : Number(line.costUnits) * amountOfAssets;

        return {
          ...record,
          [line.frequency]: record[line.frequency] + amount,
        };
      },
      { Monthly: 0, Yearly: 0 },
    );

    const currency = {
      code: currencyInfo.countryCode,
      subunitsPerUnit: currencyInfo.subunitRatio,
    };

    // Format total as a currency
    return {
      Monthly: formatCurrencyByCurrencyInfo(totalCostRecurringFees.Monthly * currencyInfo.subunitRatio, currency),
      Yearly: formatCurrencyByCurrencyInfo(totalCostRecurringFees.Yearly * currencyInfo.subunitRatio, currency),
    };
  }, [amountOfAssets, currencyInfo.countryCode, currencyInfo.subunitRatio, recurringLineItems]);

  return { formattedTotalCostRecurringFeesByFrequency };
};

export default useTotalCostRecurringFeesCalculated;
