import React from 'react';
import { css } from '@emotion/react';
import { FieldValueMapDisplay } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';
import AnimationHeight from '~/neo-ui/packages/animation/packages/animation-height/AnimationHeight';
import SwapContentRow from '~/neo-ui/packages/mapping/packages/SwapContentRow';
import DropdownToggleIcon from '~/neo-ui/packages/icon/packages/DropdownToggleIcon';
import SwapContentCell from '~/neo-ui/packages/mapping/packages/packages/SwapContentCell';
import Icon from '~/neo-ui/packages/icon/Icon';
import { CreateSwapContentSelection } from '~/neo-ui/packages/mapping/SwapContent';

export type SwapContentRowNestedProps = {
  mainRow: FieldValueMapDisplay; // For the base parent row
  mainRowSectionKey: string;
  nestedRows: FieldValueMapDisplay[]; // Map from values
  rightContentSelection: CreateSwapContentSelection; // Map to values
  isChildOptionSelected: boolean;
  updateParentId: (value: string | null) => void;
  byChildKey: string;
};

const SwapContentRowNested: React.FunctionComponent<SwapContentRowNestedProps> = ({
  mainRow,
  mainRowSectionKey,
  nestedRows,
  rightContentSelection,
  isChildOptionSelected,
  updateParentId,
  byChildKey,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(isChildOptionSelected);

  const toggleExpand = () => {
    if (isExpanded) {
      // options will collapse
      updateParentId(null);
    } else {
      // options will expand
      updateParentId(byChildKey);
    }
    setIsExpanded(!isExpanded);
  };

  const leftRowContent = (
    <SwapContentCell
      onClick={toggleExpand}
      css={css`
        column-gap: 0.5rem;
      `}
    >
      <DropdownToggleIcon
        isExpanded={isExpanded}
        color={'primary-400'}
      />
      {mainRow.label}
    </SwapContentCell>
  );

  const rightRowContent = (
    <SwapContentCell>
      <Icon
        icon={'ArrowChosen'}
        color={'dark-900-32'}
      />
      {rightContentSelection(mainRowSectionKey, mainRow, !isExpanded, 'Mapped by product')}
    </SwapContentCell>
  );

  const nestedRowRemIndent = 1.5;
  return (
    <React.Fragment>
      {/* Nested row header*/}
      <SwapContentRow
        key={mainRow.id}
        leftContent={leftRowContent}
        rightContent={rightRowContent}
        rowStyles={css`
          width: 100%;
          ${isExpanded ? 'border-bottom: none;' : undefined}
        `}
      />

      <ul
        css={css`
          display: flex;
          flex-direction: column;
          overflow: hidden;

          // Remove list indentation
          padding: 0;
          list-style-type: none;
        `}
      >
        <AnimationHeight isExpanded={isExpanded}>
          {nestedRows.map(row => (
            <SwapContentRow
              key={row.id}
              leftContent={
                <SwapContentCell
                  styles={css`
                    margin-left: ${nestedRowRemIndent}rem;
                  `}
                >
                  {row.label}
                </SwapContentCell>
              }
              rightContent={
                <SwapContentCell
                  styles={css`
                    padding-left: 0;
                    margin-left: -${nestedRowRemIndent / 2}rem;
                  `}
                >
                  <Icon
                    icon={'ArrowChosen'}
                    color={'dark-900-32'}
                  />
                  {rightContentSelection(mainRow.childSectionKey!, row, isExpanded)}
                </SwapContentCell>
              }
            />
          ))}
        </AnimationHeight>
      </ul>
    </React.Fragment>
  );
};
export default SwapContentRowNested;
