import * as React from 'react';
import { css } from '@emotion/react';
import OrderPaymentCostModule from '~/wm/packages/disposal/wizard/section/packages/module/order-payment-cost-module/OrderPaymentCostModule';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { DisposalDynamicPricingDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Dto/Model.gen';
import OrderSummaryTermOfServiceModule from '~/wm/packages/disposal/wizard/section/packages/module/order-summary-term-of-service-module/OrderSummaryTermOfServiceModule';

export type OrderSummaryCostModuleProps<T> = {
  disposalTermsOfServiceUrl: string;
  termsOfServiceUrl: string;
  brandName: string;
  assetTypeCountsFieldKey: FieldKeyExpression<T>;
  couponFieldKey: FieldKeyExpression<T>;
  disposalDynamicPricing: DisposalDynamicPricingDto;
};

const OrderSummaryCostModule = <T,>({
  disposalTermsOfServiceUrl,
  termsOfServiceUrl,
  brandName,
  assetTypeCountsFieldKey,
  couponFieldKey,
  disposalDynamicPricing,
}: OrderSummaryCostModuleProps<T>) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    `}
  >
    <OrderPaymentCostModule
      assetTypeCountsFieldKey={assetTypeCountsFieldKey}
      couponFieldKey={couponFieldKey}
      disposalDynamicPricing={disposalDynamicPricing}
    />
    <OrderSummaryTermOfServiceModule
      disposalTermsOfServiceUrl={disposalTermsOfServiceUrl}
      termsOfServiceUrl={termsOfServiceUrl}
      brandName={brandName}
    />
  </div>
);

export default OrderSummaryCostModule;
