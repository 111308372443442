import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { Enum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';

const getInsightConsoleUrl = (scope: FrontendScope, insightId: string, insightUrl: string) => {
  switch (scope.type) {
    case Enum.Account:
      return insightUrl.replace('__INSIGHT_ENUM__', insightId);
    case Enum.Organization:
      return insightUrl.replace('__INSIGHT_ENUM__', insightId).replace('__ORGANIZATION_ID__', scope.organizationId);
    case Enum.Application:
      throw new Error('Case not supported.');
  }
};

export default getInsightConsoleUrl;
