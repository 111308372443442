import { css } from '@emotion/react';
import * as React from 'react';
import { ReactNode } from 'react';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { Themeable } from '~/neo-ui/model/capacity';

type Option<T extends { id: string | number }> = { option: T; label: string };

export type ComponentPaginationProps<T extends { id: string | number }> = {
  /**
   * Option the page starts at, the cursor
   */
  startingOption: T;
  /**
   * Number of pages shown at once, default 1
   */
  pageSize?: number;
  /**
   * Number of pages to jump per click, default 1
   */
  incrementSize?: number;
  /**
   * Each option available in the pages with display label and id
   */
  options: Option<T>[];
  /**
   * Fires when left or right direction is clicked, provides next value
   */
  onClick: (value: T) => void;
  children?: ReactNode;
} & Themeable;

/**
 *  Provides left and right navigation of an array of options
 *
 *  To be used as a basic pagination for a component
 */
const ComponentPagination = <T extends { id: string | number }>({
  startingOption,
  pageSize = 1,
  incrementSize = 1,
  options,
  onClick,
  children,
  theme,
}: ComponentPaginationProps<T>) => {
  const { themeMap } = useTheme(theme);
  const [currentValue, setCurrentValue] = React.useState(startingOption);

  React.useEffect(() => {
    setCurrentValue(startingOption);
  }, [startingOption]);

  const left: Option<T> | undefined = options[options.findIndex(option => option.option.id === currentValue.id) - 1];

  let right: Option<T> | undefined;
  const rightByPageSize = options[options.findIndex(option => option.option.id === currentValue.id) + pageSize];
  const rightByIncrementSize = options[options.findIndex(option => option.option.id === currentValue.id) + incrementSize];
  if (typeof rightByPageSize !== 'undefined' && typeof rightByIncrementSize !== 'undefined') {
    right = {
      label: rightByPageSize.label,
      option: rightByIncrementSize.option,
    };
  }

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <ButtonLink
        iconLeft={'ArrowLeft'}
        theme={theme}
        onClick={() => {
          if (typeof left !== 'undefined') {
            onClick(left.option);
            setCurrentValue(left.option);
          }
        }}
        disabled={typeof left === 'undefined'}
      >
        {left && `${left.label}`}
      </ButtonLink>
      <span
        css={css`
          margin-left: 0.625rem;
          margin-right: 0.625rem;
          color: ${colorToCode(themeMap.foregroundAccent)};
        `}
      >
        {typeof children === 'undefined' ? '•' : children}
      </span>
      <ButtonLink
        iconRight={'ArrowRight'}
        theme={theme}
        onClick={() => {
          if (typeof right !== 'undefined') {
            onClick(right.option);
            setCurrentValue(right.option);
          }
        }}
        disabled={typeof right === 'undefined'}
      >
        {right && `${right.label}`}
      </ButtonLink>
    </div>
  );
};

export default ComponentPagination;
