import * as React from 'react';
import { Fragment } from 'react';
import Button from '~/neo-ui/packages/button/Button';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import InitiativeDeleteModal from '~/wm/packages/strategy/packages/initiative/packages/initiative-delete/packages/initiative-delete-modal/InitiativeDeleteModal';

export type InitiativeDeleteButtonProps = {
  initiativeId: string;
  onDeleteSuccess?: () => void;
};

const InitiativeDeleteButton = ({ initiativeId, onDeleteSuccess }: InitiativeDeleteButtonProps) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <Fragment>
      <Tooltip
        content={'Remove all assets and permanently delete this Initiative'}
        placement={'left-start'}
      >
        <Button
          iconRight={'Trash'}
          theme={'negative'}
          onClick={() => setModalOpen(true)}
        >
          Delete
        </Button>
      </Tooltip>
      <InitiativeDeleteModal
        initiativeId={initiativeId}
        isOpen={isModalOpen}
        onDismiss={() => setModalOpen(false)}
        onDeleteSuccess={() => {
          if (typeof onDeleteSuccess !== 'undefined') {
            onDeleteSuccess();
          }
          setModalOpen(false);
        }}
        onDeleteEffect={{ type: 'reload-entity' }}
      />
    </Fragment>
  );
};

export default InitiativeDeleteButton;
