import { FrequencyEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Recurring/Model/RecurringLineItemNested.gen';
import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';
import { descriptionFieldKeyId } from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/TicketCreateForm';
import { InputTypeNested } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Credential.gen';

const formatTicketDescriptionInitiative = (
  executiveSummary: string,
  formattedTotalCost: string,
  totalCostRecurringFees: Record<FrequencyEnum, string>,
  fieldOptions: IntegrationFieldOptionDto[],
  createdByUserFullName: string,
): string => {
  const ticketDescriptionFieldOption = fieldOptions.find(option => option.key === descriptionFieldKeyId);
  const ticketDescriptionFieldOptionLength = ticketDescriptionFieldOption
    ? (ticketDescriptionFieldOption.inputType as InputTypeNested.Text).validation.maxLength
    : undefined;

  const buildDescription = (summary: string) => {
    let description = summary ? `${summary}\n\n` : '';

    description += `Estimated Investment: ${formattedTotalCost}\n\n`;
    description += `Estimated total monthly costs: ${totalCostRecurringFees.Monthly}\n\n`;
    description += `Estimated total annual costs: ${totalCostRecurringFees.Yearly}\n\n`;
    description += `Ticket generated from Lifecycle Manager by ${createdByUserFullName}`;

    return description;
  };

  let description = buildDescription(executiveSummary);

  if (typeof ticketDescriptionFieldOptionLength !== 'undefined' && description.length > ticketDescriptionFieldOptionLength) {
    const excessLength = description.length - ticketDescriptionFieldOptionLength;
    const truncatedSummary = executiveSummary.slice(0, executiveSummary.length - excessLength - 1) + '…';
    description = buildDescription(truncatedSummary);
  }

  return description;
};

export default formatTicketDescriptionInitiative;
