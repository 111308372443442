import * as React from 'react';
import { css } from '@emotion/react';
import { ToggleTileProps, ToggleTileType } from '~/neo-ui/packages/toggle/packages/toggle-tile/ToggleTileInternal';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { Styleable } from '~/neo-ui/model/capacity';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import ToggleTileVertical from '~/neo-ui/packages/toggle/packages/toggle-tile/packages/toggle-tile-vertical/ToggleTileVertical';

export type FormToggleTileVerticalGroupOption<TFormDataValue = string> = Omit<ToggleTileProps, 'isSelected' | 'onClick' | 'type'> & {
  value: TFormDataValue;
};

export type FormToggleTileVerticalGroupProps<TFormData, TFormDataValue> = {
  type: ToggleTileType;
  fieldKey: FieldKeyExpression<TFormData>;
  options: FormToggleTileVerticalGroupOption<TFormDataValue>[];
  onChange?: (value: TFormDataValue[] | TFormDataValue) => void;
} & Styleable;

const FormToggleTileVerticalGroup = <TFormData, TFormDataValue = string>({
  type,
  fieldKey,
  options,
  onChange,
}: FormToggleTileVerticalGroupProps<TFormData, TFormDataValue>) => {
  const { getFormInput, setFormInput } = useFormContext<TFormData>();
  const formValues = type === 'multi' ? getFormInput<TFormDataValue[]>(fieldKey).value : getFormInput<TFormDataValue>(fieldKey).value;
  return (
    <div
      css={css`
        display: flex;
        gap: 0.5rem;
      `}
    >
      {options.map((option, index) => {
        const isSelected = type === 'multi' ? (formValues as TFormDataValue[]).includes(option.value) : formValues === option.value;
        return (
          <ToggleTileVertical
            key={index}
            isSelected={isSelected}
            title={option.title}
            type={'multi'}
            icon={option.icon}
            tooltipContent={option.description}
            description={''}
            onClick={() => {
              if (type === 'multi') {
                const updatedFormValue = isSelected
                  ? (formValues as TFormDataValue[]).filter(formValue => formValue !== option.value)
                  : [...(formValues as TFormDataValue[]), option.value];
                setFormInput<TFormDataValue[]>(fieldKey, updatedFormValue);
                if (typeof onChange !== 'undefined') {
                  onChange(updatedFormValue);
                }
              } else {
                setFormInput<TFormDataValue>(fieldKey, option.value);
                if (typeof onChange !== 'undefined') {
                  onChange(option.value);
                }
              }
            }}
          />
        );
      })}
    </div>
  );
};

export default FormToggleTileVerticalGroup;
