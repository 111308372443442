import { RenderFilter } from '@AssetManagementClient/BeastClient/Search/Model/Query/Field/Filter/Render.gen';
import { css } from '@emotion/react';
import { produce } from 'immer';
import * as React from 'react';
import { Row } from 'react-table';
import Button from '~/neo-ui/packages/button/Button';
import Theme from '~/neo-ui/packages/color/Theme';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import SelectGroup from '~/neo-ui/packages/select/packages/select-group/SelectGroup';
import handleFilterChange from '~/neo-ui/packages/table/packages/filter-table/state/handleFilterChange';
import RenderFilterFormatter from '~/neo-ui/packages/table/packages/render-filter/RenderFilterFormatter';
import useUrlTableState from '~/neo-ui/packages/table/packages/url-routing/useUrlTableState';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import ShareProps from '~/wm/packages/insight-share/model/ShareProps';
import buildPageFilter from '~/wm/packages/insight-share/packages/insight-share-button/builder/buildPageFilter';
import InsightShareButton from '~/wm/packages/insight-share/packages/insight-share-button/InsightShareButton';
import DataTable, { DataTableClientPagination, DataTableColumn, PaginationMetadata, RowOptions, SortState } from '../data-table/DataTable';
import Spinner from '~/neo-ui/spinner/Spinner';

export type ConsoleFilters = { [index: string]: string[] };

export type FilterOption = SelectOption;

export type FilterLink = {
  filterValue: string;
  linkedFilterOptions: Map<string, Set<string>>;
};

export type Filter = {
  label: string;
  value: string;
  options: FilterOption[];
  appliesToContexts?: string[];
  linking?: FilterLink;
  order: number;
  render?: RenderFilter;
  defaultOptions?: string[];
};

export type SearchContext = {
  label: string;
  value: string;
  selectedTheme: Theme;
  isSortable: boolean;
  isSearchable: boolean;
};

export type Metadata = {
  filterSet: FilterSet;
  sortingSet: SortingSet;
  supportsGlobalSearch: boolean;
};
export type SortingSet = {
  sortableColumnKeys: string[];
};

export type FilterSet = {
  filters: Filter[];
  scopedFilters?: Filter[];
  searchContexts?: SearchContext[];
};
export type FilterState = {
  selectedFilters: Map<string, Set<string>>;
  selectedSearchContextValue?: string;
  availableFilters: AvailableFilters;
  searchQuery: string | undefined;
};

export type AvailableFilters = Map<string, Filter>;

export type PaginationState = {
  /**
   * Zero-indexed
   */
  pageNumber: number;

  /**
   * How many results should fit in a single page
   */
  perPageSize: number;
};
export type TableState<T> = {
  filters?: FilterState;
  pagination?: PaginationState;
  sort?: SortState<T>;
};
export type FilterTableProps<T extends object> = {
  filtersTitle?: string;
  filtersDescription?: string;

  dataTitle?: string | React.ReactNode;
  dataDescription?: string;
  data: T[] | 'loading';
  columns: DataTableColumn<T>[];

  metadata: Metadata | 'loading' | 'error';

  defaultTableState: TableState<T>;
  onTableStateChanged: (state: TableState<T>) => void;

  // Pagination
  paginationMetadata?: PaginationMetadata;
  enableClientPaginated?: DataTableClientPagination;

  EmptyStatePlaceholder: string | React.ComponentType;

  /**
   * Optional, used for share
   */
  share?: ShareProps;
  download?: (tableState: TableState<T>) => React.ReactNode;
  getRowOptions?: (row: Row<T>) => RowOptions;

  manualSort?: boolean;

  /**
   * Optional, used for pinned rows
   */
  pinnedData?: T[];
};
const FilterTable = <T extends object>({
  filtersTitle,
  filtersDescription,

  dataTitle,
  dataDescription,
  data,
  columns,
  metadata,

  defaultTableState,
  onTableStateChanged,

  paginationMetadata,

  EmptyStatePlaceholder,
  share,
  download,
  getRowOptions,

  enableClientPaginated = undefined,
  manualSort = false,
  pinnedData,
}: FilterTableProps<T>) => {
  const [consoleFilters, setConsoleFilters] = React.useState<ConsoleFilters>();

  const [tableState, setTableState] = React.useState<TableState<T>>(defaultTableState);

  React.useEffect(() => {
    onTableStateChanged(tableState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState]);

  const [defaultSearchQuery, setDefaultSearchQuery] = React.useState<string | undefined>(defaultTableState.filters?.searchQuery);

  // Adds url routing support
  useUrlTableState(
    setTableState,
    setDefaultSearchQuery,
    setConsoleFilters,
    metadata === 'loading' || metadata === 'error' ? undefined : metadata,
    defaultTableState,
    tableState,
  );

  const onPageChange = React.useCallback((pagination: PaginationState) => {
    setTableState(prevState =>
      produce(prevState, draft => {
        draft.pagination = pagination;
      }),
    );
  }, []);

  const resetPageNumber = () =>
    setTableState(prevState => ({
      ...prevState,
      pagination: prevState.pagination ? { ...prevState.pagination, pageNumber: 0 } : undefined,
    }));

  const renderFilter = React.useCallback(
    (filter: Filter) => {
      const onFilterChange = (newSelected: string[]) => {
        if (metadata === 'loading' || metadata === 'error') {
          // Cannot filter yet
          return;
        }
        setTableState(handleFilterChange(newSelected, filter, metadata, tableState));

        resetPageNumber();
      };
      return RenderFilterFormatter<T>(filter, tableState, onFilterChange);
    },
    [tableState, metadata],
  );

  const onSortChange = React.useCallback((sort: SortState<T> | undefined) => {
    setTableState(prevState =>
      produce(prevState, draft => {
        draft.sort = sort;
      }),
    );
  }, []);

  const onSearchQueryChange = React.useCallback((query: string) => {
    setTableState(prevState =>
      produce(prevState, draft => {
        draft.filters!.searchQuery = query;
      }),
    );
  }, []);

  // Certain search contexts don't allow sorting
  const currentSearchContext =
    typeof tableState.filters !== 'undefined' &&
    typeof metadata === 'object' &&
    typeof metadata.filterSet.searchContexts !== 'undefined' &&
    metadata.filterSet.searchContexts.length > 0 &&
    metadata.filterSet.searchContexts.filter(context => context.value === tableState.filters!.selectedSearchContextValue)[0];

  const disableSort = currentSearchContext && !currentSearchContext.isSortable;

  const disableSearch = currentSearchContext && !currentSearchContext.isSearchable;

  const pageFilters = share && buildPageFilter(consoleFilters).filters;

  if (metadata === 'loading' || metadata === 'error' || data === 'loading') {
    return <Spinner />;
  }

  return (
    <div>
      {
        // Only show clear filters if there are some filters to clear
        [...metadata.filterSet.filters, ...(metadata.filterSet.scopedFilters ?? []), ...(metadata.filterSet.searchContexts ?? [])].length >
          0 && (
          <div
            css={css`
              margin-bottom: 20px;
            `}
          >
            {filtersTitle && (
              <div>
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                  `}
                >
                  <Header
                    css={css`
                      margin-bottom: 0.5rem;
                      font-weight: 500;
                    `}
                    size={3}
                  >
                    {filtersTitle}
                  </Header>
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                    `}
                  >
                    {share && (
                      <InsightShareButton
                        {...share}
                        filters={pageFilters}
                      />
                    )}
                    <div
                      css={css`
                        margin-left: 0.625rem;
                      `}
                    >
                      {download !== undefined && download(tableState)}
                    </div>
                  </div>
                </div>
                <p>{filtersDescription}</p>
              </div>
            )}
            <div
              css={css`
                display: flex;
              `}
            >
              <div
                css={css`
                  flex: 1;
                `}
              >
                {(metadata.filterSet.scopedFilters ?? []).map(renderFilter)}
                {metadata.filterSet.searchContexts && tableState.filters?.selectedSearchContextValue && (
                  <div
                    css={css`
                      display: inline-block;
                      margin-right: 0.625rem;
                      margin-bottom: 0.625rem;
                    `}
                  >
                    <SelectGroup
                      options={metadata.filterSet.searchContexts}
                      selectedOptionValue={tableState.filters.selectedSearchContextValue}
                      onOptionSelected={option => {
                        setTableState(prevState =>
                          produce(prevState, draft => {
                            draft.filters!.selectedSearchContextValue = option.value;

                            // Remove filter if it no longer applies to the new scope
                            for (const filter of metadata.filterSet.filters
                              .concat(metadata.filterSet.scopedFilters ?? [])
                              .filter(filter => draft.filters!.selectedFilters.get(filter.value))) {
                              if (filter.appliesToContexts && !filter.appliesToContexts.includes(option.value)) {
                                draft.filters!.selectedFilters.delete(filter.value);
                              }
                            }
                          }),
                        );

                        resetPageNumber();
                      }}
                    />
                  </div>
                )}
                {Array.from(tableState.filters?.availableFilters.values() ?? [])
                  .sort((a, b) => a.order - b.order)
                  .map(renderFilter)}
              </div>
              <div
                css={css`
                  align-self: flex-end;
                  margin-left: 0.625rem;
                `}
              >
                <Button
                  css={css`
                    margin-bottom: 0.625rem;
                  `}
                  onClick={() => {
                    setTableState(prevState =>
                      produce(prevState, draft => {
                        if (draft.filters) {
                          draft.filters.selectedFilters = new Map(
                            [...draft.filters.availableFilters.values()]
                              .filter(filter => (filter.defaultOptions?.length ?? 0) > 0)
                              .map<[string, Set<string>]>(filter => [filter.value, new Set(filter.defaultOptions)]),
                          );
                          draft.filters.selectedSearchContextValue =
                            metadata.filterSet.searchContexts && metadata.filterSet.searchContexts.length > 0
                              ? metadata.filterSet.searchContexts[0].value
                              : undefined;
                        }
                      }),
                    );

                    resetPageNumber();
                  }}
                >
                  Clear all
                </Button>
              </div>
            </div>
          </div>
        )
      }

      <DataTable
        title={dataTitle}
        description={dataDescription}
        data={data}
        columns={columns}
        defaultPagination={tableState.pagination}
        paginationMetadata={paginationMetadata}
        onPageChange={onPageChange}
        disableSort={disableSort}
        defaultSort={defaultTableState.sort}
        onSortChange={onSortChange}
        defaultSearchQuery={defaultSearchQuery}
        onSearchQueryChange={onSearchQueryChange}
        disableSearch={disableSearch}
        hasServerSideSearch={typeof metadata === 'object' && metadata.supportsGlobalSearch}
        sortableColumnKeys={typeof metadata === 'object' ? metadata.sortingSet.sortableColumnKeys : []}
        EmptyStatePlaceholder={EmptyStatePlaceholder}
        getRowOptions={getRowOptions}
        enableClientPaginated={enableClientPaginated}
        manualSort={manualSort}
        pinnedData={pinnedData}
      />
    </div>
  );
};
export default FilterTable;
