import { PropsWithChildren } from 'react';
import InfoIconTooltip from '~/sp-ui/icons/InfoIconTooltip';
import ToolTip from '~/sp-ui/tool-tip';

export const LineItemTooltip = (props: PropsWithChildren<{ className?: string }>) => {
  return (
    <ToolTip
      css={{ zIndex: 100 }}
      content={props.children}
    >
      {({ anchorProps, setAnchorRef }) => {
        return (
          <InfoIconTooltip
            ref={setAnchorRef}
            css={{ cursor: 'pointer' }}
            className={props.className}
            {...anchorProps}
          />
        );
      }}
    </ToolTip>
  );
};
