import { css } from '@emotion/react';
import { SubscriptionBillingPeriodEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription.gen';
import * as React from 'react';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import {
  SubscriptionAgreementInfoTerm,
  SubscriptionAgreementInfoTermV2,
} from '~/wm/packages/subscription/packages/manage/wizard/model/SubscriptionAgreementInfo';
import { TermRenewalBehaviorEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription/Pricing/Term/TermNested.gen';
import { TermEndAction } from '@SubscriptionClient/BeastClient/Billing/Subscription/Model/Domain/Subscription/TermNested.gen';

export type TermInfoProps = {
  term: SubscriptionAgreementInfoTerm | SubscriptionAgreementInfoTermV2;
  billingPeriod: SubscriptionBillingPeriodEnum;
};

const TermInfo: React.FunctionComponent<TermInfoProps> = ({ term, billingPeriod }) => {
  const thCss = css`
    padding-bottom: 0.25rem;
    padding-right: 1rem;
  `;
  const tdCss = css`
    padding-bottom: 0.25rem;
  `;
  return (
    <table>
      {term.durationMonths && (
        <tr>
          <th css={thCss}>Term:</th>
          <td css={tdCss}>{term.durationMonths.toString() + ' months'}</td>
        </tr>
      )}
      <tr>
        <th css={thCss}>Billing cycle:</th>
        <td css={tdCss}>{billingPeriod === SubscriptionBillingPeriodEnum.Monthly ? 'Monthly' : 'Annually'}</td>
      </tr>
      {term.startsAt && (
        <tr>
          <th css={thCss}>Starts on:</th>
          <td css={tdCss}>
            {formatDate(term.startsAt, {
              format: 'MMM dd, yyyy',
              timezone: TimezoneFormat.Utc,
            })}
          </td>
        </tr>
      )}
      {term.endsAt && (
        <tr>
          <th css={thCss}>
            {term.renewalBehavior === TermRenewalBehaviorEnum.Downgrade || term.renewalBehavior === TermEndAction.Cancel
              ? 'Ends on:'
              : 'Auto renew on:'}
          </th>
          <td css={tdCss}>
            {formatDate(term.endsAt, {
              format: 'MMM dd, yyyy',
              timezone: TimezoneFormat.Utc,
            })}
          </td>
        </tr>
      )}
    </table>
  );
};

export default TermInfo;
