import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import React from 'react';

const PaymentSummary = (props: {
  totalAfterTax: string;
  nextEstimatedAmountWithoutTax: string;
  nextPaymentDayLabel: string | undefined;
  nextPaymentDate: string | undefined;
  isGrow: boolean;
}) => {
  const { totalAfterTax, nextEstimatedAmountWithoutTax, nextPaymentDayLabel, nextPaymentDate, isGrow } = props;
  return (
    <div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          color: ${colorToCode('dark-900')};
        `}
      >
        <span
          css={css`
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
          `}
        >
          {isGrow ? 'Your next payment (on ' + nextPaymentDate + ')' : 'Today’s payment'}
        </span>
        <div
          css={css`
            display: flex;
            align-items: center;
            padding-bottom: 0.5rem;
          `}
        >
          <span
            css={css`
              font-size: 2.25rem;
              font-style: normal;
              font-weight: 700;
              line-height: 125%;
            `}
          >
            {totalAfterTax} USD
          </span>
        </div>
        <span
          css={css`
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 1rem;
          `}
        >
          Afterward, your estimated billed amount will be <b>{nextEstimatedAmountWithoutTax} USD</b> before tax per month on the{' '}
          <b>{nextPaymentDayLabel}</b> of each month.
        </span>
        <div
          css={css`
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          `}
        >
          <span>By paying now, you agree to ScalePad’s </span>
          <a
            css={css`
              color: ${colorToCode('primary-400')};
              text-decoration: underline;
            `}
            target="_blank"
            href={'https://app.scalepad.com/pages/privacy'}
          >
            privacy policy
          </a>
          <span> and </span>
          <a
            css={css`
              color: ${colorToCode('primary-400')};
              text-decoration: underline;
            `}
            target="_blank"
            href={'https://app.scalepad.com/pages/terms'}
          >
            terms of service
          </a>
          <span>.</span>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;
