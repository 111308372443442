import * as React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { autoEmailReportEmailTemplateGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import AutoEmailReportListMutationContext from '~/wm/packages/organization/packages/auto-email-report/context/AutoEmailReportListMutationContext';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import {
  AutoEmailReportEmailTemplateDto,
  AutoEmailReportEmailTemplateVariableDto,
  AutoEmailReportReplyToUserAvailabilityDto,
} from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';
import AutoEmailReportEmailTemplateContext from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/context/AutoEmailReportEmailTemplateContext';

export type AutoEmailReportEmailTemplateProviderProps = {
  autoEmailReportId: string;
  children: React.ReactNode;
};

const AutoEmailReportEmailTemplateProvider = ({ autoEmailReportId, children }: AutoEmailReportEmailTemplateProviderProps) => {
  const { organizationId } = useOrganizationContext();

  const [autoEmailReportEmailTemplate, setAutoEmailReportEmailTemplate] = React.useState<AutoEmailReportEmailTemplateDto | undefined>(
    undefined,
  );
  const [emailReplyToUserAvailabilities, setEmailReplyToUserAvailabilities] = React.useState<
    AutoEmailReportReplyToUserAvailabilityDto[] | undefined
  >(undefined);

  const [emailTemplateVariableAvailabilities, setEmailTemplateVariableAvailabilities] = React.useState<
    AutoEmailReportEmailTemplateVariableDto[] | undefined
  >(undefined);

  const { callApi } = useApi();

  // Get autoEmailReport list
  const reload = React.useCallback(
    async (isRequestActive?: () => boolean) => {
      const autoEmailReportEmailTemplateResponse = await callApi(() =>
        autoEmailReportEmailTemplateGet({ autoEmailReportId, organizationId }),
      );

      if (!autoEmailReportEmailTemplateResponse) {
        return;
      }

      // if a function is provided to determine whether to propagate
      // a state update (to prevent race conditions), then it will
      // ensure not to update state. otherwise, each call updates state.
      if (!isRequestActive || isRequestActive()) {
        setAutoEmailReportEmailTemplate(autoEmailReportEmailTemplateResponse.emailTemplate);
        setEmailReplyToUserAvailabilities(autoEmailReportEmailTemplateResponse.replyToUserAvailabilities);
        setEmailTemplateVariableAvailabilities(autoEmailReportEmailTemplateResponse.emailTemplateVariables);
      }
    },
    [autoEmailReportId, callApi, organizationId],
  );

  // Load autoEmailReport list
  React.useEffect(() => {
    reload();
  }, [reload]);

  if (!autoEmailReportEmailTemplate || !emailReplyToUserAvailabilities || !emailTemplateVariableAvailabilities) {
    return null;
  }

  return (
    <AutoEmailReportListMutationContext.Provider
      value={{
        triggerAutoEmailReportListReload: isRequestActive => reload(isRequestActive),
      }}
    >
      <AutoEmailReportEmailTemplateContext.Provider
        value={{
          autoEmailReportEmailTemplate,
          emailReplyToUserAvailabilities,
          emailTemplateVariableAvailabilities,
        }}
      >
        {children}
      </AutoEmailReportEmailTemplateContext.Provider>
    </AutoEmailReportListMutationContext.Provider>
  );
};

export default AutoEmailReportEmailTemplateProvider;
