import useApi from '~/wm/packages/api/hook/useApi';
import { initiativeDraftCreate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import FiscalQuarter from '~/extensions/packages/date/packages/fiscal-quarter/FiscalQuarter';

type InitiativeDraftCreatePayload = {
  createdFrom: string;
  organizationId: string;
  targetQuarter?: FiscalQuarter;
};

export type UseInitiativeDraftCreate = {
  createDraft: (draftCreatePayload: InitiativeDraftCreatePayload) => Promise<string | undefined>;
};

const useInitiativeDraftCreate = (onInitiativeDraftCreate: (initiativeId: string) => void): UseInitiativeDraftCreate => {
  const { callApi } = useApi();

  const createDraft = async (draftCreatePayload: InitiativeDraftCreatePayload) => {
    const response = await callApi(() =>
      initiativeDraftCreate({
        createdFrom: draftCreatePayload.createdFrom,
        organizationId: draftCreatePayload.organizationId,
        targetQuarter: draftCreatePayload.targetQuarter,
      }),
    );

    if (!response) {
      return;
    }

    onInitiativeDraftCreate(response.initiativeId);

    return response.initiativeId;
  };

  return { createDraft };
};

export default useInitiativeDraftCreate;
