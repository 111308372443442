import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

/**
 * Creates a css-only tooltip on the right side of the element.
 * Recommended to only use if the positioning of our Tooltip component cannot be calculated properly.
 */
const tooltipStyles = (content: string) => css`
  position: relative;

  &:hover:before {
    visibility: visible;
    opacity: 1;
  }

  &:before {
    content: '${content}';
    position: absolute;
    white-space: pre;

    top: 50%;
    transform: translateY(-50%);
    right: 100%;
    margin-right: 0.5rem;
    font-size: 0.875rem;

    padding: 0.5rem 0.8125rem;
    border-radius: 0.625rem;
    background: ${colorToCode('dark-300')};
    color: ${colorToCode('light-000')};
    box-shadow: 0 0 0.75rem -0.3125rem ${colorToCode('dark-300')};

    transition: visibility 200ms, opacity 200ms;
    visibility: hidden;
    opacity: 0;
  }
`;

export default tooltipStyles;
