import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import WarrantyCartPageHeader from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-page-header/WarrantyCartPageHeader';
import WarrantyCartDeviceTypeSwitch from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-device-type-switch/WarrantyCartDeviceTypeSwitch';
import WarrantyCartEmptyState from '~/wm/packages/warranty/packages/warranty-cart/WarrantyCartEmptyState';
import WarrantyCartSummary from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-summary/WarrantyCartSummary';
import WarrantyCartOpportunity from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-opportunity/WarrantyCartOpportunity';
import WarrantyCartSelectText from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-select-text/WarrantyCartSelectText';
import WarrantyCartFiltersProvider from '~/wm/packages/warranty/packages/warranty-cart/context/WarrantyCartFiltersProvider';
import WarrantyCartFilters from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-filters/WarrantyCartFilters';
import WarrantyCartDevicesTable from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-devices-table/WarrantyCartDevicesTable';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';
import useUserContext from '~/wm/hooks/useUserContext';
import { Enum as UserPermissionEnum } from '@SsoClient/BeastClient/Beast/Identity/Model/UserPermission/UserPermissionFactoryNested.gen';
import WarrantyCartPermissionBanner from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-permission-banner/WarrantyCartPermissionBanner';
import WarrantyCartWorkstationsReminder from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-footer/packages/warranty-cart-workstations-reminder/WarrantyCartWorkstationsReminder';
import WarrantyCartTreeImpact from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-tree-impact/WarrantyCartTreeImpact';
import WarrantyCartFooter from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-footer/WarrantyCartFooter';
import React, { Fragment, useMemo } from 'react';
import useFeatureAvailabilitiesContext from '~/wm/packages/feature/context/hooks/useFeatureAvailabilitiesContext';
import useLayoutHeaderContext from '~/neo-ui/packages/layout/packages/layout-enceladus/context/hooks/useLayoutHeaderContext';
import Badge from '~/neo-ui/packages/badge/Badge';
import Label from '~/neo-ui/packages/text/packages/label/Label';

const WarrantyCart = () => {
  const { displayEmptyStateImage } = useWarrantyCartContext();
  const { 'sp-account-phase-1': spAccountPhaseOneEnabled } = useFeatureFlagProvider();
  const { getUserPermission } = useUserContext();
  const { 'lm-essential': enabledLmEssential } = useFeatureFlagProvider();
  const { hasFeaturePermissionAccount } = useFeatureAvailabilitiesContext();
  const { setUpgradeBannerProps } = useLayoutHeaderContext();

  const canCheckout =
    (spAccountPhaseOneEnabled ? getUserPermission(UserPermissionEnum.PlaceOrders) : getUserPermission(UserPermissionEnum.ManageBilling)) ||
    getUserPermission(UserPermissionEnum.AccountAdmin);

  useMemo(() => {
    if (enabledLmEssential && !hasFeaturePermissionAccount('infrastructure-protection-discount-view')) {
      setUpgradeBannerProps({
        bannerLeftComponent: (
          <Fragment>
            <Badge
              bgColor={'excellent-050'}
              textColor={'excellent-300'}
              height={'1.75rem'}
              borderRadius={'radius500'}
            >
              <Label
                size={'lg'}
                bold={true}
              >
                20% OFF
              </Label>
            </Badge>
            On all services! When you upgrade to
          </Fragment>
        ),
      });
    }
  }, [enabledLmEssential, hasFeaturePermissionAccount, setUpgradeBannerProps]);

  return (
    <Fragment>
      {!canCheckout && <WarrantyCartPermissionBanner />}
      <WarrantyCartPageHeader />
      <WarrantyCartDeviceTypeSwitch />
      {displayEmptyStateImage ? (
        <WarrantyCartEmptyState />
      ) : (
        <Fragment>
          <WarrantyCartSummary />
          <WarrantyCartOpportunity />
          <WarrantyCartWorkstationsReminder />
          <WarrantyCartTreeImpact />
          <WarrantyCartSelectText />
          <WarrantyCartFiltersProvider>
            <WarrantyCartFilters />
            <WarrantyCartDevicesTable />
          </WarrantyCartFiltersProvider>
          <WarrantyCartFooter canCheckout={canCheckout} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default WarrantyCart;
