import { css } from '@emotion/react';
import * as React from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';

export type ModalSuccessProps = {
  description: string;
};

/**
 * @deprecated deprecated by ModalStandard
 * Modal display after a modal form has successfully submitted
 */
const ModalSuccess: React.FunctionComponent<ModalSuccessProps> = ({ description }) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
  >
    <Icon
      icon={'Success'}
      sizePx={28}
      color={'positive-400'}
      css={css`
        margin-bottom: 1.25rem;
      `}
    />
    <Header
      size={2}
      color={'positive-400'}
      css={css`
        margin-bottom: 0.625rem;
      `}
    >
      Done
    </Header>
    <Label
      muted={true}
      css={css`
        text-align: center;
      `}
    >
      {description}
    </Label>
  </div>
);

export default ModalSuccess;
