import { useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { contractAgreementList } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { ContractAgreementDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Agreement/Dto/Model.gen';

export type UseContractAgreementList = (organizationId: string) => {
  contractAgreementsUpdated: ContractAgreementDto[];
  contractAgreementsNew: ContractAgreementDto[];
};

const useContractAgreementList: UseContractAgreementList = organizationId => {
  const { callApi } = useApi();
  const [contractAgreementsUpdated, setContractAgreementsUpdated] = useState<ContractAgreementDto[]>([]);
  const [contractAgreementsNew, setContractAgreementsNew] = useState<ContractAgreementDto[]>([]);

  useEffect(() => {
    (async () => {
      const response = await callApi(() => contractAgreementList({ organizationId }));

      if (!response) {
        return;
      }

      setContractAgreementsNew(response.contractAgreementListNew);
      setContractAgreementsUpdated(response.contractAgreementListUpdated);
    })();
  }, [callApi, organizationId]);

  return { contractAgreementsNew, contractAgreementsUpdated };
};

export default useContractAgreementList;
