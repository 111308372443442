import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { InitiativeSaveFormData } from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import Button from '~/neo-ui/packages/button/Button';
import { useState } from 'react';
import { Enum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import HardwareTableSelectableSection from '~/wm/packages/hardware/packages/hardware-table-section/HardwareTableSelectableSection';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useInitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesContext';
import { Column } from '~/neo-ui/packages/table/packages/console/types';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import ColumnSelectButton from '~/neo-ui/packages/table/packages/console/column/ColumnSelectButton';
import useInitiativeAssetSelectContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/context/hooks/useInitiativeAssetSelectContext';

const assetIdsFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.assetIds;
const assetDisplayColumnsFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.assetDisplayColumns;

const InitiativeSaveAssetsSection = () => {
  const { getFormInput, setFormInput } = useFormContext<InitiativeSaveFormData>();
  const [addAssetWindowOpened, setAddAssetWindowOpen] = useState(false);
  const {
    selectedAssetIds,
    lastSelectedAssetId,
    selectAssetIds,
    deselectAssetIds,
    selectAssetId,
    deselectAssetId,
    clearSelectedAssetIds,
    displayedAssetIds,
    setDisplayedAssetIds,
  } = useInitiativeAssetSelectContext();
  const { organizationId } = useOrganizationContext();
  const { availabilities } = useInitiativeAvailabilitiesContext();
  const selectedColumnKeys = getFormInput<string[]>(assetDisplayColumnsFieldKey).value;

  const availableColumns: Column[] =
    availabilities.columnsAvailabilities
      ?.map(column => ({
        key: column.key.value,
        label: column.label,
        isSortable: column.isSortable,
        order: column.order,
        availability: column.availability,
        size: column.size,
      }))
      .sort((a, b) => a.order - b.order) ?? [];

  const displayedColumns: Column[] = availableColumns.filter(column => selectedColumnKeys.includes(column.key));

  return (
    <Box
      padding={'padding100'}
      borderRadius={'radius400'}
      boxShadow={'shadow100'}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.935rem;
      `}
    >
      <Header
        size={3}
        weight={'bold'}
      >
        Assets
      </Header>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Button
          theme={'primary'}
          iconLeft={'Add'}
          onClick={() => setAddAssetWindowOpen(true)}
          css={css`
            width: fit-content;
          `}
        >
          Add assets...
        </Button>
        {displayedAssetIds.length > 0 && (
          <ColumnSelectButton
            columnOptions={availableColumns.map(column => ({ label: column.label, value: column.key }))}
            selectedColumns={new Set<string>(selectedColumnKeys)}
            onColumnChange={columns => {
              setFormInput<string[]>(assetDisplayColumnsFieldKey, [...columns]);
            }}
          />
        )}
      </div>
      <HardwareTableSelectableSection
        displayColumns={displayedColumns}
        scope={{ type: Enum.Organization, organizationId }}
        displayedAssetIds={displayedAssetIds}
        setDisplayedAssetIds={setDisplayedAssetIds}
        selectedAssetIds={selectedAssetIds}
        lastSelectedAssetId={lastSelectedAssetId}
        selectAssetIds={selectAssetIds}
        deselectAssetIds={deselectAssetIds}
        selectAssetId={selectAssetId}
        deselectAssetId={deselectAssetId}
        clearSelectedAssetIds={clearSelectedAssetIds}
        onDisplayChange={assetIds => {
          setFormInput<string[]>(assetIdsFieldKey, assetIds);
        }}
        isAddAssetWindowOpen={addAssetWindowOpened}
        onAddAssetWindowDismiss={() => setAddAssetWindowOpen(false)}
        canSelectDisplayColumns={true}
      />
    </Box>
  );
};

export default InitiativeSaveAssetsSection;
