import { createContext } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export type RoadmapFeatureFlag = 'lm-roadmaps-v2' | 'enceladus' | 'lm-roadmaps-pdf-overview' | 'lm-initiative-tickets';

export type RoadmapFeatureFlagsContextData = {
  isFeatureFlagEnabled: (flag: RoadmapFeatureFlag) => boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const RoadmapFeatureFlagsContext = createContext<RoadmapFeatureFlagsContextData | undefined>(undefined);

export default RoadmapFeatureFlagsContext;
