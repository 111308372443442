import * as React from 'react';
import { useState } from 'react';
import Button from '~/neo-ui/packages/button/Button';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import IntegrationSetupDeleteModal from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/delete/IntegrationSetupDeleteModal';

export type IntegrationSetupDeleteButtonProps = {
  isDisabled?: boolean;
  integrationSetupId: string;
};

const IntegrationSetupDeleteButton: React.FunctionComponent<IntegrationSetupDeleteButtonProps> = ({ isDisabled, integrationSetupId }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <Button
        css={css`
          box-shadow: none;
        `}
        disabled={isDisabled}
        iconLeft={'Delete'}
        iconColorOverride={'negative-400'}
        onClick={() => setModalOpen(true)}
        size={'md'}
      >
        <Label color={'negative-400'}>Delete integration</Label>
      </Button>
      <IntegrationSetupDeleteModal
        integrationSetupId={integrationSetupId}
        isOpen={isModalOpen}
        onDismiss={() => setModalOpen(false)}
        onDelete={() => setModalOpen(false)}
      />
    </div>
  );
};

export default IntegrationSetupDeleteButton;
