import * as Yup from 'yup';
import { AutoEmailReportRecipientAddFormData } from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-recipients/packages/auto-email-report-recipient-add-form/AutoEmailReportRecipientAddForm';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const autoEmailReportRecipientAddFormValidationSchema: Yup.ObjectSchema<any> = Yup.object().shape<
  Partial<AutoEmailReportRecipientAddFormData>
>({
  name: Yup.string().required('Required.').max(255, 'The name may only be 255 characters.'),
  email: Yup.string().required('Required.').max(255, 'The email may only be 255 characters.').email('Invalid email address.'),
});

export default autoEmailReportRecipientAddFormValidationSchema;
