import { css } from '@emotion/react';
import { SubscriptionBillingPeriodEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription.gen';
import * as React from 'react';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import Info from '~/legacy-ui/packages/info/Info';
import SectionSubtitle from '~/neo-ui/packages/wizard/packages/wizard-container/packages/section-subtitle/SectionSubtitle';
import getBillingPeriodDisplay from '~/wm/packages/subscription/packages/billing/operation/getBillingPeriodDisplay';
import { AppliedProductDiscountFormatted } from '~/wm/packages/subscription/packages/manage/model/AppliedProductDiscountFormatted';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ProductDiscountSummaryProps = {
  discounts: AppliedProductDiscountFormatted[];
};

const ProductDiscountSummary = ({ discounts }: ProductDiscountSummaryProps) => (
  <SectionSubtitle
    css={css`
      margin-top: 2rem;
    `}
    subtitle="Subscription discounts"
  >
    <hr
      css={css`
        margin-top: 0.625rem;
        margin-bottom: 1rem;
        color: ${colorToCode('dark-900-12')};
      `}
    />
    <div>
      {discounts.map(discount => (
        <div key={[discount.amount, discount.title].join('.')}>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <Info
              css={css`
                &:not(:last-child) {
                  margin-bottom: 0.5rem;
                }
              `}
              title={{
                value: discount.title,
                units: '',
              }}
              titleSize={'md'}
              titleColor={'dark-700'}
              titleFontWeight="normal"
              description={
                !!discount.expiresAt && !!discount.subtitle
                  ? `${discount.subtitle} ${formatDate(discount.expiresAt, {
                      format: 'MMM dd, yyyy',
                      timezone: TimezoneFormat.Utc,
                    })}`
                  : !!discount.subtitle
                  ? discount.subtitle
                  : ''
              }
              descriptionColor={'dark-050'}
            />
            <div>
              <Info
                title={'-' + discount.amount + `/${getBillingPeriodDisplay(SubscriptionBillingPeriodEnum.Monthly).noun}`}
                titleSize={'sm'}
                titleColor={'positive-400'}
              />
            </div>
          </div>
          <div>
            <hr
              css={css`
                margin-top: 0.625rem;
                margin-bottom: 1rem;
                color: ${colorToCode('dark-900-12')};
              `}
            />
          </div>
        </div>
      ))}
    </div>
  </SectionSubtitle>
);
export default ProductDiscountSummary;
