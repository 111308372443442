import { SubscriptionGrowAvailableAction } from '@SubscriptionClient/Subscription/Packages/Grow/Model.gen';
import { subscriptionGrowAvailableAction } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

const useSubscriptionGrowAction = () => {
  const [isLoading, setLoading] = useState(true);

  const [growAction, setGrowAction] = useState<SubscriptionGrowAvailableAction>();

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => subscriptionGrowAvailableAction({}));
      setLoading(false);

      if (!response) {
        return;
      }

      setGrowAction(response.availableGrowAction);
    })();
  }, [callApi]);

  return { isLoading, growAction };
};

export default useSubscriptionGrowAction;
