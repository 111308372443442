import { PriorityDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Priority/Dto/Model.gen';
import { BudgetCurrencyDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Dto/Model.gen';
import { ScorecardItemDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardItem/Dto/Model.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import { formatCurrencyByCurrencyInfo } from '~/extensions/packages/currency/formatCurrency';
import Box from '~/neo-ui/packages/box/Box';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import ScaleSelect, { ScaleOption } from '~/neo-ui/packages/select/packages/scale-select/ScaleSelect';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Theme from '~/neo-ui/packages/color/Theme';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import { smallBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';

export type ScorecardItemProps = {
  scorecardItem: ScorecardItemDto;
  currency: BudgetCurrencyDto;
  scoreDisplayAvailabilities: ScaleOption[];
  priorityDisplayAvailabilities: PriorityDto[];
};

const ScorecardItemPresentation = ({
  scorecardItem,
  currency,
  scoreDisplayAvailabilities,
  priorityDisplayAvailabilities,
}: ScorecardItemProps) => {
  const priorityOption = priorityDisplayAvailabilities.find(option => option.key === scorecardItem.priority.key);

  const { themeMap: priorityThemeMap } = useTheme(priorityOption ? (priorityOption.theme as Theme) : undefined);

  const scoreDisplay = scoreDisplayAvailabilities.find(option => option.value === scorecardItem.score.value.toString());

  return (
    <Box
      css={css`
        background-color: ${colorToCode('light-100')};
      `}
      boxShadow={'shadow0'}
      padding={'padding100'}
      borderRadius={'radius400'}
    >
      <Header
        size={4}
        weight={'bold'}
      >
        {scorecardItem.category.label}
      </Header>
      <div
        css={css`
          display: grid;
          grid-template-columns: 3fr 0fr 2fr;
          padding: 1rem;

          @media (max-width: ${smallBreakpointRem}rem) {
            display: flex;
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.6125rem;
              flex-wrap: wrap;
            `}
          >
            <ScaleSelect
              scaleOptions={scoreDisplayAvailabilities}
              selectedScaleOption={scoreDisplay}
              isPresentation={true}
              size={'sm'}
            />
            {priorityOption && (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 0.375rem;
                  border-radius: 0.625rem;
                  padding: 0.25rem;
                  background-color: ${colorToCode(priorityThemeMap.backgroundAccent)};
                `}
              >
                <Icon
                  icon={priorityOption.icon as IconType}
                  color={priorityThemeMap.foregroundLowered}
                  sizePx={12}
                />
                <Header
                  size={6}
                  color={priorityThemeMap.foregroundLowered}
                >
                  {priorityOption.labelShort}
                </Header>
              </div>
            )}
          </div>
          <Label>{scorecardItem.score.description}</Label>
        </div>
        <div
          css={css`
            border: ${colorToCode('dark-900-12')} 0.0625rem solid;
            margin: 0 0.625rem;

            @media (max-width: ${smallBreakpointRem}rem) {
              margin: 0.625rem 0;
            }
          `}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.875rem;
          `}
        >
          <Header
            size={4}
            weight={'bold'}
          >
            {typeof scorecardItem.investment.costSubunits === 'undefined' || scorecardItem.investment.isInvestmentUnknown
              ? '-'
              : formatCurrencyByCurrencyInfo(scorecardItem.investment.costSubunits, {
                  code: currency.codeAlpha,
                  subunitsPerUnit: currency.subunitRatio,
                })}
          </Header>
          <Label>{scorecardItem.investment.description}</Label>
        </div>
      </div>
    </Box>
  );
};

export default ScorecardItemPresentation;
