import { createContext } from 'react';

export type ContractSyncStatusMutationContextData = {
  triggerContractSyncStatusReload: (isRequestActive?: () => boolean) => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ContractSyncStatusMutationContext = createContext<ContractSyncStatusMutationContextData>({
  triggerContractSyncStatusReload: async () => {},
});

export default ContractSyncStatusMutationContext;
