import useScorecardMutation, { UseScorecardMutationOptions } from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardMutation';
import { scorecardCategoryDelete } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseScorecardCategoryWithoutItemsDelete = {
  deleteScorecardCategoryWithoutItems: (deletePayload: { scorecardCategoryId: string }) => void;
  isScorecardCategoryWithoutItemsDeleting: boolean;
};

const useScorecardCategoryWithoutItemsDelete = (
  scorecardId: string,
  options?: UseScorecardMutationOptions,
): UseScorecardCategoryWithoutItemsDelete => {
  const [deleteScorecardCategoryWithoutItems, isScorecardCategoryWithoutItemsDeleting] = useScorecardMutation(
    (deletePayload: { scorecardCategoryId: string }) =>
      scorecardCategoryDelete({
        scorecardId,
        scorecardCategoryId: deletePayload.scorecardCategoryId,
      }),
    options,
  );

  return {
    deleteScorecardCategoryWithoutItems,
    isScorecardCategoryWithoutItemsDeleting,
  };
};

export default useScorecardCategoryWithoutItemsDelete;
