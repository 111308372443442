import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import * as React from 'react';
import { Fragment, useMemo } from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import { formatDate, getDateFriendlyExtendedDefinition, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import { css } from '@emotion/react';
import Badge from '~/neo-ui/packages/badge/Badge';
import calculateUserTheme from '~/wm/packages/account/packages/user/hooks/calculateUserTheme';
import formatUserNameDisplay from '~/wm/packages/account/packages/user/packages/active-users-search/formatUserNameDisplay';
import EngagementActionTableTicketDisplay from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-action-list-panel-tab/packages/engagement-action-table/packages/engagement-action-table-ticket-display/EngagementActionTableTicketDisplay';
import TableClientSide, { TableClientSideColumn } from '~/neo-ui/packages/table/packages/table-client-side/TableClientSide';

import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';

export type EngagementActionSelectableTableProps = {
  actions: EngagementActionDto[];
  selectedActionIds: string[];
  onActionSelected: (actionId: string) => void;
  onActionDeselect: (actionId: string) => void;
};

const EngagementActionSelectableTable = ({
  actions,
  onActionSelected,
  onActionDeselect,
  selectedActionIds,
}: EngagementActionSelectableTableProps) => {
  const getDueDateLabel = (dueDate: Date, isCompleted: boolean) => {
    const { color, display } = getDateFriendlyExtendedDefinition(dueDate, isCompleted);

    return (
      <Label
        css={css`
          padding: 0.5rem;
          white-space: nowrap;
        `}
        size={'md'}
        color={color}
        bold={true}
      >
        {display}
      </Label>
    );
  };

  const engagementActionColumns: TableClientSideColumn<EngagementActionDto>[] = useMemo(
    () => [
      {
        fieldKey: action => action.engagementActionId,
        header: '',
        renderCell: ({ original: action }) => (
          <InputCheckbox
            checked={selectedActionIds.includes(action.engagementActionId) ?? false}
            disabled={false}
            onClick={_ =>
              selectedActionIds.includes(action.engagementActionId) ?? false
                ? onActionDeselect(action.engagementActionId)
                : onActionSelected(action.engagementActionId)
            }
          />
        ),
      },
      {
        fieldKey: action => action.description,
        header: 'Task',
        renderCell: ({ original: action }) => (
          <div
            css={css`
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 1rem;

              // This height will make the row height consistent with the other rows
              height: 6rem;
            `}
          >
            <div
              css={css`
                display: flex;
                width: 100%;
              `}
            >
              <Tooltip
                backgroundColor={'light-000'}
                content={action.description}
                placement={'top'}
                css={css`
                  max-width: 51.6875rem;
                  word-wrap: break-word;
                `}
              >
                <Label
                  css={css`
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                  `}
                  size={'md'}
                  color={'dark-050'}
                >
                  {action.description}
                </Label>
              </Tooltip>
            </div>
          </div>
        ),
      },
      // Due Date Column
      {
        fieldKey: action => action.dueAt,
        header: 'Due Date',
        width: '6.375rem',
        renderCell: ({ original: action }) => {
          const friendlyDueDate =
            typeof action.dueAt !== 'undefined' ? getDateFriendlyExtendedDefinition(new Date(action.dueAt), action.isCompleted) : undefined;

          return typeof friendlyDueDate !== 'undefined' ? (
            <div
              css={css`
                .tooltip-target {
                  width: min-content;
                }
              `}
            >
              <Tooltip
                backgroundColor={'light-000'}
                placement={'top'}
                content={`Due on: ${formatDate(new Date(action.dueAt!), {
                  format: 'MMM do',
                  timezone: TimezoneFormat.Local,
                })}`}
              >
                {getDueDateLabel(new Date(action.dueAt!), action.isCompleted)}
              </Tooltip>
            </div>
          ) : (
            '-'
          );
        },
      },
      // Assigned Users Column
      {
        fieldKey: action => action.assignedUsers,
        header: 'Assigned to',
        widthRem: 12.5,
        renderCell: ({ original: action }) => {
          if (action.assignedUsers.length < 1) {
            return '-';
          }

          return (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
              `}
            >
              {action.assignedUsers.slice(0, 3).map((user, index) => {
                if (index > 2) {
                  return;
                }

                const userTheme = calculateUserTheme(user.id);

                return (
                  <div
                    key={user.id}
                    css={css`
                      display: flex;
                      gap: 0.5rem;
                      flex-wrap: nowrap;
                      align-items: center;
                    `}
                  >
                    <Badge
                      bgColor={userTheme.backgroundColorRest}
                      textColor={userTheme.textColorRest}
                      fontWeight={'400'}
                      borderRadius={'radius425'}
                      css={css`
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                      `}
                    >
                      {formatUserNameDisplay(user)}
                    </Badge>
                    {index === 2 && action.assignedUsers.length > 3 && (
                      <Label color={'dark-700'}>+ {action.assignedUsers.length - 3}</Label>
                    )}
                  </div>
                );
              })}
            </div>
          );
        },
      },
      // Ticket Column
      {
        fieldKey: action => action.ticketLinkState,
        header: 'PSA Ticket',
        widthRem: 6.375,
        renderCell: ({ original: action }) => (
          <div
            css={css`
              text-align: center;
            `}
          >
            {typeof action.ticketLinkState === 'undefined' ? (
              '-'
            ) : (
              <EngagementActionTableTicketDisplay ticketLinkState={action.ticketLinkState} />
            )}
          </div>
        ),
      },
    ],
    [onActionDeselect, onActionSelected, selectedActionIds],
  );

  return (
    <Fragment>
      <TableClientSide
        data={actions}
        columns={engagementActionColumns}
        pagination={{ pageSize: 10, pageIndex: 0 }}
      >
        {({ Table, Pagination }) => (
          <Fragment>
            <Pagination
              css={css`
                margin-bottom: 0.625rem;
                margin-left: auto;
              `}
            />
            <Table />
          </Fragment>
        )}
      </TableClientSide>
    </Fragment>
  );
};

export default EngagementActionSelectableTable;
