import { RenderCellDataNested } from '@AssetManagementClient/BeastClient/Renderer/Model/Data.gen';
import * as React from 'react';
import Image from '~/neo-ui/packages/image/Image';

export type RenderImageCellProps = {
  data: RenderCellDataNested.ImageData;
};

const RenderImageCell: React.FunctionComponent<RenderImageCellProps> = ({ data: { imageUrl } }) => <Image source={imageUrl} />;

export default RenderImageCell;
