import { getTreeImpactRelatedService, ServiceId } from '../../model/TreeImpactRelatedService';
import * as React from 'react';
import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { Styleable } from '~/neo-ui/model/capacity';
import TreeImpactInfoFooter from '~/wm/packages/tree-impact/packages/tree-impact-info-footer/TreeImpactInfoFooter';

export type TreeImpactIntroCardProps = {
  serviceId: ServiceId;
  treesPerOrderUnit: number;
  helpGuideUrl: string;
} & Styleable;

const TreeImpactIntroCard: React.FunctionComponent<TreeImpactIntroCardProps> = ({
  serviceId,
  treesPerOrderUnit,
  helpGuideUrl,
  className,
}) => {
  const serviceInfo = getTreeImpactRelatedService(serviceId);
  const imageSource = '/i/graphic/services/impact/internal/intro-background.svg';

  return (
    <Box
      borderRadius={'radius400'}
      padding={'padding200'}
      className={className}
      css={css`
        background-image: url('${imageSource}');
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: -2.375rem;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.125rem;
        `}
      >
        <Header
          size={3}
          css={css`
            margin-top: 0.5rem;
          `}
          weight={'medium'}
        >
          {`Plant ${treesPerOrderUnit === 1 ? 'a tree' : `${treesPerOrderUnit} trees`}`}
        </Header>
        <Label size={'sm'}>{serviceInfo.serviceIntroDescription}</Label>
      </div>
      <TreeImpactInfoFooter
        helpGuideUrl={helpGuideUrl}
        css={css`
          margin: 1.25rem -0.938rem -0.938rem;
        `}
      />
    </Box>
  );
};

export default TreeImpactIntroCard;
