import React, { useCallback } from 'react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { css } from '@emotion/react';
import SettingsLink from '~/wm/packages/settings/packages/settings-link/SettingsLink';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import routes from '~/router/types/routes';
import { Enum as WarrantyType } from '@WarrantyClient/BeastClient/Goods/WarrantyType/WarrantyTypeFactoryNested.gen';

const settingsTip = (organizationName: string) => 'Customize settings for ' + organizationName;

const WarrantyCartPageHeader: React.FunctionComponent = () => {
  const { organizationId, organizationName, warrantyType } = useWarrantyCartContext();

  const getHeaderCaption = useCallback(() => {
    switch (warrantyType) {
      case WarrantyType.InfrastructureProtection:
        return 'Infrastructure type';
      default:
        return 'Hardware type';
    }
  }, [warrantyType]);

  const getHintText = useCallback(() => {
    switch (warrantyType) {
      case WarrantyType.InfrastructureProtection:
        return 'Choose the infrastructure asset type to protect.';
      default:
        return 'Choose the hardware type to buy warranties for.';
    }
  }, [warrantyType]);

  if (warrantyType === WarrantyType.WorkstationAssurance) {
    return null;
  }

  return (
    <div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Header
          size={2}
          weight="light"
        >
          {getHeaderCaption()}
        </Header>
        <SettingsLink
          settingsUrl={routes.ORGANIZATION_WARRANTY_SETTINGS.build([organizationId])}
          tooltipContent={settingsTip(organizationName)}
        />
      </div>
      <Header
        css={css`
          margin-top: 1rem;
        `}
        size={5}
        weight="light"
        color="dark-000"
      >
        {getHintText()}
      </Header>
      <Header
        css={css`
          margin-top: 1rem;
        `}
        size={5}
        weight="light"
        color="warning-400"
      >
        Changing the hardware type will deselect all warranties
      </Header>
    </div>
  );
};

export default WarrantyCartPageHeader;
