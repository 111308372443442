import * as React from 'react';
import Popup from '~/neo-ui/packages/popup/Popup';
import Button from '~/neo-ui/packages/button/Button';
import RoadmapShareSection from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap-share-button/packages/roadmap-share-section/RoadmapShareSection';
import Testable from '~/neo-ui/packages/testable/Testable';

export type RoadmapShareButtonProps = {
  organizationId: string;
  roadmapPreviewUrl: string;
};

const RoadmapShareButton = ({ organizationId, roadmapPreviewUrl }: RoadmapShareButtonProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      backgroundColor={'light-000'}
      title={'Share your Roadmap'}
      titleColor={'primary-400'}
      showCloseButton={true}
      content={
        <RoadmapShareSection
          organizationId={organizationId}
          roadmapPreviewUrl={roadmapPreviewUrl}
          onDismiss={() => setIsOpen(false)}
        />
      }
    >
      <Testable testId={'roadmap-share-button'}>
        <Button
          iconRight={'Share'}
          onClick={() => setIsOpen(prev => !prev)}
        >
          Share with client…
        </Button>
      </Testable>
    </Popup>
  );
};

export default RoadmapShareButton;
