import { useContext } from 'react';
import InitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/InitiativeAvailabilitiesContext';

const useInitiativeAvailabilitiesContext = () => {
  const initiativeAvailabilitiesContext = useContext(InitiativeAvailabilitiesContext);
  if (!initiativeAvailabilitiesContext) {
    throw new Error('Tried to use InitiativeAvailabilitiesContext outside of an InitiativeAvailabilitiesProvider');
  }

  return initiativeAvailabilitiesContext;
};

export default useInitiativeAvailabilitiesContext;
