import { useCallback, useState, useEffect } from 'react';
import { apiKeyPublicList, apiKeyPublicUpdate } from '@SsoClient/SsoClientMsp.gen';
import { ApiKeyPublicDto } from '@SsoClient/Sso/ApiKeyPublic/Dto.gen';
import { EmptyRequest } from '@SsoClient/Common/Controller/Controller.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import invariant from 'tiny-invariant';

type SaveApiKeyFunction = (apiKeyId: string, apiKeyName: string) => void;

type UseApiKeys = () => {
  apiKeyList: ApiKeyPublicDto[] | undefined;
  saveApiKey: SaveApiKeyFunction;
};

const useApiKeys: UseApiKeys = () => {
  const [apiKeyList, setApiKeyList] = useState<ApiKeyPublicDto[] | undefined>(undefined);

  const { callApi } = useApi();

  const getApiKeys = useCallback(async (): Promise<ApiKeyPublicDto[]> => {
    const response = await callApi(() => apiKeyPublicList({} as EmptyRequest));

    invariant(response, 'No API Key response was returned from the server.');

    return response.apiKeyPublicDtos;
  }, [callApi]);

  useEffect(() => {
    if (apiKeyList !== undefined) {
      return;
    }

    getApiKeys().then(apiKeys => setApiKeyList(apiKeys));
  }, [apiKeyList, getApiKeys]);

  const saveApiKey = useCallback<SaveApiKeyFunction>(
    async (apiKeyId: string, apiKeyName: string) => {
      await apiKeyPublicUpdate({ apiKeyId, name: apiKeyName });
      getApiKeys().then(apiKeys => setApiKeyList(apiKeys));
    },
    [getApiKeys],
  );
  return { apiKeyList, saveApiKey };
};

export default useApiKeys;
