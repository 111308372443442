import { RoadmapInitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import { BudgetQuarterDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Packages/BudgetQuarter/Dto/Model.gen';

export const buildRoadmapInitiativeKey = (budgetQuarter: BudgetQuarterDto | undefined) =>
  typeof budgetQuarter === 'undefined' ? '0-0' : `${budgetQuarter.year}-${budgetQuarter.quarter}`;

const buildRoadmapInitiativesByFiscalQuarter = (roadmapInitiatives: RoadmapInitiativeDto[]) =>
  roadmapInitiatives.reduce((map, initiative) => {
    const key = buildRoadmapInitiativeKey(initiative.budgetQuarter);

    map.set(key, [...(map.get(key) ?? []), initiative]);

    return map;
  }, new Map<string, RoadmapInitiativeDto[]>());

export default buildRoadmapInitiativesByFiscalQuarter;
