import * as React from 'react';
import { getOperatorLabelForConsole } from '~/neo-ui/packages/table/packages/console/packages/filter/ConsoleRenderFilterFormatter';
import FilterPreviewMultiSelect from '~/neo-ui/packages/filter/packages/filter-headers/filter-header/packages/filter-header-selected-multi-select/FilterPreviewMultiSelect';
import FilterHeaderEmptyMultiSelect from '../../../filter-headers/filter-header/packages/filter-header-selected-multi-select/packages/filter-header-empty-multi-select/FilterHeaderEmptyMultiSelect';
import FilterHeader from '~/neo-ui/packages/filter/packages/filter-headers/filter-header/FilterHeader';
import Theme from '~/neo-ui/packages/color/Theme';
import { FilterValue } from '@AssetManagementClient/BeastClient/Search/Model/Filter.gen';

export type MultiSelectHeaderConsoleFormatterType = (
  filterLabel: string,
  operatorName: string,
  selectedOptions: FilterValue[],
  theme: Theme,
) => (isOpen: boolean) => React.ReactNode;

export const multiSelectHeaderConsoleFormatter: MultiSelectHeaderConsoleFormatterType =
  (filterLabel, operatorName, selectedOptions, theme) => (isOpen: boolean) => {
    const multiSelectFilterPreview = (
      <FilterPreviewMultiSelect
        selectedValues={selectedOptions.map(v => v.label)}
        operator={getOperatorLabelForConsole(operatorName)}
        isOpen={isOpen}
      />
    );

    const headerEmpty = (
      <FilterHeaderEmptyMultiSelect
        label={filterLabel}
        isOpen={isOpen}
      />
    );
    return (
      <FilterHeader
        theme={theme}
        selectedValues={selectedOptions.map(v => v.label)}
        operator={operatorName}
        emptyDisplay={headerEmpty}
        selectedDisplay={multiSelectFilterPreview}
      />
    );
  };
