import useAddonSelectionGet from '~/wm/packages/subscription/packages/purchase-subscription/packages/addon-selection/hooks/useAddonSelectionGet';
import Spinner from '~/neo-ui/spinner/Spinner';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import AddonSelectionOptionCard from '~/wm/packages/subscription/packages/purchase-subscription/packages/addon-selection/packages/addon-selection-option-card/AddonSelectionOptionCard';

const AddonSelection = () => {
  const { formik, goNext } = useMultiStepForm<PurchaseSubscriptionForm>();
  const { addonSelection } = useAddonSelectionGet(
    formik.values.subscriptionPlanId ?? '',
    formik.values.selectedPlanLimit ?? formik.values.defaultPlanLimit ?? 0,
  );

  if (!addonSelection) {
    return (
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </div>
    );
  }
  if (!addonSelection.showSelectionPage) {
    goNext();
  }

  return (
    <div>
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span css={{ fontSize: '2.25rem', fontStyle: 'normal', fontWeight: '400', lineHeight: '133%', marginBottom: '2.063rem' }}>
          {addonSelection.pageTitle}
        </span>
        <span css={{ fontSize: '1rem', fontStyle: 'normal', fontWeight: '400', lineHeight: '150%', marginBottom: '2rem' }}>
          {addonSelection.pageDescription}
        </span>
        {addonSelection.addonOptions.map((addonOption, addonIndex) => (
          <AddonSelectionOptionCard
            key={addonIndex}
            addonOption={addonOption}
          />
        ))}
      </div>
    </div>
  );
};

export default AddonSelection;
