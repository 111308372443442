// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


export enum PatchDeviceDateEnum
{
  WarrantyExpiry = "WarrantyExpiry",
  Purchase = "Purchase",
}



export enum TwoWaySyncStatus
{
  NotApplicable = "NotApplicable",
  Available = "Available",
  ComingSoon = "ComingSoon",
  /**
   * Insinuates that the vendor does not yet support two-way sync but we're working on it
   */
  NotAllowed = "NotAllowed",
}


