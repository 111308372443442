import { SumRecurringFeesDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Recurring/Dto/Model.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { Currency, formatCurrencyByCurrencyInfo } from '~/extensions/packages/currency/formatCurrency';
import React, { Fragment } from 'react';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';

export type RoadmapListViewRecurringFeeProps = { sumRecurringFees: SumRecurringFeesDto; currency: Currency };

/**
 * If there are no recurring fees, display a dash.
 * If there are only monthly recurring fees, display the monthly recurring fees.
 * If there are only yearly recurring fees, display the yearly recurring fees.
 * If there are both monthly and yearly recurring fees, display the monthly recurring fees and show a tooltip with both monthly and yearly recurring fees.
 */
const RoadmapListViewRecurringFee = ({ sumRecurringFees, currency }: RoadmapListViewRecurringFeeProps) => (
  <div
    css={css`
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 0.5rem;
    `}
  >
    {sumRecurringFees.sumRecurringMonthlySubunits === 0 && sumRecurringFees.sumRecurringYearlySubunits === 0 ? (
      <Label>-</Label>
    ) : (
      <Fragment>
        <Label
          css={css`
            text-align: end;
          `}
        >
          {formatCurrencyByCurrencyInfo(
            sumRecurringFees.sumRecurringMonthlySubunits > 0
              ? sumRecurringFees.sumRecurringMonthlySubunits
              : sumRecurringFees.sumRecurringYearlySubunits,
            currency,
          )}
          /{sumRecurringFees.sumRecurringMonthlySubunits > 0 ? 'month' : 'year'}
        </Label>
        {sumRecurringFees.sumRecurringMonthlySubunits > 0 && sumRecurringFees.sumRecurringYearlySubunits > 0 && (
          <Tooltip
            backgroundColor={'light-000'}
            content={
              <div>
                {sumRecurringFees.sumRecurringMonthlySubunits > 0 && (
                  <Label size={'sm'}>
                    {formatCurrencyByCurrencyInfo(sumRecurringFees.sumRecurringMonthlySubunits, currency)} monthly recurring fees
                  </Label>
                )}
                {sumRecurringFees.sumRecurringYearlySubunits > 0 && (
                  <Label size={'sm'}>
                    {formatCurrencyByCurrencyInfo(sumRecurringFees.sumRecurringYearlySubunits, currency)} annually recurring fees
                  </Label>
                )}
              </div>
            }
          >
            <Icon
              icon={'Info'}
              sizePx={12}
              css={css`
                display: flex;
                margin-right: 0.25rem;
                margin-top: -0.05125rem;
              `}
            />
          </Tooltip>
        )}
      </Fragment>
    )}
  </div>
);

export default RoadmapListViewRecurringFee;
