import * as React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import engagementNoteFormValidationSchema from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/validation/engagementNoteFormValidationSchema';
import { EngagementNoteCreateSource } from '~/wm/packages/organization/packages/engagement/model/engagementDataCollectionSources';

export type EngagementNoteCreatePayload = {
  organizationId: string;
  title: string | undefined;
  description: string;
  createdFrom: EngagementNoteCreateSource;
};

export type EngagementNoteFormProps = {
  organizationId: string;
  onCreate: (payload: EngagementNoteCreatePayload) => void;
} & Pick<EngagementNoteCreatePayload, 'createdFrom'>;

export type EngagementNoteFormData = {
  title: string | undefined;
  body: string;
};

const EngagementNoteCreateForm = ({
  organizationId,
  children,
  onCreate,
  createdFrom,
}: React.PropsWithChildren<EngagementNoteFormProps>) => {
  const onSubmit = React.useCallback(
    async (data: EngagementNoteFormData) => {
      onCreate({
        organizationId,
        title: data.title,
        description: data.body,
        createdFrom,
      });
    },
    [createdFrom, onCreate, organizationId],
  );

  const defaultFormData = React.useMemo<EngagementNoteFormData>(
    () => ({
      title: undefined,
      body: '',
    }),
    [],
  );

  return (
    <Form
      submitMethod={'manual'}
      hideSubmissionButton={true}
      hideSubmitStatusIndicator={true}
      defaultFormData={defaultFormData}
      validationSchema={engagementNoteFormValidationSchema}
      onSubmit={onSubmit}
      disableOverwriteDefaultFormDataOnSave={true}
    >
      {children}
    </Form>
  );
};

export default EngagementNoteCreateForm;
