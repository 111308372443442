import * as React from 'react';
import { useState } from 'react';
import EngagementListPageInitiativeLinkContext from './EngagementListPageInitiativeLinkContext';
import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';

export type EngagementListPageInitiativeLinkProviderProps = {
  children: React.ReactNode;
};

/**
 * Follow this GUID for other places where this pattern is used: 32c335c4-5722-4c59-af22-e48551f59474
 *
 * Hacky solution to deal with the ticket create window closing while polling for the engagement list page
 */
const EngagementListPageInitiativeLinkProvider = ({ children }: EngagementListPageInitiativeLinkProviderProps) => {
  const [selectedAction, setSelectedAction] = useState<EngagementActionDto>();
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <EngagementListPageInitiativeLinkContext.Provider
      value={{
        selectedAction,
        setSelectedAction,
        isModalOpen,
        setModalOpen,
      }}
    >
      {children}
    </EngagementListPageInitiativeLinkContext.Provider>
  );
};

export default EngagementListPageInitiativeLinkProvider;
