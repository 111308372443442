import { css } from '@emotion/react';
import Toolbar from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import LayoutHeaderFooterProps from '~/neo-ui/packages/layout/types/LayoutHeaderFooterProps';
import LayoutBar from '~/neo-ui/packages/layout/packages/bar/LayoutBar';
import { useEffect, useRef } from 'react';
import { isIframe } from '~/extensions/packages/iframe/isIframe';

/**
 * A footer that sticks to the bottom of the nav bar.
 */
const LayoutFooterEnceladus = ({
  className,
  leftControls,
  rightControls,
  layoutBarComponent,
  toolbarClassName,
}: LayoutHeaderFooterProps) => {
  const isInsideIframe = isIframe();

  /**
   * Adds required margin to the bottom of the page content
   * to prevent information being hidden at the bottom of the screen
   */
  const footerRef = useRef<HTMLDivElement>(null);
  const body = isInsideIframe ? document.getElementById('wm-content') : document.getElementById('enceladus-main-body');

  const verticalNavBar = isInsideIframe ? null : document.getElementById('enceladus-navigation-vertical-app');

  useEffect(() => {
    if (footerRef.current && body) {
      body.style.paddingBottom = `${footerRef.current.clientHeight}px`;
    }
  }, [footerRef, body]);

  useEffect(() => {
    if (footerRef.current && verticalNavBar) {
      footerRef.current.style.left = `calc(${verticalNavBar.clientWidth}px + 1px)`;
    }
  }, [footerRef, verticalNavBar]);

  return (
    <div
      ref={footerRef}
      className={className}
      css={css`
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1040;
      `}
    >
      {layoutBarComponent && (
        <LayoutBar
          css={css`
            margin-bottom: -0.0625rem;
          `}
        >
          {layoutBarComponent}
        </LayoutBar>
      )}
      {(leftControls || rightControls) && (
        <Toolbar
          leftControls={leftControls}
          rightControls={rightControls}
          css={toolbarClassName}
        />
      )}
    </div>
  );
};

export default LayoutFooterEnceladus;
