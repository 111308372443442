import { css } from '@emotion/react';
import * as React from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';

export type ListProps<T> = {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactElement;
  EmptyListPlaceholder?: string | React.ComponentType;
  orientation?: Orientation;
  children?: React.ReactElement | undefined;
  gap?: string;
};

export enum Orientation {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

/**
 * @deprecated from usage of php classes, replace with using flex box
 */
const List = <T,>({
  items,
  renderItem,
  EmptyListPlaceholder,
  orientation = Orientation.Vertical,
  children,
  gap = '0',
}: ListProps<T>): React.ReactElement<ListProps<T>> =>
  items.length === 0 && EmptyListPlaceholder ? (
    // Show placeholder when no list items
    typeof EmptyListPlaceholder === 'string' ? (
      <Label
        muted={true}
        css={css`
          font-style: italic;
          margin-bottom: 0.9375rem;
        `}
      >
        {EmptyListPlaceholder}
      </Label>
    ) : (
      <EmptyListPlaceholder />
    )
  ) : (
    <div
      className={(() => {
        switch (orientation) {
          case Orientation.Horizontal:
            return 'd-flex flex-row align-items-center flex-wrap';
          case Orientation.Vertical:
            return 'd-flex flex-column';
        }
      })()}
      css={css`
        gap: ${gap};
      `}
    >
      {children}
      {items.map(renderItem)}
    </div>
  );

export default List;
