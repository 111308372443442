import { ProgressPayload } from '~/neo-ui/packages/progress/state/model/ProgressState';

/**
 * For defining the spec
 */
export type ProgressDefinition<TContext> = (context: TContext) => ProgressPayload;

/**
 * For engine consumption
 */
export type BuildProgress<TContext> = (context: TContext) => Required<ProgressPayload>;

/**
 * Convert definition to a consumable structure, populating with all default values
 */
const buildProgress = <TContext>(progress?: ProgressDefinition<TContext>): BuildProgress<TContext> | undefined =>
  progress
    ? (context: TContext) => {
        const progressPayload = progress(context);
        return {
          ...progressPayload,
          entitiesToLock: progressPayload.entitiesToLock ?? [],
          onCompletion: progressPayload.onCompletion ?? (() => {}),
        };
      }
    : undefined;

export default buildProgress;
