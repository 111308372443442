import useApi from '~/wm/packages/api/hook/useApi';
import { useEffect, useState } from 'react';
import { SubscriptionSummary } from '@SubscriptionClient/BeastClient/Beast/Msp/Billing/PaymentSummary/Dto.gen';
import { paymentSummarySubscriptionSummaryGet } from '@SubscriptionClient/SubscriptionClientMsp.gen';

const usePaymentSummarySubscriptionSummaryGet = () => {
  const { callApi } = useApi();
  const [subscriptionSummaries, setSubscriptionSummaries] = useState<SubscriptionSummary[]>();
  const [productSquareIcons, setProductSquareIcons] = useState<string[]>();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => paymentSummarySubscriptionSummaryGet({}));
      if (!response?.subscriptionSummaries || !response?.productSquareIcons) {
        return;
      }

      setSubscriptionSummaries(response.subscriptionSummaries);
      setProductSquareIcons(response.productSquareIcons);
    })();
  }, [callApi]);
  return { subscriptionSummaries, productSquareIcons };
};

export default usePaymentSummarySubscriptionSummaryGet;
