import { ScopeKey } from '@AssetManagementClient/BeastClient/Beast/Settings/Scope.gen';
import { css } from '@emotion/react';

import { Response as WarrantyClientWarrantyCoveragePackagesSettingsPackagesAvailabilitiesControllerWarrantyCoverageSettingsAvailabilitiesGetControllerNestedResponse } from '@WarrantyClient/Warranty/Coverage/Packages/Settings/Packages/Availabilities/Controller/WarrantyCoverageSettingsAvailabilitiesGetControllerNested.gen';
import { coverageAvailabilities } from '@WarrantyClient/WarrantyClientMsp.gen';
import * as React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import SettingsConfigurable from '~/wm/packages/settings/packages/configurable/SettingsConfigurable';
import WarrantyCoverageDueSoonThresholdSettingsForm from '~/wm/packages/warranty/packages/coverage/packages/settings/configurable/WarrantyCoverageDueSoonThresholdSettingsForm';
import WarrantyCoverageOpportunitySettingsForm from '~/wm/packages/warranty/packages/coverage/packages/settings/configurable/WarrantyCoverageOpportunitySettingsForm';
import warrantyCoverageSettingsValidationSchema from '~/wm/packages/warranty/packages/coverage/packages/settings/validation/warrantyCoverageSettingsValidationSchema';

export type WarrantyCoverageSettingsConfigurableProps = {
  scopeKey: ScopeKey;
};

const WarrantyCoverageSettingsConfigurable: React.FunctionComponent<WarrantyCoverageSettingsConfigurableProps> = ({ scopeKey }) => {
  const { callApi } = useApi();
  const [availabilities, setAvailabilities] =
    React.useState<WarrantyClientWarrantyCoveragePackagesSettingsPackagesAvailabilitiesControllerWarrantyCoverageSettingsAvailabilitiesGetControllerNestedResponse>();

  React.useEffect(() => {
    (async () => {
      const response = await callApi(() => coverageAvailabilities({}));
      if (!response) {
        return;
      }
      setAvailabilities(response);
    })();
  }, [callApi]);

  if (!availabilities) {
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
      `}
    >
      <SettingsConfigurable
        settingsKey={{
          scopeKey,
          configurableId: 'warranty-coverage-opportunity',
        }}
        validationSchema={warrantyCoverageSettingsValidationSchema(availabilities?.availabilities)}
        label={'Cart defaults'}
      >
        {({ isUsingDefault }) => (
          <WarrantyCoverageOpportunitySettingsForm
            availabilities={availabilities?.availabilities}
            disabled={isUsingDefault}
          />
        )}
      </SettingsConfigurable>
      <SettingsConfigurable
        settingsKey={{
          scopeKey,
          configurableId: 'warranty-coverage-due-soon-threshold',
        }}
        label="Due soon for warranty coverage"
        description='Days before a hardware asset&apos;s expiration date that it becomes "Due soon" for warranty coverage.'
      >
        {({ isUsingDefault }) => (
          <WarrantyCoverageDueSoonThresholdSettingsForm
            availabilities={availabilities?.availabilities}
            disabled={isUsingDefault}
          />
        )}
      </SettingsConfigurable>
    </div>
  );
};

export default WarrantyCoverageSettingsConfigurable;
