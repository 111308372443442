import { css } from '@emotion/react';
import * as React from 'react';
import ActionButton from '~/neo-ui/packages/button/packages/action-button/ActionButton';
import Image from '~/neo-ui/packages/image/Image';
import TooltipSection from '~/neo-ui/packages/tooltip/packages/tooltip-section/TooltipSection';
import PdDisplayInfo from '~/wm/packages/subscription/packages/partner-development/packages/pd-contact-info/model/PdDisplayInfo';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type PdContactSectionProps = {
  pdDisplayInfo: PdDisplayInfo;
  /**
   * Provide a reason to contact the PD team member. e.g., "To talk about enterprise solutions"
   */
  reasonToContact: string;
};

const PdContactSection: React.FunctionComponent<PdContactSectionProps> = ({ pdDisplayInfo, reasonToContact }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
    `}
  >
    <Image
      css={css`
        height: 68px;
        width: 68px;
        border-radius: 50%;
        margin-right: 1.25rem;
      `}
      source={pdDisplayInfo.imageSource}
    />
    <div>
      <p
        css={css`
          margin-bottom: 0;
        `}
      >
        Get in touch with
      </p>
      <h3
        css={css`
          color: ${colorToCode('primary-400')};
          font-weight: bold;
          margin-bottom: 0.3125rem;
        `}
      >
        {pdDisplayInfo.label}
      </h3>
      <p
        css={css`
          margin-bottom: 0;
          color: ${colorToCode('dark-900-32')};
          font-size: 12px;
        `}
      >
        {reasonToContact}
      </p>
    </div>
    <div
      css={css`
        display: flex;
        margin-left: auto;
      `}
    >
      <ActionButton
        css={css`
          margin-right: 0.625rem;
        `}
        icon={'Email'}
        activeBackgroundColor={'primary-400'}
        inactiveBackgroundColor={'primary-400'}
        activeBorderColor={'primary-400'}
        inactiveBorderColor={'primary-400'}
        inactiveIconColor={'light-000'}
        activeIconColor={'light-000'}
        actionUrl={pdDisplayInfo.emailUrl}
        opensInNewTab={true}
        tooltipContent={
          <TooltipSection
            title={'Send an email'}
            description={'Opens in your mail app'}
          />
        }
      />
      <ActionButton
        icon={'Calendar'}
        activeBackgroundColor={'primary-400'}
        inactiveBackgroundColor={'primary-400'}
        activeBorderColor={'primary-400'}
        inactiveBorderColor={'primary-400'}
        inactiveIconColor={'light-000'}
        activeIconColor={'light-000'}
        actionUrl={pdDisplayInfo.meetingUrl}
        opensInNewTab={true}
        tooltipContent={
          <TooltipSection
            title={'Schedule a meeting'}
            description={'Opens in a new tab'}
          />
        }
      />
    </div>
  </div>
);

export default PdContactSection;
