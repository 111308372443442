import { AddonSelectionOption } from '@SubscriptionClient/BeastClient/Billing/Subscription/Purchase/Addon/Model.gen';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import ToolTip from '~/sp-ui/tool-tip';
import { InfoIconTooltip, SuccessIcon } from '~/sp-ui/icons';
import { Button } from '~/sp-ui/buttons';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import { useState } from 'react';
import { mobileBreakpointRem, tabletBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import theme from '~/sp-ui/theme';

export type AddonSelectionOptionCardProps = {
  addonOption: AddonSelectionOption;
};
const AddonSelectionOptionCard = ({ addonOption }: AddonSelectionOptionCardProps) => {
  const { formik } = useMultiStepForm<PurchaseSubscriptionForm>();
  const [isSelected, setIsSelected] = useState(formik.values.selectedAddonIds.includes(addonOption.addonId));

  const handleSelectAction = (addonId: string, addonPrice: number) => {
    const selectedAddons = formik.values.selectedAddonIds;
    if (!selectedAddons.includes(addonId)) {
      selectedAddons.push(addonId);
      const addonSubtotal = formik.values.addonSubtotal + addonPrice;
      formik.setFieldValue('selectedAddonIds', selectedAddons, false);
      formik.setFieldValue('addonSubtotal', addonSubtotal, false);
      setIsSelected(true);
    } else {
      const filteredAddon = selectedAddons.filter(item => item !== addonId);
      const addonSubtotal = formik.values.addonSubtotal - addonPrice;
      formik.setFieldValue('selectedAddonIds', filteredAddon, false);
      formik.setFieldValue('addonSubtotal', addonSubtotal, false);
      setIsSelected(false);
    }
  };

  return (
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: 'auto 15rem',
        gridGap: '2rem',
        borderRadius: '8px',
        boxShadow: `0px 1px 4px 0px ${colorToCode('dark-000')}`,
        padding: '1.5rem',
        height: 'min-content',
        width: '53rem',
        marginBottom: '2rem',
        backgroundColor: colorToCode('light-000'),
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <h4>{addonOption.name}</h4>
        <span css={{ marginBottom: '0.75rem', maxWidth: '25rem' }}>{addonOption.description}</span>
        {addonOption.inclusions.map((inclusion, inclusionIndex) => (
          <div
            key={inclusionIndex}
            css={{ display: 'flex', alignItems: 'center' }}
          >
            <SuccessIcon css={{ marginRight: '0.5rem' }} />
            {inclusion.label}
            {inclusion.tooltip && (
              <ToolTip content={<p css={{ maxWidth: '15rem', fontSize: '14px' }}>{inclusion.tooltip}</p>}>
                {({ anchorProps, setAnchorRef }) => {
                  return (
                    <InfoIconTooltip
                      css={{ height: '1rem' }}
                      ref={setAnchorRef}
                      {...anchorProps}
                    />
                  );
                }}
              </ToolTip>
            )}
          </div>
        ))}
      </div>
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Button
          css={{
            height: '1.5rem',
            marginBottom: '0.625rem',
            width: '14rem',
            [`@media (max-width: ${tabletBreakpointRem}rem)`]: {
              width: '10rem',
            },
            [`@media (max-width: ${mobileBreakpointRem}rem)`]: {
              width: '8rem',
            },
          }}
          type={'submit'}
          size={'small'}
          disabled={addonOption.isOwned}
          iconLeft={<div>{isSelected && <SuccessIcon />}</div>}
          variant={formik.values.selectedAddonIds.includes(addonOption.addonId) ? 'success' : 'secondary'}
          onClick={() => handleSelectAction(addonOption.addonId, addonOption.price)}
        >
          {addonOption.isOwned ? 'Already in your plan' : isSelected ? 'Added ' : `Add for ${formatCurrency(addonOption.price, 'USD')}/mo*`}
        </Button>
        {/* An addon cannot be both selected and owned at the same time, so only one of the texts will be displayed below */}
        {isSelected && <span>Added for {formatCurrency(addonOption.price, 'USD')}/mo*</span>}
        {addonOption.isOwned && <span css={{ color: `${theme.palette.grey2}` }}>At {formatCurrency(addonOption.price, 'USD')}/mo*</span>}
      </div>
    </div>
  );
};

export default AddonSelectionOptionCard;
