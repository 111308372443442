import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { GroupBase } from 'react-select';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import SingleSelectMenu from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-menu/SingleSelectMenu';
import Theme from '~/neo-ui/packages/color/Theme';
import SingleSelectOption from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-option/SingleSelectOption';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import SingleSelectInternal from '~/neo-ui/packages/select/packages/single-select/SingleSelectInternal';
import SingleSelectPresentationValueContainer from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-presentation-value-container/SingleSelectPresentationValueContainer';
import OutsideClickHandler from '~/neo-ui/packages/interactions/packages/outside-click-handler/OutsideClickHandler';

export type SingleSelectPresentationProps<T extends string = string> = {
  options: SelectOption<T>[] | GroupBase<SelectOption<T>>[];
  selectedOption: SelectOption<T> | undefined;
  onOptionSelected: (option: SelectOption<T>) => void;
  disabled?: boolean;
  theme: Theme;
  icon: IconType;
  label: string;
} & Styleable;

const SingleSelectPresentation = <T extends string>({
  options,
  selectedOption,
  onOptionSelected,
  className,
  disabled = false,
  theme,
  icon,
  label,
}: SingleSelectPresentationProps<T>) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const selectRef = React.useRef<HTMLDivElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);

  return (
    <OutsideClickHandler
      refs={[selectRef, menuRef]}
      onClickOutside={() => {
        setMenuOpen(false);
      }}
    >
      <div
        onClick={e => {
          if (disabled) {
            return;
          }
          e.stopPropagation();
          setMenuOpen(!menuOpen);
        }}
      >
        <SingleSelectInternal
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          options={options}
          selectedOption={selectedOption}
          onOptionSelected={option => {
            onOptionSelected(option);
          }}
          className={className}
          isSearchable={false}
          disabled={disabled}
          components={{
            Option: SingleSelectOption,
            Menu: props => SingleSelectMenu(props),
            /**
             * Hacky solution that makes a ValueContainer mimic a replacement Control component
             * to allow passing props without breaking how the Menu's css location is calculated internally
             */
            ValueContainer: props =>
              SingleSelectPresentationValueContainer({
                props,
                selectRef,
                icon,
                theme,
                disabled,
                label,
                onClick: () => setMenuOpen(prevState => !prevState),
              }),
            SingleValue: () => null,
            IndicatorsContainer: () => null,
            Input: () => null,
          }}
          styles={{
            menu: (provided: Record<string, unknown>) => ({
              ...provided,
              padding: '0.625rem',
              borderRadius: '0.625rem',
            }),

            control: (provided: Record<string, unknown>) => ({
              ...provided,
              borderStyle: 'none',
            }),

            valueContainer: (provided: Record<string, unknown>) => ({
              ...provided,
              padding: 'unset',
            }),
          }}
        />
      </div>
    </OutsideClickHandler>
  );
};

export default SingleSelectPresentation;
