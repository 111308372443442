import { PdContactInfo } from '@SubscriptionClient/BeastClient/Beast/PartnerDevelopment/Model/PdTeam.gen';
import { Case } from '@SubscriptionClient/BeastClient/Beast/PartnerDevelopment/Model/PdTeam/PdContactInfoNested.gen';
import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import PdDisplayInfo from '~/wm/packages/subscription/packages/partner-development/packages/pd-contact-info/model/PdDisplayInfo';

export const usePdContact = (getPdContact: () => Promise<PdContactInfo>) => {
  const [isLoading, setLoading] = useState(true);
  const [pdContactInfo, setPdContactInfo] = useState<PdContactInfo | undefined>(undefined);
  const [pdDisplayInfo, setDisplayInfo] = useState<PdDisplayInfo | undefined>(undefined);

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const pdContactInfo = await callApi(getPdContact);
      setLoading(false);

      if (!pdContactInfo) {
        return;
      }

      setPdContactInfo(pdContactInfo);

      const emailSubject = 'Get in touch with ScalePad';

      setDisplayInfo({
        imageSource: pdContactInfo.enum === Case.PdAssigned ? pdContactInfo.profileImageUrl : pdContactInfo.icon,
        label: pdContactInfo.enum === Case.PdAssigned ? pdContactInfo.firstName : 'ScalePad',
        emailUrl: `mailto:${pdContactInfo.emailAddress}?subject=${encodeURIComponent(emailSubject)}`,
        meetingUrl: pdContactInfo.scheduleMeetingUrl,
      });
    })();
  }, [callApi, getPdContact]);

  return { isLoading, pdContactInfo, pdDisplayInfo };
};
