import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { treeImpactOrderInfoGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { TreeImpactOrderInfoDto } from '@AssetManagementClient/BeastClient/Beast/TreeImpact/Dto/Model.gen';

const useTreeImpactOrderInfoGet = (serviceId: string, serviceOrderId: string) => {
  const [isLoading, setLoading] = useState(true);

  const [treeImpactOrderInfo, setTreeImpactOrderInfo] = useState<TreeImpactOrderInfoDto>();

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => treeImpactOrderInfoGet({ serviceId, relatedOrderId: serviceOrderId }));
      setLoading(false);

      if (!response) {
        return;
      }

      setTreeImpactOrderInfo(response.treeImpactOrderInfo);
    })();
  }, [callApi, serviceId, serviceOrderId]);

  return { isLoading, treeImpactOrderInfo };
};

export default useTreeImpactOrderInfoGet;
