import { ContractAgreementDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Agreement/Dto/Model.gen';
import { ContractAgreementRow } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/packages/contract-sync-window/ContractSyncWindow';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Color from '~/neo-ui/packages/color/Color.gen';
import { useMemo } from 'react';
import { css } from '@emotion/react';
import buildContractAgreementRow from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/builder/buildContractAgreementRow';
import TableSelectable from '~/neo-ui/packages/table/packages/table-selectable/TableSelectable';
import { TableClientSideColumn } from '~/neo-ui/packages/table/packages/table-client-side/TableClientSide';

export type ContractSyncTableProps = {
  title: string;
  agreements: ContractAgreementDto[];
  includeAgreementItems: boolean;
  updateAgreementIds: (agreementIds: Set<string>) => void;
  columns: TableClientSideColumn<ContractAgreementRow>[];
  tableHeaderColor: Color;
};

const ContractSyncTable = ({
  title,
  agreements,
  includeAgreementItems,
  updateAgreementIds,
  columns,
  tableHeaderColor,
}: ContractSyncTableProps) => {
  const contractAgreementRows = useMemo(
    () =>
      agreements.reduce((rows: ContractAgreementRow[], agreement: ContractAgreementDto) => {
        const agreementRow = buildContractAgreementRow(agreement);
        rows.push(agreementRow);

        if (includeAgreementItems) {
          const agreementItemRows = agreement.agreementItems.map(agreementItem => buildContractAgreementRow(agreement, agreementItem));
          rows.push(...agreementItemRows);
        }

        return rows;
      }, []),
    [agreements, includeAgreementItems],
  );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <Header
        size={4}
        weight={'bold'}
      >
        {title}
      </Header>
      <TableSelectable
        data={contractAgreementRows}
        dataKey={'agreementId'}
        columns={columns}
        dataTableProps={{ children: ({ Table }) => <Table tableHeadColor={tableHeaderColor} /> }}
        updateSelectedIds={updateAgreementIds}
      />
    </div>
  );
};

export default ContractSyncTable;
