import * as React from 'react';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import SingleSelect from '~/neo-ui/packages/select/packages/single-select/SingleSelect';
import useContractListContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/context/hooks/useContractListContext';
import useContractTemplateAvailabilitiesContext from '~/wm/packages/strategy/packages/contract/packages/contract-template/context/hooks/useContractTemplateAvailabilitiesContext';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { ContractCreateFormData } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-create-button/packages/contract-create-form/ContractCreateForm';
import useLoadContractFromTemplate from '~/wm/packages/strategy/packages/contract/packages/contract-template/packages/hooks/useLoadContractFromTemplate';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';

const ContractTemplateAvailabilitySelect: React.FunctionComponent = () => {
  const [selectedContractTemplate, setSelectedContractTemplate] = React.useState<SelectOption | undefined>(undefined);

  const { contractBillingCurrency } = useContractListContext();

  const { contractTemplateAvailabilities } = useContractTemplateAvailabilitiesContext();

  const { loadContractFromTemplate } = useLoadContractFromTemplate();

  const { setFormData } = useFormContext<ContractCreateFormData>();

  if (contractTemplateAvailabilities.length === 0) {
    return null;
  }

  const contractTemplateAvailabilityOptions = contractTemplateAvailabilities.map(
    (contractTemplateAvailability): SelectOption => ({
      label: contractTemplateAvailability.nameDisplay,
      value: contractTemplateAvailability.contractTemplateId,
    }),
  );

  return (
    <div
      css={css`
        display: flex;
        gap: 1rem;
        align-items: center;
        width: 50%;
      `}
    >
      <Label
        size={'md'}
        bold={true}
      >
        Template
      </Label>
      <div
        css={css`
          flex-grow: 1;
        `}
      >
        <SingleSelect
          shouldUseMenuPortal={false}
          options={contractTemplateAvailabilityOptions}
          selectedOption={selectedContractTemplate}
          onOptionSelected={async option => {
            setSelectedContractTemplate(option);
            loadContractFromTemplate(option.value, contractBillingCurrency, setFormData);
          }}
          placeholder={'Choose a template...'}
        />
      </div>
    </div>
  );
};

export default ContractTemplateAvailabilitySelect;
