import { ReactNode, useState } from 'react';
import useInitiativeAssetSelect from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/hooks/useInitiativeAssetSelect';
import useItemSelection from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/hooks/useItemSelection';
import InitiativeAssetSelectContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/context/InitiativeAssetSelectContext';

export type InitiativeAssetSelectProvider = {
  initialDisplayedAssetIds?: string[];
  children: ReactNode;
};

const InitiativeAssetSelectProvider = ({ initialDisplayedAssetIds = [], children }: InitiativeAssetSelectProvider) => {
  const { assetIds, clearAssetIds } = useInitiativeAssetSelect();
  const { selectedItems, lastItem, selectItems, deselectItems, selectItem, deselectItem, clearSelectedItems } = useItemSelection<string>();
  const [displayedAssetIds, setDisplayedAssetIds] = useState<string[]>(initialDisplayedAssetIds);

  return (
    <InitiativeAssetSelectContext.Provider
      value={{
        assetIds,
        clearAssetIds,
        selectedAssetIds: selectedItems,
        lastSelectedAssetId: lastItem,
        selectAssetIds: selectItems,
        deselectAssetIds: deselectItems,
        selectAssetId: selectItem,
        deselectAssetId: deselectItem,
        clearSelectedAssetIds: clearSelectedItems,
        displayedAssetIds,
        setDisplayedAssetIds,
      }}
    >
      {children}
    </InitiativeAssetSelectContext.Provider>
  );
};

export default InitiativeAssetSelectProvider;
