import { FunctionComponent, Fragment, useMemo, useState, useEffect } from 'react';
import SatelliteBanner from '~/wm/packages/sp-account/packages/satellite-banner/SatelliteBanner';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { mobileBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import PublicProductInfo, { PublicProductDto } from '~/wm/packages/sp-account/packages/public-page/PublicProductInfo';

interface PublicPageProps {
  publicProductDtos: PublicProductDto[];
  signupUrl: string;
  signinUrl: string;
}

const PublicPage: FunctionComponent<PublicPageProps> = ({ publicProductDtos, signupUrl, signinUrl }) => {
  const maxBodyWidth = '75rem';
  const [queryProduct, setQueryProduct] = useState<string | null>(null);
  const publicProductInfos = useMemo(
    () =>
      publicProductDtos.map((publicProductDto, key) => (
        <PublicProductInfo
          key={key}
          publicProductDto={publicProductDto}
          isOpenByDefault={queryProduct === publicProductDto.ProductAbbreviation}
        />
      )),
    [publicProductDtos, queryProduct],
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setQueryProduct(queryParams.get('product'));
  }, []);

  return (
    <Fragment>
      <SatelliteBanner height={'30rem'}>
        <div
          css={{
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            color: colorToCode('light-000'),
            textAlign: 'center',
          }}
        >
          <div
            css={{
              fontFamily: 'Roboto',
              fontSize: '3.75rem',
              fontWeight: 300,
              lineHeight: '4.375rem',
              letterSpacing: 0,
              [`@media (max-width: ${mobileBreakpointRem}rem)`]: {
                marginTop: 0,
              },
            }}
          >
            Get started with a ScalePad Account
          </div>
          <Label
            size={'lg'}
            color={'light-000'}
            css={{ margin: '1.480625rem 0 1.5rem' }}
          >
            Open the doors to all of our products and elevate your clients’ experience.
          </Label>
          <a
            href={signupUrl}
            css={{
              background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)), linear-gradient(0deg, #BB4A26, #BB4A26)',
              padding: '0.875rem 2rem 0.875rem 2rem',
              border: '1px solid rgba(0, 0, 0, 0.16)',
              borderRadius: '0.5rem',
              color: colorToCode('light-000'),
              fontSize: '1rem',
              lineHeight: '1.171875rem',
              [':hover']: {
                background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(0deg, #BB4A26, #BB4A26)',
                color: colorToCode('light-400'),
                textDecoration: 'none',
              },
            }}
          >
            Sign up now
          </a>
          <div css={{ marginTop: '1.25rem' }}>
            Already have an account?{' '}
            <a
              href={signinUrl}
              css={{
                color: colorToCode('light-000'),
                textDecoration: 'underline',
                [':hover']: {
                  color: colorToCode('light-400'),
                },
              }}
            >
              Sign in
            </a>
          </div>
        </div>
      </SatelliteBanner>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '2rem',
          maxWidth: maxBodyWidth,
          margin: `4rem 2rem 6.5rem calc(max((100vw - ${maxBodyWidth})/2, 2rem))`,
          [`@media (max-width: ${mobileBreakpointRem}rem)`]: {
            gridTemplateColumns: '1fr',
            margin: '4rem 1rem 6.5rem 1rem',
          },
        }}
      >
        {publicProductInfos}
      </div>
    </Fragment>
  );
};

export default PublicPage;
