import { number, object } from 'yup';

const warrantyCartFilterFormValidationSchema =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object().shape<any>({
    assetsBetweenYearsFrom: number().min(0, 'Must be greater than or equal to 0.').max(99, 'Must be less than 100.'),
    assetsBetweenYearsTo: number().min(0, 'Must be greater than or equal to 0.').max(99, 'Must be less than 100.'),
  });

export default warrantyCartFilterFormValidationSchema;
