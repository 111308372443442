import React from 'react';
import { toFieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import FormSelectInput from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import { Enum as InputTypeEnum } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Credential/InputTypeNested.gen';
import FormTextInput from '~/neo-ui/packages/form/packages/form-input/packages/form-text-input/FormTextInput';

import FormTextboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-textbox-input/FormTextboxInput';
import { css } from '@emotion/react';
import { SanitizedCredentialFieldDisplaySettingsDto } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/packages/credential-display-modes/packages/credential-form/IntegrationCredentialForm';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import IntegrationFormInputTitle from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/packages/credential-display-modes/packages/credential-form/packages/credential-inputs/packages/IntegrationFormInputTitle';
import FormCheckboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckboxInput';
import IntegrationFormInputSection from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/packages/credential-display-modes/packages/credential-form/packages/credential-inputs/packages/IntegrationFormInputSection';

type InputTypeMapProps = {
  inputs: { [key in string]: SanitizedCredentialFieldDisplaySettingsDto };
};

const InputTypeMap: React.FunctionComponent<InputTypeMapProps> = ({ inputs }) => {
  const toInputSection = (credentialFieldInput: SanitizedCredentialFieldDisplaySettingsDto) => {
    if (credentialFieldInput.displayOnly) {
      return (
        <div
          key={credentialFieldInput.name}
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          <IntegrationFormInputTitle title={credentialFieldInput.label + ': '} /> {credentialFieldInput.value}
        </div>
      );
    }

    return inputDtoToComponent(credentialFieldInput);
  };

  const inputDtoToComponent = (credentialFieldInput: SanitizedCredentialFieldDisplaySettingsDto) => {
    if (credentialFieldInput.displayOnly) {
      return <Label>{credentialFieldInput.value}</Label>;
    }

    const fieldKey = toFieldKeyExpression(credentialFieldInput.name);
    switch (credentialFieldInput.inputType.type) {
      case InputTypeEnum.Text:
        return (
          <IntegrationFormInputSection
            key={credentialFieldInput.name}
            title={credentialFieldInput.label}
            description={credentialFieldInput.description}
          >
            <FormTextInput
              fieldKey={fieldKey}
              placeholder={credentialFieldInput.value}
            />
          </IntegrationFormInputSection>
        );

      case InputTypeEnum.InteractiveOAuth:
        return (
          // OAuth is handled in OauthTileController.tsx
          <></>
        );

      case InputTypeEnum.Select:
        return (
          <IntegrationFormInputSection
            key={credentialFieldInput.name}
            title={credentialFieldInput.label}
            description={credentialFieldInput.description}
          >
            <FormSelectInput
              fieldKey={fieldKey}
              options={credentialFieldInput.inputType.options ?? []}
            />
          </IntegrationFormInputSection>
        );

      case InputTypeEnum.TextSecure:
        return (
          <IntegrationFormInputSection
            key={credentialFieldInput.name}
            title={credentialFieldInput.label}
            description={credentialFieldInput.description}
          >
            <FormTextboxInput
              fieldKey={fieldKey}
              type={'password'}
              placeholder={credentialFieldInput.isInputRequired ? '' : 'Leave blank to keep'}
            />
          </IntegrationFormInputSection>
        );

      case InputTypeEnum.Toggle:
        return (
          <FormCheckboxInput
            key={credentialFieldInput.name}
            fieldKey={fieldKey}
            description={
              <>
                {credentialFieldInput.label}
                <Label
                  size={'sm'}
                  color={'dark-900-64'}
                >
                  {credentialFieldInput.description}
                </Label>
              </>
            }
          />
        );
      default:
        return undefined;
    }
  };
  return (
    <div
      css={css`
        padding-top: 1.5rem;
      `}
    >
      {Object.keys(inputs).map(inputKey => toInputSection(inputs[inputKey]))}
    </div>
  );
};
export default InputTypeMap;
