import { ChargeDto } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/PricingModel.gen';
import { css } from '@emotion/react';
import Info from '~/legacy-ui/packages/info/Info';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import * as React from 'react';
import { ChargeItem } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription/Product/Charge.gen';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ProductChargeItemProps = {
  productLabel?: string | undefined;
  chargeDtos: ChargeDto[] | ChargeItem[];
};

const ProductChargeItem = ({ productLabel, chargeDtos }: ProductChargeItemProps) => (
  <div>
    {chargeDtos.map((chargeDto: ChargeDto) => (
      <div key={[chargeDto.defaultAmount, productLabel, chargeDto.label].join('.')}>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <Info
            css={css`
              &:not(:last-child) {
                margin-bottom: 0.5rem;
              }
            `}
            title={!productLabel ? chargeDto.label : productLabel + ' ' + chargeDto.label.toLowerCase()}
            titleSize={'md'}
            titleColor={'dark-700'}
            titleFontWeight="normal"
            description={'Billed once'}
            descriptionColor={'dark-050'}
          />
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: stretch;
            `}
          >
            {chargeDto.defaultAmount !== chargeDto.currentAmount ? (
              <Info
                css={css`
                  margin-right: 5px;
                `}
                title={`${formatCurrency(chargeDto.defaultAmount, 'USD')}`}
                titleStrikethrough={true}
                titleSize={'sm'}
                titleColor={'dark-900-32'}
              />
            ) : (
              <div />
            )}
            <Info
              title={`${formatCurrency(chargeDto.currentAmount, 'USD')}`}
              titleSize={'sm'}
              titleColor={'secondary-400'}
            />
          </div>
        </div>
        <div>
          <hr
            css={css`
              margin-top: 0.625rem;
              margin-bottom: 1rem;
              color: ${colorToCode('dark-900-12')};
            `}
          />
        </div>
      </div>
    ))}
  </div>
);

export default ProductChargeItem;
