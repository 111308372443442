// Basic component template

import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Box from '~/neo-ui/packages/box/Box';
import Theme from '~/neo-ui/packages/color/Theme';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import { Styleable } from '~/neo-ui/model/capacity';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type CalloutLinkProps = {
  theme: Theme;
  icon: IconType;
  headerText: string;
  descriptionText?: string;
  buttonText: string;
  linkUrl: string;
} & Styleable;
export const CalloutLink: React.FunctionComponent<CalloutLinkProps> = ({
  theme,
  icon,
  headerText,
  descriptionText,
  buttonText,
  linkUrl,
  className,
}) => {
  const { themeMap } = useTheme(theme);
  return (
    <Box
      css={css`
        flex-direction: row;
        display: flex;
        align-items: center;
        background-color: ${colorToCode('light-100')};
      `}
      className={className}
      borderRadius={'radius300'}
      padding={'padding100'}
    >
      <Icon
        icon={icon}
        color={themeMap.foregroundLowered}
      />
      <div
        css={css`
          margin-left: 0.5rem;
          margin-right: auto;
        `}
      >
        <Label
          size={'md'}
          color={themeMap.foregroundLowered}
          bold={true}
          css={css`
            line-height: 1rem;
          `}
        >
          {headerText}
        </Label>
        {descriptionText && (
          <Label
            size={'sm'}
            color={'dark-900-64'}
          >
            {descriptionText}
          </Label>
        )}
      </div>
      <Button
        iconRight={'GoExternal'}
        anchor={{ href: linkUrl, openInNewTab: true }}
        // If we don't set height: auto and we send an anchor prop, the <a> tag will have a 1.5rem height (for some reason??)
        css={css`
          height: auto;
        `}
      >
        {buttonText}
      </Button>
    </Box>
  );
};
