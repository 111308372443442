import React from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useAutoEmailReportDelete from '../../hooks/useAutoEmailReportDelete';

export type AutoEmailReportDeleteModalProps = {
  autoEmailReportId: string;
  reportTitle: string;
  isOpen: boolean;
  onDismiss: () => void;
  onDelete: () => void;
};

const AutoEmailReportDeleteModal: React.FunctionComponent<AutoEmailReportDeleteModalProps> = ({
  autoEmailReportId,
  reportTitle,
  isOpen,
  onDismiss,
  onDelete,
}) => {
  const { organizationId } = useOrganizationContext();
  const { deleteAutoEmailReport, isDeletingAutoEmailReport } = useAutoEmailReportDelete(organizationId, {
    onSuccess: onDelete,
  });

  return (
    <ModalConfirmation
      header={{ title: 'Delete auto email report?', icon: 'Trash' }}
      theme={'negative'}
      footer={{
        confirmButton: {
          icon: 'Trash',
          label: 'Delete',
          loading: isDeletingAutoEmailReport,
          disabled: isDeletingAutoEmailReport,
          onClick: () => deleteAutoEmailReport(autoEmailReportId),
        },
        dismissButton: {
          icon: 'Cancel',
          label: 'Cancel',
          disabled: isDeletingAutoEmailReport,
          onClick: onDismiss,
        },
      }}
      isOpen={isOpen}
    >
      {reportTitle} will be permanently deleted.
    </ModalConfirmation>
  );
};

export default AutoEmailReportDeleteModal;
