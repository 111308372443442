import Testable from '~/neo-ui/packages/testable/Testable';
import Button from '~/neo-ui/packages/button/Button';
import * as React from 'react';
import WizardPageStep from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageStep';

export type WizardPageProgressControlProps<T> = {
  isCurrentStepComplete: boolean | undefined;
  nextStep: WizardPageStep<T> | undefined;
  onClick: () => void;
};

const WizardPageProgressControl = <T,>({ isCurrentStepComplete, nextStep, onClick }: WizardPageProgressControlProps<T>) => ({
  expanded: (
    <Testable testId={'wizard-proceed-button'}>
      <Button
        theme={isCurrentStepComplete ? 'secondary' : undefined}
        iconRight={'GoRight'}
        disabled={!isCurrentStepComplete}
        onClick={onClick}
      >
        Continue to {nextStep?.header.label}
      </Button>
    </Testable>
  ),
  shrank: (
    <Testable testId={'wizard-proceed-button'}>
      <Button
        theme={isCurrentStepComplete ? 'secondary' : undefined}
        iconRight={'GoRight'}
        iconLeft={nextStep?.header.icon}
        disabled={!isCurrentStepComplete}
        onClick={onClick}
      >
        Continue
      </Button>
    </Testable>
  ),
});

export default WizardPageProgressControl;
