import { SVGProps, forwardRef, memo } from 'react';

const WarningIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
      >
        <path
          d="M11.1221 2.60948C11.5011 1.91461 12.4989 1.91461 12.8779 2.60948L23.1934 21.5211C23.5568 22.1875 23.0745 23 22.3155 23H1.68454C0.925483 23 0.44317 22.1875 0.806647 21.5211L11.1221 2.60948Z"
          fill="#EBAB00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 8H11V15H13V8ZM13 17H11V19H13V17Z"
          fill="#2F3037"
        />
      </svg>
    );
  }),
);

export default WarningIcon;
