import Icon from '~/neo-ui/packages/icon/Icon';
import React from 'react';
import { css } from '@emotion/react';
import { Styleable } from '~/neo-ui/model/capacity';
import Color from '~/neo-ui/packages/color/Color.gen';

type FilterHeaderDropdownIconProps = {
  isOpen: boolean;
  color: Color;
} & Styleable;

const FilterHeaderDropdownIcon: React.FunctionComponent<FilterHeaderDropdownIconProps> = ({ isOpen, color }) => {
  const icon = isOpen ? 'ArrowUp' : 'ArrowDown';
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <Icon
        icon={icon}
        color={color}
      />
      &nbsp;
    </div>
  );
};

export default FilterHeaderDropdownIcon;
