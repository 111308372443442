import { useState, useEffect, useCallback } from 'react';

// TODO: use different reference when sidebar has been moved to react
export const useSidebarWidthTracking = () => {
  const hasWindow = typeof window !== 'undefined';
  const hasDocument = typeof document !== 'undefined';

  const getSidebarWidth = useCallback(() => {
    const sidebar = hasWindow && hasDocument ? document.getElementsByClassName('wm-sidebar')[0] : null;
    if (sidebar) {
      return sidebar.clientWidth;
    }
    return 0;
  }, [hasWindow, hasDocument]);

  const [sidebarWidth, setSidebarWidth] = useState(getSidebarWidth);

  useEffect(() => {
    if (!hasWindow || !hasDocument) {
      return;
    }
    const handleResize = () => {
      setSidebarWidth(getSidebarWidth());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [hasWindow, hasDocument, getSidebarWidth]);

  return sidebarWidth;
};
