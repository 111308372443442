import ServiceRequest from '~/wm/packages/warranty/packages/service-request/model/ServiceRequest';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import Box from '~/neo-ui/packages/box/Box';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';

export type ServiceRequestClientInfoSectionProps = {
  serviceRequest: ServiceRequest;
} & Styleable;

const ServiceRequestClientInfoSection = ({ serviceRequest, className }: ServiceRequestClientInfoSectionProps) => (
  <Box
    padding={'padding200'}
    boxShadow={'shadow0'}
    borderRadius={'radius400'}
    className={className}
  >
    <LayoutSection
      title={'Client info'}
      titleSize={4}
      titleWeight={'bold'}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.3125rem;
        `}
      >
        <Label bold={true}>{serviceRequest.asset.orgName}</Label>
        <Label>{serviceRequest.asset.siteAddress.address.line1}</Label>
        <Label>
          {serviceRequest.asset.siteAddress.address.city} {serviceRequest.asset.siteAddress.address.stateCode},{' '}
          {serviceRequest.asset.siteAddress.address.zip}
        </Label>
      </div>
      <hr
        css={css`
          width: 100%;
          margin: 0;
          border-top: 0.0625rem solid ${colorToCode('dark-900-12')};
        `}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        `}
      >
        <Label
          bold={true}
          css={css`
            margin-bottom: 0.625rem;
          `}
        >
          {serviceRequest.contactName}
        </Label>
        <Label>{serviceRequest.contactEmail}</Label>
        <ButtonLink
          theme={'secondary'}
          anchor={{ href: `tel:${serviceRequest.contactPhone}` }}
          leftAlign={true}
        >
          {serviceRequest.contactPhone}
        </ButtonLink>
      </div>
    </LayoutSection>
  </Box>
);

export default ServiceRequestClientInfoSection;
