import { createContext } from 'react';

export type InitiativeDashboardMutationContextData = {
  triggerInitiativeDashboardReload: () => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const InitiativeDashboardMutationContext = createContext<InitiativeDashboardMutationContextData>({
  // If there's no provider, it's a graceful no-op
  triggerInitiativeDashboardReload: async () => {},
});

export default InitiativeDashboardMutationContext;
