import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { billingDataSyncTableList } from '@SubscriptionClient/SubscriptionClientWmManagement.gen';

const useDataSyncTableList = () => {
  const [isLoading, setLoading] = useState(true);
  const [tableNames, setTableNames] = useState<string[]>();

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => billingDataSyncTableList({}));
      setLoading(false);

      if (!response) {
        return;
      }

      setTableNames(response.tableNames);
    })();
  }, [callApi]);

  return { isLoading, tableNames };
};

export default useDataSyncTableList;
