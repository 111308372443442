import * as React from 'react';
import ToastInternal from '~/neo-ui/packages/toast/packages/toast-internal/ToastInternal';
import ToastContent, { ToastContentProps } from '~/neo-ui/packages/toast/packages/toast-content/ToastContent';
import useToastContext from '~/neo-ui/packages/toast/context/hooks/useToastContext';
import { css } from '@emotion/react';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';

export type ToastType = 'notification' | 'alert' | 'error';

export type ToastProps = {
  /**
   * Toast identifier
   */
  toastUniqueId: string;
  /**
   * Determines where the toast should pop into view
   * notification: top-right
   * alert: bottom-left
   * error: bottom-left, most bottom, only one
   */
  toastType: ToastType;
  /**
   * Content of the toast
   */
  toastContent: ToastContentProps;
  /**
   * Optional, To disable toast from disappearing
   */
  isNonDismissible?: boolean;
};

const Toast = ({ toastUniqueId, toastContent, isNonDismissible = false }: ToastProps) => {
  const { removeToast } = useToastContext();
  const onClose = React.useCallback(() => {
    removeToast(toastUniqueId);
  }, [toastUniqueId, removeToast]);
  return (
    <ToastInternal
      onClose={onClose}
      isOpen={true}
      disableFadeOut={isNonDismissible}
    >
      <div
        css={css`
          position: relative;
        `}
      >
        <ButtonModifier
          icon={'Cancel'}
          onClick={onClose}
          css={css`
            position: absolute;
            right: 0;
            top: 0;
          `}
        />
        <ToastContent {...toastContent} />
      </div>
    </ToastInternal>
  );
};

export default Toast;
