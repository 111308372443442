import { organizationEngagementActionTicketUnlink } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementActionListMutation, {
  UseEngagementActionListMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListMutation';

export type UseEngagementActionDashboardTicketUnlink = {
  unlinkEngagementActionTicket: (actionId: string) => void;
  isUnlinkingTicket: boolean;
};

const useEngagementActionTicketUnlink = (options?: UseEngagementActionListMutationOptions): UseEngagementActionDashboardTicketUnlink => {
  const [unlinkEngagementActionTicket, isUnlinkingTicket] = useEngagementActionListMutation(
    (actionId: string) => organizationEngagementActionTicketUnlink({ actionId }),
    options,
  );

  return {
    unlinkEngagementActionTicket,
    isUnlinkingTicket,
  };
};

export default useEngagementActionTicketUnlink;
