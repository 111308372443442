import { LineItemTooltip } from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/subscription-summary/LineItemTooltip';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import { DiscountBreakdownItem } from '@SubscriptionClient/BeastClient/Beast/Msp/Billing/SelfUpgrade/Dto/CheckoutSummaryNested.gen';
import theme from '~/sp-ui/theme';
import Text from '~/sp-ui/text/Text';
import { DiscountIcon } from '~/sp-ui/icons';

export type DiscountLineItemProps = {
  itemName: string;
  discountTooltipItems: DiscountBreakdownItem[];
  totalAmount: number;
  isCoupon?: boolean;
};

const DiscountLineItem = ({ itemName, discountTooltipItems, totalAmount, isCoupon = false }: DiscountLineItemProps) => {
  const renderSingleDiscount = (discountBreakdownItem: DiscountBreakdownItem) => {
    return (
      <DiscountLineItem
        itemName={discountBreakdownItem.name}
        totalAmount={discountBreakdownItem.amount}
        discountTooltipItems={[]}
        isCoupon={discountBreakdownItem.isCoupon}
      />
    );
  };

  const renderMultipleDiscount = (discountTooltipItems: DiscountBreakdownItem[]) => {
    return (
      <div>
        {discountTooltipItems.map((breakdownItem, index) => {
          return (
            <DiscountLineItem
              itemName={breakdownItem.name}
              totalAmount={breakdownItem.amount}
              discountTooltipItems={[]}
              isCoupon={breakdownItem.isCoupon}
              key={index}
            />
          );
        })}
        <hr css={{ backgroundColor: theme.palette.grey1, width: '100%', marginBlock: 8 }} />
        <DiscountLineItem
          itemName={'Plan discount'}
          totalAmount={totalAmount}
          discountTooltipItems={[]}
        />
      </div>
    );
  };

  return (
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        justifyContent: 'space-between',
        rowGap: '0.5rem',
        fontSize: '1rem',
        fontWeight: '400',
      }}
    >
      <div css={{ display: 'flex', alignItems: 'center' }}>
        {isCoupon && (
          <DiscountIcon
            width={16}
            height={16}
            fill={'#38A825'}
            css={{ marginRight: '0.5rem' }}
          />
        )}
        <Text>{itemName}</Text>
        {discountTooltipItems.length > 0 && (
          <LineItemTooltip css={{ width: '1rem', height: '1rem', marginLeft: '0.5rem' }}>
            {discountTooltipItems.length === 1
              ? renderSingleDiscount(discountTooltipItems[0])
              : renderMultipleDiscount(discountTooltipItems)}
          </LineItemTooltip>
        )}
      </div>
      <Text css={{ float: 'right', textAlign: 'right', color: '#38A825' }}>-{formatCurrency(totalAmount, 'USD', 2)}</Text>
    </div>
  );
};

export default DiscountLineItem;
