import { useState } from 'react';
import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Button from '~/neo-ui/packages/button/Button';
import { css } from '@emotion/react';
import Divider from '~/neo-ui/packages/divider/Divider';
import AnimationHeight from '~/neo-ui/packages/animation/packages/animation-height/AnimationHeight';

export type BannerExpandableProps = {
  title: string;
  description: string;
  expandableContent: React.ReactNode;
  onExpanded?: () => void;
  isExpandedByDefault?: boolean;
};

/**
 * A banner that can be expanded to show more content
 * Does not use BannerInternal since it provides information in a different way (expands)
 *
 * This banner is currently used to display messaging from marketing
 * As such, the styles here will not be consistent with the rest of the application
 */
const BannerExpandable = ({ title, description, expandableContent, onExpanded, isExpandedByDefault = false }: BannerExpandableProps) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);

  return (
    <Box
      borderRadius={'radius400'}
      css={css`
        padding: 1rem 1.25rem 0.75rem 1.25rem;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.75rem;
          margin: 0 1rem;
        `}
      >
        <Header
          size={3}
          weight={'medium'}
        >
          {title}
        </Header>
        <Label>{description}</Label>
        <AnimationHeight isExpanded={isExpanded}>
          <Divider />
          <div
            css={css`
              margin: 0.75rem 0;
            `}
          >
            {expandableContent}
          </div>
          <Divider />
        </AnimationHeight>
      </div>
      <Button
        theme={'caution'}
        iconRight={isExpanded ? 'ArrowUp' : 'ArrowDown'}
        onClick={() => {
          if (typeof onExpanded !== 'undefined' && !isExpanded) {
            onExpanded();
          }
          setIsExpanded(prev => !prev);
        }}
        css={css`
          margin-left: 50%;
          transform: translate(-50%, 0%);
          margin-top: ${isExpanded ? '1rem' : '0'};
          width: fit-content;
        `}
      >
        {isExpanded ? "I'm ready" : 'Discover more'}
      </Button>
    </Box>
  );
};

export default BannerExpandable;
