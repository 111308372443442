import * as React from 'react';
import { InitiativeCreateSource } from '~/wm/packages/strategy/model/strategyDataCollectionSources';
import InitiativeEngagementActionLinkModal from '~/wm/packages/strategy/packages/initiative/packages/initiative-engagement-action-link-modal/InitiativeEngagementActionLinkModal';
import useInitiativeCreate from '~/wm/packages/strategy/packages/initiative/hooks/useInitiativeCreate';
import useInitiativeEngagementActionLinkBulk from '~/wm/packages/strategy/packages/initiative/packages/initiative-engagement-action-link-modal/hooks/useInitiativeEngagementActionLinkBulk';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useEngagementListPageInitiativeLinkContext from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/context/hooks/useEngagementListPageInitiativeLinkContext';

export type EngagementActionListInitiativeLinkModalWrapperProps = {
  initiativeCreatedFrom: InitiativeCreateSource;
};

const EngagementActionListInitiativeLinkModalWrapper = ({ initiativeCreatedFrom }: EngagementActionListInitiativeLinkModalWrapperProps) => {
  const { organizationId } = useOrganizationContext();

  const { selectedAction, setSelectedAction, isModalOpen, setModalOpen } = useEngagementListPageInitiativeLinkContext();

  const onSuccess = () => {
    // Setting this to undefined is specific to cause a mutation to trigger for the action list provider
    setSelectedAction(undefined);
  };

  const { createInitiative, isCreating } = useInitiativeCreate(onSuccess);
  const { linkEngagementActions, isLinking } = useInitiativeEngagementActionLinkBulk(onSuccess);

  return typeof selectedAction !== 'undefined' && isModalOpen ? (
    <InitiativeEngagementActionLinkModal
      isOpen={typeof selectedAction !== 'undefined'}
      isLoading={isCreating || isLinking}
      onDismiss={() => {
        /**
         * We can't just set the selected action to undefined here to close the modal because it will create a
         * race condition with the mutation that will inconsistently show the wrong information in the action list table.
         *
         * Internally InitiativeListSelectModal will always trigger the onDismiss callback on submission. But we don't
         * want to cause a mutation until the request is successful.
         */
        setModalOpen(false);
      }}
      onExistingInitiativeSave={initiativeId => {
        linkEngagementActions(initiativeId, [selectedAction.engagementActionId]);
      }}
      onNewInitiativeSave={selectedTemplate => {
        createInitiative(
          organizationId,
          false,
          initiativeCreatedFrom,
          selectedTemplate
            ? {
                id: selectedTemplate.initiativeTemplateId,
                name: selectedTemplate.name,
              }
            : undefined,
          undefined,
          selectedAction.engagementActionId,
        );
      }}
    />
  ) : null;
};

export default EngagementActionListInitiativeLinkModalWrapper;
