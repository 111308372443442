import ButtonOutline from '~/neo-ui/packages/button/packages/button-outline/ButtonOutline';
import Button, { ButtonProps } from '~/neo-ui/packages/button/Button';
import React from 'react';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';

// Used in Beast: e8b3f0a4-12d2-46e5-bb98-32ad96671a11
export type ButtonType = 'button' | 'button-outline' | 'button-link';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ButtonTypeComponent: (buttonType: ButtonType) => React.ElementType<ButtonProps> = buttonType => {
  switch (buttonType) {
    case 'button-outline':
      return ButtonOutline;
    case 'button-link':
      return ButtonLink;
    case 'button':
      return Button;
    default:
      throw new Error('Invalid buttonDropdown prop');
  }
};

export default ButtonType;
