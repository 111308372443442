import * as React from 'react';
import { useEffect } from 'react';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import FormInputTextInternal from '~/neo-ui/packages/form/packages/form-input/FormInputTextInternal';
import { useTextareaInput } from '~/neo-ui/packages/input/packages/input-textarea/hooks/useTextareaInput';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { SerializedStyles } from '@emotion/react';

export type FormTextareaInputInternalProps<T> = {
  fieldKey: FieldKeyExpression<T>;
  placeholder?: string;
  disabled?: boolean;
  optimizePerformance?: boolean;
  fixedErrorPosition?: boolean;
  textAreaCss?: SerializedStyles;
  showFormError?: boolean;
  onChange?: (value: string) => void;
  /**
   * Will attempt to give focus to the input on first render
   */
  hasDefaultFocus?: boolean;
  /**
   * Will attempt to select input text on first render
   */
  hasDefaultSelect?: boolean;
  /**
   * Maximum number of characters allowed in the input.
   */
  maxLength?: number;
} & Styleable &
  Themeable;

/**
 * Text area element to be placed inside a Form component
 */
const FormTextareaInputInternal = <T,>({
  fieldKey,
  placeholder,
  disabled = false,
  optimizePerformance = true,
  fixedErrorPosition = false,
  textAreaCss,
  showFormError = true,
  hasDefaultFocus = false,
  hasDefaultSelect = false,
  maxLength,
  onChange,
  className,
}: FormTextareaInputInternalProps<T>) => {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  // replication of textarea input value
  const [inputValue, setInputValue] = React.useState<string>();
  useTextareaInput(textareaRef, inputValue);

  useEffect(() => {
    if (textareaRef.current !== null) {
      if (hasDefaultFocus) {
        textareaRef.current.focus();
      }
      if (hasDefaultSelect) {
        textareaRef.current.select();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDefaultFocus, hasDefaultSelect, textareaRef.current]);

  return (
    <FormInputTextInternal
      fieldKey={fieldKey}
      optimizePerformance={optimizePerformance}
      onChange={value => {
        setInputValue(value);
        if (typeof onChange !== 'undefined') {
          onChange(value);
        }
      }}
      fixedErrorPosition={fixedErrorPosition}
      className={className}
      showError={showFormError}
    >
      {({ field }) => (
        <textarea
          maxLength={maxLength}
          ref={textareaRef}
          disabled={disabled}
          css={textAreaCss}
          {...field}
          role={'textbox'}
          placeholder={placeholder}
        />
      )}
    </FormInputTextInternal>
  );
};

export default FormTextareaInputInternal;
