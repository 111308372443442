import * as React from 'react';

type StepProps = {
  currentStep: number;
  displayStep: number;
};

const Step: React.FunctionComponent<React.PropsWithChildren<StepProps>> = ({ currentStep, displayStep, children }) => {
  if (currentStep < displayStep) {
    return <></>;
  }
  return <>{children}</>;
};

export default Step;
