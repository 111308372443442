import * as React from 'react';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';
import { ConsoleRow } from '@AssetManagementClient/BeastClient/Search/Model/Console.gen';
import { range } from '~/neo-ui/packages/table/packages/table-selectable/packages/selectable-checkbox/Range';

// from src/frontend/ClientApp/src/wm/packages/strategy/packages/initiative/packages/initiative-edit-page/packages/initiative-edit/packages/proposal-builder/packages/proposal-assets-section/packages/asset-checkbox/AssetCheckbox.tsx
export type ConsoleRowCheckboxProps = {
  row: ConsoleRow;
  allDisplayedRows: ConsoleRow[];
  lastSelectedId: string | undefined;
  onSelect: (rowId: string) => void;
  onDeselect: (rowId: string) => void;
  onSelectMany: (rowId: string[], rowIdClicked: string | undefined) => void;
  onDeselectMany: (rowIds: string[], rowIdClicked: string | undefined) => void;
  isSelected: boolean;
};

const ConsoleRowCheckbox: React.FunctionComponent<ConsoleRowCheckboxProps> = ({
  row,
  allDisplayedRows,
  lastSelectedId,
  onSelect,
  onDeselect,
  onSelectMany,
  onDeselectMany,
  isSelected,
}) => {
  const rangeResult = range(allDisplayedRows, lastSelectedId, row.id, x => x.id);

  return (
    <InputCheckbox
      checked={isSelected}
      onClick={e =>
        e.shiftKey
          ? isSelected
            ? onDeselectMany(rangeResult, row.id)
            : onSelectMany(rangeResult, row.id)
          : isSelected
          ? onDeselect(row.id)
          : onSelect(row.id)
      }
    />
  );
};

export default ConsoleRowCheckbox;
