import { css } from '@emotion/react';
import { Form as FormContainer, Formik } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { ObjectSchema } from 'yup';
import { showAlertAction } from '~/legacy-ui/packages/alert/state/action/alertActions';
import { Styleable } from '~/neo-ui/model/capacity';
import Theme from '~/neo-ui/packages/color/Theme';
import FormAutoSubmitHandler from '~/neo-ui/packages/form/packages/form-action/packages/form-submission/FormAutoSubmitHandler';
import FormSubmitFooter, { IconPlacement } from '~/neo-ui/packages/form/packages/form-action/packages/form-submission/FormSubmitFooter';
import FormSubmitStatusIndicator from '~/neo-ui/packages/form/packages/form-action/packages/form-submission/FormSubmitStatusIndicator';
import mapBackendFieldKey from '~/neo-ui/packages/form/packages/form-action/packages/form-validation/mapBackendFieldKey';
import ValidationErrorMapper from '~/neo-ui/packages/form/packages/form-action/packages/form-validation/ValidationErrorMapper';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { resolveFieldKey, toFieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { ApiServerErrorResponseDto } from '~/wm/packages/api/packages/api-error/model/ApiServerErrorResponseDto';
import ErrorPayload from '~/wm/packages/api/packages/api-error/model/ErrorPayload';
import apiErrorAction from '~/wm/packages/api/packages/api-error/state/apiErrorAction';
import { FormContextInternal } from './context/FormContextInternal';
import { ButtonProps } from '~/neo-ui/packages/button/Button';

export type FormProps<T extends object, TResponse = never> = {
  label?: string;
  description?: string;

  /**
   * Optional schema for frontend input validation.
   */
  validationSchema?: ObjectSchema<T | undefined>;

  /**
   * Data the form starts with before any updates.
   * If changed, the form is reinitialized.
   */
  defaultFormData: T;

  /**
   * @param formData
   * @param isRequestActive This returns whether or not the state should be propagated. This is done to prevent concurrency "rewind" issues.
   */
  onSubmit: (formData: T, isRequestActive: () => boolean) => Promise<TResponse>;

  /**
   * How forms should be submitted. Defaults to auto.
   */
  submitMethod?: 'manual' | 'auto';

  /**
   * Optional, disable form submission by pressing enter key
   */
  disableSubmitOnEnter?: boolean;

  /**
   * Optional, only applies for manual save method today
   */
  submitActionLabel?: string;

  /**
   * Optional, only applies for manual save method today
   */
  submitActionIcon?: IconType;

  /**
   * Optional, only applies for manual save method today
   * Where to place the icon on the footer button, if icon is defined
   */
  submitActionIconPlacement?: IconPlacement;

  /**
   * Optional, only applies for manual save method
   */
  submitActionTheme?: Theme;

  /**
   * Optional, only applies for manual save method
   */
  submitActionDisableSticky?: boolean;

  submitButtonSize?: ButtonProps['size'];

  /**
   * Optional function that enables a cancel button in the footer.
   * The given function will execute when the cancel button is pressed.
   * Currently only applies for manual save method
   */
  onCancel?: () => Promise<void>;

  /**
   * Optional, used to customize the progress message
   */
  submittingLabel?: string;

  /**
   * Optional, used to customize the "submitted" message
   */
  submittedLabel?: string;

  /**
   * Optional submit status indicator override
   */
  SubmitStatusIndicator?: React.ReactNode;

  /**
   * Optional, hide submit status indicator
   */
  hideSubmitStatusIndicator?: boolean;

  /**
   * Optional, only applies for manual save method to not show the button
   */
  hideSubmissionButton?: boolean;

  /**
   * Optional, validation error handler
   */
  onValidationError?: (error: ErrorPayload<T>) => void;

  /**
   * Contains the body of the form including form inputs.
   */
  children: React.ReactNode;

  /**
   * Optional, mapper for the field errors returned by the backend
   * to the frontend form fields.
   */
  validationErrorMapper?: ValidationErrorMapper;

  /**
   * Prevent form from rewinding input if rendering form outside of form context.
   */
  disableOverwriteDefaultFormDataOnSave?: boolean;

  /**
   * Allow form to display a generic error banner (as opposed or in addition to custom error handling).
   */
  dispatchError?: boolean;
} & Styleable;

const Form = <T extends object, TResponse = unknown>({
  label,
  description,
  validationSchema,
  defaultFormData,
  onSubmit,
  submitMethod = 'auto',
  disableSubmitOnEnter = false,
  submitActionLabel = 'Submit form',
  submitActionIcon = 'Save',
  submitActionIconPlacement = 'left',
  submitActionTheme = 'positive',
  submitActionDisableSticky = false,
  onCancel,
  submittingLabel = 'Submitting…',
  submittedLabel = 'Submitted',
  hideSubmissionButton = false,
  hideSubmitStatusIndicator = false,
  submitButtonSize,
  onValidationError,
  SubmitStatusIndicator: CustomSubmitStatusIndicator,
  className,
  children,
  validationErrorMapper,
  disableOverwriteDefaultFormDataOnSave = false,
  dispatchError = true,
}: FormProps<T, TResponse>) => {
  const submitStatusIndicator: React.ReactNode = CustomSubmitStatusIndicator ?? (
    <FormSubmitStatusIndicator
      submittingLabel={submittingLabel}
      submittedLabel={submittedLabel}
    />
  );

  const beforeSubmitHandlersRef = React.useRef<((formData: T) => Promise<void>)[]>([]);
  const afterSubmitHandlersRef = React.useRef<((formData: T, formResponse: TResponse) => Promise<void>)[]>([]);

  const onBeforeSubmit = async (formData: T) => {
    for (const beforeSubmitHandler of beforeSubmitHandlersRef.current) {
      await beforeSubmitHandler(formData);
    }
  };

  const onAfterSubmit = async (formData: T, formResponse: TResponse) => {
    for (const afterSubmitHandler of afterSubmitHandlersRef.current) {
      await afterSubmitHandler(formData, formResponse);
    }
  };

  const [lastSuccessfullySubmittedFormData, setLastSuccessfullySubmittedFormData] = React.useState<T>(defaultFormData);

  const mostRecentFormDataStateRef = React.useRef<T>(defaultFormData);

  React.useEffect(() => setLastSuccessfullySubmittedFormData(defaultFormData), [defaultFormData]);

  const dispatch = useDispatch();

  const defaultErrorMapper = mapBackendFieldKey;

  return (
    <FormContextInternal.Provider
      value={{
        addBeforeSubmitListener: newBeforeSubmitHandler => {
          beforeSubmitHandlersRef.current.push(newBeforeSubmitHandler);

          // Returns cleanup function
          return () =>
            (beforeSubmitHandlersRef.current = beforeSubmitHandlersRef.current.filter(
              beforeSubmitHandler => beforeSubmitHandler !== newBeforeSubmitHandler,
            ));
        },
        addAfterSubmitListener: newAfterSubmitHandler => {
          afterSubmitHandlersRef.current.push(newAfterSubmitHandler);

          // Returns cleanup function
          return () =>
            (afterSubmitHandlersRef.current = afterSubmitHandlersRef.current.filter(
              afterSubmitHandler => afterSubmitHandler !== newAfterSubmitHandler,
            ));
        },
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={lastSuccessfullySubmittedFormData}
        onSubmit={(formData, { setSubmitting, setFieldError }) => {
          // IMPORTANT: Formik does support passing an async
          // function for onSubmit, but it changes the behavior
          // to handle setSubmitting(false) automatically
          // which bypasses the fetchToken check and also
          // betrays their own documentation at
          // https://formik.org/docs/guides/form-submission#submission
          // (ﾉ °益°)ﾉ 彡 ┻━┻
          (async () => {
            // If the data being processed is not the latest state,
            // don't propagate updates.
            // This assumes that the form is pending a new update and helps prevent rewinds in state.
            // THINKING: An ideal solution might be to cancel current onSubmit functions being fired.
            const isRequestActive = () => submitMethod === 'manual' || formData === mostRecentFormDataStateRef.current;

            try {
              await onBeforeSubmit(formData);
              const response = await onSubmit(formData, isRequestActive);
              await onAfterSubmit(formData, response);
            } catch (e) {
              // `Form` has support for API server 400 responses
              // This maps (with the help of validationErrorMapper) the field errors
              // returned by the backend to the frontend form fields.
              const err = e as ApiServerErrorResponseDto;

              if (!(typeof err !== 'undefined' && err.Status === 400)) {
                setSubmitting(false);
                throw e;
              }

              const errorMapper = typeof validationErrorMapper !== 'undefined' ? validationErrorMapper : defaultErrorMapper;

              const fieldErrors = Object.entries(err.Errors).map(([fieldKey, errors]) => ({
                fieldKey: toFieldKeyExpression(errorMapper(fieldKey)),
                errorMessage: errors[0],
              }));

              // Field error mapping
              for (const { fieldKey, errorMessage } of fieldErrors) {
                setFieldError(resolveFieldKey(fieldKey), errorMessage);
              }

              if (fieldErrors.length === 0 && dispatchError) {
                // No field errors, send a global message
                if (err.GlobalMessage) {
                  dispatch(
                    showAlertAction({
                      children: err.GlobalMessage,
                      theme: 'danger',
                    }),
                  );
                } else {
                  // Generic global error
                  dispatch(apiErrorAction('validation-error'));
                }
              }

              // Optional action to take when there exists 400 error
              if (typeof onValidationError !== 'undefined') {
                onValidationError({
                  globalMessage: err.GlobalMessage,
                  fieldErrors,
                });
              }

              setSubmitting(false);
              return;
            }

            if (isRequestActive()) {
              setSubmitting(false);
              if (!disableOverwriteDefaultFormDataOnSave) {
                setLastSuccessfullySubmittedFormData(formData);
              }
            }
          })();
        }}
        validationSchema={validationSchema}
      >
        <FormContainer
          className={className}
          {...(disableSubmitOnEnter && {
            onKeyPress: event => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            },
          })}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              width: 100%;
            `}
          >
            {label && (
              <Header
                css={css`
                  margin-bottom: 0.625rem;
                `}
                size={3}
              >
                {label}
              </Header>
            )}
            {submitMethod === 'auto' && !hideSubmitStatusIndicator && submitStatusIndicator}
          </div>
          {description && (
            <Label
              muted={true}
              css={css`
                margin-bottom: 0.9375rem;
              `}
            >
              {description}
            </Label>
          )}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              width: 100%;
            `}
          >
            {children}
          </div>
          {submitMethod === 'auto' && (
            <FormAutoSubmitHandler<T>
              onSubmitting={formData => {
                mostRecentFormDataStateRef.current = formData;
              }}
            />
          )}
          {submitMethod === 'manual' && !hideSubmissionButton && (
            <FormSubmitFooter
              FormSubmitStatusIndicator={!hideSubmitStatusIndicator && submitStatusIndicator}
              submitActionLabel={submitActionLabel}
              submitActionIcon={submitActionIcon}
              submitActionIconPlacement={submitActionIconPlacement}
              buttonSize={submitButtonSize}
              theme={submitActionTheme}
              disableSticky={submitActionDisableSticky}
              onCancel={onCancel}
            />
          )}
        </FormContainer>
      </Formik>
    </FormContextInternal.Provider>
  );
};

export default Form;
