import * as React from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { css } from '@emotion/react';

const logoSlugIdentifier = '_logo';
// props
export type VendorLogoProps = {
  logoSlug: string;
};

const VendorLogo = ({ logoSlug }: VendorLogoProps) => (
  <Icon
    icon={(logoSlug + logoSlugIdentifier) as IconType}
    color={'no-color'}
    css={css`
      min-height: 3.5rem;
      height: auto;
      width: 10.9375rem;
    `}
  />
);

export default VendorLogo;
