import * as React from 'react';
import { useActivityLog } from '~/wm/packages/activity/packages/activity-log/hooks/useActivityLog';
import ActivityLogLayout from '~/wm/packages/activity/packages/activity-log/ActivityLogLayout';

const ActivityLog: React.FunctionComponent = ({}) => (
  <ActivityLogLayout
    useActivityLogCallback={useActivityLog}
    isFilterable={true}
  />
);

export default ActivityLog;
