import { Typeahead } from 'react-bootstrap-typeahead';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Badge from '~/neo-ui/packages/badge/Badge';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { Key } from 'react';

// eslint-disable-next-line import/no-internal-modules
import { LabelKey } from 'react-bootstrap-typeahead/types/types';
import getMultiSelectSearchStyles from '~/neo-ui/packages/select/packages/multi-select-search/getMultiSelectSearchStyles';

export type MultiSelectSearchTokenColors = {
  backgroundColorRest: Color;
  textColorRest: Color;
};

export type MultiSelectSearchInternalProps<TData extends Record<string, unknown>> = {
  /**
   * The key of the data that should be used as the label
   */
  labelKey: keyof TData;
  /**
   * The label and icon that should be displayed to the left of the search input
   */
  inputLabel?: {
    label: string;
    icon: IconType;
  };
  /**
   * Temporary CSS workaround to get the menu to behave decently inside a modal
   */
  menuCssPosition?: 'absolute' | 'relative';
  /**
   * The results that the user has selected
   */
  selected: TData[];
  /**
   * The options that are available to the user
   */
  options: TData[];
  /**
   * Callback that is called when the user selects a result
   */
  onResultsSelected: (results: TData[]) => void;
  /**
   * Callback that is called when the user types in the search box
   */
  onSearch: (query: string) => void;
  /**
   * Callback that is called when the filter should be cleared
   */
  onClearFilter: () => void;
  /**
   * Callback that is called when the user removes a selected item
   */
  onRemoveSelected: (selectedItem: TData) => void;
  /**
   * Defines how a token should be rendered
   */
  renderToken: {
    label: (selectedItem: TData) => string;
    calculateStyles?: (selectedItem: TData) => MultiSelectSearchTokenColors;
  };
  /**
   * Determine how to render rows based on its data
   */
  renderSearchRow: (data: TData) => React.ReactNode;
} & Styleable;

const MultiSelectSearchInternal = <TData extends Record<string, unknown>>({
  labelKey,
  inputLabel,
  menuCssPosition,
  selected,
  options,
  onResultsSelected,
  onSearch,
  onRemoveSelected,
  onClearFilter,
  renderToken,
  renderSearchRow,
  className,
}: MultiSelectSearchInternalProps<TData>) => {
  const [isSearchFocused, setSearchFocused] = React.useState(false);

  let searchInputRef: HTMLInputElement | null = null;

  const resetFilter = () => {
    /*
     * Resetting the available options when the input is clicked because internally react-bootstrap-typeahead
     * will clear the user's input but not emit an onSearch event, so we need to reset the available options
     */
    const value = searchInputRef?.value;
    if (value === '') {
      onClearFilter();
    }
  };

  return (
    <Typeahead
      className={className}
      css={[
        getMultiSelectSearchStyles(),
        css`
          // In order to directly place the search menu under the renderInput's output, we need to override the default styles
          .rbt-menu {
            /**
             * Since we hardcoded the menu to be open in order to control open and close state with css we manage it here 
             *
             * Ref: bea417a4-f1fe-4a58-84ee-a422dedbdcd5
             */
            display: ${isSearchFocused ? 'block' : 'none'} !important;
            position: ${menuCssPosition} !important;
            top: 100% !important;
            width: 100% !important;
            transform: unset !important;
          }
        `,
      ]}
      labelKey={labelKey as unknown as LabelKey}
      multiple={true}
      // Ref: bea417a4-f1fe-4a58-84ee-a422dedbdcd5
      open={true}
      options={options.filter(option => selected.find(selectedItem => selectedItem.userId === option.userId) === undefined)}
      selected={selected}
      onInputChange={onSearch}
      onChange={selected => {
        onResultsSelected(selected as TData[]);
        resetFilter();
      }}
      filterBy={() =>
        // Returning true to disable the default filtering behavior, so we can implement our own through the onSearch prop
        true
      }
      renderInput={({ inputRef, referenceElementRef, onClick, onFocus, onBlur, ...inputProps }) => (
        <div
          css={css`
            position: relative;
            border: 0.0625rem solid ${colorToCode('dark-900-24')};
            border-radius: 0.5rem;
            background: ${colorToCode('light-000')};
            padding: 0.5rem;
            width: 100%;
          `}
          onClick={() => {
            /**
             * Typeahead internally binds the menu's open state to when the input is focused. However, it acts as if any element
             * rendered from renderInput as the focus element rather than specifically the input with the input reference.
             *
             * So in our case we need to manually focus the input this container is clicked, so we don't end up in a weird
             * state where the menu is open but the input is not focused.
             *
             * Ref: 1b4e2b5a-9b7a-4b7e-9e1a-9e2a5b8b8b0a
             */
            searchInputRef?.focus();
            resetFilter();
          }}
        >
          <div
            css={css`
              display: flex;
              gap: 0.5rem;
              align-items: center;
              width: 100%;
            `}
          >
            {/* Left section */}
            {typeof inputLabel !== 'undefined' && (
              <div
                css={css`
                  display: flex;
                  gap: 0.25rem;
                  white-space: nowrap;
                `}
              >
                <Icon
                  icon={inputLabel.icon}
                  color={'dark-900-64'}
                />
                <Label
                  size={'sm'}
                  bold={true}
                  color={'dark-900-64'}
                >
                  {inputLabel.label}
                </Label>
              </div>
            )}
            {/* Selected users */}
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
              `}
            >
              {selected.map(option => {
                const tokenColors =
                  typeof renderToken.calculateStyles !== 'undefined'
                    ? renderToken.calculateStyles(option)
                    : ({
                        backgroundColorRest: 'secondary-400',
                        textColorRest: 'light-000',
                      } as MultiSelectSearchTokenColors);

                return (
                  <Badge
                    key={option[labelKey] as unknown as Key}
                    bgColor={tokenColors.backgroundColorRest}
                    textColor={tokenColors.textColorRest}
                    fontWeight={'400'}
                    borderRadius={'radius425'}
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: 0.5rem;
                    `}
                  >
                    <div>{renderToken.label(option)}</div>
                    <Icon
                      sizePx={12}
                      icon={'ActionClose'}
                      color={'dark-700'}
                      css={css`
                        cursor: pointer;
                      `}
                      onClick={() => {
                        onRemoveSelected(option);
                      }}
                      preventOnClickPropagation={true}
                    />
                  </Badge>
                );
              })}
              {/* Search input switching with "+" button */}
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  flex-grow: 1;

                  input:focus ~ div {
                    display: none;
                  }

                  input:focus {
                    opacity: 100%;
                  }
                `}
              >
                <input
                  css={css`
                    all: unset;
                    min-width: min-content;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                  `}
                  {...inputProps}
                  ref={input => {
                    searchInputRef = input;
                    inputRef(input);
                    referenceElementRef(input);
                  }}
                  onBlur={e => {
                    setSearchFocused(false);
                    if (typeof onBlur !== 'undefined') {
                      onBlur(e);
                    }
                  }}
                  onFocus={e => {
                    setSearchFocused(true);
                    if (typeof onFocus !== 'undefined') {
                      onFocus(e);
                    }
                  }}
                  onClick={e => {
                    /*
                     * Resetting the available options when the input is clicked because internally react-bootstrap-typeahead
                     * will clear the user's input but not emit an onSearch event, so we need to reset the available options
                     */
                    resetFilter();
                    if (typeof onClick !== 'undefined') {
                      onClick(e);
                    }
                  }}
                />
                <div
                  css={css`
                    position: absolute;
                    display: flex;
                    align-items: center;
                    pointer-events: none;
                  `}
                >
                  <Icon
                    icon={'ActionNew'}
                    color={'dark-700'}
                    sizePx={12}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      renderMenuItemChildren={option => <div>{renderSearchRow(option as TData)}</div>}
    />
  );
};

export default MultiSelectSearchInternal;
