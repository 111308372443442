import { ErrorIcon, SuccessIcon } from '~/sp-ui/icons';
import PopoverWithPopup from '~/neo-ui/packages/popover/PopoverWithPopup';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import * as React from 'react';

type FeatureBooleanLabelProps = {
  value: boolean;
  fields?: string[];
};

const FeatureBooleanLabel = ({ value, fields = [] }: FeatureBooleanLabelProps) => {
  const icon = value ? <SuccessIcon /> : <ErrorIcon />;

  return fields.length > 0 ? (
    <div>
      <PopoverWithPopup
        header={(isDisplayed: boolean) => {
          return (
            <div className={value ? 'boolean-true' : 'boolean-false'}>
              {icon}{' '}
              <Icon
                css={css`
                  vertical-align: middle;
                `}
                icon={isDisplayed ? 'ArrowUp' : 'ArrowDown'}
                color={'dark-900'}
              />
            </div>
          );
        }}
        popoverAlign={'bottom-start'}
      >
        <ul
          css={css`
            list-style-type: none;
            padding: 0.5rem;
            margin-bottom: 0;
            min-width: 200px;
          `}
        >
          {fields.map(field => (
            <li key={field}>{field}</li>
          ))}
        </ul>
      </PopoverWithPopup>
    </div>
  ) : (
    <Label className={value ? 'boolean-true' : 'boolean-false'}>{icon}</Label>
  );
};

export default FeatureBooleanLabel;
