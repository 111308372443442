import { RenderCellDataNested } from '@AssetManagementClient/BeastClient/Renderer/Model/Data.gen';
import { Orientation } from '@AssetManagementClient/BeastClient/Renderer/Model/Enum.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import RenderCell from '../RenderCell';
import { Spacing } from '@AssetManagementClient/Renderer/Model/Enum.gen';
import { ReactElement } from 'react';

export type RenderCollectionProps = {
  data: RenderCellDataNested.CollectionData;
};

const RenderCellCollection: React.FunctionComponent<RenderCollectionProps> = ({
  data: { collectionOrientation, contentCollection, spacing },
}) => {
  const renderCollection: ReactElement[] = [];
  contentCollection.forEach((content, index) => {
    renderCollection.push(
      <div
        key={index}
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <RenderCell data={content} />
      </div>,
    );
  });

  const getSpacing = (spacingEnum: Spacing) => {
    switch (spacingEnum) {
      case Spacing.Normal:
        return '0.313rem';
      case Spacing.Small:
        return '0rem';
      case Spacing.Large:
        return '0.625rem';
      default:
        return '0.313rem';
    }
  };

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        ${collectionOrientation === Orientation.Horizontal
          ? css`
              flex-direction: row;
              gap: ${getSpacing(spacing)};
            `
          : css`
              flex-direction: column;
            `}
      `}
    >
      {renderCollection}
    </div>
  );
};

export default RenderCellCollection;
