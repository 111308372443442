import * as React from 'react';
import FormTextboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-textbox-input/FormTextboxInput';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { Styleable } from '~/neo-ui/model/capacity';
import { InputTitleSizes } from '~/neo-ui/packages/input/packages/input-title/InputTitle';

export type FormNumberInputProps<T> = {
  fieldKey: FieldKeyExpression<T>;
  placeholder?: string;
  prependCharacter?: string;
  disabled?: boolean;
  min?: number;
  isInvalid?: boolean;
  size?: InputTitleSizes;
  fixedErrorPosition?: boolean;
} & Styleable;

const FormNumberInput = <T,>({
  fieldKey,
  placeholder,
  prependCharacter,
  disabled = false,
  min,
  isInvalid = false,
  size,
  fixedErrorPosition = false,
  className,
}: FormNumberInputProps<T>) => (
  <FormTextboxInput
    fieldKey={fieldKey}
    type={'number'}
    placeholder={placeholder}
    prependCharacter={prependCharacter}
    disabled={disabled}
    min={min}
    size={size}
    isInvalid={isInvalid}
    fixedErrorPosition={fixedErrorPosition}
    className={className}
  />
);

export default FormNumberInput;
