import * as React from 'react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import FormInputInternal from '~/neo-ui/packages/form/packages/form-input/FormInputInternal';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { Styleable } from '~/neo-ui/model/capacity';
import { FormSelectDisplay } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectDisplay';

export type FormSelectOption = { label: string; value: string };

export type FormSelectInputProps<TFormData, TFormDataValue = string> = {
  fieldKey: FieldKeyExpression<TFormData>;
  disabled?: boolean;
  placeholder?: string;
  options: FormSelectOption[];
  onSelect?: (value: TFormDataValue) => void;

  /**
   * Given the form data, map to the input value
   */
  fromFormData?: (formDataValue: TFormDataValue) => string;

  /**
   * Given a value being sent by the input, map it
   * to something else when propagating to form data.
   */
  toFormData?: (inputValue: string) => TFormDataValue;
} & Styleable;

const FormSelectInput = <TFormData, TFormDataValue = string>({
  fieldKey,
  options,
  placeholder = 'Select',
  disabled = false,
  onSelect,
  fromFormData,
  toFormData,
  className,
}: FormSelectInputProps<TFormData, TFormDataValue>) => {
  const { getFormInput, setFormInput } = useFormContext<TFormData>();
  return (
    <FormInputInternal
      fieldKey={fieldKey}
      inputProps={{ type: 'select', className: 'form-group' }}
      className={className}
    >
      {({ inputProps }) => (
        <FormSelectDisplay
          options={options}
          propsForTheSelectHtmlComponent={inputProps}
          value={fromFormData ? fromFormData(getFormInput(fieldKey).value as TFormDataValue) : (getFormInput(fieldKey).value as string)}
          onChange={e => {
            const value = (toFormData ? toFormData(e.target.value) : e.target.value) as TFormDataValue;

            setFormInput<TFormDataValue>(fieldKey, value);
            if (onSelect) {
              onSelect(value);
            }
          }}
          disabled={disabled}
          placeholder={placeholder}
        />
      )}
    </FormInputInternal>
  );
};

export default FormSelectInput;
