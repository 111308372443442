import { css, SerializedStyles } from '@emotion/react';
import * as React from 'react';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from '@hello-pangea/dnd';
import { Styleable } from '~/neo-ui/model/capacity';

export type DisplayState = {
  /**
   * When nothing is within the area
   */
  empty?: React.ReactNode;
  /**
   * When you drag a tile onto area
   */
  dragOver?: React.ReactNode;
};

export type ContainerDisplaySettings = {
  /**
   * Css to apply on Hover
   */
  hoverCss?: SerializedStyles;
  /**
   * Css to apply to zone while dragging a draggable element
   */
  dragCss?: SerializedStyles;
};

export type DropSettings = {
  /**
   * Can you combine elements when dragging
   */
  isCombineEnabled?: boolean;
  /**
   * Which direction should the drop zone be rendered as
   */
  direction?: 'horizontal' | 'vertical';
  /**
   * What type of droppable is this to identify the originating DropZone
   * Used for nested DropZones
   */
  type?: string;
};

export type DropZoneProps = {
  droppableId: string;
  settings?: DropSettings;
  displayState?: DisplayState;
  containerCssStates?: ContainerDisplaySettings;
} & Styleable;

const DropZone: React.FunctionComponent<React.PropsWithChildren<DropZoneProps>> = ({
  droppableId,
  displayState,
  settings,
  className,
  containerCssStates,
  children,
}) => (
  <Droppable
    key={droppableId}
    droppableId={droppableId}
    type={settings?.type}
    isCombineEnabled={settings?.isCombineEnabled}
    direction={settings?.direction}
  >
    {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
      let displayedElement: React.ReactNode;
      if (snapshot.isDraggingOver && displayState?.dragOver && snapshot.draggingFromThisWith !== snapshot.draggingOverWith) {
        displayedElement = displayState.dragOver;
      } else if (typeof children === 'undefined' && displayState?.empty) {
        displayedElement = displayState.empty;
      }

      return (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={className}
          css={css`
            user-select: none;
            transition: background-color 0.2s ease, opacity 0.1s ease;

            /* Hover/DragOver Related Css */
            ${snapshot.isDraggingOver && containerCssStates?.hoverCss};

            /* Drag From Related Css */
            ${snapshot.draggingFromThisWith && containerCssStates?.dragCss};
          `}
        >
          {displayedElement ? displayedElement : children}
          {provided.placeholder}
        </div>
      );
    }}
  </Droppable>
);

export default DropZone;
