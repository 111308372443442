import * as React from 'react';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

interface ListGroupProps {
  className?: string;
}

/**
 * @deprecated from usage of php classes, replace with using flex box
 */
const ListGroup: React.FunctionComponent<React.PropsWithChildren<ListGroupProps>> = ({ className, children }) => (
  <div
    className={`flex-row align-items-center justify-content-between ${className || ''}`}
    css={css`
      display: flex;
      background-color: ${colorToCode('light-000')};
    `}
  >
    {children}
  </div>
);

export default ListGroup;
