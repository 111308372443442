import { css } from '@emotion/react';
import { StatusEnum } from '@WarrantyClient/Warranty/ServiceRequest/Model/ServiceRequestNested.gen';
import { Request as WarrantyClientWarrantyServiceRequestPackagesListControllerServiceRequestListControllerNestedRequest } from '@WarrantyClient/Warranty/ServiceRequest/Packages/List/Controller/ServiceRequestListControllerNested.gen';
import { serviceRequestList } from '@WarrantyClient/WarrantyClientMsp.gen';
import { formatDistanceToNow, parseISO } from 'date-fns';
import * as React from 'react';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import Button from '~/neo-ui/packages/button/Button';
import AsyncDataTable from '~/neo-ui/packages/table/packages/async-data-table/AsyncDataTable';
import { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import TableCell from '~/neo-ui/packages/table/packages/table-cell/TableCell';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import useApi from '~/wm/packages/api/hook/useApi';
import ServiceRequest, { convertBeastServiceRequest } from '../../model/ServiceRequest';
import Testable from '~/neo-ui/packages/testable/Testable';

export type ServiceRequestListProps = {
  summaryUrl: string;
};

const ServiceRequestList: React.FunctionComponent<ServiceRequestListProps> = ({ summaryUrl }) => {
  const [lastSyncTime, setLastSyncTime] = React.useState<Date | undefined>(undefined);
  const { callApi } = useApi();

  const columns: DataTableColumn<ServiceRequest>[] = React.useMemo(
    (): DataTableColumn<ServiceRequest>[] => [
      {
        Header: 'Hardware name',
        fieldKey: serviceRequest => serviceRequest.asset.assetName,
        width: '15%',
        ellipsizeTextContent: true,
        renderCell: serviceRequest => (
          <TableCell
            label={serviceRequest.asset.assetName}
            description={serviceRequest.asset.modelNumber ?? ''}
          />
        ),
      },
      {
        Header: 'Serial',
        fieldKey: serviceRequest => serviceRequest.asset.serialNumber,
        renderCell: serviceRequest => serviceRequest.asset.serialNumber,
        ellipsizeTextContent: true,
        width: '10%',
      },
      {
        Header: 'Problem',
        fieldKey: serviceRequest => serviceRequest.description,
        renderCell: serviceRequest => (
          <TableCell
            label={serviceRequest.description}
            description={serviceRequest.note}
            descriptionMuted={true}
          />
        ),
        ellipsizeTextContent: true,
        width: '25%',
      },
      {
        Header: 'Date opened',
        fieldKey: serviceRequest => serviceRequest.submittedAt,
        renderCell: serviceRequest =>
          formatDate(serviceRequest.submittedAt, {
            format: 'yyyy-MM-dd',
            timezone: TimezoneFormat.Local,
          }),
        width: '10%',
      },
      {
        Header: 'Client',
        fieldKey: serviceRequest => serviceRequest.asset.orgName,
        renderCell: serviceRequest => serviceRequest.asset.orgName,
        ellipsizeTextContent: true,
        width: '10%',
      },
      {
        Header: 'Request status',
        fieldKey: serviceRequest => serviceRequest.state,
        renderCell: serviceRequest => (
          <TableCell
            label={serviceRequest.state.label}
            labelColor={serviceRequest.state.color}
            description={serviceRequest.status === StatusEnum.Complete ? 'Request complete' : serviceRequest.sla.label}
            descriptionMuted={true}
          />
        ),
        ellipsizeTextContent: true,
        width: '10%',
      },
      {
        Header: ' ',
        fieldKey: row => row.serviceRequestId,
        width: '5%',
        renderCell: ({ serviceRequestId }) => (
          <div
            css={css`
              display: inline-block;
            `}
          >
            <Testable testId={'service-request-info-button'}>
              <Button
                iconLeft={'Info'}
                anchor={{
                  href: summaryUrl.replace('__SERVICE_REQUEST_ID__', serviceRequestId),
                  openInNewTab: false,
                }}
              />
            </Testable>
          </div>
        ),
      },
    ],
    [summaryUrl],
  );

  const onFetchData = React.useCallback(async () => {
    const serviceRequestListRequest: WarrantyClientWarrantyServiceRequestPackagesListControllerServiceRequestListControllerNestedRequest =
      {};
    const response = await callApi(() => serviceRequestList(serviceRequestListRequest));

    if (!response) {
      setLastSyncTime(undefined);
      return { data: [] };
    }

    const serviceRequests = response.serviceRequests;

    if (serviceRequests.length <= 0) {
      setLastSyncTime(undefined);
      return { data: [] };
    }

    const lastSyncedAtByDistributor = response.lastSyncedAt;
    const lastSyncedAtValues = Object.values(lastSyncedAtByDistributor);

    if (lastSyncedAtValues.length) {
      const lastSyncedAt = lastSyncedAtValues
        .map(
          // TODO WM-1555
          (str, _) => parseISO(str),
        )
        .sort()
        .reverse()[0];

      setLastSyncTime(lastSyncedAt);
    }

    return {
      data: serviceRequests.map<ServiceRequest>(serviceRequest => convertBeastServiceRequest(serviceRequest)),
    };
  }, [callApi]);

  return (
    <>
      <Header
        css={css`
          margin-bottom: 0.5rem;
        `}
        size={2}
      >
        All service requests
      </Header>
      <AsyncDataTable
        onFetchData={onFetchData}
        columns={columns}
        tableDescription={
          lastSyncTime
            ? `Updated ${formatDistanceToNow(lastSyncTime, {
                addSuffix: true,
              })}`
            : undefined
        }
        EmptyStatePlaceholder={() => <i>You'll see your service requests here.</i>}
      />
    </>
  );
};

export default ServiceRequestList;
