import { organizationEngagementNoteUnarchive } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementNoteListPanelTabMutation, {
  UseOrganizationEngagementNoteListPanelTabMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/context/hooks/useEngagementNoteListPanelTabMutation';

export type UseEngagementNoteListArchive = {
  unarchiveNote: (engagementNoteId: string) => void;
  isUnarchivingNote: boolean;
};

const useEngagementNoteListUnarchive = (
  options?: UseOrganizationEngagementNoteListPanelTabMutationOptions,
): UseEngagementNoteListArchive => {
  const [unarchiveNote, isUnarchivingNote] = useEngagementNoteListPanelTabMutation(
    (engagementNoteId: string) =>
      organizationEngagementNoteUnarchive({
        engagementNoteId,
      }),
    options,
  );

  return {
    unarchiveNote,
    isUnarchivingNote,
  };
};

export default useEngagementNoteListUnarchive;
