import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { FieldKeyExpression, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import WizardPageContent from '~/neo-ui/packages/wizard/packages/wizard-page/packages/wizard-page-content/WizardPageContent';
import WizardPageContext from '~/neo-ui/packages/wizard/packages/wizard-page/context/WizardPageContext';
import { ToolbarControl } from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import WizardPageStep from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageStep';
import IconType from '~/neo-ui/packages/icon/IconType.gen';

export type AdditionalComponentProps = {
  component: React.ReactNode;
  position: 'top' | 'bottom';
};

export type WizardPageProps<TFormData, TSuccessData> = Styleable & {
  /**
   * Data the form starts with before any updates.
   * If changed, the form is reinitialized.
   */
  defaultFormData: TFormData;

  /**
   * Unique keys of fields that should be set to incomplete by default
   * Mainly used for incomplete fields that exist on the first step to prevent proceed button from flashing
   */
  defaultIncompleteFields?: FieldKeyExpression<TFormData>[];

  /**
   * All steps of the wizard
   */
  steps: WizardPageStep<TFormData>[];

  /**
   * Additional components to display at the top or bottom of each step
   */
  AdditionalComponents?: AdditionalComponentProps[];

  /**
   * **Optional**: Summary component to display throughout the Wizard's progress
   * - Not shown during the 'success' step
   * - Placed in a LayoutBar of the LayoutFooter
   */
  SummaryComponent?: React.ReactNode;

  /**
   * Success component to display after submission
   */
  SuccessComponent: React.ComponentType<{ postSubmitPayload: TSuccessData }>;

  /**
   * Label used for submit button
   */
  submitLabel: string;
  /**
   * Icon used for submit button, defaults to a cart
   */
  submitIcon?: IconType;
  /**
   * Configurations for disabling parts of the wizard
   */
  disabledOptions?: {
    /**
     * Is submission disabled
     */
    submit?: boolean;
    /**
     * Is progression disabled
     */
    progress?: boolean;
  };
  onSubmit: (formData: TFormData) => Promise<TSuccessData>;

  onPageProgress?: (currentStepKey: string) => void;
};

/**
 * A wizard that exists inside a container, independent of the entire page
 */
const WizardPage = <TFormData extends object, TSuccessData>({
  defaultFormData,
  defaultIncompleteFields = [],
  steps,
  AdditionalComponents,
  SummaryComponent,
  SuccessComponent,
  submitLabel,
  submitIcon,
  onSubmit,
  disabledOptions = {
    submit: false,
    progress: false,
  },
  className,
  onPageProgress = () => {},
}: WizardPageProps<TFormData, TSuccessData>) => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);

  const [actionToolbarControl, setActionToolbarControl] = React.useState<ToolbarControl>();

  // This allows steps to be disabled when they are not complete.
  // Could be reconciled with form validation in the future
  const [incompleteFields, setIncompleteFields] = React.useState<Set<string>>(
    () => new Set<string>(defaultIncompleteFields?.map(fieldKey => resolveFieldKey(fieldKey))),
  );

  const isIncompleteFieldValid = (isComplete: boolean, key: string) =>
    (!isComplete && incompleteFields.has(key)) || (isComplete && !incompleteFields.has(key));

  const setFieldCompleted = <T,>(fieldKey: FieldKeyExpression<T>, isComplete: boolean) => {
    const key = resolveFieldKey(fieldKey);
    if (isIncompleteFieldValid(isComplete, key)) {
      return;
    }
    setIncompleteFields(() => {
      if (isComplete) {
        return new Set([...incompleteFields].filter(field => field !== key));
      } else {
        return new Set([...incompleteFields, key]);
      }
    });
  };

  return (
    <WizardPageContext.Provider
      value={{
        setFieldCompleted,
        setActionToolbarControl,
        setCurrentStepIndex,
        currentStepIndex,
      }}
    >
      <WizardPageContent
        defaultFormData={defaultFormData}
        AdditionalComponents={AdditionalComponents}
        SummaryComponent={SummaryComponent}
        steps={steps}
        currentStepIndex={currentStepIndex}
        SuccessComponent={SuccessComponent}
        onSubmit={onSubmit}
        submitLabel={submitLabel}
        submitIcon={submitIcon}
        actionToolbarControl={actionToolbarControl}
        incompleteFields={incompleteFields}
        className={className}
        onPageProgress={onPageProgress}
        disabledOptions={disabledOptions}
      />
    </WizardPageContext.Provider>
  );
};

export default WizardPage;
