import { Themeable } from '~/neo-ui/model/capacity';
import React from 'react';
import { css } from '@emotion/react';
import Theme from '~/neo-ui/packages/color/Theme';
import FilterHeaderDropdownIcon from '~/neo-ui/packages/filter/packages/filter-headers/filter-header-common/filter-header-dropdown-icon/FilterHeaderDropdownIcon';
import FilterHeaderLabel from '../../../filter-header-common/filter-header-label/FilterHeaderLabel';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

type FilterPreviewSplitMultiSelectProps = {
  selectedPositiveValues: string[];
  selectedNegativeValues: string[];
  positiveOperatorLabel?: string;
  negativeOperatorLabel?: string;
  positiveTheme: Theme;
  negativeTheme: Theme;
  isOpen: boolean;
} & Themeable;

const FilterPreviewSplitMultiSelect: React.FunctionComponent<FilterPreviewSplitMultiSelectProps> = ({
  selectedPositiveValues,
  selectedNegativeValues,
  positiveOperatorLabel,
  negativeOperatorLabel,
  positiveTheme,
  negativeTheme,
  isOpen,
}) => (
  <div
    css={css`
      background-color: ${colorToCode('light-000')};
    `}
  >
    {selectedPositiveValues.length >= 1 && (
      <FilterHeaderLabel
        selectedValues={selectedPositiveValues}
        operatorLabel={positiveOperatorLabel}
        theme={positiveTheme}
      />
    )}

    {selectedNegativeValues.length >= 1 && (
      <FilterHeaderLabel
        selectedValues={selectedNegativeValues}
        operatorLabel={negativeOperatorLabel}
        theme={negativeTheme}
      />
    )}
    <div
      css={css`
        padding: 0 0.438rem;
      `}
    >
      <FilterHeaderDropdownIcon
        isOpen={isOpen}
        color={'dark-900'}
      />
    </div>
  </div>
);

export default FilterPreviewSplitMultiSelect;
