/* eslint-disable consistent-default-export-name/default-import-match-filename */
/* eslint-disable consistent-default-export-name/default-export-match-filename */
import { FormikContextType, useField, useFormikContext } from 'formik';
import BaseDropdown, { DropdownProps as BaseDropdownProps, DropdownOption } from './Dropdown';
import { useMemo } from 'react';

export interface DropdownProps extends Omit<BaseDropdownProps, 'onChange'> {
  name: string;
  validate?: (value: string) => string | undefined;
  onChange?: (option: DropdownOption, formik: FormikContextType<unknown>) => void | Promise<void>;
}

const Dropdown = (props: DropdownProps) => {
  const { name, validate, onChange, onBlur, onFocus, errorSubtext, ...rest } = props;
  const [field, meta, helpers] = useField({
    name,
    validate,
  });

  const formik = useFormikContext();
  const { submitCount } = formik;
  const { touched, value, initialValue, error } = meta;

  const errorMessage = useMemo(() => {
    if (errorSubtext) {
      return errorSubtext;
    }

    const isDirty = value !== initialValue;

    if (((isDirty && touched) || submitCount) && !!error) {
      return error;
    }

    return undefined;
  }, [touched, value, error, initialValue, errorSubtext, submitCount]);

  return (
    <BaseDropdown
      name={name}
      value={field.value ?? ''}
      onChange={e => {
        helpers.setValue(e.value);
        onChange?.(e, formik);
      }}
      onBlur={e => {
        field.onBlur(name);
        onBlur?.(e);
      }}
      onFocus={e => {
        helpers.setTouched(true, false);
        onFocus?.(e);
      }}
      errorSubtext={errorMessage}
      {...rest}
    />
  );
};

export default Dropdown;
