/**
 * Convert an image file to WebP format.
 * Falls back to the original image if the browser doesn't support canvas.
 */
async function fileToWebp({ file, quality = 75 }: { file: File; quality?: number }): Promise<{ blob: Blob; fileName: string }> {
  const fileName = `${file.name.replace(/\.[^/.]+$/, '')}.webp`;

  const image = new Image();
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // If the browser doesn't support canvas, return the original image
  if (!context) {
    return { blob: new Blob([file], { type: file.type }), fileName };
  }

  const objectUrl = URL.createObjectURL(file);

  await new Promise<void>((resolve, reject) => {
    image.onload = () => {
      URL.revokeObjectURL(objectUrl);
      resolve();
    };
    image.onerror = reject;
    image.src = objectUrl;
  });

  canvas.height = image.height;
  canvas.width = image.width;
  context.drawImage(image, 0, 0);

  const blob = await new Promise<Blob>((resolve, reject) =>
    canvas.toBlob(blob => (blob ? resolve(blob) : reject(new Error('Failed to convert to webp'))), 'image/webp', quality / 100),
  );

  return { blob, fileName };
}

export default fileToWebp;
