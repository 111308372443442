import { css } from '@emotion/react';
import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import FormCheckboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckboxInput';
import FormTextareaInput from '~/neo-ui/packages/form/packages/form-input/packages/form-textarea-input/FormTextareaInput';
import FormTitleInput from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/FormTitleInput';
import FormSubmitIndicator from '~/neo-ui/packages/form/packages/form-submit-indicator/FormSubmitIndicator';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { ScorecardFormData } from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-update-form/ScorecardUpdateForm';
import DmiScoreBase from '../../../dmi/packages/dmi-score/base/DmiScoreBase';
import YearMonthSelect from '~/neo-ui/packages/date/packages/year-month-select/YearMonthSelect';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { ScorecardDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Dto/Model.gen';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ScorecardSummaryCardProps = {
  scorecard: ScorecardDto;
};

const ScorecardSummaryCard = ({ scorecard }: ScorecardSummaryCardProps) => {
  const { setFormInput } = useFormContext<ScorecardFormData>();

  return (
    <Card padding={'lg'}>
      <Header
        size={7}
        color={'dark-050'}
        css={css`
          margin-top: 0.125rem;
          margin-bottom: 0.375rem;
        `}
      >
        Title
      </Header>
      <FormTitleInput
        size={'lg'}
        fieldKey={(values: FieldKeyExpressionSegment<ScorecardFormData>) => values.title}
        placeholder={'Enter a title for this Scorecard'}
        css={css`
          flex-grow: 1;
          margin-bottom: 1.25rem;
        `}
      />
      <Header
        size={7}
        color={'dark-050'}
        css={css`
          margin-top: 0.125rem;
          margin-bottom: 0.375rem;
        `}
      >
        Executive summary
      </Header>
      <FormTextareaInput
        fieldKey={(values: FieldKeyExpressionSegment<ScorecardFormData>) => values.executiveSummary}
        placeholder={'Summarize what this client’s DMI means for their business, and how it relates to your Scorecard'}
        css={css`
          flex-grow: 1;
          margin-bottom: 1.25rem;
        `}
      />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 1.25rem;
        `}
      >
        <div
          css={css`
            flex-basis: 16.938rem;
          `}
        >
          <Header
            size={7}
            color={'dark-050'}
            css={css`
              margin-top: 0.125rem;
              margin-bottom: 0.375rem;
            `}
          >
            Dmi
          </Header>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <DmiScoreBase
              css={css`
                max-height: 3rem;
                margin-right: 0.625rem;
              `}
              dmiScore={scorecard.dmiSnapshot.scoreOn10000}
              displayDmiScore={scorecard.dmiSnapshot.scoreDisplay}
              maxDmiScore={10000}
              themeColor={scorecard.dmiSnapshot.band?.color as Color}
              sizePx={71}
              lineThickness={'thick'}
            />
            <FormCheckboxInput fieldKey={(values: FieldKeyExpressionSegment<ScorecardFormData>) => values.includeDmi} />
            <Label>Show score chart</Label>
          </div>
        </div>

        <div
          css={css`
            border-left: 1px solid ${colorToCode('dark-900-24')};
          `}
        />

        <div>
          <Header
            size={7}
            color={'dark-050'}
            css={css`
              margin-top: 0.125rem;
              margin-bottom: 0.375rem;
            `}
          >
            Presentation date
          </Header>
          <YearMonthSelect
            css={css`
              margin-top: 10px;
            `}
            fieldKeys={{
              year: (values: FieldKeyExpressionSegment<ScorecardFormData>) => values.scheduleYear,
              month: (values: FieldKeyExpressionSegment<ScorecardFormData>) => values.scheduleMonth,
            }}
            defaultValue={scorecard.schedule}
            onSelect={(year, month) => {
              setFormInput<number>(values => values.scheduleMonth, month);
              setFormInput<number>(values => values.scheduleYear, year);
            }}
            shouldExpand={true}
          />
        </div>

        <div
          css={css`
            margin-left: auto;
            margin-top: auto;
          `}
        >
          <FormSubmitIndicator />
        </div>
      </div>
    </Card>
  );
};

export default ScorecardSummaryCard;
