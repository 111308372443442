import * as React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import ContractCreateFormValidationSchema from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-create-button/packages/contract-create-form/validation/contractCreateFormValidationSchema';
import useContractCreate from '~/wm/packages/strategy/packages/contract/packages/contract-list/hooks/useContractCreate';
import useContractListContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/context/hooks/useContractListContext';
import { ContractAssetInfo } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-link-assets-button/modal/ContractAssetInfo';

export type ContractCreateBasicFormData = {
  vendor: string;
  account: string | undefined;
  status: string;
  impact: string;
  description: string | undefined;
  location: string | undefined;
  isThirdParty: boolean;
};

export type ContractCreateBillingFormData = {
  cycle: string;
  costType: string;
  costVariables: ContractCreateBillingCostCalculationFormData;
  nextDue: string | undefined;
  endDate: string | undefined;
  isAutoRenew: boolean;
  budgetPastEndDate: boolean;
};

export type ContractCreateBillingCostCalculationFormData = {
  costSubunits: number;
  perSeatCostSubunits: number | undefined;
  numberOfSeats: number | undefined;
};

export type ContractCreateAlertFormData = {
  noticeDays: number;
};

export type ContractCreateAssetFormData = {
  assets: ContractAssetInfo[];
};

export type ContractCreateFormData = {
  basic: ContractCreateBasicFormData;
  billing: ContractCreateBillingFormData;
  alert: ContractCreateAlertFormData;
  asset: ContractCreateAssetFormData;
};

type ContractCreateFormProps = {
  organizationId: string;
  onContractCreate: () => void;
};

const ContractCreateForm: React.FunctionComponent<React.PropsWithChildren<ContractCreateFormProps>> = ({
  organizationId,
  onContractCreate,
  children,
}) => {
  const { createContract } = useContractCreate({
    onSuccess: () => {
      onContractCreate();
    },
  });

  const { contractBillingCurrency, contractAvailabilities } = useContractListContext();

  const toFormData = React.useCallback(
    (): ContractCreateFormData => ({
      basic: {
        vendor: '',
        account: undefined,
        status: contractAvailabilities.statusAvailabilities[0].uniqueId,
        impact: contractAvailabilities.impactAvailabilities[0],
        description: undefined,
        location: undefined,
        isThirdParty: false,
      },
      billing: {
        cycle: contractAvailabilities.billingCycleTypeAvailabilities[0].uniqueId,
        costVariables: {
          costSubunits: 0,
          perSeatCostSubunits: undefined,
          numberOfSeats: undefined,
        },
        costType: contractAvailabilities.billingCostTypeAvailabilities[0],
        nextDue: undefined,
        endDate: undefined,
        isAutoRenew: false,
        budgetPastEndDate: false,
      },
      alert: {
        noticeDays: 30,
      },
      asset: {
        assets: [],
      },
    }),
    [
      contractAvailabilities.billingCostTypeAvailabilities,
      contractAvailabilities.billingCycleTypeAvailabilities,
      contractAvailabilities.impactAvailabilities,
      contractAvailabilities.statusAvailabilities,
    ],
  );

  const defaultFormData = React.useMemo(() => toFormData(), [toFormData]);

  const onSubmit = React.useCallback(
    async (data: ContractCreateFormData, isRequestActive: () => boolean) =>
      createContract(
        {
          organizationId,
          contractCreatePayload: {
            basicUpsertPayload: {
              title: data.basic.vendor,
              category: data.basic.account,
              impact: data.basic.impact,
              status: data.basic.status,
              location: data.basic.location,
              description: data.basic.description,
              isThirdParty: data.basic.isThirdParty,
            },
            billingUpsertPayload: {
              cycle: data.billing.cycle,
              costSubunits: data.billing.costVariables.costSubunits * contractBillingCurrency.subunitRatio,
              perSeatCostSubunits:
                typeof data.billing.costVariables.perSeatCostSubunits === 'undefined'
                  ? undefined
                  : data.billing.costVariables.perSeatCostSubunits * contractBillingCurrency.subunitRatio,
              costType: data.billing.costType,
              numberOfSeats: data.billing.costVariables.numberOfSeats,
              startAt: new Date(`${data.billing.nextDue}`).toUTCString(),
              isAutoRenew: data.billing.isAutoRenew,
              endAt:
                typeof data.billing.endDate !== 'undefined' && data.billing.endDate !== ''
                  ? new Date(`${data.billing.endDate}`).toUTCString()
                  : undefined,
              shouldBudgetPastEndDate: data.billing.budgetPastEndDate,
            },
            alertUpsertPayload: {
              notifyDaysBeforeEndDate: data.alert.noticeDays,
            },
            assetUpsertPayload: {
              assetIds: data.asset.assets.map(asset => asset.assetId),
            },
          },
        },
        isRequestActive,
      ),
    [contractBillingCurrency.subunitRatio, createContract, organizationId],
  );

  return (
    <Form
      submitMethod={'manual'}
      hideSubmissionButton={true}
      defaultFormData={defaultFormData}
      validationSchema={ContractCreateFormValidationSchema}
      onSubmit={onSubmit}
      hideSubmitStatusIndicator={true}
    >
      {children}
    </Form>
  );
};

export default ContractCreateForm;
