import { FunctionComponent, PropsWithChildren } from 'react';
import { keyframes } from '@emotion/react';

interface SatelliteBannerProps {
  height?: string;
}

const SatelliteBanner: FunctionComponent<PropsWithChildren<SatelliteBannerProps>> = ({ height = '17.5rem', children }) => {
  const satelliteSize = {
    width: '153.59px',
    height: '149.33px',
  };
  const satelliteAnimation = keyframes`
      from, 5%, to {
        left: -${satelliteSize.width};
        top: 30%;
      }

      100% {
        left: 100%;
        top: 100%;
      }
    `;

  return (
    <div
      css={{
        height,
        backgroundImage: `url('/i/graphic/account/home/homepage.png')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: `100%`,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <img
        src="/i/graphic/account/home/satellite-grey.svg"
        alt="satellite"
        css={{
          position: 'absolute',
          top: '30%',
          right: `${satelliteSize.width}`,
          width: `${satelliteSize.width}`,
          height: `${satelliteSize.height}`,
          animation: `${satelliteAnimation} 90s infinite`,
        }}
      />
      {children}
    </div>
  );
};

export default SatelliteBanner;
