type HeroImageProps = JSX.IntrinsicElements['div'];

const HeroImage = (props: HeroImageProps) => {
  const { children, className, ...rest } = props;

  return (
    <div
      css={{
        position: 'relative',
      }}
      className={className}
      {...rest}
    >
      <img
        src="/assets/sign-in-up-banners/sph-homepage-background.jpg"
        css={{
          width: '100%',
          height: '280px',
          objectFit: 'cover',
          zIndex: -1,
        }}
      />

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <div
          css={{
            width: '100%',
            maxWidth: '1200px',
            paddingInline: '32px',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default HeroImage;
