import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import * as React from 'react';

type OrderSummaryTermOfServiceModuleProps = {
  disposalTermsOfServiceUrl: string;
  termsOfServiceUrl: string;
  brandName: string;
};
const OrderSummaryTermOfServiceModule = ({
  disposalTermsOfServiceUrl,
  termsOfServiceUrl,
  brandName,
}: OrderSummaryTermOfServiceModuleProps) => (
  <Label
    size={'sm'}
    color={'dark-900-64'}
  >
    By continuing, you agree to the{' '}
    <a
      href={termsOfServiceUrl}
      target={'_blank'}
      css={css`
        color: ${colorToCode('dark-900-64')};
        text-decoration: underline;
        &:hover {
          cursor: pointer;
          color: ${colorToCode('dark-900-64')};
        }
      `}
    >
      {` ${brandName} Terms of Service `}
    </a>{' '}
    Terms of Service and the
    <a
      href={disposalTermsOfServiceUrl}
      target={'_blank'}
      css={css`
        color: ${colorToCode('dark-900-64')};
        text-decoration: underline;
        &:hover {
          cursor: pointer;
          color: ${colorToCode('dark-900-64')};
        }
      `}
    >{` ${brandName} IT Asset Disposal Terms of Service`}</a>
    .
  </Label>
);

export default OrderSummaryTermOfServiceModule;
