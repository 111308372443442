import { Response } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Controller/Field/InsightCreateFormMetadataNested.gen';
import { css } from '@emotion/react';
import React from 'react';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import { neoColorToCode } from '~/neo-ui/packages/color/NeoColor';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import FormArray from '~/neo-ui/packages/form/packages/form-input/packages/form-array/FormArray';
import FormSelectInput, { FormSelectOption } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import FormTitleInput from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/FormTitleInput';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import {
  generateDefaultPolicyFormData,
  InsightFormData,
  InsightPolicyFormData,
} from '~/wm/packages/strategy/packages/insight/packages/insight-create-button/insight-create-modal/InsightFormData';
import PolicyInput from '~/wm/packages/strategy/packages/insight/packages/insight-create-button/insight-create-modal/policy-input/PolicyInput';
import Step from '~/wm/packages/strategy/packages/insight/packages/insight-create-button/insight-create-modal/Step';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';

type InsightCreateModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  metadata: Response | undefined;
  onRefreshAction: () => void;
};

const InsightCreateModal: React.FunctionComponent<InsightCreateModalProps> = ({ isOpen, onDismiss, metadata, onRefreshAction }) => {
  const { submitForm, setFormInput, resetForm, addAfterSubmitListener, isSubmitting } = useFormContext<InsightFormData>();

  const { trackEvent } = useEventTracking(TrackingServices.EventProcessor);

  const trackAndSubmitForm = () => {
    trackEvent('insight-create', undefined);
    submitForm();
  };

  const [step, setStep] = React.useState<number>(0);
  React.useState<boolean>(false);

  const resetModal = React.useCallback(() => {
    onDismiss();
    setStep(0);
    resetForm();
  }, [onDismiss, resetForm]);

  React.useEffect(
    () =>
      addAfterSubmitListener(async () => {
        resetModal();
        onRefreshAction();
      }),
    [resetModal, onRefreshAction, addAfterSubmitListener],
  );

  const [policiesOptions, setPoliciesOptions] = React.useState<FormSelectOption[]>([]);

  const assetScopeOptions =
    metadata?.assetScopes?.flatMap(asset =>
      asset.values.map(value => ({
        label: value.label,
        value: `${asset.key}-${value.key}`,
      })),
    ) ?? [];

  const onAssetScopeChange = (assetScope: string) => {
    const [scopeId, scopeValue] = assetScope.split('-', 2);

    const availablePolicies =
      metadata?.assetScopes?.find(assetId => assetId.key === scopeId)?.values.find(assetValue => assetValue.key === scopeValue)
        ?.availablePolicies ?? [];
    const policies = metadata?.policies?.filter(policy => availablePolicies.includes(policy.key)) ?? [];

    setPoliciesOptions(
      policies.map(policy => ({
        label: policy.label,
        value: policy.key,
      })),
    );
    setFormInput<InsightPolicyFormData[]>(insight => insight.policies, [generateDefaultPolicyFormData()]);
    setStep(1);
  };

  return (
    <ModalConfirmation
      header={{ title: 'Create an Insight', icon: 'Insight' }}
      theme={'primary'}
      footer={{
        confirmButton: {
          icon: 'Success',
          label: 'Finish',
          disabled: step < 1,
          loading: isSubmitting,
          onClick: trackAndSubmitForm,
        },
        dismissButton: {
          icon: 'Cancel',
          label: 'Cancel',
          disabled: isSubmitting,
          onClick: resetModal,
        },
      }}
      isOpen={isOpen}
    >
      <Label
        css={css`
          margin-bottom: 1.25rem;
          padding-bottom: 0.9367rem;
          border-bottom: 1px solid ${neoColorToCode('dark-lighter', 0.15)};
        `}
        size={'md'}
      >
        You'll see this Insight on your Strategy dashboard for each client
      </Label>
      <LayoutSection
        title={'Name & description'}
        titleSize={5}
        css={css`
          margin-bottom: 0.9375rem;
        `}
      >
        <FormTitleInput
          fieldKey={(row: FieldKeyExpressionSegment<InsightFormData>) => row.title}
          size={'lg'}
          placeholder={'Name'}
        />
      </LayoutSection>
      <LayoutSection
        title={''}
        titleSize={5}
        css={css`
          margin-bottom: 0.9375rem;
        `}
      >
        <FormTitleInput
          fieldKey={(row: FieldKeyExpressionSegment<InsightFormData>) => row.description}
          placeholder={'Description'}
        />
      </LayoutSection>
      <LayoutSection
        title={'Asset type'}
        titleSize={5}
        css={css`
          margin-bottom: 0.9375rem;
        `}
        description={'Choose an asset type to see relevant actions for your Insight.'}
      >
        <FormSelectInput
          fieldKey={(row: FieldKeyExpressionSegment<InsightFormData>) => row.assetScope}
          options={assetScopeOptions}
          onSelect={onAssetScopeChange}
        />
      </LayoutSection>
      <Step
        currentStep={step}
        displayStep={1}
      >
        <LayoutSection
          title={'Policies'}
          titleSize={5}
          css={css`
            margin-bottom: 0.9375rem;
          `}
          description={'Set the policies to show as an Insight.'}
        >
          <FormArray
            fieldKey={(row: FieldKeyExpressionSegment<InsightFormData>) => row.policies}
            addButtonText={'Add policy...'}
            initialValue={generateDefaultPolicyFormData}
            arrayKey={element => element.key}
          >
            {(_, index) => (
              <PolicyInput
                index={index}
                policiesData={metadata?.policies ?? []}
                policyOptions={policiesOptions}
              />
            )}
          </FormArray>
        </LayoutSection>
      </Step>
    </ModalConfirmation>
  );
};

export default InsightCreateModal;
