/**
 * Converts a Blob to a base64 string
 */
const blobToBase64 = async (blob: Blob): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => (reader.result ? resolve(reader.result as string) : reject(new Error('File reading failed')));
    reader.onerror = () => reject(new Error('File reading failed'));
    reader.readAsDataURL(blob);
  });

export default blobToBase64;
