import { ComponentPropsWithoutRef } from 'react';
import SpinnerIcon from '~/sp-ui/icons/SpinnerIcon';

export interface SectionLoadingIndicatorProps extends ComponentPropsWithoutRef<'div'> {}

const SectionLoadingIndicator = (props: SectionLoadingIndicatorProps) => {
  return (
    <div
      css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      {...props}
    >
      <SpinnerIcon />
    </div>
  );
};

export default SectionLoadingIndicator;
