import { css, SerializedStyles } from '@emotion/react';
import React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';

export type SwapContentCellProps = {
  styles?: SerializedStyles;
  onClick?: (clickEvent: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
} & Styleable;

const SwapContentCell: React.FunctionComponent<SwapContentCellProps> = ({ styles, onClick, children }) => {
  return (
    <div
      onClick={onClick}
      css={css`
        width: 50%;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        padding: 0.5rem 0 0.5rem 0.5rem;
        ${onClick ? `cursor: pointer` : undefined};
        ${styles}
      `}
    >
      {children}
    </div>
  );
};
export default SwapContentCell;
