import { useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { assetConsole, assetConsoleIds } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import buildConsoleRequest from '~/neo-ui/packages/table/packages/console/buildConsoleRequest';
import { ConsoleState } from '~/neo-ui/packages/table/packages/console/types';
import { Response as RowResponse } from '@AssetManagementClient/AssetManagement/Packages/Asset/Console/Controller/AssetConsoleControllerNested.gen';
import { Response as IdResponse } from '@AssetManagementClient/AssetManagement/Packages/Asset/Console/Controller/AssetConsoleIdsControllerNested.gen';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { Enum as AssetTypeEnum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/AssetScope/Logic/AssetScopeFactoryNested.gen';

export type AssetFilterFrame = {
  forecast: number | undefined;
};

export type AssetConsoleRowResponse = RowResponse | undefined;

export type AssetConsoleRowRequest = (consoleState: ConsoleState, context: object) => Promise<AssetConsoleRowResponse>;

export type UseAssetConsoleRequest = (
  assetType: AssetTypeEnum,
  frontendScope: FrontendScope,
) => {
  reloadAssetRows: AssetConsoleRowRequest;
  reloadIds: (consoleState: ConsoleState, context: object) => Promise<IdResponse | undefined>;
};

const useAssetConsoleRequest: UseAssetConsoleRequest = (assetType: AssetTypeEnum, frontendScope: FrontendScope) => {
  const { callApi } = useApi();

  const reloadAssetRows = useCallback(
    async (consoleState: ConsoleState, context: object) => {
      const response: RowResponse | undefined = await callApi(() =>
        assetConsole({
          assetType,
          scope: frontendScope,
          forecast: (context as AssetFilterFrame).forecast,
          query: buildConsoleRequest(consoleState),
        }),
      );
      if (!response) {
        return;
      }
      return response;
    },
    [callApi, assetType, frontendScope],
  );

  const reloadIds = useCallback(
    async (consoleState: ConsoleState, context: object) => {
      const response = await callApi(() =>
        assetConsoleIds({
          assetType,
          scope: frontendScope,
          forecast: (context as AssetFilterFrame).forecast,
          query: buildConsoleRequest(consoleState),
        }),
      );
      if (!response) {
        return;
      }
      return response;
    },
    [assetType, callApi, frontendScope],
  );

  return {
    reloadAssetRows,
    reloadIds,
  };
};

export default useAssetConsoleRequest;
