import * as React from 'react';
import BannerNotice from '~/neo-ui/packages/banner/packages/banner-notice/BannerNotice';
import useScorecardContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardContext';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';

const ScorecardEditPastBanner = () => {
  const { scorecard } = useScorecardContext();
  if (
    scorecard.isLatestSchedule ||
    scorecard.schedule.year > new Date().getFullYear() ||
    (scorecard.schedule.year === new Date().getFullYear() && scorecard.schedule.month >= new Date().getMonth() + 1)
  ) {
    return null;
  }

  return (
    <BannerNotice
      title={'You’re editing a past Scorecard'}
      description={`This is your Scorecard for ${formatDate(new Date(scorecard.schedule.year, scorecard.schedule.month - 1), {
        format: 'MMMM, yyyy',
        timezone: TimezoneFormat.Utc,
      })}. You’ll find your latest Scorecard by choosing “Open…” in the toolbar.`}
      imageSource={'/i/graphic/strategy/scorecard/secondary.png'}
      closeable={true}
    />
  );
};

export default ScorecardEditPastBanner;
