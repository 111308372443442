import { useState } from 'react';

export type UseInitiativeAssetSelect = {
  /**
   * All asset ids present in the Map, flattened
   */
  assetIds: Map<string, string[]>;
  /**
   * clear assets from entire all fiscal quarters
   */
  clearAssetIds: () => void;
};

/**
 * Maintain a Map of selected asset ids where the key is fiscal quarter ("year-q") and values are the asset ids
 */
const useInitiativeAssetSelect = (): UseInitiativeAssetSelect => {
  const [assetIdsByFiscalQuarter, setAssetIdsByFiscalQuarter] = useState<Map<string, string[]>>(new Map());

  return {
    assetIds: assetIdsByFiscalQuarter,

    clearAssetIds: () => setAssetIdsByFiscalQuarter(() => new Map()),
  };
};

export default useInitiativeAssetSelect;
