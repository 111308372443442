import useDataSyncTableList from '~/wm/packages/niche/packages/billing-data-sync-page/hooks/useDataSyncTableList';
import Spinner from '~/neo-ui/spinner/Spinner';
import React, { useState } from 'react';
import useDataSyncTableResync from '~/wm/packages/niche/packages/billing-data-sync-page/hooks/useDataSyncTableResync';
import useAlert from '~/neo-ui/packages/toast/hooks/useAlert';
import { Button } from '~/sp-ui/buttons';

// This page is for handling data syncing operations
// It is intended to exist in the niche / devops section of the app
// The operations on this page are only intended for specific ScalePad developers to handle
// The functionality on this page is absolutely NOT to be exposed to other parts of the app and is not designed with re-usability in mind
const BillingDataSyncPage: React.FunctionComponent = () => {
  const { isLoading, tableNames } = useDataSyncTableList();
  const { resyncDataSyncTable } = useDataSyncTableResync();
  const sendAlert = useAlert();

  const [selectedTableName, setSelectedTableName] = useState<{ [key: string]: boolean }>({});
  const [isResyncAction, setIsResyncAction] = useState<boolean>(false);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const handleCheckboxChange = (tableName: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setSelectedTableName(prevState => ({
      ...prevState,
      [tableName]: checked,
    }));
  };
  const allSelectedTables = () => {
    return getSelectedTableNames().join(', ');
  };

  const getSelectedTableNames = () => {
    return Object.entries(selectedTableName)
      .filter(([_, value]) => value)
      .map(([key, _]) => key);
  };

  const closeModal = () => {
    setIsSubmited(false);
    if (isResyncAction) {
      return;
    }
  };

  const resyncAction = async () => {
    if (getSelectedTableNames().length > 0) {
      setIsResyncAction(true);
      resyncDataSyncTable(getSelectedTableNames()).then(response => {
        setIsResyncAction(false);
        closeModal();
        setIsSubmited(false);
        setSelectedTableName({});
        if (response) {
          sendAlert({
            toastType: 'notification',
            title: `Successfully created a job to synchronize ${allSelectedTables()}`,
            description: `Successfully created a job to synchronize the data sync table for ${allSelectedTables()}. Be sure to double-check the database yourself to confirm the results.`,
            icon: 'Done',
            theme: 'positive',
          });
        } else {
          sendAlert({
            toastType: 'notification',
            title: `Failed to create a job to synchronize ${allSelectedTables()}`,
            description: `Failed to create a job to synchronize the data sync table for ${allSelectedTables()}. Be sure to double-check the database yourself to confirm the results. Perhaps try again... or escalate the issue.`,
            icon: 'Danger',
            theme: 'negative',
          });
        }
      });
    }
  };

  const modal = (
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={() => closeModal()}
    >
      {isResyncAction && (
        <Spinner
          css={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#fff',
          padding: '1rem',
          borderRadius: '1rem',
          boxShadow: '0 0 1rem rgba(0, 0, 0, 0.2)',
        }}
        onClick={e => e.stopPropagation()}
      >
        <h4>
          Are you sure you want to resync <b>{allSelectedTables()}</b>?
        </h4>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '0.5rem',
            marginTop: '1rem',
          }}
        >
          <button
            onClick={() => closeModal()}
            css={{
              backgroundColor: '#dd2222',
              color: '#fff',
            }}
          >
            Cancel
          </button>
          <button
            onClick={async () => {
              await resyncAction();
            }}
            css={{
              backgroundColor: '#65b355',
              color: '#fff',
            }}
          >
            Resync
          </button>
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    return <Spinner />;
  }

  const tableNameButtons = (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div css={{ marginBottom: '10px' }}>
        {tableNames?.map(tableName => {
          return (
            <label
              key={tableName}
              css={{
                marginRight: '40px',
                fontSize: '1rem',
                cursor: 'pointer',
              }}
            >
              <input
                key={tableName}
                type="checkbox"
                checked={!!selectedTableName[tableName]}
                onChange={event => handleCheckboxChange(tableName, event)}
              />
              <span css={{ marginLeft: '5px' }}>{tableName}</span>
            </label>
          );
        }) ?? []}
      </div>
      <div>
        <Button
          size="small"
          disabled={getSelectedTableNames().length === 0}
          onClick={() => {
            setIsSubmited(true);
          }}
        >
          Resync tables
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <div
        css={{
          border: '1px solid #ccc',
          borderRadius: '1rem',
          padding: '1rem',
        }}
      >
        <h2>Synchronize Billing Data</h2>
        <h6>Click one of the following buttons to synchronize the corresponding table's data.</h6>
        {tableNames && tableNames.length !== 0 ? (
          <div
            css={{
              gridTemplateColumns: 'repeat(auto-fill, 16rem)',
              gap: '0.25rem',
              justifyContent: 'center',
              paddingTop: '15px',
            }}
          >
            {tableNameButtons}
          </div>
        ) : (
          <h4>Error - No tables could be found.</h4>
        )}
        {isSubmited && modal}
      </div>
    </div>
  );
};

export default BillingDataSyncPage;
