import { createContext } from 'react';
import { EngagementNoteDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Dto/Model.gen';

export type EngagementNotePanelTabContextData = {
  notes: EngagementNoteDto[];
  hasNextPage: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const EngagementNotePanelTabContext = createContext<EngagementNotePanelTabContextData | undefined>(undefined);

export default EngagementNotePanelTabContext;
