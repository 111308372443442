import { BillingInfo } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import { subscriptionBillingInfoGet } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

const useBillingInfo = () => {
  const [isLoading, setLoading] = useState(true);
  const [billingInfo, setBillingInfo] = useState<BillingInfo>();

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => subscriptionBillingInfoGet({}));
      setLoading(false);

      if (!response) {
        return;
      }

      setBillingInfo(response.billingInfo);
    })();
  }, [callApi]);

  return { isLoading, billingInfo };
};

export default useBillingInfo;
