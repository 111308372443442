import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { formatCurrencyByCurrencyInfo } from '~/extensions/packages/currency/formatCurrency';
import Divider from '~/neo-ui/packages/divider/Divider';
import * as React from 'react';
import { Fragment } from 'react';
import { RoadmapInitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';

export type RoadmapInitiativeTileFeesProps = { initiative: RoadmapInitiativeDto };

const RoadmapInitiativeTileFees = ({ initiative }: RoadmapInitiativeTileFeesProps) => {
  const { currencyInfo } = useRegionalSettingsInfoContext();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      `}
    >
      {typeof initiative.budgetLineItems !== 'undefined' && (
        <Fragment>
          {initiative.budgetLineItems.slice(0, 2).map((budgetLineItem, index) => (
            <Label
              key={index}
              muted={true}
              css={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              {budgetLineItem.label}
              <span>
                {formatCurrencyByCurrencyInfo(budgetLineItem.costSubunits, {
                  code: currencyInfo.countryCode,
                  subunitsPerUnit: currencyInfo.subunitRatio,
                })}
              </span>
            </Label>
          ))}
          {initiative.budgetLineItems.length > 2 && (
            <Label
              size={'sm'}
              bold={true}
              color={'primary-400'}
            >
              {initiative.budgetLineItems.length - 2} more
            </Label>
          )}
        </Fragment>
      )}

      <Label
        size={'lg'}
        bold={true}
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        Total one-time fees:
        <span>
          {formatCurrencyByCurrencyInfo(initiative.sumBudgetSubunits, {
            code: currencyInfo.countryCode,
            subunitsPerUnit: currencyInfo.subunitRatio,
          })}
        </span>
      </Label>
      <Divider />
      {initiative.sumRecurringFees.sumRecurringMonthlySubunits !== 0 && (
        <Label
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          Monthly recurring fees:
          <span>
            {formatCurrencyByCurrencyInfo(initiative.sumRecurringFees.sumRecurringMonthlySubunits, {
              code: currencyInfo.countryCode,
              subunitsPerUnit: currencyInfo.subunitRatio,
            })}
            /month
          </span>
        </Label>
      )}
      {initiative.sumRecurringFees.sumRecurringYearlySubunits !== 0 && (
        <Label
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          Annual recurring fees:
          <span>
            {formatCurrencyByCurrencyInfo(initiative.sumRecurringFees.sumRecurringYearlySubunits, {
              code: currencyInfo.countryCode,
              subunitsPerUnit: currencyInfo.subunitRatio,
            })}
            /year
          </span>
        </Label>
      )}
      {initiative.sumRecurringFees.sumRecurringMonthlySubunits === 0 && initiative.sumRecurringFees.sumRecurringYearlySubunits === 0 && (
        <Label
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          Total recurring fees:
          <span>
            {formatCurrencyByCurrencyInfo(0, {
              code: currencyInfo.countryCode,
              subunitsPerUnit: currencyInfo.subunitRatio,
            })}
            /month
          </span>
        </Label>
      )}
      <Divider />
    </div>
  );
};

export default RoadmapInitiativeTileFees;
