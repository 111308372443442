import { ContractSummaryDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Dto/Model.gen';
import { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import ContractEditVendorTableCell from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-list-table/packages/contract-edit-vendor-table-cell/ContractEditVendorTableCell';
import * as React from 'react';
import ContractTemplateCreateButton from '~/wm/packages/strategy/packages/contract/packages/contract-template/packages/contract-template-create-button/ContractTemplateCreateButton';

const getContractColumns = (): DataTableColumn<ContractSummaryDto>[] => [
  {
    fieldKey: contract => contract.basicSummary.title,
    Header: 'Vendor',
    renderCell: contract => (
      <ContractEditVendorTableCell
        contractId={contract.basicSummary.contractId}
        contractVendor={contract.basicSummary.title}
        organizationId={contract.basicSummary.organizationInfo.organizationId}
      />
    ),
  },
  {
    fieldKey: contract => contract.basicSummary.status.displayName,
    Header: 'Status',
    renderCell: contract => contract.basicSummary.status.displayName,
  },
  {
    fieldKey: contract => contract.basicSummary.description,
    Header: 'Description',
    renderCell: contract => (typeof contract.basicSummary.description !== 'undefined' ? contract.basicSummary.description : '-'),
  },
  {
    fieldKey: contract => contract.basicSummary.location,
    Header: 'Location',
    renderCell: contract => (typeof contract.basicSummary.location !== 'undefined' ? contract.basicSummary.location : '-'),
  },
  {
    fieldKey: contract => contract.billingSummary.cycle.displayName,
    Header: 'Cycle',
    renderCell: contract => contract.billingSummary.cycle.displayName,
  },
  {
    fieldKey: contract => contract.billingSummary.costDisplay,
    Header: 'Cost',
    renderCell: contract => contract.billingSummary.costDisplay,
  },
  {
    fieldKey: contract => contract.billingSummary.costType,
    Header: 'Cost Type',
    renderCell: contract => contract.billingSummary.costType,
  },
  {
    fieldKey: contract => contract.billingSummary.numberOfSeats,
    Header: 'Number of seats',
    renderCell: contract => (typeof contract.billingSummary.numberOfSeats !== 'undefined' ? contract.billingSummary.numberOfSeats : '-'),
  },
  {
    fieldKey: contract => contract.assetsSummary,
    Header: 'Linked assets',
    renderCell: contract => contract.assetsSummary.length,
  },
  {
    fieldKey: contract => contract.billingSummary.nextDueAt,
    Header: 'Next due',
    renderCell: contract =>
      formatDate(new Date(`${contract.billingSummary.nextDueAt}`), {
        format: 'MMM dd, yyyy',
        timezone: TimezoneFormat.Utc,
      }),
  },
  {
    fieldKey: contract => contract.billingSummary.endAt,
    Header: 'End date',
    renderCell: contract =>
      typeof contract.billingSummary.endAt !== 'undefined'
        ? formatDate(new Date(`${contract.billingSummary.endAt}`), {
            format: 'MMM dd, yyyy',
            timezone: TimezoneFormat.Utc,
          })
        : '-',
  },
  {
    fieldKey: contract => contract.alertSummary.notifyAt,
    Header: 'Notice date',
    renderCell: contract =>
      typeof contract.alertSummary.notifyAt !== 'undefined'
        ? formatDate(new Date(`${contract.alertSummary.notifyAt}`), {
            format: 'MMM dd, yyyy',
            timezone: TimezoneFormat.Utc,
          })
        : '-',
  },
  {
    fieldKey: contract => contract.billingSummary.isAutoRenew,
    Header: 'Auto renew',
    renderCell: contract =>
      typeof contract.billingSummary.isAutoRenew !== 'undefined' ? (contract.billingSummary.isAutoRenew ? 'Yes' : 'No') : '-',
  },
  {
    fieldKey: contract => contract.basicSummary.isThirdParty,
    Header: 'Third party?',
    renderCell: contract =>
      typeof contract.basicSummary.isThirdParty !== 'undefined' ? (contract.basicSummary.isThirdParty ? 'Yes' : 'No') : '-',
  },
  {
    fieldKey: contract => contract.basicSummary.contractId,
    Header: 'Template',
    renderCell: contract => <ContractTemplateCreateButton contractId={contract.basicSummary.contractId} />,
  },
];

export default getContractColumns;
