import { css } from '@emotion/react';
import * as React from 'react';
import Box from '~/neo-ui/packages/box/Box';
import Button from '~/neo-ui/packages/button/Button';

export type CardSuccessProps = {
  title: string;
  description: string;
  actionUrl: string;
  actionDescription: string;
  openInNewTab?: boolean;
  imageSource: string;
};

const CardSuccess: React.FunctionComponent<React.PropsWithChildren<CardSuccessProps>> = ({
  title,
  description,
  actionUrl,
  actionDescription,
  openInNewTab = false,
  imageSource,
  children,
}) => (
  <Box
    css={css`
      text-align: center;
      color: white;
      width: 38.75rem;
      background-image: url('${imageSource}');
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size: 7.5rem;
    `}
    background={'backgroundContrast'}
    theme={'secondary'}
    borderRadius={'radius400'}
    boxShadow={'shadow300'}
    padding={'padding500'}
  >
    <div
      css={css`
        font-size: 2.125rem;
        font-weight: bold;
        line-height: 2.5rem;
      `}
    >
      {title}
    </div>
    <div
      css={css`
        margin-top: 0.75rem;
        font-size: 1.125rem;
        margin-bottom: 1.375rem;
      `}
    >
      {description}
    </div>
    <Button
      css={css`
        display: inline-block;
        line-height: 1.75rem;
      `}
      theme={'primary'}
      size={'lg'}
      anchor={{
        href: actionUrl,
        openInNewTab,
      }}
    >
      {actionDescription}
    </Button>
    {children}
  </Box>
);

export default CardSuccess;
