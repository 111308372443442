import { Enum as ProductEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription/Product/ProductFactoryNested.gen';
import { useRef, useState } from 'react';
import { ButtonInternalRef } from '~/neo-ui/packages/button/ButtonInternal';
import MultiStepForm from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import Spinner from '~/neo-ui/spinner/Spinner';
import SubscriptionPurchasedStep from '~/wm/packages/subscription/packages/upgrade/wizard/SubscriptionUpgradeWizardV2/SubscriptionPurchasedStep';
import PlanCheckout from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/PlanCheckout';
import PlanSelection from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-selection/PlanSelection';
import useBillingInfo from '~/wm/packages/subscription/packages/billing/hooks/useBillingInfo';
import useCreditCardDetails, { CreditCardDetails } from '~/wm/packages/subscription/packages/hooks/useCreditCardDetails';
import PurchaseSubscriptionContainer from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscriptionContainer';
import { getCountries } from '~/neo-ui/model/Country';
import AddonSelection from '~/wm/packages/subscription/packages/purchase-subscription/packages/addon-selection/AddonSelection';
import { DiscountBreakdownItem } from '@SubscriptionClient/BeastClient/Beast/Msp/Billing/SelfUpgrade/Dto/CheckoutSummaryNested.gen';

export type BillingInfo = {
  address: {
    city: string;
    countryCode: string;
    countryFallback: string;
    line1: string;
    line2: string;
    stateCode: string;
    stateFallback: string;
    zip: string;
  };
  name: string;
};
export interface PurchaseSubscriptionForm {
  subscriptionPlanId: string | undefined;
  paymentCard: CreditCardDetails | undefined;
  selectedPlanLimit: number | undefined;
  defaultPlanLimit: number | undefined;
  productEnum: ProductEnum | undefined;
  purchasePrice:
    | {
        planPrice: number;
        totalBeforeTax: number;
        totalAfterTax: number;
        totalDiscountedAmount: number;
        totalCouponDiscountedAmount: number;
        expansionsPrice: number;
        activationPrice: number;
        nextEstimatedAmountWithoutTax: number;
        tax: number;
      }
    | undefined;
  billingAddress: BillingInfo | undefined;
  isBillingAddressSet: boolean;
  isTaxSet: boolean;
  nextPaymentDayLabel: string | undefined;
  nextPaymentDate: string | undefined;

  isGrow: boolean;
  productLogoUrl: string | undefined;
  productLogoLabel: string | undefined;

  planSubtotal: number;
  addonSubtotal: number;
  activationFee: number;
  subtotal: number;
  termLength: number;

  hasAddonSelectionStep: boolean;
  selectedAddonIds: string[];
  ownedAddonIds: string[];

  couponCodes: string[];
  couponBreakdown: DiscountBreakdownItem[] | undefined;
  couponCodeError: string | undefined;
  isLoadingCoupon: boolean;

  submitBillingAddress: React.RefObject<ButtonInternalRef>;
  submitCard: React.RefObject<ButtonInternalRef>;
  submitPayment: React.RefObject<ButtonInternalRef>;
}

const wrap = (index: number, pages: number) => ((index % pages) + pages) % pages;

const PurchaseSubscription = (props: {
  preselectProduct: string;
  accountCompanyName: string;
  geoLookupCountryIso3: string | undefined;
}) => {
  const { billingInfo } = useBillingInfo();
  const { creditCardResponse } = useCreditCardDetails();
  const { preselectProduct, accountCompanyName, geoLookupCountryIso3 } = props;
  const [pageIndex, setPageIndex] = useState(0);
  const onNextHandler = () => setPageIndex(n => wrap(n + 1, 4));
  const onPreviousHandler = () => setPageIndex(n => wrap(n - 1, 4));
  const onGoToPageHandler = (index: number) => setPageIndex(index);
  const submitCard = useRef<ButtonInternalRef>(null);
  const submitBillingAddress = useRef<ButtonInternalRef>(null);
  const submitPayment = useRef<ButtonInternalRef>(null);

  if (!billingInfo || !creditCardResponse) {
    return (
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </div>
    );
  }
  const initCountry = getCountries({ byAlpha2: billingInfo?.billingAddressDto?.countryCode });
  return (
    <MultiStepForm<PurchaseSubscriptionForm>
      css={{
        marginInline: '-15px',
        ...(pageIndex === 4 && {
          width: '100%',
        }),
      }}
      steps={[
        <PlanSelection
          key={0}
          preselectProduct={preselectProduct}
        />,
        <AddonSelection key={1} />,
        <PlanCheckout key={2} />,
        <SubscriptionPurchasedStep key={3} />,
      ]}
      stepIndex={pageIndex}
      initialValues={{
        subscriptionPlanId: undefined,
        selectedPlanLimit: undefined,
        defaultPlanLimit: undefined,
        paymentCard: creditCardResponse.cardDetails,
        purchasePrice: undefined,
        submitCard,
        submitBillingAddress,
        submitPayment,
        billingAddress:
          billingInfo?.billingAddressDto && billingInfo.billingAddressDto.postalZipCode
            ? {
                address: {
                  city: billingInfo?.billingAddressDto.city,
                  countryCode: billingInfo?.billingAddressDto.countryCode,
                  countryFallback: initCountry.length > 0 ? initCountry[0].alpha2 : 'USA',
                  line1: billingInfo?.billingAddressDto.addressLine1,
                  line2: billingInfo?.billingAddressDto.addressLine2 ?? '',
                  stateCode: billingInfo?.billingAddressDto.provinceStateCode,
                  stateFallback: billingInfo?.billingAddressDto.provinceStateCode,
                  zip: billingInfo?.billingAddressDto.postalZipCode,
                },
                name: billingInfo?.billingAddressDto.companyName,
              }
            : {
                address: {
                  city: '',
                  countryCode: '',
                  countryFallback: geoLookupCountryIso3 ?? 'USA',
                  line1: '',
                  line2: '',
                  stateCode: '',
                  stateFallback: '',
                  zip: '',
                },
                name: accountCompanyName,
              },
        productEnum: undefined,
        nextPaymentDayLabel: undefined,
        nextPaymentDate: undefined,
        isBillingAddressSet: !!billingInfo.billingAddressDto?.provinceStateCode,
        isTaxSet: !!billingInfo.billingAddressDto?.provinceStateCode,
        isGrow: false,
        productLogoUrl: undefined,
        productLogoLabel: undefined,
        planSubtotal: 0,
        addonSubtotal: 0,
        activationFee: 0,
        subtotal: 0,
        termLength: 12,
        hasAddonSelectionStep: true,
        selectedAddonIds: [],
        ownedAddonIds: [],
        couponCodes: [],
        couponCodeError: undefined,
        couponBreakdown: undefined,
        isLoadingCoupon: false,
      }}
      onSubmit={() => {}}
      onNextHandler={onNextHandler}
      onPreviousHandler={onPreviousHandler}
      onGoToPageHandler={onGoToPageHandler}
      Container={PurchaseSubscriptionContainer}
    />
  );
};

export default PurchaseSubscription;
