import { css } from '@emotion/react';
import React from 'react';
import { Themeable } from '~/neo-ui/model/capacity';
import FilterHeaderDropdownIcon from '~/neo-ui/packages/filter/packages/filter-headers/filter-header-common/filter-header-dropdown-icon/FilterHeaderDropdownIcon';

type FilterHeaderEmptyProps = {
  label: string;
  isOpen: boolean;
} & Themeable;

const FilterHeaderEmptyMultiSelect: React.FunctionComponent<FilterHeaderEmptyProps> = ({ label, isOpen }) => (
  <div
    css={css`
      padding-right: 0.625rem;
      display: flex;
      align-items: center;
      gap: 0.4375rem;
      border-radius: 0.375rem 0 0 0.375rem;
    `}
  >
    <div
      css={css`
        padding: 0 0.625rem;
      `}
    >
      {label}
    </div>
    <FilterHeaderDropdownIcon
      isOpen={isOpen}
      color={'dark-900'}
    />
  </div>
);

export default FilterHeaderEmptyMultiSelect;
