import * as Yup from 'yup';
import FiscalQuarter from '~/extensions/packages/date/packages/fiscal-quarter/FiscalQuarter';
import { InitiativeSaveFormData } from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';

const initiativeSaveFormValidationSchema: (
  currentFiscalQuarter: FiscalQuarter,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Yup.ObjectSchema<any> = currentFiscalQuarter =>
  Yup.object().shape<Partial<InitiativeSaveFormData>>(
    {
      name: Yup.string()
        // Duped in backend 7a638178-802b-48f9-9888-07585406e8c4
        .max(250, 'That title is too long!'),
      executiveSummary: Yup.string()
        .typeError('Executive summary must be text')
        // Duped in backend 8fb3e950-3a4e-49d2-b427-5e7d9217dd1b
        .max(10000, 'This executive summary is too long!'),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      budgetQuarter: Yup.object().shape<any>({
        // Duped in backend e5df71bd7b373b1375c25f703aab664eda13d916
        year: Yup.number().when(['quarter'], {
          is: quarter => typeof quarter !== 'undefined',
          then: Yup.number()
            .required()
            .test('Year Validation', 'Year must be within now and 5 years from now', value => {
              const minYear = currentFiscalQuarter.year - 1;
              const maxYear = minYear + 6;

              return Number(value) >= minYear && Number(value) <= maxYear;
            }),
        }),

        // Duped in backend e5df71bd7b373b1375c25f703aab664eda13d916
        quarter: Yup.number().test('Quarter Validation', 'Quarter must not be within the past', (value, ctx) => {
          const minYear = currentFiscalQuarter.year - 1;
          const maxYear = minYear + 6;
          const currentQuarter = currentFiscalQuarter.quarter;

          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const yearSelected: number = ctx.parent.year;

          return (
            ![minYear, maxYear].includes(yearSelected) ||
            (minYear === yearSelected && Number(value) >= Number(currentQuarter)) ||
            (maxYear === yearSelected && Number(value) <= Number(currentQuarter))
          );
        }),
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      budgetLineItems: Yup.lazy(() =>
        Yup.array().of(
          Yup.object().shape({
            label: Yup.string().required('Required'),
            costUnits: Yup.number()
              .typeError('Cost must be number')
              .required('Required')
              .min(0, 'Cost must be positive')
              .max(20000000, 'Cost is too large'),
          }),
        ),
      ),
      recurringLineItems: Yup.lazy(() =>
        Yup.array().of(
          Yup.object().shape({
            label: Yup.string().required('Required'),
            costUnits: Yup.number()
              .typeError('Cost must be number')
              .required('Required')
              .min(0, 'Cost must be positive')
              .max(20000000, 'Cost is too large'),
          }),
        ),
      ),
      priority: Yup.string().required(),
    },
    [['quarter', 'year']],
  );

export default initiativeSaveFormValidationSchema;
