import React from 'react';
import { WidgetDto } from '@DashboardClient/Dashboard/Packages/Widget/Packages/Dto/Model.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import { widgetAvailabilityGet } from '@DashboardClient/DashboardClientMsp.gen';

export const useWidgetAvailability = () => {
  const [widgetAvailability, setWidgetAvailability] = React.useState<WidgetDto[] | undefined>(undefined);

  const { callApi } = useApi();

  React.useEffect(() => {
    (async () => {
      const response = await callApi(() => widgetAvailabilityGet({}));
      if (!response) {
        return;
      }
      setWidgetAvailability(response.payloads);
    })();
  }, [callApi]);

  return widgetAvailability;
};
