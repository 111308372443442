import * as React from 'react';
import { ComponentType, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  HeaderContext,
  OnChangeFn,
  PaginationState,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import { FieldKeyExpression, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { css } from '@emotion/react';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { reactMemoTyped } from '~/extensions/packages/types/reactMemoTyped';
import ButtonGroup from '~/neo-ui/packages/button/packages/button-group/ButtonGroup';
import Button from '~/neo-ui/packages/button/Button';
import Icon from '~/neo-ui/packages/icon/Icon';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import TableClientSideFilterInput from '~/neo-ui/packages/table/packages/table-client-side/TableClientSideFilterInput';
import { InputTitleSizes } from '~/neo-ui/packages/input/packages/input-title/InputTitle';

export type TableClientSideProps<T extends object> = {
  data: T[];
  columns: TableClientSideColumn<T>[];

  /**
   * The row key to expand, if not set the table will not be expandable
   */
  expandingRowKey?: (originalRow: T) => T[] | undefined;

  /**
   * Sets the pagination of the table, not including this disables pagination
   */
  pagination?: PaginationState;

  /**
   * Addition optional behavior when table page change
   */
  onPageChange?: (pagination: PaginationState) => void;

  /**
   * Sets the global filter of the table
   */
  globalFilter?: string;

  /**
   * Addition optional behavior when table global filter change
   */
  onGlobalFilterChange?: (search: string) => void;

  /**
   * The children of the table, this is a function that provides the Table component
   * to be placed anywhere in the children
   *
   * Later we can add more components to be placed anywhere like pagination and search
   */
  children: ({
    Table,
    Pagination,
    GlobalFilter,
  }: {
    Table: ComponentType<TableClientSideTableProps>;
    Pagination: ComponentType<ClientSidePaginationProps>;
    GlobalFilter: ComponentType<ClientSideGlobalFilterProps>;
  }) => ReactNode;
} & Styleable;

export type TableClientSideColumn<T extends object> = {
  fieldKey: FieldKeyExpression<T> | string;
  header: (({ table }: HeaderContext<T, unknown>) => ReactNode) | ReactNode;
  renderCell: (row: Row<T>) => ReactNode;
  /**
   * This applies styling to ensure text content of the cell will be ellipsized.
   *
   * Typically, this is used when columns have specified widths, and we don't
   * want the text to wrap.
   *
   * This prevents column widths from auto-sizing based on the width of the
   * text content.
   */
  ellipsizeTextContent?: boolean;
  /**
   * Optional fixed width of the column, otherwise the column will auto-size
   */
  widthRem?: number;
  /**
   * Sets sorting for this column, enabled by default
   */
  enableSorting?: boolean;
  /**
   * Sets global filtering for this column, enabled by default
   */
  enableGlobalFilter?: boolean;
};

type ClientSidePaginationProps = Styleable;
type ClientSideGlobalFilterProps = {
  placeholder?: string;
  size?: InputTitleSizes;
} & Styleable &
  Themeable;

type TableClientSideTableProps = {
  tableHeadColor?: Color;
} & Styleable;

/**
 * Table component that renders a client-side table.
 *
 * This component provides children function with composable table components to place anywhere
 */
const TableClientSide = <T extends object>({
  data,
  columns,
  pagination: parentPagination,
  onPageChange,
  globalFilter: parentGlobalFilter = '',
  onGlobalFilterChange: onGlobalFilterUpdate,
  expandingRowKey,
  className,
  children,
}: TableClientSideProps<T>) => {
  const [pagination, setPagination] = useState<PaginationState | undefined>(parentPagination);
  const [globalFilter, setGlobalFilter] = useState(parentGlobalFilter);

  useEffect(() => {
    if (typeof onGlobalFilterUpdate !== 'undefined' && parentGlobalFilter !== globalFilter) {
      onGlobalFilterUpdate(globalFilter);
    }

    // On global filter change, reset pagination to first page
    setPagination(typeof pagination === 'undefined' ? undefined : { pageSize: pagination?.pageSize, pageIndex: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  // Manage the global filter input focus state
  const [isGlobalFilterFocused, setIsGlobalFilterFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current && isGlobalFilterFocused) {
      inputRef.current.focus();
    }
  }, [isGlobalFilterFocused, globalFilter]);

  const reactTableColumns: ColumnDef<T>[] = useMemo(
    () =>
      columns.map(
        (column): ColumnDef<T> => ({
          accessorKey: resolveFieldKey(column.fieldKey),
          header: typeof column.header === 'function' ? column.header : () => column.header,
          cell: ({ row }) => column.renderCell(row),
          size: column.widthRem ? column.widthRem * 16 : 0,
          enableSorting: column.enableSorting,
          enableGlobalFilter: column.enableGlobalFilter,
        }),
      ),
    [columns],
  );

  const table = useReactTable({
    data,
    columns: reactTableColumns,
    getSubRows: expandingRowKey,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getPaginationRowModel: typeof pagination !== 'undefined' ? getPaginationRowModel() : undefined,
    onPaginationChange: typeof pagination !== 'undefined' ? (setPagination as OnChangeFn<PaginationState>) : undefined,
    state: {
      pagination: typeof pagination !== 'undefined' ? pagination : undefined,
      globalFilter,
    },
    autoResetPageIndex: false,
  });

  const Pagination = ({ className }: ClientSidePaginationProps) =>
    typeof pagination !== 'undefined' ? (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: flex-end;
          white-space: nowrap;
        `}
        className={className}
      >
        <div
          css={css`
            margin-right: 0.625rem;
          `}
        >
          <b>
            {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} -{' '}
            {Math.min(
              table.getState().pagination.pageIndex * table.getState().pagination.pageSize + table.getState().pagination.pageSize,
              data.length,
            )}
          </b>{' '}
          of <b>{data.length}</b>
        </div>
        <ButtonGroup>
          <Button
            onClick={() => {
              if (typeof onPageChange !== `undefined`) {
                onPageChange({ pageSize: table.getState().pagination.pageSize, pageIndex: table.getState().pagination.pageIndex - 1 });
              }
              table.previousPage();
            }}
            disabled={!table.getCanPreviousPage()}
          >
            <Icon icon="ArrowLeft" />
          </Button>
          <Button
            onClick={() => {
              if (typeof onPageChange !== `undefined`) {
                onPageChange({ pageSize: table.getState().pagination.pageSize, pageIndex: table.getState().pagination.pageIndex + 1 });
              }
              table.nextPage();
            }}
            disabled={!table.getCanNextPage()}
          >
            <Icon icon="ArrowRight" />
          </Button>
        </ButtonGroup>
      </div>
    ) : null;

  const GlobalFilter = useCallback(
    ({ placeholder, size, theme, className }: ClientSideGlobalFilterProps) => (
      <TableClientSideFilterInput
        ref={inputRef}
        onFocus={() => setIsGlobalFilterFocused(true)}
        onBlur={() => setIsGlobalFilterFocused(false)}
        value={globalFilter ?? ''}
        onChange={e => setGlobalFilter(String(e))}
        placeholder={placeholder}
        size={size}
        theme={theme}
        className={className}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalFilter],
  );

  /**
   * Table component of the client-side table.
   */
  const Table = ({ tableHeadColor, className }: TableClientSideTableProps) => (
    <div
      css={css`
        display: block;
        width: 100%;
        overflow-x: auto;
      `}
      className={className}
    >
      <table
        css={css`
          width: 100%;
          border: 0.0625rem solid ${colorToCode('dark-900-12')};
          overflow: auto;
          margin-bottom: 0;
        `}
      >
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr
              key={headerGroup.id}
              css={css`
                border-bottom: 0.0625rem solid ${colorToCode('dark-900-12')};
                background-color: ${colorToCode(tableHeadColor ?? 'light-000')};
                color: ${colorToCode(typeof tableHeadColor === 'undefined' ? 'dark-900' : 'light-000')};
                height: 2.5rem;
              `}
            >
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  css={css`
                    user-select: none;
                    border-top: 0;
                    border-bottom: 0.0625rem solid ${colorToCode('dark-900-12')};
                    vertical-align: middle;
                    padding: 0.5rem;
                    max-width: ${header.column.columnDef.size === 0 ? 'auto' : `${header.column.columnDef.size}px`};
                    width: ${header.column.columnDef.size === 0 ? 'auto' : `${header.column.columnDef.size}px`};
                    ${header.column.getIsSorted()
                      ? css`
                          background: ${colorToCode('secondary-050')};
                          box-shadow: 0 -3px 0 0 inset ${colorToCode('secondary-400')};
                          color: ${colorToCode('secondary-400')};
                        `
                      : ''}
                  `}
                >
                  <div
                    onClick={e => {
                      const handler = header.column.getToggleSortingHandler();
                      if (typeof handler !== 'undefined') {
                        handler(e);
                      }
                    }}
                    css={css`
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    `}
                  >
                    <Header
                      size={5}
                      css={css`
                        width: 100%;
                      `}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </Header>
                    {header.column.getCanSort() && (
                      <img
                        alt={'sort direction'}
                        css={css`
                          flex-shrink: 0;
                        `}
                        src={
                          {
                            asc: '/i/sort/Up.svg',
                            desc: '/i/sort/Down.svg',
                          }[header.column.getIsSorted() as string] ?? '/i/sort/None.svg'
                        }
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          css={css`
            > * {
              &:nth-of-type(odd) {
                background-color: ${colorToCode('light-100')};
              }

              &:nth-of-type(even) {
                background-color: ${colorToCode('light-000')};
              }
            }
          `}
        >
          {table.getRowModel().rows.map(row => (
            <tr
              key={row.id}
              css={css`
                height: 2.5rem;
                ${row.getIsExpanded() && `background-color: ${colorToCode('primary-050')} !important;`}
              `}
            >
              {row.getVisibleCells().map(cell => (
                <td
                  key={cell.id}
                  css={[
                    css`
                      border-top: 0;
                      padding: 0.5rem;
                      max-width: ${cell.column.columnDef.size === 0 ? 'auto' : `${cell.column.columnDef.size}px`};
                    `,
                    ...((cell.column as unknown as TableClientSideColumn<T>).ellipsizeTextContent
                      ? [
                          css`
                            white-space: nowrap;
                            overflow: hidden;
                            max-width: 0;
                            text-overflow: ellipsis;
                          `,
                        ]
                      : []),
                  ]}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return <div className={className}>{children({ Table, Pagination, GlobalFilter })}</div>;
};

export default reactMemoTyped(TableClientSide);
