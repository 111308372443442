import { css } from '@emotion/react';
import * as React from 'react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type TooltipSectionProps = {
  title: string;
  description: string;
};

const TooltipSection: React.FunctionComponent<TooltipSectionProps> = ({ title, description }) => (
  <div>
    <p
      css={css`
        margin-bottom: 0;
      `}
    >
      {title}
    </p>
    <p
      css={css`
        margin-bottom: 0;
        color: ${colorToCode('light-000-80')};
        font-size: 0.75rem;
      `}
    >
      {description}
    </p>
  </div>
);

export default TooltipSection;
