import { css } from '@emotion/react';
import * as ReactSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import * as React from 'react';
import { neoColorToCode } from '~/neo-ui/packages/color/NeoColor';
import Icon from '~/neo-ui/packages/icon/Icon';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type SliderProps = {
  minValue: number;
  maxValue: number;
  value: number;
  onChange: (value: number) => void;
  className?: string;
};

const Slider: React.FunctionComponent<SliderProps> = ({ minValue, maxValue, value, onChange, className }) => (
  <div
    className={className}
    css={css`
      width: 500px;
      height: 30px;
    `}
  >
    <div
      css={css`
        width: 91%;
        margin-left: 17px;
      `}
    >
      <ReactSlider.default
        min={minValue}
        max={maxValue}
        value={value}
        onChange={value => onChange(value as number)}
        dots={true}
        marks={{
          [maxValue]: {
            style: {
              backgroundColor: 'transparent',
              marginTop: -14,
            },
            label: (
              <div
                css={css`
                  margin-top: 2px;
                `}
              >
                <Icon
                  color={'light-000'}
                  sizePx={10}
                  icon={'Add'}
                />
              </div>
            ),
          },
        }}
        handleStyle={{
          backgroundColor: colorToCode('primary-300'),
          borderColor: neoColorToCode('shadow-primary2'),
          borderWidth: 4,
          height: 30,
          width: 30,
          boxShadow: `0px 3px 6px 1px ${neoColorToCode('shadow-primary2', 0.2)}`,
        }}
        railStyle={{
          backgroundColor: colorToCode('light-200'),
          borderRadius: 15,
          height: 30,
          width: '108%',
          marginTop: -5,
          marginLeft: -17,
        }}
        trackStyle={{ display: 'none' }}
        dotStyle={{
          backgroundColor: colorToCode('light-300'),
          borderColor: colorToCode('light-300'),
          height: 20,
          width: 20,
          marginBottom: -14,
        }}
      />
    </div>
  </div>
);

export default Slider;
