import Button from '~/neo-ui/packages/button/Button';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { AutoEmailReportRecipientAddFormData } from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-recipients/packages/auto-email-report-recipient-add-form/AutoEmailReportRecipientAddForm';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import FormTitleInput from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/FormTitleInput';
import React from 'react';

const nameFieldKey: FieldKeyExpression<AutoEmailReportRecipientAddFormData> = values => values.name;
const emailFieldKey: FieldKeyExpression<AutoEmailReportRecipientAddFormData> = values => values.email;

const AutoEmailReportRecipientAdd = () => {
  const { submitForm, isSubmitting } = useFormContext<AutoEmailReportRecipientAddFormData>();

  return (
    <tr>
      <td>
        <FormTitleInput
          fieldKey={nameFieldKey}
          placeholder={'Recipient Name...'}
        />
      </td>
      <td>
        <FormTitleInput
          fieldKey={emailFieldKey}
          placeholder={'Email...'}
        />
      </td>
      <td>
        <Button
          size={'sm'}
          iconLeft={'Add'}
          theme={'primary'}
          loading={isSubmitting}
          onClick={() => submitForm()}
        />
      </td>
    </tr>
  );
};

export default AutoEmailReportRecipientAdd;
