import OrganizationStarButton from '~/wm/packages/organization/packages/organization-star/packages/organization-star-button/OrganizationStarButton';
import { css } from '@emotion/react';
import useOrganizationStar from '~/wm/packages/organization/packages/organization-star/hooks/useOrganizationStar';
import Button from '~/neo-ui/packages/button/Button';

export type OrganizationStarTitleProps = {
  organizationId: string;
  isFreeEdition: boolean;
};

const OrganizationStarTitle = ({ organizationId, isFreeEdition }: OrganizationStarTitleProps) => {
  const { starredOrganizations, addStar, removeStar } = useOrganizationStar(isFreeEdition);

  if (typeof starredOrganizations === 'undefined') {
    return null;
  }

  const isStarred = starredOrganizations.has(organizationId);

  return (
    <div
      css={css`
        display: flex;
        margin-left: 1rem;
      `}
    >
      {isFreeEdition ? (
        <Button
          iconLeft={'FavoriteNo'}
          size={'lg'}
          disabled={true}
        />
      ) : (
        <OrganizationStarButton
          isStarred={isStarred}
          onStar={isStarred => (isStarred ? addStar(organizationId) : removeStar(organizationId))}
          size={'lg'}
        />
      )}
    </div>
  );
};

export default OrganizationStarTitle;
