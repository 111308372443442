import TreeImpactRelatedService from '~/wm/packages/tree-impact/model/TreeImpactRelatedService';

// Helper functions
const getTotalTreesPledgedDescription = (totalTreesPlanted: number) => `Your total ${totalTreesPlanted > 1 ? 'trees' : 'tree'} pledged`;

const getTotalAssetsResponsiblyDisposedDescription = (totalOrderUnits: number, isFutureTense: boolean) =>
  `${totalOrderUnits > 1 ? 'Assets' : 'Asset'} ${isFutureTense ? 'will be' : ''} responsibly disposed`;

const getTotalTreesPledgedThisOrderDescription = (totalTreesPlanted: number, isFutureTense: boolean) =>
  `${totalTreesPlanted > 1 ? 'Trees' : 'Tree'} ${isFutureTense ? 'will be pledged' : 'pledged'} with this order`;

// Implementation
export const treeImpactDisposalService: TreeImpactRelatedService = {
  serviceId: 'disposal',
  serviceDisplayName: 'Disposal',

  // Intro card
  serviceIntroDescriptionLegacy: 'with every responsible disposal',
  serviceIntroDescription: 'With every asset you responsibly dispose of.',
  getTreesPerOrderUnitDescription: (treesPerOrderUnit: number) => `${treesPerOrderUnit > 1 ? 'trees' : 'tree'}`,

  // Account summary card
  accountSummaryTreesPlantedImageSource: '/i/graphic/services/impact/internal/trees-pledged-total.svg',
  accountSummaryUnitsImageSource: '/i/graphic/services/impact/internal/disposal.svg',

  getAccountSummaryUnitsDescription: (totalOrdersUnits: number) =>
    `Total ${getTotalAssetsResponsiblyDisposedDescription(totalOrdersUnits, false)}`,

  getAccountSummaryTreesPlantedDescription: getTotalTreesPledgedDescription,

  // Order summary card
  summaryServiceOrderTreesPlantedImageSource: '/i/graphic/services/impact/internal/trees-confirmed.svg',
  summaryAccountTreesPlantedImageSource: '/i/graphic/services/impact/internal/trees-pledged-total.svg',
  summaryServiceOrderUnitImageSource: '/i/graphic/services/impact/internal/disposal.svg',

  getSummaryServiceOrderUnitDescription: (totalOrderUnits: number) => getTotalAssetsResponsiblyDisposedDescription(totalOrderUnits, true),

  getSummaryAccountTreesPlantedDescription: getTotalTreesPledgedDescription,

  getSummaryServiceOrderTreesPlantedDescription: (totalTreesPlanted: number) =>
    getTotalTreesPledgedThisOrderDescription(totalTreesPlanted, false),

  // Order potential card
  potentialServiceOrderTreesPlantedImageSource: '/i/graphic/services/impact/internal/trees-potential.svg',
  potentialAccountTreesPlantedImageSource: '/i/graphic/services/impact/internal/trees-pledged-potential.svg',
  potentialServiceOrderUnitImageSource: '/i/graphic/services/impact/internal/disposal.svg',

  getPotentialServiceOrderTreesPlantedDescription: (totalTreesPlanted: number) =>
    getTotalTreesPledgedThisOrderDescription(totalTreesPlanted, true),

  getPotentialAccountTreesPlantedDescription: (totalTreesPlanted: number) =>
    `Your total ${totalTreesPlanted > 1 ? 'trees' : 'tree'} will grow`,

  getPotentialServiceOrderUnitDescription: (totalOrderUnits: number) => getTotalAssetsResponsiblyDisposedDescription(totalOrderUnits, true),

  getPotentialTreesCount: (totalOrderUnits: number, treesPerUnit: number, treesLimit?: number) =>
    treesLimit ? Math.min(totalOrderUnits * treesPerUnit, treesLimit) : totalOrderUnits * treesPerUnit,
};
