import { css } from '@emotion/react';
import * as React from 'react';
import { HeaderGroup } from 'react-table';
import { SortState } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import TableHeader from '~/neo-ui/packages/table/packages/data-table/packages/table-header/TableHeader';
import { resolveFieldKey, toFieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type TableHeadProps<T extends Record<string, unknown>> = {
  headerGroups: HeaderGroup<T>[];
  sortableColumnIds: string[];
  enableClientSorted?: boolean;
  disableSort?: boolean;
  defaultSort?: SortState<T>;
  onSortChange?: (sort: SortState<T> | undefined) => void;
  isDisplayDataOnly?: boolean;
  hasTableBorder?: boolean;
};

const TableHead = <T extends Record<string, unknown>>({
  headerGroups,
  sortableColumnIds,
  enableClientSorted = false,
  disableSort = false,
  defaultSort,
  onSortChange,
  isDisplayDataOnly = false,
  hasTableBorder = true,
}: TableHeadProps<T>) => {
  const [sort, setSort] = React.useState(defaultSort);

  React.useEffect(() => {
    if (onSortChange) {
      onSortChange(sort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  return (
    <thead>
      {headerGroups.map(headerGroup => (
        // eslint-disable-next-line react/jsx-key
        <tr
          css={css`
            ${isDisplayDataOnly ? '> * { &:last-child { text-align: right; > div { justify-content: flex-end; } } }' : ''}
            border-bottom: 1px solid ${colorToCode('dark-900-12')};
            background-color: #ffffff;
            height: 2.5rem;
          `}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map(column => {
            const canSort = !disableSort && sortableColumnIds.includes(column.id);
            const isSorted = canSort && sort && resolveFieldKey(sort.key) === column.id;
            const isSortedDesc = isSorted && sort.order === 'descending';
            return (
              <TableHeader
                key={column.id}
                column={column}
                canSort={canSort}
                isSorted={isSorted}
                isSortedDesc={isSortedDesc}
                onToggleSort={e => {
                  if (!canSort) {
                    return;
                  }
                  if (enableClientSorted) {
                    // Use react-table implementation
                    (
                      column.getSortByToggleProps() as {
                        onClick: React.MouseEventHandler;
                      }
                    ).onClick(e);
                  }
                  setSort({
                    key: toFieldKeyExpression(column.id),
                    order: !isSorted ? 'ascending' : isSortedDesc ? 'ascending' : 'descending',
                  });
                }}
                hasTableBorder={hasTableBorder}
              />
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

export default TableHead;
