import Button from '~/neo-ui/packages/button/Button';
import Testable from '~/neo-ui/packages/testable/Testable';
import InsightCreateForm from '~/wm/packages/strategy/packages/insight/packages/insight-create-button/insight-create-modal/InsightCreateForm';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import { Fragment, FunctionComponent, useState } from 'react';

export type InsightCreateButtonProps = {
  disabled: boolean;
  refreshAction: () => void;
  isButtonLink?: boolean;
};

const InsightCreateButton: FunctionComponent<InsightCreateButtonProps> = ({ disabled, refreshAction, isButtonLink = false }) => {
  const [isDisplayingModal, setDisplayModal] = useState(false);

  const buttonLabel: string = 'Create an Insight...';

  const button = isButtonLink ? (
    <ButtonLink
      iconRight={'ArrowRight'}
      rightAlign={true}
      onClick={() => setDisplayModal(true)}
    >
      Create New
    </ButtonLink>
  ) : (
    <Button
      iconLeft={'Add'}
      theme={'primary'}
      onClick={() => setDisplayModal(true)}
      disabled={disabled}
    >
      {buttonLabel}
    </Button>
  );

  return (
    <Fragment>
      <Testable testId={'insight-create'}>{button}</Testable>
      <InsightCreateForm
        isOpen={isDisplayingModal}
        onDismiss={() => setDisplayModal(false)}
        refreshAction={refreshAction}
      />
    </Fragment>
  );
};

export default InsightCreateButton;
