import useContractListMutation, {
  UseContractListMutationOptions,
} from '~/wm/packages/strategy/packages/contract/packages/contract-list/hooks/useContractListMutation';
import { contractUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { ContractUpsertPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Dto/Model.gen';

type ContractUpdatePayload = {
  contractId: string;
  organizationId: string;
  contractUpdatePayload: ContractUpsertPayloadDto;
};

export type UseContractUpdate = {
  updateContract: (contractUpdatePayload: ContractUpdatePayload, isRequestActive?: () => boolean) => void;
  isUpdatingContract: boolean;
};

const useContractUpdate = (options?: UseContractListMutationOptions): UseContractUpdate => {
  const [updateContract, isUpdatingContract] = useContractListMutation(
    (contractUpdatePayload: ContractUpdatePayload) => contractUpdate(contractUpdatePayload),
    options,
  );

  return {
    updateContract,
    isUpdatingContract,
  };
};

export default useContractUpdate;
