import * as React from 'react';
import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import PanelTabGroup from '~/neo-ui/packages/panel/packages/PanelTabGroup/PanelTabGroup';
import OrganizationProvider from '~/wm/packages/organization/context/OrganizationProvider';
import { stringToEngagementActionStatusFilter } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/types/EngagementActionStatusFilter';
import EngagementActionListPanelTab from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-action-list-panel-tab/EngagementActionListPanelTab';
import EngagementNoteListPanelTabProvider from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/context/EngagementNoteListPanelTabProvider';
import EngagementNoteListPanelTab from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/EngagementNoteListPanelTab';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import ActiveUsersProvider from '~/wm/packages/account/packages/user/context/ActiveUsersProvider';
import FeatureAccessBannerClientFocus from '~/wm/packages/feature/packages/feature-access-banner/packages/feature-access-banner-client-focus/FeatureAccessBannerClientFocus';
import TicketFieldAvailabilitiesProvider from '~/wm/packages/integration/packages/ticket/context/TicketFieldAvailabilitiesProvider';
import EngagementListPageTicketCreateProvider from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/context/EngagementListPageTicketCreateProvider';
import EngagementActionListTicketCreateWindowWrapper from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-action-list-ticket-create-window-wrapper/EngagementActionListTicketCreateWindowWrapper';
import EngagementActionListProvider from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/EngagementActionListProvider';
import EngagementActionDataCollectionProvider from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/EngagementActionDataCollectionProvider';
import engagementDataCollectionSources from '~/wm/packages/organization/packages/engagement/model/engagementDataCollectionSources';
import EngagementActionListInitiativeLinkModalWrapper from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-action-list-initiative-link-modal-wrapper/EngagementActionListInitiativeLinkModalWrapper';
import EngagementListPageInitiativeLinkProvider from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/context/EngagementListPageInitiativeLinkProvider';

export type EngagementListType = 'notes' | 'action-items';

export type EngagementListPageProps = {
  /* Organization */
  organizationId: string;
  organizationName: string;

  /* Engagement Type */
  engagementType: EngagementListType;

  /* Urls */
  engagementListUrl: string;
  upgradeUrl: string;
  growUrl: string;
};

const EngagementListPage = ({
  organizationId,
  organizationName,
  engagementType,
  engagementListUrl,
  upgradeUrl,
  growUrl,
}: EngagementListPageProps) => (
  <EngagementActionDataCollectionProvider
    createdFrom={engagementDataCollectionSources.engagementActionCreationSource.actionList}
    completedFrom={engagementDataCollectionSources.engagementActionCompletionSource.actionList}
    updatedFrom={engagementDataCollectionSources.engagementActionUpdateSource.actionList}
  >
    <ActiveUsersProvider>
      <OrganizationProvider
        organizationId={organizationId}
        organizationName={organizationName}
      >
        <FeatureAccessBannerClientFocus
          upgradeUrl={upgradeUrl}
          growUrl={growUrl}
        />
        <Box
          borderRadius={'radius500'}
          padding={'padding200'}
          css={css`
            margin: 0.313rem;
            background-color: ${colorToCode('light-200')};
          `}
        >
          <PanelTabGroup
            initialTabId={engagementType}
            initialFilterValueByTab={
              new Map([
                ['action-items', stringToEngagementActionStatusFilter('Upcoming')],
                ['notes', 'Live'],
              ])
            }
            initialPageByTab={
              new Map([
                ['action-items', 1],
                ['notes', 1],
              ])
            }
            panelTabs={[
              {
                id: 'action-items',
                title: 'Action items',
                titleIcon: 'ActionItem',
                onSelect: () => {
                  history.pushState(
                    null,
                    '',
                    engagementListUrl.replace('__ORGANIZATION_ID__', organizationId).replace('__ENGAGEMENT_TYPE__', 'action-items'),
                  );
                },
                children: (
                  <TicketFieldAvailabilitiesProvider organizationId={organizationId}>
                    <EngagementListPageTicketCreateProvider>
                      <EngagementListPageInitiativeLinkProvider>
                        <EngagementActionListProvider engagementActionGetSource={{ type: 'List' }}>
                          <EngagementActionListPanelTab initiativeCreatedFrom={'engagement-action-list'} />
                        </EngagementActionListProvider>
                        <EngagementActionListTicketCreateWindowWrapper />
                        <EngagementActionListInitiativeLinkModalWrapper initiativeCreatedFrom={'engagement-action-list'} />
                      </EngagementListPageInitiativeLinkProvider>
                    </EngagementListPageTicketCreateProvider>
                  </TicketFieldAvailabilitiesProvider>
                ),
              },
              {
                id: 'notes',
                title: 'Notes',
                titleIcon: 'Note',
                onSelect: () => {
                  history.pushState(
                    null,
                    '',
                    engagementListUrl.replace('__ORGANIZATION_ID__', organizationId).replace('__ENGAGEMENT_TYPE__', 'notes'),
                  );
                },
                children: (
                  <EngagementNoteListPanelTabProvider>
                    <EngagementNoteListPanelTab />
                  </EngagementNoteListPanelTabProvider>
                ),
              },
            ]}
          />
        </Box>
      </OrganizationProvider>
    </ActiveUsersProvider>
  </EngagementActionDataCollectionProvider>
);

export default EngagementListPage;
