import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { neoColorToCode } from '~/neo-ui/packages/color/NeoColor';
import Header, { HeaderSize, HeaderWeight } from '~/neo-ui/packages/text/packages/header/Header';
import Label, { LabelSize } from '~/neo-ui/packages/text/packages/label/Label';
import Color from '~/neo-ui/packages/color/Color.gen';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Icon from '~/neo-ui/packages/icon/Icon';

export type LayoutSectionProps = {
  title: string;
  titleWeight?: HeaderWeight;

  // H1 is reserved for titles and not to be used in sections
  titleSize: Exclude<HeaderSize, 1>;
  titleColor?: Color;

  titleIcon?: IconType;
  titleIconColor?: Color;

  actionButton?: React.ReactElement;

  description?: string | React.ReactNode;
  descriptionColor?: Color;
  descriptionSize?: LabelSize;

  children: React.ReactNode;

  onClick?: () => void;
} & Styleable;

// The margin between header & content is determined by the header size
export const sectionMarginRem = (titleSize: number) => {
  const defaultMarginSize = 1.25;
  switch (titleSize) {
    case 2:
      return defaultMarginSize;
    case 3:
      return 0.9375;
    case 4:
      return 0.625;
    case 5:
      return 0.625;
    case 6:
      return 0.625;
    case 7:
      return 0.625;
    default:
      return defaultMarginSize;
  }
};

const LayoutSection: React.FunctionComponent<LayoutSectionProps> = ({
  title,
  titleWeight,
  titleSize,
  titleColor,
  titleIcon,
  titleIconColor,
  actionButton,
  description,
  descriptionColor,
  descriptionSize,
  className,
  children,
  onClick,
}) => (
  <div
    className={className}
    css={css`
      ${onClick &&
      `transition: all 200ms ease;
          &:hover {
            cursor: pointer;
            box-shadow: 0 0.0625rem 0.625rem -1.3125rem ${neoColorToCode('dark1', 0.3)},
              0 0.4375rem 1.5625rem -0.625rem ${neoColorToCode('dark1', 0.15)} !important;
          }`}
    `}
    {...(onClick && { onClick })}
  >
    <div
      css={css`
        margin-bottom: ${sectionMarginRem(titleSize)}rem;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 0.5rem;
            align-items: center;
          `}
        >
          {titleIcon && (
            <Icon
              icon={titleIcon}
              {...(titleIconColor && { color: titleColor })}
            />
          )}
          <Header
            size={titleSize}
            color={titleColor}
            weight={titleWeight}
          >
            {title}
          </Header>
        </div>
        {typeof actionButton !== 'undefined' && actionButton}
      </div>
      {description && typeof description === 'string' ? (
        <Label
          muted={!descriptionColor}
          size={descriptionSize}
          color={descriptionColor}
          css={css`
            margin-top: 0.3125rem;
          `}
        >
          {description}
        </Label>
      ) : (
        <div
          css={css`
            margin-top: 0.3125rem;
          `}
        >
          {description}
        </div>
      )}
    </div>
    <div
      css={css`
        > *:not(:last-child) {
          margin-bottom: 1.25rem;
        }
      `}
    >
      {children}
    </div>
  </div>
);

export default LayoutSection;
