import { forwardRef } from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import ButtonInternal, { ButtonInternalColorMap, ButtonInternalProps, ButtonInternalRef } from '~/neo-ui/packages/button/ButtonInternal';

export type CardButtonProps = Omit<ButtonInternalProps, 'colorMap'> & Styleable & { isPreselected: boolean };

const CardButton = forwardRef<ButtonInternalRef, CardButtonProps>(
  (
    {
      id,
      isPreselected = false,
      loading = false,
      size = 'lg',
      anchor,
      iconLeft,
      iconRight,
      iconColorOverride,
      iconSizeOverride,
      disabled: isSetToDisabled = false,
      preventOnClickPropagation,
      onClick,
      invertColorMap = false,
      className,
      children,
    }: CardButtonProps,
    ref,
  ) => {
    const colorMap: ButtonInternalColorMap = isPreselected
      ? {
          backgroundColorRest: 'primary-400',
          backgroundColorHover: 'primary-400',
          backgroundColorActive: 'primary-400',
          backgroundColorFocus: 'primary-400',

          textColorRest: 'light-000',
          textColorHover: 'light-000',
          textColorActive: 'light-000',
          textColorFocus: 'light-000',

          borderColorRest: 'primary-500',
          borderColorHover: 'primary-500',
          borderColorActive: 'primary-500',
          borderColorFocus: 'primary-500',
        }
      : {
          backgroundColorRest: 'light-000',
          backgroundColorHover: 'light-000',
          backgroundColorActive: 'primary-200',
          backgroundColorFocus: 'light-000',

          textColorRest: 'primary-500',
          textColorHover: 'primary-500',
          textColorActive: 'primary-500',
          textColorFocus: 'primary-500',

          borderColorRest: 'primary-500',
          borderColorHover: 'primary-500',
          borderColorActive: 'primary-500',
          borderColorFocus: 'primary-500',
        };

    // Regardless of what's set, the button is disabled when loading
    const disabled = isSetToDisabled || loading;

    return (
      <ButtonInternal
        className={className}
        id={id}
        size={size}
        anchor={anchor}
        iconLeft={iconLeft}
        iconRight={iconRight}
        iconColorOverride={iconColorOverride}
        iconSizeOverride={iconSizeOverride}
        preventOnClickPropagation={preventOnClickPropagation}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        colorMap={colorMap}
        invertColorMap={invertColorMap}
        ref={ref}
      >
        {children}
      </ButtonInternal>
    );
  },
);

export default CardButton;
