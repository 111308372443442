import * as React from 'react';
import SingleSelect from '~/neo-ui/packages/select/packages/single-select/SingleSelect';
import { formatDate, formatRelativeOrDateTimeFriendly, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';
import useScorecardContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardContext';
import useScorecardUrlContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardUrlContext';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';

export type ScorecardListSelectProps = { disabled?: boolean };

const ScorecardListSelect = ({ disabled = false }: ScorecardListSelectProps) => {
  const { scorecardList, scorecard } = useScorecardContext();
  const { scorecardIndividualViewUrl } = useScorecardUrlContext();

  const scorecardOptions: SelectOption[] = scorecardList.map(scorecardOption => ({
    label: `${formatDate(new Date(scorecardOption.schedule.year, scorecardOption.schedule.month - 1), {
      format: 'MMMM yyyy',
      timezone: TimezoneFormat.Local,
    })}`,
    description: scorecardOption.updatedAt ? `Edited ${formatRelativeOrDateTimeFriendly(parseISO(scorecardOption.updatedAt))}` : undefined,
    value: scorecardOption.scorecardId,
    labelHighlightedColor: 'primary-400',
    descriptionHighlightedColor: 'primary-300',
    anchor: {
      href: scorecardIndividualViewUrl
        .replace('__ORGANIZATION_ID__', scorecard.organizationId)
        .replace('__SCORECARD_ID__', scorecardOption.scorecardId),
    },
  }));

  const currentOption = scorecardOptions.find(scorecardOption => scorecardOption.value === scorecard.scorecardId);

  const [currentScorecard, setCurrentScorecard] = React.useState<SelectOption | undefined>(currentOption);

  return (
    <SingleSelect
      options={scorecardOptions}
      onOptionSelected={option => {
        setCurrentScorecard(option);
      }}
      selectedOption={{
        label: 'Open...',
        value: currentScorecard?.value ?? '',
        prependIcon: 'ScorecardHistory',
      }}
      placeholder={
        scorecard.schedule
          ? formatDate(new Date(scorecard.schedule.year, scorecard.schedule.month - 1), {
              format: 'MMMM yyyy',
              timezone: TimezoneFormat.Local,
            })
          : ''
      }
      isSearchable={false}
      disabled={disabled}
      style={'default'}
    />
  );
};

export default ScorecardListSelect;
