import { WarrantyCoverageSettingsAvailabilities } from '@WarrantyClient/BeastClient/Beast/WarrantyCoverageSettings/Model/Availabilities.gen';
import { AvailabilityRange } from '@WarrantyClient/BeastClient/Beast/WarrantyCoverageSettings/Model/Availabilities/WarrantyCoverageSettingsAvailabilitiesNested.gen';
import { object, string, number, lazy } from 'yup';
import { mapRules } from '~/extensions/packages/validation/mapRules';

const warrantyCoverageOpportunityPreferencesValidationSchema = (markupAvailability: AvailabilityRange) =>
  object({
    markupPercentage: string()
      .matches(/^(\d+)?([.]?\d?)?$/, 'Please enter a valid number')
      .min(markupAvailability.min, 'Please enter a valid markup percentage')
      .test(
        'max value',
        'Please enter a valid markup percentage',
        value => value !== null && typeof value !== 'undefined' && parseInt(value, 10) < markupAvailability.max,
      )
      .required('Please enter a markup percentage'),
    coverageLength: number().typeError('Coverage length must be a number').required('Coverage length must be specified'),
    serviceLevelAgreement: string().required('Please select a service level agreement'),
  });

const warrantyCoverageSettingsValidationSchema = (availabilities: WarrantyCoverageSettingsAvailabilities) =>
  object().shape({
    value: object().shape({
      warrantyCoverageOpportunityPreferences: lazy(() =>
        object(
          mapRules(
            availabilities?.deviceTypeAvailabilities ?? {},
            warrantyCoverageOpportunityPreferencesValidationSchema(availabilities.markupAvailability),
          ),
        ),
      ),
    }),
  });

export default warrantyCoverageSettingsValidationSchema;
