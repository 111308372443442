import React, { useCallback } from 'react';
import ApiKeySaveModal from '~/wm/packages/sp-account/packages/api-page/packages/api-key-save-modal/ApiKeySaveModal';
import useApiKeys from '~/wm/packages/sp-account/packages/api-page/hooks/useApiKeys';
import Form from '~/neo-ui/packages/form/Form';
import Button from '~/neo-ui/packages/button/Button';
import useApi from '~/wm/packages/api/hook/useApi';
import { apiKeyPublicGenerate } from '~/SsoClient/SsoClientMsp.gen';
import { Response as ApiKeyPublicGenerateResponse } from '@SsoClient/Sso/ApiKeyPublic/Controller/ApiKeyPublicGenerateControllerNested.gen';
import { Request as ApiKeyPublicSaveRequest } from '@SsoClient/Sso/ApiKeyPublic/Controller/ApiKeyPublicUpdateControllerNested.gen';
import ApiKeyTable from '~/wm/packages/sp-account/packages/api-page/packages/api-key-table/ApiKeyTable';
import { css } from '@emotion/react';
import { paddingToCode } from '~/neo-ui/packages/style/Padding';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Spinner from '~/neo-ui/spinner/Spinner';

const ApiPage: React.FunctionComponent = () => {
  const { callApi } = useApi();
  const defaultApiKeyName = 'New API Key';
  const [generatedApiKey, setGeneratedApiKey] = React.useState<ApiKeyPublicGenerateResponse>({
    apiKey: '',
    apiKeyId: '',
  });
  const { apiKeyList, saveApiKey } = useApiKeys();
  const [isModalOpen, setModalOpen] = React.useState(false);

  const closeModal = React.useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const onSubmit = React.useCallback(
    async (apiKeySaveRequest: ApiKeyPublicSaveRequest) => saveApiKey(apiKeySaveRequest.apiKeyId, apiKeySaveRequest.name),
    [saveApiKey],
  );

  const generateApiKey = useCallback(async () => {
    const response = await callApi(() => apiKeyPublicGenerate({ name: defaultApiKeyName }));

    if (!response) {
      return;
    }
    setGeneratedApiKey(response);
    setModalOpen(true);
  }, [callApi, setGeneratedApiKey, setModalOpen]);

  return (
    <div>
      <Button
        onClick={generateApiKey}
        iconLeft={'Add'}
        theme={'primary'}
      >
        <Label size={'md'}>New secret key...</Label>
      </Button>
      {isModalOpen && (
        <Form
          submitMethod={'manual'}
          hideSubmissionButton={true}
          hideSubmitStatusIndicator={true}
          defaultFormData={{
            name: '',
            apiKeyId: generatedApiKey.apiKeyId,
          }}
          validationSchema={undefined}
          onSubmit={onSubmit}
        >
          <ApiKeySaveModal
            apiKey={generatedApiKey}
            isOpen={isModalOpen}
            onClose={closeModal}
          />
        </Form>
      )}
      <div
        css={css`
          padding-block: ${paddingToCode('padding400')};
        `}
      >
        {apiKeyList ? <ApiKeyTable apiKeyList={apiKeyList} /> : <Spinner />}
      </div>
    </div>
  );
};

export default ApiPage;
