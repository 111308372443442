import { useState, useCallback, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { disposalOrderAssetOrganizationUnassignedList } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { DisposalOrderAssetDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderAsset/Dto/Model.gen';

export type UseDisposalOrderAssetUnassignedOrganizationList = (disposalOrderId: string) => {
  disposalOrderAssets: DisposalOrderAssetDto[];
  getDisposalOrderAssetUnassignedOrganizationList: () => void;
};

const useDisposalOrderAssetUnassignedOrganizationList: UseDisposalOrderAssetUnassignedOrganizationList = disposalOrderId => {
  const { callApi } = useApi();

  const [disposalOrderAssets, setDisposalOrderAssets] = useState<DisposalOrderAssetDto[]>([]);

  const reload = useCallback(async () => {
    const response = await callApi(() => disposalOrderAssetOrganizationUnassignedList({ disposalOrderId }));

    if (!response) {
      return;
    }

    setDisposalOrderAssets(response.disposalOrderAssets);
  }, [callApi, disposalOrderId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    disposalOrderAssets,
    getDisposalOrderAssetUnassignedOrganizationList: reload,
  };
};

export default useDisposalOrderAssetUnassignedOrganizationList;
