import React, { Fragment } from 'react';
import TreeImpactLearnMoreButton from '~/wm/packages/tree-impact/packages/tree-impact-learn-more-button/TreeImpactLearnMoreButton';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import routes from '~/router/types/routes';

const WarrantyCartTreeImpact: React.FunctionComponent = () => {
  const { getSelectedAssetsCount } = useWarrantyCartContext();

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 0.3rem;
        // Fixed height to prevent jumping when the tree count changes
        min-height: 1.875rem;
      `}
    >
      {getSelectedAssetsCount() === 0 ? (
        <Fragment />
      ) : (
        <Fragment>
          <Icon
            icon={'TreeDeciduous'}
            color={'positive-400'}
            sizePx={16}
          />
          <Header
            size={5}
            weight="light"
            color={'positive-400'}
          >
            You'll plant {getSelectedAssetsCount()} tree{getSelectedAssetsCount() === 1 ? '' : 's'} with this order
          </Header>
          <TreeImpactLearnMoreButton helpGuideUrl={routes.PUBLIC_PAGE_ENVIRONMENTAL.route} />
        </Fragment>
      )}
    </div>
  );
};

export default WarrantyCartTreeImpact;
