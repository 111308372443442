import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type InfoItem = {
  title: string;
  label: string;
  description: string;
};

export type InfoItemProps = Styleable &
  InfoItem & { titleColor: Color } & {
    descriptionColor: Color;
  };

/**
 * Display information about an entity.
 *
 * @deprecated by LayoutSection
 */
const InfoItem: React.FunctionComponent<InfoItemProps> = ({ title, label, description, titleColor, descriptionColor, className }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
    `}
    className={className}
  >
    <p
      data-tracking="info-title"
      css={css`
        margin-bottom: 0.3125rem;
        font-size: 1.125rem;
        color: ${colorToCode(titleColor)};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      `}
    >
      {title}
    </p>
    <p
      data-tracking="info-label"
      css={css`
        margin-bottom: 0.3125rem;
        font-size: 0.875rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      `}
    >
      {label}
    </p>
    <p
      data-tracking="info-description"
      css={css`
        margin: 0;
        font-size: 0.75rem;
        color: ${colorToCode(descriptionColor)};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      `}
    >
      {description}
    </p>
  </div>
);

export default InfoItem;
