import { HardwareLifecycleReportSettingsAvailabilities } from '@AssetManagementClient/BeastClient/Beast/HardwareSettings/Model/LifecycleReport/Availabilities.gen';
import { SettingsValueNested } from '@AssetManagementClient/BeastClient/Beast/Settings/Model.gen';
import { Enum as DeviceTypeEnum } from '@AssetManagementClient/BeastClient/Goods/DeviceType/DeviceTypeFactoryNested.gen';
import { css } from '@emotion/react';
import React from 'react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import FormCheckboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckboxInput';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import useAccount from '~/wm/hooks/useAccount';

export type HardwareLifecycleReportSettingsFormProps = {
  availabilities: HardwareLifecycleReportSettingsAvailabilities | undefined;
  disabled?: boolean;
  lmLastCheckinDateEnabled: boolean;
};

const HardwareLifecycleReportSettingsForm: React.FunctionComponent<HardwareLifecycleReportSettingsFormProps> = ({
  availabilities,
  disabled = false,
  lmLastCheckinDateEnabled,
}) => {
  const { subscriptionIdentity } = useAccount();
  const { formData, setFormInput } = useFormContext<SettingsValueNested.HardwareLifecycleReportSettings>();
  const {
    value: { excludeDeviceTypes: excludeDeviceTypesSet },
  } = formData;
  // This little dance has to be done because of WM-1555 (HashSet<> in C# is generated as a Set<> in TS even though it's a [] in JSON)
  const excludeDeviceTypes = excludeDeviceTypesSet as unknown as DeviceTypeEnum[];
  return (
    <div>
      <div
        css={css`
          margin-left: 0.437rem;
          display: flex;
          flex-direction: column;
          gap: 1.25rem;
        `}
      >
        {(availabilities?.availableDeviceTypesToExclude ?? []).sort().map(({ enum: value, lowercaseLabelPlural }) => {
          const fieldKey = (settingsValue: FieldKeyExpressionSegment<SettingsValueNested.HardwareLifecycleReportSettings>) =>
            settingsValue.value.excludeDeviceTypes;

          const isChecked = !excludeDeviceTypes.includes(value);
          return (
            <div key={value}>
              <FormCheckboxInput
                fieldKey={fieldKey}
                disabled={disabled}
                description={`Show ${lowercaseLabelPlural}`}
                checked={isChecked}
                onChange={_ =>
                  setFormInput<DeviceTypeEnum[]>(fieldKey, [
                    // When we're unchecking, add the device type to the set of excluded
                    ...(!isChecked ? [] : [value]),
                    ...excludeDeviceTypes.filter(deviceType => deviceType !== value),
                  ])
                }
              />
            </div>
          );
        })}

        <FormCheckboxInput
          fieldKey={(settingsValue: FieldKeyExpressionSegment<SettingsValueNested.HardwareLifecycleReportSettings>) =>
            settingsValue.value.showHardwareWithUnknownReplacementStatus
          }
          disabled={disabled}
          description="Show hardware with unknown warranties"
        />
        <FormCheckboxInput
          fieldKey={(settingsValue: FieldKeyExpressionSegment<SettingsValueNested.HardwareLifecycleReportSettings>) =>
            settingsValue.value.showBudgeting
          }
          disabled={disabled}
          description="Show budgeting"
        />
        <FormCheckboxInput
          fieldKey={(settingsValue: FieldKeyExpressionSegment<SettingsValueNested.HardwareLifecycleReportSettings>) =>
            settingsValue.value.showCpuColumn
          }
          disabled={disabled}
          description="Show CPU"
        />
        <FormCheckboxInput
          fieldKey={(settingsValue: FieldKeyExpressionSegment<SettingsValueNested.HardwareLifecycleReportSettings>) =>
            settingsValue.value.showRamColumn
          }
          disabled={disabled}
          description="Show RAM"
        />
        <FormCheckboxInput
          fieldKey={(settingsValue: FieldKeyExpressionSegment<SettingsValueNested.HardwareLifecycleReportSettings>) =>
            settingsValue.value.showStorageColumn
          }
          disabled={disabled}
          description="Show Storage"
        />
        {!subscriptionIdentity.isLmFree && lmLastCheckinDateEnabled && (
          <FormCheckboxInput
            fieldKey={(settingsValue: FieldKeyExpressionSegment<SettingsValueNested.HardwareLifecycleReportSettings>) =>
              settingsValue.value.showLastCheckInDateColumn
            }
            disabled={disabled}
            description="Show Last Check-In Date"
          />
        )}
      </div>
    </div>
  );
};

export default HardwareLifecycleReportSettingsForm;
