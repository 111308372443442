import useApi from '~/wm/packages/api/hook/useApi';
import { useCallback, useState } from 'react';
import { initiativeStatusUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseInitiativeStatusUpdate = {
  updateStatus: (initiativeId: string, organizationId: string, status: string, onSuccessEffect?: () => void) => void;
  isUpdating: boolean;
};

const useInitiativeStatusUpdate = (): UseInitiativeStatusUpdate => {
  const { callApi } = useApi();
  const [isLoading, setLoading] = useState(false);

  const updateStatus = useCallback(
    async (initiativeId: string, organizationId: string, status: string, onSuccessEffect?: () => void) => {
      setLoading(true);

      const response = await callApi(() =>
        initiativeStatusUpdate({
          organizationId,
          initiativeId,
          status,
        }),
      );

      if (!response) {
        return;
      }

      setLoading(false);
      if (typeof onSuccessEffect !== 'undefined') {
        onSuccessEffect();
      }
    },
    [callApi],
  );

  return {
    updateStatus,
    isUpdating: isLoading,
  };
};

export default useInitiativeStatusUpdate;
