import * as React from 'react';
import ContractEditWindowFormWrapper from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-list-table/packages/contract-edit-vendor-table-cell/packages/contract-edit-window-form-wrapper/ContractEditWindowFormWrapper';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import { css } from '@emotion/react';
import { Styleable } from '~/neo-ui/model/capacity';

export type ContractEditVendorTableCellProps = {
  contractId: string;
  organizationId: string;
  contractVendor: string;
  onSave?: () => void;
} & Styleable;

const ContractEditVendorTableCell: React.FunctionComponent<ContractEditVendorTableCellProps> = ({
  contractId,
  organizationId,
  contractVendor,
  onSave,
  className,
}) => {
  const [isWindowOpen, setWindowOpen] = React.useState(false);

  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  return (
    <a
      className={className}
      css={css`
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      `}
      href={'#'}
      onClick={e => {
        e.preventDefault();
        setWindowOpen(true);
        trackEventProcessor('contract_update_open', {});
      }}
    >
      {contractVendor}
      {isWindowOpen && (
        <ContractEditWindowFormWrapper
          contractId={contractId}
          organizationId={organizationId}
          onDismiss={() => setWindowOpen(false)}
          isOpen={isWindowOpen}
          onSave={onSave}
        />
      )}
    </a>
  );
};

export default ContractEditVendorTableCell;
