import * as React from 'react';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import RegionalSettingsInfoContext, {
  CurrencyInfo,
} from '~/wm/packages/settings/packages/regional-settings/context/RegionalSettingsInfoContext';
import { useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { regionalSettingsInfoGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { getCurrencyCharacterByCurrencyCode } from '~/extensions/packages/currency/formatCurrency';
import FiscalQuarter, { Quarter } from '~/extensions/packages/date/packages/fiscal-quarter/FiscalQuarter';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';

export type RegionalSettingsCurrencyProviderProps = {
  frontendScope: FrontendScope;
};

const RegionalSettingsInfoProvider: React.FunctionComponent<React.PropsWithChildren<RegionalSettingsCurrencyProviderProps>> = ({
  frontendScope,
  children,
}) => {
  const [currencyInfo, setCurrencyInfo] = useState<CurrencyInfo>();
  const [fiscalQuarterStartingMonth, setFiscalQuarterStartingMonth] = React.useState<number>();
  const [currentFiscalQuarter, setCurrentFiscalQuarter] = React.useState<FiscalQuarter>();
  const [startingFiscalYear, setStartingFiscalYear] = React.useState<string>();

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => regionalSettingsInfoGet({ scope: frontendScope }));

      if (!response) {
        return;
      }

      setCurrencyInfo({
        countryCode: response.regionalSettingsInfo.currencyInfo.codeAlpha,
        subunitRatio: response.regionalSettingsInfo.currencyInfo.subunitRatio,
        exchangeRateFromUsd: response.regionalSettingsInfo.currencyInfo.exchangeRateFromUsd,
        currencySymbol: getCurrencyCharacterByCurrencyCode(response.regionalSettingsInfo.currencyInfo.codeAlpha),
      });

      setFiscalQuarterStartingMonth(response.regionalSettingsInfo.fiscalYearStartingMonth);

      setCurrentFiscalQuarter({
        year: response.regionalSettingsInfo.currentFiscalQuarter.year,
        quarter: response.regionalSettingsInfo.currentFiscalQuarter.quarter as Quarter,
      });

      const fiscalYearStartingMonthIndex = response.regionalSettingsInfo.fiscalYearStartingMonth - 1;

      const startingDate = new Date(response.regionalSettingsInfo.currentFiscalQuarter.year, fiscalYearStartingMonthIndex);

      setStartingFiscalYear(
        formatDate(startingDate, {
          format: 'MMM yyyy',
          timezone: TimezoneFormat.Local,
        }),
      );
    })();
  }, [callApi, frontendScope]);

  if (!currencyInfo || !currentFiscalQuarter || !fiscalQuarterStartingMonth || !startingFiscalYear) {
    return null;
  }

  return (
    <RegionalSettingsInfoContext.Provider value={{ currencyInfo, currentFiscalQuarter, fiscalQuarterStartingMonth, startingFiscalYear }}>
      {children}
    </RegionalSettingsInfoContext.Provider>
  );
};

export default RegionalSettingsInfoProvider;
