import React from 'react';
import { Label } from 'recharts';
import { Styleable } from '~/neo-ui/model/capacity';
import { PieGraphDataPoint } from '~/neo-ui/packages/chart/packages/pie/PieGraph';
import Tachometer from '~/neo-ui/packages/chart/packages/pie/packages/tachometer/Tachometer';
import { css } from '@emotion/react';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type LineThickness = 'normal' | 'thick';

export type DmiScoreProps = {
  themeColor: Color;
  displayDmiScore: string;
  dmiScore: number;
  maxDmiScore: number;
  sizePx: number;
  lineThickness?: LineThickness;
} & Styleable;

const DmiScoreBase: React.FunctionComponent<DmiScoreProps> = ({
  className,
  displayDmiScore,
  dmiScore,
  maxDmiScore,
  themeColor,
  sizePx,
  lineThickness = 'normal',
}) => {
  const dataPoints: PieGraphDataPoint[] = [];
  dataPoints.push({
    value: dmiScore,
    fill: colorToCode(themeColor),
  });
  dataPoints.push({
    value: maxDmiScore - dmiScore,
    fill: colorToCode('secondary-800'),
  });

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <Tachometer
        dataPoints={dataPoints}
        width={sizePx}
        height={sizePx}
        {...(lineThickness === 'thick' && { innerRadius: 70 })}
        shouldAnimate={false}
      >
        <Label
          value={displayDmiScore}
          position="center"
          fontSize={`${sizePx * 0.01699}rem`}
          fill={colorToCode(themeColor)}
          fontWeight={900}
        />
      </Tachometer>
    </div>
  );
};

export default DmiScoreBase;
