import { css } from '@emotion/react';
import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import Icon from '~/neo-ui/packages/icon/Icon';
import { PaginationMetadata } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { PaginationState } from '~/neo-ui/packages/table/packages/filter-table/FilterTable';
import ButtonGroup from '~/neo-ui/packages/button/packages/button-group/ButtonGroup';
import { Styleable } from '~/neo-ui/model/capacity';

export type PaginationControlProps = {
  defaultPagination: PaginationState;
  paginationMetadata: PaginationMetadata | 'unknown';
  onPageChange: (pagination: PaginationState) => void;
} & Styleable;

const PaginationControl: React.FunctionComponent<PaginationControlProps> = ({
  defaultPagination,
  paginationMetadata,
  onPageChange,
  className,
}) => {
  const [pageNumber, setPageNumber] = React.useState(defaultPagination.pageNumber);

  React.useEffect(() => {
    setPageNumber(defaultPagination.pageNumber);
  }, [defaultPagination.pageNumber]);

  const perPageSize = defaultPagination.perPageSize;

  React.useEffect(() => {
    onPageChange({ pageNumber, perPageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, perPageSize]);

  const startsAtRowNumber = pageNumber * perPageSize;
  const endsAtRowNumber =
    paginationMetadata === 'unknown'
      ? // Giving the best guess is just for UX here
        startsAtRowNumber + perPageSize
      : // Theoretically this is "start index + # results in current page"
        // But we might not get the # results until after the page is loaded
        Math.min(startsAtRowNumber + perPageSize, paginationMetadata.totalResults);

  const rowRange =
    paginationMetadata !== 'unknown' && paginationMetadata.totalResults === 0
      ? '0 - 0'
      : `${(startsAtRowNumber + 1).toLocaleString()} - ${endsAtRowNumber.toLocaleString()}`;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      `}
      className={className}
    >
      <div
        css={css`
          margin-right: 10px;
        `}
      >
        <b>{rowRange}</b> of <b>{paginationMetadata === 'unknown' ? '-' : paginationMetadata.totalResults.toLocaleString()}</b>
      </div>
      <ButtonGroup>
        <Button
          onClick={() => setPageNumber(pageNumber => pageNumber - 1)}
          disabled={pageNumber === 0}
        >
          <Icon icon="ArrowLeft" />
        </Button>
        <Button
          onClick={() => setPageNumber(pageNumber => pageNumber + 1)}
          disabled={paginationMetadata !== 'unknown' && endsAtRowNumber >= paginationMetadata.totalResults}
        >
          <Icon icon="ArrowRight" />
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default PaginationControl;
