import { CostTypeEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Model/BudgetLineItemNested.gen';
import { formatCurrencyByCurrencyInfo } from '~/extensions/packages/currency/formatCurrency';
import { CurrencyInfo } from '~/wm/packages/settings/packages/regional-settings/context/RegionalSettingsInfoContext';
import { BudgetLineItemFormData } from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';

export type UseTotalCostOneTimeFeesCalculatedProps = {
  currencyInfo: CurrencyInfo;
  budgetLineItems: BudgetLineItemFormData[];
  amountOfAssets: number;
};

const useTotalCostOneTimeFeesCalculated = function ({
  currencyInfo,
  budgetLineItems,
  amountOfAssets,
}: UseTotalCostOneTimeFeesCalculatedProps) {
  const totalCost = budgetLineItems.reduce(
    (sum, lineItem) =>
      sum +
      (isNaN(lineItem.costUnits)
        ? 0
        : Number(lineItem.costUnits) *
          // Multiply by the number of assets if the cost type is per asset
          (lineItem.costType === CostTypeEnum.PerAsset ? amountOfAssets : 1)),
    0,
  );

  const formattedTotalCostOneTimeFees = formatCurrencyByCurrencyInfo(totalCost * currencyInfo.subunitRatio, {
    code: currencyInfo.countryCode,
    subunitsPerUnit: currencyInfo.subunitRatio,
  });

  return { formattedTotalCostOneTimeFees };
};

export default useTotalCostOneTimeFeesCalculated;
