import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import { DisposalOrderPickupImageUploadPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderPickup/Packages/DisposalOrderPickupImage/Dto/Model.gen';
import useDisposalOrderPickupImageUpload from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderPickupImageUpload';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Image from '~/neo-ui/packages/image/Image';
import Box from '~/neo-ui/packages/box/Box';
import * as React from 'react';
import { ImageType } from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-bulk-pickup-section/packages/disposal-order-bulk-pickup-section-image-update/DisposalOrderBulkPickupSectionImageUpload';

export type DisposalOrderBulkPickupSectionImageUploadPreviewProps = {
  disposalOrderId: string;
  imageToUpload: ImageType;
  onImageDelete: () => void;
  onImageUploadSuccess: () => void;
  canUploadImage: boolean;
};

const DisposalOrderBulkPickupSectionImageUploadPreview = ({
  disposalOrderId,
  imageToUpload,
  onImageDelete,
  onImageUploadSuccess,
  canUploadImage,
}: DisposalOrderBulkPickupSectionImageUploadPreviewProps) => {
  const { uploadPickupImage, isUploading, isUploadingFailed } = useDisposalOrderPickupImageUpload(onImageUploadSuccess);
  const [imageUploadPayload, setImageUploadPayload] = React.useState<DisposalOrderPickupImageUploadPayloadDto>({
    disposalOrderId,
    contentBase64: imageToUpload.contentBase64,
    fileName: imageToUpload.fileName,
    height: undefined,
    width: undefined,
  });

  return (
    <Box
      borderRadius={'radius200'}
      padding={'padding100'}
      css={css`
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        max-height: 4rem;
        background-color: ${colorToCode('light-100')};
        align-items: center;
        width: fit-content;
      `}
    >
      <Image
        css={css`
          max-height: 4rem;
        `}
        source={imageToUpload.preview}
        // Revoke data uri after image is loaded
        onLoad={event => {
          URL.revokeObjectURL(imageToUpload.preview);
          setImageUploadPayload(prevState => ({
            disposalOrderId,
            blob: [],
            contentBase64: prevState.contentBase64,
            fileName: prevState.fileName,
            height: event.currentTarget.height,
            width: event.currentTarget.width,
          }));
        }}
      />
      <div>
        <Label
          size={'sm'}
          bold={true}
        >
          {imageToUpload.fileName}
        </Label>
        {typeof isUploadingFailed === 'undefined' && (
          <Label
            size={'sm'}
            muted={true}
          >
            {(imageToUpload.blob.size / (1024 * 1024)).toFixed(2)} MB
          </Label>
        )}
        {typeof isUploadingFailed !== 'undefined' && isUploadingFailed && (
          <Label
            size={'sm'}
            color={'negative-400'}
          >
            Upload failed
          </Label>
        )}
        {typeof isUploadingFailed !== 'undefined' && !isUploadingFailed && (
          <Label
            size={'sm'}
            color={'positive-400'}
          >
            Upload successfully
          </Label>
        )}
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
          align-items: center;
        `}
      >
        {canUploadImage && typeof isUploadingFailed === 'undefined' && (
          <ButtonLink
            size={'sm'}
            onClick={() => uploadPickupImage(imageUploadPayload)}
            disabled={isUploading || typeof isUploadingFailed !== 'undefined'}
            theme={'primary'}
          >
            Upload Image
          </ButtonLink>
        )}
        {typeof isUploadingFailed !== 'undefined' && isUploadingFailed && canUploadImage && (
          <ButtonLink
            size={'sm'}
            onClick={() => uploadPickupImage(imageUploadPayload)}
            iconLeft={'Reload'}
          />
        )}
        {(typeof isUploadingFailed === 'undefined' || isUploadingFailed) && (
          <ButtonLink
            size={'xs'}
            onClick={onImageDelete}
            iconLeft={'Cancel'}
            theme={'danger'}
          />
        )}
      </div>
    </Box>
  );
};

export default DisposalOrderBulkPickupSectionImageUploadPreview;
