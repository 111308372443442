import { ChangeEventHandler, ComponentPropsWithoutRef, FocusEventHandler, InputHTMLAttributes, useId, useMemo } from 'react';
import ErrorIcon from '../../icons/ErrorIcon';
import theme from './../../theme';

export interface TextFieldProps extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange' | 'onBlur' | 'onFocus'> {
  name?: string;
  label?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  value?: string;
  infoSubtext?: string;
  errorSubtext?: string;
  disabled?: boolean;
  readonly?: boolean;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  placeholder?: string;
  /**
   * The container will shift to the size of its contents. This means, that if there is no label, or help text, the container will size down to the height of the input field
   */
  compact?: boolean;
}

const TextField = (props: TextFieldProps) => {
  const {
    name,
    label,
    onChange,
    onBlur,
    onFocus,
    value,
    disabled = false,
    readonly = false,
    infoSubtext,
    errorSubtext,
    id,
    type = 'text',
    placeholder,
    compact = false,
    ...rest
  } = props;
  const generatedId = useId();
  const componentId = `textfield-${id ?? generatedId}`;

  const subtext = useMemo(() => errorSubtext || infoSubtext, [infoSubtext, errorSubtext]);
  const labelText = useMemo(() => (compact ? label || null : label || '\u00A0'), [compact, label]);
  const subtextContent = useMemo(() => (compact ? subtext || null : subtext || '\u00A0'), [compact, subtext]);

  return (
    <div
      id={componentId}
      css={{
        display: 'flex',
        flexDirection: 'column',
      }}
      {...rest}
    >
      <label
        id={`${componentId}-label`}
        htmlFor={`${componentId}-input`}
        css={[{ all: 'unset' }, theme.typography.body]}
      >
        {labelText}
      </label>
      <input
        css={[
          {
            all: 'unset',
            borderRadius: '4px',
            border: `1px solid ${theme.palette.grey2}`,
            backgroundColor: theme.palette.white,
            padding: '8px 9px',
            ['&::placeholder']: {
              color: theme.palette.grey2,
            },
            ['&:focus']: {
              borderColor: 'transparent',
              outline: `2px solid ${theme.palette.primary1}`,
            },
          },
          theme.typography.body,
          { color: theme.palette.black },
          errorSubtext && {
            backgroundColor: theme.palette.error2,
            borderColor: theme.palette.error1,
            boxShadow: '0px 0px 4px 2px rgba(215, 42, 71, 0.25)',
            ['&:focus']: {
              outline: 'unset',
              borderColor: theme.palette.error1,
            },
          },
          disabled && {
            backgroundColor: theme.palette.grey4,
            borderColor: theme.palette.grey3,
            color: theme.palette.grey2,
            boxShadow: 'none',
          },
        ]}
        id={`${componentId}-input`}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        readOnly={readonly}
        name={name}
        placeholder={placeholder}
      />
      <span
        id={`${componentId}-subtext`}
        css={[
          { display: 'flex', alignItems: 'start' },
          subtextContent && { marginTop: 8 },
          compact && !subtextContent && { marginTop: 'unset' },
          theme.typography.small,
          infoSubtext && {
            color: theme.palette.grey1,
          },
          errorSubtext && {
            color: theme.palette.error1,
          },
          disabled && {
            color: theme.palette.grey2,
          },
        ]}
      >
        {errorSubtext && <ErrorIcon css={{ width: 16, height: 16, flexShrink: 0, marginRight: 4 }} />}
        {subtextContent}
      </span>
    </div>
  );
};

export default TextField;
