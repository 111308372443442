import { useState, useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { insightsDelete } from '@AssetManagementClient/AssetManagementClientMsp.gen';

type UseInsightDelete = () => {
  deleteInsight: (insightId: string) => Promise<boolean>;
  isDeletingInsight: boolean;
  isDeleted: boolean;
};

const useInsightDelete: UseInsightDelete = () => {
  const { callApi } = useApi();
  const [isDeletingInsight, setDeletingInsight] = useState(false);
  const [isDeleted, setDeleted] = useState(false);

  // Returns boolean that contains if insight deletion was successful or not
  const deleteInsight = useCallback(
    async (insightId: string) => {
      setDeletingInsight(true);
      const response = await callApi(() => insightsDelete({ insightId }));
      if (response !== undefined) {
        setDeleted(true);
        setDeletingInsight(false);
        return true;
      }
      setDeletingInsight(false);
      return false;
    },
    [callApi],
  );
  return { deleteInsight, isDeletingInsight, isDeleted };
};

export default useInsightDelete;
