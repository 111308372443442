import * as React from 'react';
import BannerUpgrade from '~/neo-ui/packages/banner/packages/banner-upgrade/BannerUpgrade';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';

export type AssetConsoleUpgradeBannerProps = {
  upgradeUrl: string;
  scope: FrontendScope;
};

const AssetConsoleUpgradeBanner: React.FunctionComponent<AssetConsoleUpgradeBannerProps> = ({ upgradeUrl, scope }) => (
  <BannerUpgrade
    title={'Looking for more asset and insight details? Upgrade now.'}
    description={
      'Get insights, hardware purchase dates and warranty expiration dates, client hardware reports, and schedule client-facing reports - subscribe today.'
    }
    imageSource={'/i/marketing/hardware/image.png'}
    callToAction={{ text: 'Learn More', link: upgradeUrl }}
    id={`track_banner_clients_universal_asset_console_${scope.type.toString()}`}
  />
);

export default AssetConsoleUpgradeBanner;
