import { css } from '@emotion/react';
import React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Spinner from '~/neo-ui/spinner/Spinner';

type SaveStatus = { label: string; color: Color; icon: IconType };

export type FormSubmitIndicatorProps = {
  prependIcon?: boolean;
} & Styleable;

const FormSubmitIndicator: React.FunctionComponent<FormSubmitIndicatorProps> = ({ prependIcon = false, className }) => {
  const { isSubmitting, isValid } = useFormContext();

  const [formWasModified, setFormWasModified] = React.useState(false);
  React.useEffect(() => {
    if (isSubmitting) {
      setFormWasModified(true);
    }
  }, [isSubmitting]);

  let saveStatus: SaveStatus | undefined;
  if (isSubmitting) {
    saveStatus = { label: 'Saving', color: 'positive-400', icon: 'Success' };
  } else if (isValid && formWasModified) {
    saveStatus = { label: 'Saved', color: 'positive-400', icon: 'Success' };
  } else if (formWasModified) {
    saveStatus = { label: 'Not saved', color: 'negative-400', icon: 'Cancel' };
  }

  return typeof saveStatus === 'undefined' ? null : (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: row;
        gap: 0.3125rem;
        align-items: center;
      `}
    >
      {prependIcon && (
        <>
          {!isSubmitting && (
            <Icon
              icon={saveStatus.icon}
              color={saveStatus.color}
              sizePx={10.5}
            />
          )}
          {isSubmitting && (
            <Spinner
              color={'positive-400'}
              css={css`
                width: 0.65625rem;
                height: 0.65625rem;
              `}
            />
          )}
        </>
      )}
      <div
        css={css`
          color: ${colorToCode(saveStatus.color)};
          font-size: 0.5625rem;
          font-weight: 700;
          letter-spacing: 0.0875rem;
          text-transform: uppercase;
        `}
      >
        {saveStatus.label}
      </div>
      {!prependIcon && (
        <>
          {!isSubmitting && (
            <Icon
              icon={saveStatus.icon}
              color={saveStatus.color}
              sizePx={10.5}
            />
          )}
          {isSubmitting && (
            <Spinner
              color={'positive-400'}
              css={css`
                width: 0.65625rem;
                height: 0.65625rem;
              `}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FormSubmitIndicator;
