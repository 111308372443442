import { css } from '@emotion/react';
import { PropsWithChildren, useEffect, useRef } from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

type AccordionItemProps = {
  label: string;
  title: string;
  isActive: boolean;
  isEdited: boolean;
  editAction: () => void;
};

const AccordionItem = (props: PropsWithChildren<AccordionItemProps>) => {
  const { children, title, label, isActive, isEdited, editAction } = props;
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isActive && ref && children) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [children, ref, isActive]);
  return (
    <div
      ref={ref}
      css={css`
        border: 1px solid ${colorToCode('light-600')};
        border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
        background-color: ${colorToCode('light-000')};
        display: flex;
        flex-direction: column;
        padding: 3rem;
        margin-bottom: 1rem;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              height: 2rem;
              width: 2rem;
              border-radius: 50%;
              border: ${isActive ? `0.063rem solid ${colorToCode('primary-400')};` : `0.063rem solid ${colorToCode('light-900')};`}
              margin-right: 0.875rem;
              background-color: ${isEdited ? '#999999' : isActive ? colorToCode('primary-400') : colorToCode('light-050')};
              color: ${isEdited ? colorToCode('light-050') : isActive ? colorToCode('light-050') : colorToCode('light-900')};
            `}
          >
            <span
              css={css`
                margin-top: 0.063rem;
              `}
            >
              {label}
            </span>
          </div>
          <span
            css={css`
              font-size: 1.5rem;
              font-style: normal;
              font-weight: 400;
              line-height: 125%;
            `}
          >
            {title}
          </span>
        </div>
        {!isActive && isEdited && (
          <div
            css={css`
              display: flex;
              color: ${colorToCode('primary-400')};
              font-size: 1rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.5rem;
              cursor: pointer;
            `}
            onClick={editAction}
          >
            <Icon
              css={css`
                margin-top: 0.188rem;
              `}
              sizePx={16}
              icon={'Edit'}
              color={'primary-400'}
            />
            <span
              css={css`
                margin-left: 0.188rem;
                text-decoration: underline;
              `}
            >
              Edit
            </span>
          </div>
        )}
      </div>
      <div
        css={css`
          padding-left: 3rem;
          height: auto;
          overflow: hidden;
        `}
      >
        {children}
      </div>
    </div>
  );
};

export default AccordionItem;
