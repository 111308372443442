import { SVGProps, forwardRef, memo } from 'react';

const InfoIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
      >
        <g clipPath="url(#clip0_2209_3661)">
          <circle
            cx="12"
            cy="12"
            r="12"
            fill="#0575CC"
          />
          <path
            d="M15.0952 18.9999H9.23193V17.3227C9.39274 17.3103 9.55046 17.2948 9.70508 17.2763C9.8597 17.2577 9.99268 17.2268 10.104 17.1835C10.3019 17.1093 10.4411 17.0042 10.5215 16.8681C10.6019 16.7258 10.6421 16.5403 10.6421 16.3114V13.912C10.6421 13.6956 10.5926 13.5069 10.4937 13.3461C10.3947 13.1791 10.271 13.0461 10.1226 12.9472C10.0112 12.873 9.84115 12.8018 9.6123 12.7338C9.38965 12.6658 9.18555 12.6225 9 12.6039V10.9267L13.5459 10.6855L13.6851 10.8246V16.1908C13.6851 16.4073 13.7314 16.5929 13.8242 16.7475C13.917 16.8959 14.05 17.0072 14.2231 17.0815C14.3468 17.1371 14.4829 17.1866 14.6313 17.2299C14.7798 17.2732 14.9344 17.3041 15.0952 17.3227V18.9999Z"
            fill="white"
          />
          <path
            d="M13.7493 7.16244C13.7493 8.1766 12.9271 8.99873 11.913 8.99873C10.8988 8.99873 10.0767 8.1766 10.0767 7.16244C10.0767 6.14829 10.8988 5.32615 11.913 5.32615C12.9271 5.32615 13.7493 6.14829 13.7493 7.16244Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2209_3661">
            <rect
              width="24"
              height="24"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }),
);

export default InfoIcon;
