import RadioListItem, { RadioListOption } from '~/neo-ui/packages/list/packages/radio-list/packages/radio-list-item/RadioListItem';
import { css } from '@emotion/react';
import { Styleable } from '~/neo-ui/model/capacity';

export type RadioListProps<T extends string = string> = {
  options: RadioListOption<T>[];
  selectedOptionValue?: T;
  onOptionSelected: (option: T) => void;
  onOptionRemove?: (option: T) => void;
} & Styleable;

const RadioList = <T extends string = string>({
  options,
  selectedOptionValue,
  onOptionSelected,
  onOptionRemove,
  className,
}: RadioListProps<T>) => (
  <div
    className={className}
    css={css`
      display: flex;
      flex-direction: column;
      gap: 1rem;
    `}
  >
    {options.map(option => (
      <RadioListItem<T>
        key={option.value}
        option={option}
        selectedOption={selectedOptionValue}
        onClick={option => onOptionSelected(option)}
        onRemove={typeof onOptionRemove !== 'undefined' ? option => onOptionRemove(option) : undefined}
      />
    ))}
  </div>
);

export default RadioList;
