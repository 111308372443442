import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import useDisposalOrderInfoContext from '../../context/hooks/useDisposalOrderInfoContext';
import Spinner from '~/neo-ui/spinner/Spinner';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderType/Model/DisposalOrderTypeFactoryNested.gen';
import useEmailContext from '~/wm/packages/product/context/hooks/useEmailContext';
import Anchor from '~/neo-ui/packages/anchor/Anchor';
import DisposalOrderBulkPickupSectionPickupFinalSchedule from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-bulk-pickup-section/packages/disposal-order-bulk-pickup-section-pickup-final-schedule/DisposalOrderBulkPickupSectionPickupFinalSchedule';
import DisposalOrderBulkPickupSectionPickupAvailabilitySchedule from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-bulk-pickup-section/packages/disposal-order-bulk-pickup-section-pickup-availability-schedule/DisposalOrderBulkPickupSectionPickupAvailabilitySchedule';
import DisposalOrderBulkPickupSectionAvailabilitySchedule from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-bulk-pickup-section/packages/disposal-order-bulk-pickup-section-availability-schedule/DisposalOrderBulkPickupSectionAvailabilitySchedule';
import { Fragment, useMemo, useState } from 'react';
import DisposalOrderBulkPickupSectionImageUpload from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-bulk-pickup-section/packages/disposal-order-bulk-pickup-section-image-update/DisposalOrderBulkPickupSectionImageUpload';

type DisposalOrderBulkPickupSectionState = 'editing' | 'pickupAvailabilitySet' | 'pickupFinalScheduleSet';

const DisposalOrderBulkPickupSection = () => {
  const { disposalOrderSummary } = useDisposalOrderInfoContext();
  const { supportEmail } = useEmailContext();
  const [pickupImageCount, setPickupImageCount] = useState<number>(
    disposalOrderSummary?.orderType === Enum.ItadBulk ? disposalOrderSummary.pickupBulkDetails.imageCount : 0,
  );

  const canUploadImage = useMemo(() => pickupImageCount < 5, [pickupImageCount]);

  if (typeof disposalOrderSummary === 'undefined') {
    return <Spinner />;
  }

  // If the order is completed, we do not need to display this component
  if (typeof disposalOrderSummary.completedAt !== 'undefined') {
    return null;
  }

  if (disposalOrderSummary.orderType !== Enum.ItadBulk) {
    throw new Error('Tried to render this component with a disposal order which is not of type bulk');
  }

  const disposalOrderBulkPickupSectionState: DisposalOrderBulkPickupSectionState =
    typeof disposalOrderSummary.pickupBulkDetails.finalSchedule !== 'undefined'
      ? 'pickupFinalScheduleSet'
      : typeof disposalOrderSummary.pickupBulkDetails.requestedSchedule !== 'undefined'
      ? 'pickupAvailabilitySet'
      : 'editing';

  return (
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
          `}
        >
          <Header
            size={4}
            weight={'bold'}
            color={'dark-700'}
          >
            {
              {
                editing: <Fragment>Schedule your pickup</Fragment>,
                pickupAvailabilitySet: <Fragment>Your pickup will be scheduled soon</Fragment>,
                pickupFinalScheduleSet: <Fragment>Your pickup is scheduled</Fragment>,
              }[disposalOrderBulkPickupSectionState]
            }
          </Header>
          <div
            css={css`
              display: flex;
              gap: 0.3125rem;
              align-items: center;
            `}
          >
            {
              {
                editing: (
                  <Fragment>
                    <Label color={'dark-900-64'}>We'll email you when a pickup window is available.</Label>
                  </Fragment>
                ),
                pickupAvailabilitySet: <Label color={'dark-900-64'}>We'll email you when a pickup window is available.</Label>,
                pickupFinalScheduleSet: (
                  <Label color={'dark-900-64'}>
                    If you need to reschedule it, please reach out to{' '}
                    <Anchor
                      href={supportEmail}
                      mailTo={true}
                    >
                      {supportEmail}
                    </Anchor>
                    .
                  </Label>
                ),
              }[disposalOrderBulkPickupSectionState]
            }
          </div>
        </div>
        {
          {
            editing: (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 1rem;
                `}
              >
                <DisposalOrderBulkPickupSectionImageUpload
                  disposalOrderId={disposalOrderSummary.disposalOrderId}
                  onImageUploadSuccess={() => setPickupImageCount(prevState => prevState + 1)}
                  imageCount={pickupImageCount}
                  canUploadImage={canUploadImage}
                />
                <DisposalOrderBulkPickupSectionAvailabilitySchedule
                  disposalOrderId={disposalOrderSummary.disposalOrderId}
                  canSchedule={pickupImageCount > 0}
                />
              </div>
            ),
            pickupAvailabilitySet: (
              <Fragment>
                {typeof disposalOrderSummary.pickupBulkDetails.requestedSchedule !== 'undefined' && (
                  <DisposalOrderBulkPickupSectionPickupAvailabilitySchedule
                    startDateTime={new Date(disposalOrderSummary.pickupBulkDetails.requestedSchedule.start)}
                    endDateTime={new Date(disposalOrderSummary.pickupBulkDetails.requestedSchedule.end)}
                    pickupNotes={disposalOrderSummary.pickupBulkDetails.pickupNotes}
                  />
                )}
              </Fragment>
            ),
            pickupFinalScheduleSet: (
              <Fragment>
                {typeof disposalOrderSummary.pickupBulkDetails.finalSchedule !== 'undefined' &&
                  typeof disposalOrderSummary.pickupBulkDetails.pickupCompanyName !== 'undefined' && (
                    <DisposalOrderBulkPickupSectionPickupFinalSchedule
                      startDateTime={new Date(disposalOrderSummary.pickupBulkDetails.finalSchedule.start)}
                      endDateTime={new Date(disposalOrderSummary.pickupBulkDetails.finalSchedule.end)}
                      pickupContactName={disposalOrderSummary.pickupBulkDetails.siteInformation.siteContact!.name}
                      pickupCompanyName={disposalOrderSummary.pickupBulkDetails.pickupCompanyName}
                    />
                  )}
              </Fragment>
            ),
          }[disposalOrderBulkPickupSectionState]
        }
      </div>
    </Box>
  );
};

export default DisposalOrderBulkPickupSection;
