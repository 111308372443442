import useApi from '~/wm/packages/api/hook/useApi';
import { useCallback, useState } from 'react';
import { contractSyncSchedule } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseContractSyncSchedule = {
  scheduleSync: () => void;
  isScheduling: boolean;
};

const useContractSyncSchedule = (): UseContractSyncSchedule => {
  const { callApi } = useApi();
  const [isScheduling, setScheduling] = useState(false);

  const scheduleSync = useCallback(async () => {
    setScheduling(true);

    const response = await callApi(() => contractSyncSchedule({}));

    if (!response) {
      return;
    }

    setScheduling(false);
  }, [callApi]);

  return {
    scheduleSync,
    isScheduling,
  };
};

export default useContractSyncSchedule;
