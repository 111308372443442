import * as React from 'react';
import StackedBarChart, { Bar } from '~/neo-ui/packages/chart/packages/bar/packages/stacked/StackedBarChart';
import { Styleable } from '~/neo-ui/model/capacity';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ConsumptionMeterProps = {
  currentConsumption: number;
  maximumLimit: number;
} & Styleable;

const ConsumptionMeter: React.FunctionComponent<ConsumptionMeterProps> = ({ currentConsumption, maximumLimit, className }) => {
  // Checking for 0 here since we don't want to divide by 0 when calculating utilization
  if (maximumLimit === 0) {
    return <div />;
  }

  const formattedConsumption = Math.min(currentConsumption, maximumLimit);
  const utilization = formattedConsumption / maximumLimit;
  let color: Color;
  const bars: Bar[] = [];

  if (utilization < 0.75) {
    color = 'light-600';
  } else if (utilization < 1) {
    color = 'caution-400';
  } else {
    color = 'warning-400';
  }
  bars.push({ value: formattedConsumption, color: colorToCode(color) });
  bars.push({
    value: maximumLimit - formattedConsumption,
    color: colorToCode('light-300'),
  });

  return (
    <StackedBarChart
      height={'0.325rem'}
      bars={bars}
      className={className}
    />
  );
};

export default ConsumptionMeter;
