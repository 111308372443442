import React from 'react';

export type UseSelectionType = (
  selectionType: string, // external selection value
  onChange?: (selectionType: string) => void, // optional parent callback if new type is selected
  defaultSelectedType?: string,
) => {
  selectedType: string;
  handleTypeChange: (typeOption: string) => void;
};

const useSelectionType: UseSelectionType = (selectionType, onChange) => {
  const [selectedType, setTypeOption] = React.useState<string>(selectionType);

  /**
   * Update the selection type if a parent modifies it
   */
  React.useEffect(() => {
    if (typeof selectionType === 'undefined') {
      return;
    }
    setTypeOption(selectionType);
  }, [selectionType]);

  /**
   * Set the state to a given type option
   * @param typeOption
   */
  const handleTypeChange = (typeOption: string) => {
    if (onChange === undefined || selectedType === typeOption) {
      return;
    }
    onChange(typeOption);
    setTypeOption(typeOption);
  };

  return { selectedType, handleTypeChange };
};

export default useSelectionType;
