import * as React from 'react';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import getUrlParameter from '~/neo-ui/packages/table/packages/url-routing/getUrlParameter';
import { AssetFilterFrame } from '~/wm/packages/asset/packages/console/hooks/useAssetConsoleRequest';
import Popover from '~/neo-ui/packages/popover/Popover';
import ConsoleSingleSelect from '~/neo-ui/packages/select/packages/console-single-select/ConsoleSingleSelect';
import { css } from '@emotion/react';
import FilterHeaderPreviewForecast from '~/wm/packages/asset/packages/console/packages/forecast/packages/filter-header-preview-forecast/FilterHeaderPreviewForecast';
import FilterHeaderForecast from '~/wm/packages/asset/packages/console/packages/forecast/packages/filter-header-forecast/FilterHeaderForecast';
import updateUrlParameter from '~/neo-ui/packages/table/packages/url-routing/updateUrlParameter';
import FilterHeaderEmpty from '~/neo-ui/packages/filter/packages/filter-headers/filter-header-common/filter-header-empty/FilterHeaderEmpty';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import FilterHeaderMenuDropdownIcon from '~/neo-ui/packages/filter/packages/filter-headers/filter-header-common/filter-header-dropdown-navigation-icon/FilterHeaderMenuDropdownIcon';
import { useContext } from 'react';
import ConsoleContext from '~/neo-ui/packages/table/packages/console/contexts/ConsoleContext';

type ForecastFilterProps = Record<string, never>;

const forecastOptionsList = [
  {
    label: 'Now',
    value: 'now',
  },
  {
    label: 'In 30 days',
    value: '30',
  },
  {
    label: 'In 60 days',
    value: '60',
  },
  {
    label: 'In 90 days',
    value: '90',
  },
  {
    label: 'In 120 days',
    value: '120',
  },
].map<SelectOption>(option => ({
  label: option.label,
  value: option.value,
  selectedIcon: 'Chosen',
  selectedIconColor: 'secondary-400',
  selectedBackgroundColor: 'secondary-100',
  hoverBackgroundColor: 'secondary-050',
}));
const theme = 'secondary';

const findOption = (value: string): SelectOption | undefined => forecastOptionsList.find(option => option.value === value) ?? undefined;

export const getFilterFrameFromUrl = (): AssetFilterFrame => {
  const urlValue = getUrlParameter('Forecast');
  return getFilterFrameFromForecastOption(urlValue === null ? undefined : findOption(urlValue));
};

const parseNumberFromForecastValue = (forecast: string | undefined): number | undefined => {
  if (forecast === undefined || forecast === defaultOption.value) {
    return undefined;
  }
  return parseInt(forecast, 10);
};

const getFilterFrameFromForecastOption = (option: SelectOption | undefined): AssetFilterFrame => ({
  forecast: parseNumberFromForecastValue(option?.value),
});

const defaultOption = findOption('now')!;

const ForecastFilter: React.FunctionComponent<ForecastFilterProps> = () => {
  const { filterFrame, setFilterFrame } = useContext(ConsoleContext);
  const assetFilterFrame = filterFrame as AssetFilterFrame;
  const [selectedInternal, setSelectedInternal] = React.useState<SelectOption>(
    (typeof assetFilterFrame.forecast === 'undefined' ? undefined : findOption(assetFilterFrame.forecast.toString())) ?? defaultOption,
  );

  /**
   * Update forecast val depending on context
   */
  React.useEffect(() => {
    const newContext = getFilterFrameFromForecastOption(selectedInternal);
    if (assetFilterFrame.forecast !== newContext.forecast) {
      setFilterFrame(newContext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInternal, setFilterFrame]);

  /**
   * Update url param with internal changes
   */
  const onChangeForecast = React.useCallback((selectedForecast: SelectOption | undefined) => {
    setSelectedInternal(selectedForecast ?? defaultOption);

    if (selectedForecast?.value === undefined) {
      updateUrlParameter('Forecast', undefined);
      return;
    }
    const newUrlValue = selectedForecast.value === defaultOption.value ? undefined : selectedForecast.value;

    updateUrlParameter('Forecast', newUrlValue);
  }, []);

  React.useEffect(
    () => {
      if (assetFilterFrame.forecast !== getFilterFrameFromForecastOption(selectedInternal).forecast) {
        setSelectedInternal(
          (typeof assetFilterFrame.forecast === 'undefined' ? undefined : findOption(assetFilterFrame.forecast.toString())) ??
            defaultOption,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterFrame],
  );

  /**
   * Header Display
   */
  const headerCallback = (_isOpen: boolean) => {
    const forecastFilterPreview = (
      <FilterHeaderPreviewForecast
        selected={selectedInternal}
        theme={theme}
      />
    );

    const headerEmpty = (
      <FilterHeaderEmpty
        title={'Forecast...'}
        prependIcon={'Forecast' as IconType}
        dropdownIcon={<FilterHeaderMenuDropdownIcon color={'dark-700'} />}
      />
    );
    return (
      <FilterHeaderForecast
        selectedValue={selectedInternal}
        defaultDisplay={headerEmpty}
        selectedDisplay={forecastFilterPreview}
      />
    );
  };

  /** ***************
   * Main component *
   ******************/
  return (
    <div>
      <Popover header={headerCallback}>
        <ConsoleSingleSelect
          className={'forecast-filter'}
          description={"Show results for your filters as they'd appear:"}
          allOptions={forecastOptionsList}
          selectedOption={selectedInternal}
          selectedIcon={'Chosen'}
          onChange={onChangeForecast}
          css={css`
            width: 20rem;
          `}
        />
      </Popover>
    </div>
  );
};
export default ForecastFilter;
