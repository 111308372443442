import { DragDropZone } from '~/neo-ui/packages/drag-and-drop/DragAndDrop';
import { css } from '@emotion/react';
import RoadmapQuarterColumn from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-quarter-column/RoadmapQuarterColumn';
import { buildRoadmapInitiativeKey } from '~/wm/packages/strategy/packages/roadmap-page/builder/buildRoadmapInitiativesByFiscalQuarter';
import { Fragment, useMemo } from 'react';
import { DropResult } from '@hello-pangea/dnd';
import FiscalQuarter, { Quarter } from '~/extensions/packages/date/packages/fiscal-quarter/FiscalQuarter';
import { RoadmapInitiativeViewMode } from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/RoadmapV2';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useRoadmapInitiativesContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapInitiativesContext';
import useInitiativeScheduleUpdate from '~/wm/packages/strategy/packages/roadmap-page/hooks/useInitiativeScheduleUpdate';
import { DropdownOption } from '~/neo-ui/packages/dropdown/Dropdown';
import { MultiSelectOption } from '~/neo-ui/packages/select/packages/multi-select/MultiSelect';
import { RoadmapInitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import { Styleable } from '~/neo-ui/model/capacity';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';

export type RoadmapInitiativeViewProps = {
  selectedViewMode: DropdownOption<RoadmapInitiativeViewMode>;
  selectedStatuses: MultiSelectOption[];
  selectedPriorities: MultiSelectOption[];
  showUnscheduled: boolean;
} & Styleable;

const RoadmapInitiativeView = ({
  selectedViewMode,
  selectedStatuses,
  selectedPriorities,
  showUnscheduled,
  className,
}: RoadmapInitiativeViewProps) => {
  const { organizationId } = useOrganizationContext();
  const { initiativesByFiscalQuarter, setInitiativesByFiscalQuarter, displayFiscalQuarters, setHighlightedInitiativeIds } =
    useRoadmapInitiativesContext();
  const { updateInitiativeSchedule } = useInitiativeScheduleUpdate({});
  const { currentFiscalQuarter } = useRegionalSettingsInfoContext();

  const filteredInitiativeByFiscalQuarter = useMemo(
    () =>
      [...initiativesByFiscalQuarter.keys()].reduce((map, quarter) => {
        const orderList = initiativesByFiscalQuarter.get(quarter)!;
        map.set(
          quarter,
          orderList
            .filter(
              initiative =>
                !(
                  (selectedStatuses.length !== 0 && !selectedStatuses.some(status => status.value === initiative.status)) ||
                  (selectedPriorities.length !== 0 && !selectedPriorities.some(priority => priority.value === initiative.priority.key))
                ),
            )
            .sort((one, two) => (one.displayOrder > two.displayOrder ? -1 : 1)),
        );

        return map;
      }, new Map<string, RoadmapInitiativeDto[]>()),
    [initiativesByFiscalQuarter, selectedPriorities, selectedStatuses],
  );

  const droppableIdToFiscalQuarter = (droppableId: string): FiscalQuarter => {
    const [year, quarter] = droppableId.split('-');
    return { year: Number(year), quarter: Number(quarter) as Quarter };
  };

  const updateScheduleAfterDrag = async (
    initiativeId: string,
    source: FiscalQuarter,
    target: FiscalQuarter | undefined,
    insertingAfterInitiativeId: string | undefined,
  ) => {
    const sourceQuarter = source.year === 0 ? undefined : { year: source.year, quarter: source.quarter };

    // Getting the original target fiscal quarter initiative order list
    const listBeforeOrder =
      typeof target === 'undefined'
        ? initiativesByFiscalQuarter.get(buildRoadmapInitiativeKey(source))
        : initiativesByFiscalQuarter.get(buildRoadmapInitiativeKey(target));

    // If changing the order of initiatives within one fiscal quarter column (including the not scheduled column)
    if (typeof target === 'undefined') {
      if (typeof listBeforeOrder === 'undefined' || listBeforeOrder.length === 0) {
        return;
      }

      let resultList = Array.from(listBeforeOrder);

      // Get the index of the moving initiative in the current order list
      const currentIndex = listBeforeOrder.map(initiative => initiative.initiativeId).indexOf(initiativeId);

      if (currentIndex === -1) {
        return;
      }

      // Get the moving initiative object
      const movingInitiative = resultList.at(currentIndex)!;

      // Remove moving initiative from the current list
      resultList.splice(currentIndex, 1);

      // If the initiative is not moved to very top of the column
      if (typeof insertingAfterInitiativeId !== 'undefined') {
        // Getting the index of the initiative which will be placed before the moving initiative in the original list
        const insertingAfterInitiativeIndexInOriginalList = listBeforeOrder.findIndex(
          initiative => initiative.initiativeId === insertingAfterInitiativeId,
        );
        if (insertingAfterInitiativeIndexInOriginalList === -1) {
          return;
        }

        // Getting the index of the initiative which will be placed before the  moving initiative in the list where the moving initiative is already moved list
        const insertingIndexMinusOne = resultList.findIndex(initiative => initiative.initiativeId === insertingAfterInitiativeId);
        if (insertingIndexMinusOne === -1) {
          return;
        }

        // If we move the initiative to the bottom of the column directly append moving initiative to the very end
        if (insertingAfterInitiativeIndexInOriginalList + 1 === listBeforeOrder.length) {
          resultList = [...resultList, movingInitiative];
        }
        // If we move the initiative to somewhere not vary top or very bottom we insert the moving initiative after the initiative it supposes to be
        else {
          resultList.splice(insertingIndexMinusOne + 1, 0, movingInitiative);
        }
      } else {
        // If we move to the very top, we directly put the moving initiative on top
        resultList = [movingInitiative, ...resultList];
      }

      const resultListLength = resultList.length;
      const updatedDictionary = new Map(initiativesByFiscalQuarter);
      updatedDictionary.set(
        buildRoadmapInitiativeKey(source),
        resultList.map((initiative, index) => ({
          ...initiative,
          budgetQuarter: target,
          displayOrder: resultListLength - index,
        })),
      );
      setInitiativesByFiscalQuarter(updatedDictionary);

      updateInitiativeSchedule({
        initiativeId,
        organizationId,
        sourceQuarter,
        targetQuarter: sourceQuarter,
        targetOrderList: resultList.map(initiative => initiative.initiativeId),
      });
    }
    // If we move initiative to a different fiscal quarter column (including the not scheduled column)
    else {
      // Getting the original source order list
      const sourceOrderList = initiativesByFiscalQuarter.get(buildRoadmapInitiativeKey(source));

      if (typeof sourceOrderList === 'undefined') {
        return;
      }

      // Getting the moving initiative object
      const movingInitiative = sourceOrderList.find(initiative => initiative.initiativeId === initiativeId);

      if (typeof movingInitiative === 'undefined') {
        return;
      }

      const updatedDictionary = new Map(initiativesByFiscalQuarter);

      let resultOrderList: RoadmapInitiativeDto[] = [];

      // If the target column has no initiative result target initiative order list is just a list with the moving object
      if (typeof listBeforeOrder === 'undefined' || listBeforeOrder.length === 0) {
        resultOrderList = [movingInitiative];
      }
      // If the target column previous already contains initiative
      else {
        const previousOrderListHelper1 = [...listBeforeOrder];
        const previousOrderListHelper2 = [...listBeforeOrder];

        // If we move the moving initiative to the very top of the target column we directly add moving initiative on top of the target column list
        if (typeof insertingAfterInitiativeId === 'undefined') {
          resultOrderList = [movingInitiative, ...previousOrderListHelper1];
        }
        // If we move the moving initiative in between the target list
        else {
          // Getting the index of the initiative which should be placed before the moving initiative in the source list
          const insertingIndexMinusOne = listBeforeOrder.findIndex(initiative => initiative.initiativeId === insertingAfterInitiativeId);

          if (insertingIndexMinusOne === -1) {
            return;
          }

          // Insert moving initiative into the right place
          resultOrderList = [
            ...previousOrderListHelper1.splice(0, insertingIndexMinusOne + 1),
            movingInitiative,
            ...previousOrderListHelper2.splice(insertingIndexMinusOne + 1),
          ];
        }
      }

      const resultListLength = resultOrderList.length;

      updatedDictionary.set(
        buildRoadmapInitiativeKey(source),
        // Remove moving initiative from the source list
        sourceOrderList.filter(initiative => initiative.initiativeId !== initiativeId),
      );
      updatedDictionary.set(
        buildRoadmapInitiativeKey(target),
        resultOrderList.map((initiative, index) => ({
          ...initiative,
          budgetQuarter: target,
          displayOrder: resultListLength - index,
        })),
      );
      setInitiativesByFiscalQuarter(updatedDictionary);

      updateInitiativeSchedule({
        initiativeId,
        organizationId,
        sourceQuarter,
        targetQuarter: typeof target === 'undefined' ? sourceQuarter : target.year === 0 ? undefined : target,
        targetOrderList: resultOrderList.map(initiative => initiative.initiativeId),
      });
    }
  };
  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const sourceFiscalQuarterList = filteredInitiativeByFiscalQuarter.get(result.source.droppableId);

    if (typeof sourceFiscalQuarterList === 'undefined') {
      return;
    }

    let insertingAfterInitiativeId;

    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index > 0 &&
      result.destination.index < result.source.index
    ) {
      insertingAfterInitiativeId = sourceFiscalQuarterList.at(result.destination.index - 1)?.initiativeId;
    }
    if (result.destination.droppableId === result.source.droppableId && result.destination.index > result.source.index) {
      insertingAfterInitiativeId = sourceFiscalQuarterList.at(result.destination.index)?.initiativeId;
    }

    if (result.destination.droppableId !== result.source.droppableId) {
      const destinationFiscalQuarterList = filteredInitiativeByFiscalQuarter.get(result.destination.droppableId);

      if (typeof destinationFiscalQuarterList !== 'undefined' && destinationFiscalQuarterList.length > 0 && result.destination.index > 0) {
        insertingAfterInitiativeId = destinationFiscalQuarterList.at(result.destination.index - 1)?.initiativeId;
      }
    }
    await updateScheduleAfterDrag(
      result.draggableId,
      droppableIdToFiscalQuarter(result.source.droppableId),
      result.destination.droppableId !== result.source.droppableId ? droppableIdToFiscalQuarter(result.destination.droppableId) : undefined,
      insertingAfterInitiativeId,
    );

    setHighlightedInitiativeIds(prev => [...prev, result.draggableId]);
  };

  const isFiltered = selectedPriorities.length > 0 || selectedStatuses.length > 0;

  return (
    <DragDropZone onDragEnd={onDragEnd}>
      <div
        className={className}
        css={css`
          display: flex;
          gap: 1.5rem;
          // Allow the columns to scroll horizontally if the screen is too small
          overflow-x: auto;
          padding: 0 0.5rem;
          height: 100%;
        `}
      >
        {showUnscheduled && (
          <RoadmapQuarterColumn
            initiatives={filteredInitiativeByFiscalQuarter.get(buildRoadmapInitiativeKey(undefined)) ?? []}
            roadmapInitiativeViewMode={selectedViewMode.value}
            isFiltered={isFiltered}
          />
        )}
        {displayFiscalQuarters.map((fiscalQuarter, index) =>
          // Hide last quarter if showUnscheduled is true
          showUnscheduled && index === 3 ? (
            <Fragment key={index} />
          ) : (
            <RoadmapQuarterColumn
              key={index}
              initiatives={filteredInitiativeByFiscalQuarter.get(buildRoadmapInitiativeKey(fiscalQuarter)) ?? []}
              fiscalQuarter={fiscalQuarter}
              isCurrentQuarter={fiscalQuarter.quarter === currentFiscalQuarter.quarter && fiscalQuarter.year === currentFiscalQuarter.year}
              roadmapInitiativeViewMode={selectedViewMode.value}
              isFiltered={isFiltered}
            />
          ),
        )}
      </div>
    </DragDropZone>
  );
};

export default RoadmapInitiativeView;
