import * as React from 'react';
import { RenderCellDataNested } from '@AssetManagementClient/BeastClient/Renderer/Model/Data.gen';
import RenderCell from '~/neo-ui/packages/table/packages/render-cell-data/RenderCell';

export type RenderUrlCellProps = {
  data: RenderCellDataNested.UrlData;
};

const RenderUrlCell: React.FunctionComponent<RenderUrlCellProps> = ({ data }) => (
  <a href={data.url}>
    <RenderCell data={data.display} />
  </a>
);

export default RenderUrlCell;
