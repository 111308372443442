import { createContext } from 'react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { ToolbarControl } from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';

export type WizardPageContextValue = {
  setFieldCompleted: <TFormData>(fieldKey: FieldKeyExpression<TFormData>, value: boolean) => void;
  setActionToolbarControl: (toolbarControl: ToolbarControl | undefined) => void;
  setCurrentStepIndex: (currentStep: number) => void;
  currentStepIndex: number;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const WizardPageContext = createContext<WizardPageContextValue | undefined>(undefined);

export default WizardPageContext;
