import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import ContractTemplateCreateModal from '~/wm/packages/strategy/packages/contract/packages/contract-template/packages/contract-template-create-modal/ContractTemplateCreateModal';

export type ContractTemplateCreateButtonProps = {
  contractId: string;
};

const ContractTemplateCreateButton: React.FunctionComponent<ContractTemplateCreateButtonProps> = ({ contractId }) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Button
        theme={'secondary'}
        iconRight={'Save'}
        onClick={() => {
          setModalOpen(true);
        }}
        size={'sm'}
      />
      <ContractTemplateCreateModal
        contractId={contractId}
        isOpen={isModalOpen}
        onDismiss={() => {
          setModalOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export default ContractTemplateCreateButton;
