import * as React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { organizationEngagementNoteListGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import usePanelTabGroupContext from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/hooks/usePanelTabGroupContext';
import { EngagementType } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/EngagementDashboard';
import EngagementNoteListPanelTabContext from './EngagementNoteListPanelTabContext';
import EngagementNoteListPanelTabMutationContext from './EngagementNoteListPanelTabMutationContext';
import { EngagementNoteDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Dto/Model.gen';
import {
  EngagementNoteStatusAvailabilityDto,
  EngagementNoteStatusCountDto,
} from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Packages/EngagementNoteStatus/Dto/Model.gen';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';

const EngagementNoteListPanelTabProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const { callApi } = useApi();

  const { organizationId } = useOrganizationContext();
  const { currentPageByTab, currentFilterValueByTab, currentTabId } = usePanelTabGroupContext<EngagementType>();

  const [notes, setNotes] = React.useState<EngagementNoteDto[]>();
  const [statusAvailabilities, setStatusAvailabilities] = React.useState<EngagementNoteStatusAvailabilityDto[]>();
  const [statusCounts, setStatusCount] = React.useState<EngagementNoteStatusCountDto[]>();
  const [hasNextPage, setHasNextPage] = React.useState<boolean>(false);

  const reload = React.useCallback(async () => {
    await (async () => {
      const currentPage = currentPageByTab.get(currentTabId);
      const selectedFilterStatus = currentFilterValueByTab.get(currentTabId);

      if (typeof currentPage === 'undefined' || typeof selectedFilterStatus === 'undefined') {
        throw new Error('Current page or filter is undefined from tab context');
      }

      const response = await callApi(() =>
        organizationEngagementNoteListGet({
          organizationId,
          paginationPayload: {
            pageNumber: currentPage,
            filterStatus: selectedFilterStatus,
          },
        }),
      );
      if (!response) {
        return;
      }

      setNotes(response.engagementNotes);
      setStatusCount(response.statusCounts);
      setStatusAvailabilities(response.statusAvailabilities);
      setHasNextPage(response.hasNextPage);
    })();
  }, [callApi, currentFilterValueByTab, currentPageByTab, currentTabId, organizationId]);

  React.useEffect(() => {
    reload();
  }, [reload]);

  if (typeof notes === 'undefined' || typeof statusAvailabilities === 'undefined' || typeof statusCounts === 'undefined') {
    return null;
  }

  return (
    <EngagementNoteListPanelTabMutationContext.Provider
      value={{
        triggerEngagementNoteListPanelTabReload: () => reload(),
      }}
    >
      <EngagementNoteListPanelTabContext.Provider
        value={{
          notes,
          statusAvailabilities,
          statusCounts,
          hasNextPage,
        }}
      >
        {children}
      </EngagementNoteListPanelTabContext.Provider>
    </EngagementNoteListPanelTabMutationContext.Provider>
  );
};

export default EngagementNoteListPanelTabProvider;
