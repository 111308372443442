import { css } from '@emotion/react';
import EngagementActionDashboardCard from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-action-dashboard-panel-tab/packages/engagement-action-dashboard-card/EngagementActionDashboardCard';
import Button from '~/neo-ui/packages/button/Button';
import * as React from 'react';
import { Fragment, useState } from 'react';
import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import useEngagementActionInitiativeUnlink from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionInitiativeUnlink';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import useAlert from '~/neo-ui/packages/toast/hooks/useAlert';

export type InitiativeSaveActionLinkProps = { action: EngagementActionDto };

const InitiativeSaveActionLink = ({ action }: InitiativeSaveActionLinkProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const sendAlert = useAlert();
  const { unlinkInitiative, isUnlinkingInitiative } = useEngagementActionInitiativeUnlink({
    onSuccess: () =>
      sendAlert({
        title: 'Action item successfully unlinked',
        toastType: 'alert',
        description: <Fragment />,
        icon: 'Good',
        theme: 'success',
        buttonRight: { label: 'Ok', onClick: () => {} },
      }),
  });

  return (
    <div
      key={`${action.engagementActionId}-${action.isCompleted}`}
      /**
       * Used to cut off the animation when the action mark/unmarked as completed
       *
       * We want to cut off the animation at the edge of the widget without affecting the
       * overflow causing the assign users dropdown menu to trigger the widget to overflow.
       *
       * Ref: 6f2e61e2-7079-4094-96a6-dd5d9f635f25
       */
      css={css`
        margin: -1.25rem;
        padding: 1.25rem;
        overflow-x: hidden;

        display: flex;
        gap: 0.3125rem;
        align-items: center;
      `}
    >
      <EngagementActionDashboardCard
        isInitiative={true}
        initiativeCreatedFrom={'engagement-action-list'}
        action={action}
        css={css`
          flex-grow: 1;
        `}
      />
      <Button
        iconLeft={'Remove'}
        onClick={() => setIsOpen(true)}
        loading={isUnlinkingInitiative}
      />
      <ModalConfirmation
        header={{
          title: 'Unlink this action item from this Initiative?',
          icon: 'Remove',
        }}
        isOpen={isOpen}
        theme={'negative'}
        footer={{
          confirmButton: {
            icon: 'Remove',
            label: 'Unlink',
            loading: isUnlinkingInitiative,
            onClick: () => {
              if (typeof action.initiativeLinkInfo !== 'undefined') {
                unlinkInitiative({
                  engagementActionId: action.engagementActionId,
                  initiativeLinkId: action.initiativeLinkInfo.initiativeLinkId,
                });
              }
            },
          },
          dismissButton: {
            icon: 'Cancel',
            label: 'Cancel',
            disabled: isUnlinkingInitiative,
            onClick: () => setIsOpen(false),
          },
        }}
      >
        Removing will not delete the action item from Lifecycle Manager. You can re-link this action item later on.
      </ModalConfirmation>
    </div>
  );
};

export default InitiativeSaveActionLink;
