import { organizationEngagementActionPin } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementActionListMutation, {
  UseEngagementActionListMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListMutation';

export type UseEngagementActionPin = {
  pin: (engagementActionId: string) => void;
  isPinning: boolean;
};

const useEngagementActionPin = (options?: UseEngagementActionListMutationOptions): UseEngagementActionPin => {
  const [pin, isPinning] = useEngagementActionListMutation(
    (engagementActionId: string) =>
      organizationEngagementActionPin({
        engagementActionId,
      }),
    options,
  );

  return {
    pin,
    isPinning,
  };
};

export default useEngagementActionPin;
