import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { contractListSpreadsheet } from '@AssetManagementClient/AssetManagementClientMsp.gen';

type UseDownloadContractListSpreadsheet = () => {
  downloadContractListSpreadsheet: (organizationId: string) => void;
};

export const useDownloadContractListSpreadsheet: UseDownloadContractListSpreadsheet = () => {
  const { callApi } = useApi();

  const downloadContractListSpreadsheet = React.useCallback(
    async (organizationId: string) => {
      const stream = await callApi(() => contractListSpreadsheet({ organizationId }));

      if (!stream) {
        return;
      }
    },
    [callApi],
  );

  return { downloadContractListSpreadsheet };
};

export default useDownloadContractListSpreadsheet;
