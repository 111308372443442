import { css } from '@emotion/react';
import * as React from 'react';
import { MultiSelect as MultiSelectInternal } from 'react-multi-select-component';
import { Styleable } from '~/neo-ui/model/capacity';
import { neoColorToCode } from '~/neo-ui/packages/color/NeoColor';
import Icon from '~/neo-ui/packages/icon/Icon';
import DisableElementOverlay from '~/neo-ui/packages/overlay/DisableElementOverlay';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { shouldShowSearch } from '~/neo-ui/packages/select/packages/Common';

export type MultiSelectOption = SelectOption;

export type MultiSelectProps = {
  label: string;
  disabled?: boolean;
  options: MultiSelectOption[];
  onChange: (options: MultiSelectOption[]) => void;
  selectedOptions: MultiSelectOption[];
  hasSelectAnyOption?: boolean;
  showSelectCount?: boolean;
  alwaysNeutralColor?: boolean;
} & Styleable;

const CustomItemRenderer: React.FunctionComponent<{
  checked: boolean;
  option: { label: string };
  onClick: () => void;
  disabled: boolean;
}> = ({ checked, option, onClick, disabled }) => (
  <div className={`item-renderer${disabled ? ' disabled' : ''}`}>
    <input
      css={css`
        margin-right: 0.4375rem;
      `}
      type="checkbox"
      onChange={onClick}
      checked={checked}
      tabIndex={-1}
      disabled={disabled}
    />
    <span>{option.label}</span>
  </div>
);

const MultiSelect: React.FunctionComponent<MultiSelectProps> = ({
  label,
  disabled = false,
  options,
  onChange,
  selectedOptions,
  className,
  hasSelectAnyOption = true,
  showSelectCount = true,
  alwaysNeutralColor = false,
}) => {
  const selectAnyOption = {
    value: '<SELECT_ANY>',
    label: `All ${label.toLowerCase()}`,
  };

  const selectOptions = [...(hasSelectAnyOption ? [selectAnyOption] : []), ...options];

  return (
    <DisableElementOverlay disabled={disabled}>
      <MultiSelectInternal
        css={css`
          --rmsc-radius: 0.3125rem;
          --rmsc-h: 34px;
          ${selectedOptions.length > 0 && !alwaysNeutralColor ? `--rmsc-border: ${neoColorToCode('indigo1')} ;` : ''}
          .dropdown-container {
            ${selectedOptions.length > 0 && !alwaysNeutralColor
              ? `background-color: ${colorToCode('secondary-400')} !important; color: white;`
              : ''}
            .dropdown-heading, .dropdown-heading-value, span {
              transition: none;
            }
          }
        `}
        className={className}
        disabled={disabled}
        value={selectedOptions.length === 0 ? [...selectedOptions, selectAnyOption] : selectedOptions}
        labelledBy={label}
        options={selectOptions}
        onChange={(newSelectedOptions: MultiSelectOption[]) => {
          if (
            hasSelectAnyOption &&
            selectedOptions.length > 0 &&
            newSelectedOptions.some(option => option.value === selectAnyOption.value)
          ) {
            onChange([]);
          } else if (
            hasSelectAnyOption &&
            selectedOptions.length === 0 &&
            !newSelectedOptions.some(option => option.value === selectAnyOption.value)
          ) {
            onChange(options);
          } else {
            onChange(newSelectedOptions.filter(option => option.value !== selectAnyOption.value));
          }
        }}
        hasSelectAll={false}
        ClearSelectedIcon={<React.Fragment />}
        ItemRenderer={CustomItemRenderer}
        ArrowRenderer={({ expanded }) => (
          <Icon
            icon={expanded ? 'ArrowUp' : 'ArrowDown'}
            color={selectedOptions.length > 0 && !alwaysNeutralColor ? 'light-000' : 'dark-900'}
          />
        )}
        valueRenderer={() =>
          selectedOptions.length <= 0 && hasSelectAnyOption
            ? selectAnyOption.label
            : showSelectCount
            ? `${label} (${selectedOptions.length.toString()})`
            : label
        }
        disableSearch={!shouldShowSearch(options)}
      />
    </DisableElementOverlay>
  );
};

export default MultiSelect;
