import * as React from 'react';
import { css } from '@emotion/react';
import { countryList } from '~/neo-ui/model/Country';
import JoinedInputGroup from '~/neo-ui/packages/input/packages/joined-input-group/JoinedInputGroup';
import InputText from '~/neo-ui/packages/input/packages/input-text/InputText';
import { AddressBusiness } from '@SubscriptionClient/Common/Location/Model/Address.gen';
import SingleSelect from '~/neo-ui/packages/select/packages/single-select/SingleSelect';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import FormErrorMessage from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { Styleable } from '~/neo-ui/model/capacity';

export type RestrictedAddressPostal = {
  line1?: string;
  line2?: string;
  city?: string;
  stateCode?: string;
  stateFallback?: string;
  zip?: string;
  countryCode?: string;
  countryFallback?: string;
};

export type RestrictedAddress = {
  name?: string;
  phone?: string;
  address: RestrictedAddressPostal;
};

export type AddressInputProps<T> = {
  fieldKeys?: {
    globalErrors: FieldKeyExpression<T>;
    companyName: FieldKeyExpression<T>;
    country: FieldKeyExpression<T>;
    state: FieldKeyExpression<T>;
    line1: FieldKeyExpression<T>;
    line2: FieldKeyExpression<T>;
    city: FieldKeyExpression<T>;
    zip: FieldKeyExpression<T>;
    phone: FieldKeyExpression<T>;
  };
  initialAddress?: AddressBusiness;
  restrictAddressTo?: RestrictedAddress;
  onAddressUpdate?: (billingAddress: AddressBusiness) => void;
} & Styleable;

const AddressInput = <T,>({ fieldKeys, restrictAddressTo, initialAddress, onAddressUpdate, className }: AddressInputProps<T>) => {
  const countryListOptions = countryList.map(
    (country): SelectOption => ({
      label: country.name,
      value: country.iso2Code,
    }),
  );

  const emptyAddressBusiness: AddressBusiness = {
    name: '',
    phone: '',
    address: {
      city: '',
      zip: '',
      line1: '',
      line2: '',
      stateCode: '',
      stateFallback: '',
      countryCode: '',
      countryFallback: '',
    },
  };

  const initialCountryListOption =
    typeof initialAddress !== 'undefined'
      ? countryListOptions.find(countryOption =>
          typeof restrictAddressTo !== 'undefined' && typeof restrictAddressTo.address.countryCode !== 'undefined'
            ? countryOption.value === restrictAddressTo.address.countryCode
            : countryOption.value === initialAddress.address.countryCode,
        )
      : undefined;

  const [selectedCountry, setSelectedCountry] = React.useState<SelectOption | undefined>(initialCountryListOption);

  const [billingAddress, setBillingAddress] = React.useState(initialAddress);

  React.useEffect(() => {
    setBillingAddress(initialAddress);
  }, [initialAddress]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <div
        className={className}
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1.625rem;

          & > div {
            flex: 1;
          }
        `}
      >
        {/* Company Name */}
        <div>
          <Label
            css={css`
              margin-bottom: 0.625rem;
            `}
            bold={true}
          >
            Company name
          </Label>
          <InputText
            onTextChange={event => {
              const newBillingAddress: AddressBusiness = {
                ...(typeof billingAddress === 'undefined' ? emptyAddressBusiness : billingAddress),
                name: event.target.value,
              };

              setBillingAddress(newBillingAddress);

              if (typeof onAddressUpdate !== 'undefined') {
                onAddressUpdate(newBillingAddress);
              }
            }}
            value={billingAddress?.name ?? ''}
            placeholder={'My Company Name'}
          />
          {typeof fieldKeys !== 'undefined' && <FormErrorMessage fieldKey={fieldKeys.companyName} />}
        </div>
        {/* Address Line 1 and Line 2 */}
        <div>
          <Label
            css={css`
              margin-bottom: 0.625rem;
            `}
            bold={true}
          >
            Address
          </Label>
          <JoinedInputGroup>
            <InputText
              onTextChange={event => {
                const newBillingAddress: AddressBusiness = {
                  ...(typeof billingAddress === 'undefined' ? emptyAddressBusiness : billingAddress),
                  address: {
                    ...(typeof billingAddress === 'undefined' ? emptyAddressBusiness.address : billingAddress.address),
                    line1: event.target.value,
                  },
                };

                setBillingAddress(newBillingAddress);

                if (typeof onAddressUpdate !== 'undefined') {
                  onAddressUpdate(newBillingAddress);
                }
              }}
              value={billingAddress?.address.line1 ?? ''}
              placeholder={'Address line 1'}
            />
            <InputText
              onTextChange={event => {
                const newBillingAddress: AddressBusiness = {
                  ...(typeof billingAddress === 'undefined' ? emptyAddressBusiness : billingAddress),
                  address: {
                    ...(typeof billingAddress === 'undefined' ? emptyAddressBusiness.address : billingAddress.address),
                    line2: event.target.value,
                  },
                };

                setBillingAddress(newBillingAddress);

                if (typeof onAddressUpdate !== 'undefined') {
                  onAddressUpdate(newBillingAddress);
                }
              }}
              value={billingAddress?.address.line2 ?? ''}
              placeholder={'Address line 2 (optional)'}
            />
          </JoinedInputGroup>
          {typeof fieldKeys !== 'undefined' && (
            <React.Fragment>
              <div>
                <FormErrorMessage fieldKey={fieldKeys.line1} />
                <FormErrorMessage fieldKey={fieldKeys.line2} />
              </div>
            </React.Fragment>
          )}
        </div>
        {/* City and State */}
        <div
          css={css`
            display: flex;

            & > div {
              flex: 1;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-right: 0.4rem;
            `}
          >
            <Label
              css={css`
                margin-bottom: 0.625rem;
              `}
              bold={true}
            >
              City/Town
            </Label>
            <InputText
              onTextChange={event => {
                const newBillingAddress: AddressBusiness = {
                  ...(typeof billingAddress === 'undefined' ? emptyAddressBusiness : billingAddress),
                  address: {
                    ...(typeof billingAddress === 'undefined' ? emptyAddressBusiness.address : billingAddress.address),
                    city: event.target.value,
                  },
                };

                setBillingAddress(newBillingAddress);

                if (typeof onAddressUpdate !== 'undefined') {
                  onAddressUpdate(newBillingAddress);
                }
              }}
              value={billingAddress?.address.city ?? ''}
              placeholder={'City'}
            />
            {typeof fieldKeys !== 'undefined' && <FormErrorMessage fieldKey={fieldKeys.city} />}
          </div>
          <div>
            {/*
            This should be changed later to be State/Province Abbreviation or State/Province Abbr.
            There could be some functionality that allows the header to change
            based upon the address region being used, or the workflow.
          */}
            <Label
              css={css`
                margin-bottom: 0.625rem;
              `}
              bold={true}
            >
              State Abbreviation
            </Label>
            <InputText
              onTextChange={event => {
                // LIMIT THE USERS INPUT TO 2 CHARACTERS MAX
                const restrictedValue = event.target.value.slice(0, 2);

                const newBillingAddress: AddressBusiness = {
                  ...(typeof billingAddress === 'undefined' ? emptyAddressBusiness : billingAddress),
                  address: {
                    ...(typeof billingAddress === 'undefined' ? emptyAddressBusiness.address : billingAddress.address),
                    stateFallback: restrictedValue,
                    stateCode: restrictedValue,
                  },
                };

                setBillingAddress(newBillingAddress);

                if (typeof onAddressUpdate !== 'undefined' && billingAddress?.address.stateCode !== restrictedValue) {
                  onAddressUpdate(newBillingAddress);
                }
              }}
              value={billingAddress?.address.stateCode ?? ''}
              placeholder={'State Abbreviation'}
            />
            {typeof fieldKeys !== 'undefined' && <FormErrorMessage fieldKey={fieldKeys.state} />}
          </div>
        </div>
        {/* Country and Zip Code */}
        <div
          css={css`
            display: flex;

            & > div {
              flex: 1;
            }
          `}
        >
          <div
            css={css`
              margin-right: 0.4rem;
            `}
          >
            <Label
              css={css`
                margin-bottom: 0.625rem;
              `}
              bold={true}
            >
              Country
            </Label>
            <SingleSelect
              disabled={typeof restrictAddressTo !== 'undefined' && typeof restrictAddressTo.address.countryCode !== 'undefined'}
              options={
                typeof restrictAddressTo !== 'undefined' && typeof restrictAddressTo.address.countryCode !== 'undefined'
                  ? countryListOptions.filter(countryOption => countryOption.value === restrictAddressTo.address.countryCode)
                  : countryListOptions
              }
              selectedOption={selectedCountry}
              onOptionSelected={selectedOption => {
                setSelectedCountry(selectedOption);

                const countrySelected = countryList.find(country => country.name === selectedOption.label);

                if (countrySelected) {
                  const newBillingAddress: AddressBusiness = {
                    ...(typeof billingAddress === 'undefined' ? emptyAddressBusiness : billingAddress),
                    address: {
                      ...(typeof billingAddress === 'undefined' ? emptyAddressBusiness.address : billingAddress.address),
                      countryFallback: countrySelected.iso2Code,
                      countryCode: countrySelected.iso2Code,
                    },
                  };

                  setBillingAddress(newBillingAddress);

                  if (typeof onAddressUpdate !== 'undefined') {
                    onAddressUpdate(newBillingAddress);
                  }
                }
              }}
            />
            {typeof fieldKeys !== 'undefined' && <FormErrorMessage fieldKey={fieldKeys.country} />}
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            {/*
            This should be changed later to be Postal Code/Zip.
            There could be some functionality that allows the header to change
            based upon the address region being used, or the workflow.
          */}
            <Label
              css={css`
                margin-bottom: 0.625rem;
              `}
              bold={true}
            >
              ZIP Code
            </Label>
            <InputText
              onTextChange={event => {
                const newBillingAddress: AddressBusiness = {
                  ...(typeof billingAddress === 'undefined' ? emptyAddressBusiness : billingAddress),
                  address: {
                    ...(typeof billingAddress === 'undefined' ? emptyAddressBusiness.address : billingAddress.address),
                    zip: event.target.value,
                  },
                };

                setBillingAddress(newBillingAddress);

                if (typeof onAddressUpdate !== 'undefined') {
                  onAddressUpdate(newBillingAddress);
                }
              }}
              value={billingAddress?.address.zip ?? ''}
              placeholder={'ZIP Code'}
            />
            {typeof fieldKeys !== 'undefined' && <FormErrorMessage fieldKey={fieldKeys.zip} />}
          </div>
        </div>
      </div>
      {typeof fieldKeys !== 'undefined' && <FormErrorMessage fieldKey={fieldKeys.globalErrors} />}
    </div>
  );
};

export default AddressInput;
