import React from 'react';
import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import { useDownloadPdfProposal } from '~/wm/packages/warranty/packages/warranty-cart/hooks/useDownloadPdfProposal';
import DownloadKitButton from '~/wm/packages/warranty/packages/warranty-list/packages/download-kit-button/DownloadKitButton';

const WarrantyCartSummary: React.FunctionComponent = () => {
  const { estimateId } = useWarrantyCartContext();
  const { downloadPdfProposal } = useDownloadPdfProposal();

  return (
    <div
      css={css`
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
      `}
    >
      <Header
        size={2}
        weight={'light'}
      >
        Summary
      </Header>
      <div>
        <DownloadKitButton />
        <Button
          css={css`
            padding: 0.45rem 0.8rem;
            border-radius: 2rem;
          `}
          onClick={() => downloadPdfProposal(estimateId!.toString())}
          iconLeft={'Download'}
          size={'md'}
          theme={'primary'}
          invertColorMap={true}
          disabled={typeof estimateId === 'undefined'}
        >
          Download Proposal PDF
        </Button>
      </div>
    </div>
  );
};

export default WarrantyCartSummary;
