import React from 'react';
import {
  PatchDeviceDateEnum,
  TwoWaySyncStatus,
} from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Request/Payload/Action/PatchDevice.gen';
import { css } from '@emotion/react';
import { IntegrationPreferences } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';
import { BlankSerialNumberBehavior } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Request/Payload/Action/GetDevices.gen';
import { pascalToCamel } from '~/extensions/packages/casing/camelPascalConversion';
import IntegrationSetupPageSubSectionHeader, {
  IntegrationSetupPageSubSubSectionHeader,
} from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/page-layout/IntegrationSetupPageSubSectionHeader';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { FormSelectDisplay } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectDisplay';
import { FormCheckboxDisplay } from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckBoxDisplay';
import { SyncPreferencesData } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useSyncPreferencesForm';

export const displaySyncPreferences = (integrationPreferences: IntegrationPreferences) => ({
  displayTwoWaySync: integrationPreferences.twoWaySyncStatus === TwoWaySyncStatus.Available,
  displayCustomFields: integrationPreferences.twoWaySyncCustomFieldSettings !== undefined,
  displayMaxDaysSinceLastCheckIn: integrationPreferences.isMaxDaysSinceLastCheckInSupported,
  displayBlankSerialCheckbox: integrationPreferences.blankSerialNumberBehavior === BlankSerialNumberBehavior.DefinedByUser,
});

export type SyncPreferencesInternalProps = {
  integrationPreferences: IntegrationPreferences;
  register: UseFormRegister<SyncPreferencesData>;
  watch: UseFormWatch<SyncPreferencesData>;
};

export const SyncPreferencesInternal: React.FunctionComponent<SyncPreferencesInternalProps> = ({
  integrationPreferences,
  register,
  watch,
}) => {
  const twoWaySyncOptions = (b: 'datesToPatch' | 'datesToPatchCustomFields') => (patchEnum: PatchDeviceDateEnum) => {
    const identification = `${b}.${patchEnum}` as const;
    return (
      <FormCheckboxDisplay
        key={identification}
        id={identification}
        description={getPatchLabel(patchEnum, integrationPreferences.twoWaySyncDateLabels) ?? ''}
        propsForHtmlInputComponent={register(identification)}
      />
    );
  };

  const { twoWaySyncDatesSupported, twoWaySyncCustomFieldSettings } = integrationPreferences;

  const getPatchLabel = (patchEnum: PatchDeviceDateEnum, labelDictionary: { [key in PatchDeviceDateEnum]: string } | undefined) =>
    // This is some nasty type coercion to deal with the fact that the dictionary of labels is passed with camelCased
    // keys, but PatchEnum is passed with PascalCased values. Despite what typescript thinks!!
    labelDictionary === undefined ? undefined : labelDictionary[pascalToCamel(patchEnum) as PatchDeviceDateEnum];

  const { displayTwoWaySync, displayCustomFields, displayMaxDaysSinceLastCheckIn, displayBlankSerialCheckbox } =
    displaySyncPreferences(integrationPreferences);

  const maxDaysEnabled = watch('maxDaysSinceLastCheckInEnabled');

  return (
    <div
      css={css`
        display: flex;
        row-gap: 1.5rem;
        flex-direction: column;
      `}
    >
      <IntegrationSetupPageSubSectionHeader title={'Preferences'} />

      {/* Two-way sync */}
      {displayTwoWaySync && twoWaySyncDatesSupported !== undefined && (
        <section
          css={css`
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
          `}
        >
          <IntegrationSetupPageSubSubSectionHeader
            title={'Two-Way Sync'}
            description={
              integrationPreferences.twoWaySyncDescription ??
              'We’ll update your data in this integration with fresh data from your ScalePad products.'
            }
          />
          {/* Regular two-way sync */}
          {twoWaySyncDatesSupported.map(twoWaySyncOptions(`datesToPatch`))}
        </section>
      )}
      {/* Custom fields */}
      {displayCustomFields &&
        // twoWaySyncCustomFieldSettings is defined if displayCustomFields is true, but typescript doesn't know that
        twoWaySyncCustomFieldSettings && (
          <section
            css={css`
              display: flex;
              flex-direction: column;
              row-gap: 0.5rem;
            `}
          >
            <IntegrationSetupPageSubSubSectionHeader
              title={'Two-Way Sync Custom Fields'}
              description={twoWaySyncCustomFieldSettings.description}
            />
            {twoWaySyncCustomFieldSettings.supportedFields.map(twoWaySyncOptions(`datesToPatchCustomFields`))}
          </section>
        )}
      {/* Special hardware settings */}
      <section
        css={css`
          display: flex;
          flex-direction: column;
          row-gap: 0.5rem;
        `}
      >
        {(displayMaxDaysSinceLastCheckIn || displayBlankSerialCheckbox) && <IntegrationSetupPageSubSubSectionHeader title={'Hardware'} />}
        {displayBlankSerialCheckbox && (
          <FormCheckboxDisplay
            propsForHtmlInputComponent={register('dontSkipDevicesWithBlankSerialNumber')}
            id={'dontSkipDevicesWithBlankSerialNumber'}
            description={
              <>
                Sync assets without serial numbers
                <Label
                  size={'sm'}
                  color={'dark-900-64'}
                >
                  Some information may be unavailable for these assets
                </Label>
              </>
            }
          />
        )}
        {displayMaxDaysSinceLastCheckIn && (
          <div
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            `}
          >
            <FormCheckboxDisplay
              id={'maxDaysSinceLastCheckInEnabled'}
              propsForHtmlInputComponent={register('maxDaysSinceLastCheckInEnabled')}
              description={'Limit sync to assets checked in within the last:'}
            />
            <FormSelectDisplay
              propsForTheSelectHtmlComponent={register('maxDaysSinceLastCheckIn')}
              disabled={!maxDaysEnabled}
              options={[30, 90, 120].map(x => ({
                // not an ideal long term solution
                label: `${x} days`,
                value: x.toString(),
              }))}
            />
          </div>
        )}
      </section>
    </div>
  );
};
