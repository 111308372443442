import { disposalOrderPickupSchedule } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useDisposalOrderInfoMutation, {
  UseDisposalOrderInfoMutationOptions,
} from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderInfoMutation';
import { Request } from '@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPickupScheduleControllerNested.gen';

export type UseDisposalOrderPickupSchedule = () => {
  schedulePickup: (request: Request, isRequestActive?: () => boolean) => void;
  isScheduling: boolean;
};

const useDisposalOrderPickupSchedule: UseDisposalOrderPickupSchedule = (options?: UseDisposalOrderInfoMutationOptions) => {
  const [schedulePickup, isScheduling] = useDisposalOrderInfoMutation((request: Request) => disposalOrderPickupSchedule(request), options);

  return { schedulePickup, isScheduling };
};

export default useDisposalOrderPickupSchedule;
