import useApi from '~/wm/packages/api/hook/useApi';
import { useState, useCallback, useEffect } from 'react';
import { manufacturerBasicInfoList } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { AppManufacturerDto } from '@AssetManagementClient/BeastClient/Beast/AppManufacturer/Dto/Model.gen';

export type UseManufacturerList = () => {
  manufacturers: AppManufacturerDto[] | undefined;
};

const useManufacturerList: UseManufacturerList = () => {
  const { callApi } = useApi();
  const [manufacturers, setManufacturers] = useState<AppManufacturerDto[] | undefined>(undefined);

  const reload = useCallback(async () => {
    const response = await callApi(() => manufacturerBasicInfoList({}));

    if (!response) {
      return;
    }

    setManufacturers(response.manufacturers);
  }, [callApi]);

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    manufacturers,
  };
};

export default useManufacturerList;
