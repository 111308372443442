import { createContext } from 'react';
import FiscalQuarter from '~/extensions/packages/date/packages/fiscal-quarter/FiscalQuarter';

export type CurrencyInfo = {
  countryCode: string;
  subunitRatio: number;
  exchangeRateFromUsd: number;
  currencySymbol: string;
};

export type RegionalSettingsInfoContextData = {
  currencyInfo: CurrencyInfo;
  currentFiscalQuarter: FiscalQuarter;
  fiscalQuarterStartingMonth: number;
  startingFiscalYear: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const RegionalSettingsInfoContext = createContext<RegionalSettingsInfoContextData | undefined>(undefined);

export default RegionalSettingsInfoContext;
