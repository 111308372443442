import * as React from 'react';
import { useEffectUpdate } from '~/extensions/packages/hooks/useEffectUpdate';
import { useFormInput } from '~/neo-ui/packages/form/hooks/useFormInput';
import { FieldKeyExpression, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { InputProps } from '~/neo-ui/packages/form/packages/form-input/FormInputTextInternal';

const useFormInputBuilder: <T>(
  fieldKey: FieldKeyExpression<T>,
  options?: {
    optimizePerformance?: boolean;
    mapValue?: (value: string) => string;
    onChange?: (value: string) => void;
  },
) => {
  touched: boolean;
  field: InputProps;
  setFieldValue: (value: string) => void;
  inputValue: string | undefined;
  error: string | undefined;
} = <T>(
  fieldKey: FieldKeyExpression<T>,
  options?: {
    optimizePerformance?: boolean;
    mapValue?: (value: string) => string;
    onChange?: (value: string) => void;
  },
) => {
  const optimizePerformance = options?.optimizePerformance ?? false;
  const mapValue = options?.mapValue;
  const onChange = options?.onChange;

  const [field, { touched, error }, { setValue, setTouched }] = useFormInput<T>(fieldKey);

  // If performance optimization is enabled,
  // this represents the input value before
  // propagation to the form engine.
  const [uncontrolledInputValue, setUncontrolledInputValue] = React.useState<string>();

  const setFormValue = (value: string) => {
    setValue(value, true);
    if (onChange) {
      onChange(value);
    }
  };

  const setInputValue = (value: string) => {
    setUncontrolledInputValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  // Follow updates if the field key changed
  useEffectUpdate(() => {
    setInputValue(field.value);
  }, [resolveFieldKey(fieldKey)]);

  // Tracks whether the input has changed
  // to avoid unnecessary propagation
  // & confirmation triggers
  const dirtyRef = React.useRef(false);

  // Our confirmation before closing the window in case
  // we've yet to commit our changes (only applies when
  // optimizePerformance is set to true)
  const onBeforeUnload = React.useCallback((e: BeforeUnloadEvent) => {
    if (!dirtyRef.current) {
      return undefined;
    }

    const confirmationMessage = 'It looks like you have unsaved changes. ' + 'If you leave before saving, your changes may be lost.';

    // eslint-disable-next-line no-param-reassign,@typescript-eslint/no-unsafe-member-access
    e.returnValue = confirmationMessage; // Gecko + IE
    return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
  }, []);

  /**
   * Ensure to propagate form data updates unless currently
   * making changes.
   */
  React.useEffect(() => {
    if (dirtyRef.current) {
      return;
    }
    if (field.value !== uncontrolledInputValue) {
      setInputValue(field.value);
    }
    // eslint-disable-next-line
  }, [field.value]);

  return {
    field: {
      ...field,
      onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const finalValue = mapValue ? mapValue(e.target.value) : e.target.value;
        setFormValue(finalValue);
      },
      ...(optimizePerformance && {
        // Control input manually
        value: uncontrolledInputValue,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          // Set input to dirty
          dirtyRef.current = true;

          // Update the input value
          const finalValue = mapValue ? mapValue(e.target.value) : e.target.value;

          setInputValue(finalValue);
        },
        onFocus: (_: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          // Ensure that we warn the user before allowing them to exit
          // (as their changes wouldn't have been propagated yet)
          window.addEventListener('beforeunload', onBeforeUnload);
        },
        onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          if (dirtyRef.current) {
            // Propagate the update to the form engine
            setFormValue(e.target.value);

            // Mark the field as touched
            setTouched(true);

            // Reset the dirty state
            dirtyRef.current = false;
          }

          // Disable the confirmation warning
          window.removeEventListener('beforeunload', onBeforeUnload);
        },
      }),
    },
    setFieldValue: optimizePerformance ? setInputValue : setFormValue,
    // inputValue always represents the current value of the input
    // field.value always represents the form value of the input
    inputValue: optimizePerformance ? uncontrolledInputValue : field.value,
    error,
    touched,
  };
};

export default useFormInputBuilder;
