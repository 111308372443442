import { css } from '@emotion/react';
import * as React from 'react';
import Card, { Header } from '~/legacy-ui/packages/card/Card';
import Button, { ButtonProps } from '~/neo-ui/packages/button/Button';
import Color from '~/neo-ui/packages/color/Color.gen';

type ShadowDepth = 'none' | 'sm' | 'md' | 'lg' | 'xl';
type Padding = 'sm' | 'md' | 'lg';

export type HardwareInfoCardProps = {
  backgroundColor?: Color;
  shadow?: ShadowDepth;
  padding?: Padding;
  header?: Header;
  actionButtons?: ButtonProps[];
  actionButtonTexts?: string[];
};

const HardwareInfoCard: React.FunctionComponent<React.PropsWithChildren<HardwareInfoCardProps>> = ({
  backgroundColor,
  shadow = 'sm',
  padding = 'md',
  header,
  actionButtons,
  actionButtonTexts,
  children,
}) => (
  <Card
    css={css`
      height: 20.875rem;
    `}
    backgroundColor={backgroundColor}
    shadow={shadow}
    padding={padding}
    header={header}
    actionBarComponent={
      actionButtons && actionButtonTexts
        ? {
            left: (
              <React.Fragment>
                {actionButtons.map((button, index) => (
                  <Button
                    key={actionButtonTexts[index]}
                    {...button}
                  >
                    {actionButtonTexts[index]}
                  </Button>
                ))}
              </React.Fragment>
            ),
          }
        : undefined
    }
  >
    {children}
  </Card>
);

export default HardwareInfoCard;
