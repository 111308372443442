import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import useInitiativeAssessmentQuestionListContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-assessment-section/context/hooks/useInitiativeAssessmentQuestionListContext';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import Button from '~/neo-ui/packages/button/Button';
import getDetailsByAssessmentCriterionLabelEnum from '~/wm/packages/assessment/functions/getDetailsByAssessmentCriterionLabelEnum';
import AssessmentCriterionLabelEnum from '~/wm/packages/assessment/types/AssessmentCriterionLabelEnum';
import Badge from '~/neo-ui/packages/badge/Badge';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';
import formatUserNameDisplay from '~/wm/packages/account/packages/user/packages/active-users-search/formatUserNameDisplay';
import useInitiativeAssessmentQuestionUnlink from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-assessment-section/hooks/useInitiativeAssessmentQuestionUnlink';

import routes from '~/router/types/routes';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';

const InitiativeSaveAssessmentSection = () => {
  const { assessmentQuestions } = useInitiativeAssessmentQuestionListContext();
  const { unlinkInitiative, isUnlinkingInitiative } = useInitiativeAssessmentQuestionUnlink();
  const { organizationId } = useOrganizationContext();

  if (typeof assessmentQuestions === 'undefined' || assessmentQuestions.length === 0) {
    return null;
  }

  return (
    <Box
      padding={'padding200'}
      borderRadius={'radius400'}
      boxShadow={'shadow100'}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.935rem;
      `}
    >
      <Header
        size={3}
        weight={'bold'}
      >
        Linked assessment items
      </Header>
      {assessmentQuestions.map(assessmentQuestion => (
        <Box
          key={assessmentQuestion.assessmentEvaluationQuestionId}
          padding={'padding350'}
          borderRadius={'radius400'}
          theme={'none'}
          background={'foregroundAccent'}
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.935rem;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              gap: 1rem;
            `}
          >
            <ButtonLink
              iconRight={'GoExternal'}
              anchor={{
                href: `${routes.ORGANIZATION_ASSESSMENT_EVALUATION_PERFORM.build([
                  organizationId,
                  assessmentQuestion.assessmentEvaluationId,
                ])}#${assessmentQuestion.assessmentEvaluationQuestionId}`,
                openInNewTab: true,
              }}
            >
              {assessmentQuestion.title}
            </ButtonLink>
            <Button
              iconRight={'Remove'}
              loading={isUnlinkingInitiative}
              onClick={() => unlinkInitiative({ initiativeLinkId: assessmentQuestion.initiativeLinkId, organizationId })}
            />
          </div>
          {assessmentQuestion.selectedCriterionLabelKey && (
            <Box
              padding={'padding200'}
              borderRadius={'radius400'}
              css={css`
                display: flex;
                flex-direction: column;
                gap: 0.935rem;
              `}
            >
              <Badge
                bgColor={
                  getDetailsByAssessmentCriterionLabelEnum(assessmentQuestion.selectedCriterionLabelKey as AssessmentCriterionLabelEnum, '')
                    .backgroundColor
                }
                textColor={
                  getDetailsByAssessmentCriterionLabelEnum(assessmentQuestion.selectedCriterionLabelKey as AssessmentCriterionLabelEnum, '')
                    .textColor
                }
                borderRadius={'radius200'}
                css={css`
                  width: min-content;
                `}
              >
                {assessmentQuestion.selectedCriterionDisplayLabel}
              </Badge>
              {assessmentQuestion.selectedCriterionDescription && (
                <Label
                  size={'md'}
                  color={'dark-900-32'}
                >
                  {assessmentQuestion.selectedCriterionDescription}
                </Label>
              )}
            </Box>
          )}
          {assessmentQuestion.publicComment && assessmentQuestion.publicCommentCreateUser && (
            <Box
              padding={'padding200'}
              borderRadius={'radius400'}
              css={css`
                display: flex;
                flex-direction: column;
                gap: 0.935rem;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  gap: 1rem;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: row;
                    gap: 0.5rem;
                    align-items: center;
                  `}
                >
                  <Icon
                    sizePx={16}
                    icon={'Group'}
                  />
                  <Header
                    size={5}
                    weight={'bold'}
                    color={'dark-900-32'}
                  >
                    Public comment
                  </Header>
                </div>
                <Header
                  size={6}
                  weight={'bold'}
                  color={'dark-900-32'}
                >
                  BY {formatUserNameDisplay(assessmentQuestion.publicCommentCreateUser)}
                </Header>
              </div>
              <Label
                size={'md'}
                color={'dark-900-32'}
              >
                {assessmentQuestion.publicComment}
              </Label>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default InitiativeSaveAssessmentSection;
