import { Styleable } from '~/neo-ui/model/capacity';
import { ReactNode } from 'react';
import usePanelTabGroupContext from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/hooks/usePanelTabGroupContext';
import { css, SerializedStyles } from '@emotion/react';
import PanelTab, { PanelTabProps } from '~/neo-ui/packages/panel/packages/PanelTabGroup/panel-Tab/PanelTab';
import Divider from '~/neo-ui/packages/divider/Divider';

export type PanelTabInfo<T> = {
  id: T;
  children: ReactNode;
  onSelect?: () => void;
} & PanelTabProps;

export type PanelTabGroupInternalProps<T> = {
  panelTabs: Omit<PanelTabInfo<T>, 'isTabSelected'>[];
  panelContentCss?: SerializedStyles;
} & Styleable;

const PanelTabGroupInternal = <T,>({ panelTabs, panelContentCss, className }: PanelTabGroupInternalProps<T>) => {
  const { currentTabId, setCurrentTabId } = usePanelTabGroupContext<T>();

  const panelContent = panelTabs.find(tab => tab.id === currentTabId);

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 1.5rem;
        `}
      >
        {panelTabs.map((tab, index) => (
          <PanelTab
            key={index}
            title={tab.title}
            titleIcon={tab.titleIcon}
            theme={tab.theme}
            isTabSelected={tab.id === currentTabId}
            onSelect={() => {
              if (typeof tab.onSelect !== 'undefined') {
                tab.onSelect();
              }
              setCurrentTabId(tab.id);
            }}
          />
        ))}
      </div>
      <Divider heightRem={0.0625} />
      <div
        css={[
          panelContentCss,
          css`
            margin-top: 1rem;
          `,
        ]}
      >
        {panelContent?.children}
      </div>
    </div>
  );
};

export default PanelTabGroupInternal;
