import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';

type InitiativeAssetInfoCardProps = {
  assetCount: number;
  insightName?: string;
};

const InitiativeAssetInfoCard = ({ assetCount, insightName }: InitiativeAssetInfoCardProps) => (
  <Card
    css={css`
      border-radius: 0.1875rem;
    `}
    backgroundColor={'secondary-050'}
    padding={'sm'}
    shadow={'none'}
  >
    <div
      css={css`
        display: grid;
        grid-template-columns: min-content auto;
        align-items: center;
        gap: 0.8125rem;
        margin: 0.125rem 0 0.125rem 0;
      `}
    >
      <Icon
        icon={'Asset'}
        sizePx={22}
      />
      <div>
        <Label
          size={'md'}
          bold={true}
        >
          {`${assetCount} asset${assetCount > 1 ? 's' : ''} selected`}
        </Label>
        {insightName && <Label size={'md'}>From {insightName}</Label>}
      </div>
    </div>
  </Card>
);

export default InitiativeAssetInfoCard;
