import { ConsoleState } from '~/neo-ui/packages/table/packages/console/types';
import { useCallback, useEffect, useState } from 'react';
import useItemSelection from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/hooks/useItemSelection';
import {
  getDefaultColumnsFromUrl,
  getSearchFromUrl,
  getUrlParams,
} from '~/neo-ui/packages/table/packages/url-routing/console/LoadUrlConsoleState';
import { updateUrlWithUrlParams } from '~/neo-ui/packages/table/packages/url-routing/updateUrlParameter';
import { ColumnAvailabilityDto, ConsoleResponseDto } from '@AssetManagementClient/BeastClient/Search/Model/Console/Dto.gen';
import { DisplayFeaturesEnum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/AssetScope/Model/AssetScopeNested.gen';

export const buildUrlParameterFromMap = (
  parametersMap: Map<string, Map<string, string[]>>,
  ignoredUrlParameters: Set<string>,
): URLSearchParams => {
  const currentParameters = new URLSearchParams(window.location.search);
  const urlParams = new URLSearchParams();
  ignoredUrlParameters.forEach(value => {
    if (currentParameters.has(value)) {
      urlParams.set(value, currentParameters.get(value)!);
    }
  });
  parametersMap.forEach((tagMap, paramKey) => {
    urlParams.set(
      paramKey,
      Array.from(tagMap)
        .map(([key, value]) => `${key}:${value.join(',')}`)
        .join(';'),
    );
  });
  return urlParams;
};

export const columnsParameter = 'Columns';
export const searchParameter = 'Search';

export type ResponseTemplate = {
  result: ConsoleResponseDto;
  displayFeatures: DisplayFeaturesEnum[];
};

type ReloadRows<TResponse extends ResponseTemplate> = (consoleState: ConsoleState, filterFrame: object) => Promise<TResponse | undefined>;

const useConsole = <TResponse extends ResponseTemplate>(
  reloadConsoleRows: ReloadRows<TResponse>,
  defaultFilterFrame: object,
  ignoredUrlParameters: string[] = [],
  onResponse: (response: TResponse | undefined) => void = () => {},
) => {
  const urlColumns = getDefaultColumnsFromUrl();
  const urlConsoleState = {
    pagination: {
      pageNumber: 0,
      perPageSize: 50,
    },
    sort: undefined,
    parameters: getUrlParams(ignoredUrlParameters),
    search: getSearchFromUrl(urlColumns),
  };

  const [filterFrame, setFilterFrame] = useState<object>(defaultFilterFrame);

  const [displayFeatures, setDisplayFeatures] = useState<Set<DisplayFeaturesEnum> | undefined>(undefined);

  const [consoleState, setConsoleState] = useState<ConsoleState>(urlConsoleState);
  const selectedConsoleItems = useItemSelection<string>();

  const [defaultColumns, setDefaultColumns] = useState<Set<string> | undefined>(undefined);

  const [columns, setColumns] = useState<Set<string> | undefined>(urlColumns ?? defaultColumns);

  const [consoleResponse, setConsoleResponse] = useState<TResponse | undefined>(undefined);

  // Update the URL if the parameter changes
  useEffect(() => {
    if (!('URLSearchParams' in window)) {
      // Supported by modern browsers
      return;
    }

    const urlParams = buildUrlParameterFromMap(consoleState.parameters, new Set(ignoredUrlParameters));

    if (typeof columns !== 'undefined' && columns.size > 0) {
      urlParams.set(columnsParameter, Array.from(columns).join(','));
    }

    if (typeof consoleState.search !== 'undefined' && consoleState.search.value !== '') {
      urlParams.set(searchParameter, consoleState.search.value);
    }

    updateUrlWithUrlParams(urlParams);
  }, [consoleState, ignoredUrlParameters, columns]);

  const reloadRows: ReloadRows<TResponse> = useCallback(
    async (consoleState, context) => {
      const response = await reloadConsoleRows(consoleState, context);
      onResponse(response);
      if (typeof response !== 'undefined') {
        setConsoleResponse(response);
      }
      return response;
    },
    [onResponse, reloadConsoleRows, setConsoleResponse],
  );

  /**
   * When we need to set defaults after the first console API call, we can do so here
   *****/
  useEffect(() => {
    if (typeof defaultColumns === 'undefined' && typeof consoleResponse !== 'undefined') {
      const newDefaultColumns = new Set<string>(
        consoleResponse.result.columns
          .filter(column => column.availability === ColumnAvailabilityDto.Default)
          .map(column => column.key.value),
      );
      setDefaultColumns(newDefaultColumns);
      if (typeof columns === 'undefined') {
        setColumns(newDefaultColumns);
      }
    }
  }, [columns, defaultColumns, consoleResponse]);

  useEffect(() => {
    if (typeof displayFeatures === 'undefined' && typeof consoleResponse !== 'undefined') {
      setDisplayFeatures(new Set(consoleResponse.displayFeatures));
    }
  }, [displayFeatures, setDisplayFeatures, consoleResponse]);

  return {
    providerValue: {
      consoleState,
      setConsoleState,
      columns,
      setColumns,
      filterFrame,
      setFilterFrame,
      selectedConsoleItems,
      defaultColumns,
    },
    reloadRows,
    displayFeatures,
  };
};

export default useConsole;
