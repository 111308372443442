import { createContext } from 'react';

export type DisposalTreeImpactAccountSummaryContextData = {
  treesPerOrderUnit: number;
  totalTreesPlanted: number;
  totalOrdersUnits: number;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const DisposalTreeImpactAccountSummaryContext = createContext<DisposalTreeImpactAccountSummaryContextData | undefined>(undefined);

export default DisposalTreeImpactAccountSummaryContext;
