import { initiativeEngagementActionLinkBulk } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { useCallback, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';

type EngagementActionLinkBulkFunction = (initiativeId: string, engagementActionIds: string[]) => void;

type UseInitiativeEngagementActionLinkBulk = (onSuccessAction: () => void) => {
  isLinking: boolean;
  linkEngagementActions: EngagementActionLinkBulkFunction;
};

const useInitiativeEngagementActionLinkBulk: UseInitiativeEngagementActionLinkBulk = onSuccessAction => {
  const { callApi } = useApi();
  const [isLinking, setLinking] = useState(false);
  const { organizationId } = useOrganizationContext();

  const linkEngagementActions = useCallback<EngagementActionLinkBulkFunction>(
    async (initiativeId, engagementActionIds) => {
      setLinking(true);

      const response = await callApi(() =>
        initiativeEngagementActionLinkBulk({
          initiativeId,
          engagementActionIds,
          organizationId,
        }),
      );

      if (!response) {
        return;
      }

      setLinking(false);

      if (typeof onSuccessAction !== 'undefined') {
        onSuccessAction();
      }
    },
    [callApi, onSuccessAction, organizationId],
  );

  return {
    isLinking,
    linkEngagementActions,
  };
};

export default useInitiativeEngagementActionLinkBulk;
