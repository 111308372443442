import WizardPageStep from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageStep';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { BillingInfo as BillingInfoType } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import WarrantyTransferFormData from '~/wm/packages/warranty/packages/coverage/packages/coverage-transfer/types/WarrantyTransferFormData';
import OriginalSection from '~/wm/packages/warranty/packages/coverage/packages/coverage-transfer/wizard/section/OriginalSection';
import ServiceRequest from '~/wm/packages/warranty/packages/service-request/model/ServiceRequest';
import ReturnAddressSection from '~/wm/packages/disposal/wizard/section/ReturnAddressSection';

const returnShippingAddressFieldKey: FieldKeyExpression<WarrantyTransferFormData> = values => values.shipmentReturnAddress.billingAddress;

const addressIsValidFieldKey: FieldKeyExpression<WarrantyTransferFormData> = values => values.certification.addressIsValid;

const locationCertifiedFieldKey: FieldKeyExpression<WarrantyTransferFormData> = values => values.certification.locationCertified;

const phoneNumberFieldKey: FieldKeyExpression<WarrantyTransferFormData> = values => values.phoneNumber;

const phoneNumberIsValidKey: FieldKeyExpression<WarrantyTransferFormData> = values => values.isPhoneNumberValid;

const getWarrantyTransferDisposalStep = (
  serviceRequest: ServiceRequest,
  billingInfo: BillingInfoType,
): WizardPageStep<WarrantyTransferFormData> => ({
  header: {
    label: 'Disposal',
    icon: 'Disposal',
  },
  fields: [returnShippingAddressFieldKey, addressIsValidFieldKey, locationCertifiedFieldKey, phoneNumberFieldKey],
  sections: [
    () => OriginalSection({ serviceRequest, currentStepIndex: 1 }),
    ({ isEditingIndex, onEditing }) =>
      ReturnAddressSection({
        billingInfo,
        returnShippingAddressFieldKey,
        addressIsValidFieldKey,
        locationCertifiedFieldKey,
        isEditingIndex,
        onEditing,
        phoneNumberFieldKey,
        phoneNumberIsValidKey,
      }),
  ],
  onCompleteStateChange: () => true,
  key: 'Disposal',
});

export default getWarrantyTransferDisposalStep;
