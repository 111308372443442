import React, { useCallback } from 'react';
import Form from '~/neo-ui/packages/form/Form';
import WarrantyCartOpportunitiesFormData from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-opportunity/packages/warranty-cart-opportunity-form/WarrantyCartOpportunitiesFormData';

import useOpportunitiesMutationContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useOpportunitiesMutationContext';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import warrantyCartOpportunitiesFormValidationSchema from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-opportunity/packages/warranty-cart-opportunity-form/warrantyCartOpportunitiesFormValidationSchema';

export type WarrantyCartOpportunitiesFormProps = {
  children: React.ReactNode;
};

const WarrantyCartOpportunitiesForm: React.FunctionComponent<WarrantyCartOpportunitiesFormProps> = ({ children }) => {
  const { markupPercentage, coTermination, duration } = useWarrantyCartContext();
  const { triggerUpsert } = useOpportunitiesMutationContext();

  const onSubmit = useCallback((data: WarrantyCartOpportunitiesFormData) => triggerUpsert(data), [triggerUpsert]);

  const toFormData = useCallback(
    (markup: number, coTermination: boolean, durationMonth: string): WarrantyCartOpportunitiesFormData => ({
      markup,
      coTermination,
      durationMonth,
    }),
    [],
  );

  const defaultFormData = React.useMemo(
    () => toFormData(markupPercentage, coTermination, duration),
    [coTermination, duration, markupPercentage, toFormData],
  );

  return (
    <Form
      submitMethod={'auto'}
      defaultFormData={defaultFormData}
      onSubmit={onSubmit}
      disableOverwriteDefaultFormDataOnSave={true}
      hideSubmitStatusIndicator={true}
      validationSchema={warrantyCartOpportunitiesFormValidationSchema}
    >
      {children}
    </Form>
  );
};

export default WarrantyCartOpportunitiesForm;
