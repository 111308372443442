import * as React from 'react';
import ActionButton from '~/neo-ui/packages/button/packages/action-button/ActionButton';

export type ShareButtonProps = {
  onClick?: () => void;
  tooltipText: string;
};

/**
 * @deprecated Use Button instead, which implements ButtonInternal
 */
const ShareButton: React.FunctionComponent<ShareButtonProps> = ({ onClick, tooltipText }) => (
  <ActionButton
    icon={'Share'}
    activeBackgroundColor={'primary-400'}
    tooltipContent={tooltipText}
    onClick={onClick}
  />
);
export default ShareButton;
