import { css } from '@emotion/react';
import * as React from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import useContractDelete from '../../../../../../hooks/useContractDelete';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';

export type ContractDeleteModalProps = {
  contractId: string;
  organizationId: string;
  isOpen: boolean;
  onDismiss: () => void;
};

const ContractDeleteModal = ({ contractId, organizationId, isOpen, onDismiss }: ContractDeleteModalProps) => {
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);
  const resetModal = () => {
    onDismiss();
  };

  const confirmModal = () => {
    deleteContract(contractId);
    trackEventProcessor('contract_delete_save', {});
  };

  const { deleteContract, isDeletingContract } = useContractDelete(organizationId, { onSuccess: resetModal });

  return (
    <ModalConfirmation
      header={{
        title: 'Delete this contract',
        icon: 'Page',
      }}
      theme={'danger'}
      footer={{
        confirmButton: {
          icon: 'Delete',
          label: 'Delete Contract',
          loading: isDeletingContract,
          onClick: confirmModal,
        },
        dismissButton: {
          icon: 'Cancel',
          label: 'Cancel',
          disabled: isDeletingContract,
          onClick: resetModal,
        },
      }}
      isOpen={isOpen}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.625rem;
        `}
      >
        Would you like to delete this Contract?
        <Label muted={true}>This contract can be recycled afterwards.</Label>
      </div>
    </ModalConfirmation>
  );
};

export default ContractDeleteModal;
