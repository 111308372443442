import { css } from '@emotion/react';
import { subscriptionGrow } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import * as React from 'react';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import WizardContainer from '~/neo-ui/packages/wizard/packages/wizard-container/WizardContainer';
import useApi from '~/wm/packages/api/hook/useApi';
import useBillingInfo from '~/wm/packages/subscription/packages/billing/hooks/useBillingInfo';
import useSubscriptionGrowAvailabilities from '~/wm/packages/subscription/packages/grow/packages/grow-availabilities/hooks/useSubscriptionGrowAvailabilities';
import getGrowSubscriptionAgreementStep from '~/wm/packages/subscription/packages/grow/wizard/step/getGrowSubscriptionAgreementStep';
import SubscriptionUpdateSuccess from '~/wm/packages/subscription/packages/grow/wizard/success/SubscriptionUpdateSuccess';
import SubscriptionGrowBillingSummary from '~/wm/packages/subscription/packages/grow/wizard/summary/SubscriptionGrowBillingSummary';
import SubscriptionConfirmation from '~/wm/packages/subscription/packages/manage/wizard/step/confirmation/SubscriptionConfirmation';
import { ProductRegions, ProductTierIds } from '~/wm/packages/subscription/packages/upgrade/wizard/SubscriptionUpgradeWizard';
import { SubscriptionAgreementData } from '~/wm/packages/subscription/packages/manage/wizard/step/getSubscriptionAgreementStep';
import { SubscriptionUpdateSuccessDto } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/Success.gen';
import { SelectedProductRegion } from '@SubscriptionClient/Subscription/Packages/Upgrade/Model.gen';

export interface GrowFormData extends SubscriptionAgreementData {}

export type GrowWizardProps = {
  /**
   *  Url of subscription agreement page
   */
  subscriptionAgreementUrl: string;

  /**
   * The abbreviation of the product that should be preselected for the partner in the form
   */
  preselectProduct: string | undefined;
};

const SubscriptionGrowWizard: React.FunctionComponent<GrowWizardProps> = ({ subscriptionAgreementUrl, preselectProduct }) => {
  const { callApi } = useApi();

  const { growAvailabilities } = useSubscriptionGrowAvailabilities(preselectProduct);
  const { billingInfo } = useBillingInfo();
  const [successInfo, setSuccessInfo] = React.useState<SubscriptionUpdateSuccessDto | undefined>();

  if (!billingInfo || !growAvailabilities) {
    return null;
  }

  const defaultTierIds: ProductTierIds = {};
  const defaultProductRegions: ProductRegions = {};
  growAvailabilities.productAvailabilityPayloads.forEach(availableTiers => {
    defaultTierIds[availableTiers.productEnum.toString()] = '';
  });

  const defaultFormData: GrowFormData = {
    selectedTierIds: defaultTierIds,
    selectedProductRegions: defaultProductRegions,
  };

  const subscriptionAgreementLink: AnchorLocation = {
    href: subscriptionAgreementUrl,
    openInNewTab: true,
  };

  return (
    <WizardContainer
      css={css`
        margin-top: 0.9375rem;
      `}
      theme={'secondary-600'}
      defaultFormData={defaultFormData}
      steps={[getGrowSubscriptionAgreementStep(growAvailabilities, subscriptionAgreementLink)]}
      summaryHeaderLabel={"Here's your new subscription"}
      summaryHeaderDescription={'Manage it at any time from Billing.'}
      summaryLabel={'subscription details'}
      additionalSummaries={[
        {
          label: 'Billed to',
          content: <SubscriptionGrowBillingSummary billingInfo={billingInfo} />,
        },
      ]}
      submitLabel={'Grow my subscription now'}
      onSubmit={async data => {
        const response = await callApi(
          () => {
            const selectedTiers = Array<string>();
            Object.keys(data.selectedTierIds).forEach(product => {
              const tierId = data.selectedTierIds[product];
              if (tierId !== undefined && tierId !== '') {
                selectedTiers.push(tierId);
              }
            });
            const selectedRegions = Array<SelectedProductRegion>();
            Object.keys(data.selectedProductRegions).map(product => {
              const isProductAdded = data.selectedTierIds[product] !== '';
              const regionValue = data.selectedProductRegions[product];
              if (regionValue !== undefined && isProductAdded && regionValue !== 'UNSELECTED') {
                selectedRegions.push({
                  product,
                  selectedRegionValue: regionValue,
                });
              }
            });
            return subscriptionGrow({
              subscriptionGrowPayload: {
                productTierIds: selectedTiers,
                selectedProductRegions: selectedRegions,
              },
            });
          },
          error => {
            throw error;
          },
        );
        if (!response) {
          return 'error';
        }
        setSuccessInfo(response.successDto);

        return 'success';
      }}
      ConfirmationComponent={({ isConfirmed, toggleConfirmation }) => (
        <SubscriptionConfirmation
          finalPriceBeforeTaxesCents={undefined}
          billingPeriodEnum={growAvailabilities?.billing.periodEnum}
          hasDiscount={false}
          isConfirmed={isConfirmed}
          toggleConfirmation={toggleConfirmation}
        />
      )}
      SuccessComponent={() => <SubscriptionUpdateSuccess successDto={successInfo} />}
    />
  );
};

export default SubscriptionGrowWizard;
