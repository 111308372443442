import * as React from 'react';
import { css } from '@emotion/react';
import AnimationPresence from '~/neo-ui/packages/animation/packages/animate-presence/AnimationPresence';
import Button from '~/neo-ui/packages/button/Button';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { borderRadiusToCode } from '~/neo-ui/packages/style/BorderRadius';
import EngagementActionCreateTicketCreateButton from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-ticket/packages/engagement-action-create-ticket-create-button/EngagementActionCreateTicketCreateButton';
import useTicketFieldAvailabilitiesContext from '~/wm/packages/integration/packages/ticket/context/hooks/useTicketFieldAvailabilitiesContext';
import { InitiativeTemplateDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/InitiativeTemplate/Dto/Model.gen';
import EngagementActionInitiativeLinkCreateButton from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-initiative/packages/engagement-action-initiative-link-create-button/EngagementActionInitiativeLinkCreateButton';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';

export type EngagementActionDashboardCreateControlsToolbarProps = {
  isInitiative: boolean;
  isDisplayingControls: boolean;
  onDeleteAction: () => void;
  // Tickets
  onCreateTicket: () => void;
  onCreatingTicket: () => void;
  onCancelTicket: () => void;
  // Initiatives
  onCreatingInitiativeLink: () => void;
  onLinkExistingInitiative: (initiativeId: string) => void;
  onLinkNewInitiative: (selectedTemplate: InitiativeTemplateDto | undefined) => void;
};

const EngagementActionDashboardCreateControlsToolbar = ({
  isInitiative,
  isDisplayingControls,
  onDeleteAction,
  onCreateTicket,
  onCreatingTicket,
  onCancelTicket,
  onCreatingInitiativeLink,
  onLinkExistingInitiative,
  onLinkNewInitiative,
}: EngagementActionDashboardCreateControlsToolbarProps) => {
  const { isOrganizationRegisteredForTickets, hasTicketIntegration, ticketFieldOptions } = useTicketFieldAvailabilitiesContext();
  const { 'lm-action-item-link-initiative': enabledInitiativeActionItem } = useFeatureFlagProvider();

  return (
    <AnimationPresence isPresent={isDisplayingControls}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          min-width: 0;
          padding: 0.5rem;
          gap: 0.5rem;
          background-color: ${colorToCode('light-300')};
          border-radius: ${borderRadiusToCode('radius300')};
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
          `}
        >
          {!isInitiative && enabledInitiativeActionItem && (
            <EngagementActionInitiativeLinkCreateButton
              size={'xs'}
              onClick={onCreatingInitiativeLink}
              onLinkExistingInitiative={onLinkExistingInitiative}
              onLinkNewInitiative={onLinkNewInitiative}
            />
          )}

          <EngagementActionCreateTicketCreateButton
            ticketFieldOptions={ticketFieldOptions}
            isOrganizationRegisteredForTickets={isOrganizationRegisteredForTickets}
            hasTicketIntegration={hasTicketIntegration}
            onClick={onCreatingTicket}
            onCreateTicket={onCreateTicket}
            onCancelTicket={onCancelTicket}
          />
          <Button
            size={'xs'}
            iconLeft={'Delete'}
            onClick={onDeleteAction}
          />
        </div>
      </div>
    </AnimationPresence>
  );
};

export default EngagementActionDashboardCreateControlsToolbar;
