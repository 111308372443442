import React from 'react';
import ComponentPagination from '~/neo-ui/packages/navigation/packages/component-pagination/ComponentPagination';
import useRoadmapInitiativesContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapInitiativesContext';
import { css } from '@emotion/react';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';

export type RoadmapYearPaginationProps = {
  /**
   * Display the button as left-aligned text
   */
  leftAlign?: boolean;
};
/**
 * Provides left and right navigation of a roadmap's years
 */
const RoadmapYearPagination = ({ leftAlign = false }: RoadmapYearPaginationProps) => {
  const { onDisplayFiscalQuartersChange, fiscalQuarterAvailabilities } = useRoadmapInitiativesContext();

  const { currentFiscalQuarter } = useRegionalSettingsInfoContext();

  const [startingOption, setStartingOption] = React.useState({
    id: 1,
    year: currentFiscalQuarter.year,
  });

  // Reduce fiscalQuarterAvailabilities to only unique years using a Set
  const uniqueYears = new Set(fiscalQuarterAvailabilities.map(fiscalQuarter => fiscalQuarter.year));

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 0.3125rem;
      `}
    >
      <ComponentPagination
        startingOption={startingOption}
        options={Array.from(uniqueYears).map((year, index) => ({
          option: { id: index, year },
          label: `${year}`,
        }))}
        onClick={option => onDisplayFiscalQuartersChange({ year: option.year, quarter: 1 })}
      />
      <ButtonLink
        iconLeft={'CurrentCalendarQuarter'}
        leftAlign={leftAlign}
        onClick={() => {
          onDisplayFiscalQuartersChange({ year: startingOption.year, quarter: 1 });
          setStartingOption({ id: 1, year: currentFiscalQuarter.year });
        }}
      >
        {startingOption.year}
      </ButtonLink>
    </div>
  );
};

export default RoadmapYearPagination;
