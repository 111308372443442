import * as React from 'react';
import useRoadmapUrlContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapUrlContext';
import FeatureAccessBanner from '~/wm/packages/feature/packages/feature-access-banner/FeatureAccessBanner';
import useSubscriptionDetailsPlan from '~/wm/packages/subscription/packages/subscription-details/hooks/useSubscriptionDetailsPlan';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useFeatureAccessOrganizationUnlockClientFocusScorecardRoadmap from '~/wm/packages/feature/hooks/useFeatureAccessOrganizationUnlockClientFocusScorecardRoadmap';

const FeatureAccessBannerRoadmap = () => {
  const featureKey = 'scorecard-roadmap';
  const imageSrc = '/i/marketing/roadmaps/image.png';
  const { upgradeUrl, growUrl } = useRoadmapUrlContext();
  const { detailsPlan } = useSubscriptionDetailsPlan();
  const { organizationName } = useOrganizationContext();
  const { organizationUnlock, isUnlocking } = useFeatureAccessOrganizationUnlockClientFocusScorecardRoadmap({ reloadOnSuccess: true });

  // Don't show the a banner if we don't have enough information
  if (typeof detailsPlan === 'undefined') {
    return null;
  }

  const unlockBannerDetails = {
    title: `Want to give ${organizationName} a better experience?`,
    description: 'Unlock new features for simplified and strategic client engagement. Try it free for one client.',
  };

  if (detailsPlan.planEdition === 'Free') {
    return (
      <FeatureAccessBanner
        featureKey={featureKey}
        unlockBannerDetails={unlockBannerDetails}
        upgradeBannerDetails={{
          title: 'See projects up to 5 years ahead for all your clients.',
          description:
            "Upgrading to a Pro plan gives you full access to Roadmaps to visualize all of your clients' requirements, timelines, and budgets.",
        }}
        imageSrc={imageSrc}
        upgradeUrl={upgradeUrl}
        isUnlocking={isUnlocking}
        onOrganizationUnlock={(_, organizationId) => organizationUnlock({ organizationId })}
      />
    );
  }

  if (
    (detailsPlan.generation === 1 || detailsPlan.generation === 2 || detailsPlan.generation === 3) &&
    typeof detailsPlan.term === 'undefined'
  ) {
    return (
      <FeatureAccessBanner
        featureKey={featureKey}
        unlockBannerDetails={unlockBannerDetails}
        upgradeBannerDetails={{
          title: 'See projects up to 5 years ahead for all your clients.',
          description:
            "Upgrading to the latest version gives you full access to Roadmaps to visualize all of your clients' requirements, timelines, and budgets.",
        }}
        imageSrc={imageSrc}
        upgradeUrl={growUrl}
        isUnlocking={isUnlocking}
        onOrganizationUnlock={(_, organizationId) => organizationUnlock({ organizationId })}
      />
    );
  }

  return null;
};

export default FeatureAccessBannerRoadmap;
