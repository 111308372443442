import { LifecycleStatusMetadata } from '@AssetManagementClient/BeastClient/Beast/Asset/Model/Device/HardwareStatusType/LifecycleMetadataNested.gen';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import StackedBarChart, { Bar } from '~/neo-ui/packages/chart/packages/bar/packages/stacked/StackedBarChart';

export type LifecycleStatusBarProps<T extends string> = {
  countByStatusEnum: { [key in T]: number };
  metadataByStatusEnum: { [key in T]: LifecycleStatusMetadata };
} & Styleable;

const LifecycleStatusBar = <T extends string>({ className, countByStatusEnum, metadataByStatusEnum }: LifecycleStatusBarProps<T>) => {
  const lifecycleMetadata = React.useMemo(() => {
    const lifecycleMetadata = [];
    for (const statusEnum in metadataByStatusEnum) {
      if (metadataByStatusEnum.hasOwnProperty(statusEnum)) {
        lifecycleMetadata.push({
          enum: statusEnum,
          metadata: metadataByStatusEnum[statusEnum],
        });
      }
    }
    return lifecycleMetadata;
  }, [metadataByStatusEnum]);

  const bars = React.useMemo(
    () =>
      lifecycleMetadata
        .sort((a, b) => (a.metadata.order > b.metadata.order ? 1 : a.metadata.order === b.metadata.order ? 0 : -1))
        .map<Bar>(lifecycleStatus => ({
          value: typeof countByStatusEnum[lifecycleStatus.enum] !== 'undefined' ? countByStatusEnum[lifecycleStatus.enum] : 0,
          color: lifecycleStatus.metadata.color,
        })),
    [lifecycleMetadata, countByStatusEnum],
  );

  return (
    <div className={className}>
      <StackedBarChart
        height={'0.325rem'}
        bars={bars}
      />
    </div>
  );
};

export default LifecycleStatusBar;
