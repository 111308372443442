import React, { ReactNode } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type SwapContentRowProps = {
  leftContent: ReactNode;
  rightContent: ReactNode;
  rowStyles?: SerializedStyles;
};

export const SwapContentHeader: React.FunctionComponent<SwapContentRowProps> = ({ leftContent, rightContent, rowStyles }) => (
  <li
    css={css`
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 0;

      // Remove list indentation
      list-style-type: none;

      ${rowStyles};
    `}
  >
    {leftContent}
    {rightContent}
  </li>
);

const SwapContentRow: React.FunctionComponent<SwapContentRowProps> = ({ leftContent, rightContent, rowStyles }) => {
  const mergedRowStyles = css`
    border-bottom: 0.0625rem solid ${colorToCode('dark-900-08')};
    ${rowStyles};
  `;
  return (
    <SwapContentHeader
      leftContent={leftContent}
      rightContent={rightContent}
      rowStyles={mergedRowStyles}
    />
  );
};
export default SwapContentRow;
