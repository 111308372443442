import * as React from 'react';
import CardSuccess from '~/legacy-ui/packages/card/packages/card-success/CardSuccess';
import { SubscriptionUpdateSuccessDto } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/Success.gen';

export type SubscriptionGrowSuccessProps = {
  successDto: SubscriptionUpdateSuccessDto | undefined;
};

/**
 * This component is shared between subscription upgrade and grow
 */
const SubscriptionUpdateSuccess: React.FunctionComponent<SubscriptionGrowSuccessProps> = ({ successDto }) =>
  successDto ? (
    <CardSuccess
      title={successDto.title}
      description={successDto.description}
      actionUrl={successDto.callToActionUrl}
      actionDescription={successDto.callToActionLabel}
      openInNewTab={successDto.openUrlInNewTab}
      imageSource={successDto.imageSource}
    />
  ) : (
    <div />
  );

export default SubscriptionUpdateSuccess;
