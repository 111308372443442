import { hardwareConsoleOrganizationPdf } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import React, { useContext } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { FieldKey as AssetManagementClientBeastClientPrimitivesQueryFieldKey } from '@AssetManagementClient/BeastClient/Primitives/Query.gen';
import ConsoleContext from '~/neo-ui/packages/table/packages/console/contexts/ConsoleContext';
import buildConsoleRequest from '~/neo-ui/packages/table/packages/console/buildConsoleRequest';
import { AssetFilterFrame } from '~/wm/packages/asset/packages/console/hooks/useAssetConsoleRequest';

type UseDownloadLifecycleReportCustomizedPdf = (frontendScope: FrontendScope) => {
  downloadLifecycleReportCustomized: (pdfName: string) => void;
  isLoading: boolean;
};

export const useDownloadLifecycleReportCustomizedPdf: UseDownloadLifecycleReportCustomizedPdf = (frontendScope: FrontendScope) => {
  const { callApi } = useApi();
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const { consoleState, columns: selectedColumns, filterFrame } = useContext(ConsoleContext);

  const downloadLifecycleReportCustomized = React.useCallback(
    async (pdfName: string) => {
      setLoading(true);

      await callApi(() =>
        hardwareConsoleOrganizationPdf({
          query: buildConsoleRequest(consoleState),
          scope: frontendScope,
          forecast: (filterFrame as AssetFilterFrame).forecast,
          selectedColumns: Array.from(selectedColumns ?? new Set<string>()).map<AssetManagementClientBeastClientPrimitivesQueryFieldKey>(
            value => ({ value }),
          ),
          allColumns: typeof selectedColumns === 'undefined',
          pdfName,
        }),
      );

      setLoading(false);
    },
    [callApi, consoleState, filterFrame, frontendScope, selectedColumns],
  );

  return { downloadLifecycleReportCustomized, isLoading };
};

export default useDownloadLifecycleReportCustomizedPdf;
