import IntegrationDataTypesDisplay from '~/wm/packages/documentation/packages/supported-integrations/packages/IntegrationDataTypesDisplay';
import IntegrationBasicDetailsDisplay from '~/wm/packages/documentation/packages/supported-integrations/packages/IntegrationBasicDetailsDisplay';
import IntegrationFeaturesDisplay from '~/wm/packages/documentation/packages/supported-integrations/packages/IntegrationFeaturesDisplay';
import useSupportedIntegration from '~/wm/packages/documentation/packages/supported-integrations/hooks/useSupportedIntegration';
import { Enum as IntegrationEnum } from '@BeastClient/SyncGod/Shared/Integration/IntegrationFactoryNested.gen';
import * as React from 'react';
import { toEnhancedIntegration } from '~/wm/packages/documentation/packages/supported-integrations/packages/IntegrationDocumentationUtil';
import routes from '~/router/types/routes';

type SupportedIntegrationDetailsPageProps = {
  integrationEnum: IntegrationEnum;
};

const SupportedIntegrationDetailsPage = ({ integrationEnum }: SupportedIntegrationDetailsPageProps) => {
  const { integration } = useSupportedIntegration(integrationEnum);

  if (typeof integration === 'undefined') {
    return <div />;
  }

  const enhancedIntegration = toEnhancedIntegration(integration);

  return (
    <div>
      <h1>{integration.name}</h1>
      <h2>
        <a href={routes.HELP_CENTER_ARTICLE.build([integration.helpCenterUrlPath])}>Help page</a>
      </h2>
      <IntegrationBasicDetailsDisplay integration={enhancedIntegration.integration} />
      <IntegrationFeaturesDisplay integration={enhancedIntegration} />
      <IntegrationDataTypesDisplay dataTypes={enhancedIntegration.integration.types} />
    </div>
  );
};

export default SupportedIntegrationDetailsPage;
