import { ContractAssetInfo } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-link-assets-button/modal/ContractAssetInfo';
import { AssetSearchResponse } from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/hooks/useAssetSearch';

const buildContractAssetInfoFromAssetSearchResult = (assetSearchResults: AssetSearchResponse[]): ContractAssetInfo[] =>
  assetSearchResults.map(resultAsset => ({
    // This is hardcoded to be undefined because assets returned from elastic search are not added to a contract yet
    contractAssetId: undefined,
    assetId: resultAsset.id,
    name: resultAsset.name ?? '-',
    serialNumber: resultAsset.serialNumber,
    modelNumber: resultAsset.modelNumber,
    manufacturerName: resultAsset.manufacturerName,
    user: resultAsset.user,
    purchaseDate: resultAsset.purchaseInfo,
    warrantyExpiration: resultAsset.warrantyInfo,
    // This is hardcoded to be false because assets returned from elastic search are always active
    isDeleted: false,
  }));

export default buildContractAssetInfoFromAssetSearchResult;
