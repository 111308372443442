import { css } from '@emotion/react';
import { Styleable } from '~/neo-ui/model/capacity';
import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Icon from '~/neo-ui/packages/icon/Icon';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';
import TableClientSide, { TableClientSideColumn } from '~/neo-ui/packages/table/packages/table-client-side/TableClientSide';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { SaasAssetDto } from '@BeastClient/Beast/Saas/Packages/SaasAsset/Dto/Model.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import useSaasAssetDashboardList from '~/wm/packages/strategy/packages/strategy-dashboard/packages/saas-asset-dashboard/hooks/useSaasAssetDashboardList';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import ColorApp, { colorAppToColor } from '~/neo-ui/packages/color/packages/color-app/ColorApp';

export type StrategySaasAssetDashboardContainerProps = Styleable;

const StrategySaasAssetDashboardContainer = ({ className }: StrategySaasAssetDashboardContainerProps) => {
  const { organizationId } = useOrganizationContext();
  const [shouldIncludeIsTrialFreeProduct, setShouldIncludeIsTrialFreeProduct] = useState(false);

  const { saasAssetList } = useSaasAssetDashboardList(organizationId, shouldIncludeIsTrialFreeProduct);

  const columns: TableClientSideColumn<SaasAssetDto>[] = useMemo(() => {
    return [
      {
        fieldKey: saasAsset => saasAsset.productName,
        header: 'Product',
        renderCell: ({ original: saasAsset }) => {
          return <Label>{saasAsset.productName}</Label>;
        },
      },
      {
        fieldKey: saasAsset => saasAsset.status.label,
        header: 'Status',
        renderCell: ({ original: saasAsset }) => {
          return (
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              {typeof saasAsset.status.icon !== 'undefined' && (
                <Icon
                  icon={saasAsset.status.icon as IconType}
                  sizePx={16}
                  color={colorAppToColor(saasAsset.status.color as ColorApp)}
                />
              )}
              <Label color={colorAppToColor(saasAsset.status.color as ColorApp)}>{saasAsset.status.label}</Label>
            </div>
          );
        },
      },
      {
        fieldKey: saasAsset => saasAsset.activeCount,
        header: 'Active',
        renderCell: ({ original: saasAsset }) => {
          return <Label muted={saasAsset.activeCount < 1}>{saasAsset.activeCount}</Label>;
        },
      },
      {
        fieldKey: saasAsset => saasAsset.consumedCount,
        header: 'Consumed',
        renderCell: ({ original: saasAsset }) => {
          return <Label muted={saasAsset.activeCount < 1}>{saasAsset.consumedCount}</Label>;
        },
      },
      {
        fieldKey: saasAsset => saasAsset.availableCount,
        header: 'Available',
        renderCell: ({ original: saasAsset }) => {
          return (
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              {saasAsset.availableCount > 0 && (
                <Icon
                  icon={'Done'}
                  sizePx={16}
                  color={'positive-600'}
                />
              )}
              <Label color={saasAsset.availableCount > 0 ? 'positive-600' : 'negative-700'}>{saasAsset.availableCount}</Label>
            </div>
          );
        },
      },
      {
        fieldKey: saasAsset => saasAsset.suspendedCount,
        header: 'Suspended',
        renderCell: ({ original: saasAsset }) => {
          return <Label muted={saasAsset.activeCount < 1}>{saasAsset.suspendedCount}</Label>;
        },
      },
      {
        fieldKey: saasAsset => saasAsset.warningCount,
        header: 'Warning',
        renderCell: ({ original: saasAsset }) => {
          return <Label muted={saasAsset.activeCount < 1}>{saasAsset.warningCount}</Label>;
        },
      },
    ];
  }, []);

  if (typeof saasAssetList === 'undefined') {
    return null;
  }

  return (
    <Box
      boxShadow={'shadow0'}
      padding={'padding300'}
      borderRadius={'radius400'}
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <TableClientSide
        data={saasAssetList}
        columns={columns}
        pagination={{ pageSize: 4, pageIndex: 0 }}
      >
        {({ Table, GlobalFilter, Pagination }) => (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 1rem;
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 1rem;
                align-items: center;
              `}
            >
              <Header
                size={3}
                weight={'medium'}
              >
                Microsoft License Summary
              </Header>
              <Pagination
                css={css`
                  margin-left: auto;
                `}
              />
            </div>
            <div
              css={css`
                display: flex;
                gap: 1rem;
                align-items: center;
              `}
            >
              <GlobalFilter
                css={css`
                  flex-grow: 1;
                `}
                placeholder={'Search...'}
              />
              <InputCheckbox
                css={css`
                  text-wrap: nowrap;
                `}
                checked={shouldIncludeIsTrialFreeProduct}
                onClick={() => setShouldIncludeIsTrialFreeProduct(!shouldIncludeIsTrialFreeProduct)}
                description={'Include Free, Trial and Exploratory Licenses'}
              />
            </div>
            <Table tableHeadColor={'primary-500'} />
          </div>
        )}
      </TableClientSide>
    </Box>
  );
};

export default StrategySaasAssetDashboardContainer;
