import iso31661 from './iso31661';
import iso31662 from './iso31662';

export type Country = { name: string; iso3Code: string; iso2Code: string };

export const countryList: Country[] = [
  {
    iso3Code: 'ALA',
    iso2Code: 'AX',
    name: 'Åland',
  },
  {
    iso3Code: 'AFG',
    iso2Code: 'AF',
    name: 'Afghanistan',
  },
  {
    iso3Code: 'ALB',
    iso2Code: 'AL',
    name: 'Albania',
  },
  {
    iso3Code: 'DZA',
    iso2Code: 'DZ',
    name: 'Algeria',
  },
  {
    iso3Code: 'ASM',
    iso2Code: 'AS',
    name: 'American Samoa',
  },
  {
    iso3Code: 'AND',
    iso2Code: 'AD',
    name: 'Andorra',
  },
  {
    iso3Code: 'AGO',
    iso2Code: 'AO',
    name: 'Angola',
  },
  {
    iso3Code: 'AIA',
    iso2Code: 'AI',
    name: 'Anguilla',
  },
  {
    iso3Code: 'ATA',
    iso2Code: 'AQ',
    name: 'Antarctica',
  },
  {
    iso3Code: 'ATG',
    iso2Code: 'AG',
    name: 'Antigua and Barbuda',
  },
  {
    iso3Code: 'ARG',
    iso2Code: 'AR',
    name: 'Argentina',
  },
  {
    iso3Code: 'ARM',
    iso2Code: 'AM',
    name: 'Armenia',
  },
  {
    iso3Code: 'ABW',
    iso2Code: 'AW',
    name: 'Aruba',
  },
  {
    iso3Code: 'AUS',
    iso2Code: 'AU',
    name: 'Australia',
  },
  {
    iso3Code: 'AUT',
    iso2Code: 'AT',
    name: 'Austria',
  },
  {
    iso3Code: 'AZE',
    iso2Code: 'AZ',
    name: 'Azerbaijan',
  },
  {
    iso3Code: 'BHS',
    iso2Code: 'BS',
    name: 'Bahamas',
  },
  {
    iso3Code: 'BHR',
    iso2Code: 'BH',
    name: 'Bahrain',
  },
  {
    iso3Code: 'BGD',
    iso2Code: 'BD',
    name: 'Bangladesh',
  },
  {
    iso3Code: 'BRB',
    iso2Code: 'BB',
    name: 'Barbados',
  },
  {
    iso3Code: 'BLR',
    iso2Code: 'BY',
    name: 'Belarus',
  },
  {
    iso3Code: 'BEL',
    iso2Code: 'BE',
    name: 'Belgium',
  },
  {
    iso3Code: 'BLZ',
    iso2Code: 'BZ',
    name: 'Belize',
  },
  {
    iso3Code: 'BEN',
    iso2Code: 'BJ',
    name: 'Benin',
  },
  {
    iso3Code: 'BMU',
    iso2Code: 'BM',
    name: 'Bermuda',
  },
  {
    iso3Code: 'BTN',
    iso2Code: 'BT',
    name: 'Bhutan',
  },
  {
    iso3Code: 'BOL',
    iso2Code: 'BO',
    name: 'Bolivia',
  },
  {
    iso3Code: 'BES',
    iso2Code: 'BQ',
    name: 'Bonaire',
  },
  {
    iso3Code: 'BIH',
    iso2Code: 'BA',
    name: 'Bosnia and Herzegovina',
  },
  {
    iso3Code: 'BWA',
    iso2Code: 'BW',
    name: 'Botswana',
  },
  {
    iso3Code: 'BVT',
    iso2Code: 'BV',
    name: 'Bouvet Island',
  },
  {
    iso3Code: 'BRA',
    iso2Code: 'BR',
    name: 'Brazil',
  },
  {
    iso3Code: 'IOT',
    iso2Code: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {
    iso3Code: 'VGB',
    iso2Code: 'VG',
    name: 'British Virgin Islands',
  },
  {
    iso3Code: 'BRN',
    iso2Code: 'BN',
    name: 'Brunei',
  },
  {
    iso3Code: 'BGR',
    iso2Code: 'BG',
    name: 'Bulgaria',
  },
  {
    iso3Code: 'BFA',
    iso2Code: 'BF',
    name: 'Burkina Faso',
  },
  {
    iso3Code: 'BDI',
    iso2Code: 'BI',
    name: 'Burundi',
  },
  {
    iso3Code: 'KHM',
    iso2Code: 'KH',
    name: 'Cambodia',
  },
  {
    iso3Code: 'CMR',
    iso2Code: 'CM',
    name: 'Cameroon',
  },
  {
    iso3Code: 'CAN',
    iso2Code: 'CA',
    name: 'Canada',
  },
  {
    iso3Code: 'CPV',
    iso2Code: 'CV',
    name: 'Cape Verde',
  },
  {
    iso3Code: 'CYM',
    iso2Code: 'KY',
    name: 'Cayman Islands',
  },
  {
    iso3Code: 'CAF',
    iso2Code: 'CF',
    name: 'Central African Republic',
  },
  {
    iso3Code: 'TCD',
    iso2Code: 'TD',
    name: 'Chad',
  },
  {
    iso3Code: 'CHL',
    iso2Code: 'CL',
    name: 'Chile',
  },
  {
    iso3Code: 'CHN',
    iso2Code: 'CN',
    name: 'China',
  },
  {
    iso3Code: 'CXR',
    iso2Code: 'CX',
    name: 'Christmas Island',
  },
  {
    iso3Code: 'CCK',
    iso2Code: 'CC',
    name: 'Cocos [Keeling] Islands',
  },
  {
    iso3Code: 'COL',
    iso2Code: 'CO',
    name: 'Colombia',
  },
  {
    iso3Code: 'COM',
    iso2Code: 'KM',
    name: 'Comoros',
  },
  {
    iso3Code: 'COK',
    iso2Code: 'CK',
    name: 'Cook Islands',
  },
  {
    iso3Code: 'CRI',
    iso2Code: 'CR',
    name: 'Costa Rica',
  },
  {
    iso3Code: 'HRV',
    iso2Code: 'HR',
    name: 'Croatia',
  },
  {
    iso3Code: 'CUB',
    iso2Code: 'CU',
    name: 'Cuba',
  },
  {
    iso3Code: 'CUW',
    iso2Code: 'CW',
    name: 'Curacao',
  },
  {
    iso3Code: 'CYP',
    iso2Code: 'CY',
    name: 'Cyprus',
  },
  {
    iso3Code: 'CZE',
    iso2Code: 'CZ',
    name: 'Czech Republic',
  },
  {
    iso3Code: 'COD',
    iso2Code: 'CD',
    name: 'Democratic Republic of the Congo',
  },
  {
    iso3Code: 'DNK',
    iso2Code: 'DK',
    name: 'Denmark',
  },
  {
    iso3Code: 'DJI',
    iso2Code: 'DJ',
    name: 'Djibouti',
  },
  {
    iso3Code: 'DMA',
    iso2Code: 'DM',
    name: 'Dominica',
  },
  {
    iso3Code: 'DOM',
    iso2Code: 'DO',
    name: 'Dominican Republic',
  },
  {
    iso3Code: 'TLS',
    iso2Code: 'TL',
    name: 'East Timor',
  },
  {
    iso3Code: 'ECU',
    iso2Code: 'EC',
    name: 'Ecuador',
  },
  {
    iso3Code: 'EGY',
    iso2Code: 'EG',
    name: 'Egypt',
  },
  {
    iso3Code: 'SLV',
    iso2Code: 'SV',
    name: 'El Salvador',
  },
  {
    iso3Code: 'GNQ',
    iso2Code: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    iso3Code: 'ERI',
    iso2Code: 'ER',
    name: 'Eritrea',
  },
  {
    iso3Code: 'EST',
    iso2Code: 'EE',
    name: 'Estonia',
  },
  {
    iso3Code: 'ETH',
    iso2Code: 'ET',
    name: 'Ethiopia',
  },
  {
    iso3Code: 'FLK',
    iso2Code: 'FK',
    name: 'Falkland Islands',
  },
  {
    iso3Code: 'FRO',
    iso2Code: 'FO',
    name: 'Faroe Islands',
  },
  {
    iso3Code: 'FJI',
    iso2Code: 'FJ',
    name: 'Fiji',
  },
  {
    iso3Code: 'FIN',
    iso2Code: 'FI',
    name: 'Finland',
  },
  {
    iso3Code: 'FRA',
    iso2Code: 'FR',
    name: 'France',
  },
  {
    iso3Code: 'GUF',
    iso2Code: 'GF',
    name: 'French Guiana',
  },
  {
    iso3Code: 'PYF',
    iso2Code: 'PF',
    name: 'French Polynesia',
  },
  {
    iso3Code: 'ATF',
    iso2Code: 'TF',
    name: 'French Southern Territories',
  },
  {
    iso3Code: 'GAB',
    iso2Code: 'GA',
    name: 'Gabon',
  },
  {
    iso3Code: 'GMB',
    iso2Code: 'GM',
    name: 'Gambia',
  },
  {
    iso3Code: 'GEO',
    iso2Code: 'GE',
    name: 'Georgia',
  },
  {
    iso3Code: 'DEU',
    iso2Code: 'DE',
    name: 'Germany',
  },
  {
    iso3Code: 'GHA',
    iso2Code: 'GH',
    name: 'Ghana',
  },
  {
    iso3Code: 'GIB',
    iso2Code: 'GI',
    name: 'Gibraltar',
  },
  {
    iso3Code: 'GRC',
    iso2Code: 'GR',
    name: 'Greece',
  },
  {
    iso3Code: 'GRL',
    iso2Code: 'GL',
    name: 'Greenland',
  },
  {
    iso3Code: 'GRD',
    iso2Code: 'GD',
    name: 'Grenada',
  },
  {
    iso3Code: 'GLP',
    iso2Code: 'GP',
    name: 'Guadeloupe',
  },
  {
    iso3Code: 'GUM',
    iso2Code: 'GU',
    name: 'Guam',
  },
  {
    iso3Code: 'GTM',
    iso2Code: 'GT',
    name: 'Guatemala',
  },
  {
    iso3Code: 'GGY',
    iso2Code: 'GG',
    name: 'Guernsey',
  },
  {
    iso3Code: 'GIN',
    iso2Code: 'GN',
    name: 'Guinea',
  },
  {
    iso3Code: 'GNB',
    iso2Code: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    iso3Code: 'GUY',
    iso2Code: 'GY',
    name: 'Guyana',
  },
  {
    iso3Code: 'HTI',
    iso2Code: 'HT',
    name: 'Haiti',
  },
  {
    iso3Code: 'HMD',
    iso2Code: 'HM',
    name: 'Heard Island and McDonald Islands',
  },
  {
    iso3Code: 'HND',
    iso2Code: 'HN',
    name: 'Honduras',
  },
  {
    iso3Code: 'HKG',
    iso2Code: 'HK',
    name: 'Hong Kong',
  },
  {
    iso3Code: 'HUN',
    iso2Code: 'HU',
    name: 'Hungary',
  },
  {
    iso3Code: 'ISL',
    iso2Code: 'IS',
    name: 'Iceland',
  },
  {
    iso3Code: 'IND',
    iso2Code: 'IN',
    name: 'India',
  },
  {
    iso3Code: 'IDN',
    iso2Code: 'ID',
    name: 'Indonesia',
  },
  {
    iso3Code: 'IRN',
    iso2Code: 'IR',
    name: 'Iran',
  },
  {
    iso3Code: 'IRQ',
    iso2Code: 'IQ',
    name: 'Iraq',
  },
  {
    iso3Code: 'IRL',
    iso2Code: 'IE',
    name: 'Ireland',
  },
  {
    iso3Code: 'IMN',
    iso2Code: 'IM',
    name: 'Isle of Man',
  },
  {
    iso3Code: 'ISR',
    iso2Code: 'IL',
    name: 'Israel',
  },
  {
    iso3Code: 'ITA',
    iso2Code: 'IT',
    name: 'Italy',
  },
  {
    iso3Code: 'CIV',
    iso2Code: 'CI',
    name: 'Ivory Coast',
  },
  {
    iso3Code: 'JAM',
    iso2Code: 'JM',
    name: 'Jamaica',
  },
  {
    iso3Code: 'JPN',
    iso2Code: 'JP',
    name: 'Japan',
  },
  {
    iso3Code: 'JEY',
    iso2Code: 'JE',
    name: 'Jersey',
  },
  {
    iso3Code: 'JOR',
    iso2Code: 'JO',
    name: 'Jordan',
  },
  {
    iso3Code: 'KAZ',
    iso2Code: 'KZ',
    name: 'Kazakhstan',
  },
  {
    iso3Code: 'KEN',
    iso2Code: 'KE',
    name: 'Kenya',
  },
  {
    iso3Code: 'KIR',
    iso2Code: 'KI',
    name: 'Kiribati',
  },
  {
    iso3Code: 'XKX',
    iso2Code: 'XK',
    name: 'Kosovo',
  },
  {
    iso3Code: 'KWT',
    iso2Code: 'KW',
    name: 'Kuwait',
  },
  {
    iso3Code: 'KGZ',
    iso2Code: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    iso3Code: 'LAO',
    iso2Code: 'LA',
    name: 'Laos',
  },
  {
    iso3Code: 'LVA',
    iso2Code: 'LV',
    name: 'Latvia',
  },
  {
    iso3Code: 'LBN',
    iso2Code: 'LB',
    name: 'Lebanon',
  },
  {
    iso3Code: 'LSO',
    iso2Code: 'LS',
    name: 'Lesotho',
  },
  {
    iso3Code: 'LBR',
    iso2Code: 'LR',
    name: 'Liberia',
  },
  {
    iso3Code: 'LBY',
    iso2Code: 'LY',
    name: 'Libya',
  },
  {
    iso3Code: 'LIE',
    iso2Code: 'LI',
    name: 'Liechtenstein',
  },
  {
    iso3Code: 'LTU',
    iso2Code: 'LT',
    name: 'Lithuania',
  },
  {
    iso3Code: 'LUX',
    iso2Code: 'LU',
    name: 'Luxembourg',
  },
  {
    iso3Code: 'MAC',
    iso2Code: 'MO',
    name: 'Macao',
  },
  {
    iso3Code: 'MKD',
    iso2Code: 'MK',
    name: 'Macedonia',
  },
  {
    iso3Code: 'MDG',
    iso2Code: 'MG',
    name: 'Madagascar',
  },
  {
    iso3Code: 'MWI',
    iso2Code: 'MW',
    name: 'Malawi',
  },
  {
    iso3Code: 'MYS',
    iso2Code: 'MY',
    name: 'Malaysia',
  },
  {
    iso3Code: 'MDV',
    iso2Code: 'MV',
    name: 'Maldives',
  },
  {
    iso3Code: 'MLI',
    iso2Code: 'ML',
    name: 'Mali',
  },
  {
    iso3Code: 'MLT',
    iso2Code: 'MT',
    name: 'Malta',
  },
  {
    iso3Code: 'MHL',
    iso2Code: 'MH',
    name: 'Marshall Islands',
  },
  {
    iso3Code: 'MTQ',
    iso2Code: 'MQ',
    name: 'Martinique',
  },
  {
    iso3Code: 'MRT',
    iso2Code: 'MR',
    name: 'Mauritania',
  },
  {
    iso3Code: 'MUS',
    iso2Code: 'MU',
    name: 'Mauritius',
  },
  {
    iso3Code: 'MYT',
    iso2Code: 'YT',
    name: 'Mayotte',
  },
  {
    iso3Code: 'MEX',
    iso2Code: 'MX',
    name: 'Mexico',
  },
  {
    iso3Code: 'FSM',
    iso2Code: 'FM',
    name: 'Micronesia',
  },
  {
    iso3Code: 'MDA',
    iso2Code: 'MD',
    name: 'Moldova',
  },
  {
    iso3Code: 'MCO',
    iso2Code: 'MC',
    name: 'Monaco',
  },
  {
    iso3Code: 'MNG',
    iso2Code: 'MN',
    name: 'Mongolia',
  },
  {
    iso3Code: 'MNE',
    iso2Code: 'ME',
    name: 'Montenegro',
  },
  {
    iso3Code: 'MSR',
    iso2Code: 'MS',
    name: 'Montserrat',
  },
  {
    iso3Code: 'MAR',
    iso2Code: 'MA',
    name: 'Morocco',
  },
  {
    iso3Code: 'MOZ',
    iso2Code: 'MZ',
    name: 'Mozambique',
  },
  {
    iso3Code: 'MMR',
    iso2Code: 'MM',
    name: 'Myanmar [Burma]',
  },
  {
    iso3Code: 'NAM',
    iso2Code: 'NA',
    name: 'Namibia',
  },
  {
    iso3Code: 'NRU',
    iso2Code: 'NR',
    name: 'Nauru',
  },
  {
    iso3Code: 'NPL',
    iso2Code: 'NP',
    name: 'Nepal',
  },
  {
    iso3Code: 'NLD',
    iso2Code: 'NL',
    name: 'Netherlands',
  },
  {
    iso3Code: 'NCL',
    iso2Code: 'NC',
    name: 'New Caledonia',
  },
  {
    iso3Code: 'NZL',
    iso2Code: 'NZ',
    name: 'New Zealand',
  },
  {
    iso3Code: 'NIC',
    iso2Code: 'NI',
    name: 'Nicaragua',
  },
  {
    iso3Code: 'NER',
    iso2Code: 'NE',
    name: 'Niger',
  },
  {
    iso3Code: 'NGA',
    iso2Code: 'NG',
    name: 'Nigeria',
  },
  {
    iso3Code: 'NIU',
    iso2Code: 'NU',
    name: 'Niue',
  },
  {
    iso3Code: 'NFK',
    iso2Code: 'NF',
    name: 'Norfolk Island',
  },
  {
    iso3Code: 'PRK',
    iso2Code: 'KP',
    name: 'North Korea',
  },
  {
    iso3Code: 'MNP',
    iso2Code: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    iso3Code: 'NOR',
    iso2Code: 'NO',
    name: 'Norway',
  },
  {
    iso3Code: 'OMN',
    iso2Code: 'OM',
    name: 'Oman',
  },
  {
    iso3Code: 'PAK',
    iso2Code: 'PK',
    name: 'Pakistan',
  },
  {
    iso3Code: 'PLW',
    iso2Code: 'PW',
    name: 'Palau',
  },
  {
    iso3Code: 'PSE',
    iso2Code: 'PS',
    name: 'Palestine',
  },
  {
    iso3Code: 'PAN',
    iso2Code: 'PA',
    name: 'Panama',
  },
  {
    iso3Code: 'PNG',
    iso2Code: 'PG',
    name: 'Papua New Guinea',
  },
  {
    iso3Code: 'PRY',
    iso2Code: 'PY',
    name: 'Paraguay',
  },
  {
    iso3Code: 'PER',
    iso2Code: 'PE',
    name: 'Peru',
  },
  {
    iso3Code: 'PHL',
    iso2Code: 'PH',
    name: 'Philippines',
  },
  {
    iso3Code: 'PCN',
    iso2Code: 'PN',
    name: 'Pitcairn Islands',
  },
  {
    iso3Code: 'POL',
    iso2Code: 'PL',
    name: 'Poland',
  },
  {
    iso3Code: 'PRT',
    iso2Code: 'PT',
    name: 'Portugal',
  },
  {
    iso3Code: 'PRI',
    iso2Code: 'PR',
    name: 'Puerto Rico',
  },
  {
    iso3Code: 'QAT',
    iso2Code: 'QA',
    name: 'Qatar',
  },
  {
    iso3Code: 'COG',
    iso2Code: 'CG',
    name: 'Republic of the Congo',
  },
  {
    iso3Code: 'ROU',
    iso2Code: 'RO',
    name: 'Romania',
  },
  {
    iso3Code: 'RUS',
    iso2Code: 'RU',
    name: 'Russia',
  },
  {
    iso3Code: 'RWA',
    iso2Code: 'RW',
    name: 'Rwanda',
  },
  {
    iso3Code: 'REU',
    iso2Code: 'RE',
    name: 'Réunion',
  },
  {
    iso3Code: 'BLM',
    iso2Code: 'BL',
    name: 'Saint Barthélemy',
  },
  {
    iso3Code: 'SHN',
    iso2Code: 'SH',
    name: 'Saint Helena',
  },
  {
    iso3Code: 'KNA',
    iso2Code: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  {
    iso3Code: 'LCA',
    iso2Code: 'LC',
    name: 'Saint Lucia',
  },
  {
    iso3Code: 'MAF',
    iso2Code: 'MF',
    name: 'Saint Martin',
  },
  {
    iso3Code: 'SPM',
    iso2Code: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  {
    iso3Code: 'VCT',
    iso2Code: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  {
    iso3Code: 'WSM',
    iso2Code: 'WS',
    name: 'Samoa',
  },
  {
    iso3Code: 'SMR',
    iso2Code: 'SM',
    name: 'San Marino',
  },
  {
    iso3Code: 'SAU',
    iso2Code: 'SA',
    name: 'Saudi Arabia',
  },
  {
    iso3Code: 'SEN',
    iso2Code: 'SN',
    name: 'Senegal',
  },
  {
    iso3Code: 'SRB',
    iso2Code: 'RS',
    name: 'Serbia',
  },
  {
    iso3Code: 'SYC',
    iso2Code: 'SC',
    name: 'Seychelles',
  },
  {
    iso3Code: 'SLE',
    iso2Code: 'SL',
    name: 'Sierra Leone',
  },
  {
    iso3Code: 'SGP',
    iso2Code: 'SG',
    name: 'Singapore',
  },
  {
    iso3Code: 'SXM',
    iso2Code: 'SX',
    name: 'Sint Maarten',
  },
  {
    iso3Code: 'SVK',
    iso2Code: 'SK',
    name: 'Slovakia',
  },
  {
    iso3Code: 'SVN',
    iso2Code: 'SI',
    name: 'Slovenia',
  },
  {
    iso3Code: 'SLB',
    iso2Code: 'SB',
    name: 'Solomon Islands',
  },
  {
    iso3Code: 'SOM',
    iso2Code: 'SO',
    name: 'Somalia',
  },
  {
    iso3Code: 'ZAF',
    iso2Code: 'ZA',
    name: 'South Africa',
  },
  {
    iso3Code: 'SGS',
    iso2Code: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    iso3Code: 'KOR',
    iso2Code: 'KR',
    name: 'South Korea',
  },
  {
    iso3Code: 'SSD',
    iso2Code: 'SS',
    name: 'South Sudan',
  },
  {
    iso3Code: 'ESP',
    iso2Code: 'ES',
    name: 'Spain',
  },
  {
    iso3Code: 'LKA',
    iso2Code: 'LK',
    name: 'Sri Lanka',
  },
  {
    iso3Code: 'SDN',
    iso2Code: 'SD',
    name: 'Sudan',
  },
  {
    iso3Code: 'SUR',
    iso2Code: 'SR',
    name: 'Suriname',
  },
  {
    iso3Code: 'SJM',
    iso2Code: 'SJ',
    name: 'Svalbard and Jan Mayen',
  },
  {
    iso3Code: 'SWZ',
    iso2Code: 'SZ',
    name: 'Swaziland',
  },
  {
    iso3Code: 'SWE',
    iso2Code: 'SE',
    name: 'Sweden',
  },
  {
    iso3Code: 'CHE',
    iso2Code: 'CH',
    name: 'Switzerland',
  },
  {
    iso3Code: 'SYR',
    iso2Code: 'SY',
    name: 'Syria',
  },
  {
    iso3Code: 'STP',
    iso2Code: 'ST',
    name: 'São Tomé and Príncipe',
  },
  {
    iso3Code: 'TWN',
    iso2Code: 'TW',
    name: 'Taiwan',
  },
  {
    iso3Code: 'TJK',
    iso2Code: 'TJ',
    name: 'Tajikistan',
  },
  {
    iso3Code: 'TZA',
    iso2Code: 'TZ',
    name: 'Tanzania',
  },
  {
    iso3Code: 'THA',
    iso2Code: 'TH',
    name: 'Thailand',
  },
  {
    iso3Code: 'TGO',
    iso2Code: 'TG',
    name: 'Togo',
  },
  {
    iso3Code: 'TKL',
    iso2Code: 'TK',
    name: 'Tokelau',
  },
  {
    iso3Code: 'TON',
    iso2Code: 'TO',
    name: 'Tonga',
  },
  {
    iso3Code: 'TTO',
    iso2Code: 'TT',
    name: 'Trinidad and Tobago',
  },
  {
    iso3Code: 'TUN',
    iso2Code: 'TN',
    name: 'Tunisia',
  },
  {
    iso3Code: 'TUR',
    iso2Code: 'TR',
    name: 'Turkey',
  },
  {
    iso3Code: 'TKM',
    iso2Code: 'TM',
    name: 'Turkmenistan',
  },
  {
    iso3Code: 'TCA',
    iso2Code: 'TC',
    name: 'Turks and Caicos Islands',
  },
  {
    iso3Code: 'TUV',
    iso2Code: 'TV',
    name: 'Tuvalu',
  },
  {
    iso3Code: 'UMI',
    iso2Code: 'UM',
    name: 'U.S. Minor Outlying Islands',
  },
  {
    iso3Code: 'VIR',
    iso2Code: 'VI',
    name: 'U.S. Virgin Islands',
  },
  {
    iso3Code: 'UGA',
    iso2Code: 'UG',
    name: 'Uganda',
  },
  {
    iso3Code: 'UKR',
    iso2Code: 'UA',
    name: 'Ukraine',
  },
  {
    iso3Code: 'ARE',
    iso2Code: 'AE',
    name: 'United Arab Emirates',
  },
  {
    iso3Code: 'GBR',
    iso2Code: 'GB',
    name: 'United Kingdom',
  },
  {
    iso3Code: 'USA',
    iso2Code: 'US',
    name: 'United States',
  },
  {
    iso3Code: 'URY',
    iso2Code: 'UY',
    name: 'Uruguay',
  },
  {
    iso3Code: 'UZB',
    iso2Code: 'UZ',
    name: 'Uzbekistan',
  },
  {
    iso3Code: 'VUT',
    iso2Code: 'VU',
    name: 'Vanuatu',
  },
  {
    iso3Code: 'VAT',
    iso2Code: 'VA',
    name: 'Vatican City',
  },
  {
    iso3Code: 'VEN',
    iso2Code: 'VE',
    name: 'Venezuela',
  },
  {
    iso3Code: 'VNM',
    iso2Code: 'VN',
    name: 'Vietnam',
  },
  {
    iso3Code: 'WLF',
    iso2Code: 'WF',
    name: 'Wallis and Futuna',
  },
  {
    iso3Code: 'ESH',
    iso2Code: 'EH',
    name: 'Western Sahara',
  },
  {
    iso3Code: 'YEM',
    iso2Code: 'YE',
    name: 'Yemen',
  },
  {
    iso3Code: 'ZMB',
    iso2Code: 'ZM',
    name: 'Zambia',
  },
  {
    iso3Code: 'ZWE',
    iso2Code: 'ZW',
    name: 'Zimbabwe',
  },
];

const countriesByIso2 = new Map(countryList.map(country => [country.iso2Code, country]));

const countriesByIso3 = new Map(countryList.map(country => [country.iso3Code, country]));

export const getCountryByIso2 = (iso2Code: string) => countriesByIso2.get(iso2Code);

export const getCountryByIso3 = (iso3Code: string) => countriesByIso3.get(iso3Code);

export interface CountryIso {
  name: string;
  alpha2: string;
  alpha3: string;
}

export const getCountries = (search: { byAlpha2?: string; byAlpha3?: string; byName?: string } = {}): CountryIso[] => {
  return iso31661
    .filter(country => country.alpha2 === (search.byAlpha2 ?? country.alpha2))
    .filter(country => country.alpha3 === (search.byAlpha3 ?? country.alpha3))
    .filter(country => country.name === (search.byName ?? country.name))
    .map(country => ({
      name: country.name,
      alpha2: country.alpha2,
      alpha3: country.alpha3,
    }));
};

export const getSubdivisions = (
  search: {
    name?: string;
    countryCode?: string;
    subdivisionCode?: string;
  } = {},
) => {
  return iso31662
    .map(subdivision => ({
      name: subdivision.name,
      countryCode: subdivision.code.slice(0, 2),
      subdivisionCode: subdivision.code.slice(3),
    }))
    .filter(subdivision => subdivision.name === (search.name ?? subdivision.name))
    .filter(subdivision => subdivision.countryCode === (search.countryCode ?? subdivision.countryCode))
    .filter(subdivision => subdivision.subdivisionCode === (search.subdivisionCode ?? subdivision.subdivisionCode));
};

export const getPostalZipCodeRegex = (countryCodeIsoAlpha2: string): RegExp | undefined => {
  return {
    // https://www.canadapost-postescanada.ca/cpc/en/support/articles/addressing-guidelines/postal-codes.page
    CA: /^[ABCEGHJKLMNPRSTVXY]\d[A-Z] ?\d[A-Z]\d$/,
    // https://faq.usps.com/s/article/ZIP-Code-The-Basics
    US: /^[0-9]{5}(?:-[0-9]{4})?$/,
  }[countryCodeIsoAlpha2];
};

export default Country;
