import { BillingInfo } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import { CreditCardFormData } from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';
import DisposalAssetTypeCountFormData from '~/wm/packages/disposal/types/DisposalAssetTypeCountFormData';
import DisposalCheckoutCouponFormData from '~/wm/packages/disposal/types/DisposalCheckoutCouponFormData';
import { DisposalCheckoutSelfPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Packages/DisposalCheckoutSelf/Dto/Model.gen';

const buildDisposalSelfCheckoutPayloadDto = (
  billingInfo: BillingInfo,
  assetTypeCounts: DisposalAssetTypeCountFormData[],
  customerToken: CreditCardFormData | undefined,
  coupon: DisposalCheckoutCouponFormData | undefined,
  phoneNumber: string,
): DisposalCheckoutSelfPayloadDto => ({
  addressFrom: {
    address: {
      city: billingInfo.billingAddress?.address.city ?? '',
      countryCode: billingInfo.billingAddress?.address.countryCode ?? '',
      countryFallback: billingInfo.billingAddress?.address.countryFallback ?? '',
      line1: billingInfo.billingAddress?.address.line1 ?? '',
      line2: billingInfo.billingAddress?.address.line2 ?? '',
      zip: billingInfo.billingAddress?.address.zip ?? '',
      stateCode: billingInfo.billingAddress?.address.stateCode ?? '',
      stateFallback: billingInfo.billingAddress?.address.stateFallback ?? '',
    },
    name: billingInfo.billingAddress?.name ?? '',
    phone: phoneNumber ?? '',
  },
  disposalAssetTypeCountPayloads: assetTypeCounts.map(assetTypeCount => ({
    disposalAssetType: assetTypeCount.type,
    count: Number(assetTypeCount.count),
    label: assetTypeCount.customizedType,
  })),
  customerPayload: (() => {
    if (typeof customerToken === 'undefined') {
      return undefined;
    }

    switch (customerToken.type) {
      case 'card-on-file':
        return undefined;
      case 'new-card':
        return {
          defaultPaymentMethod: {
            token: customerToken.tokenId!,
          },
        };
    }
  })(),
  couponCode: typeof coupon !== 'undefined' ? coupon.code : undefined,
});

export default buildDisposalSelfCheckoutPayloadDto;
