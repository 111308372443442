import { css } from '@emotion/react';
import * as React from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

/**
 * Basic table cell containing a label & description with optional icon.
 */
export type TableCellProps = {
  label: string | React.ReactNode;
  labelColor?: Color;
  labelUrl?: string;
  description?: string | React.ReactNode;
  descriptionColor?: Color;
  descriptionMuted?: boolean;
  icon?: IconType;
  iconColor?: Color;
};

const TableCell: React.FunctionComponent<TableCellProps> = ({
  label,
  labelColor,
  labelUrl,
  description,
  descriptionColor,
  descriptionMuted = false,
  icon,
  iconColor,
}) => (
  <div
    css={css`
      display: flex;
      align-items: center;
    `}
  >
    {icon ? (
      <Icon
        css={css`
          margin-right: 0.625rem;
        `}
        icon={icon}
        color={iconColor}
      />
    ) : (
      ''
    )}
    <div
      css={css`
        overflow: hidden;
        text-overflow: ellipsis;
      `}
    >
      <div
        css={css`
          margin: 0;
          ${labelColor && `color: ${colorToCode(labelColor)};`}
          overflow: hidden;
          text-overflow: ellipsis;
        `}
      >
        {labelUrl ? <a href={labelUrl}>{label}</a> : label}
      </div>
      {description ? (
        <Label
          color={descriptionColor}
          muted={descriptionMuted}
          css={css`
            margin: 0;
            font-size: 0.75rem;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {description}
        </Label>
      ) : (
        ''
      )}
    </div>
  </div>
);

export default TableCell;
