import React, { useContext } from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import ConsoleContext from '~/neo-ui/packages/table/packages/console/contexts/ConsoleContext';

type SelectedDisplayCountProps = {
  displayLabel?: string;
};

const SelectedDisplayCount: React.FunctionComponent<SelectedDisplayCountProps> = ({ displayLabel = ' selected' }) => {
  const {
    selectedConsoleItems: { selectedItems, clearSelectedItems },
  } = useContext(ConsoleContext);

  if (selectedItems.size > 0) {
    return (
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-inline: 0.625rem;
        `}
      >
        <div
          css={css`
            width: 0;
            height: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateX(-0.875rem);
          `}
        >
          <ButtonModifier
            icon={'Cancel'}
            onClick={clearSelectedItems}
          />
        </div>
        <Label color={'primary-400'}>
          <b>{selectedItems.size}</b> {displayLabel}
        </Label>
      </div>
    );
  }

  return null;
};
export default SelectedDisplayCount;
