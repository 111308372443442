import { css } from '@emotion/react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import * as React from 'react';
import FormCreditCardInput, {
  CreditCardFormData,
  CreditCardFormDataOnFile,
  PaymentVersion,
} from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Button from '~/neo-ui/packages/button/Button';
import Image from '~/neo-ui/packages/image/Image';
import { Styleable } from '~/neo-ui/model/capacity';

export type FormCreditCardChangerProps<T> = {
  creditCardFieldKey: FieldKeyExpression<T>;
  paymentVersion: PaymentVersion;
  onCreditCardDataReady?: (isReady: boolean) => void;
} & Styleable;

const FormCreditCardChanger = <T,>({
  creditCardFieldKey,
  paymentVersion,
  onCreditCardDataReady,
  className,
}: FormCreditCardChangerProps<T>) => {
  const { getFormInput, setFormInput } = useFormContext<T>();

  const creditCardFormData: CreditCardFormData | null = getFormInput<CreditCardFormData>(creditCardFieldKey).value;

  // Get the credit card info on file when the component is mounted
  const initialCreditCardInfo = React.useRef(creditCardFormData as CreditCardFormDataOnFile).current;

  const [isEditing, setEditing] = React.useState(typeof initialCreditCardInfo === 'undefined');

  const creditCardIcon = initialCreditCardInfo ? getCreditCardIcon(initialCreditCardInfo.brand) : undefined;

  const creditCardImageSource =
    creditCardIcon?.type === 'image' ? `/i/image/payment/${encodeURIComponent(creditCardIcon.name)}.svg` : undefined;

  React.useEffect(() => {
    if (typeof onCreditCardDataReady !== 'undefined') {
      // This mechanism could be improved with
      // engine enhancements for step completion / validation.
      // Duplicates: 6b9724b7-ca6b-4b5d-afaa-3dfa1ffdd904
      const hasCreditCardData = creditCardFormData && (creditCardFormData.type === 'card-on-file' || creditCardFormData.isComplete);

      onCreditCardDataReady(hasCreditCardData);
    }
  }, [creditCardFieldKey, creditCardFormData, onCreditCardDataReady]);

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        text-align: left;
      `}
    >
      <div
        css={css`
          display: inline-flex;
          justify-content: space-between;
          margin-bottom: 1.25rem;
        `}
      >
        <div
          css={css`
            font-size: 1.125rem;
            font-weight: bold;
          `}
        >
          Payment method
        </div>
        {!!initialCreditCardInfo &&
          (isEditing ? (
            <Button
              size={'sm'}
              onClick={() => {
                setFormInput<CreditCardFormDataOnFile>(creditCardFieldKey, initialCreditCardInfo);
                setEditing(false);
              }}
            >
              Cancel
            </Button>
          ) : (
            <Button
              size={'sm'}
              onClick={() => {
                setFormInput<null>(creditCardFieldKey, null);
                setEditing(true);
              }}
            >
              Use a different card
            </Button>
          ))}
      </div>
      <div
        css={css`
          margin-bottom: 0.875rem;
        `}
      >
        {!isEditing && !!initialCreditCardInfo ? (
          <div>
            {creditCardImageSource && (
              <Image
                css={css`
                  width: 1rem;
                  height: 1rem;
                  margin-right: 0.625rem;
                `}
                source={creditCardImageSource}
              />
            )}
            {creditCardIcon?.type === 'string' && (
              <span
                css={css`
                  margin-right: 0.625rem;
                `}
              >
                {creditCardIcon.name}
              </span>
            )}
            <span>ending in {initialCreditCardInfo.last4}</span>
          </div>
        ) : (
          <div>
            <p
              css={css`
                font-weight: bold;
                margin-bottom: 0.625rem;
              `}
            >
              Your credit card
            </p>
            <FormCreditCardInput
              fieldKey={creditCardFieldKey}
              version={paymentVersion}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const getCreditCardIcon = (creditCardBrand: string): { name: string; type: 'image' | 'string' } => {
  switch (creditCardBrand.toLowerCase()) {
    case 'american express':
    case 'amex':
      return { name: 'AMEX', type: 'image' };
    case 'mastercard':
      return { name: 'Mastercard', type: 'image' };
    case 'visa':
      return { name: 'Visa', type: 'image' };
    case 'diners':
      return { name: 'Diners', type: 'image' };
    case 'discover':
      return { name: 'Discover', type: 'image' };
    default:
      return { name: creditCardBrand, type: 'string' };
  }
};

export default FormCreditCardChanger;
