import { css, SerializedStyles } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Theme, { themeToColor } from '~/neo-ui/packages/color/Theme';
import formControlStyles, {
  themeToFormControlStyle,
} from '~/neo-ui/packages/form/packages/form-input/packages/form-control/formControlStyles';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type InputTextProps = {
  /**
   * Temporary, lighter coloured text that will disappear on key down
   */
  placeholder?: string;
  /**
   *  Event fired when user changes the value
   */
  onTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Fired when clear button is clicked
   * Defaults to no clear button visible
   */
  onClear?: () => void;
  value: string;
  /**
   * Event fired when on key down
   */
  onKeyDown?: (e: React.KeyboardEvent) => void;
  prependIcon?: IconType;
  tabIndex?: number;
  disabled?: boolean;

  /**
   * Will attempt to give focus to the input on first render
   */
  hasDefaultFocus?: boolean;

  /**
   * Styling
   */
  theme?: Theme;
  customInputBoxStyle?: SerializedStyles;
} & Styleable;

/**
 * Simple input text that provides the value being changed
 *
 * Specifying an onClear will provide a 'clear' button at the right end of the input
 */
const InputText: React.FunctionComponent<React.PropsWithChildren<InputTextProps>> = ({
  className,
  placeholder,
  onTextChange,
  onClear,
  value,
  onKeyDown,
  prependIcon,
  tabIndex,
  disabled = false,
  hasDefaultFocus = false,
  theme,
  customInputBoxStyle,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useLayoutEffect(() => {
    if (hasDefaultFocus && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [hasDefaultFocus]);

  // Special form styling if theme is supplied
  const formControlThemeStyle = theme ? themeToFormControlStyle(theme) : css``;
  const mutedColor = themeToColor(theme, '700');

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
      className={className}
    >
      {prependIcon && (
        <div
          css={css`
            position: relative;
            width: 0;
            height: 0;
          `}
        >
          <Icon
            icon={prependIcon}
            sizePx={16}
            color={mutedColor ? mutedColor : 'light-700'}
            css={css`
              position: absolute;
              display: flex;
              top: -8px;
              left: 8px;
            `}
          />
        </div>
      )}
      <input
        className={'form-control'}
        placeholder={placeholder}
        tabIndex={tabIndex}
        onChange={evt => {
          onTextChange(evt);
        }}
        value={value}
        css={[
          formControlStyles.app,
          css`
            background-color: ${colorToCode('light-000')};
            box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
            border-radius: 0.3125rem;
            border: none;
            max-height: 2.25rem;
            &:disabled {
              background-color: inherit;
              opacity: 0.2;
            }
            padding-left: ${prependIcon ? '1.938rem' : '0.75rem'};
            padding-right: ${onClear && value ? '2rem' : '0.75rem'};

            ${customInputBoxStyle};
          `,
          formControlThemeStyle,
        ]}
        onKeyDown={onKeyDown}
        disabled={disabled}
        ref={inputRef}
      />
      {onClear && value && (
        <div
          css={css`
            width: 0;
            height: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateX(-0.875rem);
          `}
        >
          <ButtonModifier
            icon={'Cancel'}
            onClick={() => {
              onClear();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default InputText;
