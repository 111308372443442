import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { EngagementNoteDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Dto/Model.gen';
import Preformatted from '~/neo-ui/packages/text/packages/preformatted/Preformatted';
import EngagementNoteArchivedViewWindow from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/packages/engagement-note-list-card-archived/packages/engagement-note-archived-view-window/EngagementNoteArchivedViewWindow';

export type EngagementNoteListCardArchivedProps = {
  note: EngagementNoteDto;
  isUnarchivingNote: boolean;
  onNoteUnarchive: (engagementNoteId: string) => void;
};

const EngagementNoteListCardArchived = ({ note, isUnarchivingNote, onNoteUnarchive }: EngagementNoteListCardArchivedProps) => {
  const [isViewingNote, setViewingNote] = React.useState<boolean>(false);

  return (
    <Card
      backgroundColor={'light-100'}
      shadow={'sm'}
      padding={'md'}
      onClick={() => {
        setViewingNote(true);
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Header
            css={css`
              margin-left: auto;
            `}
            size={7}
            weight={'bold'}
            color={'light-900'}
          >
            CREATED ON:{' '}
            {formatDate(new Date(parseISO(note.createdAt)), {
              format: 'dd/MM/yyyy',
              timezone: TimezoneFormat.Local,
            })}
          </Header>
        </div>
        <div
          css={css`
            display: grid;
            grid-template-columns: min-content auto min-content;
            grid-template-areas:
              '. title .'
              'archive-button description .'
              '. view-note-button .';
            align-items: center;
            gap: 1rem;
          `}
        >
          {typeof note.title !== 'undefined' && (
            <Label
              css={css`
                grid-area: title;
              `}
              size={'lg'}
              bold={true}
              color={'dark-700'}
            >
              {note.title}
            </Label>
          )}
          <Button
            css={css`
              grid-area: archive-button;
            `}
            size={'xs'}
            iconLeft={'Archive'}
            theme={note.isArchived ? 'primary' : undefined}
            preventOnClickPropagation={true}
            loading={isUnarchivingNote}
            disabled={isUnarchivingNote}
            onClick={() => {
              onNoteUnarchive(note.engagementNoteId);
            }}
          />
          <Preformatted
            css={css`
              grid-area: description;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
            `}
            text={note.description}
          />
        </div>
      </div>
      {isViewingNote && (
        <EngagementNoteArchivedViewWindow
          note={note}
          isOpen={isViewingNote}
          onDismiss={() => {
            setViewingNote(false);
          }}
          isUnarchivingNote={isUnarchivingNote}
          onNoteUnarchive={onNoteUnarchive}
        />
      )}
    </Card>
  );
};

export default EngagementNoteListCardArchived;
