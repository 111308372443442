import {
  DisposalCheckoutDataDto,
  DisposalDynamicPricingDto,
} from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Dto/Model.gen';
import { BillingInfo as BillingInfoType } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import { PaymentVersion } from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';
import DisposalSelfWizard from '~/wm/packages/disposal/wizard/packages/disposal-self-wizard/DisposalSelfWizard';
import DisposalBulkWizard from '~/wm/packages/disposal/wizard/packages/disposal-bulk-wizard/DisposalBulkWizard';
import DisposalAssetTypeCountFormData from '~/wm/packages/disposal/types/DisposalAssetTypeCountFormData';
import { DisposalType } from '~/wm/packages/disposal/types/DisposalSelfCheckoutFormData';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import { DisposalOrderPickupMaterialTypeDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderPickup/Packages/DisposalOrderPickupMaterial/Dto/Model.gen';
import { DisposalCartDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalCart/Dto.gen';
import { useState } from 'react';
import { DisposalSuccessSettingsDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Success/Dto/Model.gen';
import useDisposalFeatureFlagContext from '~/wm/packages/disposal/context/hooks/useDisposalFeatureFlagContext';

/**
 * Which type of checkout to use with asset counts to transfer between types
 */
export type DisposalCheckoutType = {
  type: DisposalType;
  assetTypeCounts: DisposalAssetTypeCountFormData[];
  isBiosCertified: boolean;
};

/**
 * Props for either DisposalWizard components
 */
export type DisposalWizardProps = {
  disposalDynamicPricing: DisposalDynamicPricingDto;
  disposalAssetTypeCountAvailabilities: DisposalAssetTypeAvailabilityDto[];
  pickupMaterialTypeAvailabilities: DisposalOrderPickupMaterialTypeDto[];
  billingInfo: BillingInfoType;
  paymentVersion: PaymentVersion;
  defaultCheckoutData: DisposalCheckoutType;
  cart: DisposalCartDto | undefined;
  brandName: string;
  showLegacyBanner: boolean;
  showPermissionsBanner: boolean;
  showLocationRestrictionBanner: boolean;
  hasDisposalBulkLocationAccess: boolean;
  onDisposalTypeSwap: (disposalCheckoutType: DisposalCheckoutType) => void;
  dropOffLocationsUrl: string;
  disposalSettings: DisposalSuccessSettingsDto;
  minAssetsForFullService: number;
};

export type DisposalSectionProps = {
  billingInfo: BillingInfoType;
  paymentVersion: PaymentVersion;
  brandName: string;
  disposalCheckoutData: DisposalCheckoutDataDto;
  showLegacyBanner: boolean;
  showPermissionsBanner: boolean;
  showLocationRestrictionBanner: boolean;
  hasDisposalBulkLocationAccess: boolean;
  disposalSettings: DisposalSuccessSettingsDto;
};
const DisposalSection = ({
  billingInfo,
  paymentVersion,
  brandName,
  disposalCheckoutData,
  hasDisposalBulkLocationAccess,
  showLegacyBanner,
  showPermissionsBanner,
  showLocationRestrictionBanner,
  disposalSettings,
}: DisposalSectionProps) => {
  const { enableImproveProfit } = useDisposalFeatureFlagContext();
  // Duplicated in C# 03FB4B2A-1C0B-488E-B5F7-23A27C233A58.
  const minAssetsForFullService = enableImproveProfit ? 75 : 30;

  const [disposalCheckoutType, setDisposalCheckoutType] = useState<DisposalCheckoutType>({
    type:
      typeof disposalCheckoutData.disposalCart?.pickupSiteInformation !== 'undefined' &&
      hasDisposalBulkLocationAccess &&
      !disposalSettings.isBulkOrderPlacedDisabled
        ? DisposalType.Bulk
        : DisposalType.Self,
    assetTypeCounts:
      typeof disposalCheckoutData.disposalCart?.disposalAssetTypeCounts !== 'undefined'
        ? disposalCheckoutData.disposalCart?.disposalAssetTypeCounts.map(typeCount => ({
            type: typeCount.disposalAssetType,
            customizedType: typeCount.label,
            count: typeCount.count,
            allowMultipleCartEntries: typeof typeCount.label !== 'undefined',
          }))
        : [],
    isBiosCertified: false,
  });

  const wizardProps: DisposalWizardProps = {
    disposalAssetTypeCountAvailabilities: disposalCheckoutData.disposalAssetTypeAvailabilities ?? [],
    pickupMaterialTypeAvailabilities: disposalCheckoutData.pickupMaterialTypeAvailabilities ?? [],
    disposalDynamicPricing: disposalCheckoutData.disposalDynamicPricing,
    brandName,
    cart: disposalCheckoutData.disposalCart,
    billingInfo,
    paymentVersion,
    defaultCheckoutData: disposalCheckoutType,
    showLegacyBanner,
    showPermissionsBanner,
    showLocationRestrictionBanner,
    hasDisposalBulkLocationAccess,
    onDisposalTypeSwap: disposalCheckoutType => hasDisposalBulkLocationAccess && setDisposalCheckoutType(disposalCheckoutType),
    dropOffLocationsUrl: disposalCheckoutData.shippingCarrierInfo.dropOffLocationsUrl,
    disposalSettings,
    minAssetsForFullService,
  };

  return {
    Self: <DisposalSelfWizard {...wizardProps} />,
    Bulk: <DisposalBulkWizard {...wizardProps} />,
  }[disposalCheckoutType.type];
};

export default DisposalSection;
