import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../AlertModal';
import { hideModalAction } from './action/ModalAction';
import ModalState from './model/ModalState';

export type ModalHookProps<TRootState> = {
  modalStateSelector: (state: TRootState) => ModalState;
};

const ModalHook = <TRootState,>({ modalStateSelector }: ModalHookProps<TRootState>) => {
  const dispatch = useDispatch();
  const { modalProps } = useSelector(modalStateSelector);

  return (
    <AlertModal
      {...modalProps}
      onConfirm={() => {
        dispatch(hideModalAction());
        if (modalProps.onConfirm) {
          modalProps.onConfirm();
        }
      }}
      onDismiss={() => {
        dispatch(hideModalAction());
        if (modalProps.onDismiss) {
          modalProps.onDismiss();
        }
      }}
    />
  );
};

export default ModalHook;
