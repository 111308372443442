import * as React from 'react';
import { components, OptionProps } from 'react-select';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

const SingleSelectOption = <T extends string>(props: OptionProps<SelectOption<T>, false>) => {
  const { Option } = components;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const SwitchTag: React.ElementType = typeof props.data.anchor === 'undefined' ? 'div' : 'a';

  const optionSelectedColor =
    props.isSelected && props.data.selectedBackgroundColor ? colorToCode(props.data.selectedBackgroundColor) : 'transparent';

  return (
    <Option
      {...props}
      css={css`
        border-radius: 0.375rem;
        ${props.data.hoverBackgroundColor &&
        `&:hover {
          background-color: ${colorToCode(props.data.hoverBackgroundColor)} !important;
        }
        background-color: ${optionSelectedColor} !important;
        `}; // Undo the forced selected color from library

        ${props.isSelected && `background-color: ${colorToCode(props.data.selectedBackgroundColor ?? 'primary-050')} !important;`}
      `}
    >
      <SwitchTag
        href={props.data.anchor?.href}
        target={props.data.anchor?.openInNewTab ? '_blank' : undefined}
        css={css`
          display: flex;
          align-items: center;

          &:hover {
            text-decoration: none;
          }
        `}
      >
        {props.data.prependIcon && (
          <Icon
            css={css`
              margin-right: 0.58rem;
            `}
            icon={props.data.prependIcon}
            color={props.data.iconColor ?? props.data.labelColor ?? 'primary-400'}
          />
        )}
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Label color={!props.isSelected ? props.data.labelColor ?? 'dark-900' : props.data.labelHighlightedColor ?? 'dark-900'}>
            {props.data.label}
          </Label>
          {props.data.description && (
            <Label
              size={'sm'}
              color={!props.isSelected ? props.data.descriptionColor ?? 'dark-900' : props.data.descriptionHighlightedColor ?? 'dark-900'}
              muted={true}
            >
              {props.data.description}
            </Label>
          )}
        </div>
        {props.isSelected && (
          <Icon
            css={css`
              margin-left: auto;
            `}
            color={props.data.selectedIconColor ?? 'primary-400'}
            icon={props.data.selectedIcon ?? 'StatusAccepted'}
          />
        )}
      </SwitchTag>
    </Option>
  );
};

export default SingleSelectOption;
