import { ConsoleFilters, TableState } from '~/neo-ui/packages/table/packages/filter-table/FilterTable';
import { searchContextUrlParam, searchQueryUrlParam } from '~/neo-ui/packages/table/packages/url-routing/useUrlTableState';

const buildConsoleFilters = <T>({ filters }: TableState<T>): ConsoleFilters => {
  const consoleFilters: ConsoleFilters = {};
  filters?.selectedFilters.forEach((value, key) => (consoleFilters[key] = Array.from(value.values())));

  if (typeof filters?.searchQuery !== 'undefined') {
    consoleFilters[searchQueryUrlParam] = [filters?.searchQuery];
  }

  if (typeof filters?.selectedSearchContextValue !== 'undefined') {
    consoleFilters[searchContextUrlParam] = [filters?.selectedSearchContextValue];
  }

  return consoleFilters;
};

export default buildConsoleFilters;
