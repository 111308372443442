import { contractCreate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useContractListMutation, {
  UseContractListMutationOptions,
} from '~/wm/packages/strategy/packages/contract/packages/contract-list/hooks/useContractListMutation';
import { ContractUpsertPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Dto/Model.gen';

type ContractCreatePayload = {
  organizationId: string;
  contractCreatePayload: ContractUpsertPayloadDto;
};

export type UseContractCreate = {
  createContract: (contractCreatePayload: ContractCreatePayload, isRequestActive?: () => boolean) => void;
  isCreatingContract: boolean;
};

const useContractCreate = (options?: UseContractListMutationOptions): UseContractCreate => {
  const [createContract, isCreatingContract] = useContractListMutation(
    (contractCreatePayload: ContractCreatePayload) =>
      contractCreate({
        organizationId: contractCreatePayload.organizationId,
        contractCreatePayload: contractCreatePayload.contractCreatePayload,
      }),
    options,
  );

  return {
    createContract,
    isCreatingContract,
  };
};

export default useContractCreate;
