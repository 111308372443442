import React from 'react';
import formatBillingAddress from '~/wm/packages/subscription/packages/billing/operation/formatBillingAddress';
import { BillingInfo as BillingInfoModel } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import AddressInput from '~/neo-ui/packages/address/AddressInput';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { AddressBusiness } from '@SubscriptionClient/Common/Location/Model/Address.gen';
import { css } from '@emotion/react';
import { DisposalAddressFormData } from '~/wm/packages/disposal/packages/disposal-address/packages/disposal-address-form/DisposalAddressForm';
import { AddressBusinessDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Dto/Model.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Button from '~/neo-ui/packages/button/Button';
import Icon from '~/neo-ui/packages/icon/Icon';
import DisableElementOverlay from '~/neo-ui/packages/overlay/DisableElementOverlay';

export type DisposalAddressProps = {
  isAddressValidated: boolean;
  isValidatingAddress: boolean;
  onEditing?: (isEditing: boolean) => void;
};

const defaultAmericanAddressBusiness: AddressBusiness = {
  address: {
    line1: '',
    line2: '',
    city: '',
    stateCode: '',
    stateFallback: '',
    countryCode: 'US',
    countryFallback: 'US',
    zip: '',
  },
  phone: '',
  name: '',
};

const DisposalAddress = ({ isAddressValidated, isValidatingAddress, onEditing }: DisposalAddressProps) => {
  const fieldKey = (formData: FieldKeyExpressionSegment<DisposalAddressFormData>) => formData.shipmentReturnAddress;

  const { formData, getFormInput, submitForm, setFormInput, addAfterSubmitListener, resetForm } = useFormContext<
    DisposalAddressFormData,
    AddressBusinessDto
  >();

  React.useEffect(
    () =>
      addAfterSubmitListener(async (_, updatedBillingAddress) => {
        setBillingAddress(updatedBillingAddress);
        setFormInput<AddressBusiness>(fieldKey, updatedBillingAddress);
        setEditingAddress(false);
        resetForm();
      }),
    [addAfterSubmitListener, resetForm, setFormInput],
  );

  const [billingAddress, setBillingAddress] = React.useState(
    formData.shipmentReturnAddress?.address.countryCode === 'US' ? formData.shipmentReturnAddress : defaultAmericanAddressBusiness,
  );

  const [isEditingAddress, setEditingAddress] = React.useState(
    typeof billingAddress === 'undefined' || billingAddress === defaultAmericanAddressBusiness,
  );

  const billingAddressFormatted = typeof billingAddress !== 'undefined' ? formatBillingAddress(billingAddress, false) : '';

  const returnShippingAddressFormInput = getFormInput<BillingInfoModel>(
    (formData: FieldKeyExpressionSegment<DisposalAddressFormData>) => formData.shipmentReturnAddress,
  ).value;

  React.useEffect(() => {
    if (typeof onEditing !== 'undefined') {
      onEditing(isEditingAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 0.875rem;
      `}
    >
      {isAddressValidated && (
        <div
          css={css`
            display: flex;
            gap: 0.75rem;
            align-items: center;
          `}
        >
          <Icon
            icon={'Location'}
            color={'warning-400'}
            sizePx={22}
          />
          <Label color={'warning-400'}>We found the closest matching address. Please ensure this is correct.</Label>
        </div>
      )}
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          gap: 1rem;
        `}
      >
        {typeof billingAddress !== 'undefined' && (
          <DisableElementOverlay disabled={isEditingAddress}>
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <Label
                size={'md'}
                bold={true}
              >
                {billingAddress.name}
              </Label>
              <Label
                size={'md'}
                color={'dark-900-64'}
              >
                {billingAddressFormatted}
              </Label>
            </div>
          </DisableElementOverlay>
        )}
        {!isEditingAddress && (
          <Button
            css={css`
              width: fit-content;
            `}
            iconLeft={'Edit'}
            onClick={() => {
              setEditingAddress(true);
              if (typeof onEditing !== 'undefined') {
                onEditing(true);
              }
            }}
          >
            Use a different address…
          </Button>
        )}
      </div>
      {isEditingAddress && (
        <AddressInput
          initialAddress={billingAddress}
          restrictAddressTo={defaultAmericanAddressBusiness}
          onAddressUpdate={updatedBillingAddress => {
            setFormInput<AddressBusiness>(fieldKey, updatedBillingAddress);
            if (typeof onEditing !== 'undefined') {
              onEditing(true);
            }
          }}
          fieldKeys={{
            globalErrors: (formData: FieldKeyExpressionSegment<DisposalAddressFormData>) => formData.globalErrors,
            companyName: (formData: FieldKeyExpressionSegment<DisposalAddressFormData>) => formData.shipmentReturnAddress.name,
            phone: (formData: FieldKeyExpressionSegment<DisposalAddressFormData>) => formData.shipmentReturnAddress.phone,
            line1: (formData: FieldKeyExpressionSegment<DisposalAddressFormData>) => formData.shipmentReturnAddress.address.line1,
            line2: (formData: FieldKeyExpressionSegment<DisposalAddressFormData>) => formData.shipmentReturnAddress.address.line2,
            city: (formData: FieldKeyExpressionSegment<DisposalAddressFormData>) => formData.shipmentReturnAddress.address.city,
            state: (formData: FieldKeyExpressionSegment<DisposalAddressFormData>) => formData.shipmentReturnAddress.address.stateCode,
            country: (formData: FieldKeyExpressionSegment<DisposalAddressFormData>) => formData.shipmentReturnAddress.address.countryCode,
            zip: (formData: FieldKeyExpressionSegment<DisposalAddressFormData>) => formData.shipmentReturnAddress.address.zip,
          }}
        />
      )}
      {isEditingAddress && (
        <div
          css={css`
            display: flex;
            gap: 0.625rem;
          `}
        >
          <Button
            iconLeft={'Done'}
            theme={'primary'}
            disabled={isValidatingAddress || typeof returnShippingAddressFormInput === 'undefined'}
            loading={isValidatingAddress}
            onClick={submitForm}
          >
            Save address
          </Button>
          <Button
            iconRight={'Cancel'}
            disabled={isValidatingAddress || typeof returnShippingAddressFormInput === 'undefined'}
            onClick={() => {
              setEditingAddress(false);
              if (typeof onEditing !== 'undefined') {
                onEditing(false);
              }
            }}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};

export default DisposalAddress;
