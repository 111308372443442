import { InitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import EngagementListPageTicketCreateProvider from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/context/EngagementListPageTicketCreateProvider';
import TicketFieldAvailabilitiesProvider from '~/wm/packages/integration/packages/ticket/context/TicketFieldAvailabilitiesProvider';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import engagementDataCollectionSources from '~/wm/packages/organization/packages/engagement/model/engagementDataCollectionSources';
import EngagementActionDataCollectionProvider from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/EngagementActionDataCollectionProvider';
import EngagementActionListProvider from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/EngagementActionListProvider';
import PanelTabGroupProvider from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/PanelTabGroupProvider';
import { stringToEngagementActionStatusFilter } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/types/EngagementActionStatusFilter';
import ActiveUsersProvider from '~/wm/packages/account/packages/user/context/ActiveUsersProvider';
import * as React from 'react';
import EngagementActionCreateForm from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/packages/engagement-action-create-form/EngagementActionCreateForm';
import EngagementActionDashboardCreateControls from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-action-dashboard-panel-tab/packages/engagement-action-dashboard-create-controls/EngagementActionDashboardCreateControls';
import useEngagementActionCreate from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionCreate';
import useEngagementActionListContext from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListContext';
import EngagementListPageInitiativeLinkProvider from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/context/EngagementListPageInitiativeLinkProvider';
import InitiativeSaveActionLink from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-action-section/packages/initiative-save-action-link/InitiativeSaveActionLink';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import InitiativeLinkActionWindow from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-link-action-window/InitiativeLinkActionWindow';

export type InitiativeSaveActionSectionProps = {
  initiative: InitiativeDto;
};

const InitiativeSaveActionSection = ({ initiative }: InitiativeSaveActionSectionProps) => {
  const { organizationId } = useOrganizationContext();
  const [isLinkWindowOpen, setIsLinkWindowOpen] = React.useState(false);

  return (
    <ActiveUsersProvider>
      <PanelTabGroupProvider
        initialTabId={'Action'}
        initialFilterValueByTab={new Map([['Action', stringToEngagementActionStatusFilter('Upcoming')]])}
        initialPageByTab={new Map([['Action', 1]])}
      >
        <EngagementActionDataCollectionProvider
          createdFrom={engagementDataCollectionSources.engagementActionCreationSource.initiative}
          completedFrom={engagementDataCollectionSources.engagementActionCompletionSource.initiative}
          updatedFrom={engagementDataCollectionSources.engagementActionUpdateSource.initiative}
        >
          <TicketFieldAvailabilitiesProvider organizationId={organizationId}>
            <EngagementListPageTicketCreateProvider>
              <EngagementListPageInitiativeLinkProvider>
                <EngagementActionListProvider engagementActionGetSource={{ type: 'Initiative', id: initiative.initiativeId }}>
                  <Box
                    padding={'padding100'}
                    borderRadius={'radius400'}
                    boxShadow={'shadow100'}
                  >
                    <div
                      css={css`
                        display: flex;
                        flex-direction: row;
                        vertical-align: center;
                        width: 100%;
                      `}
                    >
                      <Header
                        size={3}
                        weight={'bold'}
                        css={css`
                          margin-bottom: 1rem;
                        `}
                      >
                        Action item
                      </Header>
                      <ButtonLink
                        iconRight={'ArrowRight'}
                        onClick={() => setIsLinkWindowOpen(true)}
                        css={css`
                          margin-left: auto;
                        `}
                      >
                        Link existing action items
                      </ButtonLink>
                    </div>
                    {isLinkWindowOpen && (
                      <EngagementActionListProvider engagementActionGetSource={{ type: 'List' }}>
                        <InitiativeLinkActionWindow
                          initiativeId={initiative.initiativeId}
                          isOpen={isLinkWindowOpen}
                          onDismiss={() => setIsLinkWindowOpen(false)}
                        />
                      </EngagementActionListProvider>
                    )}
                    <InitiativeSaveActionSectionInternal initiative={initiative} />
                  </Box>
                </EngagementActionListProvider>
              </EngagementListPageInitiativeLinkProvider>
            </EngagementListPageTicketCreateProvider>
          </TicketFieldAvailabilitiesProvider>
        </EngagementActionDataCollectionProvider>
      </PanelTabGroupProvider>
    </ActiveUsersProvider>
  );
};

/**
 * Internal component to allow us to use the context hooks
 */
const InitiativeSaveActionSectionInternal = ({ initiative }: InitiativeSaveActionSectionProps) => {
  const { organizationId } = useOrganizationContext();
  const { createEngagementAction } = useEngagementActionCreate();
  const { actions } = useEngagementActionListContext();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <EngagementActionCreateForm
        organizationId={organizationId}
        initiativeId={initiative.initiativeId}
        createEngagementAction={createEngagementAction}
      >
        <EngagementActionDashboardCreateControls
          initiativeCreatedFrom={'roadmap-quick-create'}
          isInitiative={true}
        />
      </EngagementActionCreateForm>

      {actions
        .sort((a, b) => Number(b.isPinned) - Number(a.isPinned))
        .map(action => (
          <InitiativeSaveActionLink
            key={`${action.engagementActionId}-${action.isCompleted}`}
            action={action}
          />
        ))}
    </div>
  );
};

export default InitiativeSaveActionSection;
