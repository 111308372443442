import * as React from 'react';
import IntegrationSyncLogTable from '~/wm/packages/integration/packages/integration-sync-log/packages/integration-sync-log-table/IntegrationSyncLogTable';

export type IntegrationSyncLogPageProps = {
  integrationSetupId: string;
};

const IntegrationSyncLogPage: React.FunctionComponent<IntegrationSyncLogPageProps> = ({ integrationSetupId }) => (
  <IntegrationSyncLogTable integrationSetupId={integrationSetupId} />
);

export default IntegrationSyncLogPage;
