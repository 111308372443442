import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { ExploreProduct } from '@SubscriptionClient/BeastClient/Beast/Msp/Billing/ExploreProducts/Dto.gen';
import Button from '~/neo-ui/packages/button/Button';
import { mobileBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';

export type ProductSelectionCardProps = {
  exploreProduct: ExploreProduct;
};

const ProductSelectionCard = ({ exploreProduct }: ProductSelectionCardProps) => {
  const ctaLink = '?product=' + exploreProduct.productAbbreviation;

  return (
    <div
      css={{
        display: 'grid',
        borderRadius: '8px',
        boxShadow: `0px 1px 4px 0px ${colorToCode('dark-000')}`,
        padding: '1.25rem 1rem',
        height: 'min-content',
        backgroundColor: colorToCode('light-000'),
        [`@media (max-width: ${mobileBreakpointRem}rem)`]: {
          maxWidth: '580px',
        },
      }}
    >
      <img
        css={{ height: '1.5rem', marginBottom: '1rem', display: 'block' }}
        src={exploreProduct.icon}
      />
      <p css={{ textAlign: 'left' }}>{exploreProduct.marketingText}</p>
      <Button
        size={'md'}
        anchor={{ href: ctaLink }}
        theme={'primary'}
        css={{ width: '11rem' }}
      >
        {exploreProduct.callToActionLabel}
      </Button>
    </div>
  );
};

export default ProductSelectionCard;
