import { ComponentPropsWithoutRef, useEffect, useRef } from 'react';
import Button from '~/sp-ui/buttons/Button';
import Message from '~/sp-ui/message';
import Text from '~/sp-ui/text/Text';
import theme from '~/sp-ui/theme';
import getDaythOfMonth from './getDaythOfMonth';
import ReadonlyPaymentSummary from './ui/ReadonlyPaymentSummary';
import SectionLoadingIndicator from './ui/SectionLoadingIndicator';
import { type UseQuoteSummaryApi } from './useQuoteSummaryApi';

export interface PaymentSummarySectionProps extends ComponentPropsWithoutRef<'div'> {
  isEditing: boolean;
  quoteSummaryApi: UseQuoteSummaryApi;
  onPayNow: () => void;
  canPayNow?: boolean;
}

const formatCurrency = (amountInCents: number) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amountInCents / 100);
};

const PaymentSummarySection = (props: PaymentSummarySectionProps) => {
  const { isEditing, onPayNow, quoteSummaryApi, canPayNow = true, ...rest } = props;
  const { quoteSummary, isLoading: quoteSummaryLoading, error } = quoteSummaryApi;
  const hasLoadedRef = useRef(false);

  useEffect(() => {
    if (hasLoadedRef.current) {
      return undefined;
    }

    if (!quoteSummaryLoading) {
      hasLoadedRef.current = true;
    }
  }, [quoteSummaryLoading]);

  if (quoteSummaryLoading && !hasLoadedRef.current) {
    return <SectionLoadingIndicator />;
  }

  if (!quoteSummary) {
    return null;
  }

  if (!isEditing) {
    return (
      <ReadonlyPaymentSummary
        css={{ paddingInlineStart: '48px' }}
        quoteSummary={quoteSummary}
      />
    );
  }

  const { nextAmountInCentsBeforeTaxes, nextTaxAmountInCents, totalBeforeTaxes, taxAmountInCents } = quoteSummary;

  return (
    <div
      css={{ paddingInlineStart: '48px', display: 'grid', gridTemplateColumns: '1fr 1fr', rowGap: '1rem' }}
      {...rest}
    >
      <ReadonlyPaymentSummary
        quoteSummary={quoteSummary}
        css={{ gridColumn: '1 / -1' }}
      />
      {canPayNow && (
        <>
          <div css={{ gridColumn: '1 / -1', marginTop: '1rem' }}>
            <Text css={{ gridColumn: '1 / -1', fontWeight: 700, display: 'block' }}>
              {`Today's payment ${taxAmountInCents ? '(including taxes)' : ''}`.trim()}
            </Text>
            <Text
              variant="h1"
              css={{ gridColumn: '1 / -1', fontWeight: 700 }}
            >
              {formatCurrency(totalBeforeTaxes + taxAmountInCents)} USD
            </Text>
          </div>

          {nextAmountInCentsBeforeTaxes + nextTaxAmountInCents > 0 && (
            <Text
              variant="small"
              css={{ gridColumn: '1 / -1' }}
            >
              Afterward, your estimated billed amount will be{' '}
              <Text css={{ fontWeight: 700 }}>{formatCurrency(nextAmountInCentsBeforeTaxes + nextTaxAmountInCents)}</Text> per month on the{' '}
              <Text css={{ fontWeight: 700 }}>{getDaythOfMonth()}</Text> day of each month.{' '}
              {`This estimate ${nextTaxAmountInCents ? 'includes' : 'does not include'} taxes.`}
            </Text>
          )}

          <Text
            variant="small"
            css={{ gridColumn: '1 / -1' }}
          >
            By paying now, you agree to ScalePad's{' '}
            <Text
              element="a"
              href="/pages/privacy"
              target="_blank"
              css={{ color: theme.palette.primary1, textDecoration: 'underline' }}
            >
              privacy policy
            </Text>{' '}
            and{' '}
            <Text
              element="a"
              href="/pages/terms"
              target="_blank"
              css={{ color: theme.palette.primary1, textDecoration: 'underline' }}
            >
              terms of service.
            </Text>
          </Text>
        </>
      )}

      {!!error && (
        <Message
          type="error"
          text={error}
          css={{ gridColumn: '1 / -1', marginTop: '1rem' }}
        />
      )}

      <Button
        size="large"
        css={{ justifySelf: 'start', marginTop: '1rem' }}
        onClick={onPayNow}
        disabled={quoteSummaryLoading}
        loading={quoteSummaryLoading}
      >
        {canPayNow ? 'Pay now' : 'Enter billing address'}
      </Button>
    </div>
  );
};

export default PaymentSummarySection;
