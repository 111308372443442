import * as React from 'react';
import RoadmapUrlContext from '~/wm/packages/strategy/packages/roadmap-page/context/RoadmapUrlContext';

export type RoadmapUrlProviderProps = {
  insightUrl: string;
  upgradeUrl: string;
  growUrl: string;
  organizationRegionalSettingsUrl: string;
};

const RoadmapUrlProvider: React.FunctionComponent<React.PropsWithChildren<RoadmapUrlProviderProps>> = ({
  insightUrl,
  upgradeUrl,
  growUrl,
  organizationRegionalSettingsUrl,
  children,
}) => (
  <RoadmapUrlContext.Provider
    value={{
      insightUrl,
      upgradeUrl,
      growUrl,
      organizationRegionalSettingsUrl,
    }}
  >
    {children}
  </RoadmapUrlContext.Provider>
);

export default RoadmapUrlProvider;
