import { useRef, useState, useEffect } from 'react';

/**
 * Throttle a value from updating too often
 * @param value Value to throttle
 * @param interval Interval of throttle, default 250ms
 */
const useThrottle = <T>(value: T, interval = 250) => {
  const lastExecutedMs = useRef<number>(Date.now());
  const [valueThrottled, setValueThrottled] = useState<T>(value);

  useEffect(() => {
    if (Date.now() >= lastExecutedMs.current + interval) {
      lastExecutedMs.current = Date.now();
      setValueThrottled(value);
      return;
    } else {
      const timerId = setTimeout(() => {
        lastExecutedMs.current = Date.now();
        setValueThrottled(value);
      }, interval);

      return () => clearTimeout(timerId);
    }
  }, [value, interval]);

  return valueThrottled;
};

export default useThrottle;
