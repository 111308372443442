import { ScopeKey } from '@AssetManagementClient/BeastClient/Beast/Settings/Scope.gen';
import * as React from 'react';
import SettingsSection from '~/wm/packages/settings/packages/settings-section/SettingsSection';
import WarrantyCoverageSettingsConfigurable from '~/wm/packages/warranty/packages/coverage/packages/settings/configurable/WarrantyCoverageSettingsConfigurable';

export type WarrantyCoverageSettingsProps = {
  scopeKey: ScopeKey;
  appSettingsUrl?: string;
};

const WarrantyCoverageSettings: React.FunctionComponent<WarrantyCoverageSettingsProps> = ({ scopeKey, appSettingsUrl }) => (
  <SettingsSection
    title="Warranty coverage"
    titleButtonText={appSettingsUrl ? 'Warranty coverage settings for all clients' : undefined}
    titleButtonIcon={appSettingsUrl ? 'Cog' : undefined}
    titleButtonLink={appSettingsUrl ? appSettingsUrl : undefined}
  >
    <WarrantyCoverageSettingsConfigurable scopeKey={scopeKey} />
  </SettingsSection>
);

export default WarrantyCoverageSettings;
