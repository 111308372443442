import {
  OauthTile,
  OauthTileProps,
} from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/OauthTile';
import invariant from 'tiny-invariant';
import { formatDateFriendly } from '~/extensions/packages/date/formatDate';

export type OauthTileControllerProps = {
  integrationStatus: string | undefined;
  clientId: string | undefined;
  isDisabled: boolean;

  // I'm pretty sure integrationObfuscatedId will never be undefined, because once someone has saved their oauth credentials, we set an integration id.
  // However, the PHP logic does assume it could be undefined, so I'm leaving that in.
  integrationObfuscatedId: string | undefined;
  oauthUrl: string;
  redirectUri: string;
};

export const calculateOauthInformation = (
  integrationStatus: string | undefined,
): {
  status: OauthTileProps['status'];
  title: string;
  description: string;
} => {
  if (integrationStatus === undefined) {
    return {
      status: 'noLastAttempt',
      title: 'Authorize ScalePad',
      description: 'Authorize ScalePad using the client ID you have entered above to sync your data.',
    };
  }
  const integrationStatusParsed = JSON.parse(integrationStatus) as { StatusUpdated: string; Status: string };

  if (integrationStatusParsed.Status === 'Success') {
    const statusUpdated = formatDateFriendly(new Date(integrationStatusParsed.StatusUpdated));

    return {
      status: 'lastAttemptSuccess',
      title: "We're authorized!",
      description: `ScalePad last authorized on ${statusUpdated}`,
    };
  }

  return {
    status: 'lastAttemptFailed',
    title: 'Authorization Failed',
    description: 'Authorize ScalePad using the client ID you have entered above to sync your data.',
  };
};

const calculateOauthUrl = (integrationObfuscatedId: string | undefined, oauthUrl: string, clientId: string, redirectUri: string) =>
  `${oauthUrl}&client_id=${clientId}&redirect_uri=${redirectUri}${integrationObfuscatedId ? '&state=' + integrationObfuscatedId : ''}`;

export const OauthTileController: React.FunctionComponent<OauthTileControllerProps> = ({
  integrationStatus,
  integrationObfuscatedId,
  isDisabled,
  clientId,
  oauthUrl,
  redirectUri,
}) => {
  invariant(!!clientId, 'clientId is required for OauthTileController');
  const { status, title, description } = calculateOauthInformation(integrationStatus);

  const oauthUrlWithParams = calculateOauthUrl(integrationObfuscatedId, oauthUrl, clientId, redirectUri);

  return (
    <OauthTile
      onClick={() => {
        const authWindow = window.open(oauthUrlWithParams, '_blank', 'width=800,height=800');

        // when the window is closed, reload the page
        const interval = setInterval(() => {
          if (!authWindow || authWindow.closed) {
            clearInterval(interval);
            window.location.reload();
          }
        }, 1000);
      }}
      isDisabled={isDisabled}
      status={status}
      title={title}
      description={description}
    />
  );
};
