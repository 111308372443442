import * as React from 'react';
import { css } from '@emotion/react';
import OrderPaymentFreeCostModule from '~/wm/packages/disposal/wizard/section/packages/module/order-payment-free-cost-module/OrderPaymentFreeCostModule';
import OrderSummaryTermOfServiceModule from '~/wm/packages/disposal/wizard/section/packages/module/order-summary-term-of-service-module/OrderSummaryTermOfServiceModule';

export type OrderSummaryFreeCostModuleProps = {
  disposalTermsOfServiceUrl: string;
  termsOfServiceUrl: string;
  brandName: string;
};

const OrderSummaryFreeCostModule = ({ disposalTermsOfServiceUrl, termsOfServiceUrl, brandName }: OrderSummaryFreeCostModuleProps) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    `}
  >
    <OrderPaymentFreeCostModule />
    <OrderSummaryTermOfServiceModule
      disposalTermsOfServiceUrl={disposalTermsOfServiceUrl}
      termsOfServiceUrl={termsOfServiceUrl}
      brandName={brandName}
    />
  </div>
);

export default OrderSummaryFreeCostModule;
