import * as React from 'react';
import DataTable, { DataTableColumn } from '../data-table/DataTable';

export type AsyncDataTableProps<T extends object> = {
  tableTitle?: string;
  tableDescription?: string;
  columns: DataTableColumn<T>[];
  onFetchData: () => Promise<{ data: T[] }>;
  EmptyStatePlaceholder?: string | React.ComponentType;
};
const AsyncDataTable = <T extends object>({
  tableTitle,
  tableDescription,
  columns,
  onFetchData,
  EmptyStatePlaceholder,
}: AsyncDataTableProps<T>) => {
  const [data, setData] = React.useState<T[] | 'loading'>('loading');

  React.useEffect(() => {
    (async () => {
      const { data } = await onFetchData();
      setData(data);
    })();
  }, [onFetchData]);

  return (
    <DataTable
      title={tableTitle}
      description={tableDescription}
      data={data}
      columns={columns}
      EmptyStatePlaceholder={EmptyStatePlaceholder}
    />
  );
};

export default AsyncDataTable;
