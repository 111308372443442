import BannerNotice from '~/neo-ui/packages/banner/packages/banner-notice/BannerNotice';
import ToggleSwitch from '~/neo-ui/packages/toggle/packages/toggle-switch/ToggleSwitch';
import * as React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { dashboardUserUserPreferenceUpdate } from '@DashboardClient/DashboardClientMsp.gen';
import { css } from '@emotion/react';

export type DashboardUserPreferenceSectionProps = {
  /**
   *  Whether to display old or new dashboard
   */
  isNewDashboard: boolean;

  /**
   *  Whether or not the dashboard banner is closed
   */
  isDashboardBannerClosed: boolean;

  /**
   *  Url to the help centre article to explain the new dashboard
   */
  helpGuideUrl: string;
};

const DashboardUserPreferenceSection: React.FunctionComponent<DashboardUserPreferenceSectionProps> = ({
  isNewDashboard,
  isDashboardBannerClosed,
  helpGuideUrl,
}) => {
  const { callApi } = useApi();
  const [toggleNewDashboard, setToggleNewDashboard] = React.useState(isNewDashboard);
  const [closeDashboardBanner, setCloseDashboardBanner] = React.useState(isDashboardBannerClosed);
  const initialRender = React.useRef(true);

  const onDashboardToggle = async (newValue: boolean) => {
    setToggleNewDashboard(newValue);
  };

  React.useEffect(() => {
    // No need to call API on page load
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    const setNewUserPreference = async () => {
      await callApi(() =>
        dashboardUserUserPreferenceUpdate({
          isNewDashboard: toggleNewDashboard,
          isDashboardBannerClosed: closeDashboardBanner,
        }),
      );
    };
    setNewUserPreference().catch(() => {
      // eslint-disable-next-line no-console
      console.error('[ERROR]: An exception occurred when setting user preference');
    });

    // Check if the button to display new vs old dashboard was toggled
    if (toggleNewDashboard !== isNewDashboard) {
      // Refresh page to display preferred dashboard
      window.location.reload();
    }
  }, [toggleNewDashboard, closeDashboardBanner, isNewDashboard, callApi]);

  return (
    <div
      css={css`
        margin-top: 20px;
      `}
    >
      {closeDashboardBanner ? null : (
        <BannerNotice
          title={'See it all in your new dashboard'}
          description={
            <div>
              We've redesigned our dashboard to provide you with a high-level summary of your MSP's digital health, quick metrics and
              actionable insights.{' '}
              <a
                css={css`
                  color: #0271f2;
                `}
                href={helpGuideUrl}
                target={'_blank'}
              >
                Learn more
              </a>
            </div>
          }
          imageSource={'/i/graphic/dashboard/general/primary.svg'}
          badge={'BETA'}
          closeable={true}
          onClose={() => {
            setCloseDashboardBanner(true);
          }}
        />
      )}
      <div
        css={css`
          float: right;
          display: flex;
          vertical-align: middle;
          position: relative;
          z-index: 999;
        `}
      >
        <span
          css={css`
            margin-top: 0.15rem;
            margin-right: 0.375rem;
          `}
        >
          New Dashboard
        </span>
        <ToggleSwitch
          checked={toggleNewDashboard}
          onChange={onDashboardToggle}
          theme={'positive'}
        />
      </div>
    </div>
  );
};

export default DashboardUserPreferenceSection;
