import { HardwareDueSoonThresholdSettingsAvailabilities } from '@AssetManagementClient/BeastClient/Beast/HardwareSettings/Model/DueSoonThreshold/Availabilities.gen';
import { SettingsValueNested } from '@AssetManagementClient/BeastClient/Beast/Settings/Model.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import FormSelectInput, { FormSelectOption } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';

export type HardwareDueSoonThresholdSettingsFormProps = {
  availabilities: HardwareDueSoonThresholdSettingsAvailabilities | undefined;
  disabled?: boolean;
};

const HardwareDueSoonThresholdSettingsForm: React.FunctionComponent<HardwareDueSoonThresholdSettingsFormProps> = ({
  availabilities,
  disabled = false,
}) => (
  <div
    css={css`
      max-width: 7.5rem;
    `}
  >
    <FormSelectInput
      fieldKey={(settings: FieldKeyExpressionSegment<SettingsValueNested.HardwareDueSoonThresholdSettings>) =>
        settings.value.dueSoonThreshold
      }
      disabled={disabled}
      options={
        availabilities?.hardwareDueSoonThresholdRange.map(
          (days): FormSelectOption => ({
            // translate one year worth of days to a '1 year` label
            label: days === 365 ? `1 year` : `${days} days`,
            value: days.toString(),
          }),
        ) ?? []
      }
    />
  </div>
);

export default HardwareDueSoonThresholdSettingsForm;
