import React from 'react';
import useAssetConsoleRequest from '~/wm/packages/asset/packages/console/hooks/useAssetConsoleRequest';
import AsyncConsole from '~/neo-ui/packages/table/packages/console/AsyncConsole';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/AssetScope/Logic/AssetScopeFactoryNested.gen';
import { getFilterFrameFromUrl } from '~/wm/packages/asset/packages/console/packages/forecast/packages/forecast-filter/ForecastFilter';
import { css } from '@emotion/react';
import useConsole from '~/neo-ui/packages/table/packages/console/hook/useConsole';
import ConsoleContext from '~/neo-ui/packages/table/packages/console/contexts/ConsoleContext';
import AssetInsightDropdown from '~/wm/packages/asset/packages/console/packages/insight/AssetInsightDropdown';
import { CategoryPayload } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Asset/Model/Dto/InsightOptionPayloadNested.gen';
import updateUrlParameter from '~/neo-ui/packages/table/packages/url-routing/updateUrlParameter';
import { Response as RowResponse } from '@AssetManagementClient/AssetManagement/Packages/Asset/Console/Controller/AssetConsoleControllerNested.gen';
import Window from '~/neo-ui/packages/window/Window';
import Button from '~/neo-ui/packages/button/Button';
import { Styleable } from '~/neo-ui/model/capacity';

export type AssetConsoleWindowProps = {
  isOpen: boolean;
  onDismiss: () => void;

  assetType: Enum;
  frontendScope: FrontendScope;

  clientName?: string;
  assetTypeName?: string;
  onAssetSelected: (assetIds: string[]) => void;

  canSelectDisplayColumns?: boolean;
} & Styleable;

const AssetConsoleWindow: React.FunctionComponent<AssetConsoleWindowProps> = ({
  isOpen,
  onDismiss,
  assetType,
  frontendScope,
  clientName,
  assetTypeName = 'assets',
  onAssetSelected,
  canSelectDisplayColumns = true,
  className,
}) => {
  const [insightOptions, setInsightOptions] = React.useState<CategoryPayload[]>();

  const { reloadAssetRows, reloadIds } = useAssetConsoleRequest(assetType, frontendScope);

  // A function to run after receiving the console response
  const onConsoleResponse = React.useCallback(
    (response: RowResponse | undefined) => {
      if (typeof insightOptions === 'undefined') {
        const options = response?.insightOptions;
        setInsightOptions(options);
      }
    },
    // This function only needs to be created once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { reloadRows, providerValue } = useConsole(reloadAssetRows, getFilterFrameFromUrl(), ['Forecast'], onConsoleResponse);

  const consoleContextReset = React.useCallback(() => {
    updateUrlParameter('Forecast', undefined);
    providerValue.setFilterFrame(getFilterFrameFromUrl);
  }, [providerValue]);

  return (
    <ConsoleContext.Provider value={providerValue}>
      <Window
        title={'Add assets'}
        maxWidthRem={82}
        isOpen={isOpen}
        onDismiss={onDismiss}
        footerProps={{
          rightControls: [
            {
              expanded: (
                <Button
                  iconRight={'Add'}
                  disabled={providerValue.selectedConsoleItems.selectedItems.size === 0}
                  onClick={() => {
                    onAssetSelected([...providerValue.selectedConsoleItems.selectedItems]);
                    onDismiss();
                  }}
                >
                  Add assets
                </Button>
              ),
            },
          ],
        }}
        className={className}
      >
        <AsyncConsole
          onFetchRows={reloadRows}
          onFetchIds={reloadIds}
          topFilterLine={
            <div
              css={css`
                width: 100%;
              `}
            >
              <AssetInsightDropdown options={insightOptions} />
            </div>
          }
          hasCheckboxes={true}
          noResultsText={`No ${assetTypeName}${typeof clientName === 'undefined' ? '' : ` at ${clientName}`} match these filters.`}
          reset={consoleContextReset}
          displayFeatures={undefined}
          legacyDownloadUrl={''}
          canDownload={false}
          clearFiltersOnFirstLoad={true}
          canSelectDisplayColumns={canSelectDisplayColumns}
        />
      </Window>
    </ConsoleContext.Provider>
  );
};

export default AssetConsoleWindow;
