import * as React from 'react';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import FormInputTextInternal from '~/neo-ui/packages/form/packages/form-input/FormInputTextInternal';
import InputTitle, { InputTitleSizes } from '~/neo-ui/packages/input/packages/input-title/InputTitle';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';

import useFormInputBuilder from '~/neo-ui/packages/form/packages/form-input/hooks/useFormInputBuilder';

export type FormTitleInputProps<T> = {
  fieldKey: FieldKeyExpression<T>;

  /**
   * Map the raw input value to something before it affects the form data
   */
  mapValue?: <U>(value: string) => string;

  /**
   * Callback for changes to the value
   */
  onChange?: (value: string) => void;

  /**
   * Focus event for the input
   */
  onFocus?: () => void;

  size?: InputTitleSizes;

  placeholder?: string;
  prependIcon?: IconType;
  prependCharacter?: string;
  canClear?: boolean;
  disabled?: boolean;

  optimizePerformance?: boolean;

  /**
   * Will attempt to give focus to the input on first render
   */
  hasDefaultFocus?: boolean;
  /**
   * Will attempt to select input text on first render
   */
  hasDefaultSelect?: boolean;

  /**
   * Setting to false will remove user's view of a form error
   */
  showFormError?: boolean;

  /**
   * Setting to true will fix the position of form error to under input box
   */
  fixedErrorPosition?: boolean;

  /**
   * Trigger error styles directly on the input
   */
  isInvalid?: boolean;

  /**
   * This is useful in cases where you don't want
   * clicking on the input to trigger any upstream onClick event.
   *
   * Common example: You have an input nested within a clickable element.
   */
  preventOnClickPropagation?: boolean;
} & Styleable &
  Themeable;

/**
 * Form input for titles
 *
 * Specify a header size to replicate the size and weight. Defaults to body text
 *
 * For future usage: passing `type` in inputProps will change the input type seamlessly
 */
const FormTitleInput = <T,>({
  fieldKey,
  mapValue,
  onChange,
  prependIcon,
  prependCharacter,
  canClear = false,
  placeholder,
  disabled = false,
  optimizePerformance = false,
  hasDefaultFocus = false,
  hasDefaultSelect = false,
  onFocus,
  showFormError = true,
  fixedErrorPosition = false,
  isInvalid = false,
  theme,
  size = 'md',
  preventOnClickPropagation,
  className,
}: FormTitleInputProps<T>) => {
  const { touched, error } = useFormInputBuilder<T>(fieldKey);

  return (
    <FormInputTextInternal
      fieldKey={fieldKey}
      optimizePerformance={optimizePerformance}
      mapValue={mapValue}
      onChange={onChange}
      className={className}
      showError={showFormError}
      fixedErrorPosition={fixedErrorPosition}
    >
      {({ field, setFieldValue }) => (
        <InputTitle
          value={field.value}
          onChange={value => setFieldValue(value)}
          // Pass field due to conditionally optimized props
          formField={field}
          onClear={() => setFieldValue('')}
          prependIcon={prependIcon}
          prependCharacter={prependCharacter}
          canClear={canClear}
          placeholder={placeholder}
          disabled={disabled}
          hasDefaultFocus={hasDefaultFocus}
          hasDefaultSelect={hasDefaultSelect}
          onFocus={onFocus}
          isInvalid={isInvalid || (typeof error !== 'undefined' && touched)}
          showError={showFormError}
          theme={theme}
          size={size}
          preventOnClickPropagation={preventOnClickPropagation}
        />
      )}
    </FormInputTextInternal>
  );
};
export default FormTitleInput;
