import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Dropdown, { DropdownOption } from '~/neo-ui/packages/dropdown/Dropdown';
import MultiSelect, { MultiSelectOption } from '~/neo-ui/packages/select/packages/multi-select/MultiSelect';
import ToggleSwitch from '~/neo-ui/packages/toggle/packages/toggle-switch/ToggleSwitch';
import Box from '~/neo-ui/packages/box/Box';
import useInitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesContext';
import { RoadmapInitiativeViewMode } from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/RoadmapV2';
import { RoadmapViewMode } from '~/wm/packages/strategy/packages/roadmap-page/RoadmapPage';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import * as React from 'react';
import useRoadmapUrlContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapUrlContext';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';
import useRoadmapInitiativesContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapInitiativesContext';

export type RoadmapFiltersProps = {
  selectedViewMode: DropdownOption<RoadmapInitiativeViewMode>;
  onChangeSelectedViewMode: (value: DropdownOption<RoadmapInitiativeViewMode>) => void;

  selectedStatuses: MultiSelectOption[];
  onChangeSelectedStatuses: (values: MultiSelectOption[]) => void;

  selectedPriorities: MultiSelectOption[];
  onChangeSelectedPriorities: (values: MultiSelectOption[]) => void;

  showUnscheduled: boolean;
  onChangeShowUnscheduled: (value: boolean) => void;

  roadmapViewMode: RoadmapViewMode;
};

const RoadmapFilters = ({
  selectedViewMode,
  onChangeSelectedViewMode,
  selectedStatuses,
  onChangeSelectedStatuses,
  selectedPriorities,
  onChangeSelectedPriorities,
  showUnscheduled,
  onChangeShowUnscheduled,
  roadmapViewMode,
}: RoadmapFiltersProps) => {
  const { startingFiscalYear } = useRegionalSettingsInfoContext();
  const { organizationRegionalSettingsUrl } = useRoadmapUrlContext();
  const { availabilities } = useInitiativeAvailabilitiesContext();
  const { setRoadmapsFiscalQuarterPageSize } = useRoadmapInitiativesContext();
  const statusOptions: MultiSelectOption[] = availabilities.statusAvailabilities.map(availability => ({
    value: availability.id,
    label: availability.displayLabel,
  }));
  const priorityOptions: MultiSelectOption[] = availabilities.priorityAvailabilities.map(availability => ({
    value: availability.key,
    label: availability.label,
  }));
  return (
    <Box
      theme={'none'}
      background={'foregroundAccent'}
      padding={'padding200'}
      css={css`
        display: flex;
        gap: 1.5rem;
        flex-wrap: wrap;
      `}
    >
      {roadmapViewMode === 'Roadmap' && (
        <div
          css={css`
            flex-basis: 12.5rem;
          `}
        >
          <Label
            bold={true}
            css={css`
              margin-bottom: 0.5rem;
            `}
          >
            Card info
          </Label>
          <Dropdown
            selectedOption={selectedViewMode}
            options={[
              { value: 'Description', label: 'View description' },
              { value: 'Fees', label: 'View fees' },
            ]}
            buttonDropdown={'button-outline'}
            onOptionSelected={onChangeSelectedViewMode}
            css={css`
              width: 100%;
            `}
          />
        </div>
      )}
      <div
        css={css`
          flex-basis: 12.5rem;
        `}
      >
        <Label
          bold={true}
          css={css`
            margin-bottom: 0.5rem;
          `}
        >
          Status
        </Label>
        <MultiSelect
          onChange={onChangeSelectedStatuses}
          selectedOptions={selectedStatuses}
          label={'Status'}
          options={statusOptions}
          css={css`
            width: 100%;
          `}
        />
      </div>
      <div
        css={css`
          flex-basis: 12.5rem;
        `}
      >
        <Label
          bold={true}
          css={css`
            margin-bottom: 0.5rem;
          `}
        >
          Priority
        </Label>
        <MultiSelect
          onChange={onChangeSelectedPriorities}
          selectedOptions={selectedPriorities}
          label={'Priority'}
          options={priorityOptions}
          css={css`
            width: 100%;
          `}
        />
      </div>
      <div
        css={css`
          flex-basis: 12.5rem;
        `}
      >
        <Label
          bold={true}
          css={css`
            margin-bottom: 0.5rem;
          `}
        >
          Show not scheduled
        </Label>
        <ToggleSwitch
          onChange={checked => {
            onChangeShowUnscheduled(checked);
            setRoadmapsFiscalQuarterPageSize(checked ? 3 : 4);
          }}
          checked={showUnscheduled}
          theme={'positive'}
        />
      </div>
      <div
        css={css`
          flex-basis: 12.5rem;
          margin-left: auto;
        `}
      >
        <Label
          bold={true}
          css={css`
            margin-bottom: 0.5rem;
          `}
        >
          Current fiscal year started
        </Label>
        <ButtonLink
          size={'sm'}
          theme={'secondary'}
          iconRight={'Cog'}
          anchor={{ href: organizationRegionalSettingsUrl }}
          css={css`
            min-width: 6rem;
          `}
        >
          {startingFiscalYear}
        </ButtonLink>
      </div>
    </Box>
  );
};

export default RoadmapFilters;
