import { useContext } from 'react';
import RoadmapUrlContext from '~/wm/packages/strategy/packages/roadmap-page/context/RoadmapUrlContext';

const useRoadmapUrlContext = () => {
  const roadmapUrlContext = useContext(RoadmapUrlContext);
  if (!roadmapUrlContext) {
    throw new Error('Tried to use RoadmapUrlContext outside of an RoadmapUrlProvider');
  }

  return roadmapUrlContext;
};

export default useRoadmapUrlContext;
