import Size from '~/neo-ui/model/Size';
import { Styleable } from '~/neo-ui/model/capacity';
import Button from '~/neo-ui/packages/button/Button';
import routes from '~/router/types/routes';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';

export type EngagementActionInitiativeButtonProps = {
  size: Exclude<Size, 'xl'>;
  initiativeId: string;
} & Styleable;

const EngagementActionInitiativeButton = ({ size, initiativeId, className }: EngagementActionInitiativeButtonProps) => {
  const { organizationId } = useOrganizationContext();

  return (
    <Button
      className={className}
      size={size}
      iconLeft={'Initiative'}
      anchor={{
        href: routes.ORGANIZATION_STRATEGY_ROADMAP_INITIATIVE_VIEW.build([organizationId, initiativeId]),
        openInNewTab: false,
      }}
      theme={'primary'}
      preventOnClickPropagation={true}
    />
  );
};

export default EngagementActionInitiativeButton;
