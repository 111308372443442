import * as React from 'react';
import Theme from '~/neo-ui/packages/color/Theme';
import Search, { SearchConfigureProps } from '../../Search';
import Color from '~/neo-ui/packages/color/Color.gen';

export type SearchAsyncProps<TData> = SearchConfigureProps<TData> & {
  /**
   * Provide the IO call to search based on provided query
   */
  onSearch: (query: string) => Promise<TData[]>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  theme?: Theme;
  activeColor?: Color;
  renderTokenLabel?: (selectedItem: TData) => string;
};

const SearchAsync = <TData extends Record<string, unknown>>({
  placeholder,
  itemKey,
  renderSearchRow,
  onSearch,
  selection,
  onKeyDown,
  theme,
  activeColor,
  renderTokenLabel,
}: SearchAsyncProps<TData>) => {
  const [options, setOptions] = React.useState<TData[]>([]);
  const [isLoading, setLoading] = React.useState(false);

  return (
    <Search
      isLoading={isLoading}
      placeholder={placeholder}
      options={options}
      onSearch={query => {
        setLoading(true);
        onSearch(query)
          .then(setOptions)
          .catch(_ => setOptions([]))
          .finally(() => setLoading(false));
      }}
      itemKey={itemKey}
      selection={selection}
      renderSearchRow={renderSearchRow}
      onKeyDown={onKeyDown}
      theme={theme}
      activeColor={activeColor}
      renderTokenLabel={renderTokenLabel}
    />
  );
};

export default SearchAsync;
