import * as React from 'react';
import AutoEmailReportUrlContext from '~/wm/packages/organization/packages/auto-email-report/context/AutoEmailReportUrlContext';

export type AutoEmailReportUrlProviderProps = {
  autoEmailReportEmailTemplateEditUrl: string;
};

const AutoEmailReportUrlProvider: React.FunctionComponent<React.PropsWithChildren<AutoEmailReportUrlProviderProps>> = ({
  autoEmailReportEmailTemplateEditUrl,
  children,
}) => (
  <AutoEmailReportUrlContext.Provider
    value={{
      autoEmailReportEmailTemplateEditUrl,
    }}
  >
    {children}
  </AutoEmailReportUrlContext.Provider>
);

export default AutoEmailReportUrlProvider;
