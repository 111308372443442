import { css } from '@emotion/react';
import * as React from 'react';
import SelectListItem from '~/neo-ui/packages/list/packages/select-list/packages/select-list-item/SelectListItem';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import SelectOptionGroup from '~/neo-ui/packages/select/model/SelectOptionGroup';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Color from '~/neo-ui/packages/color/Color.gen';

export type SelectListProps = {
  options: SelectOptionGroup[];
  selectedOptionValue: SelectOption | undefined;
  onOptionSelected: (option: SelectOption) => void;
  boldOptions?: boolean;
  optionPadding?: string;
  selectedBgColor?: Color;
  bgColor?: Color;
  hoverBgColor?: Color;
  horizontalDividers?: boolean;
  isHorizontal?: boolean;
  usesInversion?: boolean;
};

const horizontalCss = css`
  flex-direction: row;
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
  & > * {
    flex-grow: 1;
  }
`;

/**
 * This is a list component with selectable list item
 * An item is selected by clicking on it
 */
const SelectList: React.FunctionComponent<SelectListProps> = ({
  options,
  selectedOptionValue,
  onOptionSelected,
  boldOptions = false,
  optionPadding = '0.9375rem',
  selectedBgColor = 'primary-400',
  bgColor = 'blank',
  hoverBgColor = 'primary-050',
  horizontalDividers = true,
  isHorizontal = false,
  usesInversion = false,
}) => (
  <div css={isHorizontal ? horizontalCss : ''}>
    {options.map((group, index) => (
      <div key={index}>
        {group.title && (
          <Label
            bold={true}
            css={css`
              margin-bottom: 0.625rem;
            `}
            muted={true}
            size={'sm'}
          >
            {group.title}
          </Label>
        )}
        {group.options.map(option => (
          <SelectListItem
            key={option.value}
            option={option}
            selectedBgColor={selectedBgColor}
            bgColor={bgColor}
            hoverBgColor={hoverBgColor}
            selectedOption={selectedOptionValue}
            boldLabel={boldOptions}
            padding={optionPadding}
            onSelect={() => onOptionSelected(option)}
            usesInversion={usesInversion}
          />
        ))}
        {index < options.length - 1 && horizontalDividers && !isHorizontal && (
          <hr
            css={css`
              margin-top: 0.625rem;
              margin-bottom: 0.625rem;
              width: 100%;
            `}
          />
        )}
        {index < options.length - 1 && !horizontalDividers && !isHorizontal && (
          <div
            css={css`
              padding-bottom: 0.625rem;
            `}
          />
        )}
      </div>
    ))}
  </div>
);
export default SelectList;
