import * as Yup from 'yup';
import { LifecycleReportCustomizationSettingsFormData } from '~/neo-ui/packages/table/packages/console/download/packages/lifecycle-report-download-button/packages/lifecycle-report-customization-settings-form/LifecycleReportCustomizationSettingsForm';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const lifecycleReportCustomizationSettingsFormValidationSchema: Yup.ObjectSchema<any> = Yup.object().shape<
  Partial<LifecycleReportCustomizationSettingsFormData>
>({
  showCpu: Yup.boolean().required('Required'),
  showRam: Yup.boolean().required('Required'),
  showStorage: Yup.boolean().required('Required'),
  shouldSaveDefaultSettings: Yup.boolean().required('Required'),
});

export default lifecycleReportCustomizationSettingsFormValidationSchema;
