import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import TreeImpactAccountSummaryCard from '~/wm/packages/tree-impact/packages/tree-impact-account-summary-card/TreeImpactAccountSummaryCard';

export type DisposalTreeImpactAccountSummaryCardProps = {
  helpGuideUrl: string;
} & Styleable;

const DisposalTreeImpactAccountSummaryCard: React.FunctionComponent<DisposalTreeImpactAccountSummaryCardProps> = ({
  helpGuideUrl,
  className,
}) => (
  <TreeImpactAccountSummaryCard
    className={className}
    serviceId={'disposal'}
    helpGuideUrl={helpGuideUrl}
  />
);

export default DisposalTreeImpactAccountSummaryCard;
