import useEngagementListPageTicketCreateContext from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/context/hooks/useEngagementListPageTicketCreateContext';
import TicketErrorModalOrSuccess from '~/wm/packages/integration/packages/ticket/packages/ticket-error-modal-or-success/TicketErrorModalOrSuccess';
import TicketCreateForm, {
  descriptionFieldKeyId,
  titlefieldKeyId,
} from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/TicketCreateForm';
import TicketCreateWindow from '~/wm/packages/integration/packages/ticket/packages/ticket-create-window/TicketCreateWindow';
import useTicketFieldAvailabilitiesContext from '~/wm/packages/integration/packages/ticket/context/hooks/useTicketFieldAvailabilitiesContext';
import formatTicketTitleEngagementAction from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/format/formatTicketTitleEngagementAction';
import formatTicketDescriptionEngagementAction from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/format/formatTicketDescriptionEngagementAction';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import { useState } from 'react';
import useEngagementActionTicketCreate from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionTicketCreate';

const EngagementActionListTicketCreateWindowWrapper = () => {
  const { selectedAction, setSelectedAction } = useEngagementListPageTicketCreateContext();
  const { isOrganizationRegisteredForTickets, hasTicketIntegration, ticketFieldOptions } = useTicketFieldAvailabilitiesContext();
  const { organizationName } = useOrganizationContext();
  const [hasError, setHasError] = useState(false);

  const { createEngagementActionTicket, isCreatingTicket } = useEngagementActionTicketCreate({
    onFailure: () => {
      setHasError(true);
    },
    onSuccess: () => {
      setSelectedAction(undefined);
    },
  });

  const onDismiss = () => {
    setSelectedAction(undefined);
  };

  return typeof selectedAction !== 'undefined' ? (
    <TicketErrorModalOrSuccess
      hasTicketIntegration={hasTicketIntegration}
      isOrganizationRegisteredForTickets={isOrganizationRegisteredForTickets}
      ticketFieldOptions={ticketFieldOptions}
      onDismiss={onDismiss}
      successComponent={
        <TicketCreateForm
          onCreateTicket={fieldValues => {
            createEngagementActionTicket({ actionId: selectedAction?.engagementActionId, fieldValues });
          }}
          defaultFormData={{
            ticketFieldValues: {
              [titlefieldKeyId]: formatTicketTitleEngagementAction(selectedAction.description, ticketFieldOptions),
              [descriptionFieldKeyId]: formatTicketDescriptionEngagementAction(
                selectedAction.description,
                selectedAction.assignedUsers,
                typeof selectedAction.dueAt !== 'undefined' ? new Date(selectedAction.dueAt) : undefined,
                organizationName,
                ticketFieldOptions,
              ),
            },
          }}
        >
          <TicketCreateWindow
            fieldOptions={ticketFieldOptions}
            hasError={hasError}
            isOpen={true}
            isCreatingTicket={isCreatingTicket}
            onDismiss={onDismiss}
          />
        </TicketCreateForm>
      }
    />
  ) : null;
};

export default EngagementActionListTicketCreateWindowWrapper;
