import { organizationEngagementActionCreate } from '@AssetManagementClient/AssetManagementClientMsp.gen';

import useApi from '~/wm/packages/api/hook/useApi';
import { useCallback, useState } from 'react';
import useEngagementActionListContext from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListContext';
import { TicketCreateFieldValueDto } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Dto/Model.gen';
import useEngagementActionDataCollectionContext from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionDataCollectionContext';
import { EngagementActionInitiativeLinkPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';

export type EngagementActionCreatePayload = {
  organizationId: string;
  description: string;
  dueAt: string | undefined;
  assignedUserIds: string[];
  ticketFieldValues: TicketCreateFieldValueDto[];
  initiativeLinkPayload: EngagementActionInitiativeLinkPayloadDto | undefined;
};

type CreateActionFunction = (payload: EngagementActionCreatePayload) => void;

export type UseEngagementActionCreate = () => {
  createEngagementAction: (payload: EngagementActionCreatePayload) => void;
  isCreating: boolean;
};

const useEngagementActionCreate: UseEngagementActionCreate = () => {
  const { addCreateAnimation } = useEngagementActionListContext();
  const { callApi } = useApi();
  const [isLoading, setLoading] = useState(false);
  const { createdFrom } = useEngagementActionDataCollectionContext();

  const createEngagementAction = useCallback<CreateActionFunction>(
    async payload => {
      setLoading(true);

      const response = await callApi(() =>
        organizationEngagementActionCreate({
          organizationId: payload.organizationId,
          createPayload: {
            description: payload.description,
            dueAt: payload.dueAt,
            assignedUserIds: payload.assignedUserIds,
            createdFrom,
            ticketPayload: payload.ticketFieldValues.length > 0 ? { ticketFieldValues: payload.ticketFieldValues } : undefined,
            initiativeLinkPayload: payload.initiativeLinkPayload,
          },
        }),
      );

      if (!response) {
        setLoading(false);
        return;
      }

      addCreateAnimation(response.actionId);
    },
    [addCreateAnimation, callApi, createdFrom],
  );

  return {
    isCreating: isLoading,
    createEngagementAction,
  };
};

export default useEngagementActionCreate;
