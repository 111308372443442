import { ActivitySearchResult } from '@BeastClient/Activity/Search/Model/Query.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import List from '~/legacy-ui/packages/list/List';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { useActivitySummary } from '~/wm/packages/activity/packages/activity-summary/hooks/useActivitySummary';
import ActivitySummaryItem from '~/wm/packages/activity/packages/activity-summary/packages/activity-summary-item/ActivitySummaryItem';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ActivitySummaryProps = {
  activityLogUrl: string;
};

const ActivitySummary: React.FunctionComponent<ActivitySummaryProps> = ({ activityLogUrl }) => {
  const { alertEntries, dismissSummaryItem } = useActivitySummary();

  if (typeof alertEntries === 'undefined' || alertEntries.length === 0) {
    return null;
  }

  return (
    <div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
        `}
      >
        <div
          css={css`
            margin-bottom: 1.25rem;
          `}
        >
          <a
            href={activityLogUrl}
            css={css`
              color: inherit;
              text-decoration: inherit;
              margin: 0;

              &:hover {
                text-decoration: none;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                transition: 0.5s;

                .dashboard-header-cta-text {
                  opacity: 0;
                  transition: 0.5s;
                }

                h4 {
                  color: ${colorToCode('primary-400')};
                }

                &:hover {
                  .dashboard-header-cta-text {
                    opacity: 1;
                  }

                  h2 {
                    color: ${colorToCode('warning-400')};
                    transition: 0.5s;
                  }
                }
              `}
            >
              <Header
                size={2}
                color={'dark-900-32'}
              >
                <span
                  css={css`
                    color: ${colorToCode('warning-400')};
                  `}
                >
                  {alertEntries.length}{' '}
                </span>
                <span>{alertEntries.length > 1 ? 'alerts' : 'alert'}</span>
              </Header>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <Header
                  className="dashboard-header-cta-text"
                  css={css`
                    font-size: 0.875rem;
                  `}
                  size={4}
                >
                  See all account activities
                </Header>
                <i
                  className="fa-chevron-right fa-2x"
                  css={css`
                    margin-right: -0.375rem;
                  `}
                />
              </div>
            </div>
          </a>
        </div>
      </div>

      <List
        items={alertEntries}
        renderItem={(alertEntry: ActivitySearchResult) => (
          <ActivitySummaryItem
            key={alertEntry.id}
            entry={alertEntry}
            onDismiss={() => dismissSummaryItem(alertEntry.id)}
          />
        )}
      />
    </div>
  );
};

export default ActivitySummary;
