import * as React from 'react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import AssetsSectionAssetTypeCounts from './packages/assets-section-asset-type-counts/AssetsSectionAssetTypeCounts';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';

export type AssetsSectionProps<T> = {
  assetTypeCountsFieldKey: FieldKeyExpression<T>;
  disposalAssetTypeCountAvailabilities: DisposalAssetTypeAvailabilityDto[];
  assetCountMin: number;
};

const AssetsSection = <T,>({ assetTypeCountsFieldKey, disposalAssetTypeCountAvailabilities, assetCountMin }: AssetsSectionProps<T>) => (
  <AssetsSectionAssetTypeCounts
    assetTypeCountFieldKey={assetTypeCountsFieldKey}
    disposalAssetTypeCountAvailabilities={disposalAssetTypeCountAvailabilities}
    assetCountMin={assetCountMin}
  />
);

export default AssetsSection;
