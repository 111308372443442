import usePeopleDashboardMutation, {
  UsePeopleDashboardMutationOptions,
} from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/usePeopleDashboardMutation';
import { organizationKeyContactAdd } from '@AssetManagementClient/AssetManagementClientMsp.gen';

type OrganizationKeyContactAddPayload = {
  organizationId: string;
  organizationContactIds: string[];
};

export type UseOrganizationAccountTeamMemberAdd = {
  addOrganizationKeyContact: (createPayload: OrganizationKeyContactAddPayload) => void;
  isAddingOrganizationKeyContact: boolean;
};

const useOrganizationKeyContactAdd = (options?: UsePeopleDashboardMutationOptions): UseOrganizationAccountTeamMemberAdd => {
  const [addOrganizationKeyContact, isAddingOrganizationKeyContact] = usePeopleDashboardMutation(
    (createPayload: OrganizationKeyContactAddPayload) =>
      organizationKeyContactAdd({
        organizationId: createPayload.organizationId,
        organizationContactIds: createPayload.organizationContactIds,
      }),
    options,
  );

  return {
    addOrganizationKeyContact,
    isAddingOrganizationKeyContact,
  };
};

export default useOrganizationKeyContactAdd;
