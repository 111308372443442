import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import ProductTileV2, { ProductTileV2Props } from '~/wm/packages/product/packages/product-tile/ProductTileV2';
import ModalInternal from '~/neo-ui/packages/modal/ModalInternal';
import { css } from '@emotion/react';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import { CloseIcon } from '~/sp-ui/icons';
import Text from '~/sp-ui/text/Text';

export interface ProductExploreDto {
  Label: string;
  Sublabel: string;
  Icon: string;
  Abbreviation: string;
  ProductTileDto: ProductTileV2Props;
}

interface ExploreProductsProps {
  productExploreDtos: ProductExploreDto[];
}

const ExploreProducts: FC<ExploreProductsProps> = ({ productExploreDtos }) => {
  const [openTile, setOpenTile] = useState<number | undefined>(undefined);
  const [queryProduct, setQueryProduct] = useState<string | null>(null);
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  const productTiles = useMemo(
    () =>
      productExploreDtos.map((dto, key) => (
        <ModalInternal
          key={key}
          isOpen={openTile === key}
          onDismiss={() => setOpenTile(undefined)}
          maxWidthRem={40}
          containerCss={css`
            border-radius: 0;
            border: none;
            box-shadow: 0 12px 36px 0 ${colorToCode('dark-900-24')};
          `}
        >
          <CloseIcon
            css={{
              position: 'absolute',
              top: '2rem',
              right: '2rem',
              cursor: 'pointer',
            }}
            onClick={() => setOpenTile(undefined)}
          />
          <ProductTileV2
            ProductLogoWithProductNameUrl={dto.ProductTileDto.ProductLogoWithProductNameUrl}
            ProductSubtitle={dto.ProductTileDto.ProductSubtitle}
            ProductSummary={dto.ProductTileDto.ProductSummary}
            ProductCta={dto.ProductTileDto.ProductCta}
            ProductCtaOnClick={() => trackEventProcessor('home_page_explore_product_grow_button_clicked', { targetProduct: dto.Label })}
            ProductTestimonialUrl={dto.ProductTileDto.ProductTestimonialUrl}
            ProductTestimonialThumbnail={dto.ProductTileDto.ProductTestimonialThumbnail}
            SalesPitchSubtitle={dto.ProductTileDto.SalesPitchSubtitle}
            SalesPitch={dto.ProductTileDto.SalesPitch}
            ContinueToProductLabel={dto.ProductTileDto.ContinueToProductLabel}
            ContinueToProductUrl={dto.ProductTileDto.ContinueToProductUrl}
            ContinueToProductOnClick={() =>
              trackEventProcessor('home_page_explore_product_learn_more_clicked', { targetProduct: dto.Label })
            }
            BookDemoLabel={dto.ProductTileDto.BookDemoLabel}
            BookDemoUrl={dto.ProductTileDto.BookDemoUrl}
            BookDemoOnClick={() => trackEventProcessor('home_page_explore_product_book_demo_clicked', { targetProduct: dto.Label })}
            OnVideoWatched={(videoRef, watchTime) =>
              trackEventProcessor('home_page_explore_product_video_watched', {
                targetProduct: dto.Label,
                videoTotalDuration: videoRef.current.duration,
                videoWatchedDuration: watchTime,
              })
            }
          />
        </ModalInternal>
      )),
    [productExploreDtos, openTile, trackEventProcessor],
  );

  useEffect(() => {
    productExploreDtos.map((dto, key) => {
      if (dto.Abbreviation === queryProduct) {
        setOpenTile(key);
      }
    });
  }, [productExploreDtos, queryProduct]);

  useEffect(() => {
    if (openTile !== undefined) {
      trackEventProcessor('home_page_explore_product_tile_opened', {
        targetProduct: productExploreDtos[openTile].Label,
      });
    }
  }, [openTile, trackEventProcessor, productExploreDtos]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setQueryProduct(queryParams.get('product'));
  }, []);

  return (
    <Fragment>
      {openTile !== undefined && productTiles[openTile]}
      <Text
        variant="h3"
        css={{
          gridColumn: '1 / -1',
          fontWeight: 500,
        }}
      >
        More from ScalePad
      </Text>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: 'subgrid',
          gridColumn: '1 / -1',
        }}
      >
        {productExploreDtos?.map((value, key) => (
          <div
            key={key}
            css={{
              border: '1px solid rgba(0, 0, 0, 0)',
              display: 'flex',
              width: '11.25rem',
              flexDirection: 'column',
              padding: '1.5rem 1rem 1.5rem 1rem',
              alignItems: 'center',
              justifyContent: 'flex-start',
              ['&:hover']: {
                border: `1px solid ${colorToCode('light-300')}`,
                borderRadius: '0.5rem',
                backgroundColor: colorToCode('light-200'),
                textDecoration: 'none',
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              setOpenTile(key);
              trackEventProcessor('home_page_explore_product_tile_opened', {
                targetProduct: value.Label,
              });
            }}
          >
            <img
              src={value.Icon}
              alt={value.Label}
              css={{
                width: '4.5rem',
                height: '4.5rem',
              }}
            />

            <Label
              size={'lg'}
              color={'dark-400'}
              css={{
                marginTop: '1rem',
              }}
            >
              {value.Label}
            </Label>
            {value.Sublabel && (
              <Label
                size={'sm'}
                color={'dark-100'}
              >
                {value.Sublabel}
              </Label>
            )}
            <div
              css={{
                border: `1px solid ${colorToCode('primary-400')}`,
                borderRadius: '0.25rem',
                marginTop: '0.25rem',
                padding: '0.25rem 0.5rem 0.25rem 0.5rem',
                letterSpacing: '0.0625rem',
              }}
            >
              <Label
                size="sm"
                bold={true}
                color="primary-400"
              >
                EXPLORE
              </Label>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default ExploreProducts;
