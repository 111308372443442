import * as React from 'react';

type PropsComparator<C extends React.ComponentType> = (
  prevProps: Readonly<React.ComponentProps<C>>,
  nextProps: Readonly<React.ComponentProps<C>>,
) => boolean;

// Workaround for lost generics https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-699521381
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reactMemoTyped = <C extends React.ComponentType<any>>(Component: C, propsComparator?: PropsComparator<C>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.memo(Component, propsComparator) as any as C;
