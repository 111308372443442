import { disposalOrderAssetOrganizationAssign } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { DisposalOrderAssetOrganizationAssignPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderAsset/Dto/Model.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import { useCallback, useState } from 'react';

export type UseDisposalOrderAssetOrganizationAssignPayload = {
  disposalOrderId: string;
  disposalOrderAssetOrganizationAssignPayloads: DisposalOrderAssetOrganizationAssignPayloadDto[];
};

export type UseDisposalOrderAssetOrganizationAssign = (onSuccessEffect?: () => void) => {
  assignOrganization: (payload: UseDisposalOrderAssetOrganizationAssignPayload, isRequestActive?: () => boolean) => void;
  isAssigningOrganization: boolean;
};

const useDisposalOrderAssetOrganizationAssign: UseDisposalOrderAssetOrganizationAssign = (onSuccessEffect?: () => void) => {
  const { callApi } = useApi();
  const [isAssigningOrganization, setAssigningOrganization] = useState(false);

  const assignOrganization = useCallback(
    async (payload: UseDisposalOrderAssetOrganizationAssignPayload) => {
      setAssigningOrganization(true);

      const response = await callApi(() => disposalOrderAssetOrganizationAssign(payload));

      if (!response) {
        return;
      }

      setAssigningOrganization(false);
      if (typeof onSuccessEffect !== 'undefined') {
        onSuccessEffect();
      }
    },
    [callApi, onSuccessEffect],
  );

  return { assignOrganization, isAssigningOrganization };
};

export default useDisposalOrderAssetOrganizationAssign;
