import useApi from '~/wm/packages/api/hook/useApi';
import { useState, useCallback } from 'react';
import { initiativeTemplateUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { InitiativeTemplateUpdatePayloadDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/InitiativeTemplate/Dto/Model.gen';

export type UseInitiativeTemplateUpdate = (onTemplateUpdated: () => void) => {
  isUpdating: boolean;
  updateTemplate: (updatePayload: InitiativeTemplateUpdatePayloadDto) => void;
};

const useInitiativeTemplateUpdate: UseInitiativeTemplateUpdate = onTemplateUpdated => {
  const { callApi } = useApi();
  const [isLoading, setIsLoading] = useState(false);

  const updateTemplate = useCallback(
    async (updatePayload: InitiativeTemplateUpdatePayloadDto) => {
      setIsLoading(true);
      const response = await callApi(() =>
        initiativeTemplateUpdate({
          updatePayload,
        }),
      );
      if (!response) {
        setIsLoading(false);
        return;
      }

      onTemplateUpdated();
      setIsLoading(false);
    },
    [callApi, onTemplateUpdated],
  );

  return {
    isUpdating: isLoading,
    updateTemplate,
  };
};

export default useInitiativeTemplateUpdate;
