import { createContext } from 'react';

export type DisposalOrderInfoMutationContextData = {
  triggerDisposalOrderInfoReload: (isRequestActive?: () => boolean) => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const DisposalOrderInfoMutationContext = createContext<DisposalOrderInfoMutationContextData>({
  triggerDisposalOrderInfoReload: async () => {},
});

export default DisposalOrderInfoMutationContext;
