import * as React from 'react';
import ScorecardProvider from '~/wm/packages/strategy/packages/scorecard/context/ScorecardProvider';
import Scorecard from '~/wm/packages/strategy/packages/scorecard/Scorecard';
import ScorecardWelcomeBanner from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-banner/packages/scorecard-welcome-banner/ScorecardWelcomeBanner';
import OrganizationProvider from '~/wm/packages/organization/context/OrganizationProvider';
import ScorecardUrlProvider from '~/wm/packages/strategy/packages/scorecard/context/ScorecardUrlProvider';
import ScorecardEditPastBanner from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-banner/packages/scorecard-edit-past-banner/ScorecardEditPastBanner';
import ScorecardLayoutHeader from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-page/packages/scorecard-layout-header/ScorecardLayoutHeader';
import ScorecardLayoutFooter from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-page/packages/scorecard-layout-footer/ScorecardLayoutFooter';
import FeatureAccessBannerScorecard from '~/wm/packages/feature/packages/feature-access-banner/packages/feature-access-banner-scorecard/FeatureAccessBannerScorecard';

export type ScorecardPageProps = {
  scorecardId?: string;
  organizationId: string;
  organizationName: string;
  scorecardPreviewUrl: string;
  upgradeUrl: string;
  growUrl: string;
  scorecardViewUrl: string;
  scorecardIndividualViewUrl: string;
  insightViewUrl: string;
};

const ScorecardPage = ({
  scorecardId,
  organizationId,
  organizationName,
  scorecardPreviewUrl,
  upgradeUrl,
  growUrl,
  scorecardViewUrl,
  scorecardIndividualViewUrl,
  insightViewUrl,
}: ScorecardPageProps) => {
  const [disableScorecardListSelect, setDisableScorecardListSelect] = React.useState(false);

  return (
    <OrganizationProvider
      organizationId={organizationId}
      organizationName={organizationName}
    >
      <ScorecardUrlProvider
        upgradeUrl={upgradeUrl}
        growUrl={growUrl}
        scorecardViewUrl={scorecardViewUrl}
        scorecardIndividualViewUrl={scorecardIndividualViewUrl}
        insightViewUrl={insightViewUrl}
      >
        <ScorecardProvider
          initialScorecardId={scorecardId}
          organizationId={organizationId}
          organizationName={organizationName}
        >
          <ScorecardLayoutHeader
            organizationId={organizationId}
            scorecardPreviewUrl={scorecardPreviewUrl}
            disableScorecardListSelect={disableScorecardListSelect}
          />
          <FeatureAccessBannerScorecard />
          <ScorecardWelcomeBanner />
          <ScorecardEditPastBanner />
          <Scorecard onScorecardIsSubmitting={isSubmitting => setDisableScorecardListSelect(isSubmitting)} />
          <ScorecardLayoutFooter />
        </ScorecardProvider>
      </ScorecardUrlProvider>
    </OrganizationProvider>
  );
};

export default ScorecardPage;
