import {
  SubscriptionAgreementEnum,
  SubscriptionAgreementInfo,
} from '~/wm/packages/subscription/packages/manage/wizard/model/SubscriptionAgreementInfo';
import { CurrencyCode } from '~/extensions/packages/currency/formatCurrency';
import buildAppliedProductDiscountFormat from '~/wm/packages/subscription/packages/manage/builder/buildAppliedProductDiscountFormat';
import * as React from 'react';
import TermStepSection from '~/wm/packages/subscription/packages/manage/wizard/step/section/TermStepSection';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import ProductDiscountSummary from '~/wm/packages/subscription/packages/plan/packages/product-discount-summary/ProductDiscountSummary';
import { css } from '@emotion/react';
import NextTotalInvoiceSummary from '~/wm/packages/subscription/packages/next-invoice/packages/next-total-invoice-summary/NextTotalInvoiceSummary';

export type SubscriptionDetailsStepSectionProps = {
  subscriptionAgreementInfo: SubscriptionAgreementInfo;
  subscriptionAgreementLink: AnchorLocation;
  currencyCode: CurrencyCode;
};

const SubscriptionDetailsStepSection = ({
  subscriptionAgreementInfo,
  subscriptionAgreementLink,
  currencyCode,
}: SubscriptionDetailsStepSectionProps) => {
  const discountStepSection =
    subscriptionAgreementInfo.case === SubscriptionAgreementEnum.Preset &&
    subscriptionAgreementInfo.pricingDto.discountsDto &&
    subscriptionAgreementInfo.pricingDto.discountsDto.length > 0 ? (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          text-align: left;
        `}
      >
        <ProductDiscountSummary
          discounts={buildAppliedProductDiscountFormat(subscriptionAgreementInfo.pricingDto.discountsDto, currencyCode)}
        />
      </div>
    ) : null;

  const isFirstPayment = subscriptionAgreementInfo.case === SubscriptionAgreementEnum.Preset;

  const presetSummaryComponent =
    subscriptionAgreementInfo.case === SubscriptionAgreementEnum.Preset ? (
      <NextTotalInvoiceSummary
        priceDto={subscriptionAgreementInfo.pricingDto.priceDto}
        isFirstPayment={isFirstPayment}
        isInForm={true}
      />
    ) : null;

  const termStepSection = subscriptionAgreementInfo.term ? (
    <TermStepSection
      term={subscriptionAgreementInfo.term}
      billingPeriod={subscriptionAgreementInfo.billing.periodEnum}
      subscriptionAgreementLink={subscriptionAgreementLink}
    />
  ) : null;

  return (
    <div>
      {discountStepSection}
      {presetSummaryComponent}
      {termStepSection}
    </div>
  );
};

export default SubscriptionDetailsStepSection;
