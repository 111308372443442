import * as React from 'react';
import { Fragment } from 'react';
import Button from '~/neo-ui/packages/button/Button';
import useInitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesContext';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import { css } from '@emotion/react';
import SelectList from '~/neo-ui/packages/list/packages/select-list/SelectList';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import {
  BudgetLineItemFormData,
  InitiativeSaveFormData,
  RecurringLineItemFormData,
} from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import useAccount from '~/wm/hooks/useAccount';
import useInitiativeTemplateDelete from '~/wm/packages/strategy/packages/initiative/packages/initiative-template/hooks/useInitiativeTemplateDelete';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import SelectOptionGroup from '~/neo-ui/packages/select/model/SelectOptionGroup';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import useInitiativeAvailabilitiesMutationContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesMutationContext';

const nameFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.name;
const executiveSummaryFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.executiveSummary;
const budgetLineItemsFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.budgetLineItems;
const recurringLineItemsFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.recurringLineItems;
const initiativeTemplateIdFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.initiativeTemplateId;

const InitiativeSaveTemplateApplyButton = () => {
  const { availabilities } = useInitiativeAvailabilitiesContext();
  const { triggerInitiativeAvailabilitiesReload } = useInitiativeAvailabilitiesMutationContext();
  const { setFormInput } = useFormContext<InitiativeSaveFormData>();
  const { subunitRatio } = useAccount();

  const { deleteTemplate } = useInitiativeTemplateDelete();

  const resetModal = () => {
    setSelectedTemplate(undefined);
    setDisplayModal(false);
  };

  const confirmModal = async () => {
    const appliedTemplate =
      availabilities.templatesApplicationAvailabilities?.find(
        availability => availability.initiativeTemplateId === selectedTemplate!.value,
      ) ??
      availabilities.templatesAccountAvailabilities?.find(availability => availability.initiativeTemplateId === selectedTemplate!.value);

    if (typeof appliedTemplate === 'undefined') {
      return;
    }

    await setFormInput<string>(nameFieldKey, appliedTemplate.name ?? '');
    await setFormInput<string>(executiveSummaryFieldKey, appliedTemplate.executiveSummary ?? '');
    await setFormInput<BudgetLineItemFormData[]>(
      budgetLineItemsFieldKey,
      appliedTemplate.oneTimeBudget?.map(budget => ({
        label: budget.label,
        costUnits: budget.costSubunits / subunitRatio,
        costType: budget.costType,
      })) ?? [],
    );
    await setFormInput<RecurringLineItemFormData[]>(
      recurringLineItemsFieldKey,
      appliedTemplate.recurringBudget?.map(budget => ({
        label: budget.label,
        costUnits: budget.costSubunits / subunitRatio,
        costType: budget.costType,
        frequency: budget.frequency,
      })) ?? [],
    );
    await setFormInput<string>(initiativeTemplateIdFieldKey, appliedTemplate.initiativeTemplateId);
    resetModal();
  };

  const [filteredAccountTemplates, setFilteredAccountTemplates] = React.useState(availabilities.templatesAccountAvailabilities);

  React.useEffect(
    () => setFilteredAccountTemplates(availabilities.templatesAccountAvailabilities),
    [availabilities.templatesAccountAvailabilities],
  );

  const [isDisplayingModal, setDisplayModal] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState<SelectOption>();

  React.useEffect(() => {
    (async () => {
      if (!availabilities.templatesApplicationAvailabilities) {
        return;
      }

      const firstSuggestedTemplate = availabilities.templatesApplicationAvailabilities.find(template => template.isRecommended);

      if (typeof firstSuggestedTemplate !== 'undefined') {
        setSelectedTemplate({
          label: firstSuggestedTemplate.name,
          value: firstSuggestedTemplate.initiativeTemplateId,
        });
      }
    })();
  }, [availabilities.templatesApplicationAvailabilities, filteredAccountTemplates]);

  if (!filteredAccountTemplates || !availabilities.templatesApplicationAvailabilities) {
    return null;
  }

  const applicationTemplateOptions: SelectOption[] = availabilities.templatesApplicationAvailabilities.map(template => ({
    label: template.name,
    value: template.initiativeTemplateId,
    prependIcon: 'InitiativeTemplate',
  }));

  const templateListGroup: SelectOptionGroup[] =
    filteredAccountTemplates.length > 0
      ? [
          {
            title: `YOUR SAVED TEMPLATES`,
            options: filteredAccountTemplates.map(template => ({
              label: template.name === '' ? 'Untitled Initiative' : template.name,
              value: template.initiativeTemplateId,
              prependIcon: 'InitiativeTemplate',
              actionButton: {
                children: 'Delete',
                theme: 'negative',
                iconRight: 'Trash',
                preventOnClickPropagation: true,
                disableTransparentBackground: true,
                onClick: async () => {
                  setSelectedTemplate(undefined);
                  deleteTemplate(template.initiativeTemplateId);
                  setFilteredAccountTemplates(prev => prev?.filter(prev => prev.initiativeTemplateId !== template.initiativeTemplateId));
                  await triggerInitiativeAvailabilitiesReload();
                },
              },
            })),
          },
          { title: `DEFAULT TEMPLATES`, options: applicationTemplateOptions },
        ]
      : [{ title: `DEFAULT TEMPLATES`, options: applicationTemplateOptions }];

  return (
    <Fragment>
      <Button
        iconLeft={'InitiativeTemplate'}
        onClick={() => {
          setDisplayModal(true);
        }}
      >
        Apply template…
      </Button>
      <ModalConfirmation
        header={{
          title: 'Apply a new template',
          icon: 'InitiativeTemplate',
        }}
        theme={'primary'}
        footer={{
          confirmButton: {
            icon: 'Success',
            label: 'Apply template',
            disabled: typeof selectedTemplate === 'undefined',
            onClick: confirmModal,
          },
          dismissButton: {
            icon: 'Cancel',
            label: 'Cancel',
            onClick: resetModal,
          },
        }}
        isOpen={isDisplayingModal}
      >
        {availabilities.templatesApplicationAvailabilities && filteredAccountTemplates && (
          <div>
            <div>This Initiative’s action, title, executive summary, and budget will be replaced by those in the template.</div>
            <hr
              css={css`
                margin-top: 0.625rem;
                margin-bottom: 0;
                width: 100%;
              `}
            />
            <div
              css={css`
                display: flex;
                flex-direction: column;
                max-height: 25rem;
                overflow-y: auto;

                ::-webkit-scrollbar {
                  width: 0;
                  background: transparent;
                }

                margin-top: 0.625rem;
              `}
            >
              <SelectList
                options={templateListGroup}
                selectedOptionValue={selectedTemplate}
                onOptionSelected={setSelectedTemplate}
              />
            </div>
          </div>
        )}
      </ModalConfirmation>
    </Fragment>
  );
};

export default InitiativeSaveTemplateApplyButton;
