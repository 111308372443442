import { ReactNode } from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { css } from '@emotion/react';

export type AnimationHeightProps = {
  isExpanded: boolean;
  transitionDurationMs?: number;
  minHeightRem?: number;
  children: ReactNode;
} & Styleable;

/**
 * Animates the height of children using grid-template-rows
 * @param isExpanded - whether the children should be expanded or not
 * @param transitionDurationMs - the duration of the animation in milliseconds
 * @param minHeightRem - the minimum height of the children in rem
 * @param children - the children to be animated
 * @param className - the class name to be applied to the container (can cause animation errors, beware)
 */
const AnimationHeight = ({ isExpanded, transitionDurationMs = 300, minHeightRem, children, className }: AnimationHeightProps) => (
  <div
    className={className}
    css={css`
      display: grid;
      grid-template-rows: ${isExpanded ? '1fr' : '0fr'};
      transition: grid-template-rows ${transitionDurationMs}ms ease-in-out;
    `}
  >
    <div
      css={css`
        // Overflow hidden required for reveal animation
        overflow: hidden;
        ${typeof minHeightRem !== 'undefined' ? `min-height: ${minHeightRem}rem;` : ''};
      `}
    >
      {children}
    </div>
  </div>
);

export default AnimationHeight;
