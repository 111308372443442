import { useContext } from 'react';
import InitiativeAssetSelectContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/context/InitiativeAssetSelectContext';

const useInitiativeAssetSelectContext = () => {
  const initiativeAssetSelectContext = useContext(InitiativeAssetSelectContext);
  if (!initiativeAssetSelectContext) {
    throw new Error('Tried to use InitiativeAssetSelectContext outside of an InitiativeAssetSelectProvider');
  }

  return initiativeAssetSelectContext;
};

export default useInitiativeAssetSelectContext;
