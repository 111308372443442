import { PolicyFormUnitDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Dto/Model/CreateForm.gen';
import { UnitTypeEnum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Dto/Model/CreateForm/PolicyFormUnitDtoNested.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import FormSelectInput from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import FormTitleInput from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/FormTitleInput';
import { camelToPascal } from '~/extensions/packages/casing/camelPascalConversion';
import FormSingleSelectInput from '~/neo-ui/packages/form/packages/form-input/packages/form-single-select-input/FormSingleSelectInput';

const InsightUnitFormatter = <T,>(
  unit: PolicyFormUnitDto | undefined,
  fieldKey: FieldKeyExpression<T>,
  onChange?: (selectedValues: string[]) => void,
  insightLabel?: string,
): React.ReactNode => {
  if (typeof unit === 'undefined') {
    return <div />;
  }
  switch (unit.key) {
    case UnitTypeEnum.Boolean:
      const options = [];
      if (typeof unit.options.trueLabel !== 'undefined') {
        options.push({ label: unit.options.trueLabel, value: 'true' });
      }
      if (typeof unit.options.falseLabel !== 'undefined') {
        options.push({ label: unit.options.falseLabel, value: 'false' });
      }
      return (
        <div>
          <FormSelectInput
            fieldKey={fieldKey}
            options={options}
            onSelect={typeof onChange === 'undefined' ? undefined : value => onChange([value])}
          />
          <span>{unit.unitLabel}</span>
        </div>
      );
    case UnitTypeEnum.Integer:
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <FormTitleInput fieldKey={fieldKey} />
          <div
            css={css`
              margin-left: 5px;
            `}
          >
            {unit.unitLabel}
          </div>
        </div>
      );
    case UnitTypeEnum.Enum:
      const enumOptions = Object.entries(unit.options)
        .map(([key, value]) => ({
          label: value,
          value: camelToPascal(key),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      return (
        <div
          css={css`
            width: 100%;
          `}
        >
          <FormSingleSelectInput
            fieldKey={fieldKey}
            options={enumOptions}
            placeholder={`Search ${insightLabel?.toLowerCase() ?? ''}...`}
            truncateSelected={220}
            shouldUseMenuPortal={false}
          />
        </div>
      );
  }
};

export default InsightUnitFormatter;
