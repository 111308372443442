import { type ExpenseLineItem } from '@SubscriptionClient/BeastClient/Billing/Subscription/Quotes/Application/Queries/Models.gen';
import { Fragment } from 'react';
import Text from '~/sp-ui/text';
import formatCurrency from '../../formatCurrency';
import { LineItemTooltip } from './LineItemTooltip';

const ExpenseLineItem = (props: { lineItem: ExpenseLineItem }) => {
  const { lineItem } = props;

  const renderSingleInclusion = (inclusion: string) => {
    return <Text css={{ maxWidth: '288px', display: 'block', whiteSpace: 'wrap', overflow: 'hidden' }}>{inclusion}</Text>;
  };

  const renderMultipleInclusions = (inclusions: string[]) => {
    return (
      <ul css={{ padding: 0, margin: 0, listStylePosition: 'inside' }}>
        {inclusions.map((inclusion, index) => (
          <li key={index}>{inclusion}</li>
        ))}
      </ul>
    );
  };

  return (
    <Fragment>
      <Text css={{ display: 'inline-flex', alignItems: 'center' }}>
        {lineItem.label}
        {!!lineItem.inclusions?.length && (
          <LineItemTooltip css={{ width: '1rem', height: '1rem', marginLeft: '0.5rem' }}>
            {lineItem.inclusions.length === 1
              ? renderSingleInclusion(lineItem.inclusions[0])
              : renderMultipleInclusions(lineItem.inclusions)}
          </LineItemTooltip>
        )}
      </Text>
      <Text css={{ justifySelf: 'end' }}>{formatCurrency(lineItem.amountInCents)}</Text>
    </Fragment>
  );
};

export default ExpenseLineItem;
