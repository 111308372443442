import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Header, { HeaderSize, HeaderWeight } from '~/neo-ui/packages/text/packages/header/Header';
import { sectionMarginRem } from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import Image from '~/neo-ui/packages/image/Image';
import Color from '~/neo-ui/packages/color/Color.gen';

export type LayoutSectionWithLogoProps = {
  title: string;
  titleWeight?: HeaderWeight;

  // H1 is reserved for titles and not to be used in sections
  titleSize: Exclude<HeaderSize, 1>;
  titleColor?: Color;
  logoSource?: string;

  children: React.ReactNode;
} & Styleable;

const LayoutSectionWithLogo: React.FunctionComponent<LayoutSectionWithLogoProps> = ({
  title,
  titleWeight,
  titleSize,
  titleColor,
  logoSource,
  className,
  children,
}) => (
  <div className={className}>
    <div
      css={css`
        margin-bottom: ${sectionMarginRem(titleSize)}rem;
      `}
    >
      <div>
        <Header
          size={titleSize}
          color={titleColor}
          {...(titleWeight && { weight: titleWeight })}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              width: 100%;
            `}
          >
            {title}
            {logoSource && (
              <Image
                source={logoSource}
                css={css`
                  min-width: 80px;
                  width: 80px;
                  height: 21.55px;
                `}
              />
            )}
          </div>
        </Header>
      </div>
    </div>
    <div
      css={css`
        > *:not(:last-child) {
          margin-bottom: 1.25rem;
        }
      `}
    >
      {children}
    </div>
  </div>
);

export default LayoutSectionWithLogo;
