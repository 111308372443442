import { useContext } from 'react';
import ToastContext from '~/neo-ui/packages/toast/context/ToastContext';

const useToastContext = () => {
  const toastContext = useContext(ToastContext);
  if (!toastContext) {
    throw new Error('Tried to use ToastContext outside of an ToastProvider');
  }

  return toastContext;
};

export default useToastContext;
