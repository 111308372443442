import { InitiativeTemplateDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/InitiativeTemplate/Dto/Model.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import SelectList from '~/neo-ui/packages/list/packages/select-list/SelectList';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import SelectOptionGroup from '~/neo-ui/packages/select/model/SelectOptionGroup';
import Testable from '~/neo-ui/packages/testable/Testable';
import useInitiativeTemplateAvailability from '~/wm/packages/strategy/packages/initiative/packages/initiative-template/hooks/useInitiativeTemplateAvailability';
import useInitiativeTemplateDelete from '~/wm/packages/strategy/packages/initiative/packages/initiative-template/hooks/useInitiativeTemplateDelete';

export type InitiativeTemplateSelectProps = {
  /**
   * For suggestions
   */
  insightId?: string;
  canSpecifyNoTemplate: boolean;

  selectedTemplate: InitiativeTemplateDto | undefined;
  onTemplateSelect: (initiativeTemplate: InitiativeTemplateDto | undefined) => void;
};

const InitiativeTemplateSelect: React.FunctionComponent<InitiativeTemplateSelectProps> = ({
  insightId,
  canSpecifyNoTemplate,
  selectedTemplate,
  onTemplateSelect,
}) => {
  const noTemplateOptionValue = 'EMPTY';

  const { application: applicationTemplates, account: accountTemplates } = useInitiativeTemplateAvailability(insightId);
  const { deleteTemplate } = useInitiativeTemplateDelete();

  const initiativeTemplatesById = React.useMemo(
    () =>
      new Map([...(applicationTemplates ?? []), ...(accountTemplates ?? [])].map(template => [template.initiativeTemplateId, template])),
    [applicationTemplates, accountTemplates],
  );

  const getTemplateById = (initiativeTemplateId: string): InitiativeTemplateDto => initiativeTemplatesById.get(initiativeTemplateId)!;

  const [filteredAccountTemplates, setFilteredAccountTemplates] = React.useState(accountTemplates);

  React.useEffect(() => setFilteredAccountTemplates(accountTemplates), [accountTemplates]);

  React.useEffect(() => {
    (async () => {
      if (typeof applicationTemplates === 'undefined') {
        return;
      }

      const firstSuggestedTemplate = applicationTemplates?.find(template => template.isRecommended);

      if (typeof firstSuggestedTemplate !== 'undefined') {
        onTemplateSelect(firstSuggestedTemplate);
      }
    })();
  }, [applicationTemplates, onTemplateSelect]);

  const noTemplateGroup: SelectOptionGroup = React.useMemo(
    () => ({
      options: [
        {
          label: 'No template',
          value: noTemplateOptionValue,
          prependIcon: 'InitiativeTemplateBlank',
        },
      ],
    }),
    [],
  );

  if (!filteredAccountTemplates || !applicationTemplates) {
    return null;
  }

  const applicationTemplateGroup: SelectOptionGroup = {
    title: `DEFAULT TEMPLATES`,
    options: applicationTemplates.map(template => ({
      label: template.isRecommended ? `${template.name} (suggested)` : template.name,
      value: template.initiativeTemplateId,
      prependIcon: 'InitiativeTemplate',
    })),
  };

  const templateListGroup: SelectOptionGroup[] = [
    ...(canSpecifyNoTemplate ? [noTemplateGroup] : []),
    applicationTemplateGroup,
    ...(filteredAccountTemplates.length > 0
      ? [
          {
            title: `YOUR SAVED TEMPLATES`,
            options: filteredAccountTemplates.map(
              (template): SelectOption => ({
                label: template.name === '' ? 'Untitled initiative' : template.name,
                value: template.initiativeTemplateId,
                prependIcon: 'InitiativeTemplate',
                actionButton: {
                  children: 'Delete',
                  theme: 'negative',
                  iconRight: 'Trash',
                  preventOnClickPropagation: true,
                  disableTransparentBackground: true,
                  onClick: () => {
                    onTemplateSelect(undefined);
                    deleteTemplate(template.initiativeTemplateId);
                    setFilteredAccountTemplates(prev => prev?.filter(prev => prev.initiativeTemplateId !== template.initiativeTemplateId));
                  },
                },
              }),
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      <Testable testId={'initiative-template-select'}>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            max-height: 17.1875rem;
            overflow-y: auto;
            margin-top: 0.625rem;
          `}
        >
          <SelectList
            options={templateListGroup}
            selectedOptionValue={templateListGroup
              .flatMap(templateItem => templateItem.options)
              .find(options => options.value === (selectedTemplate?.initiativeTemplateId ?? noTemplateOptionValue))}
            onOptionSelected={templateOption =>
              onTemplateSelect(templateOption.value !== noTemplateOptionValue ? getTemplateById(templateOption.value) : undefined)
            }
          />
        </div>
      </Testable>
    </div>
  );
};

export default InitiativeTemplateSelect;
