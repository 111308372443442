import { DistributorServiceContactInfo } from '@WarrantyClient/Warranty/Distributor/Model.gen';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import { css } from '@emotion/react';
import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import Header from '~/neo-ui/packages/text/packages/header/Header';

export type ServiceRequestImmediateAttentionSectionProps = {
  contactInfo: DistributorServiceContactInfo | undefined;
};

const ServiceRequestImmediateAttentionSection = ({ contactInfo }: ServiceRequestImmediateAttentionSectionProps) =>
  contactInfo && contactInfo.phoneNumbers.length > 0 ? (
    <div>
      <Card
        padding={'lg'}
        shadow={'none'}
        css={css`
          width: 20rem;
          margin-left: 0.625rem;
        `}
      >
        <Header
          size={4}
          weight={'bold'}
          color={'caution-400'}
          css={css`
            margin-bottom: 0.9375rem;
          `}
        >
          For immediate attention
        </Header>
        <p>If this service request requires immediate attention, or the hardware site isn’t correct, please give us a call.</p>
        {contactInfo.phoneNumbers.map(phoneNumber => (
          <div
            key={phoneNumber.phoneNumber}
            css={css`
              margin-bottom: 0.625rem;
            `}
          >
            <p
              css={css`
                margin-bottom: 0;
              `}
            >
              {phoneNumber.appliesToCountries.map((country, i) => (
                <React.Fragment key={`${phoneNumber.phoneNumber}_${country.countryCode}`}>
                  {[country.name]}
                  {i < phoneNumber.appliesToCountries.length - 2 ? ', ' : i < phoneNumber.appliesToCountries.length - 1 ? ' & ' : ''}
                </React.Fragment>
              ))}
            </p>
            <ButtonLink
              theme={'secondary'}
              anchor={{ href: `tel:${phoneNumber.phoneNumber}` }}
            >
              {phoneNumber.phoneNumber}
            </ButtonLink>
          </div>
        ))}
      </Card>
    </div>
  ) : null;

export default ServiceRequestImmediateAttentionSection;
