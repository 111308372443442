import { Styleable } from '~/neo-ui/model/capacity';
import { InitiativeStatusDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/InitiativeStatus/Dto/Model.gen';
import Dropdown, { DropdownOption, DropdownProps } from '~/neo-ui/packages/dropdown/Dropdown';
import Theme from '~/neo-ui/packages/color/Theme';
import React from 'react';
import { ButtonInternalProps } from '~/neo-ui/packages/button/ButtonInternal';
import ButtonType from '~/neo-ui/packages/button/types/ButtonType';

export type InitiativeStatusDropdownProps = {
  initiativeStatusAvailabilities: InitiativeStatusDto[];
  selectedStatus: string;
  onStatusChange: (status: string) => void;
  disabled?: boolean;
} & Styleable &
  Pick<ButtonInternalProps, 'size'> &
  Pick<DropdownProps, 'buttonCss' | 'preventOnClickPropagation' | 'dropdownMenuCss'>;

const InitiativeStatusDropdown = ({
  initiativeStatusAvailabilities,
  selectedStatus,
  onStatusChange,
  disabled = false,
  size,
  buttonCss,
  preventOnClickPropagation,
  dropdownMenuCss,
  className,
}: InitiativeStatusDropdownProps) => {
  const dropdownOptions: DropdownOption[] = initiativeStatusAvailabilities.map(initiativeStatus => ({
    value: initiativeStatus.id,
    label: initiativeStatus.displayLabel,
  }));

  const selectedInitiativeStatusAvailability = initiativeStatusAvailabilities.find(
    initiativeStatusAvailability => initiativeStatusAvailability.id === selectedStatus,
  );

  if (typeof selectedInitiativeStatusAvailability === 'undefined') {
    return null;
  }

  return (
    <Dropdown
      className={className}
      theme={(selectedInitiativeStatusAvailability.displayTheme as Theme) ?? undefined}
      options={dropdownOptions}
      selectedOption={dropdownOptions.find(option => option.value === selectedStatus)}
      onOptionSelected={option => {
        if (option.value !== selectedStatus) {
          onStatusChange(option.value);
        }
      }}
      size={size}
      buttonCss={buttonCss}
      dropdownMenuCss={dropdownMenuCss}
      disabled={disabled}
      preventOnClickPropagation={preventOnClickPropagation}
      buttonDropdown={selectedInitiativeStatusAvailability.displayButtonType as ButtonType}
    />
  );
};

export default InitiativeStatusDropdown;
