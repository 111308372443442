import * as React from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import Box from '~/neo-ui/packages/box/Box';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import FormTitleInput from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/FormTitleInput';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import useClipboard from '~/extensions/packages/clipboard/hooks/useClipboard';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import { css } from '@emotion/react';
import { Response as ApiKeyPublicGenerateResponse } from '~/SsoClient/Sso/ApiKeyPublic/Controller/ApiKeyPublicGenerateControllerNested.gen';
import { Request as ApiKeyPublicSaveRequest } from '~/SsoClient/Sso/ApiKeyPublic/Controller/ApiKeyPublicUpdateControllerNested.gen';
import { paddingToCode } from '~/neo-ui/packages/style/Padding';

type ApiKeySaveFormProps = {
  apiKey: ApiKeyPublicGenerateResponse;
  isOpen: boolean;
  onClose: () => void;
};

const ApiKeySaveModal: React.FunctionComponent<React.PropsWithChildren<ApiKeySaveFormProps>> = ({ apiKey, isOpen, onClose }) => {
  const { submitForm } = useFormContext<ApiKeyPublicSaveRequest>();

  const { copyToClipboard } = useClipboard();
  const [hasCopiedKey, setHasCopiedKey] = React.useState(false);

  const copyApiKey = React.useCallback(async () => {
    const delay = (milliseconds: number) => new Promise(res => setTimeout(res, milliseconds));
    if (apiKey.apiKey.length === 0) {
      return;
    }
    copyToClipboard(apiKey.apiKey);
    setHasCopiedKey(true);
    await delay(1500);
    setHasCopiedKey(false);
  }, [copyToClipboard, apiKey]);

  const onConfirm = React.useCallback(async () => {
    await submitForm();
    onClose();
  }, [submitForm, onClose]);

  return (
    <ModalConfirmation
      header={{
        title: "Here's your new secret key",
        icon: 'Locked',
      }}
      theme={'success'}
      footer={{
        confirmButton: {
          icon: 'Done',
          label: `Done`,
          onClick: onConfirm,
        },
        dismissButton: { icon: 'Cancel', label: 'Close', onClick: onClose },
      }}
      isOpen={isOpen}
    >
      <Label
        size={'lg'}
        color={'dark-900-64'}
      >
        Give this key a name and copy it straight away. To protect your security, you’ll need to generate a new key if you lose it.
      </Label>
      <br />
      <LayoutSection
        title={'Name (optional)'}
        titleSize={5}
        titleWeight={'bold'}
      >
        <FormTitleInput
          size={'md'}
          fieldKey={(value: FieldKeyExpressionSegment<ApiKeyPublicSaveRequest>) => value.name}
          placeholder={'Give your secret key a name...'}
          hasDefaultFocus={true}
          // TODO: Make validation schema
        />
        <LayoutSection
          title={'Your secret key'}
          titleSize={5}
          titleWeight={'bold'}
        >
          <Tooltip
            content={hasCopiedKey ? 'Key copied' : 'Copy key'}
            placement={'top'}
            isActive={hasCopiedKey}
          >
            <Box
              padding={'padding200'}
              css={css`
                background-color: ${colorToCode('light-100')};
              `}
              onClick={copyApiKey}
            >
              <Label
                muted={true}
                css={css`
                  padding-bottom: ${paddingToCode('padding100')};
                  cursor: pointer;
                `}
              >
                Click to copy
              </Label>
              <Label
                bold={true}
                color={'dark-900-64'}
                css={css`
                  word-break: break-all;
                `}
              >
                {apiKey.apiKey}
              </Label>
            </Box>
          </Tooltip>
        </LayoutSection>
      </LayoutSection>
    </ModalConfirmation>
  );
};

export default ApiKeySaveModal;
