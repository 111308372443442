import useMutationInternal, {
  UseMutationInternal,
  UseMutationInternalApiCall,
  UseMutationInternalOptions,
} from '~/extensions/packages/hooks/useMutationInternal';
import useEngagementNotePanelTabMutationContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/context/hooks/useEngagementNotePanelTabMutationContext';

export type UseOrganizationEngagementNotePanelTabMutationOptions = UseMutationInternalOptions;
const useEngagementNotePanelTabMutation = <TArg = Record<string, unknown>>(
  apiCall: UseMutationInternalApiCall<TArg>,
  options?: UseOrganizationEngagementNotePanelTabMutationOptions,
): UseMutationInternal<TArg> => {
  const { triggerEngagementNotePanelTabReload } = useEngagementNotePanelTabMutationContext();

  return useMutationInternal(apiCall, triggerEngagementNotePanelTabReload, options);
};

export default useEngagementNotePanelTabMutation;
