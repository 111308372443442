import * as React from 'react';
import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';

export type EngagementListPageTicketCreateContextData = {
  selectedAction?: EngagementActionDto;
  setSelectedAction: React.Dispatch<React.SetStateAction<EngagementActionDto | undefined>>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const EngagementListPageTicketCreateContext = React.createContext<EngagementListPageTicketCreateContextData | undefined>(undefined);

export default EngagementListPageTicketCreateContext;
