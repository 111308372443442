import React from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import useScorecardContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardContext';
import useScorecardDelete from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardDelete';
import useScorecardUrlContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardUrlContext';
import Testable from '~/neo-ui/packages/testable/Testable';

export type ScorecardDeleteModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

const ScorecardDeleteModal: React.FunctionComponent<ScorecardDeleteModalProps> = ({ isOpen, onDismiss }) => {
  const { scorecardViewUrl } = useScorecardUrlContext();
  const { scorecard } = useScorecardContext();
  const { deleteScorecard, isDeletingScorecard } = useScorecardDelete({
    onSuccessEffect: {
      type: 'redirect',
      url: scorecardViewUrl.replace('__ORGANIZATION_ID__', scorecard.organizationId),
    },
  });

  return (
    <Testable testId={'scorecard-delete-modal'}>
      <ModalConfirmation
        header={{
          title: 'Delete this Scorecard?',
          icon: 'Trash',
        }}
        isOpen={isOpen}
        theme={'negative'}
        footer={{
          confirmButton: {
            icon: 'Trash',
            label: 'Delete',
            disabled: false,
            loading: isDeletingScorecard,
            onClick: () => {
              deleteScorecard(scorecard.scorecardId);
            },
          },
          dismissButton: {
            icon: 'Cancel',
            label: 'Cancel',
            disabled: false,
            onClick: onDismiss,
          },
        }}
      >
        This Scorecard will be permanently deleted.
      </ModalConfirmation>
    </Testable>
  );
};

export default ScorecardDeleteModal;
