import { createContext } from 'react';

export type PanelTabGroupContextData<T> = {
  currentPageByTab: Map<T, number | undefined>;
  currentFilterValueByTab: Map<T, string | undefined>;
  setCurrentTabPage: (currentPage: number) => void;
  setCurrentTabFilterValue: (filterValue: string) => void;
  setCurrentTabId: (id: T) => void;
  currentTabId: T;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const PanelTabGroupContext = createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PanelTabGroupContextData<any> | undefined
>(undefined);

export default PanelTabGroupContext;
