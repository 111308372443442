// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


export enum Enum
{
  TextData = "TextData",
  UrlData = "UrlData",
  StackedData = "StackedData",
  DateData = "DateData",
  ColoredCircleData = "ColoredCircleData",
  TooltipData = "TooltipData",
  /**
   * Any basic image from a specific URL, i.e. any png, svg, etc
   */
  ImageData = "ImageData",
  CollectionData = "CollectionData",
  /**
   * A "neo" icon with additional properties
   */
  IconData = "IconData",
}


