import * as React from 'react';
import { DraggableElement, DropZone } from '~/neo-ui/packages/drag-and-drop/DragAndDrop';
import Card from '~/legacy-ui/packages/card/Card';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';
import { ScaleOption } from '~/neo-ui/packages/select/packages/scale-select/ScaleSelect';
import { ScorecardItemsByScorecardCategory } from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/packages/scorecard-category-arrange/packages/scorecard-category-arrange-window/ScorecardCategoryArrangeWindow';
import { css } from '@emotion/react';
import ScorecardCategoryArrangeItemDropZone from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/packages/scorecard-category-arrange/packages/scorecard-category-arrange-item-drop-zone/ScorecardCategoryArrangeItemDropZone';

export type ScorecardCategoryDragDropZoneProps = {
  scorecardItemsByScorecardCategories: ScorecardItemsByScorecardCategory[];
  scoreDisplayAvailabilities: ScaleOption[];
};

const ScorecardCategoryArrangeCategoryDropZone = ({
  scorecardItemsByScorecardCategories,
  scoreDisplayAvailabilities,
}: ScorecardCategoryDragDropZoneProps) => (
  <DropZone
    droppableId={'scorecard'}
    settings={{ type: 'category' }}
  >
    {scorecardItemsByScorecardCategories.map((category, i) => (
      <DraggableElement
        key={category.id}
        index={i}
        draggableId={category.id}
        isUsingCustomizeDragHandler={true}
      >
        {({ dragHandleProps }) => (
          <ScorecardCategoryArrangeItemDropZone
            scorecardItemsByScorecardCategory={category}
            scoreDisplayAvailabilities={scoreDisplayAvailabilities}
          >
            <div {...dragHandleProps}>
              <Card
                key={`scorecard-category-${category.id}`}
                backgroundColor={'light-200'}
                shadow={'none'}
                padding={'sm'}
                css={css`
                  margin-bottom: 0.3125rem;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 0.625rem;
                  `}
                >
                  <Label bold={true}>{category.label}</Label>
                  <Icon
                    icon={'DragVertically'}
                    color={'dark-900-24'}
                    sizePx={20}
                  />
                </div>
              </Card>
            </div>
          </ScorecardCategoryArrangeItemDropZone>
        )}
      </DraggableElement>
    ))}
  </DropZone>
);

export default ScorecardCategoryArrangeCategoryDropZone;
