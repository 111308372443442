import { css } from '@emotion/react';
import * as React from 'react';
import useThrottle from '~/extensions/packages/hooks/useThrottle';
import { Styleable } from '~/neo-ui/model/capacity';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ToolbarProps = {
  /**
   * Controls that appear on the left of the toolbar
   *
   * Array elements appear left to right on the ui
   *
   * Dynamic controls will shrink from right to left as the screen shrinks
   */
  leftControls?: ToolbarControl[];

  /**
   * Controls that appear on the right of the toolbar
   *
   * Array elements appear right to left on the ui
   *
   * Dynamic controls will shrink from left to right as the screen shrinks
   */
  rightControls?: ToolbarControl[];
} & Styleable;

/**
 * A static or dynamic control
 */
export type ToolbarControl = ToolbarControlStatic | ToolbarControlDynamic;

/**
 * A control with only an expanded state
 */
export type ToolbarControlStatic = {
  expanded: React.ReactElement;
};
/**
 * A control with an expanded and a shrunk state
 *
 * Shrinking occurs when the toolbar becomes smaller
 */
export type ToolbarControlDynamic = {
  expanded: React.ReactElement;
  shrank: React.ReactElement;
};

/**
 * The expected height for any toolbar
 */
export const toolbarHeightRem = 3.5;

/**
 * The average width a toolbar control will occupy
 *
 * For calculating when a control should shrink
 */
const approximateToolbarControlWidthPx = 132;

/**
 * A toolbar fixed-height component for displaying controls on the left and right
 *
 * Controls in their array will display **inwards**
 *   - left controls will display left to right
 *   - right controls will display right to left
 *
 * Controls on a toolbar have an optional **shrunk** state to fit on smaller viewport widths
 *
 * Controls that may shrink will do so **outwards**
 *   - left controls will shrink right to left
 *   - right controls will shrink left to right
 *
 * Wrap this component to position it according to different situations
 *
 */
const Toolbar = ({ className, leftControls = [], rightControls = [] }: ToolbarProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [toolbarWidth, setToolbarWidth] = React.useState(1000);
  const throttledWidth = useThrottle(toolbarWidth);

  const updateToolbarWidth = () => {
    if (ref.current) {
      const newWidth = ref.current.clientWidth;
      setToolbarWidth(newWidth);
    }
  };

  React.useEffect(() => {
    updateToolbarWidth();
    window.addEventListener('resize', updateToolbarWidth);

    return () => window.removeEventListener('resize', updateToolbarWidth);
  }, []);

  // Simple calculation to determine which index to start shrinking components.
  // How many times does two controls (left and right) fit into the toolbar's width minus padding
  const shrinkAtIndex = Math.floor((throttledWidth - 60) / (2 * approximateToolbarControlWidthPx));

  return (
    <div
      ref={ref}
      className={className}
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow-x: auto;
        overflow-y: hidden;

        height: ${toolbarHeightRem}rem;
        padding: 0.625rem 1.875rem;
        background-color: ${colorToCode('light-100')};

        border-width: 0.0625rem 0 0.0625rem 0;
        border-style: solid;
        border-color: ${colorToCode('dark-900-24')};
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 0.625rem;
        `}
      >
        {leftControls.map((control, index) =>
          !('shrank' in control)
            ? React.cloneElement(control.expanded, { key: index })
            : index + 1 >= shrinkAtIndex
            ? React.cloneElement(control.shrank, { key: index })
            : React.cloneElement(control.expanded, { key: index }),
        )}
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          gap: 0.625rem;
        `}
      >
        {rightControls.map((control, index) =>
          !('shrank' in control)
            ? React.cloneElement(control.expanded, { key: index })
            : index + 1 >= shrinkAtIndex
            ? React.cloneElement(control.shrank, { key: index })
            : React.cloneElement(control.expanded, { key: index }),
        )}
      </div>
    </div>
  );
};

export default Toolbar;
