import { useContext } from 'react';
import DisposalTreeImpactAccountSummaryContext from '~/wm/packages/disposal/packages/tree-impact/context/DisposalTreeImpactAccountSummaryContext';

const useDisposalTreeImpactAccountSummaryContext = () => {
  const disposalTreeImpactAccountSummaryContext = useContext(DisposalTreeImpactAccountSummaryContext);
  if (!disposalTreeImpactAccountSummaryContext) {
    throw new Error('Tried to use DisposalTreeImpactAccountSummaryContext outside of an DisposalTreeImpactAccountSummaryProvider');
  }

  return disposalTreeImpactAccountSummaryContext;
};

export default useDisposalTreeImpactAccountSummaryContext;
