import { createContext } from 'react';
import {
  AutoEmailReportEmailTemplateDto,
  AutoEmailReportEmailTemplateVariableDto,
  AutoEmailReportReplyToUserAvailabilityDto,
} from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';

export type AutoEmailReportEmailTemplateContextData = {
  autoEmailReportEmailTemplate: AutoEmailReportEmailTemplateDto;
  emailReplyToUserAvailabilities: AutoEmailReportReplyToUserAvailabilityDto[];
  emailTemplateVariableAvailabilities: AutoEmailReportEmailTemplateVariableDto[];
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const AutoEmailReportEmailTemplateContext = createContext<AutoEmailReportEmailTemplateContextData | undefined>(undefined);

export default AutoEmailReportEmailTemplateContext;
