import useAutoEmailReportListContext from '~/wm/packages/organization/packages/auto-email-report/context/hooks/useAutoEmailReportListContext';
import AutoEmailReport from '~/wm/packages/organization/packages/auto-email-report/AutoEmailReport';
import { css } from '@emotion/react';
import React from 'react';

const AutoEmailReportSection = () => {
  const { autoEmailReports } = useAutoEmailReportListContext();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 2.125rem;
      `}
    >
      {autoEmailReports.map(report => (
        <AutoEmailReport
          key={report.autoEmailReportId}
          autoEmailReport={report}
        />
      ))}
    </div>
  );
};

export default AutoEmailReportSection;
