// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


/**
 * If adding new entries, please ensure to update Wm.Beast -> SnapshotSubjectFactory.Enum with the new types
 * ALSO ensure to add it to DeviceTypeEnumMatch PLEASEEEE
 * and also Wm.Beast -> AssetTypeConverter and Wm.Beast -> AssetBreakdownInsightService
 */
export enum Enum
{
  Workstation = "Workstation",
  Server = "Server",
  Virtual = "Virtual",
  Mobile = "Mobile",
  Network = "Network",
  Imaging = "Imaging",
  Storage = "Storage",
}


