import { Themeable } from '~/neo-ui/model/capacity';
import React from 'react';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import { css } from '@emotion/react';
import Theme from '~/neo-ui/packages/color/Theme';
import FilterHeaderDropdownIcon from '~/neo-ui/packages/filter/packages/filter-headers/filter-header-common/filter-header-dropdown-icon/FilterHeaderDropdownIcon';
import FilterHeaderLabel from '~/neo-ui/packages/filter/packages/filter-headers/filter-header-common/filter-header-label/FilterHeaderLabel';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

type FilterPreviewMultiSelectProps = {
  selectedValues: string[];
  operator?: string;
  theme?: Theme;
  fullWidth?: boolean;
  isOpen: boolean;
} & Themeable;

const FilterPreviewMultiSelect: React.FunctionComponent<FilterPreviewMultiSelectProps> = ({
  selectedValues,
  operator,
  isOpen,
  theme = 'secondary',
  fullWidth = false,
}) => {
  const { themeMap } = useTheme(theme);

  const bgColor = selectedValues.length >= 1 ? themeMap.foregroundAccent : 'light-000';
  return (
    <div
      // Padding right only because the label has left and right padding
      // | < 10 px > <label> < 10 px > <other stuff> < 10 px > |
      // we don't want to double-pad the label
      css={css`
        background-color: ${colorToCode(bgColor)};
        width: ${fullWidth ? '100%' : 'auto'};
        padding-right: 10px;
      `}
    >
      <FilterHeaderLabel
        selectedValues={selectedValues}
        operatorLabel={operator}
        theme={theme}
      />
      <FilterHeaderDropdownIcon
        isOpen={isOpen}
        color={'light-000'}
      />
    </div>
  );
};

export default FilterPreviewMultiSelect;
