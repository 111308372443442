import * as React from 'react';
import VendorLogo from '~/wm/packages/integration/packages/scalepad-account/packages/add-integration-page/VendorLogo';
import { IntegrationVendorDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Controller/IntegrationPage.gen';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { css } from '@emotion/react';

export type VendorCardProps = {
  integrationVendor: IntegrationVendorDto;
};

const VendorCard: React.FunctionComponent<VendorCardProps> = ({ integrationVendor }) => (
  <a
    href={`add/${integrationVendor.urlSlug}`}
    css={css`
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      justify-content: flex-start;
      width: fit-content;
      align-items: center;
      text-align: center;
      border-radius: 8px;
      background-color: ${colorToCode('light-000')};
      text-decoration: none;
    `}
  >
    <VendorLogo logoSlug={integrationVendor.svgToken} />
  </a>
);

export default VendorCard;
