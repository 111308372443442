import * as React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import lifecycleReportCustomizationSettingsFormValidationSchema from '~/neo-ui/packages/table/packages/console/download/packages/lifecycle-report-download-button/packages/lifecycle-report-customization-settings-form/validation/lifecycleReportCustomizationSettingsFormValidationSchema';

export type LifecycleReportCustomizationSettingsFormData = {
  showCpu: boolean;
  showRam: boolean;
  showStorage: boolean;
  showLastCheckinDate: boolean;
  shouldSaveDefaultSettings: boolean;
};

type LifecycleReportCustomizationSettingsFormProps = {
  showCpu: boolean;
  showRam: boolean;
  showStorage: boolean;
  showLastCheckinDate: boolean;
};

const LifecycleReportCustomizationSettingsForm: React.FunctionComponent<
  React.PropsWithChildren<LifecycleReportCustomizationSettingsFormProps>
> = ({ showCpu, showRam, showStorage, showLastCheckinDate, children }) => {
  const toFormData = React.useCallback(
    (): LifecycleReportCustomizationSettingsFormData => ({
      showCpu,
      showRam,
      showStorage,
      showLastCheckinDate,
      shouldSaveDefaultSettings: false,
    }),
    [showCpu, showLastCheckinDate, showRam, showStorage],
  );

  const defaultFormData = React.useMemo(() => toFormData(), [toFormData]);

  const onSubmit = React.useCallback(async () => {}, []);

  return (
    <Form
      submitMethod={'manual'}
      hideSubmissionButton={true}
      defaultFormData={defaultFormData}
      validationSchema={lifecycleReportCustomizationSettingsFormValidationSchema}
      onSubmit={onSubmit}
      hideSubmitStatusIndicator={true}
    >
      {children}
    </Form>
  );
};

export default LifecycleReportCustomizationSettingsForm;
