import Box from '~/neo-ui/packages/box/Box';
import * as React from 'react';
import { css } from '@emotion/react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Testable from '~/neo-ui/packages/testable/Testable';
import OrderSummaryAssetModule from '~/wm/packages/disposal/wizard/section/packages/module/order-summary-asset-module/OrderSummaryAssetModule';
import OrderSummaryFreeCostModule from '~/wm/packages/disposal/wizard/section/packages/module/order-summary-free-cost-module/OrderSummaryFreeCostModule';
import OrderSummaryReturnAddressModule from '~/wm/packages/disposal/wizard/section/packages/module/order-summary-return-address-module/OrderSummaryReturnAddressModule';
import DisposalSelfCheckoutFormData from '~/wm/packages/disposal/types/DisposalSelfCheckoutFormData';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import DisposalAssetTypeCountFormData from '~/wm/packages/disposal/types/DisposalAssetTypeCountFormData';

export type SelfOrderSummarySectionProps = {
  disposalTermsOfServiceUrl: string;
  termsOfServiceUrl: string;
  brandName: string;
  assetTypeCountsFieldKey: FieldKeyExpression<DisposalSelfCheckoutFormData>;
  shipmentReturnAddressFieldKey: FieldKeyExpression<DisposalSelfCheckoutFormData>;
  disposalAssetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[];
};

const SelfOrderSummaryWithoutBillingSection = ({
  disposalTermsOfServiceUrl,
  termsOfServiceUrl,
  brandName,
  assetTypeCountsFieldKey,
  shipmentReturnAddressFieldKey,
  disposalAssetTypeAvailabilities,
}: SelfOrderSummarySectionProps) => {
  const { getFormInput } = useFormContext<DisposalSelfCheckoutFormData>();

  const assetTypeCounts = getFormInput<DisposalAssetTypeCountFormData[]>(assetTypeCountsFieldKey).value;

  const totalAssetCount = assetTypeCounts.reduce((final, current) => final + parseInt(String(current.count), 10), 0);

  return (
    <Testable testId={'disposal-order-summary'}>
      <Box
        padding={'padding200'}
        boxShadow={'shadow0'}
        borderRadius={'radius400'}
      >
        <Header
          size={4}
          weight={'bold'}
          css={css`
            margin-bottom: 1.5rem;
          `}
        >
          Order summary
        </Header>
        <OrderSummaryAssetModule
          header={'Pack and ship:'}
          assetTypeCountsFieldKey={assetTypeCountsFieldKey}
          disposalAssetTypeAvailabilities={disposalAssetTypeAvailabilities}
        />
        <hr
          css={css`
            margin-top: 0.90625rem;
            margin-bottom: 0.90625rem;
          `}
        />
        <OrderSummaryReturnAddressModule
          shipmentReturnAddressFieldKey={shipmentReturnAddressFieldKey}
          header={totalAssetCount !== 1 ? 'The assets are located at:' : 'The asset is located at:'}
          description={'After you’ve placed your order, you’ll be able to choose whether to drop off or schedule a pick up.'}
        />
        <hr
          css={css`
            margin-top: 0.90625rem;
            margin-bottom: 0.90625rem;
          `}
        />
        <OrderSummaryFreeCostModule
          termsOfServiceUrl={termsOfServiceUrl}
          disposalTermsOfServiceUrl={disposalTermsOfServiceUrl}
          brandName={brandName}
        />
      </Box>
    </Testable>
  );
};

export default SelfOrderSummaryWithoutBillingSection;
