import { css } from '@emotion/react';
import * as React from 'react';
import { countryList } from '~/neo-ui/model/Country';
import FormSelectInput, { FormSelectOption } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import FormTextInput from '~/neo-ui/packages/form/packages/form-input/packages/form-text-input/FormTextInput';
import JoinedInputGroup from '~/neo-ui/packages/input/packages/joined-input-group/JoinedInputGroup';
import { BillingStepSectionFieldKeys } from '../../model/BillingInfoStepFieldKeys';
import Testable from '~/neo-ui/packages/testable/Testable';

export type BillingStepSectionProps<T> = {
  billingStepSectionFieldKeys: BillingStepSectionFieldKeys<T>;
};

const BillingStepSection = <T,>({ billingStepSectionFieldKeys }: BillingStepSectionProps<T>) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      text-align: left;
    `}
  >
    <div
      css={css`
        font-size: 1.125rem;
        font-weight: bold;
        margin-bottom: 1.25rem;
      `}
    >
      Billing address
    </div>
    <div
      css={css`
        margin-bottom: 1.625rem;
      `}
    >
      <p
        css={css`
          font-weight: bold;
          margin-bottom: 0.625rem;
        `}
      >
        Company name
      </p>
      <FormTextInput
        fieldKey={billingStepSectionFieldKeys.companyNameFieldKey}
        placeholder="Company name"
      />
    </div>
    <div
      css={css`
        display: flex;
        margin-bottom: 1.625rem;
      `}
    >
      <div
        css={css`
          width: 14.1875rem;
          margin-right: 0.4rem;
        `}
      >
        <p
          css={css`
            font-weight: bold;
            margin-bottom: 0.625rem;
          `}
        >
          Country
        </p>
        <FormSelectInput
          fieldKey={billingStepSectionFieldKeys.countryIso3FieldKey}
          options={countryList.map(
            (country): FormSelectOption => ({
              label: country.name,
              value: country.iso3Code,
            }),
          )}
        />
      </div>
      <div
        css={css`
          width: 14.1875rem;
        `}
      >
        <p
          css={css`
            font-weight: bold;
            margin-bottom: 0.625rem;
          `}
        >
          State / Province / Territory
        </p>
        <Testable testId={'wizard-billing-province-field'}>
          <FormTextInput
            fieldKey={billingStepSectionFieldKeys.stateCodeFieldKey}
            placeholder="State / Province / Territory"
          />
        </Testable>
      </div>
    </div>
    <div
      css={css`
        margin-bottom: 1.625rem;
      `}
    >
      <p
        css={css`
          font-weight: bold;
          margin-bottom: 0.625rem;
        `}
      >
        Address
      </p>
      <JoinedInputGroup>
        <Testable testId={'wizard-billing-address-field'}>
          <FormTextInput
            fieldKey={billingStepSectionFieldKeys.addressLineOneFieldKey}
            placeholder="Address line 1"
          />
        </Testable>
        <FormTextInput
          fieldKey={billingStepSectionFieldKeys.addressLineTwoFieldKey}
          placeholder="Address line 2 (optional)"
        />
      </JoinedInputGroup>
    </div>
    <div
      css={css`
        display: flex;
        margin-bottom: 1.625rem;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-right: 0.4rem;
          width: 14.1875rem;
        `}
      >
        <p
          css={css`
            font-weight: bold;
            margin-bottom: 0.625rem;
          `}
        >
          City/Town
        </p>
        <Testable testId={'wizard-billing-city-field'}>
          <FormTextInput
            fieldKey={billingStepSectionFieldKeys.cityFieldKey}
            placeholder={'City/Town'}
          />
        </Testable>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 14.1875rem;
        `}
      >
        <p
          css={css`
            font-weight: bold;
            margin-bottom: 0.625rem;
          `}
        >
          Postal code/ZIP
        </p>
        <Testable testId={'wizard-billing-postal-code-field'}>
          <FormTextInput
            fieldKey={billingStepSectionFieldKeys.postalCodeFieldKey}
            placeholder={'Postal code/ZIP'}
          />
        </Testable>
      </div>
    </div>
    <div
      css={css`
        margin-bottom: 1.625rem;
        width: 14.1875rem;
      `}
    >
      <p
        css={css`
          font-weight: bold;
          margin-bottom: 0.625rem;
        `}
      >
        Phone number
      </p>
      <Testable testId={'wizard-billing-phone-number-field'}>
        <FormTextInput
          fieldKey={billingStepSectionFieldKeys.phoneNumberFieldKey}
          placeholder="Phone number"
        />
      </Testable>
    </div>
  </div>
);

export default BillingStepSection;
