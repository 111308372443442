import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { ErrorIcon, SuccessIcon, WarningIcon } from '~/sp-ui/icons';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import * as React from 'react';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';

type TwoWaySyncLabelProps = {
  supportsWarrantyExpiryDate: boolean;
  supportsPurchaseDate: boolean;
};

const TwoWaySyncLabel = ({ supportsWarrantyExpiryDate, supportsPurchaseDate }: TwoWaySyncLabelProps) => {
  let className: string;
  let content: EmotionJSX.Element;
  if (supportsPurchaseDate && supportsWarrantyExpiryDate) {
    content = <SuccessIcon />;
    className = 'boolean-true';
  } else if (supportsPurchaseDate) {
    content = (
      <Tooltip content={'Purchase date only'}>
        <WarningIcon />
      </Tooltip>
    );
    className = 'boolean-warning';
  } else if (supportsWarrantyExpiryDate) {
    content = (
      <Tooltip content={'Warranty expiry date only'}>
        <WarningIcon />
      </Tooltip>
    );
    className = 'boolean-warning';
  } else {
    content = <ErrorIcon />;
    className = 'boolean-false';
  }

  return <Label className={className}>{content}</Label>;
};

export default TwoWaySyncLabel;
