import * as React from 'react';

export type TestableProps = {
  testId: string;
};

const Testable: React.FunctionComponent<React.PropsWithChildren<TestableProps>> = ({ testId, children }) => (
  <React.Fragment>
    {/*
      Using a sibling inline-style element to avoid testable from affecting the DOM
      Similar to working around emotion, use nth-of-type selectors instead of nth-child to avoid issues
      https://emotion.sh/docs/ssr
     */}
    <style data-test={testId} />
    {children}
  </React.Fragment>
);

export default Testable;
