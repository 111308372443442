import { useContext } from 'react';
import LayoutHeaderContext from '~/neo-ui/packages/layout/packages/layout-enceladus/context/LayoutHeaderContext';

const useLayoutHeaderContext = () => {
  const layoutHeaderContext = useContext(LayoutHeaderContext);
  if (!layoutHeaderContext) {
    throw new Error('Tried to use LayoutHeaderContext outside of an LayoutHeaderProvider');
  }

  return layoutHeaderContext;
};

export default useLayoutHeaderContext;
