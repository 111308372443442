import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import InputText from '~/neo-ui/packages/input/packages/input-text/InputText';

export type SearchInputTextProps = {
  onTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  onClear: () => void;
  disabled?: boolean;
  placeholder?: string;
} & Styleable;

const SearchInputText: React.FunctionComponent<SearchInputTextProps> = ({
  className,
  onTextChange,
  value,
  onClear,
  disabled,
  placeholder = 'Find in list...',
}) => {
  const inputValue = disabled ? '' : value;
  return (
    <InputText
      onTextChange={onTextChange}
      value={inputValue}
      placeholder={disabled ? '' : placeholder}
      className={className}
      disabled={disabled}
      onClear={onClear}
    />
  );
};

export default SearchInputText;
