import { initiativeCreate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { useCallback, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { InitiativeCreateSource } from '~/wm/packages/strategy/model/strategyDataCollectionSources';
import routes from '~/router/types/routes';
import { InitiativeAssetSourcesPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';

type CreateInitiativeFunction = (
  organizationId: string,
  shouldRedirect: boolean,
  createdFrom: InitiativeCreateSource,
  initiativeTemplate?: { id: string; name: string },
  assetSources?: InitiativeAssetSourcesPayloadDto,
  engagementActionId?: string,
) => void;

type UseInitiativeCreate = (onSuccessAction?: () => void) => {
  isCreating: boolean;
  createInitiative: CreateInitiativeFunction;
};

const useInitiativeCreate: UseInitiativeCreate = onSuccessAction => {
  const { callApi } = useApi();
  const [isLoading, setLoading] = useState(false);

  const createInitiative = useCallback<CreateInitiativeFunction>(
    async (organizationId, shouldRedirect, createdFrom, initiativeTemplate, assetSources, engagementActionId) => {
      setLoading(true);

      const response = await callApi(() =>
        initiativeCreate({
          organizationId,
          createdFrom,
          initiativeTemplateId: initiativeTemplate?.id,
          assetSources,
          name: initiativeTemplate?.name,
          engagementActionId,
        }),
      );

      if (!response) {
        return;
      }

      setLoading(false);
      if (shouldRedirect) {
        window.location.href = `${routes.ORGANIZATION_STRATEGY_ROADMAP_VIEW.build([organizationId])}#${response.initiativeId}`;
      }

      if (typeof onSuccessAction !== 'undefined') {
        onSuccessAction();
      }
    },
    [callApi, onSuccessAction],
  );

  return {
    isCreating: isLoading,
    createInitiative,
  };
};

export default useInitiativeCreate;
