import * as React from 'react';
import { css } from '@emotion/react';
import { RenderCellDataNested } from '@AssetManagementClient/BeastClient/Renderer/Model/Data.gen';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type RenderTextCellProps = {
  data: RenderCellDataNested.TextData;
};

const RenderTextCell: React.FunctionComponent<RenderTextCellProps> = ({ data }) => (
  <span
    css={css`
      ${typeof data.color !== 'undefined' &&
      css`
        color: ${colorToCode(data.color as Color)};
      `}
    `}
  >
    {data.text ?? '-'}
  </span>
);

export default RenderTextCell;
