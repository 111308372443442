import Box from '~/neo-ui/packages/box/Box';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import TextBlock from '~/neo-ui/packages/text/packages/text-block/TextBlock';
import { css } from '@emotion/react';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import { UserOrganizationStarInfoDto } from '@AssetManagementClient/BeastClient/Beast/Identity/Packages/User/Packages/OrganizationStar/Dto.gen';
import Theme from '~/neo-ui/packages/color/Theme';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import routes from '~/router/types/routes';
import AnchorWrapper from '~/neo-ui/packages/anchor/packages/anchor-wrapper/AnchorWrapper';

export type OrganizationStarBoxProps = {
  organization: UserOrganizationStarInfoDto;
};

const OrganizationStarBox = ({ organization }: OrganizationStarBoxProps) => {
  const { themeMap } = useTheme(organization.dmiBand.theme as Theme);

  return (
    <AnchorWrapper href={routes.ORGANIZATION_DASHBOARD.build([organization.organizationId])}>
      <Box
        key={organization.organizationId}
        padding={'padding100'}
        borderRadius={'radius300'}
        css={css`
          flex: 1;
          width: 13rem;
          box-shadow: inset 0 0 0 0.0625rem ${colorToCode(themeMap.backgroundAccent)};
          background-color: ${organization.dmiBand.theme === 'positive' ? 'light-000' : colorToCode(themeMap.backgroundRaised)};
          cursor: pointer;
          transition: all 100ms ease-in-out;

          &:hover {
            box-shadow: inset 0 0 0 0.125rem ${colorToCode(themeMap.foregroundRaised)};
          }
        `}
      >
        <TextBlock
          icon={organization.dmiBand.icon as IconType}
          iconColor={themeMap.foregroundAccent}
          title={organization.nameDisplay}
          description={`DMI ${organization.dmiBand.scoreDisplay}`}
          css={css`
            * {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          `}
        />
      </Box>
    </AnchorWrapper>
  );
};

export default OrganizationStarBox;
