import { SVGProps, forwardRef, memo } from 'react';

const PriceTagIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g id="icon-discount">
          <path
            id="Subtract"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7071 21.2929L21.2929 13.7071C21.6834 13.3166 21.6834 12.6834 21.2929 12.2929L11.2929 2.29289C11.1054 2.10536 10.851 2 10.5858 2H3C2.44772 2 2 2.44772 2 3V10.5858C2 10.851 2.10536 11.1054 2.29289 11.2929L12.2929 21.2929C12.6834 21.6834 13.3166 21.6834 13.7071 21.2929ZM7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9Z"
            fill="currentColor"
          />
        </g>
      </svg>
    );
  }),
);

export default PriceTagIcon;
