import React from 'react';

export type ProposalBuilderGridSize = 'sm' | 'md';

type UseProposalBuilderGrid = {
  gridFrs: { outerFr: string; innerLeftFr: string; innerRightFr: string };
};

const useProposalBuilderGrid = (gridSize: ProposalBuilderGridSize): UseProposalBuilderGrid => {
  const gridFrs = React.useMemo(() => gridSizeToGridFr(gridSize), [gridSize]);

  return { gridFrs };
};

const gridSizeToGridFr = (gridSize: ProposalBuilderGridSize) => {
  switch (gridSize) {
    case 'md':
      return {
        outerFr: '5fr 8fr',
        innerLeftFr: '4fr 1fr',
        innerRightFr: '2fr 2fr 2fr 2fr',
      };
    case 'sm':
      return {
        outerFr: '1fr 1fr',
        innerLeftFr: '2fr 1fr',
        innerRightFr: '2fr 2fr 2fr 2fr',
      };
  }
};

export default useProposalBuilderGrid;
