import WizardPageStep from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageStep';
import { BillingInfo as BillingInfoType } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { PaymentVersion } from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';
import DisposalSelfCheckoutFormData from '~/wm/packages/disposal/types/DisposalSelfCheckoutFormData';
import { DisposalDynamicPricingDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Dto/Model.gen';
import SelfOrderSummaryWithBillingSection from '~/wm/packages/disposal/wizard/section/SelfOrderSummaryWithBillingSection';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import PaymentSection from '~/wm/packages/disposal/wizard/section/PaymentSection';

const assetTypeCountsFieldKey: FieldKeyExpression<DisposalSelfCheckoutFormData> = values => values.assetTypeCounts;

const creditCardFieldKey: FieldKeyExpression<DisposalSelfCheckoutFormData> = values => values.customerToken;

const couponFieldKey: FieldKeyExpression<DisposalSelfCheckoutFormData> = values => values.coupon;

const shipmentReturnAddressFieldKey: FieldKeyExpression<DisposalSelfCheckoutFormData> = values =>
  values.shipmentReturnAddress.billingAddress;

const getDisposalSelfBillingStep = (
  billingInfo: BillingInfoType,
  paymentVersion: PaymentVersion,
  disposalTermsOfServiceUrl: string,
  termsOfServiceUrl: string,
  brandName: string,
  disposalDynamicPricing: DisposalDynamicPricingDto,
  disposalAssetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[],
): WizardPageStep<DisposalSelfCheckoutFormData> => ({
  header: {
    label: 'Billing',
    icon: 'CreditCard',
  },
  fields: [creditCardFieldKey],
  sections: [
    () =>
      SelfOrderSummaryWithBillingSection({
        disposalTermsOfServiceUrl,
        termsOfServiceUrl,
        brandName,
        assetTypeCountsFieldKey,
        shipmentReturnAddressFieldKey,
        couponFieldKey,
        disposalDynamicPricing,
        disposalAssetTypeAvailabilities,
      }),
    ({ isEditingIndex, onEditing }) =>
      PaymentSection({
        disposalDynamicPricing,
        billingInfo,
        paymentVersion,
        creditCardFieldKey,
        couponFieldKey,
        assetTypeCountsFieldKey,
        isEditingIndex,
        onEditing,
      }),
  ],
  onCompleteStateChange: () => true,
  key: 'Billing',
});

export default getDisposalSelfBillingStep;
