import { useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { contractTemplateGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { ContractBillingCurrencyDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Billing/Dto/Model.gen';
import { ContractCreateFormData } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-create-button/packages/contract-create-form/ContractCreateForm';

export type UseLoadContractFromTemplate = () => {
  loadContractFromTemplate: (
    contractTemplateId: string,
    contractBillingCurrency: ContractBillingCurrencyDto,
    onContractTemplateLoad: (contractTemplate: ContractCreateFormData) => void,
  ) => void;
};

const useLoadContractFromTemplate: UseLoadContractFromTemplate = () => {
  const { callApi } = useApi();

  const loadContractFromTemplate = useCallback(
    async (
      contractTemplateId: string,
      contractBillingCurrency: ContractBillingCurrencyDto,
      onContractTemplateLoad: (contractTemplate: ContractCreateFormData) => void,
    ) => {
      const response = await callApi(() => contractTemplateGet({ contractTemplateId }));

      if (!response) {
        return;
      }

      const contractTemplate = response.contractTemplate;

      onContractTemplateLoad({
        basic: {
          vendor: contractTemplate.title,
          account: contractTemplate.category,
          description: contractTemplate.description,
          impact: contractTemplate.impact,
          status: contractTemplate.status.uniqueId,
          isThirdParty: contractTemplate.isThirdParty ?? false,
          location: undefined,
        },
        billing: {
          cycle: contractTemplate.billingCycle.uniqueId,
          costType: contractTemplate.billingCostType,
          costVariables: {
            perSeatCostSubunits: undefined,
            costSubunits: contractTemplate.billingCostSubunits / contractBillingCurrency.subunitRatio,
            numberOfSeats: contractTemplate.numberOfSeats,
          },
          isAutoRenew: contractTemplate.isAutoRenew ?? false,
          endDate: undefined,
          nextDue: undefined,
          budgetPastEndDate: false,
        },
        alert: {
          noticeDays: contractTemplate.notifyDaysBeforeEndDate,
        },
        asset: {
          assets: [],
        },
      });
    },
    [callApi],
  );

  return {
    loadContractFromTemplate,
  };
};

export default useLoadContractFromTemplate;
