/* eslint-disable @typescript-eslint/naming-convention */
import { CardCvcElement } from '@stripe/react-stripe-js';
import { StripeCardCvcElementChangeEvent, StripeElementsOptions } from '@stripe/stripe-js';
import { ComponentPropsWithoutRef, useState } from 'react';
import ErrorIcon from '~/sp-ui/icons/ErrorIcon';
import theme from '~/sp-ui/theme';

export interface CardNumberProps extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  stripeOptions?: StripeElementsOptions;
  onChange?: (event: StripeCardCvcElementChangeEvent) => void | Promise<void>;
}

const CardCvc = (props: CardNumberProps) => {
  const { stripeOptions, onChange: _onChange, ...rest } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState<string>();

  const setFocus = () => setIsFocused(true);
  const clearFocus = () => setIsFocused(false);
  const onChange: typeof _onChange = e => {
    if (e.error) {
      setError(e.error.message);
    } else {
      setError(undefined);
    }

    _onChange?.(e);
  };

  return (
    <div
      css={{ width: '7.5rem' }}
      {...rest}
    >
      <label
        htmlFor="card-cvc"
        css={[{ all: 'unset' }, theme.typography.body]}
      >
        Security code
      </label>
      <CardCvcElement
        id="card-cvc"
        css={[
          {
            borderRadius: '4px',
            border: `1px solid ${theme.palette.grey2}`,
            backgroundColor: theme.palette.white,
            padding: '12px 9px',
          },
          isFocused && {
            borderColor: 'transparent',
            outlineOffset: '-2px',
            outline: `2px solid ${theme.palette.primary1}`,
          },
          error && {
            backgroundColor: theme.palette.error2,
            borderColor: theme.palette.error1,
            outline: 'none',
            boxShadow: '0px 0px 4px 2px rgba(215, 42, 71, 0.25)',
          },
        ]}
        options={{
          style: {
            base: {
              fontFamily: 'Roboto',
              fontSize: '16px',
              color: '#2F3037',
              backgroundColor: 'transparent',
            },
            invalid: {
              color: '#2F3037',
            },
          },
          ...stripeOptions,
        }}
        onFocus={setFocus}
        onBlur={clearFocus}
        onChange={onChange}
      />
      {error && (
        <span css={[theme.typography.small, { display: 'flex', alignItems: 'start', marginTop: 8, color: theme.palette.error1 }]}>
          <ErrorIcon css={{ width: 16, height: 16, flexShrink: 0, marginRight: 4 }} />
          {error}
        </span>
      )}
    </div>
  );
};

export default CardCvc;
