import WarrantyCartCoTerminationCostCalculator from '~/wm/packages/warranty/packages/warranty-cart/calculators/coTermination/WarrantyCartCoTerminationCostCalculator';
import { WarrantyCartSelectedAsset } from '~/wm/packages/warranty/packages/warranty-cart/types/WarrantyCartSelectedAsset';
import {
  defaultDynamicPrice,
  hideCalcCostConstant,
} from '~/wm/packages/warranty/packages/warranty-cart/constants/costCalculationConstants';
import {
  calculatePeriodDurationInMonths,
  getCoTerminationEndDate,
} from '~/wm/packages/warranty/packages/warranty-cart/calculators/warrantyCartCalculators';
import { RenewalOptionSellingPriceDto } from '@WarrantyClient/BeastClient/Beast/Estimate/Dto/Cart/PaginatedGet.gen';
import { WarrantyCartPriceCalculatorType } from '~/wm/packages/warranty/packages/warranty-cart/types/WarrantyCartCalculatorType';

const calculateCost = (
  assetAccountId: string | undefined,
  availablePlans: RenewalOptionSellingPriceDto[],
  selectedPlanId: string | undefined,
  selectedUnits: WarrantyCartSelectedAsset[],
  duration: number,
) => {
  const selectedUnit = selectedUnits.find(p => p.assetAccountId === assetAccountId);
  if (!selectedUnit) {
    return hideCalcCostConstant;
  }

  const selectedPlan = selectedPlanId ? availablePlans.find(p => p.planId === selectedPlanId) : availablePlans[0];

  const selectedAssetsStartDates = selectedUnits.map(p => p.renewalStart);
  const endDate = getCoTerminationEndDate(selectedAssetsStartDates, duration);
  const warrantyCoverageDuration = calculatePeriodDurationInMonths(new Date(selectedUnit.renewalStart), endDate);

  const dynamicPrices = selectedPlan?.dynamicPricesByTotalCoverageYears || defaultDynamicPrice;

  let totalDuration: number = 0;
  selectedUnits.forEach(unit => {
    if (totalDuration < 36) {
      const unitDuration = calculatePeriodDurationInMonths(new Date(unit.renewalStart), endDate);
      totalDuration += unitDuration;
    }
  });

  const idx = getPriceIndex(totalDuration);
  return dynamicPrices[idx] * warrantyCoverageDuration;
};

const getPriceIndex = (totalDurationInMonth: number): number => {
  const totalAssetsPerYear = totalDurationInMonth / 12;
  switch (true) {
    case totalAssetsPerYear < 2:
      return 1;
    case totalAssetsPerYear < 3:
      return 2;
    default:
      return 3;
  }
};

export const warrantyCartDynamicCoTerminationCostCalculator: WarrantyCartCoTerminationCostCalculator = {
  calculateCost,
  calculatorType: WarrantyCartPriceCalculatorType.Dynamic,
};
