import React from 'react';
import FormSelectInput from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import warrantyCartOpportunitiesFormFields from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-opportunity/packages/warranty-cart-opportunity-form/warrantyCartOpportunitiesFormFields';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';

const WarrantyCartOpportunityDurationDropDown: React.FunctionComponent = () => {
  const { durationOptions } = useWarrantyCartContext();

  return (
    <FormSelectInput
      fieldKey={warrantyCartOpportunitiesFormFields.durationMonth}
      options={durationOptions}
    />
  );
};

export default WarrantyCartOpportunityDurationDropDown;
