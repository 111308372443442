import { useState } from 'react';
import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import Button from '~/neo-ui/packages/button/Button';
import useEngagementActionTicketCreate from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionTicketCreate';
import { UseEngagementActionListMutationOptions } from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListMutation';
import Size from '~/neo-ui/model/Size';
import { Styleable } from '~/neo-ui/model/capacity';
import tooltipStyles from '~/neo-ui/packages/tooltip/styles/tooltipStyles';
import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';
import {
  descriptionFieldKeyId,
  titlefieldKeyId,
} from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/TicketCreateForm';
import TicketCreateButton from '~/wm/packages/integration/packages/ticket/packages/ticket-create-button/TicketCreateButton';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import formatTicketTitleEngagementAction from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/format/formatTicketTitleEngagementAction';
import formatTicketDescriptionEngagementAction from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/format/formatTicketDescriptionEngagementAction';

export type EngagementActionTicketMutationOptions = UseEngagementActionListMutationOptions;

export type EngagementActionTicketCreateButtonProps = {
  action: EngagementActionDto;
  fieldOptions: IntegrationFieldOptionDto[];
  size: Exclude<Size, 'xl'>;
  isOrganizationRegisteredForTickets: boolean;
  hasTicketIntegration: boolean;
} & Styleable;

const EngagementActionTicketCreateButton = ({
  action,
  fieldOptions,
  isOrganizationRegisteredForTickets,
  hasTicketIntegration,
  size,
  className,
}: EngagementActionTicketCreateButtonProps) => {
  const [hasError, setHasError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { organizationName } = useOrganizationContext();

  const { createEngagementActionTicket, isCreatingTicket } = useEngagementActionTicketCreate({
    onFailure: () => {
      setHasError(true);
    },
    onSuccess: () => {
      setIsOpen(false);
    },
  });

  return (
    <TicketCreateButton
      isOpen={isOpen}
      hasError={hasError}
      isCreatingTicket={isCreatingTicket}
      buttonComponent={
        <Button
          size={size}
          className={className}
          iconLeft={'IntegrationTicket'}
          preventOnClickPropagation={true}
          disabled={isCreatingTicket}
          loading={isCreatingTicket}
          css={tooltipStyles('Create ticket')}
          onClick={() => {
            setIsOpen(true);
          }}
        />
      }
      fieldOptions={fieldOptions}
      isOrganizationRegisteredForTickets={isOrganizationRegisteredForTickets}
      hasTicketIntegration={hasTicketIntegration}
      onCreateTicket={fieldValues => {
        createEngagementActionTicket({
          actionId: action.engagementActionId,
          fieldValues,
        });
      }}
      onDismiss={() => {
        setIsOpen(false);
      }}
      defaultFormData={{
        ticketFieldValues: {
          [titlefieldKeyId]: formatTicketTitleEngagementAction(action.description, fieldOptions),
          [descriptionFieldKeyId]: formatTicketDescriptionEngagementAction(
            action.description,
            action.assignedUsers,
            typeof action.dueAt !== 'undefined' ? new Date(action.dueAt) : undefined,
            organizationName,
            fieldOptions,
          ),
        },
      }}
    />
  );
};

export default EngagementActionTicketCreateButton;
