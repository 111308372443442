import * as React from 'react';
import { css } from '@emotion/react';
import Card from '~/legacy-ui/packages/card/Card';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { OfferSummaryDto } from '@SubscriptionClient/Subscription/Packages/Offer/Dto/Model.gen';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type DiscountOfferSummaryInfoProps = {
  discountPercentage: number;
  daysUntilExpiry: number;
  offerSummaryDto: OfferSummaryDto;
};

const DiscountOfferSummaryInfo: React.FunctionComponent<DiscountOfferSummaryInfoProps> = ({
  discountPercentage,
  daysUntilExpiry,
  offerSummaryDto,
}) => (
  <Card backgroundColor={'light-200'}>
    {discountPercentage !== 0 && (
      <div
        css={css`
          display: flex;
          align-items: flex-end;
          margin-bottom: 0.625rem;
          &:after {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            border-top: 0.625rem solid ${colorToCode('light-200')};
            border-left: 0.625rem solid transparent;
            top: 68px;
            left: -10px;
          }
        `}
      >
        <Header
          size={3}
          color={'positive-400'}
          css={css`
            margin-right: 5px;
            font-weight: 500;
          `}
        >
          {discountPercentage}%
        </Header>
        <Label
          size={'lg'}
          color={'positive-400'}
        >
          loyalty discount
        </Label>
      </div>
    )}
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-bottom: 0.625rem;
      `}
    >
      {offerSummaryDto.keyPoints.map((value, index) => (
        <span key={index}>✔ {value}</span>
      ))}
    </div>
    <Label
      css={css`
        font-size: 0.875rem;
        color: #d72a47;
      `}
    >
      Your offer expires in {daysUntilExpiry} days
    </Label>
  </Card>
);

export default DiscountOfferSummaryInfo;
