import usePolling from '~/extensions/packages/polling/hooks/usePolling';
import useApi from '~/wm/packages/api/hook/useApi';

/**
 * Periodically make API requests to a server to detect when the response tells us
 * to stop polling.
 */
const usePollingResponseDetectChange = <TResponse>(
  apiCall: () => Promise<TResponse>,
  responseKey: keyof TResponse,
  onDetectedChange: () => void,
  intervalMs: number,
  shouldPoll: boolean = true,
) => {
  const { callApi } = useApi();

  usePolling(
    async () => {
      const response = await callApi(apiCall);

      if (!response) {
        return;
      }

      if (typeof response[responseKey] !== 'boolean') {
        throw new Error(`Response key value is not a boolean`);
      }

      if (response[responseKey] !== shouldPoll) {
        onDetectedChange();
      }
    },
    intervalMs,
    shouldPoll,
  );
};

export default usePollingResponseDetectChange;
