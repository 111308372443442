import * as React from 'react';
import { BillingInfo as BillingInfoType } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import {
  CreditCardFormData,
  CreditCardFormDataNewCard,
  PaymentVersion,
} from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';
import useWizardPageStepActionUpdate from '~/neo-ui/packages/wizard/packages/wizard-page/hooks/useWizardPageStepActionUpdate';
import useWizardPageContext from '~/neo-ui/packages/wizard/packages/wizard-page/context/hooks/useWizardPageContext';
import formatBillingAddress from '~/wm/packages/subscription/packages/billing/operation/formatBillingAddress';
import Box from '~/neo-ui/packages/box/Box';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { WizardPageSectionProps } from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageSection';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { css } from '@emotion/react';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import { DisposalDynamicPricingDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Dto/Model.gen';
import DisposalAssetTypeCountFormData from '~/wm/packages/disposal/types/DisposalAssetTypeCountFormData';
import DisposalCheckoutCouponFormData from '~/wm/packages/disposal/types/DisposalCheckoutCouponFormData';
import DisposalPaymentForm from '~/wm/packages/disposal/wizard/packages/disposal-payment-form/DisposalPaymentForm';
import Label from '~/neo-ui/packages/text/packages/label/Label';

export type PaymentSectionProps<T> = {
  billingInfo: BillingInfoType;
  paymentVersion: PaymentVersion;
  creditCardFieldKey: FieldKeyExpression<T>;
  couponFieldKey: FieldKeyExpression<T>;
  assetTypeCountsFieldKey: FieldKeyExpression<T>;
  disposalDynamicPricing: DisposalDynamicPricingDto;
} & WizardPageSectionProps;

const PaymentSection = <T,>({
  billingInfo,
  paymentVersion,
  creditCardFieldKey,
  assetTypeCountsFieldKey,
  couponFieldKey,
  disposalDynamicPricing,
  onEditing,
}: PaymentSectionProps<T>) => {
  useWizardPageStepActionUpdate();
  const { setFieldCompleted } = useWizardPageContext();
  const { setFormInput, getFormInput } = useFormContext<T>();

  const billingAddressFormatted = formatBillingAddress(billingInfo.billingAddress!);

  const assetTypeCounts = getFormInput<DisposalAssetTypeCountFormData[]>(assetTypeCountsFieldKey).value;

  const assetCount = assetTypeCounts.reduce((final, current) => final + parseInt(String(current.count), 10), 0);

  const creditCardFormData: CreditCardFormData | null = getFormInput<CreditCardFormData>(creditCardFieldKey).value;

  const coupon: DisposalCheckoutCouponFormData | undefined = getFormInput<DisposalCheckoutCouponFormData>(couponFieldKey).value;

  return (
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
    >
      <LayoutSection
        title={'Payment'}
        titleSize={4}
        titleWeight={'bold'}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Label
            size={'md'}
            bold={true}
          >
            {billingInfo.billingAddress!.name}
          </Label>
          <Label
            size={'md'}
            color={'dark-900-64'}
          >
            {billingAddressFormatted}
          </Label>
        </div>
        <DisposalPaymentForm
          creditCardFormData={creditCardFormData}
          coupon={coupon}
          paymentVersion={paymentVersion}
          disposalDynamicPricing={disposalDynamicPricing}
          assetCount={assetCount}
          onEditing={(isEditing, fieldKey) => {
            setFieldCompleted(fieldKey, !isEditing);
            onEditing(isEditing);
          }}
          onNewCreditCard={creditCard => {
            setFieldCompleted(creditCardFieldKey, creditCard.isComplete);
            onEditing(!creditCard.isComplete);
            setFormInput<CreditCardFormDataNewCard>(creditCardFieldKey, creditCard);
          }}
          onCouponValidated={coupon => {
            setFormInput<DisposalCheckoutCouponFormData>(couponFieldKey, {
              code: coupon.code,
              label: coupon.label,
              offerCostSubunits: coupon.offerCostSubunits,
            });
            setFieldCompleted(couponFieldKey, true);
            onEditing(false);
          }}
          onCouponRemoved={() => {
            setFormInput<undefined>(couponFieldKey, undefined);
          }}
        />
      </LayoutSection>
    </Box>
  );
};

export default PaymentSection;
