import * as React from 'react';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Theme from '~/neo-ui/packages/color/Theme';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';

export type RadioListOption<T extends string = string> = {
  label: string;
  value: T;
  description?: string;
  descriptionMuted?: boolean;
  selectedTheme: Theme;
  hideRemoveButton?: boolean;
};

export type RadioListItemProps<T extends string = string> = {
  option: RadioListOption<T>;
  selectedOption?: T;
  onClick: (option: T) => void;
  onRemove?: (option: T) => void;
};

const RadioListItem = <T extends string = string>({ option, selectedOption, onClick, onRemove }: RadioListItemProps<T>) => {
  const { themeMap } = useTheme(option.selectedTheme);

  return (
    <div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;
        `}
        onClick={() => onClick(option.value)}
      >
        <input
          css={css`
            width: 1rem;
            height: 1rem;

            appearance: none;
            -webkit-appearance: none;
            border-radius: 50%;

            box-shadow: 0 0 0 0.125rem ${colorToCode('dark-900-12')};
            background: 0.375rem solid ${colorToCode('light-700')};

            cursor: pointer;

            :checked {
              box-shadow: 0 0 0 0.125rem ${colorToCode(themeMap.foregroundAccent)};
              border: 0.25rem solid ${colorToCode(themeMap.foregroundAccent)};
            }
          `}
          type="radio"
          checked={option.value === selectedOption}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Label
            bold={option.value === selectedOption}
            size={'md'}
            color={option.value === selectedOption ? themeMap.foregroundAccent : 'dark-500'}
          >
            {option.label}
          </Label>
          <Label
            size={'sm'}
            muted={option.descriptionMuted}
          >
            {option.description}
          </Label>
        </div>
        {typeof onRemove !== 'undefined' && !option.hideRemoveButton && (
          <ButtonModifier
            css={css`
              margin-left: auto;
              margin-right: unset;
              min-width: unset;
              height: min-content;
            `}
            icon={'Remove'}
            onClick={() => {
              onRemove(option.value);
            }}
            preventOnClickPropagation={true}
          />
        )}
      </div>
    </div>
  );
};
export default RadioListItem;
