import { css } from '@emotion/react';
import { ComponentPropsWithoutRef, PropsWithChildren, forwardRef, memo } from 'react';
import theme from '~/sp-ui/theme';
import { CloseIcon, ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from '../icons';
import Text from '../text/Text';

export interface MessageProps extends ComponentPropsWithoutRef<'div'> {
  text?: string;
  type?: 'error' | 'success' | 'info' | 'warning';
  onClose?: () => void;
}

const iconCss = css({
  flexShrink: 0,
  alignSelf: 'start',
});

const Message = memo(
  forwardRef<HTMLDivElement, PropsWithChildren<MessageProps>>((props, ref) => {
    const { text, type, onClose, children, ...rest } = props;
    return (
      <div
        ref={ref}
        css={[
          theme.typography.body,
          {
            borderRadius: 5,
            border: `1px solid ${theme.palette.grey2}`,
            padding: '0.75rem',
            backgroundColor: theme.palette.grey4,
            gap: '0.62rem',
            display: 'flex',
            alignItems: 'center',
          },
          type === 'error' && {
            borderColor: theme.palette.error1,
            backgroundColor: theme.palette.error2,
          },
          type === 'success' && {
            borderColor: theme.palette.success1,
            backgroundColor: theme.palette.success2,
          },
          type === 'info' && {
            borderColor: theme.palette.primary1,
            backgroundColor: theme.palette.primary2,
          },
          type === 'warning' && {
            borderColor: theme.palette.warning1,
            backgroundColor: theme.palette.warning2,
          },
        ]}
        {...rest}
      >
        {type === 'error' && <ErrorIcon css={iconCss} />}
        {type === 'success' && <SuccessIcon css={iconCss} />}
        {type === 'warning' && <WarningIcon css={iconCss} />}
        {type === 'info' && <InfoIcon css={iconCss} />}

        {!!children && (
          <Text
            element="div"
            css={{ flex: 1 }}
          >
            {children}
          </Text>
        )}
        {!!text && <Text css={{ flex: 1 }}>{text}</Text>}
        {!!onClose && (
          <button
            type="button"
            css={{ all: 'unset', display: 'flex' }}
            onClick={onClose}
          >
            <CloseIcon />
          </button>
        )}
      </div>
    );
  }),
);

export default Message;
