import LayoutHeaderFooterProps from '~/neo-ui/packages/layout/types/LayoutHeaderFooterProps';
import LayoutHeaderPhp from '~/neo-ui/packages/layout/packages/header/LayoutHeaderPhp';
import LayoutHeaderEnceladus from '~/neo-ui/packages/layout/packages/header/LayoutHeaderEnceladus';
import { isIframe } from '~/extensions/packages/iframe/isIframe';
import { css } from '@emotion/react';

/**
 * A header that sticks to the bottom of the nav bar.
 */
const LayoutHeader = ({ className, leftControls, rightControls, layoutBarComponent }: LayoutHeaderFooterProps) =>
  typeof WM.account !== 'undefined' && WM.account.isEnceladusAllowed ? (
    <LayoutHeaderEnceladus
      layoutBarComponent={layoutBarComponent}
      leftControls={leftControls}
      rightControls={rightControls}
      className={className}
    />
  ) : (
    <LayoutHeaderPhp
      layoutBarComponent={layoutBarComponent}
      leftControls={leftControls}
      rightControls={rightControls}
      className={className}
      css={css`
        ${isIframe() ? 'left: 0;' : ''}
      `}
    />
  );

export default LayoutHeader;
