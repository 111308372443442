import * as React from 'react';
import RoadmapFeatureFlagsProvider from '~/wm/packages/strategy/packages/roadmap-page/context/RoadmapFeatureFlagsProvider';
import RoadmapUrlProvider from '~/wm/packages/strategy/packages/roadmap-page/context/RoadmapUrlProvider';
import RoadmapInitiativesProvider from '~/wm/packages/strategy/packages/roadmap-page/context/RoadmapInitiativesProvider';
import RoadmapPresentationV2 from '~/wm/packages/strategy/packages/roadmap-presentation/RoadmapPresentationV2';
import InitiativeAvailabilitiesProvider from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/InitiativeAvailabilitiesProvider';
import OrganizationProvider from '~/wm/packages/organization/context/OrganizationProvider';
import { Enum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import RegionalSettingsInfoProvider from '~/wm/packages/settings/packages/regional-settings/context/RegionalSettingsInfoProvider';

export type RoadmapPresentationProps = {
  organizationId: string;
  organizationName: string;
  insightUrl: string;
  upgradeUrl: string;
  growUrl: string;
  organizationRegionalSettingsUrl: string;
  enabledLmRoadmapsPdfOverview: boolean;
  enabledInitiativeTickets: boolean;
};

const RoadmapPresentation = ({
  organizationId,
  organizationName,
  insightUrl,
  upgradeUrl,
  growUrl,
  organizationRegionalSettingsUrl,
  enabledLmRoadmapsPdfOverview,
  enabledInitiativeTickets,
}: RoadmapPresentationProps) => (
  <OrganizationProvider
    organizationId={organizationId}
    organizationName={organizationName}
  >
    <InitiativeAvailabilitiesProvider frontendScope={{ organizationId, type: Enum.Organization }}>
      <RegionalSettingsInfoProvider
        frontendScope={{
          type: Enum.Organization,
          organizationId,
        }}
      >
        <RoadmapInitiativesProvider
          organizationId={organizationId}
          organizationName={organizationName}
          isPresenting={true}
        >
          <RoadmapUrlProvider
            insightUrl={insightUrl}
            upgradeUrl={upgradeUrl}
            growUrl={growUrl}
            organizationRegionalSettingsUrl={organizationRegionalSettingsUrl}
          >
            <RoadmapFeatureFlagsProvider
              enabledLmRoadmapsPdfOverview={enabledLmRoadmapsPdfOverview}
              enabledInitiativeTickets={enabledInitiativeTickets}
            >
              <RoadmapPresentationV2 />
            </RoadmapFeatureFlagsProvider>
          </RoadmapUrlProvider>
        </RoadmapInitiativesProvider>
      </RegionalSettingsInfoProvider>
    </InitiativeAvailabilitiesProvider>
  </OrganizationProvider>
);

export default RoadmapPresentation;
