import Badge from '~/neo-ui/packages/badge/Badge';
import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import * as React from 'react';
import usePanelTabGroupContext from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/hooks/usePanelTabGroupContext';
import { EngagementType } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/EngagementDashboard';
import ToggleTileHorizontal from '~/neo-ui/packages/toggle/packages/toggle-tile/packages/toggle-tile-horizontal/ToggleTileHorizontal';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import useEngagementActionListContext from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListContext';
import Window from '~/neo-ui/packages/window/Window';
import EngagementActionSelectableTable from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-action-list-panel-tab/packages/engagement-action-selectable-table/EngagementActionSelectableTable';
import { useCallback, useState } from 'react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import useInitiativeEngagementActionLinkBulk from '~/wm/packages/strategy/packages/initiative/packages/initiative-engagement-action-link-modal/hooks/useInitiativeEngagementActionLinkBulk';

export type InitiativeLinkActionWindowProps = {
  initiativeId: string;
  isOpen: boolean;
  onDismiss: () => void;
};
const InitiativeLinkActionWindow = ({ initiativeId, isOpen, onDismiss }: InitiativeLinkActionWindowProps) => {
  const { actions, statusAvailabilities } = useEngagementActionListContext();
  const [selectedActionIdsByStatus, setSelectedActionIdsByStatus] = useState<Map<string, string[]>>(
    new Map(statusAvailabilities.map(status => [status.id, []])),
  );
  const [statusType, setStatusType] = useState<string>(statusAvailabilities[0].id);
  const { currentPageByTab, currentTabId, setCurrentTabFilterValue } = usePanelTabGroupContext<EngagementType>();
  const currentPage = currentPageByTab.get(currentTabId);

  if (typeof currentPage === 'undefined') {
    throw new Error('Current page is undefined from tab context');
  }

  const selectedActionIds = [...selectedActionIdsByStatus.values()].reduce((final, ids) => [...final, ...ids], []);

  const onClose = useCallback(() => {
    setStatusType(statusAvailabilities[0].id);
    setCurrentTabFilterValue(statusAvailabilities[0].displayLabel);
    onDismiss();
  }, [onDismiss, setCurrentTabFilterValue, statusAvailabilities]);

  const { isLinking, linkEngagementActions } = useInitiativeEngagementActionLinkBulk(onClose);

  return (
    <Window
      css={css`
        background-color: ${colorToCode('light-000-80')};
        height: 100vh;
      `}
      isOpen={isOpen}
      title={''}
      onDismiss={onClose}
      maxWidthRem={82}
      footerProps={{
        leftControls: [
          {
            expanded: (
              <Button
                iconLeft={'Cancel'}
                onClick={onClose}
              >
                Cancel
              </Button>
            ),
          },
        ],
        rightControls: [
          {
            expanded: (
              <Button
                theme={'primary'}
                iconLeft={'Done'}
                loading={isLinking}
                disabled={selectedActionIds.length === 0}
                onClick={() => linkEngagementActions(initiativeId, selectedActionIds)}
              >
                {`Attach ${selectedActionIds.length} action item${selectedActionIds.length === 1 ? '' : 's'}`}
              </Button>
            ),
          },
        ],
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
        `}
      >
        <Header
          size={2}
          weight={'bold'}
        >
          Attach action items
        </Header>
        <Label
          size={'md'}
          color={'dark-400'}
        >
          You can add multiple action items to a single initiative. Choose to add this action items to this initiative.
        </Label>
        <div
          css={css`
            display: flex;
            gap: 1rem;
          `}
        >
          {statusAvailabilities.map(status => {
            const selectedActionIds = selectedActionIdsByStatus.get(status.id) ?? [];

            const count = selectedActionIds.length;

            const isSelected = status.id === statusType;

            return (
              <ToggleTileHorizontal
                key={status.id}
                css={css`
                  flex-grow: 1;
                  min-width: 0;
                  background-color: ${colorToCode(isSelected ? 'primary-050' : 'light-000')};
                `}
                title={status.displayLabel}
                type={'single'}
                isSelected={isSelected}
                disabled={false}
                onClick={() => {
                  setCurrentTabFilterValue(status.displayLabel);
                  setStatusType(status.id);
                }}
                appendComponent={
                  count > 0 && (
                    <Badge
                      bgColor={'light-000'}
                      textColor={'dark-700'}
                    >
                      {count} SELECTED
                    </Badge>
                  )
                }
              />
            );
          })}
        </div>

        <EngagementActionSelectableTable
          key={statusType}
          actions={actions.filter(action => typeof action.initiativeLinkInfo === 'undefined')}
          selectedActionIds={selectedActionIdsByStatus.get(statusType) ?? []}
          onActionSelected={actionId =>
            setSelectedActionIdsByStatus(prevState => {
              const newMap = new Map(prevState);
              newMap.set(statusType, [...(prevState.get(statusType) ?? [])].concat(actionId));
              return newMap;
            })
          }
          onActionDeselect={actionId =>
            setSelectedActionIdsByStatus(prevState => {
              const newMap = new Map(prevState);
              newMap.set(
                statusType,
                [...(prevState.get(statusType) ?? [])].filter(id => id !== actionId),
              );
              return newMap;
            })
          }
        />
      </div>
    </Window>
  );
};

export default InitiativeLinkActionWindow;
