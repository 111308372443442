import { FunctionComponent, useMemo, useState } from 'react';
import Window from '~/neo-ui/packages/window/Window';
import Button from '~/neo-ui/packages/button/Button';
import TableSelectable from '~/neo-ui/packages/table/packages/table-selectable/TableSelectable';
import usePeopleDashboardContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/context/hooks/usePeopleDashboardContext';
import { ActiveUser } from '@SsoClient/BeastClient/Beast/Identity/Model/User.gen';
import useOrganizationAccountTeamMemberAdd from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/useOrganizationAccountTeamMemberAdd';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import { css } from '@emotion/react';
import useActiveUsersContext from '~/wm/packages/account/packages/user/context/hooks/useActiveUsersContext';
import formatUserNameDisplay from '~/wm/packages/account/packages/user/packages/active-users-search/formatUserNameDisplay';
import { TableClientSideColumn } from '~/neo-ui/packages/table/packages/table-client-side/TableClientSide';

type AccountTeamMemberAddWindowProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

const AccountTeamMemberAddWindow: FunctionComponent<AccountTeamMemberAddWindowProps> = ({ isOpen, onDismiss }) => {
  const { organizationId } = useOrganizationContext();
  const { users } = useActiveUsersContext();
  const { organizationAccountTeamMembers } = usePeopleDashboardContext();

  const { addOrganizationAccountTeamMember, isAddingOrganizationAccountTeamMember } = useOrganizationAccountTeamMemberAdd();

  const [selectedUserIds, setSelectedUserIds] = useState<Set<string>>(new Set());
  const [currentPage, setCurrentPage] = useState(0);

  const filteredUsers = useMemo(
    () =>
      users.filter(
        user => !organizationAccountTeamMembers.find(organizationAccountTeamMember => organizationAccountTeamMember.userId === user.userId),
      ),
    [organizationAccountTeamMembers, users],
  );

  const userColumns: TableClientSideColumn<ActiveUser>[] = useMemo(
    () => [
      {
        fieldKey: user => user.firstName,
        header: 'Name',
        renderCell: ({ original: user }) => formatUserNameDisplay(user),
      },
      {
        fieldKey: user => user.email,
        header: 'Email',
        renderCell: ({ original: user }) => user.email,
      },
    ],
    [],
  );

  const addAccountTeamMembers = () => {
    const userIds = Array.from(selectedUserIds);
    addOrganizationAccountTeamMember({
      organizationId,
      accountTeamMemberIds: userIds,
    });
    setSelectedUserIds(new Set());
    onDismiss();
  };

  return (
    <Window
      maxWidthRem={39.5}
      titleIcon={'Add'}
      title={`Add Account Team Members`}
      isOpen={isOpen}
      onDismiss={onDismiss}
      footerProps={{
        leftControls: [
          ...(selectedUserIds.size > 0
            ? [
                {
                  expanded: (
                    <Button
                      iconLeft={'Cancel'}
                      onClick={() => {
                        setSelectedUserIds(new Set());
                      }}
                    >
                      {selectedUserIds.size} account team member
                      {selectedUserIds.size === 1 ? '' : 's'} selected
                    </Button>
                  ),
                },
              ]
            : []),
        ],
        rightControls: [
          {
            expanded: (
              <Button
                theme={'primary'}
                onClick={() => {
                  addAccountTeamMembers();
                }}
                loading={isAddingOrganizationAccountTeamMember}
                disabled={selectedUserIds.size < 1}
              >
                Done
              </Button>
            ),
          },
          {
            expanded: (
              <Button
                onClick={() => {
                  onDismiss();
                }}
                disabled={isAddingOrganizationAccountTeamMember}
              >
                Cancel
              </Button>
            ),
          },
        ],
      }}
    >
      <TableSelectable
        data={filteredUsers}
        dataKey={'userId'}
        columns={userColumns}
        dataTableProps={{
          pagination: { pageSize: 15, pageIndex: currentPage },
          onPageChange: pagination => setCurrentPage(pagination.pageIndex),
          children: ({ Table, Pagination, GlobalFilter }) => (
            <div>
              <div
                css={css`
                  display: flex;
                  gap: 0.5rem;
                  margin-bottom: 1rem;
                `}
              >
                <GlobalFilter placeholder={'Search name or email'} />
                <Pagination />
              </div>
              <Table />
            </div>
          ),
        }}
        updateSelectedIds={setSelectedUserIds}
        selectedIds={selectedUserIds}
      />
    </Window>
  );
};

export default AccountTeamMemberAddWindow;
