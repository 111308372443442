import * as React from 'react';
import BannerInternal from '~/neo-ui/packages/banner/BannerInternal';
import { css } from '@emotion/react';

const DisposalPermissionBanner = () => (
  <BannerInternal
    titleProps={{
      children: 'Heads up, you’ll need permission to place an order',
      size: 3,
      color: 'secondary-400',
      weight: 'bold',
    }}
    descriptionProps={{
      children: 'Request permission to place orders from your Admin, or ask someone with permission.',
      size: 4,
      color: 'dark-900',
    }}
    graphic={{ imageSource: '/i/marketing/disposal/image.png' }}
    backgroundColor={'light-050'}
    boxShadow={'shadow200'}
    css={css`
      margin-bottom: unset;
    `}
  />
);

export default DisposalPermissionBanner;
