import * as React from 'react';
import PdContactBar from '~/wm/packages/subscription/packages/partner-development/packages/pd-contact-info/packages/pd-contact-bar/PdContactBar';
import useSubscriptionPdContact from '~/wm/packages/subscription/packages/subscription-pd-contact/hooks/useSubscriptionPdContact';

const SubscriptionPdContactBar: React.FunctionComponent = () => {
  const { pdDisplayInfo } = useSubscriptionPdContact();

  if (typeof pdDisplayInfo === 'undefined') {
    return null;
  }

  return <PdContactBar pdDisplayInfo={pdDisplayInfo} />;
};

export default SubscriptionPdContactBar;
