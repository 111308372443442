import { scorecardScorecardItemRemoveBulk } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useScorecardMutation, { UseScorecardMutationOptions } from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardMutation';

export type UseScorecardItemRemove = {
  removeScorecardItem: (removePayload: { scorecardItemId: string }) => void;
  isRemovingScorecardItem: boolean;
};

const useScorecardItemRemove = (
  scorecardId: string,
  scorecardCategoryId: string | undefined,
  options?: UseScorecardMutationOptions,
): UseScorecardItemRemove => {
  const [removeScorecardItem, isRemovingScorecardItem] = useScorecardMutation(
    (removePayload: { scorecardItemId: string }) =>
      scorecardScorecardItemRemoveBulk({
        scorecardId,
        scorecardCategoryId,
        scorecardItemIds: [removePayload.scorecardItemId],
      }),
    options,
  );

  return {
    removeScorecardItem,
    isRemovingScorecardItem,
  };
};

export default useScorecardItemRemove;
