import InitiativeSaveControlsSection from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-controls-section/InitiativeSaveControlsSection';
import InitiativeSaveDetailsSection from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-details-section/InitiativeSaveDetailsSection';
import { css } from '@emotion/react';
import InitiativeSaveFeesSection from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-fees-section/InitiativeSaveFeesSection';
import InitiativeSaveTemplateSection from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-template-section/InitiativeSaveTemplateSection';
import InitiativeSaveAssetsSection from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-assets-section/InitiativeSaveAssetsSection';
import InitiativeSaveTicketSection from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-ticket-section/InitiativeSaveTicketSection';
import { InitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';
import InitiativeSaveActionSection from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-action-section/InitiativeSaveActionSection';
import InitiativeAssessmentQuestionListProvider from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-assessment-section/context/InitiativeAssessmentQuestionListProvider';
import InitiativeSaveAssessmentSection from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-assessment-section/InitiativeSaveAssessmentSection';

export type InitiativeSaveProps = {
  initiative?: InitiativeDto;
};

const InitiativeSave = ({ initiative }: InitiativeSaveProps) => {
  const {
    'lm-initiative-tickets': enabledInitiativeTickets,
    'lm-action-item-link-initiative': enabledInitiativeActionItem,
    'lm-assessment': enableAssessment,
  } = useFeatureFlagProvider();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
      `}
    >
      <InitiativeSaveControlsSection />
      <InitiativeSaveTemplateSection />
      <InitiativeSaveDetailsSection />
      <InitiativeSaveFeesSection />
      {enabledInitiativeTickets && <InitiativeSaveTicketSection initiative={initiative} />}
      {enabledInitiativeActionItem && typeof initiative !== 'undefined' && <InitiativeSaveActionSection initiative={initiative} />}
      {enableAssessment && typeof initiative !== 'undefined' && (
        <InitiativeAssessmentQuestionListProvider initiativeId={initiative.initiativeId}>
          <InitiativeSaveAssessmentSection />
        </InitiativeAssessmentQuestionListProvider>
      )}
      <InitiativeSaveAssetsSection />
    </div>
  );
};

export default InitiativeSave;
