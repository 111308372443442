import { organizationEngagementActionTicketCreate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementActionListMutation, {
  UseEngagementActionListMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListMutation';
import { TicketCreateFieldValueDto } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Dto/Model.gen';

export type EngagementActionTicketCreatePayload = {
  actionId: string;
  fieldValues: TicketCreateFieldValueDto[];
};

export type UseEngagementActionTicketCreate = {
  createEngagementActionTicket: (createPayload: EngagementActionTicketCreatePayload) => void;
  isCreatingTicket: boolean;
};

const useEngagementActionTicketCreate = (options?: UseEngagementActionListMutationOptions): UseEngagementActionTicketCreate => {
  const [createEngagementActionTicket, isCreatingTicket] = useEngagementActionListMutation(
    ({ actionId, fieldValues }: EngagementActionTicketCreatePayload) =>
      organizationEngagementActionTicketCreate({
        createPayload: { actionId, fieldValues },
      }),
    options,
  );

  return {
    createEngagementActionTicket,
    isCreatingTicket,
  };
};

export default useEngagementActionTicketCreate;
