import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import useDisposalTreeImpactAccountSummaryContext from '~/wm/packages/disposal/packages/tree-impact/context/hooks/useDisposalTreeImpactAccountSummaryContext';
import TreeImpactOrderPotentialCard from '~/wm/packages/tree-impact/packages/tree-impact-order-potential-card/TreeImpactOrderPotentialCard';
import useDisposalFeatureFlagContext from '~/wm/packages/disposal/context/hooks/useDisposalFeatureFlagContext';

export type DisposalTreeImpactOrderPotentialCardProps = {
  totalOrderUnits: number;
  helpGuideUrl: string;
} & Styleable;

const DisposalTreeImpactOrderPotentialCard: React.FunctionComponent<DisposalTreeImpactOrderPotentialCardProps> = ({
  totalOrderUnits,
  helpGuideUrl,
  className,
}) => {
  const { treesPerOrderUnit, totalTreesPlanted } = useDisposalTreeImpactAccountSummaryContext();
  const { enableImproveProfit } = useDisposalFeatureFlagContext();
  // Duplicated in C# 7B275DE4-0401-4F76-9673-55ECB7FF3493.
  const treesLimit = enableImproveProfit ? 10 : undefined;

  return (
    <TreeImpactOrderPotentialCard
      className={className}
      serviceId={'disposal'}
      treesPerOrderUnit={treesPerOrderUnit}
      totalOrderUnits={totalOrderUnits}
      totalTreesPlanted={totalTreesPlanted}
      helpGuideUrl={helpGuideUrl}
      treesLimit={treesLimit}
    />
  );
};

export default DisposalTreeImpactOrderPotentialCard;
