import { v4 as uuidv4 } from 'uuid';

export type PolicyValueType = string | number | boolean;
export type InsightPolicyFormData = {
  key: string;
  policyEnum: string | undefined;
  policyOperatorEnum: string | undefined;
  value: PolicyValueType;
};

export type InsightFormData = {
  title: string;
  description: string;
  assetScope: string;
  policies: InsightPolicyFormData[];
};

export const generateDefaultPolicyFormData = () => ({
  key: uuidv4(),
  policyEnum: '',
  policyOperatorEnum: '',
  value: '',
});

export const defaultFormData: InsightFormData = {
  assetScope: '',
  description: '',
  policies: [generateDefaultPolicyFormData()],
  title: '',
};
