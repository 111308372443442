import * as Yup from 'yup';
import { AutoEmailReportEmailTemplateEditFormData } from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/auto-email-report-email-template-edit-form/AutoEmailReportEmailTemplateEditForm';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const autoEmailReportEmailTemplateEditFormValidationSchema: Yup.ObjectSchema<any> =
  Yup.object().shape<AutoEmailReportEmailTemplateEditFormData>({
    emailBody: Yup.string().required('Required.'),
    emailSubject: Yup.string().required('Required.'),
    replyToUserId: Yup.string().required('Required.'),
  });

export default autoEmailReportEmailTemplateEditFormValidationSchema;
