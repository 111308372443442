import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { InitiativeSaveFormData } from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import React, { useState } from 'react';
import { FrequencyEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Recurring/Model/RecurringLineItemNested.gen';
import { CostTypeEnum } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Model/BudgetLineItemNested.gen';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { FieldArray } from 'formik';
import { FieldKeyExpressionSegment, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import FeesRecurringRow from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-fees-section/packages/fees-recurring-row/FeesRecurringRow';
import InteractiveItem from '~/neo-ui/packages/interactive-item/InteractiveItem';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';
import useTotalCostRecurringFeesCalculated from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/hooks/useTotalCostRecurringFeesCalculated';

const InitiativeSaveFeesRecurring = () => {
  const { formData } = useFormContext<InitiativeSaveFormData>();
  const { currencyInfo } = useRegionalSettingsInfoContext();

  const { formattedTotalCostRecurringFeesByFrequency } = useTotalCostRecurringFeesCalculated({
    currencyInfo,
    amountOfAssets: formData.assetIds.length,
    recurringLineItems: formData.recurringLineItems,
  });

  /**
   * Determine if the inputs may be focused
   * This is used to focus the last input when a new line item is added or removed
   */
  const [allowFocus, setAllowFocus] = useState(false);

  return (
    <div>
      <div
        css={css`
          margin-bottom: 1rem;
        `}
      >
        <Header
          size={4}
          weight={'bold'}
          css={css`
            margin-bottom: 0.3125rem;
          `}
        >
          Recurring fees
        </Header>
        <Label
          size={'md'}
          muted={true}
        >
          New ongoing costs incurred as part of this Initiative that aren’t part of the client’s one-time investment.
        </Label>
      </div>
      <FieldArray
        name={resolveFieldKey((values: FieldKeyExpressionSegment<InitiativeSaveFormData>) => values.recurringLineItems)}
        render={arrayHelpers => (
          <div>
            {formData.recurringLineItems.map((lineItem, lineItemIndex) => (
              <FeesRecurringRow
                key={lineItemIndex}
                lineItem={lineItem}
                lineItemIndex={lineItemIndex}
                onRemove={() => {
                  arrayHelpers.remove(lineItemIndex);
                  setAllowFocus(false);
                }}
                currencyIcon={currencyInfo.currencySymbol}
                allowFocus={lineItemIndex === formData.recurringLineItems.length - 1 ? allowFocus : false}
              />
            ))}
            <InteractiveItem
              modifierButtonProps={{ icon: 'Add', theme: 'primary' }}
              onClick={() => {
                // Budget schema reference 620dde08-da64-4f37-982d-ca70c3478399
                arrayHelpers.push({
                  label: 'New item',
                  costUnits: 0,
                  costType: CostTypeEnum.Fixed,
                  frequency: FrequencyEnum.Monthly,
                });
                setAllowFocus(true);
              }}
              css={css`
                margin: 0.625rem 0;
              `}
            >
              <Label
                color={'primary-400'}
                heightSize={'md'}
              >
                Add recurring fee…
              </Label>
            </InteractiveItem>
          </div>
        )}
      />
      <div
        css={css`
          margin-top: 1rem;
        `}
      >
        <Header
          size={5}
          weight={'bold'}
          css={css`
            margin-bottom: 0.625rem;
          `}
        >
          Total new recurring fees
        </Header>
        <div
          css={css`
            display: flex;
            gap: 0.625rem;
          `}
        >
          <div>
            <Header
              size={4}
              weight={'bold'}
            >
              {formattedTotalCostRecurringFeesByFrequency.Monthly}
            </Header>
            <Label
              muted={true}
              size={'sm'}
              css={css`
                margin-top: 0.3125rem;
              `}
            >
              Per month
            </Label>
          </div>
          <div>
            <Header
              size={4}
              weight={'bold'}
            >
              {formattedTotalCostRecurringFeesByFrequency.Yearly}
            </Header>
            <Label
              muted={true}
              size={'sm'}
              css={css`
                margin-top: 0.3125rem;
              `}
            >
              Per year
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitiativeSaveFeesRecurring;
