import FiscalQuarter, { Quarter } from '~/extensions/packages/date/packages/fiscal-quarter/FiscalQuarter';

const getNextFiscalQuarter = (fiscalQuarter: FiscalQuarter): FiscalQuarter =>
  fiscalQuarter.quarter === 4
    ? { year: fiscalQuarter.year + 1, quarter: 1 }
    : {
        year: fiscalQuarter.year,
        quarter: (fiscalQuarter.quarter + 1) as Quarter,
      };

export default getNextFiscalQuarter;
