import Box from '~/neo-ui/packages/box/Box';
import ServiceRequest from '~/wm/packages/warranty/packages/service-request/model/ServiceRequest';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';

export type OriginalSectionProps = {
  serviceRequest: ServiceRequest;
  currentStepIndex: number;
};

const OriginalSection = ({ serviceRequest, currentStepIndex }: OriginalSectionProps) => (
  <Box
    padding={'padding200'}
    boxShadow={'shadow0'}
    borderRadius={'radius400'}
    css={css`
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
    `}
  >
    <Header
      size={4}
      weight={'bold'}
      css={css`
        margin-bottom: 0.3125rem;
      `}
    >
      {currentStepIndex === 0 ? "This asset's warranty will be transferred" : 'Package this asset for Disposal'}
    </Header>
    <Header
      size={3}
      weight={'medium'}
    >
      {serviceRequest.asset.assetName}
    </Header>
    <Label size={'lg'}>{serviceRequest.asset.modelNumber}</Label>
    <Label
      size={'sm'}
      bold={true}
      css={css`
        display: flex;
        align-items: center;
        gap: 0.3125rem;
        text-transform: uppercase;
      `}
    >
      <Icon
        icon={'Serial'}
        sizePx={12}
      />
      {serviceRequest.asset.serialNumber}
    </Label>
  </Box>
);

export default OriginalSection;
