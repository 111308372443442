import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import useDisposalTreeImpactAccountSummaryContext from '~/wm/packages/disposal/packages/tree-impact/context/hooks/useDisposalTreeImpactAccountSummaryContext';
import TreeImpactOrderSuccessSummaryCard from '~/wm/packages/tree-impact/packages/tree-impact-order-success-summary-card/TreeImpactOrderSuccessSummaryCard';

export type DisposalTreeImpactOrderSuccessSummaryCardProps = {
  totalOrderUnits: number;
  helpGuideUrl: string;
} & Styleable;

const DisposalTreeImpactOrderSuccessSummaryCard: React.FunctionComponent<DisposalTreeImpactOrderSuccessSummaryCardProps> = ({
  totalOrderUnits,
  helpGuideUrl,
  className,
}) => {
  const { treesPerOrderUnit, totalOrdersUnits } = useDisposalTreeImpactAccountSummaryContext();

  return (
    <TreeImpactOrderSuccessSummaryCard
      className={className}
      treesPerOrderUnit={treesPerOrderUnit}
      totalOrderUnits={totalOrderUnits}
      totalTreesPlanted={totalOrdersUnits + totalOrderUnits}
      serviceId={'disposal'}
      helpGuideUrl={helpGuideUrl}
    />
  );
};

export default DisposalTreeImpactOrderSuccessSummaryCard;
