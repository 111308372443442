import { useContext } from 'react';
import TicketFieldAvailabilitiesContext from '~/wm/packages/integration/packages/ticket/context/TicketFieldAvailabilitiesContext';

const useTicketFieldAvailabilitiesContext = () => {
  const ticketFieldAvailabilitiesContext = useContext(TicketFieldAvailabilitiesContext);
  if (!ticketFieldAvailabilitiesContext) {
    throw new Error('Tried to use TicketFieldAvailabilitiesContext outside of an TicketFieldAvailabilitiesProvider');
  }

  return ticketFieldAvailabilitiesContext;
};

export default useTicketFieldAvailabilitiesContext;
