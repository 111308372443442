import { settingsEnable, settingsGet, settingsResetToDefault, settingsSave } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { SettingsKey, SettingsValue } from '@AssetManagementClient/BeastClient/Beast/Settings/Model.gen';
import equal from 'fast-deep-equal';
import { useState, useCallback, useEffect } from 'react';
import mapBackendFieldKey from '~/neo-ui/packages/form/packages/form-action/packages/form-validation/mapBackendFieldKey';
import useApi from '~/wm/packages/api/hook/useApi';
import { ApiServerErrorResponseDto } from '~/wm/packages/api/packages/api-error/model/ApiServerErrorResponseDto';

export type SettingsApi = {
  settingsValue: SettingsValue | undefined;
  isUsingDefault: boolean | undefined;
  customizeSettings: () => Promise<void>;
  resetToDefault: () => Promise<void>;
  isToggling: boolean;
  saveSettings: (value: SettingsValue) => Promise<void>;
  validationErrorMapper: (apiServerField: string) => string;
};

const useSettingsApi = (settingsKey: SettingsKey): SettingsApi => {
  const { callApi } = useApi();

  const [settingsValue, setSettingsValue] = useState<SettingsValue>();
  const [isUsingDefault, setUsingDefault] = useState<boolean>();
  const [isToggling, setToggling] = useState<boolean>(false);

  const getSettings = useCallback(async () => {
    const response = await callApi(() =>
      settingsGet({
        settingsKey,
      }),
    );
    if (!response) {
      return;
    }
    setSettingsValue(response.settings.value);
    setUsingDefault(!equal(response.settings.settingsKey, settingsKey));
  }, [callApi, settingsKey]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const customizeSettings = async () => {
    setToggling(true);

    const response = await callApi(() =>
      settingsEnable({
        settingsKey,
      }),
    );

    if (!response) {
      return;
    }

    setToggling(false);
    setSettingsValue(response.settings.value);
    setUsingDefault(false);
  };

  const resetToDefault = async () => {
    setToggling(true);

    const response = await callApi(() =>
      settingsResetToDefault({
        settingsKey,
      }),
    );

    if (!response) {
      return;
    }

    setToggling(false);
    setSettingsValue(response.fallbackSettings.value);
    setUsingDefault(true);
  };

  const saveSettings = async (value: SettingsValue) => {
    await callApi(
      () =>
        settingsSave({
          settingsKey,
          settingsValue: value,
        }),
      handleValidationErrors,
    );
  };

  const handleValidationErrors = (fieldErrors: ApiServerErrorResponseDto) => {
    throw fieldErrors;
  };

  const validationErrorMapper = (apiServerField: string): string => mapBackendFieldKey(apiServerField).split('.').slice(1).join('.');

  return {
    settingsValue,
    isUsingDefault,
    customizeSettings,
    resetToDefault,
    isToggling,
    saveSettings,
    validationErrorMapper,
  };
};

export default useSettingsApi;
