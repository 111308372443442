import * as React from 'react';
import { components, GroupHeadingProps } from 'react-select';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { css } from '@emotion/react';

const SingleSelectGroupHeading = <T extends string>(props: GroupHeadingProps<SelectOption<T>, false>) => {
  const { GroupHeading } = components;

  return (
    <GroupHeading
      {...props}
      css={css`
        ${props.data.label !== '' && 'margin-bottom: 1rem !important;'}
        font-size: 0.6875rem;
        font-weight: bold;
        letter-spacing: 0.0875rem;
        line-height: 0.8125rem;
      `}
    />
  );
};

export default SingleSelectGroupHeading;
