import useApi from '~/wm/packages/api/hook/useApi';
import { useEffect, useState } from 'react';
import { subscriptionUpgradeAddonSelectionGet } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { AddonSelection } from '@SubscriptionClient/BeastClient/Beast/Msp/Billing/SelfUpgrade/Dto.gen';

const useAddonSelectionGet = (subscriptionPlanId: string, quantity: number) => {
  const { callApi } = useApi();
  const [addonSelection, setAddonSelection] = useState<AddonSelection>();

  useEffect(() => {
    (async () => {
      const response = await callApi(() => subscriptionUpgradeAddonSelectionGet({ subscriptionPlanId, quantity }));

      if (!response) {
        return;
      }
      setAddonSelection(response.addonSelection);
    })();
  }, [callApi, quantity, subscriptionPlanId]);

  return { addonSelection };
};

export default useAddonSelectionGet;
