import { useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { coverageTransfer } from '@WarrantyClient/WarrantyClientMsp.gen';
import { Request, Response } from '@WarrantyClient/Warranty/Coverage/Packages/Transfer/Controller/CoverageTransferControllerNested.gen';

export type UseCoverageTransfer = () => {
  coverageTransfer: (coverageTransferPayload: Request) => Promise<Response | undefined>;
};

const useCoverageTransfer: UseCoverageTransfer = () => {
  const { callApi } = useApi();

  const transfer = useCallback(
    async (coverageTransferPayload: Request) => {
      const response = await callApi(() => coverageTransfer(coverageTransferPayload));

      if (!response) {
        return;
      }

      return response;
    },
    [callApi],
  );

  return {
    coverageTransfer: transfer,
  };
};

export default useCoverageTransfer;
