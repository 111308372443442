import Switch from 'react-switch';
import { neoColorToCode } from '~/neo-ui/packages/color/NeoColor';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';

export type ToggleSwitchProps = {
  onChange: (checked: boolean) => void;
  checked: boolean;
  showLabel?: boolean;
  disabled?: boolean;
} & Themeable &
  Styleable;

const ToggleSwitch = ({ onChange, checked, showLabel = false, disabled = false, theme = 'positive', className }: ToggleSwitchProps) => (
  <div
    className={className}
    css={css`
      display: flex;
      align-items: center;
      gap: 0.5rem;
    `}
  >
    <Switch
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      onColor={neoColorToCode(theme)}
      handleDiameter={22}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow={`0 0.0625rem 0.3125rem ${colorToCode('dark-900-64')}`}
      activeBoxShadow={`0 0 0.0625rem 0.1875rem ${colorToCode('dark-900-24')}`}
      height={22}
      width={44}
      className={`react-switch`}
      id={'react-switch'}
      // npm package uses funky css, so updating the cursor must use `!important`
      css={css`* {${disabled ? 'cursor: not-allowed !important;' : ''}`}
    />
    {showLabel && (
      <Label
        muted={!checked}
        bold={true}
        size={'lg'}
      >
        {checked ? 'On' : 'Off'}
      </Label>
    )}
  </div>
);

export default ToggleSwitch;
