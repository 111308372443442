import { createContext } from 'react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';

export type WizardContainerContextValue = {
  setFieldCompleted: <TFormData>(fieldKey: FieldKeyExpression<TFormData>, value: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const WizardContainerContext = createContext<WizardContainerContextValue | undefined>(undefined);

export default WizardContainerContext;
