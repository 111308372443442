import { PriorityDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Priority/Dto/Model.gen';
import React from 'react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import SelectGroup, { ButtonSelectOption } from '~/neo-ui/packages/select/packages/select-group/SelectGroup';
import Theme from '~/neo-ui/packages/color/Theme';

export type PrioritySelectProps<T extends string = string> = {
  priorityAvailabilities: PriorityDto[];
  currentPriority: PriorityDto;
  onSelectPriority: (option: ButtonSelectOption<T>) => void;
};

const PrioritySelect = <T extends string = string>({
  priorityAvailabilities,
  currentPriority,
  onSelectPriority,
}: PrioritySelectProps<T>) => {
  const priorityAvailabilityOptions: ButtonSelectOption<T>[] = priorityAvailabilities.map(
    option =>
      ({
        icon: option.icon as IconType,
        value: option.key,
        selectedTheme: option.theme as Theme,
      } as ButtonSelectOption<T>),
  );

  const priorityOption = priorityAvailabilityOptions.find(option => option.value === currentPriority.key);

  const [selectedPriority, setSelectedPriority] = React.useState(priorityOption);

  if (priorityAvailabilityOptions.length <= 0) {
    return <React.Fragment />;
  }
  return (
    <SelectGroup
      options={priorityAvailabilityOptions}
      selectedOptionValue={selectedPriority!.value}
      onOptionSelected={selectedPriority => {
        setSelectedPriority(selectedPriority);
        onSelectPriority(selectedPriority);
      }}
    />
  );
};

export default PrioritySelect;
