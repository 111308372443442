import AutoEmailReportCreateButton from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-create/packages/auto-email-report-create-button/AutoEmailReportCreateButton';
import AutoEmailReportListProvider from '~/wm/packages/organization/packages/auto-email-report/context/AutoEmailReportListProvider';
import OrganizationProvider from '~/wm/packages/organization/context/OrganizationProvider';
import AutoEmailReportSection from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-section/AutoEmailReportSection';
import AutoEmailReportUrlProvider from '~/wm/packages/organization/packages/auto-email-report/context/AutoEmailReportUrlProvider';
import * as React from 'react';
import { css } from '@emotion/react';
import LayoutFooter from '~/neo-ui/packages/layout/packages/footer/LayoutFooter';

export type AutoEmailReportPageProps = {
  organizationId: string;
  organizationName: string;
  autoEmailReportEmailTemplateEditUrl: string;
  isFreeEdition: boolean;
};

const AutoEmailReportPage = ({
  organizationId,
  organizationName,
  autoEmailReportEmailTemplateEditUrl,
  isFreeEdition,
}: AutoEmailReportPageProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  return isFreeEdition ? null : (
    <OrganizationProvider
      organizationId={organizationId}
      organizationName={organizationName}
    >
      <AutoEmailReportUrlProvider autoEmailReportEmailTemplateEditUrl={autoEmailReportEmailTemplateEditUrl}>
        <AutoEmailReportListProvider>
          <div
            ref={ref}
            css={css`
              scroll-margin-top: 10rem;
            `}
          >
            <AutoEmailReportSection />
          </div>
          <LayoutFooter
            leftControls={[
              {
                expanded: <AutoEmailReportCreateButton scrollToRef={ref} />,
              },
            ]}
          />
        </AutoEmailReportListProvider>
      </AutoEmailReportUrlProvider>
    </OrganizationProvider>
  );
};

export default AutoEmailReportPage;
