import { AddressBusinessDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Dto/Model.gen';
import { AddressBusiness } from '@SubscriptionClient/Common/Location/Model/Address.gen';

const buildDisposalAddressValidatePayloadDto = (billingAddress: AddressBusiness): AddressBusinessDto => ({
  address: {
    city: billingAddress?.address?.city ?? '',
    countryCode: billingAddress?.address?.countryCode ?? '',
    countryFallback: billingAddress?.address?.countryFallback ?? '',
    line1: billingAddress?.address?.line1 ?? '',
    line2: billingAddress?.address?.line2 ?? '',
    zip: billingAddress?.address?.zip ?? '',
    stateCode: billingAddress?.address?.stateCode ?? '',
    stateFallback: billingAddress?.address?.stateFallback ?? '',
  },
  name: billingAddress?.name ?? '',
  phone: billingAddress?.phone ?? '',
});

export default buildDisposalAddressValidatePayloadDto;
