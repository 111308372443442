import { EstimateCartStoreGetFilterDto as WarrantyClientBeastClientBeastEstimateDtoCartPaginatedGetEstimateCartStoreGetFilterDto } from '@WarrantyClient/BeastClient/Beast/Estimate/Dto/Cart/PaginatedGet.gen';
import WarrantyCartFilterFormData from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-filters/packages/warranty-cart-filter-form/WarrantyCartFilterFormData';

export const warrantyCartFilterBuilder = (
  formFilter: WarrantyCartFilterFormData,
  accountIds: string[],
): WarrantyClientBeastClientBeastEstimateDtoCartPaginatedGetEstimateCartStoreGetFilterDto => {
  return {
    outOfWarrantyAssets: formFilter.outOfWarrantyAssets,
    selectedAssetAccountIds: formFilter.selectedItems ? accountIds : [],
    takeAssetsBetween: formFilter.assetsBetweenYears,
    takeAssetsBetweenFrom: formFilter.assetsBetweenYearsFrom,
    takeAssetsBetweenTo: formFilter.assetsBetweenYearsTo,
  };
};

export default warrantyCartFilterBuilder;
