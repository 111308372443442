import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import React from 'react';
import { BudgetQuarterDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Packages/BudgetQuarter/Dto/Model.gen';
import { InitiativeOverviewDashboardDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import InitiativeDashboardOverview from '~/wm/packages/strategy/packages/strategy-dashboard/packages/initiative-dashboard/packages/initiative-dashboard-overview/InitiativeDashboardOverview';
import Color from '~/neo-ui/packages/color/Color.gen';
import Box from '~/neo-ui/packages/box/Box';

export type BudgetQuarterContainerProps = {
  budgetQuarter: BudgetQuarterDto | undefined;
  initiatives: InitiativeOverviewDashboardDto[];
  enabledInitiativeTickets: boolean;
};

export const fiscalQuarterToColor = (quarter?: number): Color => {
  switch (quarter) {
    case 1:
      return 'info-700';
    case 2:
      return 'primary-700';
    case 3:
      return 'excellent-400';
    case 4:
      return 'secondary-400';
    default:
      return 'dark-900';
  }
};

const BudgetQuarterContainer: React.FunctionComponent<BudgetQuarterContainerProps> = ({
  budgetQuarter,
  initiatives,
  enabledInitiativeTickets,
}) => (
  <Box
    theme={'none'}
    background={'foregroundAccent'}
    padding={'padding200'}
    borderRadius={'radius400'}
  >
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 1rem;
        height: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          flex-direction: column;
        `}
      >
        {typeof budgetQuarter !== 'undefined' ? (
          <React.Fragment>
            <Header
              size={3}
              color={fiscalQuarterToColor(budgetQuarter.quarter)}
              weight={'bold'}
            >
              Q{budgetQuarter.quarter}
            </Header>
            <Label
              size={'lg'}
              color={fiscalQuarterToColor(budgetQuarter.quarter)}
            >
              {budgetQuarter.year}
            </Label>
          </React.Fragment>
        ) : (
          <Header
            size={3}
            color={'light-700'}
            weight={'bold'}
          >
            N/A
          </Header>
        )}
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
          width: 100%;
        `}
      >
        {initiatives.map(initiative => (
          <InitiativeDashboardOverview
            key={initiative.initiativeId}
            initiative={initiative}
            enabledInitiativeTickets={enabledInitiativeTickets}
          />
        ))}
      </div>
    </div>
  </Box>
);

export default BudgetQuarterContainer;
