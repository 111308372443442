import { css } from '@emotion/react';
import { SubscriptionBillingPeriodEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription.gen';
import * as React from 'react';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';
import getBillingPeriodDisplay from '~/wm/packages/subscription/packages/billing/operation/getBillingPeriodDisplay';
import Testable from '~/neo-ui/packages/testable/Testable';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

const SubscriptionConfirmation: React.FunctionComponent<{
  isConfirmed: boolean;
  toggleConfirmation: () => void;
  finalPriceBeforeTaxesCents?: number;
  billingPeriodEnum?: SubscriptionBillingPeriodEnum;
  hasDiscount?: boolean;
}> = ({ isConfirmed, toggleConfirmation, finalPriceBeforeTaxesCents, billingPeriodEnum, hasDiscount }) => {
  const finalPriceFormatted = typeof finalPriceBeforeTaxesCents !== 'undefined' ? formatCurrency(finalPriceBeforeTaxesCents, 'USD') : '-';

  if (!finalPriceBeforeTaxesCents) {
    return (
      <Testable testId={'subscription-confirmation-checkbox'}>
        <InputCheckbox
          theme={'secondary'}
          description={
            'I agree that from the date my subscription term applies, my credit card will be charged according to the ScalePad Subscription Agreement.'
          }
          checked={isConfirmed}
          onClick={toggleConfirmation}
        />
      </Testable>
    );
  }

  return (
    <div>
      <p
        css={css`
          margin-bottom: 0.625rem;
          font-weight: bold;
        `}
      >
        <span
          css={css`
            text-transform: capitalize;
          `}
        >
          {billingPeriodEnum ? getBillingPeriodDisplay(billingPeriodEnum).adjective : null}
        </span>{' '}
        price
      </p>
      <p
        css={css`
          font-weight: bold;
          font-size: 2.125rem;
          line-height: 1;
          margin-bottom: 0.3125rem;
        `}
      >
        USD {finalPriceFormatted}
      </p>
      <p
        css={css`
          color: ${colorToCode('light-000-80')};
        `}
      >
        {hasDiscount ? 'Including your discount, before applicable tax.' : 'Before applicable tax.'}
      </p>
      <Testable testId={'subscription-confirmation-checkbox'}>
        <InputCheckbox
          theme={'secondary'}
          description={
            'I agree that from the date my subscription term applies, my credit card will be charged according to the ScalePad Subscription Agreement.'
          }
          checked={isConfirmed}
          onClick={toggleConfirmation}
        />
      </Testable>
    </div>
  );
};

export default SubscriptionConfirmation;
