import React from 'react';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { Styleable } from '~/neo-ui/model/capacity';
import { css } from '@emotion/react';
import { useProgressBar } from '@react-aria/progress';

export type SpinnerProps = {
  size?: 'md' | 'sm';
  color?: Color;
} & Styleable;

const Spinner: React.FunctionComponent<SpinnerProps> = ({ size = 'md', color = 'dark-400', className }) => {
  const { progressBarProps } = useProgressBar({
    isIndeterminate: true,
    'aria-label': 'loading-spinner',
  });

  const center = size === 'md' ? 16 : 8;
  const strokeWidth = 3;
  const r = center - strokeWidth;
  const c = 2 * r * Math.PI;
  const percentage = 0.75;
  const offset = c - percentage * c;
  const d = center * 2;

  return (
    <div
      css={css`
        height: 100%;
      `}
    >
      <svg
        {...progressBarProps}
        width={d}
        height={d}
        viewBox={`0 0 ${d} ${d}`}
        fill="none"
        strokeWidth={strokeWidth}
        className={className}
      >
        <circle
          role="presentation"
          cx={center}
          cy={center}
          r={r}
          stroke="transparent"
        />
        <circle
          role="presentation"
          cx={center}
          cy={center}
          r={r}
          stroke={colorToCode(color)}
          strokeDasharray={`${c} ${c}`}
          strokeDashoffset={offset}
          transform={`rotate(-90 ${r} ${r})`}
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            begin="0s"
            dur="1s"
            from={`0 ${center} ${center}`}
            to={`360 ${center} ${center}`}
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </div>
  );
};

export default Spinner;
