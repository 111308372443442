import { useContext } from 'react';
import RegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/RegionalSettingsInfoContext';

const useRegionalSettingsInfoContext = () => {
  const regionalSettingsInfoContext = useContext(RegionalSettingsInfoContext);
  if (!regionalSettingsInfoContext) {
    throw new Error('Tried to use useRegionalSettingsInfoContext outside of an RegionalSettingsInfoContextProvider');
  }

  return regionalSettingsInfoContext;
};

export default useRegionalSettingsInfoContext;
