import { createContext } from 'react';

export type DisposalFeatureFlagContextData = {
  enableImproveProfit: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const DisposalFeatureFlagContext = createContext<DisposalFeatureFlagContextData | undefined>(undefined);

export default DisposalFeatureFlagContext;
