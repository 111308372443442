import * as React from 'react';
import Theme from '~/neo-ui/packages/color/Theme';
import ModalInternal from '~/neo-ui/packages/modal/ModalInternal';
import ModalConfirmationFooter, {
  ModalConfirmationFooterProps,
} from '~/neo-ui/packages/modal/packages/modal-confirmation/packages/modal-confirmation-footer/ModalConfirmationFooter';
import ModalConfirmationHeader, {
  ModalConfirmationHeaderProps,
} from '~/neo-ui/packages/modal/packages/modal-confirmation/packages/modal-confirmation-header/ModalConfirmationHeader';

export type ModalConfirmationProps = {
  header: Omit<ModalConfirmationHeaderProps, 'theme'>;
  footer?: Omit<ModalConfirmationFooterProps, 'theme'>;
  theme: Theme;
  isOpen: boolean;
};
/**
 * ModalConfirmation is a pop-out component that allows the user to confirm an action that may be destructive
 * or require special attention
 *
 * A Modal should be small height and provide Cancel/Proceed type buttons in the footer.
 * If scrolling is required the entire modal * will scroll on the viewport
 */
const ModalConfirmation: React.FunctionComponent<React.PropsWithChildren<ModalConfirmationProps>> = ({
  header,
  children,
  footer,
  theme,
  isOpen,
}) => (
  <ModalInternal
    header={
      <ModalConfirmationHeader
        icon={header.icon}
        title={header.title}
        theme={theme}
      />
    }
    footer={
      typeof footer === 'undefined' ? undefined : (
        <ModalConfirmationFooter
          confirmationButtonAlignment={footer.confirmationButtonAlignment}
          dismissButton={footer.dismissButton}
          confirmButton={footer.confirmButton}
          theme={theme}
        />
      )
    }
    isOpen={isOpen}
    onDismiss={footer?.dismissButton.onClick}
  >
    {children}
  </ModalInternal>
);

export default ModalConfirmation;
