import RootState from '~/wm/state/RootState';
import LockState from '../model/LockState';

const selectLockState = ({ ui: { lock } }: RootState): LockState => lock;
export const selectLockedEntities =
  (bucket: string) =>
  (state: RootState): Set<string> =>
    selectLockState(state).lockedEntities.get(bucket) ?? new Set();

export default selectLockState;
