import { createContext } from 'react';

export type DisposalOrderAssetSelectContextData = {
  disposalOrderAssetIds: Set<string>;
  updateSelectedDisposalOrderAssetIds: (disposalOrderAssetIds: Set<string>) => void;
  clearDisposalOrderAssetIds: () => void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const DisposalOrderAssetSelectContext = createContext<DisposalOrderAssetSelectContextData | undefined>(undefined);

export default DisposalOrderAssetSelectContext;
