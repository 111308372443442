import Box from '~/neo-ui/packages/box/Box';
import * as React from 'react';
import { css } from '@emotion/react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Testable from '~/neo-ui/packages/testable/Testable';
import OrderSummaryAssetModule from '~/wm/packages/disposal/wizard/section/packages/module/order-summary-asset-module/OrderSummaryAssetModule';
import OrderSummaryReturnAddressModule from '~/wm/packages/disposal/wizard/section/packages/module/order-summary-return-address-module/OrderSummaryReturnAddressModule';
import DisposalBulkCheckoutFormData from '~/wm/packages/disposal/types/DisposalBulkCheckoutFormData';
import OrderSummaryCostModule from '~/wm/packages/disposal/wizard/section/packages/module/order-summary-cost-module/OrderSummaryCostModule';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import { DisposalOrderPickupMaterialTypeDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderPickup/Packages/DisposalOrderPickupMaterial/Dto/Model.gen';
import { DisposalDynamicPricingDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Dto/Model.gen';
import OrderSummaryMaterialsModule from './packages/module/order-summary-materials-module/OrderSummaryMaterialsModule';

export type BulkOrderSummaryWithBillingSectionProps = {
  disposalTermsOfServiceUrl: string;
  termsOfServiceUrl: string;
  brandName: string;
  assetTypeCountsFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData>;
  materialsFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData>;
  shipmentReturnAddressFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData>;
  couponFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData>;
  disposalAssetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[];
  disposalOrderPickupMaterialTypeAvailabilities: DisposalOrderPickupMaterialTypeDto[];
  disposalDynamicPricing: DisposalDynamicPricingDto;
};

const BulkOrderSummaryWithBillingSection = ({
  disposalTermsOfServiceUrl,
  termsOfServiceUrl,
  brandName,
  assetTypeCountsFieldKey,
  materialsFieldKey,
  shipmentReturnAddressFieldKey,
  couponFieldKey,
  disposalAssetTypeAvailabilities,
  disposalOrderPickupMaterialTypeAvailabilities,
  disposalDynamicPricing,
}: BulkOrderSummaryWithBillingSectionProps) => (
  <Testable testId={'disposal-order-summary'}>
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
    >
      <Header
        size={4}
        weight={'bold'}
        css={css`
          margin-bottom: 1.5rem;
        `}
      >
        Order summary
      </Header>
      <OrderSummaryAssetModule
        header={"We'll collect:"}
        assetTypeCountsFieldKey={assetTypeCountsFieldKey}
        disposalAssetTypeAvailabilities={disposalAssetTypeAvailabilities}
      />
      <hr
        css={css`
          margin-top: 0.90625rem;
          margin-bottom: 0.90625rem;
        `}
      />
      <OrderSummaryMaterialsModule
        header={"We'll bring:"}
        materialsFieldKey={materialsFieldKey}
        disposalOrderPickupMaterialTypeAvailabilities={disposalOrderPickupMaterialTypeAvailabilities}
      />
      <hr
        css={css`
          margin-top: 0.90625rem;
          margin-bottom: 0.90625rem;
        `}
      />
      <OrderSummaryReturnAddressModule
        shipmentReturnAddressFieldKey={shipmentReturnAddressFieldKey}
        header={"We'll pick everything up from:"}
        description={"After you’ve placed your order, you'll be able to set your preferred pickup dates and times."}
      />
      <hr
        css={css`
          margin-top: 0.90625rem;
          margin-bottom: 0.90625rem;
        `}
      />
      <OrderSummaryCostModule
        termsOfServiceUrl={termsOfServiceUrl}
        disposalTermsOfServiceUrl={disposalTermsOfServiceUrl}
        brandName={brandName}
        assetTypeCountsFieldKey={assetTypeCountsFieldKey}
        couponFieldKey={couponFieldKey}
        disposalDynamicPricing={disposalDynamicPricing}
      />
    </Box>
  </Testable>
);

export default BulkOrderSummaryWithBillingSection;
