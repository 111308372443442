import React from 'react';
import { css } from '@emotion/react';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import FormNumberInput from '~/neo-ui/packages/form/packages/form-input/packages/form-number-input/FormNumberInput';
import warrantyCartOpportunitiesFormFields from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-opportunity/packages/warranty-cart-opportunity-form/warrantyCartOpportunitiesFormFields';
import { calculatePercentage } from '~/wm/packages/warranty/packages/warranty-cart/calculators/warrantyCartCalculators';

const WarrantyCartOpportunityRevenue: React.FunctionComponent = () => {
  const { markupPercentage, totalCost, formatCostOutput } = useWarrantyCartContext();
  const totalWithMarkup = totalCost + calculatePercentage(totalCost, markupPercentage);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 0.4rem;
      `}
    >
      <span>Sell at</span>
      <span>
        <b>{formatCostOutput(totalWithMarkup)}</b>
      </span>
      <span>with your</span>
      <FormNumberInput
        css={css`
          padding-left: 0.75rem;
          padding-right: 0.675rem;
          width: 7rem;
        `}
        className={'form-control-num'}
        fieldKey={warrantyCartOpportunitiesFormFields.markup}
        min={1}
      />
      <span>% markup</span>
    </div>
  );
};

export default WarrantyCartOpportunityRevenue;
