import { useContext } from 'react';
import RoadmapFeatureFlagsContext from '~/wm/packages/strategy/packages/roadmap-page/context/RoadmapFeatureFlagsContext';

const useRoadmapFeatureFlagsContext = () => {
  const roadmapFeatureFlagsContext = useContext(RoadmapFeatureFlagsContext);
  if (!roadmapFeatureFlagsContext) {
    throw new Error('Tried to use RoadmapFeatureFlagsContext outside of an RoadmapFeatureFlagsProvider');
  }

  return roadmapFeatureFlagsContext;
};

export default useRoadmapFeatureFlagsContext;
