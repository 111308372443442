import { ActivityPriority } from '@BeastClient/Activity/Search/Model.gen';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color from '~/neo-ui/packages/color/Color.gen';

type PriorityDefinition = {
  iconName: IconType;
  color: Color;
};

export default (priority: ActivityPriority): PriorityDefinition => {
  switch (priority) {
    case ActivityPriority.None:
      return {
        iconName: 'Activity',
        color: 'dark-900',
      };
    case ActivityPriority.Notification:
      return {
        iconName: 'Notification',
        color: 'primary-400',
      };
    case ActivityPriority.Alert:
      return {
        iconName: 'Warning',
        color: 'warning-400',
      };
  }
};
