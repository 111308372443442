import { PageEnum, PageFilter } from '@AssetManagementClient/AssetManagement/Packages/Share/Packages/Send/Model.gen';
import { shareSend } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import Info from '~/legacy-ui/packages/info/Info';
import AlertModal from '~/legacy-ui/packages/modal/packages/alert-modal/AlertModal';
import ModalSuccess from '~/legacy-ui/packages/modal/packages/modal-success/ModalSuccess';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import Icon from '~/neo-ui/packages/icon/Icon';
import InputText from '~/neo-ui/packages/input/packages/input-text/InputText';
import Search from '~/neo-ui/packages/search/Search';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import useApi from '~/wm/packages/api/hook/useApi';
import useShareAvailableRecipients from '~/wm/packages/insight-share/hooks/useShareAvailableRecipients';
import InsightShareRecipient from '~/wm/packages/insight-share/model/InsightShareRecipient';
import { getPageDefinition } from '~/wm/packages/insight-share/packages/insight-share-button/InsightShareButton';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';

export type InsightShareModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  userEmail: string;
  page: PageEnum;
  frontendScope: FrontendScope;
  inviteMembersUrl: string;
  canInviteMembers: boolean;
  filters?: PageFilter[];
};

type ModalState = 'draft' | 'loading' | 'submitted';

const InsightShareModal: React.FunctionComponent<InsightShareModalProps> = ({
  isOpen,
  onDismiss,
  userEmail,
  page,
  frontendScope,
  inviteMembersUrl,
  canInviteMembers,
  filters,
}) => {
  const { callApi } = useApi();

  const { availableRecipients } = useShareAvailableRecipients();

  const [filteredRecipients, setFilteredRecipients] = React.useState<InsightShareRecipient[]>(availableRecipients);
  const [selectedRecipients, setSelectedRecipients] = React.useState<InsightShareRecipient[]>([]);

  const [message, setMessage] = React.useState('');

  const [state, setState] = React.useState<ModalState>('draft');

  const formDisabled = state !== 'draft' || selectedRecipients.length === 0;
  const isDismissible = state !== 'loading';

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (state === 'submitted') {
      timeout = setTimeout(onDismiss, 2000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [state, onDismiss]);

  const bouncingRecipientFirstNames = selectedRecipients.filter(recipient => recipient.isBouncing).map(recipient => recipient.firstName);

  const triggerSubmission = async () => {
    setState('loading');

    await callApi(async () => {
      const response = await callApi(() =>
        shareSend({
          recipientUserIds: selectedRecipients.map(recipient => recipient.userId),
          filters: filters ?? [],
          message,
          page,
          frontendScope,
        }),
      );
      if (!response) {
        setState('draft');
        return;
      }
      setState('submitted');
    });
  };

  const dismissModal = () => {
    if (isDismissible) {
      onDismiss();
    }
  };

  const resetModal = async () => {
    setState('draft');
    setMessage('');
    setSelectedRecipients([]);
  };

  return (
    <AlertModal
      title={state === 'submitted' ? undefined : 'Share'}
      theme={'secondary'}
      onConfirm={triggerSubmission}
      onDismiss={dismissModal}
      onClosed={resetModal}
      footerButtons={
        state === 'submitted'
          ? undefined
          : {
              confirm: {
                icon: 'Share',
                text: 'Share',
                disabled: formDisabled || bouncingRecipientFirstNames.length > 0,
                loading: state === 'loading',
              },
              dismiss: {
                icon: 'Cancel',
                text: 'Cancel',
                disabled: !isDismissible,
              },
            }
      }
      isOpen={isOpen}
    >
      {state === 'submitted' ? (
        <ModalSuccess
          description={`Your ${getPageDefinition(page).labelSentenceCase} search was shared with ${selectedRecipients.length} member${
            selectedRecipients.length === 1 ? '' : 's'
          }`}
        />
      ) : (
        <div>
          <div
            css={css`
              margin-bottom: 1.25rem;
            `}
          >
            <Header
              size={4}
              css={css`
                margin-bottom: 0.625rem;
              `}
            >
              Link
            </Header>
            <Card
              backgroundColor={'light-200'}
              padding={'sm'}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <Icon
                  icon={'Link'}
                  sizePx={22}
                  css={css`
                    margin-right: 0.8125rem;
                  `}
                />
                <Label bold={true}>{getPageDefinition(page).label} search</Label>
              </div>
            </Card>
          </div>
          <div
            css={css`
              margin-bottom: 1.25rem;
            `}
          >
            <Header
              size={4}
              css={css`
                margin-bottom: 0.3125rem;
              `}
            >
              Recipients
            </Header>
            <Label
              size={'sm'}
              muted={true}
              css={css`
                margin-bottom: 0.625rem;
                display: flex;
              `}
            >
              Replies will be sent to
              <Label
                size={'sm'}
                color={'secondary-400'}
                css={css`
                  margin-left: 0.125rem;
                `}
              >
                {userEmail}
              </Label>
            </Label>
            <Search
              itemKey={'fullName'}
              tabIndex={1}
              placeholder={'Add recipients...'}
              selection={{
                multiSelect: true,
                selected: selectedRecipients,
                onResultsSelected: setSelectedRecipients,
              }}
              renderSearchRow={recipient => (
                <Card
                  padding={'sm'}
                  backgroundColor={'light-200'}
                >
                  <Info
                    title={`${recipient.firstName} ${recipient.lastName}`}
                    titleSize={'sm'}
                    description={recipient.email}
                    descriptionMuted={true}
                  />
                </Card>
              )}
              options={filteredRecipients}
              onSearch={query => {
                setFilteredRecipients(
                  availableRecipients.filter(
                    recipient =>
                      recipient.email.toLowerCase().includes(query.toLowerCase()) ||
                      recipient.fullName.toLowerCase().includes(query.toLowerCase()),
                  ),
                );
              }}
              renderTokenLabel={option => option.fullName}
            />
            {bouncingRecipientFirstNames.length > 0 ? (
              <Label
                color={'negative-400'}
                css={css`
                  margin-top: 0.3125rem;
                `}
              >
                The email
                {bouncingRecipientFirstNames.length > 1 && 's'} for {bouncingRecipientFirstNames.map(name => name).join(', ')}{' '}
                {bouncingRecipientFirstNames.length === 1 ? "isn't" : "aren't"} valid. Please ask them to sign in and update their address.
              </Label>
            ) : (
              ''
            )}
            <Label
              size={'md'}
              muted={true}
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 0.3125rem;
              `}
            >
              {!canInviteMembers ? (
                <span>Don't see someone? You can ask an admin to invite them.</span>
              ) : (
                <>
                  <span>Don't see someone?</span>
                  <ButtonLink
                    size={'sm'}
                    anchor={{ href: inviteMembersUrl, openInNewTab: true }}
                    iconRight={'GoExternal'}
                  >
                    Invite members
                  </ButtonLink>
                </>
              )}
            </Label>
          </div>
          <div>
            <Header
              size={4}
              css={css`
                margin-bottom: 0.625rem;
              `}
            >
              Comment
            </Header>
            <InputText
              onTextChange={evt => {
                setMessage(evt.target.value);
              }}
              onClear={() => {
                setMessage('');
              }}
              value={message}
              placeholder={'Add a message'}
              prependIcon={'Tooltip'}
              tabIndex={2}
            />
          </div>
        </div>
      )}
    </AlertModal>
  );
};

export default InsightShareModal;
