import BannerExpandable from '~/neo-ui/packages/banner/packages/banner-expandable/BannerExpandable';
import { css } from '@emotion/react';
import Image from '~/neo-ui/packages/image/Image';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';

export type DisposalExpandableBannerProps = {
  dropOffLocationsUrl: string;
};

const DisposalExpandableBanner = ({ dropOffLocationsUrl }: DisposalExpandableBannerProps) => {
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  const stepTutorials = [
    {
      title: 'Get Started',
      description:
        'Identify assets ready for disposal. Discover how to generate revenue from the replacement of clients’ end-of-life assets.',
      graphic: '/i/graphic/disposal/disposal-workstation.png',
      callToAction: (
        <ButtonLink
          anchor={{
            href: '/assets/reports/disposal/IT-Asset-Disposal-Sell-Through-Kit.zip',
            download: 'IT-Asset-Disposal-Sell-Through-Kit.zip',
          }}
          onClick={() => trackEventProcessor('disposal_tutorial_banner_sell_through_kit_download', {})}
        >
          Download sell-through kit
        </ButtonLink>
      ),
    },
    {
      title: 'Get Ready',
      description: (
        <span>
          Box everything up – cables and all – and prepare it for departure.
          <br />
          Free shipping is included. ✅
        </span>
      ),
      graphic: '/i/graphic/disposal/disposal-box.png',
    },
    {
      title: "Get 'Em Shipped",
      description: (
        <span>
          Schedule a pickup, or drop off at any of over 30,000 USPS locations.
          <br />
          Your choice, your way.
        </span>
      ),
      graphic: '/i/graphic/disposal/delivery-truck.png',
      callToAction: (
        <ButtonLink
          anchor={{ href: dropOffLocationsUrl, openInNewTab: true }}
          onClick={() => trackEventProcessor('disposal_tutorial_banner_dropoff_locate', {})}
        >
          Find a nearby drop-off location
        </ButtonLink>
      ),
    },
    {
      title: 'Get Certified',
      description: 'Once the disposal is complete and the data destroyed, you’ll receive certificates to share with your client(s).',
      graphic: '/i/graphic/disposal/disposal-certified.png',
      callToAction: (
        <ButtonLink
          anchor={{
            href: '/assets/reports/disposal/Sample-Disposal-Certificate.pdf',
            download: 'Sample-Disposal-Certificate.pdf',
          }}
          onClick={() => trackEventProcessor('disposal_tutorial_banner_sample_disposal_certificate_download', {})}
        >
          Download sample certificate
        </ButtonLink>
      ),
    },
  ];

  return (
    <BannerExpandable
      title={'The easiest way to securely dispose of end-of-life assets'}
      description={'Close the loop on asset lifecycle management with no-hassle secure disposal. No more pulling drives!'}
      onExpanded={() => trackEventProcessor('disposal_tutorial_banner_expanded', {})}
      expandableContent={
        <div
          css={css`
            display: flex;
            gap: 0.25rem;
          `}
        >
          {stepTutorials.map((stepTutorial, index) => (
            <div
              key={index}
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.625rem;
                position: relative;
              `}
            >
              <div
                css={css`
                  position: absolute;
                  width: ${index === 0 ? '30%;' : index === stepTutorials.length - 1 ? '40%;' : '100%'};
                  ${index === 0 && 'margin-left: 70%;'}
                  ${index === stepTutorials.length - 1 && 'margin-right: 60%;'}
                  border: dashed 0.0625rem ${colorToCode('dark-700')};
                  margin-top: 2.25rem;
                `}
              />
              <Image
                source={stepTutorial.graphic}
                css={css`
                  height: 4.5rem;
                  z-index: 2;
                `}
              />
              <Label
                size={'lg'}
                bold={true}
                css={css`
                  text-align: center;
                `}
              >
                {stepTutorial.title}
              </Label>
              <Label
                css={css`
                  text-align: center;
                `}
              >
                {stepTutorial.description}
              </Label>
              {stepTutorial.callToAction}
            </div>
          ))}
        </div>
      }
    />
  );
};

export default DisposalExpandableBanner;
