import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import DisableElementOverlay from '~/neo-ui/packages/overlay/DisableElementOverlay';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type CardButtonProps = {
  title: string;
  description?: string;
  icon: IconType;
  onClick: () => void;
  disabled?: boolean;
  /**
   * Optional component that indicates the action this card performs
   */
  actionComponent?: React.ReactNode;
} & Themeable &
  Styleable;

const CardButton = ({ title, description, icon, onClick, disabled = false, actionComponent, theme, className }: CardButtonProps) => {
  const { themeMap } = useTheme(theme);
  return (
    <DisableElementOverlay disabled={disabled}>
      <Card
        padding={'sm'}
        shadow={'none'}
        onClick={onClick}
        css={css`
          background-color: ${colorToCode(themeMap.backgroundRaised)};
          color: ${colorToCode(theme ? themeMap.foregroundAccent : themeMap.foregroundContrast)};
        `}
        className={className}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.9375rem;
          `}
        >
          <Icon
            icon={icon}
            sizePx={22}
            color={theme ? themeMap.foregroundAccent : themeMap.foregroundContrast}
          />
          <div>
            <Header
              size={5}
              weight={'bold'}
            >
              {title}
            </Header>
            {description && (
              <Label
                css={css`
                  margin-top: 0.3125rem;
                `}
              >
                {description}
              </Label>
            )}
          </div>
          {actionComponent && actionComponent}
        </div>
      </Card>
    </DisableElementOverlay>
  );
};

export default CardButton;
