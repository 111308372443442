import * as React from 'react';
import Window from '~/neo-ui/packages/window/Window';
import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import Button from '~/neo-ui/packages/button/Button';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { formatDate, getDateFriendlyExtendedDefinition, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import { EngagementActionEditFormDataType } from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/types/EngagementActionEditFormDataType';
import useEngagementActionMarkCompleted from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionMarkCompleted';
import ActiveUsersSearch from '~/wm/packages/account/packages/user/packages/active-users-search/ActiveUsersSearch';
import useEngagementActionPin from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionPin';
import useEngagementActionUnpin from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionUnpin';
import FormTextareaInputInvisible from '~/neo-ui/packages/form/packages/form-input/packages/form-textarea-input/packages/form-textarea-input-invisible/FormTextareaInputInvisible';
import EngagementActionTicketCreateButton from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-ticket/packages/engagement-action-ticket-create-button/EngagementActionTicketCreateButton';
import FormDatepickerInput from '~/neo-ui/packages/form/packages/form-input/packages/form-datepicker-input/FormDatepickerInput';
import EngagementActionTicketUnlinkButton from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-ticket/packages/engagement-action-ticket-unlink-button/EngagementActionTicketUnlinkButton';
import { parse, parseISO } from 'date-fns';
import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import EngagementActionDeleteButton from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-action-list-panel-tab/packages/engagement-action-delete-button/EngagementActionDeleteButton';
import EngagementActionTicketButton from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-ticket/packages/engagement-action-ticket-button/EngagementActionTicketButton';
import { Enum as AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Packages/TicketLink/Dto/Model/TicketLinkStateDtoNested.gen';
import EngagementActionTicketDeleteButton from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-ticket/packages/engagement-action-ticket-delete-button/EngagementActionTicketDeleteButton';
import useEngagementActionMarkIncomplete from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionMarkIncomplete';
import EngagementActionInitiativeButton from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-initiative/packages/engagement-action-initiative-button/EngagementActionInitiativeButton';
import EngagementActionInitiativeLinkButton from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-initiative/packages/engagement-action-initiative-link-button/EngagementActionInitiativeLinkButton';
import { InitiativeCreateSource } from '~/wm/packages/strategy/model/strategyDataCollectionSources';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';

export type EngagementActionEditWindowProps = {
  isOpen: boolean;
  hasTicketIntegration: boolean;
  isOrganizationRegisteredForTickets: boolean;
  action: EngagementActionDto;
  ticketFieldOptions: IntegrationFieldOptionDto[];
  onDismiss: () => void;
  initiativeCreatedFrom: InitiativeCreateSource;
};

const descriptionFieldKey: FieldKeyExpression<EngagementActionEditFormDataType> = values => values.description;
const dueAtFieldKey: FieldKeyExpression<EngagementActionEditFormDataType> = values => values.dueAt;
const assignedUserIdsFieldKey: FieldKeyExpression<EngagementActionEditFormDataType> = values => values.assignedUserIds;

const EngagementActionEditWindow = ({
  action,
  ticketFieldOptions,
  isOpen,
  isOrganizationRegisteredForTickets,
  hasTicketIntegration,
  onDismiss,
  initiativeCreatedFrom,
}: EngagementActionEditWindowProps) => {
  const { 'lm-action-item-link-initiative': enabledInitiativeActionItem } = useFeatureFlagProvider();
  const { getFormInput, setFormInput } = useFormContext<EngagementActionEditFormDataType>();
  const { pin, isPinning } = useEngagementActionPin();
  const { unpin, isUnpinning } = useEngagementActionUnpin();
  const { markCompleted, isMarkingCompleted } = useEngagementActionMarkCompleted();
  const { markIncomplete, isMarkingIncomplete } = useEngagementActionMarkIncomplete();

  const { submitForm, isSubmitting } = useFormContext<EngagementActionEditFormDataType>();

  const getDueDateLabel = (dueDate: Date) => {
    const dueDateDefinition = getDateFriendlyExtendedDefinition(dueDate, action.isCompleted);

    return (
      <Label
        css={css`
          white-space: nowrap;
        `}
        size={'md'}
        color={dueDateDefinition.color}
      >
        {dueDateDefinition.display}
      </Label>
    );
  };

  const assignedUserIds = getFormInput<string[]>(assignedUserIdsFieldKey).value;
  const formDateIsDefined = getFormInput<string>(dueAtFieldKey).value !== '';
  const actionDateIsDefined = typeof action.dueAt !== 'undefined';
  const currentActionDueDate =
    formDateIsDefined || actionDateIsDefined
      ? formDateIsDefined
        ? parse(getFormInput<string>(dueAtFieldKey).value, 'yyyy-MM-dd', new Date())
        : actionDateIsDefined
        ? parse(action.dueAt!, 'yyyy-MM-dd', new Date())
        : undefined
      : undefined;

  const isAnyActionProcessing = isPinning || isUnpinning || isSubmitting || isMarkingCompleted || isMarkingIncomplete;

  return (
    <Window
      title={'Edit Action'}
      isOpen={isOpen}
      onDismiss={onDismiss}
      headerProps={{
        leftControls: [
          {
            expanded: (
              <Button
                iconLeft={'Done'}
                theme={action.isCompleted ? undefined : 'primary'}
                onClick={() => {
                  if (action.isCompleted) {
                    markIncomplete(action.engagementActionId);
                  } else {
                    markCompleted(action.engagementActionId);
                  }
                  onDismiss();
                }}
                loading={isMarkingCompleted || isMarkingIncomplete}
                disabled={isSubmitting || isPinning || isUnpinning || isMarkingCompleted || isMarkingIncomplete}
              >
                {action.isCompleted ? 'Mark as incomplete' : 'Mark as complete'}
              </Button>
            ),
          },
          { expanded: <FormDatepickerInput fieldKey={dueAtFieldKey} /> },
        ],
        rightControls: [
          {
            expanded: (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  padding: 0.25rem 0;
                  align-items: flex-end;
                  justify-content: space-between;
                  align-self: stretch;
                  gap: 0.5rem;
                `}
              >
                {action.isCompleted && typeof action.completedAt !== 'undefined' && (
                  <Header
                    size={7}
                    css={css``}
                    color={'primary-400'}
                  >
                    Completed on:{' '}
                    {formatDate(parseISO(action.completedAt), {
                      format: 'MMM dd, yyyy',
                      timezone: TimezoneFormat.Local,
                    })}
                  </Header>
                )}
                <Header
                  size={7}
                  color={'dark-900-64'}
                >
                  Created on:{' '}
                  {formatDate(parseISO(action.createdAt), {
                    format: 'MMM dd, yyyy',
                    timezone: TimezoneFormat.Local,
                  })}
                </Header>
              </div>
            ),
          },
        ],
      }}
      footerProps={{
        leftControls: [
          {
            expanded: (
              <EngagementActionDeleteButton
                engagementActionId={action.engagementActionId}
                onClose={onDismiss}
              />
            ),
          },
          ...(isOrganizationRegisteredForTickets &&
          typeof action.ticketLinkState !== 'undefined' &&
          action.ticketLinkState.type !==
            AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Error
            ? [
                {
                  expanded: (
                    <EngagementActionTicketUnlinkButton
                      action={action}
                      disabled={
                        isAnyActionProcessing ||
                        action.ticketLinkState.type ===
                          AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Pending
                      }
                    />
                  ),
                },
              ]
            : []),
          ...(isOrganizationRegisteredForTickets &&
          typeof action.ticketLinkState !== 'undefined' &&
          action.ticketLinkState.type ===
            AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Error
            ? [
                {
                  expanded: <EngagementActionTicketDeleteButton action={action} />,
                },
              ]
            : []),
        ],
        rightControls: [
          {
            expanded: (
              <Button
                theme={'primary'}
                onClick={() => {
                  submitForm();
                  onDismiss();
                }}
                loading={isSubmitting}
                disabled={isAnyActionProcessing}
              >
                Save
              </Button>
            ),
          },
          {
            expanded: (
              <Button
                onClick={() => {
                  onDismiss();
                }}
                disabled={isAnyActionProcessing}
              >
                Cancel
              </Button>
            ),
          },
        ],
      }}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 1rem;
          align-items: center;
        `}
      >
        <FormTextareaInputInvisible
          css={css`
            width: 100%;
          `}
          textAreaCss={css`
            min-height: 6.438rem;
          `}
          hasDefaultFocus={true}
          hasDefaultSelect={true}
          /**
           * When this is true, this will cause some inputs to not reset properly (when performing a form reset within a form context)
           * This will need to be false to avoid the above behaviour.
           */
          optimizePerformance={false}
          fieldKey={descriptionFieldKey}
          showFormError={false}
        />
        {typeof currentActionDueDate !== 'undefined' && getDueDateLabel(currentActionDueDate)}
        <Button
          size={'sm'}
          iconLeft={'Pin'}
          theme={action.isPinned ? 'primary' : undefined}
          preventOnClickPropagation={true}
          loading={isPinning || isUnpinning}
          disabled={isAnyActionProcessing}
          onClick={() => {
            if (action.isPinned) {
              unpin(action.engagementActionId);
            } else {
              pin(action.engagementActionId);
            }
          }}
        />
        {typeof action.ticketLinkState === 'undefined' ? (
          <EngagementActionTicketCreateButton
            size={'sm'}
            action={action}
            fieldOptions={ticketFieldOptions}
            isOrganizationRegisteredForTickets={isOrganizationRegisteredForTickets}
            hasTicketIntegration={hasTicketIntegration}
          />
        ) : (
          <EngagementActionTicketButton
            size={'sm'}
            ticketLinkState={action.ticketLinkState}
          />
        )}
        {enabledInitiativeActionItem &&
          (typeof action.initiativeLinkInfo === 'undefined' ? (
            <EngagementActionInitiativeLinkButton
              size={'sm'}
              action={action}
              initiativeCreatedFrom={initiativeCreatedFrom}
            />
          ) : (
            <EngagementActionInitiativeButton
              size={'sm'}
              initiativeId={action.initiativeLinkInfo.initiativeId}
            />
          ))}
      </div>
      <ActiveUsersSearch
        inputLabel={{
          icon: 'Users',
          label: 'Assign to:',
        }}
        menuCssPosition={'relative'}
        selectedUserIds={assignedUserIds}
        onSelectedUsersChange={selectedUsers => {
          const selectedUserIds = selectedUsers.map(user => user.userId);
          setFormInput<string[]>(assignedUserIdsFieldKey, selectedUserIds);
        }}
      />
      {typeof action.ticketLinkState !== 'undefined' &&
        action.ticketLinkState.type ===
          AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Pending && (
          <Label
            size={'sm'}
            color={'dark-900-64'}
          >
            PSA ticket is being created, check back later.
          </Label>
        )}
      {typeof action.ticketLinkState !== 'undefined' &&
        action.ticketLinkState.type ===
          AssetManagementClientBeastClientBeastCoreTicketPackagesTicketLinkDtoModelTicketLinkStateDtoNestedEnum.Error && (
          <Label
            size={'sm'}
            color={'negative-500'}
          >
            PSA ticket creation failed. Please delete this ticket and try again.
          </Label>
        )}
    </Window>
  );
};

export default EngagementActionEditWindow;
