import { useContext } from 'react';
import AutoEmailReportUrlContext from '~/wm/packages/organization/packages/auto-email-report/context/AutoEmailReportUrlContext';

const useAutoEmailReportUrlContext = () => {
  const autoEmailReportUrlContext = useContext(AutoEmailReportUrlContext);
  if (!autoEmailReportUrlContext) {
    throw new Error('Tried to use AutoEmailReportUrlContext outside of an AutoEmailReportUrlProvider');
  }

  return autoEmailReportUrlContext;
};

export default useAutoEmailReportUrlContext;
