import { ClassNames } from '@emotion/react';
import { Themeable } from '~/neo-ui/model/capacity';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import { useCallback, useEffect, useRef, useState } from 'react';
import { boxShadowToCode } from '~/neo-ui/packages/style/BoxShadow';

export type ToastInternalProps = {
  isOpen: boolean;
  onClose: () => void;

  /**
   * Optional, to disable the toast appear and leave animation
   */
  disableAnimation?: boolean;
  /**
   * Optional, to disable the toast from disappearing
   */
  disableFadeOut?: boolean;
  children: React.ReactNode;
} & Themeable;

/**
 * This is called ToastInternal, as it is the base for our toast UI components.
 *
 * It is not to be used directly since it allows for many options.
 * Either use our toast component or develop a new standard toast for general use.
 */
const ToastInternal = ({
  theme,

  isOpen,
  onClose,

  disableAnimation = false,
  disableFadeOut = false,

  children,
}: ToastInternalProps) => {
  const { themeMap } = useTheme(theme);

  // Transition time for the toast to appear in milliseconds
  const transitionAppearTimeMs = 350;
  // Transition time for the toast to leave in milliseconds
  const transitionLeaveTimeMs = 500;
  // How long the toast will be displayed in millisecond
  const displayTimeMs = 5000;
  const [display, setDisplay] = useState(isOpen);

  const beginFadeOutTimeoutRef = useRef<NodeJS.Timeout>();

  const startFadeSequence = useCallback(() => {
    if (display && !disableFadeOut) {
      beginFadeOutTimeoutRef.current = setTimeout(() => {
        setDisplay(!display);
        setTimeout(
          () => {
            onClose();
          },
          disableAnimation ? 0 : transitionLeaveTimeMs,
        );
      }, displayTimeMs);
    }
  }, [disableAnimation, disableFadeOut, display, onClose]);

  const stopFadeSequence = () => {
    if (beginFadeOutTimeoutRef.current) {
      clearTimeout(beginFadeOutTimeoutRef.current);
    }
  };

  useEffect(() => {
    startFadeSequence();
    return () => {
      stopFadeSequence();
    };
  }, [startFadeSequence]);

  return (
    <ClassNames>
      {({ css }) => {
        const maxWidthRem = 25;
        const bodyPaddingRem = 0.625;

        const hasTransition = !disableAnimation;

        const transitionDuration = hasTransition
          ? display
            ? `transition: opacity ${transitionAppearTimeMs}ms ease; `
            : `transition: opacity ${transitionLeaveTimeMs}ms ease; `
          : '';

        return (
          <div
            onMouseEnter={stopFadeSequence}
            onMouseLeave={startFadeSequence}
            css={css`
              opacity: ${display ? 1 : 0};
              background-color: ${colorToCode(themeMap.foregroundAccent ?? 'light-000')};
              padding: ${bodyPaddingRem}rem;
              margin: ${bodyPaddingRem}rem;
              max-width: ${maxWidthRem}rem;
              box-shadow: ${boxShadowToCode('shadow300')};
              border-radius: 0.625rem;
              ${transitionDuration}
            `}
          >
            <div
              css={css`
                padding: 0;
                color: ${theme ? 'white' : 'black'};
              `}
            >
              {children}
            </div>
          </div>
        );
      }}
    </ClassNames>
  );
};

export default ToastInternal;
