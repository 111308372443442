import WizardPageStep from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageStep';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import AssetsSection from '~/wm/packages/disposal/wizard/section/AssetsSection';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import DisposalCheckoutTypeSection from '~/wm/packages/disposal/wizard/section/DisposalCheckoutTypeSection';
import DisposalBulkCheckoutFormData from '~/wm/packages/disposal/types/DisposalBulkCheckoutFormData';
import { DisposalCheckoutType } from '~/wm/packages/disposal/packages/disposal-section/DisposalSection';

const assetTypeCountsFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values => values.assetTypeCounts;

const biosCertifiedFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values => values.certification.biosCertified;

const getDisposalBulkAssetsStep = (
  disposalAssetTypeCountAvailabilities: DisposalAssetTypeAvailabilityDto[],
  onDisposalTypeSwap: (disposalCheckoutType: DisposalCheckoutType) => void,
  isDisposalFree: boolean,
  isBulkOrderPlacedDisabled: boolean,
  minAssetsCountForFullService: number,
): WizardPageStep<DisposalBulkCheckoutFormData> => ({
  header: { label: 'Assets', icon: 'HardwareAsset' },
  fields: [assetTypeCountsFieldKey, biosCertifiedFieldKey],
  sections: [
    () =>
      AssetsSection({
        assetTypeCountsFieldKey,
        disposalAssetTypeCountAvailabilities,
        assetCountMin: minAssetsCountForFullService,
      }),
    () =>
      DisposalCheckoutTypeSection<DisposalBulkCheckoutFormData>({
        isBulk: true,
        isDisposalFree,
        assetTypeCountsFieldKey,
        onDisposalTypeSwap,
        biosCertifiedFieldKey,
        // User must have bulk disposal location access to be here
        hasDisposalBulkLocationAccess: true,
        isBulkOrderPlacedDisabled,
        minAssetsCountForFullService,
      }),
  ],
  onCompleteStateChange: () => true,
  key: 'Assets',
});

export default getDisposalBulkAssetsStep;
