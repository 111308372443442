import * as React from 'react';
import { GroupBase } from 'react-select';
import { Styleable } from '~/neo-ui/model/capacity';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import SingleSelectGroupHeading from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-group-heading/SingleSelectGroupHeading';
import SingleSelectGroup from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-group/SingleSelectGroup';
import SingleSelectDropdownIndicator from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-dropdown-indicator/SingleSelectDropdownIndicator';
import SingleSelectMenu from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-menu/SingleSelectMenu';
import SingleSelectOption from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-option/SingleSelectOption';
import SingleSelectSingleValue from '~/neo-ui/packages/select/packages/single-select/packages/components/single-select-single-value/SingleSelectSingleValue';
import SingleSelectInternal from '~/neo-ui/packages/select/packages/single-select/SingleSelectInternal';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { customStylesDefault } from '~/neo-ui/packages/select/packages/single-select/packages/styles/SingleSelectStyleDefault';
import { customStylesTransparent } from '~/neo-ui/packages/select/packages/single-select/packages/styles/SingleSelectStyleTransparent';
import { shouldShowSearch } from '~/neo-ui/packages/select/packages/Common';
import { customStylesStackAdd } from '~/neo-ui/packages/select/packages/single-select/packages/styles/SingleSelectStyleStackAdd';
import Color from '~/neo-ui/packages/color/Color.gen';

export type SingleSelectStyle = 'default' | 'transparent' | 'stack-add';

export const getStyle = (style: SingleSelectStyle, height: string | undefined) => {
  switch (style) {
    case 'default':
      return customStylesDefault({ height });
    case 'transparent':
      return customStylesTransparent({ height });
    case 'stack-add':
      return customStylesStackAdd();
  }
};

export const getDropdownIndicatorDisplayDetails: (style: SingleSelectStyle) => {
  sizeInPx: number;
  color: Color;
} = style => {
  switch (style) {
    case 'default':
      return { sizeInPx: 15, color: 'dark-900' };
    case 'stack-add':
      return { sizeInPx: 15, color: 'primary-400' };
    case 'transparent':
      return { sizeInPx: 16, color: 'dark-900' };
  }
};

export type SingleSelectProps<T extends string = string> = {
  options: SelectOption<T>[] | GroupBase<SelectOption<T>>[];
  selectedOption: SelectOption<T> | undefined | null;
  defaultOption?: SingleSelectProps<T>['selectedOption'];
  onOptionSelected: (option: SelectOption<T>) => void;
  placeholder?: React.ReactNode;
  style?: SingleSelectStyle;
  controlContainerHeight?: string;
  dropdownIndicatorIconOpen?: IconType;
  dropdownIndicatorIconClosed?: IconType;

  /**
   * Width in pixels to truncate the selected option at
   */
  truncateSelected?: number;
  isSearchable?: boolean;
  /**
   * Indicate if option description should be displayed on the control button
   */
  shouldShowDescriptionOnControlButton?: boolean;
  /**
   * If set to true the dropdown menu will use it's own portal.
   * WARNING: This is the default behaviour of this component.
   * You may need to set this to false when used in components that use their own portals such as windows or modals.
   */
  shouldUseMenuPortal?: boolean;
  /**
   * Indicate if option description should be displayed on the control button
   */
  shouldControlRenderValue?: boolean;
  disabled?: boolean;
} & Styleable;

const SingleSelect = <T extends string>({
  options,
  selectedOption,
  defaultOption,
  onOptionSelected,
  placeholder = 'Find in list...',
  style = 'default',
  truncateSelected,
  isSearchable,
  shouldShowDescriptionOnControlButton = true,
  className,
  controlContainerHeight,
  dropdownIndicatorIconOpen = 'ArrowUp',
  dropdownIndicatorIconClosed = 'ArrowDown',
  shouldUseMenuPortal = true,
  shouldControlRenderValue = true,
  disabled = false,
}: SingleSelectProps<T>) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const dropdownIndicatorDisplayDetails = getDropdownIndicatorDisplayDetails(style);

  return (
    <SingleSelectInternal
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
      shouldUseMenuPortal={shouldUseMenuPortal}
      shouldControlRenderValue={shouldControlRenderValue}
      options={options}
      selectedOption={selectedOption}
      defaultOption={defaultOption}
      onOptionSelected={onOptionSelected}
      className={className}
      placeholder={placeholder}
      isSearchable={isSearchable ?? shouldShowSearch(options)}
      disabled={disabled}
      components={{
        Option: SingleSelectOption,
        SingleValue: props => SingleSelectSingleValue(props, placeholder, shouldShowDescriptionOnControlButton, style, truncateSelected),
        GroupHeading: SingleSelectGroupHeading,
        Group: SingleSelectGroup,
        DropdownIndicator: props =>
          SingleSelectDropdownIndicator(
            props,
            dropdownIndicatorDisplayDetails.sizeInPx,
            menuOpen,
            dropdownIndicatorIconOpen,
            dropdownIndicatorIconClosed,
            dropdownIndicatorDisplayDetails.color,
          ),
        IndicatorSeparator: () => null,
        Menu: props => SingleSelectMenu(props),
      }}
      styles={getStyle(style, controlContainerHeight)}
    />
  );
};

export default SingleSelect;
