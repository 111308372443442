import { useState } from 'react';

export type ContractAgreementSelectTableType = 'new' | 'updated';

export type UseContractAgreementSelect = {
  /**
   * All selected agreement ids
   */
  agreementIds: string[];

  /**
   * Agreement ids for a given table type
   */
  agreementIdsByTableType: (tableType: ContractAgreementSelectTableType) => Set<string>;

  /**
   * Replace a selected agreement ids with what is given for a table
   */
  updateAgreementIdsByTableType: (assetIds: Set<string>, tableType: ContractAgreementSelectTableType) => void;

  /**
   * Clear selected agreements
   */
  clearAgreementIds: () => void;
};

const useContractAgreementSelect = (): UseContractAgreementSelect => {
  const [agreementIdsMap, setAgreementIdsMap] = useState<Map<ContractAgreementSelectTableType, Set<string>>>(new Map());

  return {
    agreementIds: Array.from(agreementIdsMap.values()).reduce<string[]>((acc, cur) => acc.concat([...cur]), []),

    agreementIdsByTableType: tableType => agreementIdsMap.get(tableType) ?? new Set(),

    clearAgreementIds: () => setAgreementIdsMap(new Map()),

    updateAgreementIdsByTableType: (agreementIds, tableType) => setAgreementIdsMap(map => new Map(map).set(tableType, agreementIds)),
  };
};

export default useContractAgreementSelect;
