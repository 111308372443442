import { organizationEngagementActionUnpin } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementActionListMutation, {
  UseEngagementActionListMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListMutation';

export type UseEngagementActionUnpin = {
  unpin: (engagementActionId: string) => void;
  isUnpinning: boolean;
};

const useEngagementActionUnpin = (options?: UseEngagementActionListMutationOptions): UseEngagementActionUnpin => {
  const [unpin, isUnpinning] = useEngagementActionListMutation(
    (engagementActionId: string) =>
      organizationEngagementActionUnpin({
        engagementActionId,
      }),
    options,
  );

  return {
    unpin,
    isUnpinning,
  };
};

export default useEngagementActionUnpin;
