import * as React from 'react';
import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { DisposalOrderBulkAvailabilityScheduleFormData } from '../../DisposalOrderBulkAvailabilityScheduleForm';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import FormDatepickerInput from '~/neo-ui/packages/form/packages/form-input/packages/form-datepicker-input/FormDatepickerInput';
import Button from '~/neo-ui/packages/button/Button';
import FormTimeInput from '~/neo-ui/packages/form/packages/form-input/packages/form-time-input/FormTimeInput';
import FormTextInput from '~/neo-ui/packages/form/packages/form-input/packages/form-text-input/FormTextInput';
import FormErrorMessage from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';
import disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-bulk-pickup-section/packages/disposal-order-bulk-pickup-section-availability-schedule/packages/disposal-order-bulk-availability-schedule-form/packages/disposal-order-bulk-pickup-availability-schedule-form-section/disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles';
import TextBlock from '../../../../../../../../../../../../../neo-ui/packages/text/packages/text-block/TextBlock';

export type DisposalScheduleRules = {
  startingDateMin: string | undefined;
  endingDateMin: string | undefined;
  endingDateDisabled: boolean;
};

const addWorkingDays = (initDate: Date, daysToAdd: number, denyToReturnHolidays: boolean = true): Date => {
  let workingDaysAdded = 0;
  const date = new Date(initDate);
  while (workingDaysAdded < daysToAdd) {
    date.setDate(date.getDate() + 1);
    if (date.getDay() !== 0 && date.getDay() !== 6) {
      workingDaysAdded++;
    }
  }

  if (denyToReturnHolidays) {
    switch (date.getDay()) {
      case 0:
        date.setDate(date.getDate() + 1);
        break;
      case 6:
        date.setDate(date.getDate() + 2);
        break;
    }
  }

  return date;
};

const siteContactNameFieldKey = (formData: FieldKeyExpressionSegment<DisposalOrderBulkAvailabilityScheduleFormData>) =>
  formData.siteContactName;

const siteContactPhoneFieldKey = (formData: FieldKeyExpressionSegment<DisposalOrderBulkAvailabilityScheduleFormData>) =>
  formData.siteContactPhone;

const siteContactEmailFieldKey = (formData: FieldKeyExpressionSegment<DisposalOrderBulkAvailabilityScheduleFormData>) =>
  formData.siteContactEmail;

const startingDateFieldKey = (formData: FieldKeyExpressionSegment<DisposalOrderBulkAvailabilityScheduleFormData>) => formData.startingDate;

const startingTimeFieldKey = (formData: FieldKeyExpressionSegment<DisposalOrderBulkAvailabilityScheduleFormData>) => formData.startingTime;

const endingDateFieldKey = (formData: FieldKeyExpressionSegment<DisposalOrderBulkAvailabilityScheduleFormData>) => formData.endingDate;

const endingTimeFieldKey = (formData: FieldKeyExpressionSegment<DisposalOrderBulkAvailabilityScheduleFormData>) => formData.endingTime;

const notesFieldKey = (formData: FieldKeyExpressionSegment<DisposalOrderBulkAvailabilityScheduleFormData>) => formData.notes;

const timeWindowShiftDays: number = 21;
const timeWindowMinLengthDays: number = 7;

export type DisposalOrderBulkPickupAvailabilityScheduleFormSectionProps = {
  canSchedule: boolean;
};

const DisposalOrderBulkPickupAvailabilityScheduleFormSection = ({
  canSchedule,
}: DisposalOrderBulkPickupAvailabilityScheduleFormSectionProps) => {
  const { submitForm, isSubmitting, getFormInput } = useFormContext<DisposalOrderBulkAvailabilityScheduleFormData>();

  const contactEmailFormInput = getFormInput(siteContactEmailFieldKey);
  const contactPhoneFormInput = getFormInput(siteContactPhoneFieldKey);

  const startingDateFormInput = getFormInput(startingDateFieldKey);
  const endingDateFormInput = getFormInput(endingDateFieldKey);
  const startingTimeFormInput = getFormInput(startingTimeFieldKey);
  const endingTimeFormInput = getFormInput(endingTimeFieldKey);

  const dateFieldsInvalid =
    (typeof startingDateFormInput.error !== 'undefined' && startingDateFormInput.touched) ||
    (typeof endingDateFormInput.error !== 'undefined' && endingDateFormInput.touched);
  const timeFieldsInvalid =
    (typeof startingTimeFormInput.error !== 'undefined' && startingTimeFormInput.touched) ||
    (typeof endingTimeFormInput.error !== 'undefined' && endingTimeFormInput.touched);

  const contactEmailPhoneFieldsInvalid =
    (typeof contactEmailFormInput.error !== 'undefined' && contactEmailFormInput.touched) ||
    (typeof contactPhoneFormInput.error !== 'undefined' && contactPhoneFormInput.touched);

  const scheduleDatesRules = useMemo((): DisposalScheduleRules => {
    const startingDateMin = addWorkingDays(new Date(), timeWindowShiftDays).toISOString().split('T')[0];
    const endingDateMin = startingDateFormInput?.value
      ? addWorkingDays(new Date(startingDateFormInput.value), timeWindowMinLengthDays).toISOString().split('T')[0]
      : undefined;

    const endingDateDisabled = !startingDateFormInput.value;

    return { endingDateDisabled, endingDateMin, startingDateMin };
  }, [startingDateFormInput.value]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      `}
    >
      <section css={[disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.grid]}>
        <div
          css={[
            disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.contactNameGridColumns,
            disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.formField,
          ]}
        >
          <Label bold={true}>Contact name</Label>
          <FormTextInput
            fieldKey={siteContactNameFieldKey}
            placeholder={'Name'}
          />
        </div>
        <div
          css={[
            disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.contactEmailGridColumns,
            disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.formField,
          ]}
        >
          <Label bold={true}>Contact email</Label>
          <FormTextInput
            fieldKey={siteContactEmailFieldKey}
            placeholder={'Email address'}
            showFormError={false}
          />
        </div>
        <div
          css={[
            disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.contactPhoneNumberGridColumns,
            disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.formField,
          ]}
        >
          <Label bold={true}>Contact phone number</Label>
          <FormTextInput
            fieldKey={siteContactPhoneFieldKey}
            placeholder={'Phone number'}
            showFormError={false}
          />
        </div>
        {contactEmailPhoneFieldsInvalid && (
          <React.Fragment>
            <div
              css={[
                disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.contactEmailGridColumns,
                disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.formFieldErrorMessageSpace,
              ]}
            >
              <FormErrorMessage fieldKey={siteContactEmailFieldKey} />
            </div>
            <div
              css={[
                disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.contactPhoneNumberGridColumns,
                disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.formFieldErrorMessageSpace,
              ]}
            >
              <FormErrorMessage fieldKey={siteContactPhoneFieldKey} />
            </div>
          </React.Fragment>
        )}
        <TextBlock
          css={css`
            grid-column: 1/5;
            margin-top: 0.5rem;
          `}
          title={'What dates and times work for you?'}
          description={`Choose a ${timeWindowMinLengthDays}-day window from available times in the calendar below`}
          size={'sm'}
        />
        <Label>Between</Label>
        <FormDatepickerInput
          fieldKey={startingDateFieldKey}
          showFormError={false}
          min={scheduleDatesRules.startingDateMin}
        />
        <Label>and</Label>
        <FormDatepickerInput
          fieldKey={endingDateFieldKey}
          showFormError={false}
          min={scheduleDatesRules.endingDateMin}
          disabled={scheduleDatesRules.endingDateDisabled}
        />
        {dateFieldsInvalid && (
          <React.Fragment>
            <div css={[disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.formFieldErrorMessageSpace]} />
            <div css={[disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.formFieldErrorMessageSpace]}>
              <FormErrorMessage fieldKey={startingDateFieldKey} />
            </div>
            <div css={[disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.formFieldErrorMessageSpace]} />
            <div css={[disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.formFieldErrorMessageSpace]}>
              <FormErrorMessage fieldKey={endingDateFieldKey} />
            </div>
          </React.Fragment>
        )}
        <Label>From</Label>
        <FormTimeInput
          fieldKey={startingTimeFieldKey}
          showFormError={false}
        />
        <Label>to</Label>
        <FormTimeInput
          fieldKey={endingTimeFieldKey}
          showFormError={false}
        />
        {timeFieldsInvalid && (
          <React.Fragment>
            <div css={[disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.formFieldErrorMessageSpace]} />
            <div css={[disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.formFieldErrorMessageSpace]}>
              <FormErrorMessage fieldKey={startingTimeFieldKey} />
            </div>
            <div css={[disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.formFieldErrorMessageSpace]} />
            <div css={[disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.formFieldErrorMessageSpace]}>
              <FormErrorMessage fieldKey={endingTimeFieldKey} />
            </div>
          </React.Fragment>
        )}
        <div
          css={[
            disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.formField,
            disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.notesGridColumns,
            disposalOrderBulkPickupAvailabilityScheduleFormSectionStyles.sectionSpace,
          ]}
        >
          <Label bold={true}>What else should we know about your availability?</Label>
          <FormTextInput
            fieldKey={notesFieldKey}
            placeholder={'For example, “We’re not available on Thursdays after 15:00”'}
          />
        </div>
      </section>
      <section
        css={css`
          display: flex;
          flex-direction: row;
          gap: 0.625rem;
        `}
      >
        <Button
          iconLeft={'Done'}
          theme={'primary'}
          onClick={submitForm}
          loading={isSubmitting}
          disabled={!canSchedule}
        >
          Request pickup
        </Button>
      </section>
    </div>
  );
};

export default DisposalOrderBulkPickupAvailabilityScheduleFormSection;
