import { RoadmapPdfDownloadFormData } from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap-share-button/packages/roadmap-pdf-download-form/RoadmapPdfDownloadForm';
import { css } from '@emotion/react';

import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { FieldKeyExpression, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';

import FormCheckboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckboxInput';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';

import * as React from 'react';
import { useMemo } from 'react';

import FiscalQuarterSelect from '~/wm/packages/strategy/packages/fiscal-quarter/FiscalQuarterSelect';
import { BudgetQuarterDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Packages/BudgetQuarter/Dto/Model.gen';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import FormErrorMessage from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';
import { getIn } from 'formik';

const includeUnscheduledInitiativeFieldKey: FieldKeyExpression<RoadmapPdfDownloadFormData> = values =>
  values.shouldIncludeUnscheduledInitiative;
const startingFiscalQuarterFieldKey: FieldKeyExpression<RoadmapPdfDownloadFormData> = values => values.startingFiscalQuarter;
const startingFiscalQuarterYearFieldKey: FieldKeyExpression<RoadmapPdfDownloadFormData> = values => values.startingFiscalQuarter.year;
const startingFiscalQuarterQuarterFieldKey: FieldKeyExpression<RoadmapPdfDownloadFormData> = values => values.startingFiscalQuarter.quarter;
const endFiscalQuarterFieldKey: FieldKeyExpression<RoadmapPdfDownloadFormData> = values => values.endFiscalQuarter;
const endFiscalQuarterYearFieldKey: FieldKeyExpression<RoadmapPdfDownloadFormData> = values => values.endFiscalQuarter.year;
const endFiscalQuarterQuarterFieldKey: FieldKeyExpression<RoadmapPdfDownloadFormData> = values => values.endFiscalQuarter.quarter;

const RoadmapPdfDownloadWindowTimelineSection = () => {
  const { getFormInput, setFormInput, errors } = useFormContext<RoadmapPdfDownloadFormData>();
  const startingFiscalQuarterError = getIn(errors, resolveFieldKey(startingFiscalQuarterFieldKey));
  const endFiscalQuarterError = getIn(errors, resolveFieldKey(endFiscalQuarterFieldKey));

  const defaultEndingFiscalQuarter = useMemo(
    () => getFormInput<BudgetQuarterDto>(endFiscalQuarterFieldKey).value,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const defaultStartingFiscalQuarter = useMemo(
    () => getFormInput<BudgetQuarterDto>(startingFiscalQuarterFieldKey).value,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <Box
      padding={'padding200'}
      background={'foregroundAccent'}
      theme={'none'}
      borderRadius={'radius300'}
    >
      <Header
        size={4}
        weight={'bold'}
      >
        Timeline
      </Header>
      <hr
        css={css`
          border-color: 0.0625rem solid ${colorToCode('dark-900-12')};
        `}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 2rem;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 0.5rem;
              align-items: center;
            `}
          >
            <Label>From</Label>
            <FiscalQuarterSelect
              defaultValue={defaultStartingFiscalQuarter}
              fieldKeys={{
                year: startingFiscalQuarterYearFieldKey,
                quarter: startingFiscalQuarterQuarterFieldKey,
              }}
              onSelect={(year, quarter) => setFormInput<BudgetQuarterDto>(startingFiscalQuarterFieldKey, { year, quarter })}
              theme={'primary'}
            />
            <FormErrorMessage fieldKey={startingFiscalQuarterYearFieldKey} />
          </div>
          <div
            css={css`
              display: flex;
              gap: 0.5rem;
              align-items: center;
            `}
          >
            <Label>To</Label>
            <FiscalQuarterSelect
              defaultValue={defaultEndingFiscalQuarter}
              fieldKeys={{
                year: endFiscalQuarterYearFieldKey,
                quarter: endFiscalQuarterQuarterFieldKey,
              }}
              onSelect={(year, quarter) => setFormInput<BudgetQuarterDto>(endFiscalQuarterFieldKey, { year, quarter })}
              theme={'primary'}
            />
          </div>
        </div>
        {(startingFiscalQuarterError || endFiscalQuarterError) && (
          <Label
            css={css`
              margin-top: -0.5rem;
              font-size: 80%;
              font-weight: 400;
            `}
            color={'negative-400'}
            size={'sm'}
          >
            {'Starting fiscal quarter must be before or equals to Ending fiscal quarter'}
          </Label>
        )}
        <FormCheckboxInput
          fieldKey={includeUnscheduledInitiativeFieldKey}
          description={'Include Unscheduled'}
        />
      </div>
    </Box>
  );
};

export default RoadmapPdfDownloadWindowTimelineSection;
