import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import BorderRadius, { borderRadiusToCode } from '~/neo-ui/packages/style/BorderRadius';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type BadgeProps = {
  height?: string;
  bgColor?: Color;
  textColor?: Color;
  padding?: string;
  borderRadius?: BorderRadius;
  fontWeight?: string;
} & Styleable;

/**
 * The Badge is a component which can be used to display small amounts of information
 * in a clear fashion. The badge helps draw the users attention and provide color context
 * for the user at a glance.
 *
 * Badge is safe to use, and should have no dependencies or unexpected behavior.
 */
const Badge: React.FunctionComponent<React.PropsWithChildren<BadgeProps>> = ({
  height = '1.5rem',
  bgColor = 'primary-400',
  textColor = 'light-000',
  padding = '0 0.5rem',
  borderRadius = 'radius425', // 12px
  fontWeight = 'normal',
  children,
  className,
}) => (
  <div
    css={css`
      //Positioning and alignment
      display: flex;
      align-items: center;
      padding: ${padding};

      //Sizing and styling
      height: ${height};
      min-width: 1.5rem;
      border-radius: ${borderRadiusToCode(borderRadius)}; // 12px

      //Font/text styling (Component specific)
      white-space: nowrap;
      color: ${colorToCode(textColor)};
      font-weight: ${fontWeight};

      //Color. Must use global settings
      background-color: ${colorToCode(bgColor)};
    `}
    className={className}
  >
    {children}
  </div>
);

export default Badge;
