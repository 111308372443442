import { autoEmailReportPdfPreview } from '@AssetManagementClient/AssetManagementClientMsp.gen';

import useApi from '~/wm/packages/api/hook/useApi';
import React from 'react';

type AutoEmailReportPdfPreviewDownloadPayload = {
  organizationId: string;
  autoEmailReportId: string;
};

export type UseAutoEmailReportPdfPreview = () => {
  downloadAutoEmailReportPdfPreview: (downloadPayload: AutoEmailReportPdfPreviewDownloadPayload) => void;
};

const useAutoEmailReportPdfPreview: UseAutoEmailReportPdfPreview = () => {
  const { callApi } = useApi();

  const downloadAutoEmailReportPdfPreview = React.useCallback(
    async (downloadPayload: AutoEmailReportPdfPreviewDownloadPayload) => {
      const stream = await callApi(() =>
        autoEmailReportPdfPreview({
          organizationId: downloadPayload.organizationId,
          autoEmailReportId: downloadPayload.autoEmailReportId,
        }),
      );

      if (!stream) {
        return;
      }
    },
    [callApi],
  );

  return { downloadAutoEmailReportPdfPreview };
};

export default useAutoEmailReportPdfPreview;
