import React from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { OrganizationAccountTeamMemberDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationAccountTeamMember/Dto/Model.gen';
import useOrganizationAccountTeamMemberRemove from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/useOrganizationAccountTeamMemberRemove';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import formatUserNameDisplay from '~/wm/packages/account/packages/user/packages/active-users-search/formatUserNameDisplay';

export type AccountTeamMemberDeleteModalProps = {
  organizationAccountTeamMember: OrganizationAccountTeamMemberDto;
  isOpen: boolean;
  onDismiss: () => void;
  onDelete: () => void;
};

const AccountTeamMemberDeleteModal: React.FunctionComponent<AccountTeamMemberDeleteModalProps> = ({
  organizationAccountTeamMember,
  isOpen,
  onDismiss,
  onDelete,
}) => {
  const { removeOrganizationAccountTeamMember, isRemovingOrganizationAccountTeamMember } = useOrganizationAccountTeamMemberRemove({
    onSuccess: () => {
      onDismiss();
      onDelete();
    },
  });

  const { organizationId } = useOrganizationContext();

  return (
    <ModalConfirmation
      header={{
        title: 'Remove member from this client?',
        icon: 'Trash',
      }}
      isOpen={isOpen}
      theme={'negative'}
      footer={{
        confirmButton: {
          icon: 'Trash',
          label: 'Remove',
          loading: isRemovingOrganizationAccountTeamMember,
          onClick: () => {
            removeOrganizationAccountTeamMember({
              organizationId,
              organizationAccountTeamMemberId: organizationAccountTeamMember.organizationAccountTeamMemberId,
            });
          },
        },
        dismissButton: {
          icon: 'Cancel',
          label: 'Close',
          disabled: isRemovingOrganizationAccountTeamMember,
          onClick: onDismiss,
        },
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 1rem;
            align-items: center;
          `}
        >
          <Icon icon={'Administrator'} />
          <Label bold={true}>{formatUserNameDisplay(organizationAccountTeamMember)}</Label>
        </div>

        <div
          css={css`
            display: flex;
            gap: 1rem;
            align-items: center;
          `}
        >
          <Icon
            color={'dark-900-64'}
            icon={'Email'}
          />
          <Label color={'dark-900-64'}>{organizationAccountTeamMember.email}</Label>
        </div>
      </div>
    </ModalConfirmation>
  );
};

export default AccountTeamMemberDeleteModal;
