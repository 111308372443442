import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CardForm from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/payment/CardForm';
import Spinner from '~/neo-ui/spinner/Spinner';
import useSetupIntent from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/payment/hook/useSetupIntent';
import { useRef } from 'react';
import { css } from '@emotion/react';

const Payment = (props: { paymentSubmitAction: (setupIntent: string) => void; submitLabel: string }) => {
  const { paymentSubmitAction, submitLabel } = props;
  const stripePromise = useRef(loadStripe(WM.stripePublicKey));
  const { isLoading, clientSecret } = useSetupIntent();
  if (!clientSecret || isLoading) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <Elements stripe={stripePromise.current}>
      <CardForm
        submitLabel={submitLabel}
        clientSecret={clientSecret}
        paymentSubmitAction={paymentSubmitAction}
      />
    </Elements>
  );
};

export default Payment;
