import { organizationEngagementActionUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementActionListMutation, {
  UseEngagementActionListMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListMutation';
import useEngagementActionDataCollectionContext from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionDataCollectionContext';

export type EngagementActionUpdatePayload = {
  engagementActionId: string;
  description: string;
  dueAt: string | undefined;
  assignedUserIds: string[];
};

export type UseEngagementActionUpdate = {
  updateEngagementAction: (updatePayload: EngagementActionUpdatePayload) => void;
  isUpdating: boolean;
};

const useEngagementActionUpdate = (options?: UseEngagementActionListMutationOptions): UseEngagementActionUpdate => {
  const { updatedFrom } = useEngagementActionDataCollectionContext();

  const [updateEngagementAction, isUpdating] = useEngagementActionListMutation(
    (updatePayload: EngagementActionUpdatePayload) =>
      organizationEngagementActionUpdate({
        engagementActionId: updatePayload.engagementActionId,
        updatePayload: {
          description: updatePayload.description,
          dueAt: updatePayload.dueAt,
          assignedUserIds: updatePayload.assignedUserIds,
          updatedFrom,
        },
      }),
    options,
  );

  return {
    updateEngagementAction,
    isUpdating,
  };
};

export default useEngagementActionUpdate;
