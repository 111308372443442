import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import BadgeMarketing, { BadgeMarketingProps } from '~/neo-ui/packages/badge/packages/badge-marketing/BadgeMarketing';

export type BadgeMarketingProProps = Pick<BadgeMarketingProps, 'shouldInvertColors' | 'size'> & Styleable;

const BadgeMarketingPro = ({ shouldInvertColors, size, className }: BadgeMarketingProProps) => (
  <BadgeMarketing
    type={'pro'}
    size={size}
    shouldInvertColors={shouldInvertColors}
    className={className}
  />
);

export default BadgeMarketingPro;
