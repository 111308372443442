import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import InitiativeSaveTicketButton from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-ticket-section/packages/initiative-save-ticket-button/InitiativeSaveTicketButton';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Button from '~/neo-ui/packages/button/Button';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { InitiativeSaveFormData } from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { TicketCreateFieldValueDto } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Dto/Model.gen';
import { InitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import InitiativeSaveTicketSectionStates from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-ticket-section/packages/initiative-save-ticket-section-states/InitiativeSaveTicketSectionStates';

const shouldUnlinkTicketFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.shouldUnlinkTicket;
const ticketFieldValuesFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.ticketFieldValues;

enum TicketActionIntent {
  Unlink = 'unlink',
  Create = 'create',
  UnlinkAndCreate = 'unlinkAndCreate',
}

export type InitiativeSaveTicketSectionProps = {
  initiative?: InitiativeDto;
};

const InitiativeSaveTicketSection = ({ initiative }: InitiativeSaveTicketSectionProps) => {
  const { setFormInput, getFormInput } = useFormContext<InitiativeSaveFormData>();

  const shouldUnlinkTicket = getFormInput<boolean>(shouldUnlinkTicketFieldKey).value;
  const ticketFieldValues = getFormInput<TicketCreateFieldValueDto[] | undefined>(ticketFieldValuesFieldKey).value;
  const isCreatingTicket = typeof ticketFieldValues !== 'undefined' && ticketFieldValues.length > 0;

  let ticketActionIntent: TicketActionIntent | undefined;
  if (isCreatingTicket && shouldUnlinkTicket) {
    ticketActionIntent = TicketActionIntent.UnlinkAndCreate;
  }

  if (isCreatingTicket && !shouldUnlinkTicket) {
    ticketActionIntent = TicketActionIntent.Create;
  }

  if (!isCreatingTicket && shouldUnlinkTicket) {
    ticketActionIntent = TicketActionIntent.Unlink;
  }

  const revertAllTicketActionIntents = () => {
    setFormInput<TicketCreateFieldValueDto[] | undefined>(ticketFieldValuesFieldKey, undefined);
    setFormInput<boolean>(shouldUnlinkTicketFieldKey, false);
  };

  const hasExistingTicketLink = typeof initiative !== 'undefined' && typeof initiative.ticketLinkState !== 'undefined';

  return (
    <Box
      padding={'padding100'}
      borderRadius={'radius400'}
      boxShadow={'shadow100'}
    >
      <Header
        size={3}
        weight={'bold'}
        css={css`
          margin-bottom: 1rem;
        `}
      >
        PSA Ticket
      </Header>
      {/* Indicate to the user the changes that will happen surrounding the ticket */}
      {(() => {
        switch (ticketActionIntent) {
          case TicketActionIntent.Create:
            return (
              <div
                css={css`
                  display: flex;
                  gap: 1rem;
                  align-items: center;
                  margin-bottom: 1rem;
                `}
              >
                <Icon
                  icon={'Warning'}
                  color={'warning-500'}
                  sizePx={15}
                />
                <Label
                  size={'md'}
                  color={'warning-500'}
                >
                  You have a pending ticket creation. We will persist this after you click save changes.
                </Label>
              </div>
            );
          case TicketActionIntent.Unlink:
            return (
              <div
                css={css`
                  display: flex;
                  gap: 1rem;
                  align-items: center;
                  margin-bottom: 1rem;
                `}
              >
                <Icon
                  icon={'Warning'}
                  color={'warning-500'}
                  sizePx={15}
                />
                <Label
                  size={'md'}
                  color={'warning-500'}
                >
                  You have a pending removal of an existing ticket on this Initiative. We will persist this after you click save changes.
                </Label>
              </div>
            );
          case TicketActionIntent.UnlinkAndCreate:
            return (
              <div
                css={css`
                  display: flex;
                  gap: 1rem;
                  align-items: center;
                  margin-bottom: 1rem;
                `}
              >
                <Icon
                  icon={'Warning'}
                  color={'warning-500'}
                  sizePx={15}
                />
                <Label
                  size={'md'}
                  color={'warning-500'}
                >
                  We will remove the existing ticket and create a new one after you click save changes.
                </Label>
              </div>
            );
          default:
            return null;
        }
      })()}
      <div
        css={css`
          display: flex;
          gap: 1.5rem;
        `}
      >
        {typeof ticketActionIntent !== 'undefined' && (
          <Button
            iconLeft={'Undo'}
            onClick={() => {
              revertAllTicketActionIntents();
            }}
          >
            Revert All Changes
          </Button>
        )}
        {((!hasExistingTicketLink && !isCreatingTicket) || (hasExistingTicketLink && ticketActionIntent === TicketActionIntent.Unlink)) && (
          <InitiativeSaveTicketButton />
        )}
      </div>
      {typeof initiative !== 'undefined' &&
        typeof initiative.ticketLinkState !== 'undefined' &&
        typeof ticketActionIntent === 'undefined' && (
          <InitiativeSaveTicketSectionStates
            ticketLinkState={initiative?.ticketLinkState}
            onUnlinkTicket={() => {
              setFormInput<TicketCreateFieldValueDto[] | undefined>(ticketFieldValuesFieldKey, undefined);
              setFormInput<boolean>(shouldUnlinkTicketFieldKey, true);
            }}
          />
        )}
    </Box>
  );
};

export default InitiativeSaveTicketSection;
