import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import WarrantyCartCostLoader from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-footer/packages/warranty-cart-cost-loader/WarrantyCartCostLoader';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Button from '~/neo-ui/packages/button/Button';
import React, { useCallback } from 'react';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import { Enum as WarrantyType } from '@WarrantyClient/BeastClient/Goods/WarrantyType/WarrantyTypeFactoryNested.gen';
import routes from '~/router/types/routes';

export type WarrantyCartTotalCostSectionProps = {
  canCheckout: boolean;
};

const WarrantyCartTotalCostSection = ({ canCheckout }: WarrantyCartTotalCostSectionProps) => {
  const {
    totalCost,
    formatCostOutput,
    getSelectedAssetsCount,
    apiUpsertLoading,
    organizationId,
    warrantyType,
    getCurrency,
    continuousRenewalPolicyVisible,
  } = useWarrantyCartContext();

  const getCheckoutRoute = useCallback((): string => {
    switch (warrantyType) {
      case WarrantyType.InfrastructureProtection:
        return routes.ORGANIZATION_MARKETPLACE_CHECKOUT_INFRASTRUCTURE_PROTECTION.build([organizationId]);
      case WarrantyType.WorkstationAssurance:
        return routes.ORGANIZATION_MARKETPLACE_CHECKOUT_WORKSTATION_ASSURANCE.build([organizationId]);
      default:
        return routes.ORGANIZATION_WARRANTIES_CHECKOUT.build([organizationId]);
    }
  }, [warrantyType, organizationId]);

  return (
    <div
      css={css`
        padding: 1rem 1.5rem;
        background-color: ${colorToCode('light-000')};
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <div>
        <Header
          size={6}
          color={'dark-900-64'}
          css={css`
            margin-bottom: 0.5rem;
          `}
        >
          Your total cost
        </Header>
        <Header
          size={2}
          weight={'medium'}
          color={'positive-400'}
          css={css`
            margin-bottom: 0.75rem;
          `}
        >
          {formatCostOutput(totalCost)}

          {apiUpsertLoading && (
            <span
              css={css`
                display: inline-flex;
              `}
            >
              <WarrantyCartCostLoader color={'positive-400'} />
            </span>
          )}
        </Header>
        <Label muted={true}>
          {getCurrency() === 'USD'
            ? 'Amounts shown are in USD'
            : `All amounts shown are converted to ${getCurrency()} based on today\'s exchange rate. Final costs will be in USD.`}
        </Label>
        {continuousRenewalPolicyVisible && (
          <Label
            muted={true}
            css={css`
              margin-top: 0.5rem;
            `}
          >
            * Warranty coverage start date has been backdated to provide continuous coverage.
          </Label>
        )}
      </div>
      <div>
        <Button
          theme={'positive'}
          iconRight={'GoRight'}
          disabled={!getSelectedAssetsCount() || !canCheckout}
          anchor={{
            href: getCheckoutRoute(),
          }}
        >
          Checkout
        </Button>
      </div>
    </div>
  );
};

export default WarrantyCartTotalCostSection;
