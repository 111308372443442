import { css } from '@emotion/react';
import { mobileBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import VendorLogo from '~/wm/packages/integration/packages/scalepad-account/packages/add-integration-page/VendorLogo';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import { PropsWithChildren } from 'react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import {
  IntegrationDataTypeAndCountDto,
  IntegrationDetails,
} from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';

const SidebarContainer: React.FunctionComponent<PropsWithChildren> = ({ children }) => (
  // This exists just to hold the sidebar css
  <aside
    css={css`
      background-color: ${colorToCode('light-100')};
      flex: 1 1 15rem;
      max-width: 24rem;
      min-width: 14rem;
      @media (max-width: ${mobileBreakpointRem}rem) {
        // Hide the sidebar on mobile
        display: none;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      border-left: 0.0625rem solid ${colorToCode('dark-900-12')};
      padding: 1.5rem;
      gap: 1.5rem;
      margin-top: -2rem;
      margin-right: -1.5rem;
      height: max(95vh, auto);
    `}
  >
    {children}
  </aside>
);

type SidebarSectionHeaderProps = {
  infoLink?: string;
  children: React.ReactNode;
};

const SidebarSectionHeader: React.FunctionComponent<SidebarSectionHeaderProps> = ({ children, infoLink }) => (
  <hgroup
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    `}
  >
    <h3
      css={css`
        /* Cell Heading Medium */
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
      `}
    >
      {children}
    </h3>
    {infoLink && (
      <a
        css={css`
          display: flex;
          align-items: center;
          column-gap: 0.5rem;
        `}
        href={infoLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        Info
        <Icon
          icon={'GoExternal'}
          color={'primary-400'}
        />
      </a>
    )}
  </hgroup>
);

const SidebarDivider = () => (
  <hr
    css={css`
      align-self: stretch;
      height: 0.0625rem;
      color: ${colorToCode('dark-900-12')};
      margin: 0;
    `}
  />
);

const SidebarList: React.FunctionComponent<PropsWithChildren> = ({ children }) => (
  <ul
    css={css`
      list-style: none;
      margin: 0;
      padding: 0;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    `}
  >
    {children}
  </ul>
);

const SidebarListItem: React.FunctionComponent<{
  bulletIcon?: IconType;
  children: React.ReactNode;
}> = ({ bulletIcon, children }) => (
  <li
    css={css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
      flex: 1 0 0;
      padding: 0.25rem 0rem;
      align-self: stretch;
    `}
  >
    {bulletIcon !== undefined && <Icon icon={bulletIcon} />}
    {children}
  </li>
);

const SidebarSection: React.FunctionComponent<{
  headerText: string;
  infoLink?: string;
  children: React.ReactNode;
}> = ({ headerText, children, infoLink }) => (
  <section
    css={css`
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 0.5rem;
      width: auto;
      align-self: stretch;
    `}
  >
    <SidebarSectionHeader infoLink={infoLink}>{headerText}</SidebarSectionHeader>
    {children}
  </section>
);

const numberFormatter = Intl.NumberFormat();
const renderSupportedDataType = ({ dataTypeDto, count }: IntegrationDataTypeAndCountDto) => {
  const countString = count !== undefined ? numberFormatter.format(count) : undefined;

  return (
    <SidebarListItem
      key={dataTypeDto.enum}
      bulletIcon={dataTypeDto.icon as IconType}
    >
      {dataTypeDto.label}
      {count !== undefined && (
        <span
          css={css`
            margin-left: auto;
          `}
        >
          {countString}
        </span>
      )}
    </SidebarListItem>
  );
};

export type SidebarDisplayProps = {
  integrationDetails: IntegrationDetails;
  helpCenterLink: string | undefined;
};
export const SidebarDisplay: React.FunctionComponent<SidebarDisplayProps> = ({ integrationDetails, helpCenterLink }) => (
  <SidebarContainer>
    <VendorLogo logoSlug={integrationDetails.svgToken} />
    <SidebarDivider />

    <SidebarSection
      headerText={'Available data'}
      infoLink={helpCenterLink}
    >
      <SidebarList>
        {integrationDetails.dataTypesAndCounts
          .sort((a, b) => a.dataTypeDto.displayOrder - b.dataTypeDto.displayOrder)
          .map(renderSupportedDataType)}
      </SidebarList>
    </SidebarSection>

    <SidebarDivider />

    <SidebarSection headerText={'Type of integration'}>
      <SidebarList>
        <SidebarListItem bulletIcon={'Integration'}>{integrationDetails.toolTypeLabel}</SidebarListItem>
      </SidebarList>
    </SidebarSection>

    <SidebarDivider />

    <SidebarSection
      headerText={'Data available to:'}
      infoLink={helpCenterLink}
    >
      <SidebarList>
        {integrationDetails.products.map(({ icon, label }) => (
          <SidebarListItem key={label}>
            <img
              css={css`
                height: 1rem;
              `}
              src={icon}
              alt={label}
            />
          </SidebarListItem>
        ))}
      </SidebarList>
    </SidebarSection>
  </SidebarContainer>
);
