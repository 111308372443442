import { BillingAddressDto } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import { Form, Formik, FormikConsumer } from 'formik';
import { ComponentPropsWithoutRef, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { getCountries, getPostalZipCodeRegex, getSubdivisions } from '~/neo-ui/model/Country';
import Button from '~/sp-ui/buttons/Button';
import { DropdownFormik as Dropdown } from '~/sp-ui/inputs/dropdown';
import { TextFieldFormik as TextField } from '~/sp-ui/inputs/textfield';
import Message from '~/sp-ui/message';
import { BillingAddressFieldErrors, UseBillingAddress } from '../hooks/useBillingAddress';
import ReadonlyBillingAddress from './ui/ReadonlyBillingAddress';
import SectionLoadingIndicator from './ui/SectionLoadingIndicator';

const billingAddressSchema = Yup.object().shape({
  companyName: Yup.string().max(50, 'Must be 50 characters or less').required('Business name cannot be empty.'),
  addressLine1: Yup.string().required('Address cannot be empty.'),
  addressLine2: Yup.string().ensure(),
  city: Yup.string().max(50, 'Must be 50 characters or less').required('City cannot be empty.'),
  postalZipCode: Yup.string().when('countryCode', (countryCode, schema: Yup.StringSchema) => {
    const regex = typeof countryCode === 'string' && getPostalZipCodeRegex(countryCode);

    if (regex) {
      return schema.required('Required').matches(regex, 'Invalid postal/zip code');
    }

    return schema.required('Required');
  }),
  provinceStateCode: Yup.string().when('countryCode', {
    is: countryCode => typeof countryCode === 'string' && getSubdivisions({ countryCode }).length > 0,
    then: Yup.string().required('Required'),
  }),
  countryCode: Yup.string().required('Country cannot be empty.'),
  phoneNumber: Yup.string().ensure(),
});

const adaptFieldErrorsToForm = (fieldErrors: BillingAddressFieldErrors) => {
  return {
    addressLine1: fieldErrors['Address.Line1'],
    city: fieldErrors['Address.City'],
    postalZipCode: fieldErrors['Address.Zip'],
    provinceStateCode: fieldErrors['Address.StateCode'],
    countryCode: fieldErrors['Address.CountryCode'],
  };
};

export interface BillingSectionProps extends Omit<ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  isEditing: boolean;
  onSubmit: (billingAddress: BillingAddressDto) => Promise<void> | void;
  billingAddressApi: UseBillingAddress;
}

const BillingSection = (props: BillingSectionProps) => {
  const { isEditing, onSubmit, billingAddressApi, ...formProps } = props;
  const { billingAddress, isLoading, error: billingAddressError, fieldErrors } = billingAddressApi;
  const hasLoadedRef = useRef(false);

  useEffect(() => {
    if (hasLoadedRef.current) {
      return undefined;
    }

    if (!isLoading) {
      hasLoadedRef.current = true;
    }
  }, [isLoading]);

  if (isLoading && !hasLoadedRef.current) {
    return <SectionLoadingIndicator />;
  }

  if (!isEditing) {
    return (
      <ReadonlyBillingAddress
        billingAddress={billingAddress}
        css={{ paddingInline: '48px' }}
      />
    );
  }

  return (
    <Formik
      initialValues={billingAddress}
      initialErrors={adaptFieldErrorsToForm(fieldErrors)}
      validationSchema={billingAddressSchema}
      enableReinitialize={true}
      onSubmit={async (values, formik) => {
        onSubmit(values);
        formik.setSubmitting(false);
      }}
    >
      <Form
        css={{ width: '100%', display: 'flex', flexDirection: 'column', paddingInlineStart: '48px', paddingInlineEnd: '2px', gap: 16 }}
        {...formProps}
      >
        <TextField
          name="companyName"
          label="Business Name"
          compact={true}
        />

        <div css={{ marginBottom: 16, display: 'flex', flexDirection: 'column', gap: 8 }}>
          <TextField
            placeholder="Address Line 1"
            name="addressLine1"
            label="Address"
            compact={true}
          />

          <TextField
            placeholder="Address Line 2"
            name="addressLine2"
            compact={true}
          />
        </div>

        <TextField
          name="city"
          label="City"
          css={{ width: '50%' }}
          compact={true}
        />

        <FormikConsumer>
          {({ values }) => {
            const formValues = values as typeof billingAddress;
            const provinceStates = !!formValues.countryCode ? getSubdivisions({ countryCode: formValues.countryCode }) : [];
            const label = formValues.countryCode === 'CA' ? 'Province' : formValues.countryCode === 'US' ? 'State' : 'Region';

            return (
              <>
                <Dropdown
                  name="provinceStateCode"
                  placeholder={!!formValues.countryCode ? 'Select a region...' : 'Please select a country'}
                  label={label}
                  options={provinceStates.map(({ name, subdivisionCode }) => {
                    return {
                      label: name,
                      value: subdivisionCode,
                    };
                  })}
                  css={{ width: '50%' }}
                  compact={true}
                />

                <TextField
                  name="postalZipCode"
                  label={formValues.countryCode === 'CA' ? 'Postal Code' : 'Zip Code'}
                  css={{ width: '25%' }}
                  compact={true}
                />
              </>
            );
          }}
        </FormikConsumer>

        <Dropdown
          placeholder="Select a country..."
          name="countryCode"
          label="Country"
          options={getCountries()
            .sort((a, b) => {
              const topCountries = ['US', 'CA'];

              if (topCountries.includes(a.alpha2) && !topCountries.includes(b.alpha2)) {
                return -1;
              } else if (!topCountries.includes(a.alpha2) && topCountries.includes(b.alpha2)) {
                return 1;
              } else if (topCountries.includes(a.alpha2) && topCountries.includes(b.alpha2)) {
                return a.alpha2.localeCompare(b.alpha2);
              }

              return a.name.localeCompare(b.name);
            })
            .map(({ name, alpha2 }) => ({
              label: name,
              value: alpha2,
            }))}
          onChange={(_, formik) => {
            formik.setFieldValue('provinceStateCode', '', false);
            formik.setFieldValue('postalZipCode', '', false);
          }}
          css={{ maxWidth: '320px' }}
          compact={true}
        />

        {billingAddressError && (
          <Message
            css={{ marginTop: 16 }}
            text={billingAddressError}
            type="error"
          />
        )}

        <Button
          type="submit"
          size="large"
          loading={isLoading}
          disabled={isLoading}
          css={{ alignSelf: 'start', marginTop: 16 }}
        >
          Save and continue
        </Button>
      </Form>
    </Formik>
  );
};

export default BillingSection;
