import Label from '~/neo-ui/packages/text/packages/label/Label';
import SelectList from '~/neo-ui/packages/list/packages/select-list/SelectList';
import * as React from 'react';
import SelectOptionGroup from '~/neo-ui/packages/select/model/SelectOptionGroup';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { css } from '@emotion/react';

export type DownloadMenuSectionProps<DownloadAction extends string> = {
  heading: string;
  description: string;
  assetDownloadOptions: SelectOptionGroup<DownloadAction>[];
  onOptionSelected: (option: SelectOption<DownloadAction>) => void;
};

const DownloadMenuSection = <DownloadAction extends string>({
  heading,
  description,
  assetDownloadOptions,
  onOptionSelected,
}: DownloadMenuSectionProps<DownloadAction>) => (
  <div>
    <div
      css={css`
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      `}
    >
      <Label
        size={'lg'}
        bold={true}
      >
        {heading}
      </Label>
      <Label
        muted={true}
        css={css`
          margin-bottom: 10px;
        `}
      >
        {description}
      </Label>
    </div>
    <SelectList
      options={assetDownloadOptions}
      selectedOptionValue={undefined}
      bgColor={'secondary-050'}
      horizontalDividers={false}
      boldOptions={true}
      optionPadding={'0.625rem'}
      onOptionSelected={onOptionSelected as (option: SelectOption) => void}
      isHorizontal={true}
      usesInversion={true}
    />
  </div>
);
export default DownloadMenuSection;
