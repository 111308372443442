import { scorecardScorecardItemCreate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useScorecardMutation, { UseScorecardMutationOptions } from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardMutation';

export type UseScorecardItemCreate = {
  createScorecardItem: (createPayload: { scorecardId: string; scorecardCategoryId?: string }) => void;
};

const useScorecardItemCreate = (options?: UseScorecardMutationOptions): UseScorecardItemCreate => {
  const [createScorecardItem] = useScorecardMutation(
    (createPayload: { scorecardId: string; scorecardCategoryId?: string }) =>
      scorecardScorecardItemCreate({
        scorecardId: createPayload.scorecardId,
        scorecardCategoryId: createPayload.scorecardCategoryId,
      }),
    options,
  );

  return {
    createScorecardItem,
  };
};

export default useScorecardItemCreate;
