import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ListItemProps = {
  title: string;
  titleMuted?: boolean;

  description?: string;
  descriptionMuted?: boolean;

  size?: 'sm' | 'md';

  icon?: IconType;

  /**
   * This can be any color, but the idea is to represent the theme of the item.
   */
  theme?: Color;
} & Styleable;

/**
 * @deprecated it is more common to use flex box now
 */
const ListItem: React.FunctionComponent<React.PropsWithChildren<ListItemProps>> = ({
  title,
  titleMuted = false,
  description,
  descriptionMuted = false,
  size = 'md',
  icon,
  theme,
  className,
  children,
}) => (
  <div
    className={className}
    css={css`
      ${theme ? `color: ${colorToCode(theme)};` : ''}
    `}
  >
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      {icon && (
        <Icon
          icon={icon}
          color={theme ?? 'dark-900'}
          {...(size === 'md' ? { sizePx: 22 } : {})}
          css={css`
            margin-right: 0.8125rem;
            flex-shrink: 0;
          `}
        />
      )}
      <div>
        <Label bold={!titleMuted}>{title}</Label>
        {description && (
          <Label
            size={size}
            muted={descriptionMuted}
          >
            {description}
          </Label>
        )}
      </div>
    </div>
    {children && <div>{children}</div>}
  </div>
);

export default ListItem;
