import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { css } from '@emotion/react';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import React from 'react';
import Theme from '~/neo-ui/packages/color/Theme';
import Image from '~/neo-ui/packages/image/Image';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';

type MultiSelectStaticOptionProps<T extends string> = {
  option: SelectOption<T>;
  onSelectOption: (item: T) => void;
  onDeselectOption: (item: T) => void;
  isSelected: boolean;
  icon?: IconType;
  legacyIconUrl?: string;
  theme?: Theme;

  // Show a checkmark neo icon instead of a checked box
  displaysCheckmarkIcon?: boolean;
};

const MultiSelectStaticOption = <T extends string>({
  option,
  onSelectOption,
  onDeselectOption,
  isSelected,
  icon,
  legacyIconUrl,
  theme,
  displaysCheckmarkIcon,
}: MultiSelectStaticOptionProps<T>) => {
  const { themeMap } = useTheme(theme);

  const handleOptionSelection = (
    selectedOption: SelectOption<T>,
    isSelected: boolean,
    onSelectOption: (item: T) => void,
    onDeselectOption: (item: T) => void,
  ) => (isSelected ? onDeselectOption(selectedOption.value) : onSelectOption(selectedOption.value));

  return (
    <div
      onClick={() => handleOptionSelection(option, isSelected, onSelectOption, onDeselectOption)}
      css={css`
        padding: 0.625rem;
        display: flex;
        flex-direction: row;
        display: flex;
        align-items: center;
        background-color: ${isSelected && colorToCode(typeof theme === 'undefined' ? 'secondary-100' : themeMap.backgroundAccent)};
        &:hover {
          background-color: ${colorToCode(typeof theme === 'undefined' ? 'light-050' : themeMap.backgroundRaised)};
          cursor: pointer;
        }
      `}
    >
      {!displaysCheckmarkIcon && (
        <InputCheckbox
          checked={isSelected}
          onClick={() => handleOptionSelection(option, isSelected, onSelectOption, onDeselectOption)}
        />
      )}
      {icon && (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            padding-inline-start: 0.313rem;
            padding-inline-end: 0.625rem;
          `}
        >
          <Icon icon={icon} />
        </div>
      )}
      {/* TODO refactor to use the branding icon once created*/}
      {legacyIconUrl && (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            padding-inline-start: 0.313rem;
            padding-inline-end: 0.625rem;
          `}
        >
          <Image source={legacyIconUrl} />
        </div>
      )}
      <Label
        heightSize={'md'}
        css={css`
          line-height: 1.75rem;
        `}
      >
        {option.label}
      </Label>
      {displaysCheckmarkIcon && isSelected && (
        <Icon
          icon={'Positive'}
          color={themeMap.foregroundAccent}
          sizePx={16}
          css={css`
            margin-left: auto;
          `}
        />
      )}
    </div>
  );
};

export default MultiSelectStaticOption;
