import { css } from '@emotion/react';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import React from 'react';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import Theme from '~/neo-ui/packages/color/Theme';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';

type SelectAllCheckboxProps<T extends string> = {
  options: SelectOption<T>[];
  selectedOptions: Set<T>;
  selectOptions: (items: T[], itemClicked: T | undefined) => void;
  clearOptions: () => void;
  theme?: Theme;
};

const SelectAllCheckbox = <T extends string>({
  options,
  selectedOptions,
  selectOptions,
  clearOptions,
  theme,
}: SelectAllCheckboxProps<T>) => {
  const { themeMap } = useTheme(theme);
  const [isSelectAll, setSelectAll] = React.useState(false);

  const toggleSelectAll = () => {
    if (options.length === 0) {
      return;
    }
    if (selectedOptions.size > 0) {
      clearOptions();
      setSelectAll(false);
    } else {
      selectOptions(
        options.map(opt => opt.value),
        undefined,
      );
      setSelectAll(true);
    }
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
      `}
    >
      <InputCheckbox
        checked={isSelectAll}
        onClick={toggleSelectAll}
      />
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          padding-inline-start: 0.313rem;
          padding-inline-end: 0.625rem;
        `}
      >
        <Icon
          icon={'SelectAll'}
          color={themeMap.foregroundAccent}
        />
      </div>
      <Label
        heightSize={'md'}
        color={themeMap.foregroundAccent}
      >
        Select all
      </Label>
    </div>
  );
};

export default SelectAllCheckbox;
