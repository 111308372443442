import { DmiScoreDto } from '@AssetManagementClient/AssetManagement/Packages/Dmi/Model.gen';
import { css } from '@emotion/react';
import { parseISO } from 'date-fns';
import React from 'react';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Testable from '~/neo-ui/packages/testable/Testable';
import DmiInfo from '~/wm/packages/strategy/packages/dmi/packages/dmi-info/DmiInfo';
import DmiScore from '~/wm/packages/strategy/packages/dmi/packages/dmi-score/DmiScore';
import Color from '~/neo-ui/packages/color/Color.gen';

export type DmiContainerProps = {
  score: DmiScoreDto | undefined;
  guideUrl: string;
};

const DmiContainer: React.FunctionComponent<DmiContainerProps> = ({ score, guideUrl }) => {
  if (typeof score === 'undefined') {
    return <div />;
  }

  const guideAnchor = {
    href: guideUrl,
    openInNewTab: true,
  };

  return (
    <Testable testId={'DMI-container'}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <Testable testId={'DMI-score'}>
          <DmiScore
            dmiScore={score.scoreOn10000}
            displayDmiScore={score.scoreDisplay}
            maxDmiScore={10000}
            themeColor={score.band.color as Color}
            bandLabel={score.band.label}
            lastUpdated={score.updatedAt !== undefined ? parseISO(score.updatedAt) : undefined}
            size={200}
          />
        </Testable>
        <Testable testId={'DMI-info'}>
          <DmiInfo
            description={score.band.description?.text}
            icon={score.band.description?.icon as IconType}
            iconColor={score.band.color as Color}
          />
        </Testable>
        <div
          css={css`
            display: flex;
            margin-top: 0.625rem;
            align-self: flex-start;
          `}
        >
          <ButtonLink
            iconRight={'GoRight'}
            anchor={guideAnchor}
            size={'sm'}
          >
            Learn more about DMI
          </ButtonLink>
        </div>
      </div>
    </Testable>
  );
};

export default DmiContainer;
