import { createContext } from 'react';
import { EngagementNoteDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Dto/Model.gen';
import {
  EngagementNoteStatusAvailabilityDto,
  EngagementNoteStatusCountDto,
} from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Packages/EngagementNoteStatus/Dto/Model.gen';

export type EngagementNoteListPanelTabContextData = {
  notes: EngagementNoteDto[];
  statusAvailabilities: EngagementNoteStatusAvailabilityDto[];
  statusCounts: EngagementNoteStatusCountDto[];
  hasNextPage: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const EngagementNoteListPanelTabContext = createContext<EngagementNoteListPanelTabContextData | undefined>(undefined);

export default EngagementNoteListPanelTabContext;
