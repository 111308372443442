import { css } from '@emotion/react';
import * as React from 'react';
import { ChangeEventHandler } from 'react';
import FormInputTextInternal from '~/neo-ui/packages/form/packages/form-input/FormInputTextInternal';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import useFormInputBuilder from '~/neo-ui/packages/form/packages/form-input/hooks/useFormInputBuilder';
import useThemeInputTitle from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/hooks/useThemeInputTitle';
import useStylesInputTitle from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/hooks/useStylesInputTitle';
import { InputTitleSizes, inputTitleSizeToInputTitleDisplayDetails } from '~/neo-ui/packages/input/packages/input-title/InputTitle';

export type FormInputDisplayProps<TFormDataValue = string> = {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  prependCharacter?: string;
  min?: number;
  maxLength?: number;
  disabled?: boolean;
  placeholder?: string;
  size?: InputTitleSizes;
  propsForHtmlInputComponent?: Exclude<React.ComponentProps<'input'>, 'type'>;
  type: 'text' | 'number' | 'password';
} & Themeable;

export const FormTextboxDisplay: React.FunctionComponent<FormInputDisplayProps> = ({
  prependCharacter,
  min,
  maxLength,
  disabled,
  placeholder,
  size = 'md',
  propsForHtmlInputComponent,
  theme,
  type,
}) => {
  const displayDetails = inputTitleSizeToInputTitleDisplayDetails(size);
  const { themeMap } = useThemeInputTitle(theme);
  const { stylesInputTitle } = useStylesInputTitle(themeMap, displayDetails);

  return (
    <div
      css={css`
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
      `}
    >
      {prependCharacter && (
        <span
          css={css`
            padding-left: ${displayDetails.paddingLeftRemIcon}rem;
            padding-right: ${displayDetails.paddingRightRemIcon}rem;
            font-size: ${displayDetails.fontSizeRem}rem;
            color: ${colorToCode(disabled ? themeMap.iconColorDisabled : themeMap.iconColor)};
            display: flex;
            background-color: transparent;
            border: none;
            margin-right: -2rem;
            z-index: 4;
          `}
        >
          {prependCharacter}
        </span>
      )}
      <input
        min={min}
        maxLength={maxLength}
        disabled={disabled}
        css={[
          stylesInputTitle,
          css`
            padding-left: ${prependCharacter ? '2.25rem' : '0.625rem'};

            // Override padding to account for extra in the input
            padding-left: ${prependCharacter && `2rem`};
          `,
        ]}
        type={type}
        {...propsForHtmlInputComponent}
        role={'textbox'}
        placeholder={placeholder}
      />
    </div>
  );
};

export type FormTextboxInputProps<T, TFormDataValue = string> = {
  fieldKey: FieldKeyExpression<T>;
  type: 'text' | 'number' | 'password';
  /**
   * For performance reasons, only propagate updates after onBlur.
   * This speeds up large forms by avoiding renders on each keystroke.
   */
  optimizePerformance?: boolean;
  placeholder?: string;
  prependCharacter?: string;
  disabled?: boolean;
  onChange?: (value: TFormDataValue) => void;
  toFormData?: (inputValue: string) => TFormDataValue;
  isInvalid?: boolean;
  showFormError?: boolean;
  fixedErrorPosition?: boolean;
  /**
   * Minimum number of characters allowed in the input.
   */
  min?: number;
  /**
   * Maximum number of characters allowed in the input.
   */
  maxLength?: number;
  size?: InputTitleSizes;
} & Styleable &
  Themeable;

const FormTextboxInput = <T,>({
  fieldKey,
  type,
  optimizePerformance = false,
  placeholder,
  prependCharacter,
  disabled = false,
  onChange,
  toFormData,
  min,
  maxLength,
  size = 'md',
  theme,
  isInvalid = false,
  showFormError = true,
  fixedErrorPosition = false,
  className,
}: FormTextboxInputProps<T>) => {
  const { setFieldValue, touched, error } = useFormInputBuilder<T>(fieldKey);

  // Invalid state uses negative styles
  const hasFormInputValidationError = typeof error !== 'undefined' && touched;

  return (
    <FormInputTextInternal
      fieldKey={fieldKey}
      optimizePerformance={optimizePerformance}
      className={className}
      showError={showFormError}
      fixedErrorPosition={fixedErrorPosition}
    >
      {({ field }) => (
        <FormTextboxDisplay
          onChange={e => {
            const value = toFormData ? toFormData(e.target.value) : e.target.value;

            setFieldValue(value);
            if (typeof onChange !== 'undefined') {
              onChange(value);
            }
          }}
          prependCharacter={prependCharacter}
          min={min}
          maxLength={maxLength}
          disabled={disabled}
          placeholder={placeholder}
          size={size}
          propsForHtmlInputComponent={field}
          theme={isInvalid || hasFormInputValidationError ? 'negative' : theme}
          type={type}
        />
      )}
    </FormInputTextInternal>
  );
};

export default FormTextboxInput;
