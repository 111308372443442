import { Styleable } from '~/neo-ui/model/capacity';
import Card from '~/legacy-ui/packages/card/Card';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { css } from '@emotion/react';
import * as React from 'react';
import PeopleDashboardDropdownAccountTeamMember from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/packages/people-dashboard-dropdown/packages/people-dashboard-dropdown-account-team-member/PeopleDashboardDropdownAccountTeamMember';
import PeopleDashboardDropdownOrganizationKeyContact from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/packages/people-dashboard-dropdown/packages/people-dashboard-dropdown-organization-key-contact/PeopleDashboardDropdownOrganizationKeyContact';

export type PeopleDashboardProps = {
  peopleConsoleUrl: string;
  membersUrl: string;
  hasManageMembersPermission: boolean;
} & Styleable;

const PeopleDashboard: React.FunctionComponent<PeopleDashboardProps> = ({
  peopleConsoleUrl,
  membersUrl,
  hasManageMembersPermission,
  className,
}) => (
  <Card
    className={className}
    backgroundColor={'light-000'}
    shadow={'none'}
    padding={'lg'}
    css={css`
      margin-bottom: 1rem;
    `}
  >
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <Header
        size={4}
        weight={'bold'}
      >
        People
      </Header>

      <PeopleDashboardDropdownAccountTeamMember
        hasManageMembersPermission={hasManageMembersPermission}
        membersUrl={membersUrl}
      />

      <PeopleDashboardDropdownOrganizationKeyContact peopleConsoleUrl={peopleConsoleUrl} />
    </div>
  </Card>
);

export default PeopleDashboard;
