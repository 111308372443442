import 'core-js/stable'; // React Habitat requires Object.assign polyfill for old IE support
import 'regenerator-runtime/runtime';
import '../wm/style/App.css';
import { enableMapSet } from 'immer';
import * as ReactHabitat from 'react-habitat';
import { configureStore } from '~/extensions/packages/state/configureStore';
import rootSaga from '~/wm/io/rootSaga';
import rootReducer from '~/wm/state/rootReducer';
import { ReduxDomFactory } from 'react-habitat-redux';
import * as React from 'react';
import ExportedComponentDefinition from '~/wm-legacy/model/ExportedComponentDefinition';
import ToastProvider from '~/neo-ui/packages/toast/context/ToastProvider';
import { init } from '@sentry/react';
import * as Sentry from '@sentry/browser';
import featureFlagProvider from '~/router/feature-flag-provider/featureFlagProvider';

// Immer configuration
// Specified at runtime initialization
// See {c1e37037-370e-4734-99a4-145518fec78d}
enableMapSet();

declare const window: {
  habitat: { update: (node?: Element | undefined) => void };
};

// This wraps a component, providing an inner HTML prop
// This applies HTML without escaping, hence the danger.
// Should only be used when necessary, for HTML/JS-React interop
type PropsWithInnerHtml<T extends Record<string, unknown>> = T & {
  innerHtml: string;
};
export const provideInnerHtml =
  <T extends Record<string, unknown>>(Component: (props: React.PropsWithChildren<T>) => React.ReactElement) =>
  (props: PropsWithInnerHtml<T>) =>
    Component({
      ...props,
      children: (
        <div
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line @typescript-eslint/naming-convention
            __html: props.innerHtml,
          }}
        />
      ),
    });

const store = configureStore(rootReducer, rootSaga);

// Aggregates the function from all registrars
const updateHabitatFunctions: ((node?: Element) => void)[] = [];

// Retriggers substitution of all components
const updateHabitat = (node?: Element) => {
  for (const updateHabitat of updateHabitatFunctions) {
    updateHabitat(node);
  }
};
if (!window.habitat) {
  window.habitat = {
    update: updateHabitat,
  };
} else {
  // Add this registrar to the update command
  window.habitat.update = updateHabitat;
}

// React Habitat container to export our components to the non-react world
export class ComponentRegistrar extends ReactHabitat.Bootstrapper {
  constructor(components: ExportedComponentDefinition[]) {
    super();

    // Configure Sentry
    init({
      environment: WM.sentryEnvironment,
      release: WM.sentryRelease,
      dsn: SENTRY_DSN,
      integrations: [],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0,
      autoSessionTracking: false,
    });
    Sentry.getCurrentScope().setTags({ ['bundler']: 'webpack' });

    // Create a new container builder
    const containerBuilder: ReactHabitat.IContainerBuilder = new ReactHabitat.ContainerBuilder();

    // Wrap each React component with a style reset
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const registerComponent = (Component: any): ReactHabitat.IRegistration =>
      containerBuilder.register((props: Record<string, unknown>) => (
        <ToastProvider>
          <Component {...props} />
        </ToastProvider>
      ));

    for (const { uniqueId, component, featureFlagRequired } of components) {
      registerComponent(
        featureFlagProvider({
          reactLaunchDarklyEnabledForComponent: featureFlagRequired,
          allowStreaming: false,
          wrappedComponent: component,
        }),
      ).as(uniqueId);
    }

    // Configure Redux store
    containerBuilder.factory = new ReduxDomFactory(store);

    // Set the DOM container
    this.setContainer(containerBuilder.build());

    updateHabitatFunctions.push(this.update.bind(this));
  }
}
