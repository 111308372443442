/* eslint-disable @typescript-eslint/naming-convention */
import { Discriminant } from '@SubscriptionClient/Primitives/Results/SetupCustomerPaymentControllerNested/Response_/PublicError_/ResultNested.gen';
import { BillingAddressDto } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import { subscriptionBillingCustomerPaymentSetup } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useEffect, useRef, useState } from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Accordion from '~/sp-ui/accordion';
import Text from '~/sp-ui/text';
import useBillingAddress from '../hooks/useBillingAddress';
import useCreditCardDetails from '../hooks/useCreditCardDetails';
import BillingSection from './BillingSection';
import ConfirmationPage from './ConfirmationPage';
import PaymentMethodSection from './PaymentMethodSection';
import PaymentSummarySection from './PaymentSummarySection';
import FloatingSideBox from './ui/FloatingSideBox';
import SectionHeader from './ui/SectionHeader';
import useQuoteSummaryApi from './useQuoteSummaryApi';

export interface QuotesProps {
  quoteId: string;
}

export default function Quotes({ quoteId }: QuotesProps) {
  const creditCardDetailsApi = useCreditCardDetails();
  const sectionHeaderRefs = useRef<Partial<Record<keyof typeof accordionState, HTMLDivElement | null>>>({});
  const quoteSummaryApi = useQuoteSummaryApi(quoteId);
  const billingAddressApi = useBillingAddress();

  const [accordionState, setAccordionState] = useState({
    billing: true,
    paymentMethod: true,
    paymentSummary: true,
  });

  const [editingStates, setEditingStates] = useState({
    billing: false,
    paymentMethod: false,
    paymentSummary: true,
  });

  const [hasPaid, setHasPaid] = useState(false);
  const hasLoaded = useRef(!!quoteSummaryApi.quoteSummary);

  useEffect(() => {
    if (hasLoaded.current || !quoteSummaryApi.quoteSummary) {
      return;
    }

    hasLoaded.current = true;
    sectionHeaderRefs.current.paymentSummary?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [quoteSummaryApi.quoteSummary]);

  const editSection = (section: keyof typeof editingStates) => () =>
    setEditingStates(previous =>
      Object.keys(previous).reduce(
        (next, key) => ({
          ...next,
          [key]: false,
          [section]: true,
        }),
        previous,
      ),
    );

  const moveToNextSection = () => {
    if (editingStates.billing) {
      if (!creditCardDetailsApi.isLoading && creditCardDetailsApi.creditCardResponse?.cardDetails) {
        editSection('paymentSummary')();
        sectionHeaderRefs.current.paymentSummary?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        return;
      }

      editSection('paymentMethod')();
      sectionHeaderRefs.current.paymentMethod?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    if (editingStates.paymentMethod) {
      editSection('paymentSummary')();
      sectionHeaderRefs.current.paymentSummary?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const saveBillingAddress = async (payload: BillingAddressDto) => {
    const { updateBillingAddress, billingAddress } = billingAddressApi;
    const { refreshData } = quoteSummaryApi;

    if (payload === billingAddress) {
      // if the payload is exactly the same no need to update
      moveToNextSection();
      return;
    }

    const updatedBillingAddress = await updateBillingAddress({
      name: payload.companyName,
      address: {
        city: payload.city,
        countryCode: payload.countryCode,
        countryFallback: payload.countryCode,
        line1: payload.addressLine1,
        line2: payload.addressLine2 ?? '',
        stateCode: payload.provinceStateCode,
        stateFallback: payload.provinceStateCode,
        zip: payload.postalZipCode,
      },
    });

    if (!updatedBillingAddress) {
      // if no billing address was returned, then assume an error occurred
      return;
    }

    await refreshData();
    moveToNextSection();
  };

  const savePaymentMethod = async (
    elements: StripeElements,
    stripe: Stripe,
    clientSecret: string,
    setFormSubmissionError: (x: string | undefined) => void,
  ) => {
    const validation = await elements.submit();
    if (validation.error) {
      setFormSubmissionError(validation.error.message);
      return;
    }

    const cardElement = elements.getElement('cardNumber');

    if (!cardElement) {
      setFormSubmissionError(undefined);
      return;
    }

    // the cvc and expiry information should get _automagically_ picked up here according to
    // https://stackoverflow.com/questions/62171886/how-should-i-pass-cardnumberelement-cardexpiryelement-and-cardcvcelement
    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (result.error) {
      setFormSubmissionError(result.error.message);
      return;
    }

    const paymentSetup = await subscriptionBillingCustomerPaymentSetup({ setupIntent: result.setupIntent.id });

    if (!paymentSetup || paymentSetup.case === Discriminant.Error) {
      setFormSubmissionError(
        paymentSetup.value.messagePublic ||
          'There were errors in your payment method information. Please check the indicated fields and try again.',
      );

      return;
    }

    const { refresh } = creditCardDetailsApi;

    setFormSubmissionError(undefined);
    await refresh();
    moveToNextSection();
  };

  const onPayNow = async () => {
    if (!billingAddressApi.billingAddress.addressLine1) {
      editSection('billing')();
      sectionHeaderRefs.current.billing?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      return;
    }

    const { acceptQuote } = quoteSummaryApi;
    await acceptQuote({ onSuccess: () => setHasPaid(true) });
  };

  const { error, isLoading: isQuoteSummaryLoading, quoteSummary } = quoteSummaryApi;

  if (isQuoteSummaryLoading && !quoteSummary) {
    return null;
  }

  if (!isQuoteSummaryLoading && !!error) {
    const url = new URL(window.location.toString());
    url.pathname = url.pathname + '/quote-not-found';
    window.history.replaceState(null, '', url.toString());
    window.location.reload();
    return null;
  }

  if (hasPaid && !isQuoteSummaryLoading) {
    return <ConfirmationPage />;
  }

  return (
    <div
      className="outer"
      css={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div css={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: '32px' }}>
        <div css={{ gridColumn: '1 / -1', display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <Text
            element="a"
            css={{
              color: '#0575CC',
              textDecoration: 'underline',
              cursor: 'pointer',
              display: 'inline-flex',
              alignItems: 'center',
            }}
            href={document.referrer}
          >
            <Icon
              icon="ArrowLeft"
              color="primary-400"
              css={{ width: 24, height: 24 }}
            />
            Back
          </Text>
          <Text
            element="h1"
            variant="h1"
            css={{ fontStyle: 'normal', fontWeight: 400 }}
          >
            Checkout
          </Text>
        </div>

        <Accordion
          items={accordionState}
          css={{ width: '728px' }}
          onToggle={(itemId, open) =>
            setAccordionState(previous => ({ ...previous, [itemId]: open ?? !previous[itemId as keyof typeof accordionState] }))
          }
        >
          <Accordion.Item
            id="billing"
            header={
              <SectionHeader
                ref={el => (sectionHeaderRefs.current.billing = el)}
                title="Billing Address"
                isEditing={editingStates.billing}
                onEdit={editSection('billing')}
                stepNumber={1}
              />
            }
            disableTrigger={true}
          >
            <BillingSection
              isEditing={editingStates.billing}
              onSubmit={saveBillingAddress}
              billingAddressApi={billingAddressApi}
            />
          </Accordion.Item>

          <Accordion.Item
            id="paymentMethod"
            header={
              <SectionHeader
                ref={el => (sectionHeaderRefs.current.paymentMethod = el)}
                title="Payment Method"
                isEditing={editingStates.paymentMethod}
                onEdit={editSection('paymentMethod')}
                stepNumber={2}
              />
            }
            disableTrigger={true}
          >
            <PaymentMethodSection
              creditCardDetails={creditCardDetailsApi}
              isEditing={editingStates.paymentMethod}
              onSubmit={savePaymentMethod}
            />
          </Accordion.Item>

          <Accordion.Item
            id="paymentSummary"
            header={
              <SectionHeader
                ref={el => (sectionHeaderRefs.current.paymentSummary = el)}
                title={editingStates.paymentSummary ? 'Review subscription and pay' : 'Review subscription'}
                isEditing={editingStates.paymentSummary}
                stepNumber={3}
              />
            }
            disableTrigger={true}
          >
            <PaymentSummarySection
              isEditing={editingStates.paymentSummary}
              onPayNow={onPayNow}
              quoteSummaryApi={quoteSummaryApi}
              canPayNow={!!billingAddressApi.billingAddress.addressLine1}
            />
          </Accordion.Item>
        </Accordion>

        <FloatingSideBox
          editingStates={editingStates}
          onNextClicked={moveToNextSection}
          onPayNowClicked={onPayNow}
          quoteSummaryApi={quoteSummaryApi}
          canPayNow={!!billingAddressApi.billingAddress.addressLine1}
        />
      </div>
    </div>
  );
}
