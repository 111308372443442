import React from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import Text from '~/sp-ui/text/Text';

export interface AccountNavLinkDto {
  Label: string;
  Sublabel: string;
  Icon: string;
  Link: string;
  IsProductIcon?: boolean;
}

interface QuickLinksProps {
  accountNavLinkDtos: AccountNavLinkDto[];
}

const QuickLinks: React.FunctionComponent<QuickLinksProps> = ({ accountNavLinkDtos }) => {
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);
  return (
    <React.Fragment>
      <Text
        variant="h3"
        css={css`
          grid-column: 1 / -1;
          font-weight: 500;
        `}
      >
        Your products & shortcuts
      </Text>
      <div
        css={css`
          display: grid;
          grid-template-columns: subgrid;
          grid-column: 1 / -1;
          margin-bottom: 1rem;
        `}
      >
        {accountNavLinkDtos?.map((value, key) => (
          <a
            data-context={'hub'}
            href={value.Link}
            key={key}
            onClick={() =>
              trackEventProcessor('home_page_quick_link_clicked', {
                targetLocation: value.Label,
              })
            }
            css={css`
              display: flex;
              flex-direction: column;
              width: 11.25rem;
              justify-content: flex-start;
              align-items: center;
              text-align: center;
              border: 1px solid rgba(0, 0, 0, 0);
              &:hover {
                border: 1px solid ${colorToCode('light-300')};
                border-radius: 0.5rem;
                background-color: ${colorToCode('light-200')};
                text-decoration: none;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                padding: 1.5rem 1rem 1.5rem 1rem;
              `}
            >
              {value.IsProductIcon ? (
                <img
                  src={value.Icon}
                  alt={value.Label}
                  css={css`
                    width: 4.5rem;
                    height: 4.5rem;
                  `}
                />
              ) : (
                <div
                  css={css`
                    display: flex;
                    width: 4.5rem;
                    height: 4.5rem;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid ${colorToCode('light-300')};
                    border-radius: 0.875rem;
                  `}
                >
                  <Icon
                    key={key}
                    sizePx={32}
                    icon={value.Icon as IconType}
                  />
                </div>
              )}
              <Label
                size={'lg'}
                color={'dark-400'}
                css={css`
                  margin-top: 1rem;
                `}
              >
                {value.Label}
              </Label>
              {value.Sublabel && (
                <Label
                  size={'sm'}
                  color={'dark-100'}
                >
                  {value.Sublabel}
                </Label>
              )}
            </div>
          </a>
        ))}
      </div>
    </React.Fragment>
  );
};

export default QuickLinks;
