import Box from '~/neo-ui/packages/box/Box';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import FormTextInput from '~/neo-ui/packages/form/packages/form-input/packages/form-text-input/FormTextInput';
import * as React from 'react';
import useWizardPageContext from '~/neo-ui/packages/wizard/packages/wizard-page/context/hooks/useWizardPageContext';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import useManufacturerList from '~/wm/packages/disposal/hooks/useManufacturerList';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import FormSingleSelectInput from '~/neo-ui/packages/form/packages/form-input/packages/form-single-select-input/FormSingleSelectInput';
import Testable from '~/neo-ui/packages/testable/Testable';

export type ReplacementSectionProps<T> = {
  serialNumberFieldKey: FieldKeyExpression<T>;
  modelNumberFieldKey: FieldKeyExpression<T>;
  nameFieldKey: FieldKeyExpression<T>;
  manufacturerFieldKey: FieldKeyExpression<T>;
};

const ReplacementSection = <T,>({
  serialNumberFieldKey,
  modelNumberFieldKey,
  nameFieldKey,
  manufacturerFieldKey,
}: ReplacementSectionProps<T>) => {
  const { manufacturers } = useManufacturerList();
  const { setFieldCompleted } = useWizardPageContext();
  const { getFormInput } = useFormContext<T>();

  const serialNumber = getFormInput<string>(serialNumberFieldKey);
  const modelNumber = getFormInput<string>(modelNumberFieldKey);
  const name = getFormInput<string>(nameFieldKey);
  const manufacturer = getFormInput<string>(manufacturerFieldKey);

  React.useEffect(() => {
    setFieldCompleted(serialNumberFieldKey, !!serialNumber.value);
    setFieldCompleted(modelNumberFieldKey, !!modelNumber.value);
    setFieldCompleted(nameFieldKey, !!name.value);
    setFieldCompleted(manufacturerFieldKey, !!manufacturer.value);
  }, [
    manufacturer,
    manufacturerFieldKey,
    modelNumber,
    modelNumberFieldKey,
    name.value,
    nameFieldKey,
    serialNumber,
    serialNumberFieldKey,
    setFieldCompleted,
  ]);

  const manufacturerOptions: SelectOption[] = (manufacturers ?? []).map(option => ({
    label: option.displayName,
    value: option.manufacturerId,
  }));

  return (
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
    >
      <LayoutSection
        title={'Replacement asset'}
        titleSize={4}
        titleWeight={'bold'}
        description={"Enter the replacement asset's info to transfer warranty coverage to it."}
      >
        <div>
          <Label
            bold={true}
            css={css`
              margin-bottom: 0.3125rem;
            `}
          >
            Serial
          </Label>
          <Testable testId={'warranty-transfer-asset-serial-number-text-input'}>
            <FormTextInput
              fieldKey={serialNumberFieldKey}
              isInvalid={serialNumber.touched && !serialNumber.value}
            />
          </Testable>
          {serialNumber.touched && !serialNumber.value && <ErrorMessage />}
        </div>
        <div>
          <Label
            bold={true}
            css={css`
              margin-bottom: 0.3125rem;
            `}
          >
            Name
          </Label>
          <Testable testId={'warranty-transfer-asset-name-text-input'}>
            <FormTextInput fieldKey={nameFieldKey} />
          </Testable>
          {name.touched && !name.value && <ErrorMessage />}
        </div>
        <div>
          <Label
            bold={true}
            css={css`
              margin-bottom: 0.3125rem;
            `}
          >
            Manufacturer
          </Label>
          <Testable testId={'warranty-transfer-asset-manufacturer-select'}>
            <FormSingleSelectInput
              fieldKey={manufacturerFieldKey}
              options={manufacturerOptions}
              placeholder={'Select a manufacturer...'}
            />
          </Testable>
        </div>
        <div>
          <Label
            bold={true}
            css={css`
              margin-bottom: 0.3125rem;
            `}
          >
            Model
          </Label>
          <Testable testId={'warranty-transfer-asset-model-number-text-input'}>
            <FormTextInput fieldKey={modelNumberFieldKey} />
          </Testable>
          {modelNumber.touched && !modelNumber.value && <ErrorMessage />}
        </div>
      </LayoutSection>
    </Box>
  );
};

const ErrorMessage = () => (
  <Label
    css={css`
      margin-top: 0.23rem;
      font-size: 80%;
      font-weight: 400;
    `}
    color={'negative-400'}
    size={'sm'}
  >
    Required.
  </Label>
);

export default ReplacementSection;
