import * as React from 'react';

// eslint-disable-next-line import/no-internal-modules
import MultiSelectSearchInternal, {
  MultiSelectSearchInternalProps,
} from '~/neo-ui/packages/select/packages/multi-select-search/MultiSelectSearchInternal';

export type MultiSelectSearchProps<TData extends Record<string, unknown>> = MultiSelectSearchInternalProps<TData>;

const MultiSelectSearch = <TData extends Record<string, unknown>>({
  labelKey,
  inputLabel,
  selected,
  options,
  onResultsSelected,
  onSearch,
  onClearFilter,
  onRemoveSelected,
  renderToken,
  renderSearchRow,
  menuCssPosition,
  className,
}: MultiSelectSearchProps<TData>) => (
  <MultiSelectSearchInternal
    className={className}
    labelKey={labelKey}
    inputLabel={inputLabel}
    menuCssPosition={menuCssPosition}
    options={options}
    selected={selected}
    onSearch={onSearch}
    onResultsSelected={onResultsSelected}
    onClearFilter={onClearFilter}
    onRemoveSelected={onRemoveSelected}
    renderToken={renderToken}
    renderSearchRow={renderSearchRow}
  />
);

export default MultiSelectSearch;
