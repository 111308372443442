import WarrantyCartCostCalculator from '~/wm/packages/warranty/packages/warranty-cart/calculators/regular/WarrantyCartCostCalculator';
import { RenewalOptionSellingPriceDto } from '@WarrantyClient/BeastClient/Beast/Estimate/Dto/Cart/PaginatedGet.gen';
import { WarrantyCartPriceCalculatorType } from '~/wm/packages/warranty/packages/warranty-cart/types/WarrantyCartCalculatorType';
import { defaultDynamicPrice } from '~/wm/packages/warranty/packages/warranty-cart/constants/costCalculationConstants';

const calculateDynamicCost = (
  availablePlans: RenewalOptionSellingPriceDto[],
  selectedPlanId: string | undefined,
  selectedAssetsCount: number,
  duration: number,
): number => {
  const selectedPlan = selectedPlanId ? availablePlans.find(p => p.planId === selectedPlanId) : availablePlans[0];

  const dynamicPrices = selectedPlan?.dynamicPricesByTotalCoverageYears || defaultDynamicPrice;

  const selected = (+duration / 12) * selectedAssetsCount;
  const planIdx = Math.max(1, Math.min(3, selected));

  return dynamicPrices[planIdx] * duration;
};

export const warrantyCartDynamicCostCalculator: WarrantyCartCostCalculator = {
  calculatorType: WarrantyCartPriceCalculatorType.Dynamic,
  calculateCost: calculateDynamicCost,
};
