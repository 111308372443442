import * as React from 'react';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';
import Image from '~/neo-ui/packages/image/Image';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { Styleable } from '~/neo-ui/model/capacity';

export type TreeImpactDataSectionProps = {
  displayNumber: number;
  imageSource: string;
  icon: IconType;
  description: string;
} & Styleable;

const TreeImpactDataSection: React.FunctionComponent<TreeImpactDataSectionProps> = ({
  displayNumber,
  imageSource,
  icon,
  description,
  className,
}) => (
  <div
    className={className}
    css={css`
      min-width: 13.75rem;
    `}
  >
    <Image
      source={imageSource}
      css={css`
        height: 3.75rem;
      `}
    />
    <Header
      css={css`
        margin-top: 0.5rem;
        align-items: center;
      `}
      size={3}
      weight={'medium'}
    >
      <Icon
        sizePx={16}
        icon={icon}
        color={'positive-400'}
        css={css`
          margin-right: 0.25rem;
        `}
      />
      {displayNumber}
    </Header>
    <Label
      css={css`
        margin-left: 1.5rem;
      `}
      size={'sm'}
    >
      {description}
    </Label>
  </div>
);

export default TreeImpactDataSection;
