import { InitiativeSaveFormData } from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Button from '~/neo-ui/packages/button/Button';
import Box from '~/neo-ui/packages/box/Box';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Preformatted from '~/neo-ui/packages/text/packages/preformatted/Preformatted';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';

export type InitiativeViewDetailsSectionProps = {
  onEdit: () => void;
};

const nameFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.name;
const executiveSummaryFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.executiveSummary;

const InitiativeViewDetailsSection = ({ onEdit }: InitiativeViewDetailsSectionProps) => {
  const { getFormInput } = useFormContext<InitiativeSaveFormData>();

  const initiativeTitle = getFormInput<string>(nameFieldKey).value;
  const executiveSummary = getFormInput<string>(executiveSummaryFieldKey).value;
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-top: 1.5rem;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <Header
          size={4}
          weight={'bold'}
        >
          {initiativeTitle ?? 'Untitled Initiative'}
        </Header>
        <Button
          size={'sm'}
          iconLeft={'Edit'}
          css={css`
            margin-left: auto;
          `}
          onClick={onEdit}
        >
          Edit Initiative
        </Button>
      </div>
      {/* Add color, border radius, and update padding to be consistenct with out code base */}
      {typeof executiveSummary !== 'undefined' && executiveSummary.length > 0 && (
        <Box
          padding={'padding300'}
          borderRadius={'radius300'}
          background={'foregroundAccent'}
          css={css`
            background-color: ${colorToCode('light-100')};
          `}
        >
          <LayoutSection
            title={'Executive summary'}
            titleSize={4}
            titleWeight={'bold'}
          >
            <Preformatted text={executiveSummary} />
          </LayoutSection>
        </Box>
      )}
    </div>
  );
};

export default InitiativeViewDetailsSection;
