import { css } from '@emotion/react';
import * as React from 'react';
import { HeaderGroup } from 'react-table';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

// eslint-disable-next-line @typescript-eslint/ban-types
export type TableHeaderProps<T extends {}> = {
  column: HeaderGroup<T>;
  canSort?: boolean;
  isSorted?: boolean;
  isSortedDesc?: boolean;
  onToggleSort?: React.MouseEventHandler;
  hasTableBorder?: boolean;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const TableHeader = <T extends {}>({
  column,
  canSort = false,
  isSorted = false,
  isSortedDesc = false,
  onToggleSort,
  hasTableBorder = true,
}: TableHeaderProps<T>) => (
  <th
    {...column.getHeaderProps()}
    onClick={onToggleSort}
    css={css`
      user-select: none;
      border-top: ${hasTableBorder ? `1px solid ${colorToCode('dark-900-12')}` : '0'} !important;
      border-bottom: 1px solid ${colorToCode('dark-900-12')} !important;
      ${column.width ? `width: ${column.width};` : ''}
      vertical-align: middle !important;
      padding: 0.5rem !important;

      ${isSorted
        ? `
            background: ${colorToCode('secondary-050')};
            box-shadow: 0 -3px 0 0 inset ${colorToCode('secondary-400')};
            color: ${colorToCode('secondary-400')};
            `
        : ''}
    `}
  >
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <Header
        size={5}
        css={css`
          width: 100%;
        `}
      >
        {column.render('Header')}
      </Header>
      {(() => {
        if (!canSort) {
          return null;
        }
        const iconUrl = ((): string => {
          if (isSortedDesc) {
            return '/i/sort/Down.svg';
          }
          if (isSorted) {
            return '/i/sort/Up.svg';
          }
          // Not sorted
          return '/i/sort/None.svg';
        })();
        return (
          <img
            css={css`
              flex-shrink: 0;
            `}
            src={iconUrl}
          />
        );
      })()}
    </div>
  </th>
);

export default TableHeader;
