import { PropsWithChildren, useCallback, useMemo } from 'react';
import { parseISO } from 'date-fns';
import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import Form from '~/neo-ui/packages/form/Form';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import engagementActionEditFormValidationSchema from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/validation/engagementActionEditFormValidationSchema';
import { EngagementActionEditFormDataType } from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/types/EngagementActionEditFormDataType';
import useEngagementActionUpdate from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionUpdate';

export type EngagementActionEditFormProps = {
  action: EngagementActionDto;
};

const EngagementActionEditForm = ({ action, children }: PropsWithChildren<EngagementActionEditFormProps>) => {
  const { updateEngagementAction } = useEngagementActionUpdate();
  const onSubmit = useCallback(
    async (data: EngagementActionEditFormDataType) => {
      const dueAt = data.dueAt !== '' ? parseISO(data.dueAt).toUTCString() : undefined;

      updateEngagementAction({
        engagementActionId: action.engagementActionId,
        description: data.description,
        dueAt,
        assignedUserIds: data.assignedUserIds,
      });
    },
    [action.engagementActionId, updateEngagementAction],
  );

  const defaultFormData = useMemo<EngagementActionEditFormDataType>(
    () => ({
      description: action.description,
      assignedUserIds: action.assignedUsers.map(user => user.id),
      dueAt:
        typeof action.dueAt !== 'undefined'
          ? formatDate(parseISO(`${action.dueAt}`), {
              format: 'yyyy-MM-dd',
              timezone: TimezoneFormat.Utc,
            })
          : '',
    }),
    [action.assignedUsers, action.description, action.dueAt],
  );

  return (
    <Form
      submitMethod={'manual'}
      hideSubmissionButton={true}
      hideSubmitStatusIndicator={true}
      defaultFormData={defaultFormData}
      validationSchema={engagementActionEditFormValidationSchema}
      onSubmit={onSubmit}
    >
      {children}
    </Form>
  );
};

export default EngagementActionEditForm;
