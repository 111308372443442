import { Styleable } from '~/neo-ui/model/capacity';
import useDownloadCoverageTransferDisposalOrderCertificatePdf from '~/wm/packages/warranty/packages/service-request/hooks/useDownloadCoverageTransferDisposalOrderCertificatePdf';
import React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import useDisposalOrderInfoContext from '~/wm/packages/disposal/packages/order-info/context/hooks/useDisposalOrderInfoContext';

export type ServiceRequestDisposalCertificateButtonProps = {
  organizationName: string;
  assetSerialNumber: string;
} & Styleable;

const ServiceRequestDisposalCertificateButton = ({
  organizationName,
  assetSerialNumber,
  className,
}: ServiceRequestDisposalCertificateButtonProps) => {
  const { disposalOrderSummary } = useDisposalOrderInfoContext();
  const { downloadCoverageTransferDisposalCertificatePdf } = useDownloadCoverageTransferDisposalOrderCertificatePdf();

  if (typeof disposalOrderSummary === 'undefined') {
    return null;
  }

  return (
    <Button
      className={className}
      size={'sm'}
      iconLeft={'PDF'}
      onClick={() =>
        downloadCoverageTransferDisposalCertificatePdf(organizationName, disposalOrderSummary.disposalOrderId, assetSerialNumber)
      }
      theme={'secondary'}
    >
      Download Certificate of Disposal
    </Button>
  );
};

export default ServiceRequestDisposalCertificateButton;
