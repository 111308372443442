import React from 'react';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type WarrantyCartDeviceTableLoaderProps = {
  isLoading: boolean;
};

const WarrantyCartDeviceTableLoader: React.FunctionComponent<WarrantyCartDeviceTableLoaderProps> = ({ isLoading }) => {
  if (!isLoading) {
    return (
      <div
        css={css`
          height: 0.25rem;
        `}
      />
    );
  }
  return (
    <div
      css={css`
        height: 0.25rem;
        width: 100%;
        --c: no-repeat linear-gradient(${colorToCode('dark-300')} 0 0);
        background: var(--c), var(--c), ${colorToCode('dark-000')};
        background-size: 60% 100%;
        animation: l16 3s infinite;
        @keyframes l16 {
          0% {
            background-position: -150% 0, -150% 0;
          }
          66% {
            background-position: 250% 0, -150% 0;
          }
          100% {
            background-position: 250% 0, 250% 0;
          }
        }
      `}
    />
  );
};

export default WarrantyCartDeviceTableLoader;
