import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { FunctionComponent } from 'react';
import StrategyDashboardContent from '~/wm/packages/strategy/packages/strategy-dashboard/StrategyDashboardContent';

export type StrategyDashboardProps = {
  frontendScope: FrontendScope;
  organizationId?: string;
  organizationName?: string;
  insightUrl: string;
  assetUrl: string;
  dmiGuideUrl: string;
  upgradeUrl: string;
  growUrl: string;
  hardwareConsoleUrl: string;
  peopleConsoleUrl: string;
  membersUrl: string;
  roadmapUrl: string;
  engagementActionListUrl: string;
  engagementNoteListUrl: string;
  hasManageMembersPermission?: boolean;
  hasAccessToNewConsole: boolean;
  canFullyAccessInsights: boolean;
  enabledInitiativeTickets: boolean;
  enabledSaasM365: boolean;
};

const StrategyDashboard: FunctionComponent<StrategyDashboardProps> = ({
  frontendScope,
  organizationId = '',
  organizationName = '',
  insightUrl,
  assetUrl,
  dmiGuideUrl,
  upgradeUrl,
  growUrl,
  hardwareConsoleUrl,
  peopleConsoleUrl,
  membersUrl,
  roadmapUrl,
  engagementActionListUrl,
  hasManageMembersPermission = false,
  hasAccessToNewConsole,
  canFullyAccessInsights,
  engagementNoteListUrl,
  enabledInitiativeTickets,
  enabledSaasM365,
}) => {
  return (
    <StrategyDashboardContent
      frontendScope={frontendScope}
      organizationId={organizationId}
      organizationName={organizationName}
      insightUrl={insightUrl}
      assetUrl={assetUrl}
      dmiGuideUrl={dmiGuideUrl}
      upgradeUrl={upgradeUrl}
      growUrl={growUrl}
      hardwareConsoleUrl={hardwareConsoleUrl}
      peopleConsoleUrl={peopleConsoleUrl}
      membersUrl={membersUrl}
      roadmapUrl={roadmapUrl}
      engagementActionListUrl={engagementActionListUrl}
      hasManageMembersPermission={hasManageMembersPermission}
      engagementNoteListUrl={engagementNoteListUrl}
      hasAccessToNewConsole={hasAccessToNewConsole}
      canFullyAccessInsights={canFullyAccessInsights}
      enabledInitiativeTickets={enabledInitiativeTickets}
      enabledSaasM365={enabledSaasM365}
    />
  );
};

export default StrategyDashboard;
