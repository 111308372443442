import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import ButtonInternal, {
  ButtonInternalColorMap,
  ButtonInternalProps,
  buttonSizeToButtonDisplayDetails,
} from '~/neo-ui/packages/button/ButtonInternal';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';

export type ButtonLinkProps = {
  /**
   * Display the button as left-aligned text
   */
  leftAlign?: boolean;
  /**
   * Display the button as right-aligned text
   */
  rightAlign?: boolean;
  /**
   * Disable the transparent background of the button, will become the background of the Theme
   */
  disableTransparentBackground?: boolean;
} & Omit<ButtonInternalProps, 'colorMap'> &
  Themeable &
  Styleable;

/**
 * A button used for displaying links
 *
 * Providing an anchor prop will switch the html tag from `button` to `a`
 *
 * Supports theme to apply color variations
 *
 * Implements ButtonInternal as the base component
 */
const ButtonLink = ({
  leftAlign = false,
  rightAlign = false,
  size = 'md',
  anchor,
  iconLeft,
  iconRight,
  iconColorOverride,
  disabled = false,
  preventOnClickPropagation = false,
  onClick,
  theme = 'primary',
  disableTransparentBackground = false,
  className,
  children,
}: ButtonLinkProps) => {
  const { themeMap } = useTheme(theme);

  const backgroundColor = disableTransparentBackground ? themeMap.theme : 'blank';

  const iconColor = iconColorOverride
    ? iconColorOverride
    : disableTransparentBackground
    ? { colorRest: themeMap.foregroundAccent, colorActive: themeMap.theme }
    : themeMap.foregroundAccent;

  const colorMap: ButtonInternalColorMap = {
    backgroundColorRest: backgroundColor,
    backgroundColorHover: themeMap.backgroundAccent,
    backgroundColorActive: themeMap.foregroundAccent,
    backgroundColorFocus: themeMap.backgroundAccent,

    textColorRest: themeMap.foregroundAccent,
    textColorHover: themeMap.foregroundAccent,
    textColorActive: themeMap.foregroundContrast,
    textColorFocus: themeMap.foregroundAccent,

    borderColorRest: 'blank',
    borderColorHover: 'blank',
    borderColorActive: 'blank',
    borderColorFocus: themeMap.foregroundLowered,
  };

  return (
    <ButtonInternal
      className={className}
      size={size}
      anchor={anchor}
      iconLeft={iconLeft}
      iconRight={iconRight}
      iconColorOverride={iconColor}
      preventOnClickPropagation={preventOnClickPropagation}
      onClick={onClick}
      disabled={disabled}
      colorMap={colorMap}
      css={css`
        ${leftAlign && `margin-left: -${buttonSizeToButtonDisplayDetails(size).paddingRem ?? 0}rem;`}
        ${rightAlign && `margin-right: -${buttonSizeToButtonDisplayDetails(size).paddingRem ?? 0}rem;`}
      `}
    >
      {children}
    </ButtonInternal>
  );
};

export default ButtonLink;
