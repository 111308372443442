import { FilterDisplayOption, FilterOperatorDisplayOption } from '@AssetManagementClient/BeastClient/Search/Model/Filter.gen';
import Theme from '~/neo-ui/packages/color/Theme';
import * as React from 'react';
import { getOperatorLabelForConsole } from '~/neo-ui/packages/table/packages/console/packages/filter/ConsoleRenderFilterFormatter';
import FilterPreviewMultiSelect from '~/neo-ui/packages/filter/packages/filter-headers/filter-header/packages/filter-header-selected-multi-select/FilterPreviewMultiSelect';
import FilterHeaderEmptyMultiSelect from '~/neo-ui/packages/filter/packages/filter-headers/filter-header/packages/filter-header-selected-multi-select/packages/filter-header-empty-multi-select/FilterHeaderEmptyMultiSelect';
import FilterHeader from '../../../filter-headers/filter-header/FilterHeader';

export type SingleOperatorMultiSelectHeaderConsoleFormatterType = (
  label: string,
  selectedValues: FilterDisplayOption[],
  selectedOperator: FilterOperatorDisplayOption | undefined,
  useCustomOperatorLabel?: boolean,
  customOperatorLabel?: string,
) => (isOpen: boolean) => React.ReactNode;

export const singleOperatorMultiSelectHeaderConsoleFormatter: SingleOperatorMultiSelectHeaderConsoleFormatterType =
  (
    label: string,
    selectedValues: FilterDisplayOption[],
    selectedOperator: FilterOperatorDisplayOption | undefined,
    useCustomOperatorLabel: boolean | undefined,
    customOperatorLabel: string | undefined,
  ) =>
  (isOpen: boolean) => {
    const multiSelectFilterPreview = (
      <FilterPreviewMultiSelect
        selectedValues={selectedValues.map(v => v.filterValue.label)}
        operator={getOperatorLabelForConsole(selectedOperator?.filterValue.label)}
        theme={selectedOperator?.colorTheme as Theme}
        isOpen={isOpen}
        fullWidth={true}
      />
    );

    const headerEmpty = (
      <FilterHeaderEmptyMultiSelect
        label={label}
        isOpen={isOpen}
      />
    );
    return (
      <FilterHeader
        theme={selectedOperator?.colorTheme as Theme}
        selectedValues={selectedValues.map(v => v.filterValue.label)}
        operator={useCustomOperatorLabel ? customOperatorLabel : selectedOperator?.filterValue.label}
        emptyDisplay={headerEmpty}
        selectedDisplay={multiSelectFilterPreview}
      />
    );
  };
