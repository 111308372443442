import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import formControlStyles from '~/neo-ui/packages/form/packages/form-input/packages/form-control/formControlStyles';

export type InputNumberProps = {
  value: number | string | undefined; // The current string value of the inputted number

  /** **************************
   *  State and action props   *
   * ***************************
   */
  disabled?: boolean;

  /**
   * Set a state variable to the inputted number using the given setter
   * (Used during default input behaviour)
   */
  setInputNumber?: React.Dispatch<React.SetStateAction<number | undefined>>;

  /**
   * A customized function for input handling
   * (If not given, will use default behaviour using setState)
   * @param input The input event (number entered event)
   */
  OnNumberChange?: (input: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: () => void;
  minLimit?: number;
  maxLimit?: number; // max length of input

  /** *****************
   *  Display props   *
   * ******************
   */
  placeholder?: string; // Temporary, lighter coloured text that will disappear on key down
  hasDefaultFocus?: () => boolean; // Will attempt to give focus to the input on first render
} & Styleable;

/**
 * Simple input text that provides the value being changed
 *
 * Specifying an onClear will provide a 'clear' button at the right end of the input
 */
const InputNumber: React.FunctionComponent<InputNumberProps> = ({
  className,
  value,
  disabled = false,
  setInputNumber,
  OnNumberChange,
  minLimit = Number.MIN_SAFE_INTEGER,
  maxLimit = Number.MAX_SAFE_INTEGER,
  placeholder,
  hasDefaultFocus = () => false,
}) => {
  const [internalValue, setInternalValue] = React.useState<string>('');

  // Focus stuff
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (hasDefaultFocus() && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [hasDefaultFocus]);

  /**
   * Update the value using the limits when changed
   */
  const handleInputNumber = React.useCallback(
    (input: number) => {
      const newValue = Math.min(Math.max(input, minLimit), maxLimit);
      if (typeof setInputNumber !== 'undefined') {
        setInputNumber(newValue);
      }
      setInternalValue(newValue.toString());
      return;
    },
    [minLimit, maxLimit, setInputNumber],
  );

  React.useEffect(() => {
    if (typeof value === 'undefined') {
      return;
    }

    if (typeof value === 'string') {
      return handleInputNumber(parseInt(value, 10));
    }
    return handleInputNumber(value);
  }, [minLimit, maxLimit, value, handleInputNumber]);

  /**
   * Set the number input using a given set state function
   */
  const trackInputDefault = (inputAction: React.ChangeEvent<HTMLInputElement>) => {
    if (inputAction === undefined || inputAction.target.value === '') {
      // propagate number value through given setter
      if (typeof setInputNumber !== 'undefined') {
        setInputNumber(undefined);
      }
      setInternalValue('');
      return;
    }

    // filter out non-numbers
    const cleanInput = inputAction.target.valueAsNumber;
    handleInputNumber(cleanInput);
  };

  /**
   * Handle tracking for the input changes, either using given function or default
   */
  const handleInputChange = (input: React.ChangeEvent<HTMLInputElement>) => {
    // Use user-supplied onChange function
    if (OnNumberChange !== undefined) {
      OnNumberChange(input);
      return;
    }
    // Otherwise use default behaviour for input
    trackInputDefault(input);
  };

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
      className={className}
    >
      <input
        className={'form-control-num'}
        placeholder={placeholder}
        step={1}
        onChange={inputEvent => {
          handleInputChange(inputEvent);
        }}
        type={'number'}
        min={minLimit}
        max={maxLimit}
        value={internalValue}
        pattern={`[1-9]\d*`}
        css={[
          formControlStyles.app,
          css`
            background: #ffffff;
            box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
            border-radius: 0.3125rem;
            // transparent border is a hack so that adding border when hovered doesn't cause the element to resize
            border: 0.0675rem solid transparent;
            max-height: 2.25rem;
            &:disabled {
              background-color: inherit;
              opacity: 0.2;
            }
            padding-left: 0.75rem;
            padding-right: 0.675rem;
            width: 100%;
          `,
        ]}
        disabled={disabled}
        ref={inputRef}
      />
    </div>
  );
};

export default InputNumber;
