import React, { useCallback, useMemo, useState } from 'react';
import { DataTableColumn, PaginationMetadata } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import useApi from '~/wm/packages/api/hook/useApi';

import { cartDevices } from '@WarrantyClient/WarrantyClientMsp.gen';
import { Request as WarrantyClientWarrantyWarrantyCartControllerWarrantyCartGetControllerNestedRequest } from '@WarrantyClient/Warranty/WarrantyCart/Controller/WarrantyCartGetControllerNested.gen';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import { EstimateCartStoreGetUnitDto } from '@WarrantyClient/BeastClient/Beast/Estimate/Dto/Cart/PaginatedGet.gen';
import WarrantyCartDeviceTableServiceColumn from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-devices-table/packages/warranty-cart-device-table-service-column/WarrantyCartDeviceTableServiceColumn';
import { PaginationState } from '~/neo-ui/packages/table/packages/filter-table/FilterTable';
import PaginationControl from '~/neo-ui/packages/table/packages/data-table/packages/pagination-control/PaginationControl';
import AsyncDataTable from '~/neo-ui/packages/table/packages/async-data-table/AsyncDataTable';
import { css } from '@emotion/react';
import SearchInputText from '~/neo-ui/packages/input/packages/search-input-text/SearchInputText';
import WarrantyCartDeviceSelector from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-devices-table/packages/warranty-cart-device-selector/WarrantyCartDeviceSelector';
import {
  getCoverageStarts,
  warrantyCartFormatDateFromDate,
} from '~/wm/packages/warranty/packages/warranty-cart/calculators/warrantyCartCalculators';
import WarrantyCartCostColumn from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-devices-table/packages/warranty-cart-cost-column/WarrantyCartCostColumn';
import useDebouncedInput from '~/extensions/packages/hooks/useDebouncedInput';
import useWarrantyCartFiltersContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartFiltersContext';
import WarrantyCartDeviceTableLoader from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-devices-table/packages/warranty-cart-device-table-loader/WarrantyCartDeviceTableLoader';
import TableCell from '~/neo-ui/packages/table/packages/table-cell/TableCell';
import warrantyCartFilterBuilder from '~/wm/packages/warranty/packages/warranty-cart/functions/warrantyCartFilterBuilder';

const initPagination: PaginationState = { pageNumber: 0, perPageSize: 50 };

const WarrantyCartDevicesTable: React.FunctionComponent = () => {
  const { callApi } = useApi();
  const { organizationId, selectedDeviceType, getAssetCost, getCoverageEnds } = useWarrantyCartContext();

  const [defaultPagination, setDefaultPagination] = useState<PaginationState>(initPagination);
  const [paginationMetadata, setPaginationMetadata] = useState<PaginationMetadata>({ totalPages: 0, totalResults: 0 });

  const [loading, setLoading] = useState<boolean>(false);
  const { filter, searchQuery, setSearchQuery } = useWarrantyCartFiltersContext();
  const { input, setInput, clearInput } = useDebouncedInput(setSearchQuery);

  const { getSelectedAccountIds, setContinuousRenewalPolicyVisible, setTotalAssetsCount } = useWarrantyCartContext();

  const onFetchData = useCallback(async (): Promise<{
    data: EstimateCartStoreGetUnitDto[];
  }> => {
    setLoading(true);
    const request: WarrantyClientWarrantyWarrantyCartControllerWarrantyCartGetControllerNestedRequest = {
      organizationId,
      deviceType: selectedDeviceType,
      pageNumber: defaultPagination.pageNumber,
      pageSize: defaultPagination.perPageSize,
      search: searchQuery ?? null,
      filter: !!filter ? warrantyCartFilterBuilder(filter, getSelectedAccountIds()) : undefined,
    };
    const response = await callApi(() => cartDevices(request));

    if (!response) {
      return { data: [] };
    }

    setContinuousRenewalPolicyVisible(response.units.some(unit => unit.isContinuousRenewalPolicyApplied));
    setPaginationMetadata({ totalPages: response.totalPages, totalResults: response.totalResults });
    setTotalAssetsCount(response.totalResults);

    // Prevent the case when after filtering we get less pages that current page number
    if (response.totalPages - 1 < defaultPagination.pageNumber) {
      setDefaultPagination(initPagination);
    }
    setLoading(false);
    return { data: response.units };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, selectedDeviceType, defaultPagination.pageNumber, defaultPagination.perPageSize, searchQuery, filter, callApi]);

  const columns = useMemo(
    (): DataTableColumn<EstimateCartStoreGetUnitDto>[] => [
      {
        Header: '',
        fieldKey: 'deviceSelector',
        renderCell: row => <WarrantyCartDeviceSelector unit={row} />,
        width: '2%',
      },
      {
        Header: 'Name',
        fieldKey: p => p.name,
        renderCell: row => row.name,
        width: '20%',
      },
      {
        Header: 'Manufacturer',
        fieldKey: p => p.manufacturerName,
        renderCell: row => row.manufacturerName,
        width: '10%',
      },
      {
        Header: 'Serial',
        fieldKey: p => p.normalizedSerial,
        renderCell: row => (
          <TableCell
            label={row.normalizedSerial}
            description={row.modelDisplay}
            descriptionMuted={true}
          />
        ),
        width: '15%',
      },
      {
        Header: 'Expiration Date',
        fieldKey: p => p.expiryDate,
        renderCell: row => warrantyCartFormatDateFromDate(row.expiryDate ? new Date(row.expiryDate) : new Date()),
        width: '8%',
      },
      {
        Header: 'Age',
        fieldKey: p => p.ageInYears,
        renderCell: row => <span>{row.ageInYears} years</span>,
        width: '4%',
      },
      {
        Header: 'Service',
        fieldKey: p => p.renewalOptions,
        renderCell: row => (
          <WarrantyCartDeviceTableServiceColumn
            options={row.renewalOptions}
            assetAccountId={row.assetAccountId}
          />
        ),
        width: '10%',
      },
      {
        Header: 'Coverage starts',
        fieldKey: p => p.renewalStart,
        renderCell: row => getCoverageStarts(row.renewalStart, row.isContinuousRenewalPolicyApplied),
        width: '8%',
      },
      {
        Header: 'Coverage ends',
        fieldKey: p => p.assetAccountId,
        renderCell: row => getCoverageEnds(row.assetAccountId, row.renewalStart),
        width: '8%',
      },
      {
        Header: 'Cost',
        fieldKey: p => p.deviceType,
        renderCell: row => (
          <WarrantyCartCostColumn
            assetAccountId={row.assetAccountId}
            cost={getAssetCost(row.assetAccountId, row.renewalOptions)}
            startDate={row.renewalStart}
          />
        ),
        width: '10%',
      },
    ],
    [getCoverageEnds, getAssetCost],
  );

  return (
    <div>
      <div
        css={css`
          display: flex;
          gap: 2rem;
          margin-bottom: 0.2rem;
        `}
      >
        <SearchInputText
          css={css`
            flex-grow: 1;
          `}
          placeholder={'Search by name, serial, or model'}
          value={input}
          onTextChange={e => setInput(e.target.value)}
          onClear={clearInput}
        />
        <PaginationControl
          defaultPagination={defaultPagination}
          onPageChange={setDefaultPagination}
          paginationMetadata={paginationMetadata}
        />
      </div>
      <WarrantyCartDeviceTableLoader isLoading={loading} />
      <AsyncDataTable
        onFetchData={onFetchData}
        columns={columns}
        tableDescription={'Warranty cart devices table'}
      />
    </div>
  );
};

export default WarrantyCartDevicesTable;
