import { useContext } from 'react';
import RoadmapInitiativesContext from '~/wm/packages/strategy/packages/roadmap-page/context/RoadmapInitiativesContext';

const useRoadmapInitiativesContext = () => {
  const roadmapInitiativesContext = useContext(RoadmapInitiativesContext);
  if (!roadmapInitiativesContext) {
    throw new Error('Tried to use RoadmapInitiativesContext outside of an RoadmapInitiativesProvider');
  }

  return roadmapInitiativesContext;
};

export default useRoadmapInitiativesContext;
