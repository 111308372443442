import { PresentationalDeviceTypeCount } from '@AssetManagementClient/BeastClient/Beast/Asset/Controller/Device/Stats/ScopedDeviceTypeStatsNested.gen';
import React from 'react';
import AssetCountList from '~/wm/packages/strategy/packages/asset/packages/asset-count-list/AssetCountList';

export type AssetCountListContainerProps = {
  assetUrl: string;
  assetCounts: PresentationalDeviceTypeCount[] | undefined;
  total: number | undefined;
  hasAccessToNewConsole: boolean;
};

const AssetCountListContainer: React.FunctionComponent<AssetCountListContainerProps> = ({
  assetUrl,
  assetCounts,
  total,
  hasAccessToNewConsole,
}) => {
  if (assetCounts === undefined || total === undefined) {
    return <div />;
  }

  return (
    <AssetCountList
      assetCounts={assetCounts}
      total={total}
      urlTemplate={assetUrl}
      hasAccessToNewConsole={hasAccessToNewConsole}
    />
  );
};

export default AssetCountListContainer;
