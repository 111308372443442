import { scorecardCreate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useScorecardMutation, { UseScorecardMutationOptions } from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardMutation';
import { Response } from '@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardCreateControllerNested.gen';

export type UseScorecardCreate = {
  createScorecard: (organizationId: string) => void;
  isCreatingScorecard: boolean;
};

const useScorecardCreate = (options?: UseScorecardMutationOptions<Response>): UseScorecardCreate => {
  const [createScorecard, isCreatingScorecard] = useScorecardMutation(
    (organizationId: string) => scorecardCreate({ organizationId }),
    options,
  );

  return {
    createScorecard,
    isCreatingScorecard,
  };
};

export default useScorecardCreate;
