import React, { ReactNode } from 'react';
import Form from '~/neo-ui/packages/form/Form';
import autoEmailReportFormValidationSchema from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/validation/autoEmailReportFormValidationSchema';
import useAutoEmailReportCreate from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-create/hooks/useAutoEmailReportCreate';
import useAutoEmailReportListContext from '~/wm/packages/organization/packages/auto-email-report/context/hooks/useAutoEmailReportListContext';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import AutoEmailReportFormData, {
  autoEmailReportExcludedColumns,
  buildAutoEmailFormReportQuery,
  generateColumnKeys,
} from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/types/AutoEmailReportFormData';
import { ColumnAvailabilityDto } from '@AssetManagementClient/BeastClient/Search/Model/Console/Dto.gen';

export type AutoEmailReportCreateFormProps = {
  onAutoEmailReportCreate: () => void;
  children: ReactNode;
};

const AutoEmailReportCreateForm = ({ onAutoEmailReportCreate, children }: AutoEmailReportCreateFormProps) => {
  const { autoEmailReports, autoEmailReportAvailability } = useAutoEmailReportListContext();
  const { organizationId, organizationName } = useOrganizationContext();

  const { createAutoEmailReport } = useAutoEmailReportCreate({
    onSuccess: () => {
      onAutoEmailReportCreate();
    },
  });

  const onSubmit = React.useCallback(
    async (data: AutoEmailReportFormData, isRequestActive: () => boolean) =>
      createAutoEmailReport(
        {
          organizationId,
          autoEmailReportCreatePayload: {
            autoEmailReportTemplateId: data.autoEmailReportTemplateId,
            title: data.title,
            reportQuery: buildAutoEmailFormReportQuery(
              data.consoleState,
              Object.keys(data.columnKeys).filter(key => data.columnKeys[key]),
            ),
            saveAsTemplate: data.saveAsTemplate,
          },
        },
        isRequestActive,
      ),
    [createAutoEmailReport, organizationId],
  );

  const defaultFormData = React.useMemo<AutoEmailReportFormData>(
    () => ({
      title: `Custom Scheduled Report ${autoEmailReports.length + 1} - ${organizationName}`,
      saveAsTemplate: false,
      autoEmailReportTemplateId: undefined,
      consoleState: {
        search: undefined,
        pagination: { perPageSize: 0, pageNumber: 0 },
        parameters: new Map<string, Map<string, string[]>>(),
        sort: { key: { value: autoEmailReportAvailability.columns[0].key.value }, order: 'ascending' },
      },
      columnKeys: generateColumnKeys(
        autoEmailReportAvailability.columns
          .filter(column => column.availability !== ColumnAvailabilityDto.Mandatory)
          .map(column => column.key.value),
        true,
        autoEmailReportExcludedColumns,
      ),
    }),
    [autoEmailReportAvailability.columns, autoEmailReports.length, organizationName],
  );

  return (
    <Form
      submitMethod={'manual'}
      hideSubmissionButton={true}
      hideSubmitStatusIndicator={true}
      defaultFormData={defaultFormData}
      validationSchema={autoEmailReportFormValidationSchema}
      onSubmit={onSubmit}
    >
      {children}
    </Form>
  );
};

export default AutoEmailReportCreateForm;
