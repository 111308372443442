import React from 'react';
import Window from '~/neo-ui/packages/window/Window';
import Button from '~/neo-ui/packages/button/Button';
import TicketCreateFormModule from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/packages/ticket-create-form-module/TicketCreateFormModule';

import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { TicketCreateFormDataType } from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/types/TicketCreateFormDataType';
import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';

export type TicketCreateWindowProps = {
  fieldOptions: IntegrationFieldOptionDto[];
  isOpen: boolean;
  hasError?: boolean;
  isCreatingTicket: boolean;
  onDismiss: () => void;
};

const TicketCreateWindow = ({ fieldOptions, isOpen, hasError, isCreatingTicket, onDismiss }: TicketCreateWindowProps) => {
  const { submitForm } = useFormContext<TicketCreateFormDataType>();

  return (
    <Window
      title={'Create PSA Ticket'}
      isOpen={isOpen}
      onDismiss={onDismiss}
      titleIcon={'IntegrationTicket'}
      footerProps={{
        rightControls: [
          {
            expanded: (
              <Button
                theme={'primary'}
                onClick={() => {
                  submitForm();
                }}
                loading={isCreatingTicket}
                disabled={isCreatingTicket}
              >
                Save
              </Button>
            ),
          },
          {
            expanded: (
              <Button
                onClick={onDismiss}
                disabled={isCreatingTicket}
              >
                Cancel
              </Button>
            ),
          },
        ],
      }}
    >
      <TicketCreateFormModule
        hasError={hasError}
        fieldOptions={fieldOptions}
      />
    </Window>
  );
};

export default TicketCreateWindow;
