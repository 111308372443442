import { integrationVendors as integrationVendorsList } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { Response } from '@AssetManagementClient/BeastClient/Beast/Integration/Controller/IntegrationPage/IntegrationVendorListNested.gen';

export const useIntegrationVendors = () => {
  const [integrationVendorInformation, setIntegrationVendorInformation] = useState<Response | undefined>(undefined);

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response: Response | undefined = await callApi(() => integrationVendorsList({}));
      if (!response) {
        return;
      }
      setIntegrationVendorInformation(response);
    })();
  }, [callApi]);

  return integrationVendorInformation;
};
