import { css } from '@emotion/react';
import { Enum as PaymentMethodEnum } from '@SubscriptionClient/BeastClient/Beast/Accounting/Model/Payment/Method/PaymentMethodNested.gen';
import * as React from 'react';
import Info, { InfoTitleSize } from '~/legacy-ui/packages/info/Info';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { Styleable } from '~/neo-ui/model/capacity';

export type BillingInfoProps = {
  billingName: string;
  billingAddressFormatted: string;
  paymentMethod: PaymentMethodEnum;
  creditCardLast4Digits?: string;
  hideCreditCardSection?: boolean;
  billingNameSize?: InfoTitleSize;
  billingNameColor?: Color;
} & Styleable;

const BillingInfo: React.FunctionComponent<BillingInfoProps> = ({
  billingName,
  billingAddressFormatted,
  paymentMethod,
  creditCardLast4Digits,
  hideCreditCardSection = false,
  billingNameSize = 'lg',
  billingNameColor = 'secondary-400',
  className,
}) => (
  <Info
    title={billingName}
    titleSize={billingNameSize}
    titleColor={billingNameColor}
    description={billingAddressFormatted}
    className={className}
  >
    {paymentMethod !== PaymentMethodEnum.NonBillable && !hideCreditCardSection && (
      <span
        css={css`
          color: ${colorToCode('light-600')};
          font-size: 0.75rem;
        `}
      >
        {((): string => {
          switch (paymentMethod) {
            case PaymentMethodEnum.CreditCard:
              return creditCardLast4Digits ? `Credit card ending in ${creditCardLast4Digits}` : 'Billed to credit card';
            case PaymentMethodEnum.Invoice:
              return 'Billed to invoice';
          }
        })()}
      </span>
    )}
  </Info>
);

export default BillingInfo;
