import WizardPageStep from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageStep';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import DisposalSelfCheckoutFormData from '~/wm/packages/disposal/types/DisposalSelfCheckoutFormData';
import SelfOrderSummaryWithoutBillingSection from '../../../section/SelfOrderSummaryWithoutBillingSection';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';

const assetTypeCountsFieldKey: FieldKeyExpression<DisposalSelfCheckoutFormData> = values => values.assetTypeCounts;

const shipmentReturnAddressFieldKey: FieldKeyExpression<DisposalSelfCheckoutFormData> = values =>
  values.shipmentReturnAddress.billingAddress;

const getDisposalSelfSummaryStep = (
  disposalTermsOfServiceUrl: string,
  termsOfServiceUrl: string,
  brandName: string,
  disposalAssetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[],
): WizardPageStep<DisposalSelfCheckoutFormData> => ({
  header: {
    label: 'Summary',
    icon: 'List',
  },
  fields: [],
  sections: [
    () =>
      SelfOrderSummaryWithoutBillingSection({
        disposalTermsOfServiceUrl,
        termsOfServiceUrl,
        brandName,
        assetTypeCountsFieldKey,
        shipmentReturnAddressFieldKey,
        disposalAssetTypeAvailabilities,
      }),
  ],
  onCompleteStateChange: () => true,
  key: 'Billing',
});

export default getDisposalSelfSummaryStep;
