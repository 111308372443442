import { RenderFilter } from '@AssetManagementClient/BeastClient/Search/Model/Query/Field/Filter/Render.gen';
import { Enum } from '@AssetManagementClient/BeastClient/Search/Model/Query/Field/Filter/Render/RenderFilterNested.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';

import SingleSelect from '~/neo-ui/packages/select/packages/single-select/SingleSelect';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { ConsoleState, Filter, TagFilter } from '../../types';
import SingleOperatorMultiSelectFilter from '~/neo-ui/packages/filter/packages/filter-multi-select/packages/filter-single-operator-multi-select/SingleOperatorMultiSelectFilter';
import MultiSelectFilter from '~/neo-ui/packages/filter/packages/filter-multi-select/packages/filter-multi-select-static/MultiSelectFilter';
import SplitMultiSelectFilter from '~/neo-ui/packages/filter/packages/filter-split-multi-select/SplitMultiSelectFilter';
import RangeFilter from '~/neo-ui/packages/filter/packages/filter-range/RangeFilter';
import { rangeValueHeaderFormatter } from '~/neo-ui/packages/filter/packages/filter-range/RangeValueHeaderFormatter';
import ButtonSelectFilter from '~/neo-ui/packages/filter/packages/filter-button-select/ButtonSelectFilter';
import { multiSelectHeaderConsoleFormatter } from '~/neo-ui/packages/filter/packages/filter-multi-select/packages/filter-multi-select-static/MultiSelectHeaderConsoleFormatter';
import { singleOperatorMultiSelectHeaderConsoleFormatter } from '~/neo-ui/packages/filter/packages/filter-multi-select/packages/filter-single-operator-multi-select/SingleOperatorMultiSelectHeaderConsoleFormatter';
import { splitMultiSelectHeaderConsoleFormatter } from '~/neo-ui/packages/filter/packages/filter-split-multi-select/SplitMultiSelectHeaderConsoleFormatter';

export const getOperatorLabelForConsole = (operator: string | undefined) => {
  if (operator === undefined) {
    return operator;
  }

  const lcOperator = operator.toUpperCase();
  switch (lcOperator) {
    case 'IS':
      return undefined;
    case 'IS NOT':
      return 'NOT';
    case 'INSTALLED':
      return undefined;
    case 'NOT INSTALLED':
      return 'NOT';
    default:
      return operator;
  }
};

const ConsoleRenderFilterFormatter = (
  filter: Filter,
  setValue: (key: string, value: TagFilter) => void, // This can propagate updates to the parent component
  consoleState: ConsoleState,
): React.ReactNode => {
  const render = filter.render ?? ({ case: Enum.MultiSelect } as RenderFilter);
  const parameterValue = consoleState.parameters.get(filter.key);

  switch (render.case) {
    case Enum.MultiSelect: {
      return (
        <MultiSelectFilter
          key={filter.key}
          filter={filter}
          render={render}
          parameterValue={parameterValue}
          setValue={setValue}
          headerFormatter={multiSelectHeaderConsoleFormatter}
        />
      );
    }
    case Enum.SingleSelect: {
      const values = parameterValue?.get(render.operator) ?? [];
      let selectedOption: SelectOption | undefined;
      if (values.length > 0 && typeof render.options !== 'undefined') {
        const option = render.options.find(option => option.key === values[0]);
        if (option !== null && typeof option !== 'undefined') {
          selectedOption = {
            label: option.label,
            value: option.key,
          };
        }
      }
      return (
        <div
          key={filter.key}
          css={css`
            display: inline-block;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Label
              css={css`
                margin-right: 0.625rem;
              `}
              bold={true}
            >
              {filter.label}
            </Label>
            <SingleSelect
              options={render.options?.map(option => ({
                label: option.label,
                value: option.key,
              }))}
              disabled={!filter.isEnabled}
              selectedOption={selectedOption}
              onOptionSelected={selectedOption => setValue(filter.key, new Map([[render.operator, [selectedOption.value]]]))}
              style={'default'}
              controlContainerHeight={'2.25rem'}
              css={css`
                width: 12.5rem;
              `}
            />
          </div>
        </div>
      );
    }
    case Enum.ButtonSelect: {
      return (
        <ButtonSelectFilter
          filter={filter}
          render={render}
          parameterValue={parameterValue}
          setValue={setValue}
        />
      );
    }
    case Enum.SingleOperatorMultiSelect: {
      return (
        <SingleOperatorMultiSelectFilter
          key={filter.key}
          filter={filter}
          render={render}
          parameterValue={parameterValue}
          setValue={setValue}
          headerFormatter={singleOperatorMultiSelectHeaderConsoleFormatter}
        />
      );
    }
    case Enum.RangeValueInput: {
      return (
        <RangeFilter
          key={filter.key}
          filter={filter}
          render={render}
          parameterValue={parameterValue}
          setValue={setValue}
          headerFormatter={rangeValueHeaderFormatter}
        />
      );
    }

    case Enum.SplitMultiSelect: {
      return (
        <SplitMultiSelectFilter
          key={filter.key}
          filter={filter}
          render={render}
          parameterValue={parameterValue}
          setValue={setValue}
          headerFormatter={splitMultiSelectHeaderConsoleFormatter}
        />
      );
    }
  }
};
export default ConsoleRenderFilterFormatter;
