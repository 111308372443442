import React from 'react';
import { css } from '@emotion/react';
import WarrantyCartOpportunityCoTerminateCheckbox from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-opportunity/packages/warranty-cart-opportunity-cotermination-checkbox/WarrantyCartOpportunityCoTerminateCheckbox';
import WarrantyCartOpportunityDurationDropDown from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-opportunity/packages/warranty-cart-opportunity-duration-dropdown/WarrantyCartOpportunityDurationDropDown';
import WarrantyCartOpportunityRevenue from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-opportunity/packages/warranty-cart-opportunity-revenue/WarrantyCartOpportunityRevenue';
import WarrantyCartOpportunitiesForm from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-opportunity/packages/warranty-cart-opportunity-form/WarrantyCartOpportunitiesForm';
import Header from '~/neo-ui/packages/text/packages/header/Header';

const WarrantyCartOpportunity: React.FunctionComponent = () => {
  return (
    <WarrantyCartOpportunitiesForm>
      <div
        css={css`
          .proposal-title-margin {
            margin: 0.5rem 0;
          }
        `}
      >
        <Header
          size={5}
          color={'dark-000'}
          className={'proposal-title-margin'}
        >
          YOUR PROPOSAL
        </Header>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 0.4rem;
        `}
      >
        <span>For</span>
        <WarrantyCartOpportunityDurationDropDown />
        <WarrantyCartOpportunityCoTerminateCheckbox />
      </div>
      <WarrantyCartOpportunityRevenue />
    </WarrantyCartOpportunitiesForm>
  );
};

export default WarrantyCartOpportunity;
