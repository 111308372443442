import { useState, useCallback, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { integrationDelete } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { Discriminant } from '@AssetManagementClient/Primitives/Results/EmptyResponse_/IntegrationDeleteError_/ResultNested.gen';
import routes from '~/router/types/routes';

type UseIntegrationSetupDelete = (integrationSetupId: string) => {
  deleteIntegrationSetup: (ValidateOnly: boolean) => Promise<boolean>;
  isDeletingIntegrationSetup: boolean;
  isDeleted: boolean;
  hasError: boolean;
  errorMessage: string;
  newIntegrationRequired: boolean;
};

const useIntegrationSetupDelete: UseIntegrationSetupDelete = (integrationSetupId: string) => {
  const { callApi } = useApi();
  const [isDeletingIntegrationSetup, setDeletingIntegrationSetup] = useState(false);
  const [isDeleted, setDeleted] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newIntegrationRequired, setNewIntegrationRequired] = useState(false);

  // Returns boolean that contains if IntegrationSetup deletion was successful or not
  const deleteIntegrationSetup = useCallback(
    async (validateOnly: boolean) => {
      setDeletingIntegrationSetup(true);
      const response = await callApi(() => integrationDelete({ integrationSetupId, validateOnly }));
      if (response !== undefined) {
        setDeletingIntegrationSetup(false);
        switch (response.case) {
          case Discriminant.Ok:
            if (!validateOnly) {
              setDeleted(true);
              window.location.href = routes.ACCOUNT_INTEGRATION_INDEX.build();
              return true;
            }
            return false;
          case Discriminant.Error:
            setDeleted(false);
            setHasError(true);
            setErrorMessage(response.value.messagePublic);
            if (response.value.messagePublic.includes('Add another integration')) {
              setNewIntegrationRequired(true);
            }
            return true;
        }
      }
      setDeletingIntegrationSetup(false);
      return false;
    },
    [callApi, integrationSetupId],
  );

  // Call to validate once beforehand
  useEffect(() => {
    (async () => {
      await callApi(() => deleteIntegrationSetup(true));
    })();
  }, [deleteIntegrationSetup, callApi]);

  return {
    deleteIntegrationSetup,
    isDeletingIntegrationSetup,
    isDeleted,
    hasError,
    errorMessage,
    newIntegrationRequired,
  };
};

export default useIntegrationSetupDelete;
