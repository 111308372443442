import { ReactNode, useCallback } from 'react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import useToastContext from '~/neo-ui/packages/toast/context/hooks/useToastContext';
import { v4 as uuidv4 } from 'uuid';
import { Themeable } from '~/neo-ui/model/capacity';
import { ToastType } from '~/neo-ui/packages/toast/Toast';

export type UseAlert = (alert: Alert) => void;

export type Alert = {
  toastType?: ToastType;
  title: string;
  description: ReactNode;
  icon: IconType;
  buttonLeft?: AlertButtonProps;
  buttonRight?: AlertButtonProps;
  isNonDismissible?: boolean;
} & Themeable;

export type AlertButtonProps = {
  label: string;
  iconLeft?: IconType;
  iconRight?: IconType;
  onClick?: () => void;
  link?: string;
};

/**
 * Send an alert toast to the dom
 */
const useAlert = (): UseAlert => {
  const { addToast, removeToast } = useToastContext();
  const id = uuidv4();

  return useCallback(
    alert => {
      addToast({
        toastUniqueId: id,
        toastType: alert.toastType ?? 'alert',
        isNonDismissible: alert.isNonDismissible,
        toastContent: {
          title: alert.title,
          description: alert.description,
          icon: alert.icon,
          theme: alert.theme,
          buttonLeft: alert.buttonLeft
            ? {
                children: alert.buttonLeft.label,
                iconRight: alert.buttonLeft.iconRight,
                iconLeft: alert.buttonLeft.iconLeft,
                onClick: alert.buttonLeft.onClick
                  ? () => {
                      alert.buttonLeft!.onClick!();
                      removeToast(id);
                    }
                  : undefined,
                anchor: alert.buttonLeft.link ? { href: alert.buttonLeft.link } : undefined,
              }
            : undefined,
          buttonRight: alert.buttonRight
            ? {
                children: alert.buttonRight.label,
                theme: alert.theme,
                iconRight: alert.buttonRight.iconRight,
                iconLeft: alert.buttonRight.iconLeft,
                onClick: alert.buttonRight.onClick
                  ? () => {
                      alert.buttonRight!.onClick!();
                      removeToast(id);
                    }
                  : undefined,
                anchor: alert.buttonRight.link ? { href: alert.buttonRight.link } : undefined,
              }
            : undefined,
        },
      });
    },
    [addToast, id, removeToast],
  );
};

export default useAlert;
