import { useState, useEffect } from 'react';
import { DisposalOrderSummaryDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Dto/Model.gen';
import callApiOrError from '~/wm/packages/api/packages/api-result/callApiOrError';
import { disposalOrderPackagesLabelingProgressGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { Discriminant } from '@AssetManagementClient/Primitives/Results/ProgressResponse_/ProgressError_/ResultNested.gen';
import convertApiProgress from '~/wm/packages/api/packages/progress/model/convertApiProgress';
import useDisposalOrderInfoMutationContext from '~/wm/packages/disposal/packages/order-info/context/hooks/useDisposalOrderInfoMutationContext';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderType/Model/DisposalOrderTypeFactoryNested.gen';

export type UseDisposalOrderPackagesLabelPoller = {
  pollingState: DisposalOrderPackagesLabelPollerState;
};

export type DisposalOrderPackagesLabelPollerState = 'initializing' | 'polling' | 'error' | 'done';

const useDisposalOrderPackagesLabelPoller = (
  disposalOrderSummary: DisposalOrderSummaryDto | undefined,
): UseDisposalOrderPackagesLabelPoller => {
  const [pollingState, setPollingState] = useState<DisposalOrderPackagesLabelPollerState>('initializing');
  const { triggerDisposalOrderInfoReload } = useDisposalOrderInfoMutationContext();

  useEffect(() => {
    // Stay in the not-polling state if disposalOrderSummary is not provided
    if (typeof disposalOrderSummary === 'undefined') {
      return;
    }

    // Guard against bulk disposal usage
    if (!(disposalOrderSummary?.orderType === Enum.ItadSelf || disposalOrderSummary?.orderType === Enum.WorkstationAssurance)) {
      return;
    }

    // Guard against polling not being started
    if (disposalOrderSummary.packageCount === 0) {
      return;
    }

    // Finish polling if all shipping labels are already purchased
    if (
      (disposalOrderSummary?.orderType === Enum.ItadSelf || disposalOrderSummary?.orderType === Enum.WorkstationAssurance) &&
      disposalOrderSummary.isAllShippingLabelsCreated
    ) {
      setPollingState('done');
      return;
    }

    // Repeatedly poll for progress
    const interval = setInterval(() => {
      (async () => {
        const apiResult = await callApiOrError(() =>
          disposalOrderPackagesLabelingProgressGet({
            disposalOrderId: disposalOrderSummary.disposalOrderId,
          }),
        );

        if (apiResult.type === 'error') {
          setPollingState('error');
          clearInterval(interval);
          return;
        }

        setPollingState('polling');

        const progressResult = apiResult.data;
        if (progressResult.case === Discriminant.Error) {
          setPollingState('error');
          clearInterval(interval);
          return;
        }

        const convertedApiProgress = convertApiProgress(progressResult.value);

        if (convertedApiProgress.type === 'done') {
          await triggerDisposalOrderInfoReload();
          clearInterval(interval);
          setPollingState('done');
        }
      })();
    }, 3000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [disposalOrderSummary, triggerDisposalOrderInfoReload]);

  return {
    pollingState,
  };
};

export default useDisposalOrderPackagesLabelPoller;
