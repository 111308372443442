import { createContext } from 'react';

export type InitiativeAvailabilitiesMutationContextData = {
  triggerInitiativeAvailabilitiesReload: (isRequestActive?: () => boolean) => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const InitiativeAvailabilitiesMutationContext = createContext<InitiativeAvailabilitiesMutationContextData>({
  // If there's no provider, it's a graceful no-op
  triggerInitiativeAvailabilitiesReload: async () => {},
});

export default InitiativeAvailabilitiesMutationContext;
