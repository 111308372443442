import * as React from 'react';
import { css } from '@emotion/react';
import Color from '~/neo-ui/packages/color/Color.gen';
import { DmiScoreDto } from '@AssetManagementClient/AssetManagement/Packages/Dmi/Packages/Widget/Model/DmiScoreComponentNested.gen';
import DmiScore from '~/wm/packages/strategy/packages/dmi/packages/dmi-score/DmiScore';

const DmiScoreComponent: React.FunctionComponent<DmiScoreDto> = ({ scoreOn10000, scoreDisplay, themeColor, bandLabel }) => (
  <div
    css={css`
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 10rem;
      height: 8rem;
      overflow: hidden;
    `}
  >
    <DmiScore
      dmiScore={scoreOn10000}
      displayDmiScore={scoreDisplay}
      maxDmiScore={10000}
      themeColor={themeColor as Color}
      bandLabel={bandLabel}
      lastUpdated={undefined}
      size={160}
      showLastUpdated={false}
    />
  </div>
);

export default DmiScoreComponent;
