import { css } from '@emotion/react';
import { components, SingleValueProps } from 'react-select';
import Color from '~/neo-ui/packages/color/Color.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { SingleSelectStyle } from '~/neo-ui/packages/select/packages/single-select/SingleSelect';
import Label from '~/neo-ui/packages/text/packages/label/Label';

const SingleSelectSingleValue = <T extends string>(
  props: SingleValueProps<SelectOption<T>, false>,
  placeholder: React.ReactNode,
  shouldShowDescriptionOnSelectedOption: boolean,
  style: SingleSelectStyle | undefined,
  truncateSelected: number | undefined,
  labelColor?: Color,
) => {
  const { SingleValue } = components;

  return (
    <SingleValue {...props}>
      <div
        css={css`
          display: flex;
          align-items: center;
          min-height: 2.25rem;
        `}
      >
        {props.selectProps.menuIsOpen ? (
          placeholder
        ) : (
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {props.data.prependIcon && (
              <Icon
                css={css`
                  margin-right: 0.58rem;
                `}
                sizePx={props.data.iconSizePx ? props.data.iconSizePx : undefined}
                icon={props.data.prependIcon}
                color={props.data.iconColor ?? props.data.labelColor ?? 'dark-400'}
              />
            )}

            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <Label
                color={labelColor ?? 'dark-900'}
                size={props.data.description && shouldShowDescriptionOnSelectedOption ? 'sm' : 'md'}
                bold={style === 'transparent'}
                css={css`
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow-x: hidden;
                  ${truncateSelected !== undefined && truncateSelected > 0 && `width: ${truncateSelected}px;`}
                `}
              >
                {props.data.label}
              </Label>
              {props.data.description && shouldShowDescriptionOnSelectedOption && (
                <Label
                  size={'sm'}
                  muted={props.data.descriptionMuted ?? true}
                >
                  {props.data.description}
                </Label>
              )}
            </div>
          </div>
        )}
      </div>
    </SingleValue>
  );
};

export default SingleSelectSingleValue;
