import { organizationLinkList } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { Discriminant } from '@AssetManagementClient/Primitives/Results/OrganizationLinkListControllerNested/Response_/OrganizationLinkError_/ResultNested.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import OrganizationLock from '~/wm/packages/organization/packages/organization-lock/OrganizationLock';
import OrganizationLink from '~/wm/packages/organization/packages/linking/model/OrganizationLink';
import Organization from '../../model/Organization';
import OrganizationAddLinks from '../linking/packages/add-links/OrganizationAddLinks';
import OrganizationLinkManage from '../linking/packages/manage/OrganizationLinkManage';
import Spinner from '~/neo-ui/spinner/Spinner';

export type OrganizationNameAndLinksProps = {
  organization: Organization;
};

const OrganizationNameAndLinks: React.FunctionComponent<OrganizationNameAndLinksProps> = ({ organization }) => {
  const [linkedOrganizations, setLinkedOrganizations] = React.useState<OrganizationLink[] | undefined>();
  const { callApi } = useApi();

  // Fetch linked organizations
  React.useEffect(() => {
    (async () => {
      const response = await callApi(() =>
        organizationLinkList({
          parentOrganizationId: organization.id,
        }),
      );

      if (!response) {
        return;
      }
      if (response.case === Discriminant.Error) {
        throw new Error();
      }
      const linkedOrganizations = response.value.links.map<OrganizationLink>(link => ({
        id: link.organizationId,
        name: link.organizationName,
        vendors: link.vendors,
      }));
      setLinkedOrganizations(linkedOrganizations);
    })();
  }, [callApi, organization]);

  if (typeof linkedOrganizations === 'undefined') {
    // Here we could have loading placeholders
    return <Spinner />;
  }

  return (
    <OrganizationLock organizationIds={[organization.id]}>
      <div
        css={css`
          margin-bottom: 15px;
        `}
      >
        <OrganizationLinkManage
          parentOrganization={organization}
          linkedOrganizations={linkedOrganizations}
        />
      </div>
      <OrganizationAddLinks parentOrganization={organization} />
    </OrganizationLock>
  );
};

export default OrganizationNameAndLinks;
