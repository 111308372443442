import { useEffect } from 'react';

/**
 * Periodically making API requests to a server
 */
const usePolling = (callback: () => void, intervalMs: number, shouldPoll: boolean = true) =>
  useEffect(() => {
    if (shouldPoll) {
      const interval = setInterval(() => {
        // Stop polling when the page is hidden
        if (document.hidden) {
          return;
        }

        callback();
      }, intervalMs);
      return () => {
        clearInterval(interval);
      };
    }

    return;
  }, [callback, intervalMs, shouldPoll]);

export default usePolling;
