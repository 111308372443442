import { FilterDisplaySettingsDto } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';
import React from 'react';
import Box from '~/neo-ui/packages/box/Box';
import { UseFormRegister } from 'react-hook-form';

import { IntegrationSetupPageCustomizeSection } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/page-layout/IntegrationSetupPageCustomizeSection';
import SyncFilterCheckboxes from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/sync-filters/packages/SyncFilterCheckboxes';
import { SyncPreferencesData } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useSyncPreferencesForm';

export type SyncFilterSectionProps = {
  payloadType: string;
  sectionName: string;
  sectionInformation: FilterDisplaySettingsDto;
  register: UseFormRegister<SyncPreferencesData>;
};

const SyncFilterSection: React.FunctionComponent<SyncFilterSectionProps> = ({ payloadType, sectionName, sectionInformation, register }) => (
  <Box
    borderRadius={'radius400'}
    key={`${payloadType}.${sectionName}`}
  >
    <IntegrationSetupPageCustomizeSection
      title={sectionInformation.label}
      description={sectionInformation.description}
    >
      <SyncFilterCheckboxes
        payloadType={payloadType}
        filterName={sectionName}
        options={sectionInformation.options}
        register={register}
      />
    </IntegrationSetupPageCustomizeSection>
  </Box>
);
export default SyncFilterSection;
