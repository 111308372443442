import { scorecardScorecardItemUpdate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { ScorecardItemUpdatePayloadDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardItem/Dto/Model.gen';
import useScorecardMutation, { UseScorecardMutationOptions } from '~/wm/packages/strategy/packages/scorecard/hooks/useScorecardMutation';

export type UseScorecardItemUpdate = {
  updateScorecardItem: (updatePayload: ScorecardItemUpdatePayloadDto) => void;
  isUpdatingScorecardItem: boolean;
};

const useScorecardItemUpdate = (
  scorecardId: string,
  scorecardItemId: string,
  options?: UseScorecardMutationOptions,
): UseScorecardItemUpdate => {
  const [updateScorecardItem, isUpdatingScorecardItem] = useScorecardMutation(
    (updatePayload: ScorecardItemUpdatePayloadDto) =>
      scorecardScorecardItemUpdate({
        scorecardId,
        scorecardItemId,
        updatePayload,
      }),
    options,
  );

  return {
    updateScorecardItem,
    isUpdatingScorecardItem,
  };
};

export default useScorecardItemUpdate;
