import React, { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import routes from '~/router/types/routes';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import DataTable, { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import useWarrantiesList from '~/wm/packages/warranty/packages/warranty-list/hooks/useWarrantiesList';
import { WarrantyListOptionDto } from '@WarrantyClient/BeastClient/Beast/Warranties/Dto/WarrantyListOptions.gen';
import CartIconButton from '~/wm/packages/warranty/packages/warranty-list/packages/cart-icon-button/CartIconButton';
import CostColumnHeader from '~/wm/packages/warranty/packages/warranty-list/packages/cost-column-header/CostColumnHeader';
import Spinner from '~/neo-ui/spinner/Spinner';
import useAccount from '~/wm/hooks/useAccount';
import BannerUpgrade from '~/neo-ui/packages/banner/packages/banner-upgrade/BannerUpgrade';
import { formatCurrencyByCurrencyInfo } from '~/extensions/packages/currency/formatCurrency';
import WarrantyListHeader from '~/wm/packages/warranty/packages/warranty-list/packages/warranty-list-header/WarrantyListHeader';
import { Enum as WarrantyTypeEnum } from '@WarrantyClient/BeastClient/Goods/WarrantyType/WarrantyTypeFactoryNested.gen';
import useWarrantiesListSettings from '~/wm/packages/warranty/packages/warranty-list/hooks/useWarrantiesListSettings';
import WarrantyListDescription from '~/wm/packages/warranty/packages/warranty-list/packages/warranty-list-description/WarrantyListDescription';

// Duplicates with the backend 4b754408-80f4-4f7b-858d-ae3b0b1eb13c
const revenuePercentageForDisplaying: number = 1.6;

export type WarrantyListProps = {
  warrantyType: WarrantyTypeEnum | undefined;
};

const WarrantyListPage: React.FunctionComponent<WarrantyListProps> = ({ warrantyType }) => {
  const { clientRows, devicesCountTotal, clientRowsCount, loading } = useWarrantiesList(warrantyType);
  const {
    currencyInformation: { exchangeRate, displayCurrency, subunitRatio },
    currencyInformationLoading,
  } = useWarrantiesListSettings();
  const { subscriptionIdentity } = useAccount();

  const getClientUrl = useCallback(
    (organizationId: string): string => {
      switch (warrantyType) {
        case WarrantyTypeEnum.InfrastructureProtection:
          return routes.ORGANIZATION_MARKETPLACE_CART_INFRASTRUCTURE_PROTECTION.build([organizationId]);
        case WarrantyTypeEnum.WorkstationAssurance:
          return routes.ORGANIZATION_MARKETPLACE_CART_WORKSTATION_ASSURANCE.build([organizationId]);
        default:
          return routes.ORGANIZATION_WARRANTIES_CART_WORKSTATION.build([organizationId]);
      }
    },
    [warrantyType],
  );

  const getTotalCost = useCallback(
    (totalCost: number) => {
      return formatCurrencyByCurrencyInfo(totalCost * exchangeRate * revenuePercentageForDisplaying, {
        code: displayCurrency,
        subunitsPerUnit: subunitRatio,
      });
    },
    [displayCurrency, exchangeRate, subunitRatio],
  );

  const countColumnName = useMemo<string>((): string => {
    const lineStart = 'Available ';
    switch (warrantyType) {
      case WarrantyTypeEnum.InfrastructureProtection:
        return lineStart + routes.MARKETPLACE_INFRASTRUCTURE_PROTECTION.label;
      case WarrantyTypeEnum.WorkstationAssurance:
        return lineStart + routes.MARKETPLACE_WORKSTATION_ASSURANCE.label;
      default:
        return lineStart + 'warranties';
    }
  }, [warrantyType]);

  const currencyInfo =
    displayCurrency === 'USD'
      ? `Amounts shown are in ${displayCurrency}`
      : `All amounts shown are converted to ${displayCurrency} based on today's exchange rate. Final costs will be in USD.`;

  const columns = useMemo<DataTableColumn<WarrantyListOptionDto>[]>(
    () => [
      {
        Header: 'Clients',
        fieldKey: p => p.organizationName,
        renderCell: p => <a href={getClientUrl(p.organizationId)}>{p.organizationName}</a>,
        width: '30%',
        ellipsizeTextContent: true,
        canClientSideSearch: true,
      },
      {
        Header: countColumnName,
        fieldKey: p => p.totalDevices,
        renderCell: p => (
          <CartIconButton
            totalCount={p.totalDevices}
            clientUrl={getClientUrl(p.organizationId)}
          />
        ),
        width: '30%',
      },
      {
        Header: <CostColumnHeader />,
        fieldKey: p => p.totalCost,
        renderCell: p => <span>{getTotalCost(p.totalCost)}</span>,
        width: '30%',
      },
    ],
    [countColumnName, getClientUrl, getTotalCost],
  );

  if (loading || currencyInformationLoading) {
    return <Spinner />;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      `}
    >
      {subscriptionIdentity.isLmFree && (
        <BannerUpgrade
          title={'Unlock warranty opportunities with an upgrade'}
          description={
            'Protect client assets while generating profitable revenue — your subscription will (more than) pay for itself quickly.'
          }
          imageSource={'/i/marketing/warranties/image.png'}
          callToAction={{ text: 'Learn More', link: routes.EXTERNAL_FREE_EDITION_URL.build() }}
          id={'track_banner_warranties_upgrade'}
          openInNewTab={true}
        />
      )}
      <WarrantyListHeader
        devicesCountTotal={devicesCountTotal}
        clientRowsCount={clientRowsCount}
        warrantyType={warrantyType}
      />
      <WarrantyListDescription warrantyType={warrantyType} />
      <DataTable
        columns={columns}
        data={clientRows}
        EmptyStatePlaceholder={'No clients available'}
        defaultSort={{
          key: data => data.totalDevices,
          order: 'descending',
        }}
        sortableColumnKeys={['organizationName', 'totalDevices', 'totalCost']}
        enableClientSorted={true}
        enableClientPaginated={'inline'}
        defaultPagination={{ perPageSize: 50, pageNumber: 0 }}
      />
      <span
        css={css`
          margin-top: -1rem;
          color: ${colorToCode('dark-900-32')};
        `}
      >
        {currencyInfo}
      </span>
    </div>
  );
};

export default WarrantyListPage;
