import React from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import useScorecardItemRemove from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/hooks/useScorecardItemRemove';

export type ScorecardItemRemoveModalProps = {
  scorecardId: string;
  scorecardCategoryId?: string;
  scorecardItemId: string;
  isOpen: boolean;
  onDismiss: () => void;
};

const ScorecardItemRemoveModal = ({
  scorecardId,
  scorecardCategoryId,
  scorecardItemId,
  isOpen,
  onDismiss,
}: ScorecardItemRemoveModalProps) => {
  const { removeScorecardItem, isRemovingScorecardItem } = useScorecardItemRemove(scorecardId, scorecardCategoryId);

  return (
    <ModalConfirmation
      header={{
        title: 'Remove this Scorecard item?',
        icon: 'Trash',
      }}
      isOpen={isOpen}
      theme={'negative'}
      footer={{
        confirmButton: {
          icon: 'Trash',
          label: 'Remove',
          disabled: false,
          loading: isRemovingScorecardItem,
          onClick: () => {
            removeScorecardItem({ scorecardItemId });
          },
        },
        dismissButton: {
          icon: 'Cancel',
          label: 'Cancel',
          disabled: false,
          onClick: onDismiss,
        },
      }}
    >
      Removing this Scorecard item can't be undone.
    </ModalConfirmation>
  );
};

export default ScorecardItemRemoveModal;
