import React from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import Card from '~/legacy-ui/packages/card/Card';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Search from '~/neo-ui/packages/search/Search';
import Info from '~/legacy-ui/packages/info/Info';
import useAssetSearch from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/hooks/useAssetSearch';
import { ContractAssetInfo } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-link-assets-button/modal/ContractAssetInfo';
import buildContractAssetInfoFromAssetSearchResult from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-link-assets-button/builder/buildContractAssetInfoFromAssetSearchResult';

export type ContractLinkAssetsModalProps = {
  organizationId: string;
  selectedAssets: ContractAssetInfo[];
  isOpen: boolean;
  onDismiss: () => void;
  onConfirm: (selectedAsset: ContractAssetInfo[]) => void;
};

/**
 * Ideally we don't have to convert ContractAssetDto <-> InitiativeAssetInfo to
 * make this component work. Currently unsure why using ContractAssetDto
 * instead of InitiativeAssetInfo inside useAssetSearch causes the types of the Search component to be a record instead
 * of ContractAssetDto.
 */
const ContractLinkAssetsModal: React.FunctionComponent<ContractLinkAssetsModalProps> = ({
  organizationId,
  selectedAssets,
  isOpen,
  onDismiss,
  onConfirm,
}) => {
  const [assetSearchResult, setAssetSearchResult] = React.useState<ContractAssetInfo[]>([]);
  const [selectedContractAssets, setSelectedContractAssets] = React.useState<ContractAssetInfo[]>([...selectedAssets]);

  const { searchAssets } = useAssetSearch(assetSearchResults => {
    const resultAssets: ContractAssetInfo[] = buildContractAssetInfoFromAssetSearchResult(assetSearchResults);
    setAssetSearchResult(resultAssets);
  }, organizationId);

  return (
    <ModalConfirmation
      header={{
        title: 'Link Assets',
        icon: 'Add',
      }}
      isOpen={isOpen}
      theme={'primary'}
      footer={{
        confirmButton: {
          icon: 'Done',
          label: 'Link',
          disabled: false,
          onClick: () => {
            onConfirm(selectedContractAssets);
            onDismiss();
          },
        },
        dismissButton: {
          icon: 'Cancel',
          label: 'Cancel',
          disabled: false,
          onClick: onDismiss,
        },
      }}
    >
      <Label>What assets do you want to link to this contract?</Label>
      <Search
        itemKey={'name'}
        tabIndex={1}
        placeholder={'Name or serial number…'}
        selection={{
          multiSelect: true,
          selected: selectedContractAssets,
          onResultsSelected: setSelectedContractAssets,
        }}
        renderSearchRow={asset => (
          <Card
            padding={'sm'}
            backgroundColor={'light-200'}
          >
            <Info
              title={asset.name}
              titleSize={'sm'}
              description={asset.serialNumber ?? '-'}
              descriptionMuted={true}
            >
              {asset.modelNumber && asset.manufacturerName && (
                <Label muted={true}>{`${asset.manufacturerName} ${asset.modelNumber}`}</Label>
              )}
              {asset.user && <Label muted={true}>{asset.user}</Label>}
            </Info>
          </Card>
        )}
        options={assetSearchResult}
        onSearch={searchAssets}
        renderTokenLabel={option => option.name}
      />
    </ModalConfirmation>
  );
};

export default ContractLinkAssetsModal;
