import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import DisposalSelfCheckoutFormData from '~/wm/packages/disposal/types/DisposalSelfCheckoutFormData';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import { Styleable } from '~/neo-ui/model/capacity';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import { assertNeverOrThrow } from '~/extensions/packages/types/assertNever';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import DisposalAssetTypeCountFormData from '~/wm/packages/disposal/types/DisposalAssetTypeCountFormData';
import FormNumberStepperInput from '~/neo-ui/packages/form/packages/form-input/packages/form-number-stepper-input/FormNumberStepperInput';
import FormTitleInput from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/FormTitleInput';

import Label from '~/neo-ui/packages/text/packages/label/Label';
import { useEffect, useRef } from 'react';

export type DisposalAssetCustomTypeCountInputProps = {
  disposalAssetTypeCount: DisposalAssetTypeCountFormData;
  assetTypeCountsIndex: number;
  disposalAssetTypeCountAvailabilities: DisposalAssetTypeAvailabilityDto[];
} & Styleable;

const DisposalAssetCustomTypeCountInput = ({
  disposalAssetTypeCount,
  assetTypeCountsIndex,
  disposalAssetTypeCountAvailabilities,
  className,
}: DisposalAssetCustomTypeCountInputProps) => {
  const countFieldKey = (values: FieldKeyExpressionSegment<DisposalSelfCheckoutFormData>) =>
    values.assetTypeCounts[assetTypeCountsIndex].count;

  const customizedTypeFieldKey = (values: FieldKeyExpressionSegment<DisposalSelfCheckoutFormData>) =>
    values.assetTypeCounts[assetTypeCountsIndex].customizedType;

  const assetCountRef = useRef(disposalAssetTypeCount.count);
  const isAssetCountInvalid = isNaN(disposalAssetTypeCount.count) || disposalAssetTypeCount.count < 1;

  useEffect(() => {
    if (assetCountRef.current === disposalAssetTypeCount.count) {
      return;
    }

    assetCountRef.current = disposalAssetTypeCount.count;
  }, [disposalAssetTypeCount.count, isAssetCountInvalid]);

  const assetTypeCountAvailability = disposalAssetTypeCountAvailabilities.find(
    availability => availability.disposalAssetType === disposalAssetTypeCount.type,
  );

  // Throw in case we cannot find this availability
  if (typeof assetTypeCountAvailability === 'undefined') {
    assertNeverOrThrow(assetTypeCountAvailability as never);
  }

  // D7547DE1-C058-4F07-91ED-C2F81A27C5BC Logic duplicated in the backend
  const errorMessage = disposalAssetTypeCount.allowMultipleCartEntries
    ? typeof disposalAssetTypeCount.customizedType === 'undefined' || disposalAssetTypeCount.customizedType === ''
      ? 'Please enter an asset type. Unsupported assets: UPS devices, batteries, and printers.'
      : disposalAssetTypeCount.customizedType.length > 25
      ? 'Please enter less than 25 characters'
      : undefined
    : undefined;

  return (
    <div
      className={className}
      css={css`
        display: flex;
        align-items: center;
        gap: 1rem;
        height: fit-content;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 1rem;
          `}
        >
          <Icon
            icon={assetTypeCountAvailability?.icon as IconType}
            sizePx={24}
          />
          <FormTitleInput
            fieldKey={customizedTypeFieldKey}
            placeholder={'Enter the asset type...'}
          />
        </div>

        {errorMessage && (
          <Label
            css={css`
              margin-left: 2.5rem;
              margin-top: 0.23rem;
              font-size: 80%;
              font-weight: 400;
            `}
            color={'negative-400'}
            size={'sm'}
          >
            {errorMessage}
          </Label>
        )}
      </div>

      <FormNumberStepperInput
        fieldKey={countFieldKey}
        min={1}
        isInvalid={isAssetCountInvalid}
      />
    </div>
  );
};

export default DisposalAssetCustomTypeCountInput;
