import { ScorecardItemDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardItem/Dto/Model.gen';
import ScaleSelect, { ScaleOption } from '~/neo-ui/packages/select/packages/scale-select/ScaleSelect';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import ScorecardItemFormData from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/form/ScorecardItemFormData';
import { FieldKeyExpression, FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import * as React from 'react';
import { PriorityDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Priority/Dto/Model.gen';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import PrioritySelect from '~/wm/packages/strategy/packages/priority/PrioritySelect';
import FormTextareaInput from '~/neo-ui/packages/form/packages/form-input/packages/form-textarea-input/FormTextareaInput';
import FormErrorMessage from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';

export type ScorecardItemScorePrioritySectionProps = {
  scorecardItem: ScorecardItemDto;
  scoreDisplayAvailabilities: ScaleOption[];
  priorityDisplayAvailabilities: PriorityDto[];
};

const ScorecardItemScorePrioritySection = ({
  scorecardItem,
  scoreDisplayAvailabilities,
  priorityDisplayAvailabilities,
}: ScorecardItemScorePrioritySectionProps) => {
  const { setFormInput } = useFormContext<ScorecardItemFormData>();

  // Priority
  const priorityFieldKey: FieldKeyExpression<ScorecardItemFormData> = values => values.priority;

  // Score
  const scoreValueFieldKey: FieldKeyExpression<ScorecardItemFormData> = values => values.scoreValue;

  const [selectedScore, setSelectedScore] = React.useState(
    scoreDisplayAvailabilities.find(option => option.value === scorecardItem.score.value.toString()) ?? undefined,
  );

  React.useEffect(() => {
    setSelectedScore(scoreDisplayAvailabilities.find(option => option.value === scorecardItem.score.value.toString()) ?? undefined);
  }, [scoreDisplayAvailabilities, scorecardItem.score.value]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <Header
          size={7}
          color={'dark-050'}
        >
          Score
        </Header>
        <Header
          size={7}
          color={'dark-050'}
        >
          Priority
        </Header>
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div>
          <ScaleSelect
            scaleOptions={scoreDisplayAvailabilities}
            selectedScaleOption={selectedScore}
            onScaleChange={option => {
              setSelectedScore(option);
              setFormInput<string>(scoreValueFieldKey, option.value);
            }}
          />
          <FormErrorMessage fieldKey={scoreValueFieldKey} />
        </div>
        <div>
          <PrioritySelect
            priorityAvailabilities={priorityDisplayAvailabilities}
            currentPriority={scorecardItem.priority}
            onSelectPriority={selectedPriority => {
              setFormInput<string>(priorityFieldKey, selectedPriority.value);
            }}
          />
          <FormErrorMessage fieldKey={priorityFieldKey} />
        </div>
      </div>
      <FormTextareaInput
        fieldKey={(values: FieldKeyExpressionSegment<ScorecardItemFormData>) => values.scoreDescription}
        placeholder={'Quickly describe why you gave this score to this category.'}
      />
    </div>
  );
};

export default ScorecardItemScorePrioritySection;
