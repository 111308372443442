/* eslint-disable import/no-internal-modules */
import * as React from 'react';
import { AsyncTypeahead, Highlighter, Menu, MenuItem } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { SumTypeCase } from '~/extensions/packages/sum-type/sumType';
import Theme, { themeToColor } from '~/neo-ui/packages/color/Theme';
import getSearchStyles from './getSearchStyles';
import Color from '~/neo-ui/packages/color/Color.gen';
import { Styleable } from '~/neo-ui/model/capacity';
import Testable from '~/neo-ui/packages/testable/Testable';
import { StringPropertyNames, TypeaheadResult } from '~/neo-ui/packages/search/types/searchTypes';
import { LabelKey, Option, RenderTokenProps } from 'react-bootstrap-typeahead/types/types';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';
import { css } from '@emotion/react';
import { RenderMenuProps } from 'react-bootstrap-typeahead/types/components/Typeahead';
import { TypeaheadMenuProps } from 'react-bootstrap-typeahead/types/components/TypeaheadMenu/TypeaheadMenu';
import Badge from '~/neo-ui/packages/badge/Badge';
import Icon from '~/neo-ui/packages/icon/Icon';

type FilterableKey<T> = T extends Record<string, unknown> ? StringPropertyNames<T> : never;

export type SearchFilteringProps<TData extends Record<string, unknown>> =
  | SumTypeCase<
      'keys',
      {
        /**
         * Properties in the data object to search based on
         */
        searchKeys: FilterableKey<TData>[];
      }
    >
  | SumTypeCase<
      'custom',
      {
        /**
         * Custom filtering logic
         */
        filterBy: (option: TData, input: string) => boolean;
      }
    >;

export type SelectionConfigureProps<TData> =
  | {
      multiSelect: true;

      /**
       *  What elements are selected
       */
      selected: TData[];
      /**
       * Callback when a result has been selected
       */
      onResultsSelected: (selected: TData[]) => void;
    }
  | {
      multiSelect: false;
      /**
       * Callback when a result has been selected
       */
      onResultSelected: (selected: TData) => void;
    };

type RenderNewSelectionPrefix = {
  /**
   * Optional prefix for new option
   */
  newSelectionPrefix: string;
  renderNewOptionRow?: never;
  onSelectNewOptionRow?: never;
};

type RenderNewOptionRow<TData> = {
  newSelectionPrefix?: never;
  /**
   * Determine how to render the new option row
   *
   * @param data the data associated with the row being rendered
   * @param highlight a HOC provided to highlight text
   */
  renderNewOptionRow?: (data: TData, highlight: (text: string) => React.ReactNode) => React.ReactNode;
  /**
   * Fires when the new option row is select either through click or
   * enter key
   */
  onSelectNewOptionRow?: () => void;
};

/**
 * Props used to configure the search component
 */
export type SearchConfigureProps<TData> = {
  /**
   * Optional property to allow create new options for the data set
   */
  allowNew?: boolean;

  /**
   * Optional prefix for new option
   */
  newSelectionPrefix?: string;
  /**
   * A property in the data object is required for the use of indexing the data
   */
  itemKey: FilterableKey<TData>;

  /**
   * Search placeholder message
   */
  placeholder: string;

  /**
   * Configure selection
   */
  selection: SelectionConfigureProps<TData>;

  /**
   * Determine how to render rows based on its data
   *
   * @param data the data associated with the row being rendered
   * @param highlight a HOC provided to highlight text
   * @param index
   */
  renderSearchRow: (data: TData, highlight: (text: string) => React.ReactNode, index: number) => React.ReactNode;
} & (RenderNewSelectionPrefix | RenderNewOptionRow<TData>);
export type SearchProps<TData extends Record<string, unknown>> = SearchConfigureProps<TData> & {
  /**
   * (Unique) options available for suggesting before filtering
   */
  options: TData[];

  /**
   * Callback to perform when search is triggered
   */
  onSearch?: (query: string) => void;

  /**
   * Whether or not results are loading
   */
  isLoading?: boolean;
  /**
   * Configure how to filter input, otherwise show all options
   */
  filtering?: SearchFilteringProps<TData>;

  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;

  tabIndex?: number;

  theme?: Theme;

  activeColor?: Color;

  renderTokenLabel?: (selectedItem: TData) => string;
  // renderTokenLabel?: (
  //   selectedItem: TData,
  //   props: TokenProps,
  //   index: number,
  // ) => React.ReactNode;
} & Styleable;

/**
 * Typeahead has no typing for their public methods
 */
type TypeaheadRef = {
  blur: () => void;
  clear: () => void;
  focus: () => void;
  getInput: () => HTMLInputElement;
};

const Search = <TData extends Record<string, unknown>>({
  allowNew = false,
  newSelectionPrefix = '',
  placeholder,
  renderSearchRow,
  options,
  itemKey,
  onSearch = () => {},
  isLoading = false,
  filtering,
  selection,
  onKeyDown,
  tabIndex,
  theme = 'secondary',
  activeColor = 'excellent-800',
  renderTokenLabel,
  renderNewOptionRow,
  onSelectNewOptionRow,
  className,
}: SearchProps<TData>) => {
  const typeaheadRef = React.useRef<Typeahead>(null);

  const onResultsSelected = (results: TData[]) => {
    if (selection.multiSelect) {
      selection.onResultsSelected(results);
    } else {
      selection.onResultSelected(results[0]);
    }

    if (typeaheadRef.current) {
      const typeaheadInstance = typeaheadRef.current;

      // On selection, focus input
      typeaheadInstance.focus();

      // On selection, clear input
      if (!selection.multiSelect) {
        typeaheadInstance.clear();
      }
    }
  };

  const renderMenu =
    renderNewOptionRow && allowNew
      ? (results: TypeaheadResult<TData>[], menuProps: RenderMenuProps) => {
          const items = results.map((result, index) => {
            if (result.customOption) {
              return (
                <MenuItem
                  key={index}
                  option={result}
                  position={index}
                >
                  <Testable testId={'search-component-option-new'}>
                    {renderNewOptionRow(result, itemText => (
                      <Highlighter search={menuProps.value?.toString() || ''}>{itemText}</Highlighter>
                    ))}
                  </Testable>
                </MenuItem>
              );
            }
            return (
              <MenuItem
                key={index}
                option={result}
                position={index}
              >
                {menuProps.renderMenuItemChildren!(result, menuProps as TypeaheadMenuProps, index)}
              </MenuItem>
            );
          });
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return <Menu {...menuProps}>{items}</Menu>;
        }
      : undefined;

  return (
    <AsyncTypeahead
      allowNew={allowNew}
      newSelectionPrefix={allowNew && newSelectionPrefix ? newSelectionPrefix : undefined}
      multiple={selection.multiSelect}
      useCache={false}
      // Comment out the button for now. Having trouble setState with this button.
      // clearButton={selection.multiSelect}
      // ID specified for accessibility
      id="search-component"
      isLoading={isLoading}
      onSearch={onSearch}
      ref={typeaheadRef}
      inputProps={{ tabIndex }}
      css={getSearchStyles(theme, activeColor)}
      labelKey={
        // Removed type-safety of this because it broke as part of lint engine update
        // Not taking time to strictify this since this module's logic is subject to change
        (filtering && filtering.key === 'keys' && filtering.searchKeys.length
          ? // search keys provided, use one of them
            filtering.searchKeys[0]
          : // use provided item key
            itemKey) as unknown as LabelKey
      }
      options={
        selection.multiSelect
          ? options
              // Trim selected items from options
              .filter(option => selection.selected.find(selectedItem => selectedItem[itemKey] === option[itemKey]) === undefined)
          : options
      }
      selected={selection.multiSelect ? selection.selected : undefined}
      placeholder={placeholder}
      renderMenuItemChildren={(option, { text: query }, index) => (
        <Testable testId={'search-component-option'}>
          {renderSearchRow(
            option as TData,
            itemText => (
              <Highlighter search={query || ''}>{itemText}</Highlighter>
            ),
            index,
          )}
        </Testable>
      )}
      autoFocus={false}
      filterBy={() => true}
      onChange={selected => {
        onResultsSelected(selected as TData[]);

        const latestSelectedOption = selected[selected.length - 1];

        if (
          typeof onSelectNewOptionRow !== 'undefined' &&
          typeof latestSelectedOption !== 'undefined' &&
          // We have to ts-ignore this because the typeahead library doesn't provide the proper type
          // Options have a boolean customOption prop
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          latestSelectedOption.customOption
        ) {
          onSelectNewOptionRow();
        }
      }}
      onKeyDown={e => typeof onKeyDown !== 'undefined' && onKeyDown(e)}
      renderToken={
        renderTokenLabel
          ? (option: Option, props: RenderTokenProps, idx: number) => (
              <Badge
                key={idx}
                bgColor={themeToColor(theme, '400')}
                textColor={'light-000'}
                fontWeight={'400'}
                borderRadius={'radius425'}
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                `}
              >
                <div>{renderTokenLabel(option as TData)}</div>
                <Icon
                  sizePx={12}
                  icon={'ActionClose'}
                  color={'light-000'}
                  css={css`
                    cursor: pointer;
                  `}
                  onClick={() => {
                    props.onRemove!(option);
                  }}
                  preventOnClickPropagation={true}
                />
              </Badge>
            )
          : undefined
      }
      renderMenu={renderMenu ? (results, menuProps) => renderMenu(results as TypeaheadResult<TData>[], menuProps) : undefined}
      className={className}
    />
  );
};

export default Search;
