import { ScopeKey } from '@AssetManagementClient/BeastClient/Beast/Settings/Scope.gen';
import * as React from 'react';
import FormatSettingsConfigurable from '~/wm/packages/settings/packages/regional-settings/configurable/FormatSettingsConfigurable';
import SettingsSection from '~/wm/packages/settings/packages/settings-section/SettingsSection';

export type RegionalSettingsProps = {
  scopeKey: ScopeKey;
  appSettingsUrl?: string;
};

const RegionalSettings: React.FunctionComponent<RegionalSettingsProps> = ({ scopeKey, appSettingsUrl }) => (
  <SettingsSection
    title="Time & Region"
    titleButtonText={appSettingsUrl ? 'Time & Region settings for all clients' : undefined}
    titleButtonIcon={appSettingsUrl ? 'Cog' : undefined}
    titleButtonLink={appSettingsUrl ? appSettingsUrl : undefined}
  >
    <FormatSettingsConfigurable scopeKey={scopeKey} />
  </SettingsSection>
);

export default RegionalSettings;
