import { createContext } from 'react';

export type LayoutPresentationContextValue = {
  isPresentationMode: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const LayoutPresentationContext = createContext<LayoutPresentationContextValue | undefined>(undefined);

export default LayoutPresentationContext;
