import useAutoEmailReportListMutation, {
  UseAutoEmailReportListMutationOptions,
} from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportListMutation';
import { autoEmailReportRecipientRemove } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseAutoEmailReportRecipientRemove = {
  removeAutoEmailReportRecipient: (autoEmailReportRecipientId: string, isRequestActive?: () => boolean) => void;
  isRemovingAutoEmailReportRecipient: boolean;
};

const useAutoEmailReportRecipientRemove = (
  organizationId: string,
  autoEmailReportId: string,
  options?: UseAutoEmailReportListMutationOptions,
): UseAutoEmailReportRecipientRemove => {
  const [removeAutoEmailReportRecipient, isRemovingAutoEmailReportRecipient] = useAutoEmailReportListMutation(
    (autoEmailReportRecipientId: string) =>
      autoEmailReportRecipientRemove({
        autoEmailReportId,
        autoEmailReportRecipientId,
        organizationId,
      }),
    options,
  );

  return {
    removeAutoEmailReportRecipient,
    isRemovingAutoEmailReportRecipient,
  };
};

export default useAutoEmailReportRecipientRemove;
