import { Styleable } from '~/neo-ui/model/capacity';
import * as React from 'react';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import DisableElementOverlay from '~/neo-ui/packages/overlay/DisableElementOverlay';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';

export type PeopleDashboardDropdownMenuHeaderAccountTeamMemberProps = {
  membersUrl: string;
  disabled: boolean;
} & Styleable;

const PeopleDashboardDropdownMenuHeaderAccountTeamMember: React.FunctionComponent<
  PeopleDashboardDropdownMenuHeaderAccountTeamMemberProps
> = ({ membersUrl, disabled }) => (
  <DisableElementOverlay
    disabled={disabled}
    css={css`
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    `}
  >
    <ButtonLink
      iconRight={'ArrowRight'}
      rightAlign={true}
      iconColorOverride={'dark-900'}
      anchor={{ href: membersUrl }}
      css={css`
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
      `}
    >
      <Label
        bold={true}
        color={'dark-900'}
        css={css`
          text-align: start;
        `}
      >
        See all members
      </Label>
    </ButtonLink>

    {disabled && (
      <Label
        size={'md'}
        color={'dark-900'}
      >
        You do not have permission to see and manage members. Please contact your admin.
      </Label>
    )}
  </DisableElementOverlay>
);

export default PeopleDashboardDropdownMenuHeaderAccountTeamMember;
