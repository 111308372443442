import React from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import Anchor from '~/neo-ui/packages/anchor/Anchor';
import routes from '~/router/types/routes';
import useTicketFieldAvailabilitiesContext from '~/wm/packages/integration/packages/ticket/context/hooks/useTicketFieldAvailabilitiesContext';
import { css } from '@emotion/react';

export type TicketIntegrationSetupHelpModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

const TicketIntegrationSetupHelpModal = ({ isOpen, onDismiss }: TicketIntegrationSetupHelpModalProps) => {
  const { integrationUrl } = useTicketFieldAvailabilitiesContext();

  return (
    <ModalConfirmation
      isOpen={isOpen}
      theme={'primary'}
      header={{
        title: 'Could not access Service Boards in ConnectWise Manage',
      }}
      footer={{
        confirmationButtonAlignment: 'left',
        confirmButton:
          typeof integrationUrl !== 'undefined'
            ? {
                label: 'Open ConnectWise Manage',
                icon: 'GoExternal',
                anchor: {
                  href: integrationUrl,
                  openInNewTab: true,
                },
              }
            : undefined,
        dismissButton: {
          label: 'Close',
          icon: 'ActionClose',
          onClick: onDismiss,
        },
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        `}
      >
        <Label>It looks like we're unable to access your Service Boards in ConnectWise Manage.</Label>
        <Label>
          This{' '}
          <Anchor
            href={routes.HELP_CENTER_ARTICLE_CONNECTWISE_MANAGE_INTEGRATION_INSTRUCTIONS.build()}
            shouldOpenInNewTab={true}
          >
            help article
          </Anchor>{' '}
          indicates how to adjust permissions in the system module to allow access to service boards.
        </Label>
        <Label>If this issue persists, please contact support.</Label>
      </div>
    </ModalConfirmation>
  );
};

export default TicketIntegrationSetupHelpModal;
