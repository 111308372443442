import useMutationInternal, {
  UseMutationInternal,
  UseMutationInternalApiCall,
  UseMutationInternalOptions,
} from '~/extensions/packages/hooks/useMutationInternal';
import useContractStatusSyncMutationContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/context/useContractStatusSyncMutationContext';

export type UseContractStatusSyncMutationOptions<TResponse = unknown> = UseMutationInternalOptions<TResponse>;

const useContractStatusSyncMutation = <TArg = Record<string, unknown>>(
  apiCall: UseMutationInternalApiCall<TArg>,
  options?: UseContractStatusSyncMutationOptions,
): UseMutationInternal<TArg> => {
  const { triggerContractSyncStatusReload } = useContractStatusSyncMutationContext();

  return useMutationInternal(apiCall, isRequestActive => triggerContractSyncStatusReload(isRequestActive), options);
};

export default useContractStatusSyncMutation;
