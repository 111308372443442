import useAutoEmailReportListMutation, {
  UseAutoEmailReportListMutationOptions,
} from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportListMutation';
import { autoEmailReportPreviewSend } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseAutoEmailReportTestSend = {
  sendAutoEmailReportTest: (autoEmailReportId: string, isRequestActive?: () => boolean) => void;
  isSendingAutoEmailReportTest: boolean;
};
const useAutoEmailReportTestSend = (
  organizationId: string,
  options?: UseAutoEmailReportListMutationOptions,
): UseAutoEmailReportTestSend => {
  const [sendAutoEmailReportTest, isSendingAutoEmailReportTest] = useAutoEmailReportListMutation(
    (autoEmailReportId: string) =>
      autoEmailReportPreviewSend({
        organizationId,
        autoEmailReportId,
      }),
    options,
  );

  return {
    sendAutoEmailReportTest,
    isSendingAutoEmailReportTest,
  };
};

export default useAutoEmailReportTestSend;
