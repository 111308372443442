import { PageEnum } from '@AssetManagementClient/AssetManagement/Packages/Share/Packages/Send/Model.gen';
import * as React from 'react';
import useHardwareConsoleFilters from '~/wm/packages/insight-share/packages/insight-share-button/hooks/useHardwareConsoleFilters';
import InsightShareButton from '~/wm/packages/insight-share/packages/insight-share-button/InsightShareButton';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';

export type HardwareInsightShareButtonProps = {
  userEmail: string;
  inviteMembersUrl: string;
  canInviteMembers: boolean;
  frontendScope: FrontendScope;
};

const HardwareInsightShareButton: React.FunctionComponent<HardwareInsightShareButtonProps> = ({
  userEmail,
  inviteMembersUrl,
  canInviteMembers,
  frontendScope,
}) => {
  const { filters } = useHardwareConsoleFilters();

  return (
    <InsightShareButton
      pageEnum={PageEnum.HamConsole}
      frontendScope={frontendScope}
      inviteMembersUrl={inviteMembersUrl}
      canInviteMembers={canInviteMembers}
      userEmail={userEmail}
      filters={filters}
    />
  );
};

export default HardwareInsightShareButton;
