import Header from '~/neo-ui/packages/text/packages/header/Header';
import { css } from '@emotion/react';
import React from 'react';

export const HeaderTwo: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => (
  <Header
    size={2}
    weight={'bold'}
    css={css`
      margin-bottom: 0.5rem;
    `}
  >
    {children}
  </Header>
);
