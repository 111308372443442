import { getCountryByIso2 } from '~/neo-ui/model/Country';
import { BillingAddressDto } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';

const formatBillingAddressDto = (billingAddress: BillingAddressDto, includePhone?: boolean): string => {
  const addressLines = [billingAddress.addressLine1, billingAddress.addressLine2];

  const billingAddressLines = addressLines.filter(field => !!field?.length).join(' ');

  const addressFields = [
    billingAddressLines,
    billingAddress.city,
    billingAddress.provinceStateCode,
    getCountryByIso2(billingAddress.countryCode)?.name,
    billingAddress.postalZipCode,
    ...(includePhone ? [billingAddress.phoneNumber] : []),
  ];

  return addressFields.filter(field => !!field?.length).join(', ');
};

export default formatBillingAddressDto;
