import * as React from 'react';
import StrategyDashboardUrlContext from '~/wm/packages/strategy/packages/strategy-dashboard/context/StrategyDashboardUrlContext';

export type StrategyDashboardUrlProviderProps = {
  upgradeUrl: string;
  growUrl: string;
  roadmapUrl: string;
  engagementActionListUrl: string;
  engagementNoteListUrl: string;
};

const StrategyDashboardUrlProvider: React.FunctionComponent<React.PropsWithChildren<StrategyDashboardUrlProviderProps>> = ({
  upgradeUrl,
  growUrl,
  roadmapUrl,
  engagementActionListUrl,
  engagementNoteListUrl,
  children,
}) => (
  <StrategyDashboardUrlContext.Provider
    value={{
      upgradeUrl,
      growUrl,
      roadmapUrl,
      engagementActionListUrl,
      engagementNoteListUrl,
    }}
  >
    {children}
  </StrategyDashboardUrlContext.Provider>
);

export default StrategyDashboardUrlProvider;
