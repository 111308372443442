import { css } from '@emotion/react';
import * as React from 'react';
import { formatDateFriendly, formatTimeFriendly } from '~/extensions/packages/date/formatDate';

export type FriendlyDateTimeLineProps = {
  date: Date;
};

const FriendlyDateTimeLine: React.FunctionComponent<FriendlyDateTimeLineProps> = ({ date }) => (
  <span>
    {formatDateFriendly(date)}
    <span
      css={css`
        margin-left: 0.3125rem;
        margin-right: 0.3125rem;
      `}
    >
      •
    </span>
    {formatTimeFriendly(date)}
  </span>
);

export default FriendlyDateTimeLine;
