import theme from '~/sp-ui/theme';
import Icon from '~/neo-ui/packages/icon/Icon';
import * as React from 'react';
import useSubscriptionPdContact from '~/wm/packages/subscription/packages/subscription-pd-contact/hooks/useSubscriptionPdContact';

const ContactSalesToManagePlanBanner = () => {
  const { pdDisplayInfo } = useSubscriptionPdContact();

  return (
    <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div
        css={[
          {
            display: 'grid',
            gridTemplateColumns: 'auto',
            gridGap: '1rem',
            alignItems: 'left',
            padding: '2rem',
            borderRadius: '8px',
            backgroundColor: theme.palette.white,
            width: '41.25rem', // 660px
          },
          theme.shadows[2],
        ]}
      >
        <h4 css={{ fontWeight: 'bold' }}>Need to manage your plan?</h4>
        {pdDisplayInfo === undefined ? (
          <span>Please give us a call</span>
        ) : (
          <span>
            <a
              href={pdDisplayInfo.meetingUrl}
              target={'_blank'}
            >
              Book a call
            </a>{' '}
            directly with your Partner Success Manager, or give us a call.
          </span>
        )}
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <span>
            North American Sales Team: <a href={'tel:14159852895'}>+1 415-985-2895</a>
          </span>
          <span>
            EMEA Sales Team: <a href={'tel:442039669102'}>+44 20 3966 9102</a>
          </span>
        </div>
        <div>
          <span>For more ways to get in touch, please </span>
          <a
            href={'https://www.scalepad.com/contact/'}
            target={'_blank'}
          >
            visit our website <Icon icon={'GoExternal'} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactSalesToManagePlanBanner;
