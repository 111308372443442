import { PriorityDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Priority/Dto/Model.gen';
import { BudgetCurrencyDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Dto/Model.gen';
import { ScorecardItemDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardItem/Dto/Model.gen';
import { css } from '@emotion/react';
import { ScaleOption } from '~/neo-ui/packages/select/packages/scale-select/ScaleSelect';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import ScorecardItemScorePrioritySection from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/packages/scorecard-item-score-priority-section/ScorecardItemScorePrioritySection';
import ScorecardItemRecommendationSection from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-item/packages/scorecard-item-recommendation-section/ScorecardItemRecommendationSection';

export type ScorecardItemProps = {
  scorecardItem: ScorecardItemDto;
  currency: BudgetCurrencyDto;
  scoreDisplayAvailabilities: ScaleOption[];
  priorityDisplayAvailabilities: PriorityDto[];
};

const ScorecardItem = ({ scorecardItem, currency, scoreDisplayAvailabilities, priorityDisplayAvailabilities }: ScorecardItemProps) => (
  <div
    css={css`
      display: grid;
      grid-template-columns: 3fr 0fr 2fr;
      padding: 1rem;
    `}
  >
    <ScorecardItemScorePrioritySection
      scorecardItem={scorecardItem}
      scoreDisplayAvailabilities={scoreDisplayAvailabilities}
      priorityDisplayAvailabilities={priorityDisplayAvailabilities}
    />
    <div
      css={css`
        border-left: ${colorToCode('dark-900-12')} 0.0625rem solid;
        border-right: ${colorToCode('dark-900-12')} 0.0625rem solid;
        margin: 0 0.625rem;
      `}
    />
    <ScorecardItemRecommendationSection
      scorecardItem={scorecardItem}
      currency={currency}
    />
  </div>
);

export default ScorecardItem;
