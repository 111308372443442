import ScorecardCreateButton from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-create-button/ScorecardCreateButton';
import ScorecardListSelect from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-list/packages/scorecard-list-select/ScorecardListSelect';
import ScorecardDeleteButton from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-delete/packages/scorecard-delete-button/ScorecardDeleteButton';
import ScorecardShareButton from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-share-button/ScorecardShareButton';
import * as React from 'react';
import LayoutHeader from '~/neo-ui/packages/layout/packages/header/LayoutHeader';

export type ScorecardLayoutHeaderProps = {
  organizationId: string;
  scorecardPreviewUrl: string;
  disableScorecardListSelect: boolean;
};

const ScorecardLayoutHeader = ({ organizationId, scorecardPreviewUrl, disableScorecardListSelect }: ScorecardLayoutHeaderProps) => (
  <LayoutHeader
    leftControls={[
      {
        expanded: <ScorecardCreateButton />,
      },
      {
        expanded: <ScorecardListSelect disabled={disableScorecardListSelect} />,
      },
    ]}
    rightControls={[
      {
        expanded: <ScorecardDeleteButton />,
      },
      {
        expanded: (
          <ScorecardShareButton
            scorecardPreviewUrl={scorecardPreviewUrl}
            organizationId={organizationId}
          />
        ),
      },
    ]}
  />
);

export default ScorecardLayoutHeader;
