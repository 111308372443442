import React from 'react';
import { css } from '@emotion/react';
import Spinner from '~/neo-ui/spinner/Spinner';

const WarrantyCartLoader: React.FunctionComponent = () => {
  return (
    <div
      css={css`
        .centerScreen {
          position: absolute;
          top: 50vh;
          left: 50vw;
        }
      `}
    >
      <Spinner
        size={'md'}
        className={'centerScreen'}
      />
    </div>
  );
};

export default WarrantyCartLoader;
