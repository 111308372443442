import { css } from '@emotion/react';
import * as React from 'react';
import ActionButton from '~/neo-ui/packages/button/packages/action-button/ActionButton';

export type DownloadLinkProps = {
  downloadUrl: string;
  tooltipContent: string;
};

/**
 * @deprecated Use Button instead, which implements ButtonInternal
 */
const DownloadLink: React.FunctionComponent<DownloadLinkProps> = ({ downloadUrl, tooltipContent }) => (
  <ActionButton
    icon={'Download'}
    activeBackgroundColor={'primary-400'}
    actionUrl={downloadUrl}
    tooltipContent={
      <div>
        <p
          css={css`
            margin-bottom: 0;
          `}
        >
          {tooltipContent}
        </p>
      </div>
    }
  />
);

export default DownloadLink;
