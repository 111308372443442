import React from 'react';
import { css } from '@emotion/react';
import { Styleable } from '~/neo-ui/model/capacity';
import Icon from '~/neo-ui/packages/icon/Icon';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Popup from '../popup/Popup';
import Button from '~/neo-ui/packages/button/Button';
import { popoverButtonStyles } from '~/neo-ui/packages/popover/Popover';
import { Placement } from '@popperjs/core';

export type PopoverWithPopupProps = {
  header: React.ReactNode | ((isOpen: boolean) => React.ReactNode);
  popoverAlign?: Placement;

  disabled?: boolean;
  disabledTooltipContent?: string;
  children: React.ReactNode | ((close: () => void) => React.ReactNode);
} & Styleable;

const PopoverWithPopup: React.FunctionComponent<PopoverWithPopupProps> = ({
  header,
  popoverAlign,
  disabled = false,
  disabledTooltipContent,
  className,
  children,
}) => {
  const [isDisplayed, setDisplayed] = React.useState<boolean>(false);

  /**
   * Dynamic headers
   */
  let headerComponent: React.ReactNode;

  if (typeof header === 'function') {
    headerComponent = header(isDisplayed);
  } else if (typeof header === 'string') {
    headerComponent = (
      <div
        css={css`
          padding: 0 10px;
          display: flex;
          align-items: center;
          gap: 5px;
        `}
      >
        {header}
        <Icon
          icon={isDisplayed ? 'ArrowUp' : 'ArrowDown'}
          color={'dark-900'}
        />
      </div>
    );
  } else {
    headerComponent = header;
  }

  const popoverContent: React.ReactNode = isDisplayed ? (
    <div>{typeof children === 'function' ? children(() => setDisplayed(false)) : children}</div>
  ) : undefined;

  const popoverHeader: React.ReactNode = (
    <Button
      className={'popover-header'}
      disabled={disabled}
      onClick={disabled ? undefined : () => setDisplayed(!isDisplayed)}
      css={[popoverButtonStyles]}
    >
      {headerComponent}
    </Button>
  );

  const tooltipWrapper = (component: JSX.Element, tooltipContent: string | undefined) => {
    if (!tooltipContent || tooltipContent === '') {
      return component;
    }

    return (
      <Tooltip
        content={tooltipContent}
        placement={'left'}
      >
        {component}
      </Tooltip>
    );
  };

  const popoverButtonAndDropDown = (
    <Popup
      className={className}
      isOpen={isDisplayed}
      onClose={() => setDisplayed(false)}
      placement={popoverAlign}
      backgroundColor={'light-000'}
      content={popoverContent}
    >
      {popoverHeader}
    </Popup>
  );

  return disabled ? tooltipWrapper(popoverButtonAndDropDown, disabledTooltipContent) : popoverButtonAndDropDown;
};

export default PopoverWithPopup;
