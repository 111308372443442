import * as React from 'react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import {
  SubscriptionAgreementEnum,
  SubscriptionAgreementInfo,
} from '~/wm/packages/subscription/packages/manage/wizard/model/SubscriptionAgreementInfo';
import ProductPlanSummary from '~/wm/packages/subscription/packages/plan/packages/product-plan-summary/ProductPlanSummary';
import { PricingDto } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/PricingModel.gen';
import useSelfServePricingDtoGet from '~/wm/packages/subscription/packages/pricing-dto/hooks/useSelfServePricingDtoGet';
import TermSummary from '~/wm/packages/subscription/packages/term/packages/term-summary/TermSummary';
import { SubscriptionBillingPeriodEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription.gen';
import { UpgradeFormData } from '~/wm/packages/subscription/packages/upgrade/wizard/SubscriptionUpgradeWizard';
import { GrowFormData } from '~/wm/packages/subscription/packages/grow/wizard/SubscriptionGrowWizard';
import NextTotalInvoiceSummary from '~/wm/packages/subscription/packages/next-invoice/packages/next-total-invoice-summary/NextTotalInvoiceSummary';

export type SubscriptionAgreementStepSummaryProps<T> = {
  subscriptionAgreementInfo: SubscriptionAgreementInfo;
  subscriptionAgreementLink: AnchorLocation;
  isNewSubscription: boolean;
};

const SubscriptionAgreementStepSummary = <T,>({
  subscriptionAgreementInfo,
  subscriptionAgreementLink,
  isNewSubscription,
}: SubscriptionAgreementStepSummaryProps<T>) => {
  const { formData } = useFormContext<UpgradeFormData | GrowFormData>();

  const [selectedTierIds, setSelectedTierIds] = React.useState<string[]>([]);

  React.useEffect(() => {
    switch (subscriptionAgreementInfo.case) {
      case SubscriptionAgreementEnum.Preset:
        setSelectedTierIds([]);
        break;
      case SubscriptionAgreementEnum.SelfServe:
        const selectedTiers = Array<string>();
        if (formData) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          Object.keys(formData.selectedTierIds).forEach(key => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const tierId = formData.selectedTierIds[key];
            if (tierId !== undefined) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              selectedTiers.push(tierId);
            }
          });
        }
        setSelectedTierIds(selectedTiers);
        break;
    }
  }, [formData, subscriptionAgreementInfo.case]);

  let pricingDto: PricingDto;
  switch (subscriptionAgreementInfo.case) {
    case SubscriptionAgreementEnum.SelfServe:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const selfServeResult = useSelfServePricingDtoGet(selectedTierIds);
      if (selfServeResult.isLoading) {
        return null;
      }
      pricingDto = selfServeResult.pricingDto!;
      break;
    case SubscriptionAgreementEnum.Preset:
      pricingDto = subscriptionAgreementInfo.pricingDto;
      break;
  }

  return (
    <div>
      <ProductPlanSummary
        pricingDto={pricingDto}
        isOffer={true}
      />
      <NextTotalInvoiceSummary
        priceDto={pricingDto?.priceDto}
        isFirstPayment={isNewSubscription}
        isInForm={true}
      />
      <TermSummary
        term={subscriptionAgreementInfo.term}
        billingPeriod={SubscriptionBillingPeriodEnum.Monthly}
        subscriptionAgreementLink={{
          href: subscriptionAgreementLink.href,
          openInNewTab: true,
        }}
      />
    </div>
  );
};

export default SubscriptionAgreementStepSummary;
