import * as React from 'react';
import BannerInternal from '~/neo-ui/packages/banner/BannerInternal';
import { Styleable } from '~/neo-ui/model/capacity';
import BoxShadow from '~/neo-ui/packages/style/BoxShadow';
import CallToAction from '~/neo-ui/model/CallToAction';

export type BannerUpgradeProps = {
  id?: string;
  title: string;
  description: React.ReactNode;
  imageSource: string;
  badge?: string;
  callToAction?: CallToAction;
  onClose?: () => void;
  openInNewTab?: boolean;
  boxShadow?: BoxShadow;
} & Styleable;

/**
 * A banner to communicate a path to upgrading or demo new features with a call to action
 */
const BannerUpgrade = ({
  id,
  title,
  description,
  badge,
  imageSource,
  callToAction,
  onClose,
  openInNewTab = false,
  boxShadow = 'shadow300',
  className,
}: BannerUpgradeProps) => (
  <BannerInternal
    id={id}
    titleProps={{
      children: title,
      size: 3,
      color: 'secondary-400',
      weight: 'bold',
    }}
    descriptionProps={{
      children: description,
      size: 4,
      color: 'dark-900',
    }}
    badge={badge}
    graphic={{ imageSource }}
    boxShadow={boxShadow}
    backgroundColor={'light-050'}
    callToAction={callToAction}
    onClose={onClose}
    openInNewTab={openInNewTab}
    className={className}
  />
);

export default BannerUpgrade;
