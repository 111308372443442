import InitiativeSaveControlsSection from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-controls-section/InitiativeSaveControlsSection';
import { css } from '@emotion/react';
import InitiativeViewDetailsSection from '~/wm/packages/strategy/packages/initiative/packages/initiative-view/packages/initiative-view-details-section/InitiativeViewDetailsSection';
import InitiativeViewFeesSection from '~/wm/packages/strategy/packages/initiative/packages/initiative-view/packages/initiative-view-fees-section/InitiativeViewFeesSection';
import InitiativeViewAssetsSection from '~/wm/packages/strategy/packages/initiative/packages/initiative-view/packages/initiative-view-assets-section/InitiativeViewAssetsSection';

export type InitiativeViewProps = {
  onEdit: () => void;
};

const InitiativeView = ({ onEdit }: InitiativeViewProps) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      gap: 1.88rem;
    `}
  >
    <InitiativeSaveControlsSection />
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1.88rem;
        padding: 0 0.3125rem;
      `}
    >
      <InitiativeViewDetailsSection onEdit={onEdit} />
      <InitiativeViewFeesSection />
      <InitiativeViewAssetsSection />
    </div>
  </div>
);

export default InitiativeView;
