const strategyDataCollectionSources = {
  // Source strings are duplicated in the frontend A7C62FCE-1AD4-489E-A71D-5D3EFD9ACCD3
  initiativeCreationSource: {
    roadmapSuggestion: 'roadmap-suggestion',
    insightView: 'insight-view',
    roadmapQuickCreate: 'roadmap-quick-create',
    initiativeListView: 'initiative-list-view',
    universalConsole: 'universal-console',
    engagementActionList: 'engagement-action-list',
    engagementClientDashboard: 'engagement-client-dashboard',
  } as const,
  initiativeAssetsAddedSource: {
    initiativeEditInsight: 'initiative-edit-insight',
    initiativeEditManual: 'initiative-edit-manual',
    insightView: 'insight-view',
    roadmapSuggestion: 'roadmap-suggestion',
    roadmapQuickCreate: 'roadmap-quick-create',
    universalConsole: 'universal-console',
  } as const,
};

type InitiativeCreateSourceKey = keyof typeof strategyDataCollectionSources.initiativeCreationSource;

export type InitiativeCreateSource = (typeof strategyDataCollectionSources.initiativeCreationSource)[InitiativeCreateSourceKey];

type InitiativeAssetsAddedSourceKey = keyof typeof strategyDataCollectionSources.initiativeAssetsAddedSource;

export type InitiativeAssetsAddedSource =
  (typeof strategyDataCollectionSources.initiativeAssetsAddedSource)[InitiativeAssetsAddedSourceKey];

export default strategyDataCollectionSources;
