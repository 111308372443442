import Theme, { removeLegacyTheme } from '~/neo-ui/packages/color/Theme';
import { useMemo } from 'react';
import { ButtonInternalColorMap } from '~/neo-ui/packages/button/ButtonInternal';

export type ThemeMapButtonOutline = ButtonInternalColorMap;

type UseThemeButtonOutline = { themeMap: ThemeMapButtonOutline };

/**
 * Provides a breakdown of colors to use for an ButtonOutline
 *
 * @see useTheme - Originally defines standardization for theme colors
 * @see ButtonOutline - The component that uses this hook for theming
 */
const useThemeButtonOutline = (theme?: Theme): UseThemeButtonOutline => {
  const themeMap = useMemo(() => themeToThemeMapButtonOutline(theme), [theme]);

  return { themeMap };
};

const themeToThemeMapButtonOutline = (theme?: Theme): ThemeMapButtonOutline => {
  switch (removeLegacyTheme(theme)) {
    case 'primary':
      return {
        backgroundColorRest: 'primary-100',
        backgroundColorHover: 'light-000',
        backgroundColorActive: 'primary-200',
        backgroundColorFocus: 'primary-100',

        textColorRest: 'primary-500',
        textColorHover: 'primary-500',
        textColorActive: 'primary-500',
        textColorFocus: 'primary-500',

        borderColorRest: 'primary-500',
        borderColorHover: 'primary-500',
        borderColorActive: 'primary-500',
        borderColorFocus: 'primary-500',
      };
    case 'secondary':
      return {
        backgroundColorRest: 'secondary-100',
        backgroundColorHover: 'light-000',
        backgroundColorActive: 'secondary-200',
        backgroundColorFocus: 'secondary-100',

        textColorRest: 'secondary-500',
        textColorHover: 'secondary-500',
        textColorActive: 'secondary-500',
        textColorFocus: 'secondary-500',

        borderColorRest: 'secondary-500',
        borderColorHover: 'secondary-500',
        borderColorActive: 'secondary-500',
        borderColorFocus: 'secondary-500',
      };
    case 'negative':
      return {
        backgroundColorRest: 'negative-100',
        backgroundColorHover: 'light-000',
        backgroundColorActive: 'negative-200',
        backgroundColorFocus: 'negative-100',

        textColorRest: 'negative-500',
        textColorHover: 'negative-500',
        textColorActive: 'negative-500',
        textColorFocus: 'negative-500',

        borderColorRest: 'negative-500',
        borderColorHover: 'negative-500',
        borderColorActive: 'negative-500',
        borderColorFocus: 'negative-500',
      };
    case 'warning':
      return {
        backgroundColorRest: 'warning-100',
        backgroundColorHover: 'light-000',
        backgroundColorActive: 'warning-200',
        backgroundColorFocus: 'warning-100',

        textColorRest: 'warning-500',
        textColorHover: 'warning-500',
        textColorActive: 'warning-500',
        textColorFocus: 'warning-500',

        borderColorRest: 'warning-500',
        borderColorHover: 'warning-500',
        borderColorActive: 'warning-500',
        borderColorFocus: 'warning-500',
      };
    case 'caution':
      return {
        backgroundColorRest: 'caution-100',
        backgroundColorHover: 'light-000',
        backgroundColorActive: 'caution-200',
        backgroundColorFocus: 'caution-100',

        textColorRest: 'caution-500',
        textColorHover: 'caution-500',
        textColorActive: 'caution-500',
        textColorFocus: 'caution-500',

        borderColorRest: 'caution-500',
        borderColorHover: 'caution-500',
        borderColorActive: 'caution-500',
        borderColorFocus: 'caution-500',
      };
    case 'positive':
      return {
        backgroundColorRest: 'positive-100',
        backgroundColorHover: 'light-000',
        backgroundColorActive: 'positive-200',
        backgroundColorFocus: 'positive-100',

        textColorRest: 'positive-500',
        textColorHover: 'positive-500',
        textColorActive: 'positive-500',
        textColorFocus: 'positive-500',

        borderColorRest: 'positive-500',
        borderColorHover: 'positive-500',
        borderColorActive: 'positive-500',
        borderColorFocus: 'positive-500',
      };
    case 'info':
      return {
        backgroundColorRest: 'info-050',
        backgroundColorHover: 'light-000',
        backgroundColorActive: 'info-200',
        backgroundColorFocus: 'info-100',

        textColorRest: 'info-800',
        textColorHover: 'info-800',
        textColorActive: 'info-800',
        textColorFocus: 'info-800',

        borderColorRest: 'info-800',
        borderColorHover: 'info-800',
        borderColorActive: 'info-800',
        borderColorFocus: 'info-800',
      };
    case 'excellent':
      return {
        backgroundColorRest: 'excellent-100',
        backgroundColorHover: 'light-000',
        backgroundColorActive: 'excellent-200',
        backgroundColorFocus: 'excellent-100',

        textColorRest: 'excellent-500',
        textColorHover: 'excellent-500',
        textColorActive: 'excellent-500',
        textColorFocus: 'excellent-500',

        borderColorRest: 'excellent-500',
        borderColorHover: 'excellent-500',
        borderColorActive: 'excellent-500',
        borderColorFocus: 'excellent-500',
      };
    // light
    default:
      return {
        backgroundColorRest: 'light-000',
        backgroundColorHover: 'light-000',
        backgroundColorActive: 'light-000',
        backgroundColorFocus: 'light-000',

        textColorRest: 'dark-700',
        textColorHover: 'dark-700',
        textColorActive: 'dark-700',
        textColorFocus: 'dark-700',

        borderColorRest: 'dark-900-24',
        borderColorHover: 'dark-900-24',
        borderColorActive: 'dark-900-24',
        borderColorFocus: 'dark-900-24',
      };
  }
};

export default useThemeButtonOutline;
