import { useContext } from 'react';
import DisposalOrderInfoContext from '~/wm/packages/disposal/packages/order-info/context/DisposalOrderInfoContext';

const useDisposalOrderInfoContext = () => {
  const disposalOrderInfoContext = useContext(DisposalOrderInfoContext);
  if (!disposalOrderInfoContext) {
    throw new Error('Tried to use DisposalOrderInfoContext outside of an DisposalOrderInfoProvider');
  }

  return disposalOrderInfoContext;
};

export default useDisposalOrderInfoContext;
