import { css } from '@emotion/react';
import React from 'react';
import Toolbar from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import LayoutHeaderFooterProps from '~/neo-ui/packages/layout/types/LayoutHeaderFooterProps';
import LayoutBar from '~/neo-ui/packages/layout/packages/bar/LayoutBar';

/**
 * A footer that sticks to the bottom of the parent.
 */
const LayoutFooterReact = ({ className, leftControls, rightControls, layoutBarComponent, toolbarClassName }: LayoutHeaderFooterProps) => (
  <div
    className={className}
    css={css`
      position: sticky;
      bottom: 0;
    `}
  >
    {layoutBarComponent && (
      <LayoutBar
        css={css`
          margin-bottom: -0.0625rem;
        `}
      >
        {layoutBarComponent}
      </LayoutBar>
    )}
    {(leftControls || rightControls) && (
      <Toolbar
        leftControls={leftControls}
        rightControls={rightControls}
        css={toolbarClassName}
      />
    )}
  </div>
);

export default LayoutFooterReact;
