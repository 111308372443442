import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import React from 'react';
import FilterHeaderEmpty from '~/neo-ui/packages/filter/packages/filter-headers/filter-header-common/filter-header-empty/FilterHeaderEmpty';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import ColumnSelect from '~/neo-ui/packages/table/packages/console/column/ColumnSelect';
import Popover from '~/neo-ui/packages/popover/Popover';

export type ConsoleSelectButtonProps = {
  columnOptions: SelectOption[];
  selectedColumns: Set<string>;
  onColumnChange: (columns: Set<string>) => void;
};

const ColumnSelectButton = ({ columnOptions, selectedColumns, onColumnChange }: ConsoleSelectButtonProps) => {
  return (
    <Popover
      header={
        <FilterHeaderEmpty
          title={'Choose columns...'}
          prependIcon={'Columns' as IconType}
        />
      }
      popoverAlign={'bottom right'}
    >
      <ColumnSelect
        options={columnOptions}
        selectedColumns={selectedColumns}
        onColumnChange={onColumnChange}
      />
    </Popover>
  );
};

export default ColumnSelectButton;
