import React from 'react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { LifecycleReportCustomizationSettingsFormData } from '~/neo-ui/packages/table/packages/console/download/packages/lifecycle-report-download-button/packages/lifecycle-report-customization-settings-form/LifecycleReportCustomizationSettingsForm';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { css } from '@emotion/react';
import FormCheckboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckboxInput';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import useDownloadLifecycleReportPdf from '~/neo-ui/packages/table/packages/console/download/hooks/useDownloadLifecycleReportPdf';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import useAccount from '~/wm/hooks/useAccount';

export type LifecycleReportDownloadModalProps = {
  organizationId: string;
  isOpen: boolean;
  lifecycleReportDownloadLocation: string;
  onDismiss: () => void;
  lmLastCheckinDateEnabled: boolean;
};

const showCpuFieldKey: FieldKeyExpression<LifecycleReportCustomizationSettingsFormData> = values => values.showCpu;

const showRamFieldKey: FieldKeyExpression<LifecycleReportCustomizationSettingsFormData> = values => values.showRam;

const showStorageFieldKey: FieldKeyExpression<LifecycleReportCustomizationSettingsFormData> = values => values.showStorage;

const showLastCheckinDateFieldKey: FieldKeyExpression<LifecycleReportCustomizationSettingsFormData> = values => values.showLastCheckinDate;

const shouldSaveDefaultSettingsFieldKey: FieldKeyExpression<LifecycleReportCustomizationSettingsFormData> = values =>
  values.shouldSaveDefaultSettings;

const LifecycleReportDownloadModal: React.FunctionComponent<LifecycleReportDownloadModalProps> = ({
  organizationId,
  isOpen,
  onDismiss,
  lifecycleReportDownloadLocation,
  lmLastCheckinDateEnabled,
}) => {
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);
  const { subscriptionIdentity } = useAccount();

  const { downloadLifecycleReport, isLoading } = useDownloadLifecycleReportPdf(onDismiss);
  const { getFormInput, setFormInput } = useFormContext<LifecycleReportCustomizationSettingsFormData>();

  const [isShowCpuEnabled, setShowCpuEnabled] = React.useState<boolean>(getFormInput(showCpuFieldKey).value);

  const [isShowRamEnabled, setShowRamEnabled] = React.useState<boolean>(getFormInput(showRamFieldKey).value);

  const [isShowStorageEnabled, setShowStorageEnabled] = React.useState<boolean>(getFormInput(showStorageFieldKey).value);

  const [isShowLastCheckinEnabled, setShowLastCheckinEnabled] = React.useState<boolean>(getFormInput(showLastCheckinDateFieldKey).value);

  const [isShouldSaveDefaultSettingsEnabled, setShouldSaveDefaultSettingsEnabled] = React.useState<boolean>(
    getFormInput(shouldSaveDefaultSettingsFieldKey).value,
  );

  const hasAdditionalColumn = isShowCpuEnabled || isShowRamEnabled || isShowStorageEnabled || isShowLastCheckinEnabled;

  return (
    <ModalConfirmation
      header={{
        title: 'Customize your Hardware Lifecycle Report',
        icon: 'PDF',
      }}
      theme={'primary'}
      footer={{
        confirmButton: {
          icon: 'PDF',
          label: `Download`,
          loading: isLoading,
          disabled: isLoading,
          onClick: async () => {
            downloadLifecycleReport(
              organizationId,
              hasAdditionalColumn
                ? {
                    showCpu: isShowCpuEnabled,
                    showRam: isShowRamEnabled,
                    showStorage: isShowStorageEnabled,
                    showLastCheckInDate: isShowLastCheckinEnabled,
                  }
                : undefined,
              isShouldSaveDefaultSettingsEnabled,
            );

            trackEventProcessor('report_hw_downloaded', {
              obfuscatedClientId: organizationId,
              downloadSource: lifecycleReportDownloadLocation,
            });
          },
        },
        dismissButton: {
          icon: 'Cancel',
          label: 'Cancel',
          disabled: isLoading,
          onClick: onDismiss,
        },
      }}
      isOpen={isOpen}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.325rem;
        `}
      >
        <Label size={'md'}>Choose any additional columns you may want to add to your report.</Label>
        <FormCheckboxInput
          fieldKey={showCpuFieldKey}
          onChange={e => {
            setFormInput<boolean>(showCpuFieldKey, e.target.checked);
            setShowCpuEnabled(e.target.checked);
          }}
          checked={isShowCpuEnabled}
          description={'CPU'}
        />
        <FormCheckboxInput
          fieldKey={showRamFieldKey}
          onChange={e => {
            setFormInput<boolean>(showRamFieldKey, e.target.checked);
            setShowRamEnabled(e.target.checked);
          }}
          checked={isShowRamEnabled}
          description={'RAM'}
        />
        <FormCheckboxInput
          fieldKey={showStorageFieldKey}
          onChange={e => {
            setFormInput<boolean>(showStorageFieldKey, e.target.checked);
            setShowStorageEnabled(e.target.checked);
          }}
          checked={isShowStorageEnabled}
          description={'Storage'}
        />
        {!subscriptionIdentity.isLmFree && lmLastCheckinDateEnabled && (
          <FormCheckboxInput
            fieldKey={showLastCheckinDateFieldKey}
            onChange={e => {
              setFormInput<boolean>(showLastCheckinDateFieldKey, e.target.checked);
              setShowLastCheckinEnabled(e.target.checked);
            }}
            checked={isShowLastCheckinEnabled}
            description={'Last Check-In Date'}
          />
        )}
        <Label
          css={css`
            margin-top: 0.3rem;
          `}
          size={'md'}
        >
          Save settings as default hardware lifecycle report for this client.
        </Label>
        <FormCheckboxInput
          fieldKey={shouldSaveDefaultSettingsFieldKey}
          onChange={e => {
            setFormInput<boolean>(shouldSaveDefaultSettingsFieldKey, e.target.checked);
            setShouldSaveDefaultSettingsEnabled(e.target.checked);
          }}
          checked={isShouldSaveDefaultSettingsEnabled}
          description={'Save as default'}
        />
      </div>
    </ModalConfirmation>
  );
};

export default LifecycleReportDownloadModal;
