import { css } from '@emotion/react';
import * as React from 'react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Image from '~/neo-ui/packages/image/Image';
import ActionButton from '../button/packages/action-button/ActionButton';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type Action = {
  icon: IconType;
  url: string;
  opensInNewTab?: boolean;
  content: string | React.ReactNode;
};

export type ActionBarProps = {
  imageSource: string;
  actions: Action[];
  backgroundColor: Color;
  activeButtonBorderColor: Color;
  activeButtonBackgroundColor: Color;
};
const ActionBar: React.FunctionComponent<React.PropsWithChildren<ActionBarProps>> = ({
  imageSource,
  actions,
  backgroundColor,
  activeButtonBorderColor,
  activeButtonBackgroundColor,
  children,
}) => (
  <div
    className={`align-items-center`}
    css={css`
      // Display
      display: inline-flex;

      // Sizing and styling
      height: 2.25rem;
      border-radius: 1.125rem;

      // Font/text styling (Component specific)
      white-space: nowrap;
      color: white !important;

      // Color. Must use global settings
      background-color: ${colorToCode(backgroundColor)};
      box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    `}
  >
    <Image
      css={css`
        height: 1.875rem;
        width: 1.875rem;
        border-radius: 50%;
        margin-left: 0.1875rem;
        margin-right: 0.625rem;
        border: 2px solid white;
      `}
      source={imageSource}
    />
    <div
      css={css`
        text-align: left;
        margin-right: 0.625rem;
      `}
    >
      {children}
    </div>
    <div
      css={css`
        display: inline-flex;
        > div {
          > * {
            margin-right: 0.5rem;
          }
          &:last-child > * {
            margin-right: 0.1875rem;
          }
        }
      `}
    >
      {actions.map((action, index) => (
        <ActionButton
          key={index}
          icon={action.icon}
          inactiveIconColor={backgroundColor}
          size={'sm'}
          inactiveBorderColor={'light-000'}
          activeBorderColor={activeButtonBorderColor}
          activeBackgroundColor={activeButtonBackgroundColor}
          actionUrl={action.url}
          opensInNewTab={action.opensInNewTab}
          tooltipContent={action.content}
        />
      ))}
    </div>
  </div>
);

export default ActionBar;
