import { css } from '@emotion/react';
import React from 'react';
import Toolbar from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import LayoutHeaderFooterProps from '~/neo-ui/packages/layout/types/LayoutHeaderFooterProps';
import LayoutBar from '~/neo-ui/packages/layout/packages/bar/LayoutBar';

/**
 * **DO NOT USE** if there is already an element attached to the `top: 6rem`!
 *
 * Use this to apply a LayoutHeader that deals with the restriction Php places on React components
 *
 * A header that sticks to the bottom of the nav bar.
 * to manually bump everything up to prevent bad ui.
 * @param children The contents of the LayoutHeader
 */
const LayoutHeaderPhp = ({ className, leftControls, rightControls, layoutBarComponent }: LayoutHeaderFooterProps) => (
  <div
    // WM-6038 Hacky solution to prevent PhpLayoutHeader from covering critical errors
    id={'layout-header'}
    className={className}
    css={css`
      position: sticky;

      margin: -1.9375rem -1.875rem 1.875rem;

      top: calc(6rem + var(--app-bar-height));

      @media screen and (max-width: 767px) {
        margin: -1.9375rem -1.25rem 1.25rem;
        top: calc(4rem + var(--app-bar-height));
      }

      z-index: 1039;
    `}
  >
    {(leftControls || rightControls) && (
      <Toolbar
        leftControls={leftControls}
        rightControls={rightControls}
      />
    )}
    {layoutBarComponent && (
      <LayoutBar
        css={css`
          margin-top: -0.0625rem;
        `}
      >
        {layoutBarComponent}
      </LayoutBar>
    )}
  </div>
);

export default LayoutHeaderPhp;
