import { ClassNames } from '@emotion/react';
import * as React from 'react';
import { useFormInput } from '~/neo-ui/packages/form/hooks/useFormInput';
import FormErrorMessage from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { Styleable } from '~/neo-ui/model/capacity';

/**
 * This represents props of a generic HTML input
 */
type InputProps = Record<string, unknown> & {
  className?: string;
};

export type FormInputInternalProps<T> = {
  fieldKey: FieldKeyExpression<T>;
  children: ({ inputProps }: { inputProps: InputProps }) => React.ReactNode;
  inputProps: InputProps;
  validationErrorColor?: Color;
} & Styleable;

const FormInputInternal = <T,>({
  fieldKey,
  children,
  inputProps,
  validationErrorColor = 'negative-400',
  className,
}: FormInputInternalProps<T>) => {
  const [field, { error, touched }] = useFormInput(fieldKey, inputProps);
  return (
    <ClassNames>
      {({ css }) => (
        <div className={className}>
          {children({
            inputProps: {
              className:
                (error && touched
                  ? `is-invalid ${css`
                      border-color: ${colorToCode(validationErrorColor)} !important;
                    `}`
                  : '') + (inputProps.className ? ` ${inputProps.className}` : ''),
              ...field,
            },
          })}
          <FormErrorMessage
            fieldKey={fieldKey}
            validationErrorColor={validationErrorColor}
          />
        </div>
      )}
    </ClassNames>
  );
};

export default FormInputInternal;
