import { formatDate } from '~/extensions/packages/date/formatDate';
import { warrantyCartDateTimeFormatUtc } from '~/wm/packages/warranty/packages/warranty-cart/constants/dateTimeConstants';
import { WarrantyCartPriceCalculatorType } from '~/wm/packages/warranty/packages/warranty-cart/types/WarrantyCartCalculatorType';
import { RenewalOptionSellingPriceDto } from '@WarrantyClient/BeastClient/Beast/Estimate/Dto/Cart/PaginatedGet.gen';
import { Enum as DeviceType } from '@WarrantyClient/BeastClient/Goods/DeviceType/DeviceTypeFactoryNested.gen';
import { WarrantyCartSelectedAsset } from '~/wm/packages/warranty/packages/warranty-cart/types/WarrantyCartSelectedAsset';
import { getCoTerminationCalculatorByDeviceType } from '~/wm/packages/warranty/packages/warranty-cart/calculators/coTermination/WarrantyCartCoTerminationCostCalculator';
import { getCalculatorByDeviceType } from '~/wm/packages/warranty/packages/warranty-cart/calculators/regular/WarrantyCartCostCalculator';

export const calculatePercentage = (number: number, percentage: number): number => (number * percentage) / 100;

export const addPeriodToDate = (date: Date, durationMonth: number): Date => {
  const datePlusPeriod = new Date(date.setMonth(date.getMonth() + durationMonth));
  // Extract 1 day
  return new Date(datePlusPeriod.setDate(datePlusPeriod.getDate() - 1));
};

export const warrantyCartFormatDateFromDate = (date: Date) => formatDate(date, warrantyCartDateTimeFormatUtc);

export const getCoTerminationEndDate = (selectedAssetsDatesStrings: string[], durationMonths: number): Date => {
  const dates = selectedAssetsDatesStrings.map(p => Number(new Date(p)));
  const minDate = new Date(Math.max.apply(null, dates));
  return addPeriodToDate(minDate, durationMonths);
};

export const calculatePeriodDurationInMonths = (startDate: Date, endDate: Date): number => {
  endDate.setDate(endDate.getDate() + 1);
  const yearsDifference = endDate.getFullYear() - startDate.getFullYear();
  const monthsDifference = endDate.getMonth() - startDate.getMonth();
  const daysDifference = endDate.getDate() - startDate.getDate();
  const result = yearsDifference * 12 + monthsDifference + daysDifference / 30;
  return Math.round((result + Number.EPSILON) * 10) / 10;
};

export const getCoverageStarts = (date: string, addAsterisk: boolean = false): string => {
  if (typeof date === 'undefined') {
    return '';
  }
  const output = warrantyCartFormatDateFromDate(new Date(date));
  return addAsterisk ? output + '*' : output;
};

export const getCostCalculatorType = (deviceType: DeviceType): WarrantyCartPriceCalculatorType =>
  deviceType === DeviceType.Workstation ? WarrantyCartPriceCalculatorType.Dynamic : WarrantyCartPriceCalculatorType.Static;

export const calculateDeviceCost = (
  selectedDeviceType: DeviceType,
  coTerminationEnabled: boolean,
  assetAccountId: string | undefined,
  availablePlans: RenewalOptionSellingPriceDto[],
  selectedPlanId: string | undefined,
  selectedUnits: WarrantyCartSelectedAsset[],
  duration: number,
): number =>
  coTerminationEnabled
    ? getCoTerminationCalculatorByDeviceType(selectedDeviceType)!.calculateCost(
        assetAccountId,
        availablePlans,
        selectedPlanId,
        selectedUnits,
        duration,
      )
    : getCalculatorByDeviceType(selectedDeviceType)!.calculateCost(availablePlans, selectedPlanId, selectedUnits.length, duration);
