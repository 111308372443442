import * as React from 'react';
import useDisposalOrderInfoContext from '~/wm/packages/disposal/packages/order-info/context/hooks/useDisposalOrderInfoContext';
import Spinner from '~/neo-ui/spinner/Spinner';
import DisposalOrderShipmentSection from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/DisposalOrderShipmentSection';
import DisposalTreeImpactOrderSummaryCard from '~/wm/packages/disposal/packages/tree-impact/packages/disposal-tree-impact-order-summary-card/DisposalTreeImpactOrderSummaryCard';
import { css } from '@emotion/react';
import DisposalOrderSummaryBulkSection from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-summary-section/packages/disposal-order-summary-bulk-section/DisposalOrderSummaryBulkSection';
import DisposalOrderBulkPickupSection from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-bulk-pickup-section/DisposalOrderBulkPickupSection';
import DisableElementOverlay from '~/neo-ui/packages/overlay/DisableElementOverlay';
import DisposalOrderSummarySelfSection from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-summary-section/packages/disposal-order-summary-self-section/DisposalOrderSummarySelfSection';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderType/Model/DisposalOrderTypeFactoryNested.gen';

export type DisposalOrderSummarySectionProps = {
  disposalOrderListUrl: string;
  invoiceUrl?: string;
  treeImpactHelpGuideUrl: string;
};

const DisposalOrderSummarySection = ({ disposalOrderListUrl, invoiceUrl, treeImpactHelpGuideUrl }: DisposalOrderSummarySectionProps) => {
  const { disposalOrderSummary } = useDisposalOrderInfoContext();

  if (typeof disposalOrderSummary === 'undefined') {
    return <Spinner />;
  }

  switch (disposalOrderSummary.orderType) {
    case Enum.ItadSelf:
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 1rem;
          `}
        >
          <DisableElementOverlay disabled={disposalOrderSummary.hasError}>
            <DisposalOrderSummarySelfSection
              disposalOrderListUrl={disposalOrderListUrl}
              disposalAssetTypeAvailabilities={disposalOrderSummary.assetTypeAvailabilities}
              invoiceUrl={invoiceUrl}
            />
            <DisposalOrderShipmentSection />
          </DisableElementOverlay>
          <DisposalTreeImpactOrderSummaryCard
            serviceOrderId={disposalOrderSummary.disposalOrderId}
            helpGuideUrl={treeImpactHelpGuideUrl}
          />
        </div>
      );
    case Enum.ItadBulk:
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 1rem;
          `}
        >
          <DisableElementOverlay disabled={disposalOrderSummary.hasError}>
            <DisposalOrderSummaryBulkSection
              disposalOrderListUrl={disposalOrderListUrl}
              invoiceUrl={invoiceUrl}
              disposalAssetTypeAvailabilities={disposalOrderSummary.assetTypeAvailabilities}
            />
            <DisposalOrderBulkPickupSection />
          </DisableElementOverlay>
          <DisposalTreeImpactOrderSummaryCard
            serviceOrderId={disposalOrderSummary.disposalOrderId}
            helpGuideUrl={treeImpactHelpGuideUrl}
          />
        </div>
      );
    default:
      return null;
  }
};

export default DisposalOrderSummarySection;
