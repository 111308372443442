/**
 * Group objects by month if any exist for that month
 *
 * @param objects - objects to group
 * @param dateKey - the key of the date property on the object
 */
export const useGroupByMonth = <T>(objects: T[], dateKey: keyof T): { [month: string]: T[] } =>
  objects.reduce((acc, obj) => {
    const dateKeyValue = obj[dateKey];
    if (typeof dateKeyValue === 'undefined') {
      throw new Error(`dateKey ${dateKey.toString()} does not exist on object ${JSON.stringify(obj)}`);
    }

    const dateValue = typeof dateKeyValue === 'string' ? new Date(dateKeyValue) : (dateKeyValue as Date);

    const monthKey: string = `${dateValue.getFullYear()}-${dateValue.getMonth() + 1}`;

    if (typeof acc[monthKey] === 'undefined') {
      acc[monthKey] = [];
    }

    acc[monthKey].push(obj);

    return acc;
  }, {} as { [month: string]: T[] });

export default useGroupByMonth;
