import * as React from 'react';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import Size from '~/neo-ui/model/Size';
import { css } from '@emotion/react';
import Button from '../button/Button';
import Box from '../box/Box';
import useTheme from '../color/hooks/useTheme';
import Label, { LabelSize } from '../text/packages/label/Label';

export type ChipProps = {
  disabled?: boolean;
  size?: Exclude<Size, 'xl'>;
  onClick: () => void;
} & Styleable &
  Themeable;

const Chip: React.FunctionComponent<React.PropsWithChildren<ChipProps>> = ({
  disabled = false,
  theme = 'primary',
  size = 'xs',
  onClick,
  className,
  children,
}) => {
  const sizes = chipSizeToChipInternalSizes(size);
  const { themeMap } = useTheme(theme);

  return (
    <Box
      className={className}
      css={css`
        display: flex;
        flex-basis: fit-content;
        align-items: center;
        width: fit-content;
      `}
      borderRadius={'radius200'}
      background={'context'}
      theme={theme}
    >
      <Button
        disabled={disabled}
        onClick={onClick}
        iconLeft={'Remove'}
        size={sizes.button}
        iconSizeOverride={sizes.icon}
      />
      <div
        css={css`
          padding: 0.375rem;
        `}
      >
        <Label
          size={sizes.label}
          color={themeMap.backgroundContrast}
        >
          {children}
        </Label>
      </div>
    </Box>
  );
};

export default Chip;

const chipSizeToChipInternalSizes = (chipSize: Exclude<Size, 'xl'>): ChipInternalSizes => {
  switch (chipSize) {
    // 'xs' doesn't exist for Label size
    case 'xs':
      return {
        button: 'xs',
        icon: 'xs',
        label: 'sm',
      };

    case 'sm':
    case 'md':
    case 'lg':
      return {
        button: chipSize,
        label: chipSize,
        icon: chipSize,
      };
  }
};

type ChipInternalSizes = {
  button: Exclude<Size, 'xl'>;
  label: LabelSize;
  icon: Exclude<Size, 'xl'>;
};
