import { RenderCellData } from '@AssetManagementClient/BeastClient/Renderer/Model/Data.gen';
import { Enum } from '@AssetManagementClient/BeastClient/Renderer/Model/Data/RenderCellDataNested.gen';
import * as React from 'react';
import RenderCellCollection from '~/neo-ui/packages/table/packages/render-cell-data/collection/RenderCellCollection';
import RenderColoredCircleCell from '~/neo-ui/packages/table/packages/render-cell-data/colored-circle/RenderColoredCircleCell';
import RenderDateCell from '~/neo-ui/packages/table/packages/render-cell-data/date/RenderDateCell';
import RenderImageCell from '~/neo-ui/packages/table/packages/render-cell-data/image/RenderImageCell';
import RenderStackedCell from '~/neo-ui/packages/table/packages/render-cell-data/stacked/RenderStackedCell';
import RenderTextCell from '~/neo-ui/packages/table/packages/render-cell-data/text/RenderTextCell';
import RenderTooltipCell from '~/neo-ui/packages/table/packages/render-cell-data/tooltip/RenderTooltipCell';
import RenderUrlCell from '~/neo-ui/packages/table/packages/render-cell-data/url/RenderUrlCell';
import RenderIconCell from '~/neo-ui/packages/table/packages/render-cell-data/icon/RenderIconCell';

export type RenderCellProps = {
  data: RenderCellData;
};

const RenderCell: React.FunctionComponent<RenderCellProps> = ({ data }) => {
  switch (data.type) {
    case Enum.TextData:
      return <RenderTextCell data={data} />;
    case Enum.UrlData:
      return <RenderUrlCell data={data} />;
    case Enum.StackedData:
      return <RenderStackedCell data={data} />;
    case Enum.DateData:
      return <RenderDateCell data={data} />;
    case Enum.ColoredCircleData:
      return <RenderColoredCircleCell data={data} />;
    case Enum.TooltipData:
      return <RenderTooltipCell data={data} />;
    case Enum.ImageData:
      return <RenderImageCell data={data} />;
    case Enum.CollectionData:
      return <RenderCellCollection data={data} />;
    case Enum.IconData:
      return <RenderIconCell data={data} />;
  }
};

export default RenderCell;
