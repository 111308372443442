import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';

export type TreeImpactLearnMoreButtonProps = {
  helpGuideUrl: string;
};

const TreeImpactLearnMoreButton: React.FunctionComponent<TreeImpactLearnMoreButtonProps> = ({ helpGuideUrl }) => (
  <ButtonLink
    theme={'primary'}
    iconRight={'GoExternal'}
    size={'sm'}
    anchor={{ href: helpGuideUrl, openInNewTab: true }}
  >
    Learn More
  </ButtonLink>
);

export default TreeImpactLearnMoreButton;
