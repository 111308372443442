import { css } from '@emotion/react';
import * as React from 'react';
import ServiceRequestDisposalSection from '~/wm/packages/warranty/packages/service-request/packages/service-request-disposal-section/ServiceRequestDisposalSection';
import ServiceRequestClientInfoSection from '~/wm/packages/warranty/packages/service-request/packages/service-request-client-info-section/ServiceRequestClientInfoSection';
import ServiceRequestInfoSection from '~/wm/packages/warranty/packages/service-request/packages/service-request-info-section/ServiceRequestInfoSection';
import useServiceRequestGet from '~/wm/packages/warranty/packages/service-request/hooks/useServiceRequestGet';
import ServiceRequestShippingInfoSection from '~/wm/packages/warranty/packages/service-request/packages/service-request-shipping-info-section/ServiceRequestShippingInfoSection';
import ServiceRequestImmediateAttentionSection from '~/wm/packages/warranty/packages/service-request/packages/service-request-immediate-attention-section/ServiceRequestImmediateAttentionSection';
import EmailProvider from '~/wm/packages/product/context/EmailProvider';
import Spinner from '~/neo-ui/spinner/Spinner';

export type ServiceRequestDetailPageProps = {
  serviceRequestId: string;
  warrantyTransferUrl: string;
  hasDisposalLocationAccess: boolean;
  supportEmail: string;
};

const ServiceRequestDetailPage: React.FunctionComponent<ServiceRequestDetailPageProps> = ({
  serviceRequestId,
  warrantyTransferUrl,
  hasDisposalLocationAccess,
  supportEmail,
}) => {
  const { serviceRequest, contactInfo, isCoverageTransferred, isCoverageTransferAllowed } = useServiceRequestGet(serviceRequestId);

  if (typeof serviceRequest === 'undefined') {
    // Here we could have loading placeholders
    return <Spinner />;
  }

  return (
    <EmailProvider supportEmail={supportEmail}>
      <div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-grow: 1;
              flex-direction: column;
              gap: 0.625rem;
            `}
          >
            <ServiceRequestInfoSection serviceRequest={serviceRequest} />
            <div
              css={css`
                display: flex;
                gap: 0.625rem;
              `}
            >
              <ServiceRequestClientInfoSection
                serviceRequest={serviceRequest}
                css={css`
                  flex: 1 1 0;
                `}
              />
              <ServiceRequestDisposalSection
                serviceRequest={serviceRequest}
                warrantyTransferUrl={warrantyTransferUrl}
                hasDisposalLocationAccess={hasDisposalLocationAccess}
                isCoverageTransferred={isCoverageTransferred}
                isCoverageTransferAllowed={isCoverageTransferAllowed}
                css={css`
                  flex: 1 1 0;
                `}
              />
            </div>
            <ServiceRequestShippingInfoSection serviceRequest={serviceRequest} />
          </div>
          <ServiceRequestImmediateAttentionSection contactInfo={contactInfo} />
        </div>
      </div>
    </EmailProvider>
  );
};

export default ServiceRequestDetailPage;
