import * as React from 'react';
import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import TextBlock from '~/neo-ui/packages/text/packages/text-block/TextBlock';
import DisposalOrderSummarySectionAssetOrganizationAssignModal from './packages/disposal-order-summary-section-asset-organization-assign-modal/DisposalOrderSummarySectionAssetOrganizationAssignModal';
import useDisposalOrderAssetUnassignedOrganizationList from '../../../../hooks/useDisposalOrderAssetUnassignedOrganizationList';
import DisposalOrderAssetSelectProvider from './packages/context/DisposalOrderAssetSelectProvider';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import useDisposalOrderInfoMutationContext from '~/wm/packages/disposal/packages/order-info/context/hooks/useDisposalOrderInfoMutationContext';

export type DisposalOrderSummarySectionAssetOrganizationAssignModuleProps = {
  disposalOrderId: string;
  assetCountUnidentified: number;
  completedAt: string;
  disposalAssetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[];
};

const DisposalOrderSummarySectionAssetOrganizationAssignModule = ({
  disposalOrderId,
  assetCountUnidentified,
  completedAt,
  disposalAssetTypeAvailabilities,
}: DisposalOrderSummarySectionAssetOrganizationAssignModuleProps) => {
  const [isDisplayingModal, setDisplayModal] = React.useState(false);
  const { triggerDisposalOrderInfoReload } = useDisposalOrderInfoMutationContext();

  const { disposalOrderAssets, getDisposalOrderAssetUnassignedOrganizationList: getDisposalOrderAssetUnassignedClientList } =
    useDisposalOrderAssetUnassignedOrganizationList(disposalOrderId);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        gap: 0.8125rem;
        align-items: center;
      `}
    >
      <TextBlock
        icon={'Caution'}
        iconColor={'caution-400'}
        title={`${assetCountUnidentified} asset${assetCountUnidentified === 1 ? '' : 's'}  could not be assigned to a client`}
        titleColor={'caution-400'}
        description={'Assign these assets to clients to list them on Certificates of Disposal'}
      />
      <Button
        css={css`
          margin-left: auto;
        `}
        size={'sm'}
        iconRight={'GoRight'}
        onClick={() => setDisplayModal(true)}
      >
        Assign to clients
      </Button>
      <DisposalOrderAssetSelectProvider>
        <DisposalOrderSummarySectionAssetOrganizationAssignModal
          disposalOrderId={disposalOrderId}
          disposalOrderAssets={disposalOrderAssets}
          disposalAssetTypeAvailabilities={disposalAssetTypeAvailabilities}
          completedAt={completedAt}
          isOpen={isDisplayingModal}
          onAssign={getDisposalOrderAssetUnassignedClientList}
          onCancel={() => {
            setDisplayModal(false);
            triggerDisposalOrderInfoReload();
          }}
        />
      </DisposalOrderAssetSelectProvider>
    </div>
  );
};

export default DisposalOrderSummarySectionAssetOrganizationAssignModule;
