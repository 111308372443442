import { css } from '@emotion/react';
import * as React from 'react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import Icon from '~/neo-ui/packages/icon/Icon';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Spinner from '~/neo-ui/spinner/Spinner';

export type FormSubmitStatusIndicatorProps = {
  /**
   * Used to display a status for a form that hasn't been touched yet
   */
  untouchedLabel?: string;

  submittingLabel: string;
  submittedLabel: string;
};

const FormSubmitStatusIndicator: React.FunctionComponent<FormSubmitStatusIndicatorProps> = ({
  untouchedLabel,
  submittingLabel,
  submittedLabel,
}) => {
  const { isSubmitting, isValid } = useFormContext();
  // Tracks whether or not the form was touched at some point
  const [formWasModified, setFormWasModified] = React.useState(false);
  React.useEffect(() => {
    if (isSubmitting) {
      setFormWasModified(true);
    }
  }, [isSubmitting]);

  if (!formWasModified && !untouchedLabel) {
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        margin-bottom: 0.625rem;

        > *:first-of-type {
          margin-right: 0.3125rem;
        }
      `}
    >
      {!formWasModified ? (
        <>
          {untouchedLabel && (
            <Label
              bold={true}
              muted={true}
            >
              {untouchedLabel}
            </Label>
          )}
        </>
      ) : isSubmitting ? (
        <>
          <Spinner
            size={'sm'}
            color={'positive-400'}
          />
          <SubmittingText>{submittingLabel}</SubmittingText>
        </>
      ) : isValid ? (
        <>
          <Icon
            icon={'Success'}
            color={'positive-400'}
          />
          <SubmittingText>{submittedLabel}</SubmittingText>
        </>
      ) : null}
    </div>
  );
};

export default FormSubmitStatusIndicator;

const SubmittingText: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => (
  <span
    css={css`
      font-weight: bold;
      line-height: 1;
      color: ${colorToCode('positive-400')};
    `}
  >
    {children}
  </span>
);
