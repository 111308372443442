import Header from '~/neo-ui/packages/text/packages/header/Header';
import Box from '~/neo-ui/packages/box/Box';
import { css } from '@emotion/react';
import OrganizationStarBox from '~/wm/packages/organization/packages/organization-star/packages/organization-star-box/OrganizationStarBox';
import { UserOrganizationStarInfoDto } from '@AssetManagementClient/BeastClient/Beast/Identity/Packages/User/Packages/OrganizationStar/Dto.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';

export type OrganizationStarSectionProps = {
  starredOrganizations: Map<string, UserOrganizationStarInfoDto> | undefined;
};

const OrganizationStarSection = ({ starredOrganizations }: OrganizationStarSectionProps) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    `}
  >
    <Header
      size={2}
      weight={'bold'}
    >
      Starred Clients ({typeof starredOrganizations === 'undefined' ? 0 : starredOrganizations.size})
    </Header>
    {typeof starredOrganizations === 'undefined' || starredOrganizations.size === 0 ? (
      <Label>You haven't starred any clients yet. Star your clients to prioritize and quickly access them here.</Label>
    ) : (
      <Box
        padding={'padding200'}
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
        `}
      >
        {Array.from(starredOrganizations.values(), organization => (
          <OrganizationStarBox
            key={organization.organizationId}
            organization={organization}
          />
        ))}
      </Box>
    )}
  </div>
);

export default OrganizationStarSection;
