import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import { InputProps } from '~/neo-ui/packages/form/packages/form-input/FormInputTextInternal';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';

import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import useThemeInputTitle from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/hooks/useThemeInputTitle';
import useStylesInputTitle from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/hooks/useStylesInputTitle';
import Size from '~/neo-ui/model/Size';

export type InputTitleProps = {
  /**
   * Only specify if called within a form element
   * Spreads any conditional fields from optimizations done within useFormInputBuilder
   */
  formField?: InputProps;
  value: string | number | undefined | readonly string[];

  /**
   * Callback for changes to the value
   */
  onChange: (value: string) => void;

  /**
   * Callback to clear the value, only works if canClear is specified
   */
  onClear?: () => void;

  /**
   * Focus event for the input
   */
  onFocus?: () => void;

  placeholder?: string;
  prependIcon?: IconType;
  prependCharacter?: string;
  canClear?: boolean;
  disabled?: boolean;
  isInvalid?: boolean;

  /**
   * Will attempt to give focus to the input on first render
   */
  hasDefaultFocus?: boolean;
  /**
   * Will attempt to select input text on first render
   */
  hasDefaultSelect?: boolean;

  /**
   * Size of the input text, 'xs' and 'xl' is unsupported
   *
   * Default is `md`
   */
  size?: InputTitleSizes;
  /**
   * Indicates visually whether to display an error message or error theming.
   */
  showError?: boolean;

  /**
   * Indicates when click on the input field the input will be cleared
   */
  canClearOnClick?: boolean;

  /**
   * This is useful in cases where you don't want
   * clicking on the input to trigger any upstream onClick event.
   *
   * Common example: You have an input nested within a clickable element.
   */
  preventOnClickPropagation?: boolean;
} & Styleable &
  Themeable;

export type InputTitleDisplayDetails = {
  fontSizeRem: number;
  fontWeight: number;
  heightRem: number;
  paddingLeftRemInput: number;
  paddingRightRemInput: number;
  paddingLeftRemIcon: number;
  paddingRightRemIcon: number;
};

export type InputTitleSizes = Exclude<Size, 'xl' | 'xs'>;

/**
 * Get appropriate css props for a given input title size
 *
 * 'xs' and `xl` is unsupported
 * @param inputTitleSize Size of the input title
 */
export const inputTitleSizeToInputTitleDisplayDetails = (inputTitleSize: InputTitleSizes): InputTitleDisplayDetails => {
  switch (inputTitleSize) {
    case 'sm':
      return {
        fontSizeRem: 0.875,
        fontWeight: 400,
        heightRem: 1.75,
        paddingLeftRemInput: 0.5,
        paddingRightRemInput: 0.5,
        paddingLeftRemIcon: 0.75,
        paddingRightRemIcon: 0.75,
      };
    case 'md':
      return {
        fontSizeRem: 0.875,
        fontWeight: 400,
        heightRem: 2.25,
        paddingLeftRemInput: 0.5,
        paddingRightRemInput: 0.5,
        paddingLeftRemIcon: 0.75,
        paddingRightRemIcon: 0.75,
      };
    case 'lg':
      return {
        fontSizeRem: 1.125,
        fontWeight: 700,
        heightRem: 2.75,
        paddingLeftRemInput: 0.5,
        paddingRightRemInput: 0.5,
        paddingLeftRemIcon: 0.75,
        paddingRightRemIcon: 0.75,
      };
  }
};

/**
 * Form input for titles
 *
 * Specify a header size to replicate the size and weight. Defaults to body text
 *
 * For future usage: passing `type` in inputProps will change the input type seamlessly
 */
const InputTitle = ({
  value,
  onChange,
  prependIcon,
  prependCharacter,
  canClear = false,
  placeholder,
  disabled = false,
  hasDefaultFocus = false,
  hasDefaultSelect = false,
  onFocus,
  isInvalid = false,
  onClear,
  formField,
  theme,
  size = 'md',
  showError = true,
  canClearOnClick = false,
  preventOnClickPropagation = false,
  className,
}: InputTitleProps) => {
  // Invalid state uses negative styles
  const { themeMap } = useThemeInputTitle(isInvalid && showError ? 'negative' : theme);
  const displayDetails = inputTitleSizeToInputTitleDisplayDetails(size);
  const { stylesInputTitle } = useStylesInputTitle(themeMap, displayDetails);

  const showClearButton = canClear && value !== undefined && value !== '';

  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (inputRef.current !== null) {
      if (hasDefaultFocus) {
        inputRef.current.focus();
      }
      if (hasDefaultSelect) {
        inputRef.current.select();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDefaultFocus, hasDefaultSelect, inputRef.current]);

  return (
    <div
      className={className}
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      {prependCharacter && (
        <span
          css={css`
            padding-left: ${displayDetails.paddingLeftRemIcon}rem;
            padding-right: ${displayDetails.paddingRightRemIcon}rem;
            font-size: ${displayDetails.fontSizeRem}rem;
            color: ${colorToCode(disabled ? themeMap.iconColorDisabled : themeMap.iconColor)};
            display: flex;
            background-color: transparent;
            border: none;
            margin-right: -2rem;
            z-index: 4;
          `}
        >
          {prependCharacter}
        </span>
      )}
      {prependIcon && (
        <div
          css={css`
            height: 16px;
            margin-left: 0.5rem;
            z-index: 4;
            position: absolute;
          `}
        >
          <Icon
            icon={prependIcon}
            sizePx={16}
            color={disabled ? themeMap.iconColorDisabled : themeMap.iconColor}
          />
        </div>
      )}
      <input
        ref={inputRef}
        value={value}
        onChange={e => onChange(e.target.value)}
        onFocus={onFocus}
        // Spread form-related props, overwrites what is above
        {...formField}
        type={'text'}
        role={'textbox'}
        placeholder={placeholder}
        disabled={disabled}
        css={[
          stylesInputTitle,
          css`
            // Override padding to account for extras in the input
            padding-left: ${(prependIcon || prependCharacter) && `2rem`};
            padding-right: ${showClearButton && `1.875rem`};
          `,
        ]}
        onClick={event => {
          if (preventOnClickPropagation) {
            event.stopPropagation();
          }

          if (canClearOnClick) {
            onChange('');
          }
        }}
      />
      {showClearButton && (
        <ButtonModifier
          icon={'Cancel'}
          onClick={() => (typeof onClear === 'undefined' ? onClear : onChange(''))}
          css={css`
            margin-left: -2rem;
            margin-right: unset;
          `}
        />
      )}
    </div>
  );
};

export default InputTitle;
