import React, { useMemo } from 'react';
import { Enum as WarrantyType } from '@WarrantyClient/BeastClient/Goods/WarrantyType/WarrantyTypeFactoryNested.gen';
import useEstimateCartGet from '~/wm/packages/warranty/packages/hooks/useEstimateCartGet';
import useDevicesByTypes from '~/wm/packages/warranty/packages/warranty-cart/hooks/useDevicesByTypes';
import useSettingsGet from '~/wm/packages/warranty/packages/warranty-cart/hooks/useSettingsGet';
import { Enum as DeviceType } from '@WarrantyClient/BeastClient/Goods/DeviceType/DeviceTypeFactoryNested.gen';
import WarrantyCartLoader from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-loader/WarrantyCartLoader';
import WarrantyCartProvider from '~/wm/packages/warranty/packages/warranty-cart/context/WarrantyCartProvider';
import WarrantyCart from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart/WarrantyCart';

export type WarrantyCartPageProps = {
  organizationId: string;
  organizationName: string;
  warrantyType: WarrantyType | undefined;
  deviceId: string | undefined;
};

const WarrantyCartPage: React.FunctionComponent<WarrantyCartPageProps> = ({ organizationId, organizationName, warrantyType, deviceId }) => {
  const { cartEstimate, isEstimateLoading } = useEstimateCartGet(organizationId, warrantyType, false, deviceId);
  const { availableAssetTypes, availableAssetTypesLoading } = useDevicesByTypes(organizationId, warrantyType);
  const { settings, settingsLoading } = useSettingsGet(organizationId);

  const isLoading = useMemo(
    (): boolean => isEstimateLoading || availableAssetTypesLoading || settingsLoading,
    [availableAssetTypesLoading, settingsLoading, isEstimateLoading],
  );

  const initialDeviceType = useMemo((): DeviceType => {
    let defaultType: DeviceType;

    switch (warrantyType) {
      case WarrantyType.InfrastructureProtection:
        defaultType = DeviceType.Server;
        break;
      case WarrantyType.WorkstationAssurance:
        defaultType = DeviceType.Workstation;
        break;
      default:
        defaultType = DeviceType.Server;
    }

    if (typeof cartEstimate === 'undefined' || typeof availableAssetTypes === 'undefined') {
      return defaultType;
    }

    // If estimate exists - return first unit type
    if (cartEstimate.isEstimateExists) {
      const firstWithType = cartEstimate.estimate?.units.find(p => typeof p.deviceType !== 'undefined');
      return firstWithType?.deviceType ?? defaultType;
    }

    // If no estimate return first type with devices
    if (!cartEstimate.isEstimateExists) {
      const firstNotEmpty = availableAssetTypes.find(p => p.totalDevices > 0);
      return firstNotEmpty?.deviceType ?? defaultType;
    }

    return defaultType;
  }, [warrantyType, cartEstimate, availableAssetTypes]);

  if (isLoading) {
    return <WarrantyCartLoader />;
  }

  return (
    <WarrantyCartProvider
      organizationId={organizationId}
      organizationName={organizationName}
      deviceType={initialDeviceType}
      availableAssetTypes={availableAssetTypes}
      settings={settings}
      warrantyType={warrantyType}
    >
      <WarrantyCart />
    </WarrantyCartProvider>
  );
};

export default WarrantyCartPage;
