import { AutoEmailReportDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';
import Box from '~/neo-ui/packages/box/Box';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import React from 'react';
import Badge from '~/neo-ui/packages/badge/Badge';

export type AutoEmailReportSettingsSortingProps = {
  autoEmailReport: AutoEmailReportDto;
};

const AutoEmailReportSettingsSorting = ({ autoEmailReport }: AutoEmailReportSettingsSortingProps) => (
  <Box
    padding={'padding200'}
    borderRadius={'radius400'}
  >
    <Label
      bold={true}
      color={autoEmailReport.isScheduled ? undefined : 'dark-900-32'}
      css={css`
        margin-bottom: 0.5rem;
      `}
    >
      Sort by
    </Label>
    {autoEmailReport.reportQuery.sort.length !== 0 && (
      <Badge borderRadius={'radius200'}>{autoEmailReport.reportQuery.sort[0].field.value}</Badge>
    )}
  </Box>
);

export default AutoEmailReportSettingsSorting;
