import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';
import { ContractAgreementDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Agreement/Dto/Model.gen';
import { ContractAgreementItemDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Agreement/Packages/Item/Dto/Model.gen';
import { ContractAgreementRow } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/packages/contract-sync-window/ContractSyncWindow';

const formatDateOrDash = (dateString?: string) =>
  dateString
    ? formatDate(parseISO(dateString), {
        timezone: TimezoneFormat.Local,
        format: 'yyyy/MM/dd',
      })
    : '-';

const buildContractAgreementRow = (agreement: ContractAgreementDto, agreementItem?: ContractAgreementItemDto): ContractAgreementRow => ({
  agreementId: agreement.agreementId,
  isSelectable: typeof agreementItem === 'undefined',
  agreementItemId: agreementItem ? agreementItem.agreementItemId : undefined,
  name: agreementItem ? agreementItem.name : agreement.name,
  updatedAt: agreementItem ? '-' : formatDateOrDash(agreement.updatedAt),
  status: agreementItem ? agreementItem.status : agreement.status,
  description: agreementItem ? agreementItem.description : agreement.description,
  billingCycle: agreementItem ? agreementItem.billingCycle : agreement.billingCycle,
  billingCost: agreementItem ? agreementItem.billingCost : agreement.billingCost,
  nextDue: agreementItem ? formatDateOrDash(agreementItem.nextDue) : formatDateOrDash(agreement.nextDue),
  endDate: agreementItem ? formatDateOrDash(agreementItem.endDate) : formatDateOrDash(agreement.endDate),
  assetCount: agreementItem ? undefined : agreement.assetCount,
});

export default buildContractAgreementRow;
