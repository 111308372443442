import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import * as React from 'react';
import { css } from '@emotion/react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import DisposalAssetTypeCountFormData from '~/wm/packages/disposal/types/DisposalAssetTypeCountFormData';
import useWizardPageContext from '~/neo-ui/packages/wizard/packages/wizard-page/context/hooks/useWizardPageContext';
import Button from '~/neo-ui/packages/button/Button';
import SummarySelection from '~/wm/packages/disposal/wizard/section/packages/summary-selection/SummarySelection';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';

export type OrderSummaryAssetModuleProps<T> = {
  header: string;
  assetTypeCountsFieldKey: FieldKeyExpression<T>;
  disposalAssetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[];
};

const OrderSummaryAssetModule = <T,>({
  header,
  assetTypeCountsFieldKey,
  disposalAssetTypeAvailabilities,
}: OrderSummaryAssetModuleProps<T>) => {
  const { getFormInput } = useFormContext<T>();
  const { setCurrentStepIndex } = useWizardPageContext();
  const { trackEvent } = useEventTracking(TrackingServices.EventProcessor);

  const assetTypeCounts = getFormInput<DisposalAssetTypeCountFormData[]>(assetTypeCountsFieldKey).value;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Label
          size={'lg'}
          bold={true}
        >
          {header}
        </Label>
        <Button
          iconLeft={'Edit'}
          size={'sm'}
          onClick={() => {
            trackEvent('disposal_assets_step_navigate', {
              context: 'disposal_summary_step_assets_edit_button_clicked',
            });
            setCurrentStepIndex(0);
          }}
        >
          Edit
        </Button>
      </div>
      {assetTypeCounts.map(disposalAssetType => {
        const assetTypeCount = disposalAssetTypeAvailabilities.find(
          assetTypeCount => assetTypeCount.disposalAssetType === disposalAssetType.type,
        );
        if (typeof assetTypeCount !== 'undefined') {
          return (
            <SummarySelection
              key={disposalAssetType.type}
              isSelected={true}
              icon={assetTypeCount.icon as IconType}
              label={
                typeof disposalAssetType.customizedType === 'undefined'
                  ? disposalAssetType.count !== 1
                    ? assetTypeCount.label
                    : assetTypeCount.labelSingular
                  : disposalAssetType.customizedType
              }
              selectionCount={disposalAssetType.count}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default OrderSummaryAssetModule;
