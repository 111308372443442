import debounce from 'debounce-promise';
import * as React from 'react';
import { Row } from 'react-table';
import { v4 as uuidv4 } from 'uuid';
import {
  DataTableClientPagination,
  DataTableColumn,
  PaginationMetadata,
  RowOptions,
  SortState,
} from '~/neo-ui/packages/table/packages/data-table/DataTable';
import FilterTable, { Metadata, TableState } from '~/neo-ui/packages/table/packages/filter-table/FilterTable';
import ShareProps from '~/wm/packages/insight-share/model/ShareProps';
import Spinner from '~/neo-ui/spinner/Spinner';

export type QueryResult<T> = {
  data: T[];
  paginationMetadata?: PaginationMetadata;
};

export type AsyncFilterTableProps<T extends object> = {
  filtersTitle?: string;
  filtersDescription?: string;

  dataTitle?: string | React.ReactNode;
  dataDescription?: string;
  columns: DataTableColumn<T>[];

  onFetchMetadata: () => Promise<Metadata | 'error'>;
  onFetchData: (query: TableState<T>) => Promise<QueryResult<T>>;
  onTableStateChange?: (tableState: TableState<T>) => void;

  defaultSort?: SortState<T>;

  perPageSize?: number;
  EmptyStatePlaceholder: string | React.ComponentType;

  /**
   * Optional, used for share
   */
  share?: ShareProps;
  download?: (tableState: TableState<T>) => React.ReactNode;
  getRowOptions?: (row: Row<T>) => RowOptions;

  enableClientPaginated?: DataTableClientPagination;
  manualSort?: boolean;

  /**
   * Optional, used for pinned rows
   */
  pinnedData?: T[];
};

const AsyncFilterTable = <T extends object>({
  filtersTitle,
  filtersDescription,

  dataTitle,
  dataDescription,
  columns,
  onFetchMetadata,
  onFetchData,

  defaultSort,

  perPageSize,
  EmptyStatePlaceholder,
  share,
  download,
  onTableStateChange,
  getRowOptions,

  enableClientPaginated = undefined,
  manualSort = false,
  pinnedData,
}: AsyncFilterTableProps<T>) => {
  const [metadata, setMetadata] = React.useState<Metadata | 'loading' | 'error'>('loading');

  const [defaultQueryState, setDefaultQueryState] = React.useState<TableState<T>>();

  const [paginationMetadata, setPaginationMetadata] = React.useState<PaginationMetadata>();

  const currentFetchToken = React.useRef<string>();

  const onFetchDataDebounced = React.useMemo(() => debounce(onFetchData, 250), [onFetchData]);

  // Instead of this custom token thing, we could use something like
  // https://github.com/slorber/react-async-hook in the future.
  // Currently, this debouncing strategy doesn't abort existing requests,
  // it just ignores them.
  const onQueryChange = React.useCallback(
    async (query: TableState<T>) => {
      const fetchToken = uuidv4();
      currentFetchToken.current = fetchToken;
      const { data, paginationMetadata: paginationMetadataResponse } = await onFetchDataDebounced(query);
      if (currentFetchToken.current === fetchToken) {
        setData(data);
        setPaginationMetadata(paginationMetadataResponse);
        if (typeof onTableStateChange !== 'undefined') {
          onTableStateChange(query);
        }
      }
    },
    [onFetchDataDebounced, currentFetchToken, onTableStateChange],
  );

  const [data, setData] = React.useState<T[] | 'loading'>('loading');

  React.useEffect(() => {
    // Init possible filters and orderings
    (async () => {
      const metadata = await onFetchMetadata();
      if (metadata === 'error') {
        setMetadata('error');
        return;
      }
      setMetadata(metadata);
      const query = {
        filters: {
          selectedSearchContextValue: metadata.filterSet.searchContexts ? metadata.filterSet.searchContexts[0].value : undefined,
          selectedFilters: new Map<string, Set<string>>(),
          availableFilters: new Map(metadata.filterSet.filters.map(filter => [filter.value, filter])),
          searchQuery: undefined,
        },
        pagination: perPageSize
          ? {
              pageNumber: 0,
              perPageSize,
            }
          : undefined,
        sort: defaultSort,
      };
      setDefaultQueryState(query);
      await onQueryChange(query);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!defaultQueryState) {
    return <Spinner />;
  }

  return (
    <FilterTable
      filtersTitle={filtersTitle}
      filtersDescription={filtersDescription}
      metadata={metadata}
      defaultTableState={defaultQueryState}
      onTableStateChanged={onQueryChange}
      paginationMetadata={paginationMetadata}
      dataTitle={dataTitle}
      dataDescription={dataDescription}
      data={data}
      columns={columns}
      EmptyStatePlaceholder={EmptyStatePlaceholder}
      share={share}
      download={download}
      getRowOptions={getRowOptions}
      enableClientPaginated={enableClientPaginated}
      manualSort={manualSort}
      pinnedData={pinnedData}
    />
  );
};
export { AsyncFilterTable };
