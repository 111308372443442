import { createContext } from 'react';

export type DisposalUrlContextData = {
  disposalOrderInfoUrl: string;
  disposalTermsOfServiceUrl: string;
  termsOfServiceUrl: string;
  disposalOrderListUrl: string;
  invoiceUrl: string;
  organizationDashboardUrl: string;
  hardwareViewUrl: string;
  treeImpactHelpGuideUrl: string;
  growUrl: string;
  featureRequestUrl: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const DisposalUrlContext = createContext<DisposalUrlContextData | undefined>(undefined);

export default DisposalUrlContext;
