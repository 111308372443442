import useMutationInternal, {
  UseMutationInternal,
  UseMutationInternalApiCall,
  UseMutationInternalOptions,
} from '~/extensions/packages/hooks/useMutationInternal';

import useContractTemplateAvailabilitiesMutationContext from '~/wm/packages/strategy/packages/contract/packages/contract-template/context/hooks/useContractTemplateAvailabilitiesMutationContext';

export type UseContractTemplateAvailabilitiesMutationOptions<TResponse = unknown> = UseMutationInternalOptions<TResponse>;

const useContractTemplateAvailabilitiesMutation = <TArg = Record<string, unknown>, TResponse = unknown>(
  apiCall: UseMutationInternalApiCall<TArg, TResponse>,
  options?: UseContractTemplateAvailabilitiesMutationOptions<TResponse>,
): UseMutationInternal<TArg> => {
  const { triggerContractTemplateAvailabilitiesReload } = useContractTemplateAvailabilitiesMutationContext();

  return useMutationInternal(apiCall, triggerContractTemplateAvailabilitiesReload, options);
};

export default useContractTemplateAvailabilitiesMutation;
