import { organizationEngagementActionDelete } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementActionListMutation, {
  UseEngagementActionListMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListMutation';

export type UseEngagementActionDelete = {
  deleteEngagementAction: (engagementActionId: string) => void;
  isDeleting: boolean;
};

const useEngagementActionDelete = (options?: UseEngagementActionListMutationOptions): UseEngagementActionDelete => {
  const [deleteEngagementAction, isDeleting] = useEngagementActionListMutation(
    (engagementActionId: string) =>
      organizationEngagementActionDelete({
        engagementActionId,
      }),
    options,
  );

  return {
    deleteEngagementAction,
    isDeleting,
  };
};

export default useEngagementActionDelete;
