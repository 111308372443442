import * as Yup from 'yup';
import FiscalQuarter from '~/extensions/packages/date/packages/fiscal-quarter/FiscalQuarter';
import { RoadmapPdfDownloadFormData } from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap-share-button/packages/roadmap-pdf-download-form/RoadmapPdfDownloadForm';
import { BudgetQuarterDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Packages/BudgetQuarter/Dto/Model.gen';

const isFiscalQuarterGreaterOrEqualToOther = (fiscalQuarterA: BudgetQuarterDto, fiscalQuarterB: BudgetQuarterDto): boolean => {
  if (fiscalQuarterA.year > fiscalQuarterB.year) {
    return true;
  }

  if (fiscalQuarterA.year === fiscalQuarterB.year) {
    return fiscalQuarterA.quarter >= fiscalQuarterB.quarter;
  }

  return false;
};

const RoadmapPdfDownloadFormValidationSchema: (
  currentFiscalQuarter: FiscalQuarter,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Yup.ObjectSchema<any> = currentFiscalQuarter =>
  Yup.object().shape<Partial<RoadmapPdfDownloadFormData>>({
    shouldIncludeUnscheduledInitiative: Yup.boolean().required(),
    shouldIncludeBudgets: Yup.boolean().required(),
    shouldIncludeAssets: Yup.boolean().required(),
    includedStatuses: Yup.array<string>().required('Required').min(1),
    includedPriority: Yup.array<string>().required('Required').min(1),
    startingFiscalQuarter: Yup.object()
      .shape<BudgetQuarterDto>({
        year: Yup.number()
          .required()
          .test('Year Validation', 'Year must be within now and 5 years from now', value => {
            const minYear = currentFiscalQuarter.year - 1;
            const maxYear = minYear + 6;

            return Number(value) >= minYear && Number(value) <= maxYear;
          }),
        quarter: Yup.number()
          .required()
          .test('Quarter Validation', 'Quarter must not be within the past', (value, ctx) => {
            const minYear = currentFiscalQuarter.year - 1;
            const maxYear = minYear + 6;
            const currentQuarter = currentFiscalQuarter.quarter;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const yearSelected: number = ctx.parent.year;

            return (
              ![minYear, maxYear].includes(yearSelected) ||
              (minYear === yearSelected && Number(value) >= Number(currentQuarter)) ||
              (maxYear === yearSelected && Number(value) <= Number(currentQuarter))
            );
          }),
      })
      // Duped in backend D5FD7804-BF09-457D-B69D-8FFA0B9E3E2C
      .test(
        'starting_fiscal_quarter',
        'End fiscal quarter must be greater or equal to starting fiscal quarter',
        function (startingFiscalQuarter, ctx) {
          const { endFiscalQuarter } = ctx.parent;

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return isFiscalQuarterGreaterOrEqualToOther(endFiscalQuarter, startingFiscalQuarter!);
        },
      ),
    endFiscalQuarter: Yup.object()
      .shape<BudgetQuarterDto>({
        year: Yup.number()
          .required()
          .test('Year Validation', 'Year must be within now and 5 years from now', value => {
            const minYear = currentFiscalQuarter.year - 1;
            const maxYear = minYear + 6;

            return Number(value) >= minYear && Number(value) <= maxYear;
          }),
        quarter: Yup.number()
          .required()
          .test('Quarter Validation', 'Quarter must not be within the past', (value, ctx) => {
            const minYear = currentFiscalQuarter.year - 1;
            const maxYear = minYear + 6;
            const currentQuarter = currentFiscalQuarter.quarter;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const yearSelected: number = ctx.parent.year;

            return (
              ![minYear, maxYear].includes(yearSelected) ||
              (minYear === yearSelected && Number(value) >= Number(currentQuarter)) ||
              (maxYear === yearSelected && Number(value) <= Number(currentQuarter))
            );
          }),
      })
      // Duped in backend D5FD7804-BF09-457D-B69D-8FFA0B9E3E2C
      .test(
        'end_fiscal_quarter',
        'End fiscal quarter must be greater or equal to starting fiscal quarter',
        function (endFiscalQuarter, ctx) {
          const { startingFiscalQuarter } = ctx.parent;

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return isFiscalQuarterGreaterOrEqualToOther(endFiscalQuarter!, startingFiscalQuarter);
        },
      ),
  });

export default RoadmapPdfDownloadFormValidationSchema;
