import * as React from 'react';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import ButtonInternal, { ButtonInternalColorMap, ButtonInternalProps, ButtonInternalRef } from '~/neo-ui/packages/button/ButtonInternal';
import useThemeButtonOutline from '~/neo-ui/packages/button/packages/button-outline/hook/useThemeButtonOutline';

export type ButtonOutlineProps = Omit<ButtonInternalProps, 'colorMap'> & Styleable & Themeable;

const ButtonOutline = React.forwardRef<ButtonInternalRef, ButtonOutlineProps>(
  (
    {
      id,
      loading = false,
      size = 'md',
      anchor,
      iconLeft,
      iconRight,
      iconColorOverride,
      iconSizeOverride,
      disabled: isSetToDisabled = false,
      preventOnClickPropagation,
      onClick,
      theme,
      invertColorMap = false,
      className,
      children,
    }: ButtonOutlineProps,
    ref,
  ) => {
    const { themeMap } = useThemeButtonOutline(theme);

    const colorMap: ButtonInternalColorMap = {
      backgroundColorRest: themeMap.backgroundColorRest,
      backgroundColorHover: themeMap.backgroundColorHover,
      backgroundColorActive: themeMap.backgroundColorActive,
      backgroundColorFocus: themeMap.backgroundColorFocus,

      textColorRest: themeMap.textColorRest,
      textColorHover: themeMap.textColorHover,
      textColorActive: themeMap.textColorActive,
      textColorFocus: themeMap.textColorFocus,

      borderColorRest: themeMap.borderColorRest,
      borderColorHover: themeMap.borderColorHover,
      borderColorActive: themeMap.borderColorActive,
      borderColorFocus: themeMap.borderColorFocus,
    };

    // Regardless of what's set, the button is disabled when loading
    const disabled = isSetToDisabled || loading;

    return (
      <ButtonInternal
        className={className}
        id={id}
        size={size}
        anchor={anchor}
        iconLeft={iconLeft}
        iconRight={iconRight}
        iconColorOverride={iconColorOverride}
        iconSizeOverride={iconSizeOverride}
        preventOnClickPropagation={preventOnClickPropagation}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        colorMap={colorMap}
        invertColorMap={invertColorMap}
        ref={ref}
      >
        {children}
      </ButtonInternal>
    );
  },
);

export default ButtonOutline;
