import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import * as React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { EngagementNoteDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementNote/Dto/Model.gen';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import EngagementNoteEditForm, {
  EngagementNoteUpdatePayload,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/packages/engagement-note-edit-form/EngagementNoteEditForm';
import EngagementNoteEditWindow from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/packages/engagement-note-edit-window/EngagementNoteEditWindow';
import Preformatted from '~/neo-ui/packages/text/packages/preformatted/Preformatted';

export type EngagementNoteDashboardCardProps = {
  note: EngagementNoteDto;
  isArchivingNote: boolean;
  isPinningNote: boolean;
  onNotePin: (engagementNoteId: string) => void;
  onNoteUnpin: (engagementNoteId: string) => void;
  onNoteArchive: (engagementNoteId: string) => void;
  onNoteUnarchive: (engagementNoteId: string) => void;
  onNoteUpdate: (payload: EngagementNoteUpdatePayload) => void;
};

const EngagementNoteDashboardCard = ({
  note,
  isArchivingNote,
  isPinningNote,
  onNoteArchive,
  onNoteUnarchive,
  onNotePin,
  onNoteUnpin,
  onNoteUpdate,
}: EngagementNoteDashboardCardProps) => {
  const [isViewingNote, setViewingNote] = React.useState<boolean>(false);

  const startDivClassName = 'start-div';
  const hoverDivClassName = 'hover-div';
  const hoverStyles = css`
    &:hover .${startDivClassName} {
      display: none;
    }

    &:hover .${hoverDivClassName} {
      display: grid;
    }
  `;

  return (
    <Card
      backgroundColor={'light-100'}
      shadow={'sm'}
      padding={'md'}
      css={hoverStyles}
      onClick={() => {
        setViewingNote(true);
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Header
            css={css`
              margin-left: auto;
            `}
            size={7}
            weight={'bold'}
            color={'light-900'}
          >
            CREATED ON:{' '}
            {formatDate(new Date(parseISO(note.createdAt)), {
              format: 'dd/MM/yyyy',
              timezone: TimezoneFormat.Local,
            })}
          </Header>
        </div>
        <div
          className={startDivClassName}
          css={css`
            display: grid;
            grid-template-columns: auto min-content;
            grid-template-areas: ${typeof note.title !== 'undefined' ? `'title button-group'` : `'description button-group'`};
            align-items: center;
            gap: 1rem;
          `}
        >
          {typeof note.title !== 'undefined' ? (
            <Label
              css={css`
                grid-area: title;
              `}
              size={'lg'}
              bold={true}
              color={'dark-700'}
            >
              {note.title}
            </Label>
          ) : (
            <Label
              css={css`
                grid-area: description;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              `}
              color={'dark-050'}
            >
              {note.description}
            </Label>
          )}
          {note.isPinned && (
            <div
              css={css`
                grid-area: button-group;
                margin-left: auto;
                display: flex;
                gap: 0.5rem;
              `}
            >
              <Button
                size={'xs'}
                iconLeft={'Pin'}
                theme={note.isPinned ? 'primary' : undefined}
                preventOnClickPropagation={true}
                loading={isPinningNote}
                disabled={isArchivingNote}
                onClick={() => {
                  if (note.isPinned) {
                    onNoteUnpin(note.engagementNoteId);
                  } else {
                    onNotePin(note.engagementNoteId);
                  }
                }}
              />
            </div>
          )}
        </div>
        <div
          className={hoverDivClassName}
          css={css`
            display: none;
            grid-template-columns: min-content auto min-content;
            grid-template-areas:
              '. title .'
              'archive-button description button-group'
              '. view-note-button .';
            align-items: center;
            gap: 1rem;
          `}
        >
          {typeof note.title !== 'undefined' && (
            <Label
              css={css`
                grid-area: title;
              `}
              size={'lg'}
              bold={true}
              color={'dark-700'}
            >
              {note.title}
            </Label>
          )}
          <Button
            css={css`
              grid-area: archive-button;
            `}
            size={'xs'}
            iconLeft={'Archive'}
            theme={note.isArchived ? 'primary' : undefined}
            preventOnClickPropagation={true}
            loading={isArchivingNote}
            disabled={isPinningNote}
            onClick={() => {
              if (note.isArchived) {
                onNoteUnarchive(note.engagementNoteId);
              } else {
                onNoteArchive(note.engagementNoteId);
              }
            }}
          />
          <Preformatted
            css={css`
              grid-area: description;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
            `}
            text={note.description}
          />
          <div
            css={css`
              grid-area: button-group;
              margin-left: auto;
              display: flex;
              gap: 0.5rem;
            `}
          >
            <Button
              size={'xs'}
              iconLeft={'Pin'}
              theme={note.isPinned ? 'primary' : undefined}
              preventOnClickPropagation={true}
              loading={isPinningNote}
              disabled={isArchivingNote}
              onClick={() => {
                if (note.isPinned) {
                  onNoteUnpin(note.engagementNoteId);
                } else {
                  onNotePin(note.engagementNoteId);
                }
              }}
            />

            <Button
              size={'xs'}
              iconLeft={'Edit'}
              preventOnClickPropagation={true}
              disabled={isArchivingNote || isPinningNote}
              onClick={() => {
                setViewingNote(true);
              }}
            />
          </div>
          <ButtonLink
            css={css`
              width: max-content;
              grid-area: view-note-button;
            `}
            iconRight={'ArrowRight'}
            theme={'primary'}
            preventOnClickPropagation={true}
            onClick={() => {
              setViewingNote(true);
            }}
          >
            View Note
          </ButtonLink>
        </div>
      </div>
      {isViewingNote && (
        <EngagementNoteEditForm
          note={note}
          onEdit={onNoteUpdate}
        >
          <EngagementNoteEditWindow
            note={note}
            isOpen={isViewingNote}
            onDismiss={() => {
              setViewingNote(false);
            }}
            isArchivingNote={isArchivingNote}
            onNoteArchive={onNoteArchive}
            onNoteUnarchive={onNoteUnarchive}
          />
        </EngagementNoteEditForm>
      )}
    </Card>
  );
};

export default EngagementNoteDashboardCard;
