import { css } from '@emotion/react';
import { neoColorToCode } from '~/neo-ui/packages/color/NeoColor';
import Theme, { themeToColor } from '~/neo-ui/packages/color/Theme';
import { boxShadowToCode } from '~/neo-ui/packages/style/BoxShadow';
import { borderRadiusToCode } from '~/neo-ui/packages/style/BorderRadius';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

const formControlStyles = {
  success: css`
    height: 2.25rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;

    &:focus,
    &.focus {
      border-color: ${neoColorToCode('green3')};
      box-shadow: 0 0 0 0.2rem ${neoColorToCode('green2')}40;
    }
  `,
  app: css`
    height: 2.25rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;

    &:enabled {
      &:hover {
        border: 0.0675rem solid #a3a8db;
        box-shadow: 0 0.0675rem 0.25rem -0.0675rem rgba(0, 0, 0, 0.15);
        color: #18191f;
      }

      &:focus {
        background-color: rgba(40, 44, 115, 0.1);
        border: 0.0675rem solid ${colorToCode('secondary-400')};
        box-shadow: none;
        outline: none;
      }
    }
  `,
};

export const themeToFormControlStyle = (theme: Theme | undefined) => {
  // Default app style if undefined
  if (typeof theme === 'undefined') {
    return formControlStyles.app;
  }
  return css`
    height: 2.25rem;
    border-radius: ${borderRadiusToCode('radius200')};
    font-size: 0.875rem;
    border: 0.063rem solid ${colorToCode('dark-900-12')};
    background-color: ${colorToCode('secondary-050')};

    &:enabled {
      &:hover {
        border: 0.125rem solid ${colorToCode(themeToColor(theme, '600'))};
        box-shadow: ${boxShadowToCode('shadow100')};
        color: ${colorToCode('dark-700')};
      }

      &:focus {
        background-color: ${colorToCode(themeToColor(theme, '100'))};
        border: 0.125rem solid ${colorToCode(themeToColor(theme, '400'))}};
        box-shadow: none;
        outline: none;
      }
    }
  `;
};

export default formControlStyles;
