import React from 'react';
import { ValueMappingDisplaySettingsDto } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';
import { IntegrationSetupPageCustomizeSection } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/page-layout/IntegrationSetupPageCustomizeSection';
import pluralize from 'pluralize';
import { pascalOrCamelToSentenceCaseWithSpaces } from '~/extensions/packages/casing/pascalOrCamelToSentenceCaseWithSpaces';
import { css } from '@emotion/react';
import { Mapping } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/mapping/Mapping';
import { Control, UseFormGetValues, UseFormResetField, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { SyncPreferencesData } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useSyncPreferencesForm';
import useMappingDataProcessing from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/mapping/hooks/useMappingDataProcessing';
import { MappingInformationText } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/mapping/MappingInformationText';

export type MappingPayloadTypeSection = {
  payloadType: string;
  payloadSectionInfo: { [key in string]: ValueMappingDisplaySettingsDto };
  control: Control<SyncPreferencesData>;
  resetField: UseFormResetField<SyncPreferencesData>;
  watch: UseFormWatch<SyncPreferencesData>;
  setValue: UseFormSetValue<SyncPreferencesData>;
  getValues: UseFormGetValues<SyncPreferencesData>;
};

const MappingPayloadTypeSection: React.FunctionComponent<MappingPayloadTypeSection> = ({
  payloadType,
  payloadSectionInfo,
  control,
  watch,
  resetField,
  setValue,
  getValues,
}) => {
  const { formKeyToSelectionInfo } = useMappingDataProcessing({ payloadType, payloadSectionsDictionary: payloadSectionInfo });
  return (
    <IntegrationSetupPageCustomizeSection
      key={payloadType}
      title={pluralize(
        Object.values(payloadSectionInfo).find(x => x.payloadTypeLabel)?.payloadTypeLabel ??
          pascalOrCamelToSentenceCaseWithSpaces(payloadType),
      )}
      css={css`
        margin-top: 1rem;

        div > ul:not(:first-of-type) {
          padding-top: 0.5rem;
        }
      `}
      description={
        // Don't show description for nested mappings
        Object.values(payloadSectionInfo).some(({ mapFromOptions }) =>
          mapFromOptions.some(row => row.childOptions && row.childOptions.length > 0),
        ) ? (
          ''
        ) : (
          <MappingInformationText
            mappingSelectionsByName={watch(`selectedMappings.${payloadType}`)}
            displayInformationByName={payloadSectionInfo}
          />
        )
      }
    >
      {Object.entries(payloadSectionInfo).map(([sectionId, sectionInformation]) => (
        <Mapping
          key={`${payloadType}.${sectionId}`}
          sectionInformation={sectionInformation}
          control={control}
          resetField={resetField}
          formKeyToSelectionInfo={formKeyToSelectionInfo}
          setValue={setValue}
          getValues={getValues}
        />
      ))}
    </IntegrationSetupPageCustomizeSection>
  );
};
export default MappingPayloadTypeSection;
