import * as React from 'react';
import { sleep } from '~/extensions/packages/sleep/sleep';
import Form from '~/neo-ui/packages/form/Form';

const MockForm = <T extends object>({
  defaultValue,
  submitMethod = 'auto',
  children,
}: {
  defaultValue: T;
  submitMethod?: 'auto' | 'manual';
  children: React.ReactNode;
}) => {
  const [submittedState, setSubmittedState] = React.useState<T>();

  return (
    <Form
      label={'Mock form!'}
      submitMethod={submitMethod}
      defaultFormData={defaultValue}
      onSubmit={async data => {
        // Temporary remove action to make story work ,action addon lib is broken
        // action('Submitted successfully')(data);
        await sleep(Math.random() * 500 + 300);
        setSubmittedState(data);
      }}
    >
      {children}
      <pre>Submitted state: {JSON.stringify(submittedState ?? 'Nothing yet', null, 2)}</pre>
    </Form>
  );
};

export default MockForm;
