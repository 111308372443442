import { createContext } from 'react';
import { EnceladusBodyLayoutHeaderProps } from '~/neo-ui/packages/layout/packages/layout-enceladus/packages/enceladus-body-layout-header/EnceladusBodyLayoutHeader';
import { EnceladusBodyUpgradeBannerProps } from '~/neo-ui/packages/layout/packages/layout-enceladus/packages/enceladus-body-upgrade-banner/EnceladusBodyUpgradeBanner';

export type LayoutHeaderContextData = {
  setLayoutHeaderProps: (props: EnceladusBodyLayoutHeaderProps | undefined) => void;
  layoutHeaderProps: EnceladusBodyLayoutHeaderProps | undefined;
  setUpgradeBannerProps: (props: EnceladusBodyUpgradeBannerProps | undefined) => void;
  upgradeBannerProps: EnceladusBodyUpgradeBannerProps | undefined;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const LayoutHeaderContext = createContext<LayoutHeaderContextData | undefined>(undefined);

export default LayoutHeaderContext;
