import { css } from '@emotion/react';
import { Styleable } from '~/neo-ui/model/capacity';
import { ButtonProps } from '../../Button';
import { FunctionComponent, ReactElement } from 'react';

const ButtonGroup: FunctionComponent<
  {
    children: ReactElement<ButtonProps>[];
  } & Styleable
> = ({ className, children }) => (
  <div
    css={css`
      position: relative;
      display: inline-flex;
      flex-wrap: nowrap;

      .tooltip-target:not(:first-of-type) button,
      a {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .tooltip-target:not(:last-of-type) button,
      a {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      a,
      button {
        :not(:last-of-type) {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        :not(:first-of-type) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    `}
    className={className}
  >
    {children}
  </div>
);

export default ButtonGroup;
