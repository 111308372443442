import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import * as React from 'react';
import { css } from '@emotion/react';
import Color from '~/neo-ui/packages/color/Color.gen';
import { Styleable } from '~/neo-ui/model/capacity';

export type IntegrationSetupPageSubSectionHeaderProps = {
  title: string;
  description?: React.ReactNode;
  descriptionColor?: Color;
} & Styleable;

export const IntegrationSetupPageSubSectionHeader: React.FunctionComponent<IntegrationSetupPageSubSectionHeaderProps> = ({
  title,
  description,
  descriptionColor = 'dark-900-64',
  className,
}) => (
  <hgroup
    className={className}
    css={css`
      margin-right: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}
  >
    <Header
      size={3}
      weight={'bold'}
      css={css`
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      `}
    >
      {title}
    </Header>
    {description && (
      <Label
        size={'sm'}
        color={descriptionColor}
      >
        {description}
      </Label>
    )}
  </hgroup>
);

export const IntegrationSetupPageSubSubSectionHeader: React.FunctionComponent<IntegrationSetupPageSubSectionHeaderProps> = ({
  title,
  description,
  descriptionColor,
}) => (
  <hgroup>
    <Header
      size={4}
      weight={'bold'}
      css={css`
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
      `}
    >
      {title}
    </Header>
    {description && (
      <Label
        size={'sm'}
        color={descriptionColor}
      >
        {description}
      </Label>
    )}
  </hgroup>
);
export default IntegrationSetupPageSubSectionHeader;
