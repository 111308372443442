import * as React from 'react';
import { css } from '@emotion/react';
import { countryList } from '~/neo-ui/model/Country';
import { BillingInfo } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import BannerInfo from '~/neo-ui/packages/banner/packages/banner-info/BannerInfo';

export type DisposalLocationRestrictionBannerProps = {
  billingInfo: BillingInfo;
  featureRequestUrl: string;
};

const DisposalLocationRestrictionBanner = ({ billingInfo, featureRequestUrl }: DisposalLocationRestrictionBannerProps) => {
  const country = countryList.find(country => country.iso2Code === billingInfo?.billingAddress?.address.countryCode);

  return (
    <BannerInfo
      title={
        typeof country !== 'undefined' ? `Disposal isn’t available in ${country.name} yet.` : 'Disposal isn’t available in your region yet.'
      }
      description={
        <React.Fragment>
          <Label>
            ScalePad IT Asset Disposal (ITAD) provides a simple, secure, verifiable, and environmentally-friendly disposal process for your
            clients’ decommissioned IT assets. It’s currently available in the United States only.
          </Label>
          <br />
          <Label>If you would like to access ITAD in your region, please submit a request.</Label>
        </React.Fragment>
      }
      graphic={{
        icon: { icon: 'UnavailableRegion', color: 'warning-400' },
      }}
      css={css`
        margin-bottom: unset;
      `}
      callToAction={{
        text: 'Submit request',
        link: featureRequestUrl,
        theme: 'primary',
      }}
      openInNewTab={true}
    />
  );
};

export default DisposalLocationRestrictionBanner;
