import useMutationInternal, {
  UseMutationInternal,
  UseMutationInternalApiCall,
  UseMutationInternalOptions,
} from '~/extensions/packages/hooks/useMutationInternal';
import useDisposalOrderInfoMutationContext from '~/wm/packages/disposal/packages/order-info/context/hooks/useDisposalOrderInfoMutationContext';

export type UseDisposalOrderInfoMutationOptions = UseMutationInternalOptions;

const useDisposalOrderInfoMutation = <TArg = Record<string, unknown>>(
  apiCall: UseMutationInternalApiCall<TArg>,
  options?: UseDisposalOrderInfoMutationOptions,
): UseMutationInternal<TArg> => {
  const { triggerDisposalOrderInfoReload } = useDisposalOrderInfoMutationContext();

  return useMutationInternal(apiCall, triggerDisposalOrderInfoReload, options);
};

export default useDisposalOrderInfoMutation;
