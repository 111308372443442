import WizardPageStep from '~/neo-ui/packages/wizard/packages/wizard-page/types/WizardPageStep';
import { BillingInfo as BillingInfoType } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { PaymentVersion } from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';
import { DisposalDynamicPricingDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/Checkout/Dto/Model.gen';
import DisposalBulkCheckoutFormData from '~/wm/packages/disposal/types/DisposalBulkCheckoutFormData';

import BulkOrderSummaryWithBillingSection from '~/wm/packages/disposal/wizard/section/BulkOrderSummaryWithBillingSection';
import PaymentSection from '~/wm/packages/disposal/wizard/section/PaymentSection';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import { DisposalOrderPickupMaterialTypeDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderPickup/Packages/DisposalOrderPickupMaterial/Dto/Model.gen';

const assetTypeCountsFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values => values.assetTypeCounts;

const creditCardFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values => values.customerToken;

const couponFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values => values.coupon;

const materialsFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values => values.materials;

const shipmentReturnAddressFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData> = values =>
  values.shipmentReturnAddress.billingAddress;

const getDisposalBulkBillingStep = (
  billingInfo: BillingInfoType,
  paymentVersion: PaymentVersion,
  disposalTermsOfServiceUrl: string,
  termsOfServiceUrl: string,
  brandName: string,
  disposalDynamicPricing: DisposalDynamicPricingDto,
  disposalAssetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[],
  disposalOrderPickupMaterialTypeAvailabilities: DisposalOrderPickupMaterialTypeDto[],
): WizardPageStep<DisposalBulkCheckoutFormData> => ({
  header: {
    label: 'Billing',
    icon: 'CreditCard',
  },
  fields: [creditCardFieldKey],
  sections: [
    () =>
      BulkOrderSummaryWithBillingSection({
        disposalTermsOfServiceUrl,
        termsOfServiceUrl,
        brandName,
        assetTypeCountsFieldKey,
        materialsFieldKey,
        shipmentReturnAddressFieldKey,
        couponFieldKey,
        disposalAssetTypeAvailabilities,
        disposalOrderPickupMaterialTypeAvailabilities,
        disposalDynamicPricing,
      }),
    ({ isEditingIndex, onEditing }) =>
      PaymentSection({
        disposalDynamicPricing,
        billingInfo,
        paymentVersion,
        creditCardFieldKey,
        couponFieldKey,
        assetTypeCountsFieldKey,
        isEditingIndex,
        onEditing,
      }),
  ],
  onCompleteStateChange: () => true,
  key: 'Billing',
});

export default getDisposalBulkBillingStep;
