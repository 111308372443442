import * as React from 'react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import FormInputTextInternal from '~/neo-ui/packages/form/packages/form-input/FormInputTextInternal';
import useFormInputBuilder from '~/neo-ui/packages/form/packages/form-input/hooks/useFormInputBuilder';
import useThemeInputTitle from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/hooks/useThemeInputTitle';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import { InputTitleSizes, inputTitleSizeToInputTitleDisplayDetails } from '~/neo-ui/packages/input/packages/input-title/InputTitle';
import useStylesInputTitle from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/hooks/useStylesInputTitle';

export type FormDatepickerInputProps<T> = {
  fieldKey: FieldKeyExpression<T>;
  disabled?: boolean;
  size?: InputTitleSizes;
  showFormError?: boolean;
  fixedErrorPosition?: boolean;
  onChange?: (value: string) => void;
  min?: number | string | undefined;
  max?: number | string | undefined;
} & Themeable &
  Styleable;

/**
 * Date input to be placed inside a Form component
 */
const FormDatepickerInput = <T,>({
  fieldKey,
  disabled = false,
  size = 'md',
  theme,
  showFormError,
  fixedErrorPosition = false,
  onChange,
  min,
  max,
  className,
}: FormDatepickerInputProps<T>) => {
  const { touched, error } = useFormInputBuilder<T>(fieldKey);

  // Invalid state uses negative styles
  const hasFormInputValidationError = typeof error !== 'undefined' && touched;
  const { themeMap } = useThemeInputTitle(hasFormInputValidationError ? 'negative' : theme);
  const displayDetails = inputTitleSizeToInputTitleDisplayDetails(size);
  const { stylesInputTitle } = useStylesInputTitle(themeMap, displayDetails);

  return (
    <FormInputTextInternal
      fieldKey={fieldKey}
      showError={showFormError}
      fixedErrorPosition={fixedErrorPosition}
      className={className}
      onChange={onChange}
    >
      {({ field }) => (
        <input
          type={'date'}
          disabled={disabled}
          css={[stylesInputTitle]}
          {...field}
          min={min}
          max={max}
        />
      )}
    </FormInputTextInternal>
  );
};

export default FormDatepickerInput;
