import { ClassNames, css } from '@emotion/react';
import * as React from 'react';
import ListCard from '~/legacy-ui/packages/list/packages/list-card/ListCard';
import ListCardGroup from '~/legacy-ui/packages/list/packages/list-card/packages/list-card-group/ListCardGroup';
import ListGroupItem from '~/legacy-ui/packages/list/packages/list-group/packages/list-group-item/ListGroupItem';
import InputRadio from '../../../neo-ui/packages/input/packages/input-radio/InputRadio';

type KeyProperty<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

export type ControlProps<T> = {
  selected?: T;
  currentSelected?: T;
  items: T[];
  itemKey: KeyProperty<T>;
  labelKey: KeyProperty<T>;
  itemButton: React.FunctionComponent;
  onSelected: (selectedItem: T) => void;
};

/**
 * Converts a list of items into a radio button control (single selection)
 *
 * @deprecated No direct replacement but this is a legacy component that needs one if used again
 */
const Control = <T,>({ selected, currentSelected, items, itemKey, labelKey, itemButton: ItemButton, onSelected }: ControlProps<T>) => (
  <ListCardGroup
    type="stacked"
    bgColor="light-200"
  >
    {bgColor =>
      items.map(item => {
        const itemIdentifier = `${item[itemKey] as unknown as string | number}`;
        const itemLabel = `${item[labelKey] as unknown as string | number}`;
        const isSelected = selected === item;

        return (
          <div
            key={itemIdentifier}
            css={css`
              &:not(:last-child) {
                margin-bottom: 5px;
              }
              overflow: hidden;
            `}
          >
            <ListCard
              css={css`
                min-height: 30px;
                overflow: hidden;
              `}
              onClick={() => onSelected(item)}
              actionElement={
                <InputRadio
                  isSelected={isSelected}
                  item={item}
                  itemIdentifier={itemIdentifier}
                  onSelected={onSelected}
                />
              }
              bgColor={bgColor}
            >
              <ClassNames>
                {({ css }) => (
                  <ListGroupItem
                    css={css`
                      overflow: hidden;
                    `}
                    liClassName={css`
                      overflow: hidden;
                      margin-left: 13px;
                    `}
                  >
                    <p
                      css={css`
                        user-select: none;
                        margin-bottom: 0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      `}
                    >
                      {itemLabel}
                    </p>
                  </ListGroupItem>
                )}
              </ClassNames>
              <div
                css={css`
                  max-width: 0px;
                  height: 30px;
                `}
              />
              {isSelected &&
                typeof currentSelected !== 'undefined' &&
                currentSelected !== null &&
                currentSelected[itemKey] !== itemIdentifier && <ItemButton />}
            </ListCard>
          </div>
        );
      })
    }
  </ListCardGroup>
);

export default Control;
