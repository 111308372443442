import React from 'react';
import { useWindowDimensions } from '~/extensions/packages/hooks/useWindowDimensions';
import { textareaMinHeightRem } from '../InputTextarea';

export const useTextareaInput = <T>(textareaRef: React.RefObject<HTMLTextAreaElement>, value: T) => {
  const { innerWindowHeight, innerWindowWidth } = useWindowDimensions();
  React.useLayoutEffect(() => {
    if (textareaRef !== null && textareaRef.current !== null) {
      // Reset height - important to shrink on delete
      // eslint-disable-next-line no-param-reassign
      textareaRef.current.style.height = 'inherit';

      // Set height
      // eslint-disable-next-line no-param-reassign
      textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight / 16 + 0.3125, textareaMinHeightRem)}rem`;
    }
  }, [textareaRef, value, innerWindowHeight, innerWindowWidth]);
};
