import React from 'react';
import ComponentPagination from '~/neo-ui/packages/navigation/packages/component-pagination/ComponentPagination';
import useRoadmapInitiativesContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapInitiativesContext';
import { css } from '@emotion/react';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';

export type RoadmapQuarterPaginationProps = {
  /**
   * Display the button as left-aligned text
   */
  leftAlign?: boolean;
};
/**
 * Provides left and right navigation of a roadmap's quarters
 */
const RoadmapQuarterPagination = ({ leftAlign = false }: RoadmapQuarterPaginationProps) => {
  const { currentFiscalQuarter } = useRegionalSettingsInfoContext();
  const { onDisplayFiscalQuartersChange, fiscalQuarterAvailabilities, defaultFiscalQuarter, roadmapsFiscalQuarterPageSize } =
    useRoadmapInitiativesContext();

  const [startingOption, setStartingOption] = React.useState(
    fiscalQuarterAvailabilities
      .map((fiscalQuarter, index) => ({ id: index, fiscalQuarter }))
      .find(
        fiscalQuarterOption =>
          fiscalQuarterOption.fiscalQuarter.quarter === defaultFiscalQuarter.quarter &&
          fiscalQuarterOption.fiscalQuarter.year === defaultFiscalQuarter.year,
      ) || { id: 4, fiscalQuarter: currentFiscalQuarter },
  );

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 0.3125rem;
      `}
    >
      <ComponentPagination
        startingOption={startingOption}
        pageSize={roadmapsFiscalQuarterPageSize}
        options={fiscalQuarterAvailabilities.map((fiscalQuarter, index) => ({
          option: { id: index, fiscalQuarter },
          label: `Q${fiscalQuarter.quarter}, ${fiscalQuarter.year}`,
        }))}
        onClick={option => {
          onDisplayFiscalQuartersChange(option.fiscalQuarter);
          setStartingOption(option);
        }}
      />
      <ButtonLink
        iconLeft={'CurrentCalendarQuarter'}
        leftAlign={leftAlign}
        onClick={() => {
          onDisplayFiscalQuartersChange(currentFiscalQuarter);
          setStartingOption({ id: 4, fiscalQuarter: currentFiscalQuarter });
        }}
      >
        Current quarter
      </ButtonLink>
    </div>
  );
};

export default RoadmapQuarterPagination;
