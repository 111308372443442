import * as React from 'react';
import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import usePanelTabGroupContext from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/hooks/usePanelTabGroupContext';
import Icon from '~/neo-ui/packages/icon/Icon';
import useEngagementNoteListPanelTabContext from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/context/hooks/useEngagementNoteListPanelTabContext';
import EngagementNoteListSection from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/packages/engagement-note-list-section/EngagementNoteListSection';
import { EngagementListType } from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/EngagementListPage';
import Badge from '~/neo-ui/packages/badge/Badge';
import EngagementNoteCreateForm from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/packages/engagement-note-create-form/EngagementNoteCreateForm';
import useEngagementNoteListCreate from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/hooks/useEngagementNoteListCreate';
import EngagementNoteCreateWindow from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/packages/engagement-note-create-window/EngagementNoteCreateWindow';
import ToggleTileHorizontal from '~/neo-ui/packages/toggle/packages/toggle-tile/packages/toggle-tile-horizontal/ToggleTileHorizontal';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import engagementDataCollectionSources from '~/wm/packages/organization/packages/engagement/model/engagementDataCollectionSources';

const EngagementNoteListPanelTab = () => {
  const { notes, hasNextPage, statusAvailabilities, statusCounts } = useEngagementNoteListPanelTabContext();

  const { organizationId } = useOrganizationContext();
  const { createEngagementNote } = useEngagementNoteListCreate();

  const [isCreatingNote, setCreatingNote] = React.useState(false);

  const { currentPageByTab, currentTabId, setCurrentTabPage, currentFilterValueByTab, setCurrentTabFilterValue } =
    usePanelTabGroupContext<EngagementListType>();

  const currentPage = currentPageByTab.get(currentTabId);

  if (typeof currentPage === 'undefined') {
    throw new Error('Current page is undefined from tab context');
  }

  return (
    <React.Fragment>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 1rem;
          `}
        >
          {statusAvailabilities.map(status => {
            const statusCount = statusCounts.find(statusCount => statusCount.id === status.id);

            const count = statusCount?.count ?? 0;

            const isSelected = currentFilterValueByTab.get(currentTabId) === status.displayLabel;

            return (
              <ToggleTileHorizontal
                key={status.id}
                css={css`
                  flex-grow: 1;
                  min-width: 0;
                  background-color: ${colorToCode(isSelected ? 'primary-050' : 'light-000')};
                `}
                title={status.displayLabel}
                type={'single'}
                isSelected={isSelected}
                disabled={false}
                onClick={() => {
                  setCurrentTabFilterValue(status.displayLabel);
                  setCurrentTabPage(1);
                }}
                appendComponent={
                  status.displayCount && (
                    <Badge
                      bgColor={'light-000'}
                      textColor={'dark-700'}
                      fontWeight={'700'}
                    >
                      {count}
                    </Badge>
                  )
                }
              />
            );
          })}
        </div>

        <div
          css={css`
            display: flex;
            gap: 1rem;
            align-items: center;
          `}
        >
          <Button
            size={'sm'}
            iconLeft={'Add'}
            theme={'primary'}
            onClick={() => {
              setCreatingNote(true);
            }}
          >
            New Notes
          </Button>
          <div
            css={css`
              margin-left: auto;
              display: flex;
              gap: 1rem;
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <Button
                size={'sm'}
                onClick={() => {
                  setCurrentTabPage(currentPage - 1);
                }}
                disabled={currentPage === 1}
              >
                <Icon icon="ArrowLeft" />
              </Button>
              <Button
                size={'sm'}
                onClick={() => {
                  setCurrentTabPage(currentPage + 1);
                }}
                disabled={!hasNextPage}
              >
                <Icon icon="ArrowRight" />
              </Button>
            </div>
          </div>
        </div>
        <EngagementNoteListSection notes={notes} />
      </div>
      {isCreatingNote && (
        <EngagementNoteCreateForm
          organizationId={organizationId}
          onCreate={createEngagementNote}
          createdFrom={engagementDataCollectionSources.engagementNoteCreationSource.noteList}
        >
          <EngagementNoteCreateWindow
            isOpen={isCreatingNote}
            onDismiss={() => {
              setCreatingNote(false);
            }}
          />
        </EngagementNoteCreateForm>
      )}
    </React.Fragment>
  );
};

export default EngagementNoteListPanelTab;
