import { useCallback } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { assetSoftwareSearchSpreadsheet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { Enum as AssetManagementClientBeastClientSoftwareSearchModelQuerySearchContextSoftwareSearchContextFactoryNestedEnum } from '@AssetManagementClient/BeastClient/Software/Search/Model/Query/SearchContext/SoftwareSearchContextFactoryNested.gen';
import { Filter as AssetManagementClientBeastClientPrimitivesQueryRequestSoftwareFilterFactoryNestedEnum_QueryRequestNestedFilter } from '@AssetManagementClient/BeastClient/Primitives/Query/Request/SoftwareFilterFactoryNested/Enum_/QueryRequestNested.gen';
import { Order as AssetManagementClientBeastClientPrimitivesQuerySortingOrder } from '@AssetManagementClient/BeastClient/Primitives/Query/Sorting.gen';
import { SearchPayload as AssetManagementClientBeastClientPrimitivesQuerySearchSearchPayload } from '@AssetManagementClient/BeastClient/Primitives/Query/Search.gen';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';

type UseDownloadSoftwareSpreadsheet = () => {
  downloadSoftwareSpreadsheet: DownloadSoftwareSpreadsheet;
};

type DownloadSoftwareSpreadsheet = (
  searchContext: AssetManagementClientBeastClientSoftwareSearchModelQuerySearchContextSoftwareSearchContextFactoryNestedEnum,
  frontendScope: FrontendScope,
  filters: AssetManagementClientBeastClientPrimitivesQueryRequestSoftwareFilterFactoryNestedEnum_QueryRequestNestedFilter[],
  sorts: AssetManagementClientBeastClientPrimitivesQuerySortingOrder[],
  searchPayload?: AssetManagementClientBeastClientPrimitivesQuerySearchSearchPayload,
) => void;

export const useDownloadSoftwareSpreadsheet: UseDownloadSoftwareSpreadsheet = () => {
  const { callApi } = useApi();
  const downloadSoftwareSpreadsheet = useCallback(
    async (
      searchContext: AssetManagementClientBeastClientSoftwareSearchModelQuerySearchContextSoftwareSearchContextFactoryNestedEnum,
      frontendScope: FrontendScope,
      filters: AssetManagementClientBeastClientPrimitivesQueryRequestSoftwareFilterFactoryNestedEnum_QueryRequestNestedFilter[],
      sorts: AssetManagementClientBeastClientPrimitivesQuerySortingOrder[],
      searchPayload?: AssetManagementClientBeastClientPrimitivesQuerySearchSearchPayload,
    ) => {
      const stream = await callApi(() =>
        assetSoftwareSearchSpreadsheet({
          searchContext,
          frontendScope,
          filters,
          sorts,
          searchPayload,
        }),
      );

      if (!stream) {
        return;
      }
    },
    [callApi],
  );

  return { downloadSoftwareSpreadsheet };
};

export default useDownloadSoftwareSpreadsheet;
