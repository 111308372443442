import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';

export type DisableElementOverlayProps = {
  disabled: boolean;
  children: React.ReactNode;
} & Styleable;

/**
 * Disables the component provided
 */
const DisableElementOverlay: React.FunctionComponent<DisableElementOverlayProps> = ({ disabled, className, children }) =>
  disabled ? (
    <div
      css={css`
        position: relative;
      `}
      className={className}
    >
      <div
        css={css`
          z-index: 1;
        `}
      >
        {children}
      </div>
      <div
        css={css`
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background-color: #f8f8fc6b;
          z-index: 2;
        `}
      />
    </div>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );

export default DisableElementOverlay;
