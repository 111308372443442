import React, { useCallback, useMemo, useState } from 'react';
import {
  IntegrationSetupSubmitFunction,
  IntegrationSetupSubmitState,
} from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useIntegrationSetupAndDefinitions';
import useIntegrationCredentialSubmit from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/hooks/useIntegrationCredentialSubmit';
import { ButtonInternalOnClick } from '~/neo-ui/packages/button/ButtonInternal';
import { Request } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Controller/IntegrationSetupUpsertControllerNested.gen';
import IntegrationSetupPageSubSection from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/page-layout/IntegrationSetupPageSubSection';
import Spinner from '~/neo-ui/spinner/Spinner';
import Icon from '~/neo-ui/packages/icon/Icon';
import Button from '~/neo-ui/packages/button/Button';
import invariant from 'tiny-invariant';
import IntegrationCredentialForm, {
  SanitizedCredentialFieldDisplaySettingsDto,
} from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/packages/credential-display-modes/packages/credential-form/IntegrationCredentialForm';
import {
  CredentialFieldDisplaySettingsDto,
  PermissionWarningDto,
} from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';
import { Enum as InputTypeEnum } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Credential/InputTypeNested.gen';
import { forEach } from '~/extensions/packages/iterable/iterableExtensions';
import mapBackendFieldKey from '~/neo-ui/packages/form/packages/form-action/packages/form-validation/mapBackendFieldKey';
import InputTypeMap from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/packages/credential-display-modes/packages/credential-form/packages/credential-inputs/InputTypeMap';
import { calculateStage } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/utils';
import { CalloutLink } from '~/neo-ui/packages/callout-link/CalloutLink';
import { css } from '@emotion/react';
import { Discriminant } from '@AssetManagementClient/Primitives/Results/IntegrationSetupUpsertControllerNested/Response_/IntegrationSetupError_/ResultNested.gen';

export const findOauthCredentialField = (credentialFields: { [key in string]: CredentialFieldDisplaySettingsDto }) =>
  Object.values(credentialFields).find(x => x.inputType.type === InputTypeEnum.InteractiveOAuth);

export type IntegrationCredentialsProps = {
  integrationId: string;
  integrationSetupId?: string;
  credentialFields: { [key in string]: CredentialFieldDisplaySettingsDto };
  initialPermissionWarnings: PermissionWarningDto[];

  /**
   * Function to send the form data (Outward API call)
   * @param formData
   */
  submitIntegrationSetupForm: IntegrationSetupSubmitFunction;
  integrationSetupSubmitState: IntegrationSetupSubmitState;
  expandSuccessSection: boolean;
  setExpandSuccessSection: (enabled: boolean) => void;
  helpCenterLinkUrl?: string;
  integrationOauthRedirectUri: string;
};

export const IntegrationCredentials: React.FunctionComponent<IntegrationCredentialsProps> = ({
  integrationId,
  integrationSetupId,
  credentialFields,
  initialPermissionWarnings,
  submitIntegrationSetupForm,
  integrationSetupSubmitState,
  expandSuccessSection,
  setExpandSuccessSection,
  helpCenterLinkUrl,
}) => {
  const { credentialSubmitState, submitCredentialForm, resetToSuccessState } = useIntegrationCredentialSubmit({
    submitIntegrationSetupForm,
    integrationSetupSubmitState,
  });

  const credentialSuccessButtonOnClick: ButtonInternalOnClick = useCallback(() => {
    setExpandSuccessSection(!expandSuccessSection);
  }, [expandSuccessSection, setExpandSuccessSection]);

  const [integrationSetupPageSubSectionErrorDescription, setIntegrationSetupPageSubSectionErrorDescription] = useState<string | undefined>(
    undefined,
  );

  const [permissionWarnings, setPermissionWarnings] = useState<PermissionWarningDto[]>(initialPermissionWarnings);

  const dataTypePermissionWarnings = useMemo(
    () =>
      Array.from(new Set(permissionWarnings.filter(warning => typeof warning.dataType !== 'undefined').map(warning => warning.dataType!))),
    [permissionWarnings],
  );

  const isCredentialFieldRequired = useCallback(
    (credentialFieldDto: CredentialFieldDisplaySettingsDto) => {
      const inputType = credentialFieldDto.inputType.type;
      if (inputType === InputTypeEnum.Select || inputType === InputTypeEnum.InteractiveOAuth) {
        return credentialFieldDto.inputType.isRequired;
      }

      if (inputType === InputTypeEnum.Text) {
        return credentialFieldDto.inputType.validation.isRequired;
      }

      // Only require text secure inputs if the integration setup has never been created (no integrationSetupId saved)
      // Some secure text inputs aren't required upon creation, like MFA
      if (inputType === InputTypeEnum.TextSecure) {
        return integrationSetupId === undefined ? credentialFieldDto.inputType.validation.isRequired : false;
      }

      return false;
    },
    [integrationSetupId],
  );

  /**
   * Adds the isInputRequired field to CredentialFieldDisplaySettingsDto
   */
  const sanitizedCredentialFields = useMemo(() => {
    const sanitizedCredentialFields: { [key in string]: SanitizedCredentialFieldDisplaySettingsDto } = {};
    forEach(Object.keys(credentialFields), credentialKey => {
      if (credentialFields[credentialKey].inputType.type !== InputTypeEnum.InteractiveOAuth) {
        sanitizedCredentialFields[credentialKey] = {
          ...credentialFields[credentialKey],
          isInputRequired: isCredentialFieldRequired(credentialFields[credentialKey]),
        };
        sanitizedCredentialFields[credentialKey].name = mapBackendFieldKey(credentialFields[credentialKey].name);
      }
    });
    return sanitizedCredentialFields;
  }, [credentialFields, isCredentialFieldRequired]);

  /**
   * Returns transformed credential form data for IntegrationUpsertControllerRequests
   * Text secure fields must be undefined to allow updating those credentials with no change
   */
  const sanitizeCredentialRequestValues = useCallback(
    (credentialRequestValues?: { [key in string]: string }) => {
      // Skip processing if no credential field info available or there are no values to sanitize
      if (!credentialRequestValues || Object.keys(sanitizedCredentialFields).length === 0) {
        return undefined;
      }

      const sanitizedCredentialRequestValues = { ...credentialRequestValues };

      // Remove any text-secure fields that are empty and not required
      forEach(Object.keys(sanitizedCredentialRequestValues) ?? [], credentialFieldKey => {
        const requestFieldIsEmpty = !sanitizedCredentialRequestValues[credentialFieldKey];
        if (
          !sanitizedCredentialFields[credentialFieldKey].isInputRequired &&
          sanitizedCredentialFields[credentialFieldKey].inputType.type === InputTypeEnum.TextSecure &&
          requestFieldIsEmpty
        ) {
          delete sanitizedCredentialRequestValues[credentialFieldKey];
        }
      });

      return sanitizedCredentialRequestValues;
    },
    [sanitizedCredentialFields],
  );

  const submitCredentialFormAndUpdateDisplay = useCallback(
    async (integrationUpsertRequest: Request) => {
      const sanitizedIntegrationUpsertRequest = { ...integrationUpsertRequest };
      sanitizedIntegrationUpsertRequest.credentialValues =
        integrationUpsertRequest.credentialValues !== undefined
          ? sanitizeCredentialRequestValues(integrationUpsertRequest.credentialValues)
          : undefined;
      const response = await submitCredentialForm(sanitizedIntegrationUpsertRequest);
      setExpandSuccessSection(false);
      if (response.case === Discriminant.Ok) {
        setPermissionWarnings(response.value.integrationSetupDisplay.integrationPermissionWarnings);
      }
      return response;
    },
    [setExpandSuccessSection, submitCredentialForm, sanitizeCredentialRequestValues, setPermissionWarnings],
  );

  const credentialInputEditDisplay = (
    <IntegrationSetupPageSubSection
      title={'Enter your credentials'}
      description={'Connect to this integration to get your data and customize your sync options.'}
    >
      {helpCenterLinkUrl && (
        <CalloutLink
          theme={'secondary'}
          icon={'ActionInfo'}
          headerText={'Some setup must be completed in your integration'}
          buttonText={'See the steps'}
          linkUrl={helpCenterLinkUrl}
          css={css`
            margin-top: 0.5rem;
          `}
        />
      )}
    </IntegrationSetupPageSubSection>
  );

  const credentialCheckingDisplay = (
    <IntegrationSetupPageSubSection
      title={'Connecting...'}
      description={"We're checking your credentials and data..."}
      iconDisplay={
        <Spinner
          size={'md'}
          color={'positive-400'}
        />
      }
    />
  );

  const credentialFailedDisplay = (
    <IntegrationSetupPageSubSection
      title={'There was a problem with your credentials'}
      description={integrationSetupPageSubSectionErrorDescription ?? 'Fix your credentials to connect to this integration'}
      descriptionColor={'negative-400'}
      iconDisplay={
        <Icon
          color={'negative-400'}
          icon={'Negative'}
          sizePx={28}
        />
      }
    />
  );

  const oAuthTileInformation = findOauthCredentialField(credentialFields);

  const isOauthTileDisplayed = oAuthTileInformation !== undefined;

  const credentialSuccessDisplay = (
    <IntegrationSetupPageSubSection
      title={expandSuccessSection ? 'Update your credentials' : isOauthTileDisplayed ? 'Your credentials are saved' : "We're connected"}
      description={
        expandSuccessSection
          ? 'Save changes to update your credentials and sync again.'
          : isOauthTileDisplayed
          ? 'Check your authorization if you edit your credentials'
          : undefined
      }
      iconDisplay={
        expandSuccessSection ? undefined : (
          <Icon
            color={'positive-400'}
            icon={'Positive'}
            sizePx={28}
          />
        )
      }
      buttonDisplay={
        expandSuccessSection ? undefined : (
          <Button
            iconLeft={'Edit'}
            onClick={credentialSuccessButtonOnClick}
            size={'md'}
          >
            Edit credentials
          </Button>
        )
      }
    />
  );

  const credentialSuccessWithWarningsDisplay = (
    <IntegrationSetupPageSubSection
      title={'Your credentials are saved, but we still need permissions'}
      description={"We don't have permission for all types of data yet"}
      iconDisplay={
        <Icon
          color={'warning-400'}
          icon={'Warning'}
          sizePx={28}
        />
      }
      buttonDisplay={
        expandSuccessSection ? undefined : (
          <Button
            iconLeft={'Edit'}
            onClick={credentialSuccessButtonOnClick}
            size={'md'}
          >
            Edit credentials
          </Button>
        )
      }
    >
      {helpCenterLinkUrl && (
        <CalloutLink
          theme={'secondary'}
          icon={'ActionInfo'}
          headerText={`Go to your integration to grant ScalePad permission for the following data types: ${dataTypePermissionWarnings.join(
            ', ',
          )}`}
          buttonText={'See the steps'}
          linkUrl={helpCenterLinkUrl}
          css={css`
            margin-top: 0.5rem;
          `}
        />
      )}
    </IntegrationSetupPageSubSection>
  );

  const displayOnly = (
    <IntegrationSetupPageSubSection
      title={'Plugin connected'}
      description={'Successfully connected through our plugin'}
    >
      <InputTypeMap inputs={sanitizedCredentialFields} />
    </IntegrationSetupPageSubSection>
  );

  const credentialStateToDisplay = (state: IntegrationSetupSubmitState, allDisplayOnly: boolean, hasWarnings: boolean) => {
    if (allDisplayOnly) {
      return displayOnly;
    }

    switch (state) {
      case IntegrationSetupSubmitState.Initializing:
        return <></>;
      case IntegrationSetupSubmitState.Ready:
        return credentialInputEditDisplay;
      case IntegrationSetupSubmitState.Submitting:
        return credentialCheckingDisplay;
      case IntegrationSetupSubmitState.LastSubmitFailed:
        return credentialFailedDisplay;
      case IntegrationSetupSubmitState.LastSubmitSuccess:
        return hasWarnings ? credentialSuccessWithWarningsDisplay : credentialSuccessDisplay;
      default:
        invariant(false, 'Undefined integration credential state');
    }
  };

  // Very special case that we handle for autotask. If everything is display only, there's no need to make a form.
  const allDisplayOnly = (credentialFields: { [x: string]: CredentialFieldDisplaySettingsDto }) =>
    Object.values(credentialFields).every(x => x.displayOnly);

  const isAllDisplayOnly = allDisplayOnly(credentialFields);

  return (
    <IntegrationCredentialForm
      integrationId={integrationId}
      integrationSetupId={integrationSetupId}
      credentialFields={sanitizedCredentialFields}
      submitCredentialForm={submitCredentialFormAndUpdateDisplay}
      onCancel={
        calculateStage(integrationSetupId) === 'updateIntegration'
          ? async () => {
              setExpandSuccessSection(false);
              resetToSuccessState();
            }
          : undefined
      }
      onValidationError={error => setIntegrationSetupPageSubSectionErrorDescription(error.globalMessage ?? 'Unknown error')}
      isExpanded={expandSuccessSection && !isAllDisplayOnly}
    >
      {credentialStateToDisplay(credentialSubmitState, isAllDisplayOnly, permissionWarnings.length > 0)}
    </IntegrationCredentialForm>
  );
};
export default IntegrationCredentials;
