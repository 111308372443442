import { useContext } from 'react';
import LayoutPresentationContext, {
  LayoutPresentationContextValue,
} from '~/neo-ui/packages/layout/packages/layout-presentation/packages/context/LayoutPresentationContext';

const useLayoutPresentationContext = (): LayoutPresentationContextValue => {
  const presentationContext = useContext(LayoutPresentationContext);

  if (!presentationContext) {
    // In this case we will not throw, as the presentation context is optional
    return { isPresentationMode: false };
  }

  return presentationContext;
};

export default useLayoutPresentationContext;
