import { useState, useCallback, useEffect } from 'react';
import { supportedIntegrationsGet } from '@BeastClient/BeastClientWmManagement.gen';
import { Enum as IntegrationEnum } from '@BeastClient/SyncGod/Shared/Integration/IntegrationFactoryNested.gen';
import { SupportedIntegrationDto } from '@BeastClient/Beast/Integration/Dto/Model.gen';
import useApi from '~/wm/packages/api/hook/useApi';

export type UseSupportedIntegration = (integrationEnum: IntegrationEnum) => {
  integration: SupportedIntegrationDto | undefined;
};

const useSupportedIntegration: UseSupportedIntegration = (integrationEnum: IntegrationEnum) => {
  const { callApi } = useApi();
  const [integration, setIntegration] = useState<SupportedIntegrationDto | undefined>(undefined);

  const reload = useCallback(async () => {
    const response = await callApi(() => supportedIntegrationsGet({ integrationEnum }));

    if (!response) {
      return;
    }

    setIntegration(response.supportedIntegration);
  }, [callApi, integrationEnum]);

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    integration,
  };
};

export default useSupportedIntegration;
