import * as React from 'react';
import Box from '~/neo-ui/packages/box/Box';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import DisposalBulkCheckoutFormData from '~/wm/packages/disposal/types/DisposalBulkCheckoutFormData';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import FormTextInput from '~/neo-ui/packages/form/packages/form-input/packages/form-text-input/FormTextInput';
import useDisposalCart from '~/wm/packages/disposal/hooks/useDisposalCart';
import ToggleTileVertical from '~/neo-ui/packages/toggle/packages/toggle-tile/packages/toggle-tile-vertical/ToggleTileVertical';

export type SiteInformationSectionProps = {
  assetLocationFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData>;
  hasLoadingDockFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData>;
  isMultipleFloorsFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData>;
  isAccessibleByElevatorFieldKey: FieldKeyExpression<DisposalBulkCheckoutFormData>;
};

const SiteInformationSection = ({
  assetLocationFieldKey,
  hasLoadingDockFieldKey,
  isMultipleFloorsFieldKey,
  isAccessibleByElevatorFieldKey,
}: SiteInformationSectionProps) => {
  const { formData, setFormInput } = useFormContext<DisposalBulkCheckoutFormData>();
  const { updateDisposalCart } = useDisposalCart();

  return (
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
    >
      <LayoutSection
        title={'What should we know about this place?'}
        titleSize={4}
        titleWeight={'bold'}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          `}
        >
          <Label bold={true}> Does the site have these features?</Label>
          <div
            css={css`
              display: flex;
              gap: 0.5rem;
            `}
          >
            <ToggleTileVertical
              isSelected={formData.siteInformation.isMultipleFloors}
              title={'Multiple Floors'}
              description={''}
              type={'multi'}
              icon={'MultipleFloors'}
              onClick={() => {
                const isMultipleFloors = !formData.siteInformation.isMultipleFloors;
                setFormInput<boolean>(isMultipleFloorsFieldKey, isMultipleFloors);
                formData.siteInformation.isMultipleFloors = isMultipleFloors;
                updateDisposalCart(formData);
              }}
            />
            {formData.siteInformation.isMultipleFloors && (
              <ToggleTileVertical
                isSelected={formData.siteInformation.isAccessibleByElevator}
                title={'Elevator Access'}
                description={''}
                type={'multi'}
                icon={'Elevator'}
                onClick={() => {
                  const isAccessibleByElevator = !formData.siteInformation.isAccessibleByElevator;
                  setFormInput<boolean>(isAccessibleByElevatorFieldKey, isAccessibleByElevator);
                  formData.siteInformation.isAccessibleByElevator = isAccessibleByElevator;
                  updateDisposalCart(formData);
                }}
              />
            )}
            <ToggleTileVertical
              isSelected={formData.siteInformation.hasLoadingDock}
              title={'Loading Dock'}
              description={''}
              type={'multi'}
              icon={'Dock'}
              onClick={() => {
                const hasLoadingDock = !formData.siteInformation.hasLoadingDock;
                setFormInput<boolean>(hasLoadingDockFieldKey, hasLoadingDock);
                formData.siteInformation.hasLoadingDock = hasLoadingDock;
                updateDisposalCart(formData);
              }}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          `}
        >
          <Label bold={true}>Describe where the assets are and anything else we should know</Label>
          <FormTextInput
            fieldKey={assetLocationFieldKey}
            placeholder={'For example, “32nd floor” or “the gate code is…”'}
            onChange={value => {
              if (value.trim() !== '') {
                formData.siteInformation.assetLocation = value;
                updateDisposalCart(formData);
              }
            }}
          />
        </div>
      </LayoutSection>
    </Box>
  );
};

export default SiteInformationSection;
