import useToastContext from '~/neo-ui/packages/toast/context/hooks/useToastContext';
import * as React from 'react';
import ToastInternal from '~/neo-ui/packages/toast/packages/toast-internal/ToastInternal';
import { css } from '@emotion/react';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import ToastContent from '~/neo-ui/packages/toast/packages/toast-content/ToastContent';
import { ToastProps } from '~/neo-ui/packages/toast/Toast';

const ToastError = ({ toastUniqueId, toastContent, isNonDismissible = false }: ToastProps) => {
  const { removeToast } = useToastContext();
  const onClose = React.useCallback(() => {
    removeToast(toastUniqueId);
  }, [toastUniqueId, removeToast]);
  return (
    <ToastInternal
      onClose={onClose}
      isOpen={true}
      disableFadeOut={isNonDismissible}
    >
      <div
        css={css`
          position: relative;
        `}
      >
        <ButtonModifier
          icon={'Cancel'}
          onClick={onClose}
          css={css`
            position: absolute;
            right: 0;
            top: 0;
          `}
        />
        <ToastContent {...toastContent} />
      </div>
    </ToastInternal>
  );
};

export default ToastError;
