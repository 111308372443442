import * as React from 'react';
import { css } from '@emotion/react';
import { SimpleCountDto } from '@AssetManagementClient/Dashboard/Sdk/SharedComponent/Model/Main/SimpleCountComponentNested.gen';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

const SimpleCountComponent: React.FunctionComponent<SimpleCountDto> = ({ count, unit, color }) => {
  const numericCount = Number(count);
  const formattedCount = isNaN(numericCount) ? count : numericCount.toLocaleString();
  return (
    <div
      css={css`
        justify-content: center;
        align-items: center;
        text-align: center;
      `}
    >
      <div
        css={css`
          color: ${colorToCode(color as Color)};
          font-size: 2.75rem;
          font-weight: 500;
        `}
      >
        {formattedCount}
      </div>
      <div
        css={css`
          color: ${colorToCode('dark-050')};
          font-size: 0.875rem;
          font-weight: 400;
        `}
      >
        {unit}
      </div>
    </div>
  );
};

export default SimpleCountComponent;
