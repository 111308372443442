import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import useDisposalOrderAssetOrganizationAssign from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderAssetOrganizationAssign';
import useDisposalOrderAssetSelectContext from '../../../context/hooks/useDisposalOrderAssetSelectContext';
import { css } from '@emotion/react';

export type DisposalOrderSummarySectionAssetOrganizationAssignButtonProps = {
  disposalOrderId: string;
  organizationId: string;
  onDone: () => void;
  isDisabled?: boolean;
};

const DisposalOrderSummarySectionAssetOrganizationAssignButton: React.FunctionComponent<
  React.PropsWithChildren<DisposalOrderSummarySectionAssetOrganizationAssignButtonProps>
> = ({ disposalOrderId, organizationId, isDisabled, onDone }) => {
  const { assignOrganization, isAssigningOrganization } = useDisposalOrderAssetOrganizationAssign(() => onDone());

  const { disposalOrderAssetIds } = useDisposalOrderAssetSelectContext();

  return (
    <Button
      css={css`
        padding: 0.4375rem 0.625rem;
      `}
      iconLeft={'Done'}
      theme={'primary'}
      onClick={() => {
        assignOrganization({
          disposalOrderId,
          disposalOrderAssetOrganizationAssignPayloads: Array.from(disposalOrderAssetIds).map(disposalOrderAssetId => ({
            disposalOrderAssetId,
            disposalOrderId,
            organizationId,
          })),
        });
      }}
      disabled={isDisabled}
      loading={isAssigningOrganization}
    >
      Assign
    </Button>
  );
};

export default DisposalOrderSummarySectionAssetOrganizationAssignButton;
