import Button from '~/neo-ui/packages/button/Button';
import React from 'react';
import ContractSyncWindow from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/packages/contract-sync-window/ContractSyncWindow';
import useContractStatusSyncContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/context/useContractStatusSyncContext';

export type ContractSyncButtonProps = { organizationId: string };

const ContractSyncButton = ({ organizationId }: ContractSyncButtonProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { contractSyncStatus } = useContractStatusSyncContext();

  return (
    <React.Fragment>
      <Button
        theme={'primary'}
        iconLeft={'DownloadDocument'}
        onClick={() => setIsOpen(true)}
      >
        Sync from PSA
      </Button>
      {isOpen && (
        <ContractSyncWindow
          contractSyncStatus={contractSyncStatus}
          organizationId={organizationId}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </React.Fragment>
  );
};

export default ContractSyncButton;
