import React, { ReactNode } from 'react';
import { FieldValueMapDisplay } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { SwapContentHeader } from '~/neo-ui/packages/mapping/packages/SwapContentRow';
import SwapContentCell from '~/neo-ui/packages/mapping/packages/packages/SwapContentCell';

export type CreateSwapContentSelection = (
  sectionName: string,
  rowId: FieldValueMapDisplay,
  isEnabled?: boolean,
  disabledDisplay?: string,
) => ReactNode;
export type SwapContentProps = {
  leftLabel: string;
  rightLabel: string;
  children: React.ReactNode;
};
const SwapContent: React.FunctionComponent<SwapContentProps> = ({ leftLabel, rightLabel, children }) => {
  return (
    <ul
      css={css`
        width: 100%;

        // Remove list indentation
        padding: 0;
        margin: 0;
        list-style-type: none;
      `}
    >
      <SwapContentHeader
        leftContent={
          <SwapContentCell>
            <Label bold={true}>{leftLabel}</Label>
          </SwapContentCell>
        }
        rightContent={
          <SwapContentCell>
            <Label bold={true}>{rightLabel}</Label>
          </SwapContentCell>
        }
      />
      {children}
    </ul>
  );
};
export default SwapContent;
