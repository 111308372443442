import * as React from 'react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import Box from '~/neo-ui/packages/box/Box';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color from '~/neo-ui/packages/color/Color.gen';
import Spinner from '~/neo-ui/spinner/Spinner';
import { DisposalOrderSummaryDtoNested } from '@WarrantyClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Dto/Model.gen';

export type DisposalOrderSummaryWorkstationAssuranceSectionProps = {
  disposalOrderSummary: DisposalOrderSummaryDtoNested.WorkstationAssurance;
};

const DisposalOrderSummaryWorkstationAssuranceSection = ({
  disposalOrderSummary,
}: DisposalOrderSummaryWorkstationAssuranceSectionProps) => {
  if (typeof disposalOrderSummary === 'undefined') {
    return <Spinner />;
  }

  const assetCount = disposalOrderSummary.assetTypeCounts.reduce((final, current) => final + parseInt(String(current.count), 10), 0);

  return (
    <Box
      padding={'padding200'}
      boxShadow={'shadow0'}
      borderRadius={'radius400'}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.375rem;
          `}
        >
          <Header
            size={4}
            weight={'bold'}
          >
            Order {disposalOrderSummary.disposalOrderId}
          </Header>
          {typeof disposalOrderSummary.completedAt === 'undefined' && (
            <Label
              size={'sm'}
              muted={true}
            >
              Once
              {assetCount === 1 ? ' this asset has ' : ' these assets have '}
              been securely disposed of, you’ll be able to download Certificates of Disposal from the order list.
            </Label>
          )}
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: row;
            gap: 0.8125rem;
            align-items: center;
          `}
        >
          <Icon
            icon={disposalOrderSummary.status.displayIcon as IconType}
            color={disposalOrderSummary.status.displayColor as Color}
            sizePx={22}
          />
          <div>
            <Label
              size={'md'}
              bold={true}
            >
              {disposalOrderSummary.status.displayLabel}
            </Label>
            <Label
              size={'md'}
              muted={true}
            >
              <React.Fragment>
                Order placed{' '}
                {formatDate(new Date(`${disposalOrderSummary.createdAt}`), {
                  format: 'MMM dd, yyyy',
                  timezone: TimezoneFormat.Local,
                })}
              </React.Fragment>
            </Label>
          </div>
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: row;
            gap: 0.8125rem;
            align-items: center;
          `}
        >
          <Icon
            icon={'Workstation'}
            color={'dark-700'}
            sizePx={22}
          />
          <div>
            <Label
              size={'md'}
              bold={true}
            >
              Assets to be disposed of
            </Label>
            <Label
              size={'md'}
              muted={true}
            >
              {assetCount} workstation
              {assetCount !== 1 && 's'}
            </Label>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default DisposalOrderSummaryWorkstationAssuranceSection;
