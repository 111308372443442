import React from 'react';
import { css } from '@emotion/react';
import { Styleable } from '~/neo-ui/model/capacity';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import useAccount from '~/wm/hooks/useAccount';
import { formatCurrencyByCurrencyInfo } from '~/extensions/packages/currency/formatCurrency';
import Divider from '~/neo-ui/packages/divider/Divider';

export type FeesViewLineItem = {
  label: string;
  costSubunits: number;
};

export type FeesViewSectionProps = {
  lineItems: FeesViewLineItem[];
} & Styleable;

const FeesViewSection = ({ lineItems, className }: FeesViewSectionProps) => {
  const { currencyCode, subunitRatio } = useAccount();
  const currency = {
    code: currencyCode,
    subunitsPerUnit: subunitRatio,
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
      className={className}
    >
      {lineItems.map((lineItem, lineItemIndex) => (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
          `}
          key={lineItemIndex}
        >
          <Label
            muted={true}
            size={'sm'}
          >
            {lineItem.label}
          </Label>
          <Divider
            css={css`
              flex: 2;
            `}
            lineType={'dotted'}
          />
          <Label
            css={css`
              margin-left: auto;
            `}
            size={'sm'}
          >
            {formatCurrencyByCurrencyInfo(lineItem.costSubunits * subunitRatio, currency)}
          </Label>
        </div>
      ))}
    </div>
  );
};

export default FeesViewSection;
