import useApi from '~/wm/packages/api/hook/useApi';
import { billingDataSyncTableResync } from '@SubscriptionClient/SubscriptionClientWmManagement.gen';

const useDataSyncTableResync = () => {
  const { callApi } = useApi();
  const resyncDataSyncTable = (tableNames: string[]) => {
    const response = callApi(() => billingDataSyncTableResync({ tableNames }));
    return Promise.resolve(response);
  };
  return { resyncDataSyncTable };
};

export default useDataSyncTableResync;
