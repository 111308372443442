import React from 'react';
import { FormSelectOption } from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import Icon from '~/neo-ui/packages/icon/Icon';
import { css } from '@emotion/react';
import formControlStyles from '~/neo-ui/packages/form/packages/form-input/packages/form-control/formControlStyles';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export const FormSelectDisplay: React.FunctionComponent<{
  disabled?: boolean;
  placeholder?: string;
  options: FormSelectOption[];
  propsForTheSelectHtmlComponent: React.ComponentProps<'select'>;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}> = ({ disabled = false, placeholder = 'Select...', options, value, onChange, propsForTheSelectHtmlComponent }) => (
  <div>
    <Icon
      icon={'ArrowDown'}
      sizePx={15}
      css={css`
        float: right;
        margin-top: -16px;
        margin-right: 10px;
        pointer-events: none;
        background-color: transparent;
        padding-right: 5px;
        top: 23px;
        position: relative;
      `}
    />
    <select
      disabled={disabled}
      {...propsForTheSelectHtmlComponent}
      value={value}
      className={'form-control'}
      css={[
        formControlStyles.app,
        css`
          width: 100%;
          max-height: 1.875rem;
          background-image: none;
          background-color: ${colorToCode('light-000')};
          padding-top: 0.25rem;

          text-overflow: ellipsis;
          padding-right: 1.625rem;

          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          -ms-expand {
            display: none;
          }
        `,
      ]}
      onChange={onChange}
    >
      <option
        value=""
        defaultValue=""
        disabled={true}
        hidden={true}
      >
        {placeholder}
      </option>
      {options.map(o => (
        <option
          key={o.value}
          value={o.value}
        >
          {o.label || o.value}
        </option>
      ))}
    </select>
  </div>
);
