import { ComponentPropsWithRef, forwardRef } from 'react';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import Info, { InfoTitleSize } from '~/legacy-ui/packages/info/Info';
import Color from '~/neo-ui/packages/color/Color.gen';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';

interface Props {
  saleAmount: number;
  originalAmount: number;
  originalAmountFormatter?: (amount: number) => string;
  saleAmountFormatter?: (amount: number) => string;
  color?: Color;
  discountColor?: Color;
  size?: InfoTitleSize;
}

export type PricingAmountProps = Omit<ComponentPropsWithRef<'span'>, keyof Props> & Props;

const PricingAmount = forwardRef<HTMLSpanElement, PricingAmountProps>((props, ref) => {
  const {
    saleAmount,
    originalAmount,
    originalAmountFormatter,
    saleAmountFormatter,
    color = 'secondary-400',
    discountColor = 'positive-400',
    size = 'md',
    ...rest
  } = props;
  const isDiscounted = saleAmount < (originalAmount ?? saleAmount);

  return (
    <span
      ref={ref}
      // eslint-disable-next-line @typescript-eslint/naming-convention
      css={{ display: 'flex', gap: '0.5rem', '&& *': { margin: 'unset' } }}
      {...rest}
    >
      {isDiscounted && (
        <Tooltip content={`You save ${formatCurrency(originalAmount - saleAmount)}`}>
          <Info
            title={saleAmountFormatter?.(saleAmount) ?? formatCurrency(saleAmount)}
            titleSize={size}
            titleColor={discountColor}
          />
        </Tooltip>
      )}

      <Info
        title={originalAmountFormatter?.(originalAmount) ?? formatCurrency(originalAmount)}
        titleSize={size}
        titleColor={color}
        titleStrikethrough={isDiscounted}
      />
    </span>
  );
});

export type PricingAmountUnstrikedProps = Omit<
  PricingAmountProps & {
    amount: number;
    formatter?: (amount: number) => string;
  },
  'saleAmount' | 'originalAmount' | 'originalAmountFormatter' | 'saleAmountFormatter'
>;

const Unstriked = forwardRef<HTMLSpanElement, PricingAmountUnstrikedProps>((props, ref) => {
  const { amount, formatter, ...rest } = props;

  return (
    <PricingAmount
      ref={ref}
      originalAmount={amount}
      saleAmount={amount}
      originalAmountFormatter={formatter}
      {...rest}
    />
  );
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
PricingAmount.Unstriked = Unstriked;

export default PricingAmount as typeof PricingAmount & {
  Unstriked: typeof Unstriked;
};
