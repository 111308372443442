import { createContext } from 'react';

import { AssessmentEvaluationQuestionOverviewDto } from '@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Dto/Model.gen';

export type InitiativeAssessmentQuestionListContextDate = {
  assessmentQuestions: AssessmentEvaluationQuestionOverviewDto[];
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const InitiativeAssessmentQuestionListContext = createContext<InitiativeAssessmentQuestionListContextDate | undefined>(undefined);

export default InitiativeAssessmentQuestionListContext;
