import { Overlay, useDialog, useModalOverlay } from 'react-aria';
import { ClassNames } from '@emotion/react';
import { OverlayTriggerState } from 'react-stately';
import { isIframe } from '~/extensions/packages/iframe/isIframe';
import { FunctionComponent, PropsWithChildren, useEffect, useRef } from 'react';
import useLayoutPresentationContext from '~/neo-ui/packages/layout/packages/layout-presentation/packages/context/hooks/useLayoutPresentationContext';

export type ModalOverlayProps = {
  /**
   * Should this modal be dismissable
   * - Pressing "esc" key
   * - Clicking the modal's backdrop
   */
  isDismissable: boolean;
  /**
   * The max width of the modal
   */
  maxWidthRem: number;
  /**
   * Additional styles applied to the modal content's wrapper
   */
  wrapClassName?: string;
  /**
   * Addition styles applied to the modal's content
   */
  contentClassName?: string;
  overlayTriggerState: OverlayTriggerState;
};

const ModalOverlay: FunctionComponent<PropsWithChildren<ModalOverlayProps>> = ({
  overlayTriggerState,
  isDismissable,
  maxWidthRem,
  wrapClassName,
  contentClassName,
  children,
}) => {
  const { isPresentationMode } = useLayoutPresentationContext();
  const isInsideIframe = isIframe();
  const underlayRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const { modalProps, underlayProps } = useModalOverlay({ isDismissable }, overlayTriggerState, modalRef);

  const { dialogProps } = useDialog({ role: 'dialog' }, modalRef);

  useEffect(() => {
    if (modalRef.current) {
      /**
       * Focus must be on the modal or within it to dismiss the modal with the "esc" key.
       *
       * This will ensure that when the modal renders it will have focus.
       *
       * react-aria is supposed to move the focus to the modal when it opens, but it doesn't.
       * Either it doesn't work or something is hijacking the focus.
       */
      modalRef.current.focus();
    }
  }, [overlayTriggerState.isOpen]);

  return overlayTriggerState.isOpen ? (
    <ClassNames>
      {({ css }) => (
        <Overlay>
          <div
            onClick={event => {
              /**
               * Prevent the event from bubbling up to the parent component.
               *
               * Causing the parent component's onClick handler to fire.
               *
               * e.g. Without this RoadmapItemInitiativeScheduledOverview will keep the modal when we
               * click the ButtonModifier component to close the modal.
               */
              event.stopPropagation();
            }}
            ref={underlayRef}
            css={css`
              position: fixed;
              z-index: 1050;
              top: ${isInsideIframe || isPresentationMode ? '0' : `var(--app-bar-height)`};
              left: 0;
              width: 100vw;
              height: ${isInsideIframe || isPresentationMode ? '100vh' : 'calc(100vh - var(--app-bar-height))'};
              outline: 0;
              background-color: rgba(0, 0, 0, 0.5);
              overflow-y: scroll;
            `}
            {...underlayProps}
          >
            <div
              {...dialogProps}
              {...modalProps}
              ref={modalRef}
              css={css`
                max-width: ${maxWidthRem}rem;
                box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
                margin: 0.3125rem auto 0 auto;
                position: relative;
                width: auto;
                pointer-events: none;
                ${wrapClassName}
              `}
            >
              <div
                css={css`
                  border-radius: 0.9375rem;
                  display: flex;
                  flex-direction: column;
                  position: relative;
                  width: 100%;
                  pointer-events: auto;
                  background-color: #fff;
                  background-clip: padding-box;
                  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
                  outline: 0;
                  ${contentClassName}
                `}
              >
                {children}
              </div>
            </div>
          </div>
        </Overlay>
      )}
    </ClassNames>
  ) : null;
};
export default ModalOverlay;
