import { Enum as PaymentMethodEnum } from '@SubscriptionClient/BeastClient/Beast/Accounting/Model/Payment/Method/PaymentMethodNested.gen';
import { Request as SubscriptionUpgradeRequest } from '@SubscriptionClient/Subscription/Packages/Upgrade/Controller/SubscriptionUpgradeControllerNested.gen';
import { createValidationErrorMap } from '~/neo-ui/packages/form/packages/form-action/packages/form-validation/createValidationErrorMap';
import {
  BillingInfoStepFieldKeys,
  BillingStepSectionFieldKeys,
} from '~/wm/packages/subscription/packages/manage/wizard/model/BillingInfoStepFieldKeys';
import getBillingInfoStep from '~/wm/packages/subscription/packages/manage/wizard/step/getBillingInfoStep';
import { UpgradeFormData } from '~/wm/packages/subscription/packages/upgrade/wizard/SubscriptionUpgradeWizard';
import { PaymentVersion } from '~/neo-ui/packages/form/packages/form-input/packages/form-credit-card-input/FormCreditCardInput';

const billingAddressFieldMap: BillingStepSectionFieldKeys<UpgradeFormData> = {
  addressLineOneFieldKey: formData => formData.billingAddress.address.line1,
  addressLineTwoFieldKey: formData => formData.billingAddress.address.line2,
  cityFieldKey: formData => formData.billingAddress.address.city,
  companyNameFieldKey: formData => formData.billingAddress.name,
  countryIso3FieldKey: formData => formData.billingAddress.address.countryFallback,
  phoneNumberFieldKey: formData => formData.billingAddress.phone,
  postalCodeFieldKey: formData => formData.billingAddress.address.zip,
  stateCodeFieldKey: formData => formData.billingAddress.address.stateFallback,
};

const billingInfoFieldMap: BillingInfoStepFieldKeys<UpgradeFormData> = {
  billingStepSectionFieldKeys: billingAddressFieldMap,
  creditCardStepSectionFieldKey: formData => formData.customerToken,
};

const getUpgradeBillingInfoStep = (paymentMethod: PaymentMethodEnum, paymentVersion: PaymentVersion) =>
  getBillingInfoStep<UpgradeFormData>(
    {
      label: 'Enter your billing info',
      description: 'You’ll finalize your new subscription in the next step.',
    },
    paymentMethod,
    paymentVersion,
    billingInfoFieldMap,
    createValidationErrorMap<SubscriptionUpgradeRequest, UpgradeFormData>([
      // Would be quite nice if the validation error map had some kind of support for
      // nested field keys so we don't have to do this kind of thing:
      [request => request.payload.billingPayload.billingAddress.address.line1, formData => formData.billingAddress.address.line1],
      [request => request.payload.billingPayload.billingAddress.address.line2, formData => formData.billingAddress.address.line2],
      [request => request.payload.billingPayload.billingAddress.address.city, formData => formData.billingAddress.address.city],
      [request => request.payload.billingPayload.billingAddress.name, formData => formData.billingAddress.name],
      [
        request => request.payload.billingPayload.billingAddress.address.countryFallback,
        formData => formData.billingAddress.address.countryFallback,
      ],
      [
        request => request.payload.billingPayload.billingAddress.address.countryCode,
        formData => formData.billingAddress.address.countryFallback,
      ],
      [request => request.payload.billingPayload.billingAddress.phone, formData => formData.billingAddress.phone],
      [request => request.payload.billingPayload.billingAddress.address.zip, formData => formData.billingAddress.address.zip],
      [
        request => request.payload.billingPayload.billingAddress.address.stateFallback,
        formData => formData.billingAddress.address.stateFallback,
      ],
      [
        request => request.payload.billingPayload.billingAddress.address.stateCode,
        formData => formData.billingAddress.address.stateFallback,
      ],
      [request => request.payload.billingPayload.customerPayload.defaultPaymentMethod.token, formData => formData.customerToken],
    ]),
  );

export default getUpgradeBillingInfoStep;
