import Window from '~/neo-ui/packages/window/Window';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import ButtonMarketingUpgrade from '~/neo-ui/packages/button/packages/button-marketing/packages/button-marketing-upgrade/ButtonMarketingUpgrade';
import { css } from '@emotion/react';
import BadgeMarketingPro from '~/neo-ui/packages/badge/packages/badge-marketing/packages/badge-marketing-pro/BadgeMarketingPro';
import Icon from '~/neo-ui/packages/icon/Icon';
import routes from '~/router/types/routes';

export type FeatureDiscoveryWindowProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

/**
 * Content is currently hardcoded for insights until we get content and will consider how to make this component more generic
 */
const FeatureDiscoveryWindow = ({ isOpen, onDismiss }: FeatureDiscoveryWindowProps) => {
  return (
    <Window
      isOpen={isOpen}
      onDismiss={onDismiss}
      title={''}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          flex-direction: column;
        `}
      >
        <span
          css={css`
            display: flex;
            align-items: center;
            gap: 0.3125rem;
            cursor: pointer;
          `}
          onClick={() => {
            window.open(routes.ACCOUNT_BILLING.route, '_blank');
          }}
        >
          <Header
            css={css`
              text-wrap: nowrap;
            `}
            size={4}
            weight={'bold'}
          >
            You just discovered a Lifecycle Manager
          </Header>
          <BadgeMarketingPro />
          <Icon icon={'GoExternal'} />
          <Header
            size={4}
            weight={'bold'}
          >
            Feature
          </Header>
        </span>
        <Label
          css={css`
            margin-top: 1rem;
          `}
        >
          Upgrade to unlock all insights and create custom insights.
        </Label>

        <ButtonMarketingUpgrade
          css={css`
            margin-top: 2rem;
          `}
        />
      </div>
    </Window>
  );
};

export default FeatureDiscoveryWindow;
