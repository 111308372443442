import PanelTabGroupContext from '~/neo-ui/packages/panel/packages/PanelTabGroup/Context/PanelTabGroupContext';
import { PropsWithChildren, useCallback, useState } from 'react';

export type PanelTabGroupProviderProps<T> = {
  initialTabId: T;
  initialFilterValueByTab: Map<T, string | undefined>;
  initialPageByTab: Map<T, number | undefined>;
} & PropsWithChildren;

const PanelTabGroupProvider = <T,>({
  initialTabId,
  initialFilterValueByTab,
  initialPageByTab,
  children,
}: PanelTabGroupProviderProps<T>) => {
  const [currentTabId, setTabId] = useState<T>(initialTabId);
  const [currentPageByTab, setCurrentPageByTab] = useState(initialPageByTab);
  const [currentFilterValueByTab, setCurrentFilterValueByTab] = useState(initialFilterValueByTab);

  const setCurrentTabId = useCallback((id: T) => {
    setTabId(id);
  }, []);

  const setCurrentTabPage = useCallback(
    (currentPage: number) => {
      currentPageByTab.set(currentTabId, currentPage);
      // create a new map reference even though the values are the same otherwise state change will not trigger a re-render
      setCurrentPageByTab(new Map(...[currentPageByTab]));
    },
    [currentPageByTab, currentTabId],
  );

  const setCurrentTabFilterValue = useCallback(
    (filterValue: string) => {
      currentFilterValueByTab.set(currentTabId, filterValue);
      // create a new map reference even though the values are the same otherwise state change will not trigger a re-render
      setCurrentFilterValueByTab(new Map(...[currentFilterValueByTab]));
    },
    [currentFilterValueByTab, currentTabId],
  );

  return (
    <PanelTabGroupContext.Provider
      value={{
        currentPageByTab,
        currentFilterValueByTab,
        setCurrentTabPage,
        setCurrentTabFilterValue,
        setCurrentTabId,
        currentTabId,
      }}
    >
      {children}
    </PanelTabGroupContext.Provider>
  );
};

export default PanelTabGroupProvider;
