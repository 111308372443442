import * as React from 'react';
import useScorecardContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardContext';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import SingleSelectPresentation from '~/neo-ui/packages/select/packages/single-select/packages/single-select-presentation/SingleSelectPresentation';

const ScorecardListSingleSelectPresentation = () => {
  const { scorecard, scorecardList, openScorecard } = useScorecardContext();

  const scorecardOptions: SelectOption[] = scorecardList.map(scorecardOption => ({
    label: `${formatDate(new Date(scorecardOption.schedule.year, scorecardOption.schedule.month - 1), {
      format: 'MMMM yyyy',
      timezone: TimezoneFormat.Utc,
    })}`,
    value: scorecardOption.scorecardId,
    labelColor: 'secondary-400',
    labelHighlightedColor: 'secondary-400',
    selectedBackgroundColor: 'secondary-050',
    hoverBackgroundColor: 'secondary-050',
    selectedIconColor: 'secondary-400',
  }));

  const currentScorecard = scorecardOptions.find(scorecardOption => scorecardOption.value === scorecard.scorecardId);

  return (
    <SingleSelectPresentation
      options={scorecardOptions}
      selectedOption={currentScorecard}
      onOptionSelected={option => {
        openScorecard(option.value);
      }}
      theme={'secondary'}
      icon={'ScorecardHistory'}
      label={'Browse Scorecards…'}
    />
  );
};

export default ScorecardListSingleSelectPresentation;
