import React, { useCallback } from 'react';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import { css } from '@emotion/react';
import useWarrantyCartFiltersContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartFiltersContext';
import warrantyCartFilterBuilder from '~/wm/packages/warranty/packages/warranty-cart/functions/warrantyCartFilterBuilder';

// Duplicated in backend 6e386943-3f9d-41af-8f77-b67911562b08
const maxTotalAssetsCountForEstimate = 500;

const WarrantyCartSelectedCount: React.FunctionComponent = () => {
  const { getSelectedAssetsCount, selectAll, deselectAll, totalAssetsCount } = useWarrantyCartContext();
  const { filter, searchQuery } = useWarrantyCartFiltersContext();

  const onSelectAll = useCallback(() => {
    const filterQuery = typeof filter !== 'undefined' ? warrantyCartFilterBuilder(filter, []) : undefined;
    selectAll(searchQuery, filterQuery);
  }, [filter, searchQuery, selectAll]);

  return (
    <>
      <div
        css={css`
          display: flex;
          gap: 1rem;
        `}
      >
        <h4
          css={css`
            margin-top: 0.3rem;
          `}
        >
          {getSelectedAssetsCount()} selected
        </h4>
        <ButtonLink
          theme={'primary'}
          size={'sm'}
          onClick={onSelectAll}
          disabled={totalAssetsCount > maxTotalAssetsCountForEstimate}
        >
          Select all
        </ButtonLink>
        <ButtonLink
          theme={'primary'}
          size={'sm'}
          onClick={deselectAll}
        >
          Deselect all
        </ButtonLink>
      </div>
    </>
  );
};

export default WarrantyCartSelectedCount;
