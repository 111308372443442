import Window from '~/neo-ui/packages/window/Window';
import RoadmapPdfDownloadForm, {
  RoadmapPdfDownloadFormData,
} from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap-share-button/packages/roadmap-pdf-download-form/RoadmapPdfDownloadForm';
import RoadmapPdfDownloadButton from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap-share-button/packages/roadmap-pdf-download-form/packages/roadmap-pdf-download-button/RoadmapPdfDownloadButton';
import { css } from '@emotion/react';

import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import FormCheckboxGroupInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/packages/form-checkbox-group-Input/FormCheckboxGroupInput';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';

import useInitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesContext';
import FormCheckboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckboxInput';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import FormSelectInput from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';

import * as React from 'react';

import RoadmapPdfDownloadWindowTimelineSection from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap-share-button/packages/roadmap-pdf-download-modal/roadmap-pdf-download-modal-timeline-section/RoadmapPdfDownloadWindowTimelineSection';
import Button from '~/neo-ui/packages/button/Button';
import useRoadmapFeatureFlagsContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapFeatureFlagsContext';

const initiativeStatusFieldKey: FieldKeyExpression<RoadmapPdfDownloadFormData> = values => values.includedStatuses;
const initiativePriorityFieldKey: FieldKeyExpression<RoadmapPdfDownloadFormData> = values => values.includedPriority;
const includeBudgetFieldKey: FieldKeyExpression<RoadmapPdfDownloadFormData> = values => values.shouldIncludeBudgets;
const includeAssetsFieldKey: FieldKeyExpression<RoadmapPdfDownloadFormData> = values => values.shouldIncludeAssets;
const sortColumnFieldKey: FieldKeyExpression<RoadmapPdfDownloadFormData> = values => values.sortColumn;
const includeCoverPageFieldKey: FieldKeyExpression<RoadmapPdfDownloadFormData> = values => values.shouldIncludeCoverPage;
const includeOverviewFieldKey: FieldKeyExpression<RoadmapPdfDownloadFormData> = values => values.shouldIncludeOverview;

export type RoadmapPdfDownloadWindowProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

const RoadmapPdfDownloadWindow = ({ isOpen, onDismiss }: RoadmapPdfDownloadWindowProps) => {
  const { isFeatureFlagEnabled } = useRoadmapFeatureFlagsContext();
  const { availabilities } = useInitiativeAvailabilitiesContext();

  return isOpen ? (
    <RoadmapPdfDownloadForm onDownload={onDismiss}>
      <Window
        isOpen={isOpen}
        onDismiss={onDismiss}
        title={'Download Roadmap PDF'}
        maxWidthRem={63.75}
        footerProps={{
          rightControls: [
            { expanded: <RoadmapPdfDownloadButton /> },
            {
              expanded: <Button onClick={onDismiss}>Cancel</Button>,
            },
          ],
        }}
      >
        <Header
          size={4}
          weight={'bold'}
        >
          Customize PDF Content
        </Header>
        <Label
          muted={true}
          size={'sm'}
          css={css`
            margin-top: 0.5rem;
          `}
        >
          Customize your Roadmap PDF by selecting the options that you want to include.
        </Label>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            margin-top: 1.5rem;
          `}
        >
          {isFeatureFlagEnabled('lm-roadmaps-pdf-overview') && (
            <Box
              padding={'padding200'}
              background={'foregroundAccent'}
              theme={'none'}
              borderRadius={'radius300'}
            >
              <Header
                size={4}
                weight={'bold'}
              >
                Cover Page & Roadmap Overview
              </Header>
              <Label
                muted={true}
                size={'sm'}
                css={css`
                  margin-top: 0.5rem;
                `}
              >
                The cover page will include your client’s DMI score, Roadmap overview is a snapshot of your overall plan.
              </Label>
              <hr
                css={css`
                  border-color: 0.0625rem solid ${colorToCode('dark-900-12')};
                `}
              />
              <div
                css={css`
                  display: flex;
                  gap: 2rem;
                `}
              >
                <FormCheckboxInput
                  fieldKey={includeCoverPageFieldKey}
                  description={'Cover Page'}
                />
                <FormCheckboxInput
                  fieldKey={includeOverviewFieldKey}
                  description={'Roadmap Overview'}
                />
              </div>
            </Box>
          )}
          <Box
            padding={'padding200'}
            background={'foregroundAccent'}
            theme={'none'}
            borderRadius={'radius300'}
          >
            <Header
              size={4}
              weight={'bold'}
            >
              Initiative Status
            </Header>
            <hr
              css={css`
                border-color: 0.0625rem solid ${colorToCode('dark-900-12')};
              `}
            />
            <FormCheckboxGroupInput
              fieldKey={initiativeStatusFieldKey}
              options={availabilities.statusAvailabilities.map(status => ({
                label: status.displayLabel,
                value: status.id,
              }))}
            />
          </Box>
          <RoadmapPdfDownloadWindowTimelineSection />
          <Box
            padding={'padding200'}
            background={'foregroundAccent'}
            theme={'none'}
            borderRadius={'radius300'}
          >
            <Header
              size={4}
              weight={'bold'}
            >
              Priority
            </Header>
            <hr
              css={css`
                border-color: 0.0625rem solid ${colorToCode('dark-900-12')};
              `}
            />
            <FormCheckboxGroupInput
              fieldKey={initiativePriorityFieldKey}
              options={availabilities.priorityAvailabilities.map(piority => ({
                label: piority.label,
                value: piority.key,
                icon: piority.icon as IconType,
              }))}
            />
          </Box>
          <Box
            padding={'padding200'}
            background={'foregroundAccent'}
            theme={'none'}
            borderRadius={'radius300'}
          >
            <Header
              size={4}
              weight={'bold'}
            >
              Advanced Options
            </Header>
            <hr
              css={css`
                border-color: 0.0625rem solid ${colorToCode('dark-900-12')};
              `}
            />
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 1rem;
              `}
            >
              <div
                css={css`
                  display: flex;
                  gap: 2rem;
                `}
              >
                <FormCheckboxInput
                  fieldKey={includeBudgetFieldKey}
                  description={'Include Budget/Cost'}
                  iconLeft={'Budget'}
                />
                <FormCheckboxInput
                  fieldKey={includeAssetsFieldKey}
                  description={'Include Asset List'}
                  iconLeft={'HardwareAsset'}
                />
              </div>
              <Label
                muted={true}
                size={'sm'}
              >
                Note: Including an asset list will extend the length of your PDF.
              </Label>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                margin-top: 1.5rem;
              `}
            >
              <Label>Sort By</Label>
              <FormSelectInput
                css={css`
                  width: 12.5rem;
                `}
                fieldKey={sortColumnFieldKey}
                options={availabilities.roadmapPdfSortingColumnsAvailabilities.map(sortColumn => ({
                  label: sortColumn.displayLabel,
                  value: sortColumn.id,
                }))}
              />
            </div>
          </Box>
        </div>
      </Window>
    </RoadmapPdfDownloadForm>
  ) : null;
};

export default RoadmapPdfDownloadWindow;
