import * as React from 'react';
import { components, ValueContainerProps } from 'react-select';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import Icon from '~/neo-ui/packages/icon/Icon';
import Color from '~/neo-ui/packages/color/Color.gen';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { css } from '@emotion/react';

export type SingleSelectConsoleValueContainerProps<T extends string = string> = {
  props: ValueContainerProps<SelectOption<T>, false>;
  icon?: IconType;
  sizePx?: number;
  color?: Color;
};

const SingleSelectConsoleValueContainer = <T extends string>({
  props,
  icon,
  sizePx,
  color = 'dark-900',
}: SingleSelectConsoleValueContainerProps<T>) => {
  const { ValueContainer } = components;

  return (
    <ValueContainer {...props}>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        {icon && (
          <Icon
            icon={icon}
            sizePx={sizePx}
            color={color}
            css={css`
              margin-inline-end: 0.625rem;
            `}
          />
        )}
        {props.children}
      </div>
    </ValueContainer>
  );
};

export default SingleSelectConsoleValueContainer;
