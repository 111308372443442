import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';
import { TicketCreateFieldValueDto } from '@AssetManagementClient/BeastClient/Beast/Core/Ticket/Dto/Model.gen';
import { TicketCreateFormDataType } from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/types/TicketCreateFormDataType';
import TicketErrorModalOrSuccess from '~/wm/packages/integration/packages/ticket/packages/ticket-error-modal-or-success/TicketErrorModalOrSuccess';
import TicketCreateForm from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/TicketCreateForm';
import TicketCreateWindow from '~/wm/packages/integration/packages/ticket/packages/ticket-create-window/TicketCreateWindow';

export type TicketCreateButtonProps = {
  isOpen: boolean;
  hasError: boolean;
  isCreatingTicket: boolean;
  buttonComponent: React.ReactNode;
  fieldOptions: IntegrationFieldOptionDto[];
  isOrganizationRegisteredForTickets: boolean;
  hasTicketIntegration: boolean;
  onCreateTicket: (fieldValues: TicketCreateFieldValueDto[]) => void;
  onDismiss: () => void;
  defaultFormData: TicketCreateFormDataType;
};

const TicketCreateButton = ({
  isOpen,
  hasError,
  isCreatingTicket,
  buttonComponent,
  fieldOptions,
  isOrganizationRegisteredForTickets,
  hasTicketIntegration,
  onCreateTicket,
  onDismiss,
  defaultFormData,
}: TicketCreateButtonProps) => {
  return (
    <div>
      {buttonComponent}
      {isOpen && (
        <TicketErrorModalOrSuccess
          hasTicketIntegration={hasTicketIntegration}
          isOrganizationRegisteredForTickets={isOrganizationRegisteredForTickets}
          ticketFieldOptions={fieldOptions}
          onDismiss={onDismiss}
          successComponent={
            <TicketCreateForm
              onCreateTicket={fieldValues => onCreateTicket(fieldValues)}
              defaultFormData={defaultFormData}
            >
              <TicketCreateWindow
                fieldOptions={fieldOptions}
                hasError={hasError}
                isOpen={isOpen}
                isCreatingTicket={isCreatingTicket}
                onDismiss={onDismiss}
              />
            </TicketCreateForm>
          }
        />
      )}
    </div>
  );
};

export default TicketCreateButton;
