import * as React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import { EngagementActionCreatePayload } from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionCreate';
import { parseISO } from 'date-fns';
import { EngagementActionCreateFormDataType } from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/types/EngagementActionCreateFormDataType';
import engagementActionCreateFormValidationSchema from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/validation/engagementActionCreateFormValidationSchema';

export type EngagementActionCreateFormProps = {
  organizationId: string;
  initiativeId?: string;
  createEngagementAction: (createPayload: EngagementActionCreatePayload) => void;
};

const EngagementActionCreateForm = ({
  organizationId,
  initiativeId,
  createEngagementAction,
  children,
}: React.PropsWithChildren<EngagementActionCreateFormProps>) => {
  const onSubmit = React.useCallback(
    async (data: EngagementActionCreateFormDataType) => {
      const dueAt = data.dueAt !== '' ? parseISO(data.dueAt).toUTCString() : undefined;

      createEngagementAction({
        organizationId,
        description: data.description,
        dueAt,
        assignedUserIds: data.assignedUserIds,
        ticketFieldValues: Object.entries(data.ticketFieldValues)
          .filter(([, value]) => value !== '')
          .map(([key, value]) => ({ key, value })),
        initiativeLinkPayload:
          typeof initiativeId !== 'undefined' ? { initiativeId, createDefaultPayload: undefined } : data.initiativeLinkPayload,
      });
    },
    [createEngagementAction, initiativeId, organizationId],
  );

  const defaultFormData = React.useMemo<EngagementActionCreateFormDataType>(
    () => ({
      description: '',
      dueAt: '',
      assignedUserIds: [],
      ticketFieldValues: {},
      initiativeLinkPayload: undefined,
    }),
    [],
  );

  return (
    <Form
      submitMethod={'manual'}
      hideSubmissionButton={true}
      hideSubmitStatusIndicator={true}
      defaultFormData={defaultFormData}
      validationSchema={engagementActionCreateFormValidationSchema}
      onSubmit={onSubmit}
      disableOverwriteDefaultFormDataOnSave={true}
    >
      {children}
    </Form>
  );
};

export default EngagementActionCreateForm;
