import { css, keyframes } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import * as React from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import useButtonMarketingDefinition, {
  ButtonMarketingType,
} from '~/neo-ui/packages/button/packages/button-marketing/hooks/useButtonMarketingDefinition';
import { Styleable } from '~/neo-ui/model/capacity';

export type ButtonMarketingProps = {
  type: ButtonMarketingType;
  isAnimating: boolean;
  setIsAnimating: React.Dispatch<React.SetStateAction<boolean>>;
  isDoneAnimating: boolean;
  setIsDoneAnimating: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: () => void;
  labelSuffix?: string;
} & Styleable;

const ButtonMarketing = ({
  className,
  type,
  isAnimating,
  setIsAnimating,
  isDoneAnimating,
  setIsDoneAnimating,
  onClick,
  labelSuffix,
}: ButtonMarketingProps) => {
  const { icon, label, backgroundSizeWidthPercent, startingKeyframe, endingKeyframe } = useButtonMarketingDefinition(type);

  return (
    <button
      className={className}
      css={css`
        position: relative;
        width: max-content;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        border-radius: 0.25rem;

        &:focus {
          // Remove focus outline because it interferes with the active border
          outline: none;
        }

        &:hover {
          border: 0.0625rem solid ${colorToCode('light-000')};
        }

        &:active {
          border: 0.0625rem solid ${colorToCode('dark-900-12')};
        }

        &:active::after {
          // Darken the button while active
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.1);
          border-radius: 0.25rem;
          z-index: 1;
        }

        // Animation Starting Point
        ${startingKeyframe};
        background-size: ${backgroundSizeWidthPercent}% auto;
        border: 0.0625rem solid ${colorToCode('light-000-16')};

        // Animation Keyframes
        ${isAnimating &&
        css`
          animation: ${keyframes`
                  50% {
                      border: 0.0625rem solid ${colorToCode('light-000')};
                  }
                  75% {
                      // RGBA value equivalent to light-000-48 which does not exist in our colors currently
                      border: 0.0625rem solid rgba(255, 255, 255, 0.48);
                  }
                  100% {
                      ${endingKeyframe};
                      border: 0.0625rem solid ${colorToCode('light-000-16')};
                  }
              `};
          transition-timing-function: linear;
          animation-duration: 2.2s;
          animation-fill-mode: forwards;
        `}

        ${isDoneAnimating &&
        // Animation Ending Point
        endingKeyframe}
      `}
      onAnimationEnd={() => {
        setIsAnimating(false);
        setIsDoneAnimating(true);
      }}
      onClick={onClick}
    >
      <Icon
        icon={icon}
        sizePx={16}
        color={'light-000'}
      />
      <Label
        size={'md'}
        color={'light-000'}
      >
        {label} {typeof labelSuffix !== 'undefined' && labelSuffix}
      </Label>
    </button>
  );
};

export default ButtonMarketing;
