import { useContext } from 'react';
import AutoEmailReportListContext from '~/wm/packages/organization/packages/auto-email-report/context/AutoEmailReportListContext';

const useAutoEmailReportListContext = () => {
  const autoEmailReportListContext = useContext(AutoEmailReportListContext);
  if (!autoEmailReportListContext) {
    throw new Error('Tried to use AutoEmailReportListContext outside of an AutoEmailReportListProvider');
  }

  return autoEmailReportListContext;
};

export default useAutoEmailReportListContext;
