import * as React from 'react';
import { useState } from 'react';
import EngagementListPageTicketCreateContext from './EngagementListPageTicketCreateContext';
import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';

export type EngagementListPageTicketCreateProviderProps = {
  children: React.ReactNode;
};

/**
 * Follow this GUID for other places where this pattern is used: 32c335c4-5722-4c59-af22-e48551f59474
 *
 * Hacky solution to deal with the ticket create window closing while polling for the engagement list page
 *
 * - This enables us to keep the EngagementActionListTicketCreateWindowWrapper window open across re-renders
 * - Also allows us to trigger the mutation to the table while EngagementActionListTicketCreateWindowWrapper is a sibling component of the table
 */
const EngagementListPageTicketCreateProvider = ({ children }: EngagementListPageTicketCreateProviderProps) => {
  const [selectedAction, setSelectedAction] = useState<EngagementActionDto>();

  return (
    <EngagementListPageTicketCreateContext.Provider
      value={{
        selectedAction,
        setSelectedAction,
      }}
    >
      {children}
    </EngagementListPageTicketCreateContext.Provider>
  );
};

export default EngagementListPageTicketCreateProvider;
