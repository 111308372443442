import { createContext } from 'react';

export type InitiativeMutationContextData = {
  triggerInitiativeReload: (
    isRequestActive?: () => boolean,
    /**
     * Triggers loading state for warranty initiative related fields
     */
    shouldLoadWarrantyCosts?: boolean,
  ) => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const InitiativeMutationContext = createContext<InitiativeMutationContextData>({
  // If there's no provider, it's a graceful no-op
  triggerInitiativeReload: async () => {},
});

export default InitiativeMutationContext;
