import React from 'react';

export const useWindowDimensions = () => {
  const [innerWindowWidth, setInnerWindowWidth] = React.useState(window.innerWidth);
  const [innerWindowHeight, setInnerWindowHeight] = React.useState(window.innerHeight);

  const updateWidthAndHeight = () => {
    setInnerWindowWidth(window.innerWidth);
    setInnerWindowHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  });

  return {
    innerWindowWidth,
    innerWindowHeight,
  };
};
