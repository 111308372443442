import { useContext } from 'react';
import ActiveUsersContext from '~/wm/packages/account/packages/user/context/ActiveUsersContext';

const useActiveUsersContext = () => {
  const activeUsersContext = useContext(ActiveUsersContext);
  if (!activeUsersContext) {
    throw new Error('Tried to use ActiveUsersContext outside of an ActiveUsersContext');
  }

  return activeUsersContext;
};

export default useActiveUsersContext;
