import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Button from '~/sp-ui/buttons/Button';
import { SuccessIcon } from '~/sp-ui/icons';

export type Inclusion = {
  title: string;
  tooltip: string;
};

export type CardImageProps = {
  imageUrl: string;
  imageAlt: string;
  title: string;
  description: string;
  inclusions: Inclusion[];
  action: () => void;
};
const CardImage = (props: CardImageProps) => {
  const { title, description, inclusions, imageUrl, imageAlt, action } = props;
  return (
    <div
      css={css`
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        border: 0.063rem solid ${colorToCode('light-400')};
        background-color: ${colorToCode('light-000')};
      `}
    >
      <div
        css={css`
          display: inline-block;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-family: 'Roboto', sans-serif;
          padding: 2rem 1.5rem;
          position: relative;
        `}
      >
        <div
          css={css`
            text-align: center;
          `}
        >
          <div
            css={css`
              display: flex;
              width: 20.75rem;
              text-align: center;
              align-items: center;
              flex-direction: column;
            `}
          >
            <span
              css={css`
                font-size: 1.125rem;
                font-weight: 700;
                line-height: normal;
              `}
            >
              {title}
            </span>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                font-weight: 300;
                line-height: normal;
                padding-bottom: 1.5rem;
              `}
            >
              <img
                src={imageUrl}
                css={css`
                  margin: 1.5rem 0;
                `}
                alt={imageAlt}
              />
              <span
                css={css`
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                `}
              >
                {description}
              </span>
            </div>
            <Button
              css={css`
                width: 20.75rem;
                padding-inline: 0;
              `}
              variant={'secondary'}
              onClick={action}
            >
              Get in touch
            </Button>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              text-align: left;
              padding-top: 1.5rem;
            `}
          >
            {inclusions.map(inclusion => (
              <div
                key={inclusion.title}
                css={css`
                  display: flex;
                  flex-direction: row;
                  text-align: left;
                  align-items: center;
                  line-height: 1.5rem;
                  .tooltip-target {
                    display: flex;
                  }
                `}
              >
                <SuccessIcon />
                <span
                  css={css`
                    padding: 0 0.5rem;
                  `}
                >
                  {inclusion.title}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardImage;
