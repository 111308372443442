import { FunctionComponent, Fragment, useRef, useState } from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';

export interface VideoProps {
  source: string;
  thumbnail: string;
  maxWidth?: string;
  maxHeight?: string;
  onStopWatching?: (videoRef: React.MutableRefObject<HTMLVideoElement>, watchTime: number) => void;
}

const Video: FunctionComponent<VideoProps> = ({ source, thumbnail, maxWidth, maxHeight, onStopWatching }) => {
  const [isDisplayingLightbox, setIsDisplayingLightbox] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  // Getting the watch time of a video is tricky, we are going to define the watch time as the latest time in seconds that the user watched in the video
  const [watchTime, setWatchTime] = useState<number>(0);

  const toggleLightbox = () => {
    setIsDisplayingLightbox(!isDisplayingLightbox);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      if (isDisplayingLightbox) {
        videoRef.current.pause();
        if (onStopWatching) {
          onStopWatching(videoRef as React.MutableRefObject<HTMLVideoElement>, watchTime);
        }
      } else {
        videoRef.current.play();
      }
    }
  };

  return (
    <Fragment>
      <div
        css={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '9999',
        }}
        hidden={!isDisplayingLightbox}
      >
        <div
          css={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
            opacity: 0.6,
          }}
          onClick={toggleLightbox}
        />
        <Icon
          css={{
            position: 'fixed',
            top: '1.25rem',
            right: '1.25rem',
            ['&:hover']: {
              cursor: 'pointer',
            },
          }}
          icon={'Cancel'}
          color={'light-000-64'}
          sizePx={32}
          onClick={toggleLightbox}
        />
        <video
          ref={videoRef}
          css={{
            position: 'fixed',
            width: '48.75rem',
            maxWidth: '48.75rem',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          width="100%"
          controls={true}
          controlsList="nodownload"
          preload="metadata"
          className="wm-margin-top-30"
          onTimeUpdate={() => (videoRef.current?.currentTime ?? 0 > watchTime ? setWatchTime(videoRef.current?.currentTime ?? 0) : null)}
          onPause={() => (videoRef.current?.currentTime ?? 0 > watchTime ? setWatchTime(videoRef.current?.currentTime ?? 0) : null)}
        >
          <source
            src={source}
            type="video/mp4"
          />
        </video>
      </div>
      <div
        css={{
          position: 'relative',
          alignSelf: 'center',
          maxWidth: maxWidth ?? '100%',
          maxHeight: maxHeight ?? '100%',
          ['&:hover']: {
            cursor: 'pointer',
          },
        }}
      >
        <img
          css={{
            width: '100%',
            height: '100%',
          }}
          src={thumbnail}
          alt={'Thumbnail'}
          onClick={toggleLightbox}
        />
        <div
          css={{
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            borderRadius: '50%',
            width: '5rem',
            height: '5rem',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Icon
            css={{
              position: `relative`,
              top: `50%`,
              left: `50%`,
              transform: `translate(-50%, -50%)`,
            }}
            icon={'Play'}
            color={'light-000'}
            sizePx={42}
            onClick={toggleLightbox}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Video;
