import { createContext } from 'react';

export type PeopleDashboardMutationContextData = {
  triggerPeopleDashboardReload: (isRequestActive?: () => boolean) => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const PeopleDashboardMutationContext = createContext<PeopleDashboardMutationContextData>({
  // If there's no provider, it's a graceful no-op
  triggerPeopleDashboardReload: async () => {},
});

export default PeopleDashboardMutationContext;
