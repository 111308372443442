import ButtonMarketing from '~/neo-ui/packages/button/packages/button-marketing/ButtonMarketing';
import useFeatureAvailabilitiesContext from '~/wm/packages/feature/context/hooks/useFeatureAvailabilitiesContext';
import { ButtonMarketingType } from '~/neo-ui/packages/button/packages/button-marketing/hooks/useButtonMarketingDefinition';
import * as React from 'react';
import routes from '~/router/types/routes';
import { css } from '@emotion/react';
import { Styleable } from '~/neo-ui/model/capacity';

export type ButtonMarketingUpgradeProps = Styleable;

const ButtonMarketingUpgrade = ({ className }: ButtonMarketingUpgradeProps) => {
  const { hasFeatureAccessAccount } = useFeatureAvailabilitiesContext();
  const [isAnimating, setIsAnimating] = React.useState(false);
  const [isDoneAnimating, setIsDoneAnimating] = React.useState(false);

  const hasProFeatureAccess = hasFeatureAccessAccount('plan-pro');
  const hasEssentialFeatureAccess = hasFeatureAccessAccount('plan-essential');

  // Determine which button type to display
  let buttonMarketingType: ButtonMarketingType;
  if (hasProFeatureAccess) {
    buttonMarketingType = 'pro';
  } else if (hasEssentialFeatureAccess) {
    buttonMarketingType = 'essential';
  } else {
    buttonMarketingType = 'free';
  }

  React.useEffect(() => {
    setIsAnimating(true);
  }, []);

  return (
    <ButtonMarketing
      css={css`
        padding: 0.157rem 0.375rem;
        animation-delay: 1s;
      `}
      className={className}
      type={buttonMarketingType}
      isAnimating={isAnimating}
      setIsAnimating={setIsAnimating}
      isDoneAnimating={isDoneAnimating}
      setIsDoneAnimating={setIsDoneAnimating}
      onClick={() => {
        window.open(routes.ACCOUNT_BILLING.route, '_blank');
      }}
    />
  );
};

export default ButtonMarketingUpgrade;
