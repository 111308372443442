import { RoadmapInitiativeViewMode } from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/RoadmapV2';
import { css } from '@emotion/react';
import RoadmapInitiativeView from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-initiative-view/RoadmapInitiativeView';
import useInitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesContext';
import { useState } from 'react';
import { DropdownOption } from '~/neo-ui/packages/dropdown/Dropdown';
import { MultiSelectOption } from '~/neo-ui/packages/select/packages/multi-select/MultiSelect';
import RoadmapPresentationFilters from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-presentation-filters/RoadmapPresentationFilters';
import useRoadmapInitiativesContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapInitiativesContext';

const RoadmapPresentationV2 = () => {
  const { availabilities } = useInitiativeAvailabilitiesContext();
  const { isDefaultToShowUnscheduled } = useRoadmapInitiativesContext();

  const [selectedViewMode, setSelectedViewMode] = useState<DropdownOption<RoadmapInitiativeViewMode>>({
    value: 'Fees',
    label: 'View fees',
  });
  availabilities.statusAvailabilities.map(availability => ({
    value: availability.id,
    label: availability.displayLabel,
  }));
  const [selectedStatuses, setSelectedStatuses] = useState<MultiSelectOption[]>([]);
  availabilities.priorityAvailabilities.map(availability => ({
    value: availability.key,
    label: availability.label,
  }));
  const [selectedPriorities, setSelectedPriorities] = useState<MultiSelectOption[]>([]);

  const [showUnscheduled, setShowUnscheduled] = useState(isDefaultToShowUnscheduled);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        height: 100%;
      `}
    >
      <RoadmapPresentationFilters
        selectedViewMode={selectedViewMode}
        onChangeSelectedViewMode={setSelectedViewMode}
        selectedStatuses={selectedStatuses}
        onChangeSelectedStatuses={setSelectedStatuses}
        selectedPriorities={selectedPriorities}
        onChangeSelectedPriorities={setSelectedPriorities}
        showUnscheduled={showUnscheduled}
        onChangeShowUnscheduled={setShowUnscheduled}
      />
      <RoadmapInitiativeView
        selectedViewMode={selectedViewMode}
        selectedStatuses={selectedStatuses}
        selectedPriorities={selectedPriorities}
        showUnscheduled={showUnscheduled}
        css={css`
          margin-top: 2.5rem;
        `}
      />
    </div>
  );
};

export default RoadmapPresentationV2;
