import { IntegrationFieldOptionDto } from '@AssetManagementClient/BeastClient/Beast/Integration/Dto/Model.gen';
import { titlefieldKeyId } from '~/wm/packages/integration/packages/ticket/packages/ticket-create-form/TicketCreateForm';
import { InputTypeNested } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Credential.gen';

const formatTicketTitleEngagementAction = (actionDescription: string, fieldOptions: IntegrationFieldOptionDto[]): string => {
  const ticketTitleFieldOption = fieldOptions.find(option => option.key === titlefieldKeyId);
  const ticketTitleFieldOptionTextLength = ticketTitleFieldOption
    ? (ticketTitleFieldOption.inputType as InputTypeNested.Text).validation.maxLength
    : undefined;

  return typeof ticketTitleFieldOptionTextLength !== 'undefined' && actionDescription.length > ticketTitleFieldOptionTextLength
    ? `${actionDescription.slice(0, ticketTitleFieldOptionTextLength - 1)}…`
    : actionDescription;
};

export default formatTicketTitleEngagementAction;
