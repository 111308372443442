import { Enum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/AssetScope/Logic/AssetScopeFactoryNested.gen';
import { PolicyOperatorEnum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Policy/Logic/Operator/PolicyOperatorFactoryNested.gen';
import * as React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import useInsightCreate from '~/wm/packages/strategy/packages/insight/hooks/useInsightCreate';
import { useInsightCreateFormMetadata } from '~/wm/packages/strategy/packages/insight/hooks/useInsightCreateFormMetadata';
import InsightCreateModal from '~/wm/packages/strategy/packages/insight/packages/insight-create-button/insight-create-modal/InsightCreateModal';
import {
  defaultFormData,
  InsightFormData,
} from '~/wm/packages/strategy/packages/insight/packages/insight-create-button/insight-create-modal/InsightFormData';
import insightFormDataValidationSchema from '~/wm/packages/strategy/packages/insight/packages/insight-create-button/insight-create-modal/validation/insightFormDataValidationSchema';
import { Enum as PolicyHardwareEnum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Policy/Model/PolicyFactoryNested.gen';

type InsightCreateFormProps = {
  isOpen: boolean;
  onDismiss: () => void;
  refreshAction: () => void;
};

const InsightCreateForm: React.FunctionComponent<InsightCreateFormProps> = ({ isOpen, onDismiss, refreshAction }) => {
  const { formMetadata } = useInsightCreateFormMetadata();
  const { createInsight } = useInsightCreate();

  const onSubmit = React.useCallback(
    async (data: InsightFormData) => {
      const [scopeId, scopeValue] = data.assetScope.split('-', 2);
      await createInsight(
        data.title,
        data.description,
        { scopeId: scopeId as Enum, scopeValues: [scopeValue] },
        undefined,
        data.policies.map(policy => ({
          policyEnum: policy.policyEnum as PolicyHardwareEnum,
          policyOperatorEnum: policy.policyOperatorEnum as PolicyOperatorEnum,
          value: policy.value.toString(),
        })),
      );
    },
    [createInsight],
  );

  return (
    <Form
      defaultFormData={defaultFormData}
      submitMethod={'manual'}
      hideSubmissionButton={true}
      SubmitStatusIndicator={<></>}
      onSubmit={onSubmit}
      validationSchema={insightFormDataValidationSchema(formMetadata)}
      disableOverwriteDefaultFormDataOnSave={true}
    >
      <InsightCreateModal
        isOpen={isOpen}
        onDismiss={onDismiss}
        metadata={formMetadata}
        onRefreshAction={refreshAction}
      />
    </Form>
  );
};

export default InsightCreateForm;
