import { createContext } from 'react';
import { ContractSyncStatusDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Sync/Dto/Model.gen';

export type ContractStatusSyncContextData = {
  contractSyncStatus: ContractSyncStatusDto | undefined;
  isLoadingContractSyncStatus: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ContractStatusSyncContext = createContext<ContractStatusSyncContextData | undefined>(undefined);

export default ContractStatusSyncContext;
