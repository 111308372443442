import { css } from '@emotion/react';
import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import Theme from '~/neo-ui/packages/color/Theme';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Header from '~/neo-ui/packages/text/packages/header/Header';

export type SettingsSectionsProps = {
  title: string;
  children: React.ReactNode;
  titleButtonIcon?: IconType;
  titleButtonText?: string;
  titleButtonLink?: string;
  titleButtonColor?: Theme;
};

const SettingsSection: React.FunctionComponent<SettingsSectionsProps> = ({
  title,
  titleButtonIcon,
  titleButtonText,
  titleButtonLink,
  titleButtonColor,
  children,
}) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
    `}
  >
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
      `}
    >
      <Header size={2}>{title}</Header>
      {titleButtonText && (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Button
            css={css`
              margin-right: 0.625rem;
            `}
            theme={titleButtonColor}
            iconLeft={titleButtonIcon}
            {...(titleButtonLink && { anchor: { href: titleButtonLink } })}
          >
            {titleButtonText}
          </Button>
        </div>
      )}
    </div>
    {children}
  </div>
);

export default SettingsSection;
