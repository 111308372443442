import Popup from '~/neo-ui/packages/popup/Popup';
import * as React from 'react';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import { css } from '@emotion/react';
import InputTitle from '~/neo-ui/packages/input/packages/input-title/InputTitle';
import Button from '~/neo-ui/packages/button/Button';
import useScorecardCategoryUpdate from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/hooks/useScorecardCategoryUpdate';
import ScorecardCategoryDeleteModal from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/packages/scorecard-category-delete-modal/ScorecardCategoryDeleteModal';

export type ScorecardCategoryManageButtonProps = {
  scorecardId: string;
  scorecardCategoryId: string;
  label: string;
};

const ScorecardCategoryManageButton = ({ scorecardId, scorecardCategoryId, label }: ScorecardCategoryManageButtonProps) => {
  const { updateScorecardCategory, isUpdatingScorecardCategory } = useScorecardCategoryUpdate(scorecardId, scorecardCategoryId, {
    onSuccess: () => setIsOpen(false),
  });

  const [isOpen, setIsOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [newLabel, setNewLabel] = React.useState(label);

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      backgroundColor={'light-000'}
      placement={'bottom-start'}
      title={'Manage Category'}
      titleColor={'secondary-400'}
      showCloseButton={true}
      css={css`
        min-width: 37.5rem;
      `}
      content={
        <InputTitle
          size={'lg'}
          theme={'secondary'}
          value={newLabel}
          onChange={value => setNewLabel(value)}
        />
      }
      footer={
        <React.Fragment>
          <Button
            iconLeft={'Done'}
            theme={'secondary'}
            loading={isUpdatingScorecardCategory}
            onClick={() => updateScorecardCategory({ label: newLabel })}
          >
            Done
          </Button>
          <Button
            iconLeft={'Trash'}
            theme={'danger'}
            onClick={() => {
              setIsDeleteModalOpen(true);
              setIsOpen(false);
            }}
          >
            Delete
          </Button>
        </React.Fragment>
      }
    >
      <ButtonModifier
        icon={'Edit'}
        css={css`
          margin: 0;
        `}
        onClick={() => setIsOpen(prev => !prev)}
      />
      <ScorecardCategoryDeleteModal
        scorecardId={scorecardId}
        scorecardCategoryId={scorecardCategoryId}
        isOpen={isDeleteModalOpen}
        isUncategorizedCategory={label === 'General Items'}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={() => setIsDeleteModalOpen(false)}
      />
    </Popup>
  );
};

export default ScorecardCategoryManageButton;
