import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import Testable from '~/neo-ui/packages/testable/Testable';
import InsightDeleteModal from '~/wm/packages/strategy/packages/insight/packages/insight-delete-button/packages/insight-delete-modal/InsightDeleteModal';

export type InsightDeleteButtonProps = {
  insightId: string;
  insightName: string;
  refreshAction: () => void;
};

const InsightDeleteButton: React.FunctionComponent<InsightDeleteButtonProps> = ({ insightId, insightName, refreshAction }) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <Testable testId={'insight-delete-button'}>
      <Button
        iconLeft={'Trash'}
        theme={'negative'}
        onClick={() => setModalOpen(true)}
      />
      <InsightDeleteModal
        isOpen={isModalOpen}
        insightId={insightId}
        insightName={insightName}
        onDismiss={() => setModalOpen(false)}
        refreshAction={refreshAction}
      />
    </Testable>
  );
};

export default InsightDeleteButton;
