import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { contractListCsv } from '@AssetManagementClient/AssetManagementClientMsp.gen';

type UseDownloadContractListCsv = () => {
  downloadContractListCsv: (organizationId: string) => void;
};

export const useDownloadContractListCsv: UseDownloadContractListCsv = () => {
  const { callApi } = useApi();

  const downloadContractListCsv = React.useCallback(
    async (organizationId: string) => {
      const stream = await callApi(() => contractListCsv({ organizationId }));

      if (!stream) {
        return;
      }
    },
    [callApi],
  );

  return { downloadContractListCsv };
};

export default useDownloadContractListCsv;
