import { OverlayTriggerState, useOverlayTriggerState } from 'react-stately';
import { useEffect } from 'react';

export type ModalTriggerDefinition = {
  /**
   * Is the modal open
   */
  isOpen: boolean;
  /**
   * Fires when the modal is closed
   */
  onDismiss?: () => void;
  /**
   * Fires when the modal is toggled
   */
  onToggle?: () => void;
};

export type UseModalTriggerState = {
  overlayTriggerState: OverlayTriggerState;
};

/**
 * Used to define what happens when a modal's events get fired
 */
const useModalTriggerState = (definition: ModalTriggerDefinition): UseModalTriggerState => {
  const onDefinitionOpenChange = (isOpen: boolean) => {
    if (definition.isOpen && !isOpen && typeof definition.onDismiss !== 'undefined') {
      definition.onDismiss();
    }

    if (definition.isOpen !== isOpen && typeof definition.onToggle !== 'undefined') {
      definition.onToggle();
    }
  };

  const overlayTriggerState = useOverlayTriggerState({
    isOpen: definition.isOpen,
    onOpenChange: isOpen => onDefinitionOpenChange(isOpen),
  });

  useEffect(() => {
    onDefinitionOpenChange(definition.isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [definition.isOpen]);

  return { overlayTriggerState };
};

export default useModalTriggerState;
