import { isIframe } from '~/extensions/packages/iframe/isIframe';
import React, { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import WarrantyCartTotalCostSection from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-footer/packages/warranty-cart-total-cost-section/WarrantyCartTotalCostSection';
import WarrantyCartFooterDiscountSection from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-footer/packages/warranty-cart-footer-discount-section/WarrantyCartFooterDiscountSection';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';
import useFeatureAvailabilitiesContext from '~/wm/packages/feature/context/hooks/useFeatureAvailabilitiesContext';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';

export type WarrantyCartFooterProps = {
  canCheckout: boolean;
};

const WarrantyCartFooter = ({ canCheckout }: WarrantyCartFooterProps) => {
  const isInsideIframe = isIframe();
  const { 'lm-essential': enabledLmEssential } = useFeatureFlagProvider();
  const { hasFeaturePermissionAccount } = useFeatureAvailabilitiesContext();
  const { totalCost, formatCostOutput, apiUpsertLoading } = useWarrantyCartContext();

  /**
   * Adds required margin to the bottom of the page content
   * to prevent information being hidden at the bottom of the screen
   */
  const footerRef = useRef<HTMLDivElement>(null);
  const body = isInsideIframe ? document.getElementById('wm-content') : document.getElementById('enceladus-main-body');

  const verticalNavBar = isInsideIframe ? null : document.getElementById('enceladus-navigation-vertical-app');

  useEffect(() => {
    if (footerRef.current && body) {
      body.style.paddingBottom = `${footerRef.current.clientHeight}px`;
    }
  }, [footerRef, body]);

  useEffect(() => {
    if (footerRef.current && verticalNavBar) {
      footerRef.current.style.left = `calc(${verticalNavBar.clientWidth}px + 1px)`;
    }
  }, [footerRef, verticalNavBar]);

  return (
    <div
      ref={footerRef}
      css={css`
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1040;
        padding-top: 1rem;
      `}
    >
      {enabledLmEssential && !hasFeaturePermissionAccount('infrastructure-protection-discount-view') && (
        <WarrantyCartFooterDiscountSection
          totalCostSubunits={totalCost}
          formatCostSubunits={formatCostOutput}
          isLoading={apiUpsertLoading}
        />
      )}
      <WarrantyCartTotalCostSection canCheckout={canCheckout} />
    </div>
  );
};

export default WarrantyCartFooter;
