import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { featureAccessOrganizationUnlockClientFocusScorecardRoadmap } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseFeatureAccessOrganizationUnlock = {
  isUnlocking: boolean;
  organizationUnlock: (payload: FeatureAccessOrganizationUnlockPayload) => Promise<void>;
};

export type FeatureAccessOrganizationUnlockPayload = {
  organizationId: string;
};

export type UseFeatureAccessUnlockOptions = {
  reloadOnSuccess?: boolean;
};

const useFeatureAccessOrganizationUnlockClientFocusScorecardRoadmap = (
  options?: UseFeatureAccessUnlockOptions,
): UseFeatureAccessOrganizationUnlock => {
  const [isUnlocking, setIsUnlocking] = React.useState(false);
  const { callApi } = useApi();

  const unlockOrganization = async (payload: FeatureAccessOrganizationUnlockPayload) => {
    await (async () => {
      setIsUnlocking(true);
      const response = await callApi(() =>
        featureAccessOrganizationUnlockClientFocusScorecardRoadmap({
          organizationId: payload.organizationId,
        }),
      );
      setIsUnlocking(false);

      if (!response) {
        return;
      }

      if (options?.reloadOnSuccess) {
        window.location.reload();
        return;
      }
    })();

    return;
  };

  return { isUnlocking, organizationUnlock: unlockOrganization };
};

export default useFeatureAccessOrganizationUnlockClientFocusScorecardRoadmap;
