import { getTreeImpactRelatedService, ServiceId } from '../../model/TreeImpactRelatedService';
import * as React from 'react';
import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { css } from '@emotion/react';
import { Styleable } from '~/neo-ui/model/capacity';
import TreeImpactDataSection from '~/wm/packages/tree-impact/packages/tree-impact-data-section/TreeImpactDataSection';
import TreeImpactInfoFooter from '~/wm/packages/tree-impact/packages/tree-impact-info-footer/TreeImpactInfoFooter';

export type TreeImpactOrderSummaryCardProps = {
  serviceId: ServiceId;
  treesPerOrderUnit: number;
  totalOrderUnits: number;
  totalTreesPlanted: number;
  helpGuideUrl: string;
} & Styleable;

const TreeImpactOrderSuccessSummaryCard: React.FunctionComponent<TreeImpactOrderSummaryCardProps> = ({
  serviceId,
  treesPerOrderUnit,
  totalOrderUnits,
  totalTreesPlanted,
  helpGuideUrl,
  className,
}) => {
  const serviceInfo = getTreeImpactRelatedService(serviceId);

  return (
    <Box
      borderRadius={'radius400'}
      padding={'padding200'}
      className={className}
    >
      <Header
        size={4}
        weight={'bold'}
        css={css`
          margin-top: 0.125rem;
        `}
      >
        Your impact with this order
      </Header>
      <div
        css={css`
          margin-top: 1.25rem;
          display: flex;
          flex-wrap: wrap;
          gap: 3.219rem;
        `}
      >
        <TreeImpactDataSection
          css={css`
            flex: 1;
          `}
          icon={'Positive'}
          imageSource={serviceInfo.summaryServiceOrderTreesPlantedImageSource}
          description={serviceInfo.getSummaryServiceOrderTreesPlantedDescription(totalOrderUnits * treesPerOrderUnit)}
          displayNumber={totalOrderUnits * treesPerOrderUnit}
        />
        <TreeImpactDataSection
          css={css`
            flex: 1;
          `}
          icon={'Positive'}
          imageSource={serviceInfo.summaryAccountTreesPlantedImageSource}
          description={serviceInfo.getSummaryAccountTreesPlantedDescription(totalTreesPlanted + totalOrderUnits * treesPerOrderUnit)}
          displayNumber={totalTreesPlanted}
        />
        <TreeImpactDataSection
          css={css`
            flex: 1;
          `}
          icon={'StatusAdd'}
          imageSource={serviceInfo.summaryServiceOrderUnitImageSource}
          description={serviceInfo.getSummaryServiceOrderUnitDescription(totalOrderUnits)}
          displayNumber={totalOrderUnits}
        />
      </div>
      <TreeImpactInfoFooter
        helpGuideUrl={helpGuideUrl}
        css={css`
          margin: 1.25rem -0.938rem -0.938rem;
        `}
      />
    </Box>
  );
};

export default TreeImpactOrderSuccessSummaryCard;
