import { organizationEngagementNoteCreate } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { EngagementNoteCreatePayload } from '~/wm/packages/organization/packages/engagement/packages/engagement-note/packages/engagement-note-form/packages/engagement-note-create-form/EngagementNoteCreateForm';
import useEngagementNotePanelTabMutation, {
  UseOrganizationEngagementNotePanelTabMutationOptions,
} from '~/wm/packages/strategy/packages/strategy-dashboard/packages/engagement-dashboard/package/engagement-note-dashboard-panel-tab/context/hooks/useEngagementNotePanelTabMutation';

export type UseEngagementNoteDashboardCreate = {
  createEngagementNote: (createPayload: EngagementNoteCreatePayload) => void;
  isCreatingNote: boolean;
};

const useEngagementNoteDashboardCreate = (
  options?: UseOrganizationEngagementNotePanelTabMutationOptions,
): UseEngagementNoteDashboardCreate => {
  const [createEngagementNote, isCreatingNote] = useEngagementNotePanelTabMutation(
    (createPayload: EngagementNoteCreatePayload) =>
      organizationEngagementNoteCreate({
        organizationId: createPayload.organizationId,
        createPayload: {
          title: createPayload.title,
          description: createPayload.description,
          createdFrom: createPayload.createdFrom,
        },
      }),
    options,
  );

  return {
    createEngagementNote,
    isCreatingNote,
  };
};

export default useEngagementNoteDashboardCreate;
