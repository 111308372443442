import { InitiativeSaveFormData } from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-form/InitiativeSaveForm';
import { css } from '@emotion/react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { Enum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useInitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesContext';
import { Column } from '~/neo-ui/packages/table/packages/console/types';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import HardwareTable, { getHardwareTableDisplayColumns } from '~/wm/packages/hardware/packages/hardware-table-section/HardwareTable';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { getMinSizeInRem } from '~/neo-ui/packages/table/packages/console/ConsoleUtilities';
import { useMemo, useState } from 'react';
import { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { ConsoleRow } from '@AssetManagementClient/BeastClient/Search/Model/Console.gen';
import useInitiativeAssetSelectContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/context/hooks/useInitiativeAssetSelectContext';

const assetDisplayColumnsFieldKey: FieldKeyExpression<InitiativeSaveFormData> = values => values.assetDisplayColumns;

const InitiativeViewAssetsSection = () => {
  const { getFormInput } = useFormContext<InitiativeSaveFormData>();
  const { displayedAssetIds } = useInitiativeAssetSelectContext();
  const { organizationId } = useOrganizationContext();
  const { availabilities } = useInitiativeAvailabilitiesContext();
  const selectedColumnKeys = getFormInput<string[]>(assetDisplayColumnsFieldKey).value;

  const [assetRows, setAssetRows] = useState<ConsoleRow[]>([]);

  const availableColumns: Column[] =
    availabilities.columnsAvailabilities
      ?.map(column => ({
        key: column.key.value,
        label: column.label,
        isSortable: column.isSortable,
        order: column.order,
        availability: column.availability,
        size: column.size,
      }))
      .sort((a, b) => a.order - b.order) ?? [];

  const displayedColumns: Column[] = availableColumns.filter(column => selectedColumnKeys.includes(column.key));
  const columns: DataTableColumn<ConsoleRow>[] = useMemo(() => getHardwareTableDisplayColumns(displayedColumns), [displayedColumns]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.935rem;
      `}
    >
      <Header
        size={4}
        weight={'bold'}
      >
        Assets ({displayedAssetIds.length})
      </Header>
      <HardwareTable
        scope={{ type: Enum.Organization, organizationId }}
        assetRows={assetRows}
        columns={columns}
        sortableColumnKeys={displayedColumns.filter(column => column.isSortable).map(column => column.key)}
        displayedAssetIds={displayedAssetIds}
        minWidthRem={displayedColumns.reduce((sum, current) => sum + getMinSizeInRem(current.size), 0)}
        onAssetRowsUpdated={assetRows => setAssetRows(assetRows)}
      />
    </div>
  );
};

export default InitiativeViewAssetsSection;
