import React, { useMemo } from 'react';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type WarrantyCartCostColumnProps = {
  assetAccountId: string | undefined;
  cost: number;
  startDate: string;
};

const addText = (cost: number | string | undefined): string => `covers ${cost} months`;

const WarrantyCartCostColumn: React.FunctionComponent<WarrantyCartCostColumnProps> = ({ assetAccountId, cost, startDate }) => {
  const { formatCostOutput, coTermination, duration, getCoTerminationDuration, getIsUnitSelected } = useWarrantyCartContext();

  const coverage = useMemo((): string => {
    if (coTermination) {
      return getIsUnitSelected(assetAccountId || '') ? addText(getCoTerminationDuration(new Date(startDate))) : '-';
    }
    return addText(duration);
  }, [assetAccountId, coTermination, duration, getCoTerminationDuration, getIsUnitSelected, startDate]);

  return (
    <div>
      <div>{formatCostOutput(cost)}</div>
      <div
        css={css`
          color: ${colorToCode('dark-000')};
        `}
      >
        {coverage}
      </div>
    </div>
  );
};

export default WarrantyCartCostColumn;
