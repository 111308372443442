import BannerInternal from '~/neo-ui/packages/banner/BannerInternal';
import * as React from 'react';
import Graphic from '~/neo-ui/model/Graphic';
import CallToAction from '~/neo-ui/model/CallToAction';
import { Styleable } from '~/neo-ui/model/capacity';
import Color from '~/neo-ui/packages/color/Color.gen';

export type BannerInfoProps = {
  title: string;
  description: React.ReactNode;
  graphic: Graphic;
  callToAction?: CallToAction;
  openInNewTab?: boolean;
  backgroundColor?: Color;
} & Styleable;

/**
 * Provide information about the state of a page and an optional call to action if required
 */
const BannerInfo = ({
  title,
  description,
  graphic,
  callToAction,
  openInNewTab = false,
  backgroundColor = 'light-050',
  className,
}: BannerInfoProps) => {
  return (
    <BannerInternal
      titleProps={{ children: title, size: 4, weight: 'bold', color: 'dark-900' }}
      descriptionProps={{
        children: description,
        size: 5,
        weight: 'light',
        color: 'dark-900-80',
      }}
      graphic={{ verticalAlignment: 'start', ...graphic }}
      callToAction={callToAction && { verticalAlignment: 'end', ...callToAction }}
      boxShadow={'shadow0'}
      backgroundColor={backgroundColor}
      openInNewTab={openInNewTab}
      className={className}
    />
  );
};

export default BannerInfo;
