import { organizationEngagementNoteUnpin } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useEngagementNoteListPanelTabMutation, {
  UseOrganizationEngagementNoteListPanelTabMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-note-list-panel-tab/context/hooks/useEngagementNoteListPanelTabMutation';

export type UseEngagementNoteListUnpin = {
  unpinNote: (engagementNoteId: string) => void;
  isUnpinningNote: boolean;
};

const useEngagementNoteListUnpin = (options?: UseOrganizationEngagementNoteListPanelTabMutationOptions): UseEngagementNoteListUnpin => {
  const [unpinNote, isUnpinningNote] = useEngagementNoteListPanelTabMutation(
    (engagementNoteId: string) =>
      organizationEngagementNoteUnpin({
        engagementNoteId,
      }),
    options,
  );

  return {
    unpinNote,
    isUnpinningNote,
  };
};

export default useEngagementNoteListUnpin;
