import {
  IntegrationSetupSubmitFunction,
  IntegrationSetupSubmitState,
} from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useIntegrationSetupAndDefinitions';
import { IntegrationCredentialsProps } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/IntegrationCredentials';
import { useCallback, useEffect, useState } from 'react';
import { Request as IntegrationSetupUpsertRequest } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Controller/IntegrationSetupUpsertControllerNested.gen';
import invariant from 'tiny-invariant';
import { Result as IntegrationSetupUpsertResult } from '@AssetManagementClient/Primitives/Results/IntegrationSetupUpsertControllerNested/Response_/IntegrationSetupError_.gen';
import { Discriminant } from '@AssetManagementClient/Primitives/Results/IntegrationSetupUpsertControllerNested/Response_/IntegrationSetupError_/ResultNested.gen';

type IntegrationCredentialSubmitProps = Pick<IntegrationCredentialsProps, 'submitIntegrationSetupForm' | 'integrationSetupSubmitState'>;
const useIntegrationCredentialSubmit = ({ submitIntegrationSetupForm, integrationSetupSubmitState }: IntegrationCredentialSubmitProps) => {
  const [credentialSubmitState, setCredentialSubmitState] = useState<IntegrationSetupSubmitState>(IntegrationSetupSubmitState.Initializing);

  useEffect(() => {
    if (
      credentialSubmitState === IntegrationSetupSubmitState.Initializing &&
      integrationSetupSubmitState !== IntegrationSetupSubmitState.Initializing &&
      integrationSetupSubmitState !== IntegrationSetupSubmitState.Submitting
    ) {
      setCredentialSubmitState(integrationSetupSubmitState);
    }
  }, [credentialSubmitState, integrationSetupSubmitState]);

  const canSubmitCredentials =
    IntegrationSetupSubmitState.Ready || IntegrationSetupSubmitState.LastSubmitFailed || IntegrationSetupSubmitState.LastSubmitSuccess;

  const resetToSuccessState: () => void = useCallback(() => {
    setCredentialSubmitState(IntegrationSetupSubmitState.LastSubmitSuccess);
  }, [setCredentialSubmitState]);

  const submitCredentialForm: IntegrationSetupSubmitFunction = useCallback(
    async (integrationUpsertRequest: IntegrationSetupUpsertRequest): Promise<IntegrationSetupUpsertResult> => {
      if (!canSubmitCredentials) {
        invariant(
          false,
          `This component has not been initialized or another integration setup request is already in progress. This action is supposed be blocked`,
        );
      }

      setCredentialSubmitState(IntegrationSetupSubmitState.Submitting);
      return submitIntegrationSetupForm(integrationUpsertRequest)
        .then(integrationSetupUpsertResult => {
          if (!integrationSetupUpsertResult) {
            invariant(false, 'No response was returned from the integration setup upsert request.');
          }
          if (integrationSetupUpsertResult.case === Discriminant.Error) {
            setCredentialSubmitState(IntegrationSetupSubmitState.LastSubmitFailed);
            return integrationSetupUpsertResult;
          }

          setCredentialSubmitState(IntegrationSetupSubmitState.LastSubmitSuccess);
          return integrationSetupUpsertResult;
        })
        .catch(error => {
          setCredentialSubmitState(IntegrationSetupSubmitState.LastSubmitFailed);
          throw error;
        });
    },
    [canSubmitCredentials, submitIntegrationSetupForm],
  );

  return { credentialSubmitState, submitCredentialForm, resetToSuccessState };
};
export default useIntegrationCredentialSubmit;
