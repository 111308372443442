import { disposalOrderPickupBulkAvailabilitySchedule } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import useDisposalOrderInfoMutation, {
  UseDisposalOrderInfoMutationOptions,
} from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderInfoMutation';
import { Request } from '@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPickupBulkAvailabilityScheduleControllerNested.gen';

export type UseDisposalOrderBulkPickupAvailabilitySchedule = () => {
  schedulePickup: (request: Request, isRequestActive?: () => boolean) => void;
  isScheduling: boolean;
};

const useDisposalOrderBulkPickupAvailabilitySchedule: UseDisposalOrderBulkPickupAvailabilitySchedule = (
  options?: UseDisposalOrderInfoMutationOptions,
) => {
  const [schedulePickup, isScheduling] = useDisposalOrderInfoMutation(
    (request: Request) => disposalOrderPickupBulkAvailabilitySchedule(request),
    options,
  );

  return { schedulePickup, isScheduling };
};

export default useDisposalOrderBulkPickupAvailabilitySchedule;
