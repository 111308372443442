import { createContext } from 'react';

export type ContractTemplateAvailabilitiesMutationContextData = {
  triggerContractTemplateAvailabilitiesReload: (isRequestActive?: () => boolean) => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ContractTemplateAvailabilitiesMutationContext = createContext<ContractTemplateAvailabilitiesMutationContextData>({
  triggerContractTemplateAvailabilitiesReload: async () => {},
});

export default ContractTemplateAvailabilitiesMutationContext;
