import { css } from '@emotion/react';
import { useMultiStepForm } from '~/neo-ui/packages/form/packages/multi-step-form/MultiStepForm';
import AccordionItem from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/accordion-item/AccordionItem';
import CustomerCard from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/payment/CustomerCard';
import Payment from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/payment/Payment';
import SubscriptionSummary from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/subscription-summary/SubscriptionSummary';
import BillingSection, {
  billingAddressSchema,
} from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/billing-address/BillingSection';
import CollapsedBillingSection from '~/wm/packages/subscription/packages/purchase-subscription/packages/plan-checkout/packages/checkout-accordion/packages/billing-address/CollapsedBillingSection';
import { PurchaseSubscriptionForm } from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';

const CheckoutAccordion = (props: {
  active: number;
  actionLabel: string;
  edited: { isEdited: boolean }[];
  paymentSubmitAction: (setupIntent: string) => void;
  billingSubmitAction: () => void;
  changeEditedState: (index: number) => void;
}) => {
  const { actionLabel, paymentSubmitAction, billingSubmitAction, changeEditedState, edited, active } = props;
  const { formik } = useMultiStepForm<PurchaseSubscriptionForm>();
  const { isGrow } = formik.values;
  return (
    <div
      css={css`
        font-family: Roboto, serif;
      `}
    >
      <AccordionItem
        key={0}
        label={'1'}
        title={'Billing address'}
        isEdited={edited[0].isEdited}
        isActive={active === 0}
        editAction={async () => {
          formik.setFieldValue('isBillingAddressSet', false);
          changeEditedState(0);
        }}
      >
        {active === 0 ? (
          <BillingSection
            submitLabel={actionLabel}
            onSubmit={billingSubmitAction}
          />
        ) : edited[0].isEdited ? (
          <CollapsedBillingSection />
        ) : null}
      </AccordionItem>
      <AccordionItem
        key={1}
        label={'2'}
        title={'Payment method'}
        isActive={active === 1}
        isEdited={edited[1].isEdited}
        editAction={async () => {
          if (billingAddressSchema.isValidSync(formik.values.billingAddress)) {
            changeEditedState(1);
          }
        }}
      >
        <div
          css={css`
            margin-top: 1rem;
          `}
        >
          {active === 1 ? (
            <Payment
              submitLabel={actionLabel}
              paymentSubmitAction={paymentSubmitAction}
            />
          ) : edited[1].isEdited ? (
            <CustomerCard />
          ) : null}
        </div>
      </AccordionItem>
      <AccordionItem
        key={2}
        label={'3'}
        title={active === 2 ? (isGrow ? 'Review subscription and update' : 'Review subscription and pay') : 'Review subscription'}
        isActive={active === 2}
        isEdited={false}
        editAction={() => {}}
      >
        <SubscriptionSummary
          actionLabel={actionLabel}
          isReadyToPay={active === 2}
        />
      </AccordionItem>
    </div>
  );
};

export default CheckoutAccordion;
