import { SettingsKey, SettingsValue } from '@AssetManagementClient/BeastClient/Beast/Settings/Model.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import { ObjectSchema } from 'yup';
import Form from '~/neo-ui/packages/form/Form';
import FormSubmitStatusIndicator from '~/neo-ui/packages/form/packages/form-action/packages/form-submission/FormSubmitStatusIndicator';
import DisableElementOverlay from '~/neo-ui/packages/overlay/DisableElementOverlay';
import useSettingsApi from '~/wm/packages/settings/packages/settings-api/hooks/useSettingsApi';
import CustomizeSettingsToggle from '~/wm/packages/settings/packages/settings-form/packages/customize-settings-toggle/CustomizeSettingsToggle';
import RestoreDefaultsButton from '~/wm/packages/settings/packages/settings-form/packages/restore-defaults-button/RestoreDefaultsButton';

export type SettingsConfigurableProps = {
  settingsKey: SettingsKey;
  label: string;
  description?: string;

  /**
   * Optional schema for frontend input validation.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: ObjectSchema<any>;

  /**
   * Contains the form elements that control the form values
   */
  children: ((state: { isUsingDefault: boolean }) => React.ReactNode) | React.ReactElement;

  /**
   * TEMPORARY. Hide the save indicator in the account scope for better UX
   * when this component is embedded in the PHP form.
   */
  isEmbeddedInPhpForm?: boolean;
};

const SettingsConfigurable: React.FunctionComponent<SettingsConfigurableProps> = ({
  settingsKey,
  label,
  description,
  validationSchema,
  children,
  isEmbeddedInPhpForm = false,
}) => {
  const { settingsValue, isUsingDefault, customizeSettings, resetToDefault, saveSettings, isToggling, validationErrorMapper } =
    useSettingsApi(settingsKey);

  if (!settingsValue || typeof isUsingDefault === 'undefined') {
    return null;
  }

  // Would be better if this was returned from the backend
  const isRootScope = settingsKey.scopeKey.id === 'Account';

  return (
    <Form<SettingsValue>
      label={label}
      description={description}
      submitMethod={'auto'}
      defaultFormData={settingsValue}
      validationSchema={validationSchema}
      validationErrorMapper={validationErrorMapper}
      onSubmit={async data => {
        if (!isUsingDefault && data) {
          await saveSettings(data);
        }
      }}
      SubmitStatusIndicator={
        isRootScope ? (
          !isEmbeddedInPhpForm && (
            <FormSubmitStatusIndicator
              untouchedLabel="Settings saved"
              submittedLabel="Saved"
              submittingLabel="Saving…"
            />
          )
        ) : (
          <div
            css={css`
              display: flex;
              margin-bottom: 1.25rem;
            `}
          >
            <CustomizeSettingsToggle
              onCustomize={customizeSettings}
              onResetToDefault={resetToDefault}
              isUsingDefault={isUsingDefault}
              isToggling={isToggling}
            />
            <RestoreDefaultsButton />
          </div>
        )
      }
    >
      <DisableElementOverlay disabled={isUsingDefault}>
        {typeof children === 'function' ? children({ isUsingDefault }) : children}
      </DisableElementOverlay>
    </Form>
  );
};

export default SettingsConfigurable;
