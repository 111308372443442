import { css } from '@emotion/react';
import * as React from 'react';
import ActionBar from '~/neo-ui/packages/action-bar/ActionBar';
import TooltipSection from '~/neo-ui/packages/tooltip/packages/tooltip-section/TooltipSection';
import PdDisplayInfo from '~/wm/packages/subscription/packages/partner-development/packages/pd-contact-info/model/PdDisplayInfo';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type PdContactBarProps = {
  pdDisplayInfo: PdDisplayInfo;
};

const PdContactBar: React.FunctionComponent<PdContactBarProps> = ({ pdDisplayInfo }) => (
  <ActionBar
    imageSource={pdDisplayInfo.imageSource}
    actions={[
      {
        icon: 'Email',
        url: pdDisplayInfo.emailUrl,
        opensInNewTab: true,
        content: (
          <TooltipSection
            title={'Send an email'}
            description={'Opens in your mail app'}
          />
        ),
      },
      {
        icon: 'Calendar',
        url: pdDisplayInfo.meetingUrl,
        opensInNewTab: true,
        content: (
          <TooltipSection
            title={'Schedule a meeting'}
            description={'Opens in a new tab'}
          />
        ),
      },
    ]}
    backgroundColor={'primary-400'}
    activeButtonBorderColor={'primary-300'}
    activeButtonBackgroundColor={'primary-200'}
  >
    {
      <div>
        <p
          css={css`
            margin-bottom: 0.1875rem;
            font-size: 0.625rem;
            line-height: 1;
          `}
        >
          Get in touch with
        </p>
        <p
          css={css`
            margin-bottom: 0;
            color: ${colorToCode('light-000')};
            font-size: 0.75rem;
            font-weight: bold;
            line-height: 1;
          `}
        >
          {pdDisplayInfo.label}
        </p>
      </div>
    }
  </ActionBar>
);

export default PdContactBar;
