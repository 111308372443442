import Window from '~/neo-ui/packages/window/Window';
import Button from '~/neo-ui/packages/button/Button';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import React from 'react';
import AutoEmailReportFormData from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/types/AutoEmailReportFormData';
import AutoEmailReportFormInputs from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/AutoEmailReportFormInputs';

export type AutoEmailReportCreateWindowProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

const AutoEmailReportCreateWindow = ({ isOpen, onDismiss }: AutoEmailReportCreateWindowProps) => {
  const { submitForm, isSubmitting } = useFormContext<AutoEmailReportFormData>();

  return (
    <Window
      title={'Create Scheduled Report'}
      isOpen={isOpen}
      onDismiss={onDismiss}
      footerProps={{
        rightControls: [
          {
            expanded: (
              <Button
                iconLeft={'Done'}
                theme={'primary'}
                loading={isSubmitting}
                onClick={submitForm}
              >
                Save
              </Button>
            ),
          },
        ],
      }}
    >
      <AutoEmailReportFormInputs />
    </Window>
  );
};

export default AutoEmailReportCreateWindow;
