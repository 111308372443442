import { SubscriptionGrowAvailabilities } from '@SubscriptionClient/Subscription/Packages/Grow/Model.gen';
import { subscriptionGrowAvailabilities } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

const useSubscriptionGrowAvailabilities = (preselectProduct: string | undefined) => {
  const [isLoading, setLoading] = useState(true);

  const [growAvailabilities, setGrowAvailabilities] = useState<SubscriptionGrowAvailabilities>();

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const response = await callApi(() =>
        subscriptionGrowAvailabilities({
          preselectProductAbbreviation: preselectProduct,
        }),
      );
      setLoading(false);

      if (!response) {
        return;
      }

      setGrowAvailabilities(response.availabilities);
    })();
  }, [callApi, preselectProduct]);

  return { isLoading, growAvailabilities };
};

export default useSubscriptionGrowAvailabilities;
