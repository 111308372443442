import { SubscriptionBillingPeriodEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription.gen';
import * as React from 'react';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import { SubscriptionAgreementInfoTerm } from '~/wm/packages/subscription/packages/manage/wizard/model/SubscriptionAgreementInfo';
import TermSummary from '~/wm/packages/subscription/packages/term/packages/term-summary/TermSummary';

export type TermStepSectionProps = {
  term: SubscriptionAgreementInfoTerm;
  billingPeriod: SubscriptionBillingPeriodEnum;
  subscriptionAgreementLink: AnchorLocation;
};

const TermStepSection = ({ term, billingPeriod, subscriptionAgreementLink }: TermStepSectionProps) => (
  <TermSummary
    term={term}
    billingPeriod={billingPeriod}
    subscriptionAgreementLink={subscriptionAgreementLink}
  />
);

export default TermStepSection;
