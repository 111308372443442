import Button from '~/neo-ui/packages/button/Button';
import { Styleable } from '~/neo-ui/model/capacity';
import * as React from 'react';

export type ScorecardCategoryArrangeButtonProps = {
  onClick: () => void;
} & Styleable;

const ScorecardCategoryArrangeButton = ({ onClick, className }: ScorecardCategoryArrangeButtonProps) => (
  <Button
    iconLeft={'Arrange'}
    size={'sm'}
    onClick={onClick}
    className={className}
  >
    Arrange…
  </Button>
);

export default ScorecardCategoryArrangeButton;
