import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import SelectGroup, { ButtonSelectOption } from '~/neo-ui/packages/select/packages/select-group/SelectGroup';
import InitiativeSelect, {
  InitiativeSelectOption,
} from '~/wm/packages/strategy/packages/initiative/packages/initiative-list-select-modal/packages/initiative-select/InitiativeSelect';
import InitiativeTemplateSelect from '~/wm/packages/strategy/packages/initiative/packages/initiative-template/packages/initiative-template-select/InitiativeTemplateSelect';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { InitiativeTemplateDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/InitiativeTemplate/Dto/Model.gen';
import InsightInformation from '~/wm/packages/strategy/packages/initiative/packages/add-to-initiative/types/InsightInformation';
import { assertNeverOrThrow } from '~/extensions/packages/types/assertNever';
import { ModalConfirmationFooterButtonProps } from '~/neo-ui/packages/modal/packages/modal-confirmation/packages/modal-confirmation-footer/ModalConfirmationFooter';

export type InitiativeListSelectModalProps = {
  initiativeSelectOptions: InitiativeSelectOption[];
  insightInformation?: InsightInformation;
  title: string;
  description: ReactNode;
  isLoading: boolean;
  isOpen: boolean;
  onNewInitiativeSave: (selectedTemplate: InitiativeTemplateDto | undefined, shouldRedirect: boolean) => void;
  onExistingInitiativeSave: (initiativeId: string, shouldRedirect: boolean) => void;
  onDismiss: () => void;
  allowRedirect: boolean;
};

type AssetAction = 'existing-initiative' | 'new-initiative';

const InitiativeListSelectModal = ({
  initiativeSelectOptions,
  insightInformation,
  title,
  description,
  isLoading,
  isOpen,
  onNewInitiativeSave,
  onExistingInitiativeSave,
  onDismiss,
  allowRedirect,
}: InitiativeListSelectModalProps) => {
  const [assetAction, setAssetAction] = useState<AssetAction>('existing-initiative');
  const [selectedTemplate, setSelectedTemplate] = useState<InitiativeTemplateDto>();
  const [selectedInitiative, setSelectedInitiative] = useState<InitiativeSelectOption>();

  const assetActionOptions: ButtonSelectOption<AssetAction>[] = [
    {
      label: 'Existing Initiative',
      value: 'existing-initiative',
      disabled: initiativeSelectOptions.length === 0,
    },
    {
      label: 'New Initiative',
      value: 'new-initiative',
    },
  ];

  const resetModal = () => {
    onDismiss();
    setAssetAction(
      typeof availableInitiatives !== 'undefined' && availableInitiatives.length > 0 ? 'existing-initiative' : 'new-initiative',
    );
  };

  const triggerSubmission = (shouldRedirect: boolean) => {
    switch (assetAction) {
      case 'existing-initiative':
        if (!selectedInitiative) {
          return;
        }
        onExistingInitiativeSave(selectedInitiative.initiativeId, shouldRedirect);
        resetModal();
        return;
      case 'new-initiative':
        onNewInitiativeSave(selectedTemplate, shouldRedirect);
        resetModal();
        return;
      default:
        assertNeverOrThrow(assetAction);
    }
  };

  const availableInitiatives = useMemo(
    () =>
      initiativeSelectOptions
        // Order ascending by quarter
        ?.sort((a, b) => {
          if (!a.budgetQuarter) {
            return 1;
          }
          if (!b.budgetQuarter) {
            return -1;
          }
          const yearCompare = a.budgetQuarter.year - b.budgetQuarter.year;
          return yearCompare === 0 ? a.budgetQuarter.quarter - b.budgetQuarter.quarter : yearCompare;
        }),
    [initiativeSelectOptions],
  );

  useEffect(() => {
    (async () => {
      if (typeof availableInitiatives === 'undefined') {
        return;
      }

      if (availableInitiatives.length <= 0) {
        setAssetAction('new-initiative');
      }
    })();
  }, [availableInitiatives]);

  return (
    <ModalConfirmation
      header={{ title, icon: 'InitiativeAdd' }}
      theme={'primary'}
      footer={{
        confirmButton: [
          ...(allowRedirect
            ? [
                {
                  icon: 'GoRight',
                  label: 'Save and view',
                  loading: isLoading,
                  disabled: !(assetAction === 'new-initiative' || typeof selectedInitiative !== 'undefined'),
                  onClick: () => triggerSubmission(true),
                } satisfies ModalConfirmationFooterButtonProps,
              ]
            : []),
          {
            icon: 'Success',
            label: 'Save',
            loading: isLoading,
            disabled: !(assetAction === 'new-initiative' || typeof selectedInitiative !== 'undefined'),
            onClick: () => triggerSubmission(false),
          },
        ],
        dismissButton: {
          icon: 'Cancel',
          label: 'Cancel',
          disabled: isLoading,
          onClick: resetModal,
        },
      }}
      isOpen={isOpen}
    >
      {description}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-top: 1.25rem;
          margin-bottom: 1.25rem;
        `}
      >
        <Label
          bold={true}
          css={css`
            margin-bottom: 0.3125rem;
          `}
        >
          Add to
        </Label>
        <div>
          <SelectGroup<AssetAction>
            options={assetActionOptions}
            selectedOptionValue={assetAction}
            onOptionSelected={option => {
              if (option.value === 'existing-initiative') {
                setSelectedTemplate(undefined);
              }
              if (option.value === 'new-initiative') {
                setSelectedInitiative(undefined);
              }
              setAssetAction(option.value);
            }}
            shouldExpand={true}
          />
        </div>
        {
          {
            'existing-initiative': (
              <div
                css={css`
                  margin-top: 0.125rem;
                `}
              >
                <InitiativeSelect
                  initiatives={availableInitiatives.map(initiative => ({
                    initiativeId: initiative.initiativeId,
                    name: initiative.name,
                    budgetQuarter: initiative.budgetQuarter,
                  }))}
                  selectedInitiative={selectedInitiative}
                  onSelect={setSelectedInitiative}
                />
              </div>
            ),
            'new-initiative': (
              <div
                css={css`
                  margin-top: 0.125rem;
                `}
              >
                <InitiativeTemplateSelect
                  insightId={insightInformation?.id}
                  canSpecifyNoTemplate={true}
                  selectedTemplate={selectedTemplate}
                  onTemplateSelect={setSelectedTemplate}
                />
              </div>
            ),
          }[assetAction]
        }
      </div>
    </ModalConfirmation>
  );
};

export default InitiativeListSelectModal;
