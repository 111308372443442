import { createContext } from 'react';

export type EngagementNotePanelTabMutationContextData = {
  triggerEngagementNotePanelTabReload: (isRequestActive?: () => boolean) => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const EngagementNotePanelTabMutationContext = createContext<EngagementNotePanelTabMutationContextData>({
  // If there's no provider, it's a graceful no-op
  triggerEngagementNotePanelTabReload: async () => {},
});

export default EngagementNotePanelTabMutationContext;
