import { HardwareLifecycleReportSettingsAvailabilities } from '@AssetManagementClient/BeastClient/Beast/HardwareSettings/Model/LifecycleReport/Availabilities.gen';
import { ScopeKey } from '@AssetManagementClient/BeastClient/Beast/Settings/Scope.gen';
import React from 'react';
import HardwareLifecycleReportSettingsForm from '~/wm/packages/hardware/packages/settings/configurable/HardwareLifecycleReportSettingsForm';
import SettingsConfigurable from '~/wm/packages/settings/packages/configurable/SettingsConfigurable';

export type HardwareLifecycleReportConfigurableProps = {
  availabilities: HardwareLifecycleReportSettingsAvailabilities;
  scopeKey: ScopeKey;
  lmLastCheckinDateEnabled: boolean;
};

const HardwareLifecycleReportConfigurable: React.FunctionComponent<HardwareLifecycleReportConfigurableProps> = ({
  availabilities,
  scopeKey,
  lmLastCheckinDateEnabled,
}) => (
  <SettingsConfigurable
    settingsKey={{ scopeKey, configurableId: 'hardware-lifecycle-report' }}
    label="Lifecycle report"
    description="These settings will apply to scheduled reports"
  >
    {({ isUsingDefault }) => (
      <HardwareLifecycleReportSettingsForm
        availabilities={availabilities}
        disabled={isUsingDefault}
        lmLastCheckinDateEnabled={lmLastCheckinDateEnabled}
      />
    )}
  </SettingsConfigurable>
);

export default HardwareLifecycleReportConfigurable;
