import { css } from '@emotion/react';
import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Theme from '~/neo-ui/packages/color/Theme';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Toolbar from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import Testable from '~/neo-ui/packages/testable/Testable';
import { LabelProps } from '~/neo-ui/packages/text/packages/label/Label';

export type ModalActionFooterProps = {
  confirmationButtonAlignment?: 'left' | 'right';
  label: ModalActionFooterLabelProps;
  closeButton?: ModalActionFooterButtonProps;
  confirmButton?: ModalActionFooterButtonProps;
  buttonModifier?: ModalActionFooterButtonModifierProps;
  controls: React.ReactNode[];
  theme: Theme;
};

export type ModalActionFooterButtonProps = {
  label: string;
  icon?: IconType;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
};

export type ModalActionFooterButtonModifierProps = {
  icon: IconType;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
};

export type ModalActionFooterLabelProps = {
  active: React.ReactElement<LabelProps>;
  disabled: React.ReactElement<LabelProps>;
};

const ModalActionFooter: React.FunctionComponent<ModalActionFooterProps> = ({
  closeButton,
  confirmButton,
  buttonModifier,
  controls,
  label,
  theme,
}) => (
  <div
    css={css`
      margin-left: -1.25rem;
      margin-right: -1.25rem;
    `}
  >
    {(buttonModifier || closeButton || confirmButton) && (
      <Toolbar
        css={css`
          border-color: ${colorToCode('light-200')};
          border-bottom-left-radius: 0.9375rem;
          border-bottom-right-radius: 0.9375rem;
          padding: 0.5rem 1rem;
          overflow-y: unset;
          overflow-x: unset;
        `}
        leftControls={[
          ...(typeof buttonModifier !== 'undefined'
            ? [
                {
                  expanded: (
                    <Testable testId={'modal-footer-button-modifier'}>
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                          padding: 0.25rem;
                          padding-left: 0.5rem;
                          min-height: 2.875rem;
                          border-radius: 0.625rem;
                          background-color: ${colorToCode('light-200')};
                          gap: 1rem;
                        `}
                      >
                        <ButtonModifier
                          icon={buttonModifier.icon ?? 'Bad'}
                          onClick={buttonModifier.onClick}
                          disabled={buttonModifier.disabled}
                        />
                        <div
                          css={css`
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            margin-left: -0.5rem;
                            ${buttonModifier.disabled && 'padding-right: 0.5rem;'}
                          `}
                        >
                          {!buttonModifier.disabled ? label.active : label.disabled}
                          {!buttonModifier.disabled && (
                            <div
                              css={css`
                                display: flex;
                                align-items: center;
                                gap: 0.5rem;
                                ${buttonModifier.disabled && 'padding-right: 0.5rem;'}
                              `}
                            >
                              <Testable testId={'modal-footer-button-modifier-controls'}>{controls?.map(control => control)}</Testable>
                            </div>
                          )}
                        </div>
                      </div>
                    </Testable>
                  ),
                },
              ]
            : []),
          ...(typeof confirmButton !== 'undefined'
            ? [
                {
                  expanded: (
                    <Testable testId={'modal-footer-confirm'}>
                      <Button
                        iconRight={confirmButton.icon}
                        theme={theme}
                        onClick={confirmButton.onClick}
                        disabled={confirmButton.disabled}
                        loading={confirmButton.loading}
                      >
                        {confirmButton.label}
                      </Button>
                    </Testable>
                  ),
                },
              ]
            : []),
        ]}
        rightControls={[
          ...(typeof closeButton !== 'undefined'
            ? [
                {
                  expanded: (
                    <Testable testId={'modal-footer-close'}>
                      <Button
                        iconLeft={closeButton.icon}
                        onClick={closeButton.onClick}
                        disabled={closeButton.disabled}
                        loading={closeButton.loading}
                      >
                        {closeButton.label}
                      </Button>
                    </Testable>
                  ),
                },
              ]
            : []),
        ]}
      />
    )}
  </div>
);

export default ModalActionFooter;
