import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Image from '~/neo-ui/packages/image/Image';

export type DiscountInfoGroupProps = { pdImageSource: string } & Styleable;

const DiscountInfoGroup: React.FunctionComponent<React.PropsWithChildren<DiscountInfoGroupProps>> = ({
  pdImageSource,
  className,
  children,
}) => (
  <div className={className}>
    <div
      css={css`
        display: flex;
        align-items: flex-start;
      `}
    >
      <Image
        css={css`
          position: relative;
          top: 17px;
          height: 3.8125rem;
          width: 3.8125rem;
          border-radius: 50%;
          margin-right: 0.625rem;
        `}
        source={pdImageSource}
      />
      <div
        css={css`
          flex-grow: 1;
          > *:not(:last-child) {
            margin-bottom: 0.5rem;
          }
        `}
      >
        {children}
      </div>
    </div>
  </div>
);

export default DiscountInfoGroup;
