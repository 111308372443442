import { RoadmapInitiativeViewMode } from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/RoadmapV2';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import InitiativeStatusDropdown from '~/wm/packages/strategy/packages/initiative/packages/initiative-status-dropdown/InitiativeStatusDropdown';
import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import useInitiativeStatusUpdate from '~/wm/packages/strategy/packages/initiative/hooks/useInitiativeStatusUpdate';
import useInitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesContext';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Theme, { themeToColor } from '~/neo-ui/packages/color/Theme';
import RoadmapInitiativeTileFees from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-initiative-tile/packages/roadmap-initiative-tile-fees/RoadmapInitiativeTileFees';
import useRoadmapInitiativesContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapInitiativesContext';
import { RoadmapInitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import InitiativeSaveWindow from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-save-window/InitiativeSaveWindow';
import useLayoutPresentationContext from '~/neo-ui/packages/layout/packages/layout-presentation/packages/context/hooks/useLayoutPresentationContext';
import InitiativeTicketDisplay from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/packages/initiative-ticket-display/InitiativeTicketDisplay';
import useRoadmapFeatureFlagsContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapFeatureFlagsContext';

export type RoadmapInitiativeTileProps = {
  initiative: RoadmapInitiativeDto;
  roadmapInitiativeViewMode: RoadmapInitiativeViewMode;
};

const RoadmapInitiativeTile = ({ initiative, roadmapInitiativeViewMode }: RoadmapInitiativeTileProps) => {
  const { isFeatureFlagEnabled } = useRoadmapFeatureFlagsContext();
  const { highlightedInitiativeIds, setHighlightedInitiativeIds, focusInitiativeId } = useRoadmapInitiativesContext();
  const { isPresentationMode } = useLayoutPresentationContext();
  const { updateStatus, isUpdating } = useInitiativeStatusUpdate();
  const { onInitiativeSave } = useRoadmapInitiativesContext();
  const { availabilities } = useInitiativeAvailabilitiesContext();
  const { organizationId } = useOrganizationContext();

  const [isOpen, setIsOpen] = useState(focusInitiativeId === initiative.initiativeId);
  const tileRef = React.useRef<HTMLDivElement>(null);
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (highlightedInitiativeIds.includes(initiative.initiativeId)) {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      const onHighlighted = () => {
        setHighlightedInitiativeIds(highlightedInitiativeIds =>
          highlightedInitiativeIds.filter(highlightedInitiativeId => highlightedInitiativeId !== initiative.initiativeId),
        );
      };
      timeout = setTimeout(onHighlighted, 2000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [highlightedInitiativeIds, initiative.budgetQuarter, initiative.initiativeId, setHighlightedInitiativeIds]);

  const roadmapInitiativeView = (() => {
    switch (roadmapInitiativeViewMode) {
      case 'Description':
        return (
          <Label
            css={css`
              max-height: 14.6875rem;
              contain: paint;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 11;
              -webkit-box-orient: vertical;
              min-height: 4.5625rem;
            `}
          >
            {initiative.executiveSummary}
          </Label>
        );
      case 'Fees':
        return <RoadmapInitiativeTileFees initiative={initiative} />;
      default:
        return <Fragment />;
    }
  })();

  return (
    <div ref={tileRef}>
      <div
        ref={ref}
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: flex-start;
          min-height: 12.5rem;
        `}
        onClick={() => setIsOpen(true)}
      >
        <Header
          size={4}
          weight={'bold'}
          css={css`
            display: flex;
            gap: 0.5rem;
            margin-top: 0.5rem;
            word-break: break-all;
          `}
        >
          <Icon
            icon={'DragHorizontally'}
            css={css`
              margin-top: 0.1875rem;
            `}
          />
          {initiative.name}
        </Header>
        <InitiativeStatusDropdown
          selectedStatus={initiative.status}
          onStatusChange={status => {
            updateStatus(initiative.initiativeId, organizationId, status, () => onInitiativeSave(initiative.initiativeId));
          }}
          initiativeStatusAvailabilities={availabilities.statusAvailabilities}
          size={'md'}
          disabled={isUpdating}
          preventOnClickPropagation={true}
          css={css`
            width: 100%;
          `}
          dropdownMenuCss={css`
            overflow: auto;
            max-height: 8rem;
          `}
        />
        <div
          css={css`
            width: 100%;
          `}
        >
          {roadmapInitiativeView}
        </div>
        <div
          css={css`
            width: 100%;
            display: flex;
          `}
        >
          <div
            css={css`
              flex-grow: 1;
              display: flex;
              align-items: center;
              gap: 1rem;
              margin-bottom: 0.5rem;
            `}
          >
            <Icon
              icon={initiative.priority.icon as IconType}
              color={themeToColor(initiative.priority.theme as Theme, '400')}
            />
            <Icon
              icon={'HardwareAsset'}
              color={initiative.assetCount === 0 ? 'dark-900-32' : undefined}
            />
            {initiative.assetCount > 0 && <Label>{initiative.assetCount}</Label>}
            {typeof initiative.ticketLinkState !== 'undefined' && isFeatureFlagEnabled('lm-initiative-tickets') && (
              <InitiativeTicketDisplay ticketLinkState={initiative.ticketLinkState} />
            )}
          </div>
          <Icon
            icon={'GoExternal'}
            color={'primary-400'}
          />
        </div>
      </div>
      {isOpen && (
        <InitiativeSaveWindow
          isOpen={isOpen}
          onDismiss={() => setIsOpen(false)}
          initiativeId={initiative.initiativeId}
          onSaveInitiative={() => {
            onInitiativeSave(initiative.initiativeId);
            setIsOpen(false);
          }}
          isPresenting={isPresentationMode}
        />
      )}
    </div>
  );
};

export default RoadmapInitiativeTile;
