import { css } from '@emotion/react';
import * as React from 'react';
import ActionButton from '~/neo-ui/packages/button/packages/action-button/ActionButton';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { Placement } from '@popperjs/core';

export type SettingsLinkProps = {
  settingsUrl: string;
  tooltipContent: string;
  tooltipSetting?: Placement;
};

const SettingsLink: React.FunctionComponent<SettingsLinkProps> = ({ settingsUrl, tooltipContent, tooltipSetting }) => (
  <ActionButton
    icon={'Cog'}
    activeBackgroundColor={'secondary-400'}
    actionUrl={settingsUrl}
    tooltipContent={
      <div>
        <p
          css={css`
            margin-bottom: 0;
          `}
        >
          {tooltipContent}
        </p>
        <p
          css={css`
            margin-bottom: 0;
            color: ${colorToCode('light-000-80')};
            font-size: 0.75rem;
          `}
        >
          Open Settings
        </p>
      </div>
    }
    tooltipSetting={tooltipSetting}
  />
);

export default SettingsLink;
