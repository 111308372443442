import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';
import * as React from 'react';
import { InsightStateDto } from '@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Model.gen';
import { Styleable } from '~/neo-ui/model/capacity';
import { Enum as RiskLevel } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Rules/Logic/RiskLevelFactoryNested.gen';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { colorToThemeAdjustedColor } from '~/neo-ui/packages/color/Theme';

export type StrategyInsightDashboardFooterProps = {
  insightTrend?: number;
  insightForecast?: number;
  insightState: InsightStateDto;
  insightRiskLevel?: RiskLevel;
  isHovered: boolean;
  showTrendLabel?: boolean;
} & Styleable;

const StrategyInsightDashboardFooter: React.FunctionComponent<StrategyInsightDashboardFooterProps> = ({
  insightTrend,
  insightForecast,
  insightState,
  insightRiskLevel,
  isHovered,
  showTrendLabel = true,
}) => {
  const trendIncreasing = insightTrend !== undefined && insightTrend > 0;

  const darkerBackgroundColor = colorToCode(colorToThemeAdjustedColor(insightState.backgroundColorHover as Color, 1));

  const hoverColor = isHovered ? 'secondary-050' : 'secondary-800';

  return (
    <div>
      <div
        className={'shadow-sm'}
        css={css`
          border-radius: 0 0 10px 10px;
          height: 3rem;
          position: absolute;
          width: 100%;
          bottom: 0;
          padding: 0.1rem 0.4rem 0.1rem 0.4rem;
          background-color: ${isHovered && showTrendLabel ? darkerBackgroundColor : 'transparent'};
        `}
      >
        {/*   Trend and Forecast Label */}
        {isHovered && showTrendLabel && (
          <div
            css={css`
              display: table;
              width: 100%;
              text-align: left;
              letter-spacing: 0.1rem;
              font-size: 0.6rem;
              padding: 5px 0 0 0;
              margin: 0;
              color: ${colorToCode('light-000-80')};
            `}
          >
            <Label
              bold={true}
              size={'sm'}
              css={css`
                margin: 0;
                width: 100%;
                letter-spacing: 0.1rem;
                line-height: 0.625rem;
                font-size: 0.5625rem;
                color: ${colorToCode('light-000-80')};
              `}
            >
              <div
                css={css`
                  width: 50%;
                  text-align: left;
                  float: left;
                  padding-left: 5px;
                `}
              >
                TREND
              </div>
              <div
                css={css`
                  width: 50%;
                  text-align: right;
                  float: right;
                  padding-right: 5px;
                `}
              >
                FORECAST
              </div>
            </Label>
          </div>
        )}
        <Label
          size={'md'}
          css={css`
            border-radius: 0 0 10px 10px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: ${isHovered && showTrendLabel ? 0 : '4px'} 5px 4px 5px;
            background-color: ${isHovered ? darkerBackgroundColor : colorToCode('light-200')};
          `}
          color={hoverColor}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <Icon
                icon={insightTrend === undefined ? 'Remove' : trendIncreasing ? 'InsightTrendUp' : 'InsightTrendDown'}
                color={
                  isHovered ? 'light-000' : insightTrend === undefined ? 'light-700' : trendIncreasing ? 'negative-400' : 'positive-400'
                }
                css={css`
                  margin: 0 5px 0 10px;
                `}
              />
              <span>{insightTrend !== undefined && Math.abs(insightTrend)}</span>
            </div>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <span>{insightForecast !== undefined && insightForecast}</span>
              <Icon
                icon={insightForecast === undefined ? 'Remove' : 'Calendar90'}
                color={
                  isHovered
                    ? 'light-000'
                    : insightForecast === undefined
                    ? 'light-700'
                    : insightRiskLevel === RiskLevel.High
                    ? 'negative-400'
                    : 'secondary-400'
                }
                css={css`
                  margin: 0 10px 0 5px;
                `}
              />
            </div>
          </div>
        </Label>
      </div>
    </div>
  );
};
export default StrategyInsightDashboardFooter;
