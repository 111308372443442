import Dropdown, { DropdownOption } from '~/neo-ui/packages/dropdown/Dropdown';
import { RoadmapInitiativeViewMode } from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/RoadmapV2';
import MultiSelect, { MultiSelectOption } from '~/neo-ui/packages/select/packages/multi-select/MultiSelect';
import useInitiativeAvailabilitiesContext from '~/wm/packages/strategy/packages/initiative/packages/initiative-save/context/hooks/useInitiativeAvailabilitiesContext';
import Box from '~/neo-ui/packages/box/Box';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import ToggleSwitch from '~/neo-ui/packages/toggle/packages/toggle-switch/ToggleSwitch';
import RoadmapPagination from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap/packages/roadmap-pagination/RoadmapPagination';
import Button from '~/neo-ui/packages/button/Button';
import { useState } from 'react';
import RoadmapPdfDownloadWindow from '~/wm/packages/strategy/packages/roadmap-page/packages/roadmap-share-button/packages/roadmap-pdf-download-modal/RoadmapPdfDownloadWindow';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import useRoadmapInitiativesContext from '~/wm/packages/strategy/packages/roadmap-page/context/hooks/useRoadmapInitiativesContext';

export type RoadmapPresentationFiltersProps = {
  selectedViewMode: DropdownOption<RoadmapInitiativeViewMode>;
  onChangeSelectedViewMode: (value: DropdownOption<RoadmapInitiativeViewMode>) => void;

  selectedStatuses: MultiSelectOption[];
  onChangeSelectedStatuses: (values: MultiSelectOption[]) => void;

  selectedPriorities: MultiSelectOption[];
  onChangeSelectedPriorities: (values: MultiSelectOption[]) => void;

  showUnscheduled: boolean;
  onChangeShowUnscheduled: (value: boolean) => void;
};

const RoadmapPresentationFilters = ({
  selectedViewMode,
  onChangeSelectedViewMode,
  selectedStatuses,
  onChangeSelectedStatuses,
  selectedPriorities,
  onChangeSelectedPriorities,
  showUnscheduled,
  onChangeShowUnscheduled,
}: RoadmapPresentationFiltersProps) => {
  const { availabilities } = useInitiativeAvailabilitiesContext();
  const statusOptions: MultiSelectOption[] = availabilities.statusAvailabilities.map(availability => ({
    value: availability.id,
    label: availability.displayLabel,
  }));
  const priorityOptions: MultiSelectOption[] = availabilities.priorityAvailabilities.map(availability => ({
    value: availability.key,
    label: availability.label,
  }));
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
  const { setRoadmapsFiscalQuarterPageSize } = useRoadmapInitiativesContext();

  return (
    <Box
      theme={'none'}
      background={'foregroundAccent'}
      padding={'padding200'}
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
      `}
    >
      <RoadmapPagination roadmapViewMode={'Roadmap'} />
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          flex-basis: 53.3rem;
          gap: 1.5rem;
          padding: 0 1.5rem;

          border-left: 0.0625rem solid ${colorToCode('dark-900-24')};
          border-right: 0.0625rem solid ${colorToCode('dark-900-24')};
        `}
      >
        <div
          css={css`
            flex-basis: 12.5rem;
          `}
        >
          <Label
            bold={true}
            css={css`
              margin-bottom: 0.5rem;
            `}
          >
            Card info
          </Label>
          <Dropdown
            selectedOption={selectedViewMode}
            options={[
              { value: 'Description', label: 'View description' },
              { value: 'Fees', label: 'View fees' },
            ]}
            buttonDropdown={'button-outline'}
            onOptionSelected={onChangeSelectedViewMode}
            css={css`
              width: 100%;
            `}
          />
        </div>
        <div
          css={css`
            flex-basis: 12.5rem;
          `}
        >
          <Label
            bold={true}
            css={css`
              margin-bottom: 0.5rem;
            `}
          >
            Status
          </Label>
          <MultiSelect
            onChange={onChangeSelectedStatuses}
            selectedOptions={selectedStatuses}
            label={'Status'}
            options={statusOptions}
            css={css`
              width: 100%;
            `}
          />
        </div>
        <div
          css={css`
            flex-basis: 12.5rem;
          `}
        >
          <Label
            bold={true}
            css={css`
              margin-bottom: 0.5rem;
            `}
          >
            Priority
          </Label>
          <MultiSelect
            onChange={onChangeSelectedPriorities}
            selectedOptions={selectedPriorities}
            label={'Priority'}
            options={priorityOptions}
            css={css`
              width: 100%;
            `}
          />
        </div>
        <div>
          <Label
            bold={true}
            css={css`
              margin-bottom: 0.5rem;
            `}
          >
            Show not scheduled
          </Label>
          <ToggleSwitch
            onChange={checked => {
              onChangeShowUnscheduled(checked);
              setRoadmapsFiscalQuarterPageSize(checked ? 3 : 4);
            }}
            checked={showUnscheduled}
            theme={'positive'}
          />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Button
          theme={'primary'}
          iconRight={'Download'}
          onClick={() => setDownloadModalOpen(true)}
        >
          Download Roadmap
        </Button>
        <RoadmapPdfDownloadWindow
          onDismiss={() => {
            setDownloadModalOpen(false);
          }}
          isOpen={isDownloadModalOpen}
        />
      </div>
    </Box>
  );
};

export default RoadmapPresentationFilters;
