import { insightsCustomList } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { InsightDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Dto/Model.gen';
import { Enum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import { useState, useCallback, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

export type UseInsightList = () => {
  insights: InsightDto[] | undefined;
  refreshInsightList: () => void;
};

const useInsightList: UseInsightList = () => {
  const [insights, setInsights] = useState<InsightDto[]>();
  const { callApi } = useApi();

  const refreshInsightList = useCallback(() => {
    (async () => {
      const response = await callApi(() => insightsCustomList({ scope: { type: Enum.Account } }));
      if (!response) {
        return;
      }
      setInsights(response.insights);
    })();
  }, [callApi]);

  useEffect(() => refreshInsightList(), [refreshInsightList]);

  return {
    insights,
    refreshInsightList,
  };
};

export default useInsightList;
