import { SVGProps, forwardRef, memo } from 'react';

const JcbIcon = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="24"
        viewBox="0 0 36 24"
        fill="none"
        {...props}
      >
        <rect
          x="0.5"
          y="0.5"
          width="35"
          height="23"
          rx="2.5"
          fill="white"
        />
        <rect
          x="0.5"
          y="0.5"
          width="35"
          height="23"
          rx="2.5"
          stroke="#DDDDDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.18 5C22.97 5 22 5.99 22 7.22V10C22 10 24.18 10 25.27 10C26.911 10 27.451 10.56 27.451 11.39C27.451 11.94 26.911 12.5 26.371 12.5C26.911 12.5 27.451 13.06 27.451 13.61C27.451 14.45 26.911 15 25.27 15C24.18 15 22 15 22 15V20H25.82C27.02 20 28 19 28 17.78V5H24.18ZM25.55 11.34C25.55 10.56 25.27 10.56 23.63 10.56V12.22C25.27 12.22 25.55 12.22 25.55 11.34ZM23.63 14.45C25.27 14.45 25.55 14.45 25.55 13.57C25.55 12.78 25.27 12.78 23.63 12.78V14.45ZM25.55 11.34C25.55 10.56 25.27 10.56 23.63 10.56V12.22C25.27 12.22 25.55 12.22 25.55 11.34ZM23.63 12.78V14.45C25.27 14.45 25.55 14.45 25.55 13.57C25.55 12.78 25.27 12.78 23.63 12.78ZM23.63 10.56V12.22C25.27 12.22 25.55 12.22 25.55 11.34C25.55 10.56 25.27 10.56 23.63 10.56ZM23.63 12.78V14.45C25.27 14.45 25.55 14.45 25.55 13.57C25.55 12.78 25.27 12.78 23.63 12.78Z"
          fill="#43A047"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 5V17.78C21 19 20.02 20 18.82 20H15V14.43C15.46 14.83 16.18 15.071 17.28 15.071C18.31 15.071 19.26 14.85 20 14.501V13.091C19.75 13.741 19.04 14.161 17.7 14.161C16.75 14.161 15.98 13.511 15.98 12.501C15.98 11.491 16.75 10.841 17.7 10.841C19.04 10.841 19.75 11.261 20 11.911V10.5C19.26 10.15 18.31 9.93 17.28 9.93C16.18 9.93 15.46 10.17 15 10.57V7.22C15 5.99 15.98 5 17.18 5H21Z"
          fill="#FF1744"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.18 5C8.98 5 8 5.99 8 7.22V13.71C8 13.71 8.77 14.29 9.77 14.29C10.77 14.29 11 13.89 11 13.33C11 12.78 11 10 11 10H13C13 10 13 12.23 13 13.33C13 14.45 12.5 15.17 10.5 15.17C9 15.17 8 14.62 8 14.62V20H11.82C13.02 20 14 19 14 17.78V5H10.18Z"
          fill="#0D47A1"
        />
      </svg>
    );
  }),
);

export default JcbIcon;
