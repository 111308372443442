import { Response as SetupIntentCreate } from '@SubscriptionClient/Subscription/Packages/Billing/Controller/SetupIntentCreateControllerNested.gen';
import { subscriptionBillingSetupIntentCreate } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import { useCallback, useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

const useSetupIntentApi = () => {
  const [state, setState] = useState({
    isLoading: true,
    clientSecret: null as SetupIntentCreate['clientSecret'] | null,
  });

  const { callApi } = useApi();

  const startSetupIntent = useCallback(async () => {
    setState(previous => ({ ...previous, isLoading: true }));
    const response: SetupIntentCreate | undefined = await callApi(() => subscriptionBillingSetupIntentCreate({}));
    if (!response?.clientSecret) {
      setState({ clientSecret: null, isLoading: false });
      return;
    }

    setState({
      isLoading: false,
      clientSecret: response.clientSecret,
    });
  }, [callApi]);

  useEffect(() => {
    (async () => {
      startSetupIntent();
    })();
  }, [startSetupIntent]);

  return {
    ...state,
    startSetupIntent,
  };
};

export default useSetupIntentApi;
