import * as React from 'react';
import DisposalTreeImpactIntroCard from '~/wm/packages/disposal/packages/tree-impact/packages/disposal-tree-impact-intro-card/DisposalTreeImpactIntroCard';
import DisposalTreeImpactOrderPotentialCard from '~/wm/packages/disposal/packages/tree-impact/packages/disposal-tree-impact-order-potential-card/DisposalTreeImpactOrderPotentialCard';

import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import DisposalSelfCheckoutFormData from '~/wm/packages/disposal/types/DisposalSelfCheckoutFormData';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import DisposalTreeImpactOrderSuccessSummaryCard from '~/wm/packages/disposal/packages/tree-impact/packages/disposal-tree-impact-order-success-summary-card/DisposalTreeImpactOrderSuccessSummaryCard';
import { DisposalAssetTypeCountPayloadDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';

export type TreeImpactSectionProps = {
  treeImpactHelpGuideUrl: string;
  isCheckoutSubmitted: boolean;
};

const assetTypeCountsFieldKey: FieldKeyExpression<DisposalSelfCheckoutFormData> = values => values.assetTypeCounts;

const TreeImpactSection = ({ treeImpactHelpGuideUrl, isCheckoutSubmitted }: TreeImpactSectionProps) => {
  const { getFormInput } = useFormContext<DisposalSelfCheckoutFormData>();

  const assetTypeCounts = getFormInput<DisposalAssetTypeCountPayloadDto[]>(assetTypeCountsFieldKey).value;

  const assetTypeCountsSum = assetTypeCounts.reduce((final, current) => final + parseInt(String(current.count), 10), 0);

  const existingStepAssetsCount = assetTypeCountsSum;

  return existingStepAssetsCount === 0 ? (
    <DisposalTreeImpactIntroCard helpGuideUrl={treeImpactHelpGuideUrl} />
  ) : isCheckoutSubmitted ? (
    <DisposalTreeImpactOrderSuccessSummaryCard
      totalOrderUnits={existingStepAssetsCount}
      helpGuideUrl={treeImpactHelpGuideUrl}
    />
  ) : (
    <DisposalTreeImpactOrderPotentialCard
      totalOrderUnits={existingStepAssetsCount}
      helpGuideUrl={treeImpactHelpGuideUrl}
    />
  );
};

export default TreeImpactSection;
