import { ClassNames } from '@emotion/react';
import * as React from 'react';
import ListCard from '~/legacy-ui/packages/list/packages/list-card/ListCard';
import ListGroupItem from '~/legacy-ui/packages/list/packages/list-group/packages/list-group-item/ListGroupItem';
import Icon from '~/neo-ui/packages/icon/Icon';
import { vendorDefinition } from '~/wm/packages/integration/IntegrationVendorDefinition';
import OrganizationLink from '~/wm/packages/organization/packages/linking/model/OrganizationLink';
import Color from '~/neo-ui/packages/color/Color.gen';

export type OrganizationLinkItemProps = {
  actionElement: React.ReactElement;
  organization: OrganizationLink;
  hoverBgColor?: Color;
};

const OrganizationLinkItem: React.FunctionComponent<OrganizationLinkItemProps> = ({ actionElement, organization, hoverBgColor }) => (
  <ClassNames>
    {({ css }) => (
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <ListCard
          actionElement={actionElement}
          bgColor="light-200"
          hoverBgColor={hoverBgColor}
          css={css`
            overflow: hidden;
          `}
        >
          <ListGroupItem
            css={css`
              overflow: hidden;
            `}
            liClassName={css`
              margin-left: 13px;
              overflow: hidden;
            `}
          >
            <p
              css={css`
                user-select: none;
                margin: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              `}
            >
              {organization.name}
            </p>
          </ListGroupItem>
          <ListGroupItem
            liClassName={css`
              white-space: nowrap;
              justify-content: center;
            `}
          >
            <div
              css={css`
                line-height: 0;
              `}
            >
              {organization.vendors
                .map((vendorKey, index) => {
                  const vendorIcon = vendorDefinition(vendorKey).icon;
                  return (
                    vendorIcon && (
                      <Icon
                        key={index}
                        css={css`
                          margin-left: 10px;
                        `}
                        sizePx={20}
                        icon={vendorIcon}
                      />
                    )
                  );
                })
                .filter(element => element)}
            </div>
          </ListGroupItem>
        </ListCard>
      </div>
    )}
  </ClassNames>
);

export default OrganizationLinkItem;
