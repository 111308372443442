import { css } from '@emotion/react';
import { CoverageStatusEnum } from '@WarrantyClient/Warranty/Coverage/Model.gen';
import { differenceInCalendarDays } from 'date-fns';
import * as React from 'react';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import Button from '~/neo-ui/packages/button/Button';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import InfoItem from '../../../../../../../legacy-ui/packages/info/packages/info-item/InfoItem';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import AssetCoverage from '../../model/AssetCoverage';

export type CoverageListItemProps = {
  coverageListItem: AssetCoverage;
};
const CoverageListItem: React.FunctionComponent<CoverageListItemProps> = ({ coverageListItem }) => {
  const getServiceLink: AnchorLocation = {
    href: coverageListItem.getServiceUrl,
  };
  const buyCoverageLink: AnchorLocation = {
    href: coverageListItem.buyCoverageUrl,
  };
  const daysUntilExpire = differenceInCalendarDays(new Date(coverageListItem.coverageEndsAt), new Date());
  return (
    <div
      data-tracking="covered-asset"
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 0.625rem;
      `}
    >
      <div data-tracking="covered-asset-information">
        <InfoItem
          css={css`
            width: 12.5rem;
          `}
          title={coverageListItem.assetName}
          label={coverageListItem.orgName}
          description={`${coverageListItem.serialNumber} - ${coverageListItem.modelNumber ?? ''}`}
          titleColor={coverageListItem.coverageStatus === CoverageStatusEnum.In ? 'positive-400' : 'warning-400'}
          descriptionColor={'dark-100'}
        />
      </div>

      <div
        css={css`
          background-color: #efeff6;
          padding: 0.625rem;
          border-radius: 0.625rem;
          margin-bottom: 0.625rem;
          width: 23.125rem;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-left: 0.3125rem;
              margin-right: 0.625rem;
            `}
          >
            {coverageListItem.coverageStatus === CoverageStatusEnum.In && (
              <Header
                css={css`
                  width: 10.625rem;
                  margin-bottom: 0.25rem;
                `}
                size={5}
                color={'positive-400'}
              >
                Covered until{' '}
                {formatDate(coverageListItem.coverageEndsAt, {
                  format: 'yyyy/MM/dd',
                  timezone: TimezoneFormat.Utc,
                })}
              </Header>
            )}
            {coverageListItem.coverageStatus === CoverageStatusEnum.Soon && (
              <Header
                css={css`
                  width: 8.125rem;
                  margin-bottom: 0.25rem;
                `}
                size={5}
                color={'warning-400'}
              >
                Expires
                {daysUntilExpire === 0
                  ? ' today'
                  : ` in ${daysUntilExpire}
                    ${daysUntilExpire > 1 ? ' days' : ' day'}`}
              </Header>
            )}

            <a
              css={css`
                color: #9b9ca3 !important;
                text-decoration: underline !important;
              `}
              href={coverageListItem.coverageCardUrl}
            >
              Coverage info
            </a>
          </div>
          {coverageListItem.coverageStatus === CoverageStatusEnum.Soon && coverageListItem.isRenewable && (
            <div
              data-tracking="instant-renewals-button"
              css={css`
                display: flex;
                margin-right: 0.625rem;
              `}
            >
              <Button
                theme={'primary'}
                anchor={buyCoverageLink}
                iconLeft={'Cart'}
                size={'md'}
              >
                Renew
              </Button>
            </div>
          )}
          <div
            data-tracking="service-submit-button"
            css={css`
              display: flex;
            `}
          >
            <Button
              theme={'positive'}
              anchor={getServiceLink}
              iconLeft={'WarrantyMaster'}
              size={'md'}
            >
              Get service
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverageListItem;
