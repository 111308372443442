import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { deviceReportCustomerSettingsGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { HardwareLifecycleReportSettingsDto } from '@AssetManagementClient/BeastClient/Beast/Asset/Dto/Device/Reports/HardwareLifecycleReports/HardwareLifecycleReportSettings/Model.gen';

export type UseLifecycleReportSettings = (organizationId: string) => {
  lifecycleReportSettings: HardwareLifecycleReportSettingsDto | undefined;
};

const useLifecycleReportSettings: UseLifecycleReportSettings = organizationId => {
  const { callApi } = useApi();
  const [lifecycleReportSettings, setLifecycleReportSettings] = useState<HardwareLifecycleReportSettingsDto | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const response = await callApi(() =>
        deviceReportCustomerSettingsGet({
          organizationId,
        }),
      );

      if (!response) {
        return;
      }

      setLifecycleReportSettings(response.hardwareLifecycleReportSettings);
    })();
  }, [callApi, organizationId]);

  return {
    lifecycleReportSettings,
  };
};

export default useLifecycleReportSettings;
