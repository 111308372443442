import * as React from 'react';
import DisposalUrlContext from '~/wm/packages/disposal/context/DisposalUrlContext';

export type DisposalUrlProviderProps = {
  disposalOrderInfoUrl: string;
  disposalTermsOfServiceUrl: string;
  termsOfServiceUrl: string;
  disposalOrderListUrl: string;
  invoiceUrl: string;
  organizationDashboardUrl: string;
  hardwareViewUrl: string;
  treeImpactHelpGuideUrl: string;
  growUrl: string;
  featureRequestUrl: string;
};

const DisposalUrlProvider: React.FunctionComponent<React.PropsWithChildren<DisposalUrlProviderProps>> = ({
  disposalOrderInfoUrl,
  organizationDashboardUrl,
  hardwareViewUrl,
  growUrl,
  featureRequestUrl,
  disposalTermsOfServiceUrl,
  disposalOrderListUrl,
  invoiceUrl,
  termsOfServiceUrl,
  treeImpactHelpGuideUrl,
  children,
}) => (
  <DisposalUrlContext.Provider
    value={{
      disposalOrderInfoUrl,
      organizationDashboardUrl,
      hardwareViewUrl,
      growUrl,
      featureRequestUrl,
      disposalTermsOfServiceUrl,
      disposalOrderListUrl,
      invoiceUrl,
      termsOfServiceUrl,
      treeImpactHelpGuideUrl,
    }}
  >
    {children}
  </DisposalUrlContext.Provider>
);

export default DisposalUrlProvider;
