import useApi from '~/wm/packages/api/hook/useApi';
import usePolling from '~/extensions/packages/polling/hooks/usePolling';
import { integrationSyncStatus } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { SyncStatusDto } from '@AssetManagementClient/AssetManagement/Packages/Integration/Packages/Setup/Model.gen';
import { useCallback, useEffect, useState } from 'react';
import { IntegrationSetupSubmitState } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useIntegrationSetupAndDefinitions';

export const useSyncStatus = ({
  integrationSetupId,
  integrationSetupSubmitState,
}: {
  integrationSetupId: string | undefined;
  integrationSetupSubmitState: IntegrationSetupSubmitState;
}) => {
  const { callApi } = useApi();

  const [syncStatus, setSyncStatus] = useState<SyncStatusDto | undefined>(undefined);

  const repoll = useCallback(async () => {
    if (document.hidden || !integrationSetupId) {
      return;
    }

    const response = await callApi(() => integrationSyncStatus({ integrationSetupId }), undefined, true);
    if (!response) {
      return;
    }
    setSyncStatus(response.syncStatus);
  }, [callApi, integrationSetupId]);

  useEffect(() => {
    const updateOnSuccessfulSubmit = async () => {
      if (integrationSetupSubmitState === IntegrationSetupSubmitState.LastSubmitSuccess) {
        await repoll();
      }
    };

    void updateOnSuccessfulSubmit();
  }, [integrationSetupSubmitState, repoll]);

  usePolling(repoll, 5 * 1000);

  return { syncStatus };
};
