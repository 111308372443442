import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { Styleable } from '~/neo-ui/model/capacity';
import React from 'react';
import useItemSelection from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/hooks/useItemSelection';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import Theme from '~/neo-ui/packages/color/Theme';
import SelectAllCheckbox from '~/neo-ui/packages/select/packages/multi-select-static/packages/input-select-all/SelectAllCheckbox';
import SelectionTypeButton from './packages/button-selection-type/SelectionTypeButton';
import MultiSelectStaticOptionSearchBox from '~/neo-ui/packages/select/packages/multi-select-static/packages/multi-select-static-option-search-box/MultiSelectStaticOptionSearchBox';
import useSelectionType from './packages/button-selection-type/hooks/useSelectionType';
import MultiSelectStaticOption from '~/neo-ui/packages/select/packages/multi-select-static/packages/multi-select-static-option/MultiSelectStaticOption';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { shouldShowSearch } from '~/neo-ui/packages/select/packages/Common';

export type MultiSelectStaticSelection<T extends string> = {
  selectedOptions: T[] | undefined;
  matchType: string | undefined;
};

export type SelectStaticSharedProps<T extends string> = {
  /**
   * Display
   */
  headerLabel?: string;
  placeholderSearchString?: string;
  theme?: Theme;
  focusSearch?: boolean;

  /**
   * Values
   */
  allOptions: SelectOption<T>[]; // options to select from
} & Styleable;

export type MultiSelectStaticProps<T extends string = string> = {
  /**
   * Selection types
   */
  allSelectionTypes?: string[];
  selectedOptions: SelectOption<T>[] | undefined; // external control of selected options
  selectionType?: string; // external control of selection type (any or all)

  /**
   * Actions
   */
  // optional parent callback for responding to selection changes
  onChange?: (selection: MultiSelectStaticSelection<T>) => void;
} & SelectStaticSharedProps<T>;

export const SelectStaticHeaderLabel: React.FunctionComponent<{ headerLabel: string | undefined }> = ({ headerLabel }) =>
  headerLabel !== undefined ? (
    <Label
      className={'Header'}
      size={'lg'}
      bold={true}
      css={css`
        padding: 0.625rem;
      `}
    >
      {headerLabel}
    </Label>
  ) : null;

const MultiSelectStatic = <T extends string>({
  className,
  headerLabel,
  theme = 'secondary',
  focusSearch,
  placeholderSearchString = 'Find in list...',
  allOptions,
  allSelectionTypes = ['any', 'all'],
  selectedOptions,
  selectionType = allSelectionTypes[0],
  onChange,
}: MultiSelectStaticProps<T>) => {
  /** *****************
   * State Management *
   ********************/
  const [visibleOptions, setVisibleOptions] = React.useState<SelectOption<T>[]>(allOptions);

  /**
   * Selected options
   */
  const {
    selectItem: selectOption,
    deselectItem: deselectOption,
    selectItems: selectOptions,
    clearSelectedItems: clearOptions,
    selectedItems: internalSelectedOptions,
    isItemSelected: isOptionSelected,
    areSetsEqual: isSame,
  } = useItemSelection<T>();

  const updateSelectionType = (selectionType: string) => {
    if (onChange && internalSelectedOptions.size > 0) {
      onChange({
        selectedOptions: Array.from(internalSelectedOptions),
        matchType: selectionType,
      });
    }
  };
  const { selectedType, handleTypeChange } = useSelectionType(selectionType, updateSelectionType);

  /**
   * Update the selected options if parent modifies them
   */
  React.useEffect(() => {
    if (typeof selectedOptions === 'undefined') {
      return;
    }

    const externalSelectedOptions = new Set(selectedOptions.map(opt => opt.value) ?? []);
    if (isSame(externalSelectedOptions, internalSelectedOptions)) {
      return;
    }
    selectOptions(Array.from(externalSelectedOptions), undefined); // update internal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions, isSame, selectOptions]);

  /**
   * Call parent in response to internal values change
   */
  React.useEffect(() => {
    if (typeof selectedOptions === 'undefined' || onChange === undefined) {
      return;
    }

    const externalSelectedOptions = new Set(selectedOptions.map(opt => opt.value) ?? []);
    const updateExternal = () => {
      onChange({
        selectedOptions: Array.from(internalSelectedOptions),
        matchType: selectedType,
      });
    };
    if (isSame(externalSelectedOptions, internalSelectedOptions)) {
      return;
    }
    updateExternal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalSelectedOptions, isSame, onChange]);

  /** *************************
   * Handle displayed options *
   ****************************/
  const createVisibleOptions = (): React.ReactNode[] => {
    const optionsToDisplay: React.ReactNode[] = [];
    // Fill display options after they have been filtered
    visibleOptions.forEach(opt => {
      optionsToDisplay.push(
        <MultiSelectStaticOption
          key={`${className ? `${className}-` : ''}${headerLabel ? `${headerLabel}-` : ''}multi-select-static-${opt.value}`}
          option={opt}
          onSelectOption={selectOption}
          onDeselectOption={deselectOption}
          isSelected={isOptionSelected(opt.value)}
          icon={opt.prependIcon}
          legacyIconUrl={opt.legacyIconUrl}
          theme={theme}
        />,
      );
    });
    return optionsToDisplay;
  };

  /** ***************
   * Main component *
   ******************/
  return (
    <div
      className={className}
      css={css`
        width: 20.625rem;
      `}
    >
      <SelectStaticHeaderLabel headerLabel={headerLabel} />
      {shouldShowSearch(allOptions) && (
        <MultiSelectStaticOptionSearchBox
          className={'SearchBar'}
          placeholder={placeholderSearchString}
          allOptions={allOptions}
          setVisibleOptions={setVisibleOptions}
          theme={theme}
          focusSearch={focusSearch}
        />
      )}
      <div
        className={'SelectionControls'}
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0.313rem 0.625rem;
          border-bottom: ${colorToCode('dark-900-12')} solid 1px;
        `}
      >
        <SelectAllCheckbox
          options={visibleOptions}
          selectedOptions={internalSelectedOptions}
          selectOptions={selectOptions}
          clearOptions={clearOptions}
          theme={theme}
        />
        <SelectionTypeButton
          theme={theme}
          allSelectionTypes={allSelectionTypes}
          selectedType={selectedType}
          handleSelectedTypeChange={handleTypeChange}
        />
      </div>
      <div
        className={'Options'}
        key={`${className ? `${className}-` : ''}multi-select-static-options`}
        css={css`
          max-height: 30rem;
          overflow-y: auto;
        `}
      >
        {createVisibleOptions()}
      </div>
    </div>
  );
};
export default MultiSelectStatic;
