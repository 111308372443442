import { css } from '@emotion/react';
import { parseISO } from 'date-fns';
import * as React from 'react';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import { ScaleOption } from '~/neo-ui/packages/select/packages/scale-select/ScaleSelect';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import DmiScore from '~/wm/packages/strategy/packages/dmi/packages/dmi-score/DmiScore';
import useScorecardContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardContext';
import useDownloadScorecardPdf from '~/wm/packages/strategy/packages/scorecard/hooks/useDownloadScorecardPdf';
import Preformatted from '~/neo-ui/packages/text/packages/preformatted/Preformatted';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import ScorecardListSingleSelectPresentation from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-list/packages/scorecard-list-single-select-presentation/ScorecardListSingleSelectPresentation';
import Color from '~/neo-ui/packages/color/Color.gen';
import ScorecardCategoryPresentation from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-presentation/packages/scorecard-category-presentation/ScorecardCategoryPresentation';
import { smallBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import ScorecardProvider from '~/wm/packages/strategy/packages/scorecard/context/ScorecardProvider';

const ScorecardPresentationInternal = () => {
  const { scorecard } = useScorecardContext();
  const { downloadScorecardPdf } = useDownloadScorecardPdf();
  const { trackEvent } = useEventTracking(TrackingServices.EventProcessor);

  const trackAndDownloadScorecardPdf = () => {
    trackEvent('scorecard-download-pdf', {
      scorecardId: scorecard.scorecardId,
      organizationId: scorecard.organizationId,
    });
    downloadScorecardPdf(scorecard.scorecardId);
  };

  const scoreDisplayAvailabilities: ScaleOption[] = scorecard.scoreDisplayAvailabilities.map(score => ({
    value: score.value.toString(),
    color: `${score.theme}-300` as Color,
    borderColor: `${score.theme}-400` as Color,
  }));

  const priorityKeyToHide = 'None';

  const scorecardItemPriorityDisplayAvailabilities = scorecard.priorityDisplayAvailabilities.filter(
    priority => priority.key !== priorityKeyToHide,
  );

  return (
    <div>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.625rem;
        `}
      >
        <Header
          size={2}
          weight={'bold'}
        >
          {scorecard.title === '' ? 'Scorecard' : scorecard.title}
        </Header>
        <div
          css={css`
            display: flex;
          `}
        >
          <ScorecardListSingleSelectPresentation />
          <ButtonLink
            theme={'secondary'}
            iconRight={'Download'}
            onClick={() => trackAndDownloadScorecardPdf()}
            disabled={!scorecard}
          >
            Download PDF
          </ButtonLink>
        </div>
      </div>
      <Header
        size={4}
        color={'dark-300'}
      >
        {formatDate(new Date(scorecard.schedule.year, scorecard.schedule.month - 1), {
          format: 'MMMM yyyy',
          timezone: TimezoneFormat.Local,
        })}
      </Header>
      <div
        css={css`
          display: flex;
          gap: 3.125rem;
          vertical-align: top;
          ${scorecard.includeDmi || scorecard.executiveSummary ? 'margin-top: 1.875rem;' : ''};

          @media (max-width: ${smallBreakpointRem}rem) {
            flex-direction: column;
            gap: 0;
            margin-bottom: 1.875rem;
          }
        `}
      >
        {scorecard.includeDmi && (
          <DmiScore
            dmiScore={scorecard.dmiSnapshot.scoreOn10000}
            displayDmiScore={scorecard.dmiSnapshot.scoreDisplay}
            maxDmiScore={10000}
            themeColor={scorecard.dmiSnapshot.band.color as Color}
            bandLabel={scorecard.dmiSnapshot.band.label}
            lastUpdated={scorecard.dmiSnapshot.updatedAt !== undefined ? parseISO(scorecard.dmiSnapshot.updatedAt) : undefined}
            size={200}
          />
        )}
        {scorecard.executiveSummary && (
          <LayoutSection
            title={'Executive summary'}
            titleSize={3}
            titleWeight={'bold'}
          >
            <Preformatted text={scorecard.executiveSummary} />
          </LayoutSection>
        )}
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1.875rem;
        `}
      >
        {scorecard.scorecardCategories.map(scorecardCategory => (
          <ScorecardCategoryPresentation
            key={`scorecard-category-${scorecardCategory.scorecardCategoryId}`}
            scorecardCategory={scorecardCategory}
            currency={scorecard.currency}
            scoreDisplayAvailabilities={scoreDisplayAvailabilities}
            priorityDisplayAvailabilities={scorecardItemPriorityDisplayAvailabilities}
          />
        ))}
      </div>
    </div>
  );
};

export type ScorecardPresentationProps = {
  organizationId: string;
  organizationName: string;
};

const ScorecardPresentation = ({ organizationId, organizationName }: ScorecardPresentationProps) => (
  <ScorecardProvider
    organizationId={organizationId}
    organizationName={organizationName}
  >
    <ScorecardPresentationInternal />
  </ScorecardProvider>
);

export default ScorecardPresentation;
