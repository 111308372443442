import Header from '~/neo-ui/packages/text/packages/header/Header';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import * as React from 'react';
import { BudgetCurrencyDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/Budget/Dto/Model.gen';
import { PriorityDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Priority/Dto/Model.gen';
import ScorecardItemsSection from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-items/packages/scorecard-items-section/ScorecardItemsSection';
import ScorecardCategoryManageButton from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/packages/scorecard-category-manage-button/ScorecardCategoryManageButton';
import { ScorecardCategoryDto } from '@AssetManagementClient/BeastClient/Beast/Scorecard/Packages/ScorecardCategory/Dto/Model.gen';
import { ScaleOption } from '~/neo-ui/packages/select/packages/scale-select/ScaleSelect';
import ScorecardCategoryArrangeButton from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/packages/scorecard-category-arrange/packages/scorecard-category-arrange-button/ScorecardCategoryArrangeButton';

export type ScorecardCategorySectionProps = {
  scorecardCategory: ScorecardCategoryDto;
  scorecardId: string;
  organizationId: string;
  currency: BudgetCurrencyDto;
  scoreDisplayAvailabilities: ScaleOption[];
  priorityDisplayAvailabilities: PriorityDto[];
  onScorecardUpdateFailed: () => void;
  onArrangeOpen: () => void;
};

const ScorecardCategorySection = ({
  scorecardCategory,
  scorecardId,
  organizationId,
  currency,
  scoreDisplayAvailabilities,
  priorityDisplayAvailabilities,
  onScorecardUpdateFailed,
  onArrangeOpen,
}: ScorecardCategorySectionProps) => (
  <div>
    <div
      css={css`
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
      `}
    >
      <Header
        size={3}
        css={css`
          margin-right: 0.625rem;
          flex-shrink: 0;
        `}
      >
        {scorecardCategory.label}
      </Header>
      <ScorecardCategoryManageButton
        scorecardId={scorecardId}
        scorecardCategoryId={scorecardCategory.scorecardCategoryId}
        label={scorecardCategory.label}
      />
      <hr
        css={css`
          width: 100%;
          margin: 0 0.625rem;
          border-top: 0.0625rem solid ${colorToCode('dark-900-12')};
        `}
      />
      <ScorecardCategoryArrangeButton
        onClick={onArrangeOpen}
        css={css`
          flex-shrink: 0;
        `}
      />
    </div>
    <ScorecardItemsSection
      scorecardId={scorecardId}
      scorecardCategory={scorecardCategory}
      organizationId={organizationId}
      currency={currency}
      scoreDisplayAvailabilities={scoreDisplayAvailabilities}
      priorityDisplayAvailabilities={priorityDisplayAvailabilities}
      onScorecardUpdateFailed={onScorecardUpdateFailed}
    />
  </div>
);

export default ScorecardCategorySection;
