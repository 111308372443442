import { useContext } from 'react';
import EngagementActionDataCollectionContext from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/EngagementActionDataCollectionContext';

const useEngagementActionDataCollectionContext = () => {
  const engagementActionDataCollectionContext = useContext(EngagementActionDataCollectionContext);
  if (!engagementActionDataCollectionContext) {
    throw new Error('Tried to use EngagementActionDataCollectionContext outside of an EngagementActionDataCollectionProvider');
  }

  return engagementActionDataCollectionContext;
};

export default useEngagementActionDataCollectionContext;
