import { createContext } from 'react';

export type OrganizationContextData = {
  organizationId: string;
  organizationName: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const OrganizationContext = createContext<OrganizationContextData | undefined>(undefined);

export default OrganizationContext;
