import { QuoteSummary } from '@SubscriptionClient/BeastClient/Billing/Subscription/Quotes/Application/Queries.gen';
import { ComponentPropsWithoutRef } from 'react';
import Text from '~/sp-ui/text';
import theme from '~/sp-ui/theme';
import formatCurrency from '../../formatCurrency';
import DiscountLineItem from './DiscountLineItem';
import ExpenseLineItem from './ExpenseLineItem';

interface ReadonlyPaymentSummaryProps extends ComponentPropsWithoutRef<'div'> {
  quoteSummary: QuoteSummary;
}

const ReadonlyPaymentSummary = (props: ReadonlyPaymentSummaryProps) => {
  const { quoteSummary, ...rest } = props;

  return (
    <div {...rest}>
      <div css={{ display: 'grid', gridTemplateColumns: '1fr 1fr', rowGap: '0.5rem' }}>
        <img
          src={quoteSummary.logoUrl}
          css={{ height: '24px', marginBottom: '0.5rem', gridColumn: '1 / -1' }}
          alt={quoteSummary.productFamily}
        />
        {quoteSummary.expenseLineItems.map((lineItem, index) => {
          return (
            <ExpenseLineItem
              lineItem={lineItem}
              key={index}
            />
          );
        })}
        {!!quoteSummary.discountLineItem?.discountTotalInCents && <DiscountLineItem discountLineItem={quoteSummary.discountLineItem} />}
      </div>

      <hr css={{ backgroundColor: theme.palette.grey1, width: '100%', marginBlock: 8 }} />

      <Text>Total before taxes</Text>
      <Text css={{ float: 'right' }}>{formatCurrency(quoteSummary.totalBeforeTaxes)}</Text>
    </div>
  );
};

export default ReadonlyPaymentSummary;
