import { Request as SearchRequest } from '@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Link/Packages/Search/Controller/OrganizationLinkAvailabilitySearchControllerNested.gen';
import { organizationLinkSearch } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { Discriminant } from '@AssetManagementClient/Primitives/Results/OrganizationLinkAvailabilitySearchControllerNested/Response_/OrganizationLinkError_/ResultNested.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { showAlertAction } from '~/legacy-ui/packages/alert/state/action/alertActions';
import Card from '~/legacy-ui/packages/card/Card';
import useOperation from '~/legacy-ui/packages/operation/hooks/useOperation';
import Button from '~/neo-ui/packages/button/Button';
import SearchAsync from '~/neo-ui/packages/search/packages/search-async/SearchAsync';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import useApi from '~/wm/packages/api/hook/useApi';
import Organization from '~/wm/packages/organization/model/Organization';
import OrganizationLink from '~/wm/packages/organization/packages/linking/model/OrganizationLink';
import OrganizationLinkItem from '~/wm/packages/organization/packages/linking/packages/link-item/OrganizationLinkItem';
import addLinksOperation from './operation/addLinksOperation';

export type OrganizationAddLinksProps = {
  parentOrganization: Organization;
};

const OrganizationAddLinks: React.FunctionComponent<OrganizationAddLinksProps> = ({ parentOrganization }) => {
  const [selectedOrganizations, setSelectedOrganizations] = React.useState<OrganizationLink[]>([]);
  const addLinks = useOperation(addLinksOperation);
  const combineOrganizations = () =>
    addLinks({ targetOrganization: parentOrganization, selectedOrganizations }, () => {
      setSelectedOrganizations([]);
    });

  // enter && selectedOrganizations.length > 0 && no value being written => add links
  const [lastSelection, setLastSelection] = React.useState(0);
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // If there is a disparity between the last count of selected organizations and the actual count,
    // then the user is not drafting a new selection.
    const isDraftingNewSelection = lastSelection === selectedOrganizations.length;

    if (e.key === 'Enter' && !isDraftingNewSelection) {
      // Confirm selection
      combineOrganizations();
    } else {
      // Assumed that the user is drafting a new selection
      setLastSelection(selectedOrganizations.length);
    }
  };

  const { callApi } = useApi();
  const dispatch = useDispatch();

  return (
    <Card>
      <Header
        css={css`
          margin-bottom: 0.5rem;
        `}
        size={4}
      >
        Add links
      </Header>
      <SearchAsync
        placeholder="Search for a client to link"
        itemKey="name"
        onSearch={async (query: string) => {
          const searchRequest: SearchRequest = {
            parentOrganizationId: parentOrganization.id,
            organizationName: query,
          };

          const result = await callApi(() => organizationLinkSearch(searchRequest));
          if (!result) {
            return [];
          }
          if (result.case === Discriminant.Error) {
            dispatch(showAlertAction({ children: result.value.message }));
            return [];
          }
          return result.value.links.map(
            (link): OrganizationLink => ({
              id: link.organizationId,
              name: link.organizationName,
              vendors: link.vendors,
            }),
          );
        }}
        selection={{
          multiSelect: true,
          selected: selectedOrganizations,
          onResultsSelected: setSelectedOrganizations,
        }}
        renderSearchRow={org => (
          <OrganizationLinkItem
            actionElement={
              <Button
                size="sm"
                iconLeft={'Link'}
                theme="success"
              />
            }
            organization={org}
            hoverBgColor={'positive-100'}
          />
        )}
        onKeyDown={e => {
          if (e.type !== 'keydown') {
            throw new Error('not keydown event');
          }
          return onKeyDown(e);
        }}
        renderTokenLabel={option => option.name}
        theme={'positive'}
        activeColor={'positive-400'}
      />
      <Button
        css={css`
          margin-top: 0.625rem;
          ${!selectedOrganizations.length && 'display: none;'}
        `}
        iconLeft={'Link'}
        theme="success"
        onClick={combineOrganizations}
      >
        Link selected
      </Button>
    </Card>
  );
};

export default OrganizationAddLinks;
