import { camelToPascal, pascalToCamel } from '~/extensions/packages/casing/camelPascalConversion';

export type FieldKeyExpression<T> = (row: FieldKeyExpressionSegment<T>) => () => string;

export type FieldKeyExpressionSegment<T> = DotKeyObjectSegment<T>;
export type FieldKey = string;

/**
 * Resolve a field key expression to its corresponding string value.
 *
 * Typically, FieldKeyExpressions are passed throughout the application
 * stack, and resolution to string occurs at the engine-level.
 */
export const resolveFieldKey = <T>(fieldKeyExpression: FieldKeyExpression<T> | string): string => {
  switch (typeof fieldKeyExpression) {
    case 'string':
      return fieldKeyExpression;
    case 'function':
      return fieldKeyExpression(dotKeyObjectTraverse<T>())();
  }
};

/**
 * Resolve a field key expression to an API-compatible field key string.
 *
 * In contrast with resolveFieldKey, this excludes array indices from resolution,
 * since the main use-case for the API field key resolution is to reference
 * a field in a meta way, rather than resolving specific items within arrays.
 */
export const resolveApiFieldKey = <T>(fieldKeyExpression: FieldKeyExpression<T>): string =>
  fieldKeyExpression(
    dotKeyObjectTraverse<T>({
      keyTransformer: camelToPascal,
      excludeArrayIndices: true,
    }),
  )();

export const toFieldKeyExpression =
  <T>(fieldKey: string): FieldKeyExpression<T> =>
  _ =>
  () =>
    fieldKey;

/**
 * Given some object and a FieldKeyExpression reflecting
 * a locator to the field to mutate, perform a mutation
 * to the provided value.
 *
 * Example:
 * const x = { hello: 'world' };
 * mutateValueAtFieldKey(x, x => x.hello, 'world2')
 * // now x.hello === 'world2'
 *
 * @param data
 * @param fieldKeyExpression
 * @param value
 */
export const mutateValueAtFieldKey = <T>(
  data: T,
  fieldKeyExpression: FieldKeyExpression<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
): void => {
  const resolvedFieldKey = resolveFieldKey(fieldKeyExpression);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let dataPart = data as { [index: string]: any };
  const fieldKeySegments = resolvedFieldKey.split('.');
  for (const [fieldKeySegment, index] of fieldKeySegments.map((value, index) => [value, index])) {
    if (+index < fieldKeySegments.length - 1) {
      // Dig into the object
      dataPart = dataPart[fieldKeySegment];
    } else {
      // Finally, set the value
      dataPart[fieldKeySegment] = value;
    }
  }
};

export const getValueAtFieldKey = <T>(data: T, fieldKey: FieldKey): string | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let dataPart = data as { [index: string]: any };
  const fieldKeySegments = fieldKey.split('.').map(pascalToCamel);
  for (const fieldKeySegment of fieldKeySegments) {
    if (typeof dataPart[fieldKeySegment] === 'undefined') {
      return undefined;
    }
    dataPart = dataPart[fieldKeySegment];
  }
  const segment: unknown = dataPart;
  if (typeof segment === 'string' || typeof segment === 'number') {
    return segment.toString();
  }
  return undefined;
};

type DotKeyObjectSegment<T> = {
  [P in keyof T]: DotKeyObjectSegment<T[P]>;
} & (() => string);

const numericRegex = /^\d+$/;

export type FieldKeyOptions = {
  /**
   * Specify a function which modifies keys in the field key
   * e.g., transforming the casing - organization.name => Organization.Name
   */
  keyTransformer?: (key: string) => string;

  /**
   * Whether or not array indices should be trimmed or included in the field key.
   * False by default.
   *
   * e.g., for field expression object => object.array[0].field
   *  - Excluded: "object.array.field"
   *  - Included: "object.array[0].field"
   */
  excludeArrayIndices?: boolean;
};

/**
 * Generates a dot-separated path by traversing an object.
 * Supports array, but does not support numeric keys on objects.
 *
 * Example:
 *   generateFieldKey<Device>().organization.name === "Organization.Name"
 */
const dotKeyObjectTraverse = <T>(options?: FieldKeyOptions): DotKeyObjectSegment<T> => {
  const keyTransformer = options?.keyTransformer;
  const excludeArrayIndices = options?.excludeArrayIndices ?? false;

  const dotKeyObjectTraverseInternal = (prev?: string): DotKeyObjectSegment<T> =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    new Proxy<any>(() => prev, {
      get: (_, next) => {
        if (typeof next !== 'string') {
          throw new Error('Only supports traversing string keys');
        }

        const nextTransformed = !keyTransformer ? next : keyTransformer(next);

        // Ignore fields with only numbers: array index
        if (numericRegex.test(nextTransformed)) {
          if (typeof prev === 'undefined') {
            throw new Error('An index must be preceded by an array');
          }
          return dotKeyObjectTraverseInternal(excludeArrayIndices ? prev : `${prev}[${nextTransformed}]`);
        }

        return dotKeyObjectTraverseInternal(prev ? `${prev}.${nextTransformed}` : nextTransformed);
      },
    });

  return dotKeyObjectTraverseInternal();
};
