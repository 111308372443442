import { useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { warrantiesList } from '@WarrantyClient/WarrantyClientMsp.gen';
import { WarrantyListOptionDto } from '@WarrantyClient/BeastClient/Beast/Warranties/Dto/WarrantyListOptions.gen';
import { Enum as WarrantyTypeEnum } from '@WarrantyClient/BeastClient/Goods/WarrantyType/WarrantyTypeFactoryNested.gen';

export type UseWarrantiesList = (warrantyType: WarrantyTypeEnum | undefined) => {
  clientRows: WarrantyListOptionDto[] | 'loading';
  devicesCountTotal: number;
  clientRowsCount: number;
  loading: boolean;
};

const useWarrantiesList: UseWarrantiesList = (warrantyType: WarrantyTypeEnum | undefined) => {
  const [clientRows, setClientRows] = useState<WarrantyListOptionDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await callApi(() => warrantiesList({ warrantyType }));
      if (!response) {
        return;
      }
      setClientRows(response.items);
      setLoading(false);
    })();
  }, [callApi, warrantyType]);

  return {
    clientRows,
    devicesCountTotal: clientRows.reduce((n, { totalDevices }) => n + totalDevices, 0),
    clientRowsCount: clientRows.length,
    loading,
  };
};

export default useWarrantiesList;
