import { formatDateFriendly } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';
import { SubscriptionSummaryContractTerm } from '@SubscriptionClient/BeastClient/Billing/Subscription/PaymentSummary/Model.gen';
import formatCurrency from '~/extensions/packages/currency/formatCurrency';
import { getItemBillingFrequencyLabel } from '~/wm/packages/subscription/packages/product-subscription-page/packages/subscription-summary-card/SubscriptionSummaryCardItemSection';
import { mobileBreakpointRem, tabletBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { InfoIconTooltip } from '~/sp-ui/icons';
import ToolTip from '~/sp-ui/tool-tip';

export type SubscriptionSummaryCardTermSectionProps = {
  contractTerm: SubscriptionSummaryContractTerm | undefined;
  billingFrequency: string;
  nextPaymentAmount: number;
  nextBillingAt: string | undefined;
  currencyCode: string;
};

const SubscriptionSummaryCardInfoSection = ({
  contractTerm,
  billingFrequency,
  nextPaymentAmount,
  nextBillingAt,
  currencyCode,
}: SubscriptionSummaryCardTermSectionProps) => {
  const getRemainingTerm = (termEnd: string, remainingBillingCycle: number) => {
    const termEndDate = parseISO(termEnd ?? '');
    const currentDate = new Date();
    const daysUntilTermEnd = Math.round((termEndDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24));
    if (daysUntilTermEnd > 30) {
      const unit = remainingBillingCycle > 1 ? 'months' : 'month';
      return `${remainingBillingCycle} ${unit}`;
    }
    const unit = daysUntilTermEnd > 1 ? 'days' : 'day';
    return `${daysUntilTermEnd} ${unit}`;
  };

  return (
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: '33% 34% 33%',
        gridGap: '2rem',
        [`@media (max-width: ${tabletBreakpointRem}rem)`]: {
          borderRadius: '4px',
          border: `0.063rem solid ${colorToCode('light-300')}`,
          padding: '1rem',
          gridGap: '1rem',
        },
        [`@media (max-width: ${mobileBreakpointRem}rem)`]: {
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <div>
        <span css={{ fontWeight: 'bold' }}>Subscription term</span>
        {contractTerm === undefined ? (
          <p css={{ color: colorToCode('dark-050') }}>&ndash;</p>
        ) : (
          <div css={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <span css={{ marginRight: '3px' }}>Ending on {formatDateFriendly(parseISO(contractTerm.contractEndDate))}</span>
            <ToolTip
              content={
                <div>
                  <p css={{ marginBottom: '0' }}>
                    Remaining: {getRemainingTerm(contractTerm.contractEndDate, contractTerm.remainingBillingCycle)}
                  </p>
                  <p css={{ marginBottom: '0' }}>Billing cycle: {billingFrequency}ly</p>
                  <p css={{ marginBottom: '0' }}>Started on: {formatDateFriendly(parseISO(contractTerm.contractStartDate))}</p>
                  <p css={{ marginBottom: '0' }}>
                    {contractTerm.actionAtTermEnd} on: {formatDateFriendly(parseISO(contractTerm.contractEndDate))}
                  </p>
                </div>
              }
            >
              {({ anchorProps, setAnchorRef }) => {
                return (
                  <InfoIconTooltip
                    css={{ height: '1rem' }}
                    ref={setAnchorRef}
                    {...anchorProps}
                  />
                );
              }}
            </ToolTip>
          </div>
        )}
      </div>
      <div>
        <span css={{ fontWeight: 'bold' }}>Next payment</span>
        {nextPaymentAmount !== 0 ? (
          <p>
            {formatCurrency(nextPaymentAmount, currencyCode, 2, true)}
            {getItemBillingFrequencyLabel(billingFrequency)}*
          </p>
        ) : (
          <p css={{ color: colorToCode('dark-050') }}>&ndash;</p>
        )}
      </div>
      <div>
        <span css={{ fontWeight: 'bold' }}>Next billing date</span>
        {nextBillingAt !== undefined ? (
          <p>{formatDateFriendly(parseISO(nextBillingAt))}</p>
        ) : (
          <p css={{ color: colorToCode('dark-050') }}>&ndash;</p>
        )}
      </div>
    </div>
  );
};

export default SubscriptionSummaryCardInfoSection;
