import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import useEngagementListPageInitiativeLinkContext from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/context/hooks/useEngagementListPageInitiativeLinkContext';

export type EngagementActionTableInitiativeLinkButtonProps = {
  action: EngagementActionDto;
};

const EngagementActionTableInitiativeLinkButton = ({ action }: EngagementActionTableInitiativeLinkButtonProps) => {
  const { setSelectedAction, setModalOpen } = useEngagementListPageInitiativeLinkContext();

  return (
    <ButtonLink
      onClick={() => {
        setSelectedAction(action);
        setModalOpen(true);
      }}
      iconLeft={'ActionNew'}
    />
  );
};

export default EngagementActionTableInitiativeLinkButton;
