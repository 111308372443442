import * as React from 'react';
import useContractStatusSyncContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/context/useContractStatusSyncContext';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';

const ContractStatusSyncDisplay = () => {
  const { contractSyncStatus } = useContractStatusSyncContext();

  if (typeof contractSyncStatus === 'undefined') {
    return null;
  }

  return (
    <Label size={'md'}>
      Last synced:{' '}
      {formatDate(new Date(contractSyncStatus.lastSyncedAt), {
        format: 'hh:mm a',
        timezone: TimezoneFormat.Local,
      })}{' '}
      ,{' '}
      {formatDate(new Date(contractSyncStatus.lastSyncedAt), {
        format: 'MM-dd-yyyy',
        timezone: TimezoneFormat.Local,
      })}
    </Label>
  );
};

export default ContractStatusSyncDisplay;
