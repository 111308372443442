import React from 'react';
import { FieldValueDisplay } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';
import { css } from '@emotion/react';
import { FormCheckboxDisplay } from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckBoxDisplay';
import { SyncPreferencesData } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useSyncPreferencesForm';

import { UseFormRegister } from 'react-hook-form';

type SyncFilterCheckboxesProps = {
  options: FieldValueDisplay[];
  register: UseFormRegister<SyncPreferencesData>;
  filterName: string;
  payloadType: string;
};

const SyncFilterCheckboxes: React.FunctionComponent<SyncFilterCheckboxesProps> = ({ payloadType, options, register, filterName }) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    `}
  >
    {options.map((checkboxField: FieldValueDisplay, index) => (
      <FormCheckboxDisplay
        id={`${payloadType}.${filterName}.${checkboxField.id}`}
        key={checkboxField.id}
        // Unfortunately, this key is not type-checked. Changes to the SyncPreferencesData['filters'] type might require changes here.
        propsForHtmlInputComponent={register(`filters.${payloadType}.${filterName}.${index}.selected` as const)}
        description={checkboxField.label}
      />
    ))}
  </div>
);
export default SyncFilterCheckboxes;
