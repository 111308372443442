import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable, Themeable } from '~/neo-ui/model/capacity';
import ButtonInternal, { ButtonInternalColorMap } from '~/neo-ui/packages/button/ButtonInternal';
import useTheme from '~/neo-ui/packages/color/hooks/useTheme';
import Theme from '~/neo-ui/packages/color/Theme';
import IconType from '~/neo-ui/packages/icon/IconType.gen';

export type ButtonModifierProps = {
  onClick?: () => void;
  icon: IconType;
  theme?: Theme;
  disabled?: boolean;
  preventOnClickPropagation?: boolean;
} & Styleable &
  Themeable;

/**
 * Small round button with only an icon
 */
const ButtonModifier = ({ className, icon, theme, disabled = false, onClick, preventOnClickPropagation = false }: ButtonModifierProps) => {
  const { themeMap } = useTheme(theme);

  const colorMap: ButtonInternalColorMap = {
    backgroundColorRest: themeMap.foregroundAccent,
    backgroundColorHover: themeMap.foregroundRaised,
    backgroundColorActive: themeMap.foregroundLowered,
    backgroundColorFocus: themeMap.foregroundAccent,

    textColorRest: themeMap.foregroundContrast,
    textColorHover: themeMap.foregroundContrast,
    textColorActive: themeMap.foregroundContrast,
    textColorFocus: themeMap.foregroundContrast,

    borderColorRest: 'dark-900-12',
    borderColorHover: 'dark-900-12',
    borderColorActive: 'dark-900-12',
    borderColorFocus: themeMap.backgroundLowered,
  };

  return (
    <ButtonInternal
      colorMap={colorMap}
      size={'xs'}
      iconLeft={icon}
      disabled={disabled}
      onClick={onClick}
      className={className}
      preventOnClickPropagation={preventOnClickPropagation}
      css={css`
        min-width: 1.5rem;
        margin-right: 0.625rem;

        .svgicon {
          min-width: 0.5rem;
        }
      `}
    />
  );
};

export default ButtonModifier;
