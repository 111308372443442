import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { AccountAuthenticationSettings } from '@BeastClient/Identity/Features/Authentication/AuthenticationSettings/Models.gen';
import { authenticationSettingsGet } from '@BeastClient/BeastClientMsp.gen';
import { EmptyRequest } from '@BeastClient/ApiServer/Packages/AspNetCore/Controller/Request.gen';
export type UseAccountAuthenticationSettingsGet = () => {
  accountAuthenticationSettings: AccountAuthenticationSettings | undefined;
};

const useAccountAuthenticationSettingsGet: UseAccountAuthenticationSettingsGet = () => {
  const [accountAuthenticationSettings, setAccountAuthenticationSettings] = useState<AccountAuthenticationSettings>();
  const { callApi } = useApi();
  useEffect(() => {
    const request: EmptyRequest = {};
    (async () => {
      const response = await callApi(() => authenticationSettingsGet(request));
      if (!response) {
        return;
      }
      setAccountAuthenticationSettings(response.accountAuthenticationSettings);
    })();
  }, [callApi]);

  return {
    accountAuthenticationSettings,
  };
};

export default useAccountAuthenticationSettingsGet;
