import { css } from '@emotion/react';
import * as React from 'react';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import ListGroup from '../list-group/ListGroup';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export interface ListCardProps {
  onClick?: () => void;
  className?: string;
  actionElement: React.ReactElement<ListCardProps>;
  bgColor?: Color;
  hoverBgColor?: Color;
  icon?: IconType;
}

/**
 * @deprecated from usage of php classes, replace with using flex box
 */
class ListCard extends React.PureComponent<React.PropsWithChildren<ListCardProps>> {
  public render() {
    const { className, actionElement, icon, bgColor = 'light-200', hoverBgColor, onClick, children } = this.props;

    return (
      <div
        onClick={onClick}
        className={`list-card d-flex align-items-center`}
        css={css`
          background-color: ${colorToCode(bgColor)};
          border-radius: 8px;
          flex-grow: 1;
          padding: 5px;
          width: 100%;

          &:hover {
            ${hoverBgColor && `background-color: ${colorToCode(hoverBgColor)};`}
          }
        `}
      >
        {actionElement}
        <ListGroup
          css={css`
            background-color: ${colorToCode('blank')};
          `}
          className={`flex-grow-1 ${className ? className : ''}`}
        >
          {children}
        </ListGroup>
        {icon && (
          <Icon
            className="flex-shrink-0"
            icon={icon}
            sizePx={30}
          />
        )}
      </div>
    );
  }
}

export default ListCard;
