import { RenderFilter } from '@AssetManagementClient/BeastClient/Search/Model/Query/Field/Filter/Render.gen';
import { SortingOrder } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { NoResultsReason } from '@AssetManagementClient/BeastClient/Search/Model/Query/Result.gen';
import {
  ColumnAvailabilityDto,
  ColumnDto,
  ColumnSizeDto,
  FilterDto,
} from '@AssetManagementClient/BeastClient/Search/Model/Console/Dto.gen';
import { PaginationMetadata as PaginationMetadataDto } from '@AssetManagementClient/BeastClient/Primitives/Query/Pagination.gen';
import { FieldKey } from '@AssetManagementClient/Primitives/Query.gen';
import { ConsoleRow } from '@AssetManagementClient/BeastClient/Search/Model/Console.gen';
import { ConsoleSearch } from '~/neo-ui/packages/table/packages/console/buildConsoleRequest';

export type Filter = {
  key: string;
  label: string;
  tags: Map<string, string[]>;
  order: number;
  render: RenderFilter;
  isEnabled: boolean;
};

export type Column = {
  label: string;
  key: string;
  order: number;
  isSortable: boolean;
  availability: ColumnAvailabilityDto;
  size: ColumnSizeDto;
};

export type PaginationMetadata = {
  totalPages: number;
  totalResults: number;
};

export type Response = {
  result: {
    columns: ColumnDto[];
    filters: FilterDto[];
    results: {
      noResultsReason: NoResultsReason | undefined;
      results: ConsoleRow[];
      metadata: PaginationMetadataDto;
    };
  };
};

export type ConsoleMetadata = {
  filters: Filter[];
  columns: Column[];
  pagination: PaginationMetadata | undefined;
};

export type PaginationState = {
  /**
   * Zero-indexed
   */
  pageNumber: number;

  /**
   * How many results should fit in a single page
   */
  perPageSize: number;
};

export type SortState = {
  key: FieldKey;
  order: SortingOrder;
};

export type TagFilter = Map<string, string[]>;

export type ConsoleState = {
  pagination: PaginationState;
  sort: SortState | undefined;
  parameters: Map<string, Map<string, string[]>>;
  search: ConsoleSearch | undefined;
};

export const defaultConsoleState: ConsoleState = {
  pagination: {
    pageNumber: 0,
    perPageSize: 50,
  },
  sort: undefined,
  parameters: new Map<string, Map<string, string[]>>(),
  search: undefined,
};

export const emptyColumns: Set<string> = new Set<string>();

export const areConsoleParameterEquals = (
  consoleParams1: Map<string, Map<string, string[]>>,
  consoleParams2: Map<string, Map<string, string[]>>,
) => {
  if (consoleParams1.size !== consoleParams2.size) {
    return false;
  }

  // Outermost filter comparison
  for (const [filter, operatorValMap] of consoleParams1) {
    if (!consoleParams2.has(filter)) {
      return false;
    }
    const operatorValMap2 = consoleParams2.get(filter)!;
    if (operatorValMap.size !== operatorValMap2.size) {
      return false;
    }

    // Operator comparison
    for (const [operator, values] of operatorValMap) {
      if (!operatorValMap2.has(operator)) {
        return false;
      }
      const values1 = new Set(values);
      const values2 = new Set(operatorValMap2.get(operator));
      if (values1.size !== values2.size) {
        return false;
      }

      // Values array comparison
      for (const v of values1) {
        if (!values2.has(v)) {
          return false;
        }
      }
    }
  }
  return true;
};
