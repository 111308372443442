// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


/**
 * Levels of detail of displaying
 */
export enum DetailLevel
{
  /**
   * Least amount of information, e.g. only the count.
   */
  Small = "Small",
  /**
   * Data with some contextual information, e.g. count with description
   */
  Medium = "Medium",
  /**
   * Data with context and possibly action to take, e.g. count with description and call to action
   */
  Large = "Large",
  /**
   * Detailed data by breaking down the data into multiple buckets
   */
  Expanded = "Expanded",
}


