import useApi from '~/wm/packages/api/hook/useApi';
import React from 'react';
import { cartEstimatePdfProposal } from '@WarrantyClient/WarrantyClientMsp.gen';

type UseDownloadPdfProposal = (onDownload?: () => void) => {
  downloadPdfProposal: (estimateId: string) => void;
};

export const useDownloadPdfProposal: UseDownloadPdfProposal = onDownload => {
  const { callApi } = useApi();

  const downloadPdfProposal = React.useCallback(
    async (estimateId: string) => {
      const stream = await callApi(() => cartEstimatePdfProposal({ estimateId }));

      if (!stream) {
        return;
      }

      if (onDownload) {
        onDownload();
      }
    },
    [callApi, onDownload],
  );

  return { downloadPdfProposal };
};
