// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.
import { FieldKey as AssetManagementClientBeastClientPrimitivesQueryFieldKey } from "@AssetManagementClient/BeastClient/Primitives/Query.gen"
import { PaginationPayload as AssetManagementClientBeastClientPrimitivesQueryPaginationPaginationPayload } from "@AssetManagementClient/BeastClient/Primitives/Query/Pagination.gen"
import { Order as AssetManagementClientBeastClientPrimitivesQuerySortingOrder } from "@AssetManagementClient/BeastClient/Primitives/Query/Sorting.gen"
import { SearchPayload as AssetManagementClientBeastClientSearchModelConsoleDtoConsoleRequestDtoNestedSearchPayload } from "@AssetManagementClient/BeastClient/Search/Model/Console/Dto/ConsoleRequestDtoNested.gen"
import { RenderFilter as AssetManagementClientBeastClientSearchModelQueryFieldFilterRenderRenderFilter } from "@AssetManagementClient/BeastClient/Search/Model/Query/Field/Filter/Render.gen"
import { QuerierResult as AssetManagementClientBeastClientSearchModelQueryResultConsoleRow_QuerierResult } from "@AssetManagementClient/BeastClient/Search/Model/Query/Result/ConsoleRow_.gen"


export enum ColumnAvailabilityDto
{
  Mandatory = "Mandatory",
  Default = "Default",
  Available = "Available",
}



export interface ColumnDto
{
  /**
   * Path to use to get the data from the Dto
   */
  key: AssetManagementClientBeastClientPrimitivesQueryFieldKey;
  /**
   * Name of the field in the UI
   */
  label: string;
  /**
   * The order of display for the column. The smaller numbers should appear on the left.
   */
  order: number;
  /**
   * Is the column sortable?
   */
  isSortable: boolean;
  /**
   * Availability of the column
   */
  availability: ColumnAvailabilityDto;
  size: ColumnSizeDto;
}


export enum ColumnSizeDto
{
  XSmall = "XSmall",
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
}



export interface ConsoleRequestDto
{
  /**
   * Parameters received by the frontend
   */
  parameters: {[key in string]: {[key in string]: string[]}};
  /**
   * Order to use to sort the columns
   */
  sort: AssetManagementClientBeastClientPrimitivesQuerySortingOrder[];
  /**
   * Pagination information
   */
  pagination: AssetManagementClientBeastClientPrimitivesQueryPaginationPaginationPayload;
  /**
   * Information to use for the full-text search
   */
  search: AssetManagementClientBeastClientSearchModelConsoleDtoConsoleRequestDtoNestedSearchPayload | undefined;
}


export interface ConsoleResponseDto
{
  /**
   * Columns available for the console
   */
  columns: ColumnDto[];
  /**
   * Filters available for the console
   */
  filters: FilterDto[];
  /**
   * Sorting used by the console
   */
  sort: AssetManagementClientBeastClientPrimitivesQuerySortingOrder[];
  /**
   * Result of the query
   */
  results: AssetManagementClientBeastClientSearchModelQueryResultConsoleRow_QuerierResult;
}


export interface FilterDto
{
  /**
   * Key to use to query in the backend
   */
  key: AssetManagementClientBeastClientPrimitivesQueryFieldKey;
  /**
   * Label to use for the field
   */
  label: string;
  /**
   * The order of display for the filter. The bigger numbers should appear on the left.
   */
  order: number;
  /**
   * How to render the filter in the frontend
   */
  render: AssetManagementClientBeastClientSearchModelQueryFieldFilterRenderRenderFilter;
  /**
   * Whether or not the filter is enabled.
   */
  isEnabled: boolean;
  /**
   * Value received by the filter
   */
  tags: {[key in string]: string[]};
  /**
   * Errors from the parameter validation
   */
  errors: string[];
}

