import WizardPage from '~/neo-ui/packages/wizard/packages/wizard-page/WizardPage';
import * as React from 'react';
import buildDisposalSelfCheckoutPayloadDto from '~/wm/packages/disposal/builder/buildDisposalSelfCheckoutPayloadDto';
import DisposalSelfCheckoutFormData, { DisposalType } from '~/wm/packages/disposal/types/DisposalSelfCheckoutFormData';
import DisposalSummaryLayoutBar from '~/wm/packages/disposal/wizard/summary/DisposalSummaryLayoutBar';
import DisposalBannerSection from '~/wm/packages/disposal/wizard/additional-component/DisposalBannerSection';
import TreeImpactSection from '~/wm/packages/disposal/wizard/additional-component/TreeImpactSection';
import { Response as DisposalCheckoutSelfControllerResponse } from '@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Checkout/Packages/DisposalCheckoutSelf/Controller/DisposalCheckoutSelfControllerNested.gen';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import getDisposalSelfSummaryStep from './step/getDisposalSelfSummaryStep';
import useDisposalUrlContext from '~/wm/packages/disposal/context/hooks/useDisposalUrlContext';
import useDisposalSelfCheckout from '~/wm/packages/disposal/hooks/useDisposalSelfCheckout';
import DisposalSelfSuccessSection from '~/wm/packages/disposal/wizard/success/packages/disposal-self-success-section/DisposalSelfSuccessSection';
import { DisposalOrderSummaryDtoNested } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Dto/Model.gen';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { DisposalWizardProps } from '~/wm/packages/disposal/packages/disposal-section/DisposalSection';
import getDisposalSelfBillingStep from '~/wm/packages/disposal/wizard/packages/disposal-self-wizard/step/getDisposalSelfBillingStep';
import OrderPaymentFreeCostModule from '~/wm/packages/disposal/wizard/section/packages/module/order-payment-free-cost-module/OrderPaymentFreeCostModule';
import getDisposalSelfShippingStep from '~/wm/packages/disposal/wizard/packages/disposal-self-wizard/step/getDisposalSelfShippingStep';
import getDisposalSelfAssetsStep from '~/wm/packages/disposal/wizard/packages/disposal-self-wizard/step/getDisposalSelfAssetsStep';
import { validatePhoneNumber } from '~/wm/packages/disposal/wizard/section/AccountPhoneNumber';

const biosCertifiedFieldKey: FieldKeyExpression<DisposalSelfCheckoutFormData> = values => values.certification.biosCertified;

const DisposalSelfWizard = ({
  disposalDynamicPricing,
  disposalAssetTypeCountAvailabilities,
  billingInfo,
  paymentVersion,
  defaultCheckoutData,
  brandName,
  showLegacyBanner,
  showPermissionsBanner,
  showLocationRestrictionBanner,
  cart,
  onDisposalTypeSwap,
  hasDisposalBulkLocationAccess,
  dropOffLocationsUrl,
  disposalSettings,
  minAssetsForFullService,
}: DisposalWizardProps) => {
  const [isCheckoutSubmitted, setIsCheckoutSubmitted] = React.useState(false);
  const { disposalCheckout } = useDisposalSelfCheckout();
  const { disposalTermsOfServiceUrl, disposalOrderListUrl, termsOfServiceUrl, treeImpactHelpGuideUrl, invoiceUrl } =
    useDisposalUrlContext();
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  // If location restriction cannot submit order
  // If user have no permission cannot submit payment order when it is on a legacy plan
  const cannotSubmitOrder = showLocationRestrictionBanner || (showPermissionsBanner && showLegacyBanner);

  const checkout = React.useCallback(
    async (data: DisposalSelfCheckoutFormData) => {
      // Wrapping if statement should be removed after bulk shipping is released
      const disposalCheckoutPayload = buildDisposalSelfCheckoutPayloadDto(
        data.shipmentReturnAddress,
        data.assetTypeCounts,
        data.customerToken,
        data.coupon,
        data.phoneNumber,
      );

      const result = await disposalCheckout(disposalCheckoutPayload);
      setIsCheckoutSubmitted(true);

      return result;
    },
    [disposalCheckout],
  );

  const toFormData = React.useCallback(
    (): DisposalSelfCheckoutFormData => ({
      assetTypeCounts: defaultCheckoutData.assetTypeCounts,
      packageCount: typeof cart !== 'undefined' && cart.packageAmount > 0 ? cart.packageAmount : 1,
      shipmentReturnAddress:
        typeof billingInfo.billingAddress !== 'undefined' && billingInfo.billingAddress.address.countryCode !== 'US'
          ? { ...billingInfo, billingAddress: undefined }
          : billingInfo,
      customerToken:
        // Duplicates: 9b951c9c-6a6b-4e55-b5fd-dc032a2a5f1e
        typeof billingInfo.paymentInfo !== 'undefined' && typeof billingInfo.paymentInfo.defaultCard !== 'undefined'
          ? {
              type: 'card-on-file',
              last4: billingInfo.paymentInfo.defaultCard.last4Digits,
              brand: billingInfo.paymentInfo.defaultCard.brand,
            }
          : undefined,
      certification: {
        locationCertified: false,
        addressIsValid: false,
        biosCertified: defaultCheckoutData.isBiosCertified,
      },
      coupon: undefined,
      type: DisposalType.Self,
      phoneNumber: billingInfo.billingAddress?.phone ?? '',
      isPhoneNumberValid: typeof validatePhoneNumber(billingInfo.billingAddress?.phone) === 'undefined',
    }),
    [cart, defaultCheckoutData, billingInfo],
  );

  const isPaymentRequired = showLegacyBanner;

  const defaultFormData = React.useMemo(() => toFormData(), [toFormData]);

  // storing as const first to be able to access key for event tracking
  const disposalAssetsStep = getDisposalSelfAssetsStep(
    disposalAssetTypeCountAvailabilities,
    onDisposalTypeSwap,
    hasDisposalBulkLocationAccess,
    !isPaymentRequired,
    disposalSettings.isBulkOrderPlacedDisabled,
    minAssetsForFullService,
  );

  const disposalShippingStep = getDisposalSelfShippingStep(billingInfo, dropOffLocationsUrl);

  const disposalSummaryStep = isPaymentRequired
    ? getDisposalSelfBillingStep(
        billingInfo,
        paymentVersion,
        disposalTermsOfServiceUrl,
        termsOfServiceUrl,
        brandName,
        disposalDynamicPricing,
        disposalAssetTypeCountAvailabilities,
      )
    : getDisposalSelfSummaryStep(disposalTermsOfServiceUrl, termsOfServiceUrl, brandName, disposalAssetTypeCountAvailabilities);

  return (
    <WizardPage
      defaultFormData={defaultFormData}
      defaultIncompleteFields={defaultCheckoutData.isBiosCertified ? [] : [biosCertifiedFieldKey]}
      steps={[disposalAssetsStep, disposalShippingStep, disposalSummaryStep]}
      submitLabel={'Place order now'}
      disabledOptions={{
        submit: cannotSubmitOrder,
        progress: showLocationRestrictionBanner,
      }}
      onSubmit={async formData => checkout(formData)}
      onPageProgress={(currentStepKey: string) => {
        switch (currentStepKey) {
          case disposalAssetsStep.key:
            trackEventProcessor('continue_to_disposal_shipping_clicked', {});
            break;
          case disposalShippingStep.key:
            trackEventProcessor('continue_to_disposal_billing_clicked', {});
            break;
        }
      }}
      AdditionalComponents={[
        {
          position: 'top',
          component: (
            <DisposalBannerSection
              showLegacyBanner={showLegacyBanner}
              showPermissionBanner={showPermissionsBanner}
              showLocationRestrictionBanner={showLocationRestrictionBanner}
              billingInfo={billingInfo}
              dropOffLocationsUrl={dropOffLocationsUrl}
            />
          ),
        },
        {
          position: 'bottom',
          component: (
            <TreeImpactSection
              treeImpactHelpGuideUrl={treeImpactHelpGuideUrl}
              isCheckoutSubmitted={isCheckoutSubmitted}
            />
          ),
        },
      ]}
      SummaryComponent={
        isPaymentRequired ? <DisposalSummaryLayoutBar disposalDynamicPricing={disposalDynamicPricing} /> : <OrderPaymentFreeCostModule />
      }
      SuccessComponent={({ postSubmitPayload }) => (
        <DisposalSelfSuccessSection
          // Type of the response is lost through generics. Here you go Response, have your type back!
          disposalOrderSummary={
            (postSubmitPayload as DisposalCheckoutSelfControllerResponse).disposalOrderInfo as DisposalOrderSummaryDtoNested.ItadSelf
          }
          invoiceUrl={invoiceUrl}
          disposalOrderListUrl={disposalOrderListUrl}
        />
      )}
    />
  );
};

export default DisposalSelfWizard;
