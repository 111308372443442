import { css } from '@emotion/react';
import { EmptyRequest } from '@SubscriptionClient/ApiServer/Packages/AspNetCore/Controller/Request.gen';
import { subscriptionPricingOfferAccept } from '@SubscriptionClient/SubscriptionClientMsp.gen';
import React from 'react';
import Card from '~/legacy-ui/packages/card/Card';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import useApi from '~/wm/packages/api/hook/useApi';
import SubscriptionOfferExpired from '~/wm/packages/subscription/packages/offer/packages/expired/SubscriptionOfferExpired';
import { useSubscriptionPlanOffer } from '~/wm/packages/subscription/packages/offer/packages/subscription-offer/hooks/useSubscriptionPlanOffer';
import PdContactSection from '~/wm/packages/subscription/packages/partner-development/packages/pd-contact-info/packages/pd-contact-section/PdContactSection';
import useSubscriptionPdContact from '~/wm/packages/subscription/packages/subscription-pd-contact/hooks/useSubscriptionPdContact';
import TermSummary from '~/wm/packages/subscription/packages/term/packages/term-summary/TermSummary';
import ProductPlanSummary from '~/wm/packages/subscription/packages/plan/packages/product-plan-summary/ProductPlanSummary';
import { PricingDto } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/PricingModel.gen';
import Box from '~/neo-ui/packages/box/Box';
import { SubscriptionBillingPeriodEnum } from '@SubscriptionClient/BeastClient/Beast/Msp/Model/Subscription.gen';
import { parseISO } from 'date-fns';
import DiscountOfferSummary from '~/wm/packages/subscription/packages/discount-info/packages/discount-offer-summary/DiscountOfferSummary';
import NextTotalInvoiceSummary from '~/wm/packages/subscription/packages/next-invoice/packages/next-total-invoice-summary/NextTotalInvoiceSummary';
import Button from '~/neo-ui/packages/button/Button';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { SubscriptionUpdateSuccessDto } from '@SubscriptionClient/Subscription/Packages/Pricing/Model/Success.gen';
import SubscriptionUpdateSuccess from '~/wm/packages/subscription/packages/grow/wizard/success/SubscriptionUpdateSuccess';
import Spinner from '~/neo-ui/spinner/Spinner';

export type SubscriptionOfferPlanProps = {
  subscriptionAgreementUrl: string;
};

const SubscriptionPlanOffer: React.FunctionComponent<SubscriptionOfferPlanProps> = ({ subscriptionAgreementUrl }) => {
  const { subscriptionPlanOfferDto } = useSubscriptionPlanOffer();
  const { pdDisplayInfo } = useSubscriptionPdContact();
  const [confirmed, setConfirmed] = React.useState<boolean>(false);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
  const [successInfo, setSuccessInfo] = React.useState<SubscriptionUpdateSuccessDto | undefined>();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const { callApi } = useApi();

  if (successInfo) {
    return <SubscriptionUpdateSuccess successDto={successInfo} />;
  }

  if (typeof subscriptionPlanOfferDto === 'undefined') {
    return <div />;
  }

  if (subscriptionPlanOfferDto.isExpired) {
    return (
      <SubscriptionOfferExpired>
        {pdDisplayInfo ? (
          <Card padding={'lg'}>
            <PdContactSection
              pdDisplayInfo={pdDisplayInfo}
              reasonToContact={'To talk about your ScalePad subscription'}
            />
          </Card>
        ) : (
          <React.Fragment />
        )}
      </SubscriptionOfferExpired>
    );
  }

  const pricingDto: PricingDto = {
    billingDetailsDto: undefined,
    priceDto: subscriptionPlanOfferDto.offerPlanPricingDto.priceDto,
    productDtos: subscriptionPlanOfferDto.offerPlanPricingDto.productDtos,
    discountsDto: subscriptionPlanOfferDto.offerPlanPricingDto.discountsDto,
  };

  const term = subscriptionPlanOfferDto.termInfo
    ? {
        startsAt: subscriptionPlanOfferDto.termInfo.validFrom ? parseISO(subscriptionPlanOfferDto.termInfo.validFrom) : undefined,
        endsAt: parseISO(subscriptionPlanOfferDto.termInfo.validUntil),
        durationMonths: subscriptionPlanOfferDto.termInfo.lengthMonths,
        renewalBehavior: subscriptionPlanOfferDto.termInfo.termRenewalBehavior,
      }
    : undefined;

  return (
    <Card
      backgroundColor={'secondary-400'}
      padding={'xl'}
      css={css`
        width: 38.75rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <Header
        size={1}
        color={'light-300'}
        css={css`
          margin-bottom: 0.875rem;
          text-align: center;
        `}
      >
        {subscriptionPlanOfferDto.offerSummaryDto.title}
      </Header>
      <Header
        size={4}
        color={'light-300'}
        css={css`
          margin-bottom: 2.625rem;
          text-align: center;
        `}
      >
        {subscriptionPlanOfferDto.offerSummaryDto.subtitle}
      </Header>
      <DiscountOfferSummary
        css={css`
          margin-bottom: 0.625rem;
        `}
        pdImageUrl={pdDisplayInfo?.imageSource ?? ''}
        discountPercentage={subscriptionPlanOfferDto.loyaltyDiscount}
        daysUntilExpiry={subscriptionPlanOfferDto.daysLeft}
        offerSummaryDto={subscriptionPlanOfferDto.offerSummaryDto}
      />
      <div>
        <Box
          css={css`
            border-radius: 10px 10px 0px 0px;
            padding: 30px;
          `}
        >
          <ProductPlanSummary
            pricingDto={pricingDto}
            isOffer={true}
          />
        </Box>
        <Box
          css={css`
            background-color: #f0f1f9;
          `}
        >
          <NextTotalInvoiceSummary
            priceDto={pricingDto?.priceDto}
            isFirstPayment={false}
            isInForm={true}
          />
        </Box>
        <Box
          css={css`
            border-radius: 0px 0px 10px 10px;
            padding: 30px;
          `}
        >
          <TermSummary
            term={term}
            billingPeriod={SubscriptionBillingPeriodEnum.Monthly}
            subscriptionAgreementLink={{
              href: subscriptionAgreementUrl,
              openInNewTab: true,
            }}
          />
        </Box>
      </div>
      <div
        css={css`
          color: #fff;
          font-size: 0.875rem;
          opacity: 0.8;
          margin-top: 1.25rem;
        `}
      >
        All amounts are shown in USD, before applicable taxes.
      </div>
      <InputCheckbox
        css={css`
          align-self: flex-start;
          margin-top: 1.25rem;
          margin-bottom: 1.875rem;
        `}
        theme={'secondary'}
        description={
          'I agree that from the date my subscription term applies, my credit card will be charged according to the ScalePad Subscription Agreement.'
        }
        checked={confirmed}
        onClick={() => setConfirmed(!confirmed)}
      />
      {errorMessage && (
        <Header
          size={5}
          css={css`
            margin-bottom: 1.25rem;
            color: ${colorToCode('negative-400')};
          `}
        >
          {errorMessage}
        </Header>
      )}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <Button
          size={'lg'}
          theme={'positive'}
          disabled={!confirmed || isSubmitting}
          onClick={async () => {
            setSubmitting(true);
            const emptyRequest: EmptyRequest = {};
            const response = await callApi(() => subscriptionPricingOfferAccept(emptyRequest));
            if (!response) {
              setErrorMessage('Unexpected error: Please contact support.');
              return;
            }
            setSubmitting(false);
            if (!response.success || response.errorMessage) {
              setErrorMessage(`Error: ${response.errorMessage ?? 'Please contact support.'}`);
            } else if (response.success && response.subscriptionUpdateSuccessDto) {
              setSuccessInfo(response.subscriptionUpdateSuccessDto);
            }
          }}
        >
          {isSubmitting ? <Spinner /> : 'Grow my subscription now'}
        </Button>
      </div>
    </Card>
  );
};

export default SubscriptionPlanOffer;
