import * as React from 'react';
import ScorecardCategoryCreateButton from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-category/packages/scorecard-category-create-button/ScorecardCategoryCreateButton';
import useScorecardContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardContext';
import LayoutFooter from '~/neo-ui/packages/layout/packages/footer/LayoutFooter';

const ScorecardLayoutFooter = () => {
  const { scorecard } = useScorecardContext();

  return (
    <LayoutFooter
      leftControls={[
        {
          expanded: (
            <ScorecardCategoryCreateButton
              scorecardId={scorecard.scorecardId}
              scorecardCategoryAvailabilities={scorecard.scorecardCategoryAvailabilities}
            />
          ),
        },
      ]}
    />
  );
};

export default ScorecardLayoutFooter;
