import { css } from '@emotion/react';
import * as React from 'react';
import { Themeable } from '~/neo-ui/model/capacity';
import Button, { ButtonProps } from '~/neo-ui/packages/button/Button';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import { useCallback } from 'react';

export type IconPlacement = 'left' | 'right' | 'none';
export type FormSubmitFooterProps = {
  FormSubmitStatusIndicator: React.ReactNode;
  submitActionLabel: string;
  submitActionIcon: IconType;
  submitActionIconPlacement: IconPlacement;
  disableSticky?: boolean;
  onCancel?: () => Promise<void>;
  buttonSize?: ButtonProps['size'];
} & Themeable;

const FormSubmitFooter: React.FunctionComponent<FormSubmitFooterProps> = ({
  FormSubmitStatusIndicator,
  submitActionLabel,
  submitActionIcon,
  submitActionIconPlacement = 'left',
  disableSticky = false,
  theme = 'positive',
  onCancel,
  buttonSize = 'lg',
}) => {
  const { isSubmitting, submitForm, resetForm } = useFormContext();

  const disabled = isSubmitting;

  const onCancelAndReset = useCallback(async () => {
    if (onCancel) {
      await onCancel();
      resetForm();
    }
  }, [onCancel, resetForm]);

  return (
    <div
      css={css`
        display: flex;
        ${disableSticky
          ? ''
          : 'position: sticky;' +
            'padding: 0.9375rem;' +
            'height: 4.5rem;' +
            'bottom: 1.5rem;' +
            'border-radius: 0.635rem;' +
            'box-shadow: 0 0.0625rem 0.25rem -0.1875rem rgba(0, 0, 0, 0.8);' +
            "background-color: ${colorToCode('light-000')};"}
        margin-top: 0.9375rem;
        align-items: center;
        z-index: 10;
      `}
    >
      {/* This hidden input allows for save-on-enter */}
      <input
        css={css`
          display: none;
        `}
        disabled={disabled}
        type="submit"
      />
      <Button
        css={css`
          margin-right: 0.625rem;
        `}
        disabled={disabled}
        iconLeft={submitActionIconPlacement === 'left' ? submitActionIcon : undefined}
        iconRight={submitActionIconPlacement === 'right' ? submitActionIcon : undefined}
        size={buttonSize}
        theme={theme}
        onClick={submitForm}
      >
        {submitActionLabel}
      </Button>
      {FormSubmitStatusIndicator}
      {onCancel && (
        <Button
          css={css`
            margin-right: 0.625rem;
          `}
          disabled={disabled}
          iconLeft={'Cancel'}
          size={buttonSize}
          onClick={onCancelAndReset}
        >
          <div
            css={css`
              width: fit-content;
            `}
          >
            Cancel
          </div>
        </Button>
      )}
    </div>
  );
};

export default FormSubmitFooter;
