import * as React from 'react';
import { components, GroupProps } from 'react-select';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { css } from '@emotion/react';

const SingleSelectGroup = <T extends string>(props: GroupProps<SelectOption<T>, false>) => {
  const { Group } = components;

  return (
    <Group
      {...props}
      css={css`
        ${props.label !== `` && 'border-top: 0.08rem solid rgba(0,0,0,0.1);'}
      `}
    >
      {props.children}
    </Group>
  );
};

export default SingleSelectGroup;
